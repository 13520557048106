import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useSessionUser } from "ExtensionV2/queries/useSessionUser";
import { SiteDetails } from "Common/proto/entity/site_pb";

export const useOnlyAdminUsersCanEditCampaigns = (): boolean => {
  const { siteUIBehavior } = useSessionSite();

  // If non-admin users are restricted in any way, let's say they can't edit campaigns at all.
  if (
    siteUIBehavior.onlyAdminsCanEditStatuses ||
    siteUIBehavior.onlyAdminsCanEditBids ||
    siteUIBehavior.onlyAdminsCanEditBudgets ||
    siteUIBehavior.onlyAdminsCanEditDetails ||
    siteUIBehavior.onlyAdminsCanAddKeywords ||
    siteUIBehavior.onlyAdminsCanAddCampaigns
  ) {
    return true;
  }

  return false;
};

const useCanUserEditCampaign = (
  field: keyof SiteDetails.UIBehavior.AsObject
): boolean => {
  const { siteUIBehavior } = useSessionSite();
  const { isCurrentSiteAdmin, isAmpdOperator } = useSessionUser();

  if (isCurrentSiteAdmin || isAmpdOperator) {
    return true;
  }

  if (siteUIBehavior[field]) {
    return false;
  }

  return true;
};

export const useCanUserEditStatuses = (): boolean => {
  return useCanUserEditCampaign("onlyAdminsCanEditStatuses");
};

export const useCanUserEditBids = (): boolean => {
  return useCanUserEditCampaign("onlyAdminsCanEditBids");
};

export const useCanUserEditBudgets = (): boolean => {
  return useCanUserEditCampaign("onlyAdminsCanEditBids");
};

export const useCanUserEditDetails = (): boolean => {
  return useCanUserEditCampaign("onlyAdminsCanEditDetails");
};

export const useCanUserAddKeywords = (): boolean => {
  return useCanUserEditCampaign("onlyAdminsCanAddKeywords");
};

export const useCanUserAddCampaigns = (): boolean => {
  return useCanUserEditCampaign("onlyAdminsCanAddCampaigns");
};
