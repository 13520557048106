import { formatMetric } from "Common/utils/metrics";
import { backgroundPrimary, semanticGreen } from "../../styles/colors";

export const VoltageBudgetSparklineConfig = ({
  name,
  values,
  currentX,
  setCurrentX,
  formatX,
  metricDef,
  referenceY
}) => ({
  name: name,
  chart: {
    animation: false,
    backgroundColor: null,
    borderWidth: 0,
    type: "line",
    margin: [8, 0, 8, 0],
    width: 150,
    height: 80,
    style: {
      overflow: "visible",
      float: "right"
    },
    skipClone: true
  },
  title: {
    text: ""
  },
  credits: {
    enabled: false
  },
  xAxis: {
    lineWidth: 0,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
    plotLines: [
      {
        color: backgroundPrimary,
        dashStyle: "Solid",
        value: currentX,
        width: 2
      }
    ]
  },
  yAxis: {
    softMin: 0,
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    tickPositions: [0],
    plotLines:
      referenceY == null
        ? undefined
        : [
            {
              color: semanticGreen,
              dashStyle: "LongDash",
              value: referenceY,
              width: 2
            }
          ]
  },
  legend: {
    enabled: false
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,.7)",
    animation: false,
    crosshairs: true,
    hideDelay: 0,
    shared: true,
    useHTML: true,
    outside: true,
    formatter() {
      let s = `${formatX(this.x)}`;
      this.points.forEach(({ y, series }) => {
        s += `<br /><span style="font-size: smaller;"> ${
          series.name
        }:</span><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ${formatMetric(
            metricDef,
            y
          )}<br /><span style="font-size: smaller;"> current budget:</span><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ${formatMetric(metricDef, referenceY)}`;
      });
      return s;
    }
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      point: {
        events: {
          click() {
            setCurrentX(this.x);
          }
        }
      },
      shadow: false,
      states: {
        hover: {
          lineWidth: 2
        }
      }
    }
  },
  series: [
    {
      id: "value",
      name: "recommended budget",
      color: "black",
      dashStyle: "Solid",
      marker: {
        symbol: "circle",
        radius: 2,
        states: {
          select: {
            lineColor: "gray"
          }
        }
      },
      data: values,
      zIndex: 2
    }
  ]
});

export const VoltageMetricSparklineConfig = ({
  name,
  lowValues,
  midValues,
  highValues,
  currentX,
  setCurrentX,
  formatX,
  metricDef
}) => ({
  name: name,
  chart: {
    animation: false,
    backgroundColor: null,
    borderWidth: 0,
    type: "line",
    margin: [8, 0, 8, 0],
    width: 150,
    height: 80,
    style: {
      overflow: "visible",
      float: "right"
    },
    skipClone: true
  },
  title: {
    text: ""
  },
  credits: {
    enabled: false
  },
  xAxis: {
    lineWidth: 0,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
    plotLines: [
      {
        color: backgroundPrimary,
        dashStyle: "Solid",
        value: currentX,
        width: 2
      }
    ]
  },
  yAxis: {
    softMin: 0,
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    tickPositions: [0]
  },
  legend: {
    enabled: false
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,.7)",
    animation: false,
    crosshairs: true,
    hideDelay: 0,
    shared: true,
    useHTML: true,
    outside: true,
    formatter() {
      let s = `${formatX(this.x)}`;
      this.points.forEach(({ y, series }) => {
        s += `<br /><span style="font-size: smaller;"> ${
          series.name
        }:</span><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ${formatMetric(metricDef, y)}`;
      });
      return s;
    }
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      point: {
        events: {
          click() {
            setCurrentX(this.x);
          }
        }
      },
      shadow: false,
      states: {
        hover: {
          lineWidth: 2
        }
      }
    }
  },
  series: [
    {
      id: "high",
      name: "90% chance below",
      color: "gray",
      dashStyle: "Dot",
      marker: {
        symbol: "circle",
        radius: 1,
        states: {
          select: {
            lineColor: "gray"
          }
        }
      },
      data: highValues,
      zIndex: 1
    },
    {
      id: "mid",
      name: "expected value",
      color: "black",
      dashStyle: "Solid",
      marker: {
        symbol: "circle",
        radius: 2,
        states: {
          select: {
            lineColor: "gray"
          }
        }
      },
      data: midValues,
      zIndex: 2
    },
    {
      id: "low",
      name: "90% chance above",
      color: "gray",
      dashStyle: "Dot",
      marker: {
        symbol: "circle",
        radius: 1,
        states: {
          select: {
            lineColor: "gray"
          }
        }
      },
      data: lowValues,
      zIndex: 0
    }
  ]
});
