// source: proto/edge/grpcwebPb/grpcweb_Admin.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_edge_grpcwebPb_grpcweb_DashboardSession_pb = require('../../../proto/edge/grpcwebPb/grpcweb_DashboardSession_pb.js');
goog.object.extend(proto, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb);
var proto_common_amazon_pb = require('../../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
var proto_common_facebook_pb = require('../../../proto/common/facebook_pb.js');
goog.object.extend(proto, proto_common_facebook_pb);
var proto_common_utmParameters_pb = require('../../../proto/common/utmParameters_pb.js');
goog.object.extend(proto, proto_common_utmParameters_pb);
var proto_entity_billingAccount_pb = require('../../../proto/entity/billingAccount_pb.js');
goog.object.extend(proto, proto_entity_billingAccount_pb);
var proto_entity_site_pb = require('../../../proto/entity/site_pb.js');
goog.object.extend(proto, proto_entity_site_pb);
var proto_entity_siteManagerLink_pb = require('../../../proto/entity/siteManagerLink_pb.js');
goog.object.extend(proto, proto_entity_siteManagerLink_pb);
goog.exportSymbol('proto.grpcwebPb.GetAmpdSubscriptionsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmpdSubscriptionsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetClientSitesForManagerSiteReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetClientSitesForManagerSiteRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.IsAmpdAdsSubscriberReply', null, global);
goog.exportSymbol('proto.grpcwebPb.IsAmpdAdsSubscriberRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkBillingAccountsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkBillingAccountsReply.LinkingError', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkBillingAccountsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkClientSiteToManagerSiteReply', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkClientSiteToManagerSiteRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkDataSourceReply', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkDataSourceRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.LinkDataSourceRequest.TypeCase', null, global);
goog.exportSymbol('proto.grpcwebPb.RefreshBillingAccountReply', null, global);
goog.exportSymbol('proto.grpcwebPb.RefreshBillingAccountRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.SignUpLinkedSiteReply', null, global);
goog.exportSymbol('proto.grpcwebPb.SignUpLinkedSiteRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.SignUpReply', null, global);
goog.exportSymbol('proto.grpcwebPb.SignUpRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkDataSourceReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkDataSourceRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount', null, global);
goog.exportSymbol('proto.grpcwebPb.UnlinkDataSourceRequest.TypeCase', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.SignUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.SignUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.SignUpRequest.displayName = 'proto.grpcwebPb.SignUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.SignUpReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.SignUpReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.SignUpReply.displayName = 'proto.grpcwebPb.SignUpReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.SignUpLinkedSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.SignUpLinkedSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.SignUpLinkedSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.SignUpLinkedSiteRequest.displayName = 'proto.grpcwebPb.SignUpLinkedSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.SignUpLinkedSiteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.SignUpLinkedSiteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.SignUpLinkedSiteReply.displayName = 'proto.grpcwebPb.SignUpLinkedSiteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkDataSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpcwebPb.LinkDataSourceRequest.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.LinkDataSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkDataSourceRequest.displayName = 'proto.grpcwebPb.LinkDataSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkDataSourceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.LinkDataSourceReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.LinkDataSourceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkDataSourceReply.displayName = 'proto.grpcwebPb.LinkDataSourceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkDataSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpcwebPb.UnlinkDataSourceRequest.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.UnlinkDataSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkDataSourceRequest.displayName = 'proto.grpcwebPb.UnlinkDataSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.displayName = 'proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.displayName = 'proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.displayName = 'proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkDataSourceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UnlinkDataSourceReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UnlinkDataSourceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkDataSourceReply.displayName = 'proto.grpcwebPb.UnlinkDataSourceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.LinkClientSiteToManagerSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.displayName = 'proto.grpcwebPb.LinkClientSiteToManagerSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.LinkClientSiteToManagerSiteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkClientSiteToManagerSiteReply.displayName = 'proto.grpcwebPb.LinkClientSiteToManagerSiteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.displayName = 'proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.displayName = 'proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.displayName = 'proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.displayName = 'proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetClientSitesForManagerSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetClientSitesForManagerSiteRequest.displayName = 'proto.grpcwebPb.GetClientSitesForManagerSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetClientSitesForManagerSiteReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetClientSitesForManagerSiteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetClientSitesForManagerSiteReply.displayName = 'proto.grpcwebPb.GetClientSitesForManagerSiteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.RefreshBillingAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.RefreshBillingAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.RefreshBillingAccountRequest.displayName = 'proto.grpcwebPb.RefreshBillingAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.RefreshBillingAccountReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.RefreshBillingAccountReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.RefreshBillingAccountReply.displayName = 'proto.grpcwebPb.RefreshBillingAccountReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkBillingAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.LinkBillingAccountsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.LinkBillingAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkBillingAccountsRequest.displayName = 'proto.grpcwebPb.LinkBillingAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkBillingAccountsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.LinkBillingAccountsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.LinkBillingAccountsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkBillingAccountsReply.displayName = 'proto.grpcwebPb.LinkBillingAccountsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.LinkBillingAccountsReply.LinkingError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.displayName = 'proto.grpcwebPb.LinkBillingAccountsReply.LinkingError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.IsAmpdAdsSubscriberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.IsAmpdAdsSubscriberRequest.displayName = 'proto.grpcwebPb.IsAmpdAdsSubscriberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.IsAmpdAdsSubscriberReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.IsAmpdAdsSubscriberReply.displayName = 'proto.grpcwebPb.IsAmpdAdsSubscriberReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmpdSubscriptionsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmpdSubscriptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmpdSubscriptionsRequest.displayName = 'proto.grpcwebPb.GetAmpdSubscriptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmpdSubscriptionsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmpdSubscriptionsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmpdSubscriptionsReply.displayName = 'proto.grpcwebPb.GetAmpdSubscriptionsReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.SignUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.SignUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.SignUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    salesPlatforms: (f = msg.getSalesPlatforms()) && proto_entity_site_pb.SiteDetails.SalesPlatforms.toObject(includeInstance, f),
    siteDomain: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attribution: jspb.Message.getFieldWithDefault(msg, 3, ""),
    utmParameters: (f = msg.getUtmParameters()) && proto_common_utmParameters_pb.UTMParameters.toObject(includeInstance, f),
    siteAliasSuffix: jspb.Message.getFieldWithDefault(msg, 5, ""),
    coupon: jspb.Message.getFieldWithDefault(msg, 7, ""),
    organizationType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    siteContactEmail: jspb.Message.getFieldWithDefault(msg, 9, ""),
    gmvGreaterThan: jspb.Message.getFieldWithDefault(msg, 10, 0),
    useEnterpriseBilling: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    clientBillingAccountMax: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isCommandCenter: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.SignUpRequest}
 */
proto.grpcwebPb.SignUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.SignUpRequest;
  return proto.grpcwebPb.SignUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.SignUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.SignUpRequest}
 */
proto.grpcwebPb.SignUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 6:
      var value = new proto_entity_site_pb.SiteDetails.SalesPlatforms;
      reader.readMessage(value,proto_entity_site_pb.SiteDetails.SalesPlatforms.deserializeBinaryFromReader);
      msg.setSalesPlatforms(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribution(value);
      break;
    case 4:
      var value = new proto_common_utmParameters_pb.UTMParameters;
      reader.readMessage(value,proto_common_utmParameters_pb.UTMParameters.deserializeBinaryFromReader);
      msg.setUtmParameters(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAliasSuffix(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoupon(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.InternalSiteDetails.OrganizationType.Option} */ (reader.readEnum());
      msg.setOrganizationType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteContactEmail(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGmvGreaterThan(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseEnterpriseBilling(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClientBillingAccountMax(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCommandCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.SignUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.SignUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.SignUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalesPlatforms();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_entity_site_pb.SiteDetails.SalesPlatforms.serializeBinaryToWriter
    );
  }
  f = message.getSiteDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttribution();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUtmParameters();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_utmParameters_pb.UTMParameters.serializeBinaryToWriter
    );
  }
  f = message.getSiteAliasSuffix();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCoupon();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrganizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSiteContactEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGmvGreaterThan();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUseEnterpriseBilling();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getClientBillingAccountMax();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getIsCommandCenter();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string site_name = 1;
 * @return {string}
 */
proto.grpcwebPb.SignUpRequest.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.SiteDetails.SalesPlatforms sales_platforms = 6;
 * @return {?proto.entity.SiteDetails.SalesPlatforms}
 */
proto.grpcwebPb.SignUpRequest.prototype.getSalesPlatforms = function() {
  return /** @type{?proto.entity.SiteDetails.SalesPlatforms} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.SiteDetails.SalesPlatforms, 6));
};


/**
 * @param {?proto.entity.SiteDetails.SalesPlatforms|undefined} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
*/
proto.grpcwebPb.SignUpRequest.prototype.setSalesPlatforms = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.clearSalesPlatforms = function() {
  return this.setSalesPlatforms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.SignUpRequest.prototype.hasSalesPlatforms = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string site_domain = 2;
 * @return {string}
 */
proto.grpcwebPb.SignUpRequest.prototype.getSiteDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setSiteDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string attribution = 3;
 * @return {string}
 */
proto.grpcwebPb.SignUpRequest.prototype.getAttribution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setAttribution = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.UTMParameters utm_parameters = 4;
 * @return {?proto.common.UTMParameters}
 */
proto.grpcwebPb.SignUpRequest.prototype.getUtmParameters = function() {
  return /** @type{?proto.common.UTMParameters} */ (
    jspb.Message.getWrapperField(this, proto_common_utmParameters_pb.UTMParameters, 4));
};


/**
 * @param {?proto.common.UTMParameters|undefined} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
*/
proto.grpcwebPb.SignUpRequest.prototype.setUtmParameters = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.clearUtmParameters = function() {
  return this.setUtmParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.SignUpRequest.prototype.hasUtmParameters = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string site_alias_suffix = 5;
 * @return {string}
 */
proto.grpcwebPb.SignUpRequest.prototype.getSiteAliasSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setSiteAliasSuffix = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string coupon = 7;
 * @return {string}
 */
proto.grpcwebPb.SignUpRequest.prototype.getCoupon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setCoupon = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional entity.InternalSiteDetails.OrganizationType.Option organization_type = 8;
 * @return {!proto.entity.InternalSiteDetails.OrganizationType.Option}
 */
proto.grpcwebPb.SignUpRequest.prototype.getOrganizationType = function() {
  return /** @type {!proto.entity.InternalSiteDetails.OrganizationType.Option} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.InternalSiteDetails.OrganizationType.Option} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setOrganizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string site_contact_email = 9;
 * @return {string}
 */
proto.grpcwebPb.SignUpRequest.prototype.getSiteContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setSiteContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 gmv_greater_than = 10;
 * @return {number}
 */
proto.grpcwebPb.SignUpRequest.prototype.getGmvGreaterThan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setGmvGreaterThan = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool use_enterprise_billing = 11;
 * @return {boolean}
 */
proto.grpcwebPb.SignUpRequest.prototype.getUseEnterpriseBilling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setUseEnterpriseBilling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 client_billing_account_max = 12;
 * @return {number}
 */
proto.grpcwebPb.SignUpRequest.prototype.getClientBillingAccountMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setClientBillingAccountMax = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool is_command_center = 13;
 * @return {boolean}
 */
proto.grpcwebPb.SignUpRequest.prototype.getIsCommandCenter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.SignUpRequest} returns this
 */
proto.grpcwebPb.SignUpRequest.prototype.setIsCommandCenter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.SignUpReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.SignUpReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.SignUpReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.SignUpReply}
 */
proto.grpcwebPb.SignUpReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.SignUpReply;
  return proto.grpcwebPb.SignUpReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.SignUpReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.SignUpReply}
 */
proto.grpcwebPb.SignUpReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.SignUpReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.SignUpReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.SignUpReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.SignUpReply.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpReply} returns this
 */
proto.grpcwebPb.SignUpReply.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.SignUpLinkedSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.SignUpLinkedSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    signUpRequest: (f = msg.getSignUpRequest()) && proto.grpcwebPb.SignUpRequest.toObject(includeInstance, f),
    linkRequest: (f = msg.getLinkRequest()) && proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.toObject(includeInstance, f),
    owningAdminEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    additionalAdminEmailsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    additionalUserEmailsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.SignUpLinkedSiteRequest;
  return proto.grpcwebPb.SignUpLinkedSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.SignUpLinkedSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.SignUpRequest;
      reader.readMessage(value,proto.grpcwebPb.SignUpRequest.deserializeBinaryFromReader);
      msg.setSignUpRequest(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.LinkClientSiteToManagerSiteRequest;
      reader.readMessage(value,proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.deserializeBinaryFromReader);
      msg.setLinkRequest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwningAdminEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdditionalAdminEmails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdditionalUserEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.SignUpLinkedSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.SignUpLinkedSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignUpRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpcwebPb.SignUpRequest.serializeBinaryToWriter
    );
  }
  f = message.getLinkRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.serializeBinaryToWriter
    );
  }
  f = message.getOwningAdminEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdditionalAdminEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getAdditionalUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional SignUpRequest sign_up_request = 1;
 * @return {?proto.grpcwebPb.SignUpRequest}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.getSignUpRequest = function() {
  return /** @type{?proto.grpcwebPb.SignUpRequest} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.SignUpRequest, 1));
};


/**
 * @param {?proto.grpcwebPb.SignUpRequest|undefined} value
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
*/
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.setSignUpRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.clearSignUpRequest = function() {
  return this.setSignUpRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.hasSignUpRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LinkClientSiteToManagerSiteRequest link_request = 2;
 * @return {?proto.grpcwebPb.LinkClientSiteToManagerSiteRequest}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.getLinkRequest = function() {
  return /** @type{?proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.LinkClientSiteToManagerSiteRequest, 2));
};


/**
 * @param {?proto.grpcwebPb.LinkClientSiteToManagerSiteRequest|undefined} value
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
*/
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.setLinkRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.clearLinkRequest = function() {
  return this.setLinkRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.hasLinkRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string owning_admin_email = 3;
 * @return {string}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.getOwningAdminEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.setOwningAdminEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string additional_admin_emails = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.getAdditionalAdminEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.setAdditionalAdminEmailsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.addAdditionalAdminEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.clearAdditionalAdminEmailsList = function() {
  return this.setAdditionalAdminEmailsList([]);
};


/**
 * repeated string additional_user_emails = 5;
 * @return {!Array<string>}
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.getAdditionalUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.setAdditionalUserEmailsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.addAdditionalUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteRequest} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteRequest.prototype.clearAdditionalUserEmailsList = function() {
  return this.setAdditionalUserEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.SignUpLinkedSiteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.SignUpLinkedSiteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.SignUpLinkedSiteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpLinkedSiteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_entity_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteReply}
 */
proto.grpcwebPb.SignUpLinkedSiteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.SignUpLinkedSiteReply;
  return proto.grpcwebPb.SignUpLinkedSiteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.SignUpLinkedSiteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteReply}
 */
proto.grpcwebPb.SignUpLinkedSiteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.Site;
      reader.readMessage(value,proto_entity_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.SignUpLinkedSiteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.SignUpLinkedSiteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.SignUpLinkedSiteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SignUpLinkedSiteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.Site site = 1;
 * @return {?proto.entity.Site}
 */
proto.grpcwebPb.SignUpLinkedSiteReply.prototype.getSite = function() {
  return /** @type{?proto.entity.Site} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.Site, 1));
};


/**
 * @param {?proto.entity.Site|undefined} value
 * @return {!proto.grpcwebPb.SignUpLinkedSiteReply} returns this
*/
proto.grpcwebPb.SignUpLinkedSiteReply.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.SignUpLinkedSiteReply} returns this
 */
proto.grpcwebPb.SignUpLinkedSiteReply.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.SignUpLinkedSiteReply.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.LinkDataSourceRequest.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.grpcwebPb.LinkDataSourceRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  AMAZON_ADVERTISING: 2,
  AMAZON_SELLER: 3,
  FACEBOOK: 4
};

/**
 * @return {proto.grpcwebPb.LinkDataSourceRequest.TypeCase}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.getTypeCase = function() {
  return /** @type {proto.grpcwebPb.LinkDataSourceRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.LinkDataSourceRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkDataSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkDataSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkDataSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amazonAdvertising: (f = msg.getAmazonAdvertising()) && proto_common_amazon_pb.Amazon.AdvertisingAuthCode.toObject(includeInstance, f),
    amazonSeller: (f = msg.getAmazonSeller()) && proto_common_amazon_pb.Amazon.SellerAuthCode.toObject(includeInstance, f),
    facebook: (f = msg.getFacebook()) && proto_common_facebook_pb.Facebook.AuthCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkDataSourceRequest}
 */
proto.grpcwebPb.LinkDataSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkDataSourceRequest;
  return proto.grpcwebPb.LinkDataSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkDataSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkDataSourceRequest}
 */
proto.grpcwebPb.LinkDataSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingAuthCode;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingAuthCode.deserializeBinaryFromReader);
      msg.setAmazonAdvertising(value);
      break;
    case 3:
      var value = new proto_common_amazon_pb.Amazon.SellerAuthCode;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.SellerAuthCode.deserializeBinaryFromReader);
      msg.setAmazonSeller(value);
      break;
    case 4:
      var value = new proto_common_facebook_pb.Facebook.AuthCode;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.AuthCode.deserializeBinaryFromReader);
      msg.setFacebook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkDataSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkDataSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkDataSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmazonAdvertising();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingAuthCode.serializeBinaryToWriter
    );
  }
  f = message.getAmazonSeller();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_amazon_pb.Amazon.SellerAuthCode.serializeBinaryToWriter
    );
  }
  f = message.getFacebook();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_facebook_pb.Facebook.AuthCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.AdvertisingAuthCode amazon_advertising = 2;
 * @return {?proto.common.Amazon.AdvertisingAuthCode}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.getAmazonAdvertising = function() {
  return /** @type{?proto.common.Amazon.AdvertisingAuthCode} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingAuthCode, 2));
};


/**
 * @param {?proto.common.Amazon.AdvertisingAuthCode|undefined} value
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
*/
proto.grpcwebPb.LinkDataSourceRequest.prototype.setAmazonAdvertising = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.grpcwebPb.LinkDataSourceRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.clearAmazonAdvertising = function() {
  return this.setAmazonAdvertising(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.hasAmazonAdvertising = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.Amazon.SellerAuthCode amazon_seller = 3;
 * @return {?proto.common.Amazon.SellerAuthCode}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.getAmazonSeller = function() {
  return /** @type{?proto.common.Amazon.SellerAuthCode} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.SellerAuthCode, 3));
};


/**
 * @param {?proto.common.Amazon.SellerAuthCode|undefined} value
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
*/
proto.grpcwebPb.LinkDataSourceRequest.prototype.setAmazonSeller = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.grpcwebPb.LinkDataSourceRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.clearAmazonSeller = function() {
  return this.setAmazonSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.hasAmazonSeller = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.Facebook.AuthCode facebook = 4;
 * @return {?proto.common.Facebook.AuthCode}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.getFacebook = function() {
  return /** @type{?proto.common.Facebook.AuthCode} */ (
    jspb.Message.getWrapperField(this, proto_common_facebook_pb.Facebook.AuthCode, 4));
};


/**
 * @param {?proto.common.Facebook.AuthCode|undefined} value
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
*/
proto.grpcwebPb.LinkDataSourceRequest.prototype.setFacebook = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.grpcwebPb.LinkDataSourceRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.LinkDataSourceRequest} returns this
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.clearFacebook = function() {
  return this.setFacebook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.LinkDataSourceRequest.prototype.hasFacebook = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.LinkDataSourceReply.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkDataSourceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkDataSourceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkDataSourceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    adwordsAccountsList: jspb.Message.toObjectList(msg.getAdwordsAccountsList(),
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.toObject, includeInstance),
    amazonAdvertisingAccountsList: jspb.Message.toObjectList(msg.getAmazonAdvertisingAccountsList(),
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.toObject, includeInstance),
    amazonSellerAccountsList: jspb.Message.toObjectList(msg.getAmazonSellerAccountsList(),
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo.toObject, includeInstance),
    amazonAdvertisingProfilesList: jspb.Message.toObjectList(msg.getAmazonAdvertisingProfilesList(),
    proto_common_amazon_pb.Amazon.AdvertisingProfile.toObject, includeInstance),
    facebookAccount: (f = msg.getFacebookAccount()) && proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardFacebookAccountInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkDataSourceReply}
 */
proto.grpcwebPb.LinkDataSourceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkDataSourceReply;
  return proto.grpcwebPb.LinkDataSourceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkDataSourceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkDataSourceReply}
 */
proto.grpcwebPb.LinkDataSourceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinaryFromReader);
      msg.addAdwordsAccounts(value);
      break;
    case 2:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.deserializeBinaryFromReader);
      msg.addAmazonAdvertisingAccounts(value);
      break;
    case 3:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo.deserializeBinaryFromReader);
      msg.addAmazonSellerAccounts(value);
      break;
    case 4:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfile;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfile.deserializeBinaryFromReader);
      msg.addAmazonAdvertisingProfiles(value);
      break;
    case 5:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardFacebookAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardFacebookAccountInfo.deserializeBinaryFromReader);
      msg.setFacebookAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkDataSourceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkDataSourceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkDataSourceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdwordsAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAdvertisingAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonSellerAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAdvertisingProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfile.serializeBinaryToWriter
    );
  }
  f = message.getFacebookAccount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardFacebookAccountInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardSite.DashboardAdwordsAccountInfo adwords_accounts = 1;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.getAdwordsAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>} value
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
*/
proto.grpcwebPb.LinkDataSourceReply.prototype.setAdwordsAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.addAdwordsAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.clearAdwordsAccountsList = function() {
  return this.setAdwordsAccountsList([]);
};


/**
 * repeated DashboardSite.DashboardAmazonAdvertisingAccountInfo amazon_advertising_accounts = 2;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.getAmazonAdvertisingAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, 2));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>} value
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
*/
proto.grpcwebPb.LinkDataSourceReply.prototype.setAmazonAdvertisingAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.addAmazonAdvertisingAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.clearAmazonAdvertisingAccountsList = function() {
  return this.setAmazonAdvertisingAccountsList([]);
};


/**
 * repeated DashboardSite.DashboardAmazonSellerAccountInfo amazon_seller_accounts = 3;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.getAmazonSellerAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>} value
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
*/
proto.grpcwebPb.LinkDataSourceReply.prototype.setAmazonSellerAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.addAmazonSellerAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.clearAmazonSellerAccountsList = function() {
  return this.setAmazonSellerAccountsList([]);
};


/**
 * repeated common.Amazon.AdvertisingProfile amazon_advertising_profiles = 4;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfile>}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.getAmazonAdvertisingProfilesList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfile, 4));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfile>} value
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
*/
proto.grpcwebPb.LinkDataSourceReply.prototype.setAmazonAdvertisingProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.addAmazonAdvertisingProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.Amazon.AdvertisingProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.clearAmazonAdvertisingProfilesList = function() {
  return this.setAmazonAdvertisingProfilesList([]);
};


/**
 * optional DashboardSite.DashboardFacebookAccountInfo facebook_account = 5;
 * @return {?proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.getFacebookAccount = function() {
  return /** @type{?proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardFacebookAccountInfo, 5));
};


/**
 * @param {?proto.grpcwebPb.DashboardSite.DashboardFacebookAccountInfo|undefined} value
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
*/
proto.grpcwebPb.LinkDataSourceReply.prototype.setFacebookAccount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.LinkDataSourceReply} returns this
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.clearFacebookAccount = function() {
  return this.setFacebookAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.LinkDataSourceReply.prototype.hasFacebookAccount = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.UnlinkDataSourceRequest.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  AMAZON_ADVERTISING: 2,
  AMAZON_SELLER: 3,
  ADWORDS: 4
};

/**
 * @return {proto.grpcwebPb.UnlinkDataSourceRequest.TypeCase}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.getTypeCase = function() {
  return /** @type {proto.grpcwebPb.UnlinkDataSourceRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.UnlinkDataSourceRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkDataSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amazonAdvertising: (f = msg.getAmazonAdvertising()) && proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.toObject(includeInstance, f),
    amazonSeller: (f = msg.getAmazonSeller()) && proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.toObject(includeInstance, f),
    adwords: (f = msg.getAdwords()) && proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkDataSourceRequest;
  return proto.grpcwebPb.UnlinkDataSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector;
      reader.readMessage(value,proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.deserializeBinaryFromReader);
      msg.setAmazonAdvertising(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount;
      reader.readMessage(value,proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.deserializeBinaryFromReader);
      msg.setAmazonSeller(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector;
      reader.readMessage(value,proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.deserializeBinaryFromReader);
      msg.setAdwords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkDataSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmazonAdvertising();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.serializeBinaryToWriter
    );
  }
  f = message.getAmazonSeller();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.serializeBinaryToWriter
    );
  }
  f = message.getAdwords();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountRegion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector;
  return proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setAccountRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string account_user_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.prototype.getAccountUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.prototype.setAccountUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Region.Option account_region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.prototype.getAccountRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector.prototype.setAccountRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    sellingPartnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount;
  return proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellingPartnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSellingPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string selling_partner_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.prototype.getSellingPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.prototype.setSellingPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Region.Option region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector;
  return proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AmazonAdvertisingAccountSelector amazon_advertising = 2;
 * @return {?proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.getAmazonAdvertising = function() {
  return /** @type{?proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector, 2));
};


/**
 * @param {?proto.grpcwebPb.UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector|undefined} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
*/
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.setAmazonAdvertising = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.grpcwebPb.UnlinkDataSourceRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.clearAmazonAdvertising = function() {
  return this.setAmazonAdvertising(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.hasAmazonAdvertising = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AmazonSellerAccount amazon_seller = 3;
 * @return {?proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.getAmazonSeller = function() {
  return /** @type{?proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount, 3));
};


/**
 * @param {?proto.grpcwebPb.UnlinkDataSourceRequest.AmazonSellerAccount|undefined} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
*/
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.setAmazonSeller = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.grpcwebPb.UnlinkDataSourceRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.clearAmazonSeller = function() {
  return this.setAmazonSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.hasAmazonSeller = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AdwordsAccountSelector adwords = 4;
 * @return {?proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.getAdwords = function() {
  return /** @type{?proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector, 4));
};


/**
 * @param {?proto.grpcwebPb.UnlinkDataSourceRequest.AdwordsAccountSelector|undefined} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
*/
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.setAdwords = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.grpcwebPb.UnlinkDataSourceRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UnlinkDataSourceRequest} returns this
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.clearAdwords = function() {
  return this.setAdwords(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UnlinkDataSourceRequest.prototype.hasAdwords = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UnlinkDataSourceReply.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkDataSourceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkDataSourceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    wasLinked: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    adwordsAccountsList: jspb.Message.toObjectList(msg.getAdwordsAccountsList(),
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.toObject, includeInstance),
    amazonAdvertisingAccountsList: jspb.Message.toObjectList(msg.getAmazonAdvertisingAccountsList(),
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.toObject, includeInstance),
    amazonSellerAccountsList: jspb.Message.toObjectList(msg.getAmazonSellerAccountsList(),
    proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo.toObject, includeInstance),
    amazonAdvertisingProfilesList: jspb.Message.toObjectList(msg.getAmazonAdvertisingProfilesList(),
    proto_common_amazon_pb.Amazon.AdvertisingProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply}
 */
proto.grpcwebPb.UnlinkDataSourceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkDataSourceReply;
  return proto.grpcwebPb.UnlinkDataSourceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkDataSourceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply}
 */
proto.grpcwebPb.UnlinkDataSourceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWasLinked(value);
      break;
    case 2:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinaryFromReader);
      msg.addAdwordsAccounts(value);
      break;
    case 3:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.deserializeBinaryFromReader);
      msg.addAmazonAdvertisingAccounts(value);
      break;
    case 4:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo.deserializeBinaryFromReader);
      msg.addAmazonSellerAccounts(value);
      break;
    case 5:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfile;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfile.deserializeBinaryFromReader);
      msg.addAmazonAdvertisingProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkDataSourceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkDataSourceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkDataSourceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWasLinked();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAdwordsAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAdvertisingAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonSellerAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAdvertisingProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool was_linked = 1;
 * @return {boolean}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.getWasLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.setWasLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated DashboardSite.DashboardAdwordsAccountInfo adwords_accounts = 2;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.getAdwordsAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo, 2));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo>} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
*/
proto.grpcwebPb.UnlinkDataSourceReply.prototype.setAdwordsAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.addAdwordsAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.clearAdwordsAccountsList = function() {
  return this.setAdwordsAccountsList([]);
};


/**
 * repeated DashboardSite.DashboardAmazonAdvertisingAccountInfo amazon_advertising_accounts = 3;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.getAmazonAdvertisingAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo>} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
*/
proto.grpcwebPb.UnlinkDataSourceReply.prototype.setAmazonAdvertisingAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.addAmazonAdvertisingAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.grpcwebPb.DashboardSite.DashboardAmazonAdvertisingAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.clearAmazonAdvertisingAccountsList = function() {
  return this.setAmazonAdvertisingAccountsList([]);
};


/**
 * repeated DashboardSite.DashboardAmazonSellerAccountInfo amazon_seller_accounts = 4;
 * @return {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.getAmazonSellerAccountsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAmazonSellerAccountInfo, 4));
};


/**
 * @param {!Array<!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo>} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
*/
proto.grpcwebPb.UnlinkDataSourceReply.prototype.setAmazonSellerAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.addAmazonSellerAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpcwebPb.DashboardSite.DashboardAmazonSellerAccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.clearAmazonSellerAccountsList = function() {
  return this.setAmazonSellerAccountsList([]);
};


/**
 * repeated common.Amazon.AdvertisingProfile amazon_advertising_profiles = 5;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfile>}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.getAmazonAdvertisingProfilesList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfile, 5));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfile>} value
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
*/
proto.grpcwebPb.UnlinkDataSourceReply.prototype.setAmazonAdvertisingProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.addAmazonAdvertisingProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.Amazon.AdvertisingProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UnlinkDataSourceReply} returns this
 */
proto.grpcwebPb.UnlinkDataSourceReply.prototype.clearAmazonAdvertisingProfilesList = function() {
  return this.setAmazonAdvertisingProfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detailsAboutClient: (f = msg.getDetailsAboutClient()) && proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details.toObject(includeInstance, f),
    detailsAboutManager: (f = msg.getDetailsAboutManager()) && proto_entity_siteManagerLink_pb.SiteManagerLinkManager.Details.toObject(includeInstance, f),
    linkToManagerBilling: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkClientSiteToManagerSiteRequest;
  return proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    case 3:
      var value = new proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details;
      reader.readMessage(value,proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutClient(value);
      break;
    case 4:
      var value = new proto_entity_siteManagerLink_pb.SiteManagerLinkManager.Details;
      reader.readMessage(value,proto_entity_siteManagerLink_pb.SiteManagerLinkManager.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutManager(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLinkToManagerBilling(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetailsAboutClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details.serializeBinaryToWriter
    );
  }
  f = message.getDetailsAboutManager();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_entity_siteManagerLink_pb.SiteManagerLinkManager.Details.serializeBinaryToWriter
    );
  }
  f = message.getLinkToManagerBilling();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.SiteManagerLinkClient.Details details_about_client = 3;
 * @return {?proto.entity.SiteManagerLinkClient.Details}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.getDetailsAboutClient = function() {
  return /** @type{?proto.entity.SiteManagerLinkClient.Details} */ (
    jspb.Message.getWrapperField(this, proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details, 3));
};


/**
 * @param {?proto.entity.SiteManagerLinkClient.Details|undefined} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
*/
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.setDetailsAboutClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.clearDetailsAboutClient = function() {
  return this.setDetailsAboutClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.hasDetailsAboutClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional entity.SiteManagerLinkManager.Details details_about_manager = 4;
 * @return {?proto.entity.SiteManagerLinkManager.Details}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.getDetailsAboutManager = function() {
  return /** @type{?proto.entity.SiteManagerLinkManager.Details} */ (
    jspb.Message.getWrapperField(this, proto_entity_siteManagerLink_pb.SiteManagerLinkManager.Details, 4));
};


/**
 * @param {?proto.entity.SiteManagerLinkManager.Details|undefined} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
*/
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.setDetailsAboutManager = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.clearDetailsAboutManager = function() {
  return this.setDetailsAboutManager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.hasDetailsAboutManager = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool link_to_manager_billing = 5;
 * @return {boolean}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.getLinkToManagerBilling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteRequest} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteRequest.prototype.setLinkToManagerBilling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkClientSiteToManagerSiteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkClientSiteToManagerSiteReply;
  return proto.grpcwebPb.LinkClientSiteToManagerSiteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkClientSiteToManagerSiteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkClientSiteToManagerSiteReply} returns this
 */
proto.grpcwebPb.LinkClientSiteToManagerSiteReply.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest;
  return proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest} returns this
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest} returns this
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteRequest.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply;
  return proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply} returns this
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply} returns this
 */
proto.grpcwebPb.UnlinkClientSiteFromManagerSiteReply.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addLabelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    removeLabelsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest;
  return proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddLabels(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemoveLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getRemoveLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string add_labels = 3;
 * @return {!Array<string>}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.getAddLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.setAddLabelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.addAddLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.clearAddLabelsList = function() {
  return this.setAddLabelsList([]);
};


/**
 * repeated string remove_labels = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.getRemoveLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.setRemoveLabelsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.addRemoveLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsRequest.prototype.clearRemoveLabelsList = function() {
  return this.setRemoveLabelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detailsAboutClient: (f = msg.getDetailsAboutClient()) && proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply;
  return proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    case 3:
      var value = new proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details;
      reader.readMessage(value,proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetailsAboutClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details.serializeBinaryToWriter
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.SiteManagerLinkClient.Details details_about_client = 3;
 * @return {?proto.entity.SiteManagerLinkClient.Details}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.getDetailsAboutClient = function() {
  return /** @type{?proto.entity.SiteManagerLinkClient.Details} */ (
    jspb.Message.getWrapperField(this, proto_entity_siteManagerLink_pb.SiteManagerLinkClient.Details, 3));
};


/**
 * @param {?proto.entity.SiteManagerLinkClient.Details|undefined} value
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} returns this
*/
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.setDetailsAboutClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply} returns this
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.clearDetailsAboutClient = function() {
  return this.setDetailsAboutClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteManagerLinkClientLabelsReply.prototype.hasDetailsAboutClient = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetClientSitesForManagerSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetClientSitesForManagerSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteRequest}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetClientSitesForManagerSiteRequest;
  return proto.grpcwebPb.GetClientSitesForManagerSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetClientSitesForManagerSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteRequest}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetClientSitesForManagerSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetClientSitesForManagerSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteRequest} returns this
 */
proto.grpcwebPb.GetClientSitesForManagerSiteRequest.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetClientSitesForManagerSiteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetClientSitesForManagerSiteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSitesList: jspb.Message.toObjectList(msg.getClientSitesList(),
    proto_entity_siteManagerLink_pb.SiteManagerLinkClient.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteReply}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetClientSitesForManagerSiteReply;
  return proto.grpcwebPb.GetClientSitesForManagerSiteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetClientSitesForManagerSiteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteReply}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 2:
      var value = new proto_entity_siteManagerLink_pb.SiteManagerLinkClient;
      reader.readMessage(value,proto_entity_siteManagerLink_pb.SiteManagerLinkClient.deserializeBinaryFromReader);
      msg.addClientSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetClientSitesForManagerSiteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetClientSitesForManagerSiteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entity_siteManagerLink_pb.SiteManagerLinkClient.serializeBinaryToWriter
    );
  }
};


/**
 * optional string manager_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteReply} returns this
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated entity.SiteManagerLinkClient client_sites = 2;
 * @return {!Array<!proto.entity.SiteManagerLinkClient>}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.getClientSitesList = function() {
  return /** @type{!Array<!proto.entity.SiteManagerLinkClient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_siteManagerLink_pb.SiteManagerLinkClient, 2));
};


/**
 * @param {!Array<!proto.entity.SiteManagerLinkClient>} value
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteReply} returns this
*/
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.setClientSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.SiteManagerLinkClient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteManagerLinkClient}
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.addClientSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.SiteManagerLinkClient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetClientSitesForManagerSiteReply} returns this
 */
proto.grpcwebPb.GetClientSitesForManagerSiteReply.prototype.clearClientSitesList = function() {
  return this.setClientSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.RefreshBillingAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.RefreshBillingAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.RefreshBillingAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.RefreshBillingAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.RefreshBillingAccountRequest}
 */
proto.grpcwebPb.RefreshBillingAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.RefreshBillingAccountRequest;
  return proto.grpcwebPb.RefreshBillingAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.RefreshBillingAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.RefreshBillingAccountRequest}
 */
proto.grpcwebPb.RefreshBillingAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.RefreshBillingAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.RefreshBillingAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.RefreshBillingAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.RefreshBillingAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.RefreshBillingAccountRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.RefreshBillingAccountRequest} returns this
 */
proto.grpcwebPb.RefreshBillingAccountRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.RefreshBillingAccountReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.RefreshBillingAccountReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.RefreshBillingAccountReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.RefreshBillingAccountReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    updated: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.RefreshBillingAccountReply}
 */
proto.grpcwebPb.RefreshBillingAccountReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.RefreshBillingAccountReply;
  return proto.grpcwebPb.RefreshBillingAccountReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.RefreshBillingAccountReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.RefreshBillingAccountReply}
 */
proto.grpcwebPb.RefreshBillingAccountReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.RefreshBillingAccountReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.RefreshBillingAccountReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.RefreshBillingAccountReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.RefreshBillingAccountReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool updated = 1;
 * @return {boolean}
 */
proto.grpcwebPb.RefreshBillingAccountReply.prototype.getUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.RefreshBillingAccountReply} returns this
 */
proto.grpcwebPb.RefreshBillingAccountReply.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.LinkBillingAccountsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkBillingAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkBillingAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkBillingAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSiteAliasesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    targetSiteAlias: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkBillingAccountsRequest}
 */
proto.grpcwebPb.LinkBillingAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkBillingAccountsRequest;
  return proto.grpcwebPb.LinkBillingAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkBillingAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkBillingAccountsRequest}
 */
proto.grpcwebPb.LinkBillingAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addClientSiteAliases(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkBillingAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkBillingAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkBillingAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSiteAliasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTargetSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string client_site_aliases = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.getClientSiteAliasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.LinkBillingAccountsRequest} returns this
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.setClientSiteAliasesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.LinkBillingAccountsRequest} returns this
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.addClientSiteAliases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.LinkBillingAccountsRequest} returns this
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.clearClientSiteAliasesList = function() {
  return this.setClientSiteAliasesList([]);
};


/**
 * optional string target_site_alias = 2;
 * @return {string}
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.getTargetSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkBillingAccountsRequest} returns this
 */
proto.grpcwebPb.LinkBillingAccountsRequest.prototype.setTargetSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.LinkBillingAccountsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkBillingAccountsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkBillingAccountsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkBillingAccountsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkingErrorsList: jspb.Message.toObjectList(msg.getLinkingErrorsList(),
    proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply}
 */
proto.grpcwebPb.LinkBillingAccountsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkBillingAccountsReply;
  return proto.grpcwebPb.LinkBillingAccountsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply}
 */
proto.grpcwebPb.LinkBillingAccountsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.LinkBillingAccountsReply.LinkingError;
      reader.readMessage(value,proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.deserializeBinaryFromReader);
      msg.addLinkingErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkBillingAccountsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkBillingAccountsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkBillingAccountsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkingErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError}
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.LinkBillingAccountsReply.LinkingError;
  return proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError}
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string client_site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError} returns this
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError} returns this
 */
proto.grpcwebPb.LinkBillingAccountsReply.LinkingError.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LinkingError linking_errors = 1;
 * @return {!Array<!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError>}
 */
proto.grpcwebPb.LinkBillingAccountsReply.prototype.getLinkingErrorsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.LinkBillingAccountsReply.LinkingError, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError>} value
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply} returns this
*/
proto.grpcwebPb.LinkBillingAccountsReply.prototype.setLinkingErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply.LinkingError}
 */
proto.grpcwebPb.LinkBillingAccountsReply.prototype.addLinkingErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.LinkBillingAccountsReply.LinkingError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.LinkBillingAccountsReply} returns this
 */
proto.grpcwebPb.LinkBillingAccountsReply.prototype.clearLinkingErrorsList = function() {
  return this.setLinkingErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.IsAmpdAdsSubscriberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.IsAmpdAdsSubscriberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.IsAmpdAdsSubscriberRequest}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.IsAmpdAdsSubscriberRequest;
  return proto.grpcwebPb.IsAmpdAdsSubscriberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.IsAmpdAdsSubscriberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.IsAmpdAdsSubscriberRequest}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.IsAmpdAdsSubscriberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.IsAmpdAdsSubscriberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.IsAmpdAdsSubscriberRequest} returns this
 */
proto.grpcwebPb.IsAmpdAdsSubscriberRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.IsAmpdAdsSubscriberReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.IsAmpdAdsSubscriberReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAmpdAdsSubscriber: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.IsAmpdAdsSubscriberReply}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.IsAmpdAdsSubscriberReply;
  return proto.grpcwebPb.IsAmpdAdsSubscriberReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.IsAmpdAdsSubscriberReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.IsAmpdAdsSubscriberReply}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAmpdAdsSubscriber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.IsAmpdAdsSubscriberReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.IsAmpdAdsSubscriberReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAmpdAdsSubscriber();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_ampd_ads_subscriber = 1;
 * @return {boolean}
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.prototype.getIsAmpdAdsSubscriber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.IsAmpdAdsSubscriberReply} returns this
 */
proto.grpcwebPb.IsAmpdAdsSubscriberReply.prototype.setIsAmpdAdsSubscriber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmpdSubscriptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    subscriptionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsRequest}
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmpdSubscriptionsRequest;
  return proto.grpcwebPb.GetAmpdSubscriptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsRequest}
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveOnly(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.entity.AmpdSubscription.SubscriptionID>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubscriptionIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmpdSubscriptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSubscriptionIdsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional bool active_only = 1;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.getActiveOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} returns this
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.setActiveOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated entity.AmpdSubscription.SubscriptionID subscription_ids = 2;
 * @return {!Array<!proto.entity.AmpdSubscription.SubscriptionID>}
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.getSubscriptionIdsList = function() {
  return /** @type {!Array<!proto.entity.AmpdSubscription.SubscriptionID>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.entity.AmpdSubscription.SubscriptionID>} value
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} returns this
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.setSubscriptionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.entity.AmpdSubscription.SubscriptionID} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} returns this
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.addSubscriptionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsRequest} returns this
 */
proto.grpcwebPb.GetAmpdSubscriptionsRequest.prototype.clearSubscriptionIdsList = function() {
  return this.setSubscriptionIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmpdSubscriptionsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmpdSubscriptionsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    proto_entity_billingAccount_pb.AmpdSubscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsReply}
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmpdSubscriptionsReply;
  return proto.grpcwebPb.GetAmpdSubscriptionsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmpdSubscriptionsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsReply}
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_billingAccount_pb.AmpdSubscription;
      reader.readMessage(value,proto_entity_billingAccount_pb.AmpdSubscription.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmpdSubscriptionsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmpdSubscriptionsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_entity_billingAccount_pb.AmpdSubscription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.AmpdSubscription subscriptions = 1;
 * @return {!Array<!proto.entity.AmpdSubscription>}
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.prototype.getSubscriptionsList = function() {
  return /** @type{!Array<!proto.entity.AmpdSubscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_billingAccount_pb.AmpdSubscription, 1));
};


/**
 * @param {!Array<!proto.entity.AmpdSubscription>} value
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsReply} returns this
*/
proto.grpcwebPb.GetAmpdSubscriptionsReply.prototype.setSubscriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.AmpdSubscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.AmpdSubscription}
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.AmpdSubscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmpdSubscriptionsReply} returns this
 */
proto.grpcwebPb.GetAmpdSubscriptionsReply.prototype.clearSubscriptionsList = function() {
  return this.setSubscriptionsList([]);
};


goog.object.extend(exports, proto.grpcwebPb);
