import { useQueries, UseQueryResult } from "@tanstack/react-query";
import {
  GetCampaignConfigurationsReply,
  GetCampaignConfigurationsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { streamProcessor } from "Common/utils/grpcStreams";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import {
  CampaignConfiguration,
  CampaignConfigurationsByCampaignIdResult
} from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import useMakeQueriesStable from "ExtensionV2/state/useMakeQueriesStable";

function useMultiSiteAmpdCampaignConfigurations({
  siteAliases
}: {
  siteAliases: Array<string>;
}): Array<UseQueryResult<CampaignConfigurationsByCampaignIdResult, unknown>> {
  const oneHour = 1000 * 60 * 60; //one hour

  // useQueries does not return a stable result since it is a new array every time,
  // but the elements of the array should reach a stable state eventually.
  return useMakeQueriesStable(
    useQueries({
      queries: siteAliases.map(siteAlias => ({
        queryKey: ["multiCampaignConfigurations", siteAlias],
        staleTime: oneHour,
        cacheTime: oneHour,
        enabled: !!siteAlias,
        queryFn: async () => {
          const req = new GetCampaignConfigurationsRequest();
          req.setSiteAlias(siteAlias);
          req.setCampaignPlatform(CampaignPlatform.Option.GOOGLE_ADS);
          req.setReturnStoredAmpdCampaignConfigurations(true);

          const campaignConfigurationsByCampaignId = new Map<
            string,
            CampaignConfiguration
          >();

          await streamProcessor(
            GRPCWebCallbackClient.getCampaignConfigurations(req),
            (reply: GetCampaignConfigurationsReply) => {
              reply
                .getCampaignConfigurationsList()
                .forEach(campaignConfiguration => {
                  const campaignId = campaignConfiguration
                    ?.getAmpdResourceConfiguration()
                    ?.getGoogleAds()
                    ?.getCampaignConfiguration()
                    ?.getCampaignId();
                  if (campaignId) {
                    const campaignIdStr = String(campaignId);

                    // Put the configuration in the map if these are complete (that is,
                    // Ampd campaigns) or if they are partial and not already in the
                    // map (so we don't override a complete configuration with a
                    // partial one).
                    if (
                      !campaignConfigurationsByCampaignId.has(campaignIdStr)
                    ) {
                      const campaignConfigurationWithSiteAlias = {
                        ...campaignConfiguration.toObject(),
                        siteAlias: siteAlias
                      };

                      campaignConfigurationsByCampaignId.set(
                        campaignIdStr,
                        campaignConfigurationWithSiteAlias
                      );
                    }
                  }
                });
            }
          );

          return campaignConfigurationsByCampaignId;
        }
      }))
    })
  );
}

export default useMultiSiteAmpdCampaignConfigurations;
