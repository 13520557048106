import { Amazon } from "../proto/common/amazon_pb";
import { Walmart } from "../proto/common/walmart_pb";
import { Retailer } from "../proto/common/retailer_pb";
import { None } from "Common/utils/tsUtils";
import {
  walmartDomains,
  getWalmartMarketplaceInfo
} from "Common/utils/walmart";
import { amazonDomains, getAmazonMarketplaceInfo } from "Common/utils/amazon";

export interface MarketplaceInfo {
  retailer: Retailer.Option;
  marketplace: Amazon.Marketplace.Option | Walmart.Marketplace.Option | 0;
  name: string;
  domain: string;
  geotargets: Array<number>;
  currencyCode: string;
}

export interface AmazonMarketplaceInfo extends MarketplaceInfo {
  retailer: Retailer.Option.AMAZON;
  marketplace: Amazon.Marketplace.Option;
  name: string;
  domain: string;
  geotargets: Array<number>;
  currencyCode: string;

  region: Amazon.Region.Option;
  sellerCentralDomain: string;
  vendorCentralDomain: string;
}

export interface WalmartMarketplaceInfo extends MarketplaceInfo {
  retailer: Retailer.Option.WALMART;
  marketplace: Walmart.Marketplace.Option;
  name: string;
  domain: string;
  geotargets: Array<number>;
  currencyCode: string;
}

export function isAmazonMarketplaceInfo(
  marketplaceInfo: MarketplaceInfo | None
): marketplaceInfo is AmazonMarketplaceInfo {
  if (!marketplaceInfo || marketplaceInfo.marketplace === 0) {
    return false;
  }
  return marketplaceInfo.retailer === Retailer.Option.AMAZON;
}

export function isWalmartMarketplaceInfo(
  marketplaceInfo: MarketplaceInfo | None
): marketplaceInfo is WalmartMarketplaceInfo {
  if (!marketplaceInfo || marketplaceInfo.marketplace === 0) {
    return false;
  }
  return marketplaceInfo.retailer === Retailer.Option.WALMART;
}

// Returns the MarketplaceInfo for the specified retailer given the specified
// marketplace enum value.
export function getMarketplaceInfo(
  retailer: Retailer.Option | None,
  marketplace: Amazon.Marketplace.Option | Walmart.Marketplace.Option | None
): MarketplaceInfo | null {
  if (!retailer || !marketplace) {
    return null;
  }

  if (retailer === Retailer.Option.AMAZON) {
    return getAmazonMarketplaceInfo(marketplace as Amazon.Marketplace.Option);
  } else if (retailer === Retailer.Option.WALMART) {
    return getWalmartMarketplaceInfo(marketplace as Walmart.Marketplace.Option);
  }

  return null;
}

// Returns the MarketplaceInfo for the specified target domain.
export function getMarketplaceInfoForDomain(
  domain: string | None
): MarketplaceInfo | null {
  if (!domain) {
    return null;
  }

  const amazonMarketplaceInfo = amazonDomains.get(domain);
  if (amazonMarketplaceInfo) {
    return amazonMarketplaceInfo;
  }

  const walmartMarketplaceInfo = walmartDomains.get(domain);
  if (walmartMarketplaceInfo) {
    return walmartMarketplaceInfo;
  }

  return null;
}
