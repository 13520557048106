import _ from "lodash";
import React from "react";
import { Route, Redirect } from "react-router-dom";

function UnauthenticatedRoute({
  component: Component,
  sessionState,
  routeIfAlreadyAuthenticated,
  path,
  exact,
  strict,
  location,
  sensitive,
  ...props
}) {
  const isAuthenticated = _.get(sessionState, "isAuthenticated");

  return (
    // If the user is already authenticated, forward them to their site index
    <Route
      {...{
        path,
        exact,
        strict,
        location,
        sensitive
      }}
      render={routeProps => {
        return isAuthenticated ? (
          <Redirect to={routeIfAlreadyAuthenticated || "/"} />
        ) : (
          <Component {...routeProps} {...props} />
        );
      }}
    />
  );
}

UnauthenticatedRoute.defaultProps = {
  path: "",
  exact: false,
  strict: false,
  location: {},
  sensitive: false
};

export default UnauthenticatedRoute;
