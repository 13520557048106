import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import {
  // This alias is only necessary until we drop the local definition of AmpdSubscription
  AmpdSubscription as Subscription,
  BillingAccount
} from "Common/proto/entity/billingAccount_pb";
import { formatMetric } from "Common/utils/metrics";
import { pluralize } from "Common/utils/strings";
import { Site } from "ExtensionV2/queries/useSession";
import { getCurrencyMetricDef } from "Common/utils/money";

const billingIntervalOptions: Record<number, string> = {
  1: "month",
  3: "quarter",
  6: "half-year",
  12: "year"
};

export function genPriceHeadline(
  selectedVariant: Subscription.AsObject
): string {
  const formattedTotalAmount = formatMetric(
    getCurrencyMetricDef("USD", false),
    selectedVariant.centsPerBillingInterval / 100
  );

  // fix this to handle intervals other than months
  const priceHeadline = `${formattedTotalAmount} per ${
    billingIntervalOptions[selectedVariant.billingIntervalMonths]
  } for ${pluralize(
    selectedVariant.minimumBillingIntervals,
    billingIntervalOptions[selectedVariant.billingIntervalMonths]
  )}`;

  return priceHeadline;
}

const AMPD_CONNECT_GROUP_NAME = "Ampd Connect";
const AMPD_AUTOMATION_GROUP_NAME = "Ampd Automation";
export const AMPD_MANAGEMENT_GROUP_NAME = "Ampd Management";

export const FEATURES_BY_GROUP: Record<
  string,
  Array<{ text: string; bold: boolean }>
> = {
  [AMPD_CONNECT_GROUP_NAME]: [
    { text: "Up to 10 campaigns with Ampd Create®", bold: false },
    {
      text: "Keyword level attribution",
      bold: false
    },
    {
      text: "Ampd University®",
      bold: false
    },
    { text: "Ampd Knowledge Base Access", bold: false },
    { text: "Ampd Chat Support", bold: false }
  ],
  [AMPD_AUTOMATION_GROUP_NAME]: [
    { text: "Everything in Connect", bold: true },
    { text: "Unlimited Campaigns with Ampd Create®", bold: false },
    { text: "Ampd Protection®", bold: false },
    { text: "Ampd Predictive Bidding®", bold: false },
    { text: "1-on-1 Onboarding", bold: false }
  ],
  [AMPD_MANAGEMENT_GROUP_NAME]: [
    { text: "Everything in Ampd Automation", bold: true },
    { text: "Dedicated Account Manager", bold: false },
    { text: "Campaigns Managed by Account Manager", bold: false },
    { text: "Full-Funnel Reporting", bold: false }
  ]
};

export const SUBTITLE_BY_GROUP: Record<string, string> = {
  [AMPD_CONNECT_GROUP_NAME]:
    "Self-Serve package for Google Ads experts who will create and optimize their own campaigns.",
  [AMPD_AUTOMATION_GROUP_NAME]:
    "Leverage Ampd Automation for a more hands-off experience.",
  [AMPD_MANAGEMENT_GROUP_NAME]:
    "White Glove service. Ampd Automation + expert oversight, strategy, and reporting."
};

export const AMPD_CONNECT_UPGRADE_OPTIONS: Array<Subscription.SubscriptionID> = [
  Subscription.SubscriptionID.AMPD_CONNECT_3_MONTH,
  Subscription.SubscriptionID.AMPD_CONNECT_6_MONTH,
  Subscription.SubscriptionID.AMPD_CONNECT_12_MONTH
];

export const AMPD_AUTOMATION_UPGRADE_OPTIONS: Array<Subscription.SubscriptionID> = [
  Subscription.SubscriptionID.AMPD_AUTOMATION_3_MONTH,
  Subscription.SubscriptionID.AMPD_AUTOMATION_6_MONTH,
  Subscription.SubscriptionID.AMPD_AUTOMATION_12_MONTH
];

export const AMPD_MANAGEMENT_UPGRADE_OPTIONS: Array<Subscription.SubscriptionID> = [
  Subscription.SubscriptionID.AMPD_MANAGEMENT_3_MONTH,
  Subscription.SubscriptionID.AMPD_MANAGEMENT_6_MONTH,
  Subscription.SubscriptionID.AMPD_MANAGEMENT_12_MONTH
];

// ampdPayment tests to see if a site needs to show the
// payment screen. If the customer has not paid, or if they are
// delinquent, or they cancelled, then show the payment screen.
export function needsAmpdPayment(
  billingAccountStatus: BillingAccount.Status.Option
): boolean {
  return billingAccountStatus !== BillingAccount.Status.Option.ACTIVE;
}

export const hasActiveStripePlan = (site: Site): boolean => {
  return (
    site.billingAccountStatus === BillingAccount.Status.Option.ACTIVE &&
    site.billingAccountType === DashboardSite.BillingAccountType.Option.STRIPE
  );
};

// We introduced a line about percentage of ad spend in this version of the click wrap - so
// if someone agreed to an older clickwrap we want them to sign the new one when changing plans.
export const PRICES_REQUIRING_OCT_2023_CLICK_WRAP: Array<Subscription.SubscriptionID> = [
  Subscription.SubscriptionID.AMPD_CONNECT_1_MONTH,
  Subscription.SubscriptionID.AMPD_CONNECT_3_MONTH,
  Subscription.SubscriptionID.AMPD_CONNECT_6_MONTH,
  Subscription.SubscriptionID.AMPD_CONNECT_12_MONTH,
  Subscription.SubscriptionID.AMPD_AUTOMATION_3_MONTH,
  Subscription.SubscriptionID.AMPD_AUTOMATION_6_MONTH,
  Subscription.SubscriptionID.AMPD_AUTOMATION_12_MONTH,
  Subscription.SubscriptionID.AMPD_MANAGEMENT_3_MONTH,
  Subscription.SubscriptionID.AMPD_MANAGEMENT_6_MONTH,
  Subscription.SubscriptionID.AMPD_MANAGEMENT_12_MONTH
];

// A plan is considered an upgrade if the monthly price is higher than the current plan.
export const isUpgrade = (
  currentSubscription: Subscription.AsObject,
  targetSubscription: Subscription.AsObject
): boolean => {
  const targetSubscriptionMonthlyCost =
    targetSubscription.centsPerBillingInterval /
    targetSubscription.billingIntervalMonths;

  const currentSubscriptionMonthlyCost =
    currentSubscription.centsPerBillingInterval /
    currentSubscription.billingIntervalMonths;

  return targetSubscriptionMonthlyCost > currentSubscriptionMonthlyCost;
};
