// grpc-web
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateSiteContactEmailRequest,
  UpdateSiteContactEmailReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Site_pb";

export async function updateSiteContactEmail(
  siteAlias: string,
  siteContactEmail: string
): Promise<UpdateSiteContactEmailReply.AsObject | undefined> {
  if (!siteAlias || !siteContactEmail) {
    return undefined;
  }

  const updateRequest = new UpdateSiteContactEmailRequest();
  updateRequest.setSiteAlias(siteAlias);
  updateRequest.setSiteContactEmail(siteContactEmail);

  const response = await GRPCWebClient.updateSiteContactEmail(
    updateRequest,
    null
  );
  return response?.toObject();
}

export function getSiteContactEmail(
  response: UpdateSiteContactEmailReply
): string {
  return response.getSiteContactEmail();
}
