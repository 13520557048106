import Immutable from "immutable";
import _ from "lodash";

import { stringForEnum } from "./proto";
import { Amazon } from "../proto/common/amazon_pb";
import { Retailer } from "../proto/common/retailer_pb";
import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import {
  AmazonMarketplaceInfo,
  isAmazonMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";
import { None } from "Common/utils/tsUtils";
import { GetCreatedGoogleAdsCampaignsReply } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { ProductDetails } from "Common/proto/common/productDetails_pb";

export const AMPD_ATTRIBUTION_SANDBOX_PROFILE_ID = "1851686789003907";

export const AMAZON_URL_REGEX = /^https?:\/\/(www\.)?amazon\./;

export const UNSPECIFIED_ATTRIBUTION_BEHAVIOR = -1;
export const AMPD_ATTRIBUTION_BEHAVIOR = 0;
export const CUSTOM_ATTRIBUTION_BEHAVIOR = 1;
export const NO_ATTRIBUTION_BEHAVIOR = 2;

// The delay does not include today.  The amazon attribution metrics (revenue)
// now take approximately 24 hours to be reported, whereas google ads metrics (cost)
// are delayed by at most 3 hours: https://support.google.com/google-ads/answer/2544985?hl=en.
export const AMAZON_ATTRIBUTION_DELAY_DAYS = 1;

// Map from Amazon region proto values to human readable value.
const graphqlRegionNameMap = Immutable.Map({
  NORTH_AMERICA: "North America",
  EUROPE: "Europe",
  FAR_EAST: "Asia-Pacific"
});

// Returns a region's (eg: "NORTH_AMERICA") human-readable name
export const getNameFromRegion = (
  region: string | None
): string | undefined => {
  return graphqlRegionNameMap.get(region || "");
};

// Formats a GraphQL Amazon Advertising account to a name.
export const formatAmazonAccount = (
  account:
    | DashboardSite.DashboardAmazonAdvertisingAccountInfo.AsObject
    | undefined
    | null
): string => {
  if (!account) {
    return "";
  }

  const regionName = graphqlRegionNameMap.get(
    stringForEnum(account.regionEnumOption),
    "Unknown"
  );

  return `${account.email} (${regionName})`;
};

// Given the enum value, return the AccountType
// Eg: getAccountTypeByEnum(3) => SELLER
export const getAccountTypeByEnum = (
  enumValue: Amazon.AdvertisingProfile.AccountType.Option | None
): string => {
  if (
    !enumValue ||
    enumValue === Amazon.AdvertisingProfile.AccountType.Option.UNKNOWN
  ) {
    return "";
  }
  return stringForEnum(Amazon.AdvertisingProfile.AccountType.Option, enumValue);
};

// Given the enum value, return the Marketplace
// Eg: getMarketplaceTypeByEnum(2) => UNITED_STATES
export const getMarketplaceTypeByEnum = (
  enumValue: Amazon.Marketplace.Option | None
): string => {
  if (!enumValue) {
    return "";
  }

  return stringForEnum(Amazon.Marketplace.Option, enumValue);
};

// The MarketplaceInfo for amazon.com that can be easily used directly.
export const amazonUSMarketplaceInfo: AmazonMarketplaceInfo = {
  retailer: Retailer.Option.AMAZON,
  marketplace: Amazon.Marketplace.Option.UNITED_STATES,
  region: Amazon.Region.Option.NORTH_AMERICA,
  name: "United States",
  domain: "amazon.com",
  geotargets: [2840],
  sellerCentralDomain: "sellercentral.amazon.com",
  vendorCentralDomain: "vendorcentral.amazon.com",
  currencyCode: "USD"
};

// A map of Amazon domains to their marketplace configurations. Most of this
// information is from amazon.proto, but isn't available in the Javascript
// protos, unfortunately.
//
// Seller Urls: https://developer-docs.amazon.com/sp-api/docs/seller-central-urls
// Vendor Urls: https://developer-docs.amazon.com/sp-api/docs/vendor-central-urls
export const amazonDomains = Immutable.Map<string, AmazonMarketplaceInfo>({
  "amazon.ca": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.CANADA,
    region: Amazon.Region.Option.NORTH_AMERICA,
    name: "Canada",
    domain: "amazon.ca",
    geotargets: [2124],
    sellerCentralDomain: "sellercentral.amazon.ca",
    vendorCentralDomain: "vendorcentral.amazon.ca",
    currencyCode: "CAD"
  },
  "amazon.com": amazonUSMarketplaceInfo,
  "amazon.com.mx": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.MEXICO,
    region: Amazon.Region.Option.NORTH_AMERICA,
    name: "Mexico",
    domain: "amazon.com.mx",
    geotargets: [2484],
    sellerCentralDomain: "sellercentral.amazon.com.mx",
    vendorCentralDomain: "vendorcentral.amazon.com.mx",
    currencyCode: "MXN"
  },
  "amazon.com.br": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.BRAZIL,
    region: Amazon.Region.Option.NORTH_AMERICA,
    name: "Brazil",
    domain: "amazon.com.br",
    geotargets: [2076],
    sellerCentralDomain: "sellercentral.amazon.com.br",
    vendorCentralDomain: "vendorcentral.amazon.com.br",
    currencyCode: "BRL"
  },
  "amazon.ae": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.UNITED_ARAB_EMIRATES,
    region: Amazon.Region.Option.EUROPE,
    name: "United Arab Emirates",
    domain: "amazon.ae",
    geotargets: [2792],
    sellerCentralDomain: "sellercentral.amazon.ae",
    vendorCentralDomain: "vendorcentral.amazon.me",
    currencyCode: "AED"
  },
  "amazon.de": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.GERMANY,
    name: "Germany",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.de",
    geotargets: [2276],
    sellerCentralDomain: "sellercentral-europe.amazon.com",
    vendorCentralDomain: "vendorcentral.amazon.de",
    currencyCode: "EUR"
  },
  "amazon.es": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.SPAIN,
    name: "Spain",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.es",
    geotargets: [2724],
    sellerCentralDomain: "sellercentral-europe.amazon.com",
    vendorCentralDomain: "vendorcentral.amazon.es",
    currencyCode: "EUR"
  },
  "amazon.fr": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.FRANCE,
    name: "France",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.fr",
    geotargets: [2250],
    sellerCentralDomain: "sellercentral-europe.amazon.com",
    vendorCentralDomain: "vendorcentral.amazon.fr",
    currencyCode: "EUR"
  },
  "amazon.co.uk": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.UNITED_KINGDOM,
    name: "United Kingdom",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.co.uk",
    geotargets: [2826],
    sellerCentralDomain: "sellercentral-europe.amazon.com",
    vendorCentralDomain: "vendorcentral.amazon.co.uk",
    currencyCode: "GBP"
  },
  "amazon.in": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.INDIA,
    name: "India",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.in",
    geotargets: [2356],
    sellerCentralDomain: "sellercentral.amazon.in",
    vendorCentralDomain: "www.vendorcentral.in",
    currencyCode: "INR"
  },
  "amazon.it": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.ITALY,
    name: "Italy",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.it",
    geotargets: [2380],
    sellerCentralDomain: "sellercentral-europe.amazon.com",
    vendorCentralDomain: "vendorcentral.amazon.it",
    currencyCode: "EUR"
  },
  "amazon.nl": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.NETHERLANDS,
    name: "Netherlands",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.nl",
    geotargets: [2528],
    sellerCentralDomain: "sellercentral.amazon.nl",
    vendorCentralDomain: "vendorcentral.amazon.nl",
    currencyCode: "EUR"
  },
  "amazon.pl": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.POLAND,
    name: "Poland",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.pl",
    geotargets: [2616],
    sellerCentralDomain: "sellercentral.amazon.pl",
    vendorCentralDomain: "vendorcentral.amazon.pl",
    currencyCode: "PLN"
  },
  "amazon.sa": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.SAUDI_ARABIA,
    name: "Saudi Arabia",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.sa",
    geotargets: [2682],
    // This isn't actually listed, just guessing (git.io/Jnci6).
    sellerCentralDomain: "sellercentral.amazon.sa",
    vendorCentralDomain: "vendorcentral.amazon.me",
    currencyCode: "SAR"
  },
  "amazon.se": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.SWEDEN,
    name: "Sweden",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.se",
    geotargets: [2752],
    sellerCentralDomain: "sellercentral.amazon.se",
    vendorCentralDomain: "vendorcentral.amazon.se",
    currencyCode: "SEK"
  },
  "amazon.com.tr": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.TURKEY,
    name: "Turkey",
    region: Amazon.Region.Option.EUROPE,
    domain: "amazon.com.tr",
    geotargets: [2792],
    sellerCentralDomain: "sellercentral.amazon.com.tr",
    vendorCentralDomain: "vendorcentral.amazon.com.tr",
    currencyCode: "TRY"
  },
  "amazon.sg": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.SINGAPORE,
    name: "Singapore",
    region: Amazon.Region.Option.FAR_EAST,
    domain: "amazon.sg",
    geotargets: [2702],
    sellerCentralDomain: "sellercentral.amazon.sg",
    vendorCentralDomain: "vendorcentral.amazon.com.sg",
    currencyCode: "SGD"
  },
  "amazon.com.au": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.AUSTRALIA,
    region: Amazon.Region.Option.FAR_EAST,
    name: "Australia",
    domain: "amazon.com.au",
    geotargets: [2036],
    sellerCentralDomain: "sellercentral.amazon.com.au",
    vendorCentralDomain: "vendorcentral.amazon.com.au",
    currencyCode: "AUD"
  },
  "amazon.co.jp": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.JAPAN,
    region: Amazon.Region.Option.FAR_EAST,
    name: "Japan",
    domain: "amazon.co.jp",
    geotargets: [2392],
    sellerCentralDomain: "sellercentral.amazon.co.jp",
    vendorCentralDomain: "vendorcentral.amazon.co.jp",
    currencyCode: "JPY"
  },
  "amazon.eg": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.EGYPT,
    region: Amazon.Region.Option.EUROPE,
    name: "Egypt",
    domain: "amazon.eg",
    geotargets: [2818],
    sellerCentralDomain: "sellercentral.amazon.eg",
    vendorCentralDomain: "vendorcentral.amazon.me",
    currencyCode: "EGP"
  },
  "amazon.com.be": {
    retailer: Retailer.Option.AMAZON,
    marketplace: Amazon.Marketplace.Option.BELGIUM,
    region: Amazon.Region.Option.EUROPE,
    name: "Belgium",
    domain: "amazon.com.be",
    geotargets: [2056],
    sellerCentralDomain: "sellercentral.amazon.com.be",
    vendorCentralDomain: "vendorcentral.amazon.com.be",
    currencyCode: "EUR"
  }
});

// Map from Amazon marketplace enum values to their marketplace entries.
export const amazonMarketplaces = Immutable.Map(
  amazonDomains.mapEntries(([, x]) => [x.marketplace, x])
);

// Returns the MarketplaceInfo for the specified amazon marketplace option.
export function getAmazonMarketplaceInfo(
  marketplace: Amazon.Marketplace.Option | None
): AmazonMarketplaceInfo | null {
  if (!marketplace) {
    return null;
  }

  for (const marketplaceInfo of amazonDomains.values()) {
    if (marketplaceInfo.marketplace === marketplace) {
      return marketplaceInfo;
    }
  }

  return null;
}

// Returns the amazon marketplace option for the specified MarketplaceInfo (if is for Amazon).
export function getAmazonMarketplace(
  marketplaceInfo: MarketplaceInfo | None
): Amazon.Marketplace.Option {
  if (!isAmazonMarketplaceInfo(marketplaceInfo)) {
    return Amazon.Marketplace.Option.UNKNOWN;
  }

  return marketplaceInfo.marketplace;
}

const ALPHANUMERIC = /^[a-z0-9]+$/i;

export function isValidASIN(s: string): boolean {
  if (!s || s.length !== 10) {
    return false;
  }

  return ALPHANUMERIC.test(s);
}

export type AmazonURLInfo = {
  domain: string | null;
  marketplaceInfo: AmazonMarketplaceInfo | null;
  asin: string | null;
  quoted: boolean;
  searchTerm: string | null;
  pathAndQuery: string | null;
  detailPagePath: string | null;
  cleanURL: string | null;
};

// Returns Amazon-specific information from a URL or other string.  If the string is not
// a full URL, then see if it looks like an ASIN.  If so, assume the caller wants information
// for the product detail page URL that targets that Amazon ASIN in amazon.com.  If the string
// is not an ASIN, but it is not a recognized Amazon URL, then return null for all returned values.
export function extractAmazonURLInfoFromString(
  urlString: string | None
): AmazonURLInfo {
  let domain = null;
  let marketplaceInfo = null;
  let asin = null;
  let quoted = false;
  let searchTerm = null;
  let pathAndQuery = null;
  let detailPagePath = null;
  let cleanURL = null;

  if (!urlString) {
    return {
      domain,
      marketplaceInfo,
      asin,
      quoted,
      searchTerm,
      pathAndQuery,
      detailPagePath,
      cleanURL
    };
  }

  urlString = urlString.replace(/[\n\r\t\v]/g, "");

  if (isValidASIN(urlString)) {
    asin = urlString.toUpperCase();
    detailPagePath = `/dp/${asin}`;

    return {
      domain: "amazon.com",
      marketplaceInfo: amazonDomains.get("amazon.com") || null,
      asin,
      quoted,
      searchTerm: null,
      pathAndQuery: detailPagePath,
      detailPagePath,
      cleanURL: `https://www.amazon.com/dp/${asin}`
    };
  }

  if (urlString[0] === '"') {
    quoted = true;
    urlString = urlString.slice(1);
    if (urlString.slice(-1) === '"') {
      urlString = urlString.slice(0, -1);
    }
  }

  if (!urlString.includes("://")) {
    urlString = "https://" + urlString;
  }

  try {
    const url = new URL(urlString);

    if (url.protocol.toLowerCase() === "http:") {
      url.protocol = "https:";
    }

    let shortened = false;
    domain = url.hostname.toLowerCase();
    if (domain.toLowerCase().startsWith("www.")) {
      domain = domain.slice(4);
    }

    if (domain.toLowerCase().startsWith("smile.")) {
      domain = domain.slice(6);
    }

    if (domain === "amzn.com") {
      domain = "amazon.com";
      shortened = true;
    }

    const parameters = url.searchParams.toString();
    pathAndQuery = url.pathname + (parameters ? `?${parameters}` : "");

    url.hash = "";
    const originalParts = url.pathname.split("/");
    const parts = url.pathname.toLowerCase().split("/");
    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      if (parts[partIndex] === "dp") {
        // Most common form of URL: https://www.amazon.com/dp/B06XF2TR11
        // or https://www.amazon.com/Some_Form_Of_The_Title/dp/B06XF2TR11
        const part = parts[partIndex + 1];
        if (isValidASIN(part)) {
          asin = part.toUpperCase();

          url.pathname = originalParts.slice(0, partIndex + 2).join("/");
          cleanURL = quoted ? urlString : url.toString();
        }
      } else if (
        parts[partIndex] === "gp" &&
        parts[partIndex + 1] === "product"
      ) {
        // Another form of URL: https://www.amazon.com/gp/product/B06XF2TR11
        const part = parts[partIndex + 2];
        if (isValidASIN(part)) {
          asin = part.toUpperCase();

          url.pathname = originalParts.slice(0, partIndex + 3).join("/");
          cleanURL = quoted ? urlString : url.toString();
        }
      } else if (
        parts[partIndex] === "gp" &&
        parts[partIndex + 1] === "aw" &&
        parts[partIndex + 2] === "d"
      ) {
        // Mobile form of URL: https://www.amazon.com/gp/aw/d/B06XF2TR11
        const part = parts[partIndex + 3];
        if (isValidASIN(part)) {
          asin = part.toUpperCase();

          url.pathname = originalParts.slice(0, partIndex + 4).join("/");
          cleanURL = quoted ? urlString : url.toString();
        }
      } else if (parts[partIndex] === "s") {
        // Ampd's 2-step form of URL: https://www.amazon.com/s?k=<SEARCH_TERM>&rh=p_78%3A<ASIN>
        searchTerm =
          url.searchParams.get("k") ||
          url.searchParams.get("keywords") ||
          url.searchParams.get("field-keywords") ||
          null;
        const rh = url.searchParams.get("rh");
        if (rh && rh.startsWith("p_78:")) {
          const tail = rh.slice(5);
          if (isValidASIN(tail)) {
            asin = tail.toUpperCase();

            url.pathname = originalParts.slice(0, partIndex + 1).join("/");
            cleanURL = quoted ? urlString : url.toString();
          }
        }
      } else if (shortened && parts[partIndex].length === 10) {
        // Shortened form of URL: https://www.amzn.com/B06XF2TR11

        const part = parts[partIndex];
        if (isValidASIN(part)) {
          asin = part.toUpperCase();

          // Convert to standard form.
          url.hostname = "www.amazon.com";
          url.pathname = "dp" + originalParts.slice(0, partIndex + 1).join("/");
          cleanURL = quoted ? urlString : url.toString();
        }
      }
    }
  } catch (_) {
    return {
      domain: null,
      marketplaceInfo: null,
      asin: null,
      quoted: false,
      searchTerm: null,
      pathAndQuery: null,
      detailPagePath: null,
      cleanURL: null
    };
  }

  marketplaceInfo = amazonDomains.get(domain);
  if (!marketplaceInfo) {
    return {
      domain: null,
      marketplaceInfo: null,
      asin: null,
      quoted: false,
      searchTerm: null,
      pathAndQuery: null,
      detailPagePath: null,
      cleanURL: null
    };
  }

  // Defensive copy.
  marketplaceInfo = _.clone(marketplaceInfo);

  if (asin) {
    detailPagePath = `/dp/${asin}`;
  }

  return {
    domain,
    marketplaceInfo,
    asin,
    quoted,
    searchTerm,
    pathAndQuery,
    detailPagePath,
    cleanURL
  };
}

export function getDetailPageURLForASIN(
  marketplaceInfo: MarketplaceInfo | None,
  asin: string | None
): string | null {
  if (!isAmazonMarketplaceInfo(marketplaceInfo) || !asin) {
    return null;
  }

  return `https://${marketplaceInfo.domain}/dp/${asin}`;
}

export function getURLForCustomPathAndQuery(
  marketplaceInfo: MarketplaceInfo | None,
  pathAndQuery: string | None
): string | null {
  if (!isAmazonMarketplaceInfo(marketplaceInfo) || !pathAndQuery) {
    return null;
  }

  return `https://${marketplaceInfo.domain}${
    pathAndQuery && !pathAndQuery.startsWith("/") ? "/" : ""
  }${pathAndQuery}`;
}

export type AttributionAdvertiser = {
  advertiserName: string;
  advertiserId: number;
  advertiserIdStr: string;
  profileName: string;
  profileId: number;
  profileIdStr: string;
  profileAccountType: Amazon.AdvertisingProfile.AccountType.Option;
  accountId: string;
  marketplace: Amazon.Marketplace.Option;
  isDeprecated: boolean;
};

/**
 * @type {AttributionAdvertiser}
 */
export const emptyAttributionAdvertiser = {
  advertiserName: "",
  advertiserId: 0,
  advertiserIdStr: "",
  profileName: "Continue without attribution",
  profileId: 0,
  profileIdStr: "N/A",
  profileAccountType: 0,
  accountId: "",
  marketplace: Amazon.Marketplace.Option.UNKNOWN,
  isDeprecated: false
};

/**
 * An AttributionAdvertiser is required for setting up a campaign because we use
 * the AttributionAdvertiser's marketplace to lookup the product's details in
 * Rainforest. If the customer doesn't have an AttributionAdvertiser, we have
 * these dummy ones to allow them to continue without attribution. Right now
 * the dummy profiles are only available for Ampd Operators.
 * @type {Array.<AttributionAdvertiser>}
 */
export const dummyAttributionAdvertisers = [
  {
    ...emptyAttributionAdvertiser,
    marketplace: Amazon.Marketplace.Option.UNITED_STATES
  },
  {
    ...emptyAttributionAdvertiser,
    marketplace: Amazon.Marketplace.Option.MEXICO
  },
  {
    ...emptyAttributionAdvertiser,
    marketplace: Amazon.Marketplace.Option.CANADA
  },
  {
    ...emptyAttributionAdvertiser,
    marketplace: Amazon.Marketplace.Option.UNITED_KINGDOM
  }
];

/**
 * Returns a flattened list of advertisers pulled from each advertising profile, filtered
 * by the specified marketplace (if provided).  If includeDummyAdvertiser is true, then
 * additional dummy AttributionAdvertiser object are returned for a small set of maretplaces.
 * These dummy advertisers specify only marketplace and not any attribution target.
 */
export function getRelevantAttributionAdvertisers(
  amazonAdvertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>,
  marketplace: Amazon.Marketplace.Option | None,
  includeDummyAdvertisers: boolean
): Array<AttributionAdvertiser> {
  const advertisers = (amazonAdvertisingProfiles || []).flatMap(profile => {
    if (
      !profile.advertisersList ||
      (marketplace && marketplace !== profile.marketplace)
    ) {
      return [];
    }

    const subtype = _.get(profile, "subtype", "");
    return profile.advertisersList.map(advertiser => {
      return {
        advertiserName: advertiser.name,
        advertiserId: advertiser.advertiserId,
        advertiserIdStr: advertiser.advertiserIdStr,
        profileName: profile.name,
        profileId: profile.profileId,
        profileIdStr: profile.profileIdStr,
        profileAccountType: profile.accountType,
        accountId: profile.accountId,
        marketplace: profile.marketplace,
        isDeprecated: subtype === "AMAZON_ATTRIBUTION"
      };
    });
  });

  if (includeDummyAdvertisers) {
    dummyAttributionAdvertisers.forEach(dummyAdvertiser => {
      if (!marketplace || marketplace === dummyAdvertiser.marketplace) {
        advertisers.push(dummyAdvertiser);
      }
    });
  }

  return advertisers;
}

export function findAttributionAdvertiser(
  attributionAdvertisers: Array<AttributionAdvertiser> | None,
  attributionAdvertiserId: string
): AttributionAdvertiser | null {
  if (!attributionAdvertisers || !attributionAdvertiserId) {
    return null;
  }

  for (const advertiser of attributionAdvertisers) {
    if (advertiser.advertiserIdStr === attributionAdvertiserId) {
      return advertiser;
    }
  }

  return null;
}

export function stripURLSearchParams(productURL: string | null): string | null {
  if (!productURL) {
    return productURL;
  }

  return productURL.split("?")[0];
}

export function getCustomAttributionTargetURL(
  productURL: string | null,
  attributionCustomTags: Array<[string, string]> | null
): string | null {
  if (!productURL || _.isEmpty(attributionCustomTags)) {
    return productURL;
  }

  const urlParams = new URLSearchParams();
  (attributionCustomTags || []).forEach(([tag, value]) => {
    if (tag) {
      urlParams.set(tag, value);
    }
  });

  return `${stripURLSearchParams(productURL)}?${urlParams.toString()}`;
}

// Returns the Amazon ASIN for the requested campaign, when found, else null.
export function determineAmazonASINForCampaign(
  campaignInfo: GetCreatedGoogleAdsCampaignsReply.CampaignInfo.AsObject
): string | null {
  const { adInfo, productDetails } = findAmazonCampaignAndAdGroupInfo(
    [campaignInfo],
    String(campaignInfo.campaignId),
    ""
  );

  let createdWithASIN = null;
  if (productDetails) {
    createdWithASIN = productDetails.asin;
  }

  if (!adInfo || _.isEmpty(adInfo.finalUrlsList)) {
    return createdWithASIN;
  }

  const finalUrl = adInfo.finalUrlsList[0];
  const { asin } = extractAmazonURLInfoFromString(finalUrl);

  return asin || createdWithASIN;
}

export function findAmazonCampaignAndAdGroupInfo(
  campaignInfoList: Array<
    GetCreatedGoogleAdsCampaignsReply.CampaignInfo.AsObject
  >,
  campaignId: string,
  adGroupId: string
): {
  campaignInfo?: GetCreatedGoogleAdsCampaignsReply.CampaignInfo.AsObject;
  adGroupInfo?: GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.AsObject;
  adInfo?: GetCreatedGoogleAdsCampaignsReply.AdInfo.AsObject | undefined;
  productDetails?: ProductDetails.Amazon.AsObject;
} {
  if (!campaignInfoList || !campaignId) {
    return {};
  }

  for (
    let campaignIndex = 0;
    campaignIndex < campaignInfoList.length;
    campaignIndex++
  ) {
    const campaignInfo = campaignInfoList[campaignIndex];
    if (String(campaignInfo.campaignId) !== String(campaignId)) {
      continue;
    }

    for (
      let adGroupIndex = 0;
      adGroupIndex < campaignInfo.adGroupInfosList.length;
      adGroupIndex++
    ) {
      const adGroupInfo = campaignInfo.adGroupInfosList[adGroupIndex];
      if (adGroupId) {
        if (String(adGroupInfo.adGroupId) !== String(adGroupId)) {
          continue;
        }
      }

      if (adGroupInfo.productDetails && adGroupInfo.productDetails.amazon) {
        let adInfo;
        if (adGroupInfo && adGroupInfo.adInfosList) {
          if (adGroupInfo.adInfosList.length === 1) {
            adInfo = adGroupInfo.adInfosList[0];
          }
        }

        return {
          campaignInfo,
          adGroupInfo,
          adInfo,
          productDetails: adGroupInfo.productDetails.amazon
        };
      }
    }
  }

  return {};
}
