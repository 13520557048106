import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "@rebass/grid";
import { Image, Transition, Progress } from "semantic-ui-react";
import styled, { keyframes } from "styled-components/macro";

import { Theme } from "app.css";
import { useInterval } from "Common/hooks/useInterval";
import { getRandomNumber } from "Common/utils/getRandomNumber";

const foldCubeAngle = keyframes`
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`;

const Loader = styled.div`
  margin: 20px auto;
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  transform: rotateZ(45deg);

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
  }
  .sk-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #cccccc;
    animation: ${foldCubeAngle} 2.4s infinite linear both;
    transform-origin: 100% 100%;
  }
  .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg);
  }
  .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg);
  }
  .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg);
  }
  .sk-cube2:before {
    animation-delay: 0.3s;
  }
  .sk-cube3:before {
    animation-delay: 0.6s;
  }
  .sk-cube4:before {
    animation-delay: 0.9s;
  }
`;

const LoaderMessage = styled.span`
  position: relative;
  color: ${Theme.grey} !important;
`;

export const LoadingSpinner = props => {
  const sizes = {
    mini: "0.75rem",
    small: "1.5rem",
    medium: "2.5rem",
    large: "3.5rem"
  };
  const size = {
    height: sizes[props.size],
    width: sizes[props.size]
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        height: "100%",
        width: "100%"
      }}
    >
      {!props.loadingImages && (
        <Loader height={size.height} width={size.width}>
          <div className="sk-cube1 sk-cube" />
          <div className="sk-cube2 sk-cube" />
          <div className="sk-cube4 sk-cube" />
          <div className="sk-cube3 sk-cube" />
        </Loader>
      )}
      {props.loadingMessages ? (
        <LoaderMessage>
          <LoadingMessages
            loadingImages={props.loadingImages}
            loadingMessages={props.loadingMessages}
          />
        </LoaderMessage>
      ) : props.children ? (
        <LoaderMessage>{props.children}</LoaderMessage>
      ) : (
        ""
      )}
    </Flex>
  );
};

LoadingSpinner.defaultProps = {
  size: "medium",
  loadingMessages: null,
  loadingImages: null
};

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(["mini", "small", "medium", "large"]),
  loadingMessages: PropTypes.array,
  loadingImages: PropTypes.array
};

const LoadingMessages = ({ loadingImages, loadingMessages }) => {
  const [delay, setDelay] = useState(3000);
  const [count, setCount] = useState(0);

  useInterval(
    () => {
      setCount(count + 1);

      // Randomize the delay counter to make it feel less linear.
      setDelay(getRandomNumber(3000, 4000));
    },
    // Check if the count is less than the array length.
    // If it is, delay by 2000ms. If not, stop the interval.
    count < loadingMessages.length - 1 ? delay : null
  );

  return (
    <React.Fragment>
      {loadingImages && (
        <React.Fragment>
          <div style={{ position: "relative", width: 500, marginTop: "60%" }}>
            {loadingImages.map((src, index) => (
              <Transition key={src} unmountOnHide visible={index === count}>
                <Image
                  src={src}
                  size="large"
                  style={{
                    position: "absolute",
                    bottom: 32,
                    left: "50%",
                    transform: "translateX(-50%)"
                  }}
                />
              </Transition>
            ))}
          </div>
          <Progress
            value={count + 1}
            total={loadingMessages.length}
            progress="ratio"
            indicating
          >
            {loadingMessages[count]}
          </Progress>
        </React.Fragment>
      )}
      {!loadingImages && loadingMessages[count]}
    </React.Fragment>
  );
};

LoadingMessages.defaultProps = {
  loadingImages: null
};

LoadingMessages.propTypes = {
  loadingMessages: PropTypes.array.isRequired,
  loadingImages: PropTypes.array
};
