// source: proto/edge/grpcwebPb/grpcweb_User.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_entity_user_pb = require('../../../proto/entity/user_pb.js');
goog.object.extend(proto, proto_entity_user_pb);
goog.exportSymbol('proto.grpcwebPb.GetUISettingsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetUISettingsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateUISettingsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateUISettingsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateUISettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateUISettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateUISettingsRequest.displayName = 'proto.grpcwebPb.UpdateUISettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateUISettingsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateUISettingsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateUISettingsReply.displayName = 'proto.grpcwebPb.UpdateUISettingsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetUISettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetUISettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetUISettingsRequest.displayName = 'proto.grpcwebPb.GetUISettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetUISettingsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetUISettingsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetUISettingsReply.displayName = 'proto.grpcwebPb.GetUISettingsReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateUISettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateUISettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateUISettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateUISettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: (f = msg.getAction()) && proto_entity_user_pb.UpdateUserAction.UpdateUISettingsAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateUISettingsRequest}
 */
proto.grpcwebPb.UpdateUISettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateUISettingsRequest;
  return proto.grpcwebPb.UpdateUISettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateUISettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateUISettingsRequest}
 */
proto.grpcwebPb.UpdateUISettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_user_pb.UpdateUserAction.UpdateUISettingsAction;
      reader.readMessage(value,proto_entity_user_pb.UpdateUserAction.UpdateUISettingsAction.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateUISettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateUISettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateUISettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateUISettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_user_pb.UpdateUserAction.UpdateUISettingsAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.UpdateUserAction.UpdateUISettingsAction action = 1;
 * @return {?proto.entity.UpdateUserAction.UpdateUISettingsAction}
 */
proto.grpcwebPb.UpdateUISettingsRequest.prototype.getAction = function() {
  return /** @type{?proto.entity.UpdateUserAction.UpdateUISettingsAction} */ (
    jspb.Message.getWrapperField(this, proto_entity_user_pb.UpdateUserAction.UpdateUISettingsAction, 1));
};


/**
 * @param {?proto.entity.UpdateUserAction.UpdateUISettingsAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateUISettingsRequest} returns this
*/
proto.grpcwebPb.UpdateUISettingsRequest.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateUISettingsRequest} returns this
 */
proto.grpcwebPb.UpdateUISettingsRequest.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateUISettingsRequest.prototype.hasAction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateUISettingsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateUISettingsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateUISettingsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateUISettingsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && proto_entity_user_pb.UISettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateUISettingsReply}
 */
proto.grpcwebPb.UpdateUISettingsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateUISettingsReply;
  return proto.grpcwebPb.UpdateUISettingsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateUISettingsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateUISettingsReply}
 */
proto.grpcwebPb.UpdateUISettingsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_user_pb.UISettings;
      reader.readMessage(value,proto_entity_user_pb.UISettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateUISettingsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateUISettingsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateUISettingsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateUISettingsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_user_pb.UISettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.UISettings settings = 1;
 * @return {?proto.entity.UISettings}
 */
proto.grpcwebPb.UpdateUISettingsReply.prototype.getSettings = function() {
  return /** @type{?proto.entity.UISettings} */ (
    jspb.Message.getWrapperField(this, proto_entity_user_pb.UISettings, 1));
};


/**
 * @param {?proto.entity.UISettings|undefined} value
 * @return {!proto.grpcwebPb.UpdateUISettingsReply} returns this
*/
proto.grpcwebPb.UpdateUISettingsReply.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateUISettingsReply} returns this
 */
proto.grpcwebPb.UpdateUISettingsReply.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateUISettingsReply.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetUISettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetUISettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetUISettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetUISettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetUISettingsRequest}
 */
proto.grpcwebPb.GetUISettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetUISettingsRequest;
  return proto.grpcwebPb.GetUISettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetUISettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetUISettingsRequest}
 */
proto.grpcwebPb.GetUISettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetUISettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetUISettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetUISettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetUISettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetUISettingsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetUISettingsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetUISettingsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetUISettingsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && proto_entity_user_pb.UISettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetUISettingsReply}
 */
proto.grpcwebPb.GetUISettingsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetUISettingsReply;
  return proto.grpcwebPb.GetUISettingsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetUISettingsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetUISettingsReply}
 */
proto.grpcwebPb.GetUISettingsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_user_pb.UISettings;
      reader.readMessage(value,proto_entity_user_pb.UISettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetUISettingsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetUISettingsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetUISettingsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetUISettingsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_user_pb.UISettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.UISettings settings = 1;
 * @return {?proto.entity.UISettings}
 */
proto.grpcwebPb.GetUISettingsReply.prototype.getSettings = function() {
  return /** @type{?proto.entity.UISettings} */ (
    jspb.Message.getWrapperField(this, proto_entity_user_pb.UISettings, 1));
};


/**
 * @param {?proto.entity.UISettings|undefined} value
 * @return {!proto.grpcwebPb.GetUISettingsReply} returns this
*/
proto.grpcwebPb.GetUISettingsReply.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetUISettingsReply} returns this
 */
proto.grpcwebPb.GetUISettingsReply.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetUISettingsReply.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.grpcwebPb);
