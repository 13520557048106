import React, { useCallback, useState } from "react";
import { Icon } from "semantic-ui-react";
import SimpleTooltip from "./SimpleTooltip";

import { sendGAEvent } from "./GA";

export default function CampaignAttributeEditorLauncher(props) {
  const {
    display,
    gaCategory,
    attributeName,
    siteAlias,
    Editor,
    iconName = "pencil",
    icon
  } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  if (!display) {
    return null;
  }

  return (
    <>
      {icon ? (
        <span
          onClick={() => {
            sendGAEvent(gaCategory, `Click Edit ${attributeName}`, siteAlias);
            setModalOpen(true);
          }}
        >
          {icon}
        </span>
      ) : (
        <SimpleTooltip tooltip={`Edit ${attributeName}`}>
          <Icon
            name={iconName}
            style={{ cursor: "pointer", marginTop: -5 }}
            onClick={() => {
              sendGAEvent(gaCategory, `Click Edit ${attributeName}`, siteAlias);
              setModalOpen(true);
            }}
          />
        </SimpleTooltip>
      )}
      {modalOpen && <Editor {...props} onClose={handleModalClose} />}
    </>
  );
}
