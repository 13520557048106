import { Form, Button, Message } from "semantic-ui-react";
import { useMutation } from "react-apollo";
import React, { useState } from "react";
import { ADD_SITE_USER, DEFAULT_USER_ROLE } from "../graphql";
import validator from "validator";

export const AddSiteUserForm = ({
  siteAlias,
  onAddUserSuccess,
  focusAddUserInput = true
}) => {
  const [email, setEmail] = useState("");
  const [addUser, { loading, error }] = useMutation(ADD_SITE_USER);

  return (
    <Form error={!!error} loading={loading}>
      <Message error content={`There was a problem adding that user.`} />
      <Form.Field>
        <label htmlFor="email">Email</label>
        <input
          autoFocus={focusAddUserInput}
          name="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </Form.Field>
      <Button
        primary
        onClick={() =>
          addUser({
            variables: {
              email,
              givenName: "",
              familyName: "",
              siteRole: DEFAULT_USER_ROLE,
              siteAlias
            }
          })
            .then(() => {
              setEmail("");
              onAddUserSuccess();
            })
            .catch(e => console.error(e))
        }
        disabled={email.length === 0 || !validator.isEmail(email)}
      >
        Add User
      </Button>
    </Form>
  );
};

export default AddSiteUserForm;
