import _ from "lodash";
import { capitalCase } from "capital-case";

// Converts a value into a string, Null values are returned as empty
// strings and other non-string values are turned into JSON strings.
export function stringify(s) {
  if (_.isString(s)) {
    return s;
  }

  if (_.isNil(s)) {
    return "";
  }

  return JSON.stringify(s);
}

// Converts a value into a lower-case string, Null values are treated
// as empty strings and other non-string values are turned into JSON
// strings first.
export function toLowerCase(s) {
  return stringify(s).toLocaleLowerCase();
}

// Converts a string to a capital case string. Null values are treated
// as empty strings and other non-string values are turned into JSON
// strings first.
//
// Supports various options to tweak the behavior:
// - skipIfHasCapitalLetter: For example, iPhone will not be modified.
// - stripRegexp: RegExp used to remove extraneous characters
//   (see https://github.com/blakeembrey/change-case#options).
export function toCapitalCase(s, options) {
  let { skipIfHasCapitalLetter, stripRegexp } = options || {};

  const partToCapitalCase = part => {
    if (skipIfHasCapitalLetter) {
      if (part !== part.toLocaleLowerCase()) {
        return part;
      }
    }

    return capitalCase(part, {
      stripRegexp
    });
  };

  return stringify(s)
    .split(" ")
    .map(partToCapitalCase)
    .join(" ");
}

// Returns a random alphanumeric string of the specified length.
export function randomString(length) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";

  for (let i = 0; i < length; i += 1) {
    text += possible[Math.floor(Math.random() * possible.length)];
  }
  return text;
}

function _intlCollatorSupported() {
  return !!Intl.Collator;
}

function _localeCompareSupportsLocales() {
  try {
    "foo".localeCompare("bar", "i");
  } catch (e) {
    return e.name === "RangeError";
  }
  return false;
}

const _compareCaseInsens = (() => {
  if (_intlCollatorSupported()) {
    const collator = new Intl.Collator(undefined, { sensitivity: "accent" });
    return collator.compare.bind(collator);
  }

  if (_localeCompareSupportsLocales()) {
    return (s1, s2) =>
      s1.localeCompare(s2, undefined, { sensitivity: "accent" });
  }

  return (s1, s2) =>
    s1.toLocaleLowerCase().localeCompare(s2.toLocaleLowerCase());
})();

// Returns whether two values as equal when turned into case insensitive strings.
// Null values are treated as empty strings and other non-string values are turned
// into JSON strings first.
export function isEqualCaseInsens(s1, s2) {
  s1 = stringify(s1);
  s2 = stringify(s2);

  if (s1.length !== s2.length) {
    return false;
  }

  return _compareCaseInsens(s1, s2) === 0;
}

// Compares two values as case insensitive string.  Null values are treated
// as empty strings and other non-string values are turned into JSON
// strings first.
export function compareCaseInsens(s1, s2) {
  s1 = stringify(s1);
  s2 = stringify(s2);

  return _compareCaseInsens(s1, s2);
}

export function stringifyForCsv(value, delim) {
  if (typeof value === "undefined" || value === null) {
    return "";
  }

  delim = delim || ",";

  const str = String(value);
  if (str.includes(delim) || str.includes("\n") || str.includes('"')) {
    return `"${str.replace(/"/g, '""')}"`;
  }

  return str;
}

// Returns a single string of CSV generated from the specified array of
// arrays.  Each line is separated by '\n' and each value on the line is
// separated by a comma (or 'delim' if specified).  Each value is rendered
// as a string and escaped or quoted as necessary.
export function generateCsv(headerArray, rowArrayArray, delim) {
  delim = delim || ",";

  const lines = [];

  if (headerArray) {
    lines.push(
      headerArray.map(value => stringifyForCsv(value, delim)).join(delim)
    );
  }

  if (rowArrayArray) {
    for (let index = 0; index < rowArrayArray.length; index += 1) {
      lines.push(
        rowArrayArray[index]
          .map(value => stringifyForCsv(value, delim))
          .join(delim)
      );
    }
  }

  return lines.join("\n");
}

// Parses a single string as a CSV file and returns an array of arrays.
// This function makes no assumption that the first row is a header or is
// not a header.
export function parseCsv(strData) {
  if (!strData) {
    return [];
  }

  const re = /(,|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^,\r\n]*))/gi;
  const result = [[]];
  let matches;
  while ((matches = re.exec(strData))) {
    if (matches[1].length && matches[1] !== ",") result.push([]);
    result[result.length - 1].push(
      matches[2] !== undefined ? matches[2].replace(/""/g, '"') : matches[3]
    );
  }
  return result;
}

/**
 * Returns a string of the form "<count> <singular/plural>" based on whether the
 * count is 1 or not.  If an irregular 'plural' version of the string 'singular'
 * is specified, then it is used for non-one values, otherwise "<singular>s" is used.
 *
 * @param {number} count The count.
 * @param {string} singular The singular string.
 * @param {string} [plural] The plural string (optional).
 * @return {string} The pluralized string.
 */
export function pluralize(count, singular, plural) {
  return `${count} ${
    Number(count) === 1 ? singular : plural || singular + "s"
  }`;
}

// Adds zero width spaces before delimiter strings (by default, with reasonable
// URL component markers) so wrapping text will tend to break on delimiters.
// Also replaces hyphens found in text (by default, with non-breakable hyphens
// so the text will not tend to break on them).
export function makeMultiComponentTextWrappable(
  text,
  delims,
  hyphenReplacement
) {
  if (!delims) {
    /* Default to reasonable URL delims. */
    delims = ["/", "?", "&", "#"];
  }
  if (_.isNil(hyphenReplacement)) {
    hyphenReplacement = "\u2011";
  }

  let str = String(text);
  delims.forEach(delim => {
    if (str.includes(delim)) {
      str = str.split(delim).join(`\u200b${delim}`);
    }
  });
  if (str.includes("-") && hyphenReplacement !== "-") {
    str = str.split("-").join(hyphenReplacement);
  }

  return str;
}
