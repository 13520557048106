// grpc-web
import { GRPCWebClient } from "Common/utils/grpc";
import { UpdateSiteUIBehaviorRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Site_pb";
import { SiteDetails } from "Common/proto/entity/site_pb";

export async function updateSiteUIBehavior({
  siteAlias,
  onlyAdminUsersCanEditStatuses,
  onlyAdminUsersCanEditBids,
  onlyAdminUsersCanEditBudgets,
  onlyAdminUsersCanEditDetails,
  onlyAdminUsersCanAddKeywords,
  onlyAdminUsersCanAddCampaigns
}: {
  siteAlias: string;
  onlyAdminUsersCanEditStatuses?: boolean;
  onlyAdminUsersCanEditBids?: boolean;
  onlyAdminUsersCanEditBudgets?: boolean;
  onlyAdminUsersCanEditDetails?: boolean;
  onlyAdminUsersCanAddKeywords?: boolean;
  onlyAdminUsersCanAddCampaigns?: boolean;
}): Promise<SiteDetails.UIBehavior.AsObject | undefined> {
  if (!siteAlias) {
    return undefined;
  }

  const updateRequest = new UpdateSiteUIBehaviorRequest();
  updateRequest.setSiteAlias(siteAlias);
  if (onlyAdminUsersCanEditStatuses !== undefined) {
    updateRequest.setOnlyAdminsCanEditStatuses(onlyAdminUsersCanEditStatuses);
  }
  if (onlyAdminUsersCanEditBids !== undefined) {
    updateRequest.setOnlyAdminsCanEditBids(onlyAdminUsersCanEditBids);
  }
  if (onlyAdminUsersCanEditBudgets !== undefined) {
    updateRequest.setOnlyAdminsCanEditBudgets(onlyAdminUsersCanEditBudgets);
  }
  if (onlyAdminUsersCanEditDetails !== undefined) {
    updateRequest.setOnlyAdminsCanEditDetails(onlyAdminUsersCanEditDetails);
  }
  if (onlyAdminUsersCanAddKeywords !== undefined) {
    updateRequest.setOnlyAdminsCanAddKeywords(onlyAdminUsersCanAddKeywords);
  }
  if (onlyAdminUsersCanAddCampaigns !== undefined) {
    updateRequest.setOnlyAdminsCanAddCampaigns(onlyAdminUsersCanAddCampaigns);
  }

  const response = await GRPCWebClient.updateSiteUIBehavior(
    updateRequest,
    null
  );
  return response
    ?.getSite()
    ?.getSiteDetails()
    ?.getUiBehavior()
    ?.toObject();
}
