import React from "react";

import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnCompareInfo,
  renderFormattedValue
} from "./MetricColumns";

import { CampaignNameCell, NotApplicableCell } from "./CampaignsTableRow";

import { DataTableRow, DataTableMetricCell } from "./AmpdDataTable";

const CampaignsTableTotalRow = ({
  totalRowData,
  title,
  columns,
  costCurrencyCode,
  revenueCurrencyCode,
  rowIndex,
  showFractions
}) => {
  return (
    <DataTableRow>
      {(columns || []).map(columnName => {
        switch (columnName) {
          case COLUMN_DATA_KEYS.campaignName:
            return (
              <CampaignNameCell as="th" key={columnName} rowIndex={rowIndex}>
                {title || "Total"}
              </CampaignNameCell>
            );
          default: {
            if (!(columnName in totalRowData)) {
              return (
                <NotApplicableCell
                  as="th"
                  key={columnName}
                  rowIndex={rowIndex}
                />
              );
            }

            const formattedValue = formatMetricColumnValue(
              columnName,
              totalRowData[columnName],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              columnName,
              totalRowData,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell as="th" key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue, undefined, compareInfo)}
              </DataTableMetricCell>
            );
          }
        }
      })}
    </DataTableRow>
  );
};

export default CampaignsTableTotalRow;
