import { Error, extractErrorMessage } from "../errors/error";
import Cookies from "js-cookie";
import { datadogLogHandler } from "./datadogLoggers";
import BrowserTabsLock from "browser-tabs-lock";
import { datadogLogs } from "@datadog/browser-logs";

// Define our refreshing fetcher.
const REFRESH_TOKEN_STATUS = 440;
const REFRESH_LOCK_NAME = "ms_refresh_token";
const CSRF_TOKEN_COOKIE = "ms_csrf_token";
const CSRF_HEADER = "X-CSRF-Token";

// The maximum number of attempts we will make for a particular call,
// refreshing between each attempt.
export const MAX_FETCH_ATTEMPTS = 4;

const browserTabsLock = new BrowserTabsLock();

// Datadog logger that annotates entries with the "auth" subsystem.
export const authLogger = datadogLogs.createLogger("auth", {
  handler: datadogLogHandler,
  context: { subsystem: "auth" }
});

// Returns the current CSRF token cookie.
export const getCSRFToken = () => Cookies.get(CSRF_TOKEN_COOKIE);

// Returns whether cookies can be written in the current window context.  If the
// current window is a iframe inside of a third-party app (like Shopify), then
// Safari, at least, doesn't let our iframe access cookies by default.
export const hasCookieAccess = () => {
  const testCookie = "ampd-cookie-access-test";
  let hasCookieAccess = false;

  document.cookie = `${testCookie}=written; path=/; SameSite=none; Secure`;
  if (Cookies.get(testCookie)) {
    hasCookieAccess = true;
    // Remove test cookie
    document.cookie = `${testCookie}=; path=/; SameSite=none; Secure; expires=${new Date().toUTCString()}`;
  }

  return hasCookieAccess;
};

// Runs the executeFn, refreshing if necessary.
//
// The executeFn should take a {csrfToken} and return a {response, refresh}
// pair. The response is returned if refresh is false. The executeFn may
// alternatively throw an exception to short-circuit any refresh logic.
//
// If refresh is true, this will attempt to refresh the access token before
// retrying the executeFn, as long as there are remaining fetch attempts.
//
// If the fetch attempt limit is exceeded, an error is returned.
//
// It's possible that multiple requests trigger a refresh at the same time.
// We use a locking mechanism to avoid calling /refresh multiple times
// concurrently, and then check after acquiring the lock to see whether our
// token has already been updated.
//
// If we timeout while trying to acquire the lock, we skip the /refresh and
// immediately retry, since another request likely refreshed the token.
export const executeWithRefresh = async executeFn => {
  let fetchAttempt = 1;
  for (; ; fetchAttempt++) {
    const initialCSRFToken = getCSRFToken();

    const { response, refresh } = await executeFn({
      csrfToken: initialCSRFToken
    });

    if (!refresh) {
      return response;
    }

    if (fetchAttempt >= MAX_FETCH_ATTEMPTS) {
      authLogger.log(
        `failed to fetch data in ${fetchAttempt} attempts`,
        {},
        "error"
      );

      throw new Error({ publicText: "failed to fetch data" });
    }

    authLogger.log(
      `access token expired, fetch attempt ${fetchAttempt}`,
      {},
      "info"
    );

    await refreshCSRFToken(initialCSRFToken);
  }
};

// Refreshes the CSRF token, as necessary.
//
// Call getCSRFToken() after this completes to get the current CSRF token.
export const refreshCSRFToken = async initialCSRFToken => {
  // If we can't acquire the tab lock, there's a good chance someone else
  // already refreshed the token, so retry the request.
  if (!(await browserTabsLock.acquireLock(REFRESH_LOCK_NAME, 5000))) {
    authLogger.log("could not acquire tab lock for refresh", {}, "warn");
    return;
  }

  // We've acquired the refresh token lock.
  //
  // First, check to see whether the CSRF token has changed since before our
  // initial API call. If so, someone else refreshed the token already.
  const lockedCSRFToken = Cookies.get(CSRF_TOKEN_COOKIE);
  try {
    // Check to see whether the CSRF token hash changed since we started the
    // call. If so, someone else refreshed the token already.
    if (!!lockedCSRFToken && lockedCSRFToken !== initialCSRFToken) {
      authLogger.log("token already updated; skipping refresh", {}, "info");
      return;
    }

    authLogger.log("refreshing access token", {}, "info");
    const refreshResponse = await fetch(
      process.env.REACT_APP_AUTH_REFRESH_URI,
      {
        method: "POST",
        redirect: "error",
        headers: {
          [CSRF_HEADER]: lockedCSRFToken
        }
      }
    );

    // Refresh failed; logout the user.
    if (!refreshResponse.ok) {
      authLogger.log(
        "failed to refresh access token",
        { status: refreshResponse.status },
        "warn"
      );

      await logout();
      throw new Error({ publicText: "could not refresh authentication" });
    }
  } finally {
    await browserTabsLock.releaseLock(REFRESH_LOCK_NAME);
  }
};

// Attempts a standard fetch. If this fails with a 440, calls /refresh to get a
// new access token, then retries.
export const fetchWithRefresh = async (uri, options) => {
  const executeFn = async ({ csrfToken }) => {
    if (csrfToken) {
      options.headers[CSRF_HEADER] = csrfToken;
    }

    const response = await fetch(uri, options);
    const refresh = !response.ok && response.status === REFRESH_TOKEN_STATUS;

    return { response, refresh };
  };

  return await executeWithRefresh(executeFn);
};

// Calls /auth/logout, then redirects to the redirectURL. Also removes the
// CSRF token cookie if it has not already been removed, e.g., because of a
// network error.
export const logout = async redirectURL => {
  const csrfToken = Cookies.get(CSRF_TOKEN_COOKIE);

  if (!redirectURL) {
    redirectURL = `${window.location.origin}/login`;
  }

  localStorage.removeItem("is_authenticated");
  localStorage.removeItem("site_alias");

  try {
    await fetch(process.env.REACT_APP_AUTH_LOGOUT_URI, {
      method: "POST",
      redirect: "error",
      headers: {
        [CSRF_HEADER]: csrfToken
      }
    });
  } catch (e) {
    authLogger.log(
      `failed logout with "${extractErrorMessage(e)}"`,
      {},
      "warn"
    );
  } finally {
    // The cookie should be removed by the HTTP response, but failing that,
    // attempt to delete the CSRF cookie from the client. Note that HttpOnly
    // cookies cannot be removed in this way.
    Cookies.remove(CSRF_TOKEN_COOKIE, { path: "/" });

    window.location.href = redirectURL;
  }
};

// Save the default site alias to use when the URL doesn't specify one explicitly.
export const setDefaultSiteAlias = siteAlias => {
  localStorage.setItem("site_alias", siteAlias);
};
