import _ from "lodash";
import match from "micromatch";

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  InputOnChangeData,
  Label,
  List,
  Popup
} from "semantic-ui-react";
import styled from "styled-components/macro";

import { compareCaseInsens, pluralize } from "Common/utils/strings";
import { semanticRed } from "ExtensionV2/styles/colors";
import { KEY_RETURN } from "Common/utils/events";

export const ErrorText = styled.span`
  color: ${semanticRed};
`;

const BasicOrRegexpOptions = [
  {
    key: "basic",
    text: <small>Name contains</small>,
    description: <small>ignores case; ? and * are wildcards</small>,
    value: "basic"
  },
  {
    key: "regexp",
    text: <small>Regular expression</small>,
    description: <small>matches name</small>,
    value: "regexp"
  }
];

// FilterableObjectMap is a mapping from keys to filterable objects.
// Filterable objects are objects that we can get a name from somehow.
export type FilterableObjectMap = { [key: number | string]: unknown };

type ObjectFilterProps = {
  objectMap: FilterableObjectMap;
  nameFilter: string;
  getName: (object: unknown) => string;
  title?: string;
  onUpdateNameFilter: (s: string) => void;
};

const ObjectFilterButton: React.FC<ObjectFilterProps> = ({
  objectMap,
  nameFilter,
  title,
  getName,
  onUpdateNameFilter
}) => {
  const [objectFilterOpen, setObjectFilterOpen] = useState(false);

  const [filterType, setFilterType] = useState("");
  const [editNameFilter, setEditNameFilter] = useState("");

  const handleUpdateNameFilter = (nameFilter: string) => {
    let encodedNameFilter = nameFilter.trim();

    if (encodedNameFilter) {
      if (encodedNameFilter && filterType === "regexp") {
        encodedNameFilter = "/" + encodedNameFilter;
      }
    }

    if (onUpdateNameFilter) {
      onUpdateNameFilter(encodedNameFilter);
    }
    setObjectFilterOpen(false);
  };

  const handleObjectFilterClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    _data: ButtonProps
  ) => {
    e.stopPropagation();

    if (objectFilterOpen) {
      handleUpdateNameFilter(editNameFilter);
      setObjectFilterOpen(false);
    } else {
      setFilterType(nameFilter.startsWith("/") ? "regexp" : "basic");
      setEditNameFilter(
        nameFilter.startsWith("/") ? nameFilter.slice(1) : nameFilter
      );
      setObjectFilterOpen(true);
    }
  };

  return (
    <Popup
      trigger={
        <Button onClick={handleObjectFilterClick}>
          {title}
          <Icon
            style={{ marginLeft: "0.5em", marginRight: "-0.5em" }}
            name="filter"
          />
          {!!nameFilter && (
            <span style={{ marginLeft: "1em" }}>
              {getNameFilterLabel(nameFilter)}
            </span>
          )}
        </Button>
      }
      position="bottom left"
      wide="very"
      on="click"
      open={objectFilterOpen}
    >
      <div style={{ width: "30em" }} onClick={e => e.stopPropagation()}>
        <ObjectFilter
          objectMap={objectMap}
          getName={getName}
          filterType={filterType}
          setFilterType={setFilterType}
          nameFilter={editNameFilter}
          setNameFilter={setEditNameFilter}
          onUpdateNameFilter={handleUpdateNameFilter}
        />
      </div>
    </Popup>
  );
};

export const ObjectFilter: React.FC<ObjectFilterProps & {
  setNameFilter: (s: string) => void;
  filterType: string;
  setFilterType: (s: string) => void;
}> = ({
  objectMap,
  getName,
  filterType,
  setFilterType,
  nameFilter,
  setNameFilter,
  onUpdateNameFilter
}) => {
  const [matchingExamples, setMatchingExamples] = useState<Array<string>>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (nameFilter) {
      const currentFilter = (filterType === "regexp" ? "/" : "") + nameFilter;
      const matchingObjects = filterObjectMap(
        objectMap || {},
        currentFilter,
        getName
      );

      const newMatchingNames = Object.values(matchingObjects)
        .map(object => getName(object))
        .sort(compareCaseInsens);

      setMatchingExamples(newMatchingNames);
    } else {
      setMatchingExamples([]);
    }
  }, [nameFilter, filterType, objectMap, getName]);

  const handleNameFilterChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    setNameFilter(value);
  };

  const handleNameFilterKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.keyCode === KEY_RETURN || e.which === KEY_RETURN) {
      if (onUpdateNameFilter) {
        onUpdateNameFilter(nameFilter);
      }
    }
  };

  const handleFilterTypeChange = (
    _e: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    setFilterType(String(value));
  };

  const handleFilter = () => {
    if (onUpdateNameFilter) {
      onUpdateNameFilter(nameFilter);
    }
  };

  const handleClearFilter = () => {
    if (onUpdateNameFilter) {
      onUpdateNameFilter("");
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const regexpError =
    filterType === "regexp" ? getRegexpCompileErrors(nameFilter) : null;

  return (
    <div>
      <Input
        style={{ display: "flex", alignItems: "center" }}
        value={nameFilter}
        onChange={handleNameFilterChange}
        onKeyPress={handleNameFilterKeyPress}
      >
        <Label>
          <Icon name="filter" />
          <Dropdown
            value={filterType}
            options={BasicOrRegexpOptions}
            onChange={handleFilterTypeChange}
            upward
          />
        </Label>
        <input ref={inputRef} autoFocus onFocus={handleFocus} />
      </Input>
      <div
        style={{
          marginTop: "0.5em",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button size="mini" disabled={!nameFilter} onClick={handleClearFilter}>
          Clear Filter
        </Button>
        <Button
          size="mini"
          primary
          disabled={!nameFilter}
          onClick={handleFilter}
        >
          Filter
        </Button>
      </div>
      {regexpError && (
        <p>
          Invalid regular expression: <ErrorText>{regexpError}</ErrorText>
        </p>
      )}
      {!nameFilter &&
        (filterType === "regexp" ? (
          <>
            <p />
            <p>
              Regular expression examples: "<strong>Red .* Box</strong>" &ndash;
              "<strong>B[0-9a-z]{9}$</strong>"
            </p>
          </>
        ) : (
          <>
            <p />
            <p>
              Examples: "<strong>my product</strong>" &ndash; "
              <strong>!storefront</strong>" &ndash; "<strong>disc|brand</strong>
              "
            </p>
          </>
        ))}
      {!!nameFilter &&
        (_.isEmpty(matchingExamples) ? (
          <>
            <p />
            <p>No matching names</p>
          </>
        ) : (
          <>
            <p />
            <p>{pluralize(matchingExamples.length, "matching name")}:</p>
            <div style={{ maxHeight: "40vh", overflow: "auto" }}>
              <List>
                {matchingExamples.slice(0, 200).map((name, index) => (
                  <List.Item key={index}>
                    <small>
                      <em>{name}</em>
                    </small>
                  </List.Item>
                ))}
                {matchingExamples.length > 200 && <List.Item>...</List.Item>}
              </List>
            </div>
          </>
        ))}
    </div>
  );
};

// Returns an error string if the specified regular expression pattern cannot
// be compiled, otherwise return null.
function getRegexpCompileErrors(pattern: string): string | null {
  let errorMessage = null;
  try {
    void new RegExp(pattern);
  } catch (e) {
    errorMessage = String(e);
  }
  return errorMessage;
}

// Returns a prettier string for the name filter that encloses it in quotes
// for not regexps and slashes for regexps.
function getNameFilterLabel(nameFilter: string) {
  if (!nameFilter || nameFilter === "/") {
    return "";
  }

  return nameFilter.startsWith("/") ? nameFilter + "/" : '"' + nameFilter + '"';
}

// Returns an object map that is filtered by the specified nameFilter using the
// provided getName function to get the name of the object.
export function filterObjectMap(
  objectMap: FilterableObjectMap,
  nameFilter: string,
  getName: (object: unknown) => string
): FilterableObjectMap {
  if (!objectMap || !nameFilter) {
    return objectMap;
  }

  if (nameFilter.startsWith("/")) {
    /* regexp match */

    let regexp: RegExp | null = null;

    try {
      regexp = new RegExp(nameFilter.slice(1), "i");
    } catch (e) {
      return {};
    }

    return _.pickBy(objectMap, object => {
      const name = getName(object) || "";
      if (!regexp) {
        return false;
      }
      return name.match(regexp);
    });
  } else {
    /* basic match */

    return _.pickBy(objectMap, object => {
      const name = getName(object) || "";
      return _.some(nameFilter.split("|"), isBasicMatch(name));
    });
  }
}

// Returns whether the name basic filter matches the specified name.  This
// function does not handle the OR operator, but it does handle the NOT operator.
const isBasicMatch = (name: string) => (nameFilter: string) => {
  if (!nameFilter) {
    return false;
  }

  if (nameFilter.startsWith("!")) {
    if (nameFilter === "!") {
      return false;
    }
    return !match.isMatch(name, nameFilter.slice(1), {
      nocase: true,
      contains: true
    });
  } else {
    return match.isMatch(name, nameFilter, {
      nocase: true,
      contains: true
    });
  }
};

export default ObjectFilterButton;
