// grpc-web
import _ from "lodash";
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { LinkDataSourceRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { Amazon } from "Common/proto/common/amazon_pb";
import { Facebook } from "Common/proto/common/facebook_pb";

// Connects a Site to a data source (ie: saves datasource linking info).
export default async function addSiteDataSourceLink(siteAlias, dataSource) {
  // TODO:(anyone) Add Adwords
  const { amazonSeller, amazonAdvertising, facebook } = dataSource;

  const dataSourceCount = Object.values(dataSource).filter(Boolean).length;
  if (dataSourceCount !== 1) {
    console.error(
      `addSiteDataSourceLink() can only remove exactly 1 data source link at a time, found ${JSON.stringify(
        dataSource
      )}`
    );
    return;
  }

  // Build GRPC Request for received linkData
  let req;
  switch (true) {
    case !_.isEmpty(amazonSeller): // Link Selling Partner API
      req = proto.set(new LinkDataSourceRequest(), {
        siteAlias,
        amazon_seller: proto.set(new Amazon.SellerAuthCode(), {
          profileIdStr: amazonSeller.profileIdStr,
          sellingPartnerId: amazonSeller.sellingPartnerId,
          region: amazonSeller.regionEnumOption,
          code: amazonSeller.code
        })
      });
      break;

    case !_.isEmpty(amazonAdvertising): // Link Advertising
      req = proto.set(new LinkDataSourceRequest(), {
        siteAlias,
        amazon_advertising: proto.set(new Amazon.AdvertisingAuthCode(), {
          region: amazonAdvertising.regionEnumOption,
          code: amazonAdvertising.code
        })
      });
      break;

    case !_.isEmpty(facebook): // Link Facebook
      req = proto.set(new LinkDataSourceRequest(), {
        siteAlias,
        facebook: proto.set(new Facebook.AuthCode(), {
          code: facebook.code
        })
      });
      break;
    // TODO
    // case !_.isEmpty(adWords):
    // etc

    default:
      console.error(
        `addSiteDataSourceLink() received an unknown dataSource, found ${JSON.stringify(
          dataSource
        )}`
      );
      return;
  }

  // Return the promise for handling
  const reply = await GRPCWebClient.linkDataSource(req);
  return reply.toObject();
}
