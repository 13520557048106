// source: proto/edge/grpcwebPb/grpcweb_Walmart.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_campaignPlatform_pb = require('../../../proto/common/campaignPlatform_pb.js');
goog.object.extend(proto, proto_common_campaignPlatform_pb);
var proto_common_walmart_pb = require('../../../proto/common/walmart_pb.js');
goog.object.extend(proto, proto_common_walmart_pb);
goog.exportSymbol('proto.grpcwebPb.GetSiteWalmartProfileReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSiteWalmartProfileRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetWalmartAttributionUrlsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetWalmartAttributionUrlsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetWalmartProductReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetWalmartProductRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetWalmartSearchPageReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetWalmartSearchPageRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteWalmartProfileReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteWalmartProfileRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetWalmartProductRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetWalmartProductRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetWalmartProductRequest.displayName = 'proto.grpcwebPb.GetWalmartProductRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetWalmartProductReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetWalmartProductReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetWalmartProductReply.displayName = 'proto.grpcwebPb.GetWalmartProductReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetWalmartSearchPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetWalmartSearchPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetWalmartSearchPageRequest.displayName = 'proto.grpcwebPb.GetWalmartSearchPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetWalmartSearchPageReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetWalmartSearchPageReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetWalmartSearchPageReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetWalmartSearchPageReply.displayName = 'proto.grpcwebPb.GetWalmartSearchPageReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetWalmartAttributionUrlsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetWalmartAttributionUrlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetWalmartAttributionUrlsRequest.displayName = 'proto.grpcwebPb.GetWalmartAttributionUrlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetWalmartAttributionUrlsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetWalmartAttributionUrlsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetWalmartAttributionUrlsReply.displayName = 'proto.grpcwebPb.GetWalmartAttributionUrlsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateSiteWalmartProfileRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteWalmartProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteWalmartProfileRequest.displayName = 'proto.grpcwebPb.UpdateSiteWalmartProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteWalmartProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteWalmartProfileReply.displayName = 'proto.grpcwebPb.UpdateSiteWalmartProfileReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetSiteWalmartProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSiteWalmartProfileRequest.displayName = 'proto.grpcwebPb.GetSiteWalmartProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSiteWalmartProfileReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetSiteWalmartProfileReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetSiteWalmartProfileReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSiteWalmartProfileReply.displayName = 'proto.grpcwebPb.GetSiteWalmartProfileReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetWalmartProductRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetWalmartProductRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartProductRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetWalmartProductRequest}
 */
proto.grpcwebPb.GetWalmartProductRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetWalmartProductRequest;
  return proto.grpcwebPb.GetWalmartProductRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetWalmartProductRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetWalmartProductRequest}
 */
proto.grpcwebPb.GetWalmartProductRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetWalmartProductRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetWalmartProductRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartProductRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartProductRequest} returns this
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_url = 2;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.getProductUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartProductRequest} returns this
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.setProductUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Walmart.Marketplace.Option marketplace = 3;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetWalmartProductRequest} returns this
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string item_id = 4;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartProductRequest} returns this
 */
proto.grpcwebPb.GetWalmartProductRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetWalmartProductReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetWalmartProductReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetWalmartProductReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartProductReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && proto_common_walmart_pb.Walmart.Product.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetWalmartProductReply}
 */
proto.grpcwebPb.GetWalmartProductReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetWalmartProductReply;
  return proto.grpcwebPb.GetWalmartProductReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetWalmartProductReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetWalmartProductReply}
 */
proto.grpcwebPb.GetWalmartProductReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_walmart_pb.Walmart.Product;
      reader.readMessage(value,proto_common_walmart_pb.Walmart.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetWalmartProductReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetWalmartProductReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetWalmartProductReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartProductReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_walmart_pb.Walmart.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Walmart.Product product = 1;
 * @return {?proto.common.Walmart.Product}
 */
proto.grpcwebPb.GetWalmartProductReply.prototype.getProduct = function() {
  return /** @type{?proto.common.Walmart.Product} */ (
    jspb.Message.getWrapperField(this, proto_common_walmart_pb.Walmart.Product, 1));
};


/**
 * @param {?proto.common.Walmart.Product|undefined} value
 * @return {!proto.grpcwebPb.GetWalmartProductReply} returns this
*/
proto.grpcwebPb.GetWalmartProductReply.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetWalmartProductReply} returns this
 */
proto.grpcwebPb.GetWalmartProductReply.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetWalmartProductReply.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetWalmartSearchPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetWalmartSearchPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 3, 0),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 4, ""),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetWalmartSearchPageRequest;
  return proto.grpcwebPb.GetWalmartSearchPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetWalmartSearchPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetWalmartSearchPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetWalmartSearchPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search_url = 2;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.getSearchUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.setSearchUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Walmart.Marketplace.Option marketplace = 3;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string search_term = 4;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageRequest} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetWalmartSearchPageReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetWalmartSearchPageReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetWalmartSearchPageReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartSearchPageReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto_common_walmart_pb.Walmart.SearchPageProduct.toObject, includeInstance),
    facetsList: jspb.Message.toObjectList(msg.getFacetsList(),
    proto_common_walmart_pb.Walmart.Facet.toObject, includeInstance),
    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalResults: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetWalmartSearchPageReply;
  return proto.grpcwebPb.GetWalmartSearchPageReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetWalmartSearchPageReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_walmart_pb.Walmart.SearchPageProduct;
      reader.readMessage(value,proto_common_walmart_pb.Walmart.SearchPageProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 2:
      var value = new proto_common_walmart_pb.Walmart.Facet;
      reader.readMessage(value,proto_common_walmart_pb.Walmart.Facet.deserializeBinaryFromReader);
      msg.addFacets(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetWalmartSearchPageReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetWalmartSearchPageReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartSearchPageReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_common_walmart_pb.Walmart.SearchPageProduct.serializeBinaryToWriter
    );
  }
  f = message.getFacetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_common_walmart_pb.Walmart.Facet.serializeBinaryToWriter
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalResults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * repeated common.Walmart.SearchPageProduct products = 1;
 * @return {!Array<!proto.common.Walmart.SearchPageProduct>}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.common.Walmart.SearchPageProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_walmart_pb.Walmart.SearchPageProduct, 1));
};


/**
 * @param {!Array<!proto.common.Walmart.SearchPageProduct>} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply} returns this
*/
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Walmart.SearchPageProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.SearchPageProduct}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Walmart.SearchPageProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * repeated common.Walmart.Facet facets = 2;
 * @return {!Array<!proto.common.Walmart.Facet>}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.getFacetsList = function() {
  return /** @type{!Array<!proto.common.Walmart.Facet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_walmart_pb.Walmart.Facet, 2));
};


/**
 * @param {!Array<!proto.common.Walmart.Facet>} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply} returns this
*/
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.setFacetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Walmart.Facet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.Facet}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.addFacets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Walmart.Facet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.clearFacetsList = function() {
  return this.setFacetsList([]);
};


/**
 * optional int32 total_pages = 3;
 * @return {number}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_results = 4;
 * @return {number}
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.getTotalResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetWalmartSearchPageReply} returns this
 */
proto.grpcwebPb.GetWalmartSearchPageReply.prototype.setTotalResults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetWalmartAttributionUrlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignPlatform: jspb.Message.getFieldWithDefault(msg, 2, 0),
    platformAccountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    targetUrlsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetWalmartAttributionUrlsRequest;
  return proto.grpcwebPb.GetWalmartAttributionUrlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CampaignPlatform.Option} */ (reader.readEnum());
      msg.setCampaignPlatform(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatformAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTargetUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetWalmartAttributionUrlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPlatformAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTargetUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.CampaignPlatform.Option campaign_platform = 2;
 * @return {!proto.common.CampaignPlatform.Option}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.getCampaignPlatform = function() {
  return /** @type {!proto.common.CampaignPlatform.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CampaignPlatform.Option} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.setCampaignPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string platform_account_id = 3;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.getPlatformAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.setPlatformAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string target_urls = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.getTargetUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.setTargetUrlsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.addTargetUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsRequest} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsRequest.prototype.clearTargetUrlsList = function() {
  return this.setTargetUrlsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetWalmartAttributionUrlsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirectUrlsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    parallelTrackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parallelTrackedUrlsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetWalmartAttributionUrlsReply;
  return proto.grpcwebPb.GetWalmartAttributionUrlsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRedirectUrls(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParallelTrackingUrlTemplate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addParallelTrackedUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetWalmartAttributionUrlsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirectUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getParallelTrackingUrlTemplate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParallelTrackedUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated string redirect_urls = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.getRedirectUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.setRedirectUrlsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.addRedirectUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.clearRedirectUrlsList = function() {
  return this.setRedirectUrlsList([]);
};


/**
 * optional string parallel_tracking_url_template = 2;
 * @return {string}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.getParallelTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.setParallelTrackingUrlTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string parallel_tracked_urls = 3;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.getParallelTrackedUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.setParallelTrackedUrlsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.addParallelTrackedUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetWalmartAttributionUrlsReply} returns this
 */
proto.grpcwebPb.GetWalmartAttributionUrlsReply.prototype.clearParallelTrackedUrlsList = function() {
  return this.setParallelTrackedUrlsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteWalmartProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    brandNamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteWalmartProfileRequest;
  return proto.grpcwebPb.UpdateSiteWalmartProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addBrandNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteWalmartProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProfileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBrandNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Walmart.Marketplace.Option marketplace = 2;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string profile_name = 3;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.getProfileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.setProfileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string brand_names = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.getBrandNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.setBrandNamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.addBrandNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileRequest.prototype.clearBrandNamesList = function() {
  return this.setBrandNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteWalmartProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteWalmartProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    added: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    updated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    removed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileReply}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteWalmartProfileReply;
  return proto.grpcwebPb.UpdateSiteWalmartProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteWalmartProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileReply}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdded(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdated(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteWalmartProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteWalmartProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRemoved();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool added = 1;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.getAdded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileReply} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.setAdded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool updated = 2;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.getUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileReply} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool removed = 3;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.getRemoved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteWalmartProfileReply} returns this
 */
proto.grpcwebPb.UpdateSiteWalmartProfileReply.prototype.setRemoved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSiteWalmartProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSiteWalmartProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profileName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileRequest}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSiteWalmartProfileRequest;
  return proto.grpcwebPb.GetSiteWalmartProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSiteWalmartProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileRequest}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSiteWalmartProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSiteWalmartProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProfileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Walmart.Marketplace.Option marketplace = 2;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string profile_name = 3;
 * @return {string}
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.getProfileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileRequest} returns this
 */
proto.grpcwebPb.GetSiteWalmartProfileRequest.prototype.setProfileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSiteWalmartProfileReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSiteWalmartProfileReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    proto_common_walmart_pb.Walmart.Brand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileReply}
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSiteWalmartProfileReply;
  return proto.grpcwebPb.GetSiteWalmartProfileReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSiteWalmartProfileReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileReply}
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_walmart_pb.Walmart.Brand;
      reader.readMessage(value,proto_common_walmart_pb.Walmart.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSiteWalmartProfileReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSiteWalmartProfileReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_common_walmart_pb.Walmart.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * repeated common.Walmart.Brand brands = 1;
 * @return {!Array<!proto.common.Walmart.Brand>}
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.common.Walmart.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_walmart_pb.Walmart.Brand, 1));
};


/**
 * @param {!Array<!proto.common.Walmart.Brand>} value
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileReply} returns this
*/
proto.grpcwebPb.GetSiteWalmartProfileReply.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Walmart.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.Brand}
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Walmart.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSiteWalmartProfileReply} returns this
 */
proto.grpcwebPb.GetSiteWalmartProfileReply.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};


goog.object.extend(exports, proto.grpcwebPb);
