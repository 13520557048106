import React, { useContext, useState } from "react";
import { AmpdGridRefContext } from "./AmpdWrapper";
import { Icon, Modal } from "semantic-ui-react";
import { backgroundDark } from "ExtensionV2/styles/colors";

// A modal that fits the AmpdContentArea (the full screen except
// the header and sidebar.)
export function AmpdContentAreaModal({
  children,
  closeable = true,
  onClose,
  open,
  triggerElement
}: {
  children: React.ReactNode;
  closeable?: boolean;
  onClose?: () => void;
  open?: boolean;
  triggerElement?: React.ReactElement;
}): JSX.Element {
  const mountNode = useContext(AmpdGridRefContext);

  // If the open prop is provided, the modal should be controlled
  // by the parent component. Otherwise, the modal state should be
  // controlled internally by the triggerElement.
  const [internallyControlledOpen, setInternallyControlledOpen] = useState<
    boolean
  >(false);

  const handleClickClose = () => {
    setInternallyControlledOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      closeIcon={
        closeable && (
          <Icon data-testid="close-ampd-content-area-modal" name="close" />
        )
      }
      dimmer={{
        style: {
          gridArea: "content",
          position: "relative",
          border: `10px solid ${backgroundDark}`,
          borderRadius: "14px"
        }
      }}
      onClose={handleClickClose}
      onOpen={() => setInternallyControlledOpen(true)}
      open={open ?? internallyControlledOpen}
      trigger={triggerElement}
      mountNode={mountNode}
    >
      {children}
    </Modal>
  );
}
