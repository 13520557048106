import React, { useState, useEffect } from "react";
import { Button, ButtonProps } from "semantic-ui-react";

// This button prevents firing the click handler more than once by a user with a
// twitchy finger.  Currently, once fired the button cannot be unlocked.  If a
// caller wants to unlock the button, say after a validation error that has been
// fixed by the user, then it needs to create a new instance by changing the 'key'
// prop of the OneClickButton.
const OneClickButton = React.forwardRef<Button, ButtonProps>(
  (props: ButtonProps, ref) => {
    const [clickEvent, setClickEvent] = useState<React.MouseEvent<
      HTMLButtonElement
    > | null>(null);
    const [clickData, setClickData] = useState<ButtonProps | null>(null);
    const [called, setCalled] = useState(false);

    const onClick = props.onClick;

    useEffect(() => {
      if (clickEvent && !called) {
        if (onClick && clickData) {
          onClick(clickEvent, clickData);
        }
        setCalled(true);
      }
    }, [clickEvent, clickData, called, onClick]);

    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      data: ButtonProps
    ): void => {
      if (!clickEvent) {
        event.persist();
        setClickEvent(event);
        setClickData(data);
      }
    };

    return (
      <Button
        ref={ref}
        {...props}
        loading={!props.disabled && Boolean(clickEvent)}
        disabled={props.disabled || Boolean(clickEvent)}
        onClick={handleClick}
      />
    );
  }
);

export default OneClickButton;
