import _ from "lodash";

import React, { useState } from "react";
import { Button, Icon, Message, Modal, Segment } from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";

import { sendGAEvent } from "../GA";
import {
  AMPD_ATTRIBUTION_BEHAVIOR,
  AttributionAdvertiser,
  findAttributionAdvertiser,
  getCustomAttributionTargetURL,
  getDetailPageURLForASIN,
  getURLForCustomPathAndQuery
} from "Common/utils/amazon";
import {
  isAmazonMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";
import AmazonAttributionBehaviorSelector from "../AmazonAttributionBehaviorSelector";

// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { GetAmazonAttributionTagsRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Amazon_pb";
import {
  UpdateCampaignAction,
  UpdateCampaignsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { UpdateAdTrackingAction } from "Common/proto/googleAdsPb/ad_pb";
import { extractErrorMessage } from "Common/errors/error";
import {
  AttributionBehavior,
  ItemizedCampaignConfiguration
} from "../../queries/useItemizedCampaignConfiguration";
import { useSessionSite } from "../../queries/useSessionSite";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { Amazon } from "Common/proto/common/amazon_pb";
import { WebClient } from "Common/proto/edge/grpcwebPb/GrpcwebServiceServiceClientPb";

async function determineTrackingURLTemplate(
  webClient: WebClient,
  tagsReq: GetAmazonAttributionTagsRequest | null,
  finalURL: string | null
) {
  let trackingUrlTemplate;
  if (tagsReq) {
    const attributionTags = await webClient.getAmazonAttributionTags(
      tagsReq,
      {}
    );
    if (attributionTags.getTags()) {
      let separator = "?";
      if (finalURL && finalURL.includes("?")) {
        separator = "&";
      }

      trackingUrlTemplate = `{lpurl}${separator}${attributionTags.getTags()}`;
    }
  }

  return trackingUrlTemplate;
}

const AmazonAttributionEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    customerId,
    isCampaignRemoved,
    domain,
    asin,
    marketplaceInfo
  } = itemizedCampaignConfiguration;

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let editable = true;
  if (
    !isAmazonMarketplaceInfo(marketplaceInfo) ||
    !domain ||
    !asin ||
    !customerId ||
    isCampaignRemoved
  ) {
    editable = false;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={
          editable
            ? () => {
                sendGAEvent(gaCategory, "Click Edit Attribution", siteAlias);
                setModalOpen(true);
              }
            : undefined
        }
      >
        {editable && (
          <SimpleTooltip tooltip="Edit Attribution">
            <Icon name="pencil" style={{ cursor: "pointer" }} />
          </SimpleTooltip>
        )}
      </Flex>
      {modalOpen && (
        <AmazonAttributionBehaviorModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

const AmazonAttributionBehaviorModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const { siteFeatures } = useSessionSite();
  const scrubCampaignNamesAndAddId = siteFeatures.scrubCampaignNamesWithId;

  const {
    customerId,
    campaignId,
    campaignName,
    adGroupId,
    adGroupName,
    adId,
    asin,
    attributionBehavior,
    attributionAdvertiserId,
    attributionCustomTags,
    finalURL,
    allFinalURLs,
    trackingURLTemplate,
    customTargetURLPathAndQuery,
    marketplaceInfo,
    marketplaceAdvertisers
  } = itemizedCampaignConfiguration;

  const {
    amazonInfo: { advertisingProfiles, advertisingAccounts }
  } = useSessionSite();

  const [editAttributionAdvertisers, setEditAttributionAdvertisers] = useState(
    marketplaceAdvertisers
  );
  const [
    editAttributionAdvertiserId,
    setEditAttributionAdvertiserId
  ] = useState(attributionAdvertiserId);
  const [editAttributionBehavior, setEditAttributionBehavior] = useState(
    attributionBehavior
  );
  const [editAttributionCustomTags, setEditAttributionCustomTags] = useState(
    attributionCustomTags
  );

  const [modalOpen, setModalOpen] = useState(true);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const handleUpdateAttribution = async () => {
    const attributionAdvertiser = findAttributionAdvertiser(
      editAttributionAdvertisers,
      editAttributionAdvertiserId
    );

    setIsSubmitting(true);

    try {
      const {
        finalURLs: newFinalURLs,
        trackingURLTemplate: newTrackingUrlTemplate
      } = await collectAdTrackingVariables({
        siteAlias,
        campaignId,
        campaignName,
        adGroupId,
        adGroupName,
        attributionAdvertiser,
        attributionBehavior: editAttributionBehavior,
        attributionCustomTags: editAttributionCustomTags,
        marketplaceInfo,
        customTargetURLPathAndQuery,
        previousFinalURL: finalURL,
        asin,
        stripIdsFromNames: scrubCampaignNamesAndAddId
      });

      await sendUpdateAdTracking(
        siteAlias,
        gaCategory,
        String(customerId),
        String(campaignId),
        String(adGroupId),
        String(adId),
        !_.isEqual(newFinalURLs, allFinalURLs) ? newFinalURLs : undefined,
        newTrackingUrlTemplate !== trackingURLTemplate
          ? newTrackingUrlTemplate
          : undefined
      );

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);

      console.error(e); // log to datadog

      setErrorMessage(message);

      // Since submitAttempts is used as the key of the OneClickButton, changing it
      // will create a new button instance and re-enable it.
      setSubmitAttempts(submitAttempts + 1);
      setIsSubmitting(false);
    }
  };

  const handleSomethingChanged = <T,>(callback: React.Dispatch<T>) => (
    value: T
  ) => {
    setErrorMessage("");
    setUpdateEnabled(true);
    callback(value);
  };

  const handleModalClose = () => {
    setErrorMessage("");
    setUpdateEnabled(false);
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
      size="large"
    >
      <Modal.Header>Change Amazon Attribution</Modal.Header>
      <Modal.Content scrolling>
        <Segment>
          <AmazonAttributionBehaviorSelector
            siteAlias={siteAlias}
            gaCategory={gaCategory}
            marketplaceInfo={marketplaceInfo}
            amazonAdvertisingAccounts={advertisingAccounts}
            amazonAdvertisingProfiles={advertisingProfiles}
            amazonAdvertisingProfilesLoaded={true}
            amazonAdvertisingProfilesLoading={false}
            attributionAdvertisers={editAttributionAdvertisers}
            setAttributionAdvertisers={setEditAttributionAdvertisers}
            attributionBehavior={editAttributionBehavior}
            setAttributionBehavior={handleSomethingChanged(
              setEditAttributionBehavior
            )}
            attributionAdvertiserId={editAttributionAdvertiserId}
            setAttributionAdvertiserId={handleSomethingChanged(
              setEditAttributionAdvertiserId
            )}
            attributionCustomTags={editAttributionCustomTags}
            setAttributionCustomTags={handleSomethingChanged(
              setEditAttributionCustomTags
            )}
          />
        </Segment>
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          key={submitAttempts}
          primary
          onClick={handleUpdateAttribution}
          loading={isSubmitting}
          disabled={!updateEnabled}
        >
          Update Attribution
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

export function determineFinalURL(
  attributionBehavior: AttributionBehavior,
  attributionCustomTags: Array<[string, string]>,
  marketplaceInfo: MarketplaceInfo,
  customTargetURLPathAndQuery: string,
  previousFinalURL: string,
  asin: string
): string | null {
  if (!isAmazonMarketplaceInfo(marketplaceInfo) || !asin) {
    return previousFinalURL;
  }

  const detailsURL = getDetailPageURLForASIN(marketplaceInfo, asin);
  if (attributionBehavior === AttributionBehavior.CUSTOM_ATTRIBUTION) {
    return getCustomAttributionTargetURL(detailsURL, attributionCustomTags);
  } else if (customTargetURLPathAndQuery) {
    return (
      getURLForCustomPathAndQuery(
        marketplaceInfo,
        customTargetURLPathAndQuery
      ) || detailsURL
    );
  }

  return detailsURL;
}

export async function collectAdTrackingVariables({
  siteAlias,
  campaignId,
  campaignName,
  adGroupId,
  adGroupName,
  attributionAdvertiser,
  attributionBehavior,
  attributionCustomTags,
  marketplaceInfo,
  customTargetURLPathAndQuery,
  previousFinalURL,
  asin,
  stripIdsFromNames
}: {
  siteAlias: string;
  campaignId: number;
  campaignName: string;
  adGroupId: number;
  adGroupName: string;
  attributionAdvertiser: AttributionAdvertiser | null;
  attributionBehavior: AttributionBehavior;
  attributionCustomTags: Array<[string, string]>;
  marketplaceInfo: MarketplaceInfo;
  customTargetURLPathAndQuery: string;
  previousFinalURL: string;
  asin: string;
  stripIdsFromNames: boolean;
}): Promise<{ finalURLs: Array<string>; trackingURLTemplate: string }> {
  const newFinalURLs = [];

  const finalURL = determineFinalURL(
    attributionBehavior,
    attributionCustomTags,
    marketplaceInfo,
    customTargetURLPathAndQuery,
    previousFinalURL,
    asin
  );
  if (finalURL) {
    newFinalURLs.push(finalURL);
  }

  if (
    !siteAlias ||
    !campaignName ||
    !attributionAdvertiser ||
    !isAmazonMarketplaceInfo(marketplaceInfo)
  ) {
    return { finalURLs: newFinalURLs, trackingURLTemplate: "" };
  }

  // Only create a request if the behavior is AMPD and we have an advertiser
  // specified.
  let tagsReq: GetAmazonAttributionTagsRequest | null = null;
  if (
    attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR &&
    attributionAdvertiser
  ) {
    tagsReq = new GetAmazonAttributionTagsRequest();
    tagsReq.setSiteAlias(siteAlias);
    tagsReq.setRegion(marketplaceInfo.region);
    tagsReq.setProfileId(attributionAdvertiser.profileId);
    tagsReq.setProfileIdStr(attributionAdvertiser.profileIdStr);
    tagsReq.setAdvertiserId(attributionAdvertiser.advertiserId);
    tagsReq.setAdvertiserIdStr(attributionAdvertiser.advertiserIdStr);
    tagsReq.setPublisher(Amazon.ExternalAdPublisher.Option.GOOGLE_ADS);
    tagsReq.setCampaignName(campaignName);
    tagsReq.setAdGroupName(adGroupName || campaignName);
    tagsReq.setStripIdsFromNames(stripIdsFromNames);
    if (campaignId) {
      tagsReq.setCampaignId(String(campaignId));
    }
    if (adGroupId) {
      tagsReq.setAdGroupId(String(adGroupId));
    }
  }

  const newTrackingURLTemplate =
    (await determineTrackingURLTemplate(GRPCWebClient, tagsReq, finalURL)) ||
    "";

  return {
    finalURLs: newFinalURLs,
    trackingURLTemplate: newTrackingURLTemplate
  };
}

// Updates the finalURLs and/or trackingURLTemplate for the specified ad
export async function sendUpdateAdTracking(
  siteAlias: string,
  gaCategory: string,
  customerId: string,
  campaignId: string,
  adGroupId: string,
  adId: string,
  finalURLs: Array<string> | undefined,
  trackingURLTemplate: string | undefined
): Promise<void> {
  if (!siteAlias || !customerId || !campaignId || !adGroupId || !adId) {
    return;
  }

  let label = "";

  if (finalURLs) {
    label = "[final URLs] ";
  }
  if (trackingURLTemplate !== undefined && trackingURLTemplate !== null) {
    label = label + "[tracking URL template] ";
  }

  if (!label) {
    return;
  }

  const action = proto.set(new UpdateCampaignAction(), {
    updateAdTracking: proto.set(new UpdateAdTrackingAction(), {
      campaignId: campaignId,
      adGroupId: adGroupId,
      adId: adId,
      updateFinalUrls: !!finalURLs,
      finalUrls: finalURLs ? finalURLs : [],
      updateTrackingUrlTemplate: trackingURLTemplate != undefined,
      trackingUrlTemplate: trackingURLTemplate
    })
  });

  sendGAEvent(gaCategory, "Update Campaign Ad Tracking", siteAlias, label);

  const updateReq = proto.set(new UpdateCampaignsRequest(), {
    siteAlias,
    customerId,
    actions: [action]
  });

  await GRPCWebClient.updateCampaigns(updateReq, null);
}

export default AmazonAttributionEditButton;
