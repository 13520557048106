// source: proto/edge/grpcwebPb/grpcweb_Stripe.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_common_datetime_pb = require('../../../proto/common/datetime_pb.js');
goog.object.extend(proto, proto_common_datetime_pb);
var proto_entity_billingAccount_pb = require('../../../proto/entity/billingAccount_pb.js');
goog.object.extend(proto, proto_entity_billingAccount_pb);
goog.exportSymbol('proto.grpcwebPb.ApplyStripeCouponReply', null, global);
goog.exportSymbol('proto.grpcwebPb.ApplyStripeCouponRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateCheckoutSessionReply', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateCheckoutSessionRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateStripeSubscriptionReply', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateStripeSubscriptionRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.CustomerInvoice', null, global);
goog.exportSymbol('proto.grpcwebPb.CustomerInvoice.Status', null, global);
goog.exportSymbol('proto.grpcwebPb.FinalizeStripeInvoicingReply', null, global);
goog.exportSymbol('proto.grpcwebPb.FinalizeStripeInvoicingRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCustomerInvoicesReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCustomerInvoicesRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetNextStripeInvoiceReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetNextStripeInvoiceRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.PrepareStripeInvoicingReply', null, global);
goog.exportSymbol('proto.grpcwebPb.PrepareStripeInvoicingRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.ScheduleNextSubscriptionPlanReply', null, global);
goog.exportSymbol('proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateStripeSubscriptionReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateStripeSubscriptionRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.VerifyStripeCouponReply', null, global);
goog.exportSymbol('proto.grpcwebPb.VerifyStripeCouponRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateCheckoutSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateCheckoutSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateCheckoutSessionRequest.displayName = 'proto.grpcwebPb.CreateCheckoutSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateCheckoutSessionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateCheckoutSessionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateCheckoutSessionReply.displayName = 'proto.grpcwebPb.CreateCheckoutSessionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.PrepareStripeInvoicingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PrepareStripeInvoicingRequest.displayName = 'proto.grpcwebPb.PrepareStripeInvoicingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PrepareStripeInvoicingReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.PrepareStripeInvoicingReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PrepareStripeInvoicingReply.displayName = 'proto.grpcwebPb.PrepareStripeInvoicingReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.FinalizeStripeInvoicingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.FinalizeStripeInvoicingRequest.displayName = 'proto.grpcwebPb.FinalizeStripeInvoicingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.FinalizeStripeInvoicingReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.FinalizeStripeInvoicingReply.displayName = 'proto.grpcwebPb.FinalizeStripeInvoicingReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateStripeSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateStripeSubscriptionRequest.displayName = 'proto.grpcwebPb.CreateStripeSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateStripeSubscriptionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateStripeSubscriptionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateStripeSubscriptionReply.displayName = 'proto.grpcwebPb.CreateStripeSubscriptionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.displayName = 'proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.ScheduleNextSubscriptionPlanReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.displayName = 'proto.grpcwebPb.ScheduleNextSubscriptionPlanReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetNextStripeInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetNextStripeInvoiceRequest.displayName = 'proto.grpcwebPb.GetNextStripeInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetNextStripeInvoiceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetNextStripeInvoiceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetNextStripeInvoiceReply.displayName = 'proto.grpcwebPb.GetNextStripeInvoiceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCustomerInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCustomerInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCustomerInvoicesRequest.displayName = 'proto.grpcwebPb.GetCustomerInvoicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCustomerInvoicesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCustomerInvoicesReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCustomerInvoicesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCustomerInvoicesReply.displayName = 'proto.grpcwebPb.GetCustomerInvoicesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CustomerInvoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CustomerInvoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CustomerInvoice.displayName = 'proto.grpcwebPb.CustomerInvoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.ApplyStripeCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.ApplyStripeCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.ApplyStripeCouponRequest.displayName = 'proto.grpcwebPb.ApplyStripeCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.ApplyStripeCouponReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.ApplyStripeCouponReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.ApplyStripeCouponReply.displayName = 'proto.grpcwebPb.ApplyStripeCouponReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.VerifyStripeCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.VerifyStripeCouponRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.VerifyStripeCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.VerifyStripeCouponRequest.displayName = 'proto.grpcwebPb.VerifyStripeCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.VerifyStripeCouponReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.VerifyStripeCouponReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.VerifyStripeCouponReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.VerifyStripeCouponReply.displayName = 'proto.grpcwebPb.VerifyStripeCouponReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateStripeSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateStripeSubscriptionRequest.displayName = 'proto.grpcwebPb.UpdateStripeSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateStripeSubscriptionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateStripeSubscriptionReply.displayName = 'proto.grpcwebPb.UpdateStripeSubscriptionReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateCheckoutSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateCheckoutSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateCheckoutSessionRequest}
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateCheckoutSessionRequest;
  return proto.grpcwebPb.CreateCheckoutSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateCheckoutSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateCheckoutSessionRequest}
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateCheckoutSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateCheckoutSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateCheckoutSessionRequest} returns this
 */
proto.grpcwebPb.CreateCheckoutSessionRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateCheckoutSessionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateCheckoutSessionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateCheckoutSessionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateCheckoutSessionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkoutSessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateCheckoutSessionReply}
 */
proto.grpcwebPb.CreateCheckoutSessionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateCheckoutSessionReply;
  return proto.grpcwebPb.CreateCheckoutSessionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateCheckoutSessionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateCheckoutSessionReply}
 */
proto.grpcwebPb.CreateCheckoutSessionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckoutSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateCheckoutSessionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateCheckoutSessionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateCheckoutSessionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateCheckoutSessionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckoutSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string checkout_session_id = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateCheckoutSessionReply.prototype.getCheckoutSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateCheckoutSessionReply} returns this
 */
proto.grpcwebPb.CreateCheckoutSessionReply.prototype.setCheckoutSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PrepareStripeInvoicingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PrepareStripeInvoicingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PrepareStripeInvoicingRequest}
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PrepareStripeInvoicingRequest;
  return proto.grpcwebPb.PrepareStripeInvoicingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PrepareStripeInvoicingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PrepareStripeInvoicingRequest}
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PrepareStripeInvoicingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PrepareStripeInvoicingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PrepareStripeInvoicingRequest} returns this
 */
proto.grpcwebPb.PrepareStripeInvoicingRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PrepareStripeInvoicingReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PrepareStripeInvoicingReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    setupIntentClientSecret: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PrepareStripeInvoicingReply}
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PrepareStripeInvoicingReply;
  return proto.grpcwebPb.PrepareStripeInvoicingReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PrepareStripeInvoicingReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PrepareStripeInvoicingReply}
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupIntentClientSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PrepareStripeInvoicingReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PrepareStripeInvoicingReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetupIntentClientSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string setup_intent_client_secret = 1;
 * @return {string}
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.prototype.getSetupIntentClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PrepareStripeInvoicingReply} returns this
 */
proto.grpcwebPb.PrepareStripeInvoicingReply.prototype.setSetupIntentClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.FinalizeStripeInvoicingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.FinalizeStripeInvoicingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setupIntentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.FinalizeStripeInvoicingRequest}
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.FinalizeStripeInvoicingRequest;
  return proto.grpcwebPb.FinalizeStripeInvoicingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.FinalizeStripeInvoicingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.FinalizeStripeInvoicingRequest}
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupIntentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.FinalizeStripeInvoicingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.FinalizeStripeInvoicingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetupIntentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.FinalizeStripeInvoicingRequest} returns this
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string setup_intent_id = 2;
 * @return {string}
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.prototype.getSetupIntentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.FinalizeStripeInvoicingRequest} returns this
 */
proto.grpcwebPb.FinalizeStripeInvoicingRequest.prototype.setSetupIntentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.FinalizeStripeInvoicingReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.FinalizeStripeInvoicingReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.FinalizeStripeInvoicingReply}
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.FinalizeStripeInvoicingReply;
  return proto.grpcwebPb.FinalizeStripeInvoicingReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.FinalizeStripeInvoicingReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.FinalizeStripeInvoicingReply}
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.FinalizeStripeInvoicingReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.FinalizeStripeInvoicingReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.FinalizeStripeInvoicingReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateStripeSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateStripeSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    setupFeeId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionRequest}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateStripeSubscriptionRequest;
  return proto.grpcwebPb.CreateStripeSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateStripeSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionRequest}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupFeeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateStripeSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateStripeSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSetupFeeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string plan_id = 2;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string coupon_id = 3;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.getCouponId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string setup_fee_id = 4;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.getSetupFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionRequest.prototype.setSetupFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateStripeSubscriptionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateStripeSubscriptionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSecret: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subtotal: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountDue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appliedCoupon: (f = msg.getAppliedCoupon()) && proto_entity_billingAccount_pb.Stripe.Coupon.toObject(includeInstance, f),
    planId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateStripeSubscriptionReply;
  return proto.grpcwebPb.CreateStripeSubscriptionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateStripeSubscriptionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubtotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountDue(value);
      break;
    case 4:
      var value = new proto_entity_billingAccount_pb.Stripe.Coupon;
      reader.readMessage(value,proto_entity_billingAccount_pb.Stripe.Coupon.deserializeBinaryFromReader);
      msg.setAppliedCoupon(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateStripeSubscriptionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateStripeSubscriptionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubtotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAmountDue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAppliedCoupon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_entity_billingAccount_pb.Stripe.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string client_secret = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 subtotal = 2;
 * @return {number}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.getSubtotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.setSubtotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount_due = 3;
 * @return {number}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.getAmountDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.setAmountDue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional entity.Stripe.Coupon applied_coupon = 4;
 * @return {?proto.entity.Stripe.Coupon}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.getAppliedCoupon = function() {
  return /** @type{?proto.entity.Stripe.Coupon} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.Stripe.Coupon, 4));
};


/**
 * @param {?proto.entity.Stripe.Coupon|undefined} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
*/
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.setAppliedCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.clearAppliedCoupon = function() {
  return this.setAppliedCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.hasAppliedCoupon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string plan_id = 5;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subscription_id = 6;
 * @return {string}
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.CreateStripeSubscriptionReply.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest;
  return proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest} returns this
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string plan_id = 2;
 * @return {string}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest} returns this
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest} returns this
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.ScheduleNextSubscriptionPlanReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanReply}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.ScheduleNextSubscriptionPlanReply;
  return proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.ScheduleNextSubscriptionPlanReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.ScheduleNextSubscriptionPlanReply}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.ScheduleNextSubscriptionPlanReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ScheduleNextSubscriptionPlanReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetNextStripeInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetNextStripeInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPriceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    prorationDate: (f = msg.getProrationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    couponId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    setupFeeId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetNextStripeInvoiceRequest;
  return proto.grpcwebPb.GetNextStripeInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetNextStripeInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPriceId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setProrationDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupFeeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetNextStripeInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetNextStripeInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPriceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProrationDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCouponId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSetupFeeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest} returns this
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_price_id = 2;
 * @return {string}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.getNewPriceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest} returns this
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.setNewPriceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp proration_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.getProrationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest} returns this
*/
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.setProrationDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest} returns this
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.clearProrationDate = function() {
  return this.setProrationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.hasProrationDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string coupon_id = 4;
 * @return {string}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.getCouponId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest} returns this
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string setup_fee_id = 5;
 * @return {string}
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.getSetupFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceRequest} returns this
 */
proto.grpcwebPb.GetNextStripeInvoiceRequest.prototype.setSetupFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetNextStripeInvoiceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetNextStripeInvoiceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto_entity_billingAccount_pb.Stripe.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceReply}
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetNextStripeInvoiceReply;
  return proto.grpcwebPb.GetNextStripeInvoiceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetNextStripeInvoiceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceReply}
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_billingAccount_pb.Stripe.Invoice;
      reader.readMessage(value,proto_entity_billingAccount_pb.Stripe.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetNextStripeInvoiceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetNextStripeInvoiceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_billingAccount_pb.Stripe.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.Stripe.Invoice invoice = 1;
 * @return {?proto.entity.Stripe.Invoice}
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.prototype.getInvoice = function() {
  return /** @type{?proto.entity.Stripe.Invoice} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.Stripe.Invoice, 1));
};


/**
 * @param {?proto.entity.Stripe.Invoice|undefined} value
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceReply} returns this
*/
proto.grpcwebPb.GetNextStripeInvoiceReply.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetNextStripeInvoiceReply} returns this
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetNextStripeInvoiceReply.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCustomerInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCustomerInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCustomerInvoicesRequest}
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCustomerInvoicesRequest;
  return proto.grpcwebPb.GetCustomerInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCustomerInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCustomerInvoicesRequest}
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCustomerInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCustomerInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCustomerInvoicesRequest} returns this
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCustomerInvoicesRequest} returns this
 */
proto.grpcwebPb.GetCustomerInvoicesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCustomerInvoicesReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCustomerInvoicesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCustomerInvoicesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCustomerInvoicesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCustomerInvoicesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.grpcwebPb.CustomerInvoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCustomerInvoicesReply}
 */
proto.grpcwebPb.GetCustomerInvoicesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCustomerInvoicesReply;
  return proto.grpcwebPb.GetCustomerInvoicesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCustomerInvoicesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCustomerInvoicesReply}
 */
proto.grpcwebPb.GetCustomerInvoicesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.CustomerInvoice;
      reader.readMessage(value,proto.grpcwebPb.CustomerInvoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCustomerInvoicesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCustomerInvoicesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCustomerInvoicesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCustomerInvoicesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.CustomerInvoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomerInvoice invoices = 1;
 * @return {!Array<!proto.grpcwebPb.CustomerInvoice>}
 */
proto.grpcwebPb.GetCustomerInvoicesReply.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.grpcwebPb.CustomerInvoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.CustomerInvoice, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.CustomerInvoice>} value
 * @return {!proto.grpcwebPb.GetCustomerInvoicesReply} returns this
*/
proto.grpcwebPb.GetCustomerInvoicesReply.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.CustomerInvoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CustomerInvoice}
 */
proto.grpcwebPb.GetCustomerInvoicesReply.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.CustomerInvoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCustomerInvoicesReply} returns this
 */
proto.grpcwebPb.GetCustomerInvoicesReply.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CustomerInvoice.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CustomerInvoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CustomerInvoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CustomerInvoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amountSubscription: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountDue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    urlSite: jspb.Message.getFieldWithDefault(msg, 7, ""),
    urlFile: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CustomerInvoice}
 */
proto.grpcwebPb.CustomerInvoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CustomerInvoice;
  return proto.grpcwebPb.CustomerInvoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CustomerInvoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CustomerInvoice}
 */
proto.grpcwebPb.CustomerInvoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountSubscription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountDue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {!proto.grpcwebPb.CustomerInvoice.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlSite(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlFile(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CustomerInvoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CustomerInvoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CustomerInvoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CustomerInvoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmountSubscription();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAmountDue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getUrlSite();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUrlFile();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.grpcwebPb.CustomerInvoice.Status = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  PAID: 2,
  UNPAID: 3,
  DELINQUENT: 4,
  CANCELLED: 5,
  DRAFT: 6
};

/**
 * optional string invoice_number = 1;
 * @return {string}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount_subscription = 2;
 * @return {number}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getAmountSubscription = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setAmountSubscription = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount_due = 3;
 * @return {number}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getAmountDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setAmountDue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Status status = 5;
 * @return {!proto.grpcwebPb.CustomerInvoice.Status}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getStatus = function() {
  return /** @type {!proto.grpcwebPb.CustomerInvoice.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.grpcwebPb.CustomerInvoice.Status} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional common.DateRangeV2 date_range = 6;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 6));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
*/
proto.grpcwebPb.CustomerInvoice.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CustomerInvoice.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string url_site = 7;
 * @return {string}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getUrlSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setUrlSite = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string url_file = 8;
 * @return {string}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getUrlFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setUrlFile = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.grpcwebPb.CustomerInvoice.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CustomerInvoice} returns this
 */
proto.grpcwebPb.CustomerInvoice.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.ApplyStripeCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.ApplyStripeCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ApplyStripeCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    couponId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.ApplyStripeCouponRequest}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.ApplyStripeCouponRequest;
  return proto.grpcwebPb.ApplyStripeCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.ApplyStripeCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.ApplyStripeCouponRequest}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.ApplyStripeCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.ApplyStripeCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ApplyStripeCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouponId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 3;
 * @return {string}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponRequest} returns this
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string coupon_id = 1;
 * @return {string}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.getCouponId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponRequest} returns this
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponRequest} returns this
 */
proto.grpcwebPb.ApplyStripeCouponRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.ApplyStripeCouponReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.ApplyStripeCouponReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ApplyStripeCouponReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    amountDue: jspb.Message.getFieldWithDefault(msg, 10, 0),
    appliedCoupon: (f = msg.getAppliedCoupon()) && proto_entity_billingAccount_pb.Stripe.Coupon.toObject(includeInstance, f),
    subtotal: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply}
 */
proto.grpcwebPb.ApplyStripeCouponReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.ApplyStripeCouponReply;
  return proto.grpcwebPb.ApplyStripeCouponReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.ApplyStripeCouponReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply}
 */
proto.grpcwebPb.ApplyStripeCouponReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountDue(value);
      break;
    case 11:
      var value = new proto_entity_billingAccount_pb.Stripe.Coupon;
      reader.readMessage(value,proto_entity_billingAccount_pb.Stripe.Coupon.deserializeBinaryFromReader);
      msg.setAppliedCoupon(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubtotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.ApplyStripeCouponReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.ApplyStripeCouponReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.ApplyStripeCouponReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAmountDue();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getAppliedCoupon();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_entity_billingAccount_pb.Stripe.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getSubtotal();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string client_secret = 8;
 * @return {string}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string subscription_id = 9;
 * @return {string}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 amount_due = 10;
 * @return {number}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.getAmountDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.setAmountDue = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional entity.Stripe.Coupon applied_coupon = 11;
 * @return {?proto.entity.Stripe.Coupon}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.getAppliedCoupon = function() {
  return /** @type{?proto.entity.Stripe.Coupon} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.Stripe.Coupon, 11));
};


/**
 * @param {?proto.entity.Stripe.Coupon|undefined} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
*/
proto.grpcwebPb.ApplyStripeCouponReply.prototype.setAppliedCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.clearAppliedCoupon = function() {
  return this.setAppliedCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.hasAppliedCoupon = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 subtotal = 12;
 * @return {number}
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.getSubtotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.ApplyStripeCouponReply} returns this
 */
proto.grpcwebPb.ApplyStripeCouponReply.prototype.setSubtotal = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.VerifyStripeCouponRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.VerifyStripeCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.VerifyStripeCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.VerifyStripeCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    couponId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.VerifyStripeCouponRequest;
  return proto.grpcwebPb.VerifyStripeCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.VerifyStripeCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPriceIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.VerifyStripeCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.VerifyStripeCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.VerifyStripeCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouponId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest} returns this
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string coupon_id = 2;
 * @return {string}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.getCouponId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest} returns this
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string price_ids = 3;
 * @return {!Array<string>}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.getPriceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest} returns this
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.setPriceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest} returns this
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.addPriceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest} returns this
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.clearPriceIdsList = function() {
  return this.setPriceIdsList([]);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponRequest} returns this
 */
proto.grpcwebPb.VerifyStripeCouponRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.VerifyStripeCouponReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.VerifyStripeCouponReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.VerifyStripeCouponReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.VerifyStripeCouponReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    appliesToList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    coupon: (f = msg.getCoupon()) && proto_entity_billingAccount_pb.Stripe.Coupon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply}
 */
proto.grpcwebPb.VerifyStripeCouponReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.VerifyStripeCouponReply;
  return proto.grpcwebPb.VerifyStripeCouponReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.VerifyStripeCouponReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply}
 */
proto.grpcwebPb.VerifyStripeCouponReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplicable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAppliesTo(value);
      break;
    case 3:
      var value = new proto_entity_billingAccount_pb.Stripe.Coupon;
      reader.readMessage(value,proto_entity_billingAccount_pb.Stripe.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.VerifyStripeCouponReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.VerifyStripeCouponReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.VerifyStripeCouponReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAppliesToList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entity_billingAccount_pb.Stripe.Coupon.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool applicable = 1;
 * @return {boolean}
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.getApplicable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply} returns this
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.setApplicable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated string applies_to = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.getAppliesToList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply} returns this
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.setAppliesToList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply} returns this
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.addAppliesTo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply} returns this
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.clearAppliesToList = function() {
  return this.setAppliesToList([]);
};


/**
 * optional entity.Stripe.Coupon coupon = 3;
 * @return {?proto.entity.Stripe.Coupon}
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.getCoupon = function() {
  return /** @type{?proto.entity.Stripe.Coupon} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.Stripe.Coupon, 3));
};


/**
 * @param {?proto.entity.Stripe.Coupon|undefined} value
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply} returns this
*/
proto.grpcwebPb.VerifyStripeCouponReply.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.VerifyStripeCouponReply} returns this
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.VerifyStripeCouponReply.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateStripeSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    prorationDate: (f = msg.getProrationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateStripeSubscriptionRequest;
  return proto.grpcwebPb.UpdateStripeSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setProrationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateStripeSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProrationDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string plan_id = 3;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp proration_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.getProrationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} returns this
*/
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.setProrationDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionRequest} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.clearProrationDate = function() {
  return this.setProrationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateStripeSubscriptionRequest.prototype.hasProrationDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateStripeSubscriptionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateStripeSubscriptionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionReply}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateStripeSubscriptionReply;
  return proto.grpcwebPb.UpdateStripeSubscriptionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateStripeSubscriptionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionReply}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateStripeSubscriptionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateStripeSubscriptionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string plan_id = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_secret = 3;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payment_method_id = 4;
 * @return {string}
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateStripeSubscriptionReply} returns this
 */
proto.grpcwebPb.UpdateStripeSubscriptionReply.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.grpcwebPb);
