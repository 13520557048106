// source: proto/entity/action.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_resources_ad_pb = require('../../google/ads/googleads/v16/resources/ad_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_pb);
var google_ads_googleads_v16_resources_ad_group_criterion_pb = require('../../google/ads/googleads/v16/resources/ad_group_criterion_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_criterion_pb);
var google_ads_googleads_v16_enums_ad_group_criterion_status_pb = require('../../google/ads/googleads/v16/enums/ad_group_criterion_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_ad_group_criterion_status_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_common_crudFields_pb = require('../../proto/common/crudFields_pb.js');
goog.object.extend(proto, proto_common_crudFields_pb);
var proto_common_objectID_pb = require('../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
var proto_common_productDetails_pb = require('../../proto/common/productDetails_pb.js');
goog.object.extend(proto, proto_common_productDetails_pb);
var proto_entity_site_pb = require('../../proto/entity/site_pb.js');
goog.object.extend(proto, proto_entity_site_pb);
goog.exportSymbol('proto.entity.Action', null, global);
goog.exportSymbol('proto.entity.ActionDetails', null, global);
goog.exportSymbol('proto.entity.ActionDetails.FailureInformation', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddKeyword', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddKeywordVariables', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus', null, global);
goog.exportSymbol('proto.entity.ActionDetails.GoogleAds.TypeCase', null, global);
goog.exportSymbol('proto.entity.ActionDetails.TypeCase', null, global);
goog.exportSymbol('proto.entity.BiddingStrategy', null, global);
goog.exportSymbol('proto.entity.BiddingStrategy.Option', null, global);
goog.exportSymbol('proto.entity.KeywordMatchType', null, global);
goog.exportSymbol('proto.entity.KeywordMatchType.Option', null, global);
goog.exportSymbol('proto.entity.NewAction', null, global);
goog.exportSymbol('proto.entity.UpdateActionAction', null, global);
goog.exportSymbol('proto.entity.UpdateActionAction.ActionCase', null, global);
goog.exportSymbol('proto.entity.UpdateActionAction.UpdateProductDetailsAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.NewAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.NewAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.NewAction.displayName = 'proto.entity.NewAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Action.displayName = 'proto.entity.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.repeatedFields_, proto.entity.ActionDetails.oneofGroups_);
};
goog.inherits(proto.entity.ActionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.displayName = 'proto.entity.ActionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.ActionDetails.GoogleAds.oneofGroups_);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.displayName = 'proto.entity.ActionDetails.GoogleAds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.displayName = 'proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.displayName = 'proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddKeyword.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddKeyword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddKeyword.displayName = 'proto.entity.ActionDetails.GoogleAds.AddKeyword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddKeywordVariables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.displayName = 'proto.entity.ActionDetails.GoogleAds.AddKeywordVariables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.displayName = 'proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.displayName = 'proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.displayName = 'proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.displayName = 'proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.displayName = 'proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.repeatedFields_, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.displayName = 'proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.displayName = 'proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.displayName = 'proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ActionDetails.FailureInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ActionDetails.FailureInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ActionDetails.FailureInformation.displayName = 'proto.entity.ActionDetails.FailureInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.KeywordMatchType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.KeywordMatchType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.KeywordMatchType.displayName = 'proto.entity.KeywordMatchType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BiddingStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BiddingStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BiddingStrategy.displayName = 'proto.entity.BiddingStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateActionAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UpdateActionAction.oneofGroups_);
};
goog.inherits(proto.entity.UpdateActionAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateActionAction.displayName = 'proto.entity.UpdateActionAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateActionAction.UpdateProductDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateActionAction.UpdateProductDetailsAction.displayName = 'proto.entity.UpdateActionAction.UpdateProductDetailsAction';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.NewAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.NewAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.NewAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteSelector: (f = msg.getSiteSelector()) && proto_entity_site_pb.SiteSelector.toObject(includeInstance, f),
    actionDetails: (f = msg.getActionDetails()) && proto.entity.ActionDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.NewAction}
 */
proto.entity.NewAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.NewAction;
  return proto.entity.NewAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.NewAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.NewAction}
 */
proto.entity.NewAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.SiteSelector;
      reader.readMessage(value,proto_entity_site_pb.SiteSelector.deserializeBinaryFromReader);
      msg.setSiteSelector(value);
      break;
    case 2:
      var value = new proto.entity.ActionDetails;
      reader.readMessage(value,proto.entity.ActionDetails.deserializeBinaryFromReader);
      msg.setActionDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.NewAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.NewAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.NewAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteSelector();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.SiteSelector.serializeBinaryToWriter
    );
  }
  f = message.getActionDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.ActionDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteSelector site_selector = 1;
 * @return {?proto.entity.SiteSelector}
 */
proto.entity.NewAction.prototype.getSiteSelector = function() {
  return /** @type{?proto.entity.SiteSelector} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.SiteSelector, 1));
};


/**
 * @param {?proto.entity.SiteSelector|undefined} value
 * @return {!proto.entity.NewAction} returns this
*/
proto.entity.NewAction.prototype.setSiteSelector = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewAction} returns this
 */
proto.entity.NewAction.prototype.clearSiteSelector = function() {
  return this.setSiteSelector(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewAction.prototype.hasSiteSelector = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActionDetails action_details = 2;
 * @return {?proto.entity.ActionDetails}
 */
proto.entity.NewAction.prototype.getActionDetails = function() {
  return /** @type{?proto.entity.ActionDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails, 2));
};


/**
 * @param {?proto.entity.ActionDetails|undefined} value
 * @return {!proto.entity.NewAction} returns this
*/
proto.entity.NewAction.prototype.setActionDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewAction} returns this
 */
proto.entity.NewAction.prototype.clearActionDetails = function() {
  return this.setActionDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewAction.prototype.hasActionDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: (f = msg.getSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    actionId: (f = msg.getActionId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    executionTime: (f = msg.getExecutionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f),
    details: (f = msg.getDetails()) && proto.entity.ActionDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Action}
 */
proto.entity.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Action;
  return proto.entity.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Action}
 */
proto.entity.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setActionId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExecutionTime(value);
      break;
    case 5:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    case 6:
      var value = new proto.entity.ActionDetails;
      reader.readMessage(value,proto.entity.ActionDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getActionId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getExecutionTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.ActionDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID site_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Action.prototype.getSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Action} returns this
*/
proto.entity.Action.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Action} returns this
 */
proto.entity.Action.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Action.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ObjectID user_id = 2;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Action.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 2));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Action} returns this
*/
proto.entity.Action.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Action} returns this
 */
proto.entity.Action.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Action.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ObjectID action_id = 3;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Action.prototype.getActionId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 3));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Action} returns this
*/
proto.entity.Action.prototype.setActionId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Action} returns this
 */
proto.entity.Action.prototype.clearActionId = function() {
  return this.setActionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Action.prototype.hasActionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp execution_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.Action.prototype.getExecutionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.Action} returns this
*/
proto.entity.Action.prototype.setExecutionTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Action} returns this
 */
proto.entity.Action.prototype.clearExecutionTime = function() {
  return this.setExecutionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Action.prototype.hasExecutionTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.CRUDFields crud_fields = 5;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.Action.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 5));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.Action} returns this
*/
proto.entity.Action.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Action} returns this
 */
proto.entity.Action.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Action.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ActionDetails details = 6;
 * @return {?proto.entity.ActionDetails}
 */
proto.entity.Action.prototype.getDetails = function() {
  return /** @type{?proto.entity.ActionDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails, 6));
};


/**
 * @param {?proto.entity.ActionDetails|undefined} value
 * @return {!proto.entity.Action} returns this
*/
proto.entity.Action.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Action} returns this
 */
proto.entity.Action.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Action.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.repeatedFields_ = [3];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.ActionDetails.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.entity.ActionDetails.TypeCase = {
  TYPE_NOT_SET: 0,
  GOOGLE_ADS: 2
};

/**
 * @return {proto.entity.ActionDetails.TypeCase}
 */
proto.entity.ActionDetails.prototype.getTypeCase = function() {
  return /** @type {proto.entity.ActionDetails.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.ActionDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetsMap: (f = msg.getTargetsMap()) ? f.toObject(includeInstance, undefined) : [],
    googleAds: (f = msg.getGoogleAds()) && proto.entity.ActionDetails.GoogleAds.toObject(includeInstance, f),
    failuresList: jspb.Message.toObjectList(msg.getFailuresList(),
    proto.entity.ActionDetails.FailureInformation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails}
 */
proto.entity.ActionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails;
  return proto.entity.ActionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails}
 */
proto.entity.ActionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getTargetsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = new proto.entity.ActionDetails.GoogleAds;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    case 3:
      var value = new proto.entity.ActionDetails.FailureInformation;
      reader.readMessage(value,proto.entity.ActionDetails.FailureInformation.deserializeBinaryFromReader);
      msg.addFailures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.ActionDetails.GoogleAds.serializeBinaryToWriter
    );
  }
  f = message.getFailuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.entity.ActionDetails.FailureInformation.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.oneofGroups_ = [[2,3,4,6,5,7]];

/**
 * @enum {number}
 */
proto.entity.ActionDetails.GoogleAds.TypeCase = {
  TYPE_NOT_SET: 0,
  SET_CRITERIA_CPC_BID: 2,
  ADD_KEYWORD: 3,
  ADD_DYNAMIC_SEARCH_CAMPAIGN_AND_AD_GROUP: 4,
  ADD_STANDARD_SEARCH_CAMPAIGN: 6,
  ADD_NEGATIVE_KEYWORD: 5,
  SET_CRITERIA_STATUS: 7
};

/**
 * @return {proto.entity.ActionDetails.GoogleAds.TypeCase}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getTypeCase = function() {
  return /** @type {proto.entity.ActionDetails.GoogleAds.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setCriteriaCpcBid: (f = msg.getSetCriteriaCpcBid()) && proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.toObject(includeInstance, f),
    addKeyword: (f = msg.getAddKeyword()) && proto.entity.ActionDetails.GoogleAds.AddKeyword.toObject(includeInstance, f),
    addDynamicSearchCampaignAndAdGroup: (f = msg.getAddDynamicSearchCampaignAndAdGroup()) && proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.toObject(includeInstance, f),
    addStandardSearchCampaign: (f = msg.getAddStandardSearchCampaign()) && proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.toObject(includeInstance, f),
    addNegativeKeyword: (f = msg.getAddNegativeKeyword()) && proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.toObject(includeInstance, f),
    setCriteriaStatus: (f = msg.getSetCriteriaStatus()) && proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds}
 */
proto.entity.ActionDetails.GoogleAds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds;
  return proto.entity.ActionDetails.GoogleAds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds}
 */
proto.entity.ActionDetails.GoogleAds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.deserializeBinaryFromReader);
      msg.setSetCriteriaCpcBid(value);
      break;
    case 3:
      var value = new proto.entity.ActionDetails.GoogleAds.AddKeyword;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddKeyword.deserializeBinaryFromReader);
      msg.setAddKeyword(value);
      break;
    case 4:
      var value = new proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.deserializeBinaryFromReader);
      msg.setAddDynamicSearchCampaignAndAdGroup(value);
      break;
    case 6:
      var value = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.deserializeBinaryFromReader);
      msg.setAddStandardSearchCampaign(value);
      break;
    case 5:
      var value = new proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.deserializeBinaryFromReader);
      msg.setAddNegativeKeyword(value);
      break;
    case 7:
      var value = new proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.deserializeBinaryFromReader);
      msg.setSetCriteriaStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetCriteriaCpcBid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.serializeBinaryToWriter
    );
  }
  f = message.getAddKeyword();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.ActionDetails.GoogleAds.AddKeyword.serializeBinaryToWriter
    );
  }
  f = message.getAddDynamicSearchCampaignAndAdGroup();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.serializeBinaryToWriter
    );
  }
  f = message.getAddStandardSearchCampaign();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.serializeBinaryToWriter
    );
  }
  f = message.getAddNegativeKeyword();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.serializeBinaryToWriter
    );
  }
  f = message.getSetCriteriaStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.repeatedFields_ = [101];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currentCpcMicros: jspb.Message.getFieldWithDefault(msg, 4, 0),
    active: (f = msg.getActive()) && proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.toObject(includeInstance, f),
    alternativesList: jspb.Message.toObjectList(msg.getAlternativesList(),
    proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid;
  return proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentCpcMicros(value);
      break;
    case 100:
      var value = new proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.deserializeBinaryFromReader);
      msg.setActive(value);
      break;
    case 101:
      var value = new proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.deserializeBinaryFromReader);
      msg.addAlternatives(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrentCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getActive();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.serializeBinaryToWriter
    );
  }
  f = message.getAlternativesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      101,
      f,
      proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.serializeBinaryToWriter
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_group_id = 2;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string criteria_id = 3;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 current_cpc_micros = 4;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.getCurrentCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.setCurrentCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional SetCriteriaCPCBidVariables active = 100;
 * @return {?proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.getActive = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables, 100));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
*/
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.setActive = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.clearActive = function() {
  return this.setActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.hasActive = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * repeated SetCriteriaCPCBidVariables alternatives = 101;
 * @return {!Array<!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables>}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.getAlternativesList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables, 101));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
*/
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.setAlternativesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 101, value);
};


/**
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.addAlternatives = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 101, opt_value, proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid.prototype.clearAlternativesList = function() {
  return this.setAlternativesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpcBidMicros: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables;
  return proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 cpc_bid_micros = 1;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.prototype.getCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBidVariables.prototype.setCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.repeatedFields_ = [101];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddKeyword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeyword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: (f = msg.getActive()) && proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.toObject(includeInstance, f),
    alternativesList: jspb.Message.toObjectList(msg.getAlternativesList(),
    proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeyword}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddKeyword;
  return proto.entity.ActionDetails.GoogleAds.AddKeyword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeyword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeyword}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 100:
      var value = new proto.entity.ActionDetails.GoogleAds.AddKeywordVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.deserializeBinaryFromReader);
      msg.setActive(value);
      break;
    case 101:
      var value = new proto.entity.ActionDetails.GoogleAds.AddKeywordVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.deserializeBinaryFromReader);
      msg.addAlternatives(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddKeyword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeyword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.serializeBinaryToWriter
    );
  }
  f = message.getAlternativesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      101,
      f,
      proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddKeywordVariables active = 100;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddKeywordVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.getActive = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddKeywordVariables, 100));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddKeywordVariables|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeyword} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.setActive = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeyword} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.clearActive = function() {
  return this.setActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.hasActive = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * repeated AddKeywordVariables alternatives = 101;
 * @return {!Array<!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables>}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.getAlternativesList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddKeywordVariables, 101));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeyword} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.setAlternativesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 101, value);
};


/**
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.addAlternatives = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 101, opt_value, proto.entity.ActionDetails.GoogleAds.AddKeywordVariables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeyword} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeyword.prototype.clearAlternativesList = function() {
  return this.setAlternativesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cpcBidMicros: jspb.Message.getFieldWithDefault(msg, 4, 0),
    matchType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    criteriaText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddKeywordVariables;
  return proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidMicros(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.KeywordMatchType.Option} */ (reader.readEnum());
      msg.setMatchType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMatchType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCriteriaText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string campaign_id = 2;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_group_id = 3;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 cpc_bid_micros = 4;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.getCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.setCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional KeywordMatchType.Option match_type = 5;
 * @return {!proto.entity.KeywordMatchType.Option}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.getMatchType = function() {
  return /** @type {!proto.entity.KeywordMatchType.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.KeywordMatchType.Option} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.setMatchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string criteria_text = 6;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.getCriteriaText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddKeywordVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddKeywordVariables.prototype.setCriteriaText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.repeatedFields_ = [3,101];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignBudgetId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adGroupIdsList: jspb.Message.toObjectList(msg.getAdGroupIdsList(),
    proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.toObject, includeInstance),
    allowPartialFailure: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    active: (f = msg.getActive()) && proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.toObject(includeInstance, f),
    alternativesList: jspb.Message.toObjectList(msg.getAlternativesList(),
    proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign;
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignBudgetId(value);
      break;
    case 3:
      var value = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.deserializeBinaryFromReader);
      msg.addAdGroupIds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowPartialFailure(value);
      break;
    case 100:
      var value = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.deserializeBinaryFromReader);
      msg.setActive(value);
      break;
    case 101:
      var value = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.deserializeBinaryFromReader);
      msg.addAlternatives(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCampaignBudgetId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAdGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.serializeBinaryToWriter
    );
  }
  f = message.getAllowPartialFailure();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getActive();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.serializeBinaryToWriter
    );
  }
  f = message.getAlternativesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      101,
      f,
      proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    criterionIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs;
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCriterionIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAdIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getCriterionIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 ad_group_id = 1;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 ad_ids = 2;
 * @return {!Array<number>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.getAdIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.setAdIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.addAdIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.clearAdIdsList = function() {
  return this.setAdIdsList([]);
};


/**
 * repeated int64 criterion_ids = 3;
 * @return {!Array<number>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.getCriterionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.setCriterionIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.addCriterionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs.prototype.clearCriterionIdsList = function() {
  return this.setCriterionIdsList([]);
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 campaign_budget_id = 2;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.getCampaignBudgetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.setCampaignBudgetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AdGroupIDs ad_group_ids = 3;
 * @return {!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.getAdGroupIdsList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs, 3));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.setAdGroupIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.addAdGroupIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.AdGroupIDs, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.clearAdGroupIdsList = function() {
  return this.setAdGroupIdsList([]);
};


/**
 * optional bool allow_partial_failure = 4;
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.getAllowPartialFailure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.setAllowPartialFailure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional AddStandardSearchCampaignVariables active = 100;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.getActive = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables, 100));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.setActive = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.clearActive = function() {
  return this.setActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.hasActive = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * repeated AddStandardSearchCampaignVariables alternatives = 101;
 * @return {!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.getAlternativesList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables, 101));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.setAlternativesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 101, value);
};


/**
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.addAlternatives = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 101, opt_value, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign.prototype.clearAlternativesList = function() {
  return this.setAlternativesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.toObject = function(includeInstance, msg) {
  var f, obj = {
    newCampaignName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignBudgetMicros: jspb.Message.getFieldWithDefault(msg, 2, 0),
    biddingStrategy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxCpcMicros: jspb.Message.getFieldWithDefault(msg, 6, 0),
    geotargetsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    adGroupsList: jspb.Message.toObjectList(msg.getAdGroupsList(),
    proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables;
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewCampaignName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignBudgetMicros(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.BiddingStrategy.Option} */ (reader.readEnum());
      msg.setBiddingStrategy(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxCpcMicros(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGeotargets(values[i]);
      }
      break;
    case 5:
      var value = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.deserializeBinaryFromReader);
      msg.addAdGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewCampaignName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignBudgetMicros();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBiddingStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMaxCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getGeotargetsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getAdGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAdGroupName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    google_ads_googleads_v16_resources_ad_pb.Ad.toObject, includeInstance),
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.toObject, includeInstance),
    productDetails: (f = msg.getProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup;
  return proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewAdGroupName(value);
      break;
    case 2:
      var value = new google_ads_googleads_v16_resources_ad_pb.Ad;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_pb.Ad.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    case 3:
      var value = new google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 4:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setProductDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAdGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_ads_googleads_v16_resources_ad_pb.Ad.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.serializeBinaryToWriter
    );
  }
  f = message.getProductDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string new_ad_group_name = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.getNewAdGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.setNewAdGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated google.ads.googleads.v16.resources.Ad ads = 2;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.Ad>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.Ad>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_resources_ad_pb.Ad, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.Ad>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.Ad=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Ad}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v16.resources.Ad, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};


/**
 * repeated google.ads.googleads.v16.resources.AdGroupCriterion criteria = 3;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.AdGroupCriterion>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.AdGroupCriterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.AdGroupCriterion>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.AdGroupCriterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.AdGroupCriterion}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v16.resources.AdGroupCriterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional common.ProductDetails product_details = 4;
 * @return {?proto.common.ProductDetails}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.getProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 4));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.setProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.clearProductDetails = function() {
  return this.setProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup.prototype.hasProductDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string new_campaign_name = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.getNewCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.setNewCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 campaign_budget_micros = 2;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.getCampaignBudgetMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.setCampaignBudgetMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BiddingStrategy.Option bidding_strategy = 3;
 * @return {!proto.entity.BiddingStrategy.Option}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.getBiddingStrategy = function() {
  return /** @type {!proto.entity.BiddingStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.BiddingStrategy.Option} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.setBiddingStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 max_cpc_micros = 6;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.getMaxCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.setMaxCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated int64 geotargets = 4;
 * @return {!Array<number>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.getGeotargetsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.setGeotargetsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.addGeotargets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.clearGeotargetsList = function() {
  return this.setGeotargetsList([]);
};


/**
 * repeated AdGroup ad_groups = 5;
 * @return {!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup>}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.getAdGroupsList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup, 5));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.setAdGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup}
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.addAdGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.AdGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaignVariables.prototype.clearAdGroupsList = function() {
  return this.setAdGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.repeatedFields_ = [101];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetPartialUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaignBudgetId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    active: (f = msg.getActive()) && proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.toObject(includeInstance, f),
    alternativesList: jspb.Message.toObjectList(msg.getAlternativesList(),
    proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup;
  return proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetPartialUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignBudgetId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 100:
      var value = new proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.deserializeBinaryFromReader);
      msg.setActive(value);
      break;
    case 101:
      var value = new proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables;
      reader.readMessage(value,proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.deserializeBinaryFromReader);
      msg.addAlternatives(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetPartialUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCampaignBudgetId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getActive();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.serializeBinaryToWriter
    );
  }
  f = message.getAlternativesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      101,
      f,
      proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.serializeBinaryToWriter
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_partial_url = 2;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getTargetPartialUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setTargetPartialUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign_id = 3;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string campaign_budget_id = 4;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getCampaignBudgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setCampaignBudgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ad_group_id = 5;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ad_id = 6;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string criteria_id = 7;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AddDynamicSearchCampaignAndAdGroupVariables active = 100;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getActive = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables, 100));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setActive = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.clearActive = function() {
  return this.setActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.hasActive = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * repeated AddDynamicSearchCampaignAndAdGroupVariables alternatives = 101;
 * @return {!Array<!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables>}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.getAlternativesList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables, 101));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
*/
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.setAlternativesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 101, value);
};


/**
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.addAlternatives = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 101, opt_value, proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup.prototype.clearAlternativesList = function() {
  return this.setAlternativesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newCampaignName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignBudgetMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    newAdGroupName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    biddingStrategy: jspb.Message.getFieldWithDefault(msg, 6, 0),
    defaultCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 7, 0),
    adDescriptionLine1: jspb.Message.getFieldWithDefault(msg, 8, ""),
    adDescriptionLine2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    geoTargetsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables;
  return proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewCampaignName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignBudgetMicros(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewAdGroupName(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.BiddingStrategy.Option} */ (reader.readEnum());
      msg.setBiddingStrategy(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultCpcBidMicros(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdDescriptionLine1(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdDescriptionLine2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addGeoTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewCampaignName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignBudgetMicros();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNewAdGroupName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBiddingStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDefaultCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAdDescriptionLine1();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdDescriptionLine2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGeoTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_campaign_name = 2;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getNewCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setNewCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 campaign_budget_micros = 3;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getCampaignBudgetMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setCampaignBudgetMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string language_code = 4;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string new_ad_group_name = 5;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getNewAdGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setNewAdGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BiddingStrategy.Option bidding_strategy = 6;
 * @return {!proto.entity.BiddingStrategy.Option}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getBiddingStrategy = function() {
  return /** @type {!proto.entity.BiddingStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.BiddingStrategy.Option} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setBiddingStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 default_cpc_bid_micros = 7;
 * @return {number}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getDefaultCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setDefaultCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string ad_description_line_1 = 8;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getAdDescriptionLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setAdDescriptionLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ad_description_line_2 = 9;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getAdDescriptionLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setAdDescriptionLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string geo_targets = 10;
 * @return {!Array<string>}
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.getGeoTargetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.setGeoTargetsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.addGeoTargets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroupVariables.prototype.clearGeoTargetsList = function() {
  return this.setGeoTargetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    criteriaText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    matchType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword;
  return proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaText(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.KeywordMatchType.Option} */ (reader.readEnum());
      msg.setMatchType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCriteriaText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMatchType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_group_id = 2;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string criteria_text = 3;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.getCriteriaText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.setCriteriaText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional KeywordMatchType.Option match_type = 4;
 * @return {!proto.entity.KeywordMatchType.Option}
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.getMatchType = function() {
  return /** @type {!proto.entity.KeywordMatchType.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.KeywordMatchType.Option} value
 * @return {!proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} returns this
 */
proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword.prototype.setMatchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    criteriaId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus;
  return proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaId(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCriteriaId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_group_id = 2;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string criteria_id = 3;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.getCriteriaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.ads.googleads.v16.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus new_status = 4;
 * @return {!proto.google.ads.googleads.v16.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus}
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.getNewStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AdGroupCriterionStatusEnum.AdGroupCriterionStatus} value
 * @return {!proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} returns this
 */
proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus.prototype.setNewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SetCriteriaCPCBid set_criteria_cpc_bid = 2;
 * @return {?proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getSetCriteriaCpcBid = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid, 2));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.SetCriteriaCPCBid|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
*/
proto.entity.ActionDetails.GoogleAds.prototype.setSetCriteriaCpcBid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.clearSetCriteriaCpcBid = function() {
  return this.setSetCriteriaCpcBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.prototype.hasSetCriteriaCpcBid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AddKeyword add_keyword = 3;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddKeyword}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getAddKeyword = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddKeyword} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddKeyword, 3));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddKeyword|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
*/
proto.entity.ActionDetails.GoogleAds.prototype.setAddKeyword = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.clearAddKeyword = function() {
  return this.setAddKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.prototype.hasAddKeyword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AddDynamicSearchCampaignAndAdGroup add_dynamic_search_campaign_and_ad_group = 4;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getAddDynamicSearchCampaignAndAdGroup = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup, 4));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddDynamicSearchCampaignAndAdGroup|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
*/
proto.entity.ActionDetails.GoogleAds.prototype.setAddDynamicSearchCampaignAndAdGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.clearAddDynamicSearchCampaignAndAdGroup = function() {
  return this.setAddDynamicSearchCampaignAndAdGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.prototype.hasAddDynamicSearchCampaignAndAdGroup = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AddStandardSearchCampaign add_standard_search_campaign = 6;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getAddStandardSearchCampaign = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign, 6));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddStandardSearchCampaign|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
*/
proto.entity.ActionDetails.GoogleAds.prototype.setAddStandardSearchCampaign = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.clearAddStandardSearchCampaign = function() {
  return this.setAddStandardSearchCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.prototype.hasAddStandardSearchCampaign = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AddNegativeKeyword add_negative_keyword = 5;
 * @return {?proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getAddNegativeKeyword = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword, 5));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.AddNegativeKeyword|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
*/
proto.entity.ActionDetails.GoogleAds.prototype.setAddNegativeKeyword = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.clearAddNegativeKeyword = function() {
  return this.setAddNegativeKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.prototype.hasAddNegativeKeyword = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SetCriteriaStatus set_criteria_status = 7;
 * @return {?proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus}
 */
proto.entity.ActionDetails.GoogleAds.prototype.getSetCriteriaStatus = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus, 7));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds.SetCriteriaStatus|undefined} value
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
*/
proto.entity.ActionDetails.GoogleAds.prototype.setSetCriteriaStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.entity.ActionDetails.GoogleAds.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.GoogleAds} returns this
 */
proto.entity.ActionDetails.GoogleAds.prototype.clearSetCriteriaStatus = function() {
  return this.setSetCriteriaStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.GoogleAds.prototype.hasSetCriteriaStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ActionDetails.FailureInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ActionDetails.FailureInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ActionDetails.FailureInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.FailureInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    failureTime: (f = msg.getFailureTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    failureMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    failingDetails: (f = msg.getFailingDetails()) && proto.entity.ActionDetails.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ActionDetails.FailureInformation}
 */
proto.entity.ActionDetails.FailureInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ActionDetails.FailureInformation;
  return proto.entity.ActionDetails.FailureInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ActionDetails.FailureInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ActionDetails.FailureInformation}
 */
proto.entity.ActionDetails.FailureInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFailureTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureMessage(value);
      break;
    case 3:
      var value = new proto.entity.ActionDetails;
      reader.readMessage(value,proto.entity.ActionDetails.deserializeBinaryFromReader);
      msg.setFailingDetails(value);
      break;
    case 4:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ActionDetails.FailureInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ActionDetails.FailureInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ActionDetails.FailureInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ActionDetails.FailureInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFailureTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFailingDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.ActionDetails.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp failure_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.ActionDetails.FailureInformation.prototype.getFailureTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
*/
proto.entity.ActionDetails.FailureInformation.prototype.setFailureTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
 */
proto.entity.ActionDetails.FailureInformation.prototype.clearFailureTime = function() {
  return this.setFailureTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.FailureInformation.prototype.hasFailureTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string failure_message = 2;
 * @return {string}
 */
proto.entity.ActionDetails.FailureInformation.prototype.getFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
 */
proto.entity.ActionDetails.FailureInformation.prototype.setFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActionDetails failing_details = 3;
 * @return {?proto.entity.ActionDetails}
 */
proto.entity.ActionDetails.FailureInformation.prototype.getFailingDetails = function() {
  return /** @type{?proto.entity.ActionDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails, 3));
};


/**
 * @param {?proto.entity.ActionDetails|undefined} value
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
*/
proto.entity.ActionDetails.FailureInformation.prototype.setFailingDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
 */
proto.entity.ActionDetails.FailureInformation.prototype.clearFailingDetails = function() {
  return this.setFailingDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.FailureInformation.prototype.hasFailingDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ObjectID user_id = 4;
 * @return {?proto.common.ObjectID}
 */
proto.entity.ActionDetails.FailureInformation.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 4));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
*/
proto.entity.ActionDetails.FailureInformation.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails.FailureInformation} returns this
 */
proto.entity.ActionDetails.FailureInformation.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.FailureInformation.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, string> targets = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.entity.ActionDetails.prototype.getTargetsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.entity.ActionDetails} returns this
 */
proto.entity.ActionDetails.prototype.clearTargetsMap = function() {
  this.getTargetsMap().clear();
  return this;};


/**
 * optional GoogleAds google_ads = 2;
 * @return {?proto.entity.ActionDetails.GoogleAds}
 */
proto.entity.ActionDetails.prototype.getGoogleAds = function() {
  return /** @type{?proto.entity.ActionDetails.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.entity.ActionDetails.GoogleAds, 2));
};


/**
 * @param {?proto.entity.ActionDetails.GoogleAds|undefined} value
 * @return {!proto.entity.ActionDetails} returns this
*/
proto.entity.ActionDetails.prototype.setGoogleAds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.ActionDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ActionDetails} returns this
 */
proto.entity.ActionDetails.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ActionDetails.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FailureInformation failures = 3;
 * @return {!Array<!proto.entity.ActionDetails.FailureInformation>}
 */
proto.entity.ActionDetails.prototype.getFailuresList = function() {
  return /** @type{!Array<!proto.entity.ActionDetails.FailureInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ActionDetails.FailureInformation, 3));
};


/**
 * @param {!Array<!proto.entity.ActionDetails.FailureInformation>} value
 * @return {!proto.entity.ActionDetails} returns this
*/
proto.entity.ActionDetails.prototype.setFailuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.ActionDetails.FailureInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ActionDetails.FailureInformation}
 */
proto.entity.ActionDetails.prototype.addFailures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.ActionDetails.FailureInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ActionDetails} returns this
 */
proto.entity.ActionDetails.prototype.clearFailuresList = function() {
  return this.setFailuresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.KeywordMatchType.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.KeywordMatchType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.KeywordMatchType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.KeywordMatchType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.KeywordMatchType}
 */
proto.entity.KeywordMatchType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.KeywordMatchType;
  return proto.entity.KeywordMatchType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.KeywordMatchType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.KeywordMatchType}
 */
proto.entity.KeywordMatchType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.KeywordMatchType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.KeywordMatchType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.KeywordMatchType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.KeywordMatchType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.KeywordMatchType.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  EXACT: 2,
  PHRASE: 3,
  BROAD: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BiddingStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BiddingStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BiddingStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BiddingStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BiddingStrategy}
 */
proto.entity.BiddingStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BiddingStrategy;
  return proto.entity.BiddingStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BiddingStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BiddingStrategy}
 */
proto.entity.BiddingStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BiddingStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BiddingStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BiddingStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BiddingStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.BiddingStrategy.Option = {
  UNSPECIFIED: 0,
  MAXIMIZE_CONVERSIONS: 1,
  MANUAL_CPC: 4,
  MANUAL_CPC_ENHANCED_BID: 2,
  MAXIMIZE_CLICKS: 3
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UpdateActionAction.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.entity.UpdateActionAction.ActionCase = {
  ACTION_NOT_SET: 0,
  UPDATE_PRODUCT_DETAILS: 2
};

/**
 * @return {proto.entity.UpdateActionAction.ActionCase}
 */
proto.entity.UpdateActionAction.prototype.getActionCase = function() {
  return /** @type {proto.entity.UpdateActionAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.entity.UpdateActionAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateActionAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateActionAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateActionAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateActionAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionId: (f = msg.getActionId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    updateProductDetails: (f = msg.getUpdateProductDetails()) && proto.entity.UpdateActionAction.UpdateProductDetailsAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateActionAction}
 */
proto.entity.UpdateActionAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateActionAction;
  return proto.entity.UpdateActionAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateActionAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateActionAction}
 */
proto.entity.UpdateActionAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setActionId(value);
      break;
    case 2:
      var value = new proto.entity.UpdateActionAction.UpdateProductDetailsAction;
      reader.readMessage(value,proto.entity.UpdateActionAction.UpdateProductDetailsAction.deserializeBinaryFromReader);
      msg.setUpdateProductDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateActionAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateActionAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateActionAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateActionAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getUpdateProductDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UpdateActionAction.UpdateProductDetailsAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateActionAction.UpdateProductDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    compareProductDetails: (f = msg.getCompareProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f),
    updateProductDetails: (f = msg.getUpdateProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateActionAction.UpdateProductDetailsAction}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateActionAction.UpdateProductDetailsAction;
  return proto.entity.UpdateActionAction.UpdateProductDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateActionAction.UpdateProductDetailsAction}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setCompareProductDetails(value);
      break;
    case 2:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setUpdateProductDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateActionAction.UpdateProductDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompareProductDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
  f = message.getUpdateProductDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ProductDetails compare_product_details = 1;
 * @return {?proto.common.ProductDetails}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.getCompareProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 1));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} returns this
*/
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.setCompareProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} returns this
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.clearCompareProductDetails = function() {
  return this.setCompareProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.hasCompareProductDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ProductDetails update_product_details = 2;
 * @return {?proto.common.ProductDetails}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.getUpdateProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 2));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} returns this
*/
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.setUpdateProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateActionAction.UpdateProductDetailsAction} returns this
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.clearUpdateProductDetails = function() {
  return this.setUpdateProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateActionAction.UpdateProductDetailsAction.prototype.hasUpdateProductDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ObjectID action_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.UpdateActionAction.prototype.getActionId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.UpdateActionAction} returns this
*/
proto.entity.UpdateActionAction.prototype.setActionId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateActionAction} returns this
 */
proto.entity.UpdateActionAction.prototype.clearActionId = function() {
  return this.setActionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateActionAction.prototype.hasActionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateProductDetailsAction update_product_details = 2;
 * @return {?proto.entity.UpdateActionAction.UpdateProductDetailsAction}
 */
proto.entity.UpdateActionAction.prototype.getUpdateProductDetails = function() {
  return /** @type{?proto.entity.UpdateActionAction.UpdateProductDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateActionAction.UpdateProductDetailsAction, 2));
};


/**
 * @param {?proto.entity.UpdateActionAction.UpdateProductDetailsAction|undefined} value
 * @return {!proto.entity.UpdateActionAction} returns this
*/
proto.entity.UpdateActionAction.prototype.setUpdateProductDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.UpdateActionAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateActionAction} returns this
 */
proto.entity.UpdateActionAction.prototype.clearUpdateProductDetails = function() {
  return this.setUpdateProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateActionAction.prototype.hasUpdateProductDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.entity);
