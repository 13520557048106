import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Divider,
  Icon,
  Loader,
  Message,
  Modal
} from "semantic-ui-react";
import styled from "styled-components";

import { AdCopyReviewSection } from "./AdCopyField";
import { AttributionReviewSection } from "./AttributionField";
import { BudgetReviewSection } from "./BudgetField";
import { CampaignNameReviewSection } from "./CampaignNameField";
import { GeotargetsReviewSection } from "./GeotargetsField";
import { GoogleKeywordsReviewSection } from "./GoogleKeywordsField";
import { AsinReviewSection } from "ExtensionV2/pages/CampaignSetupPage/AsinField";
import { TargetUrlReviewSection } from "./TargetUrlField";
import {
  CampaignSetupAction,
  CampaignSetupState,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";

import { Site } from "ExtensionV2/queries/useSession";
import { MaxCPCReviewSection } from "./MaxCPCField";
import { PausingAutomationReviewSection } from "./PausingAutomationField";
import { WalmartTargetReviewSection } from "ExtensionV2/pages/CampaignSetupPage/WalmartItemIdField";

const CampaignReviewRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.25em 0;
`;

const CampaignReviewRowTitle = styled.div`
  width: 16em;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-weight: 600;
`;

const CampaignReviewRowValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ReviewSections = styled.div``;

const ReviewCampaignsWrapper = styled.div<{ activeCampaign: boolean }>`
  flex-grow: 2;
  margin: 2em 1em;
  width: 100%;
  padding: 0em 5em;
  & ${ReviewSections} {
    ${props => props.activeCampaign && `opacity: 0.5;`}
  }
`;

export const CampaignReviewSection = ({
  dataComponent,
  dataName,
  disableSave = false,
  editComponent,
  errors,
  onEdit,
  onCancel = () => {
    return;
  },
  onSave = () => {
    return;
  }
}: {
  dataComponent: JSX.Element;
  dataName: string;
  disableSave?: boolean;
  editComponent?: JSX.Element;
  onEdit: (dataName: string | null) => void;
  errors?: Set<string>;
  onCancel?: () => void;
  onSave?: () => void;
}): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <CampaignReviewRowWrapper>
        <CampaignReviewRowTitle>{dataName}</CampaignReviewRowTitle>
        <CampaignReviewRowValue>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {dataComponent}

            {editComponent && (
              <Icon
                style={{ marginLeft: "1em" }}
                data-testid={`review-campaign-edit-button-${dataName}`}
                link
                name="pencil alternate"
                onClick={() => {
                  setIsEditing(true);
                  // Notify caller that this piece of data is actively being edited.
                  onEdit(dataName);
                }}
              />
            )}
          </div>

          {errors && errors.size > 0 && (
            <Message negative>
              {Array.from(errors).map(error => (
                <p key={error}>{error}</p>
              ))}
            </Message>
          )}
        </CampaignReviewRowValue>
      </CampaignReviewRowWrapper>

      <Modal
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          // Notify caller that the edit operation is finished.
          onEdit(null);
        }}
        onUnmount={() => {
          onCancel();
        }}
        closeIcon={<Icon name="close" />}
        closeOnDimmerClick={false}
      >
        <Modal.Content>{editComponent}</Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setIsEditing(false);
              // Notify caller that the edit operation is finished.
              onEdit(null);
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            disabled={disableSave}
            data-testid={`review-campaign-save-edit-button-${dataName}`}
            onClick={() => {
              // Notify caller that the edit operation is finished.
              onEdit(null);
              setIsEditing(false);
              onSave();
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const ReviewCampaignsStage = ({
  dispatch,
  site,
  state,
  contentAreaRef
}: {
  dispatch: React.Dispatch<CampaignSetupAction>;
  site: Site;
  state: CampaignSetupState;
  contentAreaRef: React.RefObject<HTMLDivElement>;
}): JSX.Element => {
  const { siteAlias } = site;
  const { currentReviewCampaignIndex, campaigns } = state;
  const [
    campaign,
    {
      selectAsin,
      selectWalmartItemId,
      selectWalmartSearchPage,
      useSelectTargetInfo
    }
  ] = getCurrentCampaignAndSelectors(state);

  // A reference that should be set whenever any review section is being actively edited.
  // This will be used to pause any background animation so it is not
  // distracting to the user.
  const editingFieldRef = useRef<string | null>(null);

  const asin = selectAsin(campaign);
  const walmartItemId = selectWalmartItemId(campaign);
  const walmartSearchPage = selectWalmartSearchPage(campaign);

  const [showAdvancedConfigsSection, setShowAdvancedConfigsSection] = useState(
    true
  );

  // reset the scroll position to the top when switching between campaigns
  useEffect(() => {
    contentAreaRef.current?.scrollIntoView(true);
  }, [contentAreaRef, currentReviewCampaignIndex]);

  const {
    isLoading,
    error,
    amazonProductData,
    walmartProductData
  } = useSelectTargetInfo(siteAlias, campaign, state);

  const handleViewNextCampaign = () => {
    const nextCampaignIndex = currentReviewCampaignIndex + 1;
    if (nextCampaignIndex < campaigns.length) {
      dispatch({
        name: "UpdateCurrentReviewCampaignIndex",
        data: nextCampaignIndex
      });
    }
  };

  const handleViewPreviousCampaign = () => {
    const previousCampaignIndex = currentReviewCampaignIndex - 1;
    if (previousCampaignIndex >= 0) {
      dispatch({
        name: "UpdateCurrentReviewCampaignIndex",
        data: previousCampaignIndex
      });
    }
  };

  const handleEdit = (dataName: string | null) => {
    // Keep track of whether a piece of data is being actively edited.
    // This will be used to pause any background animation so it is not
    // distracting to the user.
    editingFieldRef.current = dataName;
  };

  return (
    <>
      <ReviewCampaignsWrapper
        activeCampaign={!!campaign.campaignId}
        key={state.currentReviewCampaignIndex}
      >
        {/* Toggle between review campaigns */}
        {campaigns.length > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3em"
            }}
          >
            <Icon
              style={{
                margin: "0 0.3em 0.3em 0",
                cursor: "pointer",
                alignSelf: "center",
                visibility:
                  currentReviewCampaignIndex > 0 ? "visible" : "hidden"
              }}
              name="arrow alternate circle left outline"
              onClick={handleViewPreviousCampaign}
            />
            <h3 style={{ alignSelf: "center", margin: 0 }}>
              Campaign {currentReviewCampaignIndex + 1} of {campaigns.length}
            </h3>
            <Icon
              style={{
                margin: "0 0 0.3em 0.3em",
                cursor: "pointer",
                alignSelf: "center",
                visibility:
                  currentReviewCampaignIndex < campaigns.length - 1
                    ? "visible"
                    : "hidden"
              }}
              name="arrow alternate circle right outline"
              onClick={handleViewNextCampaign}
            />
          </div>
        )}
        {error != null && (
          <Message
            negative
            content="There was an error getting your product details from Amazon. Please try again later."
          />
        )}
        <ReviewSections>
          {/* Ad Copy */}
          {isLoading && (
            <CampaignReviewRowWrapper>
              <CampaignReviewRowTitle>Ad Preview</CampaignReviewRowTitle>
              <CampaignReviewRowValue>
                <Loader
                  active
                  inline
                  content={
                    <p style={{ fontSize: "small" }}>
                      <i>Loading Ad Copy</i>
                    </p>
                  }
                />
              </CampaignReviewRowValue>
            </CampaignReviewRowWrapper>
          )}

          {!isLoading && (
            <AdCopyReviewSection
              pauseRef={editingFieldRef}
              rating={
                amazonProductData?.product?.rating ||
                walmartProductData?.product?.rating ||
                0
              }
              ratingsTotal={
                amazonProductData?.product?.ratingsTotal ||
                walmartProductData?.product?.ratingsTotal ||
                0
              }
              error={error}
              state={state}
              site={site}
              dispatch={dispatch}
              onEdit={handleEdit}
            />
          )}
          <Divider />
          {/* Keywords */}
          <GoogleKeywordsReviewSection
            state={state}
            site={site}
            dispatch={dispatch}
            onEdit={handleEdit}
          />
          <Divider />
          {/* Targeting */}
          {!!asin && (
            <AsinReviewSection
              state={state}
              site={site}
              dispatch={dispatch}
              onEdit={handleEdit}
            />
          )}
          {(!!walmartItemId || !!walmartSearchPage) && (
            <WalmartTargetReviewSection
              state={state}
              dispatch={dispatch}
              onEdit={handleEdit}
            />
          )}
          <Divider />
          {/* Geotargets */}
          <GeotargetsReviewSection
            state={state}
            dispatch={dispatch}
            onEdit={handleEdit}
          />
          <Divider />
          {/* Budget */}
          <BudgetReviewSection
            state={state}
            site={site}
            dispatch={dispatch}
            onEdit={handleEdit}
          />
          <Divider />
          {/* Max CPC */}
          <MaxCPCReviewSection
            state={state}
            site={site}
            dispatch={dispatch}
            onEdit={handleEdit}
          />
          <Divider />
          {/* Campaign Name */}
          <CampaignNameReviewSection
            state={state}
            dispatch={dispatch}
            onEdit={handleEdit}
          />
          <Divider />
          {!!asin && (
            <>
              {/* Attribution */}
              <AttributionReviewSection
                state={state}
                dispatch={dispatch}
                onEdit={handleEdit}
              />
              <Divider />
            </>
          )}
          {/* Target URL */}
          <TargetUrlReviewSection
            state={state}
            site={site}
            dispatch={dispatch}
            onEdit={handleEdit}
          />
          <Divider />
          {/* Advanced Options */}
          <Accordion>
            <Accordion.Title
              active={showAdvancedConfigsSection || !!error}
              onClick={() => setShowAdvancedConfigsSection(open => !open)}
            >
              <Icon name="dropdown" />
              Advanced Options
            </Accordion.Title>
            <Accordion.Content active={showAdvancedConfigsSection || !!error}>
              <PausingAutomationReviewSection
                state={state}
                site={site}
                dispatch={dispatch}
                onEdit={handleEdit}
              />
            </Accordion.Content>
          </Accordion>
        </ReviewSections>
      </ReviewCampaignsWrapper>
    </>
  );
};

export default ReviewCampaignsStage;
