import React, { useRef, useState } from "react";
import { DropdownProps } from "semantic-ui-react";

import {
  getCurrencyMinimumUnit,
  getCurrencySymbol
} from "Common/utils/googleAds";
import { RefetchCampaignConfigurationsResult } from "../queries/useCampaignConfigurationsByCampaignId";
import { EditDropdown } from "./CampaignsTableRow";

import { CAMPAIGN_DASHBOARD_GA_CATEGORY } from "ExtensionV2/ExtensionV2";
import {
  BIDDING_STRATEGY_MANUAL_CPC_STR,
  BIDDING_STRATEGY_TARGET_SPEND_STR,
  useUpdateCampaignBiddingStrategy
} from "../queries/useUpdateCampaignBiddingStrategy";
import { EditIconWithHistory } from "./EditIconWithHistory";
import { useCampaignBiddingStrategyHistory } from "../queries/useChangeHistory";
import { useCanUserEditBids } from "Common/utils/siteUIBehavior";

const CampaignBiddingStrategyCellContent: React.FC<{
  disabled: boolean;
  siteAlias: string;
  campaignId: string;
  adGroupId: string;
  biddingStrategy:
    | typeof BIDDING_STRATEGY_MANUAL_CPC_STR
    | typeof BIDDING_STRATEGY_TARGET_SPEND_STR;
  cpcBid: number;
  formattedCpcBid: string | JSX.Element;
  currencyCode: string;
  googleAdsAccountId: string;
  setWillChange: (willChange: boolean) => void;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  refetchCampaignKeyword: (
    campaignId: string,
    adGroupId?: string,
    criteriaId?: string
  ) => void;
}> = ({
  disabled,
  siteAlias,
  campaignId,
  adGroupId,
  biddingStrategy,
  cpcBid,
  formattedCpcBid,
  currencyCode,
  googleAdsAccountId,
  setWillChange,
  refetchCampaignConfiguration,
  refetchCampaignKeyword
}) => {
  const canEditBids = useCanUserEditBids();

  const keepOpenRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [updateCpcBid, setUpdateCpcBid] = useState<number | null>(null);

  // Don't load history items unless the history popup is open.
  const historyItems = useCampaignBiddingStrategyHistory(
    siteAlias,
    historyOpen ? campaignId : "",
    adGroupId,
    currencyCode
  );

  const {
    mutateAsync: updateCampaignBiddingStrategy,
    isLoading
  } = useUpdateCampaignBiddingStrategy();

  const handleOpen = () => {
    if (canEditBids) {
      setDropdownOpen(true);
    }

    setErrorMessage("");
  };

  const handleClose = () => {
    if (!keepOpenRef.current) {
      setDropdownOpen(false);
    }
    keepOpenRef.current = false;
  };

  const handleUpdateCampaignBiddingStrategy = async (
    newBiddingStrategy:
      | typeof BIDDING_STRATEGY_MANUAL_CPC_STR
      | typeof BIDDING_STRATEGY_TARGET_SPEND_STR,
    newCpcBid: number
  ) => {
    if (
      !siteAlias ||
      !campaignId ||
      !adGroupId ||
      !googleAdsAccountId ||
      (newBiddingStrategy === biddingStrategy && newCpcBid === cpcBid)
    ) {
      return;
    }

    setDropdownOpen(false);
    setUpdateCpcBid(newCpcBid);

    if (setWillChange) {
      setWillChange(true);
    }

    try {
      // If the current bidding strategy is TARGET_SPEND, but the new one
      // is not, let's clear the campaign max cpc first, so a change event
      // will be generated for it.
      if (
        biddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR &&
        newBiddingStrategy !== BIDDING_STRATEGY_TARGET_SPEND_STR
      ) {
        await updateCampaignBiddingStrategy({
          siteAlias,
          gaCategory: "",
          googleAdsAccountId,
          currencyCode,
          campaignId,
          adGroupId,
          newBiddingStrategy: biddingStrategy,
          newCpcBid: 0
        });
      }

      await updateCampaignBiddingStrategy({
        siteAlias,
        gaCategory: CAMPAIGN_DASHBOARD_GA_CATEGORY,
        googleAdsAccountId,
        currencyCode,
        campaignId,
        adGroupId,
        newBiddingStrategy,
        newCpcBid
      });

      if (refetchCampaignKeyword) {
        refetchCampaignKeyword(campaignId);
      }
      if (refetchCampaignConfiguration) {
        refetchCampaignConfiguration(campaignId);
      }
    } catch (e) {
      setErrorMessage("Error updating value. Try again.");
      setUpdateCpcBid(null);
      setDropdownOpen(true);
      console.error(e);
    } finally {
      if (setWillChange) {
        setWillChange(false);
      }
    }
  };

  const handleSelect = async (
    _ev: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (disabled) {
      return;
    }

    let newBiddingStrategy = biddingStrategy;
    let newCpcBid = cpcBid;
    if (value === BIDDING_STRATEGY_MANUAL_CPC_STR) {
      newBiddingStrategy = BIDDING_STRATEGY_MANUAL_CPC_STR;
    } else if (value === BIDDING_STRATEGY_TARGET_SPEND_STR) {
      newBiddingStrategy = BIDDING_STRATEGY_TARGET_SPEND_STR;
    } else {
      const minCpcBid = getCurrencyMinimumUnit(currencyCode) / 1e6;
      newCpcBid = Number(value);
      if (isNaN(newCpcBid)) {
        setErrorMessage(`Invalid amount: ${value}`);
        keepOpenRef.current = true;
        setDropdownOpen(true);
        return;
      } else if (
        biddingStrategy === BIDDING_STRATEGY_MANUAL_CPC_STR &&
        newCpcBid < minCpcBid
      ) {
        setErrorMessage(`Must be at least (${minCpcBid}): ${value}`);
        keepOpenRef.current = true;
        setDropdownOpen(true);
        return;
      } else if (
        biddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR &&
        newCpcBid < 0
      ) {
        setErrorMessage(`Must be positive: ${value}`);
        keepOpenRef.current = true;
        setDropdownOpen(true);
        return;
      } else {
        setErrorMessage("");
      }
    }

    await handleUpdateCampaignBiddingStrategy(newBiddingStrategy, newCpcBid);
    return;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center"
      }}
    >
      <EditDropdown
        compact
        open={dropdownOpen}
        icon={
          <EditIconWithHistory
            disabled={dropdownOpen}
            readonly={!canEditBids}
            historyOpen={historyOpen}
            setHistoryOpen={setHistoryOpen}
            historyItems={historyItems}
          />
        }
        disabled={isLoading || disabled}
        selection
        search
        allowAdditions={true}
        additionLabel={
          biddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR
            ? `Max: ${getCurrencySymbol(currencyCode)}`
            : `Default: ${getCurrencySymbol(currencyCode)}`
        }
        options={[
          errorMessage
            ? {
                key: "ERROR_MESSAGE",
                text: <span style={{ color: "red" }}>{errorMessage}</span>,
                disabled: true
              }
            : null,
          {
            key: "CPC_BID",
            text: formattedCpcBid,
            value: cpcBid
          },
          // Temporary option to show value being updated to until the UI state refreshes.
          updateCpcBid != null
            ? {
                key: "UPDATED_CPC_BID",
                text: `${updateCpcBid}`,
                value: updateCpcBid
              }
            : null,
          {
            key: BIDDING_STRATEGY_MANUAL_CPC_STR,
            text: "Switch to Manual CPC with Default Bid",
            disabled: biddingStrategy === BIDDING_STRATEGY_MANUAL_CPC_STR,
            value: BIDDING_STRATEGY_MANUAL_CPC_STR
          },
          {
            key: BIDDING_STRATEGY_TARGET_SPEND_STR,
            text: "Switch to using Campaign Maximum CPC Bid",
            disabled: biddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR,
            value: BIDDING_STRATEGY_TARGET_SPEND_STR
          }
        ].filter(Boolean)}
        value={updateCpcBid ?? cpcBid}
        onChange={handleSelect}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </div>
  );
};

export default CampaignBiddingStrategyCellContent;
