import React from "react";
import { Navigate, useSearchParams } from "react-router-dom-v5-compat";
import { Message } from "semantic-ui-react";
import styled from "styled-components/macro";

import { GetAmpdSubscriptionsRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import {
  AmpdSubscription,
  BillingAccount
} from "Common/proto/entity/billingAccount_pb";
import { InternalSiteDetails } from "Common/proto/entity/site_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { useQuery } from "@tanstack/react-query";

import { ProductCheckoutScreen } from "./ProductCheckoutScreen";

import { LoadingSpinner } from "Common/components/LoadingSpinner";

import { Site, User } from "ExtensionV2/queries/useSession";

import { hashCode } from "Common/utils/hashCode";

import { SelfServePricing } from "./SelfServePricing";
import { EnterprisePricing } from "./EnterprisePricing";
import { stringForEnum } from "Common/utils/proto";

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
  @media (max-width: 1099px) {
    flex-direction: column;
  }
`;

// For historic reasons this value is "price_id" even though it should refer to an
// AmpdSubscription.ID. If you change it you will need to update all of the sales magic-links.
export const SUBSCRIPTION_ID_PARAM = "price_id";
export const OFFER_PARAM = "offer";

// Sites with these billing account types should never be shown the payment page
export const NON_SELF_SERVE_BILLING_ACCOUNT_TYPES = [
  DashboardSite.BillingAccountType.Option.ENTERPRISE,
  DashboardSite.BillingAccountType.Option.LINKED
];

// Just a random string used to salt the price ID hash. This is not a secret and not secure.
const salt = "71580271";

export default function PaymentPage({
  currentSite,
  user
}: {
  currentSite: Site;
  user: User;
}): JSX.Element {
  const {
    data: ampdSubscriptions,
    isLoading: ampSubscriptionsLoading,
    error: ampSubscriptionsError
  } = useAmpdSubscriptions();

  const [
    subscriptionFromParam,
    skipOnboardingFee
  ] = useValidateSubscriptionParams(ampdSubscriptions);

  const {
    siteAlias,
    minGmv,
    organizationType,
    billingAccountType
  } = currentSite;

  // Don't show the payment page for Enterprise/Linked sites.
  if (NON_SELF_SERVE_BILLING_ACCOUNT_TYPES.includes(billingAccountType)) {
    return <Navigate to="/" replace={true} />;
  }

  // Don't show the payment page for sites with an account manager. They will need to talk to their
  // account manager about changing plans.
  if (currentSite.siteFeatures.isManaged) {
    return <Navigate to="/" replace={true} />;
  }

  if (ampSubscriptionsLoading) {
    return <LoadingSpinner />;
  }

  if (ampSubscriptionsError || !ampdSubscriptions) {
    return (
      <Message error>
        There was a problem fetching your subscription options.
      </Message>
    );
  }

  const hasActiveBillingAccount =
    currentSite.billingAccountStatus === BillingAccount.Status.Option.ACTIVE;

  const isHighValueCustomer =
    organizationType ===
      InternalSiteDetails.OrganizationType.Option.AGGREGATOR ||
    organizationType === InternalSiteDetails.OrganizationType.Option.AGENCY ||
    minGmv >= 1_000_000;

  // If there is a valid subscription param, show the checkout screen for that subscription
  if (subscriptionFromParam && !hasActiveBillingAccount) {
    return (
      <div style={{ margin: "1%" }}>
        <ProductCheckoutScreen
          ampdSubscriptions={ampdSubscriptions}
          initialSubscription={subscriptionFromParam}
          siteAlias={siteAlias}
          userEmail={user.userEmail}
          userName={user.userName}
          skipOnboardingFeeForSubscriptionId={
            skipOnboardingFee ? subscriptionFromParam.subscriptionId : null
          }
        />
      </div>
    );
    // If this is a new, "high value" customer then show them the contact-us screen.
  } else if (isHighValueCustomer && !hasActiveBillingAccount) {
    return <EnterprisePricing currentSite={currentSite} />;
  } else {
    // Show the self-serve pricing options for all other users.
    return (
      <SelfServePricing
        currentSite={currentSite}
        user={user}
        ampdSubscriptions={ampdSubscriptions}
      />
    );
  }
}

function useAmpdSubscriptions() {
  return useQuery({
    queryKey: ["ampdSubscriptions"],
    queryFn: async () => {
      const req = new GetAmpdSubscriptionsRequest();
      const reply = await GRPCWebClient.getAmpdSubscriptions(req, {});
      return reply.toObject().subscriptionsList;
    }
  });
}

/*
  Check for subscription params in the URL. These indicate that sales is trying to direct a new
  the user towards a specific plan, and there may be a discount applied. If there is a valid 
  SUBSCRIPTION_ID_PARAM param - skip the self-serve pricing options and go straight to the 
  checkout screen for that subscription.
*/
function useValidateSubscriptionParams(
  ampdSubscriptions: Array<AmpdSubscription.AsObject> | undefined
): [AmpdSubscription.AsObject | null, AmpdSubscription.AsObject | null] {
  const [searchParams] = useSearchParams();

  if (!ampdSubscriptions) {
    return [null, null];
  }

  const subscriptionIdParam =
    searchParams.get(SUBSCRIPTION_ID_PARAM) ||
    localStorage.getItem(SUBSCRIPTION_ID_PARAM);

  if (!subscriptionIdParam) {
    return [null, null];
  }
  const ampdSubscriptionFromParam = ampdSubscriptions.find(sub => {
    // We are smarter than typescript and know that the enum AmpdSubscription.SubscriptionID is
    // actually a map of string[number] ex: [AMPD_STARTER_MONTHLY_V2] = 1
    const id = (AmpdSubscription.SubscriptionID[
      (subscriptionIdParam.toUpperCase() as unknown) as number
    ] as unknown) as AmpdSubscription.SubscriptionID;
    return sub.subscriptionId === id;
  });

  if (!ampdSubscriptionFromParam) {
    return [null, null];
  }

  // If the OFFER_PARAM is present, check that the value of param matches the hashed + salted value
  // of the subscription id. This is not meant to be secure, just obscure.
  const skipOnboardingFeeParam =
    searchParams.get(OFFER_PARAM) || localStorage.getItem(OFFER_PARAM);

  if (!skipOnboardingFeeParam) {
    return [ampdSubscriptionFromParam, null];
  }

  const subscriptionName = stringForEnum(
    AmpdSubscription.SubscriptionID,
    ampdSubscriptionFromParam.subscriptionId
  )?.toLowerCase();

  const hashedPriceId = hashCode(salt + subscriptionName);
  if (hashedPriceId === skipOnboardingFeeParam) {
    return [ampdSubscriptionFromParam, ampdSubscriptionFromParam];
  }

  return [ampdSubscriptionFromParam, null];
}
