import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  campaignSetupSelectors,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors,
  GoogleKeyword
} from "./CampaignSetupPageState";
import styled from "styled-components";
import { useGoogleKeywordIdeas } from "ExtensionV2/queries/useGoogleKeywordIdeas";
import {
  ClickableText,
  SpacedUnwrappedRow,
  TightWrappingRow
} from "./SelectProductsStage";
import { Icon, Loader, Message, Modal } from "semantic-ui-react";
import { NewCampaignKeywordsPicker } from "ExtensionV2/components/AdvancedKeywordPicker";
import { GenerateGoogleAdsKeywordIdeasReply } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { Site } from "ExtensionV2/queries/useSession";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { isWalmartMarketplaceInfo } from "Common/utils/marketplace";

export const StyledKeywordSuggestionPillbox = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  border: ${props =>
    props.error
      ? "1px solid hsla(360, 100%, 80%, .75)"
      : "1px dashed lightblue"};
  background: azure;
  border-radius: 5px;
  padding: 0.75em;
  min-height: 4em;
  justify-content: center;
  align-self: flex-start;

  & div:first-child {
    align-self: center;
  }

  & p:first-child {
    display: inline;
    font-weight: 500;
    margin: 0;
  }

  & i {
    font-size: small;
  }

  & span {
    align-self: center;
    cursor: pointer;

    &:hover {
      color: gray;
    }
  }

  &:hover {
    border: ${props =>
      props.error ? "1px dashed hsla(360, 100%, 80%, .75)" : "1px dashed blue"};
  }
`;

const googleKeywordsValidator = (
  keywords: Array<GoogleKeyword> | null
): Set<string> => {
  const errors = new Set<string>();
  if (!keywords || keywords.length < 1) {
    errors.add("Please enter at least 1 Google keyword");
  }

  return errors;
};

export const googleKeywordsField = (
  initialKeywords: Array<GoogleKeyword> | null
): FormField<Array<GoogleKeyword> | null> => ({
  _value: initialKeywords,
  errors: googleKeywordsValidator(initialKeywords),
  validator: googleKeywordsValidator
});

export const GoogleKeywordsReviewSection = ({
  site,
  state,
  dispatch,
  onEdit
}: {
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { siteAlias } = site;
  const { currentReviewCampaignIndex } = state;
  const { currencyCode, customerId } = site.adwordsAccounts[0] || {};

  const [
    campaign,
    {
      selectGeotargets,
      selectSeedKeywords,
      selectGoogleKeywords,
      selectMarketplaceInfo
    }
  ] = getCurrentCampaignAndSelectors(state);
  const {
    googleKeywordsField: { errors: googleKeywordsErrors }
  } = campaign;

  const googleKeywords = selectGoogleKeywords(campaign);
  const seedKeywords = selectSeedKeywords(campaign);
  const geotargets = selectGeotargets(campaign, state);
  const marketplaceInfo = selectMarketplaceInfo(campaign, state);

  const retailer = isWalmartMarketplaceInfo(marketplaceInfo)
    ? "walmart"
    : "amazon";

  const onUpdateKeywords = useCallback(
    (keywords: Array<GoogleKeyword>) => {
      dispatch({
        name: "UpdateGoogleKeywords",
        data: {
          index: currentReviewCampaignIndex,
          keywords: keywords
        }
      });
    },
    [dispatch, currentReviewCampaignIndex]
  );

  const dataComponent = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "1em"
      }}
    >
      <GoogleTargetingKeywords
        currency={currencyCode}
        geotargets={geotargets}
        googleAdsCustomerId={customerId}
        googleKeywords={googleKeywords}
        retailerName={retailer}
        onUpdateKeywords={onUpdateKeywords}
        seedKeywords={seedKeywords}
        siteAlias={siteAlias}
      />
    </div>
  );

  /* 
    TODO: It would be nice if this followed the same pattern as the other editable fields,
    but that will require breaking apart the KeywordSelector from its tightly coupled
    modal. 
  */

  return (
    <CampaignReviewSection
      dataName="Google Targeting Keywords"
      dataComponent={dataComponent}
      errors={googleKeywordsErrors}
      onEdit={onEdit}
    />
  );
};

const KeywordSuggestionPillbox = ({
  keyword,
  onRemoveGoogleKeyword
}: {
  keyword: GoogleKeyword;
  onRemoveGoogleKeyword: (keywordText: string) => void;
}) => {
  const subtitle =
    keyword.avgMonthlySearches == null ? (
      <></>
    ) : (
      <p>
        <i>Monthly Searches: {keyword.avgMonthlySearches}</i>
      </p>
    );

  return (
    <SimpleTooltip
      key={keyword.text}
      tooltip="This keyword has very low search volume"
      disabled={!!keyword.avgMonthlySearches}
    >
      <StyledKeywordSuggestionPillbox error={keyword.avgMonthlySearches === 0}>
        <div>
          <p>{keyword.text} </p>
          {subtitle}
        </div>

        <span>
          <Icon
            style={{ margin: 0 }}
            onClick={() => onRemoveGoogleKeyword(keyword.text)}
            name="close"
          />
        </span>
      </StyledKeywordSuggestionPillbox>
    </SimpleTooltip>
  );
};

const MIN_KEYWORD_SUGGESTIONS = 10;
const MAX_KEYWORD_SUGGESTIONS = 20;

const getBestTargetingKeywordIdeas = (
  keywordIdeasList: Array<
    GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.AsObject
  >,
  seedKeywords: Array<string>,
  retailer: string
) => {
  type KeywordIdea = GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.AsObject;
  const retailerSuggestions: Array<KeywordIdea> = [];
  const nonRetailerSuggestions: Array<KeywordIdea> = [];
  const seedSuggestions: Array<KeywordIdea> = [];

  for (const suggestion of keywordIdeasList) {
    // skip branded terms unless the only brand is Amazon
    if (
      suggestion.brandNamesList.length > 1 ||
      (suggestion.brandNamesList.length === 1 &&
        suggestion.brandNamesList[0] !== retailer)
    ) {
      continue;
    }

    // Possible brands are usually, in fact, brands. Sometimes amazon shows up as a possible
    // brand.
    if (
      suggestion.possibleBrandNamesList.length > 1 ||
      (suggestion.possibleBrandNamesList.length === 1 &&
        suggestion.brandNamesList[0] !== retailer)
    ) {
      continue;
    }

    // "near me" in a keyword usually indicates someone looking for local stores, not online
    // stores.
    if (suggestion.text.match(/\bnear\s*me\b/)) {
      continue;
    }

    const searchVolume = suggestion.avgMonthlySearches?.value;
    // Include the seed keyword if its part of the Google suggestion result set and hasn't
    // already been disqualified and has any search volume.
    if (seedKeywords.includes(suggestion.text) && searchVolume) {
      seedSuggestions.push(suggestion);
      continue;
    }

    // skip very low volume keywords
    if (!searchVolume || searchVolume < 20) {
      continue;
    }

    if (retailer === "amazon" && suggestion.text.match(/\bamazon\b/)) {
      // We want "amazon" keywords but not "amazon basics" or "amazonbasics" since that is the
      // amazon in-house brand.
      if (suggestion.text.match(/\bamazon\s*basics\b/)) {
        continue;
      }

      retailerSuggestions.push(suggestion);
      continue;
    } else if (retailer === "walmart" && suggestion.text.match(/\bwalmart\b/)) {
      retailerSuggestions.push(suggestion);
      continue;
    }

    nonRetailerSuggestions.push(suggestion);
  }

  // Try to get a mix of amazon and non-amazon suggestions. Make at least half the suggestions
  // come from the nonRetailerSuggestions list since they usually have higher search volume.
  const bestSuggestions = [
    ...[...seedSuggestions, ...retailerSuggestions].slice(
      0,
      Math.floor(MAX_KEYWORD_SUGGESTIONS / 2)
    ),
    ...nonRetailerSuggestions
  ].slice(0, MAX_KEYWORD_SUGGESTIONS);

  return bestSuggestions;
};

// A component for suggesting Google Keywords. If state.googleKeywords in null, this will fetch
// suggestions using the seed keywords and load them into the form state.
export const SuggestGoogleTargetingKeywords = ({
  campaignIndex,
  site,
  state,
  dispatch
}: {
  campaignIndex: number;
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
}): JSX.Element => {
  const { siteAlias, adwordsAccounts } = site;
  const { currencyCode, customerId } = adwordsAccounts[0] || {};

  const {
    selectMarketplaceInfo,
    selectSeedKeywords,
    selectGeotargets,
    selectGoogleKeywords,
    selectWalmartSearchPhrase
  } = campaignSetupSelectors;

  const campaign = state.campaigns[campaignIndex];
  const marketplaceInfo = selectMarketplaceInfo(campaign, state);
  const seedKeywords = selectSeedKeywords(campaign);
  const geotargets = selectGeotargets(campaign, state);
  const googleKeywords = selectGoogleKeywords(campaign);
  const walmartSearchPhrase = selectWalmartSearchPhrase(campaign);

  const [seedKeywordsError, setSeedKeywordsError] = useState("");

  const [retailer, otherRetailer] = isWalmartMarketplaceInfo(marketplaceInfo)
    ? ["walmart", "amazon"]
    : ["amazon", "walmart"];

  const seedsWithAndWithoutRetailer = useMemo(() => {
    const allSeeds = [...seedKeywords];
    if (
      isWalmartMarketplaceInfo(marketplaceInfo) &&
      walmartSearchPhrase &&
      !allSeeds.includes(walmartSearchPhrase)
    ) {
      allSeeds.unshift(walmartSearchPhrase);
    }

    const retailerAppendedSeeds = allSeeds
      .filter(text => {
        const words = text.split(" ");
        return !words.includes(retailer) && !words.includes(otherRetailer);
      })
      .map(keyword => `${keyword} ${retailer}`);

    return [...allSeeds, ...retailerAppendedSeeds];
  }, [
    retailer,
    otherRetailer,
    marketplaceInfo,
    walmartSearchPhrase,
    seedKeywords
  ]);

  const {
    data: keywordIdeasData,
    isLoading: keywordIdeasAreLoading,
    error: keywordIdeasError
  } = useGoogleKeywordIdeas(
    siteAlias,
    customerId,
    "",
    seedsWithAndWithoutRetailer,
    geotargets
  );

  useEffect(() => {
    if (!keywordIdeasData?.keywordIdeasList.length) {
      return;
    }

    const bestSuggestions = getBestTargetingKeywordIdeas(
      keywordIdeasData?.keywordIdeasList,
      seedKeywords,
      retailer
    );

    if (bestSuggestions.length < MIN_KEYWORD_SUGGESTIONS) {
      setSeedKeywordsError(
        "We weren't able to find many keywords from your descriptions. Please try changing your \
          descriptions or using the advanced keyword selector."
      );
    }

    if (campaign.googleKeywordsField._value == null) {
      dispatch({
        name: "UpdateGoogleKeywords",
        data: {
          index: campaignIndex,
          keywords: bestSuggestions.map(suggestion => {
            return {
              text: suggestion.text,
              avgMonthlySearches: suggestion.avgMonthlySearches?.value,
              competitionIndex: suggestion.competitionIndex?.value,
              lowTopOfPageBid: suggestion.lowTopOfPageBid?.value,
              highTopOfPageBid: suggestion.highTopOfPageBid?.value
            };
          })
        }
      });
    }
  }, [
    campaignIndex,
    dispatch,
    retailer,
    keywordIdeasData?.keywordIdeasList,
    seedKeywords,
    campaign.googleKeywordsField._value
  ]);

  const onUpdateKeywords = useCallback(
    (keywords: Array<GoogleKeyword>) => {
      dispatch({
        name: "UpdateGoogleKeywords",
        data: {
          index: campaignIndex,
          keywords: keywords
        }
      });
    },
    [campaignIndex, dispatch]
  );

  if (keywordIdeasAreLoading) {
    return (
      <SpacedUnwrappedRow style={{ justifyContent: "center", height: "5em" }}>
        <TightWrappingRow>
          <Loader
            active
            inline
            style={{ margin: "auto" }}
            content={
              <p style={{ fontSize: "small" }}>
                <i>Loading Keywords</i>
              </p>
            }
          />
        </TightWrappingRow>
      </SpacedUnwrappedRow>
    );
  }

  if (keywordIdeasError) {
    return (
      <SpacedUnwrappedRow>
        <Message
          style={{ margin: "auto" }}
          error
          compact
          content="We were unable to load your suggested keywords. Please try again later."
        />
      </SpacedUnwrappedRow>
    );
  }

  return (
    <>
      {seedKeywordsError && googleKeywords.length < MIN_KEYWORD_SUGGESTIONS && (
        <SpacedUnwrappedRow>
          <Message
            style={{ margin: "auto" }}
            warning
            compact
            content={seedKeywordsError}
          />
        </SpacedUnwrappedRow>
      )}
      {googleKeywords.length > 0 && (
        <h5>
          Review these keywords and remove any that do not apply to your
          product. You can select additional keywords using the advanced keyword
          selector.
        </h5>
      )}
      <GoogleTargetingKeywords
        currency={currencyCode}
        geotargets={geotargets}
        googleAdsCustomerId={customerId}
        googleKeywords={googleKeywords}
        retailerName={retailer}
        onUpdateKeywords={onUpdateKeywords}
        seedKeywords={seedKeywords}
        siteAlias={siteAlias}
      />
    </>
  );
};

// A component for displaying and removing Google Keywords as pillboxes along side the advanced
// keyword selector.
export const GoogleTargetingKeywords = ({
  currency,
  geotargets,
  googleAdsCustomerId,
  googleKeywords,
  retailerName,
  onUpdateKeywords,
  seedKeywords,
  siteAlias
}: {
  currency: string;
  geotargets: Array<number>;
  googleAdsCustomerId: string;
  googleKeywords: Array<GoogleKeyword>;
  retailerName: string;
  onUpdateKeywords: (keywords: Array<GoogleKeyword>) => void;
  seedKeywords: Array<string>;
  siteAlias: string;
}): JSX.Element => {
  const [
    advancedKeywordSelectorOpen,
    setAdvancedKeywordSelectorOpen
  ] = useState(false);

  const onRemoveGoogleKeyword = (keywordText: string) => {
    const keywords = googleKeywords.filter(
      existingKeyword => existingKeyword.text !== keywordText
    );

    onUpdateKeywords(keywords);
  };

  const advancedKeywordPicker = useMemo(() => {
    return (
      <NewCampaignKeywordsPicker
        siteAlias={siteAlias}
        customerId={googleAdsCustomerId}
        geotargets={geotargets}
        googleAdsCurrencyCode={currency}
        initialNewKeywords={googleKeywords}
        initialSeedKeywords={seedKeywords}
        retailerName={retailerName}
        onSave={(keywords: Array<GoogleKeyword>) => {
          onUpdateKeywords(keywords);
          setAdvancedKeywordSelectorOpen(false);
        }}
        onCancel={() => {
          setAdvancedKeywordSelectorOpen(false);
        }}
      />
    );
  }, [
    currency,
    siteAlias,
    googleAdsCustomerId,
    geotargets,
    retailerName,
    seedKeywords,
    googleKeywords,
    onUpdateKeywords
  ]);

  return (
    <>
      <SpacedUnwrappedRow>
        {advancedKeywordSelectorOpen && (
          <Modal
            className="disablePerspective"
            closeOnDocumentClick={false}
            closeOnDimmerClick={false}
            style={{
              overflow: "hidden",
              height: "80vh",
              width: "80vw",
              padding: "1em"
            }}
            open={advancedKeywordSelectorOpen}
            onClose={() => {
              setAdvancedKeywordSelectorOpen(false);
            }}
          >
            {advancedKeywordPicker}
          </Modal>
        )}
        <TightWrappingRow style={{ gap: "1em" }}>
          {googleKeywords
            .sort((a, b) => {
              // avgMonthlySearches is sometimes undefined...sort those last
              if (!a.avgMonthlySearches) {
                return 1;
              }
              if (!b.avgMonthlySearches) {
                return -1;
              }
              return b.avgMonthlySearches - a.avgMonthlySearches;
            })
            .map(keyword => {
              return (
                <KeywordSuggestionPillbox
                  key={keyword.text}
                  keyword={keyword}
                  onRemoveGoogleKeyword={onRemoveGoogleKeyword}
                />
              );
            })}
          <div
            style={{
              height: "4em",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column"
            }}
            onClick={() => setAdvancedKeywordSelectorOpen(isOpen => !isOpen)}
          >
            <ClickableText>Advanced Keyword Selector</ClickableText>
          </div>
        </TightWrappingRow>
      </SpacedUnwrappedRow>
    </>
  );
};
