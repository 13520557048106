import _ from "lodash";

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Icon,
  Form,
  Grid,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import styled from "styled-components/macro";
import { Flex } from "@rebass/grid";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";
import InputWithMaxUTF8Size from "../InputWithMaxUTF8Size";

import { sendGAEvent } from "../GA";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";

// grpc-web
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignsRequest,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { extractErrorMessage } from "Common/errors/error";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { MAX_KEYWORD_LENGTH, scrubKeyword } from "Common/utils/googleAds";
import { isWalmartMarketplaceInfo } from "Common/utils/marketplace";

const HistoryDropdown = styled(Dropdown)`
  &&& .text {
    font-weight: normal;
  }
  &&& .menu {
    max-height: 10rem;
    overflow-y: auto;
  }
  &&& .menu .item {
    font-size: smaller;
  }
  &&& .menu .active.item {
    font-weight: normal;
  }
`;

const WalmartSearchTermsEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    customerId,
    isCampaignRemoved,
    marketplaceInfo,
    actionId
  } = itemizedCampaignConfiguration;

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  let editable = true;
  if (
    !isWalmartMarketplaceInfo(marketplaceInfo) ||
    !customerId ||
    !actionId ||
    isCampaignRemoved
  ) {
    editable = false;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={
          editable
            ? () => {
                sendGAEvent(gaCategory, "Click Edit Search Terms", siteAlias);
                setModalOpen(true);
              }
            : undefined
        }
      >
        {editable && (
          <SimpleTooltip tooltip="Edit Search Terms to Track">
            <Icon name="pencil" style={{ cursor: "pointer" }} />
          </SimpleTooltip>
        )}
      </Flex>
      {editable && modalOpen && (
        <WalmartSearchTermsModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

const WalmartSearchTermsModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const {
    customerId,
    campaignId,
    searchTerms: initialSearchTerms,
    historicalSearchTerms,
    actionId
  } = itemizedCampaignConfiguration;

  const [editSearchTerms, setEditSearchTerms] = useState<Array<string>>([]);
  const [editSearchTerm, setEditSearchTerm] = useState<string>("");
  useEffect(() => {
    setEditSearchTerms(initialSearchTerms);
  }, [initialSearchTerms]);

  const [modalOpen, setModalOpen] = useState(true);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleUpdateSearchTerms = async () => {
    const newSearchTerms = editSearchTerms.filter(Boolean);

    try {
      await sendUpdateSearchTerms(
        siteAlias,
        gaCategory,
        String(customerId),
        actionId,
        newSearchTerms
      );

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);

      console.error(e); // log to datadog

      setErrorMessage(message);
    }
  };

  const handleModalClose = () => {
    setErrorMessage("");
    setUpdateEnabled(false);
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleExistingTextChange = (index: number) => (text: string) => {
    const newSearchTerms = _.clone(editSearchTerms);
    newSearchTerms[index] = text;
    setEditSearchTerms(newSearchTerms);
    setErrorMessage("");
    setUpdateEnabled(false);
  };

  const handleNewTextChange = (text: string) => {
    setEditSearchTerm(text);
    setErrorMessage("");
    setUpdateEnabled(false);
  };

  const handleTextAccept = () => {
    setEditSearchTerms(searchTerms => searchTerms.filter(text => !!text));
    setEditSearchTerm(searchTerm => {
      if (searchTerm) {
        setEditSearchTerms(searchTerms => [...searchTerms, searchTerm]);
      }
      return "";
    });
    setErrorMessage("");
    setUpdateEnabled(true);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
    >
      <Modal.Header>
        Change Search Terms to Track in Walmart Organic Search
      </Modal.Header>
      <Modal.Content scrolling>
        <Segment>
          <div style={{ minHeight: 400 }}>
            <Form success={false}>
              <Form.Field required>
                <p>
                  Ampd will track the search positions on Walmart for these
                  terms.
                </p>
              </Form.Field>
              <Form.Field
                style={{ paddingTop: "1.0em", paddingBottom: "1.0em" }}
              >
                <Grid>
                  <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid.Column width="12" />
                  </Grid.Row>

                  {editSearchTerms.map((searchTerm, index) => {
                    return (
                      <Grid.Row
                        key={index}
                        style={{ paddingTop: "0.1em" }}
                        verticalAlign="middle"
                      >
                        <Grid.Column>
                          <SearchTermDropdown
                            searchTerm={searchTerm}
                            onChange={handleExistingTextChange(index)}
                            onAccept={handleTextAccept}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    );
                  })}
                  <Grid.Row
                    key={editSearchTerms.length}
                    style={{ paddingTop: "0.1em" }}
                    verticalAlign="middle"
                  >
                    <Grid.Column>
                      <SearchTermDropdown
                        searchTerm={editSearchTerm}
                        autofocus={true}
                        initialSearchTerms={initialSearchTerms}
                        historicalSearchTerms={historicalSearchTerms}
                        currentSearchTerms={editSearchTerms}
                        onChange={handleNewTextChange}
                        onAccept={handleTextAccept}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Form>
          </div>
        </Segment>
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          primary
          onClick={handleUpdateSearchTerms}
          disabled={
            !updateEnabled || _.isEqual(editSearchTerms, initialSearchTerms)
          }
        >
          Update Search Terms to Track
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

const SearchTermDropdown: React.FC<{
  searchTerm: string;
  onChange: (text: string) => void;
  onAccept?: () => void;
  disabled?: boolean;
  autofocus?: boolean;
  initialSearchTerms?: Array<string>;
  historicalSearchTerms?: Array<string>;
  currentSearchTerms?: Array<string>;
}> = ({
  searchTerm,
  onChange,
  onAccept,
  disabled,
  autofocus,
  initialSearchTerms,
  historicalSearchTerms,
  currentSearchTerms
}) => {
  const terms: Array<string> = [];

  let searchTermIdeaOptions;
  if (initialSearchTerms) {
    searchTermIdeaOptions = [
      {
        key: "--NONE--",
        value: "",
        text: ""
      }
    ];

    initialSearchTerms.forEach(term => {
      if (
        term &&
        term !== searchTerm &&
        !terms.includes(term) &&
        !currentSearchTerms?.includes(term)
      ) {
        terms.push(term);
      }
    });

    historicalSearchTerms?.forEach(term => {
      if (
        term &&
        term !== searchTerm &&
        !terms.includes(term) &&
        !currentSearchTerms?.includes(term)
      ) {
        terms.push(term);
      }
    });

    terms.forEach(term => {
      searchTermIdeaOptions.push({
        key: term,
        value: term,
        text: term
      });
    });
  }

  const handleSearchTermChange = (value: string) => {
    if (onChange) {
      const text = scrubKeyword(value, MAX_KEYWORD_LENGTH);
      onChange(text);
    }
  };

  const handleSearchTermAccept = (value: string) => {
    handleSearchTermChange(value);
    if (onAccept) {
      onAccept();
    }
  };

  const handleDropdownChange = (
    _e: React.MouseEvent<HTMLDivElement>,
    { value }: DropdownItemProps
  ) => {
    handleSearchTermAccept(value as string);
  };

  return (
    <Flex flexDirection="row" alignItems="center">
      <InputWithMaxUTF8Size
        disabled={disabled}
        text={searchTerm || ""}
        onTextChange={handleSearchTermChange}
        onTextAccept={handleSearchTermAccept}
        hideAnnotation={true}
        autofocus={autofocus}
        inputLabel={
          searchTermIdeaOptions ? (
            <HistoryDropdown
              value=""
              scrolling
              upward={(currentSearchTerms?.length || 0) >= 3}
              direction="left"
              selectOnNavigation={false}
              selectOnBlur={false}
              disabled={disabled || searchTermIdeaOptions.length < 2}
            >
              <Dropdown.Menu>
                {searchTermIdeaOptions.map(option =>
                  option.value === "" ? (
                    <Dropdown.Header
                      key={option.key}
                      content="Previous search terms"
                    />
                  ) : (
                    <Dropdown.Item
                      {...option}
                      key={option.key}
                      onClick={handleDropdownChange}
                    />
                  )
                )}
              </Dropdown.Menu>
            </HistoryDropdown>
          ) : (
            <Button
              icon="close"
              type="button"
              onClick={() => handleSearchTermAccept("")}
            />
          )
        }
        inputLabelPosition={"right"}
      />
    </Flex>
  );
};

// Updates the search terms to track for the specified ad group
async function sendUpdateSearchTerms(
  siteAlias: string,
  gaCategory: string,
  customerId: string,
  actionId: string,
  searchTerms: Array<string>
) {
  if (!siteAlias || !customerId || !actionId || !searchTerms) {
    return;
  }

  const action = new UpdateCampaignAction();

  const updateSearchTerms = new UpdateCampaignAction.UpdateWalmartSearchTermsToTrackAction();
  updateSearchTerms.setActionId(actionId);
  updateSearchTerms.setSearchTermsToTrackList(searchTerms);

  action.setUpdateWalmartSearchTermsToTrack(updateSearchTerms);

  sendGAEvent(
    gaCategory,
    "Update Search Terms to Track",
    siteAlias,
    searchTerms.join(", ")
  );

  const updateReq = new UpdateCampaignsRequest();
  updateReq.setSiteAlias(siteAlias);
  updateReq.setCustomerId(customerId);
  updateReq.setActionsList([action]);

  await GRPCWebClient.updateCampaigns(updateReq, null);
}

export default WalmartSearchTermsEditButton;
