import { Button, Icon, Loader, Message, Modal } from "semantic-ui-react";
import React, { useState, useCallback } from "react";

import { sendGAEvent } from "../GA";
import SimpleTooltip from "../SimpleTooltip";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import { useEditAdCopy } from "ExtensionV2/queries/useUpdateAdCopyMutation";

const AdContentEditButton: React.FC<{
  siteAlias: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
}> = ({ siteAlias, itemizedCampaignConfiguration }) => {
  const buttonTooltipText = "Edit Ad Copy";
  const buttonIcon = "pencil";
  const modalTitle = "Edit Ad Copy";
  const gaEventName = "Edit Google Ad Copy";

  const [modalOpen, setModalOpen] = useState(false);

  const {
    component: EditAdCopyComponent,
    componentError: editAdCopyComponentError,
    productDetailsLoading: productDetailsLoading,
    productDetailsError: productDetailsError,
    componentProps: editAdCopyComponentProps,
    mutation: editAdCopyMutation,
    mutationError: editAdCopyMutationError,
    mutationLoading: editAdCopyMutationLoading,
    mutationReset: editAdCopyMutationReset,
    mutationSuccess: editAdCopyMutationSuccess
  } = useEditAdCopy(itemizedCampaignConfiguration);

  const PAGE_GA_CATEGORY = "Ampd: Edit Ad Copy";

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  if (editAdCopyMutationSuccess) {
    editAdCopyMutationReset();
    if (modalOpen) {
      setModalOpen(false);
    }
  }

  return (
    <>
      <SimpleTooltip tooltip={buttonTooltipText}>
        <Icon
          name={buttonIcon}
          style={{ cursor: "pointer", marginTop: -5 }}
          onClick={() => {
            sendGAEvent(PAGE_GA_CATEGORY, gaEventName, siteAlias);
            setModalOpen(true);
          }}
        />
      </SimpleTooltip>
      {modalOpen && (
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          closeIcon={<Icon name="close" color="black" />}
          centered={false}
          dimmer="inverted"
        >
          <Modal.Header>{modalTitle}</Modal.Header>

          <Modal.Content scrolling>
            {productDetailsLoading ? (
              <Loader inline size="big" content="Generating Ad Copy" />
            ) : (
              <EditAdCopyComponent {...editAdCopyComponentProps} />
            )}

            <Message
              error
              hidden={!editAdCopyMutationError && !productDetailsError}
            >
              <p>
                {
                  "There was an internal error when trying to update this ad's copy. Please try \
                    again. If this problem persists please reach out to us for assistance."
                }
              </p>
            </Message>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button
              primary
              onClick={editAdCopyMutation}
              disabled={editAdCopyComponentError}
              loading={editAdCopyMutationLoading}
            >
              Update
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default AdContentEditButton;
