// source: proto/common/amount.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_filter_pb = require('../../proto/common/filter_pb.js');
goog.object.extend(proto, proto_common_filter_pb);
goog.exportSymbol('proto.common.Amount', null, global);
goog.exportSymbol('proto.common.Amount.TypeCase', null, global);
goog.exportSymbol('proto.common.AmountFilter', null, global);
goog.exportSymbol('proto.common.AmountFilter.AmountList', null, global);
goog.exportSymbol('proto.common.AmountFilter.ComparatorCase', null, global);
goog.exportSymbol('proto.common.AmountFilter.Operator', null, global);
goog.exportSymbol('proto.common.AmountFilter.Operator.Option', null, global);
goog.exportSymbol('proto.common.AmountFilterClause', null, global);
goog.exportSymbol('proto.common.AmountFilterClause.Filter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.Amount.oneofGroups_);
};
goog.inherits(proto.common.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amount.displayName = 'proto.common.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AmountFilterClause = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.AmountFilterClause.repeatedFields_, null);
};
goog.inherits(proto.common.AmountFilterClause, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AmountFilterClause.displayName = 'proto.common.AmountFilterClause';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AmountFilterClause.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AmountFilterClause.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AmountFilterClause.Filter.displayName = 'proto.common.AmountFilterClause.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AmountFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.AmountFilter.oneofGroups_);
};
goog.inherits(proto.common.AmountFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AmountFilter.displayName = 'proto.common.AmountFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AmountFilter.Operator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AmountFilter.Operator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AmountFilter.Operator.displayName = 'proto.common.AmountFilter.Operator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AmountFilter.AmountList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.AmountFilter.AmountList.repeatedFields_, null);
};
goog.inherits(proto.common.AmountFilter.AmountList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AmountFilter.AmountList.displayName = 'proto.common.AmountFilter.AmountList';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.Amount.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.common.Amount.TypeCase = {
  TYPE_NOT_SET: 0,
  INTEGER: 1,
  FLOAT: 2
};

/**
 * @return {proto.common.Amount.TypeCase}
 */
proto.common.Amount.prototype.getTypeCase = function() {
  return /** @type {proto.common.Amount.TypeCase} */(jspb.Message.computeOneofCase(this, proto.common.Amount.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    integer: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pb_float: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amount}
 */
proto.common.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amount;
  return proto.common.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amount}
 */
proto.common.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInteger(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 integer = 1;
 * @return {number}
 */
proto.common.Amount.prototype.getInteger = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amount} returns this
 */
proto.common.Amount.prototype.setInteger = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.common.Amount.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.Amount} returns this
 */
proto.common.Amount.prototype.clearInteger = function() {
  return jspb.Message.setOneofField(this, 1, proto.common.Amount.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amount.prototype.hasInteger = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double float = 2;
 * @return {number}
 */
proto.common.Amount.prototype.getFloat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amount} returns this
 */
proto.common.Amount.prototype.setFloat = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.common.Amount.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.Amount} returns this
 */
proto.common.Amount.prototype.clearFloat = function() {
  return jspb.Message.setOneofField(this, 2, proto.common.Amount.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amount.prototype.hasFloat = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.AmountFilterClause.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AmountFilterClause.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AmountFilterClause.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AmountFilterClause} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilterClause.toObject = function(includeInstance, msg) {
  var f, obj = {
    operator: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.common.AmountFilterClause.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AmountFilterClause}
 */
proto.common.AmountFilterClause.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AmountFilterClause;
  return proto.common.AmountFilterClause.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AmountFilterClause} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AmountFilterClause}
 */
proto.common.AmountFilterClause.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.FilterLogicalOperator.Option} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 2:
      var value = new proto.common.AmountFilterClause.Filter;
      reader.readMessage(value,proto.common.AmountFilterClause.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AmountFilterClause.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AmountFilterClause.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AmountFilterClause} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilterClause.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.AmountFilterClause.Filter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AmountFilterClause.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AmountFilterClause.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AmountFilterClause.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilterClause.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    selector: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && proto.common.AmountFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AmountFilterClause.Filter}
 */
proto.common.AmountFilterClause.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AmountFilterClause.Filter;
  return proto.common.AmountFilterClause.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AmountFilterClause.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AmountFilterClause.Filter}
 */
proto.common.AmountFilterClause.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelector(value);
      break;
    case 2:
      var value = new proto.common.AmountFilter;
      reader.readMessage(value,proto.common.AmountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AmountFilterClause.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AmountFilterClause.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AmountFilterClause.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilterClause.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelector();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.AmountFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string selector = 1;
 * @return {string}
 */
proto.common.AmountFilterClause.Filter.prototype.getSelector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AmountFilterClause.Filter} returns this
 */
proto.common.AmountFilterClause.Filter.prototype.setSelector = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AmountFilter filter = 2;
 * @return {?proto.common.AmountFilter}
 */
proto.common.AmountFilterClause.Filter.prototype.getFilter = function() {
  return /** @type{?proto.common.AmountFilter} */ (
    jspb.Message.getWrapperField(this, proto.common.AmountFilter, 2));
};


/**
 * @param {?proto.common.AmountFilter|undefined} value
 * @return {!proto.common.AmountFilterClause.Filter} returns this
*/
proto.common.AmountFilterClause.Filter.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AmountFilterClause.Filter} returns this
 */
proto.common.AmountFilterClause.Filter.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AmountFilterClause.Filter.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FilterLogicalOperator.Option operator = 1;
 * @return {!proto.common.FilterLogicalOperator.Option}
 */
proto.common.AmountFilterClause.prototype.getOperator = function() {
  return /** @type {!proto.common.FilterLogicalOperator.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.FilterLogicalOperator.Option} value
 * @return {!proto.common.AmountFilterClause} returns this
 */
proto.common.AmountFilterClause.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Filter filters = 2;
 * @return {!Array<!proto.common.AmountFilterClause.Filter>}
 */
proto.common.AmountFilterClause.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.common.AmountFilterClause.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.AmountFilterClause.Filter, 2));
};


/**
 * @param {!Array<!proto.common.AmountFilterClause.Filter>} value
 * @return {!proto.common.AmountFilterClause} returns this
*/
proto.common.AmountFilterClause.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.AmountFilterClause.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.AmountFilterClause.Filter}
 */
proto.common.AmountFilterClause.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.AmountFilterClause.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.AmountFilterClause} returns this
 */
proto.common.AmountFilterClause.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.AmountFilter.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.common.AmountFilter.ComparatorCase = {
  COMPARATOR_NOT_SET: 0,
  AMOUNT_LIST: 3
};

/**
 * @return {proto.common.AmountFilter.ComparatorCase}
 */
proto.common.AmountFilter.prototype.getComparatorCase = function() {
  return /** @type {proto.common.AmountFilter.ComparatorCase} */(jspb.Message.computeOneofCase(this, proto.common.AmountFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AmountFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AmountFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AmountFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    not: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountList: (f = msg.getAmountList()) && proto.common.AmountFilter.AmountList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AmountFilter}
 */
proto.common.AmountFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AmountFilter;
  return proto.common.AmountFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AmountFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AmountFilter}
 */
proto.common.AmountFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNot(value);
      break;
    case 2:
      var value = /** @type {!proto.common.AmountFilter.Operator.Option} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 3:
      var value = new proto.common.AmountFilter.AmountList;
      reader.readMessage(value,proto.common.AmountFilter.AmountList.deserializeBinaryFromReader);
      msg.setAmountList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AmountFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AmountFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AmountFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNot();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmountList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.AmountFilter.AmountList.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AmountFilter.Operator.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AmountFilter.Operator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AmountFilter.Operator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilter.Operator.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AmountFilter.Operator}
 */
proto.common.AmountFilter.Operator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AmountFilter.Operator;
  return proto.common.AmountFilter.Operator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AmountFilter.Operator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AmountFilter.Operator}
 */
proto.common.AmountFilter.Operator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AmountFilter.Operator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AmountFilter.Operator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AmountFilter.Operator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilter.Operator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.AmountFilter.Operator.Option = {
  UNSPECIFIED: 0,
  EQUALS: 1,
  LESS_THAN: 2,
  LESS_THAN_OR_EQUAL: 6,
  GREATER_THAN: 3,
  GREATER_THAN_OR_EQUAL: 5,
  IN_LIST: 4
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.AmountFilter.AmountList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AmountFilter.AmountList.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AmountFilter.AmountList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AmountFilter.AmountList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilter.AmountList.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountsList: jspb.Message.toObjectList(msg.getAmountsList(),
    proto.common.Amount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AmountFilter.AmountList}
 */
proto.common.AmountFilter.AmountList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AmountFilter.AmountList;
  return proto.common.AmountFilter.AmountList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AmountFilter.AmountList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AmountFilter.AmountList}
 */
proto.common.AmountFilter.AmountList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Amount;
      reader.readMessage(value,proto.common.Amount.deserializeBinaryFromReader);
      msg.addAmounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AmountFilter.AmountList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AmountFilter.AmountList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AmountFilter.AmountList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AmountFilter.AmountList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Amount amounts = 1;
 * @return {!Array<!proto.common.Amount>}
 */
proto.common.AmountFilter.AmountList.prototype.getAmountsList = function() {
  return /** @type{!Array<!proto.common.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amount, 1));
};


/**
 * @param {!Array<!proto.common.Amount>} value
 * @return {!proto.common.AmountFilter.AmountList} returns this
*/
proto.common.AmountFilter.AmountList.prototype.setAmountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amount}
 */
proto.common.AmountFilter.AmountList.prototype.addAmounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.AmountFilter.AmountList} returns this
 */
proto.common.AmountFilter.AmountList.prototype.clearAmountsList = function() {
  return this.setAmountsList([]);
};


/**
 * optional bool not = 1;
 * @return {boolean}
 */
proto.common.AmountFilter.prototype.getNot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.AmountFilter} returns this
 */
proto.common.AmountFilter.prototype.setNot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Operator.Option operator = 2;
 * @return {!proto.common.AmountFilter.Operator.Option}
 */
proto.common.AmountFilter.prototype.getOperator = function() {
  return /** @type {!proto.common.AmountFilter.Operator.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.AmountFilter.Operator.Option} value
 * @return {!proto.common.AmountFilter} returns this
 */
proto.common.AmountFilter.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AmountList amount_list = 3;
 * @return {?proto.common.AmountFilter.AmountList}
 */
proto.common.AmountFilter.prototype.getAmountList = function() {
  return /** @type{?proto.common.AmountFilter.AmountList} */ (
    jspb.Message.getWrapperField(this, proto.common.AmountFilter.AmountList, 3));
};


/**
 * @param {?proto.common.AmountFilter.AmountList|undefined} value
 * @return {!proto.common.AmountFilter} returns this
*/
proto.common.AmountFilter.prototype.setAmountList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.common.AmountFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AmountFilter} returns this
 */
proto.common.AmountFilter.prototype.clearAmountList = function() {
  return this.setAmountList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AmountFilter.prototype.hasAmountList = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.common);
