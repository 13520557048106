import React, { useContext } from "react";
import { Checkbox } from "semantic-ui-react";

import { WantsAmpdProOperatorFeaturesContext } from "ExtensionV2";

// Manages the state of the ampd pro operator features toggle.
function AmpdProOperatorFeaturesToggle(props) {
  const { label } = props;
  const [
    wantsAmpdProOperatorFeatures,
    setWantsAmpdProOperatorFeatures
  ] = useContext(WantsAmpdProOperatorFeaturesContext);

  const handleToggleWantAmpdProOperatorFeatures = () => {
    setWantsAmpdProOperatorFeatures(!wantsAmpdProOperatorFeatures);
  };

  return (
    <Checkbox
      label={label || "Ampd Pro Operator Features"}
      onClick={handleToggleWantAmpdProOperatorFeatures}
      checked={wantsAmpdProOperatorFeatures}
    />
  );
}

export default AmpdProOperatorFeaturesToggle;
