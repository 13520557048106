import React, { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";

import { ACCOUNTS_DASHBOARD_GA_CATEGORY } from "./AccountsPage";

import { sendGAEvent } from "../../components/GA";
import SiteSelectorModal from "../../components/SiteSelectorModal";
import { linkClientSiteToManagerSite } from "../../grpc/linkClientSiteToManagerSite";
import { extractErrorMessage } from "Common/errors/error";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { useSessionUser } from "ExtensionV2/queries/useSessionUser";

function LinkClientAccountButton({
  managerSiteAlias,
  clientSites,
  initialLabels,
  onDone
}: {
  managerSiteAlias: string;
  clientSites: Array<SiteManagerLinkClient.AsObject>;
  initialLabels: Array<string>;
  onDone?: (newClientSiteAliases: Array<string>) => void;
}): JSX.Element {
  const { isAmpdOperator, authorizedSitesList: userSites } = useSessionUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

  const clientSiteAliases = useMemo(
    () => (clientSites || []).map(clientSite => clientSite.clientSiteAlias),
    [clientSites]
  );

  // A user site is eligible for linking if the current user is an Admin for
  // the user site or is a superuser.
  const eligibleUserSites = useMemo(
    () =>
      (userSites || []).filter(
        userSite =>
          (userSite.isAdmin || isAmpdOperator) &&
          userSite.siteAlias !== managerSiteAlias
      ),
    [managerSiteAlias, userSites, isAmpdOperator]
  );

  const handleSelectAccountsSelect = async (siteAliases: Array<string>) => {
    if (!siteAliases || siteAliases.length === 0) {
      setModalOpen(false);
      return;
    }

    const newClientSiteAliases: Array<string> = [];
    const errors: Array<string> = [];

    await Promise.all(
      siteAliases.map(async clientSiteAlias => {
        await linkClientSiteToManagerSite(
          clientSiteAlias,
          managerSiteAlias,
          initialLabels
        )
          .then(() => {
            newClientSiteAliases.push(clientSiteAlias);
          })
          .catch(e => {
            const message = extractErrorMessage(e);
            console.error(message);
            errors.push(message);
          });
      })
    );

    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setModalOpen(false);
    }

    if (newClientSiteAliases.length > errors.length && onDone) {
      onDone(newClientSiteAliases);
    }
  };

  return (
    <>
      <Button
        disabled={eligibleUserSites.length < 1}
        onClick={() => {
          sendGAEvent(
            ACCOUNTS_DASHBOARD_GA_CATEGORY,
            "Click Link Client Account",
            managerSiteAlias
          );
          setModalOpen(true);
        }}
      >
        Connect Client Accounts
      </Button>
      {modalOpen && (
        <SiteSelectorModal
          buttonLabel="Connect"
          disabledSiteAliases={clientSiteAliases} // Don't let the user unselect current clients.
          errorMessages={errorMessages}
          onClose={() => setModalOpen(false)}
          onSelect={handleSelectAccountsSelect}
          open={modalOpen}
          setErrorMessages={setErrorMessages}
          siteAliases={clientSiteAliases}
          title="Connect Client Accounts"
          userSites={eligibleUserSites}
        />
      )}
    </>
  );
}

export default LinkClientAccountButton;
