import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

import ChangePlanConfirmationModal from "./ChangePlanConfirmationModal";
import { NextInvoice } from "./NextInvoice";
import { PlansContainer } from "./PaymentPage";
import { ProductCheckoutScreen } from "./ProductCheckoutScreen";

import { Site, User } from "ExtensionV2/queries/useSession";
import { AmpdSubscription } from "Common/proto/entity/billingAccount_pb";
import { AmpdSubscriptionDisplay } from "./AmpdPlan";
import {
  FEATURES_BY_GROUP,
  hasActiveStripePlan,
  SUBTITLE_BY_GROUP
} from "Common/utils/ampdPayment";

// Default to 12 month plan. Users can select their term length on the checkout screen.
const SELF_SERVE_SUBSCRIPTION_OPTIONS: Array<AmpdSubscription.SubscriptionID> = [
  AmpdSubscription.SubscriptionID.AMPD_CONNECT_12_MONTH,
  AmpdSubscription.SubscriptionID.AMPD_AUTOMATION_12_MONTH,
  AmpdSubscription.SubscriptionID.AMPD_MANAGEMENT_12_MONTH
];

const CHANGE_PLAN_SUBSCRIPTION_OPTIONS: Array<AmpdSubscription.SubscriptionID> = [
  AmpdSubscription.SubscriptionID.AMPD_CONNECT_3_MONTH,
  AmpdSubscription.SubscriptionID.AMPD_AUTOMATION_3_MONTH,
  AmpdSubscription.SubscriptionID.AMPD_MANAGEMENT_3_MONTH
];

export const SelfServePricing = ({
  currentSite,
  user,
  ampdSubscriptions
}: {
  currentSite: Site;
  user: User;
  ampdSubscriptions: Array<AmpdSubscription.AsObject>;
}): JSX.Element => {
  const { siteAlias } = currentSite;

  const [
    selectedSubscription,
    setSelectedSubscription
  ] = useState<AmpdSubscription.AsObject | null>(null);

  const currentSiteSubscription = currentSite.subscription;
  const [nextInvoicePreviewOpen, setNextInvoicePreviewOpen] = useState(false);
  const hasStripePlan = hasActiveStripePlan(currentSite);

  // Display these when the user doesn't have an active Stripe plan yet.
  const newSignUpOptions = SELF_SERVE_SUBSCRIPTION_OPTIONS.map(id => {
    const targetSubscription = ampdSubscriptions.find(
      s => s.subscriptionId === id
    );

    if (!targetSubscription) {
      return <></>;
    }

    const subtitle = SUBTITLE_BY_GROUP[targetSubscription.groupName];
    const featuresList = FEATURES_BY_GROUP[targetSubscription.groupName];
    return (
      <AmpdSubscriptionDisplay
        key={targetSubscription.subscriptionId}
        onSelectSubscription={setSelectedSubscription}
        subtitle={subtitle}
        disabled
        targetSubscription={targetSubscription}
        sitesCurrentSubscription={currentSiteSubscription}
        features={featuresList}
      />
    );
  }).filter(Boolean);

  // Display these when the user already has an active stripe plan.
  const changePlanOptions =
    !!currentSiteSubscription &&
    CHANGE_PLAN_SUBSCRIPTION_OPTIONS.map(newSubId => {
      const targetSubscription = ampdSubscriptions.find(
        s => s.subscriptionId === newSubId
      );

      if (!targetSubscription) {
        return <></>;
      }

      // Try to upgrade to a subscription with the same term length as the current subscription. This
      // won't always be possible, so fall back to plan in CHANGE_PLAN_SUBSCRIPTION_OPTIONS.
      const inTargetGroupWithSameTerms = ampdSubscriptions.find(s => {
        return (
          s.groupName === targetSubscription.groupName &&
          s.minimumBillingIntervals ===
            currentSiteSubscription.minimumBillingIntervals &&
          s.billingIntervalMonths ===
            currentSiteSubscription.billingIntervalMonths
        );
      });

      const subtitle = SUBTITLE_BY_GROUP[targetSubscription.groupName];
      const featuresList = FEATURES_BY_GROUP[targetSubscription.groupName];
      return (
        <AmpdSubscriptionDisplay
          key={targetSubscription.subscriptionId}
          onSelectSubscription={setSelectedSubscription}
          subtitle={subtitle}
          targetSubscription={inTargetGroupWithSameTerms ?? targetSubscription}
          sitesCurrentSubscription={currentSiteSubscription}
          features={featuresList}
        />
      );
    }).filter(Boolean);

  return (
    <>
      {selectedSubscription && hasStripePlan && currentSiteSubscription && (
        <ChangePlanConfirmationModal
          currentSite={currentSite}
          user={user}
          onClose={() => setSelectedSubscription(null)}
          currentSiteSubscription={currentSiteSubscription}
          targetSubscription={selectedSubscription}
        />
      )}

      {selectedSubscription && !hasStripePlan ? (
        <div style={{ margin: "1%" }}>
          <ProductCheckoutScreen
            initialSubscription={selectedSubscription}
            siteAlias={siteAlias}
            userEmail={user.userEmail}
            userName={user.userName}
            onClose={() => setSelectedSubscription(null)}
            ampdSubscriptions={ampdSubscriptions}
            skipOnboardingFeeForSubscriptionId={null}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            gap: "1.5em",
            height: "93vh",
            overflowY: "scroll",
            padding: "2em"
          }}
        >
          {hasStripePlan && (
            <Accordion
              fluid
              styled
              style={{ width: "50%", margin: "0 auto", minWidth: "30em" }}
            >
              <Accordion.Title
                active={true}
                index={0}
                onClick={() => setNextInvoicePreviewOpen(isOpen => !isOpen)}
              >
                <Icon name="dropdown" />
                View upcoming invoice
              </Accordion.Title>
              <Accordion.Content active={nextInvoicePreviewOpen}>
                <NextInvoice />
              </Accordion.Content>
            </Accordion>
          )}

          <>
            <PlansContainer>
              {hasStripePlan ? changePlanOptions : newSignUpOptions}
            </PlansContainer>
          </>
        </div>
      )}
    </>
  );
};
