import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetSiteWalmartProfileRequest,
  GetSiteWalmartProfileReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { Walmart } from "Common/proto/common/walmart_pb";

export const fetchWalmartProfile = async (
  siteAlias: string,
  marketplace: Walmart.Marketplace.Option,
  profileName: string
): Promise<GetSiteWalmartProfileReply.AsObject> => {
  const req = new GetSiteWalmartProfileRequest();
  req.setSiteAlias(siteAlias);
  req.setMarketplace(marketplace);
  req.setProfileName(profileName);

  const reply = await GRPCWebClient.getSiteWalmartProfile(req, {});
  return reply.toObject();
};

export const fetchWalmartProfileQueryKey = (
  siteAlias: string,
  marketplace: Walmart.Marketplace.Option,
  profileName: string
): Array<string | number> => {
  return ["walmartBrand", siteAlias, marketplace, profileName];
};

// Returns the brand details for the specified Walmart brand id.
export const useWalmartProfile = (
  siteAlias: string,
  marketplace: Walmart.Marketplace.Option,
  profileName: string
): UseQueryResult<GetSiteWalmartProfileReply.AsObject, unknown> => {
  const oneHour = 1000 * 60 * 60; //one hour
  return useQuery({
    queryKey: fetchWalmartProfileQueryKey(siteAlias, marketplace, profileName),
    staleTime: oneHour,
    cacheTime: oneHour,
    enabled: !!siteAlias && !!profileName,
    queryFn: async () =>
      fetchWalmartProfile(siteAlias, marketplace, profileName)
  });
};
