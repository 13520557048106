// source: proto/edge/grpcwebPb/grpcweb_Amazon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_amazon_pb = require('../../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
goog.exportSymbol('proto.grpcwebPb.CollectAmazonProductInfoReply', null, global);
goog.exportSymbol('proto.grpcwebPb.CollectAmazonProductInfoRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonAdvertisingProfilesReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonAttributionTagsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonAttributionTagsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonKeywordSuggestionsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonProductReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonProductRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonSearchTermRankingReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonSearchTermRankingRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonSponsoredProductsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAmazonSponsoredProductsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CollectAmazonProductInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CollectAmazonProductInfoRequest.displayName = 'proto.grpcwebPb.CollectAmazonProductInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CollectAmazonProductInfoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CollectAmazonProductInfoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CollectAmazonProductInfoReply.displayName = 'proto.grpcwebPb.CollectAmazonProductInfoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonProductRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonProductRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonProductRequest.displayName = 'proto.grpcwebPb.GetAmazonProductRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonProductReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonProductReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonProductReply.displayName = 'proto.grpcwebPb.GetAmazonProductReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmazonSearchTermRankingRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonSearchTermRankingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonSearchTermRankingRequest.displayName = 'proto.grpcwebPb.GetAmazonSearchTermRankingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonSearchTermRankingReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonSearchTermRankingReply.displayName = 'proto.grpcwebPb.GetAmazonSearchTermRankingReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.displayName = 'proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonKeywordSuggestionsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.displayName = 'proto.grpcwebPb.GetAmazonKeywordSuggestionsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonSponsoredProductsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonSponsoredProductsRequest.displayName = 'proto.grpcwebPb.GetAmazonSponsoredProductsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmazonSponsoredProductsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonSponsoredProductsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonSponsoredProductsReply.displayName = 'proto.grpcwebPb.GetAmazonSponsoredProductsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.displayName = 'proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.displayName = 'proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonAdvertisingProfilesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.displayName = 'proto.grpcwebPb.GetAmazonAdvertisingProfilesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonAttributionTagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonAttributionTagsRequest.displayName = 'proto.grpcwebPb.GetAmazonAttributionTagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAmazonAttributionTagsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAmazonAttributionTagsReply.displayName = 'proto.grpcwebPb.GetAmazonAttributionTagsReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CollectAmazonProductInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CollectAmazonProductInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoRequest}
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CollectAmazonProductInfoRequest;
  return proto.grpcwebPb.CollectAmazonProductInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CollectAmazonProductInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoRequest}
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CollectAmazonProductInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CollectAmazonProductInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoRequest} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoRequest} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CollectAmazonProductInfoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CollectAmazonProductInfoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keywords: jspb.Message.getFieldWithDefault(msg, 4, ""),
    image: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CollectAmazonProductInfoReply;
  return proto.grpcwebPb.CollectAmazonProductInfoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CollectAmazonProductInfoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywords(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CollectAmazonProductInfoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CollectAmazonProductInfoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeywords();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keywords = 4;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.getKeywords = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.setKeywords = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CollectAmazonProductInfoReply} returns this
 */
proto.grpcwebPb.CollectAmazonProductInfoReply.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonProductRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonProductRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonProductRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expanded: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    asin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonProductRequest}
 */
proto.grpcwebPb.GetAmazonProductRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonProductRequest;
  return proto.grpcwebPb.GetAmazonProductRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonProductRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonProductRequest}
 */
proto.grpcwebPb.GetAmazonProductRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpanded(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 5:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonProductRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonProductRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonProductRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpanded();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonProductRequest} returns this
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonProductRequest} returns this
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool expanded = 3;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.getExpanded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmazonProductRequest} returns this
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.setExpanded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string asin = 4;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonProductRequest} returns this
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.Amazon.Marketplace.Option marketplace = 5;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetAmazonProductRequest} returns this
 */
proto.grpcwebPb.GetAmazonProductRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonProductReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonProductReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonProductReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonProductReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && proto_common_amazon_pb.Amazon.Product.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonProductReply}
 */
proto.grpcwebPb.GetAmazonProductReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonProductReply;
  return proto.grpcwebPb.GetAmazonProductReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonProductReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonProductReply}
 */
proto.grpcwebPb.GetAmazonProductReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.Product;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonProductReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonProductReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonProductReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonProductReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Amazon.Product product = 1;
 * @return {?proto.common.Amazon.Product}
 */
proto.grpcwebPb.GetAmazonProductReply.prototype.getProduct = function() {
  return /** @type{?proto.common.Amazon.Product} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.Product, 1));
};


/**
 * @param {?proto.common.Amazon.Product|undefined} value
 * @return {!proto.grpcwebPb.GetAmazonProductReply} returns this
*/
proto.grpcwebPb.GetAmazonProductReply.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAmazonProductReply} returns this
 */
proto.grpcwebPb.GetAmazonProductReply.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonProductReply.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonSearchTermRankingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    asin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 4, ""),
    variantAsinsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonSearchTermRankingRequest;
  return proto.grpcwebPb.GetAmazonSearchTermRankingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addVariantAsins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonSearchTermRankingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVariantAsinsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Marketplace.Option marketplace = 2;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string asin = 3;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string search_term = 4;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string variant_asins = 5;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.getVariantAsinsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.setVariantAsinsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.addVariantAsins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingRequest} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingRequest.prototype.clearVariantAsinsList = function() {
  return this.setVariantAsinsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonSearchTermRankingReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    allVariantsPosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allVariantsPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxPosition: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxPage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonSearchTermRankingReply;
  return proto.grpcwebPb.GetAmazonSearchTermRankingReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllVariantsPosition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllVariantsPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPosition(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonSearchTermRankingReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAllVariantsPosition();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAllVariantsPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMaxPosition();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxPage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 all_variants_position = 3;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.getAllVariantsPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.setAllVariantsPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 all_variants_page = 4;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.getAllVariantsPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.setAllVariantsPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 max_position = 5;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.getMaxPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.setMaxPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_page = 6;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.getMaxPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonSearchTermRankingReply} returns this
 */
proto.grpcwebPb.GetAmazonSearchTermRankingReply.prototype.setMaxPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    asinsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest;
  return proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAsins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAsinsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Marketplace.Option marketplace = 2;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string asins = 3;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.getAsinsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.setAsinsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.addAsins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsRequest.prototype.clearAsinsList = function() {
  return this.setAsinsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    keywordsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonKeywordSuggestionsReply;
  return proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string keywords = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.prototype.getKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.prototype.setKeywordsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.prototype.addKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmazonKeywordSuggestionsReply} returns this
 */
proto.grpcwebPb.GetAmazonKeywordSuggestionsReply.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonSponsoredProductsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileIdStr: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonSponsoredProductsRequest;
  return proto.grpcwebPb.GetAmazonSponsoredProductsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonSponsoredProductsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profile_id_str = 2;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.prototype.getProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsRequest} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsRequest.prototype.setProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonSponsoredProductsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplacesAndAsinsList: jspb.Message.toObjectList(msg.getMarketplacesAndAsinsList(),
    proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonSponsoredProductsReply;
  return proto.grpcwebPb.GetAmazonSponsoredProductsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins;
      reader.readMessage(value,proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.deserializeBinaryFromReader);
      msg.addMarketplacesAndAsins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonSponsoredProductsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplacesAndAsinsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    asinsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins;
  return proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAsins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAsinsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional common.Amazon.Marketplace.Option marketplace = 1;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string asins = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.getAsinsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.setAsinsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.addAsins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins.prototype.clearAsinsList = function() {
  return this.setAsinsList([]);
};


/**
 * repeated MarketplaceAndAsins marketplaces_and_asins = 1;
 * @return {!Array<!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins>}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.prototype.getMarketplacesAndAsinsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins>} value
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply} returns this
*/
proto.grpcwebPb.GetAmazonSponsoredProductsReply.prototype.setMarketplacesAndAsinsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins}
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.prototype.addMarketplacesAndAsins = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.GetAmazonSponsoredProductsReply.MarketplaceAndAsins, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmazonSponsoredProductsReply} returns this
 */
proto.grpcwebPb.GetAmazonSponsoredProductsReply.prototype.clearMarketplacesAndAsinsList = function() {
  return this.setMarketplacesAndAsinsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeAdvertisers: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    alwaysQueryFromAmazon: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    dontUpdateSite: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest;
  return proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAdvertisers(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysQueryFromAmazon(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDontUpdateSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeAdvertisers();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAlwaysQueryFromAmazon();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDontUpdateSite();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} returns this
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_advertisers = 2;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.getIncludeAdvertisers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} returns this
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.setIncludeAdvertisers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool always_query_from_amazon = 3;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.getAlwaysQueryFromAmazon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} returns this
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.setAlwaysQueryFromAmazon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool dont_update_site = 4;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.getDontUpdateSite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest} returns this
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesRequest.prototype.setDontUpdateSite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    proto_common_amazon_pb.Amazon.AdvertisingProfile.toObject, includeInstance),
    siteUpdated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonAdvertisingProfilesReply;
  return proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfile;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSiteUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfile.serializeBinaryToWriter
    );
  }
  f = message.getSiteUpdated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated common.Amazon.AdvertisingProfile profiles = 1;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfile>}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfile, 1));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfile>} value
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply} returns this
*/
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Amazon.AdvertisingProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply} returns this
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};


/**
 * optional bool site_updated = 2;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.getSiteUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmazonAdvertisingProfilesReply} returns this
 */
proto.grpcwebPb.GetAmazonAdvertisingProfilesReply.prototype.setSiteUpdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonAttributionTagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profileId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    profileIdStr: jspb.Message.getFieldWithDefault(msg, 8, ""),
    advertiserId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    advertiserIdStr: jspb.Message.getFieldWithDefault(msg, 9, ""),
    publisher: jspb.Message.getFieldWithDefault(msg, 5, 0),
    campaignName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adGroupName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stripIdsFromNames: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    campaignId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonAttributionTagsRequest;
  return proto.grpcwebPb.GetAmazonAttributionTagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfileId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIdStr(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdvertiserId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertiserIdStr(value);
      break;
    case 5:
      var value = /** @type {!proto.common.Amazon.ExternalAdPublisher.Option} */ (reader.readEnum());
      msg.setPublisher(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupName(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStripIdsFromNames(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonAttributionTagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProfileId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdvertiserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPublisher();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCampaignName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdGroupName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStripIdsFromNames();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Region.Option region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 profile_id = 3;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string profile_id_str = 8;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 advertiser_id = 4;
 * @return {number}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getAdvertiserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setAdvertiserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string advertiser_id_str = 9;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional common.Amazon.ExternalAdPublisher.Option publisher = 5;
 * @return {!proto.common.Amazon.ExternalAdPublisher.Option}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getPublisher = function() {
  return /** @type {!proto.common.Amazon.ExternalAdPublisher.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.Amazon.ExternalAdPublisher.Option} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setPublisher = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string campaign_name = 6;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ad_group_name = 7;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getAdGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setAdGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool strip_ids_from_names = 10;
 * @return {boolean}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getStripIdsFromNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setStripIdsFromNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string campaign_id = 11;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ad_group_id = 12;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsRequest} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsRequest.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAmazonAttributionTagsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAmazonAttributionTagsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    tags: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsReply}
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAmazonAttributionTagsReply;
  return proto.grpcwebPb.GetAmazonAttributionTagsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAmazonAttributionTagsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsReply}
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAmazonAttributionTagsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAmazonAttributionTagsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTags();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tags = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.prototype.getTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAmazonAttributionTagsReply} returns this
 */
proto.grpcwebPb.GetAmazonAttributionTagsReply.prototype.setTags = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.grpcwebPb);
