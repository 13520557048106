import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetAmazonSponsoredProductsReply,
  GetAmazonSponsoredProductsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Amazon_pb";

export const useSponsoredProducts = (
  siteAlias: string,
  profileIdStr: string
): UseQueryResult<GetAmazonSponsoredProductsReply.AsObject, unknown> => {
  return useQuery({
    queryKey: ["sponsoredAds", siteAlias, profileIdStr],
    staleTime: 5 * 60 * 1_000, // 5 minutes
    queryFn: async () => {
      const req = new GetAmazonSponsoredProductsRequest();
      req.setSiteAlias(siteAlias);
      req.setProfileIdStr(profileIdStr);

      const reply = await GRPCWebClient.getAmazonSponsoredProducts(req, {});
      return reply.toObject();
    }
  });
};
