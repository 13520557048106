import Immutable from "immutable";
import moment from "moment";

import ApolloClient, { ApolloQueryResult } from "apollo-client";
import {
  CAMPAIGN_KEYWORD_METRICS_QUERY,
  CAMPAIGN_KEYWORDS_QUERY,
  CAMPAIGN_METRICS_QUERY,
  CAMPAIGNS_AND_BUDGETS_QUERY
} from "../graphql";

import { GetCreatedGoogleAdsCampaignsReply } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import {
  AdwordsObjectType,
  BasicMetrics,
  Budget,
  Campaign,
  Keyword,
  Query,
  QueryAdwordsMetricsArgs,
  QueryAdwordsObjectsArgs,
  Status
} from "../../graphql/graphql";
import { removeNullAndUndefined } from "Common/utils/tsUtils";

interface CampaignInfo
  extends GetCreatedGoogleAdsCampaignsReply.CampaignInfo.AsObject {
  siteAlias: string;
}

interface CampaignWithBudget extends Campaign {
  budget?: Budget;
}

// ESLint doesn't like the use of 'object', but Apollo's return value for
// useApolloClient uses it as a parameter.
// eslint-disable-next-line @typescript-eslint/ban-types
type ApolloClientType = ApolloClient<object>;

// Queries for all of google ads campaign objects for the specified
// site alias.
// This query does not use the GraphQL cache, so it will always be made anew and
// will not fill up the cache.  It is intended mainly for non-central use that won't
// hold up the primary UI.
export async function queryGoogleAdsCampaignObjects(
  apolloClient: ApolloClientType,
  siteAlias: string,
  campaignIds: Array<string>
): Promise<Immutable.Map<string, CampaignWithBudget>> {
  return apolloClient
    .query<Query, QueryAdwordsObjectsArgs>({
      query: CAMPAIGNS_AND_BUDGETS_QUERY,
      fetchPolicy: "no-cache",
      variables: {
        site: { siteAlias },
        endDate: moment()
          .endOf("day")
          .format("YYYY-MM-DD"),
        numWeeks: 8,
        campaignIds: campaignIds,
        campaignStatuses: [Status.Enabled, Status.Paused],
        queryId: `report campaigns`
      }
    })
    .then(({ data }: ApolloQueryResult<Query>) => {
      const adwordsObjects = data?.adwordsObjects;

      let campaignByIdMap = Immutable.Map<string, CampaignWithBudget>();

      for (const campaign of adwordsObjects?.campaigns || []) {
        if (!campaign?.campaignId) {
          continue;
        }

        const campaignWithBudget: CampaignWithBudget = campaign;

        for (const budget of adwordsObjects?.budgets || []) {
          if (!budget) {
            continue;
          }
          if (campaignWithBudget.budgetId === budget.budgetId) {
            campaignWithBudget.budget = budget;
          }
        }

        campaignByIdMap = campaignByIdMap.set(
          campaign.campaignId,
          campaignWithBudget
        );
      }

      return campaignByIdMap;
    })
    .catch(err => {
      console.error(err);
      return Immutable.Map();
    });
}

// Queries for all of google ads campaign metrics for the specified
// site alias over the specified date range.
// This query does not use the GraphQL cache, so it will always be made anew and
// will not fill up the cache.  It is intended mainly for non-central use that won't
// hold up the primary UI.
export async function queryGoogleAdsCampaignMetrics(
  apolloClient: ApolloClientType,
  siteAlias: string,
  startDate: string,
  endDate: string,
  queryByDay: boolean,
  campaignIds: Array<string>
): Promise<{
  siteMetricsMap: Immutable.Map<string, Array<BasicMetrics>>;
  error?: string;
}> {
  return apolloClient
    .query<Query, QueryAdwordsMetricsArgs>({
      query: CAMPAIGN_METRICS_QUERY,
      fetchPolicy: "no-cache",
      variables: {
        site: { siteAlias },
        startDate: startDate,
        endDate: endDate,
        objectType: AdwordsObjectType.Campaign,
        campaignIds: campaignIds,
        queryId: `report campaign metrics:${
          queryByDay ? "by day" : "for range"
        }`,
        includeAmazonAttributionData: true,
        queryByDay: queryByDay
      }
    })
    .then(({ data }: ApolloQueryResult<Query>) => {
      const metricsList = data?.adwordsMetrics?.basicMetrics;

      let metricsMap = Immutable.Map<string, Array<BasicMetrics>>();

      for (const metrics of metricsList || []) {
        if (!metrics?.campaignId) {
          continue;
        }

        let campaignMetricsForCampaign = metricsMap.get(metrics.campaignId);
        if (!campaignMetricsForCampaign) {
          campaignMetricsForCampaign = [];
          metricsMap = metricsMap.set(
            metrics.campaignId,
            campaignMetricsForCampaign
          );
        }

        campaignMetricsForCampaign.push(metrics);
      }

      return { siteMetricsMap: metricsMap };
    })
    .catch(err => {
      console.error(err);
      return { siteMetricsMap: Immutable.Map(), error: err };
    });
}

// Queries for all of google ads keyword objects for the specified
// site alias.
// This query does not use the GraphQL cache, so it will always be made anew and
// will not fill up the cache.  It is intended mainly for non-central use that won't
// hold up the primary UI.
export async function queryGoogleAdsCampaignKeywordObjects(
  apolloClient: ApolloClientType,
  siteAlias: string,
  campaignInfoList: Array<CampaignInfo>
): Promise<Immutable.Map<string, Array<Keyword>>> {
  // Collect campaign IDs that belong to the site.
  const campaignIds: Array<string> = campaignInfoList
    .map(campaignInfo =>
      campaignInfo?.siteAlias === siteAlias
        ? String(campaignInfo.campaignId)
        : null
    )
    .filter(removeNullAndUndefined);

  return apolloClient
    .query<Query, QueryAdwordsObjectsArgs>({
      query: CAMPAIGN_KEYWORDS_QUERY,
      fetchPolicy: "no-cache",
      variables: {
        site: { siteAlias },
        endDate: moment()
          .endOf("day")
          .format("YYYY-MM-DD"),
        numWeeks: 8,
        campaignIds: campaignIds,
        campaignStatuses: [Status.Enabled, Status.Paused],
        adGroupStatuses: [Status.Enabled, Status.Paused],
        criteriaStatuses: [Status.Enabled, Status.Paused],
        queryId: `report campaign keywords`
      }
    })
    .then(({ data }: ApolloQueryResult<Query>) => {
      const adwordsObjects = data?.adwordsObjects;

      let objectsMap = Immutable.Map<string, Array<Keyword>>();

      for (const campaign of adwordsObjects?.campaigns || []) {
        if (!campaign?.campaignId) {
          continue;
        }

        let campaignKeywords = objectsMap.get(campaign.campaignId);
        if (!campaignKeywords) {
          campaignKeywords = [];
          objectsMap = objectsMap.set(campaign.campaignId, campaignKeywords);
        }

        for (const adGroup of campaign.adGroups || []) {
          for (const keyword of adGroup?.positiveKeywords || []) {
            if (keyword) {
              campaignKeywords.push(keyword);
            }
          }
        }
      }

      return objectsMap;
    })
    .catch(err => {
      console.error(err);
      return Immutable.Map();
    });
}

// Queries for all of google ads keyword metrics for the specified
// site alias and campaign IDs over the specified date range.
// This query does not use the GraphQL cache, so it will always be made anew and
// will not fill up the cache.  It is intended mainly for non-central use that won't
// hold up the primary UI.
export async function queryGoogleAdsCampaignKeywordMetrics(
  apolloClient: ApolloClientType,
  siteAlias: string,
  startDate: string,
  endDate: string,
  queryByDay: boolean,
  campaignInfoList: Array<CampaignInfo>
): Promise<Immutable.Map<string, Array<BasicMetrics>>> {
  // Collect campaign IDs that belong to the site.
  const campaignIds = campaignInfoList
    .map(campaignInfo =>
      campaignInfo?.siteAlias === siteAlias
        ? String(campaignInfo.campaignId)
        : null
    )
    .filter(removeNullAndUndefined);

  return apolloClient
    .query<Query, QueryAdwordsMetricsArgs>({
      query: CAMPAIGN_KEYWORD_METRICS_QUERY,
      fetchPolicy: "no-cache",
      variables: {
        site: { siteAlias },
        startDate: startDate,
        endDate: endDate,
        campaignIds: campaignIds,
        objectType: AdwordsObjectType.Criteria,
        queryId: `report keyword metrics:${
          queryByDay ? "by day" : "for range"
        }`,
        includeAmazonAttributionData: true,
        queryByDay: queryByDay
      }
    })
    .then(({ data }: ApolloQueryResult<Query>) => {
      const basicMetricsList = data?.adwordsMetrics?.basicMetrics;

      let metricsMap = Immutable.Map<string, Array<BasicMetrics>>();

      for (const metrics of basicMetricsList || []) {
        if (!metrics?.campaignId) {
          continue;
        }

        let keywordMetricsForCampaign = metricsMap.get(metrics.campaignId);
        if (!keywordMetricsForCampaign) {
          keywordMetricsForCampaign = [];
          metricsMap = metricsMap.set(
            metrics.campaignId,
            keywordMetricsForCampaign
          );
        }

        keywordMetricsForCampaign.push(metrics);
      }

      return metricsMap;
    })
    .catch(err => {
      console.error(err);
      return Immutable.Map();
    });
}
