// source: google/ads/googleads/v16/resources/change_event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_enums_change_client_type_pb = require('../../../../../google/ads/googleads/v16/enums/change_client_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_change_client_type_pb);
var google_ads_googleads_v16_enums_change_event_resource_type_pb = require('../../../../../google/ads/googleads/v16/enums/change_event_resource_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_change_event_resource_type_pb);
var google_ads_googleads_v16_enums_resource_change_operation_pb = require('../../../../../google/ads/googleads/v16/enums/resource_change_operation_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_resource_change_operation_pb);
var google_ads_googleads_v16_resources_ad_pb = require('../../../../../google/ads/googleads/v16/resources/ad_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_pb);
var google_ads_googleads_v16_resources_ad_group_pb = require('../../../../../google/ads/googleads/v16/resources/ad_group_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_pb);
var google_ads_googleads_v16_resources_ad_group_ad_pb = require('../../../../../google/ads/googleads/v16/resources/ad_group_ad_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_ad_pb);
var google_ads_googleads_v16_resources_ad_group_asset_pb = require('../../../../../google/ads/googleads/v16/resources/ad_group_asset_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_asset_pb);
var google_ads_googleads_v16_resources_ad_group_bid_modifier_pb = require('../../../../../google/ads/googleads/v16/resources/ad_group_bid_modifier_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_bid_modifier_pb);
var google_ads_googleads_v16_resources_ad_group_criterion_pb = require('../../../../../google/ads/googleads/v16/resources/ad_group_criterion_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_criterion_pb);
var google_ads_googleads_v16_resources_ad_group_feed_pb = require('../../../../../google/ads/googleads/v16/resources/ad_group_feed_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_feed_pb);
var google_ads_googleads_v16_resources_asset_pb = require('../../../../../google/ads/googleads/v16/resources/asset_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_asset_pb);
var google_ads_googleads_v16_resources_asset_set_pb = require('../../../../../google/ads/googleads/v16/resources/asset_set_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_asset_set_pb);
var google_ads_googleads_v16_resources_asset_set_asset_pb = require('../../../../../google/ads/googleads/v16/resources/asset_set_asset_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_asset_set_asset_pb);
var google_ads_googleads_v16_resources_campaign_pb = require('../../../../../google/ads/googleads/v16/resources/campaign_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_pb);
var google_ads_googleads_v16_resources_campaign_asset_pb = require('../../../../../google/ads/googleads/v16/resources/campaign_asset_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_asset_pb);
var google_ads_googleads_v16_resources_campaign_asset_set_pb = require('../../../../../google/ads/googleads/v16/resources/campaign_asset_set_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_asset_set_pb);
var google_ads_googleads_v16_resources_campaign_budget_pb = require('../../../../../google/ads/googleads/v16/resources/campaign_budget_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_budget_pb);
var google_ads_googleads_v16_resources_campaign_criterion_pb = require('../../../../../google/ads/googleads/v16/resources/campaign_criterion_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_criterion_pb);
var google_ads_googleads_v16_resources_campaign_feed_pb = require('../../../../../google/ads/googleads/v16/resources/campaign_feed_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_feed_pb);
var google_ads_googleads_v16_resources_customer_asset_pb = require('../../../../../google/ads/googleads/v16/resources/customer_asset_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_customer_asset_pb);
var google_ads_googleads_v16_resources_feed_pb = require('../../../../../google/ads/googleads/v16/resources/feed_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_feed_pb);
var google_ads_googleads_v16_resources_feed_item_pb = require('../../../../../google/ads/googleads/v16/resources/feed_item_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_feed_item_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.ChangeEvent', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.ChangeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.ChangeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.ChangeEvent.displayName = 'proto.google.ads.googleads.v16.resources.ChangeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.displayName = 'proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.ChangeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.ChangeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changeDateTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changeResourceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changeResourceName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oldResource: (f = msg.getOldResource()) && proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.toObject(includeInstance, f),
    newResource: (f = msg.getNewResource()) && proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.toObject(includeInstance, f),
    resourceChangeOperation: jspb.Message.getFieldWithDefault(msg, 9, 0),
    changedFields: (f = msg.getChangedFields()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    campaign: jspb.Message.getFieldWithDefault(msg, 11, ""),
    adGroup: jspb.Message.getFieldWithDefault(msg, 12, ""),
    feed: jspb.Message.getFieldWithDefault(msg, 13, ""),
    feedItem: jspb.Message.getFieldWithDefault(msg, 14, ""),
    asset: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.ChangeEvent;
  return proto.google.ads.googleads.v16.resources.ChangeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.ChangeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeDateTime(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType} */ (reader.readEnum());
      msg.setChangeResourceType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeResourceName(value);
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.ChangeClientTypeEnum.ChangeClientType} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 7:
      var value = new proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.deserializeBinaryFromReader);
      msg.setOldResource(value);
      break;
    case 8:
      var value = new proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.deserializeBinaryFromReader);
      msg.setNewResource(value);
      break;
    case 9:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.ResourceChangeOperationEnum.ResourceChangeOperation} */ (reader.readEnum());
      msg.setResourceChangeOperation(value);
      break;
    case 10:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setChangedFields(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroup(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeed(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedItem(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.ChangeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.ChangeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangeDateTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangeResourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getChangeResourceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOldResource();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.serializeBinaryToWriter
    );
  }
  f = message.getNewResource();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.serializeBinaryToWriter
    );
  }
  f = message.getResourceChangeOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getChangedFields();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdGroup();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFeed();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFeedItem();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAsset();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    ad: (f = msg.getAd()) && google_ads_googleads_v16_resources_ad_pb.Ad.toObject(includeInstance, f),
    adGroup: (f = msg.getAdGroup()) && google_ads_googleads_v16_resources_ad_group_pb.AdGroup.toObject(includeInstance, f),
    adGroupCriterion: (f = msg.getAdGroupCriterion()) && google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.toObject(includeInstance, f),
    campaign: (f = msg.getCampaign()) && google_ads_googleads_v16_resources_campaign_pb.Campaign.toObject(includeInstance, f),
    campaignBudget: (f = msg.getCampaignBudget()) && google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget.toObject(includeInstance, f),
    adGroupBidModifier: (f = msg.getAdGroupBidModifier()) && google_ads_googleads_v16_resources_ad_group_bid_modifier_pb.AdGroupBidModifier.toObject(includeInstance, f),
    campaignCriterion: (f = msg.getCampaignCriterion()) && google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion.toObject(includeInstance, f),
    feed: (f = msg.getFeed()) && google_ads_googleads_v16_resources_feed_pb.Feed.toObject(includeInstance, f),
    feedItem: (f = msg.getFeedItem()) && google_ads_googleads_v16_resources_feed_item_pb.FeedItem.toObject(includeInstance, f),
    campaignFeed: (f = msg.getCampaignFeed()) && google_ads_googleads_v16_resources_campaign_feed_pb.CampaignFeed.toObject(includeInstance, f),
    adGroupFeed: (f = msg.getAdGroupFeed()) && google_ads_googleads_v16_resources_ad_group_feed_pb.AdGroupFeed.toObject(includeInstance, f),
    adGroupAd: (f = msg.getAdGroupAd()) && google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd.toObject(includeInstance, f),
    asset: (f = msg.getAsset()) && google_ads_googleads_v16_resources_asset_pb.Asset.toObject(includeInstance, f),
    customerAsset: (f = msg.getCustomerAsset()) && google_ads_googleads_v16_resources_customer_asset_pb.CustomerAsset.toObject(includeInstance, f),
    campaignAsset: (f = msg.getCampaignAsset()) && google_ads_googleads_v16_resources_campaign_asset_pb.CampaignAsset.toObject(includeInstance, f),
    adGroupAsset: (f = msg.getAdGroupAsset()) && google_ads_googleads_v16_resources_ad_group_asset_pb.AdGroupAsset.toObject(includeInstance, f),
    assetSet: (f = msg.getAssetSet()) && google_ads_googleads_v16_resources_asset_set_pb.AssetSet.toObject(includeInstance, f),
    assetSetAsset: (f = msg.getAssetSetAsset()) && google_ads_googleads_v16_resources_asset_set_asset_pb.AssetSetAsset.toObject(includeInstance, f),
    campaignAssetSet: (f = msg.getCampaignAssetSet()) && google_ads_googleads_v16_resources_campaign_asset_set_pb.CampaignAssetSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource;
  return proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v16_resources_ad_pb.Ad;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_pb.Ad.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    case 2:
      var value = new google_ads_googleads_v16_resources_ad_group_pb.AdGroup;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_pb.AdGroup.deserializeBinaryFromReader);
      msg.setAdGroup(value);
      break;
    case 3:
      var value = new google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.deserializeBinaryFromReader);
      msg.setAdGroupCriterion(value);
      break;
    case 4:
      var value = new google_ads_googleads_v16_resources_campaign_pb.Campaign;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_pb.Campaign.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    case 5:
      var value = new google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget.deserializeBinaryFromReader);
      msg.setCampaignBudget(value);
      break;
    case 6:
      var value = new google_ads_googleads_v16_resources_ad_group_bid_modifier_pb.AdGroupBidModifier;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_bid_modifier_pb.AdGroupBidModifier.deserializeBinaryFromReader);
      msg.setAdGroupBidModifier(value);
      break;
    case 7:
      var value = new google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion.deserializeBinaryFromReader);
      msg.setCampaignCriterion(value);
      break;
    case 8:
      var value = new google_ads_googleads_v16_resources_feed_pb.Feed;
      reader.readMessage(value,google_ads_googleads_v16_resources_feed_pb.Feed.deserializeBinaryFromReader);
      msg.setFeed(value);
      break;
    case 9:
      var value = new google_ads_googleads_v16_resources_feed_item_pb.FeedItem;
      reader.readMessage(value,google_ads_googleads_v16_resources_feed_item_pb.FeedItem.deserializeBinaryFromReader);
      msg.setFeedItem(value);
      break;
    case 10:
      var value = new google_ads_googleads_v16_resources_campaign_feed_pb.CampaignFeed;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_feed_pb.CampaignFeed.deserializeBinaryFromReader);
      msg.setCampaignFeed(value);
      break;
    case 11:
      var value = new google_ads_googleads_v16_resources_ad_group_feed_pb.AdGroupFeed;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_feed_pb.AdGroupFeed.deserializeBinaryFromReader);
      msg.setAdGroupFeed(value);
      break;
    case 12:
      var value = new google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd.deserializeBinaryFromReader);
      msg.setAdGroupAd(value);
      break;
    case 13:
      var value = new google_ads_googleads_v16_resources_asset_pb.Asset;
      reader.readMessage(value,google_ads_googleads_v16_resources_asset_pb.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    case 14:
      var value = new google_ads_googleads_v16_resources_customer_asset_pb.CustomerAsset;
      reader.readMessage(value,google_ads_googleads_v16_resources_customer_asset_pb.CustomerAsset.deserializeBinaryFromReader);
      msg.setCustomerAsset(value);
      break;
    case 15:
      var value = new google_ads_googleads_v16_resources_campaign_asset_pb.CampaignAsset;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_asset_pb.CampaignAsset.deserializeBinaryFromReader);
      msg.setCampaignAsset(value);
      break;
    case 16:
      var value = new google_ads_googleads_v16_resources_ad_group_asset_pb.AdGroupAsset;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_asset_pb.AdGroupAsset.deserializeBinaryFromReader);
      msg.setAdGroupAsset(value);
      break;
    case 17:
      var value = new google_ads_googleads_v16_resources_asset_set_pb.AssetSet;
      reader.readMessage(value,google_ads_googleads_v16_resources_asset_set_pb.AssetSet.deserializeBinaryFromReader);
      msg.setAssetSet(value);
      break;
    case 18:
      var value = new google_ads_googleads_v16_resources_asset_set_asset_pb.AssetSetAsset;
      reader.readMessage(value,google_ads_googleads_v16_resources_asset_set_asset_pb.AssetSetAsset.deserializeBinaryFromReader);
      msg.setAssetSetAsset(value);
      break;
    case 19:
      var value = new google_ads_googleads_v16_resources_campaign_asset_set_pb.CampaignAssetSet;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_asset_set_pb.CampaignAssetSet.deserializeBinaryFromReader);
      msg.setCampaignAssetSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_ads_googleads_v16_resources_ad_pb.Ad.serializeBinaryToWriter
    );
  }
  f = message.getAdGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_ads_googleads_v16_resources_ad_group_pb.AdGroup.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupCriterion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion.serializeBinaryToWriter
    );
  }
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_ads_googleads_v16_resources_campaign_pb.Campaign.serializeBinaryToWriter
    );
  }
  f = message.getCampaignBudget();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupBidModifier();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v16_resources_ad_group_bid_modifier_pb.AdGroupBidModifier.serializeBinaryToWriter
    );
  }
  f = message.getCampaignCriterion();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion.serializeBinaryToWriter
    );
  }
  f = message.getFeed();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_ads_googleads_v16_resources_feed_pb.Feed.serializeBinaryToWriter
    );
  }
  f = message.getFeedItem();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_ads_googleads_v16_resources_feed_item_pb.FeedItem.serializeBinaryToWriter
    );
  }
  f = message.getCampaignFeed();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_ads_googleads_v16_resources_campaign_feed_pb.CampaignFeed.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupFeed();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_ads_googleads_v16_resources_ad_group_feed_pb.AdGroupFeed.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupAd();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd.serializeBinaryToWriter
    );
  }
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_ads_googleads_v16_resources_asset_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getCustomerAsset();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_ads_googleads_v16_resources_customer_asset_pb.CustomerAsset.serializeBinaryToWriter
    );
  }
  f = message.getCampaignAsset();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_ads_googleads_v16_resources_campaign_asset_pb.CampaignAsset.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupAsset();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_ads_googleads_v16_resources_ad_group_asset_pb.AdGroupAsset.serializeBinaryToWriter
    );
  }
  f = message.getAssetSet();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_ads_googleads_v16_resources_asset_set_pb.AssetSet.serializeBinaryToWriter
    );
  }
  f = message.getAssetSetAsset();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_ads_googleads_v16_resources_asset_set_asset_pb.AssetSetAsset.serializeBinaryToWriter
    );
  }
  f = message.getCampaignAssetSet();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_ads_googleads_v16_resources_campaign_asset_set_pb.CampaignAssetSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Ad ad = 1;
 * @return {?proto.google.ads.googleads.v16.resources.Ad}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAd = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Ad} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_pb.Ad, 1));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Ad|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdGroup ad_group = 2;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroup}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAdGroup = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroup} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_pb.AdGroup, 2));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroup|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAdGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAdGroup = function() {
  return this.setAdGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAdGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdGroupCriterion ad_group_criterion = 3;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroupCriterion}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAdGroupCriterion = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroupCriterion} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_criterion_pb.AdGroupCriterion, 3));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroupCriterion|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAdGroupCriterion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAdGroupCriterion = function() {
  return this.setAdGroupCriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAdGroupCriterion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Campaign campaign = 4;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCampaign = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_pb.Campaign, 4));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CampaignBudget campaign_budget = 5;
 * @return {?proto.google.ads.googleads.v16.resources.CampaignBudget}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCampaignBudget = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CampaignBudget} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget, 5));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CampaignBudget|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCampaignBudget = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCampaignBudget = function() {
  return this.setCampaignBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCampaignBudget = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AdGroupBidModifier ad_group_bid_modifier = 6;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroupBidModifier}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAdGroupBidModifier = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroupBidModifier} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_bid_modifier_pb.AdGroupBidModifier, 6));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroupBidModifier|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAdGroupBidModifier = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAdGroupBidModifier = function() {
  return this.setAdGroupBidModifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAdGroupBidModifier = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CampaignCriterion campaign_criterion = 7;
 * @return {?proto.google.ads.googleads.v16.resources.CampaignCriterion}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCampaignCriterion = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CampaignCriterion} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion, 7));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CampaignCriterion|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCampaignCriterion = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCampaignCriterion = function() {
  return this.setCampaignCriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCampaignCriterion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Feed feed = 8;
 * @return {?proto.google.ads.googleads.v16.resources.Feed}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getFeed = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Feed} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_feed_pb.Feed, 8));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Feed|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setFeed = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearFeed = function() {
  return this.setFeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasFeed = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional FeedItem feed_item = 9;
 * @return {?proto.google.ads.googleads.v16.resources.FeedItem}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getFeedItem = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.FeedItem} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_feed_item_pb.FeedItem, 9));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.FeedItem|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setFeedItem = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearFeedItem = function() {
  return this.setFeedItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasFeedItem = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional CampaignFeed campaign_feed = 10;
 * @return {?proto.google.ads.googleads.v16.resources.CampaignFeed}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCampaignFeed = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CampaignFeed} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_feed_pb.CampaignFeed, 10));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CampaignFeed|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCampaignFeed = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCampaignFeed = function() {
  return this.setCampaignFeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCampaignFeed = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AdGroupFeed ad_group_feed = 11;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroupFeed}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAdGroupFeed = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroupFeed} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_feed_pb.AdGroupFeed, 11));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroupFeed|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAdGroupFeed = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAdGroupFeed = function() {
  return this.setAdGroupFeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAdGroupFeed = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AdGroupAd ad_group_ad = 12;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroupAd}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAdGroupAd = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroupAd} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd, 12));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroupAd|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAdGroupAd = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAdGroupAd = function() {
  return this.setAdGroupAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAdGroupAd = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Asset asset = 13;
 * @return {?proto.google.ads.googleads.v16.resources.Asset}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAsset = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Asset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_asset_pb.Asset, 13));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Asset|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional CustomerAsset customer_asset = 14;
 * @return {?proto.google.ads.googleads.v16.resources.CustomerAsset}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCustomerAsset = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CustomerAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_customer_asset_pb.CustomerAsset, 14));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CustomerAsset|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCustomerAsset = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCustomerAsset = function() {
  return this.setCustomerAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCustomerAsset = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional CampaignAsset campaign_asset = 15;
 * @return {?proto.google.ads.googleads.v16.resources.CampaignAsset}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCampaignAsset = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CampaignAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_asset_pb.CampaignAsset, 15));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CampaignAsset|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCampaignAsset = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCampaignAsset = function() {
  return this.setCampaignAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCampaignAsset = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional AdGroupAsset ad_group_asset = 16;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroupAsset}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAdGroupAsset = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroupAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_asset_pb.AdGroupAsset, 16));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroupAsset|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAdGroupAsset = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAdGroupAsset = function() {
  return this.setAdGroupAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAdGroupAsset = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional AssetSet asset_set = 17;
 * @return {?proto.google.ads.googleads.v16.resources.AssetSet}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAssetSet = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AssetSet} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_asset_set_pb.AssetSet, 17));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AssetSet|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAssetSet = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAssetSet = function() {
  return this.setAssetSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAssetSet = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional AssetSetAsset asset_set_asset = 18;
 * @return {?proto.google.ads.googleads.v16.resources.AssetSetAsset}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getAssetSetAsset = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AssetSetAsset} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_asset_set_asset_pb.AssetSetAsset, 18));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AssetSetAsset|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setAssetSetAsset = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearAssetSetAsset = function() {
  return this.setAssetSetAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasAssetSetAsset = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional CampaignAssetSet campaign_asset_set = 19;
 * @return {?proto.google.ads.googleads.v16.resources.CampaignAssetSet}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.getCampaignAssetSet = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CampaignAssetSet} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_asset_set_pb.CampaignAssetSet, 19));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CampaignAssetSet|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.setCampaignAssetSet = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.clearCampaignAssetSet = function() {
  return this.setCampaignAssetSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource.prototype.hasCampaignAssetSet = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string change_date_time = 2;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getChangeDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setChangeDateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.ads.googleads.v16.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType change_resource_type = 3;
 * @return {!proto.google.ads.googleads.v16.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getChangeResourceType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.ChangeEventResourceTypeEnum.ChangeEventResourceType} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setChangeResourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string change_resource_name = 4;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getChangeResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setChangeResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.ads.googleads.v16.enums.ChangeClientTypeEnum.ChangeClientType client_type = 5;
 * @return {!proto.google.ads.googleads.v16.enums.ChangeClientTypeEnum.ChangeClientType}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getClientType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.ChangeClientTypeEnum.ChangeClientType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.ChangeClientTypeEnum.ChangeClientType} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string user_email = 6;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ChangedResource old_resource = 7;
 * @return {?proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getOldResource = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource, 7));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setOldResource = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.clearOldResource = function() {
  return this.setOldResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.hasOldResource = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ChangedResource new_resource = 8;
 * @return {?proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getNewResource = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource, 8));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.ChangeEvent.ChangedResource|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setNewResource = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.clearNewResource = function() {
  return this.setNewResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.hasNewResource = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.ads.googleads.v16.enums.ResourceChangeOperationEnum.ResourceChangeOperation resource_change_operation = 9;
 * @return {!proto.google.ads.googleads.v16.enums.ResourceChangeOperationEnum.ResourceChangeOperation}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getResourceChangeOperation = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.ResourceChangeOperationEnum.ResourceChangeOperation} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.ResourceChangeOperationEnum.ResourceChangeOperation} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setResourceChangeOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.protobuf.FieldMask changed_fields = 10;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getChangedFields = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 10));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
*/
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setChangedFields = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.clearChangedFields = function() {
  return this.setChangedFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.hasChangedFields = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string campaign = 11;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ad_group = 12;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getAdGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setAdGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string feed = 13;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getFeed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setFeed = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string feed_item = 14;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getFeedItem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setFeedItem = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string asset = 20;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.getAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.ChangeEvent} returns this
 */
proto.google.ads.googleads.v16.resources.ChangeEvent.prototype.setAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


goog.object.extend(exports, proto.google.ads.googleads.v16.resources);
