import React, { useMemo, useState } from "react";
import { Dropdown, Loader, Message } from "semantic-ui-react";

import { Amazon } from "Common/proto/common/amazon_pb";

import {
  CampaignSetupAction,
  campaignSetupSelectors,
  CampaignSetupState
} from "./CampaignSetupPageState";
import {
  AddProductRowWrapper,
  ClickableText,
  CurrencyInputField,
  ProductRowInputs,
  ProductRowLabeledField,
  RowNumber,
  RemoveProductRow,
  SpacedUnwrappedRow,
  TightWrappingRow,
  SeedKeywordsSelector,
  SEED_KEYWORDS_COUNT
} from "ExtensionV2/pages/CampaignSetupPage/SelectProductsStage";

import { Site } from "ExtensionV2/queries/useSession";
import { useSponsoredProducts } from "ExtensionV2/queries/useSponsoredProducts";

import { MICROS_TO_CURRENCY_UNIT_FACTOR } from "Common/utils/money";
import { SuggestGoogleTargetingKeywords } from "./GoogleKeywordsField";
import { useAmazonProduct } from "ExtensionV2/queries/useAmazonProduct";
import { AttributionAdvertiser } from "Common/utils/amazon";

export const AmazonAsinSelectorDropdown = ({
  amazonAttributionAdvertiser,
  asin,
  onSelectAsin,
  onBlur,
  siteAlias
}: {
  amazonAttributionAdvertiser: AttributionAdvertiser | null;
  asin: string;
  onSelectAsin: (asin: string) => void;
  onBlur?: () => void;
  siteAlias: string;
}): React.ReactElement => {
  const amazonAttributionMarketplace =
    amazonAttributionAdvertiser?.marketplace ??
    Amazon.Marketplace.Option.UNKNOWN;
  const [searchTerm, setSearchTerm] = useState("");

  const [dropdownMenuAdditions, setDropdownMenuAdditions] = useState<
    Array<{ key: string; text: string; value: string }>
  >([]);

  const {
    data: sponsoredAdsData,
    isLoading: sponsoredAdsIsLoading
  } = useSponsoredProducts(
    siteAlias,
    amazonAttributionAdvertiser?.profileIdStr || ""
  );

  const asinDropdownOptions = useMemo(() => {
    const availableAsins = (
      sponsoredAdsData?.marketplacesAndAsinsList || []
    ).flatMap(asinInfo => {
      if (asinInfo.marketplace !== amazonAttributionMarketplace) {
        return [];
      }

      return asinInfo.asinsList.map(asin => {
        return {
          key: `${asin}-${asinInfo.marketplace}`,
          text: asin,
          value: asin
        };
      });
    });

    // if the user adds an ASIN not in the list we want to make sure its still available
    if (asin && !availableAsins.find(asinOption => asinOption.value === asin)) {
      availableAsins.push({
        key: `${asin}-user-provided`,
        text: asin,
        value: asin
      });
    }

    // The ASIN list can be long enough to really slow down this component (5000+ items). To get
    // around this, we only show the first ~100 items that match the search terms.
    const dropdownOptions = availableAsins
      .filter(asinOption => asinOption.text.includes(searchTerm.toUpperCase()))
      .slice(0, 100)
      .concat(dropdownMenuAdditions);

    return dropdownOptions;
  }, [
    asin,
    sponsoredAdsData?.marketplacesAndAsinsList,
    dropdownMenuAdditions,
    amazonAttributionMarketplace,
    searchTerm
  ]);

  const handleAsinAddition = (asin: string) => {
    setDropdownMenuAdditions([
      ...dropdownMenuAdditions,
      {
        key: `${asin}-${amazonAttributionMarketplace}`,
        text: asin,
        value: asin
      }
    ]);
  };

  const handleSelectAsin = (asin: string) => {
    onSelectAsin(asin);
  };

  const handleInputBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <>
      <Dropdown
        allowAdditions
        fluid
        loading={sponsoredAdsIsLoading}
        onChange={(_ev, data) => handleSelectAsin(String(data.value))}
        onSearchChange={(_ev, data) => setSearchTerm(data.searchQuery)}
        onAddItem={(_ev, data) => handleAsinAddition(String(data.value))}
        onBlur={handleInputBlur}
        options={asinDropdownOptions}
        placeholder={
          sponsoredAdsIsLoading
            ? "Getting your ASINs"
            : "Select or Enter an ASIN"
        }
        search
        selection
        value={asin}
      />
    </>
  );
};

export const SelectAmazonProductSection = ({
  campaignIndex,
  site,
  state,
  dispatch
}: {
  campaignIndex: number;
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
}): React.ReactElement => {
  const { siteAlias, adwordsAccounts } = site;
  const { currencyCode } = adwordsAccounts[0] || {};

  const {
    selectSeedKeywords,
    selectAsin,
    selectAttributionAdvertiser,
    selectBudgetMicros
  } = campaignSetupSelectors;

  const campaign = state.campaigns[campaignIndex];
  const asin = selectAsin(campaign);
  const amazonAttributionAdvertiser = selectAttributionAdvertiser(
    campaign,
    state
  );
  const seedKeywords = selectSeedKeywords(campaign);
  const budgetMicros = selectBudgetMicros(campaign);

  const attributionMarketplace =
    amazonAttributionAdvertiser?.marketplace ??
    Amazon.Marketplace.Option.UNKNOWN;

  const {
    data: amazonProductData,
    isFetching: amazonProductIsFetching,
    error: amazonProductError
  } = useAmazonProduct(siteAlias, asin, attributionMarketplace);

  const handleRemoveCampaign = () => {
    dispatch({
      name: "RemoveCampaign",
      data: campaignIndex
    });
  };

  const handleUpdateBudget = (campaignIndex: number, budgetInput: number) => {
    dispatch({
      name: "UpdateBudget",
      data: {
        index: campaignIndex,
        budgetMicros: budgetInput * MICROS_TO_CURRENCY_UNIT_FACTOR
      }
    });
  };

  return (
    <AddProductRowWrapper>
      <RowNumber>
        <p>{campaignIndex + 1}</p>
      </RowNumber>
      <ProductRowInputs>
        {amazonProductIsFetching && (
          <div>
            <p style={{ fontSize: "small", display: "inline-block" }}>
              Loading your product details
            </p>
            <Loader active inline size="tiny" style={{ margin: "0 1em" }} />
          </div>
        )}
        {!amazonProductIsFetching && amazonProductData?.product?.title && (
          <h5>{amazonProductData?.product?.title}</h5>
        )}
        <SpacedUnwrappedRow>
          <TightWrappingRow>
            <>
              <ProductRowLabeledField fieldWidth="14em">
                <AmazonAsinSelectorDropdown
                  amazonAttributionAdvertiser={amazonAttributionAdvertiser}
                  asin={asin}
                  onSelectAsin={asin => {
                    dispatch({
                      name: "UpdateAsin",
                      data: { index: campaignIndex, asin: asin }
                    });
                  }}
                  siteAlias={siteAlias}
                />
                <p>
                  <i>Select an ASIN</i>
                </p>
              </ProductRowLabeledField>
              {!!attributionMarketplace && (
                <ProductRowLabeledField>
                  <CurrencyInputField
                    adwordsCurrency={currencyCode}
                    budgetMicros={budgetMicros}
                    campaignIndex={campaignIndex}
                    handleUpdateBudget={handleUpdateBudget}
                    label="Daily budget"
                    invalid={campaign.budgetMicrosField.errors.size > 0}
                  />
                </ProductRowLabeledField>
              )}
              {asin && (
                <SeedKeywordsSelector
                  campaignIndex={campaignIndex}
                  state={state}
                  dispatch={dispatch}
                />
              )}

              {amazonProductError && (
                <div>
                  <Message
                    style={{ margin: "auto" }}
                    error
                    compact
                    content="There was a problem loading your product details. Please make sure you have entered a valid ASIN and try again."
                  />
                </div>
              )}
            </>
          </TightWrappingRow>
        </SpacedUnwrappedRow>

        {seedKeywords.length >= SEED_KEYWORDS_COUNT && (
          <SuggestGoogleTargetingKeywords
            campaignIndex={campaignIndex}
            site={site}
            state={state}
            dispatch={dispatch}
          />
        )}
      </ProductRowInputs>
      <RemoveProductRow onClick={handleRemoveCampaign}>
        <ClickableText>Remove Product</ClickableText>
      </RemoveProductRow>
    </AddProductRowWrapper>
  );
};

export default SelectAmazonProductSection;
