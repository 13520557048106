import {
  Navigate,
  useLocation,
  generatePath
} from "react-router-dom-v5-compat";

import React, { useContext, useMemo } from "react";
import { STRIPE_REDIRECT_STATUS_KEY } from "./stripe/StripeRedirectWrapper";
import { needsAmpdPayment } from "../../Common/utils/ampdPayment";
import { useSession } from "ExtensionV2/queries/useSession";
import { AMPD_ROOT, PAYMENT_PATH } from "ExtensionV2/ExtensionV2";
import { WantsDisabledPaywallContext } from "ExtensionV2";
import { checkIsAmpdProOperator } from "Common/utils/ampdProOperator";

const SETUP_PAGE_SEGMENT = "setup";

const RequirePayment = ({ children }) => {
  const [wantsDisabledPaywall] = useContext(WantsDisabledPaywallContext);
  const { currentSite, user } = useSession();
  const { billingAccountStatus, siteAlias } = currentSite || {};

  const { search, pathname } = useLocation();

  const shouldForcePaymentPageRedirect = useMemo(() => {
    const pathSegments = pathname.split("/");
    const isSetupPage =
      pathSegments[pathSegments.length - 1] === SETUP_PAGE_SEGMENT;
    // Users get redirected to the setup page immediately after submitting their payment info, so if
    // there are URL params indicating that Stripe is in the process of redirecting the user back to
    // the site, we should not bounce them to the payment page.
    if (isSetupPage) {
      const searchParams = new URLSearchParams(search);
      const stripeRedirectInProgress =
        searchParams.get(STRIPE_REDIRECT_STATUS_KEY) != null;
      if (stripeRedirectInProgress) {
        return false;
      }
    }

    // Ampd Operators can choose to access sites that do not have an active billing account.
    if (checkIsAmpdProOperator(user) && wantsDisabledPaywall) {
      return false;
    }

    return needsAmpdPayment(billingAccountStatus);
  }, [pathname, billingAccountStatus, search, user, wantsDisabledPaywall]);

  if (!siteAlias) {
    return null;
  }

  if (shouldForcePaymentPageRedirect) {
    return (
      <Navigate
        to={generatePath(`${AMPD_ROOT}/${PAYMENT_PATH}`, { siteAlias })}
        replace={true}
      />
    );
  }

  return children;
};

export default RequirePayment;
