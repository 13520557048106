import React from "react";
import styled from "styled-components";
import { GetCampaignConfigurationsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { GoogleAdsConfiguration } from "Common/proto/ampdPb/googleAdsConfiguration_pb";

const TableRowErrorMessage = styled.span`
  color: red;
  margin-bottom: 0;
`;

export const TableRowErrorContainer = styled.div`
  padding: 0.5em;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  margin: 0.5em;
`;

/* Possible reviewStatus values:
   UNSPECIFIED
   UNKNOWN
   REVIEW_IN_PROGRESS
   REVIEWED
   UNDER_APPEAL
   ELIGIBLE_MAY_SERVE */
export const AD_REVIEW_STATUS_MESSAGE: Partial<Record<
  GoogleAdsConfiguration.ReviewStatus.Option,
  string
>> = {
  [GoogleAdsConfiguration.ReviewStatus.Option.REVIEW_IN_PROGRESS]:
    "Review In Progress",
  [GoogleAdsConfiguration.ReviewStatus.Option.UNDER_APPEAL]: "Under appeal"
};

/* Possible approvalStatus values:
     UNSPECIFIED
     UNKNOWN
     DISAPPROVED
     APPROVED_LIMITED
     APPROVED
     AREA_OF_INTEREST_ONLY */
export const AD_APPROVAL_STATUS_MESSAGE: Partial<Record<
  GoogleAdsConfiguration.ApprovalStatus.Option,
  string
>> = {
  [GoogleAdsConfiguration.ApprovalStatus.Option.DISAPPROVED]: "Disapproved",
  [GoogleAdsConfiguration.ApprovalStatus.Option.APPROVED_LIMITED]: "Restricted",
  [GoogleAdsConfiguration.ApprovalStatus.Option.AREA_OF_INTEREST_ONLY]:
    "Area of interest only"
};

const CampaignsTableRowIssues: React.FC<{
  ampdCampaignConfiguration: GetCampaignConfigurationsReply.CampaignConfiguration.AsObject | null;
}> = ({ ampdCampaignConfiguration }) => {
  const adApprovalInfo = [];

  const googleAdsConfiguration =
    ampdCampaignConfiguration?.ampdResourceConfiguration?.googleAds;
  if (!googleAdsConfiguration) {
    return null;
  }

  for (const adGroupConfiguration of googleAdsConfiguration.adGroupConfigurationsList) {
    for (const adConfiguration of adGroupConfiguration.adConfigurationsList) {
      const approvalMessage =
        AD_APPROVAL_STATUS_MESSAGE[adConfiguration.approvalStatus];
      if (approvalMessage) {
        adApprovalInfo.push(
          <p key="ad-approval-status">
            Google Ad Status:{" "}
            <TableRowErrorMessage>{approvalMessage}</TableRowErrorMessage>
          </p>
        );
      }
      const reviewStatus =
        AD_REVIEW_STATUS_MESSAGE[adConfiguration.reviewStatus];
      if (reviewStatus) {
        adApprovalInfo.push(
          <p key="ad-review-status">
            Google Review Status:{" "}
            <TableRowErrorMessage>{reviewStatus}</TableRowErrorMessage>
          </p>
        );
      }

      if (adConfiguration.policyMessagesList.length > 0) {
        adApprovalInfo.push(
          <div key="ad-policy-messages">
            <p style={{ marginBottom: 0 }}>Reported Issues:</p>
            <ul style={{ margin: 0 }}>
              {adConfiguration.policyMessagesList.map(message => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          </div>
        );
      }
    }
  }

  if (!adApprovalInfo.length) {
    return null;
  }

  return <TableRowErrorContainer>{adApprovalInfo}</TableRowErrorContainer>;
};

export default CampaignsTableRowIssues;
