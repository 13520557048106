import { useEffect, useState } from "react";

// On 2/27/2023 Amazon unexpectedly changed their SDK so that Amazon.Login.Region went from
// being a string like "NA" to being an object like {code: "NA"}. This custom getter is to guard
// against them possibly changing the SDK back to the old format without announcing it.
export function getAmazonRegionCode(region) {
  return region?.code || region;
}

// Hook to indicate when the Amazon LWA is ready to use.
export default function useLoginWithAmazon() {
  const [ready, setReady] = useState(!!window.amazonLoginReady);

  // One-time setup of the Amazon LWA library.
  //
  // Adapted from
  // https://developer.amazon.com/docs/login-with-amazon/install-sdk-javascript.html.
  //
  // The isMounted approach is adapted from
  // https://stackoverflow.com/a/60907638/13241549.
  useEffect(() => {
    let isMounted = true;

    if (window.amazonLoginReady) {
      return;
    }

    if (!window.onAmazonLoginReadyCallbacks) {
      window.onAmazonLoginReadyCallbacks = [];
    }

    // Register callback to set state to true, but only if we're still
    // mounted.
    const callback = () => {
      if (isMounted) {
        setReady(true);
      }
    };

    window.onAmazonLoginReadyCallbacks.push(callback);

    // Ensure the window.onAmazonLoginReady() function is set.
    if (!window.onAmazonLoginReady) {
      window.onAmazonLoginReady = function() {
        // Set the client id.
        // eslint-disable-next-line no-undef
        amazon.Login.setClientId(process.env.REACT_APP_AMAZON_LWA_CLIENT_ID);

        // Mark that the login is ready so all future uses of useLoginWithAmazon()
        // automatically resolve.
        window.amazonLoginReady = true;

        // Trigger each of the callbacks.
        window.onAmazonLoginReadyCallbacks.forEach(callback => callback());
      };
    }

    if (!document.getElementById("amazon-root")) {
      const amazonRootDiv = document.createElement("div");
      amazonRootDiv.id = "amazon-root";
      document.body.appendChild(amazonRootDiv);

      (function(d) {
        const a = d.createElement("script");
        a.type = "text/javascript";
        a.async = true;
        a.id = "amazon-login-sdk";
        a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
        d.getElementById("amazon-root").appendChild(a);
      })(document);
    }

    // If we unmount the component, remove the callback.
    return () => (isMounted = false);
  }, []);

  return ready;
}
