import React from "react";
import AmpdDataTable from "./AmpdDataTable";

export type AmpdDataTableColumn<ColumnNameColumnDataMap> = {
  // The name of the column. Must be unique. Will match the key of the row object.
  name: keyof ColumnNameColumnDataMap;
  // The user-facing name of the column.
  displayName?: string;
  // The user-facing subtitle of the column.
  displaySubtitle?: string;
  // The width of the column. If no widths are provided, the browser column width algorithm will be
  // used.
  displayWidth?: number;
  // The minimum width of the column.
  displayMinWidth?: number;
  // The tooltip to show when hovering over the column header.
  tooltip?: string;
  // Freeze a column to the left of the table by providing the amount of left
  // offset. ex: "0px"
  freeze?: string;
  // The default sort direction for the column. If not provided, the default is "asc".
  sortDirection?: "asc" | "desc";
};

export type AmpdDataTableRow<ColumnNameColumnDataMap> = ColumnNameColumnDataMap;

export type AmpdDataTableSettings<ColumnNameColumnDataMap> = {
  // Which column to sort by by default.
  defaultSortColumn?: keyof ColumnNameColumnDataMap;
  // What to show when there is no data.
  emptyContent?: JSX.Element;
  // Map a data representation of a row to a component. If not provided, the default is to map each
  // column to a table cell containing the datum.
  mapDataRowToComponent?: (
    row: AmpdDataTableRow<ColumnNameColumnDataMap>,
    columnNames: Array<keyof ColumnNameColumnDataMap>,
    index: number
  ) => JSX.Element;
  // Map the total data to a component. If not provided, no totals rows will be shown.
  mapTotalDataToComponent?: (
    totalData: Record<string, unknown>,
    columnDataNames: Array<ColumnNameColumnDataMap>,
    rowCount: number
  ) => JSX.Element;
  // The total data to show. If not provided, no totals rows will be shown.
  totalData?: Record<string, unknown>;
  // Callback for when a column is sorted.
  onSort?: (column: string, direction: "asc" | "desc") => void;
  // Comparator function for sorting. If not provided, the default sort comparator will be used.
  sortComparator?: (
    a: AmpdDataTableRow<string>,
    b: AmpdDataTableRow<string>,
    column: string,
    sortAscending: boolean
  ) => 1 | 0 | -1;
  maxRowsToDisplay?: number;
  compact?: boolean | "very";
};

// Pass an object of column names and their data type as type parameter. TS will enforce that the
// "name" attribute of each column is in the provided type object, and that each row has a key for
// each column name in the map. ex:
// const ampdDataTable: TAmpdDataTable<{
//   keywordText: string;
//   searchVolume: number;
// }> = {
//   columns: [
//     {
//       name: "keywordText"
//     },
//     {
//       name: "searchVolume"
//     },
//     // the below will result in a TS error because "status" is not in the list of column names
//     {
//       name: "status"
//     }
//   ],
//   rows: [
//     { keywordText: "body pillow", searchVolume: 1 },
//     //  the below will result in a TS error because it is missing "searchVolume"
//     { keywordText: "triangle pillow" },
//     //  the below will result in a TS error because "location" is not in the list of column names
//     { keywordText: "body pillow", searchVolume: 1, location: "US" },
//     //  the below will result in a TS error because search volume is not a number
//     { keywordText: "body pillow", searchVolume: false, location: "US" }
//   ]
// };
export type TAmpdDataTable<ColumnNameColumnDataMap> = {
  columns: Array<AmpdDataTableColumn<ColumnNameColumnDataMap>>;
  rows: Array<AmpdDataTableRow<ColumnNameColumnDataMap>>;
  settings?: AmpdDataTableSettings<ColumnNameColumnDataMap>;
};

// TODO: Remove this component and update AmpdDataTable to use the new API. Update all usages of
// AmpdDataTable to use the new API.
export const AmpdDataTableTsWrapper = <ColumnNameColumnDataMap,>({
  tableConfig,
  isLoading,
  error
}: {
  tableConfig: TAmpdDataTable<ColumnNameColumnDataMap>;
  isLoading?: boolean;
  error?: string;
}): JSX.Element => {
  const columnDataNames = tableConfig.columns.map(column => column.name);
  const columnDisplayNamesMap = tableConfig.columns.reduce(
    (acc, column) => ({
      ...acc,
      [column.name]: column.displayName
    }),
    {}
  );

  type StringData = {
    [key in keyof ColumnNameColumnDataMap]: string | undefined;
  };

  type NumberData = {
    [key in keyof ColumnNameColumnDataMap]: number | undefined;
  };

  const columnDisplaySubtitlesMap = tableConfig.columns.reduce(
    (acc, column) => {
      acc[column.name] = column.displaySubtitle;
      return acc;
    },
    {} as StringData
  );

  const columnDisplayWidthsMap = tableConfig.columns.reduce((acc, column) => {
    acc[column.name] = column.displayWidth;
    return acc;
  }, {} as NumberData);

  const columnDisplayMinWidthsMap = tableConfig.columns.reduce(
    (acc, column) => {
      acc[column.name] = column.displayMinWidth;
      return acc;
    },
    {} as NumberData
  );

  const columnTooltipMap = tableConfig.columns.reduce((acc, column) => {
    acc[column.name] = column.tooltip;
    return acc;
  }, {} as StringData);

  const freezeColumnsMap = tableConfig.columns.reduce((acc, column) => {
    acc[column.name] = column.freeze;
    return acc;
  }, {} as StringData);

  const defaultSortDirections = tableConfig.columns.reduce((acc, column) => {
    acc[column.name] = column.sortDirection;
    return acc;
  }, {} as StringData);

  const defaultSortColumn = tableConfig.settings?.defaultSortColumn;
  const emptyContent = tableConfig.settings?.emptyContent;
  const onSort = tableConfig.settings?.onSort;
  const sortComparator = tableConfig.settings?.sortComparator;
  const maxRowsToDisplay = tableConfig.settings?.maxRowsToDisplay;
  const compact = tableConfig.settings?.compact;
  const mapDataRowToComponent = tableConfig.settings?.mapDataRowToComponent;
  const mapTotalDataToComponent = tableConfig.settings?.mapDataRowToComponent;
  const totalData = tableConfig.settings?.totalData;

  const dataRows = tableConfig.rows;

  return (
    <AmpdDataTable
      // column props
      columnDataNames={columnDataNames}
      columnDisplayNamesMap={columnDisplayNamesMap}
      columnDisplaySubtitlesMap={columnDisplaySubtitlesMap}
      columnDisplayWidthsMap={columnDisplayWidthsMap}
      columnDisplayMinWidthsMap={columnDisplayMinWidthsMap}
      columnTooltipMap={columnTooltipMap}
      freezeColumnsMap={freezeColumnsMap}
      // table config props
      mapDataRowToComponent={mapDataRowToComponent}
      defaultSortDirections={defaultSortDirections}
      defaultSortColumn={defaultSortColumn}
      emptyContent={emptyContent}
      onSort={onSort}
      sortComparator={sortComparator}
      maxRowsToDisplay={maxRowsToDisplay}
      compact={compact}
      mapTotalDataToComponent={mapTotalDataToComponent}
      totalData={totalData}
      // table component props
      dataRows={dataRows}
      errorMessage={error}
      isLoading={isLoading}
    />
  );
};
