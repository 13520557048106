import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "Common/components/LoadingSpinner";

const HUBSPOT_CLASS_NAME = "meetings-iframe-container";

const HUBSPOT_SOURCE =
  "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";

const HubspotMeetings = ({
  hubspotDataSrc
}: {
  hubspotDataSrc: string;
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const script = document.createElement("script");

    script.src = HUBSPOT_SOURCE || "";
    script.async = true;
    script.onload = () => setIsLoading(false);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={HUBSPOT_CLASS_NAME} data-src={hubspotDataSrc} />
    </>
  );
};

export default HubspotMeetings;
