import gql from "graphql-tag";

export const PREPARE_SHOPIFY_SUBSCRIPTION_MUTATION = gql`
  mutation($site: SiteSelector!, $returnUrl: String!, $plan: String!) {
    prepareShopifySubscription(
      site: $site
      returnUrl: $returnUrl
      plan: $plan
    ) {
      confirmationUrl
    }
  }
`;

export const ACTIVATE_SHOPIFY_SUBSCRIPTION_MUTATION = gql`
  mutation($site: SiteSelector!, $recurringChargeId: String!) {
    activateShopifySubscription(
      site: $site
      recurringChargeId: $recurringChargeId
    ) {
      ok
    }
  }
`;

export const GET_ELIGIBLE_SUBSCRIPTION_PLANS_QUERY = gql`
  query GetEligibleSubscriptionPlans($site: SiteSelector!) {
    getEligibleSubscriptionPlans(site: $site) {
      monthlyPlanId
      costPerMonthInCents
    }
  }
`;

export const CREATE_STRIPE_SUBSCRIPTION_MUTATION = gql`
  mutation CreateStripeSubscription(
    $site: SiteSelector!
    $plan: String!
    $trialLengthInDays: Int!
  ) {
    createStripeSubscription(
      site: $site
      plan: $plan
      trialLengthInDays: $trialLengthInDays
    ) {
      subscriptionId
      clientSecret
    }
  }
`;

export const CHECK_STRIPE_SUBSCRIPTION_MUTATION = gql`
  mutation CheckStripeSubscription($site: SiteSelector!) {
    checkStripeSubscription(site: $site) {
      ok
    }
  }
`;

export const SET_FACEBOOK_CONVERSION_MUTATION = gql`
  mutation($email: String!, $value: Int!) {
    setFacebookConversion(email: $email, value: $value) {
      ok
    }
  }
`;
