import React, { useState } from "react";
import {
  Button,
  Header,
  Modal,
  Message,
  Segment,
  Icon
} from "semantic-ui-react";

import { extractErrorMessage } from "Common/errors/error";
import { GRPCWebClient } from "Common/utils/grpc";
import * as proto from "Common/utils/proto";
import {
  UpdateStripeSubscriptionRequest,
  ScheduleNextSubscriptionPlanRequest,
  UpdateStripeSubscriptionReply,
  ScheduleNextSubscriptionPlanReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Stripe_pb";

import { stripePromise } from "./PaymentForm";
import useDocuSignClickWrapAgreement from "./useDocuSignClickWrapAgreement";

import { NextInvoice } from "./NextInvoice";
import { Site, User, useSession } from "ExtensionV2/queries/useSession";
import {
  roundToNextHour,
  useNextInvoice
} from "ExtensionV2/queries/useNextInvoice";
import { momentToTimestampProto } from "Common/utils/DateUtilities";
import { AmpdSubscription } from "Common/proto/entity/billingAccount_pb";
import {
  genPriceHeadline,
  isUpgrade,
  PRICES_REQUIRING_OCT_2023_CLICK_WRAP
} from "Common/utils/ampdPayment";

const ChangePlanConfirmationModal = ({
  currentSite,
  user,
  onClose,
  currentSiteSubscription,
  targetSubscription
}: {
  currentSite: Site;
  user: User;
  onClose: () => void;
  currentSiteSubscription: AmpdSubscription.AsObject;
  targetSubscription: AmpdSubscription.AsObject;
}): JSX.Element => {
  const { refetch: refetchSession } = useSession();
  const { siteAlias } = currentSite;
  const { userName, userEmail } = user;
  const { refetch: refetchNextInvoice } = useNextInvoice({});

  const [changePlanLoading, setChangePlanLoading] = useState(false);
  const [changePlanError, setChangePlanError] = useState("");
  const [hasAgreedToClickWrap, setHasAgreedToClickWrap] = useState(false);
  const [showClickWrap, setShowClickWrap] = useState(false);

  const hasAlreadyAgreedToCurrentClickWrap = PRICES_REQUIRING_OCT_2023_CLICK_WRAP.includes(
    currentSiteSubscription.subscriptionId
  );

  let clickwrapId: string | undefined;
  if (targetSubscription?.contract?.provider === "docusign") {
    clickwrapId =
      process.env.REACT_APP_ENV === "prod"
        ? targetSubscription.contract?.id
        : process.env.REACT_APP_DOCUSIGN_TEST_CONTRACT_ID;
  }

  const needsToSignClickWrap =
    !!clickwrapId && !hasAlreadyAgreedToCurrentClickWrap;

  useDocuSignClickWrapAgreement({
    showClickWrap,
    email: userEmail,
    userName,
    siteAlias,
    clickwrapId,
    onAgreed: () => {
      setHasAgreedToClickWrap(true);
      setShowClickWrap(false);
    },
    onDeclined: () => {
      setShowClickWrap(false);
    }
  });

  if (!targetSubscription) {
    return <></>;
  }

  const planIsUpgrading = isUpgrade(
    currentSiteSubscription,
    targetSubscription
  );

  const handleChangePlan = async () => {
    if (needsToSignClickWrap && !hasAgreedToClickWrap) {
      console.error(
        "User attempting to sign up for pro plan without signing agreement for site: ",
        siteAlias
      );
      return;
    }

    const onChangePlanError = () => {
      setChangePlanError(
        "There was a problem when updating your plan, please try again in a few moments. If this problem persists please contact us for assistance."
      );
      setChangePlanLoading(false);
    };

    setChangePlanLoading(true);
    if (planIsUpgrading) {
      // Once we update the subscription in Stripe, we receive a client secret that the Stripe UI
      // library uses to launch the confirmation modal. This modal is often blank for US customers,
      // but for non-US customers contains a form.
      const onUpdateSubscriptionSuccess = async (
        reply: UpdateStripeSubscriptionReply.AsObject
      ) => {
        const secret = reply?.clientSecret;
        const paymentMethodId = reply?.paymentMethodId;

        if (secret != null) {
          await launchConfirmModal(secret, paymentMethodId, onChangePlanError);
        }
        // Stripe can take a few seconds to update the subscription after confirmPayment
        // completes. I'm pretty sure this lag is because of our Radar rules.
        setTimeout(() => {
          refetchSession();
          refetchNextInvoice();
          setChangePlanLoading(false);
          onClose();
        }, 2000);
      };

      // Stripe prorates by the second. NextInvoice and updateSubscriptionPlan need to provide
      // the same timestamp in order for the payment preview and the actual payment to be the same,
      // so we always round to the start of the next hour.
      await updateSubscriptionPlan(
        siteAlias,
        "", // stripeInfo.subscriptionId
        targetSubscription.externalId?.id || "",
        roundToNextHour(),
        onUpdateSubscriptionSuccess,
        onChangePlanError
      );
    } else {
      const onScheduleNextSuccess = () => {
        refetchNextInvoice();
        setChangePlanLoading(false);
        onClose();
      };

      await scheduleNextSubscriptionPlan(
        siteAlias,
        "", // stripeInfo.subscriptionId,
        targetSubscription.externalId?.id || "",
        onScheduleNextSuccess,
        onChangePlanError
      );
    }
  };

  const modalContent = planIsUpgrading ? (
    <>
      {hasAlreadyAgreedToCurrentClickWrap && (
        <p>
          The term length of you current plan will remain the same, but you will
          be charged the new rate for the remainder of the term. Your next
          invoice will contain a prorated charge for the new plan as well as a
          refund for any unused time on your current plan.
        </p>
      )}

      <Segment>
        <NextInvoice nextPriceId={targetSubscription.externalId?.id} />
      </Segment>
    </>
  ) : (
    "We will switch your account over to this plan after you current plan ends."
  );

  const priceHeadline = genPriceHeadline(targetSubscription);

  return (
    <Modal
      key="changePlanConfirmation"
      onClose={() => onClose()}
      open={true}
      size="small"
    >
      <Header icon>
        <h2>{targetSubscription.name}</h2>
        <p>{priceHeadline}</p>
      </Header>

      <Modal.Content>
        {needsToSignClickWrap && (
          <Segment basic compact padded={false} loading={showClickWrap}>
            <Message
              onClick={() => setShowClickWrap(true)}
              warning={!hasAgreedToClickWrap}
              success={hasAgreedToClickWrap}
              style={{
                textAlign: "start",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Icon
                size="large"
                style={{ alignSelf: "center" }}
                name={hasAgreedToClickWrap ? "check" : "warning"}
              />
              <p>
                You must agree to the {targetSubscription.name} Terms and
                Conditions. This plan requires a{" "}
                {targetSubscription.billingIntervalMonths *
                  targetSubscription.minimumBillingIntervals}{" "}
                month commitment. <a>Click here</a> to view and agree.
              </p>
            </Message>
          </Segment>
        )}
        {modalContent}
      </Modal.Content>

      {changePlanError && <Message error>{changePlanError}</Message>}
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          disabled={needsToSignClickWrap && !hasAgreedToClickWrap}
          loading={changePlanLoading}
          onClick={handleChangePlan}
        >
          Change Plans
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const launchConfirmModal = async (
  clientSecret: string,
  paymentMethodId: string,
  onError: (error: string) => void
) => {
  if (!clientSecret) {
    return;
  }

  try {
    const stripe = await stripePromise;

    if (!stripe) {
      return;
    }

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId
    });

    if (error) {
      console.error(error);
      if (onError) {
        onError(error.message || "Stripe Error");
      }
    }
  } catch (e) {
    console.error(e);
    if (onError) {
      onError(extractErrorMessage(e));
    }
  }
};

const updateSubscriptionPlan = async (
  siteAlias: string,
  subscriptionId: string,
  priceId: string,
  prorationDate: number,
  onSuccess: (reply: UpdateStripeSubscriptionReply.AsObject) => void,
  onError: (error: string) => void
) => {
  try {
    const req = proto.set(new UpdateStripeSubscriptionRequest(), {
      siteAlias,
      subscriptionId,
      planId: priceId,
      prorationDate: momentToTimestampProto(prorationDate)
    });

    const reply = await GRPCWebClient.updateStripeSubscription(req, {});

    if (onSuccess) {
      onSuccess(reply.toObject());
    }
  } catch (e) {
    console.error(e);
    if (onError && e instanceof Error) {
      onError(e.message);
    }
  }
};

const scheduleNextSubscriptionPlan = async (
  siteAlias: string,
  subscriptionId: string,
  priceId: string,
  onSuccess: (reply: ScheduleNextSubscriptionPlanReply.AsObject) => void,
  onError: (error: string) => void
) => {
  try {
    const req = proto.set(new ScheduleNextSubscriptionPlanRequest(), {
      siteAlias,
      subscriptionId,
      planId: priceId
    });

    const reply = await GRPCWebClient.scheduleNextSubscriptionPlan(req, {});

    if (onSuccess) {
      onSuccess(reply.toObject());
    }
  } catch (e) {
    console.error(e);
    if (onError && e instanceof Error) {
      onError(e.message);
    }
  }
};

export default ChangePlanConfirmationModal;
