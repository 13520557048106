import React from "react";
import { useQuery } from "react-apollo";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Icon,
  Segment
} from "semantic-ui-react";
import styled from "styled-components";

import AddSiteUserForm from "ExtensionV2/components/AddSiteUserForm";
import AmpdProOperatorFeaturesToggle from "ExtensionV2/components/features/AmpdProOperatorFeaturesToggle";
import ManageAmazonAccountsPanel from "ExtensionV2/components/ManageAmazonAccountsPanel";
import ManageGoogleAdsAccountPanel from "ExtensionV2/components/ManageGoogleAdsAccountPanel";
import ManageSiteContactEmailForm from "ExtensionV2/components/ManageSiteContactEmailForm";
import NewAmpdAccountForm from "ExtensionV2/components/NewAmpdAccountForm";
import { SiteSwitcher } from "ExtensionV2/components/SiteSwitcher";
import ErrorBoundary from "Common/components/ErrorBoundary";
import {
  BackwardsCompatSiteUsersTable,
  SiteUsers
} from "ExtensionV2/components/SiteUsersTable";
import { InvoicesTable } from "ExtensionV2/components/stripe/InvoicesModal";
import { Site, User, useSession } from "ExtensionV2/queries/useSession";
import { backgroundLight } from "ExtensionV2/styles/colors";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { isManagerSite } from "Common/utils/site";
import { FacebookLogin } from "./FacebookLogin";
import {
  useHasSelfServeBilling,
  useShouldShowFacebookFeatures,
  useHasWalmartUIEnabledFeature
} from "Common/utils/featureFlags";
import { sendGAEvent } from "../../components/GA";
import { WalmartProfileDefinition } from "ExtensionV2/pages/SettingsPage/WalmartProfileDefinition";
import { useOnlyAdminUsersCanEditCampaigns } from "Common/utils/siteUIBehavior";

export const SETTINGS_PAGE_GA_CATEGORY = "Ampd: Settings Page";

const SettingsPageStyledSegment = styled(Segment)`
  max-width: 50em;
`;

const SettingSegment: React.FC = ({ children }) => (
  <SettingsPageStyledSegment>
    <ErrorBoundary>{children}</ErrorBoundary>
  </SettingsPageStyledSegment>
);

const SettingsPage: React.FC<{
  currentSite: Site;
  user: User;
  clientSitesList: Array<SiteManagerLinkClient.AsObject>;
}> = ({ currentSite, user, clientSitesList }) => {
  const {
    siteAlias,
    siteName,
    walmartInfo: { walmartProfiles }
  } = currentSite;
  const {
    isCurrentSiteAdmin,
    isAmpdOperator,
    authorizedSitesList,
    userId
  } = user;
  const { updateOnlyAdminUsersCanEditCampaignsMutation } = useSession();

  const {
    mutate: updateOnlyAdminUsersCanEditCampaigns
  } = updateOnlyAdminUsersCanEditCampaignsMutation;

  const shouldShowFacebookFeatures = useShouldShowFacebookFeatures();
  const hasWalmartUIEnabled = useHasWalmartUIEnabledFeature();
  const onlyAdminUsersCanEditCampaigns = useOnlyAdminUsersCanEditCampaigns();

  const hasSelfServeBilling = useHasSelfServeBilling();

  const siteUsersApolloQuery = useQuery(SiteUsers, {
    skip: !siteAlias,
    variables: {
      siteAlias
    }
  });

  const isManager = isManagerSite(currentSite, clientSitesList);
  const showAmazonPanel =
    !isManager ||
    currentSite.amazonInfo.advertisingAccounts.length > 0 ||
    currentSite.amazonInfo.advertisingProfiles.length > 0 ||
    currentSite.amazonInfo.sellerAccounts.length > 0;
  const showGooglePanel = !isManager || currentSite.adwordsAccounts.length > 0;

  const handleOnlyAdminUsersCanEditCampaigns = (
    _e: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    updateOnlyAdminUsersCanEditCampaigns({
      onlyAdminUsersCanEditCampaigns: !!data.checked
    });
  };

  return (
    <div style={{ height: "100%", textAlign: "center" }}>
      <Segment
        style={{
          display: "inline-block",
          margin: "auto",
          backgroundColor: backgroundLight,
          padding: "3em",
          textAlign: "left"
        }}
      >
        <h1>Configure Settings</h1>
        {showAmazonPanel && (
          <>
            <SettingSegment>
              <h3>Amazon Accounts</h3>

              <ManageAmazonAccountsPanel
                forceHideDisconnectLink={!isCurrentSiteAdmin}
              />
            </SettingSegment>
          </>
        )}

        {hasWalmartUIEnabled && (
          <SettingSegment>
            <>
              <h3>Walmart Profiles for Attribution</h3>

              {walmartProfiles.map(profile => (
                <WalmartProfileDefinition
                  key={profile.profileName}
                  profile={profile}
                  profiles={walmartProfiles}
                />
              ))}

              <WalmartProfileDefinition profiles={walmartProfiles} />
            </>
          </SettingSegment>
        )}

        {showGooglePanel && (
          <SettingSegment>
            <h3>Google Ads Account</h3>

            <ManageGoogleAdsAccountPanel currentSite={currentSite} />
          </SettingSegment>
        )}

        {shouldShowFacebookFeatures && (
          <SettingSegment>
            <h3>Meta Account</h3>

            <FacebookLogin />
          </SettingSegment>
        )}

        {isCurrentSiteAdmin && (
          <SettingSegment>
            <h3>Invoices</h3>
            <InvoicesTable siteAlias={siteAlias} />
          </SettingSegment>
        )}
        {isCurrentSiteAdmin && hasSelfServeBilling && (
          <SettingSegment>
            <h3>Change Plan</h3>
            <UpgradeButton
              siteAlias={siteAlias}
              gaLocation="Change Plan Setting"
              label="View Plan Options"
            />
          </SettingSegment>
        )}
        {isCurrentSiteAdmin && (
          <SettingSegment>
            <h3>Business Contact Email</h3>
            <ManageSiteContactEmailForm currentSite={currentSite} />
          </SettingSegment>
        )}
        <SettingSegment>
          <h3>{isCurrentSiteAdmin ? "Manage Users" : "Users"}</h3>
          <BackwardsCompatSiteUsersTable
            siteAlias={siteAlias}
            loggedInUserId={userId}
            isCurrentSiteAdmin={isCurrentSiteAdmin}
            siteUsersApolloQuery={siteUsersApolloQuery}
          />

          {isCurrentSiteAdmin && (
            <>
              <Segment>
                <Checkbox
                  checked={onlyAdminUsersCanEditCampaigns}
                  label="Only admin users can create and edit campaigns"
                  onChange={handleOnlyAdminUsersCanEditCampaigns}
                ></Checkbox>
              </Segment>
              <Segment>
                <h4>Add User</h4>
                <AddSiteUserForm
                  siteAlias={siteAlias}
                  onAddUserSuccess={siteUsersApolloQuery.refetch}
                  focusAddUserInput={false}
                />
              </Segment>
            </>
          )}
          {!isCurrentSiteAdmin && onlyAdminUsersCanEditCampaigns && (
            <Segment>
              <div>
                <Icon name="warning sign" color="yellow" /> Only admin users can
                create and edit campaigns in this account
              </div>
            </Segment>
          )}
        </SettingSegment>
        {authorizedSitesList.length > 1 && (
          <SettingSegment>
            <h3>Change Account</h3>
            <SiteSwitcher
              currentSiteAlias={siteAlias}
              currentSiteName={siteName}
              userSites={authorizedSitesList}
            />
          </SettingSegment>
        )}
        {(isCurrentSiteAdmin || isAmpdOperator) && (
          <SettingSegment>
            <h3>Create New Ampd Account</h3>
            <NewAmpdAccountForm siteAlias={siteAlias} />
          </SettingSegment>
        )}
        {isAmpdOperator && (
          <SettingSegment>
            <h3>Ampd Pro Operator Features</h3>
            <strong>
              <AmpdProOperatorFeaturesToggle label="Enable" />
            </strong>
          </SettingSegment>
        )}
      </Segment>
    </div>
  );
};

export const UpgradeButton: React.FC<{
  gaLocation?: string;
  siteAlias: string;
  label?: string;
  compact?: boolean;
}> = ({ siteAlias, gaLocation, label, compact }) => {
  const navigate = useNavigate();

  return (
    <Button
      primary
      compact={compact}
      content={label || "View Plan Options"}
      size={compact ? "mini" : undefined}
      onClick={() => {
        sendGAEvent(
          SETTINGS_PAGE_GA_CATEGORY,
          "Update Ampd Plan",
          siteAlias,
          gaLocation
        );

        navigate(`/t/${siteAlias}/payment`);
      }}
    />
  );
};

export default SettingsPage;
