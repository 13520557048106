import { Message } from "semantic-ui-react";
import React from "react";

// See if the string ends with a punctuation character.
function hasPunctuation(str) {
  return str && str.match(/\p{P}$/gu);
}

// Renders a Google-like ad box.
export default function AdMockUp(props) {
  const { domain, headline, description1, description2, style } = props;

  return (
    <Message
      style={{
        fontSize: "14px",
        marginLeft: 20,
        marginRight: 20,
        ...style
      }}
    >
      <div style={{ fontSize: "14px", paddingBottom: "4px" }}>
        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Ad</span>{" "}
        &nbsp;&middot;&nbsp; {domain}
      </div>
      <span style={{ color: "#2200CC", fontSize: "20px" }}>{headline}</span>
      <p style={{ paddingTop: "4px" }}>
        <span>
          {description1}
          {!!description1 && !hasPunctuation(description1.trimEnd())
            ? ". "
            : " "}
          {description2}
        </span>
      </p>
      {props.children}
    </Message>
  );
}
