import React, { useState } from "react";
import { Message } from "semantic-ui-react";
import {
  getHumanReadableAmount,
  MICROS_TO_CURRENCY_UNIT_FACTOR
} from "Common/utils/money";
import { CurrencyInputField } from "./SelectProductsStage";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";
import { Site } from "ExtensionV2/queries/useSession";

const validateMaxCPC = (maxCPC: number): Set<string> => {
  const errors = new Set<string>();
  if (maxCPC < 0) {
    errors.add("Max cost per click cannot be negative");
  }
  return errors;
};

export const maxCPCField = (initialValue: number): FormField<number> => ({
  _value: initialValue,
  errors: validateMaxCPC(initialValue),
  validator: validateMaxCPC
});

export const MaxCPCReviewSection = ({
  site,
  state,
  dispatch,
  onEdit
}: {
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;
  const { currencyCode } = site?.adwordsAccounts[0] || {};

  const [campaign, { selectMaxCPCMicros }] = getCurrentCampaignAndSelectors(
    state
  );

  const maxCPCMicros = selectMaxCPCMicros(campaign);

  const [tempMaxCPCMicros, setTempMaxCPCMicros] = useState(maxCPCMicros);

  const [localErrors, setLocalErrors] = useState<Set<string>>(
    campaign.maxCPCMicrosField.errors
  );

  const dataComponent = (
    <p>{getHumanReadableAmount(maxCPCMicros, currencyCode)}</p>
  );
  const editComponent = (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "10em" }}>
        <CurrencyInputField
          adwordsCurrency={currencyCode}
          budgetMicros={tempMaxCPCMicros}
          campaignIndex={currentReviewCampaignIndex}
          handleUpdateBudget={(_campaignIndex: number, budgetInput: number) => {
            setTempMaxCPCMicros(budgetInput * MICROS_TO_CURRENCY_UNIT_FACTOR);
          }}
          onBlur={() => {
            setLocalErrors(
              campaign.maxCPCMicrosField.validator(tempMaxCPCMicros)
            );
          }}
          label="Max Cost Per Click"
          invalid={localErrors.size > 0}
        />
      </div>
      <Message info>
        <p>
          This determines the highest amount that you're willing to pay for a
          click on your ad.
        </p>
      </Message>
      {tempMaxCPCMicros === 0 && (
        <Message warning>
          This will disable the max cost per click for this ad, which can
          negatively impact performance.
        </Message>
      )}
      {localErrors.size > 0 && (
        <Message error>
          {Array.from(localErrors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  const handleSave = () => {
    dispatch({
      name: "UpdateMaxCPC",
      data: {
        index: currentReviewCampaignIndex,
        maxCPCMicros: tempMaxCPCMicros
      }
    });
  };

  const handleCancel = () => {
    setTempMaxCPCMicros(maxCPCMicros);
  };

  return (
    <CampaignReviewSection
      dataName="Max Cost Per Click"
      dataComponent={dataComponent}
      editComponent={editComponent}
      disableSave={localErrors.size > 0}
      errors={campaign.maxCPCMicrosField.errors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
