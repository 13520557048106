import _ from "lodash";

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";

import { sendGAEvent } from "../GA";
import { isWalmartMarketplaceInfo } from "Common/utils/marketplace";

import { extractErrorMessage } from "Common/errors/error";
import {
  AttributionBehavior,
  ItemizedCampaignConfiguration
} from "../../queries/useItemizedCampaignConfiguration";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { sendUpdateAdTracking } from "ExtensionV2/components/campaignEditor/AmazonAttributionEditButton";
import { GetWalmartAttributionUrlsRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { extractWalmartURLInfoFromString } from "Common/utils/walmart";
import styled from "styled-components/macro";

const CommentBox = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  padding-left: 0;
  padding-right: 0;
`;

const WalmartAttributionEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    customerId,
    isCampaignRemoved,
    domain,
    marketplaceInfo
  } = itemizedCampaignConfiguration;

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let editable = true;
  if (
    !isWalmartMarketplaceInfo(marketplaceInfo) ||
    !domain ||
    !customerId ||
    isCampaignRemoved
  ) {
    editable = false;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={
          editable
            ? () => {
                sendGAEvent(gaCategory, "Click Edit Attribution", siteAlias);
                setModalOpen(true);
              }
            : undefined
        }
      >
        {editable && (
          <SimpleTooltip tooltip="Edit Attribution">
            <Icon name="pencil" style={{ cursor: "pointer" }} />
          </SimpleTooltip>
        )}
      </Flex>
      {modalOpen && (
        <WalmartAttributionBehaviorModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

const WalmartAttributionBehaviorModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const {
    customerId,
    campaignId,
    adGroupId,
    adId,
    allFinalURLs,
    trackingURLTemplate,
    attributionBehavior
  } = itemizedCampaignConfiguration;

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const [editAttributionBehavior, setEditAttributionBehavior] = useState(
    attributionBehavior
  );

  const handleUpdateAttribution = async () => {
    setIsSubmitting(true);

    try {
      const {
        finalURLs: newFinalURLs,
        trackingURLTemplate: newTrackingUrlTemplate
      } = await determineAdTrackingVariables({
        siteAlias,
        customerId,
        finalURLs: allFinalURLs,
        trackingURLTemplate,
        attributionBehavior: editAttributionBehavior
      });

      await sendUpdateAdTracking(
        siteAlias,
        gaCategory,
        String(customerId),
        String(campaignId),
        String(adGroupId),
        String(adId),
        !_.isEqual(newFinalURLs, allFinalURLs) ? newFinalURLs : undefined,
        newTrackingUrlTemplate !== trackingURLTemplate
          ? newTrackingUrlTemplate
          : undefined
      );

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);

      console.error(e); // log to datadog

      setErrorMessage(message);

      // Since submitAttempts is used as the key of the OneClickButton, changing it
      // will create a new button instance and re-enable it.
      setSubmitAttempts(submitAttempts + 1);
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setErrorMessage("");
    if (onClose) {
      onClose();
    }
  };

  const handleRadioClicked = (behavior: AttributionBehavior) => () => {
    setEditAttributionBehavior(behavior);
  };

  return (
    <Modal
      open={true}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
      size="large"
    >
      <Modal.Header>Change Walmart Attribution</Modal.Header>
      <Modal.Content scrolling>
        <Segment>
          <Form style={{ minHeight: 300 }}>
            <Form.Field>
              <strong>
                <Checkbox
                  radio
                  label="Attribution by parallel tracking"
                  checked={
                    editAttributionBehavior ===
                    AttributionBehavior.AMPD_WALMART_PARALLEL_ATTRIBUTION
                  }
                  onClick={handleRadioClicked(
                    AttributionBehavior.AMPD_WALMART_PARALLEL_ATTRIBUTION
                  )}
                />
              </strong>
              <CommentBox>
                <p>
                  Ampd will set up parallel tracking so clicking the ad goes
                  directly to Walmart.
                </p>
              </CommentBox>
            </Form.Field>
            <Form.Field>
              <strong>
                <Checkbox
                  radio
                  label="Attribution by redirection"
                  checked={
                    editAttributionBehavior ===
                    AttributionBehavior.AMPD_WALMART_REDIRECT_ATTRIBUTION
                  }
                  onClick={handleRadioClicked(
                    AttributionBehavior.AMPD_WALMART_REDIRECT_ATTRIBUTION
                  )}
                />
              </strong>
              <CommentBox>
                <p>
                  Ampd will set up a redirection URL that will track ad clicks
                  before redirecting to Walmart.
                </p>
              </CommentBox>
            </Form.Field>
            <Form.Field>
              <strong>
                <Checkbox
                  radio
                  label="Do not use Walmart Attribution for this ad"
                  checked={
                    editAttributionBehavior ===
                    AttributionBehavior.NO_ATTRIBUTION
                  }
                  onClick={handleRadioClicked(
                    AttributionBehavior.NO_ATTRIBUTION
                  )}
                />
              </strong>
              <CommentBox>
                <p>You will not be able to track sales generated by this ad.</p>
              </CommentBox>
            </Form.Field>
          </Form>
        </Segment>
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          key={submitAttempts}
          primary
          onClick={handleUpdateAttribution}
          loading={isSubmitting}
          disabled={editAttributionBehavior === attributionBehavior}
        >
          Update Attribution
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

export async function determineAdTrackingVariables({
  siteAlias,
  customerId,
  finalURLs,
  trackingURLTemplate,
  attributionBehavior
}: {
  siteAlias: string;
  customerId: number;
  finalURLs: Array<string>;
  trackingURLTemplate: string;
  attributionBehavior: AttributionBehavior;
}): Promise<{ finalURLs: Array<string>; trackingURLTemplate: string }> {
  const newFinalURLs = finalURLs.map(finalURL => {
    const urlInfo = extractWalmartURLInfoFromString(finalURL);
    return urlInfo.effectiveURL || finalURL;
  });

  if (
    !siteAlias ||
    !customerId ||
    (attributionBehavior !==
      AttributionBehavior.AMPD_WALMART_REDIRECT_ATTRIBUTION &&
      attributionBehavior !==
        AttributionBehavior.AMPD_WALMART_PARALLEL_ATTRIBUTION)
  ) {
    return { finalURLs: newFinalURLs, trackingURLTemplate };
  }

  // Only create a request if the behavior is AMPD and we have an advertiser
  // specified.
  const req = new GetWalmartAttributionUrlsRequest();
  req.setSiteAlias(siteAlias);
  req.setCampaignPlatform(CampaignPlatform.Option.GOOGLE_ADS);
  req.setPlatformAccountId(String(customerId));
  req.setTargetUrlsList(finalURLs);

  const reply = await GRPCWebClient.getWalmartAttributionUrls(req, {});

  if (
    attributionBehavior ===
    AttributionBehavior.AMPD_WALMART_REDIRECT_ATTRIBUTION
  ) {
    return { finalURLs: reply.getRedirectUrlsList(), trackingURLTemplate: "" };
  } else if (
    attributionBehavior ===
    AttributionBehavior.AMPD_WALMART_PARALLEL_ATTRIBUTION
  ) {
    return {
      finalURLs: reply.getParallelTrackedUrlsList(),
      trackingURLTemplate: reply.getParallelTrackingUrlTemplate()
    };
  }

  return {
    finalURLs: newFinalURLs,
    trackingURLTemplate
  };
}

export default WalmartAttributionEditButton;
