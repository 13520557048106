import _ from "lodash";

import React from "react";
import { Button, Icon, Message, Modal } from "semantic-ui-react";
import { Box, Flex } from "@rebass/grid";
import sanitizeHtml from "sanitize-html";

import {
  extractErrorMessage,
  extractErrorRequestID
} from "Common/errors/error";

// ErrorModal displays the public error message to the user and offers the
//
// Properties:
//      site: the site object
//      open: specifies if the Modal component is visible to the user
//      onClose: callback for when the user clicks on the close button
//      otherButton: optional second button to display
//      closeButton: If undefined, then
//               a basic Close button is shown and hooked up to the onClose callback.
//               If an actual component, then it is used as a replacement for the
//               basic close button.
//      heading: short description of what operation failed
//      emailInfo: object which information that will be used to populate the
//               email message, specifically
//                    subject: email subject line
//                    body: body text to include in the mail
//                    errorMessage: the public text of the error
//                    siteAlias: the alias of the user's site
//                    requestId: the request ID (if available) that can be used
//                               to track down the transaction in the logs.
//      errorMessage: error message HTML
//      error: (optional) actual error instance from graphql response.  If provided,
//               then it will be used to fill out the emailInfo object with
//               fields that weren't specifically set.
//
export const ErrorModal = props => {
  const {
    siteAlias,
    open,
    onClose,
    otherButtonTitle,
    onOtherButtonClick,
    closeButton,
    heading,
    content,
    errorMessage,
    error
  } = props;

  let emailInfo = props.emailInfo || {};
  if (siteAlias && !emailInfo.siteAlias) {
    emailInfo = _.assign({}, emailInfo, { siteAlias });
  }

  if (error && !emailInfo.errorMessage) {
    const errorMessage =
      extractErrorMessage(error) || "An unexpected error occurred.";
    emailInfo = _.assign({}, emailInfo, { errorMessage });
  }
  if (error && !emailInfo.requestId) {
    const requestId = extractErrorRequestID(error);
    if (requestId) {
      emailInfo = _.assign({}, emailInfo, { requestId });
    }
  }

  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Content>
        {content}
        <Message negative attached="top">
          <Message.Header>
            {heading || "Unable to complete operation"}
          </Message.Header>
          {errorMessage && (
            <>
              {/* See https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml */}
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(errorMessage.replace(/\n/g, "<br>"))
                }}
              />
            </>
          )}
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
          <Box ml="auto">
            <EmailSupportButton
              primary
              style={{ whiteSpace: "nowrap" }}
              emailInfo={emailInfo}
            />
          </Box>
          {!!otherButtonTitle && (
            <Box ml="10px">
              <Button onClick={onOtherButtonClick}>{otherButtonTitle}</Button>
            </Box>
          )}
          {closeButton === undefined ? (
            <Box ml="10px">
              <Button onClick={onClose}>Close</Button>
            </Box>
          ) : (
            <Box ml="10px">{closeButton}</Box>
          )}
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

export const EmailSupportButton = ({ emailInfo, ...props }) => {
  return (
    <Button
      {...props}
      icon
      labelPosition="left"
      as="a"
      href={getSupportEmailLink(emailInfo)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="mail" />
      Get Help Over Email
    </Button>
  );
};

export function getSupportEmailLink(emailInfo) {
  const subject = emailInfo.subject || "Keyword Genius Support";

  let body = `${emailInfo.body || ""}`;
  if (emailInfo.siteAlias) {
    body += `\nSite alias: ${emailInfo.siteAlias}`;
  }
  if (emailInfo.errorMessage) {
    body += `\nError: ${stripHTML(emailInfo.errorMessage)}`;
  }
  if (emailInfo.requestId) {
    body += `\nRequest ID: ${emailInfo.requestId}`;
  }

  body += "\n";

  return (
    `mailto:${process.env.REACT_APP_EXTENSION_SUPPORT_EMAIL}` +
    `?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
  );
}

// Removes all HTML tags from the input, but leaves the content.
function stripHTML(input) {
  return sanitizeHtml(input, {
    allowedTags: [],
    allowedAttributes: {}
  });
}

export default ErrorModal;
