/* eslint-disable  @typescript-eslint/no-explicit-any */

import { WebClient } from "../proto/edge/grpcwebPb/GrpcwebServiceServiceClientPb";
import {
  Request,
  StreamInterceptor,
  UnaryResponse,
  UnaryInterceptor,
  ClientReadableStream
} from "grpc-web";
import errorPb from "../proto/errorPb/error_pb";
import {
  executeWithRefresh,
  getCSRFToken,
  MAX_FETCH_ATTEMPTS,
  refreshCSRFToken
} from "./auth";
import { extractErrorFromStatus, kindOf } from "../errors/error";
import { Message } from "google-protobuf";

const CSRF_METADATA_KEY = "X-CSRF-Token";

// Sequential number to identify individual grpc requests.
let requestNumber = 1;

// Function to send grpc info to our gRPC-Ampd chrome devtools extension.
// Only set if the build environment is dev and if the extension is installed
// and enabled.
let sendDataToDevTools:
  | null
  | ((
      streaming: boolean,
      method: string,
      request: any,
      response: any,
      err: any
    ) => void) = null;

declare global {
  interface Window {
    __GRPCWEB_DEVTOOLS__(clients: WebClient[]): void;
  }
}

if (process.env.REACT_APP_ENV === "dev" && !!window.__GRPCWEB_DEVTOOLS__) {
  sendDataToDevTools = (
    streaming: boolean,
    method: string,
    request: any,
    response: any,
    err: any
  ) => {
    window.postMessage(
      {
        type: "__GRPCWEB_DEVTOOLS__",
        method,
        methodType: streaming ? "server_streaming" : "unary",
        request: request,
        response: response,
        error: err
      },
      "*"
    );
  };
}

/**
 * AuthenticatingUnaryInterceptor is a UnaryInterceptor that handles the CSRF
 * injection and refresh logic for our unary grpcweb requests.
 *
 * For information on creating interceptors for grpcweb, see
 * https://grpc.io/blog/grpc-web-interceptor/.
 *
 */
export class AuthenticatingUnaryInterceptor<
  REQ extends Message,
  RESP extends Message
> implements UnaryInterceptor<REQ, RESP> {
  /** @override */
  async intercept(
    request: Request<REQ, RESP>,
    invoker: (req: Request<REQ, RESP>) => Promise<UnaryResponse<REQ, RESP>>
  ): Promise<UnaryResponse<REQ, RESP>> {
    const executeFn = async ({ csrfToken }: { csrfToken: string }) => {
      if (csrfToken) {
        request.getMetadata()[CSRF_METADATA_KEY] = csrfToken;
      }

      const requestDesc = `${
        (request.getMethodDescriptor() as any).name
      } [${requestNumber++}]`;

      try {
        const response = await invoker(request);

        if (sendDataToDevTools) {
          sendDataToDevTools(
            false,
            requestDesc,
            request.getRequestMessage().toObject(),
            response.getResponseMessage().toObject(),
            undefined
          );
        }

        return { response, refresh: false };
      } catch (e) {
        if (isExpiredError(e)) {
          return { response: null, refresh: true };
        }

        if (sendDataToDevTools) {
          sendDataToDevTools(
            false,
            requestDesc,
            request.getRequestMessage().toObject(),
            undefined,
            e
          );
        }

        throw e;
      }
    };

    return await executeWithRefresh(executeFn);
  }
}

type EventTypeCallbacks = { string?: any };
type EventType = keyof EventTypeCallbacks;

/**
 * AuthenticatingWrappedStream is an implementation of ClientReadableStream
 * that handles our authentication and refresh logic.
 *
 * Basically, we add our own callbacks to the stream. If we get an EXPIRED
 * error before we get any data, we refresh the token, start a new stream
 * and try again.
 *
 * Once we get data, any future errors are bubbled directly to the client.
 *
 * @template RESPONSE
 */
class AuthenticatingWrappedStream<REQ extends Message, RESP extends Message>
  implements ClientReadableStream<RESP> {
  _request: Request<REQ, RESP>;
  _requestDesc: string;
  _invoker: (req: Request<REQ, RESP>) => ClientReadableStream<RESP>;
  _attempt: number;
  _callbacks: EventTypeCallbacks;
  _streamState: any;
  _lastCSRFToken: any;

  constructor(
    request: Request<REQ, RESP>,
    invoker: (req: Request<REQ, RESP>) => ClientReadableStream<RESP>
  ) {
    this._request = request;
    this._requestDesc = `${
      (request.getMethodDescriptor() as any).name
    } [${requestNumber++}]`;
    this._invoker = invoker;
    this._attempt = 0;
    this._callbacks = {}; // callbacks registered by our caller
    this._startStream();
  }

  /** Starts a new stream and cancels any previous stream. */
  _startStream() {
    // Cancel any existing stream and avoid creating multiple streams from the
    // failure of a previous stream.
    if (this._streamState) {
      if (this._streamState.ended) {
        return;
      }

      this._streamState.ended = true;
      this._streamState.stream.cancel();
    }

    this._attempt++;

    // Get and save the current CSRF token, and use it in the request.
    this._lastCSRFToken = getCSRFToken();
    this._request.getMetadata()[CSRF_METADATA_KEY] = this._lastCSRFToken;

    if (sendDataToDevTools) {
      sendDataToDevTools(
        true,
        this._requestDesc,
        this._request.getRequestMessage().toObject(),
        undefined,
        undefined
      );
    }

    // Start the stream.
    const stream = this._invoker(this._request);

    this._streamState = {
      stream,
      receivedData: false,
      ended: false
    };

    // Register any callbacks with the stream that we don't intercept.
    for (const eventType in this._callbacks) {
      this._callbacks[eventType as EventType].forEach((callback: any) => {
        if (eventType === "status") {
          stream.on("status", callback);
        } else if (eventType === "metadata") {
          stream.on("metadata", callback);
        } else if (eventType === "end") {
          /* Handle in intercept. */
        } else if (eventType === "data") {
          /* Handle in intercept. */
        } else if (eventType === "error") {
          /* Handle in intercept. */
        }
      });
    }

    // Register the callbacks that we intercept.
    stream.on("data", this._onData.bind(this));
    stream.on("end", this._onEnd.bind(this));
    stream.on("error", this._onError.bind(this));
  }

  /*
   * Internal "data" event handler.
   *
   * Mark when we receive data on the stream so we don't attempt our refresh
   * logic in the future, then pass the data to each callback.
   */
  _onData(data: any) {
    this._streamState.receivedData = true;

    if (sendDataToDevTools) {
      sendDataToDevTools(
        true,
        this._requestDesc,
        undefined,
        data.toObject(),
        undefined
      );
    }

    const callbacks = this._callbacks["data" as EventType];
    if (callbacks) {
      callbacks.forEach((callback: (arg0: any) => any) => callback(data));
    }
  }

  /*
   * Internal "end" event handler.
   *
   * Mark when we receive data on the stream so we don't attempt our refresh
   * logic in the future, then pass the data to each callback.
   */
  _onEnd() {
    this._streamState.ended = true;

    if (sendDataToDevTools) {
      sendDataToDevTools(true, this._requestDesc, undefined, "EOF", undefined);
    }

    const callbacks = this._callbacks["end" as EventType];
    if (callbacks) {
      callbacks.forEach((callback: () => any) => callback());
    }
  }

  /*
   * Internal "error" event handler.
   *
   * If we receive an EXPIRED error, we have not yet received any data on the
   * stream, and we have not exceeded our MAX_FETCH_ATTEMPTS, then refresh the
   * token and restart the stream.
   *
   * Otherwise, send the error to the upstream callbacks.
   */
  async _onError(error: any) {
    const streamHasReceivedData = this._streamState.receivedData;
    const isExpired = isExpiredError(error);

    if (
      !streamHasReceivedData &&
      isExpired &&
      this._attempt < MAX_FETCH_ATTEMPTS
    ) {
      try {
        await refreshCSRFToken(this._lastCSRFToken);
        this._startStream();
        return;
      } catch (_) {
        // Fall through to allow the upstream callbacks to get the error.
      }
    }

    if (sendDataToDevTools) {
      sendDataToDevTools(true, this._requestDesc, undefined, undefined, error);
    }

    // Otherwise, bubble up the error.
    const callbacks = this._callbacks["error" as EventType];
    if (callbacks) {
      callbacks.forEach((callback: (arg0: any) => any) => callback(error));
    }
  }

  /** @override */
  on(eventType: string, callback: any) {
    // Save the callback, in case we need to restart the stream. We also use
    // this._callbacks in our onData() and onError() handlers.
    if (this._callbacks[eventType as EventType]) {
      this._callbacks[eventType as EventType].push(callback);
    } else {
      this._callbacks[eventType as EventType] = [callback];
    }

    // Register the callback with the stream if it is not handled internally,
    // e.g., "data", "end" and "error".
    //
    // The other known event types are "status" and "metadata".
    if (!["data", "end", "error"].includes(eventType)) {
      this._streamState.stream.on(eventType, callback);
    }

    return this;
  }

  /** @override */
  removeListener(eventType: string, callback: any) {
    this._streamState.stream.removeListener(eventType, callback);
  }

  /** @override */
  cancel() {
    this._streamState.stream.cancel();
    return this;
  }
}

/**
 * AuthenticatingStreamInterceptor is a StreamInterceptor that handles the CSRF
 * injection and refresh logic for our callback-based grpcweb requests.
 *
 * For information on creating interceptors for grpcweb, see
 * https://grpc.io/blog/grpc-web-interceptor/.
 *
 */
export class AuthenticatingStreamInterceptor<
  REQ extends Message,
  RESP extends Message
> implements StreamInterceptor<REQ, RESP> {
  intercept(
    request: Request<REQ, RESP>,
    invoker: (request: Request<REQ, RESP>) => ClientReadableStream<RESP>
  ): ClientReadableStream<RESP> {
    return new AuthenticatingWrappedStream(request, invoker);
  }
}

// Returns true if the error is EXPIRED.
function isExpiredError(e: any) {
  e = extractErrorFromStatus(e);
  return kindOf(e).number === errorPb.Kind.Option.EXPIRED;
}

// Promise-based client for the grpcweb service. Only supports unary grpc calls.
export const GRPCWebClient = new WebClient(
  process.env.REACT_APP_GRPCWEB_URI || "",
  null /* credentials */,
  {
    unaryInterceptors: [new AuthenticatingUnaryInterceptor()]
  }
);

// Callback-based client for the grpcweb service. Supports both
// unary and streaming calls, though the promise-based client is much easier
// to work with for unary calls.
export const GRPCWebCallbackClient = new WebClient(
  process.env.REACT_APP_GRPCWEB_URI || "",
  null /* credentials */,
  {
    streamInterceptors: [new AuthenticatingStreamInterceptor()]
  }
);
