import * as proto from "Common/utils/proto";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";

import { GetCreatedGoogleAdsCampaignsRequest } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";

// Queries for all of created Amazon product campaigns for the specified
// site alias.
export async function queryAmpdCampaigns(siteAlias) {
  const req = proto.set(new GetCreatedGoogleAdsCampaignsRequest(), {
    siteAlias
  });

  const campaignInfoList = [];

  return streamProcessor(
    GRPCWebCallbackClient.getCreatedGoogleAdsCampaigns(req),
    response => {
      // Start the list with a blank info for the site itself, even if there are no campaigns,
      // but only on the first streamed reply.  This way, the callers knows the data was loaded,
      // but were are no campaigns.
      if (!campaignInfoList.length) {
        campaignInfoList.push({
          siteAlias
        });
      }
      campaignInfoList.push(
        ...response.toObject().campaignInfosList.map(campaignInfo => {
          campaignInfo.siteAlias = siteAlias;

          return campaignInfo;
        })
      );
    }
  )
    .then(() => {
      return { campaignInfoList };
    })
    .catch(err => {
      return { campaignInfoList: [], err };
    });
}
