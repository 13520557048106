// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignsRequest,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { UpdateCampaignMaxCPCAction } from "Common/proto/googleAdsPb/campaign_pb";
import { roundMicrosToCurrencyMinimumUnit } from "Common/utils/googleAds";
import { sendGAEvent } from "../components/GA";

export async function updateCampaignMaxCPC(
  siteAlias,
  gaCategory,
  customerId,
  campaignId,
  newMaxCPCMicros,
  currencyCode
) {
  if (!siteAlias || !customerId || !campaignId || newMaxCPCMicros == null) {
    return;
  }

  const action = proto.set(new UpdateCampaignAction(), {
    updateMaxCpc: proto.set(new UpdateCampaignMaxCPCAction(), {
      campaignId: String(campaignId),
      // Google Ads doesn't like values that are less the currency's minimum unit,
      // so we need to round the micro values to it.
      maxCpcMicros: roundMicrosToCurrencyMinimumUnit(
        newMaxCPCMicros,
        currencyCode
      )
    })
  });

  sendGAEvent(gaCategory, "Update Max CPC", siteAlias, `${newMaxCPCMicros}`);

  const updateReq = proto.set(new UpdateCampaignsRequest(), {
    siteAlias,
    customerId,
    actions: [action]
  });

  await GRPCWebClient.updateCampaigns(updateReq);
}
