import { useMemo } from "react";

import { useQueries } from "@tanstack/react-query";
import { streamProcessor } from "Common/utils/grpcStreams";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { None } from "Common/utils/tsUtils";
import useMakeQueriesStable from "ExtensionV2/state/useMakeQueriesStable";
import Immutable from "immutable";
import {
  DashboardTableMetrics,
  DashboardTableObjectType
} from "ExtensionV2/queries/useDashboardTableMetrics";
import {
  DashboardTable as DashboardTableProto,
  GetSiteDashboardTableMetricsReply,
  GetSiteDashboardTableMetricsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import { buildDateRangeV2Proto } from "Common/utils/DateUtilities";
import { Retailer } from "Common/proto/common/retailer_pb";

function useMultiSiteWalmartAmpdCampaignMetrics({
  campaignIdsBySiteMap,
  dateRangeStartDate,
  dateRangeEndDate,
  queryGroup = "campaignMetrics"
}: {
  campaignIdsBySiteMap: Immutable.Map<string, string[]>;
  dateRangeStartDate: string | None;
  dateRangeEndDate: string | None;
  queryGroup?: string;
}): {
  sitesLoadingSet: Immutable.Set<string>;
  sitesErrorMap: Immutable.Map<string, unknown>;
  campaignMetricsMap: Immutable.Map<string, DashboardTableMetrics>;
} {
  const oneHour = 1000 * 60 * 60; //one hour

  const { dateRangeProto } = buildDateRangeV2Proto(
    dateRangeStartDate,
    dateRangeEndDate
  );

  const siteAliases = useMemo(() => Array.from(campaignIdsBySiteMap.keys()), [
    campaignIdsBySiteMap
  ]);

  // useQueries does not return a stable result since it is a new array every time,
  // but the elements of the array should reach a stable state eventually.
  const results = useMakeQueriesStable(
    useQueries({
      queries: siteAliases.map(siteAlias => {
        const campaignIds = campaignIdsBySiteMap.get(siteAlias) || [];

        return {
          queryKey: [
            queryGroup,
            siteAlias,
            dateRangeStartDate,
            dateRangeEndDate,
            campaignIds
          ],
          staleTime: oneHour,
          cacheTime: oneHour,
          enabled: !!siteAlias && !!dateRangeProto,
          queryFn: async () => {
            let dashboardTableMetricsByCampaignId = Immutable.Map<
              string,
              DashboardTableMetrics
            >();
            // Don't query with any empty list of campaign ids, because, besides being unnecessary,
            // the query will return metrics for all campaigns instead of no campaigns.  Normally,
            // we would disable the query, but we want the empty map, so each site has a map.
            if (campaignIds.length === 0) {
              return dashboardTableMetricsByCampaignId;
            }

            const req = new GetSiteDashboardTableMetricsRequest();
            req.setSiteAlias(siteAlias);
            req.setRetailer(Retailer.Option.WALMART);
            req.setDateRange(dateRangeProto);
            req.setObjectTypesList([DashboardTableObjectType.CAMPAIGN]);

            const campaignsFilter = new DashboardTableProto.CampaignsFilter();
            campaignsFilter.setCampaignIdsList(campaignIds);

            const objectFilter = new DashboardTableProto.ObjectFilter();
            objectFilter.setCampaignsFilter(campaignsFilter);

            req.setFiltersList([objectFilter]);

            await streamProcessor(
              GRPCWebCallbackClient.getSiteDashboardTableMetrics(req),
              (reply: GetSiteDashboardTableMetricsReply) => {
                reply
                  .getDashboardTableMetricsList()
                  .forEach(dashboardTableMetrics => {
                    const campaignId = dashboardTableMetrics.getCampaignId();

                    dashboardTableMetricsByCampaignId = dashboardTableMetricsByCampaignId.set(
                      campaignId,
                      dashboardTableMetrics.toObject()
                    );
                  });
              }
            );

            return dashboardTableMetricsByCampaignId;
          }
        };
      })
    })
  );

  const hookState = useMemo(() => {
    let sitesLoadingSet = Immutable.Set<string>();
    let sitesErrorMap = Immutable.Map<string, unknown>();
    let campaignMetricsMap = Immutable.Map<string, DashboardTableMetrics>();

    siteAliases.forEach((siteAlias, index) => {
      const result = results[index];
      if (!result) {
        return;
      }

      if (result.isFetching) {
        sitesLoadingSet = sitesLoadingSet.add(siteAlias);
      }
      if (result.error) {
        sitesErrorMap = sitesErrorMap.set(siteAlias, result.error);
      }
      if (result.data) {
        campaignMetricsMap = campaignMetricsMap.merge(result.data);
      }
    });

    return {
      sitesLoadingSet,
      sitesErrorMap,
      campaignMetricsMap
    };
  }, [results, siteAliases]);

  return hookState;
}

export default useMultiSiteWalmartAmpdCampaignMetrics;
