import moment from "moment";

import { QUERY_STRING_DATE_FORMAT } from "ExtensionV2/components/GlobalDatePicker";

export const COMPARE_TO_PREVIOUS = "previous";
export const COMPARE_TO_END_PREFIX = "-";

function getCompareToDates(dateRangeStartDate, dateRangeEndDate, compareTo) {
  if (!dateRangeStartDate || !dateRangeEndDate || !compareTo) {
    return [];
  }

  const diff = moment(dateRangeEndDate).diff(
    moment(dateRangeStartDate),
    "days"
  );

  let compareRangeEndDate;
  if (compareTo === COMPARE_TO_PREVIOUS) {
    compareRangeEndDate = moment(dateRangeStartDate)
      .subtract(1, "days")
      .format(QUERY_STRING_DATE_FORMAT);
  } else if (compareTo.startsWith(COMPARE_TO_END_PREFIX)) {
    // If the value is a '-' followed by a valid date, treat it as the end of
    // a custom compare range.
    const compareToDate = moment(compareTo.slice(COMPARE_TO_END_PREFIX.length));
    if (compareToDate.isValid()) {
      compareRangeEndDate = compareToDate.format(QUERY_STRING_DATE_FORMAT);
    }
  } else {
    // If the value is a valid date, treat it as the start of a custom
    // compare range.
    const compareToDate = moment(compareTo);
    if (compareToDate.isValid()) {
      compareRangeEndDate = compareToDate
        .add(diff, "days")
        .format(QUERY_STRING_DATE_FORMAT);
    }
  }

  if (!compareRangeEndDate) {
    return [];
  }

  const compareRangeStartDate = moment(compareRangeEndDate)
    .subtract(diff, "days")
    .format(QUERY_STRING_DATE_FORMAT);

  return [compareRangeStartDate, compareRangeEndDate];
}

export default getCompareToDates;
