import { useEffect } from "react";
import ReactGA from "react-ga";

import { GA_AMPD_TRACKER } from "Common/Common";
// The time that this flow began, e.g., the module was loaded by React.
// Reloading the page starts a new flow.
const flowStartTime = new Date().getTime();

// The version of this flow. We can change this value to compare the behavior
// between different versions of our app.
const flowVersion = "10";

function useGAEvent(category, action, siteAlias) {
  useEffect(() => {
    sendGAEvent(category, action, siteAlias);
  }, [category, action, siteAlias]);
}

function useGTMEvent(dataLayerObject) {
  useEffect(() => {
    sendGTMEvent(dataLayerObject);
  }, [dataLayerObject]);
}

// sendGTMEvent allows data to be pushed to Google Tag Manager (GTM). GTM
// is a container that is included on all pages and track events, fire pixels
// and send page views based on events and data.

// Important Note — GTM connects to Google Analytics, Google Ads, Facebook Ads
// and is a general way to trigger events and send data to the appropriate
// ads or analytics platform.
function sendGTMEvent(dataLayerObject) {
  // Send an event to Google Tag Manager (if not initialized, then use the default array method)
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObject);
}

function sendGAEvent(category, action, siteAlias, label) {
  const platform = "web";

  const eventInfo = {
    category: category,
    action: action,
    label: label || "",
    dimension1: new Date().getTime(), // unix timestamp millis
    dimension2: siteAlias,
    dimension3: flowStartTime,
    dimension4: flowVersion,
    dimension5: platform
  };

  ReactGA.event(eventInfo, [GA_AMPD_TRACKER]);

  const sanitizedCategoryAction = `${category} ${action}`
    .replace("Ampd:", "")
    .replace(/\s+/g, "-")
    .toLowerCase();

  sendGTMEvent({
    event: sanitizedCategoryAction,
    categoryAction: `${category} ${action}`,
    category: category,
    action: action,
    label: label,
    alias: siteAlias
  });
}

export { useGAEvent, sendGAEvent, useGTMEvent, sendGTMEvent };
