import React from "react";
import { Icon } from "semantic-ui-react";

import { getURLForHubSpotCompany } from "Common/utils/hubspot";

function HubspotLink({ hubspotCompanyId, siteName }) {
  if (!hubspotCompanyId && !siteName) {
    return null;
  }

  return (
    <a
      href={getURLForHubSpotCompany(hubspotCompanyId, siteName)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon style={{ float: "right" }} link name={"hubspot"} />
    </a>
  );
}

export default HubspotLink;
