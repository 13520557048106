import { googleAdsGeotargetNameMap } from "Common/utils/googleAds";
import GeotargetDropdown from "ExtensionV2/components/GeotargetDropdown";
import React, { useMemo, useState } from "react";
import { Message } from "semantic-ui-react";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";

const geotargetsValidator = (
  _geotargets: Array<number> | null
): Set<string> => {
  const errors = new Set<string>();
  // TODO: add more validation as necessary. Geotargets field for a campaign can be empty because we
  // will fall back to the default geotargets if none is provided.
  return errors;
};

export const geotargetsField = (
  initialValue: Array<number>
): FormField<Array<number>> => ({
  _value: initialValue,
  errors: geotargetsValidator(initialValue),
  validator: geotargetsValidator
});

export const GeotargetsReviewSection = ({
  state,
  dispatch,
  onEdit
}: {
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;

  const [campaign, { selectGeotargets }] = getCurrentCampaignAndSelectors(
    state
  );
  const {
    geotargetsField: {
      validator: geotargetsValidator,
      errors: geotargetsErrors
    }
  } = campaign;
  const geotargets = selectGeotargets(campaign, state);

  const [tempGeotargets, setTempGeotargets] = useState<Array<number>>(
    geotargets
  );

  const [localErrors, setLocalErrors] = useState<Set<string>>(geotargetsErrors);

  const geotargetNames = useMemo(() => {
    return geotargets.map(gt => googleAdsGeotargetNameMap.get(gt));
  }, [geotargets]);

  const dataComponent = <p>{geotargetNames.join(", ")}</p>;

  const editComponent = (
    <>
      <GeotargetDropdown
        geotargets={tempGeotargets}
        setGeotargets={(targets: Array<number>) => setTempGeotargets(targets)}
        onGeotargetsInputBlur={() => {
          const errors = geotargetsValidator(tempGeotargets);
          setLocalErrors(errors);
        }}
      />
      {localErrors.size > 0 && (
        <Message negative>
          {Array.from(localErrors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  const handleSave = () => {
    dispatch({
      name: "UpdateGeotargets",
      data: {
        index: currentReviewCampaignIndex,
        geotargets: [...tempGeotargets]
      }
    });
  };

  const handleCancel = () => {
    setTempGeotargets(geotargets);
  };

  return (
    <CampaignReviewSection
      dataName="Ad Locations"
      dataComponent={dataComponent}
      disableSave={localErrors.size > 0 || tempGeotargets.length === 0}
      editComponent={editComponent}
      errors={geotargetsErrors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
