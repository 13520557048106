import React, { useState } from "react";

import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnAnnotation,
  getMetricColumnCompareInfo,
  renderFormattedValue,
  STATUS_ENABLED_STR
} from "./MetricColumns";

import { KeywordStatusCellContent } from "./CampaignDetailsTableRow";
import { ReactComponent as Indent } from "assets/indent.svg";
import { ReactComponent as IndentLast } from "assets/indent-last.svg";
import {
  KeywordTextCell,
  KeywordTextFlexWrapper,
  ActiveKeywordText,
  InactiveKeywordText,
  NotApplicableCell
} from "./CampaignsTableRow";
import {
  DataTableRow,
  DataTableRowCell,
  DataTableMetricCell,
  AMPD_SELECTED_ROW_FOCUS_CLASS
} from "./AmpdDataTable";
import KeywordCPCBidCellEditor from "./KeywordCPCBidCellEditor";

const CampaignsTableKeywordRow = ({
  campaignId,
  columns,
  costCurrencyCode,
  googleAdsAccountId,
  isFocalRow,
  isLastRow,
  keywordObject,
  revenueCurrencyCode,
  rowIndex,
  showFractions,
  showUnconvertedRevenue,
  siteAlias,
  disabled,
  refetchCampaignKeyword
}) => {
  const [willChange, setWillChange] = useState(false);

  return (
    <DataTableRow
      style={{ fontSize: "small" }}
      isLastKeywordRow={isLastRow}
      disabled={disabled || willChange}
    >
      {(columns || []).map(columnName => {
        switch (columnName) {
          case COLUMN_DATA_KEYS.campaignName:
            return (
              <KeywordTextCell key={columnName} rowIndex={rowIndex}>
                <KeywordTextFlexWrapper
                  className={
                    isFocalRow ? AMPD_SELECTED_ROW_FOCUS_CLASS : undefined
                  }
                >
                  {isLastRow ? <IndentLast /> : <Indent />}

                  {keywordObject[COLUMN_DATA_KEYS.status] === "ENABLED" ? (
                    <ActiveKeywordText>
                      {keywordObject[COLUMN_DATA_KEYS.keywords]}
                    </ActiveKeywordText>
                  ) : (
                    <InactiveKeywordText>
                      {keywordObject[COLUMN_DATA_KEYS.keywords]}
                    </InactiveKeywordText>
                  )}
                </KeywordTextFlexWrapper>
              </KeywordTextCell>
            );
          case COLUMN_DATA_KEYS.status:
            return (
              <DataTableRowCell
                key={columnName}
                rowIndex={rowIndex}
                style={{ paddingLeft: "2.0em" }}
              >
                <KeywordStatusCellContent
                  siteAlias={siteAlias}
                  campaignId={campaignId}
                  keywordStatus={keywordObject[COLUMN_DATA_KEYS.status]}
                  googleAdsAccountId={googleAdsAccountId}
                  adGroupId={keywordObject.adGroupId}
                  criteriaId={keywordObject.criteriaId}
                  setWillChange={setWillChange}
                  refetchCampaignKeyword={refetchCampaignKeyword}
                />
              </DataTableRowCell>
            );
          case COLUMN_DATA_KEYS.cpcBid: {
            let isAuto = !keywordObject[
              COLUMN_DATA_KEYS.effectiveDefaultCpcBid
            ];
            const cpcBid = keywordObject[columnName];
            let value = cpcBid;
            let isDefault = false;
            if (value === 0) {
              value = keywordObject[COLUMN_DATA_KEYS.effectiveCpcBid];
              isDefault = true;
            }
            if (value === 0) {
              isAuto = true;
            }
            let cellContent;
            if (keywordObject[COLUMN_DATA_KEYS.status] !== STATUS_ENABLED_STR) {
              cellContent = "";
            } else if (isAuto) {
              cellContent = "(up to max)";
            } else {
              const formattedCpcBid = formatMetricColumnValue(
                columnName,
                value,
                costCurrencyCode,
                revenueCurrencyCode,
                true
              );

              cellContent = (
                <KeywordCPCBidCellEditor
                  key={`${isDefault}:${value}`}
                  disabled={disabled || willChange}
                  siteAlias={siteAlias}
                  campaignId={campaignId}
                  adGroupId={keywordObject[COLUMN_DATA_KEYS.adGroupId]}
                  criteriaId={keywordObject[COLUMN_DATA_KEYS.criteriaId]}
                  cpcBid={cpcBid}
                  formattedCpcBid={`${
                    isDefault ? "Default: " : ""
                  }${formattedCpcBid}`}
                  currencyCode={costCurrencyCode}
                  googleAdsAccountId={googleAdsAccountId}
                  setWillChange={setWillChange}
                  refetchCampaignKeyword={refetchCampaignKeyword}
                />
              );
            }

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {cellContent}
              </DataTableMetricCell>
            );
          }
          case COLUMN_DATA_KEYS.dailyBudget:
          case COLUMN_DATA_KEYS.adTarget:
          case COLUMN_DATA_KEYS.campaignStartDate:
          case COLUMN_DATA_KEYS.bidAutomationStatus:
            return <NotApplicableCell key={columnName} rowIndex={rowIndex} />;
          default: {
            const formattedValue = formatMetricColumnValue(
              columnName,
              keywordObject[columnName],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            const annotationValue = getMetricColumnAnnotation(
              columnName,
              keywordObject,
              keywordObject[COLUMN_DATA_KEYS.unconvertedRevenueCurrencyCode],
              showFractions,
              showUnconvertedRevenue
            );

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              columnName,
              keywordObject,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(
                  formattedValue,
                  annotationValue,
                  compareInfo
                )}
              </DataTableMetricCell>
            );
          }
        }
      })}
    </DataTableRow>
  );
};

export default CampaignsTableKeywordRow;
