import React from "react";
import { Divider } from "semantic-ui-react";

import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";

import { Stripe } from "Common/proto/entity/billingAccount_pb";
import styled from "styled-components";

const PricingDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > p:first-child {
    max-width: 75%;
  }
`;

export const StripeInvoiceView = ({
  invoice,
  totalLabel = "Total",
  zeroLabel
}: {
  invoice: Stripe.Invoice.AsObject;
  totalLabel?: string;
  zeroLabel?: string;
}): JSX.Element => {
  const currencyMetricDef = getCurrencyMetricDef("USD", true);

  const zeroDisplayValue = zeroLabel || formatMetric(currencyMetricDef, 0);

  const lineItems = invoice.lineItemsList.map((item, i) => {
    const formattedAmount = formatMetric(
      currencyMetricDef,
      item.amount / 100 // Stripe stores USD amounts in cents
    );

    return (
      <PricingDetailRow key={i}>
        <p>{item.description}</p>
        <p>{item.amount === 0 ? zeroDisplayValue : formattedAmount}</p>
      </PricingDetailRow>
    );
  });

  const discounts = invoice.discountsList.map((discount, i) => {
    if (!discount.coupon) {
      return null;
    }

    const formattedAmount = discount.coupon.amountOff
      ? formatMetric(currencyMetricDef, (-1 * discount.coupon.amountOff) / 100)
      : `- ${discount.coupon.percentOff}%`;

    return (
      <PricingDetailRow key={i}>
        <p>{discount.description}</p>
        <p>{formattedAmount}</p>
      </PricingDetailRow>
    );
  });

  return (
    <div>
      {lineItems}
      {discounts}
      <Divider fitted style={{ margin: "0.5em 0" }} />
      <PricingDetailRow style={{ fontSize: "18px" }}>
        <p>
          <b>{totalLabel}</b>
        </p>
        <b>
          <p>{formatMetric(currencyMetricDef, invoice.amountDue / 100)}</p>
        </b>
      </PricingDetailRow>
    </div>
  );
};
