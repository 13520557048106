import _ from "lodash";
import { useQuery } from "react-apollo";
import { SESSION } from "../graphql";
import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { logout } from "Common/utils/auth";

const EmptySessionState = {
  isAuthenticated: false,
  id: null,
  currentSite: null,
  session: null
};

function useSessionState(topLevelPath) {
  const noSession = localStorage.getItem("is_authenticated") === null;
  const siteAlias = getSiteAliasFromURL(topLevelPath);

  const { data, loading, error, refetch: reloadSessionState } = useQuery(
    SESSION,
    {
      skip: noSession
    }
  );

  if (noSession) {
    return [EmptySessionState, false];
  }

  if (loading) {
    return [EmptySessionState, true];
  }

  if (error) {
    console.error(error);
    logout();

    return [EmptySessionState, false];
  }

  const userSites = _.get(data, "session.userSites", []);
  let currentUserSite = null;

  // If the siteAlias is set, choose that site. Otherwise, so long as there are
  // sites, choose the first one.
  if (siteAlias) {
    currentUserSite =
      _.find(userSites, userSite => siteAlias === userSite.site.siteAlias) ||
      null;

    if (!currentUserSite) {
      logout();
      console.error(`Could not find site matching ${siteAlias}`);

      return [EmptySessionState, false];
    }
  } else if (userSites.length > 0) {
    currentUserSite = userSites[0];
  }

  const currentSiteAlias = _.get(currentUserSite, "site.siteAlias", null);
  const site = _.get(currentUserSite, "site", null);
  const isSiteAdmin = _.get(currentUserSite, "isSiteAdmin", false);

  return [
    {
      isAuthenticated: !!_.get(data, "session", false),
      id: _.get(data, "session.user.id", null),
      currentSite: currentSiteAlias,
      session: {
        ..._.get(data, "session", null),
        site,
        isSiteAdmin,
        reload: reloadSessionState
      }
    },
    false
  ];
}

export default useSessionState;
