import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Icon } from "semantic-ui-react";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import getInvoices from "ExtensionV2/grpc/getInvoices";
import { extractErrorMessage } from "Common/errors/error";
import { formatStripeCurrency } from "./utils";
import { asPrettyDateNoDay, fromISO8601Date } from "Common/utils/DateUtilities";
import { sendGAEvent } from "../GA";

const PAGE_GA_CATEGORY = "Ampd: Customer Invoices";
const PAID_STATUS = "PAID";
const OPEN_STATUS = "OPEN"; // ie: invoice sent but payment not yet received

export const InvoiceRow = props => {
  const { invoice, siteAlias, gaCategory } = props;

  const gaEvent = name => sendGAEvent(gaCategory, name, siteAlias);
  const billingDate = fromISO8601Date(invoice.createdAt);

  return (
    <Table.Row>
      <Table.Cell>
        {invoice.status === PAID_STATUS ? (
          <Icon name="check circle" color="green" size="large" />
        ) : (
          <Icon name="circle outline" color="yellow" size="large" />
        )}
      </Table.Cell>

      <Table.Cell>
        {formatStripeCurrency(invoice.amountDue, invoice.currency)}
      </Table.Cell>

      <Table.Cell>{invoice.currency}</Table.Cell>

      <Table.Cell>{asPrettyDateNoDay(billingDate)}</Table.Cell>

      <Table.Cell>
        <a
          href={invoice.urlSite}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEvent("Invoice page link")}
        >
          Invoice {invoice.invoiceNumber}
        </a>
      </Table.Cell>

      <Table.Cell>
        <a
          href={invoice.urlFile}
          rel="noopener noreferrer"
          onClick={() => gaEvent("Invoice PDF click")}
        >
          <Icon name="file pdf outline" color="black" size="large" />
        </a>
      </Table.Cell>
    </Table.Row>
  );
};

export const InvoicesTable = ({ siteAlias }) => {
  const [invoices, setInvoices] = useState(null);
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  useEffect(() => {
    // Log view for Site
    sendGAEvent(PAGE_GA_CATEGORY, "View invoices modal", siteAlias);

    // Get invoices data
    setLoadingInvoices(true);
    getInvoices({ siteAlias })
      .then(invoiceList => {
        const invoicesToShow = invoiceList.filter(
          invoice =>
            invoice.status === PAID_STATUS || invoice.status === OPEN_STATUS
        );

        setInvoices(invoicesToShow);
      })
      .catch(err => {
        console.error(extractErrorMessage(err));
        setInvoices(null);
      })
      .finally(() => {
        setLoadingInvoices(false);
      });
  }, [siteAlias]);

  const NoInvoices = (
    <Table.Row style={{ fontWeight: "bold", textAlign: "center" }}>
      <Table.Cell colSpan={6}>No Invoices Available</Table.Cell>
    </Table.Row>
  );

  return loadingInvoices ? (
    <LoadingSpinner>Loading keyword ideas</LoadingSpinner>
  ) : (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Paid</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Currency</Table.HeaderCell>
          <Table.HeaderCell>Billing Date</Table.HeaderCell>
          <Table.HeaderCell>Stripe Invoice Link</Table.HeaderCell>
          <Table.HeaderCell>PDF</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {invoices?.length
          ? invoices.map((invoice, idx) => (
              <InvoiceRow
                invoice={invoice}
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                key={idx}
              />
            ))
          : NoInvoices}
      </Table.Body>
    </Table>
  );
};

const InvoicesModal = props => {
  const { open, onClose, siteAlias } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose} size="large">
      <Modal.Header>Stripe Invoices</Modal.Header>
      <Modal.Content scrolling>
        <InvoicesTable siteAlias={siteAlias} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default InvoicesModal;
