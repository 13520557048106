// source: google/ads/googleads/v16/common/asset_set_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_enums_chain_relationship_type_pb = require('../../../../../google/ads/googleads/v16/enums/chain_relationship_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_chain_relationship_type_pb);
var google_ads_googleads_v16_enums_location_ownership_type_pb = require('../../../../../google/ads/googleads/v16/enums/location_ownership_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_location_ownership_type_pb);
var google_ads_googleads_v16_enums_location_string_filter_type_pb = require('../../../../../google/ads/googleads/v16/enums/location_string_filter_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_location_string_filter_type_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.BusinessProfileLocationSet', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ChainFilter', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ChainLocationGroup', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ChainSet', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.LocationSet', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.LocationSet.SourceCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.MapsLocationInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.MapsLocationSet', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.LocationSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v16.common.LocationSet.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v16.common.LocationSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.LocationSet.displayName = 'proto.google.ads.googleads.v16.common.LocationSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.BusinessProfileLocationSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.displayName = 'proto.google.ads.googleads.v16.common.BusinessProfileLocationSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ChainSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.ChainSet.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ChainSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ChainSet.displayName = 'proto.google.ads.googleads.v16.common.ChainSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ChainFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.ChainFilter.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ChainFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ChainFilter.displayName = 'proto.google.ads.googleads.v16.common.ChainFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.MapsLocationSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.MapsLocationSet.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.MapsLocationSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.MapsLocationSet.displayName = 'proto.google.ads.googleads.v16.common.MapsLocationSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.MapsLocationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.MapsLocationInfo.displayName = 'proto.google.ads.googleads.v16.common.MapsLocationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.displayName = 'proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.displayName = 'proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.displayName = 'proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.ChainLocationGroup.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ChainLocationGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ChainLocationGroup.displayName = 'proto.google.ads.googleads.v16.common.ChainLocationGroup';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v16.common.LocationSet.oneofGroups_ = [[1,2,5]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v16.common.LocationSet.SourceCase = {
  SOURCE_NOT_SET: 0,
  BUSINESS_PROFILE_LOCATION_SET: 1,
  CHAIN_LOCATION_SET: 2,
  MAPS_LOCATION_SET: 5
};

/**
 * @return {proto.google.ads.googleads.v16.common.LocationSet.SourceCase}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.getSourceCase = function() {
  return /** @type {proto.google.ads.googleads.v16.common.LocationSet.SourceCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v16.common.LocationSet.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.LocationSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.LocationSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.LocationSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationOwnershipType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    businessProfileLocationSet: (f = msg.getBusinessProfileLocationSet()) && proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.toObject(includeInstance, f),
    chainLocationSet: (f = msg.getChainLocationSet()) && proto.google.ads.googleads.v16.common.ChainSet.toObject(includeInstance, f),
    mapsLocationSet: (f = msg.getMapsLocationSet()) && proto.google.ads.googleads.v16.common.MapsLocationSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.LocationSet}
 */
proto.google.ads.googleads.v16.common.LocationSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.LocationSet;
  return proto.google.ads.googleads.v16.common.LocationSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.LocationSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.LocationSet}
 */
proto.google.ads.googleads.v16.common.LocationSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.LocationOwnershipTypeEnum.LocationOwnershipType} */ (reader.readEnum());
      msg.setLocationOwnershipType(value);
      break;
    case 1:
      var value = new proto.google.ads.googleads.v16.common.BusinessProfileLocationSet;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.deserializeBinaryFromReader);
      msg.setBusinessProfileLocationSet(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v16.common.ChainSet;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.ChainSet.deserializeBinaryFromReader);
      msg.setChainLocationSet(value);
      break;
    case 5:
      var value = new proto.google.ads.googleads.v16.common.MapsLocationSet;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.MapsLocationSet.deserializeBinaryFromReader);
      msg.setMapsLocationSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.LocationSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.LocationSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.LocationSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationOwnershipType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBusinessProfileLocationSet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.serializeBinaryToWriter
    );
  }
  f = message.getChainLocationSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v16.common.ChainSet.serializeBinaryToWriter
    );
  }
  f = message.getMapsLocationSet();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.google.ads.googleads.v16.common.MapsLocationSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.LocationOwnershipTypeEnum.LocationOwnershipType location_ownership_type = 3;
 * @return {!proto.google.ads.googleads.v16.enums.LocationOwnershipTypeEnum.LocationOwnershipType}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.getLocationOwnershipType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.LocationOwnershipTypeEnum.LocationOwnershipType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.LocationOwnershipTypeEnum.LocationOwnershipType} value
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.setLocationOwnershipType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional BusinessProfileLocationSet business_profile_location_set = 1;
 * @return {?proto.google.ads.googleads.v16.common.BusinessProfileLocationSet}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.getBusinessProfileLocationSet = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.BusinessProfileLocationSet, 1));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.BusinessProfileLocationSet|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
*/
proto.google.ads.googleads.v16.common.LocationSet.prototype.setBusinessProfileLocationSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.google.ads.googleads.v16.common.LocationSet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.clearBusinessProfileLocationSet = function() {
  return this.setBusinessProfileLocationSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.hasBusinessProfileLocationSet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChainSet chain_location_set = 2;
 * @return {?proto.google.ads.googleads.v16.common.ChainSet}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.getChainLocationSet = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.ChainSet} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.ChainSet, 2));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.ChainSet|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
*/
proto.google.ads.googleads.v16.common.LocationSet.prototype.setChainLocationSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.google.ads.googleads.v16.common.LocationSet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.clearChainLocationSet = function() {
  return this.setChainLocationSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.hasChainLocationSet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MapsLocationSet maps_location_set = 5;
 * @return {?proto.google.ads.googleads.v16.common.MapsLocationSet}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.getMapsLocationSet = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.MapsLocationSet} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.MapsLocationSet, 5));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.MapsLocationSet|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
*/
proto.google.ads.googleads.v16.common.LocationSet.prototype.setMapsLocationSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.google.ads.googleads.v16.common.LocationSet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.LocationSet} returns this
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.clearMapsLocationSet = function() {
  return this.setMapsLocationSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.LocationSet.prototype.hasMapsLocationSet = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    httpAuthorizationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessNameFilter: jspb.Message.getFieldWithDefault(msg, 3, ""),
    labelFiltersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    listingIdFiltersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    businessAccountId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.BusinessProfileLocationSet;
  return proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHttpAuthorizationToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessNameFilter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabelFilters(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addListingIdFilters(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHttpAuthorizationToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessNameFilter();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabelFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getListingIdFiltersList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getBusinessAccountId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string http_authorization_token = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.getHttpAuthorizationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.setHttpAuthorizationToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_name_filter = 3;
 * @return {string}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.getBusinessNameFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.setBusinessNameFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string label_filters = 4;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.getLabelFiltersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.setLabelFiltersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.addLabelFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.clearLabelFiltersList = function() {
  return this.setLabelFiltersList([]);
};


/**
 * repeated int64 listing_id_filters = 5;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.getListingIdFiltersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.setListingIdFiltersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.addListingIdFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.clearListingIdFiltersList = function() {
  return this.setListingIdFiltersList([]);
};


/**
 * optional string business_account_id = 6;
 * @return {string}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.getBusinessAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationSet.prototype.setBusinessAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.ChainSet.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ChainSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ChainSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ChainSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationshipType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chainsList: jspb.Message.toObjectList(msg.getChainsList(),
    proto.google.ads.googleads.v16.common.ChainFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ChainSet}
 */
proto.google.ads.googleads.v16.common.ChainSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ChainSet;
  return proto.google.ads.googleads.v16.common.ChainSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ChainSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ChainSet}
 */
proto.google.ads.googleads.v16.common.ChainSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.ChainRelationshipTypeEnum.ChainRelationshipType} */ (reader.readEnum());
      msg.setRelationshipType(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v16.common.ChainFilter;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.ChainFilter.deserializeBinaryFromReader);
      msg.addChains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ChainSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ChainSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ChainSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelationshipType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getChainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.google.ads.googleads.v16.common.ChainFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.ChainRelationshipTypeEnum.ChainRelationshipType relationship_type = 1;
 * @return {!proto.google.ads.googleads.v16.enums.ChainRelationshipTypeEnum.ChainRelationshipType}
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.getRelationshipType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.ChainRelationshipTypeEnum.ChainRelationshipType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.ChainRelationshipTypeEnum.ChainRelationshipType} value
 * @return {!proto.google.ads.googleads.v16.common.ChainSet} returns this
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.setRelationshipType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated ChainFilter chains = 2;
 * @return {!Array<!proto.google.ads.googleads.v16.common.ChainFilter>}
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.getChainsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.ChainFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.common.ChainFilter, 2));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.ChainFilter>} value
 * @return {!proto.google.ads.googleads.v16.common.ChainSet} returns this
*/
proto.google.ads.googleads.v16.common.ChainSet.prototype.setChainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.ChainFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter}
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.addChains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.ads.googleads.v16.common.ChainFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.ChainSet} returns this
 */
proto.google.ads.googleads.v16.common.ChainSet.prototype.clearChainsList = function() {
  return this.setChainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.ChainFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ChainFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ChainFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ChainFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locationAttributesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter}
 */
proto.google.ads.googleads.v16.common.ChainFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ChainFilter;
  return proto.google.ads.googleads.v16.common.ChainFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ChainFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter}
 */
proto.google.ads.googleads.v16.common.ChainFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocationAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ChainFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ChainFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ChainFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocationAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int64 chain_id = 1;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter} returns this
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string location_attributes = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.getLocationAttributesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter} returns this
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.setLocationAttributesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter} returns this
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.addLocationAttributes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter} returns this
 */
proto.google.ads.googleads.v16.common.ChainFilter.prototype.clearLocationAttributesList = function() {
  return this.setLocationAttributesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.MapsLocationSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapsLocationsList: jspb.Message.toObjectList(msg.getMapsLocationsList(),
    proto.google.ads.googleads.v16.common.MapsLocationInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationSet}
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.MapsLocationSet;
  return proto.google.ads.googleads.v16.common.MapsLocationSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationSet}
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v16.common.MapsLocationInfo;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.MapsLocationInfo.deserializeBinaryFromReader);
      msg.addMapsLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.MapsLocationSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapsLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.google.ads.googleads.v16.common.MapsLocationInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MapsLocationInfo maps_locations = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.common.MapsLocationInfo>}
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.prototype.getMapsLocationsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.MapsLocationInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.common.MapsLocationInfo, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.MapsLocationInfo>} value
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationSet} returns this
*/
proto.google.ads.googleads.v16.common.MapsLocationSet.prototype.setMapsLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationInfo}
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.prototype.addMapsLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v16.common.MapsLocationInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationSet} returns this
 */
proto.google.ads.googleads.v16.common.MapsLocationSet.prototype.clearMapsLocationsList = function() {
  return this.setMapsLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.MapsLocationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationInfo}
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.MapsLocationInfo;
  return proto.google.ads.googleads.v16.common.MapsLocationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationInfo}
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.MapsLocationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.MapsLocationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.common.MapsLocationInfo} returns this
 */
proto.google.ads.googleads.v16.common.MapsLocationInfo.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    dynamicBusinessProfileLocationGroupFilter: (f = msg.getDynamicBusinessProfileLocationGroupFilter()) && proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup;
  return proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.deserializeBinaryFromReader);
      msg.setDynamicBusinessProfileLocationGroupFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDynamicBusinessProfileLocationGroupFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional DynamicBusinessProfileLocationGroupFilter dynamic_business_profile_location_group_filter = 1;
 * @return {?proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.prototype.getDynamicBusinessProfileLocationGroupFilter = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter, 1));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup} returns this
*/
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.prototype.setDynamicBusinessProfileLocationGroupFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.prototype.clearDynamicBusinessProfileLocationGroupFilter = function() {
  return this.setDynamicBusinessProfileLocationGroupFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.BusinessProfileLocationGroup.prototype.hasDynamicBusinessProfileLocationGroupFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelFiltersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    businessNameFilter: (f = msg.getBusinessNameFilter()) && proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.toObject(includeInstance, f),
    listingIdFiltersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter;
  return proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabelFilters(value);
      break;
    case 2:
      var value = new proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.deserializeBinaryFromReader);
      msg.setBusinessNameFilter(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addListingIdFilters(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBusinessNameFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.serializeBinaryToWriter
    );
  }
  f = message.getListingIdFiltersList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * repeated string label_filters = 1;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.getLabelFiltersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.setLabelFiltersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.addLabelFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.clearLabelFiltersList = function() {
  return this.setLabelFiltersList([]);
};


/**
 * optional BusinessProfileBusinessNameFilter business_name_filter = 2;
 * @return {?proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.getBusinessNameFilter = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter, 2));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
*/
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.setBusinessNameFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.clearBusinessNameFilter = function() {
  return this.setBusinessNameFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.hasBusinessNameFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated int64 listing_id_filters = 3;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.getListingIdFiltersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.setListingIdFiltersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.addListingIdFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter} returns this
 */
proto.google.ads.googleads.v16.common.DynamicBusinessProfileLocationGroupFilter.prototype.clearListingIdFiltersList = function() {
  return this.setListingIdFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filterType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter}
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter;
  return proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter}
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.LocationStringFilterTypeEnum.LocationStringFilterType} */ (reader.readEnum());
      msg.setFilterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilterType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string business_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.prototype.setBusinessName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.enums.LocationStringFilterTypeEnum.LocationStringFilterType filter_type = 2;
 * @return {!proto.google.ads.googleads.v16.enums.LocationStringFilterTypeEnum.LocationStringFilterType}
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.prototype.getFilterType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.LocationStringFilterTypeEnum.LocationStringFilterType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.LocationStringFilterTypeEnum.LocationStringFilterType} value
 * @return {!proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter} returns this
 */
proto.google.ads.googleads.v16.common.BusinessProfileBusinessNameFilter.prototype.setFilterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ChainLocationGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ChainLocationGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    dynamicChainLocationGroupFiltersList: jspb.Message.toObjectList(msg.getDynamicChainLocationGroupFiltersList(),
    proto.google.ads.googleads.v16.common.ChainFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ChainLocationGroup}
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ChainLocationGroup;
  return proto.google.ads.googleads.v16.common.ChainLocationGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ChainLocationGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ChainLocationGroup}
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v16.common.ChainFilter;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.ChainFilter.deserializeBinaryFromReader);
      msg.addDynamicChainLocationGroupFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ChainLocationGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ChainLocationGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDynamicChainLocationGroupFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.google.ads.googleads.v16.common.ChainFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChainFilter dynamic_chain_location_group_filters = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.common.ChainFilter>}
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.prototype.getDynamicChainLocationGroupFiltersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.ChainFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.common.ChainFilter, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.ChainFilter>} value
 * @return {!proto.google.ads.googleads.v16.common.ChainLocationGroup} returns this
*/
proto.google.ads.googleads.v16.common.ChainLocationGroup.prototype.setDynamicChainLocationGroupFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.ChainFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.ChainFilter}
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.prototype.addDynamicChainLocationGroupFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v16.common.ChainFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.ChainLocationGroup} returns this
 */
proto.google.ads.googleads.v16.common.ChainLocationGroup.prototype.clearDynamicChainLocationGroupFiltersList = function() {
  return this.setDynamicChainLocationGroupFiltersList([]);
};


goog.object.extend(exports, proto.google.ads.googleads.v16.common);
