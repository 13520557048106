import React, { useCallback } from "react";
import { Flex } from "@rebass/grid";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL
} from "./AccountsTable";
import { LinkToClient } from "./AccountsTableCampaignRow";

import { KeywordTextFlexWrapper } from "../../components/CampaignsTableRow";
import { InlineLoadingSpinner } from "../../components/MetricColumns";
import SimpleTooltip from "../../components/SimpleTooltip";

import { pluralize } from "Common/utils/strings";
import {
  DataTableRow,
  DataTableFreezeLeftCell,
  DataTableRowCell
} from "../../components/AmpdDataTable";
import { useSession } from "../../queries/useSession";
import UnlinkClientAccountButton from "./UnlinkClientAccountButton";

function AccountsTableCommandRow({
  clientSiteAlias,
  clientSiteLoading,
  subClientCount,
  campaignCount,
  columns,
  rowIndex,
  isLastRow
}) {
  const {
    currentSite: { siteAlias: managerSiteAlias },
    clientSitesList,
    user: { isCurrentSiteAdmin },
    invalidateSessionQuery
  } = useSession();

  const handleUnlinkAccountDone = useCallback(() => {
    if (invalidateSessionQuery) {
      invalidateSessionQuery();
    }
  }, [invalidateSessionQuery]);

  return (
    <DataTableRow style={{ fontSize: "small" }} isLastKeywordRow={isLastRow}>
      {(columns || []).map(columnName => {
        switch (columnName) {
          case CHECKED_COL:
            return (
              <DataTableFreezeLeftCell
                style={{ fontSize: "16px" }}
                key={columnName}
                rowIndex={rowIndex}
              />
            );
          case ACCOUNT_NAME_COL:
            return (
              <DataTableFreezeLeftCell
                style={{ cursor: "pointer", left: CHECKED_COL_WIDTH }}
                key={columnName}
                rowIndex={rowIndex}
              >
                <Flex
                  style={{ float: "right" }}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isCurrentSiteAdmin && (
                    <span>
                      &nbsp;&bull;&nbsp;
                      <UnlinkClientAccountButton
                        managerSiteAlias={managerSiteAlias}
                        clientSites={clientSitesList}
                        clientSiteAlias={clientSiteAlias}
                        onDone={handleUnlinkAccountDone}
                      />
                    </span>
                  )}
                  <span>
                    &nbsp;&bull;&nbsp;
                    <SimpleTooltip tooltip={"Switch to client account"}>
                      <span>
                        <LinkToClient clientSiteAlias={clientSiteAlias}>
                          <strong>Visit</strong>
                        </LinkToClient>
                      </span>
                    </SimpleTooltip>
                  </span>
                </Flex>

                <KeywordTextFlexWrapper>
                  {clientSiteLoading ? (
                    <InlineLoadingSpinner />
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontStyle: "italic",
                        paddingLeft: "1em",
                        display: "inline"
                      }}
                    >
                      {(campaignCount > 0 || subClientCount === 0) &&
                        pluralize(campaignCount, "campaign")}
                      {campaignCount > 0 && subClientCount > 0 && ", "}
                      {subClientCount > 0 &&
                        pluralize(subClientCount, "sub-client")}
                    </p>
                  )}
                </KeywordTextFlexWrapper>
              </DataTableFreezeLeftCell>
            );

          default:
            return <DataTableRowCell key={columnName} rowIndex={rowIndex} />;
        }
      })}
    </DataTableRow>
  );
}

export default AccountsTableCommandRow;
