// source: google/ads/googleads/v16/resources/campaign.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_common_bidding_pb = require('../../../../../google/ads/googleads/v16/common/bidding_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_bidding_pb);
var google_ads_googleads_v16_common_custom_parameter_pb = require('../../../../../google/ads/googleads/v16/common/custom_parameter_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_custom_parameter_pb);
var google_ads_googleads_v16_common_frequency_cap_pb = require('../../../../../google/ads/googleads/v16/common/frequency_cap_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_frequency_cap_pb);
var google_ads_googleads_v16_common_real_time_bidding_setting_pb = require('../../../../../google/ads/googleads/v16/common/real_time_bidding_setting_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_real_time_bidding_setting_pb);
var google_ads_googleads_v16_common_targeting_setting_pb = require('../../../../../google/ads/googleads/v16/common/targeting_setting_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_targeting_setting_pb);
var google_ads_googleads_v16_enums_ad_serving_optimization_status_pb = require('../../../../../google/ads/googleads/v16/enums/ad_serving_optimization_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_ad_serving_optimization_status_pb);
var google_ads_googleads_v16_enums_advertising_channel_sub_type_pb = require('../../../../../google/ads/googleads/v16/enums/advertising_channel_sub_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_advertising_channel_sub_type_pb);
var google_ads_googleads_v16_enums_advertising_channel_type_pb = require('../../../../../google/ads/googleads/v16/enums/advertising_channel_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_advertising_channel_type_pb);
var google_ads_googleads_v16_enums_app_campaign_app_store_pb = require('../../../../../google/ads/googleads/v16/enums/app_campaign_app_store_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_app_campaign_app_store_pb);
var google_ads_googleads_v16_enums_app_campaign_bidding_strategy_goal_type_pb = require('../../../../../google/ads/googleads/v16/enums/app_campaign_bidding_strategy_goal_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_app_campaign_bidding_strategy_goal_type_pb);
var google_ads_googleads_v16_enums_asset_automation_status_pb = require('../../../../../google/ads/googleads/v16/enums/asset_automation_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_automation_status_pb);
var google_ads_googleads_v16_enums_asset_automation_type_pb = require('../../../../../google/ads/googleads/v16/enums/asset_automation_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_automation_type_pb);
var google_ads_googleads_v16_enums_asset_field_type_pb = require('../../../../../google/ads/googleads/v16/enums/asset_field_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_field_type_pb);
var google_ads_googleads_v16_enums_asset_set_type_pb = require('../../../../../google/ads/googleads/v16/enums/asset_set_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_set_type_pb);
var google_ads_googleads_v16_enums_bidding_strategy_system_status_pb = require('../../../../../google/ads/googleads/v16/enums/bidding_strategy_system_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_bidding_strategy_system_status_pb);
var google_ads_googleads_v16_enums_bidding_strategy_type_pb = require('../../../../../google/ads/googleads/v16/enums/bidding_strategy_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_bidding_strategy_type_pb);
var google_ads_googleads_v16_enums_brand_safety_suitability_pb = require('../../../../../google/ads/googleads/v16/enums/brand_safety_suitability_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_brand_safety_suitability_pb);
var google_ads_googleads_v16_enums_campaign_experiment_type_pb = require('../../../../../google/ads/googleads/v16/enums/campaign_experiment_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_campaign_experiment_type_pb);
var google_ads_googleads_v16_enums_campaign_primary_status_pb = require('../../../../../google/ads/googleads/v16/enums/campaign_primary_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_campaign_primary_status_pb);
var google_ads_googleads_v16_enums_campaign_primary_status_reason_pb = require('../../../../../google/ads/googleads/v16/enums/campaign_primary_status_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_campaign_primary_status_reason_pb);
var google_ads_googleads_v16_enums_campaign_serving_status_pb = require('../../../../../google/ads/googleads/v16/enums/campaign_serving_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_campaign_serving_status_pb);
var google_ads_googleads_v16_enums_campaign_status_pb = require('../../../../../google/ads/googleads/v16/enums/campaign_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_campaign_status_pb);
var google_ads_googleads_v16_enums_listing_type_pb = require('../../../../../google/ads/googleads/v16/enums/listing_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_listing_type_pb);
var google_ads_googleads_v16_enums_location_source_type_pb = require('../../../../../google/ads/googleads/v16/enums/location_source_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_location_source_type_pb);
var google_ads_googleads_v16_enums_negative_geo_target_type_pb = require('../../../../../google/ads/googleads/v16/enums/negative_geo_target_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_negative_geo_target_type_pb);
var google_ads_googleads_v16_enums_optimization_goal_type_pb = require('../../../../../google/ads/googleads/v16/enums/optimization_goal_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_optimization_goal_type_pb);
var google_ads_googleads_v16_enums_payment_mode_pb = require('../../../../../google/ads/googleads/v16/enums/payment_mode_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_payment_mode_pb);
var google_ads_googleads_v16_enums_performance_max_upgrade_status_pb = require('../../../../../google/ads/googleads/v16/enums/performance_max_upgrade_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_performance_max_upgrade_status_pb);
var google_ads_googleads_v16_enums_positive_geo_target_type_pb = require('../../../../../google/ads/googleads/v16/enums/positive_geo_target_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_positive_geo_target_type_pb);
var google_ads_googleads_v16_enums_vanity_pharma_display_url_mode_pb = require('../../../../../google/ads/googleads/v16/enums/vanity_pharma_display_url_mode_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_vanity_pharma_display_url_mode_pb);
var google_ads_googleads_v16_enums_vanity_pharma_text_pb = require('../../../../../google/ads/googleads/v16/enums/vanity_pharma_text_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_vanity_pharma_text_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.CampaignBiddingStrategyCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.CategoryBid', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.Campaign.VanityPharma', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.Campaign.repeatedFields_, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.displayName = 'proto.google.ads.googleads.v16.resources.Campaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.VanityPharma, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.VanityPharma';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.CategoryBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.CategoryBid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.displayName = 'proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.repeatedFields_ = [82,12,61,40,69,80,88];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_ = [[67,49,74,24,25,37,30,31,26,48,29,27,34,41]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.CampaignBiddingStrategyCase = {
  CAMPAIGN_BIDDING_STRATEGY_NOT_SET: 0,
  BIDDING_STRATEGY: 67,
  COMMISSION: 49,
  MANUAL_CPA: 74,
  MANUAL_CPC: 24,
  MANUAL_CPM: 25,
  MANUAL_CPV: 37,
  MAXIMIZE_CONVERSIONS: 30,
  MAXIMIZE_CONVERSION_VALUE: 31,
  TARGET_CPA: 26,
  TARGET_IMPRESSION_SHARE: 48,
  TARGET_ROAS: 29,
  TARGET_SPEND: 27,
  PERCENT_CPC: 34,
  TARGET_CPM: 41
};

/**
 * @return {proto.google.ads.googleads.v16.resources.Campaign.CampaignBiddingStrategyCase}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getCampaignBiddingStrategyCase = function() {
  return /** @type {proto.google.ads.googleads.v16.resources.Campaign.CampaignBiddingStrategyCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 59, 0),
    name: jspb.Message.getFieldWithDefault(msg, 58, ""),
    primaryStatus: jspb.Message.getFieldWithDefault(msg, 81, 0),
    primaryStatusReasonsList: (f = jspb.Message.getRepeatedField(msg, 82)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    servingStatus: jspb.Message.getFieldWithDefault(msg, 21, 0),
    biddingStrategySystemStatus: jspb.Message.getFieldWithDefault(msg, 78, 0),
    adServingOptimizationStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    advertisingChannelType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    advertisingChannelSubType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 60, ""),
    urlCustomParametersList: jspb.Message.toObjectList(msg.getUrlCustomParametersList(),
    google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter.toObject, includeInstance),
    localServicesCampaignSettings: (f = msg.getLocalServicesCampaignSettings()) && proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.toObject(includeInstance, f),
    travelCampaignSettings: (f = msg.getTravelCampaignSettings()) && proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.toObject(includeInstance, f),
    discoveryCampaignSettings: (f = msg.getDiscoveryCampaignSettings()) && proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.toObject(includeInstance, f),
    realTimeBiddingSetting: (f = msg.getRealTimeBiddingSetting()) && google_ads_googleads_v16_common_real_time_bidding_setting_pb.RealTimeBiddingSetting.toObject(includeInstance, f),
    networkSettings: (f = msg.getNetworkSettings()) && proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.toObject(includeInstance, f),
    hotelSetting: (f = msg.getHotelSetting()) && proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.toObject(includeInstance, f),
    dynamicSearchAdsSetting: (f = msg.getDynamicSearchAdsSetting()) && proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.toObject(includeInstance, f),
    shoppingSetting: (f = msg.getShoppingSetting()) && proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.toObject(includeInstance, f),
    targetingSetting: (f = msg.getTargetingSetting()) && google_ads_googleads_v16_common_targeting_setting_pb.TargetingSetting.toObject(includeInstance, f),
    audienceSetting: (f = msg.getAudienceSetting()) && proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.toObject(includeInstance, f),
    geoTargetTypeSetting: (f = msg.getGeoTargetTypeSetting()) && proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.toObject(includeInstance, f),
    localCampaignSetting: (f = msg.getLocalCampaignSetting()) && proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.toObject(includeInstance, f),
    appCampaignSetting: (f = msg.getAppCampaignSetting()) && proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.toObject(includeInstance, f),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 61)) == null ? undefined : f,
    experimentType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    baseCampaign: jspb.Message.getFieldWithDefault(msg, 56, ""),
    campaignBudget: jspb.Message.getFieldWithDefault(msg, 62, ""),
    biddingStrategyType: jspb.Message.getFieldWithDefault(msg, 22, 0),
    accessibleBiddingStrategy: jspb.Message.getFieldWithDefault(msg, 71, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 63, ""),
    campaignGroup: jspb.Message.getFieldWithDefault(msg, 76, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 64, ""),
    finalUrlSuffix: jspb.Message.getFieldWithDefault(msg, 65, ""),
    frequencyCapsList: jspb.Message.toObjectList(msg.getFrequencyCapsList(),
    google_ads_googleads_v16_common_frequency_cap_pb.FrequencyCapEntry.toObject, includeInstance),
    videoBrandSafetySuitability: jspb.Message.getFieldWithDefault(msg, 42, 0),
    vanityPharma: (f = msg.getVanityPharma()) && proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.toObject(includeInstance, f),
    selectiveOptimization: (f = msg.getSelectiveOptimization()) && proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.toObject(includeInstance, f),
    optimizationGoalSetting: (f = msg.getOptimizationGoalSetting()) && proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.toObject(includeInstance, f),
    trackingSetting: (f = msg.getTrackingSetting()) && proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.toObject(includeInstance, f),
    paymentMode: jspb.Message.getFieldWithDefault(msg, 52, 0),
    optimizationScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 66, 0.0),
    excludedParentAssetFieldTypesList: (f = jspb.Message.getRepeatedField(msg, 69)) == null ? undefined : f,
    excludedParentAssetSetTypesList: (f = jspb.Message.getRepeatedField(msg, 80)) == null ? undefined : f,
    urlExpansionOptOut: jspb.Message.getBooleanFieldWithDefault(msg, 72, false),
    performanceMaxUpgrade: (f = msg.getPerformanceMaxUpgrade()) && proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.toObject(includeInstance, f),
    hotelPropertyAssetSet: jspb.Message.getFieldWithDefault(msg, 83, ""),
    listingType: jspb.Message.getFieldWithDefault(msg, 86, 0),
    assetAutomationSettingsList: jspb.Message.toObjectList(msg.getAssetAutomationSettingsList(),
    proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.toObject, includeInstance),
    biddingStrategy: jspb.Message.getFieldWithDefault(msg, 67, ""),
    commission: (f = msg.getCommission()) && google_ads_googleads_v16_common_bidding_pb.Commission.toObject(includeInstance, f),
    manualCpa: (f = msg.getManualCpa()) && google_ads_googleads_v16_common_bidding_pb.ManualCpa.toObject(includeInstance, f),
    manualCpc: (f = msg.getManualCpc()) && google_ads_googleads_v16_common_bidding_pb.ManualCpc.toObject(includeInstance, f),
    manualCpm: (f = msg.getManualCpm()) && google_ads_googleads_v16_common_bidding_pb.ManualCpm.toObject(includeInstance, f),
    manualCpv: (f = msg.getManualCpv()) && google_ads_googleads_v16_common_bidding_pb.ManualCpv.toObject(includeInstance, f),
    maximizeConversions: (f = msg.getMaximizeConversions()) && google_ads_googleads_v16_common_bidding_pb.MaximizeConversions.toObject(includeInstance, f),
    maximizeConversionValue: (f = msg.getMaximizeConversionValue()) && google_ads_googleads_v16_common_bidding_pb.MaximizeConversionValue.toObject(includeInstance, f),
    targetCpa: (f = msg.getTargetCpa()) && google_ads_googleads_v16_common_bidding_pb.TargetCpa.toObject(includeInstance, f),
    targetImpressionShare: (f = msg.getTargetImpressionShare()) && google_ads_googleads_v16_common_bidding_pb.TargetImpressionShare.toObject(includeInstance, f),
    targetRoas: (f = msg.getTargetRoas()) && google_ads_googleads_v16_common_bidding_pb.TargetRoas.toObject(includeInstance, f),
    targetSpend: (f = msg.getTargetSpend()) && google_ads_googleads_v16_common_bidding_pb.TargetSpend.toObject(includeInstance, f),
    percentCpc: (f = msg.getPercentCpc()) && google_ads_googleads_v16_common_bidding_pb.PercentCpc.toObject(includeInstance, f),
    targetCpm: (f = msg.getTargetCpm()) && google_ads_googleads_v16_common_bidding_pb.TargetCpm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign}
 */
proto.google.ads.googleads.v16.resources.Campaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign;
  return proto.google.ads.googleads.v16.resources.Campaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign}
 */
proto.google.ads.googleads.v16.resources.Campaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 81:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusEnum.CampaignPrimaryStatus} */ (reader.readEnum());
      msg.setPrimaryStatus(value);
      break;
    case 82:
      var values = /** @type {!Array<!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusReasonEnum.CampaignPrimaryStatusReason>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrimaryStatusReasons(values[i]);
      }
      break;
    case 5:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.CampaignStatusEnum.CampaignStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 21:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.CampaignServingStatusEnum.CampaignServingStatus} */ (reader.readEnum());
      msg.setServingStatus(value);
      break;
    case 78:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BiddingStrategySystemStatusEnum.BiddingStrategySystemStatus} */ (reader.readEnum());
      msg.setBiddingStrategySystemStatus(value);
      break;
    case 8:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AdServingOptimizationStatusEnum.AdServingOptimizationStatus} */ (reader.readEnum());
      msg.setAdServingOptimizationStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AdvertisingChannelTypeEnum.AdvertisingChannelType} */ (reader.readEnum());
      msg.setAdvertisingChannelType(value);
      break;
    case 10:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AdvertisingChannelSubTypeEnum.AdvertisingChannelSubType} */ (reader.readEnum());
      msg.setAdvertisingChannelSubType(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 12:
      var value = new google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter;
      reader.readMessage(value,google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter.deserializeBinaryFromReader);
      msg.addUrlCustomParameters(value);
      break;
    case 75:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.deserializeBinaryFromReader);
      msg.setLocalServicesCampaignSettings(value);
      break;
    case 85:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.deserializeBinaryFromReader);
      msg.setTravelCampaignSettings(value);
      break;
    case 87:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.deserializeBinaryFromReader);
      msg.setDiscoveryCampaignSettings(value);
      break;
    case 39:
      var value = new google_ads_googleads_v16_common_real_time_bidding_setting_pb.RealTimeBiddingSetting;
      reader.readMessage(value,google_ads_googleads_v16_common_real_time_bidding_setting_pb.RealTimeBiddingSetting.deserializeBinaryFromReader);
      msg.setRealTimeBiddingSetting(value);
      break;
    case 14:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.deserializeBinaryFromReader);
      msg.setNetworkSettings(value);
      break;
    case 32:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.deserializeBinaryFromReader);
      msg.setHotelSetting(value);
      break;
    case 33:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.deserializeBinaryFromReader);
      msg.setDynamicSearchAdsSetting(value);
      break;
    case 36:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.deserializeBinaryFromReader);
      msg.setShoppingSetting(value);
      break;
    case 43:
      var value = new google_ads_googleads_v16_common_targeting_setting_pb.TargetingSetting;
      reader.readMessage(value,google_ads_googleads_v16_common_targeting_setting_pb.TargetingSetting.deserializeBinaryFromReader);
      msg.setTargetingSetting(value);
      break;
    case 73:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.deserializeBinaryFromReader);
      msg.setAudienceSetting(value);
      break;
    case 47:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.deserializeBinaryFromReader);
      msg.setGeoTargetTypeSetting(value);
      break;
    case 50:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.deserializeBinaryFromReader);
      msg.setLocalCampaignSetting(value);
      break;
    case 51:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.deserializeBinaryFromReader);
      msg.setAppCampaignSetting(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 17:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.CampaignExperimentTypeEnum.CampaignExperimentType} */ (reader.readEnum());
      msg.setExperimentType(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCampaign(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignBudget(value);
      break;
    case 22:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BiddingStrategyTypeEnum.BiddingStrategyType} */ (reader.readEnum());
      msg.setBiddingStrategyType(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessibleBiddingStrategy(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 76:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignGroup(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrlSuffix(value);
      break;
    case 40:
      var value = new google_ads_googleads_v16_common_frequency_cap_pb.FrequencyCapEntry;
      reader.readMessage(value,google_ads_googleads_v16_common_frequency_cap_pb.FrequencyCapEntry.deserializeBinaryFromReader);
      msg.addFrequencyCaps(value);
      break;
    case 42:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BrandSafetySuitabilityEnum.BrandSafetySuitability} */ (reader.readEnum());
      msg.setVideoBrandSafetySuitability(value);
      break;
    case 44:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.VanityPharma;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.deserializeBinaryFromReader);
      msg.setVanityPharma(value);
      break;
    case 45:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.deserializeBinaryFromReader);
      msg.setSelectiveOptimization(value);
      break;
    case 54:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.deserializeBinaryFromReader);
      msg.setOptimizationGoalSetting(value);
      break;
    case 46:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.deserializeBinaryFromReader);
      msg.setTrackingSetting(value);
      break;
    case 52:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PaymentModeEnum.PaymentMode} */ (reader.readEnum());
      msg.setPaymentMode(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOptimizationScore(value);
      break;
    case 69:
      var values = /** @type {!Array<!proto.google.ads.googleads.v16.enums.AssetFieldTypeEnum.AssetFieldType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExcludedParentAssetFieldTypes(values[i]);
      }
      break;
    case 80:
      var values = /** @type {!Array<!proto.google.ads.googleads.v16.enums.AssetSetTypeEnum.AssetSetType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExcludedParentAssetSetTypes(values[i]);
      }
      break;
    case 72:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUrlExpansionOptOut(value);
      break;
    case 77:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.deserializeBinaryFromReader);
      msg.setPerformanceMaxUpgrade(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelPropertyAssetSet(value);
      break;
    case 86:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.ListingTypeEnum.ListingType} */ (reader.readEnum());
      msg.setListingType(value);
      break;
    case 88:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.deserializeBinaryFromReader);
      msg.addAssetAutomationSettings(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiddingStrategy(value);
      break;
    case 49:
      var value = new google_ads_googleads_v16_common_bidding_pb.Commission;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.Commission.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 74:
      var value = new google_ads_googleads_v16_common_bidding_pb.ManualCpa;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.ManualCpa.deserializeBinaryFromReader);
      msg.setManualCpa(value);
      break;
    case 24:
      var value = new google_ads_googleads_v16_common_bidding_pb.ManualCpc;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.ManualCpc.deserializeBinaryFromReader);
      msg.setManualCpc(value);
      break;
    case 25:
      var value = new google_ads_googleads_v16_common_bidding_pb.ManualCpm;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.ManualCpm.deserializeBinaryFromReader);
      msg.setManualCpm(value);
      break;
    case 37:
      var value = new google_ads_googleads_v16_common_bidding_pb.ManualCpv;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.ManualCpv.deserializeBinaryFromReader);
      msg.setManualCpv(value);
      break;
    case 30:
      var value = new google_ads_googleads_v16_common_bidding_pb.MaximizeConversions;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.MaximizeConversions.deserializeBinaryFromReader);
      msg.setMaximizeConversions(value);
      break;
    case 31:
      var value = new google_ads_googleads_v16_common_bidding_pb.MaximizeConversionValue;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.MaximizeConversionValue.deserializeBinaryFromReader);
      msg.setMaximizeConversionValue(value);
      break;
    case 26:
      var value = new google_ads_googleads_v16_common_bidding_pb.TargetCpa;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.TargetCpa.deserializeBinaryFromReader);
      msg.setTargetCpa(value);
      break;
    case 48:
      var value = new google_ads_googleads_v16_common_bidding_pb.TargetImpressionShare;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.TargetImpressionShare.deserializeBinaryFromReader);
      msg.setTargetImpressionShare(value);
      break;
    case 29:
      var value = new google_ads_googleads_v16_common_bidding_pb.TargetRoas;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.TargetRoas.deserializeBinaryFromReader);
      msg.setTargetRoas(value);
      break;
    case 27:
      var value = new google_ads_googleads_v16_common_bidding_pb.TargetSpend;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.TargetSpend.deserializeBinaryFromReader);
      msg.setTargetSpend(value);
      break;
    case 34:
      var value = new google_ads_googleads_v16_common_bidding_pb.PercentCpc;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.PercentCpc.deserializeBinaryFromReader);
      msg.setPercentCpc(value);
      break;
    case 41:
      var value = new google_ads_googleads_v16_common_bidding_pb.TargetCpm;
      reader.readMessage(value,google_ads_googleads_v16_common_bidding_pb.TargetCpm.deserializeBinaryFromReader);
      msg.setTargetCpm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 59));
  if (f != null) {
    writer.writeInt64(
      59,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 58));
  if (f != null) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getPrimaryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      81,
      f
    );
  }
  f = message.getPrimaryStatusReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      82,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getServingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getBiddingStrategySystemStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      78,
      f
    );
  }
  f = message.getAdServingOptimizationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getAdvertisingChannelType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAdvertisingChannelSubType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 60));
  if (f != null) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getUrlCustomParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter.serializeBinaryToWriter
    );
  }
  f = message.getLocalServicesCampaignSettings();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getTravelCampaignSettings();
  if (f != null) {
    writer.writeMessage(
      85,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getDiscoveryCampaignSettings();
  if (f != null) {
    writer.writeMessage(
      87,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getRealTimeBiddingSetting();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_ads_googleads_v16_common_real_time_bidding_setting_pb.RealTimeBiddingSetting.serializeBinaryToWriter
    );
  }
  f = message.getNetworkSettings();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.serializeBinaryToWriter
    );
  }
  f = message.getHotelSetting();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.serializeBinaryToWriter
    );
  }
  f = message.getDynamicSearchAdsSetting();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.serializeBinaryToWriter
    );
  }
  f = message.getShoppingSetting();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.serializeBinaryToWriter
    );
  }
  f = message.getTargetingSetting();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_ads_googleads_v16_common_targeting_setting_pb.TargetingSetting.serializeBinaryToWriter
    );
  }
  f = message.getAudienceSetting();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.serializeBinaryToWriter
    );
  }
  f = message.getGeoTargetTypeSetting();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.serializeBinaryToWriter
    );
  }
  f = message.getLocalCampaignSetting();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.serializeBinaryToWriter
    );
  }
  f = message.getAppCampaignSetting();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      61,
      f
    );
  }
  f = message.getExperimentType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 56));
  if (f != null) {
    writer.writeString(
      56,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 62));
  if (f != null) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getBiddingStrategyType();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getAccessibleBiddingStrategy();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 63));
  if (f != null) {
    writer.writeString(
      63,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 76));
  if (f != null) {
    writer.writeString(
      76,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 64));
  if (f != null) {
    writer.writeString(
      64,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 65));
  if (f != null) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getFrequencyCapsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      40,
      f,
      google_ads_googleads_v16_common_frequency_cap_pb.FrequencyCapEntry.serializeBinaryToWriter
    );
  }
  f = message.getVideoBrandSafetySuitability();
  if (f !== 0.0) {
    writer.writeEnum(
      42,
      f
    );
  }
  f = message.getVanityPharma();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.serializeBinaryToWriter
    );
  }
  f = message.getSelectiveOptimization();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.serializeBinaryToWriter
    );
  }
  f = message.getOptimizationGoalSetting();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.serializeBinaryToWriter
    );
  }
  f = message.getTrackingSetting();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMode();
  if (f !== 0.0) {
    writer.writeEnum(
      52,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 66));
  if (f != null) {
    writer.writeDouble(
      66,
      f
    );
  }
  f = message.getExcludedParentAssetFieldTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      69,
      f
    );
  }
  f = message.getExcludedParentAssetSetTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      80,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 72));
  if (f != null) {
    writer.writeBool(
      72,
      f
    );
  }
  f = message.getPerformanceMaxUpgrade();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 83));
  if (f != null) {
    writer.writeString(
      83,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v16.enums.ListingTypeEnum.ListingType} */ (jspb.Message.getField(message, 86));
  if (f != null) {
    writer.writeEnum(
      86,
      f
    );
  }
  f = message.getAssetAutomationSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      88,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 67));
  if (f != null) {
    writer.writeString(
      67,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      google_ads_googleads_v16_common_bidding_pb.Commission.serializeBinaryToWriter
    );
  }
  f = message.getManualCpa();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      google_ads_googleads_v16_common_bidding_pb.ManualCpa.serializeBinaryToWriter
    );
  }
  f = message.getManualCpc();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_ads_googleads_v16_common_bidding_pb.ManualCpc.serializeBinaryToWriter
    );
  }
  f = message.getManualCpm();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_ads_googleads_v16_common_bidding_pb.ManualCpm.serializeBinaryToWriter
    );
  }
  f = message.getManualCpv();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_ads_googleads_v16_common_bidding_pb.ManualCpv.serializeBinaryToWriter
    );
  }
  f = message.getMaximizeConversions();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_ads_googleads_v16_common_bidding_pb.MaximizeConversions.serializeBinaryToWriter
    );
  }
  f = message.getMaximizeConversionValue();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_ads_googleads_v16_common_bidding_pb.MaximizeConversionValue.serializeBinaryToWriter
    );
  }
  f = message.getTargetCpa();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_ads_googleads_v16_common_bidding_pb.TargetCpa.serializeBinaryToWriter
    );
  }
  f = message.getTargetImpressionShare();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_ads_googleads_v16_common_bidding_pb.TargetImpressionShare.serializeBinaryToWriter
    );
  }
  f = message.getTargetRoas();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_ads_googleads_v16_common_bidding_pb.TargetRoas.serializeBinaryToWriter
    );
  }
  f = message.getTargetSpend();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_ads_googleads_v16_common_bidding_pb.TargetSpend.serializeBinaryToWriter
    );
  }
  f = message.getPercentCpc();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_ads_googleads_v16_common_bidding_pb.PercentCpc.serializeBinaryToWriter
    );
  }
  f = message.getTargetCpm();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_ads_googleads_v16_common_bidding_pb.TargetCpm.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.toObject = function(includeInstance, msg) {
  var f, obj = {
    performanceMaxCampaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preUpgradeCampaign: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade;
  return proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerformanceMaxCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreUpgradeCampaign(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PerformanceMaxUpgradeStatusEnum.PerformanceMaxUpgradeStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerformanceMaxCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreUpgradeCampaign();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string performance_max_campaign = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.getPerformanceMaxCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.setPerformanceMaxCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pre_upgrade_campaign = 2;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.getPreUpgradeCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.setPreUpgradeCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.ads.googleads.v16.enums.PerformanceMaxUpgradeStatusEnum.PerformanceMaxUpgradeStatus status = 3;
 * @return {!proto.google.ads.googleads.v16.enums.PerformanceMaxUpgradeStatusEnum.PerformanceMaxUpgradeStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PerformanceMaxUpgradeStatusEnum.PerformanceMaxUpgradeStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PerformanceMaxUpgradeStatusEnum.PerformanceMaxUpgradeStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetGoogleSearch: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    targetSearchNetwork: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    targetContentNetwork: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    targetPartnerSearchNetwork: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    targetYoutube: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    targetGoogleTvNetwork: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings;
  return proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetGoogleSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetSearchNetwork(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetContentNetwork(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetPartnerSearchNetwork(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetYoutube(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetGoogleTvNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional bool target_google_search = 5;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.getTargetGoogleSearch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.setTargetGoogleSearch = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.clearTargetGoogleSearch = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.hasTargetGoogleSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool target_search_network = 6;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.getTargetSearchNetwork = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.setTargetSearchNetwork = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.clearTargetSearchNetwork = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.hasTargetSearchNetwork = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool target_content_network = 7;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.getTargetContentNetwork = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.setTargetContentNetwork = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.clearTargetContentNetwork = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.hasTargetContentNetwork = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool target_partner_search_network = 8;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.getTargetPartnerSearchNetwork = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.setTargetPartnerSearchNetwork = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.clearTargetPartnerSearchNetwork = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.hasTargetPartnerSearchNetwork = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool target_youtube = 9;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.getTargetYoutube = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.setTargetYoutube = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.clearTargetYoutube = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.hasTargetYoutube = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool target_google_tv_network = 10;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.getTargetGoogleTvNetwork = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.setTargetGoogleTvNetwork = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.clearTargetGoogleTvNetwork = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings.prototype.hasTargetGoogleTvNetwork = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelCenterId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo}
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo;
  return proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo}
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHotelCenterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 hotel_center_id = 2;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.prototype.getHotelCenterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.prototype.setHotelCenterId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.prototype.clearHotelCenterId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo.prototype.hasHotelCenterId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    languageCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    useSuppliedUrlsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    feedsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSuppliedUrlsOnly(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFeedsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string domain_name = 6;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string language_code = 7;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool use_supplied_urls_only = 8;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.getUseSuppliedUrlsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.setUseSuppliedUrlsOnly = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.clearUseSuppliedUrlsOnly = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.hasUseSuppliedUrlsOnly = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string feeds = 9;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.getFeedsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.setFeedsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.addFeeds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting.prototype.clearFeedsList = function() {
  return this.setFeedsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    feedLabel: jspb.Message.getFieldWithDefault(msg, 10, ""),
    campaignPriority: jspb.Message.getFieldWithDefault(msg, 7, 0),
    enableLocal: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    useVehicleInventory: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    advertisingPartnerIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    disableProductFeed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedLabel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCampaignPriority(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableLocal(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseVehicleInventory(value);
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdvertisingPartnerIds(values[i]);
      }
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableProductFeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFeedLabel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUseVehicleInventory();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAdvertisingPartnerIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int64 merchant_id = 5;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setMerchantId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.clearMerchantId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.hasMerchantId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string feed_label = 10;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getFeedLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setFeedLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 campaign_priority = 7;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getCampaignPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setCampaignPriority = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.clearCampaignPriority = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.hasCampaignPriority = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool enable_local = 8;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getEnableLocal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setEnableLocal = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.clearEnableLocal = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.hasEnableLocal = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool use_vehicle_inventory = 9;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getUseVehicleInventory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setUseVehicleInventory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated int64 advertising_partner_ids = 11;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getAdvertisingPartnerIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setAdvertisingPartnerIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.addAdvertisingPartnerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.clearAdvertisingPartnerIdsList = function() {
  return this.setAdvertisingPartnerIdsList([]);
};


/**
 * optional bool disable_product_feed = 12;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.getDisableProductFeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.setDisableProductFeed = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.clearDisableProductFeed = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting.prototype.hasDisableProductFeed = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackingUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tracking_url = 2;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.prototype.getTrackingUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.prototype.setTrackingUrl = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.prototype.clearTrackingUrl = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting.prototype.hasTrackingUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    positiveGeoTargetType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    negativeGeoTargetType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PositiveGeoTargetTypeEnum.PositiveGeoTargetType} */ (reader.readEnum());
      msg.setPositiveGeoTargetType(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.NegativeGeoTargetTypeEnum.NegativeGeoTargetType} */ (reader.readEnum());
      msg.setNegativeGeoTargetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositiveGeoTargetType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNegativeGeoTargetType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.PositiveGeoTargetTypeEnum.PositiveGeoTargetType positive_geo_target_type = 1;
 * @return {!proto.google.ads.googleads.v16.enums.PositiveGeoTargetTypeEnum.PositiveGeoTargetType}
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.prototype.getPositiveGeoTargetType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PositiveGeoTargetTypeEnum.PositiveGeoTargetType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PositiveGeoTargetTypeEnum.PositiveGeoTargetType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.prototype.setPositiveGeoTargetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.enums.NegativeGeoTargetTypeEnum.NegativeGeoTargetType negative_geo_target_type = 2;
 * @return {!proto.google.ads.googleads.v16.enums.NegativeGeoTargetTypeEnum.NegativeGeoTargetType}
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.prototype.getNegativeGeoTargetType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.NegativeGeoTargetTypeEnum.NegativeGeoTargetType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.NegativeGeoTargetTypeEnum.NegativeGeoTargetType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting.prototype.setNegativeGeoTargetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationSourceType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.LocationSourceTypeEnum.LocationSourceType} */ (reader.readEnum());
      msg.setLocationSourceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.LocationSourceTypeEnum.LocationSourceType location_source_type = 1;
 * @return {!proto.google.ads.googleads.v16.enums.LocationSourceTypeEnum.LocationSourceType}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.prototype.getLocationSourceType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.LocationSourceTypeEnum.LocationSourceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.LocationSourceTypeEnum.LocationSourceType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting.prototype.setLocationSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    biddingStrategyGoalType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    appId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    appStore: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AppCampaignBiddingStrategyGoalTypeEnum.AppCampaignBiddingStrategyGoalType} */ (reader.readEnum());
      msg.setBiddingStrategyGoalType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AppCampaignAppStoreEnum.AppCampaignAppStore} */ (reader.readEnum());
      msg.setAppStore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBiddingStrategyGoalType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAppStore();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.AppCampaignBiddingStrategyGoalTypeEnum.AppCampaignBiddingStrategyGoalType bidding_strategy_goal_type = 1;
 * @return {!proto.google.ads.googleads.v16.enums.AppCampaignBiddingStrategyGoalTypeEnum.AppCampaignBiddingStrategyGoalType}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.getBiddingStrategyGoalType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AppCampaignBiddingStrategyGoalTypeEnum.AppCampaignBiddingStrategyGoalType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AppCampaignBiddingStrategyGoalTypeEnum.AppCampaignBiddingStrategyGoalType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.setBiddingStrategyGoalType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string app_id = 4;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.setAppId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.clearAppId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.hasAppId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.ads.googleads.v16.enums.AppCampaignAppStoreEnum.AppCampaignAppStore app_store = 3;
 * @return {!proto.google.ads.googleads.v16.enums.AppCampaignAppStoreEnum.AppCampaignAppStore}
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.getAppStore = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AppCampaignAppStoreEnum.AppCampaignAppStore} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AppCampaignAppStoreEnum.AppCampaignAppStore} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting.prototype.setAppStore = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.toObject = function(includeInstance, msg) {
  var f, obj = {
    vanityPharmaDisplayUrlMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vanityPharmaText: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma}
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.VanityPharma;
  return proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma}
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.VanityPharmaDisplayUrlModeEnum.VanityPharmaDisplayUrlMode} */ (reader.readEnum());
      msg.setVanityPharmaDisplayUrlMode(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.VanityPharmaTextEnum.VanityPharmaText} */ (reader.readEnum());
      msg.setVanityPharmaText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVanityPharmaDisplayUrlMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVanityPharmaText();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.VanityPharmaDisplayUrlModeEnum.VanityPharmaDisplayUrlMode vanity_pharma_display_url_mode = 1;
 * @return {!proto.google.ads.googleads.v16.enums.VanityPharmaDisplayUrlModeEnum.VanityPharmaDisplayUrlMode}
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.prototype.getVanityPharmaDisplayUrlMode = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.VanityPharmaDisplayUrlModeEnum.VanityPharmaDisplayUrlMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.VanityPharmaDisplayUrlModeEnum.VanityPharmaDisplayUrlMode} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.prototype.setVanityPharmaDisplayUrlMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.enums.VanityPharmaTextEnum.VanityPharmaText vanity_pharma_text = 2;
 * @return {!proto.google.ads.googleads.v16.enums.VanityPharmaTextEnum.VanityPharmaText}
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.prototype.getVanityPharmaText = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.VanityPharmaTextEnum.VanityPharmaText} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.VanityPharmaTextEnum.VanityPharmaText} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.VanityPharma} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.VanityPharma.prototype.setVanityPharmaText = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversionActionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization}
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization;
  return proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization}
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addConversionActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversionActionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string conversion_actions = 2;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.prototype.getConversionActionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.prototype.setConversionActionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.prototype.addConversionActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization.prototype.clearConversionActionsList = function() {
  return this.setConversionActionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    optimizationGoalTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.google.ads.googleads.v16.enums.OptimizationGoalTypeEnum.OptimizationGoalType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOptimizationGoalTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptimizationGoalTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated google.ads.googleads.v16.enums.OptimizationGoalTypeEnum.OptimizationGoalType optimization_goal_types = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.enums.OptimizationGoalTypeEnum.OptimizationGoalType>}
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.prototype.getOptimizationGoalTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v16.enums.OptimizationGoalTypeEnum.OptimizationGoalType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.enums.OptimizationGoalTypeEnum.OptimizationGoalType>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.prototype.setOptimizationGoalTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.OptimizationGoalTypeEnum.OptimizationGoalType} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.prototype.addOptimizationGoalTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting.prototype.clearOptimizationGoalTypesList = function() {
  return this.setOptimizationGoalTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    useAudienceGrouped: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseAudienceGrouped(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool use_audience_grouped = 1;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.prototype.getUseAudienceGrouped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.prototype.setUseAudienceGrouped = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.prototype.clearUseAudienceGrouped = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting.prototype.hasUseAudienceGrouped = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryBidsList: jspb.Message.toObjectList(msg.getCategoryBidsList(),
    proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings;
  return proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v16.resources.Campaign.CategoryBid;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.deserializeBinaryFromReader);
      msg.addCategoryBids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CategoryBid category_bids = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid>}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.prototype.getCategoryBidsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.CategoryBid, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.prototype.setCategoryBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid}
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.prototype.addCategoryBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v16.resources.Campaign.CategoryBid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings.prototype.clearCategoryBidsList = function() {
  return this.setCategoryBidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    manualCpaBidMicros: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.CategoryBid;
  return proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManualCpaBidMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string category_id = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.setCategoryId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.clearCategoryId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.hasCategoryId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 manual_cpa_bid_micros = 2;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.getManualCpaBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.setManualCpaBidMicros = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.CategoryBid} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.clearManualCpaBidMicros = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.CategoryBid.prototype.hasManualCpaBidMicros = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    travelAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings;
  return proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTravelAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 travel_account_id = 1;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.prototype.getTravelAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.prototype.setTravelAccountId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.prototype.clearTravelAccountId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings.prototype.hasTravelAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    upgradedTargeting: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings;
  return proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpgradedTargeting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool upgraded_targeting = 1;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.prototype.getUpgradedTargeting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.prototype.setUpgradedTargeting = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.prototype.clearUpgradedTargeting = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings.prototype.hasUpgradedTargeting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetAutomationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetAutomationStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting;
  return proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AssetAutomationTypeEnum.AssetAutomationType} */ (reader.readEnum());
      msg.setAssetAutomationType(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AssetAutomationStatusEnum.AssetAutomationStatus} */ (reader.readEnum());
      msg.setAssetAutomationStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.ads.googleads.v16.enums.AssetAutomationTypeEnum.AssetAutomationType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v16.enums.AssetAutomationStatusEnum.AssetAutomationStatus} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.AssetAutomationTypeEnum.AssetAutomationType asset_automation_type = 1;
 * @return {!proto.google.ads.googleads.v16.enums.AssetAutomationTypeEnum.AssetAutomationType}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.getAssetAutomationType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AssetAutomationTypeEnum.AssetAutomationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetAutomationTypeEnum.AssetAutomationType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.setAssetAutomationType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.clearAssetAutomationType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.hasAssetAutomationType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.ads.googleads.v16.enums.AssetAutomationStatusEnum.AssetAutomationStatus asset_automation_status = 2;
 * @return {!proto.google.ads.googleads.v16.enums.AssetAutomationStatusEnum.AssetAutomationStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.getAssetAutomationStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AssetAutomationStatusEnum.AssetAutomationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetAutomationStatusEnum.AssetAutomationStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.setAssetAutomationStatus = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.clearAssetAutomationStatus = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting.prototype.hasAssetAutomationStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 59;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setId = function(value) {
  return jspb.Message.setField(this, 59, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearId = function() {
  return jspb.Message.setField(this, 59, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasId = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional string name = 58;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setName = function(value) {
  return jspb.Message.setField(this, 58, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearName = function() {
  return jspb.Message.setField(this, 58, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasName = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional google.ads.googleads.v16.enums.CampaignPrimaryStatusEnum.CampaignPrimaryStatus primary_status = 81;
 * @return {!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusEnum.CampaignPrimaryStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getPrimaryStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusEnum.CampaignPrimaryStatus} */ (jspb.Message.getFieldWithDefault(this, 81, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusEnum.CampaignPrimaryStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setPrimaryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 81, value);
};


/**
 * repeated google.ads.googleads.v16.enums.CampaignPrimaryStatusReasonEnum.CampaignPrimaryStatusReason primary_status_reasons = 82;
 * @return {!Array<!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusReasonEnum.CampaignPrimaryStatusReason>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getPrimaryStatusReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusReasonEnum.CampaignPrimaryStatusReason>} */ (jspb.Message.getRepeatedField(this, 82));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusReasonEnum.CampaignPrimaryStatusReason>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setPrimaryStatusReasonsList = function(value) {
  return jspb.Message.setField(this, 82, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.CampaignPrimaryStatusReasonEnum.CampaignPrimaryStatusReason} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addPrimaryStatusReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 82, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearPrimaryStatusReasonsList = function() {
  return this.setPrimaryStatusReasonsList([]);
};


/**
 * optional google.ads.googleads.v16.enums.CampaignStatusEnum.CampaignStatus status = 5;
 * @return {!proto.google.ads.googleads.v16.enums.CampaignStatusEnum.CampaignStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.CampaignStatusEnum.CampaignStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.CampaignStatusEnum.CampaignStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.ads.googleads.v16.enums.CampaignServingStatusEnum.CampaignServingStatus serving_status = 21;
 * @return {!proto.google.ads.googleads.v16.enums.CampaignServingStatusEnum.CampaignServingStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getServingStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.CampaignServingStatusEnum.CampaignServingStatus} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.CampaignServingStatusEnum.CampaignServingStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setServingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional google.ads.googleads.v16.enums.BiddingStrategySystemStatusEnum.BiddingStrategySystemStatus bidding_strategy_system_status = 78;
 * @return {!proto.google.ads.googleads.v16.enums.BiddingStrategySystemStatusEnum.BiddingStrategySystemStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getBiddingStrategySystemStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BiddingStrategySystemStatusEnum.BiddingStrategySystemStatus} */ (jspb.Message.getFieldWithDefault(this, 78, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BiddingStrategySystemStatusEnum.BiddingStrategySystemStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setBiddingStrategySystemStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 78, value);
};


/**
 * optional google.ads.googleads.v16.enums.AdServingOptimizationStatusEnum.AdServingOptimizationStatus ad_serving_optimization_status = 8;
 * @return {!proto.google.ads.googleads.v16.enums.AdServingOptimizationStatusEnum.AdServingOptimizationStatus}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAdServingOptimizationStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AdServingOptimizationStatusEnum.AdServingOptimizationStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AdServingOptimizationStatusEnum.AdServingOptimizationStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAdServingOptimizationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.ads.googleads.v16.enums.AdvertisingChannelTypeEnum.AdvertisingChannelType advertising_channel_type = 9;
 * @return {!proto.google.ads.googleads.v16.enums.AdvertisingChannelTypeEnum.AdvertisingChannelType}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAdvertisingChannelType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AdvertisingChannelTypeEnum.AdvertisingChannelType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AdvertisingChannelTypeEnum.AdvertisingChannelType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAdvertisingChannelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.ads.googleads.v16.enums.AdvertisingChannelSubTypeEnum.AdvertisingChannelSubType advertising_channel_sub_type = 10;
 * @return {!proto.google.ads.googleads.v16.enums.AdvertisingChannelSubTypeEnum.AdvertisingChannelSubType}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAdvertisingChannelSubType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AdvertisingChannelSubTypeEnum.AdvertisingChannelSubType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AdvertisingChannelSubTypeEnum.AdvertisingChannelSubType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAdvertisingChannelSubType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string tracking_url_template = 60;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setField(this, 60, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTrackingUrlTemplate = function() {
  return jspb.Message.setField(this, 60, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTrackingUrlTemplate = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * repeated google.ads.googleads.v16.common.CustomParameter url_custom_parameters = 12;
 * @return {!Array<!proto.google.ads.googleads.v16.common.CustomParameter>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getUrlCustomParametersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.CustomParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter, 12));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.CustomParameter>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setUrlCustomParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.CustomParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.CustomParameter}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addUrlCustomParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.google.ads.googleads.v16.common.CustomParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearUrlCustomParametersList = function() {
  return this.setUrlCustomParametersList([]);
};


/**
 * optional LocalServicesCampaignSettings local_services_campaign_settings = 75;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getLocalServicesCampaignSettings = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings, 75));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.LocalServicesCampaignSettings|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setLocalServicesCampaignSettings = function(value) {
  return jspb.Message.setWrapperField(this, 75, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearLocalServicesCampaignSettings = function() {
  return this.setLocalServicesCampaignSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasLocalServicesCampaignSettings = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional TravelCampaignSettings travel_campaign_settings = 85;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTravelCampaignSettings = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings, 85));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.TravelCampaignSettings|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTravelCampaignSettings = function(value) {
  return jspb.Message.setWrapperField(this, 85, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTravelCampaignSettings = function() {
  return this.setTravelCampaignSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTravelCampaignSettings = function() {
  return jspb.Message.getField(this, 85) != null;
};


/**
 * optional DiscoveryCampaignSettings discovery_campaign_settings = 87;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getDiscoveryCampaignSettings = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings, 87));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.DiscoveryCampaignSettings|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setDiscoveryCampaignSettings = function(value) {
  return jspb.Message.setWrapperField(this, 87, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearDiscoveryCampaignSettings = function() {
  return this.setDiscoveryCampaignSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasDiscoveryCampaignSettings = function() {
  return jspb.Message.getField(this, 87) != null;
};


/**
 * optional google.ads.googleads.v16.common.RealTimeBiddingSetting real_time_bidding_setting = 39;
 * @return {?proto.google.ads.googleads.v16.common.RealTimeBiddingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getRealTimeBiddingSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.RealTimeBiddingSetting} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_real_time_bidding_setting_pb.RealTimeBiddingSetting, 39));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.RealTimeBiddingSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setRealTimeBiddingSetting = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearRealTimeBiddingSetting = function() {
  return this.setRealTimeBiddingSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasRealTimeBiddingSetting = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional NetworkSettings network_settings = 14;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getNetworkSettings = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings, 14));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.NetworkSettings|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setNetworkSettings = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearNetworkSettings = function() {
  return this.setNetworkSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasNetworkSettings = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional HotelSettingInfo hotel_setting = 32;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getHotelSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo, 32));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.HotelSettingInfo|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setHotelSetting = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearHotelSetting = function() {
  return this.setHotelSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasHotelSetting = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional DynamicSearchAdsSetting dynamic_search_ads_setting = 33;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getDynamicSearchAdsSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting, 33));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.DynamicSearchAdsSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setDynamicSearchAdsSetting = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearDynamicSearchAdsSetting = function() {
  return this.setDynamicSearchAdsSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasDynamicSearchAdsSetting = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional ShoppingSetting shopping_setting = 36;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getShoppingSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting, 36));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.ShoppingSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setShoppingSetting = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearShoppingSetting = function() {
  return this.setShoppingSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasShoppingSetting = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.ads.googleads.v16.common.TargetingSetting targeting_setting = 43;
 * @return {?proto.google.ads.googleads.v16.common.TargetingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTargetingSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetingSetting} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_targeting_setting_pb.TargetingSetting, 43));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetingSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTargetingSetting = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTargetingSetting = function() {
  return this.setTargetingSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTargetingSetting = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional AudienceSetting audience_setting = 73;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAudienceSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting, 73));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.AudienceSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAudienceSetting = function(value) {
  return jspb.Message.setWrapperField(this, 73, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearAudienceSetting = function() {
  return this.setAudienceSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasAudienceSetting = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional GeoTargetTypeSetting geo_target_type_setting = 47;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getGeoTargetTypeSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting, 47));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.GeoTargetTypeSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setGeoTargetTypeSetting = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearGeoTargetTypeSetting = function() {
  return this.setGeoTargetTypeSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasGeoTargetTypeSetting = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional LocalCampaignSetting local_campaign_setting = 50;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getLocalCampaignSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting, 50));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.LocalCampaignSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setLocalCampaignSetting = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearLocalCampaignSetting = function() {
  return this.setLocalCampaignSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasLocalCampaignSetting = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional AppCampaignSetting app_campaign_setting = 51;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAppCampaignSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting, 51));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.AppCampaignSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAppCampaignSetting = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearAppCampaignSetting = function() {
  return this.setAppCampaignSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasAppCampaignSetting = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * repeated string labels = 61;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 61));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 61, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 61, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional google.ads.googleads.v16.enums.CampaignExperimentTypeEnum.CampaignExperimentType experiment_type = 17;
 * @return {!proto.google.ads.googleads.v16.enums.CampaignExperimentTypeEnum.CampaignExperimentType}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getExperimentType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.CampaignExperimentTypeEnum.CampaignExperimentType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.CampaignExperimentTypeEnum.CampaignExperimentType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setExperimentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string base_campaign = 56;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getBaseCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setBaseCampaign = function(value) {
  return jspb.Message.setField(this, 56, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearBaseCampaign = function() {
  return jspb.Message.setField(this, 56, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasBaseCampaign = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string campaign_budget = 62;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getCampaignBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setCampaignBudget = function(value) {
  return jspb.Message.setField(this, 62, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearCampaignBudget = function() {
  return jspb.Message.setField(this, 62, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasCampaignBudget = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional google.ads.googleads.v16.enums.BiddingStrategyTypeEnum.BiddingStrategyType bidding_strategy_type = 22;
 * @return {!proto.google.ads.googleads.v16.enums.BiddingStrategyTypeEnum.BiddingStrategyType}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getBiddingStrategyType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BiddingStrategyTypeEnum.BiddingStrategyType} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BiddingStrategyTypeEnum.BiddingStrategyType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setBiddingStrategyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string accessible_bidding_strategy = 71;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAccessibleBiddingStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAccessibleBiddingStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string start_date = 63;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 63, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 63, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional string campaign_group = 76;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getCampaignGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 76, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setCampaignGroup = function(value) {
  return jspb.Message.setField(this, 76, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearCampaignGroup = function() {
  return jspb.Message.setField(this, 76, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasCampaignGroup = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional string end_date = 64;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setEndDate = function(value) {
  return jspb.Message.setField(this, 64, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearEndDate = function() {
  return jspb.Message.setField(this, 64, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional string final_url_suffix = 65;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getFinalUrlSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setFinalUrlSuffix = function(value) {
  return jspb.Message.setField(this, 65, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearFinalUrlSuffix = function() {
  return jspb.Message.setField(this, 65, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasFinalUrlSuffix = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * repeated google.ads.googleads.v16.common.FrequencyCapEntry frequency_caps = 40;
 * @return {!Array<!proto.google.ads.googleads.v16.common.FrequencyCapEntry>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getFrequencyCapsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.FrequencyCapEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_common_frequency_cap_pb.FrequencyCapEntry, 40));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.FrequencyCapEntry>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setFrequencyCapsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 40, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addFrequencyCaps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 40, opt_value, proto.google.ads.googleads.v16.common.FrequencyCapEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearFrequencyCapsList = function() {
  return this.setFrequencyCapsList([]);
};


/**
 * optional google.ads.googleads.v16.enums.BrandSafetySuitabilityEnum.BrandSafetySuitability video_brand_safety_suitability = 42;
 * @return {!proto.google.ads.googleads.v16.enums.BrandSafetySuitabilityEnum.BrandSafetySuitability}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getVideoBrandSafetySuitability = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BrandSafetySuitabilityEnum.BrandSafetySuitability} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BrandSafetySuitabilityEnum.BrandSafetySuitability} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setVideoBrandSafetySuitability = function(value) {
  return jspb.Message.setProto3EnumField(this, 42, value);
};


/**
 * optional VanityPharma vanity_pharma = 44;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.VanityPharma}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getVanityPharma = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.VanityPharma} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.VanityPharma, 44));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.VanityPharma|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setVanityPharma = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearVanityPharma = function() {
  return this.setVanityPharma(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasVanityPharma = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional SelectiveOptimization selective_optimization = 45;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getSelectiveOptimization = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization, 45));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.SelectiveOptimization|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setSelectiveOptimization = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearSelectiveOptimization = function() {
  return this.setSelectiveOptimization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasSelectiveOptimization = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional OptimizationGoalSetting optimization_goal_setting = 54;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getOptimizationGoalSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting, 54));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.OptimizationGoalSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setOptimizationGoalSetting = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearOptimizationGoalSetting = function() {
  return this.setOptimizationGoalSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasOptimizationGoalSetting = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional TrackingSetting tracking_setting = 46;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTrackingSetting = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting, 46));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.TrackingSetting|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTrackingSetting = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTrackingSetting = function() {
  return this.setTrackingSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTrackingSetting = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.ads.googleads.v16.enums.PaymentModeEnum.PaymentMode payment_mode = 52;
 * @return {!proto.google.ads.googleads.v16.enums.PaymentModeEnum.PaymentMode}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getPaymentMode = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PaymentModeEnum.PaymentMode} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PaymentModeEnum.PaymentMode} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setPaymentMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 52, value);
};


/**
 * optional double optimization_score = 66;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getOptimizationScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 66, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setOptimizationScore = function(value) {
  return jspb.Message.setField(this, 66, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearOptimizationScore = function() {
  return jspb.Message.setField(this, 66, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasOptimizationScore = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * repeated google.ads.googleads.v16.enums.AssetFieldTypeEnum.AssetFieldType excluded_parent_asset_field_types = 69;
 * @return {!Array<!proto.google.ads.googleads.v16.enums.AssetFieldTypeEnum.AssetFieldType>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getExcludedParentAssetFieldTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v16.enums.AssetFieldTypeEnum.AssetFieldType>} */ (jspb.Message.getRepeatedField(this, 69));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.enums.AssetFieldTypeEnum.AssetFieldType>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setExcludedParentAssetFieldTypesList = function(value) {
  return jspb.Message.setField(this, 69, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetFieldTypeEnum.AssetFieldType} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addExcludedParentAssetFieldTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 69, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearExcludedParentAssetFieldTypesList = function() {
  return this.setExcludedParentAssetFieldTypesList([]);
};


/**
 * repeated google.ads.googleads.v16.enums.AssetSetTypeEnum.AssetSetType excluded_parent_asset_set_types = 80;
 * @return {!Array<!proto.google.ads.googleads.v16.enums.AssetSetTypeEnum.AssetSetType>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getExcludedParentAssetSetTypesList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v16.enums.AssetSetTypeEnum.AssetSetType>} */ (jspb.Message.getRepeatedField(this, 80));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.enums.AssetSetTypeEnum.AssetSetType>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setExcludedParentAssetSetTypesList = function(value) {
  return jspb.Message.setField(this, 80, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetSetTypeEnum.AssetSetType} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addExcludedParentAssetSetTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 80, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearExcludedParentAssetSetTypesList = function() {
  return this.setExcludedParentAssetSetTypesList([]);
};


/**
 * optional bool url_expansion_opt_out = 72;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getUrlExpansionOptOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 72, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setUrlExpansionOptOut = function(value) {
  return jspb.Message.setField(this, 72, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearUrlExpansionOptOut = function() {
  return jspb.Message.setField(this, 72, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasUrlExpansionOptOut = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional PerformanceMaxUpgrade performance_max_upgrade = 77;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getPerformanceMaxUpgrade = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade, 77));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign.PerformanceMaxUpgrade|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setPerformanceMaxUpgrade = function(value) {
  return jspb.Message.setWrapperField(this, 77, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearPerformanceMaxUpgrade = function() {
  return this.setPerformanceMaxUpgrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasPerformanceMaxUpgrade = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional string hotel_property_asset_set = 83;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getHotelPropertyAssetSet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setHotelPropertyAssetSet = function(value) {
  return jspb.Message.setField(this, 83, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearHotelPropertyAssetSet = function() {
  return jspb.Message.setField(this, 83, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasHotelPropertyAssetSet = function() {
  return jspb.Message.getField(this, 83) != null;
};


/**
 * optional google.ads.googleads.v16.enums.ListingTypeEnum.ListingType listing_type = 86;
 * @return {!proto.google.ads.googleads.v16.enums.ListingTypeEnum.ListingType}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getListingType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.ListingTypeEnum.ListingType} */ (jspb.Message.getFieldWithDefault(this, 86, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.ListingTypeEnum.ListingType} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setListingType = function(value) {
  return jspb.Message.setField(this, 86, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearListingType = function() {
  return jspb.Message.setField(this, 86, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasListingType = function() {
  return jspb.Message.getField(this, 86) != null;
};


/**
 * repeated AssetAutomationSetting asset_automation_settings = 88;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting>}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getAssetAutomationSettingsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting, 88));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting>} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setAssetAutomationSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 88, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.addAssetAutomationSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 88, opt_value, proto.google.ads.googleads.v16.resources.Campaign.AssetAutomationSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearAssetAutomationSettingsList = function() {
  return this.setAssetAutomationSettingsList([]);
};


/**
 * optional string bidding_strategy = 67;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getBiddingStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.setBiddingStrategy = function(value) {
  return jspb.Message.setOneofField(this, 67, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearBiddingStrategy = function() {
  return jspb.Message.setOneofField(this, 67, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasBiddingStrategy = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional google.ads.googleads.v16.common.Commission commission = 49;
 * @return {?proto.google.ads.googleads.v16.common.Commission}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getCommission = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.Commission} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.Commission, 49));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.Commission|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setCommission = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional google.ads.googleads.v16.common.ManualCpa manual_cpa = 74;
 * @return {?proto.google.ads.googleads.v16.common.ManualCpa}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getManualCpa = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.ManualCpa} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.ManualCpa, 74));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.ManualCpa|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setManualCpa = function(value) {
  return jspb.Message.setOneofWrapperField(this, 74, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearManualCpa = function() {
  return this.setManualCpa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasManualCpa = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional google.ads.googleads.v16.common.ManualCpc manual_cpc = 24;
 * @return {?proto.google.ads.googleads.v16.common.ManualCpc}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getManualCpc = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.ManualCpc} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.ManualCpc, 24));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.ManualCpc|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setManualCpc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearManualCpc = function() {
  return this.setManualCpc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasManualCpc = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.ads.googleads.v16.common.ManualCpm manual_cpm = 25;
 * @return {?proto.google.ads.googleads.v16.common.ManualCpm}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getManualCpm = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.ManualCpm} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.ManualCpm, 25));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.ManualCpm|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setManualCpm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearManualCpm = function() {
  return this.setManualCpm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasManualCpm = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.ads.googleads.v16.common.ManualCpv manual_cpv = 37;
 * @return {?proto.google.ads.googleads.v16.common.ManualCpv}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getManualCpv = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.ManualCpv} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.ManualCpv, 37));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.ManualCpv|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setManualCpv = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearManualCpv = function() {
  return this.setManualCpv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasManualCpv = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.ads.googleads.v16.common.MaximizeConversions maximize_conversions = 30;
 * @return {?proto.google.ads.googleads.v16.common.MaximizeConversions}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getMaximizeConversions = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.MaximizeConversions} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.MaximizeConversions, 30));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.MaximizeConversions|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setMaximizeConversions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearMaximizeConversions = function() {
  return this.setMaximizeConversions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasMaximizeConversions = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.ads.googleads.v16.common.MaximizeConversionValue maximize_conversion_value = 31;
 * @return {?proto.google.ads.googleads.v16.common.MaximizeConversionValue}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getMaximizeConversionValue = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.MaximizeConversionValue} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.MaximizeConversionValue, 31));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.MaximizeConversionValue|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setMaximizeConversionValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearMaximizeConversionValue = function() {
  return this.setMaximizeConversionValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasMaximizeConversionValue = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.ads.googleads.v16.common.TargetCpa target_cpa = 26;
 * @return {?proto.google.ads.googleads.v16.common.TargetCpa}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTargetCpa = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetCpa} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.TargetCpa, 26));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetCpa|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTargetCpa = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTargetCpa = function() {
  return this.setTargetCpa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTargetCpa = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.ads.googleads.v16.common.TargetImpressionShare target_impression_share = 48;
 * @return {?proto.google.ads.googleads.v16.common.TargetImpressionShare}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTargetImpressionShare = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetImpressionShare} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.TargetImpressionShare, 48));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetImpressionShare|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTargetImpressionShare = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTargetImpressionShare = function() {
  return this.setTargetImpressionShare(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTargetImpressionShare = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.ads.googleads.v16.common.TargetRoas target_roas = 29;
 * @return {?proto.google.ads.googleads.v16.common.TargetRoas}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTargetRoas = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetRoas} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.TargetRoas, 29));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetRoas|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTargetRoas = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTargetRoas = function() {
  return this.setTargetRoas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTargetRoas = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.ads.googleads.v16.common.TargetSpend target_spend = 27;
 * @return {?proto.google.ads.googleads.v16.common.TargetSpend}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTargetSpend = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetSpend} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.TargetSpend, 27));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetSpend|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTargetSpend = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTargetSpend = function() {
  return this.setTargetSpend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTargetSpend = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.ads.googleads.v16.common.PercentCpc percent_cpc = 34;
 * @return {?proto.google.ads.googleads.v16.common.PercentCpc}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getPercentCpc = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.PercentCpc} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.PercentCpc, 34));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.PercentCpc|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setPercentCpc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearPercentCpc = function() {
  return this.setPercentCpc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasPercentCpc = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.ads.googleads.v16.common.TargetCpm target_cpm = 41;
 * @return {?proto.google.ads.googleads.v16.common.TargetCpm}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.getTargetCpm = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetCpm} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_bidding_pb.TargetCpm, 41));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetCpm|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
*/
proto.google.ads.googleads.v16.resources.Campaign.prototype.setTargetCpm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.google.ads.googleads.v16.resources.Campaign.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.Campaign} returns this
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.clearTargetCpm = function() {
  return this.setTargetCpm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.Campaign.prototype.hasTargetCpm = function() {
  return jspb.Message.getField(this, 41) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v16.resources);
