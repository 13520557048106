import { FormField } from "./CampaignSetupPageState";
import { None } from "Common/utils/tsUtils";
import { MarketplaceInfo } from "Common/utils/marketplace";

const validateAttribution = (
  _marketplaceInfo: MarketplaceInfo | None
): Set<string> => {
  const errors = new Set<string>();
  // TODO: add more validation as necessary. MarketplaceInfo field for a campaign can be empty because we
  // will fall back to the default marketplaceInfo (amazon.com) if none is provided.
  return errors;
};

export const marketplaceInfoField = (
  initialValue: MarketplaceInfo | None
): FormField<MarketplaceInfo | None> => ({
  _value: initialValue,
  errors: validateAttribution(initialValue),
  validator: validateAttribution
});
