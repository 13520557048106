import {
  getStoredFacebookTableDataColumns,
  resetAllFacebookTableOptions,
  setStoredFacebookTableDataColumns
} from "Common/utils/savedTablePreferences";
import { GlobalDateContext } from "ExtensionV2";
import AmpdDataTableOptions, {
  useAmpdDataTableOptions
} from "ExtensionV2/components/AmpdDataTableOptions";
import { GlobalDatePicker } from "ExtensionV2/components/GlobalDatePicker";
import { FB_RESOURCE_TYPE } from "ExtensionV2/queries/useFacebookPerformanceData";
import { popover } from "ExtensionV2/styles/zIndexes";
import React, { useContext, useMemo } from "react";
import { Button, Dropdown, Icon, Label, Popup } from "semantic-ui-react";
import styled from "styled-components";

import {
  allFacebookColumns,
  defaultFacebookColumns,
  FacebookAdsPerformanceTable,
  facebookPerformanceColumns,
  unhideableFacebookColumns
} from "./FacebookPerformanceTable";
import { useFacebookPerformanceRowData } from "./useFacebookPerformanceRowData";

const FilterLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

export function AdPerformanceTab(): JSX.Element {
  const [selectedRollup, setSelectedRollup] = React.useState<FB_RESOURCE_TYPE>(
    FB_RESOURCE_TYPE.AD
  );

  const { startDate, endDate } = useContext(GlobalDateContext);

  const {
    showFractions,
    showUnconvertedRevenue,
    selectableOptionsMap,
    selectedColumns,
    handleSelectColumnOption,
    handleResetTableOptions,
    handleToggleShowFraction,
    handleToggleShowUnconvertedRevenue
  } = useAmpdDataTableOptions({
    allColumns: allFacebookColumns,
    defaultColumns: defaultFacebookColumns,
    unhideableColumns: unhideableFacebookColumns,
    getStoredDataTableColumns: getStoredFacebookTableDataColumns,
    setStoredDataTableColumns: setStoredFacebookTableDataColumns,
    resetAllDataTableOptions: resetAllFacebookTableOptions
  });

  const {
    rowData,
    isLoading: rowsLoading,
    error: rowsError,
    awaitingUpdatedConfigs
  } = useFacebookPerformanceRowData(startDate, endDate, selectedRollup);

  const displayColumns = useMemo(() => {
    if (selectedRollup === FB_RESOURCE_TYPE.AD_SET) {
      return selectedColumns.filter(col => col !== "adSetName");
    } else if (selectedRollup === FB_RESOURCE_TYPE.CAMPAIGN) {
      return selectedColumns.filter(
        col => col !== "adSetName" && col !== "campaignName"
      );
    }

    return selectedColumns;
  }, [selectedColumns, selectedRollup]);

  return (
    <>
      <div style={{ flexShrink: 0, zIndex: popover }}>
        <GlobalDatePicker />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <FacebookTableFilters onSelectRollupLevel={setSelectedRollup} />
          {awaitingUpdatedConfigs && (
            <Label>
              <Icon loading name="spinner" size="large" />
              Refreshing
            </Label>
          )}
          <Popup
            trigger={
              <Button style={{ height: "3em" }} size="medium">
                Table Options
              </Button>
            }
            on="click"
            position="bottom right"
            content={
              <AmpdDataTableOptions
                columnOptionsMap={selectableOptionsMap}
                columnTitles={facebookPerformanceColumns.reduce((acc, curr) => {
                  return { ...acc, [curr.name]: curr.displayName };
                }, {})}
                onToggleColumn={handleSelectColumnOption}
                onToggleShowFractions={handleToggleShowFraction}
                onToggleShowUnconvertedRevenue={
                  handleToggleShowUnconvertedRevenue
                }
                onResetTableOptions={handleResetTableOptions}
                showFractions={showFractions}
                showUnconvertedRevenue={showUnconvertedRevenue}
                showAmazonLagPeriodToggle={false}
                showFractionsToggle={true}
                showUnconvertedRevenueToggle={false}
              />
            }
          />
        </div>
      </div>

      <div
        style={{
          overflow: "auto"
        }}
      >
        <FacebookAdsPerformanceTable
          selectedColumns={displayColumns}
          showFractions={showFractions}
          rows={rowData}
          dataLoading={rowsLoading}
          dataError={rowsError}
        />
      </div>
    </>
  );
}

function FacebookTableFilters({
  onSelectRollupLevel
}: {
  onSelectRollupLevel: (level: FB_RESOURCE_TYPE) => void;
}): JSX.Element {
  return (
    <div style={{ marginTop: "0.5em" }}>
      <FilterLabel>Select Level</FilterLabel>
      <Dropdown
        selection
        defaultValue={FB_RESOURCE_TYPE.AD}
        options={[
          { key: "adId", text: "Ad", value: FB_RESOURCE_TYPE.AD },
          { key: "adSetId", text: "Ad Set", value: FB_RESOURCE_TYPE.AD_SET },
          {
            key: "campaignId",
            text: "Campaign",
            value: FB_RESOURCE_TYPE.CAMPAIGN
          }
        ]}
        onChange={(_, { value }) => {
          onSelectRollupLevel(value as FB_RESOURCE_TYPE);
        }}
      />
    </div>
  );
}
