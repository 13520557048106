import Immutable from "immutable";

import {
  AACOS_RANGE_MAX,
  AACOS_RANGE_MIN,
  ACCOUNTS_METRIC_COLUMNS,
  CAMPAIGNS_COL,
  CampaignStatusFilter,
  CampaignTypeFilter,
  DAILY_BUDGET_COL
} from "ExtensionV2/pages/AccountsPage/AccountsTable";
import {
  calculateDerivedMetrics,
  COLUMN_DATA_KEYS,
  convertMetricColumnToUSD,
  DataObject,
  ENABLED_STATUS,
  MetricColumnKey,
  MetricColumnsMetrics,
  PAUSED_STATUS
} from "ExtensionV2/components/MetricColumns";
import { convertValueToUSD } from "Common/utils/googleAds";
import { defaultSortComparator } from "../../components/AmpdDataTable";
import { ClientSite } from "ExtensionV2/grpc/getClientSitesForManagerSite";
import { CampaignConfiguration } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import { BasicMetrics } from "graphql/graphql";
import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import { None } from "Common/utils/tsUtils";
import { getItemizedCampaignConfiguration } from "ExtensionV2/queries/useItemizedCampaignConfiguration";
import {
  AMAZON_AMPD_CAMPAIGN_TYPE,
  WALMART_AMPD_CAMPAIGN_TYPE
} from "ExtensionV2/pages/CampaignsPage/CampaignsPage";

type WithCompareMetrics = {
  compareMetricsLoading?: boolean;
  compareMetrics?: MetricColumnsMetrics;
};

type CampaignRowData = DataObject &
  WithCompareMetrics & {
    campaignId?: string;
  };
type CampaignMetrics = {
  [K in typeof ACCOUNTS_METRIC_COLUMNS[number]]?: number | None;
} & { [AACOS_RANGE_MIN]?: number; [AACOS_RANGE_MAX]?: number } & BasicMetrics &
  WithCompareMetrics;

export type ClientSiteRowData = DataObject & {
  campaignRowDataList: Array<CampaignRowData>;
  subClientRowDataList: Array<ClientSite>;
};

function getClientSiteRowData({
  clientSite,
  clientSiteAliases = [],
  subClientSitesByClientSiteMap,
  campaignConfigurationsByCampaignIdMap,
  campaignCompareMetricsMap,
  campaignIdsBySiteMap,
  campaignMetricsMap,
  campaignTypeFilter,
  campaignStatusFilter,
  doCompare,
  compareMetricsLoading,
  aliasToCurrency
}: {
  clientSite?: ClientSite;
  clientSiteAliases: Array<string>;
  subClientSitesByClientSiteMap?: Immutable.Map<string, Array<ClientSite>>;
  campaignConfigurationsByCampaignIdMap: Immutable.Map<
    string,
    CampaignConfiguration
  >;
  campaignIdsBySiteMap: Immutable.Map<string, Array<string>>;
  campaignMetricsMap: Immutable.Map<string, BasicMetrics>;
  campaignCompareMetricsMap: Immutable.Map<string, BasicMetrics>;
  campaignTypeFilter: CampaignTypeFilter;
  campaignStatusFilter: CampaignStatusFilter;
  doCompare: boolean;
  compareMetricsLoading: boolean;
  aliasToCurrency?: Record<string, string>;
}): ClientSiteRowData {
  let subClientRowDataList: Array<ClientSite> = [];
  if (clientSite && subClientSitesByClientSiteMap) {
    const subClientSites = subClientSitesByClientSiteMap.get(
      clientSite.clientSiteAlias
    );
    if (subClientSites && subClientSites.length > 0) {
      subClientRowDataList = subClientSites
        .map(subClientSite => ({
          ...subClientSite
        }))
        .sort((a, b) =>
          defaultSortComparator(a, b, COLUMN_DATA_KEYS.clientSiteName, true)
        );
    }
  }

  // Count the number of enabled and paused campaigns
  const campaignsDesc = getCampaignCountDescription(
    clientSiteAliases,
    campaignConfigurationsByCampaignIdMap,
    campaignTypeFilter
  );

  // Initialize site metrics.
  const siteMetrics = initializeSiteMetrics();
  let siteCompareMetrics = null;
  if (doCompare) {
    siteCompareMetrics = initializeSiteMetrics();
  }

  const siteAacosValues: Array<number> = [];
  const siteAacosCompareValues: Array<number> = [];
  let siteBudget = 0;

  const campaignIds = clientSiteAliases.reduce(
    (ids: Array<string>, alias: string) =>
      ids.concat(campaignIdsBySiteMap.get(alias) || []),
    []
  );
  let budgetIds = Immutable.Set();
  const campaignRowDataList = [];
  for (const campaignId of campaignIds) {
    const campaignConfiguration = campaignConfigurationsByCampaignIdMap.get(
      campaignId
    );
    if (!campaignConfiguration) {
      continue;
    }
    const {
      campaignName,
      campaignStatus,
      campaignBudget,
      campaignBudgetId,
      currencyCode
    } = getItemizedCampaignConfiguration(campaignConfiguration, []);

    if (
      campaignStatusFilter === ENABLED_STATUS &&
      campaignStatus != "ENABLED"
    ) {
      continue;
    } else if (
      campaignStatusFilter === PAUSED_STATUS &&
      campaignStatus != "PAUSED"
    ) {
      continue;
    }

    const metrics:
      | (BasicMetrics & {
          [COLUMN_DATA_KEYS.aacos]?: number | None;
        })
      | None = campaignMetricsMap.get(String(campaignId));
    const compareMetrics:
      | (BasicMetrics & {
          [COLUMN_DATA_KEYS.aacos]?: number | None;
        })
      | None = campaignCompareMetricsMap.get(String(campaignId));

    if (metrics) {
      calculateDerivedMetrics(metrics, [
        ...ACCOUNTS_METRIC_COLUMNS,
        COLUMN_DATA_KEYS.aacos
      ]);
      const value = metrics[COLUMN_DATA_KEYS.aacos as keyof BasicMetrics];
      if (value) {
        siteAacosValues.push(Number(value));
      }
    }
    if (compareMetrics) {
      calculateDerivedMetrics(compareMetrics, [
        ...ACCOUNTS_METRIC_COLUMNS,
        COLUMN_DATA_KEYS.aacos
      ]);
      const value = compareMetrics[COLUMN_DATA_KEYS.aacos];
      if (value) {
        siteAacosCompareValues.push(value);
      }
    }

    // Only add the budget for enabled campaigns to the site budget value, because
    // those are the only ones that are active.
    if (campaignStatus === "ENABLED") {
      if (campaignBudgetId && !budgetIds.has(campaignBudgetId)) {
        let addAmount = campaignBudget;
        if (aliasToCurrency) {
          addAmount = convertValueToUSD({
            value: addAmount,
            currency: currencyCode || "USD"
          });
        }

        siteBudget += addAmount;
      }

      budgetIds = budgetIds.add(campaignBudgetId);
    }

    const campaignRowData: CampaignRowData = {
      ...metrics,
      campaignId: campaignId,
      name: campaignName,
      status: campaignStatus,
      [DAILY_BUDGET_COL]: campaignBudget,
      compareMetricsLoading,
      compareMetrics
    };

    campaignRowDataList.push(campaignRowData);

    // Update site metrics.
    if (metrics) {
      updateSiteMetrics(siteMetrics, metrics, !!aliasToCurrency);
    }
    if (siteCompareMetrics && compareMetrics) {
      updateSiteMetrics(siteCompareMetrics, compareMetrics, !!aliasToCurrency);
    }
  }

  // Finalize site metrics.
  finalizeSiteMetrics(siteMetrics, siteAacosValues, siteBudget);
  if (siteCompareMetrics) {
    finalizeSiteMetrics(siteCompareMetrics, siteAacosCompareValues, siteBudget);

    siteMetrics.compareMetricsLoading = compareMetricsLoading;
    siteMetrics.compareMetrics = siteCompareMetrics;
  }

  return {
    ...clientSite,
    ...siteMetrics,
    [CAMPAIGNS_COL]: campaignsDesc,
    campaignRowDataList,
    subClientRowDataList
  };
}

// Returns a string of the form "<enabled count> / <total count>".
function getCampaignCountDescription(
  siteAliases: Array<string>,
  campaignConfigurationsByCampaignIdMap: Immutable.Map<
    string,
    CampaignConfiguration
  >,
  campaignTypeFilter: CampaignTypeFilter
): string | undefined {
  let enabledCount = 0;
  let pausedCount = 0;

  for (const ampdCampaignConfiguration of campaignConfigurationsByCampaignIdMap.values()) {
    if (!siteAliases.includes(ampdCampaignConfiguration.siteAlias)) {
      continue;
    }

    if (
      campaignTypeFilter === AMAZON_AMPD_CAMPAIGN_TYPE &&
      !ampdCampaignConfiguration.ampdProductDetails?.amazon
    ) {
      continue;
    }

    if (
      campaignTypeFilter === WALMART_AMPD_CAMPAIGN_TYPE &&
      !ampdCampaignConfiguration.ampdProductDetails?.walmart
    ) {
      continue;
    }

    const status =
      ampdCampaignConfiguration.ampdResourceConfiguration?.googleAds
        ?.campaignConfiguration?.status;

    if (status === GoogleAdsResourceStatus.Option.ENABLED) {
      enabledCount += 1;
    } else if (status === GoogleAdsResourceStatus.Option.PAUSED) {
      pausedCount += 1;
    }
  }

  if (enabledCount + pausedCount === 0) {
    return "0";
  }

  return `${enabledCount} / ${enabledCount + pausedCount}`;
}

// Initialize site metric values.
function initializeSiteMetrics(): CampaignMetrics {
  const siteMetrics = (ACCOUNTS_METRIC_COLUMNS as Array<
    MetricColumnKey
  >).reduce<MetricColumnsMetrics>(
    (
      data: MetricColumnsMetrics,
      column: MetricColumnKey
    ): MetricColumnsMetrics => {
      data[column] = 0;
      return data;
    },
    {} as MetricColumnsMetrics
  );

  return siteMetrics;
}

// Update site metric values with a campaign's metric values.
function updateSiteMetrics(
  siteMetrics: CampaignMetrics,
  metrics: CampaignMetrics,
  convertToUSD: boolean
) {
  if (!metrics) {
    return;
  }

  const costCurrencyCode = metrics.costCurrencyCode;
  const revenueCurrencyCode = metrics.revenueCurrencyCode;
  if (convertToUSD) {
    siteMetrics.costCurrencyCode = "USD";
    siteMetrics.revenueCurrencyCode = "USD";

    // Sum all site metric columns for now.  The metrics that are not themselves
    // summable but are derived from summable metrics will be calculated after
    // the loop.  Treat all null and NaNs as zeros.
    ACCOUNTS_METRIC_COLUMNS.forEach(column => {
      let siteValue = siteMetrics[column] || 0;
      siteValue +=
        convertMetricColumnToUSD(
          column,
          metrics[column],
          costCurrencyCode || "USD",
          revenueCurrencyCode || "USD"
        ) || 0;
      siteMetrics[column] = siteValue;
    });
  } else {
    siteMetrics.costCurrencyCode = costCurrencyCode;
    siteMetrics.revenueCurrencyCode = revenueCurrencyCode;

    // Sum all site metric columns for now.  The metrics that are not themselves
    // summable but are derived from summable metrics will be calculated after
    // the loop.  Treat all null and NaNs as zeros.
    ACCOUNTS_METRIC_COLUMNS.forEach(column => {
      let siteValue = siteMetrics[column] || 0;
      siteValue += metrics[column] || 0;
      siteMetrics[column] = siteValue;
    });
  }
}

// Finalize site metric values
function finalizeSiteMetrics(
  siteMetrics: CampaignMetrics,
  aacosValues: Array<number>,
  siteBudget: number
) {
  // Now calculate all derived site metric values.
  calculateDerivedMetrics(siteMetrics, ACCOUNTS_METRIC_COLUMNS);

  // For AACOS, we will show a range of values instead of an aggregation.
  aacosValues.sort();
  siteMetrics[AACOS_RANGE_MIN] = aacosValues[0];
  siteMetrics[AACOS_RANGE_MAX] = aacosValues[aacosValues.length - 1];
  siteMetrics[DAILY_BUDGET_COL] = siteBudget;
}

export default getClientSiteRowData;
