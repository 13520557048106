// This is a Typescript-compatible null/undefined items filter function.
// Typescript is unable to infer that .filter(Boolean) is a type guard against
// nullish things, so we have to annotate it so that it can be confident, else
// it will still want an "| null" attached to your array types that have been
// null-filtered.
export function removeNullAndUndefined<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false;

  // https://stackoverflow.com/a/46700791/3395069
  const typeCheckedValue: TValue = value;
  return typeCheckedValue !== null; // avoid typescript unused const lint error
}

export type None = null | undefined;
