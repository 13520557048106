import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Input, TextArea } from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import styled from "styled-components/macro";
import { genTextEncoder } from "ExtensionV2/globals";

import { Colors } from "../../app.css";

const InputWithError = styled(Input)`
  &&&.error > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const TextAreaWithError = styled(TextArea)`
  &&&.error {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const AnnotationText = styled.small`
  opacity: 0.8;
  font-size: 60%;
  line-height: 0.8em;
`;

// Accepts input from the user up to a specified maxLength and with optional
// custom validation.
function InputWithMaxUTF8Size(props) {
  const {
    placeholder,
    text,
    maxLength,
    validateText,
    onTextAccept,
    onTextChange,
    autofocus,
    holdFocusOnEnter,
    partLabel,
    numRows,
    hideAnnotation,
    inputLabel,
    inputLabelPosition,
    noSpellCheck,
    disabled
  } = props;

  const [editText, setEditText] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setEditText(text || "");
  }, [text]);

  const utf8Length = getUTF8Length(editText);

  const handleInputChange = (e, { value }) => {
    if (value !== editText) {
      if (onTextChange) {
        onTextChange(value);
      }

      setEditText(value);
    }
  };

  const handleInputKeyPress = e => {
    e.stopPropagation();
    if (e.keyCode === 13 || e.which === 13) {
      const elem = e.target;

      if (holdFocusOnEnter && editText) {
        onTextAccept(editText);
        setEditText("");
      } else {
        elem.blur();
      }
    }
  };

  const handleInputBlur = () => {
    if (editText !== null) {
      onTextAccept(editText);
    }
  };

  const [validateMessage, validateFixAction, validateFix] = validateInput(
    editText,
    maxLength,
    validateText
  );

  const handleValidateFixMouseDown = !validateFix
    ? undefined
    : e => {
        e.stopPropagation();
        e.preventDefault();

        const newText = validateFix(editText);
        setEditText(newText);

        if (inputRef.current) {
          inputRef.current.focus();
        }
      };

  return (
    <div
      style={{
        width: "100%"
      }}
    >
      <div>
        {numRows ? (
          <TextAreaWithError
            disabled={disabled}
            rows={numRows}
            style={{ fontSize: "90%", width: "100%" }}
            className={validateMessage ? "error" : undefined}
            wrap="soft"
            spellCheck={true}
            value={editText || ""}
            autoFocus={autofocus}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            onBlur={handleInputBlur}
          />
        ) : (
          <InputWithError
            disabled={disabled}
            ref={inputRef}
            style={{ fontSize: "90%" }}
            fluid
            error={!!validateMessage}
            value={editText || ""}
            autoFocus={autofocus}
            placeholder={placeholder}
            label={inputLabel}
            labelPosition={inputLabelPosition}
            input={
              <input
                data-lpignore="true"
                spellCheck={noSpellCheck ? "false" : "true"}
              /> /* To avoid LastPass trying to manage the control */
            }
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            onBlur={handleInputBlur}
          />
        )}
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {!hideAnnotation && (
            <span style={{ lineHeight: ".8em" }}>
              <Breadcrumb>
                {!!validateMessage && (
                  <>
                    <Breadcrumb.Section>
                      <small style={{ color: Colors.negative, marginRight: 3 }}>
                        {validateMessage}
                      </small>
                    </Breadcrumb.Section>
                    {!!validateFixAction && !!validateFix && (
                      <Breadcrumb.Section
                        link
                        onMouseDown={handleValidateFixMouseDown}
                      >
                        <small>{validateFixAction}</small>
                      </Breadcrumb.Section>
                    )}
                  </>
                )}
              </Breadcrumb>
            </span>
          )}
          {partLabel && (
            <AnnotationText style={{ marginLeft: "auto", marginRight: 20 }}>
              {partLabel}
            </AnnotationText>
          )}
          {maxLength && (
            <AnnotationText
              style={{
                color:
                  utf8Length > maxLength ? Colors.negative : Colors.positive,
                minWidth: 40,
                textAlign: "right"
              }}
            >
              {utf8Length} / {maxLength}
            </AnnotationText>
          )}
        </Flex>
      </div>
    </div>
  );
}

export function validateInput(text, maxLength, validateText) {
  if (maxLength && getUTF8Length(text) > maxLength) {
    return [
      "Too many characters:",
      "click to truncate",
      s => truncateString(s, maxLength)
    ];
  } else if (validateText) {
    return validateText(text);
  }

  return [undefined, undefined, undefined];
}

const truncateString = (text, maxLength) => {
  let newText = text.slice(0, maxLength).trim();
  while (newText && getUTF8Length(newText) > maxLength) {
    newText = newText.slice(0, newText.length - 1).trim();
  }

  return newText;
};

const utf8TextEncoder = genTextEncoder();

export function getUTF8Length(text) {
  if (!text) {
    return 0;
  }
  return utf8TextEncoder.encode(text).length;
}

export default InputWithMaxUTF8Size;
