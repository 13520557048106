import React, { useState } from "react";
import styled from "styled-components/macro";
import { Flex } from "@rebass/grid";

import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  renderFormattedValue,
  InlineLoadingSpinner
} from "./MetricColumns";

import { ReactComponent as Indent } from "assets/indent.svg";
import { ReactComponent as IndentLast } from "assets/indent-last.svg";
import {
  CampaignNameCell,
  KeywordTextCell,
  KeywordTextFlexWrapper,
  ActiveKeywordText
} from "./CampaignsTableRow";
import SimpleTooltip from "./SimpleTooltip";
import { pluralize } from "Common/utils/strings";
import {
  DataTableRow,
  DataTableRowCell,
  DataTableMetricCell
} from "./AmpdDataTable";
import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";

export const DEFAULT_SAMPLE_SEARCH_TERM_ROWS = 10;
export const INCREMENT_SAMPLE_SEARCH_TERM_ROWS = 100;

const LinkButton = styled.a`
  font-style: normal;
  font-weight: bold;
`;

export const CampaignDetailsTableMoreSearchTermsRow = ({
  columns,
  allSearchTerms,
  searchTermsLoading,
  isLastSearchTermRow,
  sampleSearchTermRows,
  setMaxSampleSearchTerms,
  rowIndex
}) => {
  const sampleCount = (sampleSearchTermRows || []).length;
  const searchTermCount = (allSearchTerms || []).length;

  const handleMore = () =>
    setMaxSampleSearchTerms(max =>
      max
        ? max + INCREMENT_SAMPLE_SEARCH_TERM_ROWS
        : DEFAULT_SAMPLE_SEARCH_TERM_ROWS
    );
  const handleFewer = () =>
    setMaxSampleSearchTerms(max =>
      Math.max(max - INCREMENT_SAMPLE_SEARCH_TERM_ROWS, 0)
    );

  return (
    <DataTableRow
      style={{ fontSize: "small" }}
      isLastKeywordRow={isLastSearchTermRow}
    >
      {(columns || []).map(columnName => {
        switch (columnName) {
          case COLUMN_DATA_KEYS.keywords:
            return (
              <CampaignNameCell
                key={columnName}
                rowIndex={rowIndex}
                style={{ cursor: "pointer" }}
              >
                <Flex
                  style={{ float: "right" }}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {searchTermCount > 0 && (
                    <>
                      {sampleCount < searchTermCount && (
                        <span>
                          &nbsp;&bull;&nbsp;
                          <SimpleTooltip tooltip="Show more search terms">
                            <span>
                              <LinkButton onClick={handleMore}>
                                Show More
                              </LinkButton>
                            </span>
                          </SimpleTooltip>
                        </span>
                      )}
                      {sampleCount > 0 && (
                        <span>
                          &nbsp;&bull;&nbsp;
                          <SimpleTooltip tooltip="Show fewer search terms">
                            <span>
                              <LinkButton onClick={handleFewer}>
                                Show Fewer
                              </LinkButton>
                            </span>
                          </SimpleTooltip>
                        </span>
                      )}
                    </>
                  )}
                </Flex>

                <KeywordTextFlexWrapper>
                  {searchTermsLoading ? (
                    <InlineLoadingSpinner />
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontStyle: "italic",
                        paddingLeft: "1em"
                      }}
                    >
                      {sampleCount}&nbsp;/&nbsp;
                      {pluralize(searchTermCount, "search term")}
                    </p>
                  )}
                </KeywordTextFlexWrapper>
              </CampaignNameCell>
            );

          default:
            return <DataTableRowCell key={columnName} rowIndex={rowIndex} />;
        }
      })}
    </DataTableRow>
  );
};

const CampaignDetailsTableSearchTermRow = ({
  columns,
  costCurrencyCode,
  isLastSearchTerm,
  searchTermObject,
  rowIndex,
  showFractions,
  onAddSearchTermAsKeyword,
  onAddSearchTermAsNegativeKeyword,
  keywords,
  negativeKeywords
}) => {
  const queryText = searchTermObject[COLUMN_DATA_KEYS.queryText];

  let existingPositiveKeyword = false;
  let paused = false;
  for (const keyword of keywords) {
    if (keyword.text === queryText) {
      existingPositiveKeyword = true;
      paused = keyword.status === GoogleAdsResourceStatus.Option.PAUSED;
    }
  }

  let existingNegativeKeyword = false;
  for (const keyword of negativeKeywords) {
    if (keyword.text === queryText) {
      existingNegativeKeyword = true;
    }
  }

  const [isAddingKeyword, setIsAddingKeyword] = useState(false);
  const [addKeywordErrorMessage, setAddKeywordErrorMessage] = useState("");
  const [isAddingNegativeKeyword, setIsAddingNegativeKeyword] = useState(false);
  const [
    addNegativeKeywordErrorMessage,
    setAddNegativeKeywordErrorMessage
  ] = useState("");

  const handleAddKeyword = async () => {
    setIsAddingKeyword(true);
    const errorMessage = await onAddSearchTermAsKeyword(queryText);
    setAddKeywordErrorMessage(errorMessage);
    setIsAddingKeyword(false);
  };
  const handleAddNegativeKeyword = async () => {
    setIsAddingNegativeKeyword(true);
    const errorMessage = await onAddSearchTermAsNegativeKeyword(queryText);
    setAddNegativeKeywordErrorMessage(errorMessage);
    setIsAddingNegativeKeyword(false);
  };

  return (
    <DataTableRow
      style={{ fontSize: "small" }}
      isLastKeywordRow={isLastSearchTerm}
    >
      {(columns || []).map(columnName => {
        switch (columnName) {
          case COLUMN_DATA_KEYS.keywords:
            return (
              <KeywordTextCell key={columnName} rowIndex={rowIndex}>
                <KeywordTextFlexWrapper>
                  {isLastSearchTerm ? <IndentLast /> : <Indent />}
                  <ActiveKeywordText>{queryText}</ActiveKeywordText>
                </KeywordTextFlexWrapper>
              </KeywordTextCell>
            );
          case COLUMN_DATA_KEYS.status: {
            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                <Flex
                  style={{ gap: ".3em" }}
                  flexDirection="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {existingPositiveKeyword && (
                    <span>
                      <em>{paused ? "paused keyword" : "enabled keyword"}</em>
                    </span>
                  )}
                  {!existingPositiveKeyword && !existingNegativeKeyword && (
                    <span style={{ whiteSpace: "nowrap" }}>
                      {isAddingKeyword ? (
                        <InlineLoadingSpinner />
                      ) : (
                        <span>&nbsp;&bull;&nbsp;</span>
                      )}
                      <LinkButton
                        style={{ display: "inline-block" }}
                        onClick={handleAddKeyword}
                      >
                        Add as Keyword
                      </LinkButton>
                      {addKeywordErrorMessage && (
                        <span style={{ color: "red" }}>
                          {" "}
                          - {addKeywordErrorMessage}
                        </span>
                      )}
                    </span>
                  )}
                  {existingNegativeKeyword && (
                    <span>
                      <em>negative keyword</em>
                    </span>
                  )}
                  {!existingNegativeKeyword &&
                    (!existingPositiveKeyword || paused) && (
                      <span style={{ whiteSpace: "nowrap" }}>
                        {isAddingNegativeKeyword ? (
                          <InlineLoadingSpinner />
                        ) : (
                          <span>&nbsp;&bull;&nbsp;</span>
                        )}
                        <LinkButton
                          style={{ display: "inline-block" }}
                          onClick={handleAddNegativeKeyword}
                        >
                          Add as Negative
                        </LinkButton>
                        {addNegativeKeywordErrorMessage && (
                          <span style={{ color: "red" }}>
                            {" "}
                            - {addNegativeKeywordErrorMessage}
                          </span>
                        )}
                      </span>
                    )}
                </Flex>
              </DataTableMetricCell>
            );
          }
          case COLUMN_DATA_KEYS.impressions:
          case COLUMN_DATA_KEYS.clicks:
          case COLUMN_DATA_KEYS.clickThroughRate:
          case COLUMN_DATA_KEYS.cost:
          case COLUMN_DATA_KEYS.averageCpc: {
            const formattedValue = formatMetricColumnValue(
              columnName,
              searchTermObject[columnName],
              costCurrencyCode,
              costCurrencyCode,
              showFractions
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue)}
              </DataTableMetricCell>
            );
          }
          default:
            return <DataTableRowCell key={columnName} rowIndex={rowIndex} />;
        }
      })}
    </DataTableRow>
  );
};

export default CampaignDetailsTableSearchTermRow;
