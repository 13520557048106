import Immutable from "immutable";
import moment from "moment";

import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useApolloClient } from "@apollo/react-hooks";
import { BasicMetrics } from "../../graphql/graphql";
import { queryGoogleAdsCampaignMetrics } from "../grpc/queryGoogleAds";

export const RECENT_CAMPAIGN_METRICS_QUERY_KEY_NAME =
  "RecentMetricsForCampaigns";

export const RECENT_METRICS_DAILY_DAYS = 1;
export const RECENT_METRICS_WEEKLY_DAYS = 7;
export const RECENT_METRICS_BIWEEKLY_DAYS = 14;
export const RECENT_METRICS_MONTHLY_DAYS = 30;

export type RecentMetricsForCampaigns = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  campaignToMetricsMap: Immutable.Map<string, BasicMetrics>;
};

export const DATE_FORMAT = "YYYY-MM-DD";

export const useRecentMetricsForCampaigns = ({
  siteAlias,
  startDate,
  endDate,
  campaignIds,
  recentMetricsDays
}: {
  siteAlias: string;
  startDate: string;
  endDate: string;
  campaignIds: Array<string>;
  recentMetricsDays: number;
}): UseQueryResult<Array<RecentMetricsForCampaigns>> => {
  const apolloClient = useApolloClient();

  return useQuery({
    queryKey: [
      RECENT_CAMPAIGN_METRICS_QUERY_KEY_NAME,
      siteAlias,
      startDate,
      endDate,
      campaignIds.join(":"),
      recentMetricsDays
    ],
    staleTime: 15 * 60 * 1_000, // 15 minutes
    enabled: !!siteAlias && !!startDate && !!endDate,
    queryFn: async () => {
      const dateRanges = splitDateRange(startDate, endDate, recentMetricsDays);

      const promises: Array<Promise<void>> = [];
      const recentMetricsForCampaigns: Array<RecentMetricsForCampaigns> = [];
      for (const [recentStartDate, recentEndDate] of dateRanges) {
        const recentCampaignMetrics = {
          startDate: recentStartDate,
          endDate: recentEndDate,
          campaignToMetricsMap: Immutable.Map<string, BasicMetrics>()
        };
        recentMetricsForCampaigns.push(recentCampaignMetrics);

        const queryMetrics = async () => {
          const { siteMetricsMap } = await queryGoogleAdsCampaignMetrics(
            apolloClient,
            siteAlias,
            recentStartDate.format(DATE_FORMAT),
            recentEndDate.format(DATE_FORMAT),
            false,
            campaignIds
          );

          // queryGoogleAdsCampaignMetrics returns an array of BasicMetrics in case
          // queryByDay was true, but it is false for us, so we only want the first
          // element in each array.
          recentCampaignMetrics.campaignToMetricsMap = Immutable.Map(
            siteMetricsMap.mapEntries(([campaignId, metricsList]) => [
              campaignId,
              metricsList[0] || {}
            ])
          );
        };

        promises.push(queryMetrics());
      }

      await Promise.all(promises);

      return recentMetricsForCampaigns;
    }
  });
};

function splitDateRange(
  startDate: string,
  endDate: string,
  recentMetricsDays: number
): Array<[moment.Moment, moment.Moment]> {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const dateRanges: Array<[moment.Moment, moment.Moment]> = [];

  for (
    ;
    startMoment.isBefore(endMoment);
    endMoment.add(-recentMetricsDays, "days")
  ) {
    const periodEndDate = moment(endMoment);
    const periodStartDate = moment(endMoment).add(
      -recentMetricsDays + 1,
      "days"
    );

    dateRanges.unshift([periodStartDate, periodEndDate]);
  }

  return dateRanges;
}
