// grpc-web
import _ from "lodash";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UnlinkDataSourceReply,
  UnlinkDataSourceRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";

// Disconnects a Site from a data source (ie: removes datasource linking info).
export default async function removeSiteDataSourceLink(
  removeDataSourceReq: UnlinkDataSourceRequest.AsObject
): Promise<UnlinkDataSourceReply.AsObject | undefined> {
  const { amazonSeller, amazonAdvertising, adwords } = removeDataSourceReq;

  const dataSourceCount = [amazonSeller, amazonAdvertising, adwords].filter(
    Boolean
  ).length;

  if (dataSourceCount !== 1) {
    console.error(
      `removeSiteDataSourceLink() can only remove exactly 1 data source link at a time, found ${JSON.stringify(
        removeDataSourceReq
      )}`
    );
    return;
  }

  const req = new UnlinkDataSourceRequest().setSiteAlias(
    removeDataSourceReq.siteAlias
  );

  if (!_.isEmpty(amazonSeller)) {
    const selector = new UnlinkDataSourceRequest.AmazonSellerAccount()
      .setSellingPartnerId(amazonSeller.sellingPartnerId)
      .setRegion(amazonSeller.region);

    req.setAmazonSeller(selector);
  } else if (!_.isEmpty(amazonAdvertising)) {
    const selector = new UnlinkDataSourceRequest.AmazonAdvertisingAccountSelector()
      .setAccountUserId(amazonAdvertising.accountUserId)
      .setAccountRegion(amazonAdvertising.accountRegion);

    req.setAmazonAdvertising(selector);
  } else if (!_.isEmpty(adwords)) {
    const selector = new UnlinkDataSourceRequest.AdwordsAccountSelector().setCustomerId(
      adwords.customerId
    );

    req.setAdwords(selector);
  } else {
    console.error(
      `removeSiteDataSourceLink() received an unknown dataSource, found ${JSON.stringify(
        removeDataSourceReq
      )}`
    );
    return;
  }

  const reply = await GRPCWebClient.unlinkDataSource(req, {});
  return reply.toObject();
}
