import React from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { notify } from "react-notify-toast";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";

import { SiteUsers } from "ExtensionV2/components/SiteUsersTable";

// Dropdown with smaller text size.
const SmallTextDropdown = styled(Dropdown)`
  &.ui.dropdown .menu > .item {
    font-size: 0.9rem;
  }
`;

const roleOptions = [
  {
    key: "USER",
    text: "User",
    value: "USER"
  },
  {
    key: "ADMIN",
    text: "Admin",
    value: "ADMIN"
  },
  {
    key: "DISABLED",
    text: "Disabled",
    value: "DISABLED"
  }
];

const ChangeUserRole = gql`
  mutation($siteAlias: String!, $userId: String, $newSiteRole: SiteRole!) {
    changeSiteRole(
      site: { siteAlias: $siteAlias }
      user: { id: $userId }
      newSiteRole: $newSiteRole
    ) {
      ok
    }
  }
`;

export function UserEditRole(props) {
  const { session, siteAlias, user, currentRole, disabled } = props;

  const [updateRole, { loading, error }] = useMutation(ChangeUserRole, {
    name: "changeUserRole",
    refetchQueries: [
      {
        query: SiteUsers,
        skip: !siteAlias,
        variables: {
          siteAlias
        }
      }
    ],
    onError(error) {
      console.error(error);
    }
  });

  if (error) {
    notify.show(error.message, "error");
  }

  return (
    <SmallTextDropdown
      disabled={disabled || session.user.id === user.id}
      loading={loading}
      options={roleOptions}
      value={currentRole}
      onChange={(_ev, { value }) => {
        updateRole({
          variables: {
            siteAlias,
            userId: user.id,
            newSiteRole: value
          }
        });
      }}
    />
  );
}
