import _ from "lodash";

import React, { useCallback } from "react";
import { Button, Card, Form } from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import LazyLoad from "react-lazyload";

import { formatMetric } from "Common/utils/metrics";
import { GOOGLE_ADS_DAYS_PER_MONTH } from "Common/utils/googleAds";
import { GetCampaignBidAutomationResultsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { getCurrencyMetricDef } from "Common/utils/money";

import styled from "styled-components/macro";
import SimpleTooltip from "../SimpleTooltip";
import {
  InlineLoadingSpinner,
  roundedNumberMetricDef,
  roundedPercentageMetricDef
} from "../MetricColumns";
import {
  VoltageBudgetSparklineConfig,
  VoltageMetricSparklineConfig
} from "../campaignEditor/highcharts";
import { VOLTAGE_MIN, VOLTAGE_STEP } from "./TargetVoltageSlider";
import { MarketplaceInfo } from "Common/utils/marketplace";

const CenteredSpan = styled.span`
  text-align: center;
`;

export type RecentMetricsDisplay = {
  startDateString: string;
  endDateString: string;
  costString: string;
  clicksString: string;
  revenueString: string;
  aacosString: string;
  bold: boolean;
};

type VoltageCharacteristics = GetCampaignBidAutomationResultsReply.VoltageCharacteristics.AsObject;

const TargetVoltageMetrics: React.FC<{
  disabled: boolean;
  targetVoltage: number;
  setTargetVoltage: (value: number) => void;
  targetVoltageCharacteristics: VoltageCharacteristics | null;
  allVoltageCharacteristics: Array<VoltageCharacteristics> | undefined;
  targetVoltageIndex: number;
  currencyCode: string;
  marketplaceInfo: MarketplaceInfo;
  dailyBudget: number;
  recommendedDailyBudget: number;
  setDailyBudget: (value: number) => void;
  daysForMetrics: number;
  recentMetrics: Array<RecentMetricsDisplay>;
  recentMetricsLoading: boolean;
}> = ({
  disabled,
  targetVoltage: _targetVoltage,
  setTargetVoltage,
  targetVoltageCharacteristics,
  allVoltageCharacteristics,
  targetVoltageIndex,
  currencyCode,
  marketplaceInfo,
  dailyBudget,
  recommendedDailyBudget,
  setDailyBudget,
  daysForMetrics,
  recentMetrics,
  recentMetricsLoading
}) => {
  const showCents =
    (targetVoltageCharacteristics?.costMid || 0) < 10 ||
    (targetVoltageCharacteristics?.revenueMid || 0) < 10;

  const costCurrencyMetricDef = getCurrencyMetricDef(currencyCode, showCents);
  const revenueCurrencyMetricDef = getCurrencyMetricDef(
    marketplaceInfo.currencyCode,
    showCents
  );

  const handleChartClick = useCallback(
    (voltageIndex: number) => {
      if (!disabled) {
        setTargetVoltage(voltageIndex * VOLTAGE_STEP + VOLTAGE_MIN);
      }
    },
    [disabled, setTargetVoltage]
  );

  return (
    <Form>
      <Form.Field>
        <LazyLoad height="18em" overflow>
          <Card.Group
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
              fontSize: "small"
            }}
          >
            <MetricCard
              name="cost"
              metricDef={costCurrencyMetricDef}
              valueLabel="Cost"
              midField="costMid"
              lowField="costLow"
              highField="costHigh"
              valueMultiplier={daysForMetrics / 7}
              targetVoltageCharacteristics={targetVoltageCharacteristics}
              allVoltageCharacteristics={allVoltageCharacteristics}
              targetVoltageIndex={targetVoltageIndex}
              onChartClick={handleChartClick}
              recentMetricsLoading={recentMetricsLoading}
              recentMetrics={recentMetrics}
              recentMetricField="costString"
            />

            <MetricCard
              name="clicks"
              metricDef={roundedNumberMetricDef}
              valueLabel="Clicks"
              midField="clicksMid"
              lowField="clicksLow"
              highField="clicksHigh"
              valueMultiplier={daysForMetrics / 7}
              targetVoltageCharacteristics={targetVoltageCharacteristics}
              allVoltageCharacteristics={allVoltageCharacteristics}
              targetVoltageIndex={targetVoltageIndex}
              onChartClick={handleChartClick}
              recentMetricsLoading={recentMetricsLoading}
              recentMetrics={recentMetrics}
              recentMetricField="clicksString"
            />

            <MetricCard
              name="revenue"
              metricDef={revenueCurrencyMetricDef}
              valueLabel="Revenue"
              midField="revenueMid"
              lowField="revenueLow"
              highField="revenueHigh"
              valueMultiplier={daysForMetrics / 7}
              targetVoltageCharacteristics={targetVoltageCharacteristics}
              allVoltageCharacteristics={allVoltageCharacteristics}
              targetVoltageIndex={targetVoltageIndex}
              onChartClick={handleChartClick}
              recentMetricsLoading={recentMetricsLoading}
              recentMetrics={recentMetrics}
              recentMetricField="revenueString"
            />

            <MetricCard
              name="AACOS"
              metricDef={roundedPercentageMetricDef}
              valueLabel="AACOS"
              midField="aacosMid"
              lowField="aacosLow"
              highField="aacosHigh"
              valueMultiplier={1}
              targetVoltageCharacteristics={targetVoltageCharacteristics}
              allVoltageCharacteristics={allVoltageCharacteristics}
              targetVoltageIndex={targetVoltageIndex}
              onChartClick={handleChartClick}
              recentMetricsLoading={recentMetricsLoading}
              recentMetrics={recentMetrics}
              recentMetricField="aacosString"
            />

            <Card style={{ width: "10em", flexGrow: "1.5" }}>
              <Card.Content>
                <Card.Header textAlign="center">Budget</Card.Header>
                <Card.Description textAlign="center">
                  Recommended:{" "}
                  <span style={{ fontSize: "larger" }}>
                    {formatMetric(
                      costCurrencyMetricDef,
                      recommendedDailyBudget * daysForMetrics
                    )}
                  </span>
                  <Flex flexDirection="row" justifyContent="space-evenly">
                    <CenteredSpan>
                      <strong>
                        {formatMetric(
                          getCurrencyMetricDef(currencyCode, true),
                          recommendedDailyBudget
                        )}{" "}
                        daily
                      </strong>
                    </CenteredSpan>
                    <span>/</span>
                    <CenteredSpan>
                      {formatMetric(
                        costCurrencyMetricDef,
                        recommendedDailyBudget * GOOGLE_ADS_DAYS_PER_MONTH
                      )}{" "}
                      monthly
                    </CenteredSpan>
                  </Flex>
                </Card.Description>
              </Card.Content>
              <Card.Content style={{ height: "5em" }}>
                {!!recommendedDailyBudget && (
                  <Button
                    style={{ width: "100%" }}
                    positive={recommendedDailyBudget > dailyBudget}
                    size="mini"
                    compact
                    onClick={() => setDailyBudget(recommendedDailyBudget)}
                  >
                    Use Recommended
                  </Button>
                )}
              </Card.Content>
              <Card.Content style={{ height: 100 }}>
                <Flex flexDirection="row" justifyContent="space-around">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={VoltageBudgetSparklineConfig({
                      name: "budget",
                      values: _.map(
                        allVoltageCharacteristics,
                        c => (c["recommendedBudget"] * daysForMetrics) / 7
                      ),
                      currentX: targetVoltageIndex,
                      setCurrentX: handleChartClick,
                      formatX: formatVoltageIndex,
                      metricDef: costCurrencyMetricDef,
                      referenceY: dailyBudget * daysForMetrics
                    })}
                  />
                </Flex>
              </Card.Content>
            </Card>
          </Card.Group>
        </LazyLoad>
      </Form.Field>
    </Form>
  );
};

// Turns voltage index value into a formatted string.
function formatVoltageIndex(voltageIndex: number): string {
  return `${(voltageIndex * VOLTAGE_STEP + VOLTAGE_MIN).toFixed(1)}`;
}

const MetricCard: React.FC<{
  name: string;
  metricDef: unknown;
  valueLabel: string;
  midField: keyof VoltageCharacteristics;
  lowField: keyof VoltageCharacteristics;
  highField: keyof VoltageCharacteristics;
  valueMultiplier: number;
  targetVoltageCharacteristics: VoltageCharacteristics | null;
  allVoltageCharacteristics: Array<VoltageCharacteristics> | undefined;
  targetVoltageIndex: number;
  onChartClick: (voltageIndex: number) => void;
  recentMetricsLoading: boolean;
  recentMetrics: Array<RecentMetricsDisplay>;
  recentMetricField: keyof Omit<RecentMetricsDisplay, "bold">;
}> = ({
  name,
  metricDef,
  valueLabel,
  midField,
  lowField,
  highField,
  valueMultiplier,
  targetVoltageCharacteristics,
  allVoltageCharacteristics,
  targetVoltageIndex,
  onChartClick,
  recentMetricsLoading,
  recentMetrics,
  recentMetricField
}) => {
  return (
    <Card style={{ width: "10em", flexGrow: "1" }}>
      <Card.Content>
        <Card.Header textAlign="center">{valueLabel}</Card.Header>
        <Card.Description textAlign="center">
          <SimpleTooltip
            tooltip={
              "Expected value at recommended budget over date span," +
              " with 20% chance outside of range below"
            }
          >
            <span>
              Projected:{" "}
              <strong style={{ fontSize: "larger" }}>
                {formatMetric(
                  metricDef,
                  targetVoltageCharacteristics
                    ? targetVoltageCharacteristics[midField] * valueMultiplier
                    : undefined
                )}
              </strong>
            </span>
          </SimpleTooltip>
          <Flex flexDirection="row" justifyContent="space-evenly">
            <CenteredSpan>
              {formatMetric(
                metricDef,
                targetVoltageCharacteristics
                  ? targetVoltageCharacteristics[lowField] * valueMultiplier
                  : undefined
              )}
            </CenteredSpan>
            <span>-</span>
            <CenteredSpan>
              {formatMetric(
                metricDef,
                targetVoltageCharacteristics
                  ? targetVoltageCharacteristics[highField] * valueMultiplier
                  : undefined
              )}
            </CenteredSpan>
          </Flex>
        </Card.Description>
      </Card.Content>
      <Card.Content style={{ height: "5em" }}>
        <Card.Description textAlign="center">
          Actual: {recentMetricsLoading && <InlineLoadingSpinner />}
          {recentMetrics.map((metrics, index) => (
            <MetricDisplay
              key={index}
              index={index}
              startDate={metrics.startDateString}
              endDate={metrics.endDateString}
              metricString={metrics[recentMetricField]}
              bold={metrics.bold}
            />
          ))}
        </Card.Description>
      </Card.Content>
      <Card.Content style={{ height: 100 }}>
        <Flex flexDirection="row" justifyContent="space-around">
          <HighchartsReact
            highcharts={Highcharts}
            options={VoltageMetricSparklineConfig({
              name: name,
              lowValues: _.map(
                allVoltageCharacteristics,
                c => c[lowField] * valueMultiplier
              ),
              midValues: _.map(
                allVoltageCharacteristics,
                c => c[midField] * valueMultiplier
              ),
              highValues: _.map(
                allVoltageCharacteristics,
                c => c[highField] * valueMultiplier
              ),
              currentX: targetVoltageIndex,
              setCurrentX: onChartClick,
              formatX: formatVoltageIndex,
              metricDef: metricDef
            })}
          />
        </Flex>
      </Card.Content>
    </Card>
  );
};

const MetricDisplay: React.FC<{
  index: number;
  startDate: string;
  endDate: string;
  metricString: string;
  bold: boolean;
}> = ({ index, startDate, endDate, metricString, bold }) => {
  return (
    <>
      {index > 0 && <span> / </span>}
      <SimpleTooltip
        tooltip={
          <span>
            {startDate} - <br />
            {endDate}
          </span>
        }
      >
        <span
          style={{ fontWeight: bold ? "bold" : "normal", whiteSpace: "nowrap" }}
        >
          {metricString}
        </span>
      </SimpleTooltip>
    </>
  );
};

export default TargetVoltageMetrics;
