import _ from "lodash";
import React, { useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  Message,
  Modal
} from "semantic-ui-react";
import isEmail from "validator/lib/isEmail";

import {
  extractErrorMessage,
  extractErrorRequestID
} from "Common/errors/error";
import {
  LinkClientSiteToManagerSiteRequest,
  SignUpLinkedSiteRequest,
  SignUpRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { InternalSiteDetails, SiteDetails } from "Common/proto/entity/site_pb";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { pluralize } from "Common/utils/strings";
import { convertUTMParamsToProto, getUTMParams } from "Common/utils/utmParams";

import { EXISTING_USER } from "../../components/AttributionDropdown";
import { sendGAEvent } from "../../components/GA";
import { gmvBucketDropdownOptions } from "../SignUpPage";
import { ACCOUNTS_DASHBOARD_GA_CATEGORY } from "./AccountsPage";

import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useSessionUser } from "ExtensionV2/queries/useSessionUser";

export function linkBillingExplanationMessage(
  clientBillingAccountCount: number,
  clientBillingAccountMax: number
): string {
  const baseMessage = `By linking this client to your payment plan, the client will not need to 
  enter payment information and you will be billed for their actions in accordance with the 
  enterprise agreement you have with Ampd. You have
  ${pluralize(clientBillingAccountCount, "site", "sites")}
  currently linked to your payment plan`;

  if (clientBillingAccountMax < Infinity) {
    return `${baseMessage} out of a maximum of
    ${clientBillingAccountMax}.`;
  } else {
    return `${baseMessage}.`;
  }
}

export const maxClientBillingAccountMessage = `
This account has the maximum number of client accounts
linked to its payment plan. You can still create new client
accounts but they will be prompted to provide their own
billing information. Please reach out about increasing your
limit.
`;

function CreateNewAccountButton({
  managerSiteAlias,
  onDone,
  initialLabels
}: {
  managerSiteAlias: string;
  onDone: (clientSiteAlias: string) => void;
  initialLabels: string[];
}): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        color="green"
        onClick={() => {
          sendGAEvent(
            ACCOUNTS_DASHBOARD_GA_CATEGORY,
            "Click Create New Client Account",
            managerSiteAlias
          );
          setModalOpen(true);
        }}
      >
        Create New Client Account
      </Button>
      {modalOpen && (
        <CreateNewAccountModal
          managerSiteAlias={managerSiteAlias}
          initialLabels={initialLabels}
          onDone={onDone}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
}

function CreateNewAccountModal({
  managerSiteAlias,
  initialLabels,
  onDone,
  setModalOpen
}: {
  managerSiteAlias: string;
  initialLabels: string[];
  onDone: (clientSiteAlias: string) => void;
  setModalOpen: (open: boolean) => void;
}): JSX.Element {
  const { userEmail } = useSessionUser();
  const {
    clientBillingAccountMax,
    clientBillingAccountCount
  } = useSessionSite();

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [gmvValue, setGmvValue] = useState(0);
  const [extraAdminEmail, setExtraAdminEmail] = useState("");

  const canLinkMoreSites = clientBillingAccountCount < clientBillingAccountMax;
  const [linkToManagerBilling, setLinkToManagerBilling] = useState(
    canLinkMoreSites
  );

  const handleEmailChanged = (
    _e: React.ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    setExtraAdminEmail(value);
  };

  const [invalidEmail, setInvalidEmail] = useState(false);
  const handleValidateEmail = () => {
    setInvalidEmail(extraAdminEmail.length > 0 && !isEmail(extraAdminEmail));
  };

  const handleNewAccountButtonClicked = async () => {
    setSubmitting(true);

    try {
      const signUpReq = new SignUpRequest();
      signUpReq.setAttribution(EXISTING_USER);
      signUpReq.setOrganizationType(
        InternalSiteDetails.OrganizationType.Option.AMAZON_FBA
      );
      signUpReq.setSiteAliasSuffix("web");
      signUpReq.setSiteName(businessName);
      signUpReq.setSalesPlatforms(
        new SiteDetails.SalesPlatforms().setAmazon(true)
      );
      signUpReq.setUtmParameters(convertUTMParamsToProto(getUTMParams()));
      signUpReq.setGmvGreaterThan(gmvValue);

      const linkReq = new LinkClientSiteToManagerSiteRequest();
      linkReq.setManagerSiteAlias(managerSiteAlias);
      if (initialLabels?.length) {
        linkReq.setDetailsAboutClient(
          new SiteManagerLinkClient.Details().setLabelsList(initialLabels)
        );
      }
      linkReq.setLinkToManagerBilling(linkToManagerBilling);

      const signUpAndLinkSiteReq = new SignUpLinkedSiteRequest();
      signUpAndLinkSiteReq.setSignUpRequest(signUpReq);
      signUpAndLinkSiteReq.setLinkRequest(linkReq);
      signUpAndLinkSiteReq.setOwningAdminEmail(userEmail);

      if (!_.isEmpty(extraAdminEmail.trim())) {
        signUpAndLinkSiteReq.setAdditionalAdminEmailsList([extraAdminEmail]);
      }

      const reply = await GRPCWebClient.signUpLinkedSite(
        signUpAndLinkSiteReq,
        {}
      );

      const clientSiteAlias = reply.getSite()?.getSiteAlias();
      if (onDone && !!clientSiteAlias) {
        onDone(clientSiteAlias);
      }

      setModalOpen(false);
    } catch (err) {
      console.error(err); // send to datadog
      sendGAEvent(
        ACCOUNTS_DASHBOARD_GA_CATEGORY,
        "Error: Sign up",
        "",
        extractErrorRequestID(err)
      );
      const errorMessage = extractErrorMessage(err);
      setErrorMessage(
        !_.isEmpty(errorMessage)
          ? errorMessage
          : "There was an error signing up with Ampd. Please contact us for assistance."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
      size="large"
    >
      <Modal.Header>Create New Client Account</Modal.Header>
      <Modal.Content>
        <Form error={!!errorMessage}>
          <Message error>
            <p>{errorMessage}</p>
          </Message>
          <Form.Field
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setBusinessName(e.target.value)
            }
            required
            value={businessName}
          >
            <label>Client Name</label>
            <input placeholder="Enter business name of client" />
          </Form.Field>
          <Form.Select
            required
            label="To help us understand your business, what is your approximate Gross Merchandise Value (GMV) in US dollars?"
            onChange={(_e, data) => setGmvValue(Number(data.value))}
            options={gmvBucketDropdownOptions}
            placeholder="Select one"
          />
          <Form.Field>
            <p>
              <small>
                You will be an Admin user for the new account. If you want an
                additional Admin user, enter their Google email address below.
                They will be able to log into the new account and set up
                payment.
              </small>
            </p>
            <label htmlFor="email">
              Secondary Admin user email for new account (optional)
            </label>
            <Input
              name="email"
              placeholder="Email"
              value={extraAdminEmail}
              onChange={handleEmailChanged}
              onBlur={handleValidateEmail}
            />
            <Message hidden={!invalidEmail} negative>
              Invalid Email
            </Message>
          </Form.Field>
          {!!clientBillingAccountMax && (
            <Form.Field>
              <label htmlFor="email">
                Link this client to your payment plan.
              </label>
              <p>
                {canLinkMoreSites ? (
                  <small>
                    {linkBillingExplanationMessage(
                      clientBillingAccountCount,
                      clientBillingAccountMax
                    )}
                  </small>
                ) : (
                  <small>{maxClientBillingAccountMessage}</small>
                )}
              </p>
              <Checkbox
                toggle
                disabled={!canLinkMoreSites}
                checked={linkToManagerBilling}
                onClick={_e =>
                  setLinkToManagerBilling(last => {
                    if (!canLinkMoreSites) {
                      return last;
                    }
                    return !last;
                  })
                }
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={submitting || !businessName || invalidEmail}
          onClick={handleNewAccountButtonClicked}
        >
          Create New Account
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateNewAccountButton;
