import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Icon,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import ValueWithSuggestionsDropdown from "../ValueWithSuggestionsDropdown";
import OneClickButton from "Common/components/OneClickButton";

import { extractErrorMessage } from "Common/errors/error";

import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import SimpleTooltip from "../SimpleTooltip";
import { sendGAEvent } from "../GA";
import { useAmazonProduct } from "../../queries/useAmazonProduct";
import { googleAdsCurrencies } from "Common/utils/googleAds";
import { InlineLoadingSpinner } from "../MetricColumns";
import {
  convertToMicros,
  convertMicrosToCurrencyUnit,
  getHumanReadableAmount
} from "Common/utils/money";
import { useUpdatePausingAutomation } from "../../queries/useUpdatePausingAutomation";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";

export const UPGRADE_FOR_PAUSING_AUTOMATION_TOOLTIP =
  "Upgrade to Ampd Automation plan to use Ampd Protection";

export const RECOMMENDED_COST_THRESHOLD_MULTIPLE = 2;
const INITIAL_AACOS_THRESHOLD_VALUES = [100, 125, 150, 200];
export const RECOMMENDED_AACOS_THRESHOLD = 150;
const INITIAL_MIN_ACTIVE_VALUES = [0, 1, 2, 3, 5, 8];
export const RECOMMENDED_MIN_ACTIVE = 2;

const PausingAutomationEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  disabled?: boolean;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  disabled
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <SimpleTooltip
        tooltip={
          disabled
            ? UPGRADE_FOR_PAUSING_AUTOMATION_TOOLTIP
            : "Edit Ampd Protection"
        }
      >
        <Icon
          disabled={disabled}
          name={"pencil"}
          style={{ cursor: "pointer", marginTop: -5 }}
          onClick={
            disabled
              ? undefined
              : () => {
                  sendGAEvent(
                    gaCategory,
                    `Click Edit Ampd Protection`,
                    siteAlias
                  );
                  setModalOpen(true);
                }
          }
        />
      </SimpleTooltip>
      {modalOpen && (
        <PausingAutomationModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

// This fallback price is used if the Amazon product data is not available.
const fallbackPrice = 50;

const PausingAutomationModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const {
    customerId,
    campaignId,
    currencyCode,
    asin,
    marketplaceInfo,
    pausingAutomationEnabled,
    pausingAutomationCostThresholdMicros,
    pausingAutomationAACOSThresholdPoints,
    pausingAutomationReevaluatePausedKeywords,
    pausingAutomationMinNumActiveKeywords
  } = itemizedCampaignConfiguration;

  const [modalOpen, setModalOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const [editAutomationEnabled, setEditAutomationEnabled] = useState<boolean>(
    false
  );

  const [itemPrice, setItemPrice] = useState<number | null>(null);

  const [recommendedCostThreshold, setRecommendedCostThreshold] = useState<
    number
  >(fallbackPrice);
  const [editCostThreshold, setEditCostThreshold] = useState<number | null>(
    recommendedCostThreshold
  );

  const [editAACOSThreshold, setEditAACOSThreshold] = useState<number | null>(
    RECOMMENDED_AACOS_THRESHOLD
  );

  const [
    editReevaluatePausedKeywords,
    setEditReevaluatePausedKeywords
  ] = useState<boolean>(false);

  const [editMinActive, setEditMinActive] = useState<number | null>(
    RECOMMENDED_MIN_ACTIVE
  );

  useEffect(() => {
    setEditAutomationEnabled(pausingAutomationEnabled);
    setEditAACOSThreshold(
      pausingAutomationAACOSThresholdPoints <= 0
        ? RECOMMENDED_AACOS_THRESHOLD
        : pausingAutomationAACOSThresholdPoints
    );
    setEditReevaluatePausedKeywords(
      pausingAutomationAACOSThresholdPoints <= 0
        ? true
        : pausingAutomationReevaluatePausedKeywords
    );
    setEditMinActive(
      pausingAutomationMinNumActiveKeywords < 0
        ? RECOMMENDED_MIN_ACTIVE
        : pausingAutomationMinNumActiveKeywords
    );
  }, [
    pausingAutomationEnabled,
    pausingAutomationAACOSThresholdPoints,
    pausingAutomationReevaluatePausedKeywords,
    pausingAutomationMinNumActiveKeywords
  ]);

  // Since the recommendedCostThreshold depends on the amazonProductData that
  // loads lazily, let's update that initial state separately from the others,
  // so we don't trash those if the user has started interacting with them.
  useEffect(() => {
    setEditCostThreshold(
      pausingAutomationCostThresholdMicros <= 0
        ? recommendedCostThreshold
        : convertMicrosToCurrencyUnit(pausingAutomationCostThresholdMicros)
    );
  }, [pausingAutomationCostThresholdMicros, recommendedCostThreshold]);

  const {
    data: amazonProductData,
    isFetching: amazonProductDataIsLoading
  } = useAmazonProduct(siteAlias, asin, marketplaceInfo.marketplace);

  useEffect(() => {
    if (amazonProductData) {
      let price = amazonProductData.product?.buyboxWinner?.price?.value;
      const priceCurrency =
        amazonProductData.product?.buyboxWinner?.price?.currency ||
        marketplaceInfo.currencyCode;
      if (price) {
        if (priceCurrency !== currencyCode) {
          const fromCurrencyConversion = googleAdsCurrencies.get(
            priceCurrency || "USD"
          )?.conversion;
          const toCurrencyConversion = googleAdsCurrencies.get(currencyCode)
            ?.conversion;

          if (fromCurrencyConversion && toCurrencyConversion) {
            price = (price * toCurrencyConversion) / fromCurrencyConversion;
          }
        }

        price = Number(Number(price).toPrecision(2));

        setItemPrice(price);
        setRecommendedCostThreshold(
          price * RECOMMENDED_COST_THRESHOLD_MULTIPLE
        );
      }
    }
  }, [amazonProductData, currencyCode, marketplaceInfo.currencyCode]);

  const { mutateAsync: updatePausingAutomation } = useUpdatePausingAutomation();

  const handleToggleAutomationEnabled = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    if (data.checked) {
      setEditAutomationEnabled(true);
      if ((editCostThreshold ?? 0) <= 0) {
        setEditCostThreshold(recommendedCostThreshold);
      }
      if ((editAACOSThreshold ?? 0) <= 0) {
        setEditCostThreshold(RECOMMENDED_AACOS_THRESHOLD);
      }
      if ((editMinActive ?? 0) <= 0) {
        setEditMinActive(2);
      }
    } else {
      setEditAutomationEnabled(false);
    }
  };

  const handleToggleReevaluatePausedKeywords = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    if (data.checked) {
      setEditReevaluatePausedKeywords(true);
    } else {
      setEditReevaluatePausedKeywords(false);
    }
  };

  const disableSubmit = () => {
    if (
      // Disable if automation is enabled but the item price hasn't been loaded yet,
      // because the price, if available determines the recommended cost threshold.
      (amazonProductDataIsLoading && editAutomationEnabled) ||
      // Disable if initial edit values aren't set yet.
      editCostThreshold == null ||
      editAACOSThreshold == null ||
      editMinActive == null
    ) {
      return true;
    }

    // Disable if nothing has changed.  However, if the enabled state hasn't changed
    // and is still off, ignore the changes caused by pre-populating the UI with
    // the recommended values (because the is no point in saving the recommended
    // values if the automation is still disabled: better to leave the settings
    // unset).
    return (
      pausingAutomationEnabled === editAutomationEnabled &&
      (!editAutomationEnabled ||
        (pausingAutomationCostThresholdMicros ===
          convertToMicros(editCostThreshold) &&
          pausingAutomationAACOSThresholdPoints === editAACOSThreshold &&
          pausingAutomationReevaluatePausedKeywords ===
            editReevaluatePausedKeywords &&
          pausingAutomationMinNumActiveKeywords === editMinActive))
    );
  };

  const handleSubmit = async () => {
    if (
      !customerId ||
      !campaignId ||
      editCostThreshold == null ||
      editAACOSThreshold == null ||
      editMinActive == null
    ) {
      return;
    }

    setIsSubmitting(true);
    try {
      await updatePausingAutomation({
        siteAlias,
        gaCategory,
        googleAdsCustomerId: String(customerId),
        campaignId: String(campaignId),
        currencyCode,
        pausingAutomationEnabled: editAutomationEnabled,
        pausingAutomationCostThresholdMicros: convertToMicros(
          editCostThreshold
        ),
        pausingAutomationAACOSThresholdPoints: editAACOSThreshold,
        pausingAutomationReevaluatePausedKeywords: editReevaluatePausedKeywords,
        pausingAutomationMinNumActiveKeywords: editMinActive
      });
      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);
      console.error(message);
      setErrorMessage(message);

      // Since submitAttempts is used as the key of the OneClickButton, changing it
      // will create a new button instance and re-enable it.
      setSubmitAttempts(submitAttempts + 1);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
        onClose();
      }}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
    >
      <Modal.Header>Ampd Protection</Modal.Header>
      <Modal.Content scrolling>
        <PausingAutomationEditor
          AACOSThreshold={editAACOSThreshold}
          automationEnabled={editAutomationEnabled}
          costThreshold={editCostThreshold}
          currencyCode={currencyCode}
          errorMessage={errorMessage}
          itemPrice={itemPrice}
          itemPriceLoading={amazonProductDataIsLoading}
          minActive={editMinActive}
          onChangeMinActive={setEditMinActive}
          onChangeReevaluateKeywords={handleToggleReevaluatePausedKeywords}
          onChangeAACOSThreshold={setEditAACOSThreshold}
          onChangeAutomationEnabled={handleToggleAutomationEnabled}
          onChangeCostThreshold={setEditCostThreshold}
          recommendedCostThreshold={recommendedCostThreshold}
          reevaluateKeywords={editReevaluatePausedKeywords}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setModalOpen(false);
            onClose();
          }}
        >
          Cancel
        </Button>
        <OneClickButton
          key={submitAttempts}
          primary={true}
          disabled={disableSubmit()}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Update Automation Settings
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

export const PausingAutomationEditor = ({
  AACOSThreshold,
  automationEnabled,
  costThreshold,
  currencyCode,
  errorMessage,
  itemPrice,
  itemPriceLoading,
  minActive,
  onChangeMinActive,
  onChangeReevaluateKeywords,
  onChangeAACOSThreshold,
  onChangeAutomationEnabled,
  onChangeCostThreshold,
  recommendedCostThreshold,
  reevaluateKeywords
}: {
  AACOSThreshold: number | null;
  automationEnabled: boolean;
  costThreshold: number | null;
  currencyCode: string;
  errorMessage: string;
  itemPrice: number | null;
  itemPriceLoading: boolean;
  minActive: number | null;
  onChangeMinActive: (val: number | null) => void;
  onChangeReevaluateKeywords: (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => void;
  onChangeAACOSThreshold: (val: number | null) => void;
  onChangeAutomationEnabled: (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => void;
  onChangeCostThreshold: (val: number | null) => void;
  recommendedCostThreshold: number;
  reevaluateKeywords: boolean;
}): JSX.Element => {
  const price = itemPrice || fallbackPrice;
  return (
    <>
      <Checkbox
        toggle
        checked={automationEnabled}
        onChange={onChangeAutomationEnabled}
        label="Use Ampd Protection to pause underperforming keywords"
      />

      <Segment
        style={{ display: "flex", flexDirection: "column", gap: 20 }}
        disabled={!automationEnabled}
      >
        <ValueWithSuggestionsDropdown
          title={"Cost threshold"}
          disabled={!automationEnabled || itemPriceLoading}
          currency={currencyCode}
          value={costThreshold}
          setValue={onChangeCostThreshold}
          initialValueList={[price * 1, price * 1.5, price * 2, price * 3]}
          recommendedValue={recommendedCostThreshold}
        >
          <span style={{ marginLeft: 10 }}>
            Item price:{" "}
            {itemPriceLoading ? (
              <InlineLoadingSpinner></InlineLoadingSpinner>
            ) : itemPrice == null ? (
              "unknown"
            ) : (
              getHumanReadableAmount(convertToMicros(itemPrice))
            )}
          </span>
        </ValueWithSuggestionsDropdown>
        <div>
          <Message>
            <p>
              A keyword will be considered for automatic pausing when the ad
              spend for the keyword exceeds this amount over a 28-day period,
              and there have not been sufficient sales attributed to it (if its
              AACOS is too high).
            </p>
          </Message>
        </div>
        <ValueWithSuggestionsDropdown
          title={"AACOS threshold"}
          disabled={!automationEnabled}
          isPercentage={true}
          value={AACOSThreshold}
          setValue={onChangeAACOSThreshold}
          initialValueList={INITIAL_AACOS_THRESHOLD_VALUES}
          recommendedValue={RECOMMENDED_AACOS_THRESHOLD}
        >
          <Checkbox
            toggle
            checked={reevaluateKeywords}
            onChange={onChangeReevaluateKeywords}
            label="Re-evaluate after pausing"
          />
        </ValueWithSuggestionsDropdown>
        <div>
          <Message>
            <p>
              Keywords with worse/higher AACOS (Adjusted ACOS) than this over a
              28-day period may be automatically paused. If re-evaluate is
              checked, then paused keywords may be unpaused if their AACOS
              improves because of delayed attribution.
            </p>
          </Message>
        </div>
        <ValueWithSuggestionsDropdown
          title={"Minimum number of active keywords"}
          disabled={!automationEnabled}
          upward={true}
          allowZero={true}
          value={minActive}
          setValue={onChangeMinActive}
          initialValueList={INITIAL_MIN_ACTIVE_VALUES}
          recommendedValue={RECOMMENDED_MIN_ACTIVE}
        />
        <div>
          <Message>
            <p>
              If there is only this many keywords active, then no further
              keywords will be automatically paused.
            </p>
          </Message>
        </div>
      </Segment>
      {!!errorMessage && <Message error>{errorMessage}</Message>}
    </>
  );
};

export default PausingAutomationEditButton;
