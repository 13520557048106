import { GetCampaignConfigurationsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import {
  GoogleAdsConfiguration,
  GoogleAdsResourceStatus
} from "Common/proto/ampdPb/googleAdsConfiguration_pb";

export const BiddingStrategy =
  GoogleAdsConfiguration.CampaignBiddingStrategy.Option;
export type BiddingStrategy = GoogleAdsConfiguration.CampaignBiddingStrategy.Option;
export const KeywordMatchType = GoogleAdsConfiguration.KeywordMatchType.Option;
export type KeywordMatchType = GoogleAdsConfiguration.KeywordMatchType.Option;

export type KeywordBiddingConfiguration = {
  criteriaId: number;
  text: string;
  cpcBidMicros: number;
};

export type CampaignBiddingConfiguration = {
  biddingStrategy: BiddingStrategy;
  campaignMaxCPCBidMicros: number;
  adGroupDefaultCPCBidMicros: number;
  keywords: Array<KeywordBiddingConfiguration>;
};

export function getBiddingConfiguration(
  ampdCampaignConfiguration:
    | GetCampaignConfigurationsReply.CampaignConfiguration.AsObject
    | undefined
    | null
): CampaignBiddingConfiguration {
  const biddingConfiguration: CampaignBiddingConfiguration = {
    biddingStrategy:
      GoogleAdsConfiguration.CampaignBiddingStrategy.Option.UNKNOWN,
    campaignMaxCPCBidMicros: 0,
    adGroupDefaultCPCBidMicros: 0,
    keywords: []
  };

  const googleAdsConfiguration =
    ampdCampaignConfiguration?.ampdResourceConfiguration?.googleAds;
  if (googleAdsConfiguration) {
    const campaignConfiguration = googleAdsConfiguration.campaignConfiguration;
    if (campaignConfiguration) {
      biddingConfiguration.biddingStrategy =
        campaignConfiguration.biddingStrategy;
      biddingConfiguration.campaignMaxCPCBidMicros =
        campaignConfiguration.maxCpcMicros;
    }

    // NOTE: Only considering the first ad in the first ad group to be part of
    // the Ampd configuration.
    const adGroupConfiguration =
      googleAdsConfiguration?.adGroupConfigurationsList[0];
    if (adGroupConfiguration) {
      biddingConfiguration.adGroupDefaultCPCBidMicros =
        adGroupConfiguration.cpcBidMicros;

      for (const keywordConfiguration of adGroupConfiguration.keywordConfigurationsList) {
        if (
          keywordConfiguration.status === GoogleAdsResourceStatus.Option.ENABLED
        ) {
          let text = keywordConfiguration.text;
          if (keywordConfiguration.matchType == KeywordMatchType.PHRASE) {
            text = `"${text}"`;
          } else if (keywordConfiguration.matchType == KeywordMatchType.EXACT) {
            text = `[${text}]`;
          }

          biddingConfiguration.keywords.push({
            criteriaId: keywordConfiguration.criteriaId,
            text: text,
            cpcBidMicros: keywordConfiguration.cpcBidMicros
          });
        }
      }

      biddingConfiguration.keywords.sort(
        (a, b) => b.cpcBidMicros - a.cpcBidMicros
      );
    }
  }

  return biddingConfiguration;
}
