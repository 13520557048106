import _ from "lodash";

import React, { useState } from "react";
import {
  Dropdown,
  Form,
  Grid,
  Input,
  List,
  Message,
  Segment,
  Button
} from "semantic-ui-react";

import {
  extractErrorMessage,
  extractErrorRequestID
} from "Common/errors/error";
import GoogleAdsButton from "../components/GoogleAdsLoginButton";
import {
  googleAdsCurrencies,
  googleAdsTimezones
} from "Common/utils/googleAds";

// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { CreateGoogleAdsAccountRequest } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { sendGAEvent, useGAEvent } from "../components/GA";
import { useSession } from "ExtensionV2/queries/useSession";

const PAGE_GA_CATEGORY = "Ampd: Create Google Ads Account";
// Form for creating the Google Ads account. The fields are pre-filled with
// information from the site or browser, when available.
function CreateGoogleAdsAccountForm(props) {
  const {
    disabled,
    name,
    setName,
    timezone,
    setTimezone,
    currency,
    setCurrency,
    site
  } = props;
  const siteAlias = _.get(site, "siteAlias", "");

  const timezones = getTimezoneDropdownOptions();
  const currencies = getCurrencyDropdownOptions();

  return (
    <Form loading={disabled}>
      <Form.Field required>
        <label>Account Name</label>
        <Input
          placeholder="Account name"
          onClick={() =>
            sendGAEvent(PAGE_GA_CATEGORY, "Click Account Name", siteAlias)
          }
          onChange={(event, { value }) => {
            setName(value);
          }}
          value={name}
          disabled={disabled}
        />
      </Form.Field>
      <Form.Field required>
        <label>Timezone</label>
        <Dropdown
          options={timezones}
          onChange={(event, { value }) => {
            setTimezone(value);
            sendGAEvent(PAGE_GA_CATEGORY, "Change Timezone", siteAlias, value);
          }}
          value={timezone}
          placeholder="Select a timezone"
          search
          fluid
          selection
          disabled={disabled}
        />
      </Form.Field>
      <Form.Field required>
        <label>Currency</label>
        <Dropdown
          options={currencies}
          onChange={(event, { value }) => {
            setCurrency(value);
            sendGAEvent(PAGE_GA_CATEGORY, "Change Currency", siteAlias, value);
          }}
          value={currency}
          placeholder="Select a currency"
          search
          fluid
          selection
          disabled={disabled}
        />
      </Form.Field>
    </Form>
  );
}

// Content for the main frame of the Create Google Ads Account page.
function CreateGoogleAdsAccountContent(props) {
  const {
    creatingAccount,
    errors,
    setErrors,
    email,
    setGoogleLogin,
    site
  } = props;

  const siteAlias = _.get(site, "siteAlias", "");

  const onGoogleLogin = response => {
    sendGAEvent(PAGE_GA_CATEGORY, "Sign Into Google Success", siteAlias);
    setGoogleLogin(response);
    setErrors(null);
  };

  return (
    <div style={{ minHeight: 400 }}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <GoogleAdsButton
              siteAlias={siteAlias}
              gaCategory={PAGE_GA_CATEGORY}
              onGoogleLogin={onGoogleLogin}
              email={email}
              disabled={creatingAccount}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {(email && (
            <Grid.Column>
              <CreateGoogleAdsAccountForm
                {...props}
                disabled={creatingAccount}
              />
            </Grid.Column>
          )) || (
            <Grid.Column textAlign="center">
              Sign in with the Google account that will own your new Google Ads
              account.
            </Grid.Column>
          )}
        </Grid.Row>
        {_.size(errors) > 0 && (
          <Grid.Row>
            <Grid.Column>
              <Message negative>
                <Message.Header>Errors</Message.Header>
                <List bulleted>
                  {errors.map((error, i) => (
                    <List.Item key={i}>{error}</List.Item>
                  ))}
                </List>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
}

// Page for a user to create a new Google Ads account. The user must sign into a
// Google account before they can create a Google Ads account.
function CreateGoogleAdsAccount({ canCreateGoogleAdsAccount, setGoogleLogin }) {
  const { currentSite, user, invalidateSessionQuery } = useSession();
  const { siteAlias, siteName: defaultName } = currentSite;
  const { userEmail: email } = user;

  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultCurrency = "USD";

  useGAEvent(PAGE_GA_CATEGORY, "Loaded", siteAlias);

  const [name, setName] = useState(defaultName);
  const [timezone, setTimezone] = useState(defaultTimezone);
  const [currency, setCurrency] = useState(defaultCurrency);

  const [creatingAccount, setCreatingAccount] = useState(false);
  const [errors, setErrors] = useState(null);

  const createAccount = async e => {
    e.stopPropagation();
    setCreatingAccount(true);

    try {
      const createAccountReq = proto.set(new CreateGoogleAdsAccountRequest(), {
        siteAlias,
        userEmail: email,
        accountName: name,
        timezone,
        currencyCode: currency
      });

      await GRPCWebClient.createGoogleAdsAccount(createAccountReq);

      sendGAEvent(
        PAGE_GA_CATEGORY,
        "Create Google Ads Account Success",
        siteAlias
      );
      invalidateSessionQuery();
    } catch (err) {
      console.error(err); // send to datadog
      sendGAEvent(
        PAGE_GA_CATEGORY,
        "Internal Error: Create Google Ads Account",
        siteAlias,
        extractErrorRequestID(err)
      );

      const errMessage = extractErrorMessage(err);
      setErrors([
        <>
          <p>
            There was an internal error linking your Google Ads account. Please
            contact us for assistance.
          </p>
          {errMessage && <p>Error: {errMessage}</p>}
        </>
      ]);
      setCreatingAccount(false);
    }
  };

  if (!canCreateGoogleAdsAccount) {
    return null;
  }

  const createAccountContent = (
    <CreateGoogleAdsAccountContent
      errors={errors}
      setErrors={setErrors}
      creatingAccount={creatingAccount}
      email={email}
      setGoogleLogin={setGoogleLogin}
      name={name}
      setName={setName}
      timezone={timezone}
      setTimezone={setTimezone}
      currency={currency}
      setCurrency={setCurrency}
    />
  );

  return (
    <>
      {createAccountContent}
      <Segment>
        <Button onClick={createAccount} primary>
          Create Account
        </Button>
      </Segment>
    </>
  );
}

export function getTimezoneDropdownOptions() {
  return googleAdsTimezones
    .map(timezone => {
      return {
        key: timezone,
        value: timezone,
        text: timezone
      };
    })
    .toArray();
}

export function getCurrencyDropdownOptions() {
  return googleAdsCurrencies
    .valueSeq()
    .toArray()
    .map(entry => {
      return {
        key: entry.code,
        value: entry.code,
        text: `${entry.name} (${entry.symbol || entry.code})`
      };
    });
}

export default CreateGoogleAdsAccount;
