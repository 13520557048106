import React, { useState } from "react";

import { logout } from "Common/utils/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { FullPageLoadingScreen } from "./components/layout/AmpdWrapper";
import { useSession } from "./queries/useSession";

import ExtensionV2 from "ExtensionV2/ExtensionV2";

import {
  getWantAmpdProOperatorFeatures as getStoredWantAmpdProOperatorFeatures,
  setWantAmpdProOperatorFeatures as setStoredWantsAmpdProOperatorFeatures
} from "Common/utils/ampdProOperator";
import { browserIsAuthenticated } from "Common/utils/session";
import { useAmazonLagPeriodSetting } from "Common/utils/savedTablePreferences";
import {
  GlobalDates,
  GlobalDateSetters,
  useGlobalDates
} from "./components/GlobalDatePicker";

type GetAndSetReactStateValue<T> = [T, React.Dispatch<React.SetStateAction<T>>];

// a react context the provides a value with a type of GetAndSetBooleanStateValue
// which is an array of a boolean and a function that takes a boolean and returns void
export const WantsAmpdProOperatorFeaturesContext = React.createContext<
  GetAndSetReactStateValue<boolean>
>([
  false,
  () => {
    return;
  }
]);

export const WantsDisabledPaywallContext = React.createContext<
  GetAndSetReactStateValue<boolean>
>([
  false,
  () => {
    return;
  }
]);

export const WantsExcludeAmazonLagPeriodContext = React.createContext<
  GetAndSetReactStateValue<boolean>
>([
  false,
  () => {
    return;
  }
]);

export const GlobalDateContext = React.createContext<
  GlobalDates & GlobalDateSetters
>({
  startDate: "",
  endDate: "",
  namedRange: "",
  compareTo: "",
  setGlobalStartDate: () => {
    return;
  },
  setGlobalEndDate: () => {
    return;
  },
  setGlobalNamedRange: () => {
    return;
  },
  setGlobalCompareTo: () => {
    return;
  }
});

const queryClient = new QueryClient();

function Index(): JSX.Element {
  const storedWantsAmpdProOperatorFeatures = getStoredWantAmpdProOperatorFeatures();
  const [
    wantsAmpdProOperatorFeatures,
    setWantsAmpdProOperatorFeatures
  ] = useState(storedWantsAmpdProOperatorFeatures);

  const [wantsDisabledPaywall, setWantsDisabledPaywall] = useState(false);

  const [
    excludeAmazonLagPeriod,
    setExcludeAmazonLagPeriod
  ] = useAmazonLagPeriodSetting();

  const globalDates = useGlobalDates(excludeAmazonLagPeriod);

  return (
    <WantsAmpdProOperatorFeaturesContext.Provider
      value={[
        wantsAmpdProOperatorFeatures,
        want => {
          setStoredWantsAmpdProOperatorFeatures(want);
          setWantsAmpdProOperatorFeatures(want);
        }
      ]}
    >
      <WantsDisabledPaywallContext.Provider
        value={[wantsDisabledPaywall, setWantsDisabledPaywall]}
      >
        <WantsExcludeAmazonLagPeriodContext.Provider
          value={[excludeAmazonLagPeriod, setExcludeAmazonLagPeriod]}
        >
          <GlobalDateContext.Provider value={globalDates}>
            <QueryClientProvider client={queryClient}>
              <LoadSession />
              <ReactQueryDevtools initialIsOpen={false} position="top-right" />
            </QueryClientProvider>
          </GlobalDateContext.Provider>
        </WantsExcludeAmazonLagPeriodContext.Provider>
      </WantsDisabledPaywallContext.Provider>
    </WantsAmpdProOperatorFeaturesContext.Provider>
  );
}

// Loads the session state if we have an authenticated browser cookie but have
// not yet loaded the session state.
export function LoadSession(): JSX.Element {
  const {
    user,
    currentSite,
    clientSitesList,
    isLoading: sessionLoading,
    error: sessionError
  } = useSession();
  const isAuthenticated = browserIsAuthenticated();

  if (!isAuthenticated) {
    // Keep the requested route for after the login.
    sessionStorage.setItem("login_ref", window.location.href);
    logout("");
    return <></>;
  }

  if (sessionLoading) {
    return <FullPageLoadingScreen />;
  }

  if (sessionError) {
    console.error(sessionError?.message || sessionError);

    // If we detect attempted access to an unauthorized site, clear the site_alias so we
    // don't keep redirecting the user to a site they can't access. Otherwise, assume the user
    // has access and save the login_ref so we redirect them to the correct place after
    // logging in. Operators are added to the target site after they attempt to log in, so we don't
    // want to clear the site_alias for them.
    if (
      user &&
      !user.isAmpdOperator &&
      /the request was unauthorized/.test(sessionError.message)
    ) {
      localStorage.removeItem("site_alias");
    } else {
      sessionStorage.setItem("login_ref", window.location.href);
    }

    logout("");
    return <></>;
  }

  if (!user || !currentSite || !clientSitesList) {
    console.error("Invalid session", {
      user: user?.userEmail,
      currentSite: currentSite?.siteAlias,
      clientSitesListPresent: !!clientSitesList
    });

    logout("");
    return <></>;
  }
  return (
    <ExtensionV2
      isAuthenticated={isAuthenticated}
      user={user}
      currentSite={currentSite}
      clientSitesList={clientSitesList}
    />
  );
}

export { Index };
