import React, { useState } from "react";
import { Message } from "semantic-ui-react";
import {
  getHumanReadableAmount,
  MICROS_TO_CURRENCY_UNIT_FACTOR
} from "Common/utils/money";
import { CurrencyInputField } from "./SelectProductsStage";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";
import { Site } from "ExtensionV2/queries/useSession";
import { GOOGLE_ADS_DAYS_PER_MONTH } from "Common/utils/googleAds";

const validateBudget = (budgetMicros: number): Set<string> => {
  const errors = new Set<string>();
  if (budgetMicros < 1) {
    errors.add("Budget must be greater than 0");
  }
  return errors;
};

export const defaultBudgetUsdMicros = 30_000_000;

export const budgetMicrosField = (initialValue: number): FormField<number> => ({
  _value: initialValue,
  errors: validateBudget(initialValue),
  validator: validateBudget
});

export const BudgetReviewSection = ({
  site,
  state,
  dispatch,
  onEdit
}: {
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;
  const { currencyCode } = site?.adwordsAccounts[0] || {};

  const [campaign, { selectBudgetMicros }] = getCurrentCampaignAndSelectors(
    state
  );
  const { errors, validator: validateBudget } = campaign.budgetMicrosField;
  const budgetMicros = selectBudgetMicros(campaign);

  const [tempBudgetMicros, setTempBudgetMicros] = useState<number>(
    budgetMicros
  );
  const [localErrors, setLocalErrors] = useState<Set<string>>(errors);

  const dataComponent = (
    <p>
      {getHumanReadableAmount(budgetMicros, currencyCode)} daily average (
      {getHumanReadableAmount(budgetMicros * GOOGLE_ADS_DAYS_PER_MONTH, "USD")}{" "}
      monthly max)
    </p>
  );
  const editComponent = (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "10em" }}>
        <CurrencyInputField
          adwordsCurrency={currencyCode}
          budgetMicros={tempBudgetMicros}
          campaignIndex={currentReviewCampaignIndex}
          handleUpdateBudget={(_campaignIndex: number, budgetInput: number) => {
            setTempBudgetMicros(budgetInput * MICROS_TO_CURRENCY_UNIT_FACTOR);
          }}
          onBlur={() => {
            setLocalErrors(validateBudget(tempBudgetMicros));
          }}
          label="Daily Budget"
          invalid={localErrors.size > 0}
        />
      </div>
      {localErrors.size > 0 && (
        <Message error>
          {Array.from(localErrors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  const handleSave = () => {
    dispatch({
      name: "UpdateBudget",
      data: {
        index: currentReviewCampaignIndex,
        budgetMicros: tempBudgetMicros
      }
    });
  };

  const handleCancel = () => {
    setTempBudgetMicros(budgetMicros);
  };

  return (
    <CampaignReviewSection
      dataName="Budget"
      dataComponent={dataComponent}
      editComponent={editComponent}
      disableSave={localErrors.size > 0}
      errors={errors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
