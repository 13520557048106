import {
  roundedPercentageMetricDef,
  roundedNumberMetricDef
} from "../MetricColumns";
import { getCurrencyMetricDef } from "Common/utils/money";
import {
  PerformanceMetrics,
  GetSitePerformanceMetricsReply
} from "Common/proto/edge/grpcwebPb/grpcweb_PerformanceMetrics_pb";

export const AMAZON_ADS_CHANNEL = "AMAZON_ADS";
export const GOOGLE_ADS_CHANNEL = "GOOGLE_ADS";
export const ORGANIC_CHANNEL = "ORGANIC";
export const SELLER_CHANNEL = "SELLER";
export const BRAND_CHANNEL = "BRAND";

// Custom Charts metrics defs functions built from other more-flexible Metrics Defs
const currencyWithCentsMetricDef = (currency?: string): MetricDef =>
  getCurrencyMetricDef(currency, true, false);

const roundedPercentMetricDef = (): MetricDef => roundedPercentageMetricDef;
const currencyMetricDef = (currency?: string): MetricDef =>
  getCurrencyMetricDef(currency, false, false);

// Call structure when formatting metrics defs
// TODO: Move to metrics.js when it's converted to metrics.ts
export interface MetricDefFormatting {
  postfix: string;
  sign: boolean;
  isPercent: boolean;
  currencyUseMinorUnits?: boolean;
  useAsciiCurrency?: boolean;
  precision?: number;
  abbreviated?: boolean;
}

// Metric value definition for use with formatting
// TODO: Move to metrics.js when it's converted to metrics.ts
export interface MetricDef {
  formatting: MetricDefFormatting;
  // Is this metric a currency?
  isCurrency: boolean;
  // If this metric is a currency, format using this currency code.
  currencyCode?: string;
  // What we show when there's nothing to show.
  nanString: string;
}

const SourceFields = [
  "amazonAds",
  "googleAds",
  "organics",
  "seller",
  "brand"
] as const;
type SourceField = typeof SourceFields[number];

export function isValidSourceField(
  sourceField: string | undefined | null
): sourceField is SourceField {
  if (sourceField === null || sourceField === undefined) return false;

  return (SourceFields as ReadonlyArray<string>).includes(sourceField);
}

// All available performance metrics fields. These all have "List" awkwardly
// appended to them by grpc-js, because each metric field is a list type -
// whether a list of 1+ daily timeseries data points or a singleton list
// of the aggregated date-range value.
// TODO: Figure out how to keep these type MetricField/s dynamic, more like:
// export type MetricField = Exclude<
//   | keyof PerformanceMetrics.GoogleAds.AsObject
//   | keyof PerformanceMetrics.AmazonAds.AsObject
//   | keyof PerformanceMetrics.Seller.AsObject
//   | keyof GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.AsObject
//   | keyof PerformanceMetrics.Organics.AsObject,
//   "currencyCode" | "unconvertedRevenueCurrencyCode" | "unconvertedCurrencyCode"
// >;
const MetricFields = [
  "absoluteTopImpressionPercentageList",
  "absoluteTopImpressionShareList",
  "addToCartsList",
  "adjustedCostList",
  "adjustedCostPerClickList",
  "amazonAcosList",
  "averageUnitPriceList",
  "brandReferralBonusList",
  "browserPageViewsList",
  "browserSessionsList",
  "cartRateList",
  "categoryRanksList",
  "clicksList",
  "clickShareList",
  "clickThroughRateList",
  "conversionRateList",
  "conversionsList",
  "costList",
  "costOfAcquisitionList",
  "costPerClickList",
  "googleAcosList",
  "googleAacosList",
  "googleRoasList",
  "impressionsList",
  "impressionShareList",
  "lostAbsoluteTopImpressionShareLowBudgetList",
  "lostAbsoluteTopImpressionShareLowRankList",
  "lostImpressionShareLowBudgetList",
  "lostImpressionShareLowRankList",
  "lostTopImpressionShareLowRankList",
  "lostTopImpressionShareLowBudgetList",
  "mobileAppPageViewsList",
  "mobileAppSessionsList",
  "newToBrandConversionsList",
  "orderCountList",
  "pageViewsList",
  "projectedAnnualizedRevenueList",
  "projectedAnnualizedRoasList",
  "projectedRevenueList",
  "projectedRoasList",
  "revenueList",
  "searchTermRanksList",
  "sessionsList",
  "tacosList",
  "topImpressionPercentageList",
  "topImpressionShareList",
  "unitsSoldList"
] as const;
type MetricField = typeof MetricFields[number];

export type MetricFieldSource = Partial<
  | PerformanceMetrics.GoogleAds.AsObject
  | PerformanceMetrics.AmazonAds.AsObject
  | PerformanceMetrics.Seller.AsObject
  | GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.AsObject
  | PerformanceMetrics.Organics.AsObject
>;

export const isValidSourceMetricField = (
  metricField: string | undefined | null,
  source: MetricFieldSource | undefined | null
): metricField is MetricField => {
  if (metricField === null || metricField === undefined) return false;
  if (source === null || source === undefined) return false;

  if (!(metricField in source)) {
    return false;
  }

  return (MetricFields as ReadonlyArray<string>).includes(metricField);
};

export function isValidNumbersList(
  numbersList: string | number[] | undefined | null
): numbersList is number[] {
  if (numbersList === null || numbersList === undefined) return false;

  return Array.isArray(numbersList);
}

// Defines each supported metrics data source channels. Metrics are grouped
// under their source channel in the metrics data response. Channels include
// primary data sources like Google & Amazon (Ads & Seller) as well as
// calculated pseudo-sources like Organics & Brand.
export interface ChannelType {
  // The key for this ChannelType in the allChannelTypes object.
  key: string;
  // UI-friendly display name
  name: string;
  // Field name used to access this source channel in the data response.
  sourceField: SourceField;
  // Color associated with this channel source (eg: Ampd = AmpdRed)
  color?: string;
}

// Metrics are calculated by one or more parts combined as numerators or
// denominators.
export interface ChannelMetricPart {
  // Name of the field to find this metric part's data
  metricField: MetricField;
  // Name of the source to find this metric part. When unspecified, assumes the
  // current channel.
  source?: SourceField;
}

// Defines how a metric is to be calculated from its parts. Some metrics only
// have one part - either because they represent only that part or because they
// are too complicated (eg: rely on external information) to recalculate in the
// UI. Other metrics may have multiple parts - either in the numerator or
// denominator of the calculation. Metrics parts may be sourced from a single
// channel or multiple channels, as required (eg: TACOS is recalculated from
// Google Ads, Amazon Ads, & Amazon Seller data source channels).
export interface ChannelMetricParts {
  // The numerator metrics sub-parts used to recalculate this primary metric.
  numerators: Array<ChannelMetricPart>;
  // The denominator metrics sub-parts used to recalculate this primary metric.
  denominators?: Array<ChannelMetricPart>;
}

// Not ever channel supports every types of metric, so for a given metric, this
// contains channel-specific details about each channel that supports the
// metric, such as what the metric data access field is called, how to
// recalculate it (used for math operations like smoothing), etc.
export interface MetricTypeChannel {
  // A channel type that supports the metric
  channelType: ChannelType;
  // The field name for this metric within the channel
  metricField: MetricField;
  // Defines the parts to re-calculate the metric for the given channel. Each
  // part must be an available metric. Parts may be from this current channel
  // or from another supported channel.
  metricParts: ChannelMetricParts;
  // UI description for this channel-specific metric info
  description?: string;
}

// A supported metric type - defines info for UI controls & display,
// channels that support this metric, data access, data manipulation,
// etc.
export interface MetricType {
  // The key for this MetricType in the allMetricsTypes object
  key: MetricTypeKey;
  // UI-friendly display name
  name: string;
  // UI display units (used for chart labels)
  units: string;
  // Defines how this metric's data should be formatted
  metricDef: (currency?: string) => MetricDef;
  // Channels that support this metric & configs on how to interact with the
  // metric through the channel.
  channels: Array<MetricTypeChannel>;
  // Can the metrics data be summed directly (eg: counts) or must such math
  // operations rely on a full recalculation of this metric from its parts.
  summable: boolean;
  // While most metrics indicate increasing success as they go up (eg: revenue),
  // some metrics indicate increasing success as they go DOWN (eg: spend, tacos,
  // CPC, etc). This flag should be set to true if lower is generally better.
  isInvertedSuccess?: boolean;
}

// All channel types supported for metrics data. In our metrics data responses,
// channels contain various metrics & their data (aggregate/timeseries).
export const allChannelTypes: { [type: string]: ChannelType } = {
  [AMAZON_ADS_CHANNEL]: {
    key: AMAZON_ADS_CHANNEL,
    name: "Amazon Sponsored Ads",
    sourceField: "amazonAds"
  },
  [GOOGLE_ADS_CHANNEL]: {
    key: GOOGLE_ADS_CHANNEL,
    name: "Google Ads",
    sourceField: "googleAds"
  },
  [ORGANIC_CHANNEL]: {
    key: ORGANIC_CHANNEL,
    name: "Organic",
    sourceField: "organics"
  },
  [SELLER_CHANNEL]: {
    key: SELLER_CHANNEL,
    name: "Total",
    sourceField: "seller"
  },
  [BRAND_CHANNEL]: {
    key: BRAND_CHANNEL,
    name: "Brand",
    sourceField: "brand"
  }
} as const;

// All of the supported configured metrics type keys
export type MetricTypeKey =
  | "ADJUSTED_COST"
  | "BRB"
  | "CARTS"
  | "CLICKS"
  | "COST_OF_ACQUISITION"
  | "CONVERSIONS"
  | "COST"
  | "CPC"
  | "CTR"
  | "CVR"
  | "IMPRESSIONS"
  | "NEW_TO_BRAND_CONVERSIONS"
  | "PROJECTED_ANNUALIZED_REVENUE"
  | "PROJECTED_ANNUALIZED_ROAS"
  | "PROJECTED_REVENUE"
  | "PROJECTED_ROAS"
  | "REVENUE"
  | "ROAS"
  | "TACOS"
  | "UNITS_SOLD";

// All metric types supported for data. Data for each metric is found within
// its source channel. Not every channel supports every metric, so this
// config contains channel-specific information on the channels that support
// the metric. This also contains information for UI controls & display,
// data manipulation, etc.
export const allMetricsTypes: Record<MetricTypeKey, MetricType> = {
  ADJUSTED_COST: {
    key: "ADJUSTED_COST",
    name: "Adjusted Google Ads Cost",
    units: "Adjusted Cost Amount",
    metricDef: currencyMetricDef, // This metricDef uses the passed currency
    summable: true,
    isInvertedSuccess: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "adjustedCostList",
        metricParts: {
          numerators: [{ metricField: "adjustedCostList" }]
        },
        description: `This product's Ampd Google Ads cost reduced by any Amazon Brand
         Referral Bonus external advertising credit.`
      }
    ]
  },
  BRB: {
    key: "BRB",
    name: "Brand Referral Bonus",
    units: "External Ads Credit Amount",
    metricDef: currencyMetricDef, // This metricDef uses the passed currency
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "brandReferralBonusList",
        metricParts: {
          numerators: [{ metricField: "brandReferralBonusList" }]
        },
        description: `This product's Amazon Brand Referral Bonus credit from Amazon
         to offset the costs of external advertising.`
      }
    ]
  },
  CARTS: {
    key: "CARTS",
    name: "Carts",
    units: "Number of Add to Carts",
    metricDef: () => roundedNumberMetricDef,
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "addToCartsList",
        metricParts: {
          numerators: [{ metricField: "addToCartsList" }]
        },
        description: "This product's Ampd Google Ads driven add-to-carts."
      }
    ]
  },
  CLICKS: {
    key: "CLICKS",
    name: "Clicks",
    units: "Number of Clicks",
    metricDef: () => roundedNumberMetricDef,
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "clicksList",
        metricParts: {
          numerators: [{ metricField: "clicksList" }]
        },
        description: "This product's Ampd Google Ads ad-clicks."
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "clicksList",
        metricParts: {
          numerators: [{ metricField: "clicksList" }]
        },
        description: "This product's Amazon Ads ad-clicks."
      }
    ]
  },
  COST_OF_ACQUISITION: {
    key: "COST_OF_ACQUISITION",
    name: "Cost of Acquisition",
    units: "Cost per New Customer",
    metricDef: currencyMetricDef,
    summable: false,
    isInvertedSuccess: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "costOfAcquisitionList",
        metricParts: {
          numerators: [{ metricField: "adjustedCostList" }],
          denominators: [{ metricField: "newToBrandConversionsList" }]
        },
        description: `The average cost acquiring a new customer using Ampd
         Google Ads.`
      }
    ]
  },
  CONVERSIONS: {
    key: "CONVERSIONS",
    name: "Conversions",
    units: "Number of Conversions",
    metricDef: () => roundedNumberMetricDef,
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "conversionsList",
        metricParts: {
          numerators: [{ metricField: "conversionsList" }]
        },
        description: "This product's Ampd's Google Ads conversions."
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "conversionsList",
        metricParts: {
          numerators: [{ metricField: "conversionsList" }]
        },
        description: "This product's Amazon Ads conversions."
      }
    ]
  },
  COST: {
    key: "COST",
    name: "Cost",
    units: "Cost Amount",
    metricDef: currencyMetricDef, // This metricDef uses the passed currency
    summable: true,
    isInvertedSuccess: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "costList",
        metricParts: {
          numerators: [{ metricField: "costList" }]
        },
        description: "This product's Ampd Google Ads advertising costs."
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "costList",
        metricParts: {
          numerators: [{ metricField: "costList" }]
        },
        description: `This product's Amazon Ads (Sponsored Products) advertising
           costs. This does not include any Brand, Display, Video, etc 
           advertising costs.`
      }
    ]
  },
  CPC: {
    key: "CPC",
    name: "Cost Per Click",
    units: "",
    metricDef: currencyWithCentsMetricDef, // uses the passed currency
    summable: false,
    isInvertedSuccess: true,
    channels: [
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "costPerClickList",
        metricParts: {
          numerators: [{ metricField: "costList" }],
          denominators: [{ metricField: "clicksList" }]
        },
        description: "The average cost of a click on an ad from Amazon Ads."
      },
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "costPerClickList",
        metricParts: {
          numerators: [{ metricField: "costList" }],
          denominators: [{ metricField: "clicksList" }]
        },
        description: "The average cost of a click on an ad from Google Ads."
      }
    ]
  },
  CTR: {
    key: "CTR",
    name: "Click Through Rate",
    units: "",
    metricDef: () => roundedPercentageMetricDef,
    summable: false,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "clickThroughRateList",
        metricParts: {
          numerators: [{ metricField: "clicksList" }],
          denominators: [{ metricField: "impressionsList" }]
        },
        description: `This product's Google Ads advertising click-through-rate. 
           This is the percentage of clicks out of the total times the ad
           was displayed (ie: "impressions").`
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "clickThroughRateList",
        metricParts: {
          numerators: [{ metricField: "clicksList" }],
          denominators: [{ metricField: "impressionsList" }]
        },
        description: `This product's Amazon Ads advertising click-through-rate. 
           This is the percentage of clicks out of the total times the ad
           was displayed (ie: "impressions").`
      }
    ]
  },
  CVR: {
    key: "CVR",
    name: "Conversion Rate",
    units: "",
    metricDef: () => roundedPercentageMetricDef,
    summable: false,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "conversionRateList",
        metricParts: {
          numerators: [{ metricField: "conversionsList" }],
          denominators: [{ metricField: "clicksList" }]
        },
        description: `This product's Ampd Google Ads advertising conversion-rate. 
         This is the percentage of Ampd Google Ads clicks that result in a
         product purchase (within the brand) within 14 days of the click.`
      }
    ]
  },
  IMPRESSIONS: {
    key: "IMPRESSIONS",
    name: "Impressions",
    units: "Number of Impressions",
    metricDef: () => roundedNumberMetricDef,
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "impressionsList",
        metricParts: {
          numerators: [{ metricField: "impressionsList" }]
        },
        description: "This product's Ampd Google Ads impressions."
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "impressionsList",
        metricParts: {
          numerators: [{ metricField: "impressionsList" }]
        },
        description: "This product's Amazon Ads impressions."
      }
    ]
  },
  NEW_TO_BRAND_CONVERSIONS: {
    key: "NEW_TO_BRAND_CONVERSIONS",
    name: "New to Brand Conversions",
    units: "Conversions",
    metricDef: () => roundedNumberMetricDef,
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "newToBrandConversionsList",
        metricParts: {
          numerators: [{ metricField: "newToBrandConversionsList" }]
        },
        description: `This product's Ampd Google Ads conversions from new
         customers.`
      }
    ]
  },
  PROJECTED_ANNUALIZED_REVENUE: {
    key: "PROJECTED_ANNUALIZED_REVENUE",
    name: "Projected Annualized Revenue",
    units: "Projected Annualized Revenue",
    metricDef: currencyMetricDef, // This metricDef uses the passed currency
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.BRAND,
        metricField: "projectedAnnualizedRevenueList",
        metricParts: {
          numerators: [{ metricField: "projectedAnnualizedRevenueList" }]
        },
        description:
          "This product's projected annualized revenue created by Ampd."
      }
    ]
  },
  PROJECTED_ANNUALIZED_ROAS: {
    key: "PROJECTED_ANNUALIZED_ROAS",
    name: "Projected Annualized ROAS",
    units: "Percent",
    metricDef: roundedPercentMetricDef, // This metricDef uses the passed currency
    summable: false,
    channels: [
      {
        channelType: allChannelTypes.BRAND,
        metricField: "projectedAnnualizedRoasList",
        metricParts: {
          numerators: [{ metricField: "projectedAnnualizedRoasList" }]
        },
        description: `This product's projected Ampd-driven return on Google Ads
        spend, including projected repeat purchases over the first year & beyond.`
      }
    ]
  },
  PROJECTED_REVENUE: {
    key: "PROJECTED_REVENUE",
    name: "Projected Revenue",
    units: "Projected Revenue",
    metricDef: currencyMetricDef, // This metricDef uses the passed currency
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.BRAND,
        metricField: "projectedRevenueList",
        metricParts: {
          numerators: [{ metricField: "projectedRevenueList" }]
        },
        description: "This product's projected revenue created by Ampd."
      }
    ]
  },
  PROJECTED_ROAS: {
    key: "PROJECTED_ROAS",
    name: "Projected ROAS",
    units: "Percent",
    metricDef: roundedPercentMetricDef, // This metricDef uses the passed currency
    summable: false,
    channels: [
      {
        channelType: allChannelTypes.BRAND,
        metricField: "projectedRoasList",
        metricParts: {
          numerators: [{ metricField: "projectedRoasList" }]
        },
        description: `This product's projected Ampd-driven return on Google Ads spend.`
      }
    ]
  },
  REVENUE: {
    key: "REVENUE",
    name: "Revenue",
    units: "Revenue Amount",
    metricDef: currencyMetricDef, // This metricDef uses the passed currency
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.SELLER,
        metricField: "revenueList",
        metricParts: {
          numerators: [{ metricField: "revenueList" }]
        },
        description: `This product's total Amazon revenue. This does not include
           any Brand Referral Bonus.`
      },
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "revenueList",
        metricParts: {
          numerators: [{ metricField: "revenueList" }]
        },
        description: "This product's Ampd Google Ads revenue."
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "revenueList",
        metricParts: {
          numerators: [{ metricField: "revenueList" }]
        },
        description: "This product's Amazon Ads (Sponsored Products) revenue."
      },
      {
        channelType: allChannelTypes.ORGANIC,
        metricField: "revenueList",
        metricParts: {
          numerators: [{ metricField: "revenueList" }]
        },
        description: "This product's estimated organic revenue."
      }
    ]
  },
  ROAS: {
    key: "ROAS",
    name: "ROAS",
    units: "Percent",
    metricDef: roundedPercentMetricDef, // This metricDef uses the passed currency
    summable: false,
    channels: [
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "googleRoasList",
        metricParts: {
          numerators: [
            {
              metricField: "revenueList",
              source: allChannelTypes.SELLER.sourceField
            }
          ],
          denominators: [
            {
              metricField: "adjustedCostList",
              source: allChannelTypes.GOOGLE_ADS.sourceField
            }
          ]
        },
        description: `This product's Google Ads advertising return on ad-spend (ROAS). 
           This is the total revenue from Google Ads advertising divided by
           the Google Ads advertising cost (offset by any Brand Referral Bonus
           external advertising credits).`
      }
    ]
  },
  TACOS: {
    key: "TACOS",
    name: "TACoS",
    units: "",
    metricDef: () => roundedPercentageMetricDef,
    summable: false,
    isInvertedSuccess: true,
    channels: [
      {
        channelType: allChannelTypes.SELLER,
        metricField: "tacosList",
        metricParts: {
          numerators: [
            {
              metricField: "adjustedCostList",
              source: allChannelTypes.GOOGLE_ADS.sourceField
            },
            {
              metricField: "costList",
              source: allChannelTypes.AMAZON_ADS.sourceField
            }
          ],
          denominators: [{ metricField: "revenueList" }]
        },
        description: `This product's combined Google Ads cost (minus any Amazon
           Brand Referral Bonus) and Amazon Ads cost divided by its total
           revenue.`
      }
      // TODO(clint): Temp disabled until seller ingestion (+computed AACOS) is avail.
      //  If other UI elements need this data, it could be filtered as needed.
      // {
      //   channelType: allChannelTypes.GOOGLE_ADS,
      //   metricField: "googleAacos",
      //   metricParts: {
      //     numerators: [{ metricField: "cost" }],
      //     denominators: [{ metricField: "adjustedRevenue" }]
      //   },
      //   description: `This product's Ampd's Google Ads advertising cost vs Ampd's
      //      Google Ads advertising revenue. The extra 'A' (A-Acos) means that
      //      this includes any brand-referral bonus increase to revenue.`
      // },
      // {
      //   channelType: allChannelTypes.AMAZON_ADS,
      //   metricField: "amazonAcos",
      //   metricParts: {
      //     numerators: [{ metricField: "cost" }],
      //     denominators: [{ metricField: "revenue" }]
      //   },
      //   description:
      //     "This product's Amazon Ads advertising cost vs Amazon Ads advertising revenue."
      // }
    ]
  },
  UNITS_SOLD: {
    key: "UNITS_SOLD",
    name: "Units Sold",
    units: "Number of Units Sold",
    metricDef: () => roundedNumberMetricDef,
    summable: true,
    channels: [
      {
        channelType: allChannelTypes.SELLER,
        metricField: "unitsSoldList",
        metricParts: {
          numerators: [{ metricField: "unitsSoldList" }]
        },
        description: "This product's total units sold."
      },
      {
        channelType: allChannelTypes.GOOGLE_ADS,
        metricField: "unitsSoldList",
        metricParts: {
          numerators: [{ metricField: "unitsSoldList" }]
        },
        description: "This product's Ampd Google Ads driven units sold."
      },
      {
        channelType: allChannelTypes.AMAZON_ADS,
        metricField: "unitsSoldList",
        metricParts: {
          numerators: [{ metricField: "unitsSoldList" }]
        },
        description: "This product's Amazon Ads driven units sold."
      },
      {
        channelType: allChannelTypes.ORGANIC,
        metricField: "unitsSoldList",
        metricParts: {
          numerators: [{ metricField: "unitsSoldList" }]
        },
        description: "This product's organic (non-paid channel) units sold."
      }
    ]
  }
};
