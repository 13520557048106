import React from "react";
import { Popup } from "semantic-ui-react";

export default function SimpleTooltip(props) {
  const {
    children,
    context,
    offset,
    on,
    disabled,
    position,
    tooltip,
    wide,
    hoverable,
    mouseLeaveDelay
  } = props;

  return (
    <Popup
      style={{ padding: 10 }}
      animation="none"
      trigger={children}
      position={position || "top center"}
      disabled={disabled}
      context={context}
      offset={offset}
      wide={wide}
      on={on || "hover"}
      hoverable={hoverable}
      mouseLeaveDelay={mouseLeaveDelay}
    >
      <strong>
        <small>{tooltip}</small>
      </strong>
    </Popup>
  );
}
