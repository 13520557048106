import _ from "lodash";

// Return object for formatMetric that acts as a string in JSX but which
// retains references to the raw numeric value and metric definition that
// were originally used.
function FormattedValue(formattedValue, rawValue, metDef) {
  const value = new String(formattedValue);
  value.rawValue = rawValue;
  value.metDef = metDef;
  return value;
}

export function formatMetric(metDef, value) {
  const rawValue = value;

  /* Don't render null, undefined, or NaN values as zero. */
  if (
    _.isNil(value) ||
    isNaN(value) ||
    value === Number.POSITIVE_INFINITY ||
    value === Number.NEGATIVE_INFINITY
  ) {
    return FormattedValue(metDef.nanString || "(N/A)", rawValue, metDef);
  }

  const formatting = {
    postfix: "",
    precision: 0,
    prefix: "",
    sign: false,
    isPercent: false,
    abbreviated: false
  };

  if (metDef.formatting) {
    _.extend(formatting, metDef.formatting);
  }

  // TODO(robert): We could use getUserLocale() (from get-user-locale) here,
  //  but not sure how that will work with the rest of our interface.
  const locale = "en-us";

  const { prefix, postfix, isPercent, useAsciiCurrency } = formatting;
  const opts = getNumberFormatOptions(
    metDef.isCurrency ? metDef.currencyCode || "USD" : undefined,
    formatting
  );

  if (isPercent) {
    value *= 100;
  }

  let output = new Intl.NumberFormat(locale, opts).format(value);

  // Intl.NumberFormat may insert &nbsp; chars between currency symbol and digits.
  // If we want a strictly ASCII representation, convert them into ASCII spaces.
  if (useAsciiCurrency) {
    output = output.replace(/\s/g, " ");
  }

  if (prefix) {
    output = prefix + output;
  }
  if (isPercent) {
    output += "%";
  }
  if (postfix) {
    output = output + postfix;
  }
  return FormattedValue(output, rawValue, metDef);
}

// Returns the format options for use with the Intl.NumberFormat API.
//
// For more information, see
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export function getNumberFormatOptions(currencyCode, formatting) {
  const { sign, useAsciiCurrency, abbreviated } = formatting;

  const opts = {
    signDisplay: sign === "always" ? "always" : sign ? "exceptZero" : "auto"
  };

  if (currencyCode) {
    opts.style = "currency";
    opts.currency = currencyCode;
  }

  let fractionDigits;
  if (abbreviated) {
    opts.notation = "compact"; // eg: $1.25M
    // Override currency fraction digits when abbreviated

    // Hide fraction zeros. Ex: 1_000_000 => 1M (not 1.00M)
    opts.minimumFractionDigits = 0;

    // Treat the configured abbreviation precision as a max-precision
    fractionDigits = formatting.precision || 0;
  }

  // We set both the minimum and maximum fraction digits to ensure a tight
  // range.
  //
  // This is doubly important for currencies, where the default value for
  // minimumFractionDigits is the minor currency units. If the maximum
  // value (e.g., 0) < minimum value (e.g., 2) we get a
  // "maximumFractionDigits value is out of range" error.
  if (!currencyCode) {
    fractionDigits = formatting.precision || 0;
  } else if (!formatting.currencyUseMinorUnits) {
    fractionDigits = 0;
  }

  // Set fraction digits (respect minimum fraction digits when explicitly set)
  opts.minimumFractionDigits = opts.minimumFractionDigits ?? fractionDigits;
  opts.maximumFractionDigits = fractionDigits;

  // If we want an ASCII representation, use the currency code instead of the
  // currency symbol.
  if (useAsciiCurrency) {
    opts.currencyDisplay = "code";
  }

  return opts;
}
