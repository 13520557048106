import gql from "graphql-tag";

const SITE_ERROR_FIELDS = `
  ... on BaseSiteError {
    errorType
  }
  ... on DomainError {
    domain
  }
`;

export const SESSION = gql`
  query {
    session {
      userSites {
        isSiteAdmin
        site {
          id
          name
          shopifyShop
          shopifyInfo {
            timezone
            currencyCode
          }
          bigCommerceStore
          bigCommerceInfo {
            timezone
            currencyCode
          }
          billingAccountStatus
          stripeInfo {
            subscriptionStatus
            subscriptionPlan
            subscriptionId
            amountCents
          }
          coupon
          contactEmail
          currencyCode
          webAppEnabled
          isAmpdAdsSubscriber
          trialExpirationTime {
            date {
              year
              month
              day
            }
            time {
              hour
              minute
              second
            }
          }
          siteAlias
          siteDomainName
          featureMask
          hasAdwordsAccount
          adwordsCustomerIds
          adwordsAccounts {
            name
            customerId
            currencyCode
            invitation {
              link
              email
              status
            }
          }
          amazonInfo {
            advertisingAccounts {
              userId
              email
              region
            }
            sellerAccounts {
              sellingPartnerId
              region
            }
          }
          hasGaInfo
          gaViewIds
          siteProblems {
            message
            error {
              ${SITE_ERROR_FIELDS}
            }
          }
          userFeedbackStatus
          salesPlatforms {
            amazon
            bigCommerce
            shopify
          }
        }
      }
      user {
        id
        email
        profile {
          name
          givenName
          familyName
          nickname
          picture
        }
        operator
      }
    }
  }
`;

export const SITE_HUBS = gql`
  query($site: SiteSelector!) {
    site(site: $site) {
      hubs {
        alias
        name
        type
      }
    }
  }
`;

export const USER_BOOKMARKS = gql`
  query($featureCategory: String) {
    userBookmarks(featureCategory: $featureCategory) {
      bookmarks {
        name
        featureCategory
        parameters {
          name
          value
        }
      }
    }
  }
`;

export const UPDATE_USER_BOOKMARKS = gql`
  mutation($featureCategory: String!, $bookmarks: [InputUserBookmark]!) {
    updateUserBookmarks(
      featureCategory: $featureCategory
      bookmarks: $bookmarks
    ) {
      ok
    }
  }
`;

export default SESSION;
