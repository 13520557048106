import React, { useCallback, useMemo } from "react";
import { Dropdown, Form } from "semantic-ui-react";

import { sendGAEvent } from "./GA";
import {
  googleAdsGeotargetNameMap,
  googleAdsGeotargets
} from "Common/utils/googleAds";

// Displays a selector for people to choose the locations where their ad will
// run.
export default function GeotargetDropdown(props) {
  const {
    siteAlias,
    gaCategory,
    geotargets,
    setGeotargets,
    disabled,
    onGeotargetsInputBlur
  } = props;

  const options = useMemo(
    () =>
      googleAdsGeotargets
        .map(entry => {
          const key = entry.criteriaId;
          return {
            text: entry.name,
            key,
            value: key
          };
        })
        .toArray(),
    []
  );

  // Handles the selection of a new geotarget from the dropdown.
  const onGeotargetsChanged = useCallback(
    (e, data) => {
      e.stopPropagation();

      const oldGeotargets = geotargets;
      const newGeotargets = data.value;

      const addedGeotargets = newGeotargets.filter(
        x => !oldGeotargets.includes(x)
      );
      const removedGeotargets = oldGeotargets.filter(
        x => !newGeotargets.includes(x)
      );

      // Send GA events for added/removed geotargets.
      addedGeotargets.forEach(x => {
        sendGAEvent(
          gaCategory,
          "Added geotarget",
          siteAlias,
          googleAdsGeotargetNameMap.get(x)
        );
      });

      removedGeotargets.forEach(x => {
        sendGAEvent(
          gaCategory,
          "Removed geotarget",
          siteAlias,
          googleAdsGeotargetNameMap.get(x)
        );
      });

      setGeotargets(newGeotargets);
    },
    [geotargets, setGeotargets, gaCategory, siteAlias]
  );

  return (
    <Form>
      <Form.Field required>
        <label>
          Select one or more countries or regions where your ad should be shown
        </label>
        <Dropdown
          options={options}
          onChange={onGeotargetsChanged}
          onBlur={onGeotargetsInputBlur}
          value={geotargets}
          placeholder="Select where your ad should be shown"
          fluid
          multiple
          search
          selection
          disabled={disabled}
        />
      </Form.Field>
    </Form>
  );
}
