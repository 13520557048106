import Immutable from "immutable";

import React from "react";
import { Dropdown } from "semantic-ui-react";
import { snakeCase } from "change-case";

import { sendGAEvent } from "./GA";

const EXISTING_USER_TEXT = "Existing Ampd Customer";
export const EXISTING_USER = snakeCase("Existing Ampd Customer");

const attributionList = Immutable.List([
  "Ampd Sales Team — Andrew Forster",
  "Surge Summit",
  "Amazon Accelerate",
  "Sell + Scale Summit",
  "Amazon unBoxed",
  "Prosper Show",
  "Billion Dollar Seller Summit",
  "Google Search",
  "Conference",
  "Youtube",
  "Podcast",
  "Facebook",
  "LinkedIn",
  "Agency Recommendation",
  EXISTING_USER_TEXT,
  "Other"
]);

// Displays a selector for people to self-report how they heard about
// Ampd Ads.
export default function AttributionDropdown(props) {
  const {
    disabled,
    attribution,
    setAttribution,
    siteAlias,
    gaCategory
  } = props;

  const options = attributionList
    .map(text => {
      const key = snakeCase(text);
      return {
        text,
        key,
        value: key
      };
    })
    .toArray();

  // Handles the selection of a new attribution from the dropdown.
  const onAttributionChanged = async (e, data) => {
    e.stopPropagation();

    const newAttribution = data.value || "";

    sendGAEvent(gaCategory, "Choose Attribution", siteAlias, newAttribution);

    setAttribution(newAttribution);
  };

  return (
    <Dropdown
      options={options}
      onChange={onAttributionChanged}
      value={attribution ? attribution : undefined}
      placeholder="Select where you heard about Ampd"
      fluid
      selection
      clearable
      disabled={disabled}
      upward
    />
  );
}
