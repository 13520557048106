import match from "micromatch";
import Immutable from "immutable";

import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Divider,
  Icon,
  Input,
  Modal,
  List,
  Message,
  InputOnChangeData
} from "semantic-ui-react";

import { formatCustomerId } from "Common/utils/googleAds";
import { compareCaseInsens } from "Common/utils/strings";
import { GetDashboardSessionReply } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";

const CONTAINS_MATCH_OPTIONS = {
  nocase: true,
  contains: true
};

// Modal component that shows the user a list of available sites (for that user).
// The user can check/uncheck any number of sites before clicking the Select button.
// Above the list is a filter field that accepts site name fragments,
// domain name fragments, or partial account numbers (with or without hyphens).
const SiteSelectorModal = ({
  buttonLabel,
  disabledSiteAliases,
  errorMessages,
  onClose,
  onSelect,
  open,
  setErrorMessages,
  siteAliases,
  title,
  userSites
}: {
  buttonLabel: string;
  disabledSiteAliases: Array<string>;
  errorMessages: Array<string>;
  onClose: () => void;
  onSelect: (siteAliases: Array<string>) => void;
  open: boolean;
  setErrorMessages: (errorMessages: Array<string>) => void;
  siteAliases: Array<string>;
  title: string;
  userSites: Array<GetDashboardSessionReply.SiteAuthorization.AsObject>;
}): JSX.Element => {
  const [selectedAliases, setSelectedAliases] = useState(
    Immutable.Set<string>(siteAliases)
  );

  const disabledAliases = Immutable.Set(disabledSiteAliases || []);

  const inputRef = useRef<HTMLInputElement>(null);
  const [matchText, setMatchText] = useState("");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setMatchText("");
    }
  }, [open]);

  const filteredUserSites = useMemo(() => {
    const _filteredUserSites = userSites.filter(userSite => {
      if (!matchText) {
        return true;
      }

      // As a "secret" option, if the first match character is a '@', then show
      // site aliases in the list and only match on the alias.
      if (matchText[0] === "@") {
        if (
          matchText.length === 1 ||
          match.isMatch(
            userSite.siteAlias,
            matchText.slice(1),
            CONTAINS_MATCH_OPTIONS
          )
        ) {
          return true;
        }

        return false;
      }

      // Look for partial matches of the site name and the site domain name.
      if (match.isMatch(userSite.siteName, matchText, CONTAINS_MATCH_OPTIONS)) {
        return true;
      }

      // Next, look for matches among the site's adwords customer ids, both with and
      // without the hyphens.
      for (const id of userSite.adwordsAccountIdsList) {
        if (
          match.isMatch(id, matchText, CONTAINS_MATCH_OPTIONS) ||
          match.isMatch(formatCustomerId(id), matchText, CONTAINS_MATCH_OPTIONS)
        ) {
          return true;
        }
      }

      return false;
    });

    return _filteredUserSites.sort((a, b) =>
      compareCaseInsens(a.siteName, b.siteName)
    );
  }, [userSites, matchText]);

  const modalClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleClose = () => {
    if (errorMessages && setErrorMessages) {
      setErrorMessages([]);
    }

    if (onClose) {
      onClose();
    }
  };

  const handleSelect = () => {
    if (errorMessages && setErrorMessages) {
      setErrorMessages([]);
    }

    if (onSelect) {
      onSelect(selectedAliases.subtract(disabledAliases).toArray());
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleToggleSite = (siteAlias: string) => {
    if (errorMessages && setErrorMessages) {
      setErrorMessages([]);
    }

    if (disabledAliases.has(siteAlias)) {
      return;
    }

    if (selectedAliases.has(siteAlias)) {
      setSelectedAliases(selectedAliases.remove(siteAlias));
    } else {
      setSelectedAliases(selectedAliases.add(siteAlias));
    }
  };

  const handleMatchTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    setMatchText(value);
  };

  const handleMatchTextKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (errorMessages && setErrorMessages) {
      setErrorMessages([]);
    }

    e.stopPropagation();
    if (filteredUserSites.length !== 1) {
      return;
    }
    const userSite = filteredUserSites[0];
    if (!userSite) {
      return;
    }

    if (e.keyCode === 13 || e.which === 13) {
      handleToggleSite(userSite.siteAlias);
      inputRef?.current?.select();
    }
  };

  return (
    <Modal open={open} onClose={modalClose} onClick={handleModalClick}>
      <Modal.Header>{title || "Select Brands"}</Modal.Header>
      <Modal.Content>
        <Input
          fluid
          onChange={handleMatchTextChange}
          onKeyPress={handleMatchTextKeyPress}
        >
          <input ref={inputRef} />
        </Input>
        <br />
        <div
          style={{
            overflowY: "auto",
            height: "40vh",
            border: "1px solid #444"
          }}
        >
          <List divided relaxed selection verticalAlign="middle">
            {filteredUserSites.map(userSite => (
              <List.Item
                key={userSite.siteAlias}
                disabled={disabledAliases.has(userSite.siteAlias)}
                active={selectedAliases.has(userSite.siteAlias)}
                onClick={() => handleToggleSite(userSite.siteAlias)}
              >
                <List.Content floated="left">
                  <Icon
                    name={
                      selectedAliases.has(userSite.siteAlias)
                        ? "check square outline"
                        : "square outline"
                    }
                  />
                </List.Content>
                <List.Content
                  style={{ fontSize: "smaller", width: "130px" }}
                  floated="right"
                >
                  {userSite.adwordsAccountIdsList.map(id => (
                    <React.Fragment key={id}>
                      {formatCustomerId(id)}
                      <br />
                    </React.Fragment>
                  ))}
                </List.Content>
                <List.Content>
                  <span>
                    {matchText[0] === "@" ? (
                      userSite.siteAlias
                    ) : (
                      <span>{userSite.siteName}</span>
                    )}
                  </span>
                </List.Content>
              </List.Item>
            ))}
            {selectedAliases.size > 0 && (
              <List.Item disabled={true}>
                <Divider horizontal fitted>
                  checked
                </Divider>
              </List.Item>
            )}
            {userSites.map(userSite => {
              if (
                !selectedAliases.has(userSite.siteAlias) ||
                disabledAliases.has(userSite.siteAlias)
              ) {
                return null;
              }
              return (
                <List.Item
                  key={"SELECTED_" + userSite.siteAlias}
                  active={true}
                  onClick={() => handleToggleSite(userSite.siteAlias)}
                >
                  <List.Content floated="left">
                    <Icon name="check square outline" />
                  </List.Content>
                  <List.Content
                    style={{ fontSize: "smaller", width: "110px" }}
                    floated="right"
                  >
                    {userSite.adwordsAccountIdsList.map(id => (
                      <React.Fragment key={id}>
                        {formatCustomerId(id)}
                        <br />
                      </React.Fragment>
                    ))}
                  </List.Content>
                  <List.Content>
                    <span>
                      {matchText[0] === "@"
                        ? userSite.siteAlias
                        : userSite.siteName}
                    </span>
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </div>
      </Modal.Content>
      <Modal.Actions>
        {errorMessages.length > 0 && (
          <Message error>
            {errorMessages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </Message>
        )}
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleSelect}
          primary
          disabled={selectedAliases.subtract(disabledAliases).size === 0}
        >
          {buttonLabel || "Select"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export function getUserSiteForAlias(
  userSites: Array<GetDashboardSessionReply.SiteAuthorization.AsObject>,
  siteAlias: string
): GetDashboardSessionReply.SiteAuthorization.AsObject | null {
  if (!userSites || !siteAlias) {
    return null;
  }

  for (const userSite of userSites) {
    if (userSite.siteAlias === siteAlias) {
      return userSite;
    }
  }

  return null;
}

export default SiteSelectorModal;
