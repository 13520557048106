import moment from "moment/moment";

import { useCallback, useMemo, useState } from "react";

import {
  BiddingStrategy,
  CampaignBiddingConfiguration,
  getBiddingConfiguration
} from "./getBiddingConfiguration";
import {
  BidAutomationStatusInfo,
  getBidAutomationStatusInfo
} from "./getBidAutomationStatusInfo";
import { GetCampaignBidAutomationResultsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import {
  DATE_FORMAT,
  RecentMetricsForCampaigns,
  useRecentMetricsForCampaigns
} from "../../queries/useRecentMetricsForCampaigns";
import { GoogleAdsConfiguration } from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import { extractErrorMessage } from "Common/errors/error";

import {
  AutomationStatus,
  ItemizedCampaignConfiguration
} from "../../queries/useItemizedCampaignConfiguration";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { useUpdateBidAutomation } from "../../queries/useUpdateBidAutomation";
import {
  BIDDING_STRATEGY_MANUAL_CPC_STR,
  BIDDING_STRATEGY_TARGET_SPEND_STR,
  useUpdateCampaignBiddingStrategy
} from "../../queries/useUpdateCampaignBiddingStrategy";
import { sendUpdateCampaignBudget } from "../CampaignBudgetEditButton";
import { convertMicrosToCurrencyUnit } from "Common/utils/money";
import { useCampaignBidAutomationResults } from "../../queries/useCampaignBidAutomationResults";

const AUTOMATION_DELAY_DAYS = 2;

export type EditBidAutomationState = {
  editBidAutomationEnabled: boolean;
  setEditBidAutomationEnabled: (enabled: boolean) => void;
  editTargetVoltage: number;
  setEditTargetVoltage: (val: number) => void;
  editDailyBudget: number;
  setEditDailyBudget: (val: number) => void;
  dragTargetVoltage: number;
  displayTargetVoltage: number;
  setDragTargetVoltage: (val: number) => void;
  biddingConfiguration: CampaignBiddingConfiguration;
  bidAutomationStatusInfo: BidAutomationStatusInfo;
  bidAutomationResultsData:
    | GetCampaignBidAutomationResultsReply.AsObject
    | undefined;
  bidAutomationResultsIsLoading: boolean;
  hasAnyBidAutomationMetrics: boolean;
  targetVoltageIndex: number;
  targetVoltageCharacteristics: GetCampaignBidAutomationResultsReply.VoltageCharacteristics.AsObject | null;
  recentMetricsForCampaigns: RecentMetricsForCampaigns[] | undefined;
  recentMetricsLoading: boolean;
  editBiddingStrategy: GoogleAdsConfiguration.CampaignBiddingStrategy.Option;
  setEditBiddingStrategy: (
    strategy: GoogleAdsConfiguration.CampaignBiddingStrategy.Option
  ) => void;
  hasUnappliedChanges: boolean;

  errorMessage: string;
  submitAttempts: number;
  setSubmitAttempts: (val: number) => void;
  isSubmitting: boolean;
  handleSubmit: () => Promise<boolean>;
  handleCancel: () => void;
};

// Returns UI state for changing the bid automation settings of a campaign.
// The state contains various pieces, but a single Submit (and Cancel) handler.
// The unified state object allows the UI to be organized in different ways for
// different workflows.
export function useEditBidAutomationState({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  daysForMetrics,
  campaignIds
}: {
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  daysForMetrics: number;
  campaignIds: Array<string>;
}): EditBidAutomationState {
  const {
    fullAmpdConfiguration,
    campaignId,
    campaignBudget,
    bidAutomationStatus,
    bidAutomationTargetVoltage
  } = itemizedCampaignConfiguration;

  const biddingConfiguration = getBiddingConfiguration(fullAmpdConfiguration);
  const bidAutomationStatusInfo = getBidAutomationStatusInfo(
    itemizedCampaignConfiguration.fullAmpdConfiguration
  );

  const bidAutomationEnabled = bidAutomationStatus === AutomationStatus.ENABLED;
  const [editBidAutomationEnabled, setEditBidAutomationEnabled] = useState(
    bidAutomationEnabled
  );

  const [editDailyBudget, setEditDailyBudget] = useState(campaignBudget);

  const [editTargetVoltage, setEditTargetVoltage] = useState(
    bidAutomationTargetVoltage
  );
  const [dragTargetVoltage, setDragTargetVoltage] = useState(0);

  const [editBiddingStrategy, setEditBiddingStrategy] = useState(
    biddingConfiguration.biddingStrategy
  );

  const hasUnappliedChanges =
    editDailyBudget !== campaignBudget ||
    editBidAutomationEnabled !== bidAutomationEnabled ||
    (editBidAutomationEnabled &&
      bidAutomationTargetVoltage !== editTargetVoltage) ||
    editBiddingStrategy !== biddingConfiguration.biddingStrategy;

  // Either show metrics for the explicitly set voltage or for the voltage that
  // was targeted in the last run.
  const displayTargetVoltage =
    dragTargetVoltage ||
    editTargetVoltage ||
    bidAutomationStatusInfo.targetedVoltage;

  const {
    data: bidAutomationResultsData,
    isFetching: bidAutomationResultsIsLoading
  } = useCampaignBidAutomationResults(siteAlias, String(campaignId));

  const hasAnyBidAutomationMetrics = Boolean(
    bidAutomationResultsData?.voltageCharacteristicsList.length
  );

  const [targetVoltageIndex, targetVoltageCharacteristics] = useMemo<
    [
      number,
      GetCampaignBidAutomationResultsReply.VoltageCharacteristics.AsObject | null
    ]
  >(() => {
    const voltage = displayTargetVoltage;
    let closestIndex = 0;
    let closestCharacteristics: GetCampaignBidAutomationResultsReply.VoltageCharacteristics.AsObject | null = null;

    (bidAutomationResultsData?.voltageCharacteristicsList || []).forEach(
      (characteristics, index) => {
        if (
          closestCharacteristics == null ||
          Math.abs(characteristics.voltageLevel - voltage) <
            Math.abs(closestCharacteristics.voltageLevel - voltage)
        ) {
          closestIndex = index;
          closestCharacteristics = characteristics;
        }
      }
    );

    return [closestIndex, closestCharacteristics];
  }, [bidAutomationResultsData, displayTargetVoltage]);

  // Collect recent metrics based on the number of days specified by the page's
  // date span control.
  const [startDate, endDate] = useMemo(() => {
    const endDate = moment()
      .subtract(AUTOMATION_DELAY_DAYS, "days")
      .endOf("day");
    const startDate = moment(endDate).subtract(daysForMetrics * 4 - 1, "days");

    return [startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT)];
  }, [daysForMetrics]);

  const {
    data: recentMetricsForCampaigns,
    isLoading: recentMetricsLoading
  } = useRecentMetricsForCampaigns({
    siteAlias,
    startDate,
    endDate,
    campaignIds,
    recentMetricsDays: daysForMetrics
  });

  const {
    errorMessage,
    submitAttempts,
    setSubmitAttempts,
    isSubmitting,
    handleSubmit,
    handleCancel
  } = useSubmitBidAutomationState({
    siteAlias,
    gaCategory,
    itemizedCampaignConfiguration,
    refetchCampaignConfiguration,
    editBidAutomationEnabled,
    setEditBidAutomationEnabled,
    editTargetVoltage,
    setEditTargetVoltage,
    editDailyBudget,
    setEditDailyBudget,
    biddingConfiguration,
    editBiddingStrategy,
    setEditBiddingStrategy
  });

  return useMemo(
    () => ({
      editBidAutomationEnabled,
      setEditBidAutomationEnabled,
      editTargetVoltage,
      setEditTargetVoltage,
      editDailyBudget,
      setEditDailyBudget,
      dragTargetVoltage,
      displayTargetVoltage,
      setDragTargetVoltage,
      biddingConfiguration,
      bidAutomationStatusInfo,
      bidAutomationResultsData,
      bidAutomationResultsIsLoading,
      hasAnyBidAutomationMetrics,
      targetVoltageIndex,
      targetVoltageCharacteristics,
      recentMetricsForCampaigns,
      recentMetricsLoading,
      editBiddingStrategy,
      setEditBiddingStrategy,
      hasUnappliedChanges,
      errorMessage,
      submitAttempts,
      setSubmitAttempts,
      isSubmitting,
      handleSubmit,
      handleCancel
    }),
    [
      editBidAutomationEnabled,
      setEditBidAutomationEnabled,
      editTargetVoltage,
      setEditTargetVoltage,
      editDailyBudget,
      setEditDailyBudget,
      dragTargetVoltage,
      displayTargetVoltage,
      setDragTargetVoltage,
      biddingConfiguration,
      bidAutomationStatusInfo,
      bidAutomationResultsData,
      bidAutomationResultsIsLoading,
      hasAnyBidAutomationMetrics,
      targetVoltageIndex,
      targetVoltageCharacteristics,
      recentMetricsForCampaigns,
      recentMetricsLoading,
      editBiddingStrategy,
      setEditBiddingStrategy,
      hasUnappliedChanges,
      submitAttempts,
      setSubmitAttempts,
      isSubmitting,
      handleSubmit,
      handleCancel,
      errorMessage
    ]
  );
}

export function useSubmitBidAutomationState({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  editBidAutomationEnabled,
  setEditBidAutomationEnabled,
  editTargetVoltage,
  setEditTargetVoltage,
  editDailyBudget,
  setEditDailyBudget,
  biddingConfiguration,
  editBiddingStrategy,
  setEditBiddingStrategy
}: {
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  editBidAutomationEnabled: boolean;
  setEditBidAutomationEnabled: (enabled: boolean) => void;
  editTargetVoltage: number;
  setEditTargetVoltage: (val: number) => void;
  editDailyBudget: number;
  setEditDailyBudget: (val: number) => void;
  biddingConfiguration: CampaignBiddingConfiguration;
  editBiddingStrategy: GoogleAdsConfiguration.CampaignBiddingStrategy.Option;
  setEditBiddingStrategy: (
    strategy: GoogleAdsConfiguration.CampaignBiddingStrategy.Option
  ) => void;
}): {
  errorMessage: string;
  submitAttempts: number;
  setSubmitAttempts: (val: number) => void;
  isSubmitting: boolean;
  handleSubmit: () => Promise<boolean>;
  handleCancel: () => void;
} {
  const {
    customerId,
    campaignId,
    currencyCode,
    campaignBudgetId,
    campaignBudget,
    campaignBudgetIsShared,
    adGroupId,
    bidAutomationStatus,
    bidAutomationTargetVoltage,
    bidAutomationRunFrequencyDays
  } = itemizedCampaignConfiguration;

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const { mutateAsync: updateBidAutomation } = useUpdateBidAutomation();

  const {
    mutateAsync: updateCampaignBiddingStrategy
  } = useUpdateCampaignBiddingStrategy();

  // Returns true if the submission was fully successful.
  const handleSubmit = useCallback(async (): Promise<boolean> => {
    if (!customerId || !campaignId) {
      return false;
    }

    setIsSubmitting(true);
    try {
      // Update the campaign budget if it has changed and the budget is known
      // and not shared.
      if (
        campaignBudgetId &&
        !campaignBudgetIsShared &&
        editDailyBudget !== campaignBudget
      ) {
        await sendUpdateCampaignBudget(
          siteAlias,
          gaCategory,
          String(customerId),
          String(campaignBudgetId),
          editDailyBudget,
          currencyCode
        );
      }

      let newBidAutomationStatus = AutomationStatus.UNSPECIFIED;
      if (editBidAutomationEnabled) {
        newBidAutomationStatus = AutomationStatus.ENABLED;
      } else {
        // If the automation is already disabled, keep it disabled.  Otherwise,
        // turn the automation to DRY_RUN instead of DISABLED so the automation model
        // continues to be updated.  That way, if it is re-enabled, it doesn't
        // have relearn from scratch.
        if (bidAutomationStatus === AutomationStatus.DISABLED) {
          newBidAutomationStatus = AutomationStatus.DISABLED;
        } else if (bidAutomationStatus !== AutomationStatus.UNSPECIFIED) {
          newBidAutomationStatus = AutomationStatus.DRY_RUN;
        }
      }

      if (
        editTargetVoltage !== bidAutomationTargetVoltage ||
        newBidAutomationStatus !== bidAutomationStatus
      ) {
        await updateBidAutomation({
          siteAlias,
          gaCategory,
          googleAdsCustomerId: String(customerId),
          campaignId: String(campaignId),
          bidAutomationStatus: newBidAutomationStatus,
          bidAutomationTargetVoltage: editTargetVoltage,
          runFrequencyDays: bidAutomationRunFrequencyDays || 3.5 // twice a week, by default
        });
      }

      if (editBiddingStrategy !== biddingConfiguration.biddingStrategy) {
        // If the current bidding strategy is TARGET_SPEND, but the new one
        // is not, let's clear the campaign max cpc first, so a change event
        // will be generated for it.
        if (
          biddingConfiguration.biddingStrategy ===
            BiddingStrategy.TARGET_SPEND &&
          editBiddingStrategy !== BiddingStrategy.TARGET_SPEND
        ) {
          await updateCampaignBiddingStrategy({
            siteAlias,
            gaCategory: "",
            googleAdsAccountId: String(customerId),
            currencyCode,
            campaignId: String(campaignId),
            adGroupId: String(adGroupId),
            // Need to use one of the strings expected by the function.
            newBiddingStrategy: BIDDING_STRATEGY_TARGET_SPEND_STR,
            newCpcBid: 0
          });
        }

        await updateCampaignBiddingStrategy({
          siteAlias,
          gaCategory: gaCategory,
          googleAdsAccountId: String(customerId),
          currencyCode,
          campaignId: String(campaignId),
          adGroupId: String(adGroupId),
          // Need to convert enum one of the strings expected by the function.
          newBiddingStrategy:
            editBiddingStrategy === BiddingStrategy.TARGET_SPEND
              ? BIDDING_STRATEGY_TARGET_SPEND_STR
              : BIDDING_STRATEGY_MANUAL_CPC_STR,
          // Function expects non-micros.
          newCpcBid:
            editBiddingStrategy === BiddingStrategy.TARGET_SPEND
              ? convertMicrosToCurrencyUnit(
                  biddingConfiguration.adGroupDefaultCPCBidMicros
                )
              : convertMicrosToCurrencyUnit(
                  biddingConfiguration.campaignMaxCPCBidMicros
                )
        });
      }

      await refetchCampaignConfiguration(String(campaignId));
      return true;
    } catch (e) {
      const message = extractErrorMessage(e);
      console.error(e);
      setErrorMessage(message);
      return false;
    } finally {
      // Since submitAttempts is used as the key of the OneClickButton, changing it
      // will create a new button instance and re-enable it.
      setSubmitAttempts(submitAttempts + 1);
      setIsSubmitting(false);
    }
  }, [
    adGroupId,
    bidAutomationRunFrequencyDays,
    bidAutomationStatus,
    bidAutomationTargetVoltage,
    campaignBudget,
    campaignBudgetId,
    campaignBudgetIsShared,
    campaignId,
    currencyCode,
    customerId,
    gaCategory,
    refetchCampaignConfiguration,
    siteAlias,
    biddingConfiguration.adGroupDefaultCPCBidMicros,
    biddingConfiguration.biddingStrategy,
    biddingConfiguration.campaignMaxCPCBidMicros,
    editBidAutomationEnabled,
    editBiddingStrategy,
    editDailyBudget,
    editTargetVoltage,
    submitAttempts,
    updateBidAutomation,
    updateCampaignBiddingStrategy
  ]);

  const handleCancel = useCallback(() => {
    setEditBidAutomationEnabled(
      bidAutomationStatus === AutomationStatus.ENABLED
    );
    setEditDailyBudget(campaignBudget);
    setEditTargetVoltage(bidAutomationTargetVoltage);
    setEditBiddingStrategy(biddingConfiguration.biddingStrategy);
  }, [
    bidAutomationStatus,
    campaignBudget,
    bidAutomationTargetVoltage,
    biddingConfiguration.biddingStrategy,
    setEditBidAutomationEnabled,
    setEditDailyBudget,
    setEditTargetVoltage,
    setEditBiddingStrategy
  ]);

  return {
    errorMessage,
    submitAttempts,
    setSubmitAttempts,
    isSubmitting,
    handleSubmit,
    handleCancel
  };
}
