import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Icon,
  Message,
  Modal
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import InputWithMaxUTF8Size from "../InputWithMaxUTF8Size";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";

import { sendGAEvent } from "../GA";
import { AMPD_ATTRIBUTION_BEHAVIOR } from "Common/utils/amazon";

// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignsRequest,
  UpdateCampaignsReply,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { UpdateCampaignNameAction } from "Common/proto/googleAdsPb/campaign_pb";
import { UpdateAdGroupNameAction } from "Common/proto/googleAdsPb/adGroup_pb";
import { UpdateAdTrackingAction } from "Common/proto/googleAdsPb/ad_pb";
import { extractErrorMessage } from "Common/errors/error";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { collectAdTrackingVariables } from "./AmazonAttributionEditButton";
import { useSessionSite } from "../../queries/useSessionSite";

const CampaignNameEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => {
          sendGAEvent(gaCategory, "Click Edit Campaign Name", siteAlias);
          setModalOpen(true);
        }}
      >
        <SimpleTooltip tooltip="Edit Campaign Name">
          <Icon name="pencil" style={{ cursor: "pointer" }} />
        </SimpleTooltip>
      </Flex>
      {modalOpen && (
        <CampaignNameEditModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

const CampaignNameEditModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const { siteFeatures } = useSessionSite();
  const scrubCampaignNamesAndAddId = siteFeatures.scrubCampaignNamesWithId;

  const {
    customerId,
    campaignId,
    campaignName,
    adGroupId,
    adId,
    attributionAdvertiser,
    attributionBehavior,
    attributionCustomTags,
    customTargetURLPathAndQuery,
    marketplaceInfo,
    asin,
    finalURL,
    trackingURLTemplate
  } = itemizedCampaignConfiguration;

  const [modalOpen, setModalOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const [editCampaignName, setEditCampaignName] = useState(campaignName);
  const [updateAttribution, setUpdateAttribution] = useState(false);

  const [editTrackingURLTemplate, setEditTrackingURLTemplate] = useState("");
  const [
    editTrackingURLTemplateLoading,
    setEditTrackingURLTemplateLoading
  ] = useState(false);

  useEffect(() => {
    setEditTrackingURLTemplateLoading(true);
    collectAdTrackingVariables({
      siteAlias,
      campaignId,
      campaignName: editCampaignName,
      adGroupId,
      adGroupName: editCampaignName,
      attributionAdvertiser,
      attributionBehavior,
      attributionCustomTags,
      marketplaceInfo,
      customTargetURLPathAndQuery,
      previousFinalURL: finalURL,
      asin,
      stripIdsFromNames: scrubCampaignNamesAndAddId
    })
      .then(({ trackingURLTemplate }) => {
        setEditTrackingURLTemplate(trackingURLTemplate);
      })
      .finally(() => setEditTrackingURLTemplateLoading(false));
  }, [
    siteAlias,
    attributionAdvertiser,
    attributionBehavior,
    attributionCustomTags,
    customTargetURLPathAndQuery,
    marketplaceInfo,
    finalURL,
    asin,
    editCampaignName,
    scrubCampaignNamesAndAddId,
    campaignId,
    adGroupId
  ]);

  const handleUpdateCampaignName = async () => {
    if (customerId && campaignId && adGroupId && adId) {
      setIsSubmitting(true);

      try {
        const doUpdateAttribution =
          attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR &&
          updateAttribution;

        await sendUpdateCampaignName(
          siteAlias,
          gaCategory,
          customerId,
          campaignId,
          adGroupId,
          adId,
          editCampaignName,
          doUpdateAttribution ? editTrackingURLTemplate : "",
          scrubCampaignNamesAndAddId
        );

        if (refetchCampaignConfiguration) {
          await refetchCampaignConfiguration(String(campaignId));
        }

        setIsSubmitting(false);
        if (onClose) {
          onClose();
        }
      } catch (e) {
        const message = extractErrorMessage(e) || "Server error";

        console.error(e); // log to datadog

        setErrorMessage(message);

        // Since submitAttempts is used as the key of the OneClickButton, changing it
        // will create a new button instance and re-enable it.
        setSubmitAttempts(submitAttempts + 1);
        setIsSubmitting(false);
      }
    }
  };

  const updateEnabled = useMemo(() => {
    return (
      editCampaignName !== campaignName ||
      (trackingURLTemplate && editTrackingURLTemplate !== trackingURLTemplate)
    );
  }, [
    campaignName,
    editCampaignName,
    trackingURLTemplate,
    editTrackingURLTemplate
  ]);

  const handleCampaignNameChange = (value: string) => {
    value = value.trim();
    setEditCampaignName(value);
    setErrorMessage("");

    if (
      scrubCampaignNamesAndAddId &&
      attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR &&
      !!trackingURLTemplate
    ) {
      setUpdateAttribution(true);
    }
  };

  const handleUpdateAttributionChange = (
    _e: React.FormEvent<HTMLInputElement>,
    { checked }: CheckboxProps
  ) => {
    setUpdateAttribution(!!checked);
  };

  const handleModalClose = () => {
    setErrorMessage("");
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const usingAttribution =
    attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR &&
    !!trackingURLTemplate &&
    !!editTrackingURLTemplate &&
    !editTrackingURLTemplateLoading;

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
      size="large"
    >
      <Modal.Header>Change Campaign Name</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Field>
            <InputWithMaxUTF8Size
              style={{ width: "100%" }}
              text={editCampaignName}
              placeholder="Enter Campaign Name"
              onTextChange={handleCampaignNameChange}
              onTextAccept={handleCampaignNameChange}
              hideAnnotation={true}
              autofocus={true}
            />
          </Form.Field>
          {attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR &&
            !!trackingURLTemplate && (
              <Form.Field>
                <Checkbox
                  style={{ marginLeft: "2em" }}
                  disabled={
                    trackingURLTemplate === editTrackingURLTemplate ||
                    editTrackingURLTemplateLoading
                  }
                  label="Also update Amazon Attribution URL with name change (optional)"
                  checked={updateAttribution}
                  onChange={handleUpdateAttributionChange}
                />
              </Form.Field>
            )}
        </Form>
        {updateEnabled &&
          usingAttribution &&
          trackingURLTemplate === editTrackingURLTemplate && (
            <Message style={{ marginLeft: "2em" }} positive>
              No attribution URL change needed (only the first 200 characters of
              name are used in attribution URL)
            </Message>
          )}
        {updateEnabled &&
          usingAttribution &&
          trackingURLTemplate !== editTrackingURLTemplate && (
            <Message style={{ marginLeft: "2em" }} warning>
              <Icon name="warning sign" color="yellow" />
              Updating attribution URL may trigger an automatic Google Ads
              review and Amazon may withhold attribution data for a day or so.
            </Message>
          )}
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          key={submitAttempts}
          primary
          onClick={handleUpdateCampaignName}
          loading={isSubmitting}
          disabled={!updateEnabled || editTrackingURLTemplateLoading}
        >
          Update
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

// Updates the campaign name and, if the campaign is an Ampd created campaign,
// the associated ad group name and the trackingURLTemplate.
export async function sendUpdateCampaignName(
  siteAlias: string,
  gaCategory: string,
  customerId: number,
  campaignId: number,
  adGroupId: number,
  adId: number,
  campaignName: string,
  trackingURLTemplate: string,
  scrubCampaignNamesAndAddId?: boolean
): Promise<UpdateCampaignsReply.AsObject | null> {
  if (!siteAlias || !customerId || !campaignId || !campaignName) {
    return null;
  }

  let label = "[campaign name]";

  const campaignNameAction = new UpdateCampaignAction();

  const updateCampaignName = new UpdateCampaignNameAction();
  updateCampaignName.setCampaignId(String(campaignId));
  updateCampaignName.setNewName(campaignName);

  campaignNameAction.setUpdateCampaignName(updateCampaignName);
  if (scrubCampaignNamesAndAddId) {
    campaignNameAction.setScrubNamesAndAddId(true);
  }

  let adGroupNameAction = null;
  if (adGroupId) {
    label = label + "[ad group name] ";

    adGroupNameAction = new UpdateCampaignAction();

    const updateAdGroupName = new UpdateAdGroupNameAction();
    updateAdGroupName.setCampaignId(String(campaignId));
    updateAdGroupName.setAdGroupId(String(adGroupId));
    updateAdGroupName.setNewName(campaignName); // Using the same name

    adGroupNameAction.setUpdateAdGroupName(updateAdGroupName);
    if (scrubCampaignNamesAndAddId) {
      adGroupNameAction.setScrubNamesAndAddId(true);
    }
  }

  let trackingUrlTemplateAction = null;
  if (adGroupId && adId && trackingURLTemplate) {
    label = label + "[tracking URL template] ";

    trackingUrlTemplateAction = new UpdateCampaignAction();

    const updateAdTracking = new UpdateAdTrackingAction();
    updateAdTracking.setCampaignId(String(campaignId));
    updateAdTracking.setAdGroupId(String(adGroupId));
    updateAdTracking.setAdId(String(adId));
    updateAdTracking.setUpdateTrackingUrlTemplate(true);
    updateAdTracking.setTrackingUrlTemplate(trackingURLTemplate);

    trackingUrlTemplateAction.setUpdateAdTracking(updateAdTracking);
  }

  sendGAEvent(gaCategory, "Update Campaign Name", siteAlias, label);

  const updateReq = proto.set(new UpdateCampaignsRequest(), {
    siteAlias,
    customerId: String(customerId),
    actions: [
      campaignNameAction,
      adGroupNameAction,
      trackingUrlTemplateAction
    ].filter(Boolean)
  });

  const reply = await GRPCWebClient.updateCampaigns(updateReq, null);
  return reply.toObject();
}

export default CampaignNameEditButton;
