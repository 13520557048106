// source: proto/edge/grpcwebPb/grpcweb_PerformanceMetrics.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_amazon_pb = require('../../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
var proto_common_datetime_pb = require('../../../proto/common/datetime_pb.js');
goog.object.extend(proto, proto_common_datetime_pb);
goog.exportSymbol('proto.grpcwebPb.CategoryRanks', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAsinPerformanceMetricsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries', null, global);
goog.exportSymbol('proto.grpcwebPb.GetAsinPerformanceMetricsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSitePerformanceMetricsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSitePerformanceMetricsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.PerformanceMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.PerformanceMetrics.AmazonAds', null, global);
goog.exportSymbol('proto.grpcwebPb.PerformanceMetrics.GoogleAds', null, global);
goog.exportSymbol('proto.grpcwebPb.PerformanceMetrics.Organics', null, global);
goog.exportSymbol('proto.grpcwebPb.PerformanceMetrics.Ranks', null, global);
goog.exportSymbol('proto.grpcwebPb.PerformanceMetrics.Seller', null, global);
goog.exportSymbol('proto.grpcwebPb.RequestedPerformanceMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.RequestedPerformanceMetrics.Options', null, global);
goog.exportSymbol('proto.grpcwebPb.SearchTermRanks', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAsinPerformanceMetricsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAsinPerformanceMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAsinPerformanceMetricsRequest.displayName = 'proto.grpcwebPb.GetAsinPerformanceMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAsinPerformanceMetricsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAsinPerformanceMetricsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.displayName = 'proto.grpcwebPb.GetAsinPerformanceMetricsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.displayName = 'proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.displayName = 'proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.displayName = 'proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetSitePerformanceMetricsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetSitePerformanceMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSitePerformanceMetricsRequest.displayName = 'proto.grpcwebPb.GetSitePerformanceMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetSitePerformanceMetricsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSitePerformanceMetricsReply.displayName = 'proto.grpcwebPb.GetSitePerformanceMetricsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.displayName = 'proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.displayName = 'proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.displayName = 'proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.displayName = 'proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.RequestedPerformanceMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.RequestedPerformanceMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.RequestedPerformanceMetrics.displayName = 'proto.grpcwebPb.RequestedPerformanceMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PerformanceMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.PerformanceMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PerformanceMetrics.displayName = 'proto.grpcwebPb.PerformanceMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.PerformanceMetrics.GoogleAds.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.PerformanceMetrics.GoogleAds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PerformanceMetrics.GoogleAds.displayName = 'proto.grpcwebPb.PerformanceMetrics.GoogleAds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.PerformanceMetrics.AmazonAds.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.PerformanceMetrics.AmazonAds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PerformanceMetrics.AmazonAds.displayName = 'proto.grpcwebPb.PerformanceMetrics.AmazonAds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PerformanceMetrics.Seller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.PerformanceMetrics.Seller.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.PerformanceMetrics.Seller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PerformanceMetrics.Seller.displayName = 'proto.grpcwebPb.PerformanceMetrics.Seller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PerformanceMetrics.Organics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.PerformanceMetrics.Organics.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.PerformanceMetrics.Organics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PerformanceMetrics.Organics.displayName = 'proto.grpcwebPb.PerformanceMetrics.Organics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.PerformanceMetrics.Ranks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.PerformanceMetrics.Ranks.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.PerformanceMetrics.Ranks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.PerformanceMetrics.Ranks.displayName = 'proto.grpcwebPb.PerformanceMetrics.Ranks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.SearchTermRanks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.SearchTermRanks.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.SearchTermRanks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.SearchTermRanks.displayName = 'proto.grpcwebPb.SearchTermRanks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CategoryRanks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.CategoryRanks.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.CategoryRanks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CategoryRanks.displayName = 'proto.grpcwebPb.CategoryRanks';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAsinPerformanceMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    metricsInclusionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAsinPerformanceMetricsRequest;
  return proto.grpcwebPb.GetAsinPerformanceMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMetricsInclusions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAsinPerformanceMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getMetricsInclusionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.DateRangeV2 date_range = 2;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 2));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RequestedPerformanceMetrics.Options metrics_inclusions = 3;
 * @return {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.getMetricsInclusionsList = function() {
  return /** @type {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.setMetricsInclusionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.grpcwebPb.RequestedPerformanceMetrics.Options} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.addMetricsInclusions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsRequest.prototype.clearMetricsInclusionsList = function() {
  return this.setMetricsInclusionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    asinMetricsList: jspb.Message.toObjectList(msg.getAsinMetricsList(),
    proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAsinPerformanceMetricsReply;
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics;
      reader.readMessage(value,proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.deserializeBinaryFromReader);
      msg.addAsinMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getAsinMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    asin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateRangeAggregates: (f = msg.getDateRangeAggregates()) && proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.toObject(includeInstance, f),
    timeseries: (f = msg.getTimeseries()) && proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics;
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates;
      reader.readMessage(value,proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.deserializeBinaryFromReader);
      msg.setDateRangeAggregates(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries;
      reader.readMessage(value,proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.deserializeBinaryFromReader);
      msg.setTimeseries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDateRangeAggregates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.serializeBinaryToWriter
    );
  }
  f = message.getTimeseries();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    googleAds: (f = msg.getGoogleAds()) && proto.grpcwebPb.PerformanceMetrics.GoogleAds.toObject(includeInstance, f),
    amazonAds: (f = msg.getAmazonAds()) && proto.grpcwebPb.PerformanceMetrics.AmazonAds.toObject(includeInstance, f),
    seller: (f = msg.getSeller()) && proto.grpcwebPb.PerformanceMetrics.Seller.toObject(includeInstance, f),
    organics: (f = msg.getOrganics()) && proto.grpcwebPb.PerformanceMetrics.Organics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates;
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.PerformanceMetrics.GoogleAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.PerformanceMetrics.AmazonAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinaryFromReader);
      msg.setAmazonAds(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.PerformanceMetrics.Seller;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.PerformanceMetrics.Organics;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinaryFromReader);
      msg.setOrganics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.PerformanceMetrics.GoogleAds.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.PerformanceMetrics.AmazonAds.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.PerformanceMetrics.Seller.serializeBinaryToWriter
    );
  }
  f = message.getOrganics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.PerformanceMetrics.Organics.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.DateRangeV2 date_range = 1;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 1));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PerformanceMetrics.GoogleAds google_ads = 2;
 * @return {?proto.grpcwebPb.PerformanceMetrics.GoogleAds}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.getGoogleAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.GoogleAds, 2));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.GoogleAds|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.setGoogleAds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PerformanceMetrics.AmazonAds amazon_ads = 3;
 * @return {?proto.grpcwebPb.PerformanceMetrics.AmazonAds}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.getAmazonAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.AmazonAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.AmazonAds, 3));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.AmazonAds|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.setAmazonAds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.clearAmazonAds = function() {
  return this.setAmazonAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.hasAmazonAds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PerformanceMetrics.Seller seller = 4;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Seller}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.getSeller = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Seller} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Seller, 4));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Seller|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PerformanceMetrics.Organics organics = 5;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Organics}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.getOrganics = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Organics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Organics, 5));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Organics|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.setOrganics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.clearOrganics = function() {
  return this.setOrganics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates.prototype.hasOrganics = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.toObject = function(includeInstance, msg) {
  var f, obj = {
    unixDatesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    googleAds: (f = msg.getGoogleAds()) && proto.grpcwebPb.PerformanceMetrics.GoogleAds.toObject(includeInstance, f),
    amazonAds: (f = msg.getAmazonAds()) && proto.grpcwebPb.PerformanceMetrics.AmazonAds.toObject(includeInstance, f),
    seller: (f = msg.getSeller()) && proto.grpcwebPb.PerformanceMetrics.Seller.toObject(includeInstance, f),
    organics: (f = msg.getOrganics()) && proto.grpcwebPb.PerformanceMetrics.Organics.toObject(includeInstance, f),
    ranks: (f = msg.getRanks()) && proto.grpcwebPb.PerformanceMetrics.Ranks.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries;
  return proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnixDates(values[i]);
      }
      break;
    case 2:
      var value = new proto.grpcwebPb.PerformanceMetrics.GoogleAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.PerformanceMetrics.AmazonAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinaryFromReader);
      msg.setAmazonAds(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.PerformanceMetrics.Seller;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.PerformanceMetrics.Organics;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinaryFromReader);
      msg.setOrganics(value);
      break;
    case 6:
      var value = new proto.grpcwebPb.PerformanceMetrics.Ranks;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Ranks.deserializeBinaryFromReader);
      msg.setRanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnixDatesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.PerformanceMetrics.GoogleAds.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.PerformanceMetrics.AmazonAds.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.PerformanceMetrics.Seller.serializeBinaryToWriter
    );
  }
  f = message.getOrganics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.PerformanceMetrics.Organics.serializeBinaryToWriter
    );
  }
  f = message.getRanks();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grpcwebPb.PerformanceMetrics.Ranks.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 unix_dates = 1;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.getUnixDatesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.setUnixDatesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.addUnixDates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.clearUnixDatesList = function() {
  return this.setUnixDatesList([]);
};


/**
 * optional PerformanceMetrics.GoogleAds google_ads = 2;
 * @return {?proto.grpcwebPb.PerformanceMetrics.GoogleAds}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.getGoogleAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.GoogleAds, 2));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.GoogleAds|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.setGoogleAds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PerformanceMetrics.AmazonAds amazon_ads = 3;
 * @return {?proto.grpcwebPb.PerformanceMetrics.AmazonAds}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.getAmazonAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.AmazonAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.AmazonAds, 3));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.AmazonAds|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.setAmazonAds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.clearAmazonAds = function() {
  return this.setAmazonAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.hasAmazonAds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PerformanceMetrics.Seller seller = 4;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Seller}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.getSeller = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Seller} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Seller, 4));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Seller|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PerformanceMetrics.Organics organics = 5;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Organics}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.getOrganics = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Organics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Organics, 5));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Organics|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.setOrganics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.clearOrganics = function() {
  return this.setOrganics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.hasOrganics = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PerformanceMetrics.Ranks ranks = 6;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Ranks}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.getRanks = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Ranks} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Ranks, 6));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Ranks|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.setRanks = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.clearRanks = function() {
  return this.setRanks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries.prototype.hasRanks = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string asin = 1;
 * @return {string}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Marketplace.Option marketplace = 2;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional DateRangeAggregates date_range_aggregates = 3;
 * @return {?proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.getDateRangeAggregates = function() {
  return /** @type{?proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates, 3));
};


/**
 * @param {?proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.DateRangeAggregates|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.setDateRangeAggregates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.clearDateRangeAggregates = function() {
  return this.setDateRangeAggregates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.hasDateRangeAggregates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timeseries timeseries = 4;
 * @return {?proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.getTimeseries = function() {
  return /** @type{?proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries, 4));
};


/**
 * @param {?proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.Timeseries|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.setTimeseries = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.clearTimeseries = function() {
  return this.setTimeseries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics.prototype.hasTimeseries = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.DateRangeV2 date_range = 1;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 1));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AsinMetrics asin_metrics = 2;
 * @return {!Array<!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics>}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.getAsinMetricsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics, 2));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics>} value
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} returns this
*/
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.setAsinMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics}
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.addAsinMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpcwebPb.GetAsinPerformanceMetricsReply.AsinMetrics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetAsinPerformanceMetricsReply} returns this
 */
proto.grpcwebPb.GetAsinPerformanceMetricsReply.prototype.clearAsinMetricsList = function() {
  return this.setAsinMetricsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSitePerformanceMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    metricsInclusionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSitePerformanceMetricsRequest;
  return proto.grpcwebPb.GetSitePerformanceMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMetricsInclusions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSitePerformanceMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getMetricsInclusionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.DateRangeV2 date_range = 2;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 2));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RequestedPerformanceMetrics.Options metrics_inclusions = 3;
 * @return {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>}
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.getMetricsInclusionsList = function() {
  return /** @type {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.RequestedPerformanceMetrics.Options>} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.setMetricsInclusionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.grpcwebPb.RequestedPerformanceMetrics.Options} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.addMetricsInclusions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsRequest} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsRequest.prototype.clearMetricsInclusionsList = function() {
  return this.setMetricsInclusionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteMetrics: (f = msg.getSiteMetrics()) && proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSitePerformanceMetricsReply;
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics;
      reader.readMessage(value,proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.deserializeBinaryFromReader);
      msg.setSiteMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSitePerformanceMetricsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteMetrics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateRangeAggregates: (f = msg.getDateRangeAggregates()) && proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.toObject(includeInstance, f),
    timeseries: (f = msg.getTimeseries()) && proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics;
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates;
      reader.readMessage(value,proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.deserializeBinaryFromReader);
      msg.setDateRangeAggregates(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries;
      reader.readMessage(value,proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.deserializeBinaryFromReader);
      msg.setTimeseries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateRangeAggregates();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.serializeBinaryToWriter
    );
  }
  f = message.getTimeseries();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    googleAds: (f = msg.getGoogleAds()) && proto.grpcwebPb.PerformanceMetrics.GoogleAds.toObject(includeInstance, f),
    amazonAds: (f = msg.getAmazonAds()) && proto.grpcwebPb.PerformanceMetrics.AmazonAds.toObject(includeInstance, f),
    seller: (f = msg.getSeller()) && proto.grpcwebPb.PerformanceMetrics.Seller.toObject(includeInstance, f),
    organics: (f = msg.getOrganics()) && proto.grpcwebPb.PerformanceMetrics.Organics.toObject(includeInstance, f),
    brand: (f = msg.getBrand()) && proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates;
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.PerformanceMetrics.GoogleAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.PerformanceMetrics.AmazonAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinaryFromReader);
      msg.setAmazonAds(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.PerformanceMetrics.Seller;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.PerformanceMetrics.Organics;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinaryFromReader);
      msg.setOrganics(value);
      break;
    case 6:
      var value = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics;
      reader.readMessage(value,proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.PerformanceMetrics.GoogleAds.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.PerformanceMetrics.AmazonAds.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.PerformanceMetrics.Seller.serializeBinaryToWriter
    );
  }
  f = message.getOrganics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.PerformanceMetrics.Organics.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.DateRangeV2 date_range = 1;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 1));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PerformanceMetrics.GoogleAds google_ads = 2;
 * @return {?proto.grpcwebPb.PerformanceMetrics.GoogleAds}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.getGoogleAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.GoogleAds, 2));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.GoogleAds|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.setGoogleAds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PerformanceMetrics.AmazonAds amazon_ads = 3;
 * @return {?proto.grpcwebPb.PerformanceMetrics.AmazonAds}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.getAmazonAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.AmazonAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.AmazonAds, 3));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.AmazonAds|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.setAmazonAds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.clearAmazonAds = function() {
  return this.setAmazonAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.hasAmazonAds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PerformanceMetrics.Seller seller = 4;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Seller}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.getSeller = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Seller} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Seller, 4));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Seller|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PerformanceMetrics.Organics organics = 5;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Organics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.getOrganics = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Organics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Organics, 5));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Organics|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.setOrganics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.clearOrganics = function() {
  return this.setOrganics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.hasOrganics = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BrandMetrics brand = 6;
 * @return {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.getBrand = function() {
  return /** @type{?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics, 6));
};


/**
 * @param {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.toObject = function(includeInstance, msg) {
  var f, obj = {
    unixDatesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    googleAds: (f = msg.getGoogleAds()) && proto.grpcwebPb.PerformanceMetrics.GoogleAds.toObject(includeInstance, f),
    amazonAds: (f = msg.getAmazonAds()) && proto.grpcwebPb.PerformanceMetrics.AmazonAds.toObject(includeInstance, f),
    seller: (f = msg.getSeller()) && proto.grpcwebPb.PerformanceMetrics.Seller.toObject(includeInstance, f),
    organics: (f = msg.getOrganics()) && proto.grpcwebPb.PerformanceMetrics.Organics.toObject(includeInstance, f),
    brand: (f = msg.getBrand()) && proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries;
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnixDates(values[i]);
      }
      break;
    case 2:
      var value = new proto.grpcwebPb.PerformanceMetrics.GoogleAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.PerformanceMetrics.AmazonAds;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinaryFromReader);
      msg.setAmazonAds(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.PerformanceMetrics.Seller;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.PerformanceMetrics.Organics;
      reader.readMessage(value,proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinaryFromReader);
      msg.setOrganics(value);
      break;
    case 6:
      var value = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics;
      reader.readMessage(value,proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnixDatesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpcwebPb.PerformanceMetrics.GoogleAds.serializeBinaryToWriter
    );
  }
  f = message.getAmazonAds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.PerformanceMetrics.AmazonAds.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpcwebPb.PerformanceMetrics.Seller.serializeBinaryToWriter
    );
  }
  f = message.getOrganics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.PerformanceMetrics.Organics.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 unix_dates = 1;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.getUnixDatesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.setUnixDatesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.addUnixDates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.clearUnixDatesList = function() {
  return this.setUnixDatesList([]);
};


/**
 * optional PerformanceMetrics.GoogleAds google_ads = 2;
 * @return {?proto.grpcwebPb.PerformanceMetrics.GoogleAds}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.getGoogleAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.GoogleAds, 2));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.GoogleAds|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.setGoogleAds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PerformanceMetrics.AmazonAds amazon_ads = 3;
 * @return {?proto.grpcwebPb.PerformanceMetrics.AmazonAds}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.getAmazonAds = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.AmazonAds} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.AmazonAds, 3));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.AmazonAds|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.setAmazonAds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.clearAmazonAds = function() {
  return this.setAmazonAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.hasAmazonAds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PerformanceMetrics.Seller seller = 4;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Seller}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.getSeller = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Seller} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Seller, 4));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Seller|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PerformanceMetrics.Organics organics = 5;
 * @return {?proto.grpcwebPb.PerformanceMetrics.Organics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.getOrganics = function() {
  return /** @type{?proto.grpcwebPb.PerformanceMetrics.Organics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.PerformanceMetrics.Organics, 5));
};


/**
 * @param {?proto.grpcwebPb.PerformanceMetrics.Organics|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.setOrganics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.clearOrganics = function() {
  return this.setOrganics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.hasOrganics = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BrandMetrics brand = 6;
 * @return {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.getBrand = function() {
  return /** @type{?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics, 6));
};


/**
 * @param {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectedRevenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    projectedAnnualizedRevenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    projectedRoasList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    projectedAnnualizedRoasList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics;
  return proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectedRevenue(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectedAnnualizedRevenue(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectedRoas(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectedAnnualizedRoas(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectedRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getProjectedAnnualizedRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getProjectedRoasList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getProjectedAnnualizedRoasList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated double projected_revenue = 2;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.getProjectedRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.setProjectedRevenueList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.addProjectedRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.clearProjectedRevenueList = function() {
  return this.setProjectedRevenueList([]);
};


/**
 * repeated double projected_annualized_revenue = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.getProjectedAnnualizedRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.setProjectedAnnualizedRevenueList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.addProjectedAnnualizedRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.clearProjectedAnnualizedRevenueList = function() {
  return this.setProjectedAnnualizedRevenueList([]);
};


/**
 * repeated double projected_roas = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.getProjectedRoasList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.setProjectedRoasList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.addProjectedRoas = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.clearProjectedRoasList = function() {
  return this.setProjectedRoasList([]);
};


/**
 * repeated double projected_annualized_roas = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.getProjectedAnnualizedRoasList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.setProjectedAnnualizedRoasList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.addProjectedAnnualizedRoas = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.BrandMetrics.prototype.clearProjectedAnnualizedRoasList = function() {
  return this.setProjectedAnnualizedRoasList([]);
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DateRangeAggregates date_range_aggregates = 2;
 * @return {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.getDateRangeAggregates = function() {
  return /** @type{?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates, 2));
};


/**
 * @param {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.DateRangeAggregates|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.setDateRangeAggregates = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.clearDateRangeAggregates = function() {
  return this.setDateRangeAggregates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.hasDateRangeAggregates = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timeseries timeseries = 3;
 * @return {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.getTimeseries = function() {
  return /** @type{?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries, 3));
};


/**
 * @param {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.Timeseries|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.setTimeseries = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.clearTimeseries = function() {
  return this.setTimeseries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics.prototype.hasTimeseries = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SiteMetrics site_metrics = 1;
 * @return {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.prototype.getSiteMetrics = function() {
  return /** @type{?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics, 1));
};


/**
 * @param {?proto.grpcwebPb.GetSitePerformanceMetricsReply.SiteMetrics|undefined} value
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply} returns this
*/
proto.grpcwebPb.GetSitePerformanceMetricsReply.prototype.setSiteMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSitePerformanceMetricsReply} returns this
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.prototype.clearSiteMetrics = function() {
  return this.setSiteMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSitePerformanceMetricsReply.prototype.hasSiteMetrics = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.RequestedPerformanceMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.RequestedPerformanceMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.RequestedPerformanceMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.RequestedPerformanceMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.RequestedPerformanceMetrics}
 */
proto.grpcwebPb.RequestedPerformanceMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.RequestedPerformanceMetrics;
  return proto.grpcwebPb.RequestedPerformanceMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.RequestedPerformanceMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.RequestedPerformanceMetrics}
 */
proto.grpcwebPb.RequestedPerformanceMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.RequestedPerformanceMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.RequestedPerformanceMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.RequestedPerformanceMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.RequestedPerformanceMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.RequestedPerformanceMetrics.Options = {
  UNKNOWN: 0,
  AGGREGATES: 1,
  TIMESERIES: 2,
  RANKS: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PerformanceMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PerformanceMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PerformanceMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PerformanceMetrics}
 */
proto.grpcwebPb.PerformanceMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PerformanceMetrics;
  return proto.grpcwebPb.PerformanceMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PerformanceMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PerformanceMetrics}
 */
proto.grpcwebPb.PerformanceMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PerformanceMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PerformanceMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PerformanceMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.repeatedFields_ = [3,4,5,6,7,8,30,10,11,33,34,35,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,31,29,32];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PerformanceMetrics.GoogleAds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unconvertedRevenueCurrencyCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    impressionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    clicksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    conversionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    unitsSoldList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    addToCartsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    costList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    adjustedCostList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 30)) == null ? undefined : f,
    revenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    brandReferralBonusList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 11)) == null ? undefined : f,
    newToBrandConversionsList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    newToBrandRevenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 34)) == null ? undefined : f,
    costOfAcquisitionList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 35)) == null ? undefined : f,
    clickShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 12)) == null ? undefined : f,
    topImpressionPercentageList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 13)) == null ? undefined : f,
    absoluteTopImpressionPercentageList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 14)) == null ? undefined : f,
    impressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 15)) == null ? undefined : f,
    topImpressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 16)) == null ? undefined : f,
    absoluteTopImpressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 17)) == null ? undefined : f,
    lostImpressionShareLowRankList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 18)) == null ? undefined : f,
    lostTopImpressionShareLowRankList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 19)) == null ? undefined : f,
    lostAbsoluteTopImpressionShareLowRankList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 20)) == null ? undefined : f,
    lostImpressionShareLowBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 21)) == null ? undefined : f,
    lostTopImpressionShareLowBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 22)) == null ? undefined : f,
    lostAbsoluteTopImpressionShareLowBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 23)) == null ? undefined : f,
    googleAcosList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 24)) == null ? undefined : f,
    googleAacosList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 25)) == null ? undefined : f,
    googleRoasList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 26)) == null ? undefined : f,
    cartRateList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 27)) == null ? undefined : f,
    costPerClickList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 28)) == null ? undefined : f,
    adjustedCostPerClickList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 31)) == null ? undefined : f,
    clickThroughRateList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 29)) == null ? undefined : f,
    conversionRateList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 32)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PerformanceMetrics.GoogleAds;
  return proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnconvertedRevenueCurrencyCode(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addImpressions(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClicks(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversions(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnitsSold(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAddToCarts(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCost(values[i]);
      }
      break;
    case 30:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdjustedCost(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRevenue(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBrandReferralBonus(values[i]);
      }
      break;
    case 33:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNewToBrandConversions(values[i]);
      }
      break;
    case 34:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNewToBrandRevenue(values[i]);
      }
      break;
    case 35:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCostOfAcquisition(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClickShare(values[i]);
      }
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTopImpressionPercentage(values[i]);
      }
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAbsoluteTopImpressionPercentage(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addImpressionShare(values[i]);
      }
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTopImpressionShare(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAbsoluteTopImpressionShare(values[i]);
      }
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostImpressionShareLowRank(values[i]);
      }
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostTopImpressionShareLowRank(values[i]);
      }
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostAbsoluteTopImpressionShareLowRank(values[i]);
      }
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostImpressionShareLowBudget(values[i]);
      }
      break;
    case 22:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostTopImpressionShareLowBudget(values[i]);
      }
      break;
    case 23:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostAbsoluteTopImpressionShareLowBudget(values[i]);
      }
      break;
    case 24:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGoogleAcos(values[i]);
      }
      break;
    case 25:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGoogleAacos(values[i]);
      }
      break;
    case 26:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGoogleRoas(values[i]);
      }
      break;
    case 27:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCartRate(values[i]);
      }
      break;
    case 28:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCostPerClick(values[i]);
      }
      break;
    case 31:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdjustedCostPerClick(values[i]);
      }
      break;
    case 29:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClickThroughRate(values[i]);
      }
      break;
    case 32:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversionRate(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PerformanceMetrics.GoogleAds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnconvertedRevenueCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImpressionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getClicksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getConversionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getUnitsSoldList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getAddToCartsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getCostList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getAdjustedCostList();
  if (f.length > 0) {
    writer.writePackedDouble(
      30,
      f
    );
  }
  f = message.getRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
  f = message.getBrandReferralBonusList();
  if (f.length > 0) {
    writer.writePackedDouble(
      11,
      f
    );
  }
  f = message.getNewToBrandConversionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      33,
      f
    );
  }
  f = message.getNewToBrandRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      34,
      f
    );
  }
  f = message.getCostOfAcquisitionList();
  if (f.length > 0) {
    writer.writePackedDouble(
      35,
      f
    );
  }
  f = message.getClickShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      12,
      f
    );
  }
  f = message.getTopImpressionPercentageList();
  if (f.length > 0) {
    writer.writePackedDouble(
      13,
      f
    );
  }
  f = message.getAbsoluteTopImpressionPercentageList();
  if (f.length > 0) {
    writer.writePackedDouble(
      14,
      f
    );
  }
  f = message.getImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      15,
      f
    );
  }
  f = message.getTopImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      16,
      f
    );
  }
  f = message.getAbsoluteTopImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      17,
      f
    );
  }
  f = message.getLostImpressionShareLowRankList();
  if (f.length > 0) {
    writer.writePackedDouble(
      18,
      f
    );
  }
  f = message.getLostTopImpressionShareLowRankList();
  if (f.length > 0) {
    writer.writePackedDouble(
      19,
      f
    );
  }
  f = message.getLostAbsoluteTopImpressionShareLowRankList();
  if (f.length > 0) {
    writer.writePackedDouble(
      20,
      f
    );
  }
  f = message.getLostImpressionShareLowBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      21,
      f
    );
  }
  f = message.getLostTopImpressionShareLowBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      22,
      f
    );
  }
  f = message.getLostAbsoluteTopImpressionShareLowBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      23,
      f
    );
  }
  f = message.getGoogleAcosList();
  if (f.length > 0) {
    writer.writePackedDouble(
      24,
      f
    );
  }
  f = message.getGoogleAacosList();
  if (f.length > 0) {
    writer.writePackedDouble(
      25,
      f
    );
  }
  f = message.getGoogleRoasList();
  if (f.length > 0) {
    writer.writePackedDouble(
      26,
      f
    );
  }
  f = message.getCartRateList();
  if (f.length > 0) {
    writer.writePackedDouble(
      27,
      f
    );
  }
  f = message.getCostPerClickList();
  if (f.length > 0) {
    writer.writePackedDouble(
      28,
      f
    );
  }
  f = message.getAdjustedCostPerClickList();
  if (f.length > 0) {
    writer.writePackedDouble(
      31,
      f
    );
  }
  f = message.getClickThroughRateList();
  if (f.length > 0) {
    writer.writePackedDouble(
      29,
      f
    );
  }
  f = message.getConversionRateList();
  if (f.length > 0) {
    writer.writePackedDouble(
      32,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unconverted_revenue_currency_code = 2;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getUnconvertedRevenueCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setUnconvertedRevenueCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 impressions = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getImpressionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setImpressionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addImpressions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearImpressionsList = function() {
  return this.setImpressionsList([]);
};


/**
 * repeated int64 clicks = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getClicksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setClicksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addClicks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearClicksList = function() {
  return this.setClicksList([]);
};


/**
 * repeated int64 conversions = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getConversionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setConversionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addConversions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearConversionsList = function() {
  return this.setConversionsList([]);
};


/**
 * repeated int64 units_sold = 6;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getUnitsSoldList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setUnitsSoldList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addUnitsSold = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearUnitsSoldList = function() {
  return this.setUnitsSoldList([]);
};


/**
 * repeated int64 add_to_carts = 7;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getAddToCartsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setAddToCartsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addAddToCarts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearAddToCartsList = function() {
  return this.setAddToCartsList([]);
};


/**
 * repeated double cost = 8;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getCostList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setCostList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addCost = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearCostList = function() {
  return this.setCostList([]);
};


/**
 * repeated double adjusted_cost = 30;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getAdjustedCostList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setAdjustedCostList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addAdjustedCost = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearAdjustedCostList = function() {
  return this.setAdjustedCostList([]);
};


/**
 * repeated double revenue = 10;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setRevenueList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearRevenueList = function() {
  return this.setRevenueList([]);
};


/**
 * repeated double brand_referral_bonus = 11;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getBrandReferralBonusList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setBrandReferralBonusList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addBrandReferralBonus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearBrandReferralBonusList = function() {
  return this.setBrandReferralBonusList([]);
};


/**
 * repeated int64 new_to_brand_conversions = 33;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getNewToBrandConversionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setNewToBrandConversionsList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addNewToBrandConversions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearNewToBrandConversionsList = function() {
  return this.setNewToBrandConversionsList([]);
};


/**
 * repeated double new_to_brand_revenue = 34;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getNewToBrandRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 34));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setNewToBrandRevenueList = function(value) {
  return jspb.Message.setField(this, 34, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addNewToBrandRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearNewToBrandRevenueList = function() {
  return this.setNewToBrandRevenueList([]);
};


/**
 * repeated double cost_of_acquisition = 35;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getCostOfAcquisitionList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 35));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setCostOfAcquisitionList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addCostOfAcquisition = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearCostOfAcquisitionList = function() {
  return this.setCostOfAcquisitionList([]);
};


/**
 * repeated double click_share = 12;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getClickShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setClickShareList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addClickShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearClickShareList = function() {
  return this.setClickShareList([]);
};


/**
 * repeated double top_impression_percentage = 13;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getTopImpressionPercentageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setTopImpressionPercentageList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addTopImpressionPercentage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearTopImpressionPercentageList = function() {
  return this.setTopImpressionPercentageList([]);
};


/**
 * repeated double absolute_top_impression_percentage = 14;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getAbsoluteTopImpressionPercentageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setAbsoluteTopImpressionPercentageList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addAbsoluteTopImpressionPercentage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearAbsoluteTopImpressionPercentageList = function() {
  return this.setAbsoluteTopImpressionPercentageList([]);
};


/**
 * repeated double impression_share = 15;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setImpressionShareList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearImpressionShareList = function() {
  return this.setImpressionShareList([]);
};


/**
 * repeated double top_impression_share = 16;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getTopImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setTopImpressionShareList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addTopImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearTopImpressionShareList = function() {
  return this.setTopImpressionShareList([]);
};


/**
 * repeated double absolute_top_impression_share = 17;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getAbsoluteTopImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setAbsoluteTopImpressionShareList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addAbsoluteTopImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearAbsoluteTopImpressionShareList = function() {
  return this.setAbsoluteTopImpressionShareList([]);
};


/**
 * repeated double lost_impression_share_low_rank = 18;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getLostImpressionShareLowRankList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setLostImpressionShareLowRankList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addLostImpressionShareLowRank = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearLostImpressionShareLowRankList = function() {
  return this.setLostImpressionShareLowRankList([]);
};


/**
 * repeated double lost_top_impression_share_low_rank = 19;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getLostTopImpressionShareLowRankList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setLostTopImpressionShareLowRankList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addLostTopImpressionShareLowRank = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearLostTopImpressionShareLowRankList = function() {
  return this.setLostTopImpressionShareLowRankList([]);
};


/**
 * repeated double lost_absolute_top_impression_share_low_rank = 20;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getLostAbsoluteTopImpressionShareLowRankList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setLostAbsoluteTopImpressionShareLowRankList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addLostAbsoluteTopImpressionShareLowRank = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearLostAbsoluteTopImpressionShareLowRankList = function() {
  return this.setLostAbsoluteTopImpressionShareLowRankList([]);
};


/**
 * repeated double lost_impression_share_low_budget = 21;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getLostImpressionShareLowBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setLostImpressionShareLowBudgetList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addLostImpressionShareLowBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearLostImpressionShareLowBudgetList = function() {
  return this.setLostImpressionShareLowBudgetList([]);
};


/**
 * repeated double lost_top_impression_share_low_budget = 22;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getLostTopImpressionShareLowBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 22));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setLostTopImpressionShareLowBudgetList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addLostTopImpressionShareLowBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearLostTopImpressionShareLowBudgetList = function() {
  return this.setLostTopImpressionShareLowBudgetList([]);
};


/**
 * repeated double lost_absolute_top_impression_share_low_budget = 23;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getLostAbsoluteTopImpressionShareLowBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 23));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setLostAbsoluteTopImpressionShareLowBudgetList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addLostAbsoluteTopImpressionShareLowBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearLostAbsoluteTopImpressionShareLowBudgetList = function() {
  return this.setLostAbsoluteTopImpressionShareLowBudgetList([]);
};


/**
 * repeated double google_acos = 24;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getGoogleAcosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 24));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setGoogleAcosList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addGoogleAcos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearGoogleAcosList = function() {
  return this.setGoogleAcosList([]);
};


/**
 * repeated double google_aacos = 25;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getGoogleAacosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 25));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setGoogleAacosList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addGoogleAacos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearGoogleAacosList = function() {
  return this.setGoogleAacosList([]);
};


/**
 * repeated double google_roas = 26;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getGoogleRoasList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 26));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setGoogleRoasList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addGoogleRoas = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearGoogleRoasList = function() {
  return this.setGoogleRoasList([]);
};


/**
 * repeated double cart_rate = 27;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getCartRateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 27));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setCartRateList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addCartRate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearCartRateList = function() {
  return this.setCartRateList([]);
};


/**
 * repeated double cost_per_click = 28;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getCostPerClickList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 28));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setCostPerClickList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addCostPerClick = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearCostPerClickList = function() {
  return this.setCostPerClickList([]);
};


/**
 * repeated double adjusted_cost_per_click = 31;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getAdjustedCostPerClickList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 31));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setAdjustedCostPerClickList = function(value) {
  return jspb.Message.setField(this, 31, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addAdjustedCostPerClick = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 31, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearAdjustedCostPerClickList = function() {
  return this.setAdjustedCostPerClickList([]);
};


/**
 * repeated double click_through_rate = 29;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getClickThroughRateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 29));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setClickThroughRateList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addClickThroughRate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearClickThroughRateList = function() {
  return this.setClickThroughRateList([]);
};


/**
 * repeated double conversion_rate = 32;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.getConversionRateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 32));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.setConversionRateList = function(value) {
  return jspb.Message.setField(this, 32, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.addConversionRate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 32, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.GoogleAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.GoogleAds.prototype.clearConversionRateList = function() {
  return this.setConversionRateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.repeatedFields_ = [3,4,5,6,7,8,9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PerformanceMetrics.AmazonAds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unconvertedCurrencyCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    impressionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    clicksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    conversionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    unitsSoldList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    costList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    revenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    amazonAcosList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    costPerClickList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    clickThroughRateList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PerformanceMetrics.AmazonAds;
  return proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnconvertedCurrencyCode(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addImpressions(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClicks(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversions(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnitsSold(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCost(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRevenue(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAmazonAcos(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCostPerClick(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClickThroughRate(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PerformanceMetrics.AmazonAds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnconvertedCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImpressionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getClicksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getConversionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getUnitsSoldList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getCostList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getAmazonAcosList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
  f = message.getCostPerClickList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
  f = message.getClickThroughRateList();
  if (f.length > 0) {
    writer.writePackedDouble(
      11,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unconverted_currency_code = 2;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getUnconvertedCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setUnconvertedCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 impressions = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getImpressionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setImpressionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addImpressions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearImpressionsList = function() {
  return this.setImpressionsList([]);
};


/**
 * repeated int64 clicks = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getClicksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setClicksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addClicks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearClicksList = function() {
  return this.setClicksList([]);
};


/**
 * repeated int64 conversions = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getConversionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setConversionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addConversions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearConversionsList = function() {
  return this.setConversionsList([]);
};


/**
 * repeated int64 units_sold = 6;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getUnitsSoldList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setUnitsSoldList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addUnitsSold = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearUnitsSoldList = function() {
  return this.setUnitsSoldList([]);
};


/**
 * repeated double cost = 7;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getCostList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setCostList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addCost = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearCostList = function() {
  return this.setCostList([]);
};


/**
 * repeated double revenue = 8;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setRevenueList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearRevenueList = function() {
  return this.setRevenueList([]);
};


/**
 * repeated double amazon_acos = 9;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getAmazonAcosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setAmazonAcosList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addAmazonAcos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearAmazonAcosList = function() {
  return this.setAmazonAcosList([]);
};


/**
 * repeated double cost_per_click = 10;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getCostPerClickList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setCostPerClickList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addCostPerClick = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearCostPerClickList = function() {
  return this.setCostPerClickList([]);
};


/**
 * repeated double click_through_rate = 11;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.getClickThroughRateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.setClickThroughRateList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.addClickThroughRate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.AmazonAds} returns this
 */
proto.grpcwebPb.PerformanceMetrics.AmazonAds.prototype.clearClickThroughRateList = function() {
  return this.setClickThroughRateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.PerformanceMetrics.Seller.repeatedFields_ = [3,4,5,6,7,9,10,11,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PerformanceMetrics.Seller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PerformanceMetrics.Seller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.Seller.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unconvertedCurrencyCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    revenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    averageUnitPriceList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    unitsSoldList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    orderCountList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    tacosList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    pageViewsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    mobileAppPageViewsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    browserPageViewsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    sessionsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    mobileAppSessionsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    browserSessionsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PerformanceMetrics.Seller;
  return proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PerformanceMetrics.Seller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnconvertedCurrencyCode(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRevenue(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAverageUnitPrice(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnitsSold(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderCount(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTacos(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPageViews(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMobileAppPageViews(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBrowserPageViews(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSessions(values[i]);
      }
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMobileAppSessions(values[i]);
      }
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBrowserSessions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PerformanceMetrics.Seller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PerformanceMetrics.Seller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.Seller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnconvertedCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getAverageUnitPriceList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getUnitsSoldList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getOrderCountList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getTacosList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getPageViewsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getMobileAppPageViewsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getBrowserPageViewsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getMobileAppSessionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      13,
      f
    );
  }
  f = message.getBrowserSessionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      14,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unconverted_currency_code = 2;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getUnconvertedCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setUnconvertedCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated double revenue = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setRevenueList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearRevenueList = function() {
  return this.setRevenueList([]);
};


/**
 * repeated double average_unit_price = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getAverageUnitPriceList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setAverageUnitPriceList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addAverageUnitPrice = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearAverageUnitPriceList = function() {
  return this.setAverageUnitPriceList([]);
};


/**
 * repeated int64 units_sold = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getUnitsSoldList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setUnitsSoldList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addUnitsSold = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearUnitsSoldList = function() {
  return this.setUnitsSoldList([]);
};


/**
 * repeated int64 order_count = 6;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getOrderCountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setOrderCountList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addOrderCount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearOrderCountList = function() {
  return this.setOrderCountList([]);
};


/**
 * repeated double tacos = 7;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getTacosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setTacosList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addTacos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearTacosList = function() {
  return this.setTacosList([]);
};


/**
 * repeated int64 page_views = 9;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getPageViewsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setPageViewsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addPageViews = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearPageViewsList = function() {
  return this.setPageViewsList([]);
};


/**
 * repeated int64 mobile_app_page_views = 10;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getMobileAppPageViewsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setMobileAppPageViewsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addMobileAppPageViews = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearMobileAppPageViewsList = function() {
  return this.setMobileAppPageViewsList([]);
};


/**
 * repeated int64 browser_page_views = 11;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getBrowserPageViewsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setBrowserPageViewsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addBrowserPageViews = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearBrowserPageViewsList = function() {
  return this.setBrowserPageViewsList([]);
};


/**
 * repeated int64 sessions = 12;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getSessionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setSessionsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addSessions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};


/**
 * repeated int64 mobile_app_sessions = 13;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getMobileAppSessionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setMobileAppSessionsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addMobileAppSessions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearMobileAppSessionsList = function() {
  return this.setMobileAppSessionsList([]);
};


/**
 * repeated int64 browser_sessions = 14;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.getBrowserSessionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.setBrowserSessionsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.addBrowserSessions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Seller} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Seller.prototype.clearBrowserSessionsList = function() {
  return this.setBrowserSessionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.PerformanceMetrics.Organics.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PerformanceMetrics.Organics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PerformanceMetrics.Organics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.Organics.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    revenueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    unitsSoldList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PerformanceMetrics.Organics;
  return proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PerformanceMetrics.Organics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRevenue(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnitsSold(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PerformanceMetrics.Organics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PerformanceMetrics.Organics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.Organics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRevenueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getUnitsSoldList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated double revenue = 2;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.getRevenueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.setRevenueList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.addRevenue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.clearRevenueList = function() {
  return this.setRevenueList([]);
};


/**
 * repeated int64 units_sold = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.getUnitsSoldList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.setUnitsSoldList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.addUnitsSold = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Organics} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Organics.prototype.clearUnitsSoldList = function() {
  return this.setUnitsSoldList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.PerformanceMetrics.Ranks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.PerformanceMetrics.Ranks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTermRanksList: jspb.Message.toObjectList(msg.getSearchTermRanksList(),
    proto.grpcwebPb.SearchTermRanks.toObject, includeInstance),
    categoryRanksList: jspb.Message.toObjectList(msg.getCategoryRanksList(),
    proto.grpcwebPb.CategoryRanks.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Ranks}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.PerformanceMetrics.Ranks;
  return proto.grpcwebPb.PerformanceMetrics.Ranks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.PerformanceMetrics.Ranks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Ranks}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.SearchTermRanks;
      reader.readMessage(value,proto.grpcwebPb.SearchTermRanks.deserializeBinaryFromReader);
      msg.addSearchTermRanks(value);
      break;
    case 2:
      var value = new proto.grpcwebPb.CategoryRanks;
      reader.readMessage(value,proto.grpcwebPb.CategoryRanks.deserializeBinaryFromReader);
      msg.addCategoryRanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.PerformanceMetrics.Ranks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.PerformanceMetrics.Ranks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTermRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.SearchTermRanks.serializeBinaryToWriter
    );
  }
  f = message.getCategoryRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpcwebPb.CategoryRanks.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SearchTermRanks search_term_ranks = 1;
 * @return {!Array<!proto.grpcwebPb.SearchTermRanks>}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.getSearchTermRanksList = function() {
  return /** @type{!Array<!proto.grpcwebPb.SearchTermRanks>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.SearchTermRanks, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.SearchTermRanks>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Ranks} returns this
*/
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.setSearchTermRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.SearchTermRanks=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.addSearchTermRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.SearchTermRanks, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Ranks} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.clearSearchTermRanksList = function() {
  return this.setSearchTermRanksList([]);
};


/**
 * repeated CategoryRanks category_ranks = 2;
 * @return {!Array<!proto.grpcwebPb.CategoryRanks>}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.getCategoryRanksList = function() {
  return /** @type{!Array<!proto.grpcwebPb.CategoryRanks>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.CategoryRanks, 2));
};


/**
 * @param {!Array<!proto.grpcwebPb.CategoryRanks>} value
 * @return {!proto.grpcwebPb.PerformanceMetrics.Ranks} returns this
*/
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.setCategoryRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpcwebPb.CategoryRanks=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CategoryRanks}
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.addCategoryRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpcwebPb.CategoryRanks, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.PerformanceMetrics.Ranks} returns this
 */
proto.grpcwebPb.PerformanceMetrics.Ranks.prototype.clearCategoryRanksList = function() {
  return this.setCategoryRanksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.SearchTermRanks.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.SearchTermRanks.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.SearchTermRanks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.SearchTermRanks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SearchTermRanks.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organicPositionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    sponsoredPositionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    allVariantsOrganicPositionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    allVariantsSponsoredPositionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    maxPositionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.SearchTermRanks}
 */
proto.grpcwebPb.SearchTermRanks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.SearchTermRanks;
  return proto.grpcwebPb.SearchTermRanks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.SearchTermRanks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.SearchTermRanks}
 */
proto.grpcwebPb.SearchTermRanks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganicPositions(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSponsoredPositions(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllVariantsOrganicPositions(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllVariantsSponsoredPositions(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMaxPositions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.SearchTermRanks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.SearchTermRanks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.SearchTermRanks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.SearchTermRanks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganicPositionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getSponsoredPositionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getAllVariantsOrganicPositionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getAllVariantsSponsoredPositionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getMaxPositionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.grpcwebPb.SearchTermRanks.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 organic_positions = 2;
 * @return {!Array<number>}
 */
proto.grpcwebPb.SearchTermRanks.prototype.getOrganicPositionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.setOrganicPositionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.addOrganicPositions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.clearOrganicPositionsList = function() {
  return this.setOrganicPositionsList([]);
};


/**
 * repeated int32 sponsored_positions = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.SearchTermRanks.prototype.getSponsoredPositionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.setSponsoredPositionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.addSponsoredPositions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.clearSponsoredPositionsList = function() {
  return this.setSponsoredPositionsList([]);
};


/**
 * repeated int32 all_variants_organic_positions = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.SearchTermRanks.prototype.getAllVariantsOrganicPositionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.setAllVariantsOrganicPositionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.addAllVariantsOrganicPositions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.clearAllVariantsOrganicPositionsList = function() {
  return this.setAllVariantsOrganicPositionsList([]);
};


/**
 * repeated int32 all_variants_sponsored_positions = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.SearchTermRanks.prototype.getAllVariantsSponsoredPositionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.setAllVariantsSponsoredPositionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.addAllVariantsSponsoredPositions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.clearAllVariantsSponsoredPositionsList = function() {
  return this.setAllVariantsSponsoredPositionsList([]);
};


/**
 * repeated int32 max_positions = 6;
 * @return {!Array<number>}
 */
proto.grpcwebPb.SearchTermRanks.prototype.getMaxPositionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.setMaxPositionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.addMaxPositions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.SearchTermRanks} returns this
 */
proto.grpcwebPb.SearchTermRanks.prototype.clearMaxPositionsList = function() {
  return this.setMaxPositionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.CategoryRanks.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CategoryRanks.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CategoryRanks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CategoryRanks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CategoryRanks.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryLink: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ranksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CategoryRanks}
 */
proto.grpcwebPb.CategoryRanks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CategoryRanks;
  return proto.grpcwebPb.CategoryRanks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CategoryRanks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CategoryRanks}
 */
proto.grpcwebPb.CategoryRanks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryLink(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRanks(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CategoryRanks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CategoryRanks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CategoryRanks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CategoryRanks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRanksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.grpcwebPb.CategoryRanks.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CategoryRanks} returns this
 */
proto.grpcwebPb.CategoryRanks.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_link = 2;
 * @return {string}
 */
proto.grpcwebPb.CategoryRanks.prototype.getCategoryLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CategoryRanks} returns this
 */
proto.grpcwebPb.CategoryRanks.prototype.setCategoryLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 ranks = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.CategoryRanks.prototype.getRanksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.CategoryRanks} returns this
 */
proto.grpcwebPb.CategoryRanks.prototype.setRanksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CategoryRanks} returns this
 */
proto.grpcwebPb.CategoryRanks.prototype.addRanks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.CategoryRanks} returns this
 */
proto.grpcwebPb.CategoryRanks.prototype.clearRanksList = function() {
  return this.setRanksList([]);
};


goog.object.extend(exports, proto.grpcwebPb);
