import _ from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import { Flex } from "@rebass/grid";
import {
  Button,
  Dropdown,
  Icon,
  Modal,
  List,
  Message
} from "semantic-ui-react";

import { Amazon } from "Common/proto/common/amazon_pb";
import OneClickButton from "Common/components/OneClickButton";
import { sendGAEvent } from "./GA";
import LinkAmazonAdvertising from "./LinkAmazonAdvertising";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import LinkStyleButton from "./LinkStyleButton";
import useLoginWithAmazon, {
  getAmazonRegionCode
} from "../../Common/hooks/useLoginWithAmazon";
import { formatAmazonAccount, getNameFromRegion } from "Common/utils/amazon";
import { useSession } from "ExtensionV2/queries/useSession";
import { stringForEnum } from "Common/utils/proto";

/*
  A small form to Link/Unlink an Amazon Advertising Account, including selecting an Amazon region
*/
function ConnectAmazonAdvertising({
  gaCategory,
  forceHideDisconnectLink = false
}) {
  const { currentSite, disconnectDataSourceMutation } = useSession();
  const { siteAlias, amazonInfo } = currentSite || {};
  const { advertisingAccounts } = amazonInfo || {};

  const [region, setRegion] = useState(null);
  const [disconnectOpen, setDisconnectOpen] = useState(false);

  const {
    mutate: disconnectDataSource,
    isLoading: disconnectDataSourceIsLoading,
    error: disconnectDataSourceError
  } = disconnectDataSourceMutation;

  if (disconnectDataSourceError) {
    console.error(disconnectDataSourceError);
  }

  // Note: We can't use the amazon global until the LWA SDK is installed.
  const amazonReady = useLoginWithAmazon();

  // If we don't have a region set, default to North America.
  useEffect(() => {
    if (amazonReady && !region) {
      // eslint-disable-next-line no-undef
      setRegion(getAmazonRegionCode(amazon.Login.Region.NorthAmerica));
    }
  }, [amazonReady, region]);

  const regions = useMemo(() => {
    if (!amazonReady) {
      return [];
    }

    // eslint-disable-next-line no-undef
    const amazonLWARegion = amazon.Login.Region;

    return [
      {
        key: getAmazonRegionCode(amazonLWARegion.NorthAmerica),
        value: getAmazonRegionCode(amazonLWARegion.NorthAmerica),
        text: "North America"
      },
      {
        key: getAmazonRegionCode(amazonLWARegion.Europe),
        value: getAmazonRegionCode(amazonLWARegion.Europe),
        text: "Europe"
      },
      {
        key: getAmazonRegionCode(amazonLWARegion.AsiaPacific),
        value: getAmazonRegionCode(amazonLWARegion.AsiaPacific),
        text: "Asia-Pacific"
      }
    ];
  }, [amazonReady]);

  const [accountToUnlink, setAccountToUnlink] = useState(null);
  const [existingAccountOptions, setExistingAccountOptions] = useState([]);

  useEffect(() => {
    const options = advertisingAccounts?.map(account => {
      const key = account.userId + "\t" + account.regionEnumOption;
      return {
        key: key,
        value: key,
        text: formatAmazonAccount(account)
      };
    });

    setExistingAccountOptions(options);
    setAccountToUnlink(options && options.length > 0 ? options[0].value : null);
  }, [advertisingAccounts]);

  const onRegionChanged = (e, data) => {
    e.stopPropagation();
    const newRegion = data.value;
    sendGAEvent(gaCategory, "Change Region", siteAlias, newRegion);
    setRegion(newRegion);
  };

  const onAccountToUnlinkChanged = (e, { value }) => {
    e.stopPropagation();
    sendGAEvent(gaCategory, "Change Account to Unlink", siteAlias, value);
    setAccountToUnlink(value);
  };

  const handleDisconnectLinkClicked = e => {
    e.stopPropagation();
    setDisconnectOpen(true);
  };

  const handleDisconnect = async e => {
    e.stopPropagation();
    if (accountToUnlink) {
      sendGAEvent(gaCategory, "Disconnect from Amazon Advertising", siteAlias);
      const [userId, regionEnumOption] = accountToUnlink.split("\t");

      disconnectDataSource({
        removeDataSourceReq: {
          siteAlias,
          amazonAdvertising: {
            accountUserId: userId,
            accountRegion: regionEnumOption
          }
        }
      });

      setDisconnectOpen(false);
    }
  };

  const isLoading = disconnectDataSourceIsLoading;

  return (
    <>
      {!_.size(advertisingAccounts) && (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
            flexWrap="wrap"
          >
            {amazonReady ? (
              <Dropdown
                options={regions}
                onChange={onRegionChanged}
                value={region}
                placeholder="Select a region"
                selection
                style={{ margin: "0.5em" }}
              />
            ) : (
              <LoadingSpinner />
            )}

            <LinkAmazonAdvertising
              region={region}
              style={{ margin: "0.5em" }}
            />
          </Flex>
          <p style={{ marginLeft: 25, marginTop: 10 }}>
            This is required for attribution tracking. Ampd will not see your
            credentials, and you can disconnect at anytime.
          </p>
          {isLoading && <LoadingSpinner />}
        </>
      )}

      {_.size(advertisingAccounts) > 0 && (
        <>
          {advertisingAccounts.map(account => {
            const regionEnumString = stringForEnum(
              Amazon.Region.Option,
              account.regionEnumOption
            );
            return (
              <p key={`${account.userId}-${account.regionEnumOption}`}>
                {account.email} ({getNameFromRegion(regionEnumString)})
              </p>
            );
          })}
          {!forceHideDisconnectLink && (
            <p style={{ marginTop: 10 }}>
              You can{" "}
              <LinkStyleButton onClick={handleDisconnectLinkClicked}>
                disconnect
              </LinkStyleButton>{" "}
              your Amazon Advertising account at any time.
            </p>
          )}

          {isLoading && <LoadingSpinner />}
        </>
      )}

      <Modal open={disconnectOpen} size="small">
        <Modal.Header>Disconnect from Amazon Advertising</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            <Dropdown
              options={existingAccountOptions}
              onChange={onAccountToUnlinkChanged}
              value={accountToUnlink}
              selection
            />
          </div>
          <Icon name="warning sign" color="yellow" /> If you disconnect your
          Amazon Advertising account:
          <List bulleted>
            <List.Item>
              You will no longer be able to see new Amazon sales numbers
              reflected in your campaign dashboard.
            </List.Item>
            <List.Item>
              You will no longer be able to determine which keywords are driving
              add-to-carts or sales on Amazon.
            </List.Item>
            <List.Item>
              You will not be able to add Ampd attribution for new campaigns.
            </List.Item>
            <List.Item>
              You will no longer be able to pick from the list of products you
              are advertising on Amazon.
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={e => {
              e.stopPropagation();
              sendGAEvent(
                gaCategory,
                "Cancelled disconnect from Amazon Advertising",
                siteAlias
              );
              setDisconnectOpen(false);
            }}
          >
            Cancel
          </Button>
          <OneClickButton negative onClick={handleDisconnect}>
            Disconnect
          </OneClickButton>
        </Modal.Actions>
      </Modal>
      {disconnectDataSourceError != null && (
        <Message error>
          There was a problem updating your Amazon Advertising account. Please
          try again in a few moments or contact us if this problem persists.
        </Message>
      )}
    </>
  );
}

export default ConnectAmazonAdvertising;
