import {
  allChannelTypes,
  allMetricsTypes,
  AMAZON_ADS_CHANNEL,
  BRAND_CHANNEL,
  GOOGLE_ADS_CHANNEL,
  ORGANIC_CHANNEL,
  SELLER_CHANNEL,
  MetricType
} from "../../components/charts/metricsConfigs";

export const supportedBrandOverviewChannelTypes = {
  [AMAZON_ADS_CHANNEL]: allChannelTypes.AMAZON_ADS,
  [GOOGLE_ADS_CHANNEL]: allChannelTypes.GOOGLE_ADS,
  [ORGANIC_CHANNEL]: allChannelTypes.ORGANIC,
  [SELLER_CHANNEL]: allChannelTypes.SELLER,
  [BRAND_CHANNEL]: allChannelTypes.BRAND
};

export interface BrandOverviewMetricType extends MetricType {
  // Brand overview only displays a single channel in each chart.
  channelKey: string;
  tooltip?: string;
}

export const supportedBrandOverviewMetricTypes: {
  [type: string]: BrandOverviewMetricType;
} = {
  ADJUSTED_COST: {
    ...allMetricsTypes.ADJUSTED_COST,
    name: "Adjusted Spend (Adjusted Cost)",
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated Google Ads ad costs, minus any Amazon Brand
     Referral Bonus credit, over the selected time range`
  },
  BRB: {
    ...allMetricsTypes.BRB,
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated Amazon Brand Referral Bonus over the selected
     time range.`
  },
  CARTS: {
    ...allMetricsTypes.CARTS,
    name: "Add To Carts",
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated add-to-carts over the selected time range.`
  },
  CLICKS: {
    ...allMetricsTypes.CLICKS,
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated Google Ads clicks over the selected time range.`
  },
  COST_OF_ACQUISITION: {
    ...allMetricsTypes.COST_OF_ACQUISITION,
    name: "Cost of Acquisition",
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Average cost of acquiring a new customer via Google Ads.`
  },
  CONVERSIONS: {
    ...allMetricsTypes.CONVERSIONS,
    name: "Purchases (Conversions)",
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated purchases over the selected time range.`
  },
  COST: {
    ...allMetricsTypes.COST,
    name: "Spend (Cost)",
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated Google Ads ad spend over the selected time range.`
  },
  CPC: {
    ...allMetricsTypes.CPC,
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Average Google Ads cost-per-click (CPC) over all campaigns in the
     selected time range. Calculated by: Cost ÷ Clicks.`
  },
  CTR: {
    ...allMetricsTypes.CTR,
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Average Google Ads click-through-rate (CTR) over all campaigns in
     the selected time range. Calculated by: Clicks ÷ Impressions.`
  },
  CVR: {
    ...allMetricsTypes.CVR,
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Average Google Ads conversion-rate (CVR) over all campaigns in the
     selected time range. Calculated by: Conversions ÷ Clicks.`
  },
  IMPRESSIONS: {
    ...allMetricsTypes.IMPRESSIONS,
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `Total accumulated Google Ads impressions over the selected time
     range.`
  },
  NEW_TO_BRAND_CONVERSIONS: {
    ...allMetricsTypes.NEW_TO_BRAND_CONVERSIONS,
    name: "New To Brand Customers",
    channelKey: allChannelTypes.GOOGLE_ADS.key,
    tooltip: `This product's Ampd Google Ads conversions over the selected
     time range from customers who had not bought in the prior 12 months.`
  },
  PROJECTED_ANNUALIZED_REVENUE: {
    ...allMetricsTypes.PROJECTED_ANNUALIZED_REVENUE,
    channelKey: supportedBrandOverviewChannelTypes.BRAND.key,
    tooltip: `Projected Ampd-driven revenue over the selected time range.
     Considers the projected annualized repeat purchases of the customer.
     Using values from the "Brand & Customer Behavior Information" section,
     Projected Annualized Revenue" is calculated by: already-realized revenues
     + projected Brand Tailored Promos revenue (based on Average Order Value
     in the selected time range) + projected retargeting revenue + projected
     repeat purchases. This helps reflect that Google Ads driven traffic
     is more-valuable than simply their first purchase.`
  },
  PROJECTED_ANNUALIZED_ROAS: {
    ...allMetricsTypes.PROJECTED_ANNUALIZED_ROAS,
    channelKey: supportedBrandOverviewChannelTypes.BRAND.key,
    tooltip: `The projected Ampd-driven "Return On Ad Spend" (ROAS) that is
     expected to be driven annually from customers over the selected time range.
     This includes annual repeat purchases of the Ampd-driven customer.
     Using values from the "Brand & Customer Behavior Information"
     section, "Projected Annualized ROAS" is calculated by: projected annualized
     revenue ÷ (Google Ads cost - Amazon Brand Referral Bonus). This helps
     reflect that Google Ads driven traffic is more-valuable than simply their
     first purchase.`
  },
  PROJECTED_REVENUE: {
    ...allMetricsTypes.PROJECTED_REVENUE,
    channelKey: supportedBrandOverviewChannelTypes.BRAND.key,
    tooltip: `The revenue projected from Ampd-driven customers over the selected
     time range. Using values from the "Brand & Customer Behavior Information"
     section, "Projected Revenue" is calculated by: already-realized conversions,
     + projected Brand Tailored Promos revenue (based on Average Order Value in
     the selected time range) + projected retargeting revenue. This helps reflect
     the extended value & opportunity of Google Ads traffic.`
  },
  PROJECTED_ROAS: {
    ...allMetricsTypes.PROJECTED_ROAS,
    channelKey: supportedBrandOverviewChannelTypes.BRAND.key,
    tooltip: `The total "Return On Ad Spend" (ROAS) projected to occur from the
     Ampd-driven ads traffic over the selected time range. This calculation
     is based off of already-realized conversions, + projected Brand Tailored
     Promos revenue (based on Average Order Value in the selected time range) +
     projected retargeting revenue. Using values from the "Brand & Customer
     Behavior Information" section, "Projected ROAS" is calculated by:
     projected revenue ÷ (Google Ads cost - Amazon Brand Referral Bonus). This
     helps reflect the extended value & opportunity of Google Ads traffic.`
  },
  REVENUE: {
    ...allMetricsTypes.REVENUE,
    name: "Revenue",
    channelKey: supportedBrandOverviewChannelTypes.GOOGLE_ADS.key,
    tooltip: `The total revenue across all Google Ads campaigns over the selected
     time range.`
  },
  ROAS: {
    ...allMetricsTypes.ROAS,
    name: "ROAS",
    channelKey: supportedBrandOverviewChannelTypes.GOOGLE_ADS.key,
    tooltip: `The Google Ads return on ad-spend (ROAS) across all Ampd Google
     Ads campaigns over the selected time period. This is calculated by dividing
     the total Revenue by the total Google Ads costs (offset by any Amazon Brand
     Referral Bonus credit).`
  },
  TACOS: {
    ...allMetricsTypes.TACOS,
    channelKey: allChannelTypes.SELLER.key,
    tooltip: `Total accumulated Google Ads cost (offset by any Amazon Brand
     Referral Bonus credits) & accumulated Amazon Ads cost, divided by the total
     accumulated revenue of the corresponding Ampd-advertised products.`
  }
};
