// grpc-web
import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetDashboardSessionRequest,
  GetDashboardSessionReply
} from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";

export const getSession = async (
  siteAlias: string
): Promise<GetDashboardSessionReply.AsObject> => {
  const req = new GetDashboardSessionRequest();
  req.setSiteAlias(siteAlias);

  const response = await GRPCWebClient.getDashboardSession(req, {});

  return response.toObject();
};
