import _ from "lodash";

import React, { useState } from "react";
import {
  Divider,
  DropdownProps,
  Form,
  Message,
  Segment
} from "semantic-ui-react";
import { useMutation } from "react-apollo";

import { sendGAEvent, useGAEvent } from "../components/GA";
import LeftDescriptionPageLayout from "../components/LeftDescriptionPageLayout";
import {
  extractErrorMessage,
  extractErrorRequestID
} from "Common/errors/error";
import { generatePath, useLocation } from "react-router-dom";
import {
  AMPD_ROOT,
  paymentPath,
  signUpPathExistingUserParam,
  signUpPathExtraAdminEmailParam
} from "../ExtensionV2";
import { ADD_SITE_USER, ADMIN_USER_ROLE } from "../graphql";
import { logout } from "Common/utils/auth";
import { InternalSiteDetails } from "Common/proto/entity/site_pb";

import AttributionDropdown, {
  EXISTING_USER
} from "../components/AttributionDropdown";
import { navigateToPage } from "../util";

import SignUp, { UNLIMITED_LINKED_BILLING_ACCOUNTS } from "../grpc/SignUp";
import { Site, User, useSession } from "ExtensionV2/queries/useSession";
import { useWantsProOperatorFeatures } from "Common/utils/featureFlags";

const PAGE_GA_CATEGORY = "Ampd: Sign Up";

export const gmvBucketDropdownOptions = [
  { key: "$0 - $250k", text: "$0 - $250k", value: 1 },
  { key: "$250k - $500k", text: "$250k - $500k", value: 250_000 },
  { key: "$500k - $1M", text: "$500k - $1M", value: 500_000 },
  { key: "$1M - $5M", text: "$1M - $5M", value: 1_000_000 },
  { key: "$5M - $10M", text: "$5M - $10M", value: 5_000_000 },
  { key: "$10M+", text: "$10M+", value: 10_000_000 }
];

const businessTypeDropdownOptions = Object.values(
  InternalSiteDetails.OrganizationType.Option
).flatMap(organizationType => {
  let text = "";
  switch (organizationType) {
    case InternalSiteDetails.OrganizationType.Option.AMAZON_FBA:
      text = "Amazon FBA (I own and manage my own brand on Amazon)";
      break;
    case InternalSiteDetails.OrganizationType.Option.AGENCY:
      text = "Agency (I am setting up Ampd on behalf of a brand I manage)";
      break;
    case InternalSiteDetails.OrganizationType.Option.AGGREGATOR:
      text = "Aggregator (I own and manage many brands on Amazon)";
      break;
  }

  return text
    ? [
        {
          text,
          key: organizationType,
          value: organizationType
        }
      ]
    : [];
});

function SignUpPage({
  currentSite,
  user
}: {
  currentSite: Site;
  user: User;
}): JSX.Element {
  const { siteAlias } = currentSite;
  const { userEmail } = user;
  const { invalidateSessionQuery } = useSession();

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();

  const location = useLocation();

  // form page state
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // form values
  const [businessName, setBusinessName] = useState("");
  const [organizationType, setOrganizationType] = useState<number>(
    InternalSiteDetails.OrganizationType.Option.UNSPECIFIED
  );
  const [siteContactEmail, setSiteContactEmail] = useState("");
  const [gmvValue, setGmvValue] = useState(0);

  // enterprise form values
  const [wantsEnterpriseBilling, setWantsEnterpriseBilling] = useState(false);
  const [createAsCommandCenter, setCreateAsCommandCenter] = useState(false);
  const [wantsLinkedBilling, setWantsLinkedBilling] = useState(false);
  const [maxLinkedBillingAccounts, setMaxLinkedBillingAccounts] = useState(10);
  const [
    wantsUnlimitedLinkedAccounts,
    setWantsUnlimitedLinkedAccounts
  ] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const isExistingUser = urlParams.get(signUpPathExistingUserParam) != null;
  const extraAdminEmail = urlParams.get(signUpPathExtraAdminEmailParam);
  const [addUser] = useMutation(ADD_SITE_USER);

  const [attribution, setAttribution] = useState(
    isExistingUser ? EXISTING_USER : ""
  );

  useGAEvent(PAGE_GA_CATEGORY, "Loaded", "");

  const signUp = async () => {
    setSubmitting(true);

    let maxBillingAccounts = 0;
    if (wantsLinkedBilling) {
      maxBillingAccounts = wantsUnlimitedLinkedAccounts
        ? UNLIMITED_LINKED_BILLING_ACCOUNTS
        : maxLinkedBillingAccounts;
    }
    try {
      const signUpReply = await SignUp(
        businessName,
        attribution,
        organizationType,
        siteContactEmail,
        gmvValue,
        wantsEnterpriseBilling,
        maxBillingAccounts,
        createAsCommandCenter
      );

      if (signUpReply == null) {
        throw new Error("Invalid Request");
      }
      const siteAlias = signUpReply.getSiteAlias();
      if (siteAlias == null) {
        throw new Error("Site Alias Not Found");
      }

      sendGAEvent(PAGE_GA_CATEGORY, "Successful sign up", siteAlias);

      if (extraAdminEmail) {
        await addUser({
          variables: {
            email: extraAdminEmail,
            givenName: "",
            familyName: "",
            siteRole: ADMIN_USER_ROLE,
            siteAlias
          }
        })
          .then(() => {
            console.info("Successfully added admin user: ", extraAdminEmail);
          })
          .catch(e => console.error(e));
      }

      invalidateSessionQuery();
      navigateToPage({
        location,
        siteAlias,
        toPage: paymentPath,
        params: null
      });
    } catch (err) {
      console.error(err); // send to datadog
      sendGAEvent(
        PAGE_GA_CATEGORY,
        "Error: Sign up",
        "",
        extractErrorRequestID(err)
      );
      const errorMessage = extractErrorMessage(err);
      setErrorMessage(
        !_.isEmpty(errorMessage)
          ? errorMessage
          : "There was an error signing up with Ampd. Please contact us for assistance."
      );
    } finally {
      setSubmitting(false);
    }
  };

  // We shouldn't end up on the sign-up page if we already have a site.
  if (!_.isEmpty(siteAlias) && !isExistingUser) {
    sendGAEvent(PAGE_GA_CATEGORY, "Already signed up", siteAlias);
    window.location.href = generatePath(AMPD_ROOT, { siteAlias });
    return <></>;
  }

  const missingValue = !businessName || !organizationType;

  return (
    <LeftDescriptionPageLayout
      heading="Welcome to Ampd!"
      historyTitle="Welcome"
      description={
        <>
          <p>
            You created a new user: <strong>{userEmail}</strong>.
          </p>
          <p>
            If you already have an Ampd user account,{" "}
            <button
              className="button-link"
              onClick={() => {
                sendGAEvent(PAGE_GA_CATEGORY, "Sign out", "");
                logout("");
              }}
            >
              please click here to sign out
            </button>{" "}
            and sign in as your existing user.
          </p>
        </>
      }
      component={
        <Segment>
          <Form error={!!errorMessage}>
            <Message error>
              <p>{errorMessage}</p>
            </Message>
            <Form.Field
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBusinessName(e.target.value)
              }
              required
              value={businessName}
            >
              <label>Business Name</label>
              <input placeholder="Enter your business name" />
            </Form.Field>
            <Form.Select
              required
              label="Choose your business type"
              onChange={(_e, data: DropdownProps) => {
                if (data.value) {
                  setOrganizationType(Number(data.value));
                }
              }}
              options={businessTypeDropdownOptions}
              placeholder="Select one"
            />
            <Form.Field
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSiteContactEmail(e.target.value)
              }
              value={siteContactEmail}
            >
              <label>Business email</label>
              <input placeholder="Enter your business email" />
              <span style={{ color: "#6d7b8a", fontSize: "14px" }}>
                If you would like us to contact you somewhere other than the
                email address you used to sign up.
              </span>
            </Form.Field>
            <Form.Select
              required
              label="To help us understand your business, what is your approximate Gross Merchandise Value (GMV) in US dollars?"
              onChange={(_e, data) => {
                if (data.value) {
                  setGmvValue(Number(data.value));
                }
              }}
              options={gmvBucketDropdownOptions}
              placeholder="Select one"
            />
            <Form.Field>
              <label>How did you hear about Ampd?</label>
              <AttributionDropdown
                attribution={attribution}
                setAttribution={setAttribution}
              />
            </Form.Field>

            {/* 
              Enterprise billing fields. Only expose to Ampd Operators 
            */}
            {wantsProOperatorFeatures && (
              <>
                <Divider horizontal> Internal </Divider>

                <Form.Checkbox
                  checked={wantsEnterpriseBilling}
                  onClick={() =>
                    setWantsEnterpriseBilling(!wantsEnterpriseBilling)
                  }
                  label={"This site is an enterprise account."}
                />
                <p>
                  An enterprise account will not be prompted to provide payment
                  information. You will need to set up billing for them
                  manually.
                </p>
                {wantsEnterpriseBilling && (
                  <>
                    <Form.Checkbox
                      checked={createAsCommandCenter}
                      onClick={() =>
                        setCreateAsCommandCenter(!createAsCommandCenter)
                      }
                      label="Create this site as a Command Center."
                    />
                    <p>
                      Select this if you are setting up this site to be a
                      command center. After the site is created, you will be
                      able to add client sites to it.
                    </p>
                    {createAsCommandCenter && (
                      <>
                        <Form.Checkbox
                          checked={wantsLinkedBilling}
                          onClick={() =>
                            setWantsLinkedBilling(!wantsLinkedBilling)
                          }
                          label="Allow this site to have other sites billed through it."
                        />
                        <p>
                          Select this if the command center should be allowed to
                          have its client sites billed through it. Otherwise,
                          any client sites will need to provide their own
                          payment information when onboarding.
                        </p>
                        {wantsLinkedBilling && (
                          <>
                            <Form.Checkbox
                              checked={!wantsUnlimitedLinkedAccounts}
                              onClick={() =>
                                setWantsUnlimitedLinkedAccounts(
                                  !wantsUnlimitedLinkedAccounts
                                )
                              }
                              label="Limit the number of linked billing accounts."
                            />
                            {wantsUnlimitedLinkedAccounts ? (
                              <p>
                                No limit on the number of linked billing
                                accounts.
                              </p>
                            ) : (
                              <Form.Input
                                type="number"
                                label="Maximum number of linked billing accounts"
                                value={maxLinkedBillingAccounts}
                                onChange={e =>
                                  setMaxLinkedBillingAccounts(
                                    Number(e.target.value)
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <p style={{ fontSize: "14px", margin: "2em 0 0" }}>
              By clicking "Continue" you agree to Ampd's{" "}
              <a
                href="https://www.ampd.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendGAEvent(PAGE_GA_CATEGORY, "Clicked terms of service", "")
                }
              >
                terms of service
              </a>{" "}
              and{" "}
              <a
                href="https://www.ampd.io/privacy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendGAEvent(PAGE_GA_CATEGORY, "Clicked privacy policy", "")
                }
              >
                privacy policy
              </a>
              .
            </p>
          </Form>
        </Segment>
      }
      nextOnClick={signUp}
      nextLoading={submitting}
      nextDisabled={missingValue || submitting}
      nextLabel="Continue"
    />
  );
}

export default SignUpPage;
