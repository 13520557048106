// A simple, non-cryptographic hash function based on Java's String.hashCode() method.
// See https://stackoverflow.com/a/7616484
export function hashCode(str: string): string {
  if (typeof str !== "string") {
    return "0";
  }

  let hash = 0;
  if (str.length === 0) {
    return "0";
  }

  let chr = 0;
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return String(hash);
}
