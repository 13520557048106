import React from "react";
import { GoogleLoginResponse } from "react-google-login";
import { Button } from "semantic-ui-react";
import smallGoogleSignIn from "../../assets/small-google-sign-in.svg";
import GoogleAdsLogin from "../../Common/components/GoogleAdsLogin";
import { sendGAEvent } from "./GA";

function GoogleAdsLoginButton({
  siteAlias,
  gaCategory,
  email,
  onGoogleLogin,
  disabled
}: {
  siteAlias: string;
  gaCategory: string;
  email: string;
  onGoogleLogin: (googleLogin: GoogleLoginResponse) => void;
  disabled: boolean;
}): JSX.Element {
  const buttonText = email || "Sign in";

  return (
    <GoogleAdsLogin
      onSuccess={onGoogleLogin}
      render={(buttonProps: { onClick: () => void; disabled?: boolean }) => (
        <Button
          basic
          color="blue"
          disabled={disabled}
          onClick={_e => {
            sendGAEvent(gaCategory, "Click Google sign-in", siteAlias);
            buttonProps.onClick();
          }}
        >
          <img
            src={smallGoogleSignIn}
            style={{ width: "25px", float: "left", paddingRight: "5px" }}
            alt="Google Sign-in"
          />
          {buttonText}
        </Button>
      )}
    />
  );
}

export default GoogleAdsLoginButton;
