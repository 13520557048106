import _ from "lodash";

// Returns the smoothed series, calculated from one or more series and looking
// back over the specified number of days in the smoothing window. A minimum of
// one numerator series is required, but denominator series are optional.
export const ratioSmooth = (
  numeratorsLists, // Numerators lists - will sum by index if more than 1.
  denominatorsLists, // Denominator lists - will sum by index if more than 1
  smoothingWindow = 1, // smooth over this number of items PRIOR
  minValue, // Don't output lower than this value; default no minValue
  maxValue,
  decimalPlaces = 0, // Number of decimals in output; default integer rounding
  startIndex, // optional - defaults to start of list
  endIndex // optional - defaults to end of list
) => {
  const placesFactor = Math.pow(10, decimalPlaces);
  const isRatio = denominatorsLists?.some(d => d?.length > 0);

  // Should always be a first numerator timeseries. Use it for the base length.
  let length = numeratorsLists?.[0]?.length;
  if (!length) {
    console.error("ratioSmooth(): Timeseries numerators expected!");
    return [];
  }

  // Verify all numerator timeseries are the same length
  for (let tsIndex = 0; tsIndex < numeratorsLists.length; tsIndex++) {
    const timeseries = numeratorsLists[tsIndex];
    if (timeseries?.length !== length) {
      console.error("ratioSmooth(): Mismatched numerator timeseries length!");
      return [];
    }
  }
  // Verify all denominator timeseries are also the same length
  for (let tsIndex = 0; tsIndex < denominatorsLists.length; tsIndex++) {
    const timeseries = denominatorsLists[tsIndex];
    if (timeseries?.length !== length) {
      console.error("ratioSmooth(): Mismatched denominator timeseries length!");
      return [];
    }
  }

  // Value or default start/end
  startIndex = startIndex ?? 0;
  endIndex = endIndex ?? length - 1;

  // Can't smooth nonsense, so return null
  if (endIndex < startIndex) {
    console.error("ratioSmooth(): endIndex cannot be less than startIndex!");
    return [];
  }
  // Smooth over nothing returns empty
  if (smoothingWindow < 1) {
    console.error("ratioSmooth(): Smoothing window cannot be less than 1!");
    return [];
  }

  const smoothed = [];
  for (let curr = startIndex; curr <= endIndex; curr++) {
    const lookback = smoothingWindow - 1; // ignore self!

    let smoothStart = curr - lookback;
    let adjustedInterval = smoothingWindow;
    if (smoothStart < 0) {
      // Adjust for the beginning of the timeseries
      smoothStart = 0;
      adjustedInterval = curr + 1; // when limited, use what's available
    }

    // Loop to calculate a smooth number over the smoothWindow subset of days
    // Erroneous numbers will not be factored into the calc.
    let numTotal = 0;
    let denTotal = 0;
    for (let smoothIdx = smoothStart; smoothIdx <= curr; smoothIdx++) {
      numeratorsLists.forEach(ts => (numTotal += ts[smoothIdx]));
      denominatorsLists.forEach(ts => (denTotal += ts[smoothIdx]));
    }
    const numSmoothed = numTotal / adjustedInterval;
    const denSmoothed = denTotal / adjustedInterval;

    // Zero denominator is useless, return minValue instead.
    if (isRatio && denTotal === 0) {
      smoothed.push(minValue);
      continue;
    }

    const smoothValue = isRatio ? numSmoothed / denSmoothed : numSmoothed;

    // Default to minimum when below
    if (minValue != null && smoothValue < minValue) {
      smoothed.push(minValue);
    } else if (maxValue != null && smoothValue > maxValue) {
      smoothed.push(maxValue);
    } else {
      smoothed.push(
        Math.round((smoothValue + Number.EPSILON) * placesFactor) / placesFactor
      );
    }
  }

  return smoothed;
};

// Zips one or more arrays together by summing their corresponding index values.
export const zipArraysUsingSum = arrays => {
  let combined = [];
  let consistentLength = undefined;

  for (let arraysIndex = 0; arraysIndex < arrays.length; arraysIndex++) {
    const thisArr = arrays[arraysIndex];

    if (_.isEmpty(thisArr)) {
      return combined;
    }

    if (consistentLength === undefined) {
      consistentLength = thisArr.length;
    } else if (thisArr.length !== consistentLength) {
      console.error(
        `zipArraysUsingSum() found errors of different lengths: (${thisArr.length} vs ${consistentLength})!`
      );
      return [];
    }

    // Zip-sum into "combined"
    for (let i = 0; i < thisArr.length; i++) {
      combined[i] = (combined[i] || 0) + thisArr[i];
    }
  }

  return combined;
};

// Returns the sum total of the values across multiple arrays.
export const arraysSum = arrays => {
  return zipArraysUsingSum(arrays).reduce((total, value) => total + value, 0);
};

// Given a numerator array and a denominator array, return their by-index ratios
// in a matching-length return array. Round each of the values in the return
// array to the specified number of places.
export const calculateRatios = (numArr, denArr, decimalPlaces) => {
  const placesFactor = Math.pow(10, decimalPlaces);

  if (numArr.length !== denArr.length || !numArr.length || !denArr.length) {
    return [];
  }

  return numArr.map((num, i) => {
    const den = denArr[i];
    if (den === 0) {
      return 0;
    }

    if (decimalPlaces === null) {
      return num / den;
    }

    if (decimalPlaces === 0) {
      return Math.round(num / den);
    }

    return (
      Math.round((num / den + Number.EPSILON) * placesFactor) / placesFactor
    );
  });
};

// Given a list of numerators and denominators, returns their average ratio
// rounded to the requested number of places (null indicates no rounding).
export const calculateAverageRatio = (numArr, denArr, decimalPlaces) => {
  const placesFactor = Math.pow(10, decimalPlaces);
  let totalNum = 0;
  let totalDen = 0;

  // Sum the  numerators & denominators
  for (let i = 0; i < numArr.length; i++) {
    totalNum += numArr[i];
    totalDen += denArr[i];
  }

  if (totalDen === 0) {
    return 0;
  }

  if (decimalPlaces === null) {
    return totalNum / totalDen;
  }

  if (decimalPlaces === 0) {
    return Math.round(totalNum / totalDen); // integer rounding
  }

  return (
    Math.round((totalNum / totalDen + Number.EPSILON) * placesFactor) /
    placesFactor
  );
};
