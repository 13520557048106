// Renders the user's name for display.
//
// - If there is no user, return the empty string.
// - If both the given name and family name are available, concatenate them.
// - If only the given name is available, return that.
// - If neither the given name nor family name are available, return the email.
export const renderUserName = (user, includeEmail) => {
  const renderEmail = email => email || "";

  if (!user) {
    return "";
  }

  // If no profile is provided, try to render the email, if available.
  const { profile, email } = user;
  if (!profile) {
    return renderEmail(email);
  }

  const suffix = includeEmail && email ? ` <${email}>` : "";

  // If there is a profile, render the givenName and/or familyName if available.
  const { givenName, familyName } = profile;
  if (givenName && familyName) {
    return `${givenName} ${familyName}${suffix}`;
  }

  if (givenName) {
    return `${givenName}${suffix}`;
  }

  // If all else fails, try to render the email, if available.
  return renderEmail(email);
};
