import { UseMutationResult, useMutation } from "@tanstack/react-query";

import {
  UpdateCampaignsRequest,
  UpdateCampaignsReply,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { GRPCWebClient } from "Common/utils/grpc";

import { CampaignSettings } from "Common/proto/entity/campaign_pb";
import { getCurrencyMinimumUnit } from "Common/utils/googleAds";
import * as proto from "Common/utils/proto";
import { sendGAEvent } from "../components/GA";

interface UpdatePausingAutomationArgs {
  siteAlias: string;
  gaCategory: string;
  googleAdsCustomerId: string;
  campaignId: string;
  currencyCode: string;
  pausingAutomationEnabled: boolean;
  pausingAutomationCostThresholdMicros: number;
  pausingAutomationAACOSThresholdPoints: number;
  pausingAutomationReevaluatePausedKeywords: boolean;
  pausingAutomationMinNumActiveKeywords: number;
}

export const useUpdatePausingAutomation = (): UseMutationResult<
  UpdateCampaignsReply.AsObject,
  unknown,
  UpdatePausingAutomationArgs,
  unknown
> => {
  return useMutation(
    async (
      updatePausingAutomationArgs: UpdatePausingAutomationArgs
    ): Promise<UpdateCampaignsReply.AsObject> => {
      for (const [key, value] of Object.entries(updatePausingAutomationArgs)) {
        if (value == null || value?.length === 0) {
          throw new Error(`Missing required argument: ${key}`);
        }
      }

      const {
        siteAlias,
        gaCategory,
        googleAdsCustomerId,
        campaignId,
        currencyCode,
        pausingAutomationEnabled,
        pausingAutomationCostThresholdMicros,
        pausingAutomationAACOSThresholdPoints,
        pausingAutomationReevaluatePausedKeywords,
        pausingAutomationMinNumActiveKeywords
      } = updatePausingAutomationArgs;

      // Google Ads doesn't like values that are less the currency's minimum unit,
      // so we need to round the micro values to it.
      const currencyMinimumMicros = getCurrencyMinimumUnit(currencyCode);

      const autoAction = new UpdateCampaignAction.UpdatePausingAutomationSettingsAction();
      autoAction.setCampaignId(campaignId);
      autoAction.setStatus(
        pausingAutomationEnabled
          ? CampaignSettings.AutomationStatus.Option.ENABLED
          : CampaignSettings.AutomationStatus.Option.DISABLED
      );
      autoAction.setCost28DayThresholdMicros(
        Math.round(
          pausingAutomationCostThresholdMicros / currencyMinimumMicros
        ) * currencyMinimumMicros
      );
      autoAction.setAacos28DayThresholdPoints(
        pausingAutomationAACOSThresholdPoints
      );
      autoAction.setReevaluatePausedKeywords(
        pausingAutomationReevaluatePausedKeywords
      );
      autoAction.setMinimumNumberOfActiveKeywords(
        pausingAutomationMinNumActiveKeywords
      );

      const action = proto.set(new UpdateCampaignAction(), {
        updatePausingAutomationSettings: autoAction
      });

      sendGAEvent(
        gaCategory,
        "Update Campaign Pausing Automation",
        siteAlias,
        `${pausingAutomationEnabled}:cost${pausingAutomationCostThresholdMicros}` +
          `:aacos${pausingAutomationAACOSThresholdPoints}` +
          `:reeval${pausingAutomationReevaluatePausedKeywords}` +
          `:min${pausingAutomationMinNumActiveKeywords}`
      );

      const updateReq = proto.set(new UpdateCampaignsRequest(), {
        siteAlias,
        customerId: googleAdsCustomerId,
        actions: [action]
      });

      const reply = await GRPCWebClient.updateCampaigns(updateReq, {});
      return reply.toObject();
    }
  );
};
