import _ from "lodash";
import match from "micromatch";

import React, { useState } from "react";
import {
  Button,
  Icon,
  Input,
  Label,
  Message,
  Popup,
  Portal,
  Segment
} from "semantic-ui-react";
import styled from "styled-components/macro";
import Draggable from "react-draggable";
import { Flex } from "@rebass/grid";

import { Colors } from "../../app.css";
import InputWithMaxUTF8Size, { validateInput } from "./InputWithMaxUTF8Size";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { sendGAEvent } from "./GA";

export const UnselectedLabel = styled(Label)`
  &&& {
    padding: 0.3rem;
    margin: 0 0.3rem 0.3rem 0;
    background-color: #ffffff !important;
    border: 1px solid #00b5ad !important;
    color: #000000 !important;
  }
`;

export const EditableSelectedLabel = styled(Label)`
  &&& {
    padding: 0.3rem;
    margin: 0 0.3rem 0.3rem 0;
    background-color: #ffffff !important;
    border: 1px solid #aaaaaa !important;
    color: #000000 !important;
  }
`;

export const SelectedLabel = styled(Label)`
  &&& {
    padding: 0.3rem;
    margin: 0 0.3rem 0.3rem 0;
    background-color: #e1f7f7 !important;
    border: 1px solid #00b5ad !important;
    color: #000000 !important;
  }
`;

export const ErrorLabel = styled(Label)`
  &&& {
    padding: 0.3rem;
    margin: 0 0.3rem 0.3rem 0;
    background-color: #fff6f6 !important;
    border: 1px solid #e0b4b4 !important;
    color: #9f3a38 !important;
  }
`;

// Renders the phrases that have already been selected by the user.
export function SelectedPhrases(props) {
  const {
    style,
    selectedPhrases,
    minPhraseCount,
    maxPhraseCount,
    maxPhraseLength,
    validatePhrase,
    newPhraseAutofocus,
    newPhrasePlaceholder,
    newPhraseTooltip,
    updatePhrases
  } = props;

  const [editingPhrase, setEditingPhrase] = useState(null);

  const handleTextAccept = text => {
    if (text != null) {
      updatePhrases(text, "");
    }
  };

  const currentCount = selectedPhrases.length;
  const requiredCount = minPhraseCount || 0;
  const missingRequired =
    currentCount < requiredCount ? requiredCount - currentCount : 0;

  return (
    <div style={style}>
      {selectedPhrases.map(phrase => (
        <SelectedPhrase
          key={phrase}
          phrase={phrase}
          updatePhrases={updatePhrases}
          canEdit={true}
          canRemove={true}
          validatePhrase={validatePhrase}
          editingPhrase={editingPhrase}
          setEditingPhrase={setEditingPhrase}
        />
      ))}
      {!!missingRequired && (
        <small
          style={{
            color: Colors.negative,
            display: "inline-block",
            marginBottom: 10
          }}
        >
          ({missingRequired} more required)
        </small>
      )}
      {(!!currentCount || !!missingRequired) && <br />}
      {(!maxPhraseCount || currentCount < maxPhraseCount) && (
        <Popup
          disabled={!newPhraseTooltip}
          trigger={
            <span>
              <InputWithMaxUTF8Size
                text={""}
                autofocus={newPhraseAutofocus}
                placeholder={newPhrasePlaceholder}
                onTextAccept={handleTextAccept}
                maxLength={maxPhraseLength}
                validateText={validatePhrase}
                holdFocusOnEnter={true}
              />
            </span>
          }
          on="hover"
          position="left center"
        >
          {newPhraseTooltip}
        </Popup>
      )}
    </div>
  );
}

// Renders a single phrase that have already been selected by the user.
export function SelectedPhrase(props) {
  const {
    phrase,
    updatePhrases,
    canEdit,
    canRemove,
    maxPhraseLength,
    validatePhrase,
    editingPhrase,
    setEditingPhrase
  } = props;
  const gaActionPrefix = props.gaActionPrefix || "";
  const sendEvent = props.sendEvent || (() => {});

  const handleMouseDown = () => {
    if (canEdit) {
      sendEvent(gaActionPrefix + "Edit Phrase", phrase);
      setEditingPhrase(phrase);
    } else if (canRemove) {
      // Remove phrase
      sendEvent(gaActionPrefix + "Remove Phrase", phrase);
      updatePhrases("", phrase);
    }
  };

  const handleTextAccept = text => {
    // Replace phrase
    updatePhrases(text, phrase);

    setEditingPhrase(null);
  };

  const [validateMessage] = validateInput(
    phrase,
    maxPhraseLength,
    validatePhrase
  );
  const HighlightLabel =
    canEdit && validateMessage
      ? ErrorLabel
      : canEdit
      ? EditableSelectedLabel
      : SelectedLabel;

  return (
    <>
      {editingPhrase === phrase || (canEdit && validateMessage) ? (
        <InputWithMaxUTF8Size
          text={phrase}
          autofocus={editingPhrase === phrase}
          onTextAccept={handleTextAccept}
          maxLength={maxPhraseLength}
          validateText={validatePhrase}
        />
      ) : (
        <HighlightLabel
          style={{
            cursor: canEdit ? "text" : canRemove ? "pointer" : "default"
          }}
        >
          {canEdit && (
            <Popup
              disabled={!canEdit}
              trigger={
                <Icon name="pencil" color="grey" onClick={handleMouseDown} />
              }
              on="hover"
              position="bottom center"
            >
              Click to edit
            </Popup>
          )}
          <span style={{ fontWeight: "normal" }} onClick={handleMouseDown}>
            {phrase}
          </span>
          {canRemove && (
            <Popup
              disabled={!canEdit}
              trigger={
                <Icon
                  name="close"
                  color="black"
                  onClick={() => updatePhrases("", phrase)}
                />
              }
              on="hover"
              position="bottom center"
            >
              Click to unselect
            </Popup>
          )}
        </HighlightLabel>
      )}
    </>
  );
}

export function Suggestions(props) {
  const {
    siteAlias,
    gaCategory,
    gaName,
    loading,
    openIcon,
    openLabel,
    sections,
    selectedPhrases,
    updatePhrases,
    portalHeight,
    showFilter,
    onOpen,
    onClose
  } = props;
  const [portalOpen, setPortalOpen] = useState(false);

  const [filterText, setFilterText] = useState("");

  const sendEvent = (action, label) => {
    if (gaCategory && gaName) {
      sendGAEvent(gaCategory, gaName + ": " + action, siteAlias, label);
    }
  };

  const handleClose = () => {
    sendEvent("Close (Button)");
    setPortalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleToggleOpen = () => {
    sendEvent(portalOpen ? "Close (Toggle)" : "Open");
    setPortalOpen(!portalOpen);
    if (!portalOpen) {
      if (onOpen) {
        onOpen();
      }
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  const handleFilterChange = (e, { value }) => {
    setFilterText(value);
  };

  return (
    <>
      <Flex flexDirection="row" justifyContent="space-around">
        <Button
          type="button"
          compact={openIcon ? true : undefined}
          size="mini"
          color={portalOpen ? undefined : "teal"}
          onClick={handleToggleOpen}
        >
          {openIcon}
          {openLabel || "Open Suggestions"}
        </Button>
      </Flex>
      <Portal
        open={portalOpen}
        onClose={handleClose}
        closeOnDocumentClick={false}
      >
        <Draggable
          handle=".draggableHandle"
          onStart={() => sendEvent("Move Box")}
        >
          <Segment
            style={{
              left: 5,
              top: 30,
              width: "33vw",
              height: portalHeight,
              maxHeight: "90vh",
              position: "fixed",
              zIndex: 1000
            }}
          >
            <Flex
              style={{
                width: "100%",
                position: "relative",
                top: -5,
                height: 7
              }}
              className="draggableHandle"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Icon style={{ margin: "0 auto" }} name="bars" size="small" />
            </Flex>

            <Button
              style={{
                top: -10,
                right: -15,
                position: "absolute",
                zIndex: 1001
              }}
              active={true}
              circular
              size="tiny"
              icon="close"
              autoFocus
              onClick={handleClose}
            />

            {loading ? (
              <LoadingSpinner />
            ) : (
              <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
                {showFilter && (
                  <Input
                    style={{ width: "100%", fontSize: "80%" }}
                    placeholder="filter by word"
                    value={filterText}
                    onClick={() => sendEvent("Click Filter Input")}
                    onChange={handleFilterChange}
                  />
                )}
                {sections.map(({ header, suggestedPhrases, maxHeight, name }) =>
                  _.isEmpty(suggestedPhrases) ? null : (
                    <Message
                      key={header}
                      color="teal"
                      size="mini"
                      style={{
                        backgroundColor: "#F8FFFF",
                        overflowY: "auto",
                        maxHeight: maxHeight,
                        borderWidth: 1,
                        borderStyle: "solid",
                        boxShadow: "none"
                      }}
                    >
                      <Message.Header>{header}</Message.Header>
                      <Message.Content>
                        Click suggestions to add.
                        <div>
                          {filterPhrases(suggestedPhrases, filterText).map(
                            phrase => {
                              const gaActionPrefix = name ? name + ": " : "";

                              const isSelected = _.includes(
                                selectedPhrases,
                                phrase
                              );

                              if (isSelected) {
                                return (
                                  <SelectedPhrase
                                    key={phrase}
                                    phrase={phrase}
                                    updatePhrases={updatePhrases}
                                    canRemove={true}
                                    gaActionPrefix={gaActionPrefix}
                                    sendEvent={sendEvent}
                                  />
                                );
                              } else {
                                const handleMouseDown = () => {
                                  // Add phrase
                                  sendEvent(
                                    gaActionPrefix + "Add Phrase",
                                    phrase
                                  );
                                  updatePhrases(phrase, "");
                                };

                                return (
                                  <UnselectedLabel
                                    key={phrase}
                                    basic={true}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                    onMouseDown={handleMouseDown}
                                  >
                                    <Icon
                                      name="plus"
                                      style={{ marginRight: "0.25rem" }}
                                      color="grey"
                                    />
                                    <span
                                      style={{
                                        color: Colors.text,
                                        fontWeight: "normal"
                                      }}
                                    >
                                      {phrase}
                                    </span>
                                  </UnselectedLabel>
                                );
                              }
                            }
                          )}
                        </div>
                      </Message.Content>
                    </Message>
                  )
                )}
              </div>
            )}
          </Segment>
        </Draggable>
      </Portal>
    </>
  );
}

export function filterPhrases(phrases, filterText) {
  if (!phrases) {
    return phrases;
  }

  if (!filterText) {
    return phrases;
  }

  /* basic word match */

  const patterns = filterText.split(" ");
  return phrases.filter(keyword => {
    const words = keyword.split(" ");

    let allMatch = true;
    patterns.forEach((pattern, index) => {
      if (!pattern) {
        return;
      }
      if (index === patterns.length - 1) {
        pattern = pattern + "*";
      }
      if (pattern.startsWith("!")) {
        if (match(words, pattern).length < words.length) {
          allMatch = false;
        }
      } else {
        if (!match(words, pattern).length > 0) {
          allMatch = false;
        }
      }
    });

    return allMatch;
  });
}
