// source: google/ads/googleads/v16/common/asset_policy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_common_policy_pb = require('../../../../../google/ads/googleads/v16/common/policy_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_policy_pb);
var google_ads_googleads_v16_enums_asset_link_primary_status_pb = require('../../../../../google/ads/googleads/v16/enums/asset_link_primary_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_link_primary_status_pb);
var google_ads_googleads_v16_enums_asset_link_primary_status_reason_pb = require('../../../../../google/ads/googleads/v16/enums/asset_link_primary_status_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_link_primary_status_reason_pb);
var google_ads_googleads_v16_enums_asset_offline_evaluation_error_reasons_pb = require('../../../../../google/ads/googleads/v16/enums/asset_offline_evaluation_error_reasons_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_asset_offline_evaluation_error_reasons_pb);
var google_ads_googleads_v16_enums_policy_approval_status_pb = require('../../../../../google/ads/googleads/v16/enums/policy_approval_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_policy_approval_status_pb);
var google_ads_googleads_v16_enums_policy_review_status_pb = require('../../../../../google/ads/googleads/v16/enums/policy_review_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_policy_review_status_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.common.AdAssetPolicySummary', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.AssetDisapproved', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.DetailsCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.AdAssetPolicySummary.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.AdAssetPolicySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.AdAssetPolicySummary.displayName = 'proto.google.ads.googleads.v16.common.AdAssetPolicySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.displayName = 'proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.AssetDisapproved = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.common.AssetDisapproved.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.AssetDisapproved, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.AssetDisapproved.displayName = 'proto.google.ads.googleads.v16.common.AssetDisapproved';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.AdAssetPolicySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyTopicEntriesList: jspb.Message.toObjectList(msg.getPolicyTopicEntriesList(),
    google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry.toObject, includeInstance),
    reviewStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.AdAssetPolicySummary;
  return proto.google.ads.googleads.v16.common.AdAssetPolicySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry;
      reader.readMessage(value,google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry.deserializeBinaryFromReader);
      msg.addPolicyTopicEntries(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (reader.readEnum());
      msg.setReviewStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (reader.readEnum());
      msg.setApprovalStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.AdAssetPolicySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyTopicEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry.serializeBinaryToWriter
    );
  }
  f = message.getReviewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated PolicyTopicEntry policy_topic_entries = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.common.PolicyTopicEntry>}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.getPolicyTopicEntriesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.PolicyTopicEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.PolicyTopicEntry>} value
 * @return {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} returns this
*/
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.setPolicyTopicEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.PolicyTopicEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.PolicyTopicEntry}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.addPolicyTopicEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.ads.googleads.v16.common.PolicyTopicEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} returns this
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.clearPolicyTopicEntriesList = function() {
  return this.setPolicyTopicEntriesList([]);
};


/**
 * optional google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus review_status = 2;
 * @return {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.getReviewStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus} value
 * @return {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} returns this
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.setReviewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus approval_status = 3;
 * @return {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus}
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.getApprovalStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} value
 * @return {!proto.google.ads.googleads.v16.common.AdAssetPolicySummary} returns this
 */
proto.google.ads.googleads.v16.common.AdAssetPolicySummary.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.DetailsCase = {
  DETAILS_NOT_SET: 0,
  ASSET_DISAPPROVED: 3
};

/**
 * @return {proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.DetailsCase}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.getDetailsCase = function() {
  return /** @type {proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetDisapproved: (f = msg.getAssetDisapproved()) && proto.google.ads.googleads.v16.common.AssetDisapproved.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails;
  return proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.google.ads.googleads.v16.common.AssetDisapproved;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.AssetDisapproved.deserializeBinaryFromReader);
      msg.setAssetDisapproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAssetDisapproved();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.google.ads.googleads.v16.common.AssetDisapproved.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason reason = 1;
 * @return {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.getReason = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusReasonEnum.AssetLinkPrimaryStatusReason} value
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} returns this
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.setReason = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} returns this
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.clearReason = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.hasReason = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.ads.googleads.v16.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus status = 2;
 * @return {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetLinkPrimaryStatusEnum.AssetLinkPrimaryStatus} value
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} returns this
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} returns this
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AssetDisapproved asset_disapproved = 3;
 * @return {?proto.google.ads.googleads.v16.common.AssetDisapproved}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.getAssetDisapproved = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.AssetDisapproved} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.AssetDisapproved, 3));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.AssetDisapproved|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} returns this
*/
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.setAssetDisapproved = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails} returns this
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.clearAssetDisapproved = function() {
  return this.setAssetDisapproved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.AssetLinkPrimaryStatusDetails.prototype.hasAssetDisapproved = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.AssetDisapproved.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.AssetDisapproved} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.toObject = function(includeInstance, msg) {
  var f, obj = {
    offlineEvaluationErrorReasonsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.AssetDisapproved}
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.AssetDisapproved;
  return proto.google.ads.googleads.v16.common.AssetDisapproved.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.AssetDisapproved} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.AssetDisapproved}
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.google.ads.googleads.v16.enums.AssetOfflineEvaluationErrorReasonsEnum.AssetOfflineEvaluationErrorReasons>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOfflineEvaluationErrorReasons(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.AssetDisapproved.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.AssetDisapproved} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfflineEvaluationErrorReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated google.ads.googleads.v16.enums.AssetOfflineEvaluationErrorReasonsEnum.AssetOfflineEvaluationErrorReasons offline_evaluation_error_reasons = 1;
 * @return {!Array<!proto.google.ads.googleads.v16.enums.AssetOfflineEvaluationErrorReasonsEnum.AssetOfflineEvaluationErrorReasons>}
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.prototype.getOfflineEvaluationErrorReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v16.enums.AssetOfflineEvaluationErrorReasonsEnum.AssetOfflineEvaluationErrorReasons>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.enums.AssetOfflineEvaluationErrorReasonsEnum.AssetOfflineEvaluationErrorReasons>} value
 * @return {!proto.google.ads.googleads.v16.common.AssetDisapproved} returns this
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.prototype.setOfflineEvaluationErrorReasonsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.AssetOfflineEvaluationErrorReasonsEnum.AssetOfflineEvaluationErrorReasons} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.AssetDisapproved} returns this
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.prototype.addOfflineEvaluationErrorReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.common.AssetDisapproved} returns this
 */
proto.google.ads.googleads.v16.common.AssetDisapproved.prototype.clearOfflineEvaluationErrorReasonsList = function() {
  return this.setOfflineEvaluationErrorReasonsList([]);
};


goog.object.extend(exports, proto.google.ads.googleads.v16.common);
