import _ from "lodash";

import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Icon,
  Message,
  Modal
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import InputWithMaxUTF8Size from "../InputWithMaxUTF8Size";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";

import { sendGAEvent } from "../GA";
import {
  extractAmazonURLInfoFromString,
  getURLForCustomPathAndQuery
} from "Common/utils/amazon";
import {
  determineFinalURL,
  collectAdTrackingVariables,
  sendUpdateAdTracking
} from "./AmazonAttributionEditButton";

import { extractErrorMessage } from "Common/errors/error";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { useSessionSite } from "../../queries/useSessionSite";
import { isAmazonMarketplaceInfo } from "Common/utils/marketplace";

const AmazonFinalURLEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    customerId,
    isCampaignRemoved,
    domain,
    asin,
    marketplaceInfo
  } = itemizedCampaignConfiguration;

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let editable = true;
  if (
    !isAmazonMarketplaceInfo(marketplaceInfo) ||
    !domain ||
    !asin ||
    !customerId ||
    isCampaignRemoved
  ) {
    editable = false;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={
          editable
            ? () => {
                sendGAEvent(gaCategory, "Click Edit Target URL", siteAlias);
                setModalOpen(true);
              }
            : undefined
        }
      >
        {editable && (
          <SimpleTooltip tooltip="Edit Target URL">
            <Icon name="pencil" style={{ cursor: "pointer" }} />
          </SimpleTooltip>
        )}
      </Flex>
      {modalOpen && (
        <FinalURLModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

const FinalURLModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const { siteFeatures } = useSessionSite();
  const scrubCampaignNamesAndAddId = siteFeatures.scrubCampaignNamesWithId;

  const {
    customerId,
    campaignId,
    campaignName,
    adGroupId,
    adGroupName,
    adId,
    asin,
    attributionBehavior,
    attributionAdvertiser,
    attributionCustomTags,
    finalURL,
    allFinalURLs,
    trackingURLTemplate,
    detailPagePath,
    customTargetURLPathAndQuery,
    marketplaceInfo,
    marketplaceAdvertisers
  } = itemizedCampaignConfiguration;

  const [modalOpen, setModalOpen] = useState(true);
  const [editing, setEditing] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const [editFinalURL, setEditFinalURL] = useState("");
  const [
    editCustomTargetURLPathAndQuery,
    setEditCustomTargetURLPathAndQuery
  ] = useState(customTargetURLPathAndQuery);

  useEffect(() => {
    setEditFinalURL(
      determineFinalURL(
        attributionBehavior,
        attributionCustomTags,
        marketplaceInfo,
        editCustomTargetURLPathAndQuery,
        finalURL,
        asin
      ) || ""
    );
  }, [
    attributionBehavior,
    attributionCustomTags,
    editCustomTargetURLPathAndQuery,
    marketplaceInfo,
    finalURL,
    asin
  ]);

  const handleUpdateTargetURLPath = async () => {
    setIsSubmitting(true);

    try {
      const {
        finalURLs: newFinalURLs,
        trackingURLTemplate: newTrackingUrlTemplate
      } = await collectAdTrackingVariables({
        siteAlias,
        campaignId,
        campaignName,
        adGroupId,
        adGroupName,
        attributionAdvertiser,
        attributionBehavior,
        attributionCustomTags,
        marketplaceInfo,
        customTargetURLPathAndQuery: editCustomTargetURLPathAndQuery,
        previousFinalURL: finalURL,
        asin,
        stripIdsFromNames: scrubCampaignNamesAndAddId
      });

      await sendUpdateAdTracking(
        siteAlias,
        gaCategory,
        String(customerId),
        String(campaignId),
        String(adGroupId),
        String(adId),
        !_.isEqual(newFinalURLs, allFinalURLs) ? newFinalURLs : undefined,
        newTrackingUrlTemplate !== trackingURLTemplate
          ? newTrackingUrlTemplate
          : undefined
      );

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);

      console.error(e); // log to datadog

      setErrorMessage(message);

      // Since submitAttempts is used as the key of the OneClickButton, changing it
      // will create a new button instance and re-enable it.
      setSubmitAttempts(submitAttempts + 1);
      setIsSubmitting(false);
    }
  };

  const handleUseDefaultTargetURLPath = () => {
    setEditCustomTargetURLPathAndQuery("");
    setUpdateEnabled(!!customTargetURLPathAndQuery);
    setWarningMessage("");
    setErrorMessage("");
  };

  const handleUseCustomTargetURLPathAndQuery = () => {
    setEditCustomTargetURLPathAndQuery(
      customTargetURLPathAndQuery || detailPagePath
    );
    setUpdateEnabled(false);
    setWarningMessage("");
    setErrorMessage("");
  };

  const handleTargetURLPathChange = () => {
    setEditing(true);
    setUpdateEnabled(false);
    setWarningMessage("");
    setErrorMessage("");
  };

  const handleTargetURLPathAccept = (value: string) => {
    let pathAndQuery = value
      .trim()
      .replaceAll(" ", "+")
      .replaceAll(":", "%3A");
    const urlString = getURLForCustomPathAndQuery(
      marketplaceInfo,
      pathAndQuery
    );
    try {
      const url = new URL(urlString || "");
      const parts = url.toString().split("/");
      pathAndQuery = "/" + parts.slice(3).join("/");
    } catch (err) {
      console.error(err);
    }

    const { asin: foundASIN } = extractAmazonURLInfoFromString(urlString);
    let warningMessage = "";
    if (!foundASIN) {
      // We are not showing an error message for this now
      warningMessage = "";
    } else if (foundASIN.toLowerCase() !== asin.toLowerCase()) {
      warningMessage =
        `The ASIN found in path and query parameters (${foundASIN})` +
        ` does not match the one used to create the ad (${asin}).`;
    }

    setEditing(false);
    setEditCustomTargetURLPathAndQuery(pathAndQuery);
    setUpdateEnabled(pathAndQuery !== customTargetURLPathAndQuery);
    setErrorMessage("");
    setWarningMessage(warningMessage);
  };

  const handleModalClose = () => {
    setErrorMessage("");
    setUpdateEnabled(false);
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
      size="large"
    >
      <Modal.Header>Configure Target URL for Ad</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Field disabled={_.isEmpty(marketplaceAdvertisers)}>
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Checkbox
                radio
                label={
                  <label>
                    <strong>Use default product page for ASIN:</strong>
                  </label>
                }
                checked={!editCustomTargetURLPathAndQuery}
                onClick={handleUseDefaultTargetURLPath}
              />
              <span style={{ marginLeft: 5 }}>{asin}</span>
            </Flex>
          </Form.Field>
          {!editCustomTargetURLPathAndQuery && (
            <Form.Field disabled={editing}>
              <Message>
                <p>
                  Here is the target URL for your ad (without any attribution
                  parameters):
                </p>
                <p style={{ wordBreak: "break-all", marginLeft: "1em" }}>
                  <a
                    href={editFinalURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {editFinalURL}
                  </a>
                </p>
                <p>
                  Click on the link to test that it goes to the expected page.
                </p>
              </Message>
            </Form.Field>
          )}
          <Divider horizontal>Or</Divider>
          <Form.Field>
            <strong>
              <Checkbox
                radio
                label="Use custom path and query parameters:"
                checked={!!editCustomTargetURLPathAndQuery}
                onClick={handleUseCustomTargetURLPathAndQuery}
              />
            </strong>
            <div
              style={{
                marginTop: 10,
                marginLeft: 30,
                paddingLeft: 0,
                paddingRight: 0
              }}
            >
              <InputWithMaxUTF8Size
                style={{ width: "100%" }}
                text={editCustomTargetURLPathAndQuery}
                placeholder={customTargetURLPathAndQuery || detailPagePath}
                onTextChange={handleTargetURLPathChange}
                onTextAccept={handleTargetURLPathAccept}
                hideAnnotation={true}
                autofocus={!!editCustomTargetURLPathAndQuery}
                noSpellCheck={true}
                inputLabel={
                  marketplaceInfo ? `https://${marketplaceInfo.domain}` : ""
                }
              />
            </div>
          </Form.Field>
          {!!editCustomTargetURLPathAndQuery && (
            <Form.Field disabled={editing}>
              <Message>
                <p>
                  Here is the target URL for your ad (without any attribution
                  parameters):
                </p>
                <p style={{ wordBreak: "break-all", marginLeft: "1em" }}>
                  <a
                    href={editFinalURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {editFinalURL}
                  </a>
                </p>
                <p>
                  Click on the link to test that it goes to the expected page.
                </p>
              </Message>
            </Form.Field>
          )}
        </Form>
        {!!warningMessage && (
          <Message warning>
            <p>{warningMessage}</p>
            <p>
              If you use this value, there may be problems attributing traffic
              to the correct product.
            </p>
          </Message>
        )}
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          key={submitAttempts}
          primary
          onClick={handleUpdateTargetURLPath}
          loading={isSubmitting}
          disabled={!updateEnabled}
        >
          Update
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

export default AmazonFinalURLEditButton;
