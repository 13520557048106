import React, { useState } from "react";
import { Button, Form, Icon, Message, Segment } from "semantic-ui-react";

import { extractErrorMessage } from "Common/errors/error";
import { Stripe } from "Common/proto/entity/billingAccount_pb";
import { formatMetric } from "Common/utils/metrics";
import { PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useDocuSignClickWrapAgreement from "./useDocuSignClickWrapAgreement";

import { useCreateAndFinalizeSubscriptionMutation } from "ExtensionV2/queries/useCreateAndFinalizeSubscriptionMutation";

import { getCurrencyMetricDef } from "Common/utils/money";

import { AmpdSubscription } from "Common/proto/entity/billingAccount_pb";

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY || ""
);

/* 
  PaymentForm wraps the docusign clickwrap and the stripe payment form,
  both of which are necessary when creating a stripe subscription.
*/
const PaymentForm = ({
  ampdSubscription,
  coupon,
  setupFeeId,
  siteAlias,
  userEmail,
  userName,
  invoice,
  invoiceLoading,
  invoiceError
}: {
  ampdSubscription: AmpdSubscription.AsObject;
  coupon: Stripe.Coupon.AsObject | null;
  setupFeeId: string;
  siteAlias: string;
  userEmail: string;
  userName: string;
  invoice: Stripe.Invoice.AsObject | undefined;
  invoiceLoading: boolean;
  invoiceError: boolean;
}): JSX.Element => {
  const [showClickWrap, setShowClickWrap] = useState(false);
  const [hasAgreedToClickWrap, setHasAgreedToClickWrap] = useState(false);

  let clickwrapId: string | undefined;
  if (ampdSubscription?.contract?.provider === "docusign") {
    clickwrapId =
      process.env.REACT_APP_ENV === "prod"
        ? ampdSubscription.contract?.id
        : process.env.REACT_APP_DOCUSIGN_TEST_CONTRACT_ID;
  }

  useDocuSignClickWrapAgreement({
    showClickWrap,
    email: userEmail,
    userName,
    siteAlias,
    clickwrapId,
    onAgreed: () => {
      setHasAgreedToClickWrap(true);
      setShowClickWrap(false);
    },
    onDeclined: () => {
      setShowClickWrap(false);
    }
  });

  const {
    mutate: createSubscription,
    isLoading: isCreatingSubscription,
    error: createSubscriptionError,
    reset: resetCreateSubscription
  } = useCreateAndFinalizeSubscriptionMutation();

  const needsToSignClickwrap = !!clickwrapId && !hasAgreedToClickWrap;

  return (
    <>
      {!!clickwrapId && (
        <MustSignClickwrapWarning
          hasAgreedToClickWrap={hasAgreedToClickWrap}
          showClickWrap={showClickWrap}
          setShowClickWrap={setShowClickWrap}
          planName={ampdSubscription.name}
        />
      )}

      <Form
        style={{
          marginTop: "2em",
          opacity: needsToSignClickwrap ? 0.4 : 1,
          pointerEvents: needsToSignClickwrap ? "none" : "auto"
        }}
        onSubmit={() => {
          if (needsToSignClickwrap) {
            return;
          }

          resetCreateSubscription();

          createSubscription({
            siteAlias,
            priceId: ampdSubscription.externalId?.id || "",
            couponId: coupon?.id,
            setupFeeId
          });
        }}
      >
        <>
          {/* iFrame loaded from Stripe */}
          <PaymentElement />

          {invoice != null && (
            <SubmitPaymentButton
              invoice={invoice}
              subscription={ampdSubscription}
              isLoading={isCreatingSubscription || invoiceLoading}
              disabled={needsToSignClickwrap || invoiceError}
              needsProAgreement={needsToSignClickwrap}
            />
          )}

          {createSubscriptionError && (
            <Message negative>
              There was a problem completing your payment:
              <p>{extractErrorMessage(createSubscriptionError)}</p>
            </Message>
          )}
        </>
      </Form>
    </>
  );
};

const SubmitPaymentButton = ({
  isLoading,
  disabled,
  needsProAgreement,
  subscription,
  invoice
}: {
  isLoading: boolean;
  disabled: boolean;
  needsProAgreement: boolean;
  subscription: AmpdSubscription.AsObject;
  invoice: Stripe.Invoice.AsObject;
}): JSX.Element => {
  const currencyDef = getCurrencyMetricDef("USD");
  const onboardingFee = subscription?.onboardingFee?.feeCents || 0;
  const subtotal = subscription?.centsPerBillingInterval + onboardingFee;
  const formattedSubtotal = formatMetric(currencyDef, subtotal / 100);

  let submitButtonContent: JSX.Element;
  if (invoice.amountDue != subtotal) {
    const formattedTotal = formatMetric(currencyDef, invoice.amountDue / 100);

    submitButtonContent = (
      <p>
        Pay <s>{formattedSubtotal}</s> {formattedTotal}
      </p>
    );
  } else {
    submitButtonContent = <p>Pay {formattedSubtotal}</p>;
  }

  return (
    <Button
      primary
      type="submit"
      disabled={isLoading || disabled}
      loading={isLoading}
      style={{
        marginTop: "2em",
        width: "100%",
        cursor: needsProAgreement ? "not-allowed" : "pointer",
        textAlign: "center"
      }}
    >
      {submitButtonContent}
    </Button>
  );
};

const MustSignClickwrapWarning = ({
  hasAgreedToClickWrap,
  showClickWrap,
  setShowClickWrap,
  planName
}: {
  hasAgreedToClickWrap: boolean;
  showClickWrap: boolean;
  setShowClickWrap: (show: boolean) => void;
  planName: string;
}): JSX.Element => (
  <Segment
    basic
    compact
    padded={false}
    loading={showClickWrap}
    style={{ margin: 0, padding: 0 }}
  >
    <Message
      onClick={() => setShowClickWrap(true)}
      warning={!hasAgreedToClickWrap}
      success={hasAgreedToClickWrap}
      style={{
        textAlign: "start",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Icon
        size="large"
        style={{ alignSelf: "center" }}
        name={hasAgreedToClickWrap ? "check" : "warning"}
      />

      {hasAgreedToClickWrap ? null : (
        <p style={{ width: "90%", marginTop: "0" }}>
          You must agree to the {planName} Terms and Conditions.{" "}
          <a>Click here</a> to view and agree.
        </p>
      )}
    </Message>
  </Segment>
);

export default PaymentForm;
