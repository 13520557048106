import React, { useCallback, useState } from "react";
import { Icon, Image, Loader, Message } from "semantic-ui-react";

import {
  CampaignSetupAction,
  campaignSetupSelectors,
  CampaignSetupState
} from "./CampaignSetupPageState";

import { Site } from "ExtensionV2/queries/useSession";

import { MICROS_TO_CURRENCY_UNIT_FACTOR } from "Common/utils/money";
import { SuggestGoogleTargetingKeywords } from "./GoogleKeywordsField";
import {
  AddProductRowWrapper,
  ClickableText,
  CurrencyInputField,
  ProductRowInputs,
  ProductRowLabeledField,
  RowNumber,
  RemoveProductRow,
  SpacedUnwrappedRow,
  TightWrappingRow,
  SeedKeywordsSelector,
  SEED_KEYWORDS_COUNT
} from "ExtensionV2/pages/CampaignSetupPage/SelectProductsStage";
import { useWalmartProduct } from "ExtensionV2/queries/useWalmartProduct";
import { useWalmartSearchPage } from "ExtensionV2/queries/useWalmartSearchPage";
import {
  extractWalmartURLInfoFromString,
  getWalmartSearchPageURL,
  getWalmartMarketplace,
  walmartUSMarketplaceInfo
} from "Common/utils/walmart";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import {
  WalmartBrandsDropdown,
  WalmartFacetsDropdown,
  WalmartTargetSelectorDropdown
} from "ExtensionV2/components/WalmartTargetSelector";
import { fullyScrubHtml } from "ExtensionV2/util";

const SelectWalmartProductSection = ({
  campaignIndex,
  site,
  state,
  dispatch
}: {
  campaignIndex: number;
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
}): React.ReactElement => {
  const { siteAlias, adwordsAccounts } = site;
  const { currencyCode } = adwordsAccounts[0] || {};

  const {
    selectMarketplaceInfo,
    selectWalmartProfileName,
    selectWalmartBrands,
    selectWalmartItemId,
    selectWalmartSearchPage,
    selectBudgetMicros,
    selectSeedKeywords
  } = campaignSetupSelectors;

  const campaign = state.campaigns[campaignIndex];
  const walmartProfileName = selectWalmartProfileName(campaign);
  const brands = selectWalmartBrands(campaign);
  const searchPage = selectWalmartSearchPage(campaign);
  const searchPageURLInfo = searchPage
    ? extractWalmartURLInfoFromString(searchPage)
    : undefined;
  const itemId = selectWalmartItemId(campaign);
  const seedKeywords = selectSeedKeywords(campaign);
  const budgetMicros = selectBudgetMicros(campaign);

  const marketplaceInfo = selectMarketplaceInfo(campaign, state);

  const {
    data: walmartProductData,
    isFetching: walmartProductIsFetching,
    error: walmartProductError
  } = useWalmartProduct(
    siteAlias,
    itemId,
    getWalmartMarketplace(marketplaceInfo)
  );

  const [brandHint, setBrandHint] = useState("");

  let brandSearchURL = "";
  if (searchPage) {
    brandSearchURL = searchPage;
  } else {
    brandSearchURL =
      getWalmartSearchPageURL(
        marketplaceInfo,
        brandHint,
        [],
        null,
        null,
        true
      ) || "";
  }

  // Look for brands by searching for the brandHint or searchPage without any filter.
  const {
    data: brandSearchData,
    isFetching: brandSearchIsFetching
  } = useWalmartSearchPage(
    siteAlias,
    brandSearchURL,
    null,
    getWalmartMarketplace(marketplaceInfo)
  );

  // Look for items that result from querying with a given search string.
  const {
    data: searchPageData,
    isFetching: searchPageIsFetching
  } = useWalmartSearchPage(
    siteAlias,
    searchPage,
    null,
    getWalmartMarketplace(marketplaceInfo)
  );

  const handleRemoveCampaign = useCallback(() => {
    dispatch({
      name: "RemoveCampaign",
      data: campaignIndex
    });
  }, [dispatch, campaignIndex]);

  const handleUpdateBudget = useCallback(
    (campaignIndex: number, budgetInput: number) => {
      dispatch({
        name: "UpdateBudget",
        data: {
          index: campaignIndex,
          budgetMicros: budgetInput * MICROS_TO_CURRENCY_UNIT_FACTOR
        }
      });
    },
    [dispatch]
  );

  const handleSelectItemId = useCallback(
    newItemId => {
      dispatch({
        name: "UpdateWalmartItemId",
        data: { index: campaignIndex, itemId: newItemId }
      });
    },
    [dispatch, campaignIndex]
  );

  const handleSelectSearchPage = useCallback(
    newSearchPage => {
      dispatch({
        name: "UpdateWalmartSearchPage",
        data: { index: campaignIndex, searchPage: newSearchPage }
      });

      // Take any brands from the searchPage URL.
      if (newSearchPage) {
        const urlInfo = extractWalmartURLInfoFromString(newSearchPage);
        dispatch({
          name: "UpdateWalmartBrands",
          data: {
            index: campaignIndex,
            brands: urlInfo.searchBrands || []
          }
        });
      }
    },
    [dispatch, campaignIndex]
  );

  const handleSelectBrands = useCallback(
    newBrands => {
      dispatch({
        name: "UpdateWalmartBrands",
        data: { index: campaignIndex, brands: newBrands }
      });

      if (searchPage) {
        const urlInfo = extractWalmartURLInfoFromString(searchPage);
        const newSearchPage = getWalmartSearchPageURL(
          urlInfo.marketplaceInfo,
          urlInfo.searchPhrase,
          newBrands,
          urlInfo.searchOtherFacets,
          urlInfo.searchOtherParameters,
          true
        );

        if (newSearchPage) {
          dispatch({
            name: "UpdateWalmartSearchPage",
            data: {
              index: campaignIndex,
              searchPage: newSearchPage
            }
          });
        }
      }
    },
    [dispatch, campaignIndex, searchPage]
  );

  const handleSelectFacets = useCallback(
    newFacets => {
      if (searchPage) {
        const urlInfo = extractWalmartURLInfoFromString(searchPage);
        const newSearchPage = getWalmartSearchPageURL(
          urlInfo.marketplaceInfo,
          urlInfo.searchPhrase,
          urlInfo.searchBrands,
          newFacets,
          urlInfo.searchOtherParameters,
          true
        );

        if (newSearchPage) {
          dispatch({
            name: "UpdateWalmartSearchPage",
            data: {
              index: campaignIndex,
              searchPage: newSearchPage
            }
          });
        }
      }
    },
    [dispatch, campaignIndex, searchPage]
  );

  const searchImages = searchPageData?.productsList?.filter(
    product => !!product.mainImage
  );
  const productTitle = walmartProductData?.product?.title;
  const productLink = walmartProductData?.product?.link;
  const productImage = walmartProductData?.product?.mainImage?.link;

  return (
    <AddProductRowWrapper>
      <RowNumber>
        <p>{campaignIndex + 1}</p>
      </RowNumber>
      <ProductRowInputs>
        {walmartProductIsFetching && (
          <div>
            <p style={{ fontSize: "small", display: "inline-block" }}>
              Loading your product details
            </p>
            <Loader active inline size="tiny" style={{ margin: "0 1em" }} />
          </div>
        )}
        {!!searchPage && (
          <>
            <h5 style={{ overflowWrap: "anywhere" }}>
              {searchPage}
              <a href={searchPage} target="_blank" rel="noopener noreferrer">
                <Icon style={{ marginLeft: ".5em" }} name="external" />
              </a>
            </h5>
            {searchImages && (
              <div>
                {searchImages.slice(0, 10).map((product, index) => (
                  <SimpleTooltip
                    key={index}
                    hoverable={true}
                    wide="very"
                    tooltip={
                      <div>
                        ({product.itemId}) {product.title}
                        <hr />
                        {fullyScrubHtml(product.description)}
                      </div>
                    }
                  >
                    <Image
                      src={product.mainImage}
                      alt={product.title}
                      style={{ cursor: "pointer" }}
                      inline
                      size="tiny"
                    />
                  </SimpleTooltip>
                ))}
              </div>
            )}
          </>
        )}
        {!searchPage &&
          !walmartProductIsFetching &&
          walmartProductData?.product?.title && (
            <h5>
              <SimpleTooltip
                tooltip={fullyScrubHtml(
                  walmartProductData?.product?.description
                )}
                hoverable={true}
                wide="very"
              >
                <span>
                  {!!productImage && (
                    <Image
                      src={productImage}
                      alt={productTitle}
                      style={{ cursor: "pointer" }}
                      inline
                      size="tiny"
                    />
                  )}
                  {productTitle}
                </span>
              </SimpleTooltip>
              {productLink ? (
                <a href={productLink} target="_blank" rel="noopener noreferrer">
                  <Icon style={{ marginLeft: ".5em" }} name="external" />
                </a>
              ) : null}
            </h5>
          )}
        <SpacedUnwrappedRow>
          <TightWrappingRow>
            <>
              {/* The dropdown for the user to type or paste and item id, */
              /* search phrase, or marketplace URL */}
              <ProductRowLabeledField fieldWidth="15em">
                <WalmartTargetSelectorDropdown
                  marketplaceInfo={marketplaceInfo || walmartUSMarketplaceInfo}
                  profileName={walmartProfileName}
                  itemId={itemId}
                  searchPage={searchPage}
                  brands={brands}
                  onSelectItemId={handleSelectItemId}
                  onSelectSearchPage={handleSelectSearchPage}
                  searchData={
                    searchPage
                      ? searchPageIsFetching
                        ? undefined
                        : searchPageData
                      : undefined
                  }
                />
                <p>
                  <i>Enter Walmart item ID, search phrase, or Walmart URL</i>
                </p>
              </ProductRowLabeledField>
              {/* The dropdown for the brand.  Only show this if no there is no searchPage, */
              /* or if searchPage is a pure search phrase or has a recognized search phrase */
              /* URL component. In particular, don't show the dropdown for "store" or "brand" */
              /* page URLs. */}
              {(!searchPageURLInfo || !!searchPageURLInfo.searchPhrase) && (
                <ProductRowLabeledField fieldWidth="15em">
                  <WalmartBrandsDropdown
                    brandHint={
                      brandHint || walmartProductData?.product?.brand || ""
                    }
                    setBrandHint={setBrandHint}
                    brands={brands}
                    defaultPlaceholder={
                      searchPage ? "Select brand" : "Enter or select brand"
                    }
                    onSelectBrands={handleSelectBrands}
                    brandSearchData={
                      brandSearchIsFetching ? undefined : brandSearchData
                    }
                    brandSearchIsFetching={brandSearchIsFetching}
                  />
                  <p>
                    <i>Select brand(s) to target</i>
                  </p>
                </ProductRowLabeledField>
              )}
              {/* The dropdown for the other facets.  Only show this if is searchPage is a pure */
              /* search phrase or has a recognized search phrase URL component. */}
              {!!searchPageURLInfo?.searchPhrase && (
                <ProductRowLabeledField fieldWidth="15em">
                  <WalmartFacetsDropdown
                    facets={searchPageURLInfo.searchOtherFacets}
                    onSelectFacets={handleSelectFacets}
                    brandSearchData={
                      brandSearchIsFetching ? undefined : brandSearchData
                    }
                    brandSearchIsFetching={brandSearchIsFetching}
                  />
                  <p>
                    <i>Select other search filters</i>
                  </p>
                </ProductRowLabeledField>
              )}
              <ProductRowLabeledField>
                <CurrencyInputField
                  adwordsCurrency={currencyCode}
                  budgetMicros={budgetMicros}
                  campaignIndex={campaignIndex}
                  handleUpdateBudget={handleUpdateBudget}
                  label="Daily ad budget"
                  invalid={campaign.budgetMicrosField.errors.size > 0}
                />
              </ProductRowLabeledField>
              {walmartProductError && (
                <div>
                  <Message
                    style={{ margin: "auto" }}
                    error
                    compact
                    content="There was a problem loading your product details. Please make sure you have entered a valid Walmart item ID and try again."
                  />
                </div>
              )}
            </>
          </TightWrappingRow>
        </SpacedUnwrappedRow>

        {(itemId || searchPage) && (
          <SpacedUnwrappedRow>
            <TightWrappingRow>
              <SeedKeywordsSelector
                campaignIndex={campaignIndex}
                state={state}
                dispatch={dispatch}
              />
            </TightWrappingRow>
          </SpacedUnwrappedRow>
        )}

        {seedKeywords.length >= SEED_KEYWORDS_COUNT && (
          <SuggestGoogleTargetingKeywords
            campaignIndex={campaignIndex}
            site={site}
            state={state}
            dispatch={dispatch}
          />
        )}
      </ProductRowInputs>
      <RemoveProductRow onClick={handleRemoveCampaign}>
        <ClickableText>Remove Product</ClickableText>
      </RemoveProductRow>
    </AddProductRowWrapper>
  );
};

export default SelectWalmartProductSection;
