import React from "react";
import queryString from "query-string";
import { randomString } from "Common/utils/strings";

import LoginWithAmazonButton from "../../Common/components/LoginWithAmazonButton";
import { Amazon } from "Common/proto/common/amazon_pb";
import { getAmazonRegionCode } from "Common/hooks/useLoginWithAmazon";
import { useSession } from "ExtensionV2/queries/useSession";
import { LoadingSpinner } from "Common/components/LoadingSpinner";

// Page demonstrating how we can link an Amazon Advertising account to a site.
export default function LinkAmazonAdvertising({ region }) {
  const { currentSite, connectDataSourceMutation } = useSession();
  const { siteAlias } = currentSite || {};

  const {
    mutate: connectDataSource,
    isLoading: connectDataSourceIsLoading,
    error: connectDataSourceError
  } = connectDataSourceMutation;

  if (connectDataSourceError) {
    console.error(connectDataSourceError);
  }

  const nonce = randomString(16);
  const state = queryString.stringify({
    siteAlias,
    nonce
  });

  const linkAdvertisingAccountToSite = async ({ code }) => {
    // eslint-disable-next-line no-undef
    const amazonLWARegion = amazon.Login.Region;

    const regionMap = {
      [getAmazonRegionCode(amazonLWARegion.NorthAmerica)]: Amazon.Region.Option
        .NORTH_AMERICA,
      [getAmazonRegionCode(amazonLWARegion.Europe)]: Amazon.Region.Option
        .EUROPE,
      [getAmazonRegionCode(amazonLWARegion.AsiaPacific)]: Amazon.Region.Option
        .FAR_EAST
    };

    connectDataSource({
      siteAlias,
      dataSource: {
        amazonAdvertising: {
          regionEnumOption: regionMap[region],
          code
        }
      }
    });
  };

  const onAuthorized = async response => {
    const { state: responseState, code } = response || {};

    if (responseState === state) {
      await linkAdvertisingAccountToSite({ code });
    } else {
      console.error("LinkAmazonAdvertising: state mismatch");
    }
  };

  if (connectDataSourceIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <LoginWithAmazonButton
      scope="advertising::campaign_management profile"
      state={state}
      onAuthorized={onAuthorized}
      region={region}
    />
  );
}
