import _ from "lodash";
import { useParams, useLocation } from "react-router-dom-v5-compat";
import { logout } from "Common/utils/auth";
import { AMPD_APP_PATH_SESSION_STORAGE } from "../ExtensionV2";

function RequireAuth(props) {
  const { children, isAuthenticated = false, siteAlias } = props;

  const location = useLocation();
  const { siteAlias: routeParamSiteAlias } = useParams();

  const pathSegments = location.pathname.split("/");
  const isWelcomePage =
    routeParamSiteAlias === "_" &&
    pathSegments[pathSegments.length - 1] === "welcome";

  const isRouteForDifferentSite =
    !isWelcomePage &&
    !!siteAlias &&
    !!routeParamSiteAlias &&
    !_.isEqual(routeParamSiteAlias, siteAlias);

  // We shouldn't be here if the route is for a different site, but if we are
  // for some reason, just return.
  if (isRouteForDifferentSite) {
    return null;
  }

  // If the user is not logged in, go to the login page, but remember the target
  // siteAlias and the full location URL.
  if (!isAuthenticated) {
    logout();
    return null;
  }

  sessionStorage.setItem(
    AMPD_APP_PATH_SESSION_STORAGE,
    location.pathname + location.search + location.hash
  );

  return children;
}

export default RequireAuth;
