// source: proto/googleAdsPb/adWithAmazonAttribution.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_amazon_pb = require('../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
goog.exportSymbol('proto.googleAdsPb.AdWithAmazonAttribution', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.AdWithAmazonAttribution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.googleAdsPb.AdWithAmazonAttribution.repeatedFields_, null);
};
goog.inherits(proto.googleAdsPb.AdWithAmazonAttribution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.AdWithAmazonAttribution.displayName = 'proto.googleAdsPb.AdWithAmazonAttribution';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.googleAdsPb.AdWithAmazonAttribution.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.AdWithAmazonAttribution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.AdWithAmazonAttribution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdWithAmazonAttribution.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaignStatus: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    adGroupName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adGroupStatus: jspb.Message.getFieldWithDefault(msg, 7, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    finalUrlsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    usesAmpdAttribution: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    unknownTagsInTemplate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    region: jspb.Message.getFieldWithDefault(msg, 19, 0),
    amazonProfileId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    amazonProfileIdStr: jspb.Message.getFieldWithDefault(msg, 21, ""),
    amazonAdvertiserId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    amazonAdvertiserIdStr: jspb.Message.getFieldWithDefault(msg, 14, ""),
    needsAmazonProfileMigration: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    amazonProfileMigration: (f = msg.getAmazonProfileMigration()) && proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.toObject(includeInstance, f),
    defaultAmazonAttributionTags: jspb.Message.getFieldWithDefault(msg, 15, ""),
    ampdAttributionTags: jspb.Message.getFieldWithDefault(msg, 16, ""),
    newTrackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    skipReason: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution}
 */
proto.googleAdsPb.AdWithAmazonAttribution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.AdWithAmazonAttribution;
  return proto.googleAdsPb.AdWithAmazonAttribution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.AdWithAmazonAttribution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution}
 */
proto.googleAdsPb.AdWithAmazonAttribution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalUrls(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsesAmpdAttribution(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnknownTagsInTemplate(value);
      break;
    case 19:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmazonProfileId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonProfileIdStr(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmazonAdvertiserId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonAdvertiserIdStr(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsAmazonProfileMigration(value);
      break;
    case 23:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfileMigration;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.deserializeBinaryFromReader);
      msg.setAmazonProfileMigration(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultAmazonAttributionTags(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmpdAttributionTags(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewTrackingUrlTemplate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkipReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.AdWithAmazonAttribution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.AdWithAmazonAttribution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.AdWithAmazonAttribution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCampaignName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCampaignStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAdGroupName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdGroupStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getFinalUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getTrackingUrlTemplate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUsesAmpdAttribution();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getUnknownTagsInTemplate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getAmazonProfileId();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getAmazonProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAmazonAdvertiserId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getAmazonAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNeedsAmazonProfileMigration();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getAmazonProfileMigration();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.serializeBinaryToWriter
    );
  }
  f = message.getDefaultAmazonAttributionTags();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAmpdAttributionTags();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNewTrackingUrlTemplate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSkipReason();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 campaign_id = 2;
 * @return {number}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string campaign_name = 3;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string campaign_status = 4;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getCampaignStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setCampaignStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 ad_group_id = 5;
 * @return {number}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string ad_group_name = 6;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAdGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAdGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ad_group_status = 7;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAdGroupStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAdGroupStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 ad_id = 8;
 * @return {number}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAdId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAdId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated string final_urls = 9;
 * @return {!Array<string>}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getFinalUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setFinalUrlsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.addFinalUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.clearFinalUrlsList = function() {
  return this.setFinalUrlsList([]);
};


/**
 * optional string tracking_url_template = 10;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool uses_ampd_attribution = 11;
 * @return {boolean}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getUsesAmpdAttribution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setUsesAmpdAttribution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string unknown_tags_in_template = 12;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getUnknownTagsInTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setUnknownTagsInTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional common.Amazon.Region.Option region = 19;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional int64 amazon_profile_id = 20;
 * @return {number}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAmazonProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAmazonProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string amazon_profile_id_str = 21;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAmazonProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAmazonProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int64 amazon_advertiser_id = 13;
 * @return {number}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAmazonAdvertiserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAmazonAdvertiserId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string amazon_advertiser_id_str = 14;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAmazonAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAmazonAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool needs_amazon_profile_migration = 22;
 * @return {boolean}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getNeedsAmazonProfileMigration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setNeedsAmazonProfileMigration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional common.Amazon.AdvertisingProfileMigration amazon_profile_migration = 23;
 * @return {?proto.common.Amazon.AdvertisingProfileMigration}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAmazonProfileMigration = function() {
  return /** @type{?proto.common.Amazon.AdvertisingProfileMigration} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfileMigration, 23));
};


/**
 * @param {?proto.common.Amazon.AdvertisingProfileMigration|undefined} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
*/
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAmazonProfileMigration = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.clearAmazonProfileMigration = function() {
  return this.setAmazonProfileMigration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.hasAmazonProfileMigration = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string default_amazon_attribution_tags = 15;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getDefaultAmazonAttributionTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setDefaultAmazonAttributionTags = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ampd_attribution_tags = 16;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getAmpdAttributionTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setAmpdAttributionTags = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string new_tracking_url_template = 17;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getNewTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setNewTrackingUrlTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string skip_reason = 18;
 * @return {string}
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.getSkipReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution} returns this
 */
proto.googleAdsPb.AdWithAmazonAttribution.prototype.setSkipReason = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


goog.object.extend(exports, proto.googleAdsPb);
