import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetWalmartProductRequest,
  GetWalmartProductReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { Walmart } from "Common/proto/common/walmart_pb";

export const fetchWalmartProduct = async (
  siteAlias: string,
  itemId: string,
  marketplace: Walmart.Marketplace.Option
): Promise<GetWalmartProductReply.AsObject> => {
  const req = new GetWalmartProductRequest();
  req.setSiteAlias(siteAlias);
  req.setItemId(itemId);
  req.setMarketplace(marketplace);

  const reply = await GRPCWebClient.getWalmartProduct(req, {});
  return reply.toObject();
};

export const fetchWalmartProductQueryKey = (
  siteAlias: string,
  itemId: string,
  marketplace: Walmart.Marketplace.Option
): Array<string | number> => {
  return ["walmartProduct", siteAlias, itemId, marketplace];
};

// Executes a realtime Rainforest scrape for any Walmart product.
export const useWalmartProduct = (
  siteAlias: string,
  itemId: string,
  marketplace: Walmart.Marketplace.Option
): UseQueryResult<GetWalmartProductReply.AsObject, unknown> => {
  const oneDay = 1000 * 60 * 60 * 24; //one day
  return useQuery({
    queryKey: fetchWalmartProductQueryKey(siteAlias, itemId, marketplace),
    staleTime: oneDay,
    cacheTime: oneDay,
    enabled:
      !!siteAlias &&
      !!itemId &&
      marketplace != Walmart.Marketplace.Option.UNKNOWN,
    queryFn: async () => fetchWalmartProduct(siteAlias, itemId, marketplace)
  });
};
