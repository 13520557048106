// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { LinkClientSiteToManagerSiteRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";

// Adds a  manager-client link between the specified sites.
export async function linkClientSiteToManagerSite(
  clientSiteAlias,
  managerSiteAlias,
  labels
) {
  if (!managerSiteAlias || !clientSiteAlias) {
    return undefined;
  }

  let details;
  if (labels && labels.length > 0) {
    details = proto.set(new SiteManagerLinkClient.Details(), {
      labels: labels
    });
  }

  const linkRequest = proto.set(new LinkClientSiteToManagerSiteRequest(), {
    managerSiteAlias,
    clientSiteAlias,
    detailsAboutClient: details
  });

  const response = await GRPCWebClient.linkClientSiteToManagerSite(linkRequest);
  return response?.toObject();
}
