import React from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import styled from "styled-components";

import {
  AMPD_USER_EMAIL,
  UIChangeHistoryItem
} from "../queries/useChangeHistory";

const EditIconTrigger = styled.span`
  cursor: pointer;
  margin-left: 0.5em;
  position: relative;
  z-index: 3; // Same as .ui.search.dropdown > .text
`;

export const EditIconWithHistory: React.FC<{
  disabled: boolean;
  readonly?: boolean;
  historyOpen: boolean;
  setHistoryOpen: (open: boolean) => void;
  historyItems: Array<UIChangeHistoryItem> | null;
}> = ({ disabled, readonly, historyOpen, setHistoryOpen, historyItems }) => {
  return (
    <Popup
      trigger={
        <EditIconTrigger>
          <Icon
            name={readonly ? "info" : "pencil"}
            circular={readonly ? true : undefined}
            size={readonly ? "tiny" : undefined}
          />
        </EditIconTrigger>
      }
      disabled={disabled}
      position="bottom center"
      on="hover"
      hoverable={true}
      size="small"
      wide="very"
      open={historyOpen && !!historyItems}
      onOpen={() => setHistoryOpen(true)}
      onClose={() => setHistoryOpen(false)}
    >
      {!historyItems?.length ? (
        <small>No changes recorded</small>
      ) : (
        <div style={{ width: "21em", maxHeight: "15em", overflow: "auto" }}>
          <HistoryList historyItems={historyItems} />
        </div>
      )}
    </Popup>
  );
};
const HistoryList: React.FC<{
  historyItems: Array<UIChangeHistoryItem> | null;
}> = ({ historyItems }) => {
  if (!historyItems) {
    return null;
  }

  return (
    <small>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ paddingTop: 0, paddingBottom: 0 }}
              textAlign="right"
            >
              Change
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ paddingTop: 0, paddingBottom: 0 }}
              textAlign="left"
            >
              Date
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {historyItems.map((item, index) => {
            const time = item.changeTime;

            return (
              <Table.Row key={index}>
                <Table.Cell textAlign="right">{item.text}</Table.Cell>
                <Table.Cell>
                  {time && time.format("ddd, MMM Do YYYY")}
                  {/* Don't bother showing the email address if it is our default email. */
                  !!item.userEmail && item.userEmail !== AMPD_USER_EMAIL && (
                    <p>by {item.userEmail}</p>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </small>
  );
};
