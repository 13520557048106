import Immutable from "immutable";

import React, { useRef, useState } from "react";
import { Button, Checkbox, Input, List, Modal } from "semantic-ui-react";

import { campaignDropdown } from "../../styles/zIndexes";
import OneClickButton from "Common/components/OneClickButton";
import { compareCaseInsens } from "Common/utils/strings";
import { extractErrorMessage } from "Common/errors/error";

function EditLabelsButton({
  buttonLabel,
  buttonColor,
  title,
  managerSiteAlias,
  clientSiteAliases,
  siteLabelsByAlias,
  knownLabels,
  updateClientLabels
}) {
  const [labelCountMap, setLabelCountMap] = useState(Immutable.Map());
  const maxLabelCount = clientSiteAliases.length;

  const [modalOpen, setModalOpen] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [editText, setEditText] = useState("");
  const inputRef = useRef(null);

  const handleModalOpen = () => {
    let newLabelCountMap = Immutable.Map();

    knownLabels.forEach(label => {
      let labelCount = 0;

      clientSiteAliases.forEach(alias => {
        if ((siteLabelsByAlias[alias] || []).includes(label)) {
          labelCount += 1;
        }
      });

      newLabelCountMap = newLabelCountMap.set(label, labelCount);
    });

    setLabelCountMap(newLabelCountMap);

    setModalOpen(true);
    setCanUpdate(false);
  };

  const handleUpdateLabels = async () => {
    if (!updateClientLabels) {
      setModalOpen(false);
      return;
    }

    await Promise.all(
      clientSiteAliases.map(async clientSiteAlias => {
        const siteLabels = siteLabelsByAlias[clientSiteAlias] || [];
        const addLabels = [];
        const removeLabels = [];

        labelCountMap.forEach((labelCount, label) => {
          if (labelCount === maxLabelCount) {
            if (!siteLabels.includes(label)) {
              addLabels.push(label);
            }
          } else if (labelCount === 0) {
            if (siteLabels.includes(label)) {
              removeLabels.push(label);
            }
          } else {
            // Don't do anything if the label count is partial;
          }
        });

        if (addLabels.length > 0 || removeLabels.length > 0) {
          updateClientLabels({
            managerSiteAlias,
            clientSiteAlias,
            addLabels,
            removeLabels
          });
        }
      })
    ).catch(e => {
      const message = extractErrorMessage(e);
      console.error(message);
    });

    setModalOpen(false);
  };

  const handleLabelClick = (label, checked) => {
    if (checked) {
      setLabelCountMap(labelCountMap.set(label, maxLabelCount));
    } else {
      setLabelCountMap(labelCountMap.set(label, 0));
    }
    setCanUpdate(true);
  };
  const handleInputChange = (e, { value }) => {
    if (value !== editText) {
      setEditText(value);
    }
  };

  const handleInputKeyPress = e => {
    e.stopPropagation();
    if (e.keyCode === 13 || e.which === 13) {
      const elem = e.target;
      elem.blur();
    }
  };

  const handleInputBlur = () => {
    if (editText !== null) {
      if (editText.trim().length > 0) {
        const newLabels = editText.split(",").map(s => s.trim());
        let newLabelCountMap = labelCountMap;
        newLabels.forEach(label => {
          newLabelCountMap = newLabelCountMap.set(label, maxLabelCount);
        });

        setLabelCountMap(newLabelCountMap);
      }

      setEditText("");
      setCanUpdate(true);
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }
  };

  const sortedLabels = labelCountMap
    .keySeq()
    .toArray()
    .sort(compareCaseInsens);

  return (
    <>
      <Modal
        style={{ zIndex: campaignDropdown }}
        size="tiny"
        onOpen={handleModalOpen}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        position="bottom left"
        closeOnDimmerClick={false}
        trigger={<Button color={buttonColor}>{buttonLabel}</Button>}
      >
        <Modal.Header>{title || "Edit Labels"}</Modal.Header>
        <Modal.Content>
          <Input
            ref={inputRef}
            fluid
            icon="add"
            autoFocus={true}
            iconPosition="left"
            placeholder="add labels"
            value={editText}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            onBlur={handleInputBlur}
          />
          <div
            style={{
              marginTop: ".5em",
              width: "100%",
              height: "21em",
              overflow: "auto"
            }}
          >
            <List>
              {sortedLabels.map(label => {
                const labelCount = labelCountMap.get(label);

                return (
                  <List.Item key={label}>
                    <Checkbox
                      label={label}
                      checked={labelCount === maxLabelCount}
                      indeterminate={
                        labelCount > 0 && labelCount < maxLabelCount
                      }
                      onClick={(e, { checked }) =>
                        handleLabelClick(label, checked)
                      }
                    />
                  </List.Item>
                );
              })}
            </List>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button size="mini" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <OneClickButton
            disabled={!canUpdate}
            size="mini"
            positive={true}
            onClick={handleUpdateLabels}
          >
            Update
          </OneClickButton>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default EditLabelsButton;
