import createTheme from "styled-components-theme";
import styled, { createGlobalStyle } from "styled-components/macro";

export const Colors = {
  primary: "#F93822",
  // This is the recommend "dark theme" surface color from Material Design.
  // https://material.io/design/color/dark-theme.html
  secondary: "#121212",
  primaryAction: "#FF9842",
  current: "#4e79a7",
  previous: "#dcdcdc",
  red: "#FF5B48",
  orange: "#f2711c",
  yellow: "#fbbd08",
  olive: "#b5cc18",
  green: "#00B15B",
  teal: "#00b5ad",
  blue: "#2155b0",
  violet: "#6435c9",
  purple: "#a333c8",
  pink: "#e03997",
  brown: "#a5673f",
  lightGrey: "#FAFAFA",
  grey: "#CCCCCC",
  black: "#000000",
  white: "#FFFFFF",

  chartCurrent: "#0277BD",
  chartPrevious: "#999",

  positive: "#000",
  negative: "#FF5B48",

  border: "rgba(0, 0, 0, 0.13)",
  solidBorder: "#DDDDDD",
  internalBorder: "rgba(0, 0, 0, 0.06)",
  selectedBorder: "#51A7E8",

  link: "#4078C0",
  linkHover: "#4078C0",

  text: "rgba(0, 0, 0, 0.87)"
};

export const SIDEBAR_WIDTH = "240px";

export const Theme = createTheme(...Object.keys(Colors));

export const MainContainer = styled.main`
  height: 100%;
  grid-area: main;
  display: grid;
  grid-template-areas: ${props =>
    props.isAuthenticated ? '"header" "content"' : '"content"'};
  grid-template-columns: 1fr;
  grid-template-rows: ${props => (props.isAuthenticated ? "70px 1fr" : "1fr")};
  overflow: auto;
  .content {
    grid-area: content;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    background-color: #fafafa;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
  }

  #root {
    height: 100vh;
  }

  a {
    color: #005c9c;
    &:hover {
      color: #005c9c;
      text-decoration: underline;
    }
  }

  .button-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #005c9c;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
    &:hover {
      color: #005c9c;
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  .ui.button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  .ui.left.sidebar,
  .ui.right.sidebar {
    width: ${SIDEBAR_WIDTH};
  }
  
  .ui.visible.left.sidebar ~ .fixed,
  .ui.visible.left.sidebar ~ .pusher {
    -webkit-transform: translate3d(${SIDEBAR_WIDTH}, 0, 0);
    transform: translate3d(${SIDEBAR_WIDTH}, 0, 0);
  }
  
  .highcharts-tooltip {
    > span {
      width: initial !important;
    }
  }

  #notification-wrapper {
    position: fixed;
    top: 0;
    z-index: 99999;
  }
`;
