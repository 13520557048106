import {
  AMAZON_ADS_CHANNEL,
  GOOGLE_ADS_CHANNEL,
  ORGANIC_CHANNEL,
  SELLER_CHANNEL,
  allChannelTypes,
  allMetricsTypes
} from "./metricsConfigs";
import { getAggregate } from "../../queries/useAsinPerformanceMetricsByKey";
import { ampdRed } from "../../styles/colors";

// This controls which channels of data are shown or supported by the chart.
// Colors are configured for Performance By Channel chart consistency.
export const supportedChannelTypes = {
  [AMAZON_ADS_CHANNEL]: allChannelTypes.AMAZON_ADS,
  [GOOGLE_ADS_CHANNEL]: allChannelTypes.GOOGLE_ADS,
  [ORGANIC_CHANNEL]: allChannelTypes.ORGANIC,
  [SELLER_CHANNEL]: allChannelTypes.SELLER
};

export const performanceByChannelColor = {
  [AMAZON_ADS_CHANNEL]: "orange",
  [GOOGLE_ADS_CHANNEL]: ampdRed,
  [ORGANIC_CHANNEL]: "green",
  [SELLER_CHANNEL]: "black"
};

// This controls which metrics are supported by the chart & configures them
// for showing. To add a new metric into the chart, just configure it here.
// Notes:
//   - All metricDefs are called with currency, but not all use it.
//   - Smoothing uses full timeseries for each numerator & denominator
//   - "summable" indicates whether highcharts can group via sum. Otherwise, average.
export const supportedChartMetricTypes = {
  TACOS: { ...allMetricsTypes.TACOS },
  REVENUE: { ...allMetricsTypes.REVENUE },
  COST: { ...allMetricsTypes.COST },
  CONVERSIONS: { ...allMetricsTypes.CONVERSIONS },
  UNITS_SOLD: { ...allMetricsTypes.UNITS_SOLD },
  IMPRESSIONS: { ...allMetricsTypes.IMPRESSIONS },
  CLICKS: { ...allMetricsTypes.CLICKS },
  CPC: { ...allMetricsTypes.CPC },
  CTR: { ...allMetricsTypes.CTR },
  CARTS: { ...allMetricsTypes.CARTS }
};

// Returns whether the metrics revenue data indicates that the Seller's
// Total Revenue aggregate is less than the combined Ads Revenues aggregates.
// This is a valid data situation, but can be a confusing user experience.
// This can happen for two known reasons:
//  1. Some of the ASINs are not being aggregated properly. Either their variant
//      data is not all available or some variants are missing from the parent.
//  2. This advertised product converts well, but the customers coming in on
//      this product end up buying a completely different product from the Brand.
export const determineIsRevenueMisaligned = aggregates => {
  const amazonSource = supportedChannelTypes[AMAZON_ADS_CHANNEL].sourceField;
  const googleSource = supportedChannelTypes[GOOGLE_ADS_CHANNEL].sourceField;
  const sellerSource = supportedChannelTypes[SELLER_CHANNEL].sourceField;

  let amazonRevenue = 0;
  let googleRevenue = 0;
  let sellerRevenue = 0;
  supportedChartMetricTypes.REVENUE.channels.forEach(channel => {
    const metricField = channel.metricField;

    switch (channel.channelType.key) {
      case AMAZON_ADS_CHANNEL:
        amazonRevenue = getAggregate(aggregates[amazonSource][metricField]);
        break;
      case GOOGLE_ADS_CHANNEL:
        googleRevenue = getAggregate(aggregates[googleSource][metricField]);
        break;
      case SELLER_CHANNEL:
        sellerRevenue = getAggregate(aggregates[sellerSource][metricField]);
        break;
    }
  });

  return sellerRevenue < amazonRevenue + googleRevenue;
};

// Returns true if all expected channel metrics are found, or false if expected
// metrics are missing. Checks are not exhaustive due to the amount of data, but
// this checks valuable points where data is likely.
export const determineHasChannelMetrics = metrics => {
  const hasMetrics = Object.values(supportedChannelTypes)?.every(channel => {
    const sourceField = channel.sourceField;
    return (
      metrics.timeseries?.[sourceField] &&
      metrics.dateRangeAggregates?.[sourceField]
    );
  });

  if (!hasMetrics) {
    return false;
  }

  // Has ANY key data to show
  return ["TACOS", "REVENUE", "COST", "IMPRESSIONS"].some(metric => {
    return supportedChartMetricTypes[metric].channels?.some(
      ({ channelType, metricField }) => {
        const source = channelType.sourceField;
        const agg = metrics?.dateRangeAggregates?.[source]?.[metricField] ?? 0;

        return getAggregate(agg) > 0;
      }
    );
  });
};

// Returns true if any SearchTerm/Category Ranks data is found in the metrics.
export const determineHasRanks = metrics =>
  metrics?.timeseries?.ranks?.searchTermRanksList?.length ||
  metrics?.timeseries?.ranks?.categoryRanksList?.length;
