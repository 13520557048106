// Creates an emailInfo object for the siteProblem.
import { AdWordsAccount } from "Common/proto/common/dataSourceInfo_pb";
// eslint-disable-next-line no-unused-vars
import { Site } from "ExtensionV2/queries/useSession";
// eslint-disable-next-line no-unused-vars
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import _ from "lodash";

export const makeSiteProblemEmailInfo = (siteAlias, siteProblem) => {
  return {
    subject: "Ampd is having trouble connecting to my Google Ads account",
    body:
      "I received a notice that Ampd is having trouble connecting to my Google Ads account. " +
      `Please assist me.

Message: ${_.get(siteProblem, "message", "(none)")}
`,
    siteAlias
  };
};

// Returns the platform for the current site in the current page.
//
// 1. Are they on a specific platform page?
// 2. Have they connected to a platform?
// 3. Have they self-reported that they use a particular platform?
export const getPlatform = () => {
  return "web";
};

// Returns the site label for the site. If the platform is left
// unspecified, that is auto-detected.
export const getSiteLabel = ({ site, platform }) => {
  if (!platform) {
    platform = getPlatform(site);
  }

  return platform === "shopify"
    ? "shop"
    : platform === "bigcommerce"
    ? "store"
    : "site";
};

/**
 * Returns whether the site has at least one Amazon advertising account.
 * @param {Site}  site
 * @returns {boolean}
 */
export const siteHasAmazonAdvertisingAccount = site => {
  if (!site?.amazonInfo?.advertisingAccounts?.length) {
    return false;
  }

  return true;
};

/**
 * Returns whether the site has a valid Google Ads account.
 * @param {Site}  site
 * @returns {boolean}
 */
export const siteHasValidGoogleAdsAccount = site => {
  const googleAdsAccount = site?.adwordsAccounts[0];

  if (!googleAdsAccount || !googleAdsAccount.customerId) {
    return false;
  }

  // If no invitation is present, assume the GA account is valid, otherwise
  // check the invitation status.
  if (!googleAdsAccount.invitation) {
    return true;
  }

  return (
    googleAdsAccount.invitation.statusEnumOption ===
      AdWordsAccount.Invitation.Status.IS_MEMBER ||
    googleAdsAccount.invitation.statusEnumOption ===
      AdWordsAccount.Invitation.Status.NONE
  );
};

/**
 * Description placeholder
 * @param {Site}  site
 * @param {Array<SiteManagerLinkClient.AsObject>}  clientSiteList
 * @returns {boolean}
 */
export const isManagerSite = (site, clientSiteList) => {
  if (!site || !clientSiteList) {
    return false;
  }

  if (site.siteAlias.endsWith("-mgr")) {
    return true;
  }

  if (clientSiteList.length && clientSiteList.length > 0) {
    return true;
  }

  return false;
};
