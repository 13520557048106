import moment from "moment";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from "react";
import { Button, Checkbox, Divider } from "semantic-ui-react";
import styled from "styled-components";

import { AMAZON_ATTRIBUTION_DELAY_DAYS } from "Common/utils/amazon";
import {
  getStoredExcludeLagPeriod,
  getStoredShowFractions,
  getStoredShowUnconvertedRevenue,
  setStoredShowFractions,
  setStoredShowUnconvertedRevenue
} from "Common/utils/savedTablePreferences";
import { useWantsProOperatorFeatures } from "Common/utils/featureFlags";
import { AMPD_PRO_ONLY_COLUMNS } from "./MetricColumns";
import { WantsExcludeAmazonLagPeriodContext } from "ExtensionV2";

const TableOptionsWindow = styled.div`
  width: 35em;
  height: 25em;
  overflow-y: auto;
`;
/**
 * @typedef {Object} AmpdDataTableOptionsProps
 * @property {Map<string, boolean>} columnOptionsMap - Map of column names to their visibility status.
 * @property {Object<string, string | React.Element>} columnTitles - Map of column names to their display names.
 * @property {(columnName: string, isChecked: boolean) => void} onToggleColumn - Function to handle toggling the visibility of a column.
 * @property {(isChecked: boolean) => void} onToggleShowFractions - Function to handle toggling the visibility of fractions.
 * @property {(isChecked: boolean) => void} onToggleShowUnconvertedRevenue - Function to handle toggling the visibility of unconverted revenue values.
 * @property {() => void} onResetTableOptions - Function to reset all table options.
 * @property {boolean} showFractions - Flag indicating whether to show fractions.
 * @property {boolean} showUnconvertedRevenue - Flag indicating whether to show unconverted revenue values.
 * @property {boolean} [showAmazonLagPeriodToggle=true] - Flag indicating whether the Amazon lag period config is visible.
 * @property {boolean} [showFractionsToggle=true] - Flag indicating whether the allow fractions config is visible.
 * @property {boolean} [showUnconvertedRevenueToggle=true] - Flag indicating whether the unconverted revenue config is visible.
 * @param {AmpdDataTableOptionsProps} props
 */
const AmpdDataTableOptions = ({
  columnOptionsMap,
  columnTitles,
  onToggleColumn,
  onToggleShowFractions,
  onToggleShowUnconvertedRevenue,
  onResetTableOptions,
  showFractions,
  showUnconvertedRevenue,
  showAmazonLagPeriodToggle = true,
  showFractionsToggle = true,
  showUnconvertedRevenueToggle = true
}) => {
  const [excludeAmazonLagPeriod, setExcludeAmazonLagPeriod] = useContext(
    WantsExcludeAmazonLagPeriodContext
  );

  const amazonIncompleteDataDate = moment()
    .startOf("day")
    .subtract(AMAZON_ATTRIBUTION_DELAY_DAYS + 1, "days");

  return (
    <TableOptionsWindow>
      {showAmazonLagPeriodToggle && (
        <>
          <div>
            <Checkbox
              id="exclude-incomplete-data"
              toggle
              label={`Exclude incomplete data (after ${amazonIncompleteDataDate.format(
                "MMM Do YYYY"
              )}) *`}
              onChange={(_e, data) => setExcludeAmazonLagPeriod(data.checked)}
              checked={excludeAmazonLagPeriod}
            />
          </div>
          <p style={{ margin: "0.5em" }}>
            <i>
              *Amazon can take up to 2 days to make sales data available. Data
              from today and yesterday should be considered incomplete.
            </i>
          </p>
        </>
      )}
      {showFractionsToggle && (
        <div>
          <Checkbox
            id="show-fractions"
            toggle
            label="Show fractions"
            onChange={(_e, data) => onToggleShowFractions(data.checked)}
            checked={showFractions}
          />
        </div>
      )}
      {showUnconvertedRevenueToggle && (
        <>
          <div style={{ marginTop: "1em" }}>
            <Checkbox
              id="show-unconverted-revenue"
              toggle
              label="Show unconverted revenue values"
              onChange={(_e, data) =>
                onToggleShowUnconvertedRevenue(data.checked)
              }
              checked={showUnconvertedRevenue}
            />
          </div>
          <p style={{ margin: "0.5em" }}>
            <i>
              Amazon revenue values are approximately converted to the Google
              Ads account's currency, if different from the Amazon marketplace's
              currency.
            </i>
          </p>
        </>
      )}

      <Divider />
      <div>
        <ul
          style={{
            listStyleType: "none",
            columns: 2,
            padding: 0
          }}
        >
          {[...columnOptionsMap.keys()].map(columnName => (
            <li key={columnName} style={{ marginBottom: "0.5em" }}>
              <Checkbox
                label={columnTitles[columnName] || columnName}
                onChange={(_e, { checked }) =>
                  onToggleColumn(columnName, checked)
                }
                checked={columnOptionsMap.get(columnName)}
              />
            </li>
          ))}
        </ul>
      </div>
      <Divider />
      <div>
        <Button
          style={{ float: "right", marginRight: "0.5em" }}
          onClick={() => onResetTableOptions()}
        >
          Restore Default Table Options
        </Button>
      </div>
    </TableOptionsWindow>
  );
};

/**
 * Returns the current table options based on the set of columns and local storage handlers
 * specified in tableOptionsArgs.  NOTE: All the arrays and handlers specified in tableOptionsArgs
 * should be stable (globals or effectively globals) because they are used as React dependencies.
 *
 * @typedef {Object} UseAmpdDataTableOptionsArgs
 * @property {string[]} allColumns - All the selectable column names.
 * @property {string[]} defaultColumns - The default set of selected column names.
 * @property {string[]} unhideableColumns - The column names that cannot be unselected.
 * @property {() => string[]} getStoredDataTableColumns - function to current set selected column names.
 * @property {(columns: string[]) => void} setStoredDataTableColumns - function to save the current set selected column names.
 * @property {() => void} resetAllDataTableOptions - function to reset table options to the default values.
 * @param {UseAmpdDataTableOptionsArgs} tableOptionsArgs
 */
export const useAmpdDataTableOptions = ({
  allColumns,
  defaultColumns,
  unhideableColumns,
  getStoredDataTableColumns,
  setStoredDataTableColumns,
  resetAllDataTableOptions
}) => {
  const [showFractions, setShowFractions] = useState(getStoredShowFractions());
  const [showUnconvertedRevenue, setShowUnconvertedRevenue] = useState(
    getStoredShowUnconvertedRevenue()
  );

  const [excludeAmazonLagPeriod, setExcludeAmazonLagPeriod] = useContext(
    WantsExcludeAmazonLagPeriodContext
  );

  const wantsProOperatorFeatures = useWantsProOperatorFeatures();

  const [storedColumns, setStoredColumns] = useState(
    getStoredDataTableColumns()
  );

  useEffect(() => {
    setStoredColumns(getStoredDataTableColumns());
  }, [getStoredDataTableColumns]);

  // Rebuild the column options map if any of the input args change.
  const columnOptionsMap = useMemo(() => {
    return generateInitialColumnsMap(
      allColumns,
      storedColumns,
      defaultColumns,
      unhideableColumns,
      wantsProOperatorFeatures ? [] : AMPD_PRO_ONLY_COLUMNS
    );
  }, [
    allColumns,
    defaultColumns,
    storedColumns,
    unhideableColumns,
    wantsProOperatorFeatures
  ]);

  const selectableOptionsMap = useMemo(() => {
    const selectableOptions = new Map(columnOptionsMap);
    if (unhideableColumns) {
      unhideableColumns.forEach(column => selectableOptions.delete(column));
    }
    return selectableOptions;
  }, [columnOptionsMap, unhideableColumns]);

  const selectedColumns = useMemo(
    () => [...columnOptionsMap.keys()].filter(key => columnOptionsMap.get(key)),
    [columnOptionsMap]
  );

  const handleSelectColumnOption = useCallback(
    (columnName, checked) => {
      const updateColumns = [
        ...columnOptionsMap.set(columnName, checked).keys()
      ].filter(key => columnOptionsMap.get(key));

      setStoredColumns(updateColumns);
      setStoredDataTableColumns(updateColumns);
    },
    [columnOptionsMap, setStoredDataTableColumns]
  );

  const handleResetTableOptions = useCallback(() => {
    resetAllDataTableOptions();

    setShowFractions(getStoredShowFractions());
    setShowUnconvertedRevenue(getStoredShowUnconvertedRevenue());
    setExcludeAmazonLagPeriod(getStoredExcludeLagPeriod());
    setStoredColumns(defaultColumns);
  }, [defaultColumns, resetAllDataTableOptions, setExcludeAmazonLagPeriod]);

  const handleToggleShowFraction = useCallback(showFractions => {
    setStoredShowFractions(showFractions);
    setShowFractions(showFractions);
  }, []);

  const handleToggleShowUnconvertedRevenue = useCallback(
    showUnconvertedRevenue => {
      setStoredShowUnconvertedRevenue(showUnconvertedRevenue);
      setShowUnconvertedRevenue(showUnconvertedRevenue);
    },
    []
  );

  return {
    showFractions,
    showUnconvertedRevenue,
    excludeAmazonLagPeriod,
    selectableOptionsMap,
    selectedColumns,
    handleSelectColumnOption,
    handleResetTableOptions,
    handleToggleShowFraction,
    handleToggleShowUnconvertedRevenue,
    handleToggleExcludeAmazonLagPeriod: setExcludeAmazonLagPeriod
  };
};

// Try to pull the user's selected columns from LocalStorage, or
// fallback to the default list.
const generateInitialColumnsMap = (
  allColumns,
  storedColumns,
  defaultColumns,
  unhidableColumns,
  excludeColumns
) => {
  const orderedDefaultColumnOptions = new Map([
    ...allColumns
      .map(col => (excludeColumns.includes(col) ? null : [col, false]))
      .filter(Boolean)
  ]);

  for (const column of unhidableColumns) {
    if (orderedDefaultColumnOptions.has(column)) {
      orderedDefaultColumnOptions.set(column, true);
    }
  }

  const selectedColumns =
    storedColumns && storedColumns.length ? storedColumns : defaultColumns;

  for (const column of selectedColumns) {
    if (orderedDefaultColumnOptions.has(column)) {
      orderedDefaultColumnOptions.set(column, true);
    }
  }

  return orderedDefaultColumnOptions;
};

export default AmpdDataTableOptions;
