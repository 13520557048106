import { Table } from "semantic-ui-react";
import React from "react";
import gql from "graphql-tag";

import { renderUserName } from "Common/utils/user";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { UserEditRole } from "Common/components/UserEditRole";

export const SiteUsers = gql`
  query($siteAlias: String!) {
    site(site: { siteAlias: $siteAlias }) {
      siteUsers {
        user {
          id
          email
          profile {
            givenName
            familyName
          }
        }
        siteRole
      }
    }
  }
`;

// TODO: Update SiteUsersTable to handle the new data shape then drop this reshaping code below
export const BackwardsCompatSiteUsersTable = ({
  siteAlias,
  loggedInUserId,
  isCurrentSiteAdmin,
  siteUsersApolloQuery
}) => {
  let oldFormatSession = {
    user: {
      id: loggedInUserId
    }
  };
  return (
    <SiteUsersTable
      siteAlias={siteAlias}
      session={oldFormatSession}
      isSiteAdmin={isCurrentSiteAdmin}
      siteUsersApolloQuery={siteUsersApolloQuery}
    />
  );
};

export const SiteUsersTable = ({
  siteAlias,
  session,
  siteUsersApolloQuery,
  isSiteAdmin
}) => {
  if (siteUsersApolloQuery.loading) {
    return <LoadingSpinner />;
  }

  if (siteUsersApolloQuery.error) {
    console.error(siteUsersApolloQuery.error);
  }

  return (
    <Table compact celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {(siteUsersApolloQuery?.data?.site?.siteUsers || []).map(
          ({ user, siteRole }) => (
            <Table.Row key={user.id}>
              <Table.Cell collapsing>{renderUserName(user, true)}</Table.Cell>
              <Table.Cell>
                <UserEditRole
                  session={session}
                  siteAlias={siteAlias}
                  user={user}
                  currentRole={siteRole}
                  disabled={!isSiteAdmin}
                />
              </Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
};

export default SiteUsersTable;
