import { KeywordMatchType } from "Common/proto/entity/action_pb";
import {
  UpdateCampaignAction,
  UpdateCampaignsRequest,
  UpdateCampaignsReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { NewKeyword } from "Common/proto/googleAdsPb/keyword_pb";
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from "@tanstack/react-query";
import { GRPCWebClient } from "Common/utils/grpc";
import { useSessionSite } from "./useSessionSite";
import { Error, extractErrorMessage } from "Common/errors/error";
import { sendGAEvent } from "../components/GA";

type AddKeywordsMutationFnArgs = {
  keywordsToAdd: Array<string>;
  adGroupId: string;
  siteAlias: string;
  customerId: string;
  gaCategory?: string;
};
export const useAddKeywordsToCampaignMutation = (): UseMutationResult<
  UpdateCampaignsReply.AsObject,
  Error,
  AddKeywordsMutationFnArgs,
  unknown
> => {
  const { siteAlias } = useSessionSite();
  const queryClient = useQueryClient();

  const mutationFn = async ({
    siteAlias,
    customerId,
    adGroupId,
    keywordsToAdd,
    gaCategory
  }: AddKeywordsMutationFnArgs): Promise<UpdateCampaignsReply.AsObject> => {
    const missingArgs = [
      !keywordsToAdd.length && "keywordsToAdd",
      !adGroupId && "adGroupId",
      !siteAlias && "siteAlias",
      !customerId && "customerId"
    ].filter(Boolean);

    if (missingArgs.length) {
      throw new Error(
        `useAddKeywordToCampaignMutation: Missing or empty required parameter: ${missingArgs.join(
          ", "
        )}`
      );
    }

    if (gaCategory) {
      sendGAEvent(gaCategory, "Add Keyword", siteAlias);
    }

    const addKeywordActions = keywordsToAdd.map(keywordText => {
      const action = new UpdateCampaignAction();
      const keyword = new NewKeyword();
      keyword.setAdGroupId(adGroupId);
      keyword.setText(keywordText);
      keyword.setMatchType(KeywordMatchType.Option.EXACT);

      action.setAddAdGroupKeyword(keyword);
      return action;
    });

    const req = new UpdateCampaignsRequest();
    req.setSiteAlias(siteAlias);
    req.setCustomerId(customerId);
    req.setActionsList(addKeywordActions);

    const reply = await GRPCWebClient.updateCampaigns(req, {});

    const errorMessages: Array<string> = [];
    reply.getAddAdGroupKeywordFailuresList().forEach(e => {
      const errorMessage =
        extractErrorMessage(Error.fromProto(e)) || "Unable to add keyword";

      console.error("Error adding keyword to campaign: ", errorMessage);

      errorMessages.push(errorMessage);
    });

    if (errorMessages.length) {
      throw errorMessages.join("\n\n");
    }

    return reply.toObject();
  };

  const onSuccess = () => {
    return queryClient.invalidateQueries({
      queryKey: ["campaignConfigurations", siteAlias]
    });
  };

  return useMutation({
    mutationFn,
    onSuccess
  });
};
