import React, { useMemo } from "react";

import { Checkbox, Table } from "semantic-ui-react";
import styled from "styled-components/macro";

import { getCurrencyMinimumUnit } from "Common/utils/googleAds";
import { formatMetric } from "Common/utils/metrics";
import { stringForEnum } from "Common/utils/proto";
import { Campaign } from "Common/google/ads/googleads/v16/resources/campaign_pb";
import { CampaignBudget } from "Common/google/ads/googleads/v16/resources/campaign_budget_pb";
import { AdGroup } from "Common/google/ads/googleads/v16/resources/ad_group_pb";
import { AdGroupCriterion } from "Common/google/ads/googleads/v16/resources/ad_group_criterion_pb";
import { CampaignStatusEnum } from "Common/google/ads/googleads/v16/enums/campaign_status_pb";
import { AdGroupCriterionStatusEnum } from "Common/google/ads/googleads/v16/enums/ad_group_criterion_status_pb";
import { GoogleAdsChangeEvent } from "Common/proto/warehousePb/googleAds_pb";

import {
  DataTable,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
  SEMANTIC_DARK_ACCENT
} from "../AmpdDataTable";
import { backgroundDark } from "ExtensionV2/styles/colors";
import { tableHeader } from "ExtensionV2/styles/zIndexes";
import {
  convertMicrosToCurrencyUnit,
  getCurrencyMetricDef
} from "Common/utils/money";
import { momentFromTimestampProto } from "Common/utils/DateUtilities";
import { useCampaignChangeHistoryProto } from "../../queries/useChangeHistory";
import {
  BiddingStrategy,
  CampaignBiddingConfiguration
} from "./getBiddingConfiguration";

const CAMPAIGN_CHANGE_OBJECT_KEY = "_CAMPAIGN_";
const CAMPAIGN_BUDGET_CHANGE_OBJECT_KEY = "_BUDGET_";

const DataTableRowCell = styled.td<{ rowIndex: number }>`
  &&& {
    background-color: ${props =>
      props.rowIndex % 2 === 0 ? "white" : SEMANTIC_DARK_ACCENT};
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.3em;
    height: 2.7em;
  }
`;

const StickyDataTableHeaderCell = styled(DataTableHeaderCell)`
  position: sticky;
  top: 0;
  left: 0;
  border-right: 1px solid ${backgroundDark};
`;

const StickyDataTableRowCell = styled(DataTableRowCell)`
  position: sticky;
  left: 0;
  z-index: ${tableHeader};
  border-right: 1px solid ${backgroundDark};
`;

const BiddingStrategySelector: React.FC<{
  siteAlias: string;
  currencyCode: string;
  campaignId: number;
  adGroupId: number;
  campaignBudget: number;
  biddingConfiguration: CampaignBiddingConfiguration;
  editBiddingStrategy: BiddingStrategy;
  setEditBiddingStrategy: (_: BiddingStrategy) => void;
}> = ({
  siteAlias,
  currencyCode,
  campaignId,
  adGroupId,
  campaignBudget,
  biddingConfiguration,
  editBiddingStrategy,
  setEditBiddingStrategy
}) => {
  const {
    changesByDateByObject,
    dateKeys,
    isLoading: areChangesLoading
  } = useChangesByDateByObject(siteAlias, currencyCode, campaignId, adGroupId);

  const costCurrencyWithCentsMetricDef = getCurrencyMetricDef(
    currencyCode,
    true
  );

  const handleSelectMaximizeClicks = () => {
    setEditBiddingStrategy(BiddingStrategy.TARGET_SPEND);
  };

  const handleSelectManualCPC = () => {
    setEditBiddingStrategy(BiddingStrategy.MANUAL_CPC);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1em"
        }}
      >
        <Checkbox
          radio
          label={
            <label>
              <strong>Maximize Clicks</strong> (single cost-per-click bid for
              all keywords)
            </label>
          }
          checked={editBiddingStrategy === BiddingStrategy.TARGET_SPEND}
          onClick={handleSelectMaximizeClicks}
        />
        <Checkbox
          radio
          label={
            <label>
              <strong>Manual CPC</strong> (individual cost-per-click bids for
              each keyword)
            </label>
          }
          checked={editBiddingStrategy === BiddingStrategy.MANUAL_CPC}
          onClick={handleSelectManualCPC}
        />
      </div>
      {!areChangesLoading && (
        <div
          style={{
            overflow: "auto",
            maxHeight: "40em",
            fontSize: "small",
            marginTop: "1em"
          }}
        >
          <DataTable celled singleLine sortable>
            <DataTableHeader>
              <DataTableRow>
                <StickyDataTableHeaderCell />
                <DataTableHeaderCell>Current</DataTableHeaderCell>
                {dateKeys.map(dateKey => (
                  <DataTableHeaderCell key={dateKey}>
                    {dateKey}
                  </DataTableHeaderCell>
                ))}
              </DataTableRow>
            </DataTableHeader>
            <Table.Body>
              <DataTableRow>
                <StickyDataTableRowCell rowIndex={0}>
                  Campaign
                </StickyDataTableRowCell>
                <DataTableRowCell rowIndex={0}>
                  {biddingConfiguration.biddingStrategy ===
                  BiddingStrategy.TARGET_SPEND
                    ? `max: ${formatMetric(
                        costCurrencyWithCentsMetricDef,
                        convertMicrosToCurrencyUnit(
                          biddingConfiguration.campaignMaxCPCBidMicros
                        )
                      )}`
                    : `default: ${formatMetric(
                        costCurrencyWithCentsMetricDef,
                        convertMicrosToCurrencyUnit(
                          biddingConfiguration.adGroupDefaultCPCBidMicros
                        )
                      )}`}
                </DataTableRowCell>
                {dateKeys.map(dateKey => (
                  <DataTableRowCell
                    key={dateKey}
                    style={{ whiteSpace: "pre" }}
                    rowIndex={0}
                  >
                    {changesByDateByObject[dateKey][
                      CAMPAIGN_CHANGE_OBJECT_KEY
                    ]?.join("\n")}
                  </DataTableRowCell>
                ))}
              </DataTableRow>
              <DataTableRow>
                <StickyDataTableRowCell rowIndex={1}>
                  Daily Budget
                </StickyDataTableRowCell>
                <DataTableRowCell rowIndex={1}>
                  {formatMetric(costCurrencyWithCentsMetricDef, campaignBudget)}
                </DataTableRowCell>
                {dateKeys.map(dateKey => (
                  <DataTableRowCell
                    key={dateKey}
                    style={{ whiteSpace: "pre" }}
                    rowIndex={1}
                  >
                    {changesByDateByObject[dateKey][
                      CAMPAIGN_BUDGET_CHANGE_OBJECT_KEY
                    ]?.join("\n")}
                  </DataTableRowCell>
                ))}
              </DataTableRow>
              {biddingConfiguration.keywords.map((keyword, keywordIndex) => (
                <DataTableRow key={keyword.criteriaId}>
                  <StickyDataTableRowCell rowIndex={keywordIndex + 2}>
                    <em>{keyword.text}</em>
                  </StickyDataTableRowCell>
                  <DataTableRowCell rowIndex={keywordIndex + 2}>
                    {biddingConfiguration.biddingStrategy ===
                    BiddingStrategy.TARGET_SPEND
                      ? `max: ${formatMetric(
                          costCurrencyWithCentsMetricDef,
                          convertMicrosToCurrencyUnit(
                            biddingConfiguration.campaignMaxCPCBidMicros
                          )
                        )}`
                      : keyword.cpcBidMicros
                      ? formatMetric(
                          costCurrencyWithCentsMetricDef,
                          convertMicrosToCurrencyUnit(keyword.cpcBidMicros)
                        )
                      : `default: ${formatMetric(
                          costCurrencyWithCentsMetricDef,
                          convertMicrosToCurrencyUnit(
                            biddingConfiguration.adGroupDefaultCPCBidMicros
                          )
                        )}`}
                  </DataTableRowCell>
                  {dateKeys.map(dateKey => (
                    <DataTableRowCell
                      key={dateKey}
                      style={{ whiteSpace: "pre" }}
                      rowIndex={keywordIndex + 2}
                    >
                      {changesByDateByObject[dateKey][
                        String(keyword.criteriaId)
                      ]?.join("\n")}
                    </DataTableRowCell>
                  ))}
                </DataTableRow>
              ))}
            </Table.Body>
          </DataTable>
        </div>
      )}
    </div>
  );
};

type ChangesByDate = Record<string, Array<string>>;
type ChangesByDateByObject = Record<string, ChangesByDate>;

export const useChangesByDateByObject = (
  siteAlias: string,
  currencyCode: string,
  campaignId: number,
  adGroupId: number
): {
  changesByDateByObject: ChangesByDateByObject;
  dateKeys: Array<string>;
  isLoading: boolean;
} => {
  const {
    data: campaignChangeHistory,
    isLoading,
    error
  } = useCampaignChangeHistoryProto(siteAlias, String(campaignId));

  const changesByDateByObject = useMemo<ChangesByDateByObject>(() => {
    if (isLoading || error || !campaignChangeHistory) {
      return {};
    }

    const changes: ChangesByDateByObject = {};

    for (const event of campaignChangeHistory.getEventsList()) {
      const oldCampaign =
        event
          .getDetails()
          ?.getOldResource()
          ?.getCampaign() || new Campaign();
      const newCampaign =
        event
          .getDetails()
          ?.getNewResource()
          ?.getCampaign() || new Campaign();

      addCampaignChanges(
        changes,
        oldCampaign,
        newCampaign,
        event,
        currencyCode
      );

      const oldBudget =
        event
          .getDetails()
          ?.getOldResource()
          ?.getCampaignBudget() || new CampaignBudget();
      const newBudget =
        event
          .getDetails()
          ?.getNewResource()
          ?.getCampaignBudget() || new CampaignBudget();

      addCampaignBudgetChanges(
        changes,
        oldBudget,
        newBudget,
        event,
        currencyCode
      );
    }

    for (const adGroupChangeHistory of campaignChangeHistory.getAdGroupsList()) {
      if (adGroupChangeHistory.getAdGroupId() === adGroupId) {
        for (const event of adGroupChangeHistory.getEventsList()) {
          const oldAdGroup =
            event
              .getDetails()
              ?.getOldResource()
              ?.getAdGroup() || new AdGroup();
          const newAdGroup =
            event
              .getDetails()
              ?.getNewResource()
              ?.getAdGroup() || new AdGroup();

          addAdGroupChanges(
            changes,
            oldAdGroup,
            newAdGroup,
            event,
            currencyCode
          );
        }

        for (const criteriaChangeHistory of adGroupChangeHistory.getAdGroupCriteriaList()) {
          for (const event of criteriaChangeHistory.getEventsList()) {
            const oldCriteria =
              event
                .getDetails()
                ?.getOldResource()
                ?.getAdGroupCriterion() || new AdGroupCriterion();
            const newCriteria =
              event
                .getDetails()
                ?.getNewResource()
                ?.getAdGroupCriterion() || new AdGroupCriterion();

            addCriteriaChanges(
              changes,
              oldCriteria,
              newCriteria,
              String(criteriaChangeHistory.getCriteriaId()),
              event,
              currencyCode
            );
          }
        }
      }
    }

    return changes;
  }, [isLoading, campaignChangeHistory, error, currencyCode, adGroupId]);

  const dateKeys = Object.keys(changesByDateByObject)
    .sort()
    .reverse();

  return { changesByDateByObject, dateKeys, isLoading };
};

function addCampaignChanges(
  changes: ChangesByDateByObject,
  oldCampaign: Campaign,
  newCampaign: Campaign,
  event: GoogleAdsChangeEvent,
  currencyCode: string
) {
  if (oldCampaign.getStatus() !== newCampaign.getStatus()) {
    addChangesByDateByObject(
      changes,
      CAMPAIGN_CHANGE_OBJECT_KEY,
      event,
      stringForEnum(
        CampaignStatusEnum.CampaignStatus,
        newCampaign.getStatus()
      ) || ""
    );
  }
  if (!oldCampaign.getTargetSpend() && newCampaign.getTargetSpend()) {
    addChangesByDateByObject(
      changes,
      CAMPAIGN_CHANGE_OBJECT_KEY,
      event,
      "MAXIMIZE CLICKS"
    );
  }
  if (
    oldCampaign.getManualCpc() !== newCampaign.getManualCpc() &&
    newCampaign.getManualCpc()
  ) {
    addChangesByDateByObject(
      changes,
      CAMPAIGN_CHANGE_OBJECT_KEY,
      event,
      "MANUAL CPC"
    );
  }
  if (
    oldCampaign.getTargetSpend()?.getCpcBidCeilingMicros() !==
      newCampaign.getTargetSpend()?.getCpcBidCeilingMicros() &&
    newCampaign.getTargetSpend()?.getCpcBidCeilingMicros()
  ) {
    addChangesByDateByObject(
      changes,
      CAMPAIGN_CHANGE_OBJECT_KEY,
      event,
      `max: ${formatMetric(
        getCurrencyMetricDef(currencyCode, true),
        (newCampaign.getTargetSpend()?.getCpcBidCeilingMicros() || 0) / 1e6
      )}`
    );
  }
}

function addCampaignBudgetChanges(
  changes: ChangesByDateByObject,
  oldBudget: CampaignBudget,
  newBudget: CampaignBudget,
  event: GoogleAdsChangeEvent,
  currencyCode: string
) {
  if (oldBudget.getAmountMicros() !== newBudget.getAmountMicros()) {
    addChangesByDateByObject(
      changes,
      CAMPAIGN_BUDGET_CHANGE_OBJECT_KEY,
      event,
      String(
        formatMetric(
          getCurrencyMetricDef(currencyCode, true),
          newBudget.getAmountMicros() / 1e6
        )
      )
    );
  }
}

function addAdGroupChanges(
  changes: ChangesByDateByObject,
  oldAdGroup: AdGroup,
  newAdGroup: AdGroup,
  event: GoogleAdsChangeEvent,
  currencyCode: string
) {
  if (
    oldAdGroup.getCpcBidMicros() !== newAdGroup.getCpcBidMicros() &&
    newAdGroup.getCpcBidMicros() > getCurrencyMinimumUnit(currencyCode)
  ) {
    addChangesByDateByObject(
      changes,
      CAMPAIGN_CHANGE_OBJECT_KEY,
      event,
      `default: ${formatMetric(
        getCurrencyMetricDef(currencyCode, true),
        (newAdGroup?.getCpcBidMicros() || 0) / 1e6
      )}`
    );
  }
}

function addCriteriaChanges(
  changes: ChangesByDateByObject,
  oldCriteria: AdGroupCriterion,
  newCriteria: AdGroupCriterion,
  objectKey: string,
  event: GoogleAdsChangeEvent,
  currencyCode: string
) {
  if (oldCriteria.getStatus() !== newCriteria.getStatus()) {
    addChangesByDateByObject(
      changes,
      objectKey,
      event,
      stringForEnum(
        AdGroupCriterionStatusEnum.AdGroupCriterionStatus,
        newCriteria.getStatus()
      ) || ""
    );
  }
  if (oldCriteria.getCpcBidMicros() !== newCriteria.getCpcBidMicros()) {
    addChangesByDateByObject(
      changes,
      objectKey,
      event,
      newCriteria.getCpcBidMicros() > getCurrencyMinimumUnit(currencyCode)
        ? String(
            formatMetric(
              getCurrencyMetricDef(currencyCode, true),
              newCriteria.getCpcBidMicros() / 1e6
            )
          )
        : "use default"
    );
  }
}

function addChangesByDateByObject(
  changesByDateByObject: ChangesByDateByObject,
  objectKey: string,
  event: GoogleAdsChangeEvent,
  change: string
) {
  const dateKey =
    momentFromTimestampProto(event.getChangeTime())?.format("YYYY-MM-DD") ||
    "Unknown";

  let objectChangesByDate = changesByDateByObject[dateKey];
  if (!objectChangesByDate) {
    objectChangesByDate = {};
    changesByDateByObject[dateKey] = objectChangesByDate;
  }

  let objectChanges = objectChangesByDate[objectKey];
  if (!objectChanges) {
    objectChanges = [];
    objectChangesByDate[objectKey] = objectChanges;
  }

  objectChanges.unshift(change);
}

export default BiddingStrategySelector;
