import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GenerateGoogleAdsKeywordIdeasRequest,
  GenerateGoogleAdsKeywordIdeasReply
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";

export const useGoogleKeywordIdeas = (
  siteAlias: string,
  googleAdsCustomerId: string,
  seedUrl: string,
  seedKeywords: Array<string>,
  geotargets: Array<number>
): UseQueryResult<GenerateGoogleAdsKeywordIdeasReply.AsObject, unknown> => {
  // GOTCHA: Arrays as query keys are order dependent, sort them before use.
  return useQuery({
    queryKey: [
      "generateGoogleAdsKeywordIdeas",
      siteAlias,
      googleAdsCustomerId,
      seedUrl,
      [...seedKeywords].sort(),
      [...geotargets].sort()
    ],
    // 30 minutes. This data could change frequently but we want to avoid a refetch in the middle of
    // a user creating a campaign.
    staleTime: 30 * 60 * 1_000,
    enabled: !!siteAlias && (seedKeywords.length > 0 || !!seedUrl),
    queryFn: async () => {
      /* 
        It appears that there is a bug in the Google generateKeywordIdeas API where requests with
        both seedUrl and seedKeywords only return keyword ideas for the seedKeywords and ignore the
        seedUrl. So if we want ideas for both seedKeywords and a seedUrl, we need to make two
        requests and combine the results. 
      */
      let reply: GenerateGoogleAdsKeywordIdeasReply;
      if (!!seedUrl && seedKeywords.length > 0) {
        const seedKeywordsReq = new GenerateGoogleAdsKeywordIdeasRequest();
        seedKeywordsReq.setSiteAlias(siteAlias);
        seedKeywordsReq.setCustomerId(googleAdsCustomerId);
        seedKeywordsReq.setGeotargetsList(geotargets);
        seedKeywordsReq.setSeedKeywordsList(seedKeywords);

        const urlReq = new GenerateGoogleAdsKeywordIdeasRequest();
        urlReq.setSiteAlias(siteAlias);
        urlReq.setCustomerId(googleAdsCustomerId);
        urlReq.setGeotargetsList(geotargets);
        urlReq.setSeedUrl(seedUrl);

        const [seedKeywordsReply, urlReply] = await Promise.all([
          GRPCWebClient.generateGoogleAdsKeywordIdeas(seedKeywordsReq, {}),
          GRPCWebClient.generateGoogleAdsKeywordIdeas(urlReq, {})
        ]);

        const combinedIdeaList = seedKeywordsReply
          .getKeywordIdeasList()
          .concat(urlReply.getKeywordIdeasList());

        const seen = new Set();
        const combinedIdeaListWithoutDuplicates = combinedIdeaList.filter(
          idea => {
            const duplicate = seen.has(idea.getText());
            seen.add(idea.getText());
            return !duplicate;
          }
        );

        const combinedReply = new GenerateGoogleAdsKeywordIdeasReply();
        combinedReply.setKeywordIdeasList(combinedIdeaListWithoutDuplicates);

        reply = combinedReply;
      } else {
        const req = new GenerateGoogleAdsKeywordIdeasRequest();
        req.setSiteAlias(siteAlias);
        req.setCustomerId(googleAdsCustomerId);
        req.setGeotargetsList(geotargets);
        req.setSeedUrl(seedUrl);
        req.setSeedKeywordsList(seedKeywords);

        reply = await GRPCWebClient.generateGoogleAdsKeywordIdeas(req, {});
      }

      return reply?.toObject();
    }
  });
};
