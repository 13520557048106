import { useQuery, UseQueryResult } from "@tanstack/react-query";

import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetNextStripeInvoiceReply,
  GetNextStripeInvoiceRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Stripe_pb";
import { momentToTimestampProto } from "Common/utils/DateUtilities";

// Stripe prorates by the second. We must use the same time as the proration date for the invoice
// preview and the change mutation. If you use Date.now() there be a small difference in the preview
// and actual price that accounts for difference in time of when the preview is loaded and the
// time the user clicks the confirmation button. This provides the next hour rounded up to use for
// the proration date.
export const roundToNextHour = (): number => {
  const currentHour = new Date().getHours();
  return new Date().setHours(currentHour + 1, 0, 0, 0);
};

// Generates a preview of the next invoice from Stripe including any setup fees or coupons. No real
// invoices are generated, they are all previews/hypothetical.
//
// If a priceId is provided, the invoice will be generated using that price ID. For site with an
// active subscription, this will be an invoice supposing the subscription was changed to use this
// price on the prorationDate (and will probably include a proration for unused time on the current
// plan). For sites without an active stripe subscription, this will be a an invoice for a new
// subscription using that price.
//
// If no priceId is provided, the invoice will be the next invoice for the site's current active
// subscription, if one exists.
export const useNextInvoice = ({
  priceId,
  prorationDate,
  couponId,
  setupFeeId
}: {
  priceId?: string;
  prorationDate?: number;
  couponId?: string;
  setupFeeId?: string;
}): UseQueryResult<GetNextStripeInvoiceReply.AsObject, unknown> => {
  const siteAlias = getSiteAliasFromURL("/t");

  return useQuery({
    queryKey: [
      "nextInvoice",
      siteAlias,
      priceId,
      couponId,
      prorationDate,
      setupFeeId
    ],
    staleTime: 5 * 60 * 1_000, // 5 minutes
    queryFn: async () => {
      const req = new GetNextStripeInvoiceRequest();
      req.setSiteAlias(siteAlias);

      if (priceId) {
        req.setNewPriceId(priceId);

        req.setProrationDate(
          momentToTimestampProto(prorationDate || roundToNextHour())
        );
      }

      if (setupFeeId) {
        req.setSetupFeeId(setupFeeId);
      }

      if (couponId) {
        req.setCouponId(couponId);
      }

      const reply = await GRPCWebClient.getNextStripeInvoice(req, {});
      return reply.toObject();
    }
  });
};
