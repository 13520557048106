import { extractErrorMessage } from "Common/errors/error";
import { Facebook } from "Common/proto/common/facebook_pb";
import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import {
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell
} from "ExtensionV2/components/AmpdDataTable";
import {
  AmpdDataTableColumn,
  AmpdDataTableSettings,
  AmpdDataTableTsWrapper,
  TAmpdDataTable
} from "ExtensionV2/components/AmpdDataTableTsWrapper";
import { EditDropdown } from "ExtensionV2/components/CampaignsTableRow";
import {
  fractionalPercentageMetricDef,
  roundedNumberMetricDef,
  roundedPercentageMetricDef
} from "ExtensionV2/components/MetricColumns";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import {
  FacebookPerformanceRollup,
  FB_RESOURCE_TYPE
} from "ExtensionV2/queries/useFacebookPerformanceData";
import React, { useMemo, useState } from "react";
import { DropdownProps, Icon, Table } from "semantic-ui-react";
import styled from "styled-components";
import { useUpdateFacebookStatus } from "ExtensionV2/queries/useUpdateFacebookStatus";
import {
  EditFacebookBidAmount,
  EditFacebookDailyBudget,
  EditFacebookLifetimeBudget
} from "./EditFacebookBudget";

export type FacebookPerformanceTableData = FacebookPerformanceRollup & {
  name: string;
  status: string;
  id: string;
  rollupLevel: FB_RESOURCE_TYPE;
  dailyBudgetCents?: number;
  lifetimeBudgetCents?: number;
  bidAmountCents?: number;
  adSetName?: string;
  campaignName?: string;
};

export const facebookPerformanceColumns: Array<AmpdDataTableColumn<
  FacebookPerformanceTableData
>> = [
  { name: "name", displayName: "Name", freeze: "0px", displayMinWidth: 400 },
  { name: "status", displayName: "Status" },
  { name: "id", displayName: "ID" },
  { name: "campaignName", displayName: "Campaign Name" },
  { name: "adSetName", displayName: "Ad Set Name" },
  { name: "spend", displayName: "Cost" },
  { name: "dailyBudgetCents", displayName: "Daily Budget" },
  { name: "lifetimeBudgetCents", displayName: "Lifetime Budget" },
  { name: "bidAmountCents", displayName: "Bid Amount" },
  { name: "impressions", displayName: "Impressions" },
  { name: "costPerMille", displayName: "CPM" },
  { name: "facebookClicks", displayName: "Meta Clicks" },
  { name: "facebookCostPerClick", displayName: "Cost Per Meta Click" },
  { name: "facebookClickRate", displayName: "Meta Click Through Rate" },
  { name: "marketplaceClicks", displayName: "Marketplace Clicks" },
  {
    name: "marketplaceClickRate",
    displayName: "Marketplace Click Through Rate"
  },
  {
    name: "marketplaceCostPerClick",
    displayName: "Cost Per Marketplace Click"
  },
  { name: "detailPageViews", displayName: "Detail Page Views" },
  { name: "detailPageViewRate", displayName: "Detail Page View Rate" },
  { name: "costPerDetailPageView", displayName: "Cost Per Detail Page View" },
  { name: "addToCarts", displayName: "Carts" },
  { name: "addToCartRate", displayName: "Cart Rate" },
  { name: "ntbConversionRate", displayName: "% of Conversions NTB" },
  { name: "ntbUnitsSoldRate", displayName: "% of Units Sold NTB" },
  { name: "ntbRevenueRate", displayName: "% of Revenue NTB" },
  { name: "ntbROAS", displayName: "NTB ROAS" },
  { name: "conversions", displayName: "Conversions" },
  { name: "unitsSold", displayName: "Units Sold" },
  { name: "conversionValue", displayName: "Revenue" },
  { name: "brandReferralBonus", displayName: "Brand Referral Bonus" },
  { name: "roas", displayName: "ROAS" }
];

export const unhideableFacebookColumns: Array<keyof FacebookPerformanceTableData> = [
  "name"
];

export const defaultFacebookColumns: Array<keyof FacebookPerformanceTableData> = [
  "name",
  "status",
  "spend",
  "impressions",
  "facebookClicks",
  "facebookCostPerClick",
  "detailPageViews"
];

export const allFacebookColumns: Array<keyof FacebookPerformanceTableData> = facebookPerformanceColumns.map(
  col => col.name
);

const ResourceNameCell = styled(DataTableFreezeLeftCell)<{
  rowIndex?: number;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 35em;
`;

const WideTextCell = styled(DataTableMetricCell)<{
  rowIndex?: number;
  centerContent?: boolean;
}>`
  &&& {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    max-width: 20em;
  }
`;

const EditFacebookStatus = ({
  resourceId,
  status,
  rollupLevel
}: {
  resourceId: string;
  status: string;
  rollupLevel: FB_RESOURCE_TYPE;
}): JSX.Element => {
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const {
    mutate: updateStatus,
    isLoading: updateLoading
  } = useUpdateFacebookStatus(rollupLevel);

  const handleOnChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    event.preventDefault();
    if (
      typeof data.value !== "number" ||
      !Object.values(Facebook.API.UpdateStatus.Option).includes(data.value)
    ) {
      return;
    }

    const option = data.value;
    updateStatus({
      resourceId: resourceId,
      newStatus: option
    });
  };

  return (
    <EditDropdown
      compact
      open={statusDropdownOpen}
      onOpen={() => setStatusDropdownOpen(true)}
      onClose={() => setStatusDropdownOpen(false)}
      onChange={handleOnChange}
      icon={
        <Icon
          style={{ marginLeft: "0.5em" }}
          name="pencil"
          onClick={() => setStatusDropdownOpen(true)}
        />
      }
      disabled={updateLoading}
      selection
      options={[
        {
          key: "Active",
          text: "Enabled",
          value: Facebook.API.UpdateStatus.Option.ACTIVE
        },
        {
          key: "Paused",
          text: "Paused",
          value: Facebook.API.UpdateStatus.Option.PAUSED
        }
      ]}
      value={
        status === "Active"
          ? Facebook.API.UpdateStatus.Option.ACTIVE
          : Facebook.API.UpdateStatus.Option.PAUSED
      }
    />
  );
};

// This wrapper is just so we can get make the TS compiler happy since
// DataTableMetricCell is defined in a JS file and JSDOC comments don't seem to
// work on styled components
const FbDataTableMetricCell = styled(DataTableMetricCell)<{
  rowIndex?: number;
  centerContent?: boolean;
}>`
  &&& {
    ${props => (props.centerContent ? "text-align: center;" : "")}
  }
`;

const FbDataTableRowCell = styled(DataTableRowCell)<{
  rowIndex?: number;
}>``;

function mapPerformanceDataToComponent(
  showFractions: boolean,
  metaCurrency: string
) {
  const rateFormatter = showFractions
    ? fractionalPercentageMetricDef
    : roundedPercentageMetricDef;

  const currencyFormatter = getCurrencyMetricDef(metaCurrency, showFractions);

  return (
    performanceData: FacebookPerformanceTableData,
    columns: Array<keyof FacebookPerformanceTableData>,
    i: number
  ): JSX.Element => {
    return (
      <Table.Row key={performanceData.id}>
        {columns.map(column => {
          if (performanceData[column] == undefined) {
            return (
              <FbDataTableMetricCell
                key={column}
                rowIndex={i}
                centerContent={true}
              >
                N/A
              </FbDataTableMetricCell>
            );
          }
          switch (column) {
            case "name":
              return (
                <SimpleTooltip key={column} tooltip={performanceData[column]}>
                  <ResourceNameCell key={column} rowIndex={i}>
                    {performanceData[column]}
                  </ResourceNameCell>
                </SimpleTooltip>
              );
            case "adSetName":
            case "campaignName":
              return (
                <SimpleTooltip key={column} tooltip={performanceData[column]}>
                  <WideTextCell key={column} rowIndex={i}>
                    {performanceData[column]}
                  </WideTextCell>
                </SimpleTooltip>
              );
            case "marketplaceClickRate":
            case "addToCartRate":
            case "detailPageViewRate":
            case "facebookClickRate":
            case "ntbConversionRate":
            case "ntbUnitsSoldRate":
            case "ntbRevenueRate":
            case "ntbROAS":
            case "roas":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(rateFormatter, performanceData[column])}
                </FbDataTableMetricCell>
              );
            // cost per metrics always show fractions of a currency
            case "marketplaceCostPerClick":
            case "costPerAddToCart":
            case "costPerDetailPageView":
            case "costPerMille":
            case "facebookCostPerClick":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(
                    getCurrencyMetricDef(metaCurrency, true),
                    performanceData[column]
                  )}
                </FbDataTableMetricCell>
              );
            // user selects if they want to see fractions of a currency
            case "conversionValue":
            case "brandReferralBonus":
            case "spend":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(currencyFormatter, performanceData[column])}
                </FbDataTableMetricCell>
              );
            case "addToCarts":
            case "marketplaceClicks":
            case "conversions":
            case "detailPageViews":
            case "impressions":
            case "facebookClicks":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i}>
                  {formatMetric(
                    roundedNumberMetricDef,
                    performanceData[column]
                  )}
                </FbDataTableMetricCell>
              );
            case "status":
              return (
                <FbDataTableMetricCell
                  key={column}
                  rowIndex={i}
                  centerContent={true}
                >
                  <EditFacebookStatus
                    resourceId={performanceData.id}
                    status={performanceData[column]}
                    rollupLevel={performanceData.rollupLevel}
                  />
                </FbDataTableMetricCell>
              );
            case "bidAmountCents":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i} centerContent>
                  <EditFacebookBidAmount
                    currency={metaCurrency}
                    dailyBudgetCents={performanceData[column] ?? 0}
                    resourceId={performanceData.id}
                    rollupLevel={performanceData.rollupLevel}
                  />
                </FbDataTableMetricCell>
              );

            case "lifetimeBudgetCents":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i} centerContent>
                  <EditFacebookLifetimeBudget
                    currency={metaCurrency}
                    dailyBudgetCents={performanceData[column] ?? 0}
                    resourceId={performanceData.id}
                    rollupLevel={performanceData.rollupLevel}
                  />
                </FbDataTableMetricCell>
              );
            case "dailyBudgetCents":
              return (
                <FbDataTableMetricCell key={column} rowIndex={i} centerContent>
                  <EditFacebookDailyBudget
                    currency={metaCurrency}
                    dailyBudgetCents={performanceData[column] ?? 0}
                    resourceId={performanceData.id}
                    rollupLevel={performanceData.rollupLevel}
                  />
                </FbDataTableMetricCell>
              );
            default:
              return (
                <FbDataTableRowCell key={column} rowIndex={i}>
                  {performanceData[column]}
                </FbDataTableRowCell>
              );
          }
        })}
      </Table.Row>
    );
  };
}

export function FacebookAdsPerformanceTable({
  selectedColumns,
  showFractions,
  dataLoading,
  dataError,
  rows
}: {
  selectedColumns: Array<keyof FacebookPerformanceTableData>;
  showFractions: boolean;
  dataLoading: boolean;
  dataError: unknown;
  rows: Array<FacebookPerformanceTableData>;
}): JSX.Element {
  const tableSettings: AmpdDataTableSettings<FacebookPerformanceTableData> = {
    defaultSortColumn: "name",
    emptyContent: <div>No data available</div>,
    compact: "very",
    // TODO: fetch the correct currency from Facebook, handle multiple
    // currencies
    mapDataRowToComponent: mapPerformanceDataToComponent(showFractions, "USD")
  };

  const columns = useMemo(() => {
    return selectedColumns.flatMap(column => {
      const found = facebookPerformanceColumns.find(c => c.name === column);
      return found ? [found] : [];
    });
  }, [selectedColumns]);

  const tableConfig: TAmpdDataTable<FacebookPerformanceTableData> = {
    columns,
    rows,
    settings: tableSettings
  };

  return (
    <AmpdDataTableTsWrapper
      isLoading={dataLoading}
      error={extractErrorMessage(dataError)}
      tableConfig={tableConfig}
    />
  );
}
