import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";
import {
  GetSiteDashboardTableMetricsReply,
  GetSiteDashboardTableMetricsRequest,
  DashboardTable as DashboardTableProto,
  DashboardTableMetrics as DashboardTableMetricsProto
} from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import { buildDateRangeV2Proto } from "Common/utils/DateUtilities";
import { Retailer } from "Common/proto/common/retailer_pb";

export const DashboardTableObjectType = DashboardTableProto.ObjectType.Option;
export type DashboardTableObjectType = DashboardTableProto.ObjectType.Option;
export type DashboardTableMetrics = DashboardTableMetricsProto.AsObject;

type DashboardTableMetricsByCampaignIdByObjectType = {
  [objectType in DashboardTableObjectType]?: {
    [campaignId: string]: Array<DashboardTableMetrics>;
  };
};

// Hook to return an object whose keys are the various object types that have
// been requested, and whose values are object that map campaign IDs (as strings)
// to DashboardTableMetrics objects.
export const useDashboardTableMetrics = ({
  objectTypes,
  siteAlias,
  retailer,
  campaignIds,
  dateRangeStartDate,
  dateRangeEndDate
}: {
  objectTypes: Array<DashboardTableObjectType>;
  siteAlias: string;
  retailer: Retailer.Option;
  campaignIds: Array<string>;
  dateRangeStartDate: string;
  dateRangeEndDate: string;
}): UseQueryResult<DashboardTableMetricsByCampaignIdByObjectType, unknown> => {
  const queryKey = [
    "dashboardTableMetrics",
    siteAlias,
    retailer,
    objectTypes,
    dateRangeStartDate,
    dateRangeEndDate,
    campaignIds
  ];
  const { dateRangeProto } = buildDateRangeV2Proto(
    dateRangeStartDate,
    dateRangeEndDate
  );

  return useQuery({
    queryKey,
    staleTime: 10 * 60 * 1_000, // 10 minutes
    cacheTime: 11 * 60 * 1_000, // 11 minutes
    enabled: !!siteAlias,
    retry: 2,
    queryFn: async (): Promise<
      DashboardTableMetricsByCampaignIdByObjectType
    > => {
      const dashboardTableMetricsByCampaignIdByObjectType: DashboardTableMetricsByCampaignIdByObjectType = {};
      objectTypes.forEach(
        objectType =>
          (dashboardTableMetricsByCampaignIdByObjectType[objectType] = {})
      );

      if (!campaignIds?.length && !objectTypes?.length) {
        return dashboardTableMetricsByCampaignIdByObjectType;
      }

      const req = new GetSiteDashboardTableMetricsRequest();
      req.setSiteAlias(siteAlias);
      req.setRetailer(retailer);
      req.setDateRange(dateRangeProto);
      req.setObjectTypesList([...objectTypes]);

      const campaignsFilter = new DashboardTableProto.CampaignsFilter();
      campaignsFilter.setCampaignIdsList(campaignIds);

      const objectFilter = new DashboardTableProto.ObjectFilter();
      objectFilter.setCampaignsFilter(campaignsFilter);

      req.setFiltersList([objectFilter]);

      await streamProcessor(
        GRPCWebCallbackClient.getSiteDashboardTableMetrics(req),
        (reply: GetSiteDashboardTableMetricsReply) => {
          reply
            .getDashboardTableMetricsList()
            .forEach(dashboardTableMetrics => {
              const objectType = dashboardTableMetrics.getObjectType();
              const campaignId = dashboardTableMetrics.getCampaignId();
              const dashboardTableMetricsByCampaignId =
                dashboardTableMetricsByCampaignIdByObjectType[objectType] || {};

              const dashboardTableMetricsList =
                dashboardTableMetricsByCampaignId[campaignId] || [];

              dashboardTableMetricsList.push(dashboardTableMetrics.toObject());

              dashboardTableMetricsByCampaignId[
                campaignId
              ] = dashboardTableMetricsList;
            });
        }
      );

      return dashboardTableMetricsByCampaignIdByObjectType;
    }
  });
};
