// source: proto/googleAdsPb/adGroup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupAction.ActionCase', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction', null, global);
goog.exportSymbol('proto.googleAdsPb.UpdateAdGroupNameAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateAdGroupAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.googleAdsPb.UpdateAdGroupAction.oneofGroups_);
};
goog.inherits(proto.googleAdsPb.UpdateAdGroupAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateAdGroupAction.displayName = 'proto.googleAdsPb.UpdateAdGroupAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateAdGroupNameAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateAdGroupNameAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateAdGroupNameAction.displayName = 'proto.googleAdsPb.UpdateAdGroupNameAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.displayName = 'proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.googleAdsPb.UpdateAdGroupAction.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.googleAdsPb.UpdateAdGroupAction.ActionCase = {
  ACTION_NOT_SET: 0,
  UPDATE_NAME: 1,
  UPDATE_DEFAULT_CPC_BID: 2
};

/**
 * @return {proto.googleAdsPb.UpdateAdGroupAction.ActionCase}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.getActionCase = function() {
  return /** @type {proto.googleAdsPb.UpdateAdGroupAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.googleAdsPb.UpdateAdGroupAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateAdGroupAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateAdGroupAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateName: (f = msg.getUpdateName()) && proto.googleAdsPb.UpdateAdGroupNameAction.toObject(includeInstance, f),
    updateDefaultCpcBid: (f = msg.getUpdateDefaultCpcBid()) && proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateAdGroupAction}
 */
proto.googleAdsPb.UpdateAdGroupAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateAdGroupAction;
  return proto.googleAdsPb.UpdateAdGroupAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateAdGroupAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateAdGroupAction}
 */
proto.googleAdsPb.UpdateAdGroupAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.googleAdsPb.UpdateAdGroupNameAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateAdGroupNameAction.deserializeBinaryFromReader);
      msg.setUpdateName(value);
      break;
    case 2:
      var value = new proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction;
      reader.readMessage(value,proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.deserializeBinaryFromReader);
      msg.setUpdateDefaultCpcBid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateAdGroupAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateAdGroupAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.googleAdsPb.UpdateAdGroupNameAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateDefaultCpcBid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAdGroupNameAction update_name = 1;
 * @return {?proto.googleAdsPb.UpdateAdGroupNameAction}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.getUpdateName = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupNameAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateAdGroupNameAction, 1));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupNameAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateAdGroupAction} returns this
*/
proto.googleAdsPb.UpdateAdGroupAction.prototype.setUpdateName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.googleAdsPb.UpdateAdGroupAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateAdGroupAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.clearUpdateName = function() {
  return this.setUpdateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.hasUpdateName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateAdGroupDefaultCPCBidAction update_default_cpc_bid = 2;
 * @return {?proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.getUpdateDefaultCpcBid = function() {
  return /** @type{?proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} */ (
    jspb.Message.getWrapperField(this, proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction, 2));
};


/**
 * @param {?proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction|undefined} value
 * @return {!proto.googleAdsPb.UpdateAdGroupAction} returns this
*/
proto.googleAdsPb.UpdateAdGroupAction.prototype.setUpdateDefaultCpcBid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.googleAdsPb.UpdateAdGroupAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.googleAdsPb.UpdateAdGroupAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.clearUpdateDefaultCpcBid = function() {
  return this.setUpdateDefaultCpcBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.googleAdsPb.UpdateAdGroupAction.prototype.hasUpdateDefaultCpcBid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateAdGroupNameAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateAdGroupNameAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupNameAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateAdGroupNameAction}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateAdGroupNameAction;
  return proto.googleAdsPb.UpdateAdGroupNameAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateAdGroupNameAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateAdGroupNameAction}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateAdGroupNameAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateAdGroupNameAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupNameAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string campaign_id = 3;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupNameAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ad_group_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupNameAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_name = 2;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.getNewName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupNameAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupNameAction.prototype.setNewName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.prototype.toObject = function(opt_includeInstance) {
  return proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newCpcBidMicros: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction}
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction;
  return proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction}
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewCpcBidMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewCpcBidMicros();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string ad_group_id = 1;
 * @return {string}
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.prototype.getAdGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 new_cpc_bid_micros = 2;
 * @return {number}
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.prototype.getNewCpcBidMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction} returns this
 */
proto.googleAdsPb.UpdateAdGroupDefaultCPCBidAction.prototype.setNewCpcBidMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.googleAdsPb);
