import Immutable from "immutable";

import { Walmart } from "../proto/common/walmart_pb";
import { Retailer } from "../proto/common/retailer_pb";
import {
  isWalmartMarketplaceInfo,
  MarketplaceInfo,
  WalmartMarketplaceInfo
} from "Common/utils/marketplace";
import { None } from "Common/utils/tsUtils";

// https://goto.walmart.com or https://www.walmart.com or walmart.sjv.io or walmart.pxf.io
export const WALMART_URL_REGEX = /(^https?:\/\/(www\.|goto\.)?walmart)|(^walmart\.(sjv|pxf)\.io)/;

// A map of Walmart domains to their marketplace configurations. Most of this
// information is from walmart.proto, but isn't available in the Javascript
// protos, unfortunately.
//
export const walmartUSMarketplaceInfo: WalmartMarketplaceInfo = {
  retailer: Retailer.Option.WALMART,
  marketplace: Walmart.Marketplace.Option.UNITED_STATES,
  name: "United States",
  domain: "walmart.com",
  geotargets: [2840],
  currencyCode: "USD"
};

export const walmartDomains = Immutable.Map<string, WalmartMarketplaceInfo>({
  "walmart.ca": {
    retailer: Retailer.Option.WALMART,
    marketplace: Walmart.Marketplace.Option.CANADA,
    name: "Canada",
    domain: "walmart.ca",
    geotargets: [2124],
    currencyCode: "CAD"
  },
  "walmart.com": walmartUSMarketplaceInfo,
  "walmart.com.mx": {
    retailer: Retailer.Option.WALMART,
    marketplace: Walmart.Marketplace.Option.MEXICO,
    name: "Mexico",
    domain: "walmart.com.mx",
    geotargets: [2484],
    currencyCode: "MXN"
  }
});

// Map from Walmart marketplace enum values to their marketplace entries.
export const walmartMarketplaces = Immutable.Map<
  Walmart.Marketplace.Option,
  WalmartMarketplaceInfo
>(walmartDomains.mapEntries(([, x]) => [x.marketplace, x]));

// Returns the MarketplaceInfo for the specified walmart marketplace option.
export function getWalmartMarketplaceInfo(
  marketplace: Walmart.Marketplace.Option
): WalmartMarketplaceInfo | null {
  for (const marketplaceInfo of walmartDomains.values()) {
    if (marketplaceInfo.marketplace === marketplace) {
      return marketplaceInfo;
    }
  }

  return null;
}

// Returns the walmart marketplace option for the specified MarketplaceInfo (if is for Walmart).
export function getWalmartMarketplace(
  marketplaceInfo: MarketplaceInfo | None
): Walmart.Marketplace.Option {
  if (!isWalmartMarketplaceInfo(marketplaceInfo)) {
    return Walmart.Marketplace.Option.UNKNOWN;
  }

  return marketplaceInfo.marketplace;
}

const ITEM_ID_REGEXP = /^[0-9]+$/i;

export function isValidItemID(s: string): boolean {
  if (!s) {
    return false;
  }

  return ITEM_ID_REGEXP.test(s);
}

export type WalmartURLInfo = {
  // Identifies the domain and marketplace info for the URL.  These will
  // be the default US Walmart marketplace if the urlString is a simple
  // item id or search phrase.
  domain: string | null;
  marketplaceInfo: WalmartMarketplaceInfo | null;

  itemId: string | null;
  searchPhrase: string | null;

  // This is the effective target URL if the urlString was either a direct Walmart URL or
  // an indirect goto.walmart URL (but not if the url string is a simple item id or a simple
  // search phrase).
  effectiveURL: string | null;
  redirectURL: string | null;

  // Parsed fields from the URL.
  searchBrands: Array<string>;
  searchOtherFacets: Array<[string, string]>;
  searchOtherParameters: Array<[string, string]>;
};

// Returns Walmart-specific information from a URL or other string.  If the string is not
// a full URL, then either it is a number string or not.  If it is a number string, assume
// the caller wants information for the product detail page URL that targets that Walmart
// item id in walmart.com.  If the string is not a URL or a number, assume the caller wants
// information for the search page URL that targets relevant products in walmart.com.  If the
// string is a URL (or appears to be one), but it is not a recognized Walmart URL, then return
// null for all returned values.
export function extractWalmartURLInfoFromString(
  urlString: string
): WalmartURLInfo {
  let domain = null;
  let marketplaceInfo = null;
  let itemId = null;
  let searchPhrase = null;
  let effectiveURL = null;
  let searchBrands: Array<string> = [];
  let searchOtherFacets: Array<[string, string]> = [];
  let searchOtherParameters: Array<[string, string]> = [];

  if (!urlString) {
    return {
      domain,
      marketplaceInfo,
      itemId,
      searchPhrase,
      effectiveURL,
      redirectURL: null,
      searchBrands,
      searchOtherFacets,
      searchOtherParameters
    };
  }

  urlString = urlString.replace(/[\n\r\t\v]/g, "");

  // If the string doesn't have any colons or slashes, then assume it is either an itemId or
  // a search term for US Walmart.
  if (!urlString.includes(":") && !urlString.includes("/")) {
    if (isValidItemID(urlString)) {
      itemId = urlString.toUpperCase();

      return {
        domain: "walmart.com",
        marketplaceInfo: walmartDomains.get("walmart.com", null),
        itemId,
        searchPhrase: null,
        effectiveURL,
        redirectURL: null,
        searchBrands: [],
        searchOtherFacets: [],
        searchOtherParameters: []
      };
    } else {
      return {
        domain: "walmart.com",
        marketplaceInfo: walmartDomains.get("walmart.com", null),
        itemId: null,
        searchPhrase: urlString.trim(),
        effectiveURL,
        redirectURL: null,
        searchBrands: [],
        searchOtherFacets: [],
        searchOtherParameters: []
      };
    }
  }

  try {
    const url = new URL(urlString);

    effectiveURL = urlString;

    if (url.protocol.toLowerCase() === "http:") {
      url.protocol = "https:";
    }

    domain = url.hostname.toLowerCase();
    if (domain.toLowerCase().startsWith("www.")) {
      domain = domain.slice(4);
    }

    // If the domain is "goto.walmart.com" or equivalent Impact Radius click trackers,
    // look for the embedded Walmart URL on the "u" search parameter, and use that instead.
    if (
      domain.toLowerCase() === "goto.walmart.com" ||
      domain.toLowerCase() === "walmart.sjv.io" ||
      domain.toLowerCase() === "walmart.pxf.io"
    ) {
      // Example of a Walmart affiliate URL:
      //   https://goto.walmart.com/c/4845193/565706/9383?subId1=ga-1442801476
      //     &subId2={campaignid}&subId3={adgroupid}_{targetid}
      //     &sharedid=ampd_ga-1442801476_ca-{campaignid}_ag-{adgroupid}_ad-{creative}_{targetid}
      //     &veh=aff&sourceid=imp_000011112222333344
      //     &u=https%3A%2F%2Fwww.walmart.com%2Fsearch%3Fq%3Dno7%2Bface%2Bmoisturizer
      //     %26facet%3Dbrand%253ANo7%257C%257Cbrand%253ANO7%257C%257Cbrand%253ANo7
      //     %2BBeauty%257C%257Cretailer_type%253AWalmart%26sort%3Dbest_seller
      const referencedURL = url.searchParams.get("u");

      if (referencedURL) {
        const urlInfo = extractWalmartURLInfoFromString(referencedURL);
        urlInfo.redirectURL = urlString;
        return urlInfo;
      }
    }

    // Remove query parameters added by redirecting through goto.walmart.com
    url.searchParams.delete("clickid");
    url.searchParams.delete("irgwc");
    url.searchParams.delete("sourceid");
    url.searchParams.delete("veh");
    url.searchParams.delete("wmlspartner");
    url.searchParams.delete("affiliates_ad_id");
    url.searchParams.delete("campaign_id");
    url.searchParams.delete("sharedid");

    effectiveURL = url.toString();

    const parts = url.pathname.toLowerCase().split("/");
    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      if (parts[partIndex] === "ip") {
        // Most common form of Walmart product detail page URL:
        //    https://www.walmart.com/ip/243166545
        // or https://www.walmart.com/ip/Boots-No7-Protect/243166545
        const part = parts[parts.length - 1];
        if (isValidItemID(part)) {
          itemId = part;
        }
      } else if (parts[partIndex] === "search") {
        // Example of a Walmart search page URL:
        //   https://www.walmart.com/search?q=no7+face+moisturizer
        //     &facet=brand%3ANo7%7C%7Cbrand%3ANO7%7C%7Cbrand%3ANo7+Beauty
        //     %7C%7Cretailer_type%3AWalmart&sort=best_seller&min_price=10&max_price=30
        const queryParameter =
          url.searchParams.get("q") || url.searchParams.get("query");
        if (queryParameter) {
          searchPhrase = queryParameter;
          searchBrands = [];
          searchOtherFacets = [];
          searchOtherParameters = [];

          const facetParam = url.searchParams.get("facet");
          if (facetParam) {
            const facets = facetParam.split("||");
            facets.forEach(facet => {
              const colonIndex = facet.indexOf(":");
              if (colonIndex >= 0) {
                const name = facet.slice(0, colonIndex);
                const value = facet.slice(colonIndex + 1);

                if (name === "brand") {
                  searchBrands.push(value);
                } else {
                  searchOtherFacets.push([name, value]);
                }
              }
            });
          }

          url.searchParams.forEach((value, name) => {
            if (["q", "query", "facet"].includes(name)) {
              return;
            }

            searchOtherParameters.push([name, value]);
          });
        }
      }
    }
  } catch (_) {
    return {
      domain: null,
      marketplaceInfo: null,
      itemId: null,
      searchPhrase: null,
      effectiveURL: null,
      redirectURL: null,
      searchBrands: [],
      searchOtherFacets: [],
      searchOtherParameters: []
    };
  }

  marketplaceInfo = walmartDomains.get(domain, null);

  return {
    domain,
    itemId,
    marketplaceInfo,
    searchPhrase,
    effectiveURL,
    redirectURL: null,
    searchBrands,
    searchOtherFacets,
    searchOtherParameters
  };
}

export function getWalmartDetailPageURLForItemId(
  marketplaceInfo: MarketplaceInfo | None,
  itemId: string
): string | null {
  if (!isWalmartMarketplaceInfo(marketplaceInfo) || !itemId) {
    return null;
  }

  // Add www. subdomain because that is what redirecting through goto.walmart.com does.
  return `https://www.${marketplaceInfo.domain}/ip/${itemId}`;
}

export function getWalmartSearchPageURL(
  marketplaceInfo: MarketplaceInfo | None,
  searchPhrase: string | None,
  searchBrands: Array<string> | None,
  searchFacets: Array<[string, string]> | None,
  searchParameters: Array<[string, string]> | None,
  sortByBestSeller: boolean
): string | null {
  if (!isWalmartMarketplaceInfo(marketplaceInfo) || !searchPhrase) {
    return null;
  }

  // Add www. subdomain because that is what redirecting through goto.walmart.com does.
  let urlString = `https://www.${marketplaceInfo.domain}/search`;

  const urlParams = new URLSearchParams();
  urlParams.set("q", searchPhrase);

  if (searchParameters) {
    searchParameters.forEach(([name, value]) => {
      if (name) {
        urlParams.set(name, value);
      }
    });
  }
  const facets: Array<string> = [];
  if (searchBrands) {
    searchBrands.forEach(brand => facets.push(`brand:${brand}`));
  }
  if (searchFacets) {
    searchFacets.forEach(([name, value]) => facets.push(`${name}:${value}`));
  }

  if (facets.length > 0) {
    let facetParam = urlParams.get("facet") || "";
    if (facetParam) {
      facetParam += "||";
    }
    facetParam += facets.join("||");

    urlParams.set("facet", facetParam);
  }

  if (sortByBestSeller) {
    urlParams.set("sort", "best_seller");
  }

  const urlParamsString = urlParams.toString();
  if (urlParamsString) {
    urlString = `${urlString}?${urlParamsString}`;
  }

  return urlString;
}

// Unlikely strings that will not be url encoded.
const LBRACE_TOKEN = "xxLBRACExx";
const RBRACE_TOKEN = "xxRBRACExx";
const LBRACE_REGEXP = new RegExp(LBRACE_TOKEN, "g");
const RBRACE_REGEXP = new RegExp(RBRACE_TOKEN, "g");

// Returns a new redirect URL, given an existing redirect (outer) URL and an
// effective (inner) URL.  If no redirect URL is specified, then the effective URL
// is returned.  If the redirect URL has any braces ('{', '}'), then those braces
// are retained and are NOT url encoded.  If the redirect URL is not a valid URL,
// then null is returned.
export function getWalmartURLWithRedirect(
  effectiveURL: string,
  redirectURL: string | None
): string | null {
  if (!redirectURL) {
    return effectiveURL;
  }

  try {
    const urlWithoutBraces = redirectURL
      .replace(/{/g, LBRACE_TOKEN)
      .replace(/}/g, RBRACE_TOKEN);

    const url = new URL(urlWithoutBraces);

    url.searchParams.set("u", effectiveURL);

    const urlWithBraces = url
      .toString()
      .replace(LBRACE_REGEXP, "{")
      .replace(RBRACE_REGEXP, "}");

    return urlWithBraces;
  } catch (_) {
    return null;
  }
}

const impactRadiusTrackingHostname = "goto.walmart.com";
const impactRadiusTrackingHostnameAlt1 = "walmart.sjv.io";
const impactRadiusTrackingHostnameAlt2 = "walmart.pxf.io";

const impactRadiusPathRegex = /^\/c\/([0-9]+)\/([0-9]+)\/([0-9]+)/;

const impactRadiusAmpdPartnerID = "4845193";
const impactRadiusAdID = "565706";
const impactRadiusWalmartAttributionProgramID = "9383";
const impactRadiusAffiliateProviderIDFromWalmart = "000011112222333344";

// The expected form of a url with Walmart/Impact Radius attribution:
//
//	https://goto.walmart.com/c/{impactRadiusPartnerId}/{impactRadiusAdId}/{impactRadiusProgramId}
//	   ?subId1={ga-customerid}
//	    &subId2={campaignid}
//	    &subId3={targetid}
//	    &sharedid=ampd-{campaignid}-{targetid}
//	    &veh=aff
//	    &sourceid={imp_affiliateproviderid}
//	    &u={targeturl}
export function checkForWalmartAttributionPath(urlString: string): boolean {
  const parsedURL = new URL(urlString.toLowerCase());
  const hostname = parsedURL.hostname;
  if (
    hostname !== impactRadiusTrackingHostname &&
    hostname !== impactRadiusTrackingHostnameAlt1 &&
    hostname !== impactRadiusTrackingHostnameAlt2
  ) {
    return false;
  }

  const pathMatches = parsedURL.pathname.match(impactRadiusPathRegex);
  if (!pathMatches || pathMatches.length < 4) {
    return false;
  }

  const impactRadiusPartnerId = pathMatches[1];
  const impactRadiusAdId = pathMatches[2];
  const impactRadiusProgramId = pathMatches[3];

  const usesAmpdWalmartAttributionPath =
    impactRadiusPartnerId === impactRadiusAmpdPartnerID &&
    impactRadiusAdId === impactRadiusAdID &&
    impactRadiusProgramId === impactRadiusWalmartAttributionProgramID;

  return usesAmpdWalmartAttributionPath;
}

export function checkForWalmartAttributionParams(queryString: string): boolean {
  // just using a dummy base URL here so we can parse the query string,
  // we're not actually using the base URL for anything
  const parsedURL = new URL(
    `https://goto.walmart.com?${queryString.toLocaleLowerCase()}`
  );

  const params = parsedURL.searchParams;
  if (params.get("veh") !== "aff") {
    return false;
  }

  const sourceId = params.get("sourceid");
  const subId1 = params.get("subid1");
  const subId2 = params.get("subid2");
  const subId3 = params.get("subid3");
  const sharedid = params.get("sharedid");

  if (sourceId !== `imp_${impactRadiusAffiliateProviderIDFromWalmart}`) {
    return false;
  }

  if ((!subId1 && !subId2 && !subId3) || !sharedid) {
    return false;
  }

  return true;
}
