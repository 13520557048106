// grpc-web
import { GRPCWebClient } from "Common/utils/grpc";
import { GetClientSitesForManagerSiteRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";

export type ClientSite = SiteManagerLinkClient.AsObject;

// Lists manager-client links for specified manager site.
export async function getClientSitesForManagerSite(
  managerSiteAlias: string
): Promise<Array<ClientSite>> {
  if (!managerSiteAlias) {
    return [];
  }

  const getRequest = new GetClientSitesForManagerSiteRequest().setManagerSiteAlias(
    managerSiteAlias
  );

  const response = await GRPCWebClient.getClientSitesForManagerSite(
    getRequest,
    null
  );

  return response.toObject().clientSitesList;
}
