import _ from "lodash";
import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback
} from "react";
import {
  Checkbox,
  Form,
  Icon,
  Input,
  Divider,
  Button,
  Message,
  Table,
  Accordion
} from "semantic-ui-react";
import styled from "styled-components";
import { useGoogleKeywordIdeas } from "ExtensionV2/queries/useGoogleKeywordIdeas";
import {
  AmpdDataTableColumn,
  AmpdDataTableSettings,
  AmpdDataTableRow,
  AmpdDataTableTsWrapper,
  TAmpdDataTable
} from "./AmpdDataTableTsWrapper";
import { GenerateGoogleAdsKeywordIdeasReply } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { extractErrorMessage } from "Common/errors/error";
import { GoogleKeyword } from "ExtensionV2/pages/CampaignSetupPage/CampaignSetupPageState";
import {
  backgroundDark,
  backgroundLight,
  semanticGreen,
  semanticGreenHover
} from "ExtensionV2/styles/colors";
import {
  getHumanReadableAmount,
  MICROS_TO_CURRENCY_UNIT_FACTOR
} from "Common/utils/money";
import { pluralize } from "Common/utils/strings";
import SimpleTooltip from "./SimpleTooltip";
import { ClickableText } from "ExtensionV2/pages/CampaignSetupPage/SelectProductsStage";
import { MAX_KEYWORD_LENGTH, scrubKeyword } from "Common/utils/googleAds";
import { useAddKeywordsToCampaignMutation } from "ExtensionV2/queries/useAddKeywordsToCampaignMutation";
import { PositiveKeywordResource } from "ExtensionV2/queries/useItemizedCampaignConfiguration";
import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";

const SeedKeywordPill = styled.div`
  align-items: center;
  background-color: ${semanticGreen};
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  gap: 1em;
  height: 3em;
  justify-content: space-around;
  line-height: 1.5em;
  padding: 0.5em;
  text-align: center;

  :hover {
    background-color: ${semanticGreenHover};
  }

  & div:first-child {
    min-width: 5em;
    max-width: 15em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    cursor: auto;
  }

  & i {
    cursor: pointer;
  }
`;

const AdvancedKeywordPickerLayout = styled.div`
  display: grid;
  grid-template-columns: 4fr minmax(18em, 1fr);
  grid-template-rows: auto 1fr 3em auto;
  grid-template-areas:
    "seed-keywords rightNav"
    "suggestions   rightNav"
    "suggestions   rightNav"
    "errors        rightNav";
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  height: 100%;
`;

const ErrorsGridArea = styled.div`
  grid-area: errors;
  white-space: pre-wrap;
  max-height: 10em;
  overflow-y: auto;
`;

const SeedKeywordsGridArea = styled.div`
  overflow: hidden;
  grid-area: seed-keywords;
  & p:first-child {
    margin: 0 0 0.25em 0;
    font-style: italic;
  }
`;

const KeywordSuggestionsFiltersGridArea = styled.div`
  grid-area: rightNav;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-child {
    height: 100%;
    padding: 0.5em;
    border: 1px solid lightgray;
    border-radius: 5px;
    overflow: auto;
  }

  & > div:last-child {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & a {
      margin-bottom: 0;
    }
  }

  & p:first-child {
    margin: 0 0 0.25em 0;
    font-style: italic;
  }

  & .basic-filters {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 1em;
    font-weight: 600;
    font-size: large;
  }

  & .concept-filter-grouping {
    margin-bottom: 1em;
    gap: 1em;

    & .concept-filter-group-name {
      flex-shrink: 0;
      font-weight: 600;
      margin-bottom: 0.5em;
    }

    & .concept-filter-names {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      & .concept-filter-name {
        margin-left: 0.5em;
      }
    }
  }
`;

const KeywordsSuggestionsGridArea = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 0.5em;
  overflow: auto;
  grid-area: suggestions;

  & p:first-child {
    padding-top: 0.5em;
`;

const PillCrumbInput = styled.div`
  &:focus-within {
    border: 1px solid lightblue;
  }

  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5em;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`;

const ChosenKeywordsSection = styled.div`
  margin-bottom: 1em;
  & p:first-child {
    display: inline-block;
    font-style: italic;
  }

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
  }
`;

const KeywordPill = styled.div`
  height: 2.2em;
  border-radius: 0.5em;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25em;
  cursor: pointer;
`;

const SelectedKeywordPill = styled(KeywordPill)<{
  error?: boolean;
  disabled?: boolean;
}>`
  background-color: azure;
  border: ${props =>
    props.error
      ? "1px solid hsla(360, 100%, 80%, .75)"
      : "1px solid lightblue"};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  :hover {
    border: ${props =>
      props.error ? "1px dashed hsla(360, 100%, 80%, .75)" : "1px dashed blue"};
  }
`;

const CampaignKeywordPill = styled(KeywordPill)`
  background-color: ${backgroundLight};
  border: 1px solid ${backgroundDark};
`;

type KeywordSuggestionFilters = {
  // keyword must contain this text
  text: string;
  // keyword must contain the retailer name, "amazon", "walmart"
  hasRetailerName: boolean;
  // include terms marked as "branded", terms marked as "possibly branded" are included regardless
  includeBrandedTerms: boolean;
  // only include terms that match the selected concepts
  concepts: Array<string>;
};

const MAX_SEED_KEYWORD_COUNT = 10;

interface CandidateGoogleKeyword extends GoogleKeyword {
  // The user may have requested this keyword, but we still need to check with the keyword planner
  // if it has any search volume.
  awaitingConfirmation?: boolean;
}

type AdvancedKeywordPickerProps = {
  siteAlias: string;
  // Google Ads customer id
  customerId: string;
  // The geotargets to use when querying for keyword suggestions
  geotargets: Array<number>;
  // The currency to display keyword bid prices in.
  googleAdsCurrencyCode: string;
  // Empty array if this is a new campaign. Keywords that are already in the campaign. These will
  // show up as already selected and disabled in the keyword suggestions table.
  campaignKeywords: Array<PositiveKeywordResource>;
  // Keywords to be added to the campaign. This will probably be empty if this is an existing campaign.
  initialNewKeywords: Array<GoogleKeyword>;
  // The initial seed keywords when the Picker loads.
  initialSeedKeywords: Array<string>;
  // The name of the retailer ("amazon", "walmart") for special treatment in keyword filters
  retailerName: string;
  // Called when the user clicks the cancel button.
  onCancel: () => void;
  // Called when the user clicks the save button.
  onSubmit: (newKeywords: Array<GoogleKeyword>) => void;
  // Whether the form is currently submitting.
  isSubmitting: boolean;
  // An error message to display if the form submission fails.
  submitError: string;
};

// Use to add new keywords to an existing campaign.
export const AddKeywordsPicker = (
  props: Omit<
    AdvancedKeywordPickerProps,
    "onSubmit" | "isSubmitting" | "submitError"
  > & { adGroupId: string; onSave: (keywords: Array<GoogleKeyword>) => void }
): JSX.Element => {
  const { adGroupId, siteAlias, customerId, onSave } = props;

  const {
    mutate: addKeywordsToCampaign,
    isLoading: addKeywordsToCampaignLoading,
    error: addKeywordsToCampaignError
  } = useAddKeywordsToCampaignMutation();

  const onSubmit = useCallback(
    (newKeywords: Array<GoogleKeyword>) => {
      addKeywordsToCampaign(
        {
          keywordsToAdd: newKeywords.map(kw => kw.text),
          adGroupId,
          siteAlias,
          customerId
        },
        {
          onSuccess: response => {
            if (response.addAdGroupKeywordFailuresList.length === 0) {
              onSave(newKeywords);
            }
          }
        }
      );
    },
    [adGroupId, addKeywordsToCampaign, customerId, onSave, siteAlias]
  );

  let errorMessage = "";
  if (addKeywordsToCampaignError) {
    if (typeof addKeywordsToCampaignError === "string") {
      errorMessage = addKeywordsToCampaignError;
    } else {
      errorMessage = extractErrorMessage(addKeywordsToCampaignError);
    }
  }

  return (
    <AdvancedKeywordPicker
      {...props}
      onSubmit={onSubmit}
      isSubmitting={addKeywordsToCampaignLoading}
      submitError={errorMessage}
    />
  );
};

// Use as part of the campaign setup flow. Saving AdvancedKeywordPicker here doesn't actually make
// any network calls, it just sets the keywords in the local campaign state so the campaign can be
// saved later.
export const NewCampaignKeywordsPicker = (
  props: Omit<
    AdvancedKeywordPickerProps,
    "campaignKeywords" | "onSubmit" | "isSubmitting" | "submitError"
  > & { onSave: (keywords: Array<GoogleKeyword>) => void }
): JSX.Element => {
  return (
    <AdvancedKeywordPicker
      {...props}
      isSubmitting={false}
      submitError={""}
      onSubmit={props.onSave}
      campaignKeywords={[]}
    />
  );
};

const AdvancedKeywordPicker = (
  props: AdvancedKeywordPickerProps
): JSX.Element => {
  const {
    customerId,
    geotargets,
    googleAdsCurrencyCode,
    campaignKeywords,
    initialNewKeywords,
    initialSeedKeywords,
    retailerName,
    siteAlias,
    onCancel,
    onSubmit,
    isSubmitting,
    submitError
  } = props;

  const [
    showCampaignKeywordsAccordion,
    setShowCampaignKeywordsAccordion
  ] = useState(true);

  const [seedKeywords, setSeedKeywords] = useState([...initialSeedKeywords]);
  const [proposedKeywords, setProposedKeywords] = useState<
    Array<CandidateGoogleKeyword>
  >(initialNewKeywords);

  const {
    data: keywordSuggestions,
    isFetching: keywordSuggestionsAreFetching,
    error: keywordSuggestionsError
  } = useGoogleKeywordIdeas(
    siteAlias,
    customerId,
    "", // seed url
    seedKeywords,
    geotargets
  );

  // we may have added a keyword that doesn't have any data yet, so we need to
  // update it when useGoogleKeywordIdeas completes
  useEffect(() => {
    for (const kw of proposedKeywords) {
      if (kw.awaitingConfirmation) {
        const keywordInfo = keywordSuggestions?.keywordIdeasList.find(
          idea => kw.text === idea.text
        );

        if (keywordInfo) {
          const keywords = [...proposedKeywords].filter(
            k => k.text !== kw.text
          );
          keywords.push({
            text: kw.text,
            avgMonthlySearches: keywordInfo.avgMonthlySearches?.value,
            competitionIndex: keywordInfo.competitionIndex?.value,
            lowTopOfPageBid: keywordInfo.lowTopOfPageBid?.value,
            highTopOfPageBid: keywordInfo.highTopOfPageBid?.value,
            awaitingConfirmation: false
          });
          setProposedKeywords(keywords);
        }
      }
    }
  }, [proposedKeywords, keywordSuggestions?.keywordIdeasList]);

  // Keyword Suggestions can have an optional concept which belongs to a concept group, ex: the
  // keyword suggestion "dried salmon biscuits" might have the concept "dog food" which belongs to
  // the concept group "pet supplies"
  const concepts = useMemo(() => {
    return getConceptGroupings(keywordSuggestions?.keywordIdeasList);
  }, [keywordSuggestions]);

  const [filters, setFilters] = useState<KeywordSuggestionFilters>({
    text: "",
    hasRetailerName: false,
    includeBrandedTerms: false,
    concepts: []
  });

  const {
    filteredWithBrandedTerms,
    filteredWithoutBrandedTerms
  } = useMemo(() => {
    if (!keywordSuggestions) {
      return {
        filteredWithBrandedTerms: [],
        filteredWithoutBrandedTerms: []
      };
    }

    return filterKeywordSuggestions({
      keywordSuggestions,
      filters,
      retailerName
    });
  }, [keywordSuggestions, filters, retailerName]);

  const filteredKeywordSuggestions = filters.includeBrandedTerms
    ? filteredWithBrandedTerms
    : filteredWithoutBrandedTerms;

  const retailerKeywordCount = useMemo(() => {
    return filteredKeywordSuggestions.filter(keyword => {
      return keyword.text.includes(retailerName);
    }).length;
  }, [filteredKeywordSuggestions, retailerName]);

  const [seedKeywordInputVal, setSeedKeywordInputVal] = useState("");

  const seedKeywordInputRef = useRef<HTMLInputElement>(null);

  const handleRemoveGoogleKeyword = useCallback(
    (keyword: GoogleKeyword) => {
      setProposedKeywords(
        proposedKeywords.filter(k => k.text !== keyword.text)
      );
    },
    [proposedKeywords]
  );

  // This memo has a notable impact on performance, remove with caution.
  const keywordSuggestionsTable = useMemo(() => {
    const handleAddGoogleKeyword = (keyword: GoogleKeyword) => {
      setProposedKeywords([...proposedKeywords, keyword]);
    };

    const campaignKeywordTexts = new Set([
      ...campaignKeywords.map(kw => kw.text)
    ]);

    return (
      <KeywordSuggestionsTable
        preselectedKeywords={campaignKeywordTexts}
        googleAdsCurrencyCode={googleAdsCurrencyCode}
        keywordIdeasList={filteredKeywordSuggestions}
        keywordSuggestionsAreFetching={keywordSuggestionsAreFetching}
        keywordSuggestionsError={extractErrorMessage(keywordSuggestionsError)}
        selectedGoogleKeywords={new Set(proposedKeywords.map(k => k.text))}
        onToggleKeyword={(keyword: GoogleKeyword, checked: boolean) => {
          if (checked) {
            handleAddGoogleKeyword(keyword);
          } else {
            handleRemoveGoogleKeyword(keyword);
          }
        }}
      />
    );
  }, [
    campaignKeywords,
    filteredKeywordSuggestions,
    googleAdsCurrencyCode,
    proposedKeywords,
    handleRemoveGoogleKeyword,
    keywordSuggestionsAreFetching,
    keywordSuggestionsError
  ]);

  const handleAddSeedKeyword = (_e: React.FormEvent<HTMLFormElement>) => {
    if (seedKeywords.length >= MAX_SEED_KEYWORD_COUNT) {
      return;
    }

    const newSeedKeyword = scrubKeyword(
      seedKeywordInputVal,
      MAX_KEYWORD_LENGTH
    );
    setSeedKeywordInputVal("");

    if (!newSeedKeyword) {
      return;
    }

    setSeedKeywords(prevSeeds => {
      const nextSeeds = [...prevSeeds, newSeedKeyword];
      return _.uniq(nextSeeds);
    });

    // Add the seed keyword to the list of google keywords. If it turns out there is no suggestion
    // data for this keyword, we will annotate it with a warning for the user.
    if (!proposedKeywords.find(kw => kw.text === newSeedKeyword)) {
      setProposedKeywords(prevGoogleKeywords => [
        ...prevGoogleKeywords,
        {
          text: newSeedKeyword,
          avgMonthlySearches: 0,
          competitionIndex: 0,
          lowTopOfPageBid: 0,
          highTopOfPageBid: 0,
          awaitingConfirmation: true
        }
      ]);
    }
  };

  const handleRemoveSeedKeyword = (keyword: string) => {
    setSeedKeywords(seedKeywords.filter(k => k !== keyword));
  };

  const handleRemoveAllGoogleKeywords = () => {
    setProposedKeywords([]);
  };

  // Disable the submit button if the user hasn't added any new keywords.
  const [newKeywords, userHasChangedKeywords] = useMemo(() => {
    const newKeywords = proposedKeywords.filter(
      kw =>
        !campaignKeywords.find(
          campaignKeyword => campaignKeyword.text === kw.text
        )
    );

    return [
      newKeywords,
      // Don't enable the save button unless there are actually any changes to save.
      _.xorBy(newKeywords, initialNewKeywords, "text").length > 0
    ];
  }, [campaignKeywords, initialNewKeywords, proposedKeywords]);

  const activeCampaignKeywords = campaignKeywords.filter(
    kw => kw.status === GoogleAdsResourceStatus.Option.ENABLED
  );

  return (
    <AdvancedKeywordPickerLayout>
      {/* 
        Seed Keywords
      */}
      <SeedKeywordsGridArea
        onClick={() => seedKeywordInputRef.current?.focus()}
      >
        <p>
          Enter some simple search phrases that describe your product. We'll
          provide some keyword suggestions based on what you enter.
        </p>
        <PillCrumbInput>
          {seedKeywords.map(keyword => {
            return (
              <SeedKeywordPill key={keyword}>
                <div>{keyword}</div>
                <Icon
                  name="x"
                  size="small"
                  onClick={() => handleRemoveSeedKeyword(keyword)}
                />
              </SeedKeywordPill>
            );
          })}
          <Form onSubmit={handleAddSeedKeyword}>
            {seedKeywords.length < MAX_SEED_KEYWORD_COUNT && (
              <Input
                autoFocus
                transparent
                style={{
                  minWidth: "15em",
                  height: "3em",
                  paddingLeft: "0.5em"
                }}
                placeholder="+ Enter a keyword"
                value={seedKeywordInputVal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSeedKeywordInputVal(e.currentTarget.value)
                }
              >
                <input ref={seedKeywordInputRef} />
              </Input>
            )}
          </Form>
        </PillCrumbInput>
      </SeedKeywordsGridArea>

      {/* 
        Right Nav
      */}
      <KeywordSuggestionsFiltersGridArea>
        {/* 
          Filters
        */}
        <div>
          <p>
            {`Showing ${pluralize(
              filteredKeywordSuggestions.length,
              "idea",
              "ideas"
            )} of ${keywordSuggestions?.keywordIdeasList.length ?? 0}`}
          </p>

          <KeywordSuggestionsFilters
            retailerName={retailerName}
            retailerKeywordCount={retailerKeywordCount}
            brandedCount={
              filteredWithBrandedTerms.length -
              filteredWithoutBrandedTerms.length
            }
            concepts={concepts}
            filters={filters}
            onUpdateFilters={setFilters}
          />
        </div>
        {/* 
          Controls
        */}
        <div>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            primary
            disabled={
              keywordSuggestionsAreFetching ||
              isSubmitting ||
              !userHasChangedKeywords ||
              proposedKeywords.length === 0
            }
            loading={keywordSuggestionsAreFetching || isSubmitting}
            onClick={() => {
              onSubmit(newKeywords);
            }}
          >
            Save
          </Button>
        </div>
      </KeywordSuggestionsFiltersGridArea>

      <KeywordsSuggestionsGridArea>
        {/* 
           Active Campaign keywords
        */}
        {activeCampaignKeywords.length > 0 && (
          <Accordion>
            <Accordion.Title
              active={showCampaignKeywordsAccordion}
              onClick={() => setShowCampaignKeywordsAccordion(open => !open)}
            >
              <Icon name="dropdown" />
              Existing Search Keywords
            </Accordion.Title>
            <Accordion.Content active={showCampaignKeywordsAccordion}>
              <ChosenKeywordsSection>
                <div>
                  {activeCampaignKeywords.map(kw => {
                    return (
                      <CampaignKeywordPill
                        key={kw.text}
                        onClick={() => {
                          setSeedKeywords(prevSeeds => {
                            const nextSeeds = [...prevSeeds, kw.text];
                            return _.uniq(nextSeeds);
                          });
                        }}
                      >
                        <p style={{ margin: 0, padding: 0 }}>{kw.text}</p>
                      </CampaignKeywordPill>
                    );
                  })}
                </div>
              </ChosenKeywordsSection>
            </Accordion.Content>
          </Accordion>
        )}
        {/* 
          Selected Keywords
        */}
        <ChosenKeywordsSection>
          {newKeywords.length > 0 && (
            <>
              <p>
                Add These Google Search Keywords To Your Campaign (
                {newKeywords.length}) -
              </p>
              <ClickableText
                style={{ display: "inline-block", marginLeft: "0.5em" }}
                onClick={handleRemoveAllGoogleKeywords}
              >
                Remove All
              </ClickableText>
            </>
          )}

          <div>
            {newKeywords.map(kw => {
              // avgMonthlySearches is undefined when copying keywords. Check for explicit zero.
              const hasNoSearchVolume = kw.avgMonthlySearches === 0;

              const warnNoSearchVolume =
                hasNoSearchVolume && !keywordSuggestionsAreFetching;

              const pillDisabled =
                kw.awaitingConfirmation && keywordSuggestionsAreFetching;

              return (
                <SimpleTooltip
                  tooltip="This keyword has very low search volume."
                  disabled={!warnNoSearchVolume}
                  key={kw.text}
                >
                  <SelectedKeywordPill
                    error={warnNoSearchVolume}
                    disabled={pillDisabled}
                  >
                    <p style={{ margin: 0, padding: 0 }}>{kw.text} </p>
                    {!pillDisabled && (
                      <Icon
                        onClick={() => handleRemoveGoogleKeyword(kw)}
                        style={{ margin: 0, padding: 0 }}
                        name="cancel"
                        size="small"
                      />
                    )}
                  </SelectedKeywordPill>
                </SimpleTooltip>
              );
            })}
          </div>
        </ChosenKeywordsSection>
        {/* 
          Keyword Ideas table
        */}
        {keywordSuggestionsTable}
      </KeywordsSuggestionsGridArea>
      <ErrorsGridArea>
        <Message
          hidden={!submitError}
          error
          content={
            <>
              <p>There was an error when saving your keywords.</p>
              <p>{submitError}</p>
            </>
          }
        />
      </ErrorsGridArea>
    </AdvancedKeywordPickerLayout>
  );
};

const KeywordSuggestionsFilters = ({
  retailerName,
  retailerKeywordCount,
  brandedCount,
  concepts,
  filters,
  onUpdateFilters
}: {
  retailerName: string;
  retailerKeywordCount: number;
  brandedCount: number;
  concepts: ConceptGroupings;
  filters: KeywordSuggestionFilters;
  onUpdateFilters: (filters: KeywordSuggestionFilters) => void;
}) => {
  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // process the search filter async to keep the UI responsive.
    const text = e.currentTarget.value;
    setTimeout(() => {
      onUpdateFilters({
        ...filters,
        text
      });
    }, 0);
  };

  const handleAddConcepts = (concepts: Array<string>) => {
    onUpdateFilters({
      ...filters,
      concepts: [...filters.concepts, ...concepts]
    });
  };

  const handleRemoveConcept = (concepts: Array<string>) => {
    onUpdateFilters({
      ...filters,
      concepts: filters.concepts.filter(
        conceptName => !concepts.includes(conceptName)
      )
    });
  };

  const conceptsToggles = [];
  for (const [groupName, groupConcepts] of concepts.entries()) {
    const conceptFilters = [];
    let groupCount = 0;

    for (const [concept, count] of groupConcepts.entries()) {
      groupCount += count;
      conceptFilters.push(
        <Checkbox
          className={"concept-filter-name"}
          key={concept}
          label={`${concept} (${count})`}
          checked={filters.concepts.includes(concept)}
          onChange={(_e, data) => {
            if (data.checked) {
              handleAddConcepts([concept]);
            } else {
              handleRemoveConcept([concept]);
            }
          }}
        />
      );
    }

    // A concept group can't itself be selected - the box is checked if all of its child-concepts
    // are selected, and unchecking it should uncheck all its child-concepts.
    const conceptGroup = (
      <Checkbox
        className={"concept-filter-group-name"}
        label={`${groupName} (${groupCount})`}
        checked={[...groupConcepts.keys()].every(concept =>
          filters.concepts.includes(concept)
        )}
        onChange={(_e, data) => {
          const concepts = [...groupConcepts.keys()];
          if (data.checked) {
            handleAddConcepts(concepts);
          } else {
            handleRemoveConcept(concepts);
          }
        }}
      />
    );

    conceptsToggles.push(
      <React.Fragment key={groupName}>
        <Divider />
        <div className="concept-filter-grouping">
          {conceptGroup}
          <div className="concept-filter-names">{conceptFilters}</div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div style={{ gridArea: "filter-options" }}>
      <div className="basic-filters">
        <Input
          icon="filter"
          iconPosition="left"
          placeholder="Filter by text"
          onChange={handleSearchTermChange}
        />
        <Checkbox
          className={"select-keyword-filter-checkbox"}
          label={`Only show '${retailerName}' keywords (${retailerKeywordCount})`}
          onChange={(_e, data) =>
            onUpdateFilters({ ...filters, hasRetailerName: !!data.checked })
          }
        />

        <Checkbox
          className={"select-keyword-filter-checkbox"}
          label={`Include branded terms (${brandedCount})`}
          onChange={(_e, data) =>
            onUpdateFilters({
              ...filters,
              includeBrandedTerms: !!data.checked
            })
          }
        />
      </div>

      {conceptsToggles}
    </div>
  );
};

const KeywordSuggestionsTable = ({
  preselectedKeywords,
  googleAdsCurrencyCode,
  keywordIdeasList,
  keywordSuggestionsAreFetching,
  keywordSuggestionsError,
  selectedGoogleKeywords,
  onToggleKeyword
}: {
  preselectedKeywords: Set<string>;
  googleAdsCurrencyCode: string;
  keywordIdeasList: Array<
    GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.AsObject
  >;
  keywordSuggestionsAreFetching: boolean;
  keywordSuggestionsError: string;
  selectedGoogleKeywords: Set<string>;
  onToggleKeyword: (keyword: GoogleKeyword, checked: boolean) => void;
}) => {
  type KeywordTableData = {
    isSelected: boolean;
    keyword: string;
    searchVolume: number;
    highBid: number;
    lowBid: number;
    competition: number;
    isPreselectedKeyword?: boolean;
  };

  const columns: Array<AmpdDataTableColumn<KeywordTableData>> = [
    { name: "isSelected", displayName: "✓" },
    { name: "keyword", displayName: "Additional Keyword Ideas" },
    { name: "searchVolume", displayName: "Search Volume" },
    { name: "highBid", displayName: "High Bid" },
    { name: "lowBid", displayName: "Low Bid" }
  ];

  const [rows, setRows] = useState<Array<
    AmpdDataTableRow<KeywordTableData>
  > | null>(null);
  useEffect(() => {
    // This is a hack to put the rendering of the table rows onto the event loop so that it doesn't
    // block launching the modal. This is necessary because we can receive up to 500 keyword
    // suggestions from Google, which can take ~100-200 ms to render, which is pretty noticeable
    // when it means the modal doesn't pop up for that long. A better long-term solution might be to
    // support paging or lazy rendering in AmpdDataTable, or paginate the KW query. Sorting the
    // table columns is a bit laggy with with 500 rows, there are some optimizations we could make
    // there too (e.g. presorting and caching the various sort states.)
    const timeout = setTimeout(() => {
      setRows(
        keywordIdeasList.map(keywordIdea => {
          return {
            isSelected: selectedGoogleKeywords.has(keywordIdea.text),
            keyword: keywordIdea.text,
            searchVolume: keywordIdea.avgMonthlySearches?.value || 0,
            highBid: keywordIdea.highTopOfPageBid?.value || 0,
            lowBid: keywordIdea.lowTopOfPageBid?.value || 0,
            competition: keywordIdea.competitionIndex?.value || 0,
            isPreselectedKeyword: preselectedKeywords.has(keywordIdea.text)
          };
        })
      );
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [preselectedKeywords, keywordIdeasList, selectedGoogleKeywords]);

  const config: AmpdDataTableSettings<KeywordTableData> = {
    defaultSortColumn: "searchVolume",
    emptyContent: (
      <div
        style={{
          minHeight: "12em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          textAlign: "center"
        }}
      >
        <p>
          No suggestions found. Try adding some more keywords or removing some
          filters.
        </p>
      </div>
    ),
    mapDataRowToComponent: (row, columnNames) => {
      const googleKeyword: GoogleKeyword = {
        text: row.keyword,
        avgMonthlySearches: row.searchVolume,
        highTopOfPageBid: row.highBid,
        lowTopOfPageBid: row.lowBid,
        competitionIndex: row.competition
      };
      return (
        <Table.Row
          disabled={row.isPreselectedKeyword}
          key={row.keyword}
          onClick={() => {
            onToggleKeyword(googleKeyword, !row.isSelected);
          }}
        >
          {columnNames.map(columnName => {
            const data = row[columnName];
            const key = `${row.keyword}-${columnName}`;
            switch (columnName) {
              case "isSelected":
                return (
                  <Table.Cell key={key}>
                    <Checkbox
                      disabled={row.isPreselectedKeyword}
                      checked={row.isSelected || row.isPreselectedKeyword}
                    />
                  </Table.Cell>
                );
              case "highBid":
              case "lowBid":
                return (
                  <Table.Cell key={key}>
                    {data
                      ? getHumanReadableAmount(
                          Number(data) * MICROS_TO_CURRENCY_UNIT_FACTOR,
                          googleAdsCurrencyCode
                        )
                      : "-"}
                  </Table.Cell>
                );
              case "competition":
                return <></>;
              default:
                return <Table.Cell key={key}>{`${data ?? "-"}`}</Table.Cell>;
            }
          })}
        </Table.Row>
      );
    }
  };

  const tableConfig: TAmpdDataTable<KeywordTableData> = {
    columns,
    rows: rows || [],
    settings: config
  };

  return (
    <AmpdDataTableTsWrapper
      tableConfig={tableConfig}
      isLoading={keywordSuggestionsAreFetching || rows == null}
      error={keywordSuggestionsError}
    />
  );
};

const filterKeywordSuggestions = ({
  keywordSuggestions,
  filters,
  retailerName
}: {
  filters: KeywordSuggestionFilters;
  keywordSuggestions: GenerateGoogleAdsKeywordIdeasReply.AsObject;
  retailerName: string;
}): {
  filteredWithBrandedTerms: GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.AsObject[];
  filteredWithoutBrandedTerms: GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.AsObject[];
} => {
  const { text, hasRetailerName, concepts } = filters;

  let filteredKeywordSuggestions = [...keywordSuggestions.keywordIdeasList];

  if (text) {
    filteredKeywordSuggestions = filteredKeywordSuggestions.filter(keyword => {
      return keyword.text.includes(text);
    });
  }

  if (hasRetailerName) {
    filteredKeywordSuggestions = filteredKeywordSuggestions.filter(keyword => {
      return keyword.text.includes(retailerName);
    });
  }

  if (concepts.length > 0) {
    filteredKeywordSuggestions = filteredKeywordSuggestions.filter(keyword => {
      for (const concept of keyword.conceptsList) {
        if (concepts.includes(concept.text)) {
          return true;
        }
      }
    });
  }

  const filteredWithoutBrandedTerms = filteredKeywordSuggestions.filter(
    keyword => {
      // If the only brand name is "amazon" consider the keyword not-branded
      return (
        keyword.brandNamesList.length === 0 ||
        (keyword.brandNamesList.length === 1 &&
          keyword.brandNamesList[0] === retailerName)
      );
    }
  );

  return {
    filteredWithBrandedTerms: filteredKeywordSuggestions,
    filteredWithoutBrandedTerms
  };
};

// ex: {
//   [conceptGrouping]: {
//     [concept]: count;
//   }
// }
type ConceptGroupings = Map<string, Map<string, number>>;

const getConceptGroupings = (
  keywordIdeas:
    | Array<GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.AsObject>
    | undefined
): ConceptGroupings => {
  const conceptGroupings: ConceptGroupings = new Map();

  for (const keyword of keywordIdeas || []) {
    for (const concept of keyword.conceptsList) {
      const group = conceptGroupings.get(concept.conceptGroup);
      if (!group) {
        conceptGroupings.set(
          concept.conceptGroup,
          new Map([[concept.text, 1]])
        );
      } else {
        const count = group.get(concept.text) || 0;
        group.set(concept.text, count + 1);
      }
    }
  }

  return conceptGroupings;
};
