// Utility methods for managing storing, loading and reporting UTM parameters.

import _ from "lodash";
import Immutable from "immutable";
import * as proto from "./proto";
import { UTMParameters } from "../proto/common/utmParameters_pb";
import { extractErrorMessage } from "../errors/error";

// Local storage key where we preserve our UTM parameters.
export const utmParamsKey = "utm_params";

const utmParamMap = Immutable.Map({
  utm_source: "source",
  utm_medium: "medium",
  utm_campaign: "campaign",
  utm_term: "term",
  utm_content: "content"
});

// Extracts the UTM parameters from a query object.
export const extractUTMParams = query => {
  const utmParams = {};

  utmParamMap.entrySeq().forEach(([param, key]) => {
    const value = _.get(query, param);
    if (_.isString(value)) {
      utmParams[key] = value;
    }
  });

  return utmParams;
};

// Saves the UTM params to local storage. Note that this will override any
// previous value, even if the params are empty.
export const saveUTMParams = params => {
  const utmParams = {};

  // Set the value for each UTM key that is present.
  utmParamMap.valueSeq().forEach(key => {
    const value = _.get(params, key);
    if (_.isString(value)) {
      utmParams[key] = value;
    }
  });

  localStorage.setItem(utmParamsKey, JSON.stringify(utmParams));
};

// Loads the UTM params from local storage. Returns an empty object if there
// are no params in local storage, or the params are not an object.
export const getUTMParams = () => {
  const utmParamsJSON = localStorage.getItem(utmParamsKey);
  if (!utmParamsJSON) {
    return {};
  }

  try {
    const utmParams = JSON.parse(utmParamsJSON);
    return _.isPlainObject(utmParams) ? utmParams : {};
  } catch (error) {
    console.error(extractErrorMessage(error)); // log to datadog
    return {};
  }
};

// Removes any UTM params from local storage.
export const deleteUTMParams = () => {
  localStorage.removeItem(utmParamsKey);
};

// Converts the UTM parameters to a UTMParameters proto.
export const convertUTMParamsToProto = params => {
  params = params || {};

  const paramsProto = new UTMParameters();

  // Set the value for each UTM key that is present.
  utmParamMap.valueSeq().forEach(key => {
    const value = _.get(params, key);
    if (_.isString(value)) {
      proto.set(paramsProto, { [key]: value });
    }
  });

  return paramsProto;
};
