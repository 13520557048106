import { FormField } from "./CampaignSetupPageState";

const walmartBrandsValidator = (brands: Array<string>): Set<string> => {
  const errors = new Set<string>();
  if (!brands.length) {
    errors.add("Please enter a brand on Walmart");
    return errors;
  }

  return errors;
};

export const walmartBrandsField = (
  initialValue: Array<string>
): FormField<Array<string>> => ({
  _value: initialValue,
  errors: walmartBrandsValidator(initialValue),
  validator: walmartBrandsValidator
});
