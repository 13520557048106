import React from "react";

import { Checkbox } from "semantic-ui-react";
import styled from "styled-components";

import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnAnnotation,
  getMetricColumnCompareInfo,
  renderFormattedValue
} from "./MetricColumns";

import { ReactComponent as Indent } from "assets/indent.svg";
import { ReactComponent as IndentLast } from "assets/indent-last.svg";
import { IMPACTED_PRODUCT_COLUMNS } from "../pages/CampaignsPage/CampaignsPage";
import { KeywordTextCell, NotApplicableCell } from "./CampaignsTableRow";
import {
  AMPD_SELECTED_ROW_FOCUS_CLASS,
  DataTableMetricCell,
  DataTableRow,
  DataTableRowCell,
  defaultSortComparator,
  defaultValueSortComparator
} from "./AmpdDataTable";
import SimpleTooltip from "./SimpleTooltip";
import { getDetailPageURLForASIN } from "Common/utils/amazon";
import { getWalmartDetailPageURLForItemId } from "Common/utils/walmart";
import {
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo
} from "../../Common/utils/marketplace";
import { Retailer } from "../../Common/proto/common/retailer_pb";
import { stringForEnum } from "../../Common/utils/proto";

export const SHOW_PRODUCT_ASIN_IN_TABLE = 1 << 0;
export const SHOW_PARENT_ASIN_IN_TABLE = 1 << 1;
export const SHOW_BRAND_NAME_IN_TABLE = 1 << 2;
export const SHOW_BRAND_MEMBERSHIP_IN_TABLE = 1 << 3;

export const SHOW_PRODUCT_SUBCATEGORY_IN_TABLE = 1 << 4;
export const SHOW_PRODUCT_CATEGORY_IN_TABLE = 1 << 5;
export const SHOW_PRODUCT_GROUP_IN_TABLE = 1 << 6;

const TooltipLabel = styled.span`
  font-style: italic;
  font-weight: lighter;
  margin-right: 1em;
`;

const TextWithEllipsis = styled.div`
  max-width: 15em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TooltipTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: smaller;
  justify-content: space-between;
  align-content: center;
  gap: 1em;
`;

const CheckboxTightLabel = styled(Checkbox)`
  label {
    padding-left: 1.5em !important;
    font-size: smaller !important;
    font-weight: normal;
  }
`;

const StrikeableSpan = styled.span``;

const StrikeableDataTableRow = styled(DataTableRow)`
  &.strikeout td ${StrikeableSpan}:before {
    text-decoration: line-through;
    content: "\\00A0";
  }
  &.strikeout td ${StrikeableSpan} {
    text-decoration: line-through;
  }

  &.strikeout td ${StrikeableSpan}:after {
    text-decoration: line-through;
    content: "\\00A0";
  }
`;

const CampaignsTableImpactedProductRow = ({
  itemizedCampaignConfiguration,
  columns,
  costCurrencyCode,
  isFocalRow,
  isLastRow,
  impactedProductsTableFlags,
  impactedProduct,
  revenueCurrencyCode,
  rowIndex,
  showFractions,
  showUnconvertedRevenue,
  disabled
}) => {
  const { marketplaceInfo } = itemizedCampaignConfiguration;

  // Products that do not belong to the brand will have the text in their
  // table cells overstricken to indicate that they do not contribute to the
  // campaign values.
  let className = undefined;
  if (
    impactedProduct.brandMembership ===
    Retailer.BrandMembership.Option.NON_BRAND
  ) {
    className = "strikeout";
  }

  return (
    <StrikeableDataTableRow
      className={className}
      style={{
        fontSize: "small"
      }}
      isLastKeywordRow={isLastRow}
      disabled={disabled}
    >
      {(columns || []).map(columnName => {
        switch (columnName) {
          case COLUMN_DATA_KEYS.campaignName: {
            return (
              <KeywordTextCell key={columnName} rowIndex={rowIndex}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center"
                  }}
                >
                  <span
                    className={
                      isFocalRow ? AMPD_SELECTED_ROW_FOCUS_CLASS : undefined
                    }
                    style={{
                      marginRight: ".5em"
                    }}
                  >
                    {isLastRow ? <IndentLast /> : <Indent />}
                  </span>
                  <StrikeableSpan
                    style={{ height: "fit-content", alignSelf: "center" }}
                  >
                    {impactedProduct.productName}
                  </StrikeableSpan>
                </div>
              </KeywordTextCell>
            );
          }

          case COLUMN_DATA_KEYS.status: {
            // Show Brand Name, if requested.
            const showBrandName = showInImpactedProductsTable(
              impactedProductsTableFlags,
              SHOW_BRAND_NAME_IN_TABLE
            );
            // Show Parent ASIN, if requested.
            const showParentAsin = showInImpactedProductsTable(
              impactedProductsTableFlags,
              SHOW_PARENT_ASIN_IN_TABLE
            );
            // Show Product ASIN, if requested or if the Parent ASIN was requested
            // but there is no parent ASIN.
            const showProductAsin =
              showInImpactedProductsTable(
                impactedProductsTableFlags,
                SHOW_PRODUCT_ASIN_IN_TABLE
              ) ||
              (showParentAsin && !impactedProduct.parentAsin);
            // Show Brand Membership, if requested (and appropriate).
            const showBrandMembership = showInImpactedProductsTable(
              impactedProductsTableFlags,
              SHOW_BRAND_MEMBERSHIP_IN_TABLE
            );

            let parentProductUrl = "";
            let productUrl = "";
            if (isAmazonMarketplaceInfo(marketplaceInfo)) {
              parentProductUrl =
                getDetailPageURLForASIN(
                  marketplaceInfo,
                  impactedProduct.parentAsin
                ) || "";
              productUrl =
                getDetailPageURLForASIN(
                  marketplaceInfo,
                  impactedProduct.productAsin
                ) || "";
            } else if (isWalmartMarketplaceInfo(marketplaceInfo)) {
              productUrl = getWalmartDetailPageURLForItemId(
                marketplaceInfo,
                impactedProduct.productAsin
              );
            }

            return (
              <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                <SimpleTooltip
                  tooltip={<ProductTooltip impactedProduct={impactedProduct} />}
                >
                  <div>
                    {showBrandName && !!impactedProduct.brandName && (
                      <TextWithEllipsis>
                        <StrikeableSpan>
                          Brand: {impactedProduct.brandName}
                        </StrikeableSpan>
                      </TextWithEllipsis>
                    )}
                    {showParentAsin && !!impactedProduct.parentAsin && (
                      <TextWithEllipsis>
                        <StrikeableSpan>
                          Parent:{" "}
                          {parentProductUrl ? (
                            <a
                              href={parentProductUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {impactedProduct.parentAsin}
                            </a>
                          ) : (
                            impactedProduct.parentAsin
                          )}
                        </StrikeableSpan>
                      </TextWithEllipsis>
                    )}
                    {showProductAsin && !!impactedProduct.productAsin && (
                      <TextWithEllipsis>
                        <StrikeableSpan>
                          {productUrl ? (
                            <a
                              href={productUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {impactedProduct.productAsin}
                            </a>
                          ) : (
                            impactedProduct.productAsin
                          )}
                        </StrikeableSpan>
                      </TextWithEllipsis>
                    )}
                    {showBrandMembership &&
                      impactedProduct.brandMembership ===
                        Retailer.BrandMembership.Option.BRAND_PROMOTED && (
                        <StrikeableSpan>
                          <strong>PROMOTED</strong>
                        </StrikeableSpan>
                      )}
                    {showBrandMembership &&
                      impactedProduct.brandMembership ===
                        Retailer.BrandMembership.Option.BRAND_INFERRED && (
                        <StrikeableSpan>
                          <em>inferred</em>
                        </StrikeableSpan>
                      )}
                  </div>
                </SimpleTooltip>
              </DataTableRowCell>
            );
          }
          case COLUMN_DATA_KEYS.dailyBudget: {
            const showProductGroup = showInImpactedProductsTable(
              impactedProductsTableFlags,
              SHOW_PRODUCT_GROUP_IN_TABLE
            );
            const showProductCategory = showInImpactedProductsTable(
              impactedProductsTableFlags,
              SHOW_PRODUCT_CATEGORY_IN_TABLE
            );
            const showProductSubcategory = showInImpactedProductsTable(
              impactedProductsTableFlags,
              SHOW_PRODUCT_SUBCATEGORY_IN_TABLE
            );

            return (
              <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                <SimpleTooltip
                  tooltip={
                    <ProductCategoryTooltip impactedProduct={impactedProduct} />
                  }
                >
                  <div>
                    {showProductGroup && !!impactedProduct.productGroup && (
                      <TextWithEllipsis>
                        <StrikeableSpan>
                          {impactedProduct.productGroup}
                        </StrikeableSpan>
                      </TextWithEllipsis>
                    )}
                    {showProductCategory && !!impactedProduct.productCategory && (
                      <TextWithEllipsis>
                        <StrikeableSpan>
                          {impactedProduct.productCategory}
                        </StrikeableSpan>
                      </TextWithEllipsis>
                    )}
                    {showProductSubcategory &&
                      !!impactedProduct.productSubcategory && (
                        <TextWithEllipsis>
                          <StrikeableSpan>
                            {impactedProduct.productSubcategory}
                          </StrikeableSpan>
                        </TextWithEllipsis>
                      )}
                  </div>
                </SimpleTooltip>
              </DataTableRowCell>
            );
          }

          case COLUMN_DATA_KEYS.clicks:
          case COLUMN_DATA_KEYS.detailPageViews:
          case COLUMN_DATA_KEYS.carts:
          case COLUMN_DATA_KEYS.conversions:
          case COLUMN_DATA_KEYS.revenue:
          case COLUMN_DATA_KEYS.unitsSold:
          case COLUMN_DATA_KEYS.newToBrandConversionsPercentage:
          case COLUMN_DATA_KEYS.newToBrandRevenuePercentage:
          case COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage: {
            const formattedValue = formatMetricColumnValue(
              columnName,
              impactedProduct[columnName],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            let annotationValue;
            if (columnName === COLUMN_DATA_KEYS.newToBrandRevenuePercentage) {
              annotationValue = getMetricColumnAnnotation(
                columnName,
                impactedProduct,
                revenueCurrencyCode,
                showFractions,
                showUnconvertedRevenue
              );
            } else {
              annotationValue = getMetricColumnAnnotation(
                columnName,
                impactedProduct,
                impactedProduct[
                  COLUMN_DATA_KEYS.unconvertedRevenueCurrencyCode
                ],
                showFractions,
                showUnconvertedRevenue
              );
            }

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              columnName,
              impactedProduct,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                <StrikeableSpan>
                  {renderFormattedValue(
                    formattedValue,
                    annotationValue,
                    compareInfo
                  )}
                </StrikeableSpan>
              </DataTableMetricCell>
            );
          }

          default:
            return <NotApplicableCell key={columnName} rowIndex={rowIndex} />;
        }
      })}
    </StrikeableDataTableRow>
  );
};

const TooltipTitleWithButton = ({ title, flag, flags, setFlags }) => (
  <TooltipTitle>
    <TooltipLabel>{title}</TooltipLabel>
    {!!setFlags && (
      <CheckboxTightLabel
        label="In table"
        checked={(flags & flag) !== 0}
        onClick={e => {
          e.stopPropagation();
          setFlags(flags => flags ^ flag);
        }}
      />
    )}
  </TooltipTitle>
);

export const ProductHeaderTooltip = ({ flags, setFlags }) => (
  <div>
    <TooltipTitleWithButton
      title="Brand:"
      flag={SHOW_BRAND_NAME_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
    <hr />
    <TooltipTitleWithButton
      title="Parent Product:"
      flag={SHOW_PARENT_ASIN_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
    <hr />
    <TooltipTitleWithButton
      title="Product ID:"
      flag={SHOW_PRODUCT_ASIN_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
    <hr />
    <TooltipTitleWithButton
      title="Promoted or Halo:"
      flag={SHOW_BRAND_MEMBERSHIP_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
  </div>
);

export const ProductTooltip = ({ impactedProduct }) => (
  <div>
    <TooltipLabel>Brand:</TooltipLabel>
    {impactedProduct.brandName}
    <hr />
    <TooltipLabel>Parent ASIN:</TooltipLabel>
    {impactedProduct.parentAsin}
    <hr />
    <TooltipLabel>ASIN:</TooltipLabel>
    {impactedProduct.productAsin}
    <hr />
    <TooltipLabel>Promoted or Halo:</TooltipLabel>
    {stringForEnum(
      Retailer.BrandMembership.Option,
      impactedProduct.brandMembership
    )}
  </div>
);

export const ProductCategoryHeaderTooltip = ({ flags, setFlags }) => (
  <div>
    <TooltipTitleWithButton
      title="Group:"
      flag={SHOW_PRODUCT_GROUP_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
    <hr />
    <TooltipTitleWithButton
      title="Category:"
      flag={SHOW_PRODUCT_CATEGORY_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
    <hr />
    <TooltipTitleWithButton
      title="Subcategory:"
      flag={SHOW_PRODUCT_SUBCATEGORY_IN_TABLE}
      flags={flags}
      setFlags={setFlags}
    />
  </div>
);

export const ProductCategoryTooltip = ({ impactedProduct }) => (
  <div>
    <TooltipLabel>Group:</TooltipLabel>
    {impactedProduct.productGroup}
    <hr />
    <TooltipLabel>Category:</TooltipLabel>
    {impactedProduct.productCategory}
    <hr />
    <TooltipLabel>Subcategory:</TooltipLabel>
    {impactedProduct.productSubcategory}
  </div>
);

export const showInImpactedProductsTable = (
  impactedProductsTableFlags,
  flag
) => {
  return (impactedProductsTableFlags & flag) !== 0;
};

// Returns a truncated list of impacted products after sorting them with the specified
// sort column and direction.
export const getSortedSampleOfImpactedProducts = (
  impactedProductRows,
  sortColumn,
  sortIsAscending,
  maxCount,
  impactedProductsTableFlags
) => {
  if (!IMPACTED_PRODUCT_COLUMNS.includes(sortColumn)) {
    sortColumn = COLUMN_DATA_KEYS.status;
    sortIsAscending = true;
  }

  // Since we are sorting the impacted products within the campaigns table, sort by
  // product name if the sort column is campaign name.
  const sortedKeywordRows = impactedProductRows.sort((a, b) => {
    let compare = defaultSortComparator(a, b, sortColumn, sortIsAscending);

    // Let's do a special sort for the parent ASIN (displayed in the campaign start
    // date column).
    if (sortColumn === COLUMN_DATA_KEYS.status) {
      let aValue = "";
      let bValue = "";
      if (
        showInImpactedProductsTable(
          impactedProductsTableFlags,
          SHOW_BRAND_MEMBERSHIP_IN_TABLE
        )
      ) {
        aValue += `${a.brandMembership}|`;
        bValue += `${b.brandMembership}|`;
      }
      if (
        showInImpactedProductsTable(
          impactedProductsTableFlags,
          SHOW_BRAND_NAME_IN_TABLE
        )
      ) {
        aValue += `${a.brandName}|`;
        bValue += `${b.brandName}|`;
      }
      if (
        showInImpactedProductsTable(
          impactedProductsTableFlags,
          SHOW_PARENT_ASIN_IN_TABLE
        )
      ) {
        aValue += `${a.parentAsin}|`;
        bValue += `${b.parentAsin}|`;
      }

      // Always add the product ASIN to keep order consistent
      aValue += `${a.productAsin}|`;
      bValue += `${b.productAsin}|`;

      compare = defaultValueSortComparator(aValue, bValue, sortIsAscending);
    } else if (sortColumn === COLUMN_DATA_KEYS.dailyBudget) {
      let aValue = "";
      let bValue = "";
      if (
        showInImpactedProductsTable(
          impactedProductsTableFlags,
          SHOW_PRODUCT_GROUP_IN_TABLE
        )
      ) {
        aValue += `${a.productGroup}|`;
        bValue += `${b.productGroup}|`;
      }
      if (
        showInImpactedProductsTable(
          impactedProductsTableFlags,
          SHOW_PRODUCT_CATEGORY_IN_TABLE
        )
      ) {
        aValue += `${a.productCategory}|`;
        bValue += `${b.productCategory}|`;
      }
      if (
        showInImpactedProductsTable(
          impactedProductsTableFlags,
          SHOW_PRODUCT_SUBCATEGORY_IN_TABLE
        )
      ) {
        aValue += `${a.productSubcategory}|`;
        bValue += `${b.productSubcategory}|`;
      }

      compare = defaultValueSortComparator(aValue, bValue, sortIsAscending);
    }

    // If the sort column values still compare equal, further sort by
    // conversions and product name (which is shown in the campaign name column).
    if (sortColumn !== COLUMN_DATA_KEYS.campaignName && compare === 0) {
      // Do a secondary,  descending sort on conversions.
      if (sortColumn !== COLUMN_DATA_KEYS.conversions) {
        compare = defaultSortComparator(
          a,
          b,
          COLUMN_DATA_KEYS.conversions,
          false
        );
      }

      if (compare === 0) {
        compare = defaultSortComparator(
          a,
          b,
          COLUMN_DATA_KEYS.campaignName,
          true
        );
      }
    }

    return compare;
  });

  return sortedKeywordRows.slice(0, maxCount);
};

export default CampaignsTableImpactedProductRow;
