import { Set as ImmutableSet, Map as ImmutableMap } from "immutable";
import React, { useState } from "react";
import {
  Message,
  Icon,
  Button,
  Table,
  Modal,
  Divider,
  Checkbox,
  Accordion,
  AccordionTitle,
  AccordionContent
} from "semantic-ui-react";

import { AttributionAdvertiser } from "Common/utils/amazon";
import {
  FacebookAd,
  getFacebookMarketingQueryKey
} from "ExtensionV2/queries/useFacebookMarketingResources";
import { AmpdContentAreaModal } from "ExtensionV2/components/layout/AmpdContentAreaModal";
import { AmazonAttributionAdvertiserSelector } from "ExtensionV2/components/AmazonAttributionAdvertiserSelector";
import { None } from "Common/utils/tsUtils";
import { useQueryClient } from "@tanstack/react-query";
import { UpdateFacebookAdAttributionRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Facebook_pb";
import { Retailer } from "Common/proto/common/retailer_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import pLimit from "p-limit";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { extractErrorMessage } from "Common/errors/error";
import { CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS } from "Common/utils/facebook";

// TODO: There is a potential race condition when the updated campaign is saved
// to the database if there is more than one campaign being updated
// concurrently. I'm leaving in the pLimit code with the intention that we will
// want to address this and turn the limit back up. It was previously set to 5
// and was otherwise working fine.
const MAX_CONCURRENT_AD_UPDATES = 1;

async function updateAdAttribution(
  siteAlias: string,
  ad: FacebookAd,
  selectedAttribution: AttributionAdvertiser | None,
  setLoadingAds: React.Dispatch<React.SetStateAction<ImmutableSet<string>>>,
  setErrorAds: React.Dispatch<
    React.SetStateAction<ImmutableMap<string, string>>
  >,
  setSuccessfulAds: React.Dispatch<React.SetStateAction<ImmutableSet<string>>>
): Promise<Error | None> {
  try {
    const req = new UpdateFacebookAdAttributionRequest();
    req.setSiteAlias(siteAlias);
    req.setRetailer(ad.retailer);

    req.setAdId(ad.id);
    req.setAdSetId(ad.adSetId);
    req.setCampaignId(ad.campaignId);
    req.setAdAccountId(ad.accountId);

    if (ad.retailer === Retailer.Option.AMAZON) {
      if (
        !selectedAttribution?.advertiserIdStr ||
        !selectedAttribution?.profileIdStr
      ) {
        setErrorAds(errors =>
          errors.set(ad.id, "No Amazon Attribution profile selected")
        );
        return new Error("No Amazon Attribution profile selected");
      }

      req.setAmazonAttributionAdvertiserId(
        selectedAttribution?.advertiserIdStr || ""
      );
      req.setAmazonAttributionProfileId(
        selectedAttribution?.profileIdStr || ""
      );
    }

    const resp = await GRPCWebClient.updateFacebookAdAttribution(req, {});

    if (resp == null) {
      setErrorAds(errors => errors.set(ad.id, "Unknown error"));
      return new Error("Unknown error");
    } else {
      setSuccessfulAds(successfulAds => successfulAds.add(ad.id));
      return null;
    }
  } catch (e) {
    console.error(`Error updating ad ${ad.id}`, e);

    const errorMessage = extractErrorMessage(e);
    setErrorAds(errors => errors.set(ad.id, errorMessage));
    return new Error(errorMessage);
  } finally {
    setLoadingAds(loadingAds => loadingAds.delete(ad.id));
  }
}

function EnableAttributionConfirmationModal({
  initiallySelectedAds,
  modalOpen,
  onClose
}: {
  initiallySelectedAds: Array<FacebookAd>;
  modalOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const site = useSessionSite();

  const queryClient = useQueryClient();

  const [selectedAttribution, setSelectedAttribution] = useState<
    AttributionAdvertiser | None
  >(null);

  const [selectedAdIds, setSelectedAdIds] = useState(
    initiallySelectedAds.reduce(
      (acc, ad) => acc.set(ad.id, ad),
      ImmutableMap<string, FacebookAd>()
    )
  );

  const [loadingAdIds, setLoadingAdIds] = useState<ImmutableSet<string>>(
    ImmutableSet()
  );
  const [errorMessages, setErrorMessages] = useState<
    ImmutableMap<
      string, //adID
      string // message
    >
  >(ImmutableMap());

  const [successfulAdIds, setSuccessfulAdIds] = useState<ImmutableSet<string>>(
    ImmutableSet()
  );

  const [moreInfoExpanded, setMoreInfoExpanded] = useState(false);

  const needsAmazonAttribution = selectedAdIds.some(ad => {
    return ad.retailer === Retailer.Option.AMAZON;
  });

  const handleSubmit = async () => {
    if (!selectedAttribution && needsAmazonAttribution) {
      return;
    }

    setLoadingAdIds(ImmutableSet([...selectedAdIds.keys()]));
    setErrorMessages(ImmutableMap());

    const limit = pLimit(MAX_CONCURRENT_AD_UPDATES);
    const queries: Array<Promise<Error | None>> = [];

    for (const [, ad] of selectedAdIds) {
      queries.push(
        limit(() =>
          updateAdAttribution(
            site.siteAlias,
            ad,
            selectedAttribution,
            setLoadingAdIds,
            setErrorMessages,
            setSuccessfulAdIds
          )
        )
      );
    }

    await Promise.all(queries).then(responseErrors => {
      const queryKey = getFacebookMarketingQueryKey(
        site.siteAlias,
        CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS
      );
      queryClient.invalidateQueries(queryKey);
      // There are async issues with checking the errorMessages state value, so check
      // the promise return values directly for errors
      const hasErrorsToReview = responseErrors.some(e => !!e);
      if (!hasErrorsToReview) {
        setTimeout(() => {
          // Let the user see all the green checks before closing the modal
          onClose();
        }, 1_000);
      }
    });
  };

  return (
    <AmpdContentAreaModal open={modalOpen} onClose={onClose}>
      <Modal.Header>Enable Ampd Attribution</Modal.Header>

      <Modal.Content scrolling>
        {needsAmazonAttribution && (
          <>
            <p
              style={{ color: selectedAttribution == null ? "red" : undefined }}
            >
              Please select an Amazon Attribution profile
            </p>
            <AmazonAttributionAdvertiserSelector
              onChange={attributionAdvertiser =>
                setSelectedAttribution(attributionAdvertiser)
              }
            />
          </>
        )}

        <Divider horizontal />

        <div>
          <p>
            You are about to enable Ampd Attribution for the following ads. This
            will allow you to track conversions from these ads to your Amazon
            store.
          </p>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Ad Name</Table.HeaderCell>
                <Table.HeaderCell>Ad ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {initiallySelectedAds.map(ad => {
                let statusCell = <></>;
                if (successfulAdIds.has(ad.id)) {
                  statusCell = <Icon color="green" name="check circle" />;
                } else if (loadingAdIds.has(ad.id)) {
                  statusCell = <Icon loading name="spinner" />;
                } else {
                  statusCell = (
                    <Checkbox
                      checked={selectedAdIds.has(ad.id)}
                      onChange={(_ev, { checked }) => {
                        if (checked) {
                          setSelectedAdIds(ads => ads.set(ad.id, ad));
                        } else {
                          setSelectedAdIds(ads => ads.remove(ad.id));
                        }
                      }}
                    />
                  );
                }

                return (
                  <Table.Row key={ad.id}>
                    <Table.Cell>{statusCell}</Table.Cell>
                    <Table.Cell>
                      <p>{ad.name}</p>
                      {errorMessages.has(ad.id) && (
                        <AttributionErrorMessage
                          errorMessage={errorMessages.get(ad.id)}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>{ad.id}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <Accordion>
            <AccordionTitle
              active={moreInfoExpanded}
              onClick={() => {
                setMoreInfoExpanded(expanded => !expanded);
              }}
            >
              <Icon name="dropdown" />
              Meta excludes 3rd parties from accessing some of their latest
              features. (Learn More)
            </AccordionTitle>
            <AccordionContent
              active={moreInfoExpanded}
              style={{ overflow: "auto" }}
            >
              <p>
                This means that some settings that are available for your ad in
                the Meta Ads Manager cannot be accessed by Ampd, and may turn
                off when you turn on attribution tracking for your ad. If you
                are using these features in your Meta ads, you may need to
                reapply them using the Meta Ads Manager after clicking “submit”
                on this page.{" "}
              </p>
            </AccordionContent>
          </Accordion>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          disabled={
            (needsAmazonAttribution && selectedAttribution == null) ||
            loadingAdIds.size > 0 ||
            selectedAdIds.size === 0
          }
          loading={loadingAdIds.size > 0}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Modal.Actions>
    </AmpdContentAreaModal>
  );
}

const DEFAULT_ERROR_MESSAGE = "An unknown error occurred";

function AttributionErrorMessage({
  errorMessage
}: {
  errorMessage: string | None;
}): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  return (
    <Message error style={{ maxWidth: "36em" }}>
      <p>Unable to set up attribution</p>

      <Accordion>
        <AccordionTitle
          active={expanded}
          onClick={() => {
            setExpanded(expanded => !expanded);
          }}
        >
          <Icon name="dropdown" />
          More Info
        </AccordionTitle>
        <AccordionContent active={expanded} style={{ overflow: "auto" }}>
          <p>We are unable to set up attribution for this ad.</p>
          <p>Error reason:</p>
          {errorMessage || DEFAULT_ERROR_MESSAGE}
        </AccordionContent>
      </Accordion>
    </Message>
  );
}

export function EnableAttributionConfirmationModalLauncher({
  selectedAds,
  disabled
}: {
  selectedAds: Array<FacebookAd>;
  disabled: boolean;
}): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const showModal = selectedAds.length > 0 && modalOpen;

  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        primary
        disabled={selectedAds.length === 0 || disabled}
      >
        Enable Ampd Attribution{" "}
        {selectedAds.length > 0 && `(${selectedAds.length})`}
      </Button>

      {showModal && (
        <EnableAttributionConfirmationModal
          initiallySelectedAds={selectedAds}
          modalOpen={showModal}
          onClose={handleClose}
        />
      )}
    </>
  );
}
