import { FormField } from "./CampaignSetupPageState";
import { extractWalmartURLInfoFromString } from "Common/utils/walmart";

const walmartSearchPageValidator = (searchPage: string): Set<string> => {
  const errors = new Set<string>();
  if (!searchPage) {
    return errors;
  }

  const urlInfo = extractWalmartURLInfoFromString(searchPage);
  if (!urlInfo.effectiveURL) {
    errors.add(
      "Please enter either a search phrase or a Walmart search page URL"
    );
  }

  return errors;
};

export const walmartSearchPageField = (
  initialValue: string
): FormField<string> => ({
  _value: initialValue,
  errors: walmartSearchPageValidator(initialValue),
  validator: walmartSearchPageValidator
});
