import _ from "lodash";
import Immutable from "immutable";

import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  Form,
  Icon,
  Input,
  Message,
  Table,
  TextArea
} from "semantic-ui-react";
import { Flex, Box } from "@rebass/grid";

import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { sendGAEvent } from "./GA";
import {
  UNSPECIFIED_ATTRIBUTION_BEHAVIOR,
  AMPD_ATTRIBUTION_BEHAVIOR,
  CUSTOM_ATTRIBUTION_BEHAVIOR,
  NO_ATTRIBUTION_BEHAVIOR,
  getRelevantAttributionAdvertisers,
  findAttributionAdvertiser
} from "Common/utils/amazon";

import { Amazon } from "Common/proto/common/amazon_pb";
import useLoginWithAmazon, {
  getAmazonRegionCode
} from "../../Common/hooks/useLoginWithAmazon";
import LinkAmazonAdvertising from "./LinkAmazonAdvertising";
import { AmazonAttributionAdvertiserSelector } from "./AmazonAttributionAdvertiserSelector";

function AmazonAttributionBehaviorSelector(props) {
  const {
    gaCategory,
    marketplaceInfo,
    amazonAdvertisingAccounts,
    amazonAdvertisingProfiles,
    amazonAdvertisingProfilesLoaded,
    amazonAdvertisingProfilesLoading,
    attributionAdvertisers,
    setAttributionAdvertisers,
    attributionBehavior,
    setAttributionBehavior,
    attributionAdvertiserId,
    setAttributionAdvertiserId,
    attributionCustomTags,
    setAttributionCustomTags
  } = props;

  // Note: We can't use the amazon global until the LWA SDK is installed.
  const amazonReady = useLoginWithAmazon();
  const regionToLWARegionMap = amazonReady && {
    [Amazon.Region.Option.NORTH_AMERICA]: getAmazonRegionCode(
      window.amazon?.Login?.Region?.NorthAmerica
    ),
    [Amazon.Region.Option.EUROPE]: getAmazonRegionCode(
      window.amazon?.Login?.Region?.Europe
    ),
    [Amazon.Region.Option.FAR_EAST]: getAmazonRegionCode(
      window.amazon?.Login?.Region?.AsiaPacific
    )
  };

  const [profilesForMarketplace, setProfilesForMarketplace] = useState([]);

  useEffect(() => {
    if (amazonAdvertisingProfilesLoaded && !amazonAdvertisingProfilesLoading) {
      const marketplace = _.get(
        marketplaceInfo,
        "marketplace",
        Amazon.Marketplace.Option.UNKNOWN
      );

      const attributionAdvertisers = getRelevantAttributionAdvertisers(
        amazonAdvertisingProfiles,
        marketplace,
        false
      );

      setAttributionAdvertisers(attributionAdvertisers);
      setProfilesForMarketplace(
        _.filter(
          amazonAdvertisingProfiles,
          profile => profile.marketplace === marketplace
        )
      );
    }
  }, [
    amazonAdvertisingProfilesLoaded,
    amazonAdvertisingProfilesLoading,
    amazonAdvertisingProfiles,
    marketplaceInfo,
    setAttributionAdvertisers
  ]);

  useEffect(() => {
    if (
      attributionAdvertisers !== null &&
      attributionBehavior === UNSPECIFIED_ATTRIBUTION_BEHAVIOR
    ) {
      if (_.isEmpty(attributionAdvertisers)) {
        setAttributionBehavior(NO_ATTRIBUTION_BEHAVIOR);
      } else {
        setAttributionBehavior(AMPD_ATTRIBUTION_BEHAVIOR);
      }
    }
  }, [attributionAdvertisers, attributionBehavior, setAttributionBehavior]);

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false);
    }
  }, [attributionAdvertisers]); // eslint-disable-line react-hooks/exhaustive-deps

  const [customTagEditRow, setCustomTagEditRow] = useState(-1);
  const [customTagEditColumn, setCustomTagEditColumn] = useState(-1);
  const [customTagEditText, setCustomTagEditText] = useState("");

  const [customParams, setCustomParams] = useState("");

  useEffect(() => {
    if (!customParams || customTagEditRow >= 0) {
      const urlParams = new URLSearchParams();
      attributionCustomTags.forEach(([tag, value]) => {
        if (tag) {
          urlParams.set(tag, value);
        }
      });

      setCustomParams(`?${urlParams.toString()}`);
    }
  }, [customParams, attributionCustomTags, customTagEditRow]);

  const handleRadioClicked = behavior => () => {
    setAttributionBehavior(behavior);
  };

  const handleAdvertiserChange = attributionAdvertiserId => {
    sendGAEvent(gaCategory, "Advertiser Change");

    setAttributionAdvertiserId(attributionAdvertiserId);
  };

  const handleTableCellClick = (row, column) => e => {
    e.stopPropagation();

    if (row >= attributionCustomTags.size) {
      setAttributionCustomTags(attributionCustomTags.push(["", ""]));
      column = 0;
    }

    setCustomTagEditRow(row);
    setCustomTagEditColumn(column);

    const pair = attributionCustomTags.get(row, ["", ""]);
    setCustomTagEditText(pair[column]);
  };

  const smartSplitCustomTag = value => {
    let [paramName, paramValue] = attributionCustomTags.get(customTagEditRow, [
      "",
      ""
    ]);
    if (customTagEditColumn === 0) {
      if (value.includes("=")) {
        const parts = value.split("=");
        paramName = parts[0];
        paramValue = parts.slice(1).join("=");
      } else {
        paramName = value;
      }
    } else {
      paramValue = value;
    }

    return [paramName, paramValue];
  };

  const handleTableCellTextChange = (e, { value }) => {
    const [paramName, paramValue] = smartSplitCustomTag(value);
    setAttributionCustomTags(
      attributionCustomTags.set(customTagEditRow, [paramName, paramValue])
    );
    setCustomTagEditText(value);
  };

  const handleTableCellTextKeyPress = e => {
    if (e.keyCode === 13 || e.which === 13) {
      handleTableCellTextBlur();
    }
  };

  const handleTableCellTextBlur = () => {
    const [paramName, paramValue] = smartSplitCustomTag(customTagEditText);

    if (!paramName) {
      setAttributionCustomTags(attributionCustomTags.remove(customTagEditRow));
    } else {
      setAttributionCustomTags(
        attributionCustomTags.set(customTagEditRow, [paramName, paramValue])
      );
    }

    setCustomTagEditRow(-1);
    setCustomTagEditColumn(-1);
    setCustomTagEditText("");
  };

  const handleCustomParamsChange = (e, { value }) => {
    try {
      const url = new URL(value);

      value = `?${url.searchParams.toString()}`;
    } catch (err) {
      console.error(err);
    }

    setCustomParams(value);
    try {
      const urlParams = new URLSearchParams(value);
      const tags = Immutable.List(urlParams.entries());

      setAttributionCustomTags(tags);
    } catch {
      return;
    }
  };

  let attributionAdvertiser = findAttributionAdvertiser(
    attributionAdvertisers,
    attributionAdvertiserId
  );

  if (
    !attributionAdvertiser &&
    attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR
  ) {
    attributionAdvertiser = attributionAdvertisers[0];
  }

  return attributionBehavior === UNSPECIFIED_ATTRIBUTION_BEHAVIOR ? (
    <LoadingSpinner size="small">
      Loading Amazon Attribution Profiles
    </LoadingSpinner>
  ) : (
    <Form style={{ minHeight: 300 }} warning>
      <Form.Field disabled={_.isEmpty(attributionAdvertisers)}>
        <strong>
          <Checkbox
            radio
            label={
              _.isEmpty(attributionAdvertisers)
                ? "Ampd Amazon Attribution (Unavailable)"
                : "Enable Ampd Amazon Attribution (Recommended option)"
            }
            checked={attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR}
            onClick={handleRadioClicked(AMPD_ATTRIBUTION_BEHAVIOR)}
          />
        </strong>
        <div
          style={{
            marginTop: 10,
            marginLeft: 30,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <p>Ampd will set up Amazon Attribution automatically.</p>
          {attributionBehavior === AMPD_ATTRIBUTION_BEHAVIOR && (
            <>
              <p style={{ marginBottom: 0 }}>
                Select the Advertiser account for attribution:
              </p>

              <i>
                Accounts labeled “★ Seller” may be eligible for Brand Referral
                Bonus.
              </i>
              <div style={{ marginTop: 5, marginRight: 10 }}>
                <AmazonAttributionAdvertiserSelector
                  onChange={attributionAdvertiser =>
                    handleAdvertiserChange(
                      attributionAdvertiser?.advertiserIdStr
                    )
                  }
                  initialSelectedAttributionAdvertiser={attributionAdvertiser}
                  marketplace={marketplaceInfo.marketplace}
                />
              </div>
            </>
          )}
        </div>
      </Form.Field>
      {_.isEmpty(amazonAdvertisingAccounts) &&
        (amazonAdvertisingProfilesLoading ? (
          <LoadingSpinner size="small" />
        ) : (
          <Form.Field style={{ marginLeft: 30 }}>
            <Message warning>
              <Flex flexDirection="row" justifyContent="space-between">
                <Box>
                  You are not connected to an Amazon Advertising account.
                </Box>
                {!!regionToLWARegionMap && marketplaceInfo && (
                  <LinkAmazonAdvertising
                    region={regionToLWARegionMap[marketplaceInfo.region]}
                  />
                )}
              </Flex>
            </Message>
          </Form.Field>
        ))}
      {!_.isEmpty(amazonAdvertisingAccounts) &&
        amazonAdvertisingProfilesLoaded &&
        !amazonAdvertisingProfilesLoading &&
        _.isEmpty(attributionAdvertisers) &&
        marketplaceInfo &&
        marketplaceInfo.sellerCentralDomain && (
          <Form.Field style={{ marginLeft: 30 }}>
            <Message warning>
              <Icon name="arrow circle right" color="yellow" />{" "}
              {_.isEmpty(profilesForMarketplace)
                ? "You do not have an Amazon Branded Seller profile "
                : "Amazon Attribution is not available "}
              for the {marketplaceInfo.name} marketplace (
              {marketplaceInfo.domain})
            </Message>
          </Form.Field>
        )}
      <Divider horizontal>Or</Divider>
      {attributionBehavior === CUSTOM_ATTRIBUTION_BEHAVIOR && (
        <>
          <Form.Field>
            <strong>
              <Checkbox
                radio
                label="Use custom, manual Amazon Attribution (Advanced option)"
                checked={attributionBehavior === CUSTOM_ATTRIBUTION_BEHAVIOR}
                onClick={handleRadioClicked(CUSTOM_ATTRIBUTION_BEHAVIOR)}
              />
            </strong>
            <div
              style={{
                marginTop: 10,
                marginLeft: 30,
                paddingLeft: 0,
                paddingRight: 0
              }}
            >
              <p>
                Manually apply your own Amazon Attribution parameters for the
                final product URL. Either paste your Click-through URL from
                Amazon below or enter the desired extra parameters into the
                table one by one.
              </p>
              {attributionBehavior === CUSTOM_ATTRIBUTION_BEHAVIOR && (
                <>
                  <TextArea
                    style={{ width: "100%", wordBreak: "break-all" }}
                    placeholder="For example: https://www.amazon.com/dp/XXXXXXXXXX"
                    rows="6"
                    value={customParams || ""}
                    onClick={() =>
                      sendGAEvent(gaCategory, "Click Custom Attribution Input")
                    }
                    onChange={handleCustomParamsChange}
                  />
                  <Table size="small" style={{ width: "100%" }} compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Parameter</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {attributionCustomTags
                        .toSeq()
                        .map(([tag, value], row) => (
                          <Table.Row key={row}>
                            {customTagEditRow === row &&
                            customTagEditColumn === 0 ? (
                              <Table.Cell>
                                <Input
                                  style={{ width: 200 }}
                                  value={customTagEditText}
                                  onChange={handleTableCellTextChange}
                                  onKeyPress={handleTableCellTextKeyPress}
                                  onBlur={handleTableCellTextBlur}
                                  autoFocus
                                />
                              </Table.Cell>
                            ) : (
                              <Table.Cell
                                style={{ wordBreak: "break-word" }}
                                onClick={handleTableCellClick(row, 0)}
                              >
                                <div style={{ width: 150 }}>{tag}</div>
                              </Table.Cell>
                            )}
                            {customTagEditRow === row &&
                            customTagEditColumn === 1 ? (
                              <Table.Cell>
                                <Input
                                  fluid
                                  value={customTagEditText}
                                  onChange={handleTableCellTextChange}
                                  onKeyPress={handleTableCellTextKeyPress}
                                  onBlur={handleTableCellTextBlur}
                                  autoFocus
                                />
                              </Table.Cell>
                            ) : (
                              <Table.Cell
                                style={{ wordBreak: "break-word" }}
                                onClick={handleTableCellClick(row, 1)}
                              >
                                {value}
                              </Table.Cell>
                            )}
                          </Table.Row>
                        ))}
                      <Table.Row>
                        <Table.Cell
                          onClick={handleTableCellClick(
                            attributionCustomTags.size,
                            0
                          )}
                        >
                          <div style={{ width: 150 }} />
                        </Table.Cell>
                        <Table.Cell
                          onClick={handleTableCellClick(
                            attributionCustomTags.size,
                            1
                          )}
                        >
                          {"<add another parameter>"}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Message warning>
                    You will not be able to see your sales from this ad in the
                    Ampd dashboard. Sales data will only be available in{" "}
                    <a
                      href="https://advertising.amazon.com/solutions/products/amazon-attribution"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Amazon Attribution
                    </a>
                    .
                  </Message>
                </>
              )}
            </div>
          </Form.Field>
          <Divider horizontal>Or</Divider>
        </>
      )}
      <Form.Field>
        <strong>
          <Checkbox
            radio
            label="Do not use Amazon Attribution for this ad"
            checked={attributionBehavior === NO_ATTRIBUTION_BEHAVIOR}
            onClick={handleRadioClicked(NO_ATTRIBUTION_BEHAVIOR)}
          />
        </strong>
        <div
          style={{
            marginTop: 10,
            marginLeft: 30,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <p>You will not be able to track sales directly from this ad.</p>
          {attributionBehavior === NO_ATTRIBUTION_BEHAVIOR && (
            <Message warning>
              You will not be able to see your sales from this ad in the Ampd
              dashboard or have them attributed to this ad in{" "}
              <a
                href="https://advertising.amazon.com/solutions/products/amazon-attribution"
                target="_blank"
                rel="noopener noreferrer"
              >
                Amazon Attribution
              </a>
              .
            </Message>
          )}
        </div>
      </Form.Field>
    </Form>
  );
}

export default AmazonAttributionBehaviorSelector;
