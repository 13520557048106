// source: proto/edge/grpcwebPb/grpcweb_GoogleAds.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_edge_grpcwebPb_grpcweb_DashboardSession_pb = require('../../../proto/edge/grpcwebPb/grpcweb_DashboardSession_pb.js');
goog.object.extend(proto, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb);
var proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb = require('../../../proto/edge/grpcwebPb/grpcweb_PerformanceMetrics_pb.js');
goog.object.extend(proto, proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb);
var google_ads_googleads_v16_common_ad_type_infos_pb = require('../../../google/ads/googleads/v16/common/ad_type_infos_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_ad_type_infos_pb);
var google_ads_googleads_v16_common_criteria_pb = require('../../../google/ads/googleads/v16/common/criteria_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_criteria_pb);
var google_ads_googleads_v16_resources_campaign_pb = require('../../../google/ads/googleads/v16/resources/campaign_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_pb);
var google_ads_googleads_v16_resources_campaign_budget_pb = require('../../../google/ads/googleads/v16/resources/campaign_budget_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_budget_pb);
var google_ads_googleads_v16_resources_campaign_criterion_pb = require('../../../google/ads/googleads/v16/resources/campaign_criterion_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_campaign_criterion_pb);
var google_ads_googleads_v16_resources_ad_group_pb = require('../../../google/ads/googleads/v16/resources/ad_group_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_pb);
var google_ads_googleads_v16_resources_ad_group_ad_pb = require('../../../google/ads/googleads/v16/resources/ad_group_ad_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_resources_ad_group_ad_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var proto_common_dataSourceInfo_pb = require('../../../proto/common/dataSourceInfo_pb.js');
goog.object.extend(proto, proto_common_dataSourceInfo_pb);
var proto_common_datetime_pb = require('../../../proto/common/datetime_pb.js');
goog.object.extend(proto, proto_common_datetime_pb);
var proto_common_objectID_pb = require('../../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
var proto_common_productDetails_pb = require('../../../proto/common/productDetails_pb.js');
goog.object.extend(proto, proto_common_productDetails_pb);
var proto_entity_action_pb = require('../../../proto/entity/action_pb.js');
goog.object.extend(proto, proto_entity_action_pb);
var proto_googleAdsPb_ad_pb = require('../../../proto/googleAdsPb/ad_pb.js');
goog.object.extend(proto, proto_googleAdsPb_ad_pb);
var proto_googleAdsPb_adWithAmazonAttribution_pb = require('../../../proto/googleAdsPb/adWithAmazonAttribution_pb.js');
goog.object.extend(proto, proto_googleAdsPb_adWithAmazonAttribution_pb);
var proto_warehousePb_googleAds_pb = require('../../../proto/warehousePb/googleAds_pb.js');
goog.object.extend(proto, proto_warehousePb_googleAds_pb);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsAccountReply', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsAccountRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsCampaignReply', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsCampaignRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign', null, global);
goog.exportSymbol('proto.grpcwebPb.CreateGoogleAdsCampaignRequest.TypeCase', null, global);
goog.exportSymbol('proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept', null, global);
goog.exportSymbol('proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea', null, global);
goog.exportSymbol('proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.Option', null, global);
goog.exportSymbol('proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetExecutedGoogleAdsActionsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents', null, global);
goog.exportSymbol('proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.GoogleAdsAuditReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GoogleAdsAuditRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.QueryGoogleAdsCampaignReply', null, global);
goog.exportSymbol('proto.grpcwebPb.QueryGoogleAdsCampaignRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateGoogleRsaAction', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateGoogleRsaAction.ActionCase', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateGoogleRsaReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateGoogleRsaRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateGoogleAdsAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateGoogleAdsAccountRequest.displayName = 'proto.grpcwebPb.CreateGoogleAdsAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateGoogleAdsAccountReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateGoogleAdsAccountReply.displayName = 'proto.grpcwebPb.CreateGoogleAdsAccountReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.repeatedFields_, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.CreateGoogleAdsCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateGoogleAdsCampaignRequest.displayName = 'proto.grpcwebPb.CreateGoogleAdsCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.displayName = 'proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.displayName = 'proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.CreateGoogleAdsCampaignReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.CreateGoogleAdsCampaignReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.CreateGoogleAdsCampaignReply.displayName = 'proto.grpcwebPb.CreateGoogleAdsCampaignReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.QueryGoogleAdsCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.QueryGoogleAdsCampaignRequest.displayName = 'proto.grpcwebPb.QueryGoogleAdsCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.QueryGoogleAdsCampaignReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.QueryGoogleAdsCampaignReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.QueryGoogleAdsCampaignReply.displayName = 'proto.grpcwebPb.QueryGoogleAdsCampaignReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.displayName = 'proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetExecutedGoogleAdsActionsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.displayName = 'proto.grpcwebPb.GetExecutedGoogleAdsActionsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.displayName = 'proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.displayName = 'proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.displayName = 'proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateGoogleRsaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateGoogleRsaRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateGoogleRsaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateGoogleRsaRequest.displayName = 'proto.grpcwebPb.UpdateGoogleRsaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateGoogleRsaAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpcwebPb.UpdateGoogleRsaAction.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.UpdateGoogleRsaAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateGoogleRsaAction.displayName = 'proto.grpcwebPb.UpdateGoogleRsaAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateGoogleRsaReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateGoogleRsaReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateGoogleRsaReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateGoogleRsaReply.displayName = 'proto.grpcwebPb.UpdateGoogleRsaReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GoogleAdsAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GoogleAdsAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GoogleAdsAuditRequest.displayName = 'proto.grpcwebPb.GoogleAdsAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GoogleAdsAuditReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GoogleAdsAuditReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GoogleAdsAuditReply.displayName = 'proto.grpcwebPb.GoogleAdsAuditReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.displayName = 'proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.displayName = 'proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.displayName = 'proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.displayName = 'proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.displayName = 'proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateGoogleAdsAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    attribution: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateGoogleAdsAccountRequest;
  return proto.grpcwebPb.CreateGoogleAdsAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateGoogleAdsAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAttribution();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_email = 2;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string attribution = 6;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.getAttribution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountRequest.prototype.setAttribution = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateGoogleAdsAccountReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateGoogleAdsAccountReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    adwordsAccount: (f = msg.getAdwordsAccount()) && proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountReply}
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateGoogleAdsAccountReply;
  return proto.grpcwebPb.CreateGoogleAdsAccountReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateGoogleAdsAccountReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountReply}
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.deserializeBinaryFromReader);
      msg.setAdwordsAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateGoogleAdsAccountReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateGoogleAdsAccountReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdwordsAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardSite.DashboardAdwordsAccountInfo adwords_account = 1;
 * @return {?proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo}
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.prototype.getAdwordsAccount = function() {
  return /** @type{?proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto_edge_grpcwebPb_grpcweb_DashboardSession_pb.DashboardSite.DashboardAdwordsAccountInfo, 1));
};


/**
 * @param {?proto.grpcwebPb.DashboardSite.DashboardAdwordsAccountInfo|undefined} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountReply} returns this
*/
proto.grpcwebPb.CreateGoogleAdsAccountReply.prototype.setAdwordsAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CreateGoogleAdsAccountReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.prototype.clearAdwordsAccount = function() {
  return this.setAdwordsAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsAccountReply.prototype.hasAdwordsAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.repeatedFields_ = [6];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.oneofGroups_ = [[7,8]];

/**
 * @enum {number}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  PRODUCT_SEARCH_CAMPAIGN: 7,
  SITE_WIDE_CAMPAIGN: 8
};

/**
 * @return {proto.grpcwebPb.CreateGoogleAdsCampaignRequest.TypeCase}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getTypeCase = function() {
  return /** @type {proto.grpcwebPb.CreateGoogleAdsCampaignRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateGoogleAdsCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    validateOnly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    campaignName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scrubNamesAndAddId: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    budgetMicros: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxCpcMicros: jspb.Message.getFieldWithDefault(msg, 9, 0),
    geotargetsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    allowPartialFailures: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    productSearchCampaign: (f = msg.getProductSearchCampaign()) && proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.toObject(includeInstance, f),
    siteWideCampaign: (f = msg.getSiteWideCampaign()) && proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateGoogleAdsCampaignRequest;
  return proto.grpcwebPb.CreateGoogleAdsCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidateOnly(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScrubNamesAndAddId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBudgetMicros(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxCpcMicros(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGeotargets(values[i]);
      }
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowPartialFailures(value);
      break;
    case 7:
      var value = new proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign;
      reader.readMessage(value,proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.deserializeBinaryFromReader);
      msg.setProductSearchCampaign(value);
      break;
    case 8:
      var value = new proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign;
      reader.readMessage(value,proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.deserializeBinaryFromReader);
      msg.setSiteWideCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateGoogleAdsCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValidateOnly();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCampaignName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScrubNamesAndAddId();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBudgetMicros();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMaxCpcMicros();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getGeotargetsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getAllowPartialFailures();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getProductSearchCampaign();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.serializeBinaryToWriter
    );
  }
  f = message.getSiteWideCampaign();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    finalUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rsaInfo: (f = msg.getRsaInfo()) && google_ads_googleads_v16_common_ad_type_infos_pb.ResponsiveSearchAdInfo.toObject(includeInstance, f),
    keywordsList: jspb.Message.toObjectList(msg.getKeywordsList(),
    google_ads_googleads_v16_common_criteria_pb.KeywordInfo.toObject, includeInstance),
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productDetails: (f = msg.getProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign;
  return proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalUrl(value);
      break;
    case 2:
      var value = new google_ads_googleads_v16_common_ad_type_infos_pb.ResponsiveSearchAdInfo;
      reader.readMessage(value,google_ads_googleads_v16_common_ad_type_infos_pb.ResponsiveSearchAdInfo.deserializeBinaryFromReader);
      msg.setRsaInfo(value);
      break;
    case 3:
      var value = new google_ads_googleads_v16_common_criteria_pb.KeywordInfo;
      reader.readMessage(value,google_ads_googleads_v16_common_criteria_pb.KeywordInfo.deserializeBinaryFromReader);
      msg.addKeywords(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 4:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setProductDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinalUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRsaInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_ads_googleads_v16_common_ad_type_infos_pb.ResponsiveSearchAdInfo.serializeBinaryToWriter
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_ads_googleads_v16_common_criteria_pb.KeywordInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackingUrlTemplate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string final_url = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.getFinalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.setFinalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.common.ResponsiveSearchAdInfo rsa_info = 2;
 * @return {?proto.google.ads.googleads.v16.common.ResponsiveSearchAdInfo}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.getRsaInfo = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.ResponsiveSearchAdInfo} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_ad_type_infos_pb.ResponsiveSearchAdInfo, 2));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.ResponsiveSearchAdInfo|undefined} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
*/
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.setRsaInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.clearRsaInfo = function() {
  return this.setRsaInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.hasRsaInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated google.ads.googleads.v16.common.KeywordInfo keywords = 3;
 * @return {!Array<!proto.google.ads.googleads.v16.common.KeywordInfo>}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.getKeywordsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.KeywordInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_common_criteria_pb.KeywordInfo, 3));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.KeywordInfo>} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
*/
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.setKeywordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.KeywordInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.KeywordInfo}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.addKeywords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.ads.googleads.v16.common.KeywordInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};


/**
 * optional string tracking_url_template = 5;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.ProductDetails product_details = 4;
 * @return {?proto.common.ProductDetails}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.getProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 4));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
*/
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.setProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.clearProductDetails = function() {
  return this.setProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign.prototype.hasProductDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description2: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign;
  return proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description1 = 2;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.getDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.setDescription1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description2 = 3;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.getDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign.prototype.setDescription2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool validate_only = 3;
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getValidateOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setValidateOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string campaign_name = 4;
 * @return {string}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool scrub_names_and_add_id = 11;
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getScrubNamesAndAddId = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setScrubNamesAndAddId = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 budget_micros = 5;
 * @return {number}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getBudgetMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setBudgetMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 max_cpc_micros = 9;
 * @return {number}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getMaxCpcMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setMaxCpcMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated int64 geotargets = 6;
 * @return {!Array<number>}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getGeotargetsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setGeotargetsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.addGeotargets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.clearGeotargetsList = function() {
  return this.setGeotargetsList([]);
};


/**
 * optional bool allow_partial_failures = 10;
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getAllowPartialFailures = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setAllowPartialFailures = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional ProductSearchCampaign product_search_campaign = 7;
 * @return {?proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getProductSearchCampaign = function() {
  return /** @type{?proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign, 7));
};


/**
 * @param {?proto.grpcwebPb.CreateGoogleAdsCampaignRequest.ProductSearchCampaign|undefined} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
*/
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setProductSearchCampaign = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.clearProductSearchCampaign = function() {
  return this.setProductSearchCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.hasProductSearchCampaign = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SiteWideCampaign site_wide_campaign = 8;
 * @return {?proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.getSiteWideCampaign = function() {
  return /** @type{?proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign, 8));
};


/**
 * @param {?proto.grpcwebPb.CreateGoogleAdsCampaignRequest.SiteWideCampaign|undefined} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
*/
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.setSiteWideCampaign = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.grpcwebPb.CreateGoogleAdsCampaignRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.clearSiteWideCampaign = function() {
  return this.setSiteWideCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignRequest.prototype.hasSiteWideCampaign = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.CreateGoogleAdsCampaignReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    adIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    criteriaIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.CreateGoogleAdsCampaignReply;
  return proto.grpcwebPb.CreateGoogleAdsCampaignReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdGroupIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCriteriaIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.CreateGoogleAdsCampaignReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAdGroupIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getAdIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCriteriaIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 ad_group_ids = 2;
 * @return {!Array<number>}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.getAdGroupIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.setAdGroupIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.addAdGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.clearAdGroupIdsList = function() {
  return this.setAdGroupIdsList([]);
};


/**
 * repeated int64 ad_ids = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.getAdIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.setAdIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.addAdIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.clearAdIdsList = function() {
  return this.setAdIdsList([]);
};


/**
 * repeated int64 criteria_ids = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.getCriteriaIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.setCriteriaIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.addCriteriaIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.CreateGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.CreateGoogleAdsCampaignReply.prototype.clearCriteriaIdsList = function() {
  return this.setCriteriaIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.QueryGoogleAdsCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    adGroupId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    adId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.QueryGoogleAdsCampaignRequest;
  return proto.grpcwebPb.QueryGoogleAdsCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.QueryGoogleAdsCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAdId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 campaign_id = 3;
 * @return {number}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 ad_group_id = 4;
 * @return {number}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 ad_id = 5;
 * @return {number}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.getAdId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignRequest} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.QueryGoogleAdsCampaignReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaign: (f = msg.getCampaign()) && google_ads_googleads_v16_resources_campaign_pb.Campaign.toObject(includeInstance, f),
    campaignBudget: (f = msg.getCampaignBudget()) && google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget.toObject(includeInstance, f),
    adGroup: (f = msg.getAdGroup()) && google_ads_googleads_v16_resources_ad_group_pb.AdGroup.toObject(includeInstance, f),
    adGroupAd: (f = msg.getAdGroupAd()) && google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd.toObject(includeInstance, f),
    locationCriteriaList: jspb.Message.toObjectList(msg.getLocationCriteriaList(),
    google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.QueryGoogleAdsCampaignReply;
  return proto.grpcwebPb.QueryGoogleAdsCampaignReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new google_ads_googleads_v16_resources_campaign_pb.Campaign;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_pb.Campaign.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    case 3:
      var value = new google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget.deserializeBinaryFromReader);
      msg.setCampaignBudget(value);
      break;
    case 4:
      var value = new google_ads_googleads_v16_resources_ad_group_pb.AdGroup;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_pb.AdGroup.deserializeBinaryFromReader);
      msg.setAdGroup(value);
      break;
    case 5:
      var value = new google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd;
      reader.readMessage(value,google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd.deserializeBinaryFromReader);
      msg.setAdGroupAd(value);
      break;
    case 6:
      var value = new google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion;
      reader.readMessage(value,google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion.deserializeBinaryFromReader);
      msg.addLocationCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.QueryGoogleAdsCampaignReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_ads_googleads_v16_resources_campaign_pb.Campaign.serializeBinaryToWriter
    );
  }
  f = message.getCampaignBudget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget.serializeBinaryToWriter
    );
  }
  f = message.getAdGroup();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_ads_googleads_v16_resources_ad_group_pb.AdGroup.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupAd();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd.serializeBinaryToWriter
    );
  }
  f = message.getLocationCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.resources.Campaign campaign = 2;
 * @return {?proto.google.ads.googleads.v16.resources.Campaign}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.getCampaign = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.Campaign} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_pb.Campaign, 2));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.Campaign|undefined} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
*/
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.ads.googleads.v16.resources.CampaignBudget campaign_budget = 3;
 * @return {?proto.google.ads.googleads.v16.resources.CampaignBudget}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.getCampaignBudget = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.CampaignBudget} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_campaign_budget_pb.CampaignBudget, 3));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.CampaignBudget|undefined} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
*/
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.setCampaignBudget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.clearCampaignBudget = function() {
  return this.setCampaignBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.hasCampaignBudget = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.ads.googleads.v16.resources.AdGroup ad_group = 4;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroup}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.getAdGroup = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroup} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_pb.AdGroup, 4));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroup|undefined} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
*/
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.setAdGroup = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.clearAdGroup = function() {
  return this.setAdGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.hasAdGroup = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.ads.googleads.v16.resources.AdGroupAd ad_group_ad = 5;
 * @return {?proto.google.ads.googleads.v16.resources.AdGroupAd}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.getAdGroupAd = function() {
  return /** @type{?proto.google.ads.googleads.v16.resources.AdGroupAd} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_resources_ad_group_ad_pb.AdGroupAd, 5));
};


/**
 * @param {?proto.google.ads.googleads.v16.resources.AdGroupAd|undefined} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
*/
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.setAdGroupAd = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.clearAdGroupAd = function() {
  return this.setAdGroupAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.hasAdGroupAd = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated google.ads.googleads.v16.resources.CampaignCriterion location_criteria = 6;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.CampaignCriterion>}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.getLocationCriteriaList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.CampaignCriterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_resources_campaign_criterion_pb.CampaignCriterion, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.CampaignCriterion>} value
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
*/
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.setLocationCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.CampaignCriterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.CampaignCriterion}
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.addLocationCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v16.resources.CampaignCriterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.QueryGoogleAdsCampaignReply} returns this
 */
proto.grpcwebPb.QueryGoogleAdsCampaignReply.prototype.clearLocationCriteriaList = function() {
  return this.setLocationCriteriaList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest;
  return proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addActionTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} returns this
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string action_types = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.getActionTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} returns this
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.setActionTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} returns this
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.addActionTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest} returns this
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsRequest.prototype.clearActionTypesList = function() {
  return this.setActionTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto_entity_action_pb.Action.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetExecutedGoogleAdsActionsReply;
  return proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_action_pb.Action;
      reader.readMessage(value,proto_entity_action_pb.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_entity_action_pb.Action.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.Action actions = 1;
 * @return {!Array<!proto.entity.Action>}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.entity.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_action_pb.Action, 1));
};


/**
 * @param {!Array<!proto.entity.Action>} value
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply} returns this
*/
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.Action}
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetExecutedGoogleAdsActionsReply} returns this
 */
proto.grpcwebPb.GetExecutedGoogleAdsActionsReply.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.DateRangeV2 date_range = 2;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 2));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignInfosList: jspb.Message.toObjectList(msg.getCampaignInfosList(),
    proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo;
      reader.readMessage(value,proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.deserializeBinaryFromReader);
      msg.addCampaignInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    adGroupInfosList: jspb.Message.toObjectList(msg.getAdGroupInfosList(),
    proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.toObject, includeInstance),
    campaignMetrics: (f = msg.getCampaignMetrics()) && proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.Option} */ (reader.readEnum());
      msg.setCampaignType(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo;
      reader.readMessage(value,proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.deserializeBinaryFromReader);
      msg.addAdGroupInfos(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics;
      reader.readMessage(value,proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.deserializeBinaryFromReader);
      msg.setCampaignMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCampaignType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAdGroupInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getCampaignMetrics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.repeatedFields_ = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    impressionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    clicksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    costsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    conversionsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    conversionValueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    addToCartClicksList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    unitsSoldList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    topImpressionPercentageList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    absoluteTopImpressionPercentageList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    impressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 11)) == null ? undefined : f,
    exactMatchImpressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 12)) == null ? undefined : f,
    topImpressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 13)) == null ? undefined : f,
    absoluteTopImpressionShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 14)) == null ? undefined : f,
    lostImpressionShareLowRankList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 15)) == null ? undefined : f,
    lostTopImpressionShareLowRankList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 16)) == null ? undefined : f,
    lostAbsoluteTopImpressionShareLowRankList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 17)) == null ? undefined : f,
    lostImpressionShareLowBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 18)) == null ? undefined : f,
    lostTopImpressionShareLowBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 19)) == null ? undefined : f,
    lostAbsoluteTopImpressionShareLowBudgetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 20)) == null ? undefined : f,
    clickShareList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 21)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addImpressions(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClicks(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCosts(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversions(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversionValue(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAddToCartClicks(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnitsSold(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTopImpressionPercentage(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAbsoluteTopImpressionPercentage(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addImpressionShare(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExactMatchImpressionShare(values[i]);
      }
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTopImpressionShare(values[i]);
      }
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAbsoluteTopImpressionShare(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostImpressionShareLowRank(values[i]);
      }
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostTopImpressionShareLowRank(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostAbsoluteTopImpressionShareLowRank(values[i]);
      }
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostImpressionShareLowBudget(values[i]);
      }
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostTopImpressionShareLowBudget(values[i]);
      }
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLostAbsoluteTopImpressionShareLowBudget(values[i]);
      }
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClickShare(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getImpressionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getClicksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getConversionsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
  f = message.getConversionValueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getAddToCartClicksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getUnitsSoldList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getTopImpressionPercentageList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
  f = message.getAbsoluteTopImpressionPercentageList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
  f = message.getImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      11,
      f
    );
  }
  f = message.getExactMatchImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      12,
      f
    );
  }
  f = message.getTopImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      13,
      f
    );
  }
  f = message.getAbsoluteTopImpressionShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      14,
      f
    );
  }
  f = message.getLostImpressionShareLowRankList();
  if (f.length > 0) {
    writer.writePackedDouble(
      15,
      f
    );
  }
  f = message.getLostTopImpressionShareLowRankList();
  if (f.length > 0) {
    writer.writePackedDouble(
      16,
      f
    );
  }
  f = message.getLostAbsoluteTopImpressionShareLowRankList();
  if (f.length > 0) {
    writer.writePackedDouble(
      17,
      f
    );
  }
  f = message.getLostImpressionShareLowBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      18,
      f
    );
  }
  f = message.getLostTopImpressionShareLowBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      19,
      f
    );
  }
  f = message.getLostAbsoluteTopImpressionShareLowBudgetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      20,
      f
    );
  }
  f = message.getClickShareList();
  if (f.length > 0) {
    writer.writePackedDouble(
      21,
      f
    );
  }
};


/**
 * optional common.DateRangeV2 date_range = 1;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 1));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 impressions = 2;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getImpressionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setImpressionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addImpressions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearImpressionsList = function() {
  return this.setImpressionsList([]);
};


/**
 * repeated int64 clicks = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getClicksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setClicksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addClicks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearClicksList = function() {
  return this.setClicksList([]);
};


/**
 * repeated double costs = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getCostsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setCostsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addCosts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};


/**
 * repeated double conversions = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getConversionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setConversionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addConversions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearConversionsList = function() {
  return this.setConversionsList([]);
};


/**
 * repeated double conversion_value = 6;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getConversionValueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setConversionValueList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addConversionValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearConversionValueList = function() {
  return this.setConversionValueList([]);
};


/**
 * repeated int64 add_to_cart_clicks = 7;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getAddToCartClicksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setAddToCartClicksList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addAddToCartClicks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearAddToCartClicksList = function() {
  return this.setAddToCartClicksList([]);
};


/**
 * repeated int64 units_sold = 8;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getUnitsSoldList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setUnitsSoldList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addUnitsSold = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearUnitsSoldList = function() {
  return this.setUnitsSoldList([]);
};


/**
 * repeated double top_impression_percentage = 9;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getTopImpressionPercentageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setTopImpressionPercentageList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addTopImpressionPercentage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearTopImpressionPercentageList = function() {
  return this.setTopImpressionPercentageList([]);
};


/**
 * repeated double absolute_top_impression_percentage = 10;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getAbsoluteTopImpressionPercentageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setAbsoluteTopImpressionPercentageList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addAbsoluteTopImpressionPercentage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearAbsoluteTopImpressionPercentageList = function() {
  return this.setAbsoluteTopImpressionPercentageList([]);
};


/**
 * repeated double impression_share = 11;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setImpressionShareList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearImpressionShareList = function() {
  return this.setImpressionShareList([]);
};


/**
 * repeated double exact_match_impression_share = 12;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getExactMatchImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setExactMatchImpressionShareList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addExactMatchImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearExactMatchImpressionShareList = function() {
  return this.setExactMatchImpressionShareList([]);
};


/**
 * repeated double top_impression_share = 13;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getTopImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setTopImpressionShareList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addTopImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearTopImpressionShareList = function() {
  return this.setTopImpressionShareList([]);
};


/**
 * repeated double absolute_top_impression_share = 14;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getAbsoluteTopImpressionShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setAbsoluteTopImpressionShareList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addAbsoluteTopImpressionShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearAbsoluteTopImpressionShareList = function() {
  return this.setAbsoluteTopImpressionShareList([]);
};


/**
 * repeated double lost_impression_share_low_rank = 15;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getLostImpressionShareLowRankList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setLostImpressionShareLowRankList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addLostImpressionShareLowRank = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearLostImpressionShareLowRankList = function() {
  return this.setLostImpressionShareLowRankList([]);
};


/**
 * repeated double lost_top_impression_share_low_rank = 16;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getLostTopImpressionShareLowRankList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setLostTopImpressionShareLowRankList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addLostTopImpressionShareLowRank = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearLostTopImpressionShareLowRankList = function() {
  return this.setLostTopImpressionShareLowRankList([]);
};


/**
 * repeated double lost_absolute_top_impression_share_low_rank = 17;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getLostAbsoluteTopImpressionShareLowRankList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setLostAbsoluteTopImpressionShareLowRankList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addLostAbsoluteTopImpressionShareLowRank = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearLostAbsoluteTopImpressionShareLowRankList = function() {
  return this.setLostAbsoluteTopImpressionShareLowRankList([]);
};


/**
 * repeated double lost_impression_share_low_budget = 18;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getLostImpressionShareLowBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setLostImpressionShareLowBudgetList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addLostImpressionShareLowBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearLostImpressionShareLowBudgetList = function() {
  return this.setLostImpressionShareLowBudgetList([]);
};


/**
 * repeated double lost_top_impression_share_low_budget = 19;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getLostTopImpressionShareLowBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setLostTopImpressionShareLowBudgetList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addLostTopImpressionShareLowBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearLostTopImpressionShareLowBudgetList = function() {
  return this.setLostTopImpressionShareLowBudgetList([]);
};


/**
 * repeated double lost_absolute_top_impression_share_low_budget = 20;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getLostAbsoluteTopImpressionShareLowBudgetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setLostAbsoluteTopImpressionShareLowBudgetList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addLostAbsoluteTopImpressionShareLowBudget = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearLostAbsoluteTopImpressionShareLowBudgetList = function() {
  return this.setLostAbsoluteTopImpressionShareLowBudgetList([]);
};


/**
 * repeated double click_share = 21;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.getClickShareList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.setClickShareList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.addClickShare = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics.prototype.clearClickShareList = function() {
  return this.setClickShareList([]);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 campaign_id = 2;
 * @return {number}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CampaignType.Option campaign_type = 3;
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.Option}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.getCampaignType = function() {
  return /** @type {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.Option} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.setCampaignType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated AdGroupInfo ad_group_infos = 4;
 * @return {!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.getAdGroupInfosList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo, 4));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.setAdGroupInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.addAdGroupInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.clearAdGroupInfosList = function() {
  return this.setAdGroupInfosList([]);
};


/**
 * optional CampaignMetrics campaign_metrics = 5;
 * @return {?proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.getCampaignMetrics = function() {
  return /** @type{?proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics, 5));
};


/**
 * @param {?proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.CampaignMetrics|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.setCampaignMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.clearCampaignMetrics = function() {
  return this.setCampaignMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo.prototype.hasCampaignMetrics = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignType.Option = {
  UNKNOWN: 0,
  DYNAMIC_SEARCH: 1,
  STANDARD_SEARCH: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.repeatedFields_ = [15,3,4,18,8,10,11,12,5,6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productDetails: (f = msg.getProductDetails()) && proto_common_productDetails_pb.ProductDetails.toObject(includeInstance, f),
    actionId: (f = msg.getActionId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    dateRange: (f = msg.getDateRange()) && proto_common_datetime_pb.DateRangeV2.toObject(includeInstance, f),
    latestVariantAsinsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    priceCurrency: jspb.Message.getFieldWithDefault(msg, 17, ""),
    impressionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    clicksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    costsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 18)) == null ? undefined : f,
    conversionsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    conversionValueList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    addToCartClicksList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    unitsSoldList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    searchTermRanksList: jspb.Message.toObjectList(msg.getSearchTermRanksList(),
    proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.SearchTermRanks.toObject, includeInstance),
    categoryRanksList: jspb.Message.toObjectList(msg.getCategoryRanksList(),
    proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.CategoryRanks.toObject, includeInstance),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adInfosList: jspb.Message.toObjectList(msg.getAdInfosList(),
    proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = new proto_common_productDetails_pb.ProductDetails;
      reader.readMessage(value,proto_common_productDetails_pb.ProductDetails.deserializeBinaryFromReader);
      msg.setProductDetails(value);
      break;
    case 13:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setActionId(value);
      break;
    case 14:
      var value = new proto_common_datetime_pb.DateRangeV2;
      reader.readMessage(value,proto_common_datetime_pb.DateRangeV2.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addLatestVariantAsins(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceCurrency(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addImpressions(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClicks(values[i]);
      }
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCosts(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversions(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConversionValue(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAddToCartClicks(values[i]);
      }
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnitsSold(values[i]);
      }
      break;
    case 5:
      var value = new proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.SearchTermRanks;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.SearchTermRanks.deserializeBinaryFromReader);
      msg.addSearchTermRanks(value);
      break;
    case 6:
      var value = new proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.CategoryRanks;
      reader.readMessage(value,proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.CategoryRanks.deserializeBinaryFromReader);
      msg.addCategoryRanks(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 9:
      var value = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo;
      reader.readMessage(value,proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.deserializeBinaryFromReader);
      msg.addAdInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProductDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_productDetails_pb.ProductDetails.serializeBinaryToWriter
    );
  }
  f = message.getActionId();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_common_datetime_pb.DateRangeV2.serializeBinaryToWriter
    );
  }
  f = message.getLatestVariantAsinsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getImpressionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getClicksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      18,
      f
    );
  }
  f = message.getConversionsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getConversionValueList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
  f = message.getAddToCartClicksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getUnitsSoldList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getSearchTermRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.SearchTermRanks.serializeBinaryToWriter
    );
  }
  f = message.getCategoryRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.CategoryRanks.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAdInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ad_group_id = 1;
 * @return {number}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ProductDetails product_details = 2;
 * @return {?proto.common.ProductDetails}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getProductDetails = function() {
  return /** @type{?proto.common.ProductDetails} */ (
    jspb.Message.getWrapperField(this, proto_common_productDetails_pb.ProductDetails, 2));
};


/**
 * @param {?proto.common.ProductDetails|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setProductDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearProductDetails = function() {
  return this.setProductDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.hasProductDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ObjectID action_id = 13;
 * @return {?proto.common.ObjectID}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getActionId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 13));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setActionId = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearActionId = function() {
  return this.setActionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.hasActionId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.DateRangeV2 date_range = 14;
 * @return {?proto.common.DateRangeV2}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getDateRange = function() {
  return /** @type{?proto.common.DateRangeV2} */ (
    jspb.Message.getWrapperField(this, proto_common_datetime_pb.DateRangeV2, 14));
};


/**
 * @param {?proto.common.DateRangeV2|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated string latest_variant_asins = 15;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getLatestVariantAsinsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setLatestVariantAsinsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addLatestVariantAsins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearLatestVariantAsinsList = function() {
  return this.setLatestVariantAsinsList([]);
};


/**
 * optional double price = 16;
 * @return {number}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string price_currency = 17;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getPriceCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated int64 impressions = 3;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getImpressionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setImpressionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addImpressions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearImpressionsList = function() {
  return this.setImpressionsList([]);
};


/**
 * repeated int64 clicks = 4;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getClicksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setClicksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addClicks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearClicksList = function() {
  return this.setClicksList([]);
};


/**
 * repeated double costs = 18;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getCostsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setCostsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addCosts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};


/**
 * repeated double conversions = 8;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getConversionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setConversionsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addConversions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearConversionsList = function() {
  return this.setConversionsList([]);
};


/**
 * repeated double conversion_value = 10;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getConversionValueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setConversionValueList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addConversionValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearConversionValueList = function() {
  return this.setConversionValueList([]);
};


/**
 * repeated int64 add_to_cart_clicks = 11;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getAddToCartClicksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setAddToCartClicksList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addAddToCartClicks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearAddToCartClicksList = function() {
  return this.setAddToCartClicksList([]);
};


/**
 * repeated int64 units_sold = 12;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getUnitsSoldList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setUnitsSoldList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addUnitsSold = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearUnitsSoldList = function() {
  return this.setUnitsSoldList([]);
};


/**
 * repeated SearchTermRanks search_term_ranks = 5;
 * @return {!Array<!proto.grpcwebPb.SearchTermRanks>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getSearchTermRanksList = function() {
  return /** @type{!Array<!proto.grpcwebPb.SearchTermRanks>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.SearchTermRanks, 5));
};


/**
 * @param {!Array<!proto.grpcwebPb.SearchTermRanks>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setSearchTermRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.grpcwebPb.SearchTermRanks=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.SearchTermRanks}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addSearchTermRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.grpcwebPb.SearchTermRanks, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearSearchTermRanksList = function() {
  return this.setSearchTermRanksList([]);
};


/**
 * repeated CategoryRanks category_ranks = 6;
 * @return {!Array<!proto.grpcwebPb.CategoryRanks>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getCategoryRanksList = function() {
  return /** @type{!Array<!proto.grpcwebPb.CategoryRanks>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_edge_grpcwebPb_grpcweb_PerformanceMetrics_pb.CategoryRanks, 6));
};


/**
 * @param {!Array<!proto.grpcwebPb.CategoryRanks>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setCategoryRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.grpcwebPb.CategoryRanks=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.CategoryRanks}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addCategoryRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.grpcwebPb.CategoryRanks, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearCategoryRanksList = function() {
  return this.setCategoryRanksList([]);
};


/**
 * optional google.protobuf.Timestamp creation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated AdInfo ad_infos = 9;
 * @return {!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.getAdInfosList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo, 9));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.setAdInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.addAdInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdGroupInfo.prototype.clearAdInfosList = function() {
  return this.setAdInfosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.repeatedFields_ = [9,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reviewStatus: jspb.Message.getFieldWithDefault(msg, 7, ""),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    policyMessagesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    finalUrlsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    trackingUrlTemplate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usesAmpdAttribution: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    advertiserId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    advertiserIdStr: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo;
  return proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovalStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addPolicyMessages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFinalUrls(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingUrlTemplate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsesAmpdAttribution(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdvertiserId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertiserIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReviewStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPolicyMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getFinalUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTrackingUrlTemplate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsesAmpdAttribution();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAdvertiserId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 ad_id = 1;
 * @return {number}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getAdId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setAdId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string review_status = 7;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getReviewStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setReviewStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string approval_status = 8;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getApprovalStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string policy_messages = 9;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getPolicyMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setPolicyMessagesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.addPolicyMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.clearPolicyMessagesList = function() {
  return this.setPolicyMessagesList([]);
};


/**
 * repeated string final_urls = 2;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getFinalUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setFinalUrlsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.addFinalUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.clearFinalUrlsList = function() {
  return this.setFinalUrlsList([]);
};


/**
 * optional string tracking_url_template = 3;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getTrackingUrlTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setTrackingUrlTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool uses_ampd_attribution = 4;
 * @return {boolean}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getUsesAmpdAttribution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setUsesAmpdAttribution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 advertiser_id = 5;
 * @return {number}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getAdvertiserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setAdvertiserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string advertiser_id_str = 6;
 * @return {string}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.getAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.AdInfo.prototype.setAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated CampaignInfo campaign_infos = 1;
 * @return {!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo>}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.prototype.getCampaignInfosList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo>} value
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply} returns this
*/
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.prototype.setCampaignInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo}
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.prototype.addCampaignInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.CampaignInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply} returns this
 */
proto.grpcwebPb.GetCreatedGoogleAdsCampaignsReply.prototype.clearCampaignInfosList = function() {
  return this.setCampaignInfosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest;
  return proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest} returns this
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto_googleAdsPb_adWithAmazonAttribution_pb.AdWithAmazonAttribution.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply;
  return proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_googleAdsPb_adWithAmazonAttribution_pb.AdWithAmazonAttribution;
      reader.readMessage(value,proto_googleAdsPb_adWithAmazonAttribution_pb.AdWithAmazonAttribution.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_googleAdsPb_adWithAmazonAttribution_pb.AdWithAmazonAttribution.serializeBinaryToWriter
    );
  }
};


/**
 * repeated googleAdsPb.AdWithAmazonAttribution ads = 1;
 * @return {!Array<!proto.googleAdsPb.AdWithAmazonAttribution>}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.googleAdsPb.AdWithAmazonAttribution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_googleAdsPb_adWithAmazonAttribution_pb.AdWithAmazonAttribution, 1));
};


/**
 * @param {!Array<!proto.googleAdsPb.AdWithAmazonAttribution>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply} returns this
*/
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.googleAdsPb.AdWithAmazonAttribution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.googleAdsPb.AdWithAmazonAttribution}
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.googleAdsPb.AdWithAmazonAttribution, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply} returns this
 */
proto.grpcwebPb.GetGoogleAdsAdsWithAmazonAttributionReply.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateGoogleRsaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateGoogleRsaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.grpcwebPb.UpdateGoogleRsaAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateGoogleRsaRequest;
  return proto.grpcwebPb.UpdateGoogleRsaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateGoogleRsaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.UpdateGoogleRsaAction;
      reader.readMessage(value,proto.grpcwebPb.UpdateGoogleRsaAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateGoogleRsaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateGoogleRsaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grpcwebPb.UpdateGoogleRsaAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_id = 3;
 * @return {string}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated UpdateGoogleRsaAction actions = 4;
 * @return {!Array<!proto.grpcwebPb.UpdateGoogleRsaAction>}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.UpdateGoogleRsaAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.UpdateGoogleRsaAction, 4));
};


/**
 * @param {!Array<!proto.grpcwebPb.UpdateGoogleRsaAction>} value
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest} returns this
*/
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpcwebPb.UpdateGoogleRsaAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateGoogleRsaAction}
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpcwebPb.UpdateGoogleRsaAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaRequest} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaRequest.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.UpdateGoogleRsaAction.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.ActionCase = {
  ACTION_NOT_SET: 0,
  UPDATE_COPY: 1
};

/**
 * @return {proto.grpcwebPb.UpdateGoogleRsaAction.ActionCase}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.getActionCase = function() {
  return /** @type {proto.grpcwebPb.UpdateGoogleRsaAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.UpdateGoogleRsaAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateGoogleRsaAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateGoogleRsaAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateGoogleRsaAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateCopy: (f = msg.getUpdateCopy()) && proto_googleAdsPb_ad_pb.UpdateRsaCopyAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaAction}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateGoogleRsaAction;
  return proto.grpcwebPb.UpdateGoogleRsaAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateGoogleRsaAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaAction}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_googleAdsPb_ad_pb.UpdateRsaCopyAction;
      reader.readMessage(value,proto_googleAdsPb_ad_pb.UpdateRsaCopyAction.deserializeBinaryFromReader);
      msg.setUpdateCopy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateGoogleRsaAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateGoogleRsaAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateGoogleRsaAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateCopy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_googleAdsPb_ad_pb.UpdateRsaCopyAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional googleAdsPb.UpdateRsaCopyAction update_copy = 1;
 * @return {?proto.googleAdsPb.UpdateRsaCopyAction}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.getUpdateCopy = function() {
  return /** @type{?proto.googleAdsPb.UpdateRsaCopyAction} */ (
    jspb.Message.getWrapperField(this, proto_googleAdsPb_ad_pb.UpdateRsaCopyAction, 1));
};


/**
 * @param {?proto.googleAdsPb.UpdateRsaCopyAction|undefined} value
 * @return {!proto.grpcwebPb.UpdateGoogleRsaAction} returns this
*/
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.setUpdateCopy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.grpcwebPb.UpdateGoogleRsaAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaAction} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.clearUpdateCopy = function() {
  return this.setUpdateCopy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateGoogleRsaAction.prototype.hasUpdateCopy = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateGoogleRsaReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateGoogleRsaReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateGoogleRsaReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateGoogleRsaReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateGoogleRsaReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedadidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaReply}
 */
proto.grpcwebPb.UpdateGoogleRsaReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateGoogleRsaReply;
  return proto.grpcwebPb.UpdateGoogleRsaReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateGoogleRsaReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaReply}
 */
proto.grpcwebPb.UpdateGoogleRsaReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUpdatedadids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateGoogleRsaReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateGoogleRsaReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateGoogleRsaReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateGoogleRsaReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedadidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string UpdatedAdIds = 1;
 * @return {!Array<string>}
 */
proto.grpcwebPb.UpdateGoogleRsaReply.prototype.getUpdatedadidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.UpdateGoogleRsaReply} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaReply.prototype.setUpdatedadidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.UpdateGoogleRsaReply} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaReply.prototype.addUpdatedadids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateGoogleRsaReply} returns this
 */
proto.grpcwebPb.UpdateGoogleRsaReply.prototype.clearUpdatedadidsList = function() {
  return this.setUpdatedadidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GoogleAdsAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GoogleAdsAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GoogleAdsAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GoogleAdsAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GoogleAdsAuditRequest}
 */
proto.grpcwebPb.GoogleAdsAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GoogleAdsAuditRequest;
  return proto.grpcwebPb.GoogleAdsAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GoogleAdsAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GoogleAdsAuditRequest}
 */
proto.grpcwebPb.GoogleAdsAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GoogleAdsAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GoogleAdsAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GoogleAdsAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GoogleAdsAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GoogleAdsAuditRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GoogleAdsAuditRequest} returns this
 */
proto.grpcwebPb.GoogleAdsAuditRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.GoogleAdsAuditRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GoogleAdsAuditRequest} returns this
 */
proto.grpcwebPb.GoogleAdsAuditRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GoogleAdsAuditReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GoogleAdsAuditReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GoogleAdsAuditReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GoogleAdsAuditReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    googleAdsAudit: (f = msg.getGoogleAdsAudit()) && proto_common_dataSourceInfo_pb.GoogleAdsAudit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GoogleAdsAuditReply}
 */
proto.grpcwebPb.GoogleAdsAuditReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GoogleAdsAuditReply;
  return proto.grpcwebPb.GoogleAdsAuditReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GoogleAdsAuditReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GoogleAdsAuditReply}
 */
proto.grpcwebPb.GoogleAdsAuditReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.GoogleAdsAudit;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GoogleAdsAudit.deserializeBinaryFromReader);
      msg.setGoogleAdsAudit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GoogleAdsAuditReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GoogleAdsAuditReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GoogleAdsAuditReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GoogleAdsAuditReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoogleAdsAudit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.GoogleAdsAudit.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.GoogleAdsAudit google_ads_audit = 1;
 * @return {?proto.common.GoogleAdsAudit}
 */
proto.grpcwebPb.GoogleAdsAuditReply.prototype.getGoogleAdsAudit = function() {
  return /** @type{?proto.common.GoogleAdsAudit} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GoogleAdsAudit, 1));
};


/**
 * @param {?proto.common.GoogleAdsAudit|undefined} value
 * @return {!proto.grpcwebPb.GoogleAdsAuditReply} returns this
*/
proto.grpcwebPb.GoogleAdsAuditReply.prototype.setGoogleAdsAudit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GoogleAdsAuditReply} returns this
 */
proto.grpcwebPb.GoogleAdsAuditReply.prototype.clearGoogleAdsAudit = function() {
  return this.setGoogleAdsAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GoogleAdsAuditReply.prototype.hasGoogleAdsAudit = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    constraints: (f = msg.getConstraints()) && proto_warehousePb_googleAds_pb.GoogleAdsChangeEventConstraints.toObject(includeInstance, f),
    maxEvents: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updateChangeHistoryFirst: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new proto_warehousePb_googleAds_pb.GoogleAdsChangeEventConstraints;
      reader.readMessage(value,proto_warehousePb_googleAds_pb.GoogleAdsChangeEventConstraints.deserializeBinaryFromReader);
      msg.setConstraints(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxEvents(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateChangeHistoryFirst(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConstraints();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_warehousePb_googleAds_pb.GoogleAdsChangeEventConstraints.serializeBinaryToWriter
    );
  }
  f = message.getMaxEvents();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdateChangeHistoryFirst();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional warehousePb.GoogleAdsChangeEventConstraints constraints = 3;
 * @return {?proto.warehousePb.GoogleAdsChangeEventConstraints}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.getConstraints = function() {
  return /** @type{?proto.warehousePb.GoogleAdsChangeEventConstraints} */ (
    jspb.Message.getWrapperField(this, proto_warehousePb_googleAds_pb.GoogleAdsChangeEventConstraints, 3));
};


/**
 * @param {?proto.warehousePb.GoogleAdsChangeEventConstraints|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.setConstraints = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.clearConstraints = function() {
  return this.setConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.hasConstraints = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 max_events = 4;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.getMaxEvents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.setMaxEvents = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool update_change_history_first = 5;
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.getUpdateChangeHistoryFirst = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryRequest.prototype.setUpdateChangeHistoryFirst = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignsList: jspb.Message.toObjectList(msg.getCampaignsList(),
    proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.toObject, includeInstance),
    otherEventsList: jspb.Message.toObjectList(msg.getOtherEventsList(),
    proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.toObject, includeInstance),
    remainingOtherEvents: (f = msg.getRemainingOtherEvents()) && proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.deserializeBinaryFromReader);
      msg.addCampaigns(value);
      break;
    case 2:
      var value = new proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent;
      reader.readMessage(value,proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.deserializeBinaryFromReader);
      msg.addOtherEvents(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader);
      msg.setRemainingOtherEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.serializeBinaryToWriter
    );
  }
  f = message.getOtherEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.serializeBinaryToWriter
    );
  }
  f = message.getRemainingOtherEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.repeatedFields_ = [2,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.toObject, includeInstance),
    remainingEvents: (f = msg.getRemainingEvents()) && proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject(includeInstance, f),
    adGroupsList: jspb.Message.toObjectList(msg.getAdGroupsList(),
    proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.toObject, includeInstance),
    campaignCriteriaList: jspb.Message.toObjectList(msg.getCampaignCriteriaList(),
    proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = new proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent;
      reader.readMessage(value,proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 6:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader);
      msg.setRemainingEvents(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.deserializeBinaryFromReader);
      msg.addAdGroups(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.deserializeBinaryFromReader);
      msg.addCampaignCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.serializeBinaryToWriter
    );
  }
  f = message.getRemainingEvents();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.serializeBinaryToWriter
    );
  }
  f = message.getCampaignCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 customer_id = 7;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated warehousePb.GoogleAdsChangeEvent events = 2;
 * @return {!Array<!proto.warehousePb.GoogleAdsChangeEvent>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.warehousePb.GoogleAdsChangeEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent, 2));
};


/**
 * @param {!Array<!proto.warehousePb.GoogleAdsChangeEvent>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.warehousePb.GoogleAdsChangeEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.warehousePb.GoogleAdsChangeEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional RemainingEvents remaining_events = 6;
 * @return {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.getRemainingEvents = function() {
  return /** @type{?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents, 6));
};


/**
 * @param {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.setRemainingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.clearRemainingEvents = function() {
  return this.setRemainingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.hasRemainingEvents = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated AdGroup ad_groups = 4;
 * @return {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.getAdGroupsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup, 4));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.setAdGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.addAdGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.clearAdGroupsList = function() {
  return this.setAdGroupsList([]);
};


/**
 * repeated Criteria campaign_criteria = 5;
 * @return {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.getCampaignCriteriaList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria, 5));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.setCampaignCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.addCampaignCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign.prototype.clearCampaignCriteriaList = function() {
  return this.setCampaignCriteriaList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    adGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.toObject, includeInstance),
    remainingEvents: (f = msg.getRemainingEvents()) && proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject(includeInstance, f),
    adGroupAdsList: jspb.Message.toObjectList(msg.getAdGroupAdsList(),
    proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.toObject, includeInstance),
    adGroupCriteriaList: jspb.Message.toObjectList(msg.getAdGroupCriteriaList(),
    proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdGroupId(value);
      break;
    case 2:
      var value = new proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent;
      reader.readMessage(value,proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 5:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader);
      msg.setRemainingEvents(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.deserializeBinaryFromReader);
      msg.addAdGroupAds(value);
      break;
    case 4:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.deserializeBinaryFromReader);
      msg.addAdGroupCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.serializeBinaryToWriter
    );
  }
  f = message.getRemainingEvents();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.serializeBinaryToWriter
    );
  }
  f = message.getAdGroupCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ad_group_id = 1;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.getAdGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.setAdGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated warehousePb.GoogleAdsChangeEvent events = 2;
 * @return {!Array<!proto.warehousePb.GoogleAdsChangeEvent>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.warehousePb.GoogleAdsChangeEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent, 2));
};


/**
 * @param {!Array<!proto.warehousePb.GoogleAdsChangeEvent>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.warehousePb.GoogleAdsChangeEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.warehousePb.GoogleAdsChangeEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional RemainingEvents remaining_events = 5;
 * @return {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.getRemainingEvents = function() {
  return /** @type{?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents, 5));
};


/**
 * @param {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.setRemainingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.clearRemainingEvents = function() {
  return this.setRemainingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.hasRemainingEvents = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Ad ad_group_ads = 3;
 * @return {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.getAdGroupAdsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.setAdGroupAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.addAdGroupAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.clearAdGroupAdsList = function() {
  return this.setAdGroupAdsList([]);
};


/**
 * repeated Criteria ad_group_criteria = 4;
 * @return {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.getAdGroupCriteriaList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria, 4));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.setAdGroupCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.addAdGroupCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.AdGroup.prototype.clearAdGroupCriteriaList = function() {
  return this.setAdGroupCriteriaList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.toObject, includeInstance),
    remainingEvents: (f = msg.getRemainingEvents()) && proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdId(value);
      break;
    case 2:
      var value = new proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent;
      reader.readMessage(value,proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader);
      msg.setRemainingEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.serializeBinaryToWriter
    );
  }
  f = message.getRemainingEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ad_id = 1;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.getAdId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.setAdId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated warehousePb.GoogleAdsChangeEvent events = 2;
 * @return {!Array<!proto.warehousePb.GoogleAdsChangeEvent>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.warehousePb.GoogleAdsChangeEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent, 2));
};


/**
 * @param {!Array<!proto.warehousePb.GoogleAdsChangeEvent>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.warehousePb.GoogleAdsChangeEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.warehousePb.GoogleAdsChangeEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional RemainingEvents remaining_events = 3;
 * @return {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.getRemainingEvents = function() {
  return /** @type{?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents, 3));
};


/**
 * @param {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.setRemainingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.clearRemainingEvents = function() {
  return this.setRemainingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Ad.prototype.hasRemainingEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.toObject, includeInstance),
    remainingEvents: (f = msg.getRemainingEvents()) && proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCriteriaId(value);
      break;
    case 2:
      var value = new proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent;
      reader.readMessage(value,proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 3:
      var value = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents;
      reader.readMessage(value,proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader);
      msg.setRemainingEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent.serializeBinaryToWriter
    );
  }
  f = message.getRemainingEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 criteria_id = 1;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.getCriteriaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.setCriteriaId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated warehousePb.GoogleAdsChangeEvent events = 2;
 * @return {!Array<!proto.warehousePb.GoogleAdsChangeEvent>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.warehousePb.GoogleAdsChangeEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent, 2));
};


/**
 * @param {!Array<!proto.warehousePb.GoogleAdsChangeEvent>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.warehousePb.GoogleAdsChangeEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.warehousePb.GoogleAdsChangeEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional RemainingEvents remaining_events = 3;
 * @return {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.getRemainingEvents = function() {
  return /** @type{?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents, 3));
};


/**
 * @param {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.setRemainingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.clearRemainingEvents = function() {
  return this.setRemainingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Criteria.prototype.hasRemainingEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    remainingCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    earliestTime: (f = msg.getEarliestTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    latestTime: (f = msg.getLatestTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userEmailsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents;
  return proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingCount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEarliestTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLatestTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemainingCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEarliestTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatestTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional int64 remaining_count = 1;
 * @return {number}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.getRemainingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.setRemainingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp earliest_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.getEarliestTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.setEarliestTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.clearEarliestTime = function() {
  return this.setEarliestTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.hasEarliestTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp latest_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.getLatestTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.setLatestTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.clearLatestTime = function() {
  return this.setLatestTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.hasLatestTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string user_emails = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};


/**
 * repeated Campaign campaigns = 1;
 * @return {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.getCampaignsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.setCampaignsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.addCampaigns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.Campaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.clearCampaignsList = function() {
  return this.setCampaignsList([]);
};


/**
 * repeated warehousePb.GoogleAdsChangeEvent other_events = 2;
 * @return {!Array<!proto.warehousePb.GoogleAdsChangeEvent>}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.getOtherEventsList = function() {
  return /** @type{!Array<!proto.warehousePb.GoogleAdsChangeEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_warehousePb_googleAds_pb.GoogleAdsChangeEvent, 2));
};


/**
 * @param {!Array<!proto.warehousePb.GoogleAdsChangeEvent>} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.setOtherEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.warehousePb.GoogleAdsChangeEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.warehousePb.GoogleAdsChangeEvent}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.addOtherEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.warehousePb.GoogleAdsChangeEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.clearOtherEventsList = function() {
  return this.setOtherEventsList([]);
};


/**
 * optional RemainingEvents remaining_other_events = 3;
 * @return {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.getRemainingOtherEvents = function() {
  return /** @type{?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents, 3));
};


/**
 * @param {?proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.RemainingEvents|undefined} value
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} returns this
*/
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.setRemainingOtherEvents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetGoogleAdsChangeHistoryReply} returns this
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.clearRemainingOtherEvents = function() {
  return this.setRemainingOtherEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetGoogleAdsChangeHistoryReply.prototype.hasRemainingOtherEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seedUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seedKeywordsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    geotargetsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest;
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeedUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeedKeywords(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGeotargets(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeedUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeedKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getGeotargetsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string seed_url = 3;
 * @return {string}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.getSeedUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.setSeedUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string seed_keywords = 4;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.getSeedKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.setSeedKeywordsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.addSeedKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.clearSeedKeywordsList = function() {
  return this.setSeedKeywordsList([]);
};


/**
 * repeated int64 geotargets = 5;
 * @return {!Array<number>}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.getGeotargetsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.setGeotargetsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.addGeotargets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasRequest.prototype.clearGeotargetsList = function() {
  return this.setGeotargetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    keywordIdeasList: jspb.Message.toObjectList(msg.getKeywordIdeasList(),
    proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply;
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea;
      reader.readMessage(value,proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.deserializeBinaryFromReader);
      msg.addKeywordIdeas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeywordIdeasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avgMonthlySearches: (f = msg.getAvgMonthlySearches()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    competitionIndex: (f = msg.getCompetitionIndex()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    lowTopOfPageBid: (f = msg.getLowTopOfPageBid()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    highTopOfPageBid: (f = msg.getHighTopOfPageBid()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    brandNamesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    possibleBrandNamesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    conceptsList: jspb.Message.toObjectList(msg.getConceptsList(),
    proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea;
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setAvgMonthlySearches(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setCompetitionIndex(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setLowTopOfPageBid(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setHighTopOfPageBid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addBrandNames(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossibleBrandNames(value);
      break;
    case 8:
      var value = new proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept;
      reader.readMessage(value,proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.deserializeBinaryFromReader);
      msg.addConcepts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvgMonthlySearches();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getCompetitionIndex();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getLowTopOfPageBid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getHighTopOfPageBid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getBrandNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getPossibleBrandNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getConceptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int64Value avg_monthly_searches = 2;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getAvgMonthlySearches = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
*/
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setAvgMonthlySearches = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearAvgMonthlySearches = function() {
  return this.setAvgMonthlySearches(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.hasAvgMonthlySearches = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int64Value competition_index = 3;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getCompetitionIndex = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
*/
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setCompetitionIndex = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearCompetitionIndex = function() {
  return this.setCompetitionIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.hasCompetitionIndex = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.DoubleValue low_top_of_page_bid = 4;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getLowTopOfPageBid = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 4));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
*/
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setLowTopOfPageBid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearLowTopOfPageBid = function() {
  return this.setLowTopOfPageBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.hasLowTopOfPageBid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.DoubleValue high_top_of_page_bid = 5;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getHighTopOfPageBid = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 5));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
*/
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setHighTopOfPageBid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearHighTopOfPageBid = function() {
  return this.setHighTopOfPageBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.hasHighTopOfPageBid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string brand_names = 6;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getBrandNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setBrandNamesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.addBrandNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearBrandNamesList = function() {
  return this.setBrandNamesList([]);
};


/**
 * repeated string possible_brand_names = 7;
 * @return {!Array<string>}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getPossibleBrandNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setPossibleBrandNamesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.addPossibleBrandNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearPossibleBrandNamesList = function() {
  return this.setPossibleBrandNamesList([]);
};


/**
 * repeated KeywordConcept concepts = 8;
 * @return {!Array<!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept>}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.getConceptsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept, 8));
};


/**
 * @param {!Array<!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept>} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
*/
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.setConceptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.addConcepts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea.prototype.clearConceptsList = function() {
  return this.setConceptsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.toObject = function(includeInstance, msg) {
  var f, obj = {
    conceptGroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept;
  return proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConceptGroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConceptGroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string concept_group = 1;
 * @return {string}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.prototype.getConceptGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.prototype.setConceptGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordConcept.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated KeywordIdea keyword_ideas = 1;
 * @return {!Array<!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea>}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.prototype.getKeywordIdeasList = function() {
  return /** @type{!Array<!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea>} value
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply} returns this
*/
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.prototype.setKeywordIdeasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea}
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.prototype.addKeywordIdeas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.KeywordIdea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply} returns this
 */
proto.grpcwebPb.GenerateGoogleAdsKeywordIdeasReply.prototype.clearKeywordIdeasList = function() {
  return this.setKeywordIdeasList([]);
};


goog.object.extend(exports, proto.grpcwebPb);
