import React, { useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dropdown,
  DropdownProps,
  Icon,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import OneClickButton from "Common/components/OneClickButton";

import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import SimpleTooltip from "../SimpleTooltip";
import { sendGAEvent } from "../GA";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import BidAutomationContent, {
  metricsDateRangeOptions
} from "../bidAutomation/BidAutomationContent";
import { RECENT_METRICS_BIWEEKLY_DAYS } from "../../queries/useRecentMetricsForCampaigns";
import {
  EditBidAutomationState,
  useEditBidAutomationState
} from "../bidAutomation/useEditBidAutomationState";

export const UPGRADE_FOR_BID_AUTOMATION_TOOLTIP =
  "Upgrade to Ampd Automation plan to use Ampd Bid Automation";

const BidAutomationEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  buttonComponent?: JSX.Element;
  disabled?: boolean;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  disabled
}) => {
  const { campaignId } = itemizedCampaignConfiguration;

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <SimpleTooltip
        tooltip={
          disabled
            ? UPGRADE_FOR_BID_AUTOMATION_TOOLTIP
            : "Edit Ampd Bid Automation"
        }
      >
        <Icon
          disabled={disabled}
          name={"pencil"}
          style={{ cursor: "pointer", marginTop: -5 }}
          onClick={
            disabled
              ? undefined
              : () => {
                  sendGAEvent(
                    gaCategory,
                    `Click Edit Ampd Bid Automation`,
                    siteAlias
                  );
                  setModalOpen(true);
                }
          }
        />
      </SimpleTooltip>
      {modalOpen && (
        <BidAutomationModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          campaignIds={[String(campaignId)]}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export const BidAutomationModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  campaignIds: Array<string>;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  campaignIds,
  onClose
}) => {
  const [selectedDaysForMetrics, setSelectedDaysForMetrics] = useState(
    RECENT_METRICS_BIWEEKLY_DAYS
  );

  const { campaignName } = itemizedCampaignConfiguration;

  const editBidAutomationState = useEditBidAutomationState({
    siteAlias,
    gaCategory,
    itemizedCampaignConfiguration,
    refetchCampaignConfiguration,
    daysForMetrics: selectedDaysForMetrics,
    campaignIds
  });

  const [modalOpen, setModalOpen] = useState(true);

  const {
    submitAttempts,
    hasUnappliedChanges,
    isSubmitting,
    handleSubmit,
    handleCancel
  } = editBidAutomationState;

  return (
    <Modal
      style={{ width: "80vw" }}
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
        onClose();
      }}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
    >
      <Modal.Header>{campaignName}</Modal.Header>
      <Modal.Content>
        <BidAutomationEditor
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          daysForMetrics={selectedDaysForMetrics}
          setDaysForMetrics={setSelectedDaysForMetrics}
          editBidAutomationState={editBidAutomationState}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            handleCancel?.();
            setModalOpen(false);
            onClose();
          }}
        >
          Cancel
        </Button>
        <OneClickButton
          key={submitAttempts}
          primary={true}
          disabled={!hasUnappliedChanges}
          loading={isSubmitting}
          onClick={async () => {
            if (await handleSubmit?.()) {
              setModalOpen(false);
              onClose();
            }
          }}
        >
          Update Bid Automation Settings
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

export const BidAutomationEditor: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  editBidAutomationState: EditBidAutomationState;
  daysForMetrics: number;
  setDaysForMetrics: (val: number) => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  editBidAutomationState,
  daysForMetrics,
  setDaysForMetrics
}) => {
  const handleUpdateMetricsDateRange = (
    _e: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    setDaysForMetrics(Number(value) || 7);
  };

  const {
    editBidAutomationEnabled,
    setEditBidAutomationEnabled,
    hasAnyBidAutomationMetrics,
    errorMessage
  } = editBidAutomationState;

  const handleToggleAutomationEnabled = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    setEditBidAutomationEnabled(!!data.checked);
  };

  return (
    <>
      <Checkbox
        toggle
        checked={editBidAutomationEnabled}
        onChange={handleToggleAutomationEnabled}
        label="Use Ampd Bid Automation to adjust cost-per-click bids based on performance"
      />

      <Segment
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          pointerEvents: editBidAutomationEnabled ? "auto" : "none"
        }}
        disabled={!editBidAutomationEnabled}
      >
        {hasAnyBidAutomationMetrics && (
          <Dropdown
            style={{ width: "19em" }}
            selection
            options={metricsDateRangeOptions}
            value={daysForMetrics}
            onChange={handleUpdateMetricsDateRange}
          />
        )}

        <BidAutomationContent
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          editBidAutomationState={editBidAutomationState}
          daysForMetrics={daysForMetrics}
          showAdvancedSettings={false}
          provideSaveAndCancelButtons={false}
        />
      </Segment>
      {!!errorMessage && <Message error>{errorMessage}</Message>}
    </>
  );
};

export default BidAutomationEditButton;
