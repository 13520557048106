import _ from "lodash";

// Converts a value to pascalcase, e.g., DomainName.
const pascalcase = key => _.upperFirst(_.camelCase(key));

// Sets each field from the data on the proto. The data must be a plain object
// or a Map. Fields may be in snake case (the original proto name), camelcase
// (the standard JSON representation) or pascalcase (the set*() method name).
export function set(proto, data) {
  const setFieldOnProto = (key, value) => {
    let pascalKey = pascalcase(key);

    if (pascalKey.length < 1) {
      return;
    }

    // Repeated fields have setXList() instead of setX().
    if (_.isArray(value)) {
      pascalKey += "List";
    }

    const setMethodName = "set" + pascalKey;
    const setMethod = proto[setMethodName];
    if (!setMethod) {
      throw new Error(`cannot find method ${setMethodName}`);
    }

    proto["set" + pascalKey].call(proto, value);
  };

  if (_.isPlainObject(data)) {
    Object.getOwnPropertyNames(data).forEach(key => {
      setFieldOnProto(key, data[key]);
    });
  }

  if (data instanceof Map) {
    data.forEach((value, key) => {
      setFieldOnProto(key, value);
    });
  }

  return proto;
}

// Returns the field from the proto specified by the fieldPath, or the
// defaultValue if it cannot be found.
//
// The fieldPath is a dot-separated list of fields to traverse,
// e.g., "siteDetails.dataSourceLinks.adwordsInfo". Each part of the field may
// be snake-case, camel-case, or pascal-case.
export function get(proto, fieldPath, defaultValue = null) {
  const fields = fieldPath.split(".");

  // Returns a [value, ok] pair, where ok indicates whether we were able to
  // get the field from currentValue.
  const getValue = (currentValue, field) => {
    const hasMethod = currentValue["has" + field];
    if (hasMethod && !hasMethod.call(currentValue)) {
      return [undefined, false];
    }

    const getMethod = currentValue["get" + field];
    if (!getMethod) {
      return [undefined, false];
    }

    return [getMethod.call(currentValue), true];
  };

  let currentValue = proto;
  for (let i = 0; i < fields.length; i++) {
    const field = pascalcase(fields[i]);

    if (!field) {
      continue;
    }

    let [nextValue, foundValue] = getValue(currentValue, field);
    if (foundValue) {
      currentValue = nextValue;
      continue;
    }

    // Check whether this is a repeated field, which should have the
    // following four methods:
    // - addX()
    // - getXList()
    // - setXList()
    // - clearXList()
    //
    // However, it should not have a hasXList() method.
    const repeatedField = field + "List";
    const addMethod = currentValue["add" + field];
    const hasListMethod = currentValue["has" + repeatedField];
    const getListMethod = currentValue["get" + repeatedField];
    const setListMethod = currentValue["set" + repeatedField];
    const clearListMethod = currentValue["clear" + repeatedField];

    if (
      !addMethod ||
      !getListMethod ||
      !setListMethod ||
      !clearListMethod ||
      !!hasListMethod
    ) {
      return defaultValue;
    }

    // This looks like a repeated field. Try again with "List" appended to the
    // field.
    [nextValue, foundValue] = getValue(currentValue, repeatedField);
    if (foundValue) {
      currentValue = nextValue;
      continue;
    }

    return defaultValue;
  }

  return currentValue;
}

// Returns the string representation of the enum value, or the zero value if it
// cannot be found.
// @return {string}
export function stringForEnum(enumClass, value) {
  return (
    _.findKey(enumClass, _.partial(_.isEqual, value)) ||
    _.findKey(enumClass, _.partial(_.isEqual, 0)) ||
    ""
  );
}

// Returns the human-friendly string representation of an identifier.
// For example, "TRIAL_EXPIRED" -> "Trial expired", "aBigPony" -> "A big pony".
export function prettyStringForIdentifier(string) {
  return _.capitalize(_.startCase(string));
}

// Returns the human-friendly string representation of the enum value, or the zero value if it
// cannot be found.
export function prettyStringForEnum(enumClass, value) {
  const string = stringForEnum(enumClass, value);

  return prettyStringForIdentifier(string);
}

// Returns the string representation of the oneof case field that is set, or the
// default value if it is unset or cannot be found.
export function stringForOneofCase(caseClass, oneofCase, defaultValue = null) {
  // The oneof is unset.
  if (oneofCase === 0) {
    return defaultValue;
  }

  const foundKey = _.findKey(caseClass, _.partial(_.isEqual, oneofCase));
  return foundKey ? foundKey.toLowerCase() : defaultValue;
}
