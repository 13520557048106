import _ from "lodash";
import Immutable from "immutable";

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dropdown,
  DropdownItemProps,
  Icon,
  Form,
  Grid,
  Message,
  Modal,
  Loader,
  Segment
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import styled from "styled-components/macro";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";
import InputWithMaxUTF8Size from "../InputWithMaxUTF8Size";

import { sendGAEvent } from "../GA";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import { GET_ASIN_PERFORMANCE_METRICS_QUERY_KEY_NAME } from "../../queries/useAsinPerformanceMetricsByKey";

// grpc-web
import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetAmazonSearchTermRankingRequest,
  GetAmazonSearchTermRankingReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Amazon_pb";
import {
  UpdateCampaignsRequest,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { extractErrorMessage } from "Common/errors/error";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { Amazon } from "Common/proto/common/amazon_pb";
import { MAX_KEYWORD_LENGTH, scrubKeyword } from "Common/utils/googleAds";
import { useInvalidateQueries } from "../../queries/useInvalidateQueries";
import {
  isAmazonMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";

// Creates the GetAmazonSearchTermRankingRequest for the given values.
function makeGetAmazonSearchTermRankingRequest({
  siteAlias,
  searchTerm,
  marketplace,
  asin,
  variantAsins
}: {
  siteAlias: string;
  searchTerm: string;
  marketplace: Amazon.Marketplace.Option | undefined;
  asin: string;
  variantAsins: Array<string>;
}): GetAmazonSearchTermRankingRequest {
  const req = new GetAmazonSearchTermRankingRequest();
  req.setSiteAlias(siteAlias);
  req.setAsin(asin);
  req.setSearchTerm(searchTerm);
  req.setVariantAsinsList(variantAsins);
  if (marketplace) {
    req.setMarketplace(marketplace);
  }

  return req;
}

const BasicDropdown = styled(Dropdown)`
  &&& .text {
    font-weight: normal;
  }
  &&& .menu {
    max-height: 10rem;
    overflow-y: auto;
  }
  &&& .menu .item {
    font-size: smaller;
  }
  &&& .menu .active.item {
    font-weight: normal;
  }
`;

const AmazonSearchTermsEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    customerId,
    isCampaignRemoved,
    asin,
    marketplaceInfo,
    actionId
  } = itemizedCampaignConfiguration;

  const handleAmazonAttributionBehaviorModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  let editable = true;
  if (
    !isAmazonMarketplaceInfo(marketplaceInfo) ||
    !asin ||
    !customerId ||
    !actionId ||
    isCampaignRemoved
  ) {
    editable = false;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={
          editable
            ? () => {
                sendGAEvent(gaCategory, "Click Edit Search Terms", siteAlias);
                setModalOpen(true);
              }
            : undefined
        }
      >
        {editable && (
          <SimpleTooltip tooltip="Edit Search Terms to Track">
            <Icon name="pencil" style={{ cursor: "pointer" }} />
          </SimpleTooltip>
        )}
      </Flex>
      {editable && modalOpen && (
        <AmazonSearchTermsModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleAmazonAttributionBehaviorModalClose}
        />
      )}
    </>
  );
};

const AmazonSearchTermsModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const {
    customerId,
    campaignId,
    asin,
    variantASINs,
    marketplaceInfo,
    trackOrganicRankings,
    searchTerms: initialSearchTerms,
    historicalSearchTerms,
    actionId
  } = itemizedCampaignConfiguration;

  const [editTrackOrganicRankings, setEditTrackOrganicRankings] = useState<
    boolean
  >(trackOrganicRankings);

  const [searchTermRankInfoMap, setSearchTermRankInfoMap] = useState(
    Immutable.Map<string, GetAmazonSearchTermRankingReply.AsObject>()
  );

  useEffect(() => {
    const loadInitialSearchTerms = async (searchTerms: Array<string>) => {
      for (const searchTerm of searchTerms) {
        if (searchTerm) {
          loadSearchTermRankInfo(
            siteAlias,
            marketplaceInfo,
            asin,
            variantASINs,
            searchTerm,
            searchTermRankInfoMap,
            setSearchTermRankInfoMap
          );
        }
      }
    };
    loadInitialSearchTerms(initialSearchTerms || []);
  }, [
    initialSearchTerms,
    siteAlias,
    marketplaceInfo,
    asin,
    variantASINs,
    searchTermRankInfoMap,
    setSearchTermRankInfoMap
  ]);

  const [editSearchTerms, setEditSearchTerms] = useState(["", "", ""]);
  useEffect(() => {
    const searchTerms = [
      initialSearchTerms[0] || "",
      initialSearchTerms[1] || "",
      initialSearchTerms[2] || ""
    ];

    setEditSearchTerms(searchTerms);
    setEditTrackOrganicRankings(trackOrganicRankings);
  }, [trackOrganicRankings, initialSearchTerms]);

  const [modalOpen, setModalOpen] = useState(true);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const invalidateQueries = useInvalidateQueries();

  const handleUpdateSearchTerms = async () => {
    const newSearchTerms = editSearchTerms.filter(Boolean);

    try {
      await sendUpdateSearchTerms(
        siteAlias,
        gaCategory,
        String(customerId),
        actionId,
        newSearchTerms,
        editTrackOrganicRankings
      );

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      // If the user enables search term tracking (or changes search tems), we
      // may already have a history of the search term ranks, so let's invalidate
      // all ASIN performance metrics queries (that include the rank data) so if
      // the user goes back to the Products page, the graph will appear.
      invalidateQueries(GET_ASIN_PERFORMANCE_METRICS_QUERY_KEY_NAME);

      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);

      console.error(e); // log to datadog

      setErrorMessage(message);
    }
  };

  const handleModalClose = () => {
    setErrorMessage("");
    setUpdateEnabled(false);
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleToggleTrackOrganicRankings = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    setEditTrackOrganicRankings(!!data.checked);
    setUpdateEnabled(true);
  };

  const handleTextChange = (index: number) => (text: string) => {
    const newSearchTerms = _.clone(editSearchTerms);
    newSearchTerms[index] = text;
    setEditSearchTerms(newSearchTerms);
    setErrorMessage("");
    setUpdateEnabled(true);
  };

  const handleCheckRank = (index: number) => async () => {
    const searchTerm = editSearchTerms[index];
    if (!searchTerm) {
      return;
    }

    loadSearchTermRankInfo(
      siteAlias,
      marketplaceInfo,
      asin,
      variantASINs,
      searchTerm,
      searchTermRankInfoMap,
      setSearchTermRankInfoMap
    );
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
    >
      <Modal.Header>
        Change Search Terms to Track in Amazon Organic Search
      </Modal.Header>
      <Modal.Content scrolling>
        <Checkbox
          toggle
          checked={editTrackOrganicRankings}
          onChange={handleToggleTrackOrganicRankings}
          label="Collect organic search term performance on Amazon"
        />

        <Segment disabled={!editTrackOrganicRankings}>
          <div style={{ minHeight: 400 }}>
            <Form success={false}>
              <Form.Field required>
                <p>
                  Ampd will track the search rank on Amazon of your product for
                  these terms in our Amazon Rankings dashboard from now on.
                </p>
              </Form.Field>
              <Form.Field
                style={{ paddingTop: "1.0em", paddingBottom: "1.0em" }}
              >
                <Grid>
                  <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid.Column width="12" />
                    <Grid.Column width="2" textAlign="right">
                      <small>
                        <strong>Rank</strong>
                      </small>
                    </Grid.Column>
                    <Grid.Column width="2" textAlign="right">
                      <small>
                        <strong>Page</strong>
                      </small>
                    </Grid.Column>
                  </Grid.Row>

                  {[0, 1, 2].map(index => {
                    const rankInfo = searchTermRankInfoMap.get(
                      editSearchTerms[index]
                    );

                    return (
                      <Grid.Row
                        key={index}
                        style={{ paddingTop: "0.1em" }}
                        verticalAlign="middle"
                      >
                        <Grid.Column width="12">
                          <SearchTermDropdown
                            disabled={!editTrackOrganicRankings}
                            searchTerm={editSearchTerms[index]}
                            initialSearchTerms={initialSearchTerms}
                            historicalSearchTerms={historicalSearchTerms}
                            excludeSearchTerms={editSearchTerms}
                            onChange={handleTextChange(index)}
                          />
                        </Grid.Column>
                        {rankInfo ? (
                          <>
                            <Grid.Column width="2" textAlign="right">
                              {rankInfo.position < 0 ? (
                                <Loader active inline size="tiny" />
                              ) : rankInfo.allVariantsPosition ? (
                                `# ${rankInfo.allVariantsPosition}`
                              ) : rankInfo.maxPosition ? (
                                `> ${rankInfo.maxPosition}`
                              ) : (
                                "not found"
                              )}
                            </Grid.Column>
                            <Grid.Column width="2" textAlign="right">
                              {rankInfo.position < 0 ? (
                                <Loader active inline size="tiny" />
                              ) : rankInfo.allVariantsPage ? (
                                rankInfo.allVariantsPage
                              ) : rankInfo.maxPage ? (
                                `> ${rankInfo.maxPage}`
                              ) : (
                                "not found"
                              )}
                            </Grid.Column>
                          </>
                        ) : (
                          <>
                            <Grid.Column width="4">
                              {!!editSearchTerms[index] &&
                                editSearchTerms[index] !==
                                  initialSearchTerms[index] && (
                                  <Button
                                    type="button"
                                    color="teal"
                                    compact
                                    size="mini"
                                    onClick={handleCheckRank(index)}
                                  >
                                    Check Rank
                                  </Button>
                                )}
                            </Grid.Column>
                          </>
                        )}
                      </Grid.Row>
                    );
                  })}
                </Grid>
              </Form.Field>
            </Form>
          </div>
        </Segment>
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          primary
          onClick={handleUpdateSearchTerms}
          disabled={!updateEnabled}
        >
          Update Search Terms to Track
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

const SearchTermDropdown: React.FC<{
  searchTerm: string;
  initialSearchTerms: Array<string>;
  historicalSearchTerms: Array<string>;
  excludeSearchTerms: Array<string>;
  onChange: (text: string) => void;
  disabled?: boolean;
}> = ({
  searchTerm,
  initialSearchTerms,
  historicalSearchTerms,
  excludeSearchTerms,
  onChange,
  disabled
}) => {
  const searchTermOptions = [
    {
      key: "--NONE--",
      value: "",
      text: ""
    }
  ];

  const terms: Array<string> = [];

  initialSearchTerms.forEach(term => {
    if (
      term &&
      term !== searchTerm &&
      !terms.includes(term) &&
      !excludeSearchTerms.includes(term)
    ) {
      terms.push(term);
    }
  });
  historicalSearchTerms.forEach(term => {
    if (
      term &&
      term !== searchTerm &&
      !terms.includes(term) &&
      !excludeSearchTerms.includes(term)
    ) {
      terms.push(term);
    }
  });

  terms.forEach(term => {
    searchTermOptions.push({
      key: term,
      value: term,
      text: term
    });
  });

  const handleSearchTermChange = (value: string) => {
    if (onChange) {
      const text = scrubKeyword(value, MAX_KEYWORD_LENGTH);
      onChange(text);
    }
  };

  const handleDropdownChange = (
    _e: React.MouseEvent<HTMLDivElement>,
    { value }: DropdownItemProps
  ) => {
    handleSearchTermChange(value as string);
  };

  return (
    <Flex flexDirection="row" alignItems="center">
      <InputWithMaxUTF8Size
        disabled={disabled}
        text={searchTerm || ""}
        onTextChange={handleSearchTermChange}
        onTextAccept={handleSearchTermChange}
        hideAnnotation={true}
        inputLabel={
          <BasicDropdown
            value=""
            scrolling
            selectOnNavigation={false}
            selectOnBlur={false}
            disabled={disabled || searchTermOptions.length < 2}
          >
            <Dropdown.Menu>
              {searchTermOptions.map(option =>
                option.value === "" ? (
                  <Dropdown.Header
                    key={option.key}
                    content="Previous search terms"
                  />
                ) : (
                  <Dropdown.Item
                    {...option}
                    key={option.key}
                    onClick={handleDropdownChange}
                  />
                )
              )}
            </Dropdown.Menu>
          </BasicDropdown>
        }
        inputLabelPosition="right"
      />
    </Flex>
  );
};

async function loadSearchTermRankInfo(
  siteAlias: string,
  marketplaceInfo: MarketplaceInfo | undefined,
  asin: string,
  variantASINs: Array<string>,
  searchTerm: string,
  searchTermRankInfoMap: Immutable.Map<
    string,
    GetAmazonSearchTermRankingReply.AsObject
  >,
  setSearchTermRankInfoMap: React.Dispatch<
    React.SetStateAction<
      Immutable.Map<string, GetAmazonSearchTermRankingReply.AsObject>
    >
  >
) {
  if (!searchTerm || searchTermRankInfoMap.get(searchTerm)) {
    return;
  }

  // Using position == -1 to indicate that the rank info is still loading.
  const loadingStub = new GetAmazonSearchTermRankingReply().toObject();
  loadingStub.position = -1;
  setSearchTermRankInfoMap(map => map.set(searchTerm, loadingStub));

  try {
    const req: GetAmazonSearchTermRankingRequest = makeGetAmazonSearchTermRankingRequest(
      {
        siteAlias,
        marketplace: marketplaceInfo?.marketplace,
        asin: asin,
        searchTerm,
        variantAsins: variantASINs || []
      }
    );

    const result = await GRPCWebClient.getAmazonSearchTermRanking(req, null);

    setSearchTermRankInfoMap(map => map.set(searchTerm, result.toObject()));
  } catch (error) {
    console.error(error); // datadog
    setSearchTermRankInfoMap(map =>
      map.set(searchTerm, new GetAmazonSearchTermRankingReply().toObject())
    );
  }
}

// Updates the search terms to track for the specified ad group
async function sendUpdateSearchTerms(
  siteAlias: string,
  gaCategory: string,
  customerId: string,
  actionId: string,
  searchTerms: Array<string>,
  trackOrganicRankings: boolean
) {
  if (!siteAlias || !customerId || !actionId || !searchTerms) {
    return;
  }

  const action = new UpdateCampaignAction();

  const updateSearchTerms = new UpdateCampaignAction.UpdateAmazonSearchTermsToTrackAction();
  updateSearchTerms.setActionId(actionId);
  updateSearchTerms.setSearchTermsToTrackList(searchTerms);
  updateSearchTerms.setUpdateTrackOrganicRankings(true);
  updateSearchTerms.setTrackOrganicRankings(trackOrganicRankings);

  action.setUpdateAmazonSearchTermsToTrack(updateSearchTerms);

  sendGAEvent(
    gaCategory,
    "Update Search Terms to Track",
    siteAlias,
    searchTerms.join(", ")
  );

  const updateReq = new UpdateCampaignsRequest();
  updateReq.setSiteAlias(siteAlias);
  updateReq.setCustomerId(customerId);
  updateReq.setActionsList([action]);

  await GRPCWebClient.updateCampaigns(updateReq, null);
}

export default AmazonSearchTermsEditButton;
