import React from "react";
import { GoogleLogin } from "react-google-login";

// Component providing login with a Google Account, requesting a scope
// with Google Ads permissions.
const GoogleAdsLogin = ({
  onSuccess,
  onFailure = response => console.error(response),
  ...props
}) => (
  <GoogleLogin
    clientId={process.env.REACT_APP_GOOGLE_ADS_CLIENT_ID}
    onSuccess={onSuccess}
    onFailure={onFailure}
    scope="https://www.googleapis.com/auth/adwords"
    prompt="select_account consent"
    {...props}
  />
);

export default GoogleAdsLogin;
