import _ from "lodash";

import React, { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import { Flex } from "@rebass/grid";

import SiteSelectorModal from "../SiteSelectorModal";

function SelectSitesFromUserSitesButtons({
  currentSiteAlias,
  userSites,
  selectedSiteAliases,
  setSelectedSiteAliases
}) {
  const [siteSelectorOpen, setSiteSelectorOpen] = useState(false);

  // Only user sites with a Google Ads account are eligible.
  const eligibleUserSites = useMemo(() => {
    return (userSites || []).filter(userSite => {
      if (_.isEmpty(userSite.adwordsAccountIdsList)) {
        return false;
      }

      return true;
    });
  }, [userSites]);

  const handleSelectAccountsButton = () => {
    setSiteSelectorOpen(true);
  };

  const handleSelectAccountsSelect = siteAliases => {
    if (siteAliases) {
      setSelectedSiteAliases(siteAliases);
    }
    setSiteSelectorOpen(false);
  };

  const handleSelectAllAccountsButton = () => {
    setSelectedSiteAliases(userSites.map(userSite => userSite.siteAlias));
  };

  const handleSelectCurrentAccountOnly = () => {
    setSelectedSiteAliases([currentSiteAlias]);
  };

  return (
    <>
      <Flex
        style={{ gap: "1em" }}
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Button
          active={
            selectedSiteAliases.length === 1 &&
            selectedSiteAliases.includes(currentSiteAlias)
          }
          onClick={handleSelectCurrentAccountOnly}
        >
          Current Brand Only
        </Button>
        <Button
          active={selectedSiteAliases.length === userSites.length}
          onClick={handleSelectAllAccountsButton}
        >
          All Brands
        </Button>
        <Button
          active={
            selectedSiteAliases.length < userSites.length &&
            (selectedSiteAliases.length > 1 ||
              !selectedSiteAliases.includes(currentSiteAlias))
          }
          onClick={handleSelectAccountsButton}
        >
          Select Brands
        </Button>
      </Flex>
      <SiteSelectorModal
        open={siteSelectorOpen}
        onSelect={handleSelectAccountsSelect}
        onClose={() => setSiteSelectorOpen(false)}
        siteAliases={selectedSiteAliases}
        userSites={eligibleUserSites}
        buttonLabel=""
        disabledSiteAliases={[]}
        errorMessages={[]}
        setErrorMessages={() => {
          return;
        }}
        title=""
      />
    </>
  );
}

export default SelectSitesFromUserSitesButtons;
