import React, { useState } from "react";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  getCurrentCampaignAndSelectors,
  PausingAutomationConfig
} from "./CampaignSetupPageState";
import { Site } from "ExtensionV2/queries/useSession";
import { PausingAutomationEditor } from "ExtensionV2/components/campaignEditor/PausingAutomationEditButton";
import { Loader, Message } from "semantic-ui-react";
import {
  convertMicrosToCurrencyUnit,
  convertToMicros,
  getHumanReadableAmount
} from "Common/utils/money";
import { useHasAmpdProtectFeature } from "Common/utils/featureFlags";
import { Link } from "react-router-dom-v5-compat";

export const PausingAutomationReviewSection = ({
  site,
  state,
  dispatch,
  onEdit
}: {
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;

  const [
    campaign,
    { useSelectPausingAutomationConfig }
  ] = getCurrentCampaignAndSelectors(state);

  const { isLoading, hasError, config } = useSelectPausingAutomationConfig(
    site.siteAlias,
    campaign,
    state
  );

  let errors: Array<string> = [];
  if (hasError) {
    errors = [
      "We were unable to look up the price of this ASIN. If you know the product's price, you can configure Ampd Protection manually."
    ];
    config.enabled = false;
  }

  return (
    <ConfigurePausingAutomation
      key={`${isLoading}-${hasError}-${config.itemPrice}`}
      config={config}
      errors={errors}
      isLoading={isLoading}
      dispatch={dispatch}
      currentReviewCampaignIndex={currentReviewCampaignIndex}
      currencyCode={site.adwordsAccounts[0].currencyCode}
      onEdit={onEdit}
    />
  );
};

const ConfigurePausingAutomation = ({
  config,
  errors,
  isLoading,
  dispatch,
  currentReviewCampaignIndex,
  currencyCode,
  onEdit
}: {
  config: PausingAutomationConfig;
  errors: Array<string>;
  isLoading: boolean;
  dispatch: React.Dispatch<CampaignSetupAction>;
  currentReviewCampaignIndex: number;
  currencyCode: string;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const [tempConfig, setTempConfig] = useState(config);

  const hasAmpdProtectFeature = useHasAmpdProtectFeature();

  const setMinNumActiveKeywords = (minNumActiveKeywords: number | null) => {
    if (minNumActiveKeywords == null) {
      return;
    }
    setTempConfig({
      ...tempConfig,
      minNumActiveKeywords
    });
  };

  const setAACOSThresholdPoints = (AACOSThresholdPoints: number | null) => {
    if (!AACOSThresholdPoints) {
      return;
    }
    setTempConfig({
      ...tempConfig,
      AACOSThresholdPoints
    });
  };

  const setCostThresholdMicros = (costThreshold: number | null) => {
    if (!costThreshold) {
      return;
    }
    setTempConfig({
      ...tempConfig,
      costThresholdMicros: convertToMicros(costThreshold)
    });
  };

  const setEnabled = (enabled: boolean) => {
    setTempConfig({
      ...tempConfig,
      enabled
    });
  };

  const setReevaluatePausedKeywords = (reevaluatePausedKeywords: boolean) => {
    setTempConfig({
      ...tempConfig,
      reevaluatePausedKeywords
    });
  };

  let dataComponent = <></>;
  if (!hasAmpdProtectFeature) {
    dataComponent = (
      <div style={{ maxWidth: "40em" }}>
        <Message info>
          Ampd protection continuously analyzes keyword performance and
          automatically removes underperforming keywords from the auction to
          ensure your budget is allocated effectively.{" "}
          <Link style={{ fontWeight: 500 }} target="_blank" to={"../payment"}>
            Upgrade your Ampd plan{" "}
          </Link>{" "}
          to unlock this feature.
        </Message>
      </div>
    );
  } else if (isLoading) {
    dataComponent = (
      <div>
        <Loader active inline size="mini" />
        <p
          style={{
            display: "inline-block",
            marginLeft: "1em"
          }}
        >
          <i>Getting your Auto Pausing Config</i>
        </p>
      </div>
    );
  } else {
    dataComponent = (
      <div>
        <p>{config.enabled ? "Enabled" : "Disabled"}</p>
        {config.enabled && (
          <>
            <p>
              Cost Threshold:{" "}
              {getHumanReadableAmount(config.costThresholdMicros, currencyCode)}
            </p>
            <p>Min Keywords: {config.minNumActiveKeywords}</p>
          </>
        )}
      </div>
    );
  }

  let errorMessage = "";
  if (tempConfig.enabled && tempConfig.costThresholdMicros < 1) {
    errorMessage = "Cost Threshold must be greater than 0";
  } else if (tempConfig.enabled && tempConfig.AACOSThresholdPoints < 1) {
    errorMessage = "AACOS Threshold must be greater than 0";
  }

  let editComponent;
  if (hasAmpdProtectFeature && !isLoading) {
    editComponent = (
      <PausingAutomationEditor
        AACOSThreshold={tempConfig.AACOSThresholdPoints}
        automationEnabled={tempConfig.enabled}
        costThreshold={convertMicrosToCurrencyUnit(
          tempConfig.costThresholdMicros
        )}
        currencyCode={currencyCode}
        errorMessage={errorMessage}
        itemPrice={tempConfig.itemPrice || null}
        itemPriceLoading={false}
        minActive={tempConfig.minNumActiveKeywords}
        onChangeMinActive={setMinNumActiveKeywords}
        onChangeReevaluateKeywords={(_ev, d) =>
          setReevaluatePausedKeywords(d.checked || false)
        }
        onChangeAACOSThreshold={setAACOSThresholdPoints}
        onChangeAutomationEnabled={(_ev, d) => setEnabled(d.checked || false)}
        onChangeCostThreshold={setCostThresholdMicros}
        recommendedCostThreshold={convertMicrosToCurrencyUnit(
          tempConfig.costThresholdMicros
        )}
        reevaluateKeywords={tempConfig.reevaluatePausedKeywords}
      />
    );
  }

  const handleSave = () => {
    dispatch({
      name: "UpdatePausingAutomationConfig",
      data: {
        index: currentReviewCampaignIndex,
        pausingAutomation: {
          ...tempConfig
        }
      }
    });
  };

  const handleCancel = () => {
    setTempConfig(config);
  };

  return (
    <CampaignReviewSection
      dataName="Ampd Protection"
      dataComponent={dataComponent}
      editComponent={editComponent}
      errors={new Set(errors)}
      disableSave={!!errorMessage}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
