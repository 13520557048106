import { toUpper } from "lodash";
import { formatMetric } from "Common/utils/metrics";

export const MICROS_TO_CURRENCY_UNIT_FACTOR = 1e6;
/* Google likes to talk about money in terms of "micros" which are 1/1,000,000 of a curency unit. 
This function is useful for converting micros to a whole unit. */
export function convertMicrosToCurrencyUnit(amountInMicros) {
  if (isNaN(amountInMicros)) {
    return 0;
  }
  return amountInMicros / MICROS_TO_CURRENCY_UNIT_FACTOR;
}
export function convertToMicros(amount) {
  if (isNaN(amount)) {
    return 0;
  }
  return amount * MICROS_TO_CURRENCY_UNIT_FACTOR;
}

export function getCurrencyMetricDef(
  currencyCode,
  withCents = true,
  useAsciiCurrencyForNonUSD = false
) {
  currencyCode = toUpper(currencyCode) || "USD";

  const currencyMetricDef = {
    isCurrency: true,
    currencyCode,
    formatting: {
      postfix: "",
      sign: false,
      isPercent: false,
      currencyUseMinorUnits: withCents,
      useAsciiCurrency: useAsciiCurrencyForNonUSD
        ? currencyCode !== "USD"
        : false
    },
    nanString: " --"
  };

  return currencyMetricDef;
}

export function getHumanReadableAmount(amountInMicros, currencyCode) {
  const currencyDef = getCurrencyMetricDef(currencyCode);
  const displayAmount = formatMetric(
    currencyDef,
    convertMicrosToCurrencyUnit(amountInMicros)
  );

  return displayAmount;
}

const _currencyNames = new Intl.DisplayNames(["en"], {
  type: "currency",
  fallback: "none"
});

export function getCurrencyNameInEnglish(currencyCode) {
  if (!currencyCode) {
    return "Unspecified Currency";
  }

  try {
    return _currencyNames.of(currencyCode) || "Unknown Currency";
  } catch {
    return "Unknown Currency";
  }
}
