import _ from "lodash";

import React, { useMemo, useState } from "react";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";

import {
  extractWalmartURLInfoFromString,
  getWalmartMarketplace,
  getWalmartSearchPageURL
} from "Common/utils/walmart";
import { GetWalmartSearchPageReply } from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { MarketplaceInfo } from "Common/utils/marketplace";
import { useWalmartProfile } from "ExtensionV2/queries/useWalmartProfile";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { removeNullAndUndefined } from "Common/utils/tsUtils";
import styled from "styled-components/macro";
import { semanticFocusBorder } from "ExtensionV2/styles/colors";

const TargetDropdown = styled(Dropdown)`
  &&& {
    .menu {
      left: 0;
      right: auto;
      border-top: 1px solid ${semanticFocusBorder} !important;
      border-radius: 0.28571429rem 0 0.28571429rem 0.28571429rem;
      width: max-content !important;
      min-width: 200px;
      max-width: 50vw;
      max-height: 20rem;
      .item {
        height: 4em;
      }
      .text {
        display: inline-block;
        line-height: normal;
      }
      .description {
        font-size: smaller;
        word-break: break-all;
        margin-bottom: 3px;
        width: calc(50vw - 14em);
      }
    }
  }
`;

export const WalmartTargetSelectorDropdown: React.FC<{
  marketplaceInfo: MarketplaceInfo;
  profileName: string;
  itemId: string;
  searchPage: string;
  brands: Array<string>;
  searchData: GetWalmartSearchPageReply.AsObject | undefined;
  onSelectItemId: (itemId: string) => void;
  onSelectSearchPage: (searchPage: string) => void;
}> = ({
  marketplaceInfo,
  profileName,
  itemId,
  searchPage,
  brands,
  searchData,
  onSelectItemId,
  onSelectSearchPage
}) => {
  const { siteAlias } = useSessionSite();

  const {
    data: walmartProfileData,
    isFetching: walmartProfileIsFetching
  } = useWalmartProfile(
    siteAlias,
    getWalmartMarketplace(marketplaceInfo),
    profileName
  );

  const searchPhrase = useMemo(() => {
    const urlInfo = extractWalmartURLInfoFromString(searchPage);

    return urlInfo.searchPhrase || "";
  }, [searchPage]);

  const itemIdOptions: Array<DropdownItemProps> = useMemo(() => {
    let options: Array<DropdownItemProps> = [];

    let hasCurrent = false;
    if (searchData) {
      options = searchData.productsList.map(product => {
        if (product.itemId === itemId) {
          hasCurrent = true;
        }
        return {
          key: `${product.page}-${product.positionInPage}`,
          text: product.itemId,
          value: product.itemId,
          description: product.title
        };
      });
    } else if (walmartProfileData) {
      options = walmartProfileData.brandsList
        .flatMap(brand => {
          const items = brand.brandDetails?.itemsList;
          if (!items) {
            return undefined;
          }

          const itemById = new Map(items.map(item => [item.itemId, item]));

          return items.map(item => {
            let itemName = item.itemName;
            if (item.primaryItemId && item.variantLabel) {
              const primaryItem = itemById.get(item.primaryItemId);
              itemName = `${item.itemName ||
                primaryItem?.itemName ||
                item.itemId} (${item.variantLabel})`;
            }

            return {
              key: `${brand.brandName}-${item.itemId}`,
              text: item.itemId,
              value: item.itemId,
              description: itemName
            };
          });
        })
        .filter(removeNullAndUndefined);
    }

    if (!hasCurrent) {
      options.unshift({
        key: "added",
        text: itemId,
        value: itemId,
        description: ""
      });
    }

    if (searchPhrase) {
      options.unshift({
        key: "searchPhrase",
        text: searchPhrase ? `'${searchPhrase}'` : "CUSTOM URL",
        value: searchPhrase,
        description: ""
      });
    }

    return options;
  }, [searchData, walmartProfileData, itemId, searchPhrase]);

  const handleSelectItemIdOrText = (input: string) => {
    const text = input.trim().replace(/^['"]?([^"']+)["']?$/, "$1");

    const urlInfo = extractWalmartURLInfoFromString(text);

    if (urlInfo.itemId) {
      onSelectItemId(urlInfo.itemId);
    } else if (urlInfo.searchPhrase) {
      let newSearchPage = text;
      if (!urlInfo.effectiveURL) {
        newSearchPage =
          getWalmartSearchPageURL(
            marketplaceInfo,
            text,
            brands,
            null,
            null,
            true
          ) || "";
      }

      if (newSearchPage) {
        onSelectSearchPage(newSearchPage);
      }
    } else if (urlInfo.effectiveURL) {
      onSelectSearchPage(urlInfo.effectiveURL);
    }
  };

  return (
    <>
      <TargetDropdown
        allowAdditions
        fluid
        loading={walmartProfileIsFetching}
        onChange={(_ev: never, data: DropdownProps) =>
          handleSelectItemIdOrText(String(data.value))
        }
        options={itemIdOptions}
        placeholder={
          walmartProfileIsFetching
            ? "Looking for products"
            : "Item ID or search phrase"
        }
        noResultsMessage={
          searchData ? "No products found" : "Or enter item ID or search phrase"
        }
        additionLabel="Item ID or search phrase: "
        search={targetSearch}
        selection
        value={itemId || searchPhrase}
      />
    </>
  );
};

function targetSearch(
  options: Array<DropdownItemProps>,
  query: string
): Array<DropdownItemProps> {
  const filtered = _.filter(options, option => {
    if (!_.isNaN(Number(query))) {
      return _.toLower(String(option.text)).startsWith(query);
    }

    return _.toLower(String(option.description)).includes(
      query.trim().toLowerCase()
    );
  });

  return filtered;
}

export const WalmartBrandsDropdown: React.FC<{
  brandHint: string;
  setBrandHint?: (brand: string) => void;
  brands: Array<string>;
  brandSearchData: GetWalmartSearchPageReply.AsObject | undefined;
  brandSearchIsFetching: boolean;
  defaultPlaceholder: string;
  onSelectBrands: (brands: Array<string>) => void;
  loading?: boolean;
  upward?: boolean;
}> = ({
  brandHint,
  setBrandHint,
  brands,
  brandSearchData,
  brandSearchIsFetching,
  defaultPlaceholder,
  onSelectBrands,
  loading,
  upward
}) => {
  const [open, setOpen] = useState(false);

  const [initialBrands, setInitialBrands] = useState<typeof brands>([]);
  const [editBrands, setEditBrands] = useState<Array<string>>(brands);

  if (!_.isEqual(initialBrands, brands)) {
    setInitialBrands(brands);
    setEditBrands(brands);
  }

  const brandOptions = useMemo(() => {
    const listedBrands = [...editBrands];
    if (brandSearchData) {
      brandSearchData.facetsList.forEach(facet => {
        if (facet.name === "brand") {
          facet.valuesList.forEach(value => {
            if (!listedBrands.includes(value.name)) {
              listedBrands.push(value.name);
            }
          });
        }
      });
    }

    return listedBrands.map((brand, index) => ({
      key: index,
      text: brand,
      value: brand
    }));
  }, [brandSearchData, editBrands]);

  const handleAddBrand = (brand: string) => {
    if (brand && setBrandHint) {
      setBrandHint(brand);
    }
  };

  const handleSelectBrands = (newBrands: Array<string>) => {
    setEditBrands(newBrands);
    if (onSelectBrands && !_.isEqual(brands, newBrands)) {
      onSelectBrands(newBrands);
    }

    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  let placeholder = defaultPlaceholder;
  if (brandSearchIsFetching) {
    if (brandHint) {
      placeholder = `Looking for brand names related to: ${brandHint}`;
    } else {
      placeholder = "Searching";
    }
  }

  return (
    <>
      <Dropdown
        allowAdditions
        fluid
        loading={loading || brandSearchIsFetching}
        upward={upward}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        onLabelClick={() => setOpen(true)}
        onChange={(_ev, data) =>
          handleSelectBrands(data.value as Array<string>)
        }
        onAddItem={(_ev, data) => handleAddBrand(String(data.value).trim())}
        options={brandOptions}
        placeholder={placeholder}
        noResultsMessage={
          brandSearchIsFetching ? "Or enter brand now" : "No more brands found"
        }
        multiple
        search
        selection
        value={editBrands}
      />
    </>
  );
};

export const WalmartFacetsDropdown: React.FC<{
  facets: Array<[string, string]>;
  brandSearchData: GetWalmartSearchPageReply.AsObject | undefined;
  brandSearchIsFetching: boolean;
  onSelectFacets: (facets: Array<[string, string]>) => void;
}> = ({ facets, brandSearchData, brandSearchIsFetching, onSelectFacets }) => {
  const [open, setOpen] = useState(false);

  const [initialFacets, setInitialFacets] = useState<typeof facets>([]);
  const [tempFacetTexts, setTempFacetTexts] = useState<Array<string>>([]);

  if (!_.isEqual(initialFacets, facets)) {
    setInitialFacets(facets);
    setTempFacetTexts(facets.map(facet => `${facet[0]}: ${facet[1]}`));
  }

  const facetOptions: Array<{
    key: unknown;
    text: string;
    value: string;
  }> = useMemo(() => {
    const listedFacets = [...facets];
    if (brandSearchData) {
      brandSearchData.facetsList.forEach(facet => {
        if (facet.name !== "brand") {
          facet.valuesList.forEach(value => {
            if (!listedFacets.includes([facet.name, value.name])) {
              listedFacets.push([facet.name, value.name]);
            }
          });
        }
      });
    }

    return listedFacets.map((facet, index) => ({
      key: index,
      text: `${facet[0]}: ${facet[1]}`,
      value: `${facet[0]}: ${facet[1]}`
    }));
  }, [brandSearchData, facets]);

  const handleSelectFacets = (facets: Array<string>) => {
    setTempFacetTexts(facets);

    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);

    const newFacets: Array<[string, string]> = tempFacetTexts.map(
      facetString => {
        const sep = facetString.indexOf(": ");
        return [facetString.slice(0, sep), facetString.slice(sep + 2)];
      }
    );

    onSelectFacets(newFacets);
  };

  const placeholder = "select other search filters";

  return (
    <>
      <Dropdown
        fluid
        loading={brandSearchIsFetching}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        onLabelClick={() => setOpen(true)}
        onChange={(_ev, data) =>
          handleSelectFacets(data.value as Array<string>)
        }
        options={facetOptions}
        placeholder={placeholder}
        noResultsMessage={"No other search filters found"}
        multiple
        search
        selection
        value={tempFacetTexts}
      />
    </>
  );
};
