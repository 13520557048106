export const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const domainNameRegexp = new RegExp(
  /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.){1,128}([a-zA-Z]{2,63})$/
);

export function isValidDomain(domain) {
  if (!domain) {
    return false;
  }

  return !!domain.match(domainNameRegexp);
}

export default isValidUrl;
