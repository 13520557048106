import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";
import {
  GetProductsRequest,
  GetProductsReply,
  Product
} from "Common/proto/edge/grpcwebPb/grpcweb_Products_pb";

const getProducts = async (
  siteAlias: string,
  includeRelated: boolean,
  relationshipFilters: Array<number>
): Promise<Array<Product.AsObject>> => {
  const req = new GetProductsRequest();
  req.setSiteAlias(siteAlias);
  req.setIncludeRelated(includeRelated);
  req.setRelationshipFiltersList(relationshipFilters);

  const products: Array<Product.AsObject> = [];
  try {
    await streamProcessor(
      GRPCWebCallbackClient.getProducts(req, {}),
      (reply: GetProductsReply) => {
        const productsBatch = reply?.toObject().productsList || [];
        products.push(...productsBatch);
      }
    );
  } catch (err) {
    console.error(err);
  }

  return products;
};

export const useProducts = (
  siteAlias: string,
  includeRelated: boolean,
  relationshipFilters: Array<number>
): UseQueryResult<Array<Product.AsObject>, unknown> => {
  return useQuery({
    queryKey: ["getProducts", siteAlias, includeRelated, relationshipFilters],
    staleTime: 15 * 60 * 1_000, // 15 minutes
    enabled: !!siteAlias,
    queryFn: async () => {
      return await getProducts(siteAlias, includeRelated, relationshipFilters);
    }
  });
};
