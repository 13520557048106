import React, { useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button, Icon, Message } from "semantic-ui-react";

import { campaignDropdown } from "ExtensionV2/styles/zIndexes";
import { formatMetric } from "Common/utils/metrics";
import { GOOGLE_ADS_DAYS_PER_MONTH } from "Common/utils/googleAds";

import { getCurrencyMetricDef } from "Common/utils/money";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import OneClickButton from "../../../Common/components/OneClickButton";
import TargetVoltageMetrics from "./TargetVoltageMetrics";
import TargetVoltageSlider from "./TargetVoltageSlider";
import BiddingStrategySelector from "./BiddingStrategySelector";
import {
  roundedNumberMetricDef,
  roundedPercentageMetricDef
} from "../MetricColumns";
import BudgetDropdown from "../BudgetDropdown";
import {
  RECENT_METRICS_BIWEEKLY_DAYS,
  RECENT_METRICS_MONTHLY_DAYS,
  RECENT_METRICS_WEEKLY_DAYS,
  RecentMetricsForCampaigns
} from "../../queries/useRecentMetricsForCampaigns";
import { asPrettyDate } from "Common/utils/DateUtilities";
import { EditBidAutomationState } from "./useEditBidAutomationState";
import { MarketplaceInfo } from "Common/utils/marketplace";

export const metricsDateRangeOptions = [
  {
    key: RECENT_METRICS_WEEKLY_DAYS,
    text: "Weekly (7-day) Metric Values",
    value: RECENT_METRICS_WEEKLY_DAYS
  },
  {
    key: RECENT_METRICS_BIWEEKLY_DAYS,
    text: "Biweekly (14-day) Metric Values",
    value: RECENT_METRICS_BIWEEKLY_DAYS
  },
  {
    key: RECENT_METRICS_MONTHLY_DAYS,
    text: "Monthly (30-day) Metric Values",
    value: RECENT_METRICS_MONTHLY_DAYS
  }
];

const BidAutomationContent: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  editBidAutomationState: EditBidAutomationState;
  daysForMetrics: number;
  showAdvancedSettings: boolean;
  provideSaveAndCancelButtons: boolean;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  editBidAutomationState,
  daysForMetrics,
  showAdvancedSettings,
  provideSaveAndCancelButtons
}) => {
  const {
    campaignId,
    currencyCode,
    marketplaceInfo
  } = itemizedCampaignConfiguration;

  const {
    errorMessage,
    editBidAutomationEnabled,
    displayTargetVoltage,
    setEditTargetVoltage,
    editDailyBudget,
    setEditDailyBudget,
    setDragTargetVoltage,
    bidAutomationStatusInfo,
    bidAutomationResultsData,
    bidAutomationResultsIsLoading,
    hasAnyBidAutomationMetrics,
    recentMetricsForCampaigns,
    recentMetricsLoading,
    targetVoltageIndex,
    targetVoltageCharacteristics,
    hasUnappliedChanges,
    submitAttempts,
    isSubmitting,
    handleSubmit,
    handleCancel
  } = editBidAutomationState;

  const [budgetOpen, setBudgetOpen] = useState(false);
  const [strategyOpen, setStrategyOpen] = useState(false);

  const buttonsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hasUnappliedChanges && buttonsRef.current) {
      buttonsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }, [hasUnappliedChanges]);

  const recommendedDailyBudget =
    (targetVoltageCharacteristics?.recommendedBudget || NaN) / 7;

  const { statusColor, statusMessage } = bidAutomationStatusInfo;

  const recentMetrics = useMemo(() => {
    return getRecentMetricsDisplays(
      recentMetricsForCampaigns,
      campaignId,
      currencyCode,
      marketplaceInfo
    );
  }, [recentMetricsForCampaigns, campaignId, currencyCode, marketplaceInfo]);

  return (
    <div>
      {!!errorMessage && <Message error>{errorMessage}</Message>}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em"
        }}
      >
        {!bidAutomationResultsIsLoading && (
          <>
            {editBidAutomationEnabled && !!statusMessage && (
              <Message style={{ margin: 0 }} color={statusColor}>
                <p>{statusMessage}</p>
              </Message>
            )}
            {hasAnyBidAutomationMetrics && (
              <>
                <TargetVoltageMetrics
                  disabled={!editBidAutomationEnabled}
                  targetVoltage={displayTargetVoltage}
                  setTargetVoltage={setEditTargetVoltage}
                  targetVoltageCharacteristics={targetVoltageCharacteristics}
                  allVoltageCharacteristics={
                    bidAutomationResultsData?.voltageCharacteristicsList
                  }
                  targetVoltageIndex={targetVoltageIndex}
                  currencyCode={currencyCode}
                  marketplaceInfo={marketplaceInfo}
                  dailyBudget={editDailyBudget}
                  recommendedDailyBudget={recommendedDailyBudget}
                  setDailyBudget={(value: number) => {
                    setEditDailyBudget(value);
                    setBudgetOpen(true);
                  }}
                  daysForMetrics={daysForMetrics}
                  recentMetricsLoading={recentMetricsLoading}
                  recentMetrics={recentMetrics}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1em"
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <TargetVoltageSlider
                      disabled={!editBidAutomationEnabled}
                      targetVoltage={displayTargetVoltage}
                      setDragTargetVoltage={setDragTargetVoltage}
                      setTargetVoltage={setEditTargetVoltage}
                    />
                  </div>
                </div>
              </>
            )}
            {showAdvancedSettings ? (
              <BidAutomationAdvancedSettings
                siteAlias={siteAlias}
                gaCategory={gaCategory}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                editBidAutomationState={editBidAutomationState}
                budgetOpen={budgetOpen}
                setBudgetOpen={setBudgetOpen}
                daysForMetrics={daysForMetrics}
                recommendedDailyBudget={recommendedDailyBudget}
                strategyOpen={strategyOpen}
                setStrategyOpen={setStrategyOpen}
              />
            ) : (
              <BidAutomationBasicSettings
                siteAlias={siteAlias}
                gaCategory={gaCategory}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                editBidAutomationState={editBidAutomationState}
                recommendedDailyBudget={recommendedDailyBudget}
              />
            )}
            {provideSaveAndCancelButtons && hasUnappliedChanges && (
              <div
                ref={buttonsRef}
                style={{
                  width: "100%",
                  marginBottom: "-1em",
                  paddingBottom: "1em"
                }}
              >
                <OneClickButton
                  key={submitAttempts}
                  style={{ float: "right", marginRight: 0 }}
                  disabled={!hasUnappliedChanges}
                  compact
                  primary={true}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </OneClickButton>
                <Button
                  style={{ float: "right" }}
                  compact
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const BidAutomationAdvancedSettings: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  editBidAutomationState: EditBidAutomationState;
  budgetOpen: boolean;
  setBudgetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  daysForMetrics: number;
  recommendedDailyBudget: number;
  strategyOpen: boolean;
  setStrategyOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  editBidAutomationState,
  budgetOpen,
  setBudgetOpen,
  daysForMetrics,
  recommendedDailyBudget,
  strategyOpen,
  setStrategyOpen
}) => {
  const {
    campaignId,
    currencyCode,
    campaignBudgetId,
    campaignBudget,
    campaignBudgetIsShared,
    campaignBiddingStrategy,
    adGroupId
  } = itemizedCampaignConfiguration;

  const {
    editDailyBudget,
    setEditDailyBudget,
    biddingConfiguration,
    editBiddingStrategy,
    setEditBiddingStrategy
  } = editBidAutomationState;

  const budgetRef = useRef<HTMLDivElement>(null);
  const strategyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (budgetOpen && budgetRef.current) {
      budgetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }, [budgetOpen]);

  useEffect(() => {
    if (strategyOpen && strategyRef.current) {
      strategyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }, [strategyOpen]);

  const costCurrencyWithCentsMetricDef = getCurrencyMetricDef(
    currencyCode,
    true
  );

  const handleBudgetToggle = () => {
    setBudgetOpen(isOpen => !isOpen);
  };

  const handleStrategyToggle = () => {
    setStrategyOpen(isOpen => !isOpen);
  };

  return (
    <Accordion style={{ marginTop: "-0.5em" }}>
      <Accordion.Title
        active={budgetOpen}
        index={0}
        onClick={handleBudgetToggle}
      >
        <Icon name="dropdown" />
        Budget &mdash;{" "}
        {formatMetric(
          costCurrencyWithCentsMetricDef,
          campaignBudget * daysForMetrics
        )}{" "}
        ({formatMetric(costCurrencyWithCentsMetricDef, campaignBudget)} daily
        average,{" "}
        {formatMetric(
          costCurrencyWithCentsMetricDef,
          campaignBudget * GOOGLE_ADS_DAYS_PER_MONTH
        )}{" "}
        monthly max)
      </Accordion.Title>
      <Accordion.Content style={{ marginLeft: "1.5em" }} active={budgetOpen}>
        <div
          ref={budgetRef}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1em",
            position: "relative",
            zIndex: campaignDropdown
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <BudgetDropdown
              gaCategory={gaCategory}
              siteAlias={siteAlias}
              siteLabel="product page"
              currency={currencyCode}
              budget={editDailyBudget}
              setBudget={setEditDailyBudget}
              recommendedBudget={recommendedDailyBudget}
              disabled={!campaignBudgetId || campaignBudgetIsShared}
              title="Daily budget for this Google Ad campaign"
            />
          </div>
          <div style={{ marginTop: 0, width: "50%" }}>
            {editDailyBudget < recommendedDailyBudget && (
              <Message warning>
                <Message.Header>Note</Message.Header>
                <p>
                  All projected metric values assume you are using at least the
                  recommended budget for the current prioritization setting.
                  Ampd will not automatically change your campaign budget.
                </p>
              </Message>
            )}
          </div>
        </div>
      </Accordion.Content>

      <Accordion.Title
        active={strategyOpen}
        index={1}
        onClick={handleStrategyToggle}
      >
        <Icon name="dropdown" />
        Bidding Strategy &mdash; {campaignBiddingStrategy}
      </Accordion.Title>
      <Accordion.Content style={{ marginLeft: "1.5em" }} active={strategyOpen}>
        {strategyOpen && (
          <div
            ref={strategyRef}
            style={{ marginBottom: "-1em", paddingBottom: "1em" }}
          >
            <BiddingStrategySelector
              siteAlias={siteAlias}
              currencyCode={currencyCode}
              campaignId={campaignId}
              adGroupId={adGroupId}
              campaignBudget={campaignBudget}
              biddingConfiguration={biddingConfiguration}
              editBiddingStrategy={editBiddingStrategy}
              setEditBiddingStrategy={setEditBiddingStrategy}
            />
          </div>
        )}
      </Accordion.Content>
    </Accordion>
  );
};

const BidAutomationBasicSettings: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  editBidAutomationState: EditBidAutomationState;
  recommendedDailyBudget: number;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  editBidAutomationState,
  recommendedDailyBudget
}) => {
  const {
    currencyCode,
    campaignBudgetId,
    campaignBudgetIsShared
  } = itemizedCampaignConfiguration;

  const { editDailyBudget, setEditDailyBudget } = editBidAutomationState;

  return (
    <>
      {editDailyBudget < recommendedDailyBudget && (
        <Message warning>
          <Message.Header>Note</Message.Header>
          <p>
            All projected metric values assume you are using at least the
            recommended budget for the current prioritization setting. Ampd will
            not automatically change your campaign budget.
          </p>
        </Message>
      )}
      <div style={{ flexGrow: 1 }}>
        <BudgetDropdown
          gaCategory={gaCategory}
          siteAlias={siteAlias}
          siteLabel="product page"
          currency={currencyCode}
          budget={editDailyBudget}
          setBudget={setEditDailyBudget}
          recommendedBudget={recommendedDailyBudget}
          disabled={!campaignBudgetId || campaignBudgetIsShared}
          title="Daily budget for this Google Ad campaign"
        />
      </div>
    </>
  );
};

export type RecentMetricsDisplay = {
  startDateString: string;
  endDateString: string;
  clicksString: string;
  costString: string;
  revenueString: string;
  aacosString: string;
  bold: boolean;
};

// Returns a list of formatted display strings for the given list of campaign
// metrics from different (probably distinct, but not necessarily) time periods.
// The last one (presumably the most recent) is marked with bold==true.
function getRecentMetricsDisplays(
  recentMetricsForCampaigns: RecentMetricsForCampaigns[] | undefined,
  campaignId: number,
  currencyCode: string,
  marketplaceInfo: MarketplaceInfo
): RecentMetricsDisplay[] {
  if (!recentMetricsForCampaigns) {
    return [];
  }

  const metrics = recentMetricsForCampaigns.map(periodMetricsForCampaigns => {
    const display: RecentMetricsDisplay = {
      startDateString: asPrettyDate(periodMetricsForCampaigns.startDate),
      endDateString: asPrettyDate(periodMetricsForCampaigns.endDate),
      clicksString: "--",
      costString: "--",
      revenueString: "--",
      aacosString: "--",
      bold: false
    };

    const metrics = periodMetricsForCampaigns.campaignToMetricsMap.get(
      String(campaignId)
    );
    if (metrics) {
      const clicks = metrics?.clicks || 0;
      const cost = metrics?.cost || 0;
      const revenue = metrics?.revenue || 0;
      const brb = metrics?.brandReferralBonus || 0;

      display.clicksString = String(
        formatMetric(roundedNumberMetricDef, clicks)
      );
      const costCurrencyMetricDef = getCurrencyMetricDef(
        currencyCode,
        cost < 10
      );
      display.costString = String(formatMetric(costCurrencyMetricDef, cost));
      const revenueCurrencyMetricDef = getCurrencyMetricDef(
        marketplaceInfo.currencyCode,
        revenue < 10
      );
      display.revenueString = String(
        formatMetric(revenueCurrencyMetricDef, revenue)
      );

      if (revenue) {
        let adjustedCost = cost - brb;
        if (adjustedCost < 0) {
          adjustedCost = 0;
        }
        display.aacosString = String(
          formatMetric(roundedPercentageMetricDef, adjustedCost / revenue)
        );
      }
    }

    return display;
  });

  if (metrics.length > 0) {
    metrics[metrics.length - 1].bold = true;
  }

  return metrics;
}

export default BidAutomationContent;
