import { useEffect, useRef } from "react";

import { replaceEqualDeep } from "@tanstack/react-query";

// Returns a stable object even if a new object is literally different from, but is
// deeply equal to, the previous object.
export function useMakeQueriesStable<T>(value: T): T {
  const ref = useRef(value);
  const stable = replaceEqualDeep(ref.current, value);
  useEffect(() => {
    ref.current = stable;
  }, [stable]);
  return stable;
}

export default useMakeQueriesStable;
