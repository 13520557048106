import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  CheckboxProps,
  Dropdown,
  Form,
  Input,
  Label
} from "semantic-ui-react";

import { collectGoogleAdsTextErrors } from "Common/utils/googleAds";

import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  AdDescription,
  AdHeadline,
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";

import AdMockUp from "ExtensionV2/components/AdMockUp";
import { pluralize } from "Common/utils/strings";
import { Site } from "ExtensionV2/queries/useSession";
import {
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";
import { None } from "Common/utils/tsUtils";

const MIN_HEADLINE_COUNT = 3;
export const MAX_HEADLINE_COUNT = 15;
const MIN_DESCRIPTION_COUNT = 2;
export const MAX_DESCRIPTION_COUNT = 4;
const MAX_HEADLINE_LENGTH = 30;
const MAX_DESCRIPTION_LENGTH = 90;

// Collect errors for both the Field wrapper of headlines/descriptions and on each individual
// headline/description. This makes it simpler to display errors on each individual
// asset and on the the entire adCopy field.
const adTextValidator = (
  assets: Array<AdHeadline> | Array<AdDescription>,
  title: "Headline" | "Description",
  maxLength: number,
  minCount: number,
  maxCount: number
): Set<string> => {
  const assetFieldErrors = new Set<string>();

  for (let i = 0; i < assets.length; i += 1) {
    const assetErrors = new Set<string>();

    const asset = assets[i];
    if (!asset) {
      continue;
    }

    // check for duplicates
    if (asset.text.length > 0) {
      for (let j = 0; j < assets.length; j += 1) {
        if (i == j || !assets[j]) {
          continue;
        } else if (assets[j].text === asset.text) {
          const dupeError = `Duplicate ${title}: ${asset.text}`;
          assetFieldErrors.add(dupeError);
          assetErrors.add(dupeError);
        }
      }
    }

    const validationErrors = collectGoogleAdsTextErrors(asset.text);
    for (const error of validationErrors) {
      assetErrors.add(error);
      assetFieldErrors.add(error);
    }

    if (asset.text.length > maxLength) {
      const tooLongError = `${title} must be less than ${maxLength} characters`;
      assetErrors.add(tooLongError);
      assetFieldErrors.add(tooLongError);
    }

    asset.errors = assetErrors;
  }

  if (assets.length < minCount) {
    assetFieldErrors.add(
      `You must have at least ${pluralize(minCount, title, title + "s")}`
    );
  }

  if (assets.length > maxCount) {
    assetFieldErrors.add(
      `You can only have ${pluralize(
        maxCount,
        title,
        title + "s"
      )}, found ${pluralize(assets.length, title, title + "s")}`
    );
  }

  return assetFieldErrors;
};

const adHeadlinesValidator = (headlines: Array<AdHeadline>): Set<string> => {
  return adTextValidator(
    headlines,
    "Headline",
    MAX_HEADLINE_LENGTH,
    MIN_HEADLINE_COUNT,
    MAX_HEADLINE_COUNT
  );
};

const adDescriptionValidator = (
  descriptions: Array<AdDescription>
): Set<string> => {
  return adTextValidator(
    descriptions,
    "Description",
    MAX_DESCRIPTION_LENGTH,
    MIN_DESCRIPTION_COUNT,
    MAX_DESCRIPTION_COUNT
  );
};

export const headlinesField = (
  initialValue: Array<AdHeadline>
): FormField<Array<AdHeadline>> => ({
  _value: initialValue,
  errors: adHeadlinesValidator(initialValue),
  validator: adHeadlinesValidator
});

export const descriptionsField = (
  initialValue: Array<AdDescription>
): FormField<Array<AdDescription>> => ({
  _value: initialValue,
  errors: adDescriptionValidator(initialValue),
  validator: adDescriptionValidator
});

const MAX_RSA_HEADLINE_COUNT = 15;

// Returns some default ad descriptions for the specified MarketplaceInfo.
export const getDefaultDescriptions = (
  marketplaceInfo: MarketplaceInfo | None
): Array<AdDescription> => {
  let defaultDescriptions: Array<{
    text: string;
    _id: string;
    errors: Set<string>;
    position: number;
  }> = [];
  if (isAmazonMarketplaceInfo(marketplaceInfo)) {
    defaultDescriptions = [
      {
        text: "Free Shipping On Prime Eligible Orders",
        _id: window.crypto.randomUUID(),
        errors: new Set<string>(),
        position: 1
      },
      {
        text: "Shop High Quality Products Today On Amazon",
        _id: window.crypto.randomUUID(),
        errors: new Set<string>(),
        position: 2
      }
    ];
  } else if (isWalmartMarketplaceInfo(marketplaceInfo)) {
    defaultDescriptions = [
      {
        text: "Free Shipping with Walmart+ Membership",
        _id: window.crypto.randomUUID(),
        errors: new Set<string>(),
        position: 1
      },
      {
        text: "Shop High Quality Products Today On Walmart",
        _id: window.crypto.randomUUID(),
        errors: new Set<string>(),
        position: 2
      }
    ];
  }

  while (defaultDescriptions.length < MAX_DESCRIPTION_COUNT) {
    defaultDescriptions.push({
      text: "",
      _id: window.crypto.randomUUID(),
      errors: new Set<string>(),
      position: 0
    });
  }

  return defaultDescriptions;
};

// Returns some default ad headlines for the specified MarketplaceInfo,
// given a set of keyword phrases and some ratings information.
export const getDefaultHeadlines = (
  marketplaceInfo: MarketplaceInfo | None,
  googleKeywords: Array<string>,
  rating: number,
  ratingsTotal: number
): Array<AdHeadline> => {
  // Take the first 12 keywords and make them headlines. Ignore headlines that are too long and take
  // care to Capitalize The First Letter Of Each Word.
  const firstPositions = googleKeywords
    .filter(kw => kw.length < MAX_HEADLINE_LENGTH)
    .slice(0, 12)
    .map(kw => {
      return {
        text: kw
          .split(" ")
          .map(word => _.capitalize(word))
          .join(" "),
        position: 1,
        _id: window.crypto.randomUUID(),
        errors: new Set<string>()
      };
    });

  let defaultSecondPositionHeadlines: Array<string> = [];
  let thirdHeadline = "";
  if (isAmazonMarketplaceInfo(marketplaceInfo)) {
    defaultSecondPositionHeadlines = [
      "Shop On Amazon", // Always include this headline in position 2
      "Buy With Prime", // Always include this headline, either in position 2 or 3
      "Buy On Amazon",
      "Shop On Amazon Now",
      "Buy On Amazon Now",
      "Shop Now On Amazon",
      "View On Amazon"
    ];

    thirdHeadline = "Buy With Prime";
  } else if (isWalmartMarketplaceInfo(marketplaceInfo)) {
    defaultSecondPositionHeadlines = [
      "Shop On Walmart", // Always include this headline in position 2
      "Buy On Walmart",
      "Shop On Walmart Now",
      "Buy On Walmart Now",
      "Shop Now On Walmart",
      "View On Walmart"
    ];

    thirdHeadline = "Buy Online, Pick Up In Store";
  }

  // If the product has over 100 reviews and an average rating of 3.5 or higher, pin that info to
  // headline 3. Otherwise pin 'Buy With Prime' to the third position instead of the second.
  const highlyRatedProduct = rating >= 3.5 && ratingsTotal >= 100;
  if (highlyRatedProduct) {
    let ratingString = "";
    if (rating === 5) {
      ratingString = "5 stars";
    } else if (rating >= 4.5) {
      const roundedRating = Math.floor(rating * 10 + Number.EPSILON) / 10;
      ratingString = `${roundedRating}+ stars`;
    } else if (rating >= 4) {
      ratingString = `4+ stars`;
    } else {
      ratingString = `3.5+ stars`;
    }

    const roundedReviewCount = Math.floor(ratingsTotal / 100) * 100;
    thirdHeadline = `${roundedReviewCount.toLocaleString()}+ Reviews - ${ratingString}`;
  } else {
    defaultSecondPositionHeadlines.splice(1, 1);
  }

  // Take as many defaultSecondPositionHeadlines as we need to get to 14 headlines, leaving the 15th
  // slot open to pin headline 3.
  const secondPositions = defaultSecondPositionHeadlines
    .slice(0, MAX_RSA_HEADLINE_COUNT - firstPositions.length - 1)
    .map(defaultHeadline => {
      return {
        text: defaultHeadline,
        position: 2,
        _id: window.crypto.randomUUID(),
        errors: new Set<string>()
      };
    });

  const thirdPosition = {
    text: thirdHeadline,
    position: 3,
    _id: window.crypto.randomUUID(),
    errors: new Set<string>()
  };

  const defaultHeadlines = [
    ...firstPositions,
    ...secondPositions,
    thirdPosition
  ];

  while (defaultHeadlines.length < MAX_RSA_HEADLINE_COUNT) {
    defaultHeadlines.push({
      text: "",
      position: 0,
      _id: window.crypto.randomUUID(),
      errors: new Set<string>()
    });
  }

  return defaultHeadlines;
};

const genRandomHeadline = (
  firstHeadlines: Array<string>,
  secondHeadlines: Array<string>,
  thirdHeadlines: Array<string>
) => {
  const firstHeadline = _.sample(firstHeadlines);
  const secondHeadline = _.sample(secondHeadlines);
  const thirdHeadline = _.sample(thirdHeadlines);

  return [firstHeadline, secondHeadline, thirdHeadline]
    .filter(Boolean)
    .join(" | ");
};

const PinnedHeadlineAdMockup = ({
  pauseRef,
  headlines,
  descriptions,
  marketplaceInfo
}: {
  pauseRef: React.MutableRefObject<string | null>;
  headlines: Array<AdHeadline>;
  descriptions: Array<AdDescription>;
  marketplaceInfo: MarketplaceInfo | None;
}) => {
  const {
    firstHeadlines,
    secondHeadlines,
    thirdHeadlines,
    firstDescriptions,
    secondDescriptions
  } = useMemo(() => {
    const firstHeadlines: Array<string> = [];
    const secondHeadlines: Array<string> = [];
    const thirdHeadlines: Array<string> = [];
    const firstDescriptions: Array<string> = [];
    const secondDescriptions: Array<string> = [];

    for (const headline of headlines) {
      if (headline.text.trim() === "") {
        continue;
      }
      if (headline.position === 1) {
        firstHeadlines.push(headline.text);
      } else if (headline.position === 2) {
        secondHeadlines.push(headline.text);
      } else {
        thirdHeadlines.push(headline.text);
      }
    }

    for (const description of descriptions) {
      if (description.text.trim() === "") {
        continue;
      }
      if (description.position === 1) {
        firstDescriptions.push(description.text);
      } else {
        secondDescriptions.push(description.text);
      }
    }

    return {
      firstHeadlines,
      secondHeadlines,
      thirdHeadlines,
      firstDescriptions,
      secondDescriptions
    };
  }, [headlines, descriptions]);

  const [adCopy, setAdCopy] = useState<{
    headline: string;
    desc1: string;
    desc2: string;
  }>({
    headline: "",
    desc1: "",
    desc2: ""
  });

  // setAdCopy instantly when the headlines/descriptions change - don't wait for the setInterval
  const [{ prevHeadlines, prevDescriptions }, setPrevCopy] = useState<{
    prevHeadlines: Array<AdHeadline>;
    prevDescriptions: Array<AdDescription>;
  }>({ prevHeadlines: [], prevDescriptions: [] });

  if (headlines !== prevHeadlines || descriptions !== prevDescriptions) {
    setPrevCopy({ prevHeadlines: headlines, prevDescriptions: descriptions });

    setAdCopy({
      headline: genRandomHeadline(
        firstHeadlines,
        secondHeadlines,
        thirdHeadlines
      ),
      desc1: _.sample(firstDescriptions) || "",
      desc2: _.sample(secondDescriptions) || ""
    });
  }

  // change the adCopy every 5 seconds
  useEffect(() => {
    const headlineTimer = setInterval(() => {
      if (pauseRef.current) {
        return;
      }
      const nextHeadline = genRandomHeadline(
        firstHeadlines,
        secondHeadlines,
        thirdHeadlines
      );

      setAdCopy({
        headline: nextHeadline,
        desc1: _.sample(firstDescriptions) || "",
        desc2: _.sample(secondDescriptions) || ""
      });
    }, 5000);

    return () => {
      clearInterval(headlineTimer);
    };
  }, [
    pauseRef,
    firstHeadlines,
    secondHeadlines,
    thirdHeadlines,
    firstDescriptions,
    secondDescriptions
  ]);

  return (
    <>
      {adCopy.headline && (
        <AdMockUp
          style={{
            minHeight: 160,
            marginTop: 5,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20
          }}
          domain={marketplaceInfo?.domain}
          headline={adCopy.headline}
          description1={adCopy.desc1}
          description2={adCopy.desc2}
        />
      )}
    </>
  );
};

export const AdCopyReviewSection = ({
  pauseRef,
  dispatch,
  state,
  rating,
  ratingsTotal,
  error,
  onEdit
}: {
  pauseRef: React.MutableRefObject<string | null>;
  dispatch: React.Dispatch<CampaignSetupAction>;
  site: Site;
  state: CampaignSetupState;
  rating: number;
  ratingsTotal: number;
  error: unknown;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const [
    campaign,
    {
      selectMarketplaceInfo,
      selectHeadlines,
      selectDescriptions,
      selectGoogleKeywords
    }
  ] = getCurrentCampaignAndSelectors(state);

  const {
    customHeadlinesField: { validator: headlinesValidator },
    customDescriptionsField: { validator: descriptionsValidator },
    wantsDefaultAdCopy
  } = campaign;

  const marketplaceInfo = selectMarketplaceInfo(campaign, state);
  const googleKeywords = selectGoogleKeywords(campaign);
  const headlines = selectHeadlines(campaign, rating, ratingsTotal);
  const descriptions = selectDescriptions(campaign);

  // Make sure we have enough inputs for the user to add a the maximum number of
  // headlines/descriptions. Empty text assets are cleared out before submitting the campaign.
  const initialHeadlines = headlines.concat(
    new Array(MAX_HEADLINE_COUNT - headlines.length)
      .fill(undefined)
      .map(_h => ({
        _id: window.crypto.randomUUID(),
        text: "",
        position: 0,
        errors: new Set<string>()
      }))
  );

  const initialDescriptions = descriptions.concat(
    new Array(MAX_DESCRIPTION_COUNT - descriptions.length)
      .fill(undefined)
      .map(_d => ({
        _id: window.crypto.randomUUID(),
        text: "",
        position: 0,
        errors: new Set<string>()
      }))
  );

  const defaultHeadlines = getDefaultHeadlines(
    marketplaceInfo,
    googleKeywords.map(kw => kw.text),
    rating,
    ratingsTotal
  );

  const defaultDescriptions = getDefaultDescriptions(marketplaceInfo);

  const [tempCustomHeadlines, setTempCustomHeadlines] = useState<
    Array<AdHeadline>
  >(initialHeadlines);

  const [tempCustomDescriptions, setTempCustomDescriptions] = useState<
    Array<AdDescription>
  >(initialDescriptions);

  const [tempWantsDefaultAdCopy, setTempWantsDefaultAdCopy] = useState(
    wantsDefaultAdCopy
  );

  const headlinesToUse = tempWantsDefaultAdCopy
    ? headlines
    : tempCustomHeadlines;

  const descriptionsToUse = tempWantsDefaultAdCopy
    ? descriptions
    : tempCustomDescriptions;

  const headlineAndDescriptionErrors = useMemo(() => {
    const errors = new Set([
      ...headlinesValidator(headlinesToUse),
      ...descriptionsValidator(descriptionsToUse)
    ]);

    if (error) {
      console.error(error);
      errors.add("There was a problem loading your product details.");
    }

    return errors;
  }, [
    error,

    descriptionsValidator,
    headlinesValidator,
    descriptionsToUse,
    headlinesToUse
  ]);

  const dataComponent = (
    <div
      style={{
        width: "30em"
      }}
    >
      <PinnedHeadlineAdMockup
        pauseRef={pauseRef}
        headlines={headlinesToUse}
        descriptions={descriptionsToUse}
        marketplaceInfo={marketplaceInfo}
      />
    </div>
  );

  const editComponent = (
    <EditAdCopy
      customHeadlines={tempCustomHeadlines}
      customDescriptions={tempCustomDescriptions}
      onUpdateCustomHeadlines={setTempCustomHeadlines}
      onUpdateCustomDescriptions={setTempCustomDescriptions}
      onUpdateWantsDefaultAdCopy={val => {
        setTempWantsDefaultAdCopy(val);
      }}
      wantsDefaultAdCopy={tempWantsDefaultAdCopy}
      defaultHeadlines={defaultHeadlines}
      defaultDescriptions={defaultDescriptions}
    />
  );

  const handleSave = () => {
    if (!tempWantsDefaultAdCopy) {
      dispatch({
        name: "UpdateCustomHeadlines",
        data: {
          index: state.currentReviewCampaignIndex,
          customHeadlines: tempCustomHeadlines
        }
      });

      dispatch({
        name: "UpdateCustomDescriptions",
        data: {
          index: state.currentReviewCampaignIndex,
          customDescriptions: tempCustomDescriptions
        }
      });
    }

    dispatch({
      name: "UpdateWantsDefaultAdCopy",
      data: {
        index: state.currentReviewCampaignIndex,
        wantsDefaultCopy: tempWantsDefaultAdCopy
      }
    });
  };

  const handleCancel = () => {
    setTempCustomHeadlines(initialHeadlines);
    setTempCustomDescriptions(initialDescriptions);
    setTempWantsDefaultAdCopy(wantsDefaultAdCopy);
  };

  return (
    <CampaignReviewSection
      dataName="Ad Preview"
      dataComponent={dataComponent}
      editComponent={editComponent}
      errors={headlineAndDescriptionErrors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      disableSave={
        headlinesToUse.some(h => h.errors.size > 0) ||
        descriptionsToUse.some(d => d.errors.size > 0)
      }
    />
  );
};

export const EditAdCopy = ({
  wantsDefaultAdCopy,
  customHeadlines,
  customDescriptions,
  defaultHeadlines,
  defaultDescriptions,
  onUpdateCustomDescriptions,
  onUpdateCustomHeadlines,
  onUpdateWantsDefaultAdCopy
}: {
  wantsDefaultAdCopy: boolean;
  customHeadlines: Array<AdHeadline>;
  customDescriptions: Array<AdDescription>;
  defaultHeadlines: Array<AdHeadline>;
  defaultDescriptions: Array<AdDescription>;
  onUpdateCustomHeadlines: (headlines: Array<AdHeadline>) => void;
  onUpdateCustomDescriptions: (descriptions: Array<AdDescription>) => void;
  onUpdateWantsDefaultAdCopy: (val: boolean) => void;
}): JSX.Element => {
  const handleUpdateHeadlineText = (index: number, text: string) => {
    // update the text of the headline and clear the errors before the validations run again
    const updatedHeadlines = customHeadlines.map((headline, i) => {
      if (index === i) {
        return { ...headline, text, errors: new Set<string>() };
      }

      return headline;
    });

    onUpdateCustomHeadlines(updatedHeadlines);
  };

  const handleUpdateHeadlinePosition = (index: number, position: number) => {
    const updatedHeadlines = customHeadlines.map((headline, i) => {
      if (index === i) {
        return { ...headline, position };
      }

      return headline;
    });

    updatedHeadlines.sort((a, b) => {
      if (a.position === 0) {
        return 1;
      } else if (b.position === 0) {
        return -1;
      } else {
        return a.position - b.position;
      }
    });

    onUpdateCustomHeadlines(updatedHeadlines);
  };

  const validateHeadlines = () => {
    const validatedHeadlines = _.cloneDeep(customHeadlines);
    adHeadlinesValidator(validatedHeadlines);

    onUpdateCustomHeadlines(validatedHeadlines);
  };

  const headlinesToUse = wantsDefaultAdCopy
    ? defaultHeadlines
    : customHeadlines;

  const headlineInputs: Array<JSX.Element> = [];
  for (let i = 0; i < headlinesToUse.length; i++) {
    const headline = headlinesToUse[i];

    headlineInputs.push(
      <Form.Field
        style={{ display: "flex", gap: "0.5em", flexGrow: "1" }}
        key={headline?._id ?? i}
      >
        <div
          style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}
        >
          <Input
            disabled={wantsDefaultAdCopy}
            error={headline?.errors.size > 0}
            placeholder="Enter a Headline"
            maxLength={MAX_HEADLINE_LENGTH}
            value={headline?.text}
            onChange={(e, d) => {
              handleUpdateHeadlineText(i, d.value);
            }}
            onBlur={() => {
              validateHeadlines();
            }}
          />
          {!wantsDefaultAdCopy && (
            <p
              style={{
                fontSize: "small",
                marginLeft: "0.5em",
                fontStyle: "italic",
                alignSelf: "flex-end"
              }}
            >
              {headline?.text.length ?? 0} / {MAX_HEADLINE_LENGTH} characters
            </p>
          )}

          {headline?.text && headline?.errors.size > 0 && (
            <Label pointing="above">
              {Array.from(headline?.errors).map((error, i) => (
                <p key={`h-${i}`}>{error}</p>
              ))}
            </Label>
          )}
        </div>
        <div style={{ width: "5em" }}>
          <Dropdown
            disabled={wantsDefaultAdCopy}
            fluid
            selection
            value={headline?.position}
            key={i}
            onChange={(e, d) => {
              handleUpdateHeadlinePosition(i, Number(d.value));
            }}
            options={[
              { key: 0, text: "-", value: 0 },
              { key: 1, text: "1st", value: 1 },
              { key: 2, text: "2nd", value: 2 },
              { key: 3, text: "3rd", value: 3 }
            ]}
          />
        </div>
      </Form.Field>
    );
  }

  const handleUpdateDescription = (index: number, text: string) => {
    const updatedDescriptions = customDescriptions.map((description, i) => {
      if (index === i) {
        // For now the description in the first position is pinned to position 1, the others are
        // pinned to position 2
        return {
          ...description,
          text,
          position: index === 0 ? 1 : 2
        };
      }
      return description;
    });

    onUpdateCustomDescriptions(updatedDescriptions);
  };

  const validateDescriptions = () => {
    const validatedDescriptions = _.cloneDeep(customDescriptions);
    adDescriptionValidator(validatedDescriptions);

    onUpdateCustomDescriptions(validatedDescriptions);
  };

  const descriptionsToUse = wantsDefaultAdCopy
    ? defaultDescriptions
    : customDescriptions;

  const descriptionInputs: Array<JSX.Element> = [];
  for (let i = 0; i < descriptionsToUse.length; i++) {
    const description = descriptionsToUse[i];
    descriptionInputs.push(
      <Form.Field
        style={{ display: "flex", gap: "0.5em", flexGrow: "1" }}
        key={description?._id || i}
      >
        <div
          style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}
        >
          <Input
            disabled={wantsDefaultAdCopy}
            error={description?.errors.size > 0}
            fluid
            placeholder="Enter a Description"
            value={description?.text}
            onChange={(e, d) => {
              handleUpdateDescription(i, d.value);
            }}
            onBlur={() => {
              validateDescriptions();
            }}
          />
          {!wantsDefaultAdCopy && (
            <p
              style={{
                fontSize: "small",
                marginLeft: "0.5em",
                fontStyle: "italic",
                alignSelf: "flex-end"
              }}
            >
              {description?.text.length ?? 0} / {MAX_DESCRIPTION_LENGTH}{" "}
              characters
            </p>
          )}
          {description?.errors.size > 0 && (
            <Label pointing="above">
              {Array.from(description?.errors).map((error, i) => (
                <p key={`d-${i}`}>{error}</p>
              ))}
            </Label>
          )}
        </div>
      </Form.Field>
    );
  }

  const handleToggleDefaultAdCopy = (
    _event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    if (data.checked) {
      onUpdateWantsDefaultAdCopy(true);
    } else {
      onUpdateWantsDefaultAdCopy(false);
      // When switching over to the custom ad copy view for the first time, pre-seed all the
      // fields with the default ad copy values.
      if (!customHeadlines.some(headline => !!headline.text.trim())) {
        onUpdateCustomHeadlines(defaultHeadlines);
        onUpdateCustomDescriptions(defaultDescriptions);
      }
    }
  };

  return (
    <>
      <Checkbox
        toggle
        checked={wantsDefaultAdCopy}
        onChange={handleToggleDefaultAdCopy}
        label="Use Ampd Ad Copy (recommended)"
      />
      <h5>Ad Headlines</h5>
      <p>
        <i>
          Enter between 3 and {MAX_HEADLINE_COUNT} different headlines to be
          used in your ad. We will automatically optimize which headlines are
          used and the order in which they are displayed in order to maximize
          conversion. Optionally, you can lock a headline to a specific
          position. Headlines can be a maximum of {MAX_HEADLINE_LENGTH}{" "}
          characters
        </i>
        <i></i>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1em"
        }}
      >
        {headlineInputs}
        {/* Dummy component to the last flex row so we can evenly lay out 15 headlines in 2 columns */}
        <Form.Field
          style={{
            visibility: "hidden",
            display: "flex",
            gap: "0.5em",
            flexGrow: "1"
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}
          >
            <Input value={""} />
          </div>
          <div style={{ width: "5em" }}>
            <Dropdown />
          </div>
        </Form.Field>
      </div>
      <h5>Ad Descriptions</h5>
      <p>
        <i>
          Enter between 2 and {MAX_DESCRIPTION_COUNT} different descriptions to
          be used in your ad. Descriptions can be a maximum of{" "}
          {MAX_DESCRIPTION_LENGTH} characters
        </i>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em"
        }}
      >
        {descriptionInputs}
      </div>
    </>
  );
};
