// source: google/ads/googleads/v16/common/frequency_cap.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_enums_frequency_cap_event_type_pb = require('../../../../../google/ads/googleads/v16/enums/frequency_cap_event_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_frequency_cap_event_type_pb);
var google_ads_googleads_v16_enums_frequency_cap_level_pb = require('../../../../../google/ads/googleads/v16/enums/frequency_cap_level_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_frequency_cap_level_pb);
var google_ads_googleads_v16_enums_frequency_cap_time_unit_pb = require('../../../../../google/ads/googleads/v16/enums/frequency_cap_time_unit_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_frequency_cap_time_unit_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.common.FrequencyCapEntry', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.FrequencyCapKey', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.FrequencyCapEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.FrequencyCapEntry.displayName = 'proto.google.ads.googleads.v16.common.FrequencyCapEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.FrequencyCapKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.FrequencyCapKey.displayName = 'proto.google.ads.googleads.v16.common.FrequencyCapKey';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.FrequencyCapEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.google.ads.googleads.v16.common.FrequencyCapKey.toObject(includeInstance, f),
    cap: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.FrequencyCapEntry;
  return proto.google.ads.googleads.v16.common.FrequencyCapEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v16.common.FrequencyCapKey;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.FrequencyCapKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.FrequencyCapEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v16.common.FrequencyCapKey.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional FrequencyCapKey key = 1;
 * @return {?proto.google.ads.googleads.v16.common.FrequencyCapKey}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.getKey = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.FrequencyCapKey} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.FrequencyCapKey, 1));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.FrequencyCapKey|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} returns this
*/
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 cap = 3;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.getCap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.setCap = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapEntry} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.clearCap = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.FrequencyCapEntry.prototype.hasCap = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.FrequencyCapKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeUnit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeLength: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.FrequencyCapKey;
  return proto.google.ads.googleads.v16.common.FrequencyCapKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.FrequencyCapLevelEnum.FrequencyCapLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.FrequencyCapEventTypeEnum.FrequencyCapEventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.FrequencyCapTimeUnitEnum.FrequencyCapTimeUnit} */ (reader.readEnum());
      msg.setTimeUnit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.FrequencyCapKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.FrequencyCapKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTimeUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.FrequencyCapLevelEnum.FrequencyCapLevel level = 1;
 * @return {!proto.google.ads.googleads.v16.enums.FrequencyCapLevelEnum.FrequencyCapLevel}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.getLevel = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.FrequencyCapLevelEnum.FrequencyCapLevel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FrequencyCapLevelEnum.FrequencyCapLevel} value
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.enums.FrequencyCapEventTypeEnum.FrequencyCapEventType event_type = 3;
 * @return {!proto.google.ads.googleads.v16.enums.FrequencyCapEventTypeEnum.FrequencyCapEventType}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.getEventType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.FrequencyCapEventTypeEnum.FrequencyCapEventType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FrequencyCapEventTypeEnum.FrequencyCapEventType} value
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.ads.googleads.v16.enums.FrequencyCapTimeUnitEnum.FrequencyCapTimeUnit time_unit = 2;
 * @return {!proto.google.ads.googleads.v16.enums.FrequencyCapTimeUnitEnum.FrequencyCapTimeUnit}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.getTimeUnit = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.FrequencyCapTimeUnitEnum.FrequencyCapTimeUnit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FrequencyCapTimeUnitEnum.FrequencyCapTimeUnit} value
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.setTimeUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 time_length = 5;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.getTimeLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.setTimeLength = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.FrequencyCapKey} returns this
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.clearTimeLength = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.FrequencyCapKey.prototype.hasTimeLength = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v16.common);
