import _ from "lodash";
import Immutable from "immutable";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Icon, Popup, SemanticICONS } from "semantic-ui-react";
import { Box, Flex } from "@rebass/grid";
import styled from "styled-components/macro";
import { useQuery } from "react-apollo";
import { useSearchParams } from "react-router-dom-v5-compat";

import { SITE_USERS_QUERY } from "../../graphql";
import {
  campaignDropdown,
  headerDropdown,
  popover
} from "../../styles/zIndexes";

import AmpdDataTable from "../../components/AmpdDataTable";
import AmpdDataTableOptions from "../../components/AmpdDataTableOptions";
import {
  ALL_BUT_REMOVED_STATUS,
  COLUMN_DATA_KEYS,
  COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY,
  COLUMN_DISPLAY_NAME_FROM_DATA_KEY,
  ENABLED_STATUS,
  PAUSED_STATUS,
  SORT_ASCENDING,
  SORT_DESCENDING,
  SORT_NONE
} from "../../components/MetricColumns";

import { pluralize } from "Common/utils/strings";
import { isValidEmail } from "Common/utils/email";
import useMultiSiteAmpdCampaignConfigurations from "../../state/useMultiSiteAmpdCampaignConfigurations";
import useMultiSiteAmazonAmpdCampaignMetrics from "ExtensionV2/state/useMultiSiteAmazonAmpdCampaignMetrics";
import useMultiSiteWalmartAmpdCampaignMetrics from "ExtensionV2/state/useMultiSiteWalmartAmpdCampaignMetrics";
import useMultiSiteClientSites from "ExtensionV2/state/useMultiSiteClientSites";
import getCompareToDates from "../../state/getCompareToDates";

import AccountsTableRow from "./AccountsTableRow";
import getClientSiteRowData, {
  ClientSiteRowData
} from "./getClientSiteRowData";
import CreateNewAccountButton from "./CreateNewAccountButton";
import AccountsTableTotalRow from "./AccountsTableTotalRow";
import AccountsTableCheckedOperations from "./AccountsTableCheckedOperations";
import LinkClientAccountButton from "./LinkClientAccountButton";
import { ClientSite } from "ExtensionV2/grpc/getClientSitesForManagerSite";
import { SiteUser } from "graphql/graphql";
import { CampaignConfiguration } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import {
  AMAZON_AMPD_CAMPAIGN_TYPE,
  ANY_AMPD_CAMPAIGN_TYPE,
  WALMART_AMPD_CAMPAIGN_TYPE
} from "ExtensionV2/pages/CampaignsPage/CampaignsPage";
import { useHasWalmartUIEnabledFeature } from "Common/utils/featureFlags";
import {
  getStoredAccountsCampaignStatusOption,
  setStoredAccountsCampaignStatusOption
} from "Common/utils/savedTablePreferences";
import { None } from "Common/utils/tsUtils";

export const SITE_ALIAS_QUERY_PARAM = "client";
export const RETAILER_QUERY_PARAM = "retailer";
export const RETAILER_QUERY_PARAM_AMAZON = "amazon";
export const RETAILER_QUERY_PARAM_WALMART = "walmart";
export const RETAILER_QUERY_PARAM_ANY = "any";

export const CHECKED_COL_WIDTH = "36px";

export const CHECKED_COL = COLUMN_DATA_KEYS.checked;
export const ACCOUNT_NAME_COL = COLUMN_DATA_KEYS.clientSiteName;
export const GOOGLE_ADS_COL = COLUMN_DATA_KEYS.googleAdsCustomerId;
export const BILLING_STATUS_COL = COLUMN_DATA_KEYS.billingStatusDescription;
export const CAMPAIGNS_COL = COLUMN_DATA_KEYS.clientCampaignsDesc;
export const IMPRESSIONS_COL = COLUMN_DATA_KEYS.impressions;
export const CLICKS_COL = COLUMN_DATA_KEYS.clicks;
export const COST_COL = COLUMN_DATA_KEYS.cost;
export const AVERAGE_CPC_COL = COLUMN_DATA_KEYS.averageCpc;
export const ADD_TO_CARTS_COL = COLUMN_DATA_KEYS.carts;
export const CONVERSIONS_COL = COLUMN_DATA_KEYS.conversions;
export const DAILY_BUDGET_COL = COLUMN_DATA_KEYS.dailyBudget;
export const REVENUE_COL = COLUMN_DATA_KEYS.revenue;
export const BRAND_REFERRAL_BONUS_COL = COLUMN_DATA_KEYS.brandReferralBonus;
export const AACOS_RANGE_COL = COLUMN_DATA_KEYS.aacosRange;

export const AACOS_RANGE_MIN = "aacosRangeMin";
export const AACOS_RANGE_MAX = "aacosRangeMax";

export const ALL_ACCOUNTS_COLUMNS = [
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  BILLING_STATUS_COL,
  GOOGLE_ADS_COL,
  CAMPAIGNS_COL,
  COLUMN_DATA_KEYS.campaignStartDate,
  DAILY_BUDGET_COL,
  IMPRESSIONS_COL,
  CLICKS_COL,
  COLUMN_DATA_KEYS.clickThroughRate,
  COST_COL,
  AVERAGE_CPC_COL,
  ADD_TO_CARTS_COL,
  COLUMN_DATA_KEYS.cartRate,
  CONVERSIONS_COL,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.unitsSold,
  REVENUE_COL,
  BRAND_REFERRAL_BONUS_COL,
  COLUMN_DATA_KEYS.roas,
  COLUMN_DATA_KEYS.acos,
  AACOS_RANGE_COL
];
export const DEFAULT_ACCOUNTS_COLUMNS = [
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  GOOGLE_ADS_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  IMPRESSIONS_COL,
  CLICKS_COL,
  COST_COL,
  AVERAGE_CPC_COL,
  CONVERSIONS_COL,
  DAILY_BUDGET_COL,
  REVENUE_COL,
  BRAND_REFERRAL_BONUS_COL,
  AACOS_RANGE_COL
];
export const UNHIDEABLE_ACCOUNTS_COLUMNS = [CHECKED_COL, ACCOUNT_NAME_COL];
export const ACCOUNTS_METRIC_COLUMNS = [
  DAILY_BUDGET_COL,
  IMPRESSIONS_COL,
  CLICKS_COL,
  COST_COL,
  AVERAGE_CPC_COL,
  ADD_TO_CARTS_COL,
  CONVERSIONS_COL,
  REVENUE_COL,
  BRAND_REFERRAL_BONUS_COL,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.roas,
  COLUMN_DATA_KEYS.acos
];

const ANY_USER = "_ANY_";
const USER_UNASSIGNED = "_UNASSIGNED_";

const CHECK_STATE_OFF_ICON_NAME = "square outline";
const CHECK_STATE_ON_ICON_NAME = "check square outline";
const CHECK_STATE_PARTIAL_ICON_NAME = "minus square outline";

const FilterDropdown = styled(Form.Dropdown)`
  z-index: ${headerDropdown};
  max-width: 400px;
  & .ui.dropdown {
    .menu {
      left: 0;
      right: auto;
      border-top: 1px solid #96c8da !important;
      border-radius: 0.28571429rem 0 0.28571429rem 0.28571429rem;
      width: max-content !important;
      min-width: 200px;
      max-height: 20rem;
      .text {
        display: inline-block;
        line-height: normal;
      }
      .description {
        font-size: smaller;
        word-break: break-all;
        margin-bottom: 3px;
        line-height: normal;
      }
    }
    .menu > .item > .text,
    .menu > .item > .description {
      max-width: 21rem;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const DEFAULT_SORT_COLUMN = ACCOUNT_NAME_COL;
const DEFAULT_SORT_DIRECTIONS = {
  ...COLUMN_DEFAULT_SORT_DIRECTION_FROM_DATA_KEY,
  [CHECKED_COL]: SORT_NONE,
  [ACCOUNT_NAME_COL]: SORT_ASCENDING,
  [GOOGLE_ADS_COL]: SORT_ASCENDING,
  [BILLING_STATUS_COL]: SORT_ASCENDING,
  [CAMPAIGNS_COL]: SORT_DESCENDING,
  [AACOS_RANGE_COL]: SORT_DESCENDING
};
const FREEZE_COLUMNS_MAP = {
  [CHECKED_COL]: "0px",
  [ACCOUNT_NAME_COL]: CHECKED_COL_WIDTH
};
const COLUMN_DISPLAY_NAMES_MAP = {
  ...COLUMN_DISPLAY_NAME_FROM_DATA_KEY,
  [CHECKED_COL]: "",
  [ACCOUNT_NAME_COL]: "Client Account",
  [GOOGLE_ADS_COL]: "Google Ads",
  [BILLING_STATUS_COL]: "Ampd Billing Status",
  [AVERAGE_CPC_COL]: "Average CPC",
  [ADD_TO_CARTS_COL]: "Add To Carts",
  [DAILY_BUDGET_COL]: "Active Daily Budget",
  [AACOS_RANGE_COL]: "AACOS Range",
  [CAMPAIGNS_COL]: "Active Campaigns"
};
const COLUMN_DISPLAY_WIDTHS_MAP = {
  [CHECKED_COL]: CHECKED_COL_WIDTH,
  [ACCOUNT_NAME_COL]: "20em",
  [GOOGLE_ADS_COL]: "9em"
};
const COLUMN_DISPLAY_MIN_WIDTHS_MAP = {
  [CHECKED_COL]: CHECKED_COL_WIDTH,
  [ACCOUNT_NAME_COL]: "20em",
  [GOOGLE_ADS_COL]: "9em"
};

const TableBox = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow-y: auto;
`;

export const CampaignStatusOptions = [
  {
    key: ALL_BUT_REMOVED_STATUS,
    text: ALL_BUT_REMOVED_STATUS,
    value: ALL_BUT_REMOVED_STATUS,
    description: "Campaigns"
  },
  {
    key: ENABLED_STATUS,
    text: ENABLED_STATUS,
    value: ENABLED_STATUS,
    description: "Campaigns"
  },
  {
    key: PAUSED_STATUS,
    text: PAUSED_STATUS,
    value: PAUSED_STATUS,
    description: "Campaigns"
  }
];

export type CampaignStatusFilter =
  | typeof ALL_BUT_REMOVED_STATUS
  | typeof ENABLED_STATUS
  | typeof PAUSED_STATUS;

export const CampaignTypeOptions = [
  {
    key: AMAZON_AMPD_CAMPAIGN_TYPE,
    text: "Amazon Campaigns",
    value: AMAZON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: WALMART_AMPD_CAMPAIGN_TYPE,
    text: "Walmart Campaigns",
    value: WALMART_AMPD_CAMPAIGN_TYPE
  },
  {
    key: ANY_AMPD_CAMPAIGN_TYPE,
    text: "All Ampd Campaigns",
    value: ANY_AMPD_CAMPAIGN_TYPE
  }
];

export type CampaignTypeFilter =
  | typeof AMAZON_AMPD_CAMPAIGN_TYPE
  | typeof WALMART_AMPD_CAMPAIGN_TYPE
  | typeof ANY_AMPD_CAMPAIGN_TYPE;

export type ClientLabels = {
  managerSiteAlias: string;
  clientSiteAlias: string;
  addLabels: Array<string>;
  removeLabels: Array<string>;
};

const AccountsTable: React.FC<{
  managerSiteAlias: string;
  isSiteAdmin: boolean;
  userEmail: string;
  dateRangeStartDate: string | None;
  dateRangeEndDate: string | None;
  compareTo: string | None;
  clientSites: Array<ClientSite>;
  showFractions: boolean;
  showUnconvertedRevenue: boolean;
  showHubspotLinks: boolean;
  selectableOptionsMap: Map<string, boolean>;
  selectedColumns: Array<string>;
  handleSelectColumnOption: (columnName: string, isChecked: boolean) => void;
  handleResetTableOptions: () => void;
  handleToggleShowFraction: (isChecked: boolean) => void;
  handleToggleShowUnconvertedRevenue: (isChecked: boolean) => void;
  handleToggleExcludeAmazonLagPeriod: (isChecked: boolean) => void;
  updateClientLabels: (args: ClientLabels) => void;
  invalidateSessionQuery: () => void;
}> = ({
  managerSiteAlias,
  isSiteAdmin,
  userEmail,
  dateRangeStartDate,
  dateRangeEndDate,
  compareTo,
  clientSites,
  showFractions,
  showUnconvertedRevenue,
  showHubspotLinks,
  selectableOptionsMap,
  selectedColumns,
  handleSelectColumnOption,
  handleResetTableOptions,
  handleToggleShowFraction,
  handleToggleShowUnconvertedRevenue,
  updateClientLabels,
  invalidateSessionQuery
}) => {
  const clientSiteAliases = useMemo(
    () => (clientSites || []).map(clientSite => clientSite.clientSiteAlias),
    [clientSites]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSiteAlias = searchParams.get(SITE_ALIAS_QUERY_PARAM);
  const retailerQueryParam = searchParams.get(RETAILER_QUERY_PARAM);

  const [userLabelFilter, setUserLabelFilter] = useState("");
  const [nonUserLabelsFilter, setNonUserLabelsFilter] = useState<Array<string>>(
    []
  );

  const [campaignStatusFilter, setCampaignStatusFilter] = useState<
    CampaignStatusFilter
  >(
    (getStoredAccountsCampaignStatusOption() as CampaignStatusFilter) ||
      ALL_BUT_REMOVED_STATUS
  );
  const [campaignTypeFilter, setCampaignTypeFilter] = useState<
    CampaignTypeFilter
  >(
    retailerQueryParam === RETAILER_QUERY_PARAM_WALMART
      ? WALMART_AMPD_CAMPAIGN_TYPE
      : retailerQueryParam === RETAILER_QUERY_PARAM_ANY
      ? ANY_AMPD_CAMPAIGN_TYPE
      : AMAZON_AMPD_CAMPAIGN_TYPE
  );

  const hasWalmartUI = useHasWalmartUIEnabledFeature();

  const setSelectedSite = useCallback(
    alias => {
      if (!alias) {
        searchParams.delete(SITE_ALIAS_QUERY_PARAM);
      } else {
        searchParams.set(SITE_ALIAS_QUERY_PARAM, alias);
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const assignedSiteAliasSet = useMemo(
    () =>
      Immutable.Set(
        (clientSites || [])
          .filter(clientSite =>
            doesClientSiteSatisfyUserFilter(clientSite, userLabelFilter)
          )
          .map(clientSite => clientSite.clientSiteAlias)
      ),
    [clientSites, userLabelFilter]
  );
  const [checkedSiteAliasSet, setCheckedSiteAliasSet] = useState(
    Immutable.Set<string>()
  );

  // If there is no userLabelFilter, see if the current user has any client
  // sites assigned.  If so, filter to those sites currently assigned to the
  // user.  If not, show the full list.
  useEffect(() => {
    if (clientSites && !userLabelFilter) {
      if (
        !isSiteAdmin ||
        clientSites.filter(clientSite =>
          doesClientSiteSatisfyUserFilter(clientSite, userEmail)
        ).length > 0
      ) {
        setUserLabelFilter(userEmail);
      } else {
        setUserLabelFilter(ANY_USER);
      }
    }
  }, [isSiteAdmin, userEmail, clientSites, userLabelFilter]);

  const [sortIsAscending, setSortIsAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState<string>(DEFAULT_SORT_COLUMN);

  // Filter the list of sites down to those that are eligible for querying.
  // That is, those that have a google ads customer id and no outstanding
  // google ads error.  No point in querying the others.
  //
  // Further, filter the list to only those sites that satisfy the user label
  // filter, so the user doesn't need to wait for sites assigned to a different
  // user.
  const [siteAliasesToLoad, setSiteAliasesToLoad] = useState<Array<string>>([]);

  useEffect(() => {
    const aliases = (clientSites || [])
      .filter(
        clientSite =>
          clientSite.googleAdsCustomerId &&
          !clientSite.googleAdsErrorDescription &&
          assignedSiteAliasSet.has(clientSite.clientSiteAlias)
      )
      .map(clientSite => clientSite.clientSiteAlias)
      .sort();
    if (!_.isEqual(aliases, siteAliasesToLoad)) {
      setSiteAliasesToLoad(aliases);
    }
  }, [clientSites, siteAliasesToLoad, assignedSiteAliasSet]);

  const campaignConfigurationsResults = useMultiSiteAmpdCampaignConfigurations({
    siteAliases: siteAliasesToLoad
  });
  const campaignConfigurationsLoading = campaignConfigurationsResults.some(
    result => result.isLoading
  );

  const [
    campaignConfigurationsByCampaignIdMap,
    ampdCampaignIdsBySiteMap
  ] = useMemo(() => {
    let configationsByIdMap = Immutable.Map<string, CampaignConfiguration>();
    let allIdsBySiteMap = Immutable.Map<string, Array<string>>();
    let amazonIdsBySiteMap = Immutable.Map<string, Array<string>>();
    let walmartIdsBySiteMap = Immutable.Map<string, Array<string>>();

    if (campaignConfigurationsResults.every(result => !result.isLoading)) {
      siteAliasesToLoad.forEach((siteAlias, siteIndex) => {
        const campaignConfigurationsByCampaignId =
          campaignConfigurationsResults[siteIndex]?.data;
        if (!campaignConfigurationsByCampaignId) {
          return;
        }

        const amazonCampaignIds: Array<string> = [];
        const walmartCampaignIds: Array<string> = [];
        for (const [
          campaignId,
          ampdCampaignConfiguration
        ] of campaignConfigurationsByCampaignId) {
          if (ampdCampaignConfiguration.ampdProductDetails?.amazon) {
            amazonCampaignIds.push(campaignId);
          } else if (ampdCampaignConfiguration.ampdProductDetails?.walmart) {
            walmartCampaignIds.push(campaignId);
          }

          configationsByIdMap = configationsByIdMap.set(
            campaignId,
            ampdCampaignConfiguration
          );
        }

        amazonIdsBySiteMap = amazonIdsBySiteMap.set(
          siteAlias,
          amazonCampaignIds
        );
        walmartIdsBySiteMap = walmartIdsBySiteMap.set(
          siteAlias,
          walmartCampaignIds
        );
        allIdsBySiteMap = allIdsBySiteMap.set(
          siteAlias,
          amazonCampaignIds.concat(walmartCampaignIds)
        );
      });
    }

    return [
      configationsByIdMap,
      {
        [AMAZON_AMPD_CAMPAIGN_TYPE]: amazonIdsBySiteMap,
        [WALMART_AMPD_CAMPAIGN_TYPE]: walmartIdsBySiteMap,
        [ANY_AMPD_CAMPAIGN_TYPE]: allIdsBySiteMap
      }
    ];
  }, [siteAliasesToLoad, campaignConfigurationsResults]);

  const {
    clientSitesBySiteMap: subClientSitesByClientSiteMap
  } = useMultiSiteClientSites({
    siteAliases: clientSiteAliases
  });

  // Load metrics for Amazon Ampd campaigns
  const {
    sitesLoadingSet: amazonMetricsLoadingSet,
    campaignMetricsMap: amazonCampaignMetricsMap
  } = useMultiSiteAmazonAmpdCampaignMetrics({
    campaignIdsBySiteMap: ampdCampaignIdsBySiteMap[AMAZON_AMPD_CAMPAIGN_TYPE],
    // Don't load campaign metrics until all campaign objects are loaded.
    dateRangeStartDate:
      ampdCampaignIdsBySiteMap[AMAZON_AMPD_CAMPAIGN_TYPE].size === 0 ||
      campaignConfigurationsLoading
        ? null
        : dateRangeStartDate,
    dateRangeEndDate:
      ampdCampaignIdsBySiteMap[AMAZON_AMPD_CAMPAIGN_TYPE].size === 0 ||
      campaignConfigurationsLoading
        ? null
        : dateRangeEndDate
  });

  const [compareRangeStartDate, compareRangeEndDate] = useMemo(
    () => getCompareToDates(dateRangeStartDate, dateRangeEndDate, compareTo),
    [dateRangeStartDate, dateRangeEndDate, compareTo]
  );

  // Load any compare metrics for Amazon Ampd campaigns
  const {
    sitesLoadingSet: amazonCompareMetricsLoadingSet,
    campaignMetricsMap: amazonCampaignCompareMetricsMap
  } = useMultiSiteAmazonAmpdCampaignMetrics({
    campaignIdsBySiteMap: ampdCampaignIdsBySiteMap[AMAZON_AMPD_CAMPAIGN_TYPE],
    // Don't load compare metrics until all campaign objects and campaign metrics are loaded.
    dateRangeStartDate:
      ampdCampaignIdsBySiteMap[AMAZON_AMPD_CAMPAIGN_TYPE].size === 0 ||
      amazonCampaignMetricsMap.size === 0 ||
      amazonMetricsLoadingSet.size > 0
        ? null
        : compareRangeStartDate,
    dateRangeEndDate:
      ampdCampaignIdsBySiteMap[AMAZON_AMPD_CAMPAIGN_TYPE].size === 0 ||
      amazonCampaignMetricsMap.size === 0 ||
      amazonMetricsLoadingSet.size > 0
        ? null
        : compareRangeEndDate,
    queryGroup: "compareMetrics"
  });

  // Load metrics for Walmart Ampd campaigns
  const {
    sitesLoadingSet: walmartMetricsLoadingSet,
    campaignMetricsMap: walmartCampaignMetricsMap
  } = useMultiSiteWalmartAmpdCampaignMetrics({
    campaignIdsBySiteMap: ampdCampaignIdsBySiteMap[WALMART_AMPD_CAMPAIGN_TYPE],
    // Don't load campaign metrics until all campaign objects are loaded.
    dateRangeStartDate:
      ampdCampaignIdsBySiteMap[WALMART_AMPD_CAMPAIGN_TYPE].size === 0 ||
      campaignConfigurationsLoading
        ? null
        : dateRangeStartDate,
    dateRangeEndDate:
      ampdCampaignIdsBySiteMap[WALMART_AMPD_CAMPAIGN_TYPE].size === 0 ||
      campaignConfigurationsLoading
        ? null
        : dateRangeEndDate
  });

  // Load any compare metrics for Walmart Ampd campaigns
  const {
    sitesLoadingSet: walmartCompareMetricsLoadingSet,
    campaignMetricsMap: walmartCampaignCompareMetricsMap
  } = useMultiSiteWalmartAmpdCampaignMetrics({
    campaignIdsBySiteMap: ampdCampaignIdsBySiteMap[WALMART_AMPD_CAMPAIGN_TYPE],
    // Don't load compare metrics until all campaign objects and campaign metrics are loaded.
    dateRangeStartDate:
      ampdCampaignIdsBySiteMap[WALMART_AMPD_CAMPAIGN_TYPE].size === 0 ||
      walmartCampaignMetricsMap.size === 0 ||
      walmartMetricsLoadingSet.size > 0
        ? null
        : compareRangeStartDate,
    dateRangeEndDate:
      ampdCampaignIdsBySiteMap[WALMART_AMPD_CAMPAIGN_TYPE].size === 0 ||
      walmartCampaignMetricsMap.size === 0 ||
      walmartMetricsLoadingSet.size > 0
        ? null
        : compareRangeEndDate,
    queryGroup: "compareMetrics"
  });

  const [
    metricsLoadingSet,
    campaignMetricsMap,
    compareMetricsLoadingSet,
    campaignCompareMetricsMap
  ] = useMemo(() => {
    return [
      amazonMetricsLoadingSet.union(walmartMetricsLoadingSet),
      amazonCampaignMetricsMap.merge(walmartCampaignMetricsMap),
      amazonCompareMetricsLoadingSet.union(walmartCompareMetricsLoadingSet),
      amazonCampaignCompareMetricsMap.merge(walmartCampaignCompareMetricsMap)
    ];
  }, [
    amazonMetricsLoadingSet,
    amazonCampaignMetricsMap,
    amazonCompareMetricsLoadingSet,
    amazonCampaignCompareMetricsMap,
    walmartMetricsLoadingSet,
    walmartCampaignMetricsMap,
    walmartCompareMetricsLoadingSet,
    walmartCampaignCompareMetricsMap
  ]);

  const siteRowDataBySiteMap = useMemo(() => {
    let siteRowDataMap = Immutable.Map<string, ClientSiteRowData>();

    const campaignIdsBySiteMap = ampdCampaignIdsBySiteMap[campaignTypeFilter];

    (clientSites || []).forEach(clientSite => {
      const clientSiteAlias = clientSite.clientSiteAlias;

      const clientSiteRowData = getClientSiteRowData({
        clientSite,
        clientSiteAliases: [clientSiteAlias],
        subClientSitesByClientSiteMap,
        campaignConfigurationsByCampaignIdMap,
        campaignCompareMetricsMap,
        campaignIdsBySiteMap,
        campaignMetricsMap,
        campaignTypeFilter,
        campaignStatusFilter,
        doCompare: !!compareRangeStartDate,
        compareMetricsLoading:
          campaignIdsBySiteMap.size === 0 ||
          campaignConfigurationsLoading ||
          metricsLoadingSet.size > 0 ||
          compareMetricsLoadingSet.has(clientSiteAlias)
      });
      clientSiteRowData[CHECKED_COL] = checkedSiteAliasSet.has(clientSiteAlias)
        ? CHECK_STATE_ON_ICON_NAME
        : CHECK_STATE_OFF_ICON_NAME;

      siteRowDataMap = siteRowDataMap.set(clientSiteAlias, clientSiteRowData);
    });

    return siteRowDataMap;
  }, [
    clientSites,
    checkedSiteAliasSet,
    campaignConfigurationsLoading,
    campaignConfigurationsByCampaignIdMap,
    campaignCompareMetricsMap,
    ampdCampaignIdsBySiteMap,
    campaignTypeFilter,
    campaignStatusFilter,
    campaignMetricsMap,
    compareMetricsLoadingSet,
    compareRangeStartDate,
    metricsLoadingSet,
    subClientSitesByClientSiteMap
  ]);

  const totalRowData = useMemo(() => {
    let totalTitle = "";
    const aliasesToTotal: Array<string> = [];
    const aliasToCurrency: Record<string, string> = {};
    (clientSites || []).forEach(clientSite => {
      const clientSiteAlias = clientSite.clientSiteAlias;
      if (
        assignedSiteAliasSet.has(clientSiteAlias) &&
        (checkedSiteAliasSet.size === 0 ||
          checkedSiteAliasSet.has(clientSiteAlias))
      ) {
        aliasesToTotal.push(clientSiteAlias);
        aliasToCurrency[clientSiteAlias] = clientSite.currencyCode;
        if (clientSite.currencyCode !== "USD") {
          totalTitle = " (in US Dollars)";
        }
      }
    });

    if (checkedSiteAliasSet.size === 0) {
      totalTitle = `Total${totalTitle}`;
    } else {
      totalTitle = `${pluralize(
        checkedSiteAliasSet.size,
        "account"
      )}${totalTitle}`;
    }

    const campaignIdsBySiteMap = ampdCampaignIdsBySiteMap[campaignTypeFilter];

    const rowData = getClientSiteRowData({
      clientSiteAliases: aliasesToTotal,
      campaignConfigurationsByCampaignIdMap,
      campaignCompareMetricsMap,
      campaignIdsBySiteMap,
      campaignMetricsMap,
      campaignTypeFilter,
      campaignStatusFilter,
      doCompare: !!compareRangeStartDate,
      compareMetricsLoading:
        campaignIdsBySiteMap.size === 0 ||
        campaignConfigurationsLoading ||
        metricsLoadingSet.size > 0 ||
        compareMetricsLoadingSet.size > 0,
      aliasToCurrency
    });

    rowData[ACCOUNT_NAME_COL] = totalTitle;

    return rowData;
  }, [
    clientSites,
    checkedSiteAliasSet,
    assignedSiteAliasSet,
    campaignConfigurationsLoading,
    campaignConfigurationsByCampaignIdMap,
    campaignCompareMetricsMap,
    ampdCampaignIdsBySiteMap,
    campaignTypeFilter,
    campaignStatusFilter,
    campaignMetricsMap,
    compareMetricsLoadingSet,
    compareRangeStartDate,
    metricsLoadingSet
  ]);

  const siteUsersQueryResponse = useQuery(SITE_USERS_QUERY, {
    skip: !managerSiteAlias,
    variables: {
      siteAlias: managerSiteAlias
    }
  });

  const allLabels = useMemo(() => {
    const currentLabels = (clientSites || []).reduce(
      (labels: Immutable.Set<string>, clientSite: ClientSite) =>
        labels.union(getClientSiteLabels(clientSite)),
      Immutable.Set<string>()
    );

    const emailLabels: Array<string> = (
      siteUsersQueryResponse?.data?.site?.siteUsers || []
    ).map((siteUser: SiteUser): string => siteUser?.user?.email || "");

    const labels = currentLabels.union(nonUserLabelsFilter).union(emailLabels);

    labels.add(userEmail);

    return labels.toArray().sort();
  }, [clientSites, siteUsersQueryResponse, nonUserLabelsFilter, userEmail]);

  const userLabels = useMemo(
    () => _.filter(allLabels, label => isValidEmail(label)),
    [allLabels]
  );

  const nonUserLabels = useMemo(() => {
    const currentLabels = (clientSites || []).reduce(
      (labels, clientSite) =>
        labels.union(
          assignedSiteAliasSet.has(clientSite.clientSiteAlias)
            ? getClientSiteLabels(clientSite)
            : []
        ),
      Immutable.Set<string>()
    );

    const labels = currentLabels.union(nonUserLabelsFilter);

    return labels
      .toArray()
      .filter(label => !isValidEmail(label))
      .sort();
  }, [clientSites, nonUserLabelsFilter, assignedSiteAliasSet]);

  const userLabelOptions = useMemo(() => {
    const options: Array<{
      key: string;
      value: string;
      text: string;
      description?: string;
    }> = [
      { key: ANY_USER, value: ANY_USER, text: "<All Client Accounts>" },
      {
        key: USER_UNASSIGNED,
        value: USER_UNASSIGNED,
        text: "<Unassigned>"
      }
    ].concat(
      userLabels.map((label: string) => ({
        key: label,
        value: label,
        text: label
      }))
    );

    options.forEach(option => {
      option.description = `${pluralize(
        _.size(
          _.filter(clientSites, clientSite =>
            doesClientSiteSatisfyUserFilter(clientSite, option.value)
          )
        ),
        "account"
      )}`;
    });

    return options;
  }, [clientSites, userLabels]);

  const nonUserLabelOptions = useMemo(
    () =>
      nonUserLabels.map(label => ({
        key: label,
        value: label,
        text: label
      })),
    [nonUserLabels]
  );

  const handleUserLabelChanged = useCallback((_e, { value: label }) => {
    setUserLabelFilter(label);
    setCheckedSiteAliasSet(Immutable.Set());
  }, []);

  const handleUserLabelClose = useCallback(_e => {
    if (document.activeElement) {
      (document.activeElement as HTMLElement)?.blur();
    }
  }, []);

  const handleLabelsFilterChanged = useCallback((_e, { value: labels }) => {
    setNonUserLabelsFilter(labels);
  }, []);

  const handleCampaignStatusFilterChange = useCallback(
    (_e, { value }) => {
      setStoredAccountsCampaignStatusOption(value);

      setSearchParams(searchParams);

      setCampaignStatusFilter(value);
    },
    [searchParams, setSearchParams]
  );

  const handleCampaignTypeFilterChange = useCallback(
    (_e, { value }) => {
      if (value === AMAZON_AMPD_CAMPAIGN_TYPE) {
        searchParams.set(RETAILER_QUERY_PARAM, RETAILER_QUERY_PARAM_AMAZON);
      } else if (value === WALMART_AMPD_CAMPAIGN_TYPE) {
        searchParams.set(RETAILER_QUERY_PARAM, RETAILER_QUERY_PARAM_WALMART);
      } else {
        searchParams.set(RETAILER_QUERY_PARAM, RETAILER_QUERY_PARAM_ANY);
      }

      setSearchParams(searchParams);

      setCampaignTypeFilter(value);
    },
    [searchParams, setSearchParams]
  );

  const mapSiteRowDataToComponent = useCallback(
    (siteRowData, columns, rowIndex) => {
      const clientSiteAlias = siteRowData.clientSiteAlias;

      if (!assignedSiteAliasSet.has(clientSiteAlias)) {
        return <React.Fragment key={clientSiteAlias}></React.Fragment>;
      }

      const siteLabels = getClientSiteLabels(siteRowData);
      if (
        nonUserLabelsFilter.length > 0 &&
        nonUserLabelsFilter.filter(label => siteLabels.includes(label))
          .length === 0
      ) {
        return <React.Fragment key={clientSiteAlias}></React.Fragment>;
      }

      const siteIndex = siteAliasesToLoad.indexOf(clientSiteAlias);
      const clientSiteError = campaignConfigurationsResults[siteIndex]?.error;

      // If campaignIdsBySiteMap is still empty, but we know at least this site
      // is queryable, then we have not yet started the queries so the loading
      // sets may not be initialized.  Campaign metrics aren't loaded until all
      // campaign objects are loaded.
      const clientSiteWillLoad =
        siteAliasesToLoad.includes(clientSiteAlias) &&
        ampdCampaignIdsBySiteMap[ANY_AMPD_CAMPAIGN_TYPE].size === 0;

      const handleCheckClick = checkedSiteAliasSet.has(clientSiteAlias)
        ? () =>
            setCheckedSiteAliasSet(checkedSiteAliasSet.remove(clientSiteAlias))
        : () =>
            setCheckedSiteAliasSet(checkedSiteAliasSet.add(clientSiteAlias));

      return (
        <AccountsTableRow
          key={clientSiteAlias}
          clientSiteAlias={clientSiteAlias}
          clientSiteLoading={
            clientSiteWillLoad ||
            campaignConfigurationsResults[siteIndex]?.isLoading
          }
          clientSiteError={clientSiteError}
          siteRowData={siteRowData}
          isSelectedSite={selectedSiteAlias === clientSiteAlias}
          setSelectedSite={setSelectedSite}
          sortColumn={sortColumn}
          sortIsAscending={sortIsAscending}
          rowIndex={rowIndex}
          columns={columns}
          metricsLoading={
            clientSiteWillLoad ||
            campaignConfigurationsLoading ||
            metricsLoadingSet.has(clientSiteAlias)
          }
          campaignTypeFilter={campaignTypeFilter}
          showFractions={showFractions}
          showHubspotLinks={showHubspotLinks}
          onCheckClick={handleCheckClick}
        />
      );
    },
    [
      siteAliasesToLoad,
      ampdCampaignIdsBySiteMap,
      selectedSiteAlias,
      setSelectedSite,
      campaignConfigurationsLoading,
      campaignConfigurationsResults,
      campaignTypeFilter,
      metricsLoadingSet,
      sortColumn,
      sortIsAscending,
      showFractions,
      showHubspotLinks,
      assignedSiteAliasSet,
      checkedSiteAliasSet,
      nonUserLabelsFilter
    ]
  );

  const mapAccountTotalsToComponent = useCallback(
    (totalRowData, columns, rowIndex) => {
      return (
        <AccountsTableTotalRow
          key="accountTotals"
          clientSitesLoading={campaignConfigurationsLoading}
          metricsLoading={metricsLoadingSet.size > 0}
          totalRowData={totalRowData}
          columns={columns}
          rowIndex={rowIndex}
          showFractions={showFractions}
        />
      );
    },
    [showFractions, campaignConfigurationsLoading, metricsLoadingSet]
  );

  const handleCreateNewAccountDone = useCallback(
    newClientSiteAlias => {
      if (invalidateSessionQuery) {
        invalidateSessionQuery();
      }
      setSelectedSite(newClientSiteAlias);
    },
    [invalidateSessionQuery, setSelectedSite]
  );

  const handleLinkAccountsDone = useCallback(
    newClientSiteAliases => {
      if (invalidateSessionQuery) {
        invalidateSessionQuery();
      }
      if (newClientSiteAliases?.length > 0) {
        setSelectedSite(newClientSiteAliases[0]);
      }
    },
    [invalidateSessionQuery, setSelectedSite]
  );

  let tableCheckState: SemanticICONS = CHECK_STATE_OFF_ICON_NAME;
  if (checkedSiteAliasSet.size > 0) {
    if (checkedSiteAliasSet.size === assignedSiteAliasSet.size)
      tableCheckState = CHECK_STATE_ON_ICON_NAME;
    else {
      tableCheckState = CHECK_STATE_PARTIAL_ICON_NAME;
    }
  }

  const handleTableCheckClick = useCallback(
    e => {
      e.stopPropagation();
      if (tableCheckState === CHECK_STATE_OFF_ICON_NAME) {
        setCheckedSiteAliasSet(assignedSiteAliasSet);
      } else {
        setCheckedSiteAliasSet(Immutable.Set());
      }
    },
    [assignedSiteAliasSet, tableCheckState]
  );

  const columnDisplayNamesMap = useMemo(() => {
    return {
      ...COLUMN_DISPLAY_NAMES_MAP,
      [CHECKED_COL]: (
        <Icon
          style={{ margin: "-0.3em" }}
          size="large"
          name={tableCheckState}
          onClick={handleTableCheckClick}
        />
      )
    };
  }, [tableCheckState, handleTableCheckClick]);

  const siteLabelsByAlias = useMemo(() => getSiteLabelsByAlias(clientSites), [
    clientSites
  ]);

  return (
    <>
      {/* Buttons */}
      {checkedSiteAliasSet.size > 0 ? (
        <AccountsTableCheckedOperations
          managerSiteAlias={managerSiteAlias}
          clientSites={clientSites}
          checkedSiteAliases={checkedSiteAliasSet.toArray()}
          siteRowDataBySiteMap={siteRowDataBySiteMap}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
          siteLabelsByAlias={siteLabelsByAlias}
          userLabels={userLabels}
          nonUserLabels={nonUserLabels}
          updateClientLabels={updateClientLabels}
          onClose={() => setCheckedSiteAliasSet(Immutable.Set())}
        />
      ) : (
        <div style={{ marginBottom: "1em" }}>
          <Flex
            style={{ gap: "1em" }}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box style={{ whiteSpace: "nowrap" }}>
              <CreateNewAccountButton
                managerSiteAlias={managerSiteAlias}
                onDone={handleCreateNewAccountDone}
                initialLabels={
                  userLabelFilter !== ANY_USER &&
                  userLabelFilter !== USER_UNASSIGNED
                    ? [userLabelFilter]
                    : []
                }
              />
            </Box>

            {isSiteAdmin && (
              <Box>
                <FilterDropdown
                  style={{ zIndex: campaignDropdown }}
                  options={userLabelOptions}
                  onChange={handleUserLabelChanged}
                  onClose={handleUserLabelClose}
                  value={userLabelFilter}
                  closeOnChange={true}
                  search
                  selection
                  scrolling
                  selectOnNavigation={false}
                />
              </Box>
            )}

            <Box>
              <FilterDropdown
                style={{ zIndex: campaignDropdown, minWidth: "11em" }}
                options={nonUserLabelOptions}
                onChange={handleLabelsFilterChanged}
                value={nonUserLabelsFilter}
                placeholder="Only with labels"
                closeOnChange
                multiple
                search
                selection
                scrolling
              />
            </Box>

            {hasWalmartUI && (
              <Box>
                <FilterDropdown
                  style={{ zIndex: campaignDropdown }}
                  compact={true}
                  selection
                  options={CampaignTypeOptions}
                  value={campaignTypeFilter}
                  onChange={handleCampaignTypeFilterChange}
                />
              </Box>
            )}

            <Box>
              <FilterDropdown
                style={{ zIndex: campaignDropdown }}
                compact={true}
                selection
                options={CampaignStatusOptions}
                value={campaignStatusFilter}
                onChange={handleCampaignStatusFilterChange}
              />
            </Box>

            {isSiteAdmin && (
              <Box>
                <LinkClientAccountButton
                  managerSiteAlias={managerSiteAlias}
                  clientSites={clientSites}
                  onDone={handleLinkAccountsDone}
                  initialLabels={
                    userLabelFilter !== ANY_USER &&
                    userLabelFilter !== USER_UNASSIGNED
                      ? [userLabelFilter]
                      : []
                  }
                />
              </Box>
            )}

            <Box style={{ flexGrow: 1 }}></Box>

            <div style={{ marginLeft: "auto", display: "inline" }}>
              <Popup
                style={{ zIndex: popover }}
                trigger={<Button size="medium">Table Options</Button>}
                on="click"
                position="bottom right"
                content={
                  <AmpdDataTableOptions
                    columnOptionsMap={selectableOptionsMap}
                    columnTitles={COLUMN_DISPLAY_NAME_FROM_DATA_KEY}
                    onToggleColumn={handleSelectColumnOption}
                    onToggleShowFractions={handleToggleShowFraction}
                    onToggleShowUnconvertedRevenue={
                      handleToggleShowUnconvertedRevenue
                    }
                    onResetTableOptions={handleResetTableOptions}
                    showFractions={showFractions}
                    showUnconvertedRevenue={showUnconvertedRevenue}
                  />
                }
              />
            </div>
          </Flex>
        </div>
      )}

      <TableBox
        style={{
          height: 400,
          flexGrow: "1"
        }}
      >
        <AmpdDataTable
          columnDataNames={selectedColumns}
          freezeColumnsMap={FREEZE_COLUMNS_MAP}
          columnDisplayNamesMap={columnDisplayNamesMap}
          columnDisplayWidthsMap={COLUMN_DISPLAY_WIDTHS_MAP}
          columnDisplayMinWidthsMap={COLUMN_DISPLAY_MIN_WIDTHS_MAP}
          mapDataRowToComponent={mapSiteRowDataToComponent}
          totalData={totalRowData}
          mapTotalDataToComponent={mapAccountTotalsToComponent}
          dataRows={siteRowDataBySiteMap.valueSeq().toArray()}
          defaultSortColumn={DEFAULT_SORT_COLUMN}
          defaultSortDirections={DEFAULT_SORT_DIRECTIONS}
          onSort={(column: string, isAscending: boolean) => {
            setSortColumn(column);
            setSortIsAscending(isAscending);
          }}
          compact={undefined}
        />
      </TableBox>
    </>
  );
};

export function getClientSiteLabels(clientSite: ClientSite): Array<string> {
  return clientSite?.detailsAboutClient?.labelsList || [];
}

// Returns whether the set of client site's labels matches the specified user label filter
// by looking for a match.  The ANY_USER userLabelFilter will match all
// client sites.  The UNASSIGNED_USER userLabelFilter will match all client
// sites that don't have any labels which look like valid email addresses.
function doesClientSiteSatisfyUserFilter(
  clientSite: ClientSite,
  userLabelFilter: string
) {
  if (userLabelFilter === ANY_USER) {
    return true;
  }

  const siteLabels = getClientSiteLabels(clientSite);

  if (userLabelFilter === USER_UNASSIGNED) {
    const anyAssignment = _.find(siteLabels, label => isValidEmail(label));
    if (anyAssignment) {
      return false;
    }
  } else if (!siteLabels.includes(userLabelFilter)) {
    return false;
  }

  return true;
}

// Returns an object with clientSiteAliases as keys and arrays of labels as values.
function getSiteLabelsByAlias(clientSites: Array<ClientSite>) {
  const siteLabelsByAlias: Record<string, Array<string>> = {};

  for (const clientSite of clientSites) {
    siteLabelsByAlias[clientSite.clientSiteAlias] = getClientSiteLabels(
      clientSite
    );
  }

  return siteLabelsByAlias;
}

export default AccountsTable;
