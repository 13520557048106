import React, { createContext, useContext, useReducer } from "react";
import { PageInfo } from "Common/components/PageHeader";

// This sets up global context state/actions to be used across the app
// for reading/updating current pageInfo

export const PageInfoContext = createContext();

export const PageInfoProvider = ({ reducer, initialState, children }) => (
  <PageInfoContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </PageInfoContext.Provider>
);

export const PageInfoReducer = (state, action) => {
  switch (action.type) {
    case "updatePageInfo":
      if (!state.pageInfo.isDataEqual(action.info)) {
        return { pageInfo: PageInfo.make(action.info) };
      }
      return state;

    case "resetPageInfo":
      return { pageInfo: new PageInfo() };

    default:
      return state;
  }
};

export const usePageInfo = () => useContext(PageInfoContext);
