import _ from "lodash";
import Immutable from "immutable";
import pLimit from "p-limit";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Image,
  Message,
  Modal,
  Progress
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import styled from "styled-components/macro";
import ampd_logo from "assets/ampd-icon.svg";
import file_csv_icon from "assets/icons/file-csv-solid.svg";
import file_excel_icon from "assets/icons/file-excel-solid.svg";
import file_pdf_icon from "assets/icons/file-pdf-solid.svg";

import { compareCaseInsens } from "Common/utils/strings";

import { useApolloClient } from "@apollo/react-hooks";
import { extractErrorMessage } from "Common/errors/error";

import {
  CHECK_STATE_ON,
  CHECK_STATE_OFF,
  CHECK_STATE_PARTIAL,
  CampaignSelectorTable,
  determineCheckState
} from "./CampaignSelectorTable";
import {
  MetricSelectorTable,
  SUMMARY_REPORT_COLUMNS
} from "./MetricSelectorTable";
import SelectSitesFromUserSitesButtons from "./SelectSitesFromUserSitesButtons";
import { DateRangeSelectorTable } from "./DateRangeSelectorTable";
import { REPORT_TYPES, generateReport } from "./generateReport";
import { WantsAmpdProOperatorFeaturesContext } from "ExtensionV2";
import useMultiSiteAmpdCampaignObjects from "../../state/useMultiSiteAmpdCampaignObjects";
import { useSession } from "../../queries/useSession";
import { AmpdContentAreaModal } from "../layout/AmpdContentAreaModal";

export const REPORTING_GA_CATEGORY = "Ampd: Amazon Reporting";

// Shared limiter used for report queries so there are not too many queries from
// one user in flight at the same time.
const queryPromiseLimiter = pLimit(10);

const DropdownImage = styled(Image)`
  &&&&& {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }
`;

const emptyArray = [];

const nextCheckStateMap = {
  [CHECK_STATE_OFF]: CHECK_STATE_PARTIAL,
  [CHECK_STATE_PARTIAL]: CHECK_STATE_ON,
  [CHECK_STATE_ON]: CHECK_STATE_OFF
};

// Contents of the Reporting Pane so user can configure and generate CSV and PDF
// reports based on created Amazon product campaigns.
function Reporting({
  userSites,
  siteAliasesToReport,
  campaignIdsToReport,
  dateRangeStartDate: initialStartDate,
  dateRangeEndDate: initialEndDate,
  campaignInfoList: initialCampaignInfoList,
  campaignObjectMap: initialCampaignObjectMap,
  siteNameBySiteMap = {},
  currencyCodeBySiteMap = {},
  siteLabel,
  setCanGenerate,
  generateIndex,
  onGenerated
}) {
  const {
    currentSite,
    user: { isAmpdOperator }
  } = useSession();
  const { siteAlias } = currentSite;

  const [wantAmpdProOperatorFeatures] = useContext(
    WantsAmpdProOperatorFeaturesContext
  );
  const enableOperatorFeatures = isAmpdOperator && wantAmpdProOperatorFeatures;

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const hasDates = !!initialStartDate && !!initialEndDate;

  const [reportStartDates, setReportStartDates] = useState(
    Immutable.List(hasDates ? [initialStartDate] : [])
  );
  const [reportEndDates, setReportEndDates] = useState(
    Immutable.List(hasDates ? [initialEndDate] : [])
  );
  const [reportDateRangeNames, setReportDateRangeNames] = useState(
    Immutable.List(hasDates ? [""] : [])
  );

  const [reportSummaryColumns, setReportSummaryColumns] = useState(
    Immutable.Set(SUMMARY_REPORT_COLUMNS)
  );

  const [reportSiteAliases, setReportSiteAliases] = useState(
    siteAliasesToReport || [siteAlias]
  );
  const [reportCampaignIds, setReportCampaignIds] = useState(Immutable.Set());
  const [checkStateBySite, setCheckStateBySite] = useState(Immutable.Map());

  const [includePausedCampaigns, setIncludePausedCampaigns] = useState(false);
  const [includeEnabledKeywords, setIncludeEnabledKeywords] = useState(false);
  const [includePausedKeywords, setIncludePausedKeywords] = useState(false);

  const [progressCount, setProgressCount] = useState(0);
  const [progressIndex, setProgressIndex] = useState(0);
  const [progressLabel, setProgressLabel] = useState("");

  const apolloClient = useApolloClient();

  const handleProgressStart = useCallback(siteAliases => {
    setProgressLabel("Loading campaigns");
    setProgressCount(siteAliases?.length);
    setProgressIndex(0);
  }, []);

  const handleProgressAdvance = useCallback(() => {
    setProgressIndex(counter => counter + 1);
  }, []);

  const handleProgressFinish = useCallback(() => {
    setProgressLabel("");
    setProgressCount(0);
    setProgressIndex(0);
  }, []);

  const loadCampaigns = !initialCampaignInfoList || !initialCampaignObjectMap;
  const {
    campaignInfoList: loadedCampaignInfoList,
    campaignObjectMap: loadedCampaignObjectMap
  } = useMultiSiteAmpdCampaignObjects({
    siteAliases: loadCampaigns ? reportSiteAliases : emptyArray,
    onStart: handleProgressStart,
    onLoad: handleProgressAdvance,
    onFinish: handleProgressFinish
  });

  const campaignInfoList = loadCampaigns
    ? loadedCampaignInfoList
    : initialCampaignInfoList;
  const campaignObjectMap = loadCampaigns
    ? loadedCampaignObjectMap
    : initialCampaignObjectMap;

  const sortedCampaignInfos = useMemo(() => {
    const {
      filteredCampaignInfos,
      newReportCampaignIds,
      newCheckStateBySite
    } = filterCampaignInfos({
      campaignIdsToReport,
      campaignInfoList,
      campaignObjectMap,
      includePausedCampaigns,
      checkStateBySite,
      reportSiteAliases,
      reportCampaignIds,
      siteNameBySiteMap,
      currencyCodeBySiteMap
    });

    if (!reportCampaignIds.equals(newReportCampaignIds)) {
      setReportCampaignIds(newReportCampaignIds);
    }
    if (!checkStateBySite.equals(newCheckStateBySite)) {
      setCheckStateBySite(newCheckStateBySite);
    }

    return filteredCampaignInfos.sort(
      (a, b) =>
        compareCaseInsens(a.siteName, b.siteName) ||
        compareCaseInsens(a.campaign?.status, b.campaign?.status) ||
        compareCaseInsens(a.campaign?.name, b.campaign?.name)
    );
  }, [
    campaignObjectMap,
    campaignInfoList,
    includePausedCampaigns,
    checkStateBySite,
    reportSiteAliases,
    reportCampaignIds,
    siteNameBySiteMap,
    campaignIdsToReport,
    currencyCodeBySiteMap
  ]);

  useEffect(() => {
    const selected = sortedCampaignInfos.filter(campaignInfo =>
      reportCampaignIds.has(campaignInfo.campaignId)
    );

    setSelectedCampaignInfos(selected);
    if (setCanGenerate) {
      setCanGenerate(selected.length > 0);
    }
  }, [sortedCampaignInfos, reportCampaignIds, setCanGenerate]);

  const [selectedCampaignInfos, setSelectedCampaignInfos] = useState([]);

  const [reportType, setReportType] = useState(REPORT_TYPES.CAMPAIGN_CSV);

  useEffect(() => {
    setSuccessMessage(null);
  }, [reportStartDates, reportEndDates]);

  const handleGenerateAndDownloadReport = useCallback(async () => {
    setSuccessMessage(null);
    setErrorMessage(null);
    if (_.isEmpty(reportSiteAliases) || !reportType) {
      return;
    }

    try {
      await generateReport(
        apolloClient,
        queryPromiseLimiter,
        reportSiteAliases,
        reportType,
        reportStartDates.toArray(),
        reportEndDates.toArray(),
        reportDateRangeNames.toArray(),
        reportSummaryColumns.toArray(),
        selectedCampaignInfos,
        includeEnabledKeywords,
        includePausedKeywords,
        setProgressCount,
        setProgressIndex,
        setProgressLabel
      );

      setProgressCount(0);
      setProgressIndex(0);
      setProgressLabel("");
      setSuccessMessage("Downloaded");

      if (onGenerated) {
        onGenerated();
      }
    } catch (e) {
      const message =
        extractErrorMessage(e, {
          defaultGraphQLMessage: "Server error generating report"
        }) || "Error generating report";

      console.error(message); // log to datadog

      setProgressCount(0);
      setProgressIndex(0);
      setProgressLabel("");
      setErrorMessage(message);
    }
  }, [
    apolloClient,
    includeEnabledKeywords,
    includePausedKeywords,
    onGenerated,
    reportDateRangeNames,
    reportEndDates,
    reportSiteAliases,
    reportStartDates,
    reportSummaryColumns,
    reportType,
    selectedCampaignInfos
  ]);

  const [generateCount, setGenerateCount] = useState(0);

  useEffect(() => {
    if (generateIndex && generateIndex > generateCount) {
      handleGenerateAndDownloadReport();
      setGenerateCount(generateIndex);
    }
  }, [generateIndex, generateCount, handleGenerateAndDownloadReport]);

  const handleIncludePausedCampaigns = (e, { checked }) => {
    setSuccessMessage(null);
    let newReportCampaignIds = Immutable.Set();

    sortedCampaignInfos.forEach(campaignInfo => {
      const campaignId = campaignInfo.campaignId;
      if (campaignInfo.campaign?.status === "PAUSED") {
        if (checked) {
          newReportCampaignIds = newReportCampaignIds.add(campaignId);
        }
      } else if (reportCampaignIds.get(campaignId)) {
        newReportCampaignIds = newReportCampaignIds.add(campaignId);
      }
    });

    if (!reportCampaignIds.equals(newReportCampaignIds)) {
      setReportCampaignIds(newReportCampaignIds);
    }

    setIncludePausedCampaigns(checked);
  };

  const handleIncludeEnabledKeywords = (e, { checked }) => {
    setSuccessMessage(null);
    setIncludeEnabledKeywords(checked);
  };

  const handleIncludePausedKeywords = (e, { checked }) => {
    setSuccessMessage(null);
    setIncludePausedKeywords(checked);
  };

  const handleToggleSite = useCallback(
    siteAlias => {
      setSuccessMessage(null);
      const checkState = checkStateBySite.get(siteAlias, 0);
      const newCheckState = nextCheckStateMap[checkState];

      let newReportCampaignIds = Immutable.Set();
      let addedCount = 0;

      sortedCampaignInfos.forEach(campaignInfo => {
        const campaignId = campaignInfo.campaignId;
        if (campaignInfo.siteAlias !== siteAlias) {
          if (reportCampaignIds.get(campaignId)) {
            newReportCampaignIds = newReportCampaignIds.add(campaignId);
          }
        } else if (newCheckState === CHECK_STATE_ON) {
          newReportCampaignIds = newReportCampaignIds.add(campaignId);
        } else if (newCheckState === CHECK_STATE_PARTIAL) {
          if (
            includePausedCampaigns ||
            campaignInfo.campaign?.status === "ENABLED"
          ) {
            newReportCampaignIds = newReportCampaignIds.add(campaignId);
            addedCount += 1;
          }
        }
      });

      // If we are going for a mixed state, but nothing got selected, let's
      // do full selection (of the site) instead.
      if (newCheckState === CHECK_STATE_PARTIAL && addedCount === 0) {
        sortedCampaignInfos.forEach(campaignInfo => {
          const campaignId = campaignInfo.campaignId;
          if (campaignInfo.siteAlias === siteAlias) {
            newReportCampaignIds = newReportCampaignIds.add(campaignId);
          }
        });
      }

      if (!reportCampaignIds.equals(newReportCampaignIds)) {
        setReportCampaignIds(newReportCampaignIds);
      }
    },
    [
      reportCampaignIds,
      checkStateBySite,
      sortedCampaignInfos,
      includePausedCampaigns
    ]
  );

  const handleToggleCampaign = useCallback(
    campaignId => {
      setSuccessMessage(null);
      if (reportCampaignIds.has(campaignId)) {
        setReportCampaignIds(reportCampaignIds.remove(campaignId));
      } else {
        setReportCampaignIds(reportCampaignIds.add(campaignId));
      }
    },
    [reportCampaignIds]
  );

  const handleToggleColumn = col => {
    setSuccessMessage(null);
    if (reportSummaryColumns.has(col)) {
      setReportSummaryColumns(reportSummaryColumns.remove(col));
    } else {
      setReportSummaryColumns(reportSummaryColumns.add(col));
    }
  };

  const handleReportTypeChange = (e, { value }) => {
    setSuccessMessage(null);
    setReportType(value);
  };

  const reportTypeOptions = useMemo(() => {
    const options = [
      {
        key: REPORT_TYPES.CAMPAIGN_CSV,
        value: REPORT_TYPES.CAMPAIGN_CSV,
        text: "Daily Campaign Metrics as CSV",
        image: <DropdownImage src={file_csv_icon} alt="csv file" inline />
      },
      {
        key: REPORT_TYPES.KEYWORD_CSV,
        value: REPORT_TYPES.KEYWORD_CSV,
        text: "Daily Keyword Metrics as CSV",
        image: <DropdownImage src={file_csv_icon} alt="csv file" inline />
      },
      {
        key: REPORT_TYPES.EXCEL,
        value: REPORT_TYPES.EXCEL,
        text: "Multi-sheet Excel Report",
        image: <DropdownImage src={file_excel_icon} alt="excel file" inline />
      }
    ];

    if (enableOperatorFeatures) {
      options.push({
        key: REPORT_TYPES.PDF,
        value: REPORT_TYPES.PDF,
        text: "PDF Summary Report",
        image: (
          <DropdownImage
            style={{ width: 20, height: 20, marginTop: 0 }}
            src={file_pdf_icon}
            alt="pdf file"
            inline
          />
        )
      });
    }
    if (enableOperatorFeatures) {
      options.push({
        key: REPORT_TYPES.AMPD_PRO_OP_CAMPAIGN_IMPRESSIONS_SHARE_CSV,
        value: REPORT_TYPES.AMPD_PRO_OP_CAMPAIGN_IMPRESSIONS_SHARE_CSV,
        text: "Campaign Impression Shares",
        image: (
          <Image.Group style={{ display: "inline" }}>
            <DropdownImage src={file_csv_icon} alt="csv file" inline />
            <DropdownImage src={ampd_logo} alt="Ampd Logo" inline />
          </Image.Group>
        )
      });
      options.push({
        key: REPORT_TYPES.AMPD_PRO_OP_KEYWORD_IMPRESSIONS_SHARE_CSV,
        value: REPORT_TYPES.AMPD_PRO_OP_KEYWORD_IMPRESSIONS_SHARE_CSV,
        text: "Keyword Impression Shares",
        image: (
          <Image.Group style={{ display: "inline" }}>
            <DropdownImage src={file_csv_icon} alt="csv file" inline />
            <DropdownImage src={ampd_logo} alt="Ampd Logo" inline />
          </Image.Group>
        )
      });
    }

    return options;
  }, [enableOperatorFeatures]);

  return (
    <div>
      <AmpdContentAreaModal open={progressCount > 0} closeable={false}>
        <Modal.Content>
          <Progress
            style={{ width: "75vw" }}
            total={progressCount}
            value={progressIndex + 1}
            active
          >
            {progressLabel}
          </Progress>
        </Modal.Content>
      </AmpdContentAreaModal>
      <Flex
        flexDirection="column"
        style={{
          gap: "1em"
        }}
      >
        {!!errorMessage && <Message error>{errorMessage}</Message>}
        {!!successMessage && <Message success>{successMessage}</Message>}
        <Form>
          <Form.Field>
            <label>Report Type:</label>
            <Dropdown
              style={{ width: "100%" }}
              selection
              options={reportTypeOptions}
              value={reportType}
              onChange={handleReportTypeChange}
            />
          </Form.Field>
        </Form>
        <DateRangeSelectorTable
          initialEndDate={initialEndDate}
          reportStartDates={reportStartDates}
          setReportStartDates={setReportStartDates}
          reportEndDates={reportEndDates}
          setReportEndDates={setReportEndDates}
          reportDateRangeNames={reportDateRangeNames}
          setReportDateRangeNames={setReportDateRangeNames}
        />
        <Flex
          style={{ marginLeft: "1em", marginRight: "1em", gap: "1em" }}
          alignItems="center"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <Checkbox
            label="Include paused campaigns"
            checked={includePausedCampaigns}
            onChange={handleIncludePausedCampaigns}
          />
          {[REPORT_TYPES.PDF, REPORT_TYPES.EXCEL].includes(reportType) && (
            <Checkbox
              label="Include keywords metrics"
              checked={includeEnabledKeywords}
              onChange={handleIncludeEnabledKeywords}
            />
          )}
          {[
            REPORT_TYPES.KEYWORD_CSV,
            REPORT_TYPES.PDF,
            REPORT_TYPES.EXCEL,
            REPORT_TYPES.AMPD_PRO_OP_KEYWORD_IMPRESSIONS_SHARE_CSV
          ].includes(reportType) && (
            <Checkbox
              label="Include paused keywords"
              checked={includePausedKeywords}
              onChange={handleIncludePausedKeywords}
            />
          )}
        </Flex>
        <CampaignSelectorTable
          siteAlias={siteAlias}
          sortedCampaignInfos={sortedCampaignInfos}
          reportCampaignIds={reportCampaignIds}
          checkStateBySite={checkStateBySite}
          siteLabel={siteLabel}
          onToggleSite={handleToggleSite}
          onToggleCampaign={handleToggleCampaign}
        />
        {userSites && (
          <SelectSitesFromUserSitesButtons
            currentSiteAlias={siteAlias}
            userSites={userSites}
            selectedSiteAliases={reportSiteAliases}
            setSelectedSiteAliases={setReportSiteAliases}
          />
        )}
        {[REPORT_TYPES.PDF, REPORT_TYPES.EXCEL].includes(reportType) && (
          <MetricSelectorTable
            reportColumns={reportSummaryColumns}
            onToggleColumn={handleToggleColumn}
          />
        )}
        {generateIndex == null && (
          <Flex width="100%" flexDirection="row" justifyContent="flex-end">
            <Button
              positive
              style={{ width: "25em" }}
              disabled={selectedCampaignInfos.length === 0}
              onClick={() => handleGenerateAndDownloadReport()}
            >
              Generate and Download
            </Button>
          </Flex>
        )}
      </Flex>
    </div>
  );
}

// Filters the campaignInfoList and determines the new state of the reportCampaignIds
// Set and the checkStateBySite Map.  A campaignInfo is filtered out of the
// list if it does not belong to one of the reportSiteAliases or if it's campaign
// is not found in the campaignObjectMap.  Otherwise, the campaignInfo is
// enhanced with further fields needed for report generation, namely
// 'campaign', 'siteName', 'costCurrencyCode', and 'revenueCurrencyCode'.
function filterCampaignInfos({
  campaignIdsToReport,
  campaignInfoList,
  campaignObjectMap,
  includePausedCampaigns,
  checkStateBySite,
  reportSiteAliases,
  reportCampaignIds,
  siteNameBySiteMap,
  currencyCodeBySiteMap
}) {
  let newReportCampaignIds = Immutable.Set();
  let newCheckStateBySite = Immutable.Map();
  const filterToSiteAliases = Immutable.Set(reportSiteAliases);

  const filteredCampaignInfos = campaignInfoList
    .map(campaignInfo => {
      const { campaignId, siteAlias } = campaignInfo;

      if (!filterToSiteAliases.has(siteAlias)) {
        return null;
      }

      if (
        campaignId &&
        campaignIdsToReport &&
        !campaignIdsToReport.includes(String(campaignId))
      ) {
        return null;
      }

      let campaign = null;
      if (campaignId) {
        campaign = campaignObjectMap.get(String(campaignId));
        if (!campaign) {
          return null;
        }
      }

      if (campaign) {
        let newCheckState = newCheckStateBySite.get(siteAlias, null);

        if (checkStateBySite.has(siteAlias)) {
          if (reportCampaignIds.has(campaignId)) {
            newReportCampaignIds = newReportCampaignIds.add(campaignId);
            newCheckState = determineCheckState(newCheckState, CHECK_STATE_ON);
          } else {
            newCheckState = determineCheckState(newCheckState, CHECK_STATE_OFF);
          }
        } else {
          if (
            campaign.status === "ENABLED" ||
            (includePausedCampaigns && campaign.status === "PAUSED")
          ) {
            newReportCampaignIds = newReportCampaignIds.add(
              campaignInfo.campaignId
            );
            newCheckState = determineCheckState(newCheckState, CHECK_STATE_ON);
          } else {
            newCheckState = determineCheckState(newCheckState, CHECK_STATE_OFF);
          }
        }

        if (newCheckStateBySite.get(siteAlias, null) !== newCheckState) {
          newCheckStateBySite = newCheckStateBySite.set(
            siteAlias,
            newCheckState
          );
        }
      }

      const currencyCode = currencyCodeBySiteMap.get(siteAlias);
      return {
        ...campaignInfo,
        campaign,
        siteName: siteNameBySiteMap.get(siteAlias, siteAlias),
        // Until we know otherwise, use the site currency for the cost
        // and revenue currency.
        costCurrencyCode: currencyCode,
        revenueCurrencyCode: currencyCode
      };
    })
    .filter(Boolean);

  return {
    filteredCampaignInfos,
    newReportCampaignIds,
    newCheckStateBySite
  };
}

export default Reporting;
