import { GRPCWebClient } from "Common/utils/grpc";
import {
  SignUpReply,
  SignUpRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { SiteDetails } from "Common/proto/entity/site_pb";
import { InternalSiteDetails } from "Common/proto/entity/site_pb";
import { convertUTMParamsToProto, getUTMParams } from "Common/utils/utmParams";

export const UNLIMITED_LINKED_BILLING_ACCOUNTS = -1;

export default function SignUp(
  siteName: string,
  attribution: string,
  organizationType: InternalSiteDetails.OrganizationType.Option,
  siteContactEmail: string,
  gmvValue: number,
  useEnterpriseBilling: boolean,
  clientBillingAccountMax: number,
  createAsCommandCenter: boolean
): Promise<SignUpReply> | undefined {
  if (siteName == null) {
    return;
  }

  const signUpReq = new SignUpRequest()
    .setAttribution(attribution)
    .setOrganizationType(organizationType)
    .setSiteAliasSuffix("web")
    .setSiteContactEmail(siteContactEmail)
    .setSiteDomain("")
    .setSiteName(siteName)
    .setSalesPlatforms(new SiteDetails.SalesPlatforms().setAmazon(true))
    .setUtmParameters(convertUTMParamsToProto(getUTMParams()))
    .setGmvGreaterThan(gmvValue)
    .setUseEnterpriseBilling(useEnterpriseBilling)
    .setClientBillingAccountMax(clientBillingAccountMax)
    .setIsCommandCenter(createAsCommandCenter);

  return GRPCWebClient.signUp(signUpReq, {});
}
