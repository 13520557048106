import React, { useMemo, useState } from "react";
import {
  Divider,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  Label
} from "semantic-ui-react";
import styled from "styled-components";

import { googleAdsCurrencies, scrubKeyword } from "Common/utils/googleAds";

import {
  CampaignSetupAction,
  campaignSetupSelectors,
  CampaignSetupState,
  createEmptyCampaign
} from "./CampaignSetupPageState";

import { Site } from "ExtensionV2/queries/useSession";

import { MICROS_TO_CURRENCY_UNIT_FACTOR } from "Common/utils/money";
import {
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo
} from "Common/utils/marketplace";
import SelectAmazonProductSection from "ExtensionV2/pages/CampaignSetupPage/SelectAmazonProductSection";
import SelectWalmartProductSection from "ExtensionV2/pages/CampaignSetupPage/SelectWalmartProductSection";

export const SEED_KEYWORDS_COUNT = 3;

export const RemoveProductRow = styled.div`
  align-self: center;
  visibility: hidden;
  margin-left: auto;
`;

export const AddProductRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;

  &:hover {
    & ${RemoveProductRow} {
      visibility: visible;
    }
  }
`;

export const RowNumber = styled.div`
  border-right: 1px solid darkgray;
  min-height: 3em;
  min-width: 3em;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 3em;

  & p {
    margin: auto;
  }
`;

export const ProductRowInputs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
`;

export const SpacedUnwrappedRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const TightWrappingRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2em;
  flex-wrap: wrap;
`;

export const ProductRowLabeledField = styled.div<{
  fieldWidth?: string;
}>`
  width: ${props => props.fieldWidth};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
  & p {
    font-size: small;
  }
`;

export const SeedKeywordPillbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & div {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 0.75em 0.25em 0.75em 0.5em;
    height: 2.75em;
    justify-content: center;

    &:hover {
      border: 1px solid darkgray;
    }
  }

  & span {
    margin-left: 1em;
    float: right;
    &:hover {
      color: gray;
    }
  }
`;

export const ClickableText = styled.p`
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  &:hover {
    font-style: normal;
    color: gray;
  }
`;

export const CurrencyInputField = ({
  adwordsCurrency,
  budgetMicros,
  campaignIndex,
  handleUpdateBudget,
  onBlur,
  label,
  placeholder,
  invalid
}: {
  adwordsCurrency: string;
  budgetMicros: number;
  campaignIndex: number;
  handleUpdateBudget: (campaignIndex: number, budget: number) => void;
  onBlur?: () => void;
  label: string;
  placeholder?: string;
  invalid?: boolean;
}): React.ReactElement => {
  const currencySymbol = googleAdsCurrencies.get(adwordsCurrency)?.symbol;

  return (
    <>
      <Input
        labelPosition="left"
        error={invalid}
        type="number"
        onBlur={() => {
          // round down to 2 decimal places on blur
          const roundedPositive =
            Math.round(
              ((budgetMicros + Number.EPSILON) /
                MICROS_TO_CURRENCY_UNIT_FACTOR) *
                100
            ) / 100;

          handleUpdateBudget(campaignIndex, roundedPositive);
          if (onBlur) {
            onBlur();
          }
        }}
        value={
          budgetMicros > 0 ? budgetMicros / MICROS_TO_CURRENCY_UNIT_FACTOR : 0
        }
        placeholder={placeholder ?? label}
        onChange={(
          _ev: React.ChangeEvent<HTMLInputElement>,
          data: InputOnChangeData
        ) => {
          handleUpdateBudget(campaignIndex, Number(data.value));
        }}
      >
        <Label basic>{currencySymbol || adwordsCurrency}</Label>
        <input style={{ width: "5.5em" }} />
      </Input>
      {invalid ? (
        <p style={{ color: "red" }}>
          <i>Please enter a number greater than 0</i>
        </p>
      ) : (
        <p>
          <i>{label}</i>
        </p>
      )}
    </>
  );
};

export const SeedKeywordsSelector: React.FC<{
  campaignIndex: number;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
}> = ({ campaignIndex, state, dispatch }) => {
  const { selectSeedKeywords } = campaignSetupSelectors;

  const campaign = state.campaigns[campaignIndex];

  const seedKeywords = selectSeedKeywords(campaign);

  const [currentSeedKeyword, setCurrentSeedKeyword] = useState("");

  const handleAddSeedKeyword = (campaignIndex: number, keyword: string) => {
    const scrubbedKeyword = scrubKeyword(keyword, 0);

    if (
      scrubbedKeyword === "" ||
      seedKeywords.find(kw => kw === scrubbedKeyword)
    ) {
      return;
    }

    const keywords = seedKeywords.concat([scrubbedKeyword]);

    dispatch({
      name: "UpdateSeedKeywords",
      data: { index: campaignIndex, keywords: keywords }
    });
  };

  const keywordPillboxes = useMemo(() => {
    const handleRemoveSeedKeyword = (
      campaignIndex: number,
      keyword: string
    ) => {
      const keywords = seedKeywords.filter(
        existingKeyword => existingKeyword !== keyword
      );

      dispatch({
        name: "UpdateSeedKeywords",
        data: { index: campaignIndex, keywords: keywords }
      });
    };

    const seedKeywordPills = seedKeywords.map((seedKeyword, i) => {
      return (
        <ProductRowLabeledField key={seedKeyword}>
          <SeedKeywordPillbox>
            <div>
              {seedKeyword}
              <span
                onClick={() =>
                  handleRemoveSeedKeyword(campaignIndex, seedKeyword)
                }
                style={{ cursor: "pointer", margin: 0 }}
              >
                <Icon name="x" />
              </span>
            </div>
            <p style={{ marginTop: "0.5em" }}>
              <i>
                Keyword {i + 1}/{SEED_KEYWORDS_COUNT}
              </i>
            </p>
          </SeedKeywordPillbox>
        </ProductRowLabeledField>
      );
    });

    return <>{seedKeywordPills}</>;
  }, [seedKeywords, campaignIndex, dispatch]);

  const newSeedKeywordPlaceholderText = `Enter ${SEED_KEYWORDS_COUNT -
    seedKeywords.length} more ${
    SEED_KEYWORDS_COUNT - seedKeywords.length > 1 ? "keywords" : "keyword"
  }`;

  let hint = "Enter keyword for your product.";
  if (seedKeywords.length === 0) {
    hint = "Enter first of 3 keywords for your product.";
  } else if (seedKeywords.length === 1) {
    hint = "Enter second of 3 keywords for your product.";
  } else if (seedKeywords.length === 2) {
    hint = "Enter third of 3 keywords for your product.";
  }

  return (
    <>
      {keywordPillboxes}
      {seedKeywords.length < SEED_KEYWORDS_COUNT && (
        <ProductRowLabeledField fieldWidth="18em">
          <Form
            onSubmit={() => {
              handleAddSeedKeyword(campaignIndex, currentSeedKeyword.trim());
              setCurrentSeedKeyword("");
            }}
            onBlur={() => {
              handleAddSeedKeyword(campaignIndex, currentSeedKeyword.trim());
              setCurrentSeedKeyword("");
            }}
          >
            <Input
              action={{ icon: "plus" }}
              disabled={seedKeywords.length >= SEED_KEYWORDS_COUNT}
              fluid
              placeholder={newSeedKeywordPlaceholderText}
              onChange={(
                _ev: React.ChangeEvent<HTMLInputElement>,
                data: InputOnChangeData
              ) => {
                setCurrentSeedKeyword(data.value);
              }}
              value={currentSeedKeyword}
            />
          </Form>
          <p>
            <i>{hint}</i>
          </p>
        </ProductRowLabeledField>
      )}
    </>
  );
};

const SelectProductsStage = ({
  dispatch,
  site,
  state
}: {
  dispatch: React.Dispatch<CampaignSetupAction>;
  site: Site;
  state: CampaignSetupState;
}): React.ReactElement => {
  const { campaigns } = state;

  const { selectMarketplaceInfo } = campaignSetupSelectors;

  const handleAddAnotherProductClick = () => {
    dispatch({
      name: "AttachCampaign",
      data: createEmptyCampaign()
    });
  };

  return (
    <>
      {campaigns.map((campaign, index) => {
        const marketplaceInfo = selectMarketplaceInfo(campaign, state);

        return (
          <React.Fragment key={index}>
            {isAmazonMarketplaceInfo(marketplaceInfo) && (
              <SelectAmazonProductSection
                campaignIndex={index}
                site={site}
                state={state}
                dispatch={dispatch}
              />
            )}
            {isWalmartMarketplaceInfo(marketplaceInfo) && (
              <SelectWalmartProductSection
                campaignIndex={index}
                site={site}
                state={state}
                dispatch={dispatch}
              />
            )}
            <Divider style={{ width: "85%", margin: "auto" }} />
          </React.Fragment>
        );
      })}
      <div style={{ marginTop: "3em", display: "inline-block" }}>
        <ClickableText onClick={handleAddAnotherProductClick}>
          + Add another product
        </ClickableText>
      </div>
    </>
  );
};

export default SelectProductsStage;
