import React, { useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import styled from "styled-components/macro";

const FadeoutContainer = styled.div`
  width: 100%;
  text-align: center;
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const FadeoutTopBanner = props => {
  const { message, displayTimeMs, isError = false } = props;
  const defaultDisplayTimeMs = 7000;
  const [display, setDisplay] = useState(true);

  const displayTime =
    displayTimeMs != null ? displayTimeMs : defaultDisplayTimeMs;

  const messageStyles = {
    animation: `fadeout ${displayTime}ms ease-in`
  };

  useEffect(() => {
    if (!isError) {
      setTimeout(() => {
        setDisplay(false);
      }, displayTime);
    }
  }, [displayTime, displayTimeMs, isError]);

  if (!display) {
    return null;
  }

  return (
    <FadeoutContainer style={isError ? {} : messageStyles}>
      <Message positive={!isError} error={isError}>
        {message}
      </Message>
    </FadeoutContainer>
  );
};

export default FadeoutTopBanner;
