import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: process.env.REACT_APP_ENV,
  forwardErrorsToLogs: true,
  sampleRate: 100
});

export const datadogLogHandler =
  process.env.REACT_APP_DATADOG_LOG_HANDLER || "http";
datadogLogs.logger.setHandler(datadogLogHandler);
