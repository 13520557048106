// Sets the string value into LocalStorage under the requested string key.
// Note: Array values should be comma-separated with no extra spaces.
export const setLocalStorageValue = (storageKey: string, value: string): void =>
  localStorage.setItem(storageKey, value);

// Returns the array list retrieved by its local storage key, or an empty
// array if nothing was found. Expects the local storage value to be a
// comma-separated list with no extra spaces.
export const getLocalStorageListByKey = (
  storageKey: string,
  defaultList: string[] = []
): Array<string> => {
  const listString = localStorage.getItem(storageKey);

  return listString ? listString.split(",") : defaultList;
};
