import _ from "lodash";
import match from "micromatch";
import React, { useState, useMemo } from "react";
import { Input, List } from "semantic-ui-react";

import { formatCustomerId } from "Common/utils/googleAds";
import { compareCaseInsens } from "Common/utils/strings";
import { generatePath } from "react-router-dom-v5-compat";
import { DASHBOARD_PATH, CAMPAIGNS_PATH } from "ExtensionV2/ExtensionV2";

const MATCH_OPTIONS = {
  nocase: true,
  contains: true
};

export const SiteSwitcher = ({
  currentSiteName,
  currentSiteAlias,
  userSites
}) => {
  const [matchText, setMatchText] = useState("");

  const filteredUserSites = useMemo(() => {
    const filteredUserSites = userSites.filter(userSite => {
      if (!matchText) {
        return true;
      }

      // match.isMatch will throw if it receives a non-string value in the first argument.
      try {
        const name = _.isString(userSite.siteName) ? userSite.siteName : "";
        const alias = _.isString(userSite.siteAlias) ? userSite.siteAlias : "";

        // As a "secret" option, if the first match character is a '@', then show
        // site aliases in the list and only match on the alias.
        if (matchText[0] === "@") {
          if (
            matchText.length === 1 ||
            match.isMatch(alias, matchText.slice(1), MATCH_OPTIONS)
          ) {
            return true;
          }

          return false;
        }

        // Look for partial matches of the site name and the site domain name.
        if (match.isMatch(name, matchText, MATCH_OPTIONS)) {
          return true;
        }

        // Next, look for matches among the site's adwords ids, both with and
        // without the hyphens.
        for (const id of userSite.adwordsAccountIdsList) {
          if (!_.isString(id)) {
            continue;
          }

          if (
            match.isMatch(id, matchText, MATCH_OPTIONS) ||
            match.isMatch(formatCustomerId(id), matchText, MATCH_OPTIONS)
          ) {
            return true;
          }
        }

        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    });

    return filteredUserSites.sort((a, b) =>
      compareCaseInsens(a.siteName, b.siteName)
    );
  }, [userSites, matchText]);

  const handleSwitchSite = siteAlias => {
    window.location.href = generatePath(`${DASHBOARD_PATH}/${CAMPAIGNS_PATH}`, {
      siteAlias
    });
  };

  const handleMatchTextChange = (e, { value }) => {
    setMatchText(value);
  };

  const handleMatchTextKeyPress = e => {
    e.stopPropagation();

    // If there is no matching sites or more than one, don't do
    // anything special.
    if (filteredUserSites.length !== 1) {
      return;
    }
    const userSite = filteredUserSites[0];
    if (!userSite) {
      return;
    }

    // If there is exactly one match, and the user hit 'Return',
    // just switch to matching site now.
    if (e.keyCode === 13 || e.which === 13) {
      handleSwitchSite(userSite.siteAlias);
    }
  };
  return (
    <>
      <Input
        fluid
        placeholder={currentSiteName}
        onChange={handleMatchTextChange}
        onKeyPress={handleMatchTextKeyPress}
      />
      <br />
      <div
        style={{
          overflowY: "auto",
          height: "30vh",
          border: "1px solid #444"
        }}
      >
        <List divided relaxed selection verticalAlign="middle">
          {filteredUserSites.map(userSite => {
            let siteName = userSite.siteName;
            if (matchText[0] === "@") {
              siteName = userSite.siteAlias;
            }
            return (
              <List.Item
                key={userSite.siteAlias}
                active={userSite.siteAlias === currentSiteAlias}
                onClick={() => handleSwitchSite(userSite.siteAlias)}
              >
                <List.Content
                  style={{ fontSize: "smaller", width: "110px" }}
                  floated="left"
                >
                  {userSite.adwordsAccountIdsList.map(id => (
                    <React.Fragment key={id}>
                      {formatCustomerId(id)}
                      <br />
                    </React.Fragment>
                  ))}
                </List.Content>
                <List.Content>
                  <span>{siteName}</span>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    </>
  );
};
