import { getAggregate } from "../../queries/useAsinPerformanceMetricsByKey";
import {
  arraysSum,
  calculateAverageRatio,
  calculateRatios,
  zipArraysUsingSum
} from "./util";

const RATIO_DECIMAL_PLACES = 3;

// Returns numerator & denominator timeseries lists for use in smoothing calcs.
export const getSmoothingTimeseriesLists = (
  channel, // channel info for this metric
  allTimeseries, // All metrics timeseries for this channel
  channelTimeseries // The selected metrics timeseries for this channel
) => {
  const { channelType: type, metricParts } = channel;

  let numerators = [];
  metricParts?.numerators?.forEach(den => {
    const listField = den.metricField;

    // Handle custom sources, when specified
    if (den.source && den.source !== type.sourceField) {
      numerators.push(allTimeseries[den.source]?.[listField]);
      return;
    }
    numerators.push(channelTimeseries[listField]);
  });

  let denominators = [];
  metricParts?.denominators?.forEach(den => {
    const listField = den.metricField;

    // Handle custom sources, when specified
    if (den.source && den.source !== type.sourceField) {
      denominators.push(allTimeseries[den.source]?.[listField]);
      return;
    }
    denominators.push(channelTimeseries[listField]);
  });

  return { numerators, denominators };
};

// Retrieves the direct aggregate value for the selected metric field. If that
// that is not available, then it will attempt ot use the defined field parts
// to calculate the metric aggregate value.
export const getOrCalcMetricAggregate = (
  channel,
  channelAggregates,
  channelTimeseries,
  allTimeseries
) => {
  const {
    channelType: type,
    metricField: defaultMetricField,
    metricParts: { numerators, denominators }
  } = channel;
  const metricListField = defaultMetricField;

  // Default direct data access from field
  if (defaultMetricField && channelAggregates?.[metricListField]?.length) {
    return getAggregate(channelAggregates[metricListField]);
  }

  // Default direct data access from single numerator
  if (numerators?.length === 1 && denominators?.length === 0) {
    const { metricField } = numerators[0];
    return arraysSum([channelTimeseries[metricField]]);
  }

  // Custom case - calculate the metrics timeseries based on its defined parts
  const { combinedNums, combinedDens } = getCombinedPartsArrays(
    numerators,
    denominators,
    type,
    allTimeseries
  );

  if (combinedDens?.length) {
    // Numerators & denominators, so find their average
    if (combinedNums.length !== combinedDens.length) {
      console.error("getOrCalcMetricAggregate(): Arrays not the same length!");
      return null;
    }
    return calculateAverageRatio(
      combinedNums,
      combinedDens,
      RATIO_DECIMAL_PLACES
    );
  }

  // Just numerators, so return their sum total
  return arraysSum([combinedNums]);
};

// First tries to retrieve the direct timeseries array for the selected metric
// but if that is not available, then it will attempt ot use the defined metric
// parts to calculate the metric timeseries array.
export const getOrCalcMetricTimeseries = (
  channel,
  channelTimeseries,
  allTimeseries
) => {
  const {
    channelType: type,
    metricField: defaultMetricField,
    metricParts: { numerators, denominators }
  } = channel;
  const timeseriesField = defaultMetricField;

  // Default direct data access from field
  if (defaultMetricField && channelTimeseries[timeseriesField]?.length) {
    return channelTimeseries[timeseriesField];
  }

  // Default direct data access from single numerator
  if (numerators?.length === 1 && denominators?.length === 0) {
    const { metricField } = numerators[0];
    return channelTimeseries[metricField];
  }

  // Custom case - calculate the metrics timeseries based on its defined parts
  const { combinedNums, combinedDens } = getCombinedPartsArrays(
    numerators,
    denominators,
    type,
    allTimeseries
  );

  if (combinedDens?.length) {
    if (combinedNums.length !== combinedDens.length) {
      console.error("getOrCalcMetricTimeseries(): Arrays not the same length!");
      return null;
    }
    return calculateRatios(combinedNums, combinedDens, RATIO_DECIMAL_PLACES);
  }

  // Just numerators, so return them
  return combinedNums;
};

// Given the numerators & denominators metrics parts info, returns the
// appropriate combined numerator & denominator timeseries arrays.
const getCombinedPartsArrays = (
  metricsPartsNumerators,
  metricsPartsDenominators,
  type,
  allTimeseries
) => {
  // Retrieve the numerator timeseries arrays from their parts fields
  const numeratorTimeseries = metricsPartsNumerators.map(numField => {
    const { source = type.sourceField, metricField } = numField;
    return allTimeseries[source]?.[metricField];
  });

  // Retrieve the denominator timeseries arrays from their parts fields (possibly none)
  const denominatorTimeseries = metricsPartsDenominators?.map(denField => {
    const { source = type.sourceField, metricField } = denField;
    return allTimeseries[source]?.[metricField];
  });

  // Combine the individual numerator & denominator arrays via sum
  const combinedNums = zipArraysUsingSum(numeratorTimeseries);
  const combinedDens = zipArraysUsingSum(denominatorTimeseries);

  return { combinedNums, combinedDens };
};

// Converts a standard daily timeseries into an "accumulating" timeseries that
// accumulates the daily running total across the date range.
export const toAccumulatingTimeseries = timeseries => {
  const accumulatingTimeseries = [...timeseries];
  for (let i = 0; i < timeseries.length; i++) {
    if (i === 0) {
      continue;
    }
    // Through the end, combine latest value & the prior chart-sum value.
    accumulatingTimeseries[i] = timeseries[i] + accumulatingTimeseries[i - 1];
  }

  return accumulatingTimeseries;
};
