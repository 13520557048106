import _ from "lodash";

import React, { lazy, Suspense, useEffect } from "react";
import { generatePath, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { ThemeProvider } from "styled-components/macro";
import Notifications from "react-notify-toast";
import { Colors, GlobalStyle, MainContainer } from "app.css";
import { useSearchParams } from "react-router-dom-v5-compat";

import UnauthenticatedRoute from "../Common/components/UnauthenticatedRoute";
import useSessionState from "./hooks/useSessionState";
import { AMPD_ROOT, signUpPath } from "../ExtensionV2/ExtensionV2";
import {
  OFFER_PARAM,
  SUBSCRIPTION_ID_PARAM
} from "ExtensionV2/pages/PaymentPage/PaymentPage";

export const GA_AMPD_TRACKER = "ampd";
export const GA_EXTENSION_TRACKER = "extension";
export const GA_ENTERPRISE_TRACKER = "enterprise";

const AmazonSPAPICallback = lazy(() =>
  import("Common/pages/AmazonSPAPICallback")
);

const Login = lazy(() => import("Common/pages/Login"));

const GenerateGoogleAuthToken = lazy(() =>
  import("Common/components/GenerateGoogleAuthToken")
);
const GenerateAmazonAdvertisingAuthCode = lazy(() =>
  import("Common/components/GenerateAmazonAdvertisingAuthCode")
);

const GA = location => {
  if (process.env.REACT_APP_ENV === "prod") {
    ReactGA.pageview(location, [GA_ENTERPRISE_TRACKER]);
  }
};

// Router for routes that are not under any of our other apps,
// e.g., login, callbacks, etc.
function Common({ env }) {
  const [sessionState, sessionStateLoading] = useSessionState();

  if (sessionStateLoading) {
    return null;
  }

  return <CommonRoutes {...{ env, sessionState }} />;
}

/*
  /s/:siteAlias hosts an old application that only has 2 paying customers. They pay
  enough that we need to keep it around. Only redirect to /s if the site alias
  is one of these 2.
 */
const LEGACY_ENTERPRISE_SITE_ALIASES = ["blindsgalore", "signature-hardware"];

function CommonRoutes({ sessionState }) {
  const [searchParams] = useSearchParams();

  const isAuthenticated = _.get(sessionState, "isAuthenticated", false);
  const session = _.get(sessionState, "session", {});
  const site = _.get(session, "site", null);
  const siteAlias = _.get(site, "siteAlias", null);
  const isLegacyEnterpriseSite = LEGACY_ENTERPRISE_SITE_ALIASES.includes(
    siteAlias
  );

  const defaultRoute = _.isEmpty(siteAlias)
    ? signUpPath
    : isLegacyEnterpriseSite
    ? generatePath("/s/:siteAlias", { siteAlias })
    : generatePath(AMPD_ROOT, { siteAlias });

  const routeIfNotAuthenticated = "/login";

  useEffect(() => {
    const location = window.location.pathname + window.location.search;
    GA(location);
  }, []);

  // Sales hands out special links that direct customers to a particular payment screen and/or offer
  // special discounts. We need to preserve these params through the sign-up redirect, so we store
  // them in localStorage and retrieve them on the payment page.
  const priceId = searchParams.get(SUBSCRIPTION_ID_PARAM);
  const offer = searchParams.get(OFFER_PARAM);
  if (priceId || offer) {
    localStorage.setItem(SUBSCRIPTION_ID_PARAM, priceId || "");
    localStorage.setItem(OFFER_PARAM, offer || "");
  }

  return (
    <ThemeProvider theme={Colors}>
      <Suspense fallback={null}>
        <>
          <Helmet defaultTitle="Ampd" />
          <Notifications options={{ zIndex: 10000 }} />

          <MainContainer isAuthenticated={isAuthenticated}>
            <div className="content">
              <Switch>
                <Route
                  path="/callback/amazon-spapi"
                  component={AmazonSPAPICallback}
                />

                <UnauthenticatedRoute
                  path="/login"
                  sessionState={sessionState}
                  routeIfAlreadyAuthenticated={defaultRoute}
                  component={Login}
                />

                {(process.env.REACT_APP_ENV === "dev" ||
                  process.env.REACT_APP_ENV === "staging") && (
                  <>
                    <Route
                      path="/generateGoogleAuthToken"
                      component={GenerateGoogleAuthToken}
                    />
                    <Route
                      path="/generateAmazonAdvertisingAuthCode"
                      component={GenerateAmazonAdvertisingAuthCode}
                    />
                  </>
                )}

                <RedirectHREF
                  to={isAuthenticated ? defaultRoute : routeIfNotAuthenticated}
                />
              </Switch>
            </div>
          </MainContainer>

          <GlobalStyle isAuthenticated={isAuthenticated} />
        </>
      </Suspense>
    </ThemeProvider>
  );
}

function RedirectHREF(props) {
  const { to } = props;

  useEffect(() => {
    if (to) {
      window.location.href = to;
    }
  }, [to]);

  return null;
}

export default Common;
