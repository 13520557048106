import React from "react";
import { Message } from "semantic-ui-react";

import { extractErrorMessage } from "Common/errors/error";

// Note: An error boundary can only be implemented in a class component because it uses the
// componentDidCatch lifecycle method
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: extractErrorMessage(error)
    };
  }

  componentDidCatch(error, info) {
    console.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Message error>
          <p>
            Ampd has encountered an unexpected error, please try refreshing your
            browser.
          </p>
          <p>
            If you continue to see this message, please let us know using the
            chat tool in the lower right hand corner of the screen.
          </p>

          {this.state.errorMessage && (
            <p>Additional Info: {this.state.errorMessage}</p>
          )}
        </Message>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
