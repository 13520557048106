import { Facebook } from "Common/proto/common/facebook_pb";
import { FacebookCampaign } from "ExtensionV2/queries/useFacebookMarketingResources";
import { AMAZON_URL_REGEX } from "./amazon";
import {
  checkForWalmartAttributionParams,
  checkForWalmartAttributionPath,
  WALMART_URL_REGEX
} from "./walmart";

// The fields we want to fetch from the Facebook Marketing API for each campaign.
export const CONFIGURE_FACEBOOK_CAMPAIGNS_RESOURCE_FIELDS = [
  "campaign.account_id",
  "campaign.name",
  "campaign.effective_status",
  "campaign.objective",
  "ad_set.account_id",
  "ad_set.campaign_id",
  "ad_set.name",
  "ad.account_id",
  "ad.campaign_id",
  "ad.adset_id",
  "ad.name",
  "ad.effective_status",
  "ad_creative.link_url",
  "ad_creative.url_tags",
  "ad_creative.body",
  "asset_feed_spec.link_urls",
  "object_story_spec.link_data",
  "object_story_spec.template_data"
];

// An ad's tracking status lets us determine if the ad is eligible to have Ampd
// Attribution turned on or if it already has attribution turned on and should
// be collecting conversion data.
export const adTrackingStatus = (
  ad: Facebook.API.Ad.AsObject
): {
  isAmazonAd: boolean;
  hasAmazonAttributionTags: boolean;
  isWalmartAd: boolean;
  hasWalmartAttributionTags: boolean;
} => {
  return {
    ...amazonTrackingStatus(ad),
    ...walmartTrackingStatus(ad)
  };
};

// When checking if a Facebook ad is set up for Walmart, we need to check both
// the URL path and the query params
const walmartTrackingStatus = (
  ad: Facebook.API.Ad.AsObject
): { isWalmartAd: boolean; hasWalmartAttributionTags: boolean } => {
  const walmartDestinations = findMatchingCreativeDestinations(
    ad,
    WALMART_URL_REGEX
  );

  if (walmartDestinations.length === 0) {
    return {
      isWalmartAd: false,
      hasWalmartAttributionTags: false
    };
  }

  // Check each destination URL to see if it has both the correct path and the
  // correct query params.
  let hasLinkWithAttributionPath = false;
  let hasLinkWithAttributionPathAndParams = false;
  for (const destination of walmartDestinations) {
    if (checkForWalmartAttributionPath(destination)) {
      hasLinkWithAttributionPath = true;

      hasLinkWithAttributionPathAndParams = checkForWalmartAttributionParams(
        destination
      );
    }

    if (hasLinkWithAttributionPathAndParams) {
      break;
    }
  }

  if (hasLinkWithAttributionPathAndParams) {
    return {
      isWalmartAd: true,
      hasWalmartAttributionTags: true
    };
  }

  // If one of the destinations has the correct path but not the correct query
  // params, the query params could still be on the "urlTags" field. The tags in
  // the urlTags field get appended to each URL, so just so long as one of the
  // other URLs has the correct attribution path and the "urlTags" field has the
  // correct attribution params, we can consider this ad to have valid walmart
  // attribution.
  let hasAttributionParamsAsURLTags = false;
  if (ad.adCreative?.urlTags && hasLinkWithAttributionPath) {
    hasAttributionParamsAsURLTags = checkForWalmartAttributionParams(
      ad.adCreative.urlTags
    );
  }

  return {
    isWalmartAd: true,
    hasWalmartAttributionTags:
      hasLinkWithAttributionPath && hasAttributionParamsAsURLTags
  };
};

const amazonTrackingStatus = (
  ad: Facebook.API.Ad.AsObject
): { isAmazonAd: boolean; hasAmazonAttributionTags: boolean } => {
  const amazonDestinations = findMatchingCreativeDestinations(
    ad,
    AMAZON_URL_REGEX
  );

  let hasTrackingTags = false;
  if (checkForAmazonAttributionTags(ad.adCreative?.urlTags)) {
    hasTrackingTags = true;
  } else {
    hasTrackingTags = amazonDestinations.some(url => {
      return checkForAmazonAttributionTags(url);
    });
  }

  // Sometime the ad configuration requires us to put the URL tags directly onto
  // the URL itself instead of the urlTags field.
  const hasTagsOnDestination = amazonDestinations.some(url => {
    return checkForAmazonAttributionTags(url);
  });

  return {
    isAmazonAd: amazonDestinations.length > 0,
    hasAmazonAttributionTags: hasTrackingTags || hasTagsOnDestination
  };
};

// To know if a campaign is eligible for Ampd Attribution, we need to check if
// the campaign has ads targeting Amazon (or Walmart, etc.) There are a lot of
// different places Meta stores the AdCreative's destination URL depending on
// the type of ad and the status of the ad. This method will likely continue to
// grow as we encounter more Meta ad configurations.
const findMatchingCreativeDestinations = (
  ad: Facebook.API.Ad.AsObject,
  destination: RegExp
): Array<string> => {
  const matchingStrings: string[] = [];
  if (ad.adCreative?.linkUrl?.match(destination)) {
    matchingStrings.push(ad.adCreative.linkUrl);
  }

  if (ad.adCreative?.assetFeedSpec?.linkUrlsList) {
    ad.adCreative.assetFeedSpec.linkUrlsList.forEach(link => {
      if (link?.websiteUrl?.match(destination)) {
        matchingStrings.push(link.websiteUrl);
      }
    });
  }

  if (ad.adCreative?.objectStorySpec?.linkData?.link?.match(destination)) {
    matchingStrings.push(ad.adCreative.objectStorySpec.linkData.link);
  }
  return matchingStrings;
};

const checkForAmazonAttributionTags = (url?: string): boolean => {
  if (!url) {
    return false;
  }

  // The attribution tags can url encoded or not, so we need to check for both.
  return (
    // ad group
    ((url.includes("aa_adgroupid={{adset.id}}") ||
      url.includes("%7B%7Badset.id%7D%7D")) &&
      // campaign
      (url.includes("aa_campaignid={{campaign.id}}") ||
        url.includes("aa_campaignid=%7B%7Bcampaign.id%7D%7D")) &&
      // creative
      url.includes("aa_creativeid={{ad.id}}")) ||
    url.includes("aa_creativeid=%7B%7Bad.id%7D%7D")
  );
};

// A campaign's tracking status lets us determine if the campaign is eligible to
// have Ampd Attribution turned on or if it already has attribution turned on
// and should be collecting conversion data.
export function campaignTrackingStatus(
  campaign: FacebookCampaign
): {
  hasAtLeastOneAmazonAd: boolean;
  hasAtLeastOneTrackedAmazonAd: boolean;
  hasAtLeastOneWalmartAd: boolean;
  hasAtLeastOneTrackedWalmartAd: boolean;
} {
  let hasAtLeastOneAmazonAd = false;
  let hasAtLeastOneTrackedAmazonAd = false;
  const hasAtLeastOneWalmartAd = false;
  const hasAtLeastOneTrackedWalmartAd = false;
  for (let i = 0; i < campaign.ads.length; i++) {
    const amazonTrackingStatus = adTrackingStatus(campaign.ads[i]);
    if (amazonTrackingStatus.isAmazonAd) {
      hasAtLeastOneAmazonAd = true;
    }
    if (amazonTrackingStatus.hasAmazonAttributionTags) {
      hasAtLeastOneTrackedAmazonAd = true;
    }

    const walmartTrackingStatus = adTrackingStatus(campaign.ads[i]);
    if (walmartTrackingStatus.isWalmartAd) {
      hasAtLeastOneAmazonAd = true;
    }
    if (walmartTrackingStatus.hasWalmartAttributionTags) {
      hasAtLeastOneTrackedAmazonAd = true;
    }

    if (
      hasAtLeastOneAmazonAd &&
      hasAtLeastOneTrackedAmazonAd &&
      hasAtLeastOneWalmartAd &&
      hasAtLeastOneTrackedWalmartAd
    ) {
      break;
    }
  }

  return {
    hasAtLeastOneAmazonAd,
    hasAtLeastOneTrackedAmazonAd,
    hasAtLeastOneWalmartAd,
    hasAtLeastOneTrackedWalmartAd
  };
}

export function getTrackingUrlParams(advertiserProfileId: string): string {
  return (
    "aa_adgroupid={{adset.id}}&aa_creativeid={{ad.id}}&aa_campaignid={{campaign.id}}" +
    `&maas=maas_adg_api_${advertiserProfileId}_macro_2_34&ref_=aa_maas`
  );
}
