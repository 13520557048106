import * as proto from "Common/utils/proto";
import { MarkSiteRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Site_pb";
import { GRPCWebClient } from "Common/utils/grpc";

// Marks that the specified site has used the application.
export const markApplicationUsage = ({ siteAlias, application }) => {
  if (!siteAlias) {
    return;
  }

  const markSiteReq = proto.set(new MarkSiteRequest(), {
    siteAlias,
    application
  });

  GRPCWebClient.markSite(markSiteReq).catch(err => console.error(err));
};
