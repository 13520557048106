import { UseMutationResult, useMutation } from "@tanstack/react-query";

import {
  UpdateCampaignsRequest,
  UpdateCampaignsReply,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { GRPCWebClient } from "Common/utils/grpc";

import { CampaignSettings } from "Common/proto/entity/campaign_pb";
import * as proto from "Common/utils/proto";
import { sendGAEvent } from "../components/GA";

interface UpdateBidAutomationArgs {
  siteAlias: string;
  gaCategory: string;
  googleAdsCustomerId: string;
  campaignId: string;
  bidAutomationStatus: CampaignSettings.AutomationStatus.Option;
  bidAutomationTargetVoltage: number;
  runFrequencyDays: number;
}

export const useUpdateBidAutomation = (): UseMutationResult<
  UpdateCampaignsReply.AsObject,
  unknown,
  UpdateBidAutomationArgs,
  unknown
> => {
  return useMutation(
    async (
      updateBidAutomationArgs: UpdateBidAutomationArgs
    ): Promise<UpdateCampaignsReply.AsObject> => {
      for (const [key, value] of Object.entries(updateBidAutomationArgs)) {
        if (value == null || value?.length === 0) {
          throw new Error(`Missing required argument: ${key}`);
        }
      }

      const {
        siteAlias,
        gaCategory,
        googleAdsCustomerId,
        campaignId,
        bidAutomationStatus,
        bidAutomationTargetVoltage,
        runFrequencyDays
      } = updateBidAutomationArgs;

      const autoAction = new UpdateCampaignAction.UpdateBidAutomationSettingsAction();
      autoAction.setCampaignId(campaignId);
      autoAction.setStatus(bidAutomationStatus);
      autoAction.setTargetVoltage(bidAutomationTargetVoltage);
      if (runFrequencyDays > 0) {
        autoAction.setRunFrequencyDays(runFrequencyDays);
      }

      const action = proto.set(new UpdateCampaignAction(), {
        updateBidAutomationSettings: autoAction
      });

      sendGAEvent(
        gaCategory,
        "Update Campaign Max CPC Automation",
        siteAlias,
        `${bidAutomationStatus}:voltage${bidAutomationTargetVoltage}`
      );

      const updateReq = proto.set(new UpdateCampaignsRequest(), {
        siteAlias,
        customerId: googleAdsCustomerId,
        actions: [action]
      });

      const reply = await GRPCWebClient.updateCampaigns(updateReq, {});
      return reply.toObject();
    }
  );
};
