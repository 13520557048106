// source: proto/entity/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_crudFields_pb = require('../../proto/common/crudFields_pb.js');
goog.object.extend(proto, proto_common_crudFields_pb);
var proto_common_objectID_pb = require('../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
var proto_entity_oidcProfile_pb = require('../../proto/entity/oidcProfile_pb.js');
goog.object.extend(proto, proto_entity_oidcProfile_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.entity.Bookmark', null, global);
goog.exportSymbol('proto.entity.BookmarkParameter', null, global);
goog.exportSymbol('proto.entity.GlobalRole', null, global);
goog.exportSymbol('proto.entity.GlobalRole.Option', null, global);
goog.exportSymbol('proto.entity.SignUpProfile', null, global);
goog.exportSymbol('proto.entity.SiteRole', null, global);
goog.exportSymbol('proto.entity.SiteRole.Option', null, global);
goog.exportSymbol('proto.entity.SiteUser', null, global);
goog.exportSymbol('proto.entity.TrialDetails', null, global);
goog.exportSymbol('proto.entity.UISettings', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.ActionCase', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.DeleteUserAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.SetGlobalRoleAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UndeleteUserAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UpdateBookmarksAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UpdateUISettingsAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UpdateUISettingsAction.ActionCase', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction', null, global);
goog.exportSymbol('proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction', null, global);
goog.exportSymbol('proto.entity.User', null, global);
goog.exportSymbol('proto.entity.User.OrganizationDetails', null, global);
goog.exportSymbol('proto.entity.UserDetails', null, global);
goog.exportSymbol('proto.entity.UserProfile', null, global);
goog.exportSymbol('proto.entity.UserSelector', null, global);
goog.exportSymbol('proto.entity.UserSelector.SelectorCase', null, global);
goog.exportSymbol('proto.entity.UserSiteMapping', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UserSelector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UserSelector.oneofGroups_);
};
goog.inherits(proto.entity.UserSelector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UserSelector.displayName = 'proto.entity.UserSelector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.User.displayName = 'proto.entity.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.User.OrganizationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.User.OrganizationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.User.OrganizationDetails.displayName = 'proto.entity.User.OrganizationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.GlobalRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.GlobalRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.GlobalRole.displayName = 'proto.entity.GlobalRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UserDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.UserDetails.repeatedFields_, null);
};
goog.inherits(proto.entity.UserDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UserDetails.displayName = 'proto.entity.UserDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UserProfile.displayName = 'proto.entity.UserProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UISettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UISettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UISettings.displayName = 'proto.entity.UISettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SignUpProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SignUpProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SignUpProfile.displayName = 'proto.entity.SignUpProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.TrialDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.TrialDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.TrialDetails.displayName = 'proto.entity.TrialDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UserSiteMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UserSiteMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UserSiteMapping.displayName = 'proto.entity.UserSiteMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteUser.displayName = 'proto.entity.SiteUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteRole.displayName = 'proto.entity.SiteRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UpdateUserAction.oneofGroups_);
};
goog.inherits(proto.entity.UpdateUserAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.displayName = 'proto.entity.UpdateUserAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateUserAction.SetGlobalRoleAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.SetGlobalRoleAction.displayName = 'proto.entity.UpdateUserAction.SetGlobalRoleAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.DeleteUserAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateUserAction.DeleteUserAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.DeleteUserAction.displayName = 'proto.entity.UpdateUserAction.DeleteUserAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.UndeleteUserAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateUserAction.UndeleteUserAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.UndeleteUserAction.displayName = 'proto.entity.UpdateUserAction.UndeleteUserAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.UpdateUserAction.UpdateBookmarksAction.repeatedFields_, null);
};
goog.inherits(proto.entity.UpdateUserAction.UpdateBookmarksAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.UpdateBookmarksAction.displayName = 'proto.entity.UpdateUserAction.UpdateBookmarksAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UpdateUserAction.UpdateUISettingsAction.oneofGroups_);
};
goog.inherits(proto.entity.UpdateUserAction.UpdateUISettingsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.UpdateUISettingsAction.displayName = 'proto.entity.UpdateUserAction.UpdateUISettingsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.displayName = 'proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.displayName = 'proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.displayName = 'proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Bookmark = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.Bookmark.repeatedFields_, null);
};
goog.inherits(proto.entity.Bookmark, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Bookmark.displayName = 'proto.entity.Bookmark';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BookmarkParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BookmarkParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BookmarkParameter.displayName = 'proto.entity.BookmarkParameter';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UserSelector.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.entity.UserSelector.SelectorCase = {
  SELECTOR_NOT_SET: 0,
  USER_ID: 1,
  EMAIL: 2
};

/**
 * @return {proto.entity.UserSelector.SelectorCase}
 */
proto.entity.UserSelector.prototype.getSelectorCase = function() {
  return /** @type {proto.entity.UserSelector.SelectorCase} */(jspb.Message.computeOneofCase(this, proto.entity.UserSelector.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UserSelector.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UserSelector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UserSelector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserSelector.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UserSelector}
 */
proto.entity.UserSelector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UserSelector;
  return proto.entity.UserSelector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UserSelector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UserSelector}
 */
proto.entity.UserSelector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UserSelector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UserSelector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UserSelector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserSelector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional common.ObjectID user_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.UserSelector.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.UserSelector} returns this
*/
proto.entity.UserSelector.prototype.setUserId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.UserSelector.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserSelector} returns this
 */
proto.entity.UserSelector.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserSelector.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.entity.UserSelector.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UserSelector} returns this
 */
proto.entity.UserSelector.prototype.setEmail = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.entity.UserSelector.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.entity.UserSelector} returns this
 */
proto.entity.UserSelector.prototype.clearEmail = function() {
  return jspb.Message.setOneofField(this, 2, proto.entity.UserSelector.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserSelector.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.User.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    globalRole: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userDetails: (f = msg.getUserDetails()) && proto.entity.UserDetails.toObject(includeInstance, f),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.User}
 */
proto.entity.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.User;
  return proto.entity.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.User}
 */
proto.entity.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.GlobalRole.Option} */ (reader.readEnum());
      msg.setGlobalRole(value);
      break;
    case 4:
      var value = new proto.entity.UserDetails;
      reader.readMessage(value,proto.entity.UserDetails.deserializeBinaryFromReader);
      msg.setUserDetails(value);
      break;
    case 3:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getGlobalRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.UserDetails.serializeBinaryToWriter
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.User.OrganizationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.User.OrganizationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.User.OrganizationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.User.OrganizationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: (f = msg.getOrganizationId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    isOrganizationAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    defaultSiteRole: (f = msg.getDefaultSiteRole()) && proto.entity.SiteRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.User.OrganizationDetails}
 */
proto.entity.User.OrganizationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.User.OrganizationDetails;
  return proto.entity.User.OrganizationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.User.OrganizationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.User.OrganizationDetails}
 */
proto.entity.User.OrganizationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOrganizationAdmin(value);
      break;
    case 3:
      var value = new proto.entity.SiteRole;
      reader.readMessage(value,proto.entity.SiteRole.deserializeBinaryFromReader);
      msg.setDefaultSiteRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.User.OrganizationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.User.OrganizationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.User.OrganizationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.User.OrganizationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getIsOrganizationAdmin();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDefaultSiteRole();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.SiteRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID organization_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.User.OrganizationDetails.prototype.getOrganizationId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.User.OrganizationDetails} returns this
*/
proto.entity.User.OrganizationDetails.prototype.setOrganizationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.User.OrganizationDetails} returns this
 */
proto.entity.User.OrganizationDetails.prototype.clearOrganizationId = function() {
  return this.setOrganizationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.User.OrganizationDetails.prototype.hasOrganizationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_organization_admin = 2;
 * @return {boolean}
 */
proto.entity.User.OrganizationDetails.prototype.getIsOrganizationAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.User.OrganizationDetails} returns this
 */
proto.entity.User.OrganizationDetails.prototype.setIsOrganizationAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional SiteRole default_site_role = 3;
 * @return {?proto.entity.SiteRole}
 */
proto.entity.User.OrganizationDetails.prototype.getDefaultSiteRole = function() {
  return /** @type{?proto.entity.SiteRole} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteRole, 3));
};


/**
 * @param {?proto.entity.SiteRole|undefined} value
 * @return {!proto.entity.User.OrganizationDetails} returns this
*/
proto.entity.User.OrganizationDetails.prototype.setDefaultSiteRole = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.User.OrganizationDetails} returns this
 */
proto.entity.User.OrganizationDetails.prototype.clearDefaultSiteRole = function() {
  return this.setDefaultSiteRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.User.OrganizationDetails.prototype.hasDefaultSiteRole = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ObjectID user_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.User.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.User} returns this
*/
proto.entity.User.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.User} returns this
 */
proto.entity.User.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.User.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GlobalRole.Option global_role = 2;
 * @return {!proto.entity.GlobalRole.Option}
 */
proto.entity.User.prototype.getGlobalRole = function() {
  return /** @type {!proto.entity.GlobalRole.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.GlobalRole.Option} value
 * @return {!proto.entity.User} returns this
 */
proto.entity.User.prototype.setGlobalRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional UserDetails user_details = 4;
 * @return {?proto.entity.UserDetails}
 */
proto.entity.User.prototype.getUserDetails = function() {
  return /** @type{?proto.entity.UserDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.UserDetails, 4));
};


/**
 * @param {?proto.entity.UserDetails|undefined} value
 * @return {!proto.entity.User} returns this
*/
proto.entity.User.prototype.setUserDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.User} returns this
 */
proto.entity.User.prototype.clearUserDetails = function() {
  return this.setUserDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.User.prototype.hasUserDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.CRUDFields crud_fields = 3;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.User.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 3));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.User} returns this
*/
proto.entity.User.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.User} returns this
 */
proto.entity.User.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.User.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.GlobalRole.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.GlobalRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.GlobalRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.GlobalRole.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.GlobalRole}
 */
proto.entity.GlobalRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.GlobalRole;
  return proto.entity.GlobalRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.GlobalRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.GlobalRole}
 */
proto.entity.GlobalRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.GlobalRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.GlobalRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.GlobalRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.GlobalRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.GlobalRole.Option = {
  UNDEFINED: 0,
  USER: 1,
  SUPERUSER: 2,
  ROBOT_ACCOUNT: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.UserDetails.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UserDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UserDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UserDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    userProfile: (f = msg.getUserProfile()) && proto.entity.UserProfile.toObject(includeInstance, f),
    bookmarksList: jspb.Message.toObjectList(msg.getBookmarksList(),
    proto.entity.Bookmark.toObject, includeInstance),
    uiSettings: (f = msg.getUiSettings()) && proto.entity.UISettings.toObject(includeInstance, f),
    hubSpotContactId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UserDetails}
 */
proto.entity.UserDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UserDetails;
  return proto.entity.UserDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UserDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UserDetails}
 */
proto.entity.UserDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.entity.UserProfile;
      reader.readMessage(value,proto.entity.UserProfile.deserializeBinaryFromReader);
      msg.setUserProfile(value);
      break;
    case 3:
      var value = new proto.entity.Bookmark;
      reader.readMessage(value,proto.entity.Bookmark.deserializeBinaryFromReader);
      msg.addBookmarks(value);
      break;
    case 4:
      var value = new proto.entity.UISettings;
      reader.readMessage(value,proto.entity.UISettings.deserializeBinaryFromReader);
      msg.setUiSettings(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UserDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UserDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UserDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getBookmarksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.entity.Bookmark.serializeBinaryToWriter
    );
  }
  f = message.getUiSettings();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.UISettings.serializeBinaryToWriter
    );
  }
  f = message.getHubSpotContactId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional UserProfile user_profile = 2;
 * @return {?proto.entity.UserProfile}
 */
proto.entity.UserDetails.prototype.getUserProfile = function() {
  return /** @type{?proto.entity.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.entity.UserProfile, 2));
};


/**
 * @param {?proto.entity.UserProfile|undefined} value
 * @return {!proto.entity.UserDetails} returns this
*/
proto.entity.UserDetails.prototype.setUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserDetails} returns this
 */
proto.entity.UserDetails.prototype.clearUserProfile = function() {
  return this.setUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserDetails.prototype.hasUserProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Bookmark bookmarks = 3;
 * @return {!Array<!proto.entity.Bookmark>}
 */
proto.entity.UserDetails.prototype.getBookmarksList = function() {
  return /** @type{!Array<!proto.entity.Bookmark>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.Bookmark, 3));
};


/**
 * @param {!Array<!proto.entity.Bookmark>} value
 * @return {!proto.entity.UserDetails} returns this
*/
proto.entity.UserDetails.prototype.setBookmarksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.Bookmark=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.Bookmark}
 */
proto.entity.UserDetails.prototype.addBookmarks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.Bookmark, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UserDetails} returns this
 */
proto.entity.UserDetails.prototype.clearBookmarksList = function() {
  return this.setBookmarksList([]);
};


/**
 * optional UISettings ui_settings = 4;
 * @return {?proto.entity.UISettings}
 */
proto.entity.UserDetails.prototype.getUiSettings = function() {
  return /** @type{?proto.entity.UISettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.UISettings, 4));
};


/**
 * @param {?proto.entity.UISettings|undefined} value
 * @return {!proto.entity.UserDetails} returns this
*/
proto.entity.UserDetails.prototype.setUiSettings = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserDetails} returns this
 */
proto.entity.UserDetails.prototype.clearUiSettings = function() {
  return this.setUiSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserDetails.prototype.hasUiSettings = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string hub_spot_contact_id = 5;
 * @return {string}
 */
proto.entity.UserDetails.prototype.getHubSpotContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UserDetails} returns this
 */
proto.entity.UserDetails.prototype.setHubSpotContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    oidcProfile: (f = msg.getOidcProfile()) && proto_entity_oidcProfile_pb.OIDCProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UserProfile}
 */
proto.entity.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UserProfile;
  return proto.entity.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UserProfile}
 */
proto.entity.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_oidcProfile_pb.OIDCProfile;
      reader.readMessage(value,proto_entity_oidcProfile_pb.OIDCProfile.deserializeBinaryFromReader);
      msg.setOidcProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOidcProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_oidcProfile_pb.OIDCProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional OIDCProfile oidc_profile = 1;
 * @return {?proto.entity.OIDCProfile}
 */
proto.entity.UserProfile.prototype.getOidcProfile = function() {
  return /** @type{?proto.entity.OIDCProfile} */ (
    jspb.Message.getWrapperField(this, proto_entity_oidcProfile_pb.OIDCProfile, 1));
};


/**
 * @param {?proto.entity.OIDCProfile|undefined} value
 * @return {!proto.entity.UserProfile} returns this
*/
proto.entity.UserProfile.prototype.setOidcProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserProfile} returns this
 */
proto.entity.UserProfile.prototype.clearOidcProfile = function() {
  return this.setOidcProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserProfile.prototype.hasOidcProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UISettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UISettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UISettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UISettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardIntroVideoDismissed: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ampdDashboardV2OptIn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UISettings}
 */
proto.entity.UISettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UISettings;
  return proto.entity.UISettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UISettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UISettings}
 */
proto.entity.UISettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDashboardIntroVideoDismissed(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmpdDashboardV2OptIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UISettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UISettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UISettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UISettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardIntroVideoDismissed();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAmpdDashboardV2OptIn();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 dashboard_intro_video_dismissed = 1;
 * @return {number}
 */
proto.entity.UISettings.prototype.getDashboardIntroVideoDismissed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.UISettings} returns this
 */
proto.entity.UISettings.prototype.setDashboardIntroVideoDismissed = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool ampd_dashboard_v2_opt_in = 2;
 * @return {boolean}
 */
proto.entity.UISettings.prototype.getAmpdDashboardV2OptIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.UISettings} returns this
 */
proto.entity.UISettings.prototype.setAmpdDashboardV2OptIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SignUpProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SignUpProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SignUpProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SignUpProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    company: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    country: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SignUpProfile}
 */
proto.entity.SignUpProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SignUpProfile;
  return proto.entity.SignUpProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SignUpProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SignUpProfile}
 */
proto.entity.SignUpProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SignUpProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SignUpProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SignUpProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SignUpProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain = 2;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string company = 5;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone = 7;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.entity.SignUpProfile.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SignUpProfile} returns this
 */
proto.entity.SignUpProfile.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.TrialDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.TrialDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.TrialDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.TrialDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    signUpProfile: (f = msg.getSignUpProfile()) && proto.entity.SignUpProfile.toObject(includeInstance, f),
    authToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialQuota: jspb.Message.getFieldWithDefault(msg, 3, 0),
    remainingQuota: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalConsumed: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trialStartTime: (f = msg.getTrialStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.TrialDetails}
 */
proto.entity.TrialDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.TrialDetails;
  return proto.entity.TrialDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.TrialDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.TrialDetails}
 */
proto.entity.TrialDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SignUpProfile;
      reader.readMessage(value,proto.entity.SignUpProfile.deserializeBinaryFromReader);
      msg.setSignUpProfile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInitialQuota(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingQuota(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalConsumed(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTrialStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.TrialDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.TrialDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.TrialDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.TrialDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignUpProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SignUpProfile.serializeBinaryToWriter
    );
  }
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialQuota();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRemainingQuota();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalConsumed();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTrialStartTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional SignUpProfile sign_up_profile = 1;
 * @return {?proto.entity.SignUpProfile}
 */
proto.entity.TrialDetails.prototype.getSignUpProfile = function() {
  return /** @type{?proto.entity.SignUpProfile} */ (
    jspb.Message.getWrapperField(this, proto.entity.SignUpProfile, 1));
};


/**
 * @param {?proto.entity.SignUpProfile|undefined} value
 * @return {!proto.entity.TrialDetails} returns this
*/
proto.entity.TrialDetails.prototype.setSignUpProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.TrialDetails} returns this
 */
proto.entity.TrialDetails.prototype.clearSignUpProfile = function() {
  return this.setSignUpProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.TrialDetails.prototype.hasSignUpProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string auth_token = 2;
 * @return {string}
 */
proto.entity.TrialDetails.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.TrialDetails} returns this
 */
proto.entity.TrialDetails.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 initial_quota = 3;
 * @return {number}
 */
proto.entity.TrialDetails.prototype.getInitialQuota = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.TrialDetails} returns this
 */
proto.entity.TrialDetails.prototype.setInitialQuota = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 remaining_quota = 4;
 * @return {number}
 */
proto.entity.TrialDetails.prototype.getRemainingQuota = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.TrialDetails} returns this
 */
proto.entity.TrialDetails.prototype.setRemainingQuota = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_consumed = 6;
 * @return {number}
 */
proto.entity.TrialDetails.prototype.getTotalConsumed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.TrialDetails} returns this
 */
proto.entity.TrialDetails.prototype.setTotalConsumed = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp trial_start_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.TrialDetails.prototype.getTrialStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.TrialDetails} returns this
*/
proto.entity.TrialDetails.prototype.setTrialStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.TrialDetails} returns this
 */
proto.entity.TrialDetails.prototype.clearTrialStartTime = function() {
  return this.setTrialStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.TrialDetails.prototype.hasTrialStartTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UserSiteMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UserSiteMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UserSiteMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserSiteMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    siteId: (f = msg.getSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    siteRole: jspb.Message.getFieldWithDefault(msg, 3, 0),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UserSiteMapping}
 */
proto.entity.UserSiteMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UserSiteMapping;
  return proto.entity.UserSiteMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UserSiteMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UserSiteMapping}
 */
proto.entity.UserSiteMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.SiteRole.Option} */ (reader.readEnum());
      msg.setSiteRole(value);
      break;
    case 4:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UserSiteMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UserSiteMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UserSiteMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserSiteMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getSiteRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID user_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.UserSiteMapping.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.UserSiteMapping} returns this
*/
proto.entity.UserSiteMapping.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserSiteMapping} returns this
 */
proto.entity.UserSiteMapping.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserSiteMapping.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ObjectID site_id = 2;
 * @return {?proto.common.ObjectID}
 */
proto.entity.UserSiteMapping.prototype.getSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 2));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.UserSiteMapping} returns this
*/
proto.entity.UserSiteMapping.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserSiteMapping} returns this
 */
proto.entity.UserSiteMapping.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserSiteMapping.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SiteRole.Option site_role = 3;
 * @return {!proto.entity.SiteRole.Option}
 */
proto.entity.UserSiteMapping.prototype.getSiteRole = function() {
  return /** @type {!proto.entity.SiteRole.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.SiteRole.Option} value
 * @return {!proto.entity.UserSiteMapping} returns this
 */
proto.entity.UserSiteMapping.prototype.setSiteRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional common.CRUDFields crud_fields = 4;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.UserSiteMapping.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 4));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.UserSiteMapping} returns this
*/
proto.entity.UserSiteMapping.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserSiteMapping} returns this
 */
proto.entity.UserSiteMapping.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserSiteMapping.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteUser.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    siteRole: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userProfile: (f = msg.getUserProfile()) && proto.entity.UserProfile.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hubSpotContactId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteUser}
 */
proto.entity.SiteUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteUser;
  return proto.entity.SiteUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteUser}
 */
proto.entity.SiteUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.SiteRole.Option} */ (reader.readEnum());
      msg.setSiteRole(value);
      break;
    case 3:
      var value = new proto.entity.UserProfile;
      reader.readMessage(value,proto.entity.UserProfile.deserializeBinaryFromReader);
      msg.setUserProfile(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getSiteRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserProfile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHubSpotContactId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional common.ObjectID user_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteUser.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteUser} returns this
*/
proto.entity.SiteUser.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteUser} returns this
 */
proto.entity.SiteUser.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteUser.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SiteRole.Option site_role = 2;
 * @return {!proto.entity.SiteRole.Option}
 */
proto.entity.SiteUser.prototype.getSiteRole = function() {
  return /** @type {!proto.entity.SiteRole.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.SiteRole.Option} value
 * @return {!proto.entity.SiteUser} returns this
 */
proto.entity.SiteUser.prototype.setSiteRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional UserProfile user_profile = 3;
 * @return {?proto.entity.UserProfile}
 */
proto.entity.SiteUser.prototype.getUserProfile = function() {
  return /** @type{?proto.entity.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.entity.UserProfile, 3));
};


/**
 * @param {?proto.entity.UserProfile|undefined} value
 * @return {!proto.entity.SiteUser} returns this
*/
proto.entity.SiteUser.prototype.setUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteUser} returns this
 */
proto.entity.SiteUser.prototype.clearUserProfile = function() {
  return this.setUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteUser.prototype.hasUserProfile = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.entity.SiteUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteUser} returns this
 */
proto.entity.SiteUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string hub_spot_contact_id = 5;
 * @return {string}
 */
proto.entity.SiteUser.prototype.getHubSpotContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteUser} returns this
 */
proto.entity.SiteUser.prototype.setHubSpotContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteRole.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteRole.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteRole}
 */
proto.entity.SiteRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteRole;
  return proto.entity.SiteRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteRole}
 */
proto.entity.SiteRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.SiteRole.Option = {
  UNDEFINED: 0,
  NONE: 3,
  USER: 1,
  ADMIN: 2,
  DISABLED: 4
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UpdateUserAction.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.entity.UpdateUserAction.ActionCase = {
  ACTION_NOT_SET: 0,
  SET_GLOBAL_ROLE: 1,
  DELETE_USER: 2,
  UNDELETE_USER: 3,
  UPDATE_BOOKMARKS: 4,
  UPDATE_UI_SETTINGS: 5,
  UPDATE_HUB_SPOT_CONTACT_ID: 6
};

/**
 * @return {proto.entity.UpdateUserAction.ActionCase}
 */
proto.entity.UpdateUserAction.prototype.getActionCase = function() {
  return /** @type {proto.entity.UpdateUserAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.entity.UpdateUserAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    setGlobalRole: (f = msg.getSetGlobalRole()) && proto.entity.UpdateUserAction.SetGlobalRoleAction.toObject(includeInstance, f),
    deleteUser: (f = msg.getDeleteUser()) && proto.entity.UpdateUserAction.DeleteUserAction.toObject(includeInstance, f),
    undeleteUser: (f = msg.getUndeleteUser()) && proto.entity.UpdateUserAction.UndeleteUserAction.toObject(includeInstance, f),
    updateBookmarks: (f = msg.getUpdateBookmarks()) && proto.entity.UpdateUserAction.UpdateBookmarksAction.toObject(includeInstance, f),
    updateUiSettings: (f = msg.getUpdateUiSettings()) && proto.entity.UpdateUserAction.UpdateUISettingsAction.toObject(includeInstance, f),
    updateHubSpotContactId: (f = msg.getUpdateHubSpotContactId()) && proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction}
 */
proto.entity.UpdateUserAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction;
  return proto.entity.UpdateUserAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction}
 */
proto.entity.UpdateUserAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.UpdateUserAction.SetGlobalRoleAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.SetGlobalRoleAction.deserializeBinaryFromReader);
      msg.setSetGlobalRole(value);
      break;
    case 2:
      var value = new proto.entity.UpdateUserAction.DeleteUserAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.DeleteUserAction.deserializeBinaryFromReader);
      msg.setDeleteUser(value);
      break;
    case 3:
      var value = new proto.entity.UpdateUserAction.UndeleteUserAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.UndeleteUserAction.deserializeBinaryFromReader);
      msg.setUndeleteUser(value);
      break;
    case 4:
      var value = new proto.entity.UpdateUserAction.UpdateBookmarksAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.UpdateBookmarksAction.deserializeBinaryFromReader);
      msg.setUpdateBookmarks(value);
      break;
    case 5:
      var value = new proto.entity.UpdateUserAction.UpdateUISettingsAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.UpdateUISettingsAction.deserializeBinaryFromReader);
      msg.setUpdateUiSettings(value);
      break;
    case 6:
      var value = new proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.deserializeBinaryFromReader);
      msg.setUpdateHubSpotContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetGlobalRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.UpdateUserAction.SetGlobalRoleAction.serializeBinaryToWriter
    );
  }
  f = message.getDeleteUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UpdateUserAction.DeleteUserAction.serializeBinaryToWriter
    );
  }
  f = message.getUndeleteUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.UpdateUserAction.UndeleteUserAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBookmarks();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.UpdateUserAction.UpdateBookmarksAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUiSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.UpdateUserAction.UpdateUISettingsAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateHubSpotContactId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.SetGlobalRoleAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.SetGlobalRoleAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalRole: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.SetGlobalRoleAction}
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.SetGlobalRoleAction;
  return proto.entity.UpdateUserAction.SetGlobalRoleAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.SetGlobalRoleAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.SetGlobalRoleAction}
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.GlobalRole.Option} */ (reader.readEnum());
      msg.setGlobalRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.SetGlobalRoleAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.SetGlobalRoleAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional GlobalRole.Option global_role = 1;
 * @return {!proto.entity.GlobalRole.Option}
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.prototype.getGlobalRole = function() {
  return /** @type {!proto.entity.GlobalRole.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.GlobalRole.Option} value
 * @return {!proto.entity.UpdateUserAction.SetGlobalRoleAction} returns this
 */
proto.entity.UpdateUserAction.SetGlobalRoleAction.prototype.setGlobalRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.DeleteUserAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.DeleteUserAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.DeleteUserAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.DeleteUserAction.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.DeleteUserAction}
 */
proto.entity.UpdateUserAction.DeleteUserAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.DeleteUserAction;
  return proto.entity.UpdateUserAction.DeleteUserAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.DeleteUserAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.DeleteUserAction}
 */
proto.entity.UpdateUserAction.DeleteUserAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.DeleteUserAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.DeleteUserAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.DeleteUserAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.DeleteUserAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.UndeleteUserAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.UndeleteUserAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.UndeleteUserAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UndeleteUserAction.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.UndeleteUserAction}
 */
proto.entity.UpdateUserAction.UndeleteUserAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.UndeleteUserAction;
  return proto.entity.UpdateUserAction.UndeleteUserAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.UndeleteUserAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.UndeleteUserAction}
 */
proto.entity.UpdateUserAction.UndeleteUserAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.UndeleteUserAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.UndeleteUserAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.UndeleteUserAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UndeleteUserAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.UpdateBookmarksAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.UpdateBookmarksAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureCategory: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bookmarksList: jspb.Message.toObjectList(msg.getBookmarksList(),
    proto.entity.Bookmark.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.UpdateBookmarksAction}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.UpdateBookmarksAction;
  return proto.entity.UpdateUserAction.UpdateBookmarksAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.UpdateBookmarksAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.UpdateBookmarksAction}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatureCategory(value);
      break;
    case 2:
      var value = new proto.entity.Bookmark;
      reader.readMessage(value,proto.entity.Bookmark.deserializeBinaryFromReader);
      msg.addBookmarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.UpdateBookmarksAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.UpdateBookmarksAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookmarksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.entity.Bookmark.serializeBinaryToWriter
    );
  }
};


/**
 * optional string feature_category = 1;
 * @return {string}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.getFeatureCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateUserAction.UpdateBookmarksAction} returns this
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.setFeatureCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Bookmark bookmarks = 2;
 * @return {!Array<!proto.entity.Bookmark>}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.getBookmarksList = function() {
  return /** @type{!Array<!proto.entity.Bookmark>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.Bookmark, 2));
};


/**
 * @param {!Array<!proto.entity.Bookmark>} value
 * @return {!proto.entity.UpdateUserAction.UpdateBookmarksAction} returns this
*/
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.setBookmarksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.Bookmark=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.Bookmark}
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.addBookmarks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.Bookmark, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UpdateUserAction.UpdateBookmarksAction} returns this
 */
proto.entity.UpdateUserAction.UpdateBookmarksAction.prototype.clearBookmarksList = function() {
  return this.setBookmarksList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.ActionCase = {
  ACTION_NOT_SET: 0,
  UPDATE_DASHBOARD_INTRO_VIDEO_DISMISSED: 1,
  UPDATE_AMPD_DASHBOARD_V2_OPT_IN: 2
};

/**
 * @return {proto.entity.UpdateUserAction.UpdateUISettingsAction.ActionCase}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.getActionCase = function() {
  return /** @type {proto.entity.UpdateUserAction.UpdateUISettingsAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.entity.UpdateUserAction.UpdateUISettingsAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.UpdateUISettingsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateDashboardIntroVideoDismissed: (f = msg.getUpdateDashboardIntroVideoDismissed()) && proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.toObject(includeInstance, f),
    updateAmpdDashboardV2OptIn: (f = msg.getUpdateAmpdDashboardV2OptIn()) && proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.UpdateUISettingsAction;
  return proto.entity.UpdateUserAction.UpdateUISettingsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.deserializeBinaryFromReader);
      msg.setUpdateDashboardIntroVideoDismissed(value);
      break;
    case 2:
      var value = new proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction;
      reader.readMessage(value,proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.deserializeBinaryFromReader);
      msg.setUpdateAmpdDashboardV2OptIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.UpdateUISettingsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateDashboardIntroVideoDismissed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAmpdDashboardV2OptIn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    dismissedAt: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction;
  return proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDismissedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDismissedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 dismissed_at = 1;
 * @return {number}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.prototype.getDismissedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction} returns this
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction.prototype.setDismissedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    optIn: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction;
  return proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptIn();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool opt_in = 1;
 * @return {boolean}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.prototype.getOptIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction} returns this
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction.prototype.setOptIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional UpdateDashboardIntroVideoDismissedAction update_dashboard_intro_video_dismissed = 1;
 * @return {?proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.getUpdateDashboardIntroVideoDismissed = function() {
  return /** @type{?proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction, 1));
};


/**
 * @param {?proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateDashboardIntroVideoDismissedAction|undefined} value
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction} returns this
*/
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.setUpdateDashboardIntroVideoDismissed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.UpdateUserAction.UpdateUISettingsAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction} returns this
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.clearUpdateDashboardIntroVideoDismissed = function() {
  return this.setUpdateDashboardIntroVideoDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.hasUpdateDashboardIntroVideoDismissed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateAmpdDashboardV2OptInAction update_ampd_dashboard_v2_opt_in = 2;
 * @return {?proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.getUpdateAmpdDashboardV2OptIn = function() {
  return /** @type{?proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction, 2));
};


/**
 * @param {?proto.entity.UpdateUserAction.UpdateUISettingsAction.UpdateAmpdDashboardV2OptInAction|undefined} value
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction} returns this
*/
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.setUpdateAmpdDashboardV2OptIn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.UpdateUserAction.UpdateUISettingsAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction.UpdateUISettingsAction} returns this
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.clearUpdateAmpdDashboardV2OptIn = function() {
  return this.setUpdateAmpdDashboardV2OptIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.UpdateUISettingsAction.prototype.hasUpdateAmpdDashboardV2OptIn = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newHubSpotContactId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction}
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction;
  return proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction}
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewHubSpotContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewHubSpotContactId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_hub_spot_contact_id = 1;
 * @return {string}
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.prototype.getNewHubSpotContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction} returns this
 */
proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction.prototype.setNewHubSpotContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SetGlobalRoleAction set_global_role = 1;
 * @return {?proto.entity.UpdateUserAction.SetGlobalRoleAction}
 */
proto.entity.UpdateUserAction.prototype.getSetGlobalRole = function() {
  return /** @type{?proto.entity.UpdateUserAction.SetGlobalRoleAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.SetGlobalRoleAction, 1));
};


/**
 * @param {?proto.entity.UpdateUserAction.SetGlobalRoleAction|undefined} value
 * @return {!proto.entity.UpdateUserAction} returns this
*/
proto.entity.UpdateUserAction.prototype.setSetGlobalRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.UpdateUserAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction} returns this
 */
proto.entity.UpdateUserAction.prototype.clearSetGlobalRole = function() {
  return this.setSetGlobalRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.prototype.hasSetGlobalRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteUserAction delete_user = 2;
 * @return {?proto.entity.UpdateUserAction.DeleteUserAction}
 */
proto.entity.UpdateUserAction.prototype.getDeleteUser = function() {
  return /** @type{?proto.entity.UpdateUserAction.DeleteUserAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.DeleteUserAction, 2));
};


/**
 * @param {?proto.entity.UpdateUserAction.DeleteUserAction|undefined} value
 * @return {!proto.entity.UpdateUserAction} returns this
*/
proto.entity.UpdateUserAction.prototype.setDeleteUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.UpdateUserAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction} returns this
 */
proto.entity.UpdateUserAction.prototype.clearDeleteUser = function() {
  return this.setDeleteUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.prototype.hasDeleteUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UndeleteUserAction undelete_user = 3;
 * @return {?proto.entity.UpdateUserAction.UndeleteUserAction}
 */
proto.entity.UpdateUserAction.prototype.getUndeleteUser = function() {
  return /** @type{?proto.entity.UpdateUserAction.UndeleteUserAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.UndeleteUserAction, 3));
};


/**
 * @param {?proto.entity.UpdateUserAction.UndeleteUserAction|undefined} value
 * @return {!proto.entity.UpdateUserAction} returns this
*/
proto.entity.UpdateUserAction.prototype.setUndeleteUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.UpdateUserAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction} returns this
 */
proto.entity.UpdateUserAction.prototype.clearUndeleteUser = function() {
  return this.setUndeleteUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.prototype.hasUndeleteUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UpdateBookmarksAction update_bookmarks = 4;
 * @return {?proto.entity.UpdateUserAction.UpdateBookmarksAction}
 */
proto.entity.UpdateUserAction.prototype.getUpdateBookmarks = function() {
  return /** @type{?proto.entity.UpdateUserAction.UpdateBookmarksAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.UpdateBookmarksAction, 4));
};


/**
 * @param {?proto.entity.UpdateUserAction.UpdateBookmarksAction|undefined} value
 * @return {!proto.entity.UpdateUserAction} returns this
*/
proto.entity.UpdateUserAction.prototype.setUpdateBookmarks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.UpdateUserAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction} returns this
 */
proto.entity.UpdateUserAction.prototype.clearUpdateBookmarks = function() {
  return this.setUpdateBookmarks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.prototype.hasUpdateBookmarks = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdateUISettingsAction update_ui_settings = 5;
 * @return {?proto.entity.UpdateUserAction.UpdateUISettingsAction}
 */
proto.entity.UpdateUserAction.prototype.getUpdateUiSettings = function() {
  return /** @type{?proto.entity.UpdateUserAction.UpdateUISettingsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.UpdateUISettingsAction, 5));
};


/**
 * @param {?proto.entity.UpdateUserAction.UpdateUISettingsAction|undefined} value
 * @return {!proto.entity.UpdateUserAction} returns this
*/
proto.entity.UpdateUserAction.prototype.setUpdateUiSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.entity.UpdateUserAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction} returns this
 */
proto.entity.UpdateUserAction.prototype.clearUpdateUiSettings = function() {
  return this.setUpdateUiSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.prototype.hasUpdateUiSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UpdateHubSpotContactIDAction update_hub_spot_contact_id = 6;
 * @return {?proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction}
 */
proto.entity.UpdateUserAction.prototype.getUpdateHubSpotContactId = function() {
  return /** @type{?proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction, 6));
};


/**
 * @param {?proto.entity.UpdateUserAction.UpdateHubSpotContactIDAction|undefined} value
 * @return {!proto.entity.UpdateUserAction} returns this
*/
proto.entity.UpdateUserAction.prototype.setUpdateHubSpotContactId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.entity.UpdateUserAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateUserAction} returns this
 */
proto.entity.UpdateUserAction.prototype.clearUpdateHubSpotContactId = function() {
  return this.setUpdateHubSpotContactId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateUserAction.prototype.hasUpdateHubSpotContactId = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.Bookmark.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Bookmark.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Bookmark.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Bookmark} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Bookmark.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureCategory: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.entity.BookmarkParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Bookmark}
 */
proto.entity.Bookmark.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Bookmark;
  return proto.entity.Bookmark.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Bookmark} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Bookmark}
 */
proto.entity.Bookmark.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatureCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.entity.BookmarkParameter;
      reader.readMessage(value,proto.entity.BookmarkParameter.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Bookmark.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Bookmark.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Bookmark} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Bookmark.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.entity.BookmarkParameter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string feature_category = 1;
 * @return {string}
 */
proto.entity.Bookmark.prototype.getFeatureCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Bookmark} returns this
 */
proto.entity.Bookmark.prototype.setFeatureCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.Bookmark.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Bookmark} returns this
 */
proto.entity.Bookmark.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated BookmarkParameter parameters = 3;
 * @return {!Array<!proto.entity.BookmarkParameter>}
 */
proto.entity.Bookmark.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.entity.BookmarkParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.BookmarkParameter, 3));
};


/**
 * @param {!Array<!proto.entity.BookmarkParameter>} value
 * @return {!proto.entity.Bookmark} returns this
*/
proto.entity.Bookmark.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.BookmarkParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.BookmarkParameter}
 */
proto.entity.Bookmark.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.BookmarkParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.Bookmark} returns this
 */
proto.entity.Bookmark.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BookmarkParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BookmarkParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BookmarkParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BookmarkParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BookmarkParameter}
 */
proto.entity.BookmarkParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BookmarkParameter;
  return proto.entity.BookmarkParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BookmarkParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BookmarkParameter}
 */
proto.entity.BookmarkParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BookmarkParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BookmarkParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BookmarkParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BookmarkParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.BookmarkParameter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.BookmarkParameter} returns this
 */
proto.entity.BookmarkParameter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.entity.BookmarkParameter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.BookmarkParameter} returns this
 */
proto.entity.BookmarkParameter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.entity);
