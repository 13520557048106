import { FormField } from "./CampaignSetupPageState";

const walmartProfileNameValidator = (profileName: string): Set<string> => {
  const errors = new Set<string>();

  // profileName field can be empty to denote custom list of brands.
  if (!profileName) {
    return errors;
  }

  // TODO: Accept any name, although perhaps we could validate by checking
  // the profile names currently listed on the site.

  return errors;
};

export const walmartProfileNameField = (
  initialValue: string
): FormField<string> => ({
  _value: initialValue,
  errors: walmartProfileNameValidator(initialValue),
  validator: walmartProfileNameValidator
});
