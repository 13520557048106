/* 
  Shared Table Preferences
*/
import { useCallback, useState } from "react";
import {
  getLocalStorageListByKey,
  setLocalStorageValue
} from "./localStorageUtil";

const EXCLUDE_LAG_PERIOD_STORAGE_KEY = "excludeLagPeriod";

/**
 * @typedef {Array} LagPeriodSetting
 * @property {boolean} 0 - excludeLagPeriod
 * @property {function} 1 - setExcludeLagPeriod
 */

/**
 * @returns {LagPeriodSetting}
 */
export const useAmazonLagPeriodSetting = () => {
  const [excludeLagPeriod, _setExcludeLagPeriod] = useState(
    getStoredExcludeLagPeriod()
  );

  const setExcludeLagPeriod = useCallback(excludeLagPeriod => {
    setStoredExcludeLagPeriod(excludeLagPeriod);
    _setExcludeLagPeriod(excludeLagPeriod);
  }, []);

  return [excludeLagPeriod, setExcludeLagPeriod];
};

export const getStoredExcludeLagPeriod = () => {
  let boolString = localStorage.getItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  if (!boolString) {
    // default behavior is to exclude the lag period if a preference has not been set by the user
    return true;
  }

  return boolString === "true";
};

export const setStoredExcludeLagPeriod = bool => {
  setLocalStorageValue(EXCLUDE_LAG_PERIOD_STORAGE_KEY, bool);
};

const SHOW_FRACTIONS_STORAGE_KEY = "showFractions";

export const getStoredShowFractions = () => {
  let boolString = localStorage.getItem(SHOW_FRACTIONS_STORAGE_KEY);
  if (!boolString) {
    // default behavior is to NOT show fractions if a preference has not been set by the user
    return false;
  }

  return boolString === "true";
};

export const setStoredShowFractions = bool => {
  setLocalStorageValue(SHOW_FRACTIONS_STORAGE_KEY, bool);
};

const SHOW_UNCONVERTED_REVENUE_STORAGE_KEY = "showUnconvertedRevenue";

export const getStoredShowUnconvertedRevenue = () => {
  let boolString = localStorage.getItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
  if (!boolString) {
    // default behavior is to show unconverted revenue numbers, if a preference has not been set by the user.
    return true;
  }

  return boolString === "true";
};

export const setStoredShowUnconvertedRevenue = bool => {
  setLocalStorageValue(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY, bool);
};

/*
  Campaigns Table Preferences for Amazon campaigns
*/

const AMAZON_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY =
  "campaignsTableDataColumns";

export const getStoredAmazonCampaignsTableDataColumns = () => {
  return getLocalStorageListByKey(
    AMAZON_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
};

export const setStoredAmazonCampaignsTableDateColumns = columnsArray => {
  setLocalStorageValue(
    AMAZON_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY,
    columnsArray
  );
};

export const resetAmazonCampaignsTableOptions = () => {
  localStorage.removeItem(AMAZON_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY);
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Campaigns Table Preferences for Walmart campaigns
*/

const WALMART_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY =
  "walmartCampaignsTableDataColumns";

export const getStoredWalmartCampaignsTableDataColumns = () => {
  return getLocalStorageListByKey(
    WALMART_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
};

export const setStoredWalmartCampaignsTableDateColumns = columnsArray => {
  setLocalStorageValue(
    WALMART_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY,
    columnsArray
  );
};

export const resetWalmartCampaignsTableOptions = () => {
  localStorage.removeItem(WALMART_CAMPAIGNS_TABLE_DATA_COLUMNS_STORAGE_KEY);
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Campaigns Table Status Preferences
*/

const CAMPAIGN_STATUS_STORAGE_KEY = "campaignStatus";

export const getStoredCampaignStatusOption = () => {
  return localStorage.getItem(CAMPAIGN_STATUS_STORAGE_KEY);
};

export const setStoredCampaignStatusOption = option => {
  setLocalStorageValue(CAMPAIGN_STATUS_STORAGE_KEY, option);
};

/*
  Campaigns Details Table Preferences for Amazon Campaigns
*/

const AMAZON_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY =
  "campaignDetailsTableDataColumns";

export const getStoredAmazonCampaignDetailsTableDataColumns = () => {
  return getLocalStorageListByKey(
    AMAZON_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
};

export const setStoredAmazonCampaignDetailsTableDateColumns = columnsArray => {
  setLocalStorageValue(
    AMAZON_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY,
    columnsArray
  );
};

export const resetAmazonCampaignDetailsTableOptions = () => {
  localStorage.removeItem(
    AMAZON_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Campaigns Details Table Preferences for Walmart Campaigns
*/

const WALMART_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY =
  "walmartCampaignDetailsTableDataColumns";

export const getStoredWalmartCampaignDetailsTableDataColumns = () => {
  return getLocalStorageListByKey(
    WALMART_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
};

export const setStoredWalmartCampaignDetailsTableDateColumns = columnsArray => {
  setLocalStorageValue(
    WALMART_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY,
    columnsArray
  );
};

export const resetWalmartCampaignDetailsTableOptions = () => {
  localStorage.removeItem(
    WALMART_CAMPAIGN_DETAILS_TABLE_DATA_COLUMNS_STORAGE_KEY
  );
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

/*
  Accounts Table Preferences
*/

const ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY = "accountsTableDataColumns";

export const getStoredAccountsTableDataColumns = () => {
  return getLocalStorageListByKey(ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY);
};

export const setStoredAccountsTableDateColumns = columnsArray => {
  setLocalStorageValue(ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY, columnsArray);
};

export const resetAllAccountsTableOptions = () => {
  localStorage.removeItem(ACCOUNTS_TABLE_DATA_COLUMNS_STORAGE_KEY);
  localStorage.removeItem(EXCLUDE_LAG_PERIOD_STORAGE_KEY);
  localStorage.removeItem(SHOW_FRACTIONS_STORAGE_KEY);
  localStorage.removeItem(SHOW_UNCONVERTED_REVENUE_STORAGE_KEY);
};

const ACCOUNTS_CAMPAIGN_STATUS_STORAGE_KEY = "accountsCampaignStatus";

export const getStoredAccountsCampaignStatusOption = () => {
  return localStorage.getItem(ACCOUNTS_CAMPAIGN_STATUS_STORAGE_KEY);
};

export const setStoredAccountsCampaignStatusOption = option => {
  setLocalStorageValue(ACCOUNTS_CAMPAIGN_STATUS_STORAGE_KEY, option);
};

/*
  Facebook Table Preferences
*/
const FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY = "facebookTableDataColumns";

export const getStoredFacebookTableDataColumns = () => {
  return getLocalStorageListByKey(FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY);
};

export const setStoredFacebookTableDataColumns = columnsArray => {
  setLocalStorageValue(FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY, columnsArray);
};

export const resetAllFacebookTableOptions = () => {
  localStorage.removeItem(FACEBOOK_TABLE_DATA_COLUMNS_STORAGE_KEY);
};
