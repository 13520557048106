// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  CustomerInvoice,
  GetCustomerInvoicesRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Stripe_pb";

const STRIPE_STATUS_STRINGS = Object.keys(CustomerInvoice.Status);

// Given a Stripe status enum value, return its enum key string
export const getStripeStatusByEnum = statusEnumValue => {
  const UNKNOWN = CustomerInvoice.Status.UNKNOWN;

  return (
    STRIPE_STATUS_STRINGS[statusEnumValue] ?? STRIPE_STATUS_STRINGS[UNKNOWN]
  );
};

export default async function getInvoices({ siteAlias }) {
  const req = proto.set(new GetCustomerInvoicesRequest(), { siteAlias });

  // Retrieve & transform the invoice response for use
  return GRPCWebClient.getCustomerInvoices(req).then(res => {
    return (
      res?.getInvoicesList()?.map(i => {
        const invoice = i.toObject();
        return { ...invoice, status: getStripeStatusByEnum(invoice.status) };
      }) ?? []
    );
  });
}
