// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { RefreshBillingAccountRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";

export default async function refreshBillingAccount(siteAlias) {
  if (!siteAlias) {
    return;
  }

  const refreshReq = proto.set(new RefreshBillingAccountRequest(), {
    siteAlias: String(siteAlias)
  });
  try {
    await GRPCWebClient.refreshBillingAccount(refreshReq);
  } catch (e) {
    console.error(e);
  }
}
