import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from "react";
import { Message } from "semantic-ui-react";

import {
  ALL_BUT_REMOVED_STATUS,
  ENABLED_STATUS,
  PAUSED_STATUS,
  REMOVED_STATUS
} from "ExtensionV2/components/MetricColumns";
import {
  calculateDerivedMetrics,
  COLUMN_DATA_KEYS,
  COLUMN_DISPLAY_NAME_FROM_DATA_KEY,
  METRIC_COLUMNS
} from "../../components/MetricColumns";
import useCampaignObjects, {
  getAuditInfoRefreshCount
} from "ExtensionV2/state/useCampaignObjects";
import CampaignsPageRenderer from "./CampaignsPageRenderer";
import useKeywordObjects, {
  getKeywordMetricsMapKey
} from "ExtensionV2/state/useKeywordObjects";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useCampaignConfigurationsByCampaignId } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import {
  DashboardTableObjectType,
  useDashboardTableMetrics
} from "../../queries/useDashboardTableMetrics";
import { getItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import Immutable from "immutable";
import { Retailer } from "../../../Common/proto/common/retailer_pb";
import { GlobalDateContext } from "ExtensionV2";
import {
  getStoredAmazonCampaignsTableDataColumns,
  setStoredAmazonCampaignsTableDateColumns,
  resetAmazonCampaignsTableOptions,
  getStoredWalmartCampaignsTableDataColumns,
  setStoredWalmartCampaignsTableDateColumns,
  resetWalmartCampaignsTableOptions
} from "Common/utils/savedTablePreferences";
import { useSearchParams } from "react-router-dom-v5-compat";
import {
  getMarketplaceInfoForDomain,
  isWalmartMarketplaceInfo
} from "../../../Common/utils/marketplace";
import { MARKETPLACE_QUERY_PARAM } from "../../ExtensionV2";

export const AMAZON_AMPD_CAMPAIGN_TYPE = "amazonAmpdCampaigns";
export const WALMART_AMPD_CAMPAIGN_TYPE = "walmartAmpdCampaigns";
export const ANY_AMPD_CAMPAIGN_TYPE = "ampdCampaigns";
export const NON_AMPD_CAMPAIGN_TYPE = "nonAmpdCampaigns";
export const ALL_CAMPAIGN_TYPES = "allCampaignTypes";

// TODO (robert): Remove this set of options when we fully release the Walmart UI.
export const campaignTypeOptionsLegacy = [
  {
    key: AMAZON_AMPD_CAMPAIGN_TYPE,
    text: "Ampd Campaigns",
    value: AMAZON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: NON_AMPD_CAMPAIGN_TYPE,
    text: "Non-Ampd Campaigns",
    value: NON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: ALL_CAMPAIGN_TYPES,
    text: "All Campaigns",
    value: ALL_CAMPAIGN_TYPES
  }
];

export const campaignTypeOptionsWithWalmart = [
  {
    key: AMAZON_AMPD_CAMPAIGN_TYPE,
    text: "Amazon Ampd Campaigns",
    value: AMAZON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: WALMART_AMPD_CAMPAIGN_TYPE,
    text: "Walmart Ampd Campaigns",
    value: WALMART_AMPD_CAMPAIGN_TYPE
  },
  {
    key: NON_AMPD_CAMPAIGN_TYPE,
    text: "Non-Ampd Campaigns",
    value: NON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: ALL_CAMPAIGN_TYPES,
    text: "All Campaigns",
    value: ALL_CAMPAIGN_TYPES
  }
];

export const campaignStatusOptions = [
  {
    key: ALL_BUT_REMOVED_STATUS,
    text: "Enabled and Paused",
    value: ALL_BUT_REMOVED_STATUS
  },
  { key: ENABLED_STATUS, text: "Enabled", value: ENABLED_STATUS },
  { key: PAUSED_STATUS, text: "Paused", value: PAUSED_STATUS },
  { key: REMOVED_STATUS, text: "Removed", value: REMOVED_STATUS }
];

export const UNHIDEABLE_CAMPAIGNS_COLUMNS = [COLUMN_DATA_KEYS.campaignName];

export const DEFAULT_AMAZON_CAMPAIGNS_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status,
  COLUMN_DATA_KEYS.dailyBudget,
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.aacos
];

export const ALL_AMAZON_CAMPAIGNS_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status,
  COLUMN_DATA_KEYS.bidAutomationStatus,
  COLUMN_DATA_KEYS.campaignStartDate,
  COLUMN_DATA_KEYS.dailyBudget,
  COLUMN_DATA_KEYS.adTarget,
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.cpcBid,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.impressionShare,
  COLUMN_DATA_KEYS.lostISLowRank,
  COLUMN_DATA_KEYS.lostISLowBudget,
  COLUMN_DATA_KEYS.clickShare,
  COLUMN_DATA_KEYS.attributedClicks,
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.roas,
  COLUMN_DATA_KEYS.newToBrandRoas,
  COLUMN_DATA_KEYS.aroas,
  COLUMN_DATA_KEYS.acos,
  COLUMN_DATA_KEYS.aacos,
  COLUMN_DATA_KEYS.tacos,
  COLUMN_DATA_KEYS.purchaseFrequency,
  COLUMN_DATA_KEYS.annualizedRevenue,
  COLUMN_DATA_KEYS.annualizedRoas,
  COLUMN_DATA_KEYS.annualizedAroas,
  COLUMN_DATA_KEYS.annualizedAcos,
  COLUMN_DATA_KEYS.annualizedAacos
];

// Strip off the columns that don't apply to Walmart, or we don't want to show by default for Walmart.
export const DEFAULT_WALMART_CAMPAIGNS_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status,
  COLUMN_DATA_KEYS.dailyBudget,
  COLUMN_DATA_KEYS.adTarget,
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.impressionShare
];

// Strip off the columns that don't apply to Walmart.
export const ALL_WALMART_CAMPAIGNS_COLUMNS = ALL_AMAZON_CAMPAIGNS_COLUMNS.filter(
  value =>
    ![
      COLUMN_DATA_KEYS.bidAutomationStatus,
      COLUMN_DATA_KEYS.attributedClicks,
      COLUMN_DATA_KEYS.detailPageViews,
      COLUMN_DATA_KEYS.carts,
      COLUMN_DATA_KEYS.cartRate,
      COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
      COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage,
      COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
      COLUMN_DATA_KEYS.brandReferralBonus,
      COLUMN_DATA_KEYS.newToBrandRoas,
      COLUMN_DATA_KEYS.aroas,
      COLUMN_DATA_KEYS.acos,
      COLUMN_DATA_KEYS.aacos,
      COLUMN_DATA_KEYS.tacos,
      COLUMN_DATA_KEYS.purchaseFrequency,
      COLUMN_DATA_KEYS.annualizedRevenue,
      COLUMN_DATA_KEYS.annualizedRoas,
      COLUMN_DATA_KEYS.annualizedAroas,
      COLUMN_DATA_KEYS.annualizedAcos,
      COLUMN_DATA_KEYS.annualizedAacos
    ].includes(value)
);

// The columns that are summable (or derived from summable columns) that can
// be shown on the Total row.
export const CAMPAIGN_TOTAL_COLUMNS = [
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.attributedClicks,
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.annualizedRevenue,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.newToBrandConversions,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.newToBrandRevenue,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.newToBrandUnitsSold,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage
];

// The Campaigns page columns that are relevant for the keyword sub-table (all
// the columns minus those that are not relevant).
export const CAMPAIGN_KEYWORD_COLUMNS = _.difference(
  ALL_AMAZON_CAMPAIGNS_COLUMNS,
  [
    COLUMN_DATA_KEYS.dailyBudget,
    COLUMN_DATA_KEYS.adTarget,
    COLUMN_DATA_KEYS.campaignStartDate,
    COLUMN_DATA_KEYS.bidAutomationStatus
  ]
);

export const COLUMN_DISPLAY_NAME_FOR_CAMPAIGN_KEYWORDS = CAMPAIGN_KEYWORD_COLUMNS.reduce(
  (obj, column) => {
    if (column === COLUMN_DATA_KEYS.status) {
      obj[column] = "Keyword Status";
    } else {
      obj[column] = COLUMN_DISPLAY_NAME_FROM_DATA_KEY[column];
    }
    return obj;
  },
  {}
);

// The Campaigns page columns that are relevant for the impacted products sub-table.
export const IMPACTED_PRODUCT_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status, // Product
  COLUMN_DATA_KEYS.dailyBudget, // Product Category
  COLUMN_DATA_KEYS.clicks, // Detail Page Views
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.newToBrandConversions,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.newToBrandRevenue,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.newToBrandUnitsSold,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage
];

export const COLUMN_DISPLAY_NAME_FOR_IMPACTED_PRODUCTS = IMPACTED_PRODUCT_COLUMNS.reduce(
  (obj, column) => {
    if (column === COLUMN_DATA_KEYS.status) {
      obj[column] = "Product";
    } else if (column === COLUMN_DATA_KEYS.dailyBudget) {
      obj[column] = "Product Category";
    } else if (column === COLUMN_DATA_KEYS.clicks) {
      obj[column] = "Detail Page Views";
    } else {
      obj[column] = COLUMN_DISPLAY_NAME_FROM_DATA_KEY[column];
    }
    return obj;
  },
  {}
);

const CampaignsPage = () => {
  const {
    adwordsAccounts,
    siteAlias,
    siteCurrencyCode: currencyCode,
    amazonInfo: { advertisingProfiles, advertisingAccounts }
  } = useSessionSite();
  const googleAdsAccount = adwordsAccounts?.[0];
  const amazonAccount = advertisingAccounts?.[0];

  const [showKeywordsForCampaignId, setShowKeywordsForCampaignId] = useState(
    null
  );

  const [queryRemovedCampaigns, setQueryRemovedCampaigns] = useState(false);

  const {
    startDate: dateRangeStartDateString,
    endDate: dateRangeEndDateString,
    compareTo
  } = useContext(GlobalDateContext);

  const {
    data: campaignConfigurationsByCampaignId,
    isLoading: campaignConfigurationsLoading,
    isSuccess: campaignConfigurationsSuccess,
    error: campaignConfigurationsError,
    refetch: refetchCampaignConfigurations
  } = useCampaignConfigurationsByCampaignId(siteAlias);

  useEffect(() => {
    // The first call is for stored configurations directly from our database, but upon
    // success immediately refetch to get the most up-to-date versions of the configurations
    // by querying Google Ads.
    if (campaignConfigurationsSuccess) {
      refetchCampaignConfigurations().finally();
    }
  }, [campaignConfigurationsSuccess, refetchCampaignConfigurations]);

  const [disabledCampaignIds, setDisableCampaignIds] = useState(
    Immutable.Set()
  );

  const refreshCount = getAuditInfoRefreshCount();

  // TODO: useCampaignObjects doesn't handle any network errors.
  const {
    campaignMetricsMap,
    campaignCompareMetricsLoading,
    campaignCompareMetricsMap
  } = useCampaignObjects({
    refreshCount,
    siteAlias,
    currencyCode,
    hasGoogleAdsAccounts: !!googleAdsAccount,
    hasAmazonAccount: !!amazonAccount,
    queryMetricsOnly: true,
    dateRangeStartDate: dateRangeStartDateString,
    dateRangeEndDate: dateRangeEndDateString,
    compareTo,
    queryRemovedCampaigns
  });

  const refetchCampaignConfiguration = useCallback(
    async campaignId => {
      setDisableCampaignIds(ids => ids.add(campaignId));

      const result = await refetchCampaignConfigurations();

      setDisableCampaignIds(ids => ids.remove(campaignId));

      return result;
    },
    [refetchCampaignConfigurations]
  );

  const campaignIdsByCampaignType = useMemo(() => {
    if (campaignConfigurationsByCampaignId) {
      const createdCampaignIds = {
        [AMAZON_AMPD_CAMPAIGN_TYPE]: [],
        [WALMART_AMPD_CAMPAIGN_TYPE]: [],
        [ANY_AMPD_CAMPAIGN_TYPE]: [],
        [NON_AMPD_CAMPAIGN_TYPE]: [],
        [ALL_CAMPAIGN_TYPES]: []
      };
      for (const [
        campaignId,
        ampdCampaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        if (ampdCampaignConfiguration?.ampdProductDetails) {
          createdCampaignIds[ANY_AMPD_CAMPAIGN_TYPE].push(campaignId);
          if (ampdCampaignConfiguration.ampdProductDetails.amazon) {
            createdCampaignIds[AMAZON_AMPD_CAMPAIGN_TYPE].push(campaignId);
          }
          if (ampdCampaignConfiguration.ampdProductDetails.walmart) {
            createdCampaignIds[WALMART_AMPD_CAMPAIGN_TYPE].push(campaignId);
          }
        } else {
          createdCampaignIds[NON_AMPD_CAMPAIGN_TYPE].push(campaignId);
        }
        createdCampaignIds[ALL_CAMPAIGN_TYPES].push(campaignId);
      }

      createdCampaignIds[AMAZON_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[WALMART_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[ANY_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[NON_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[ALL_CAMPAIGN_TYPES].sort();

      return createdCampaignIds;
    }
    return {};
  }, [campaignConfigurationsByCampaignId]);

  const walmartCampaignIdsSet = useMemo(
    () => new Set(campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE]),
    [campaignIdsByCampaignType]
  );

  const {
    keywordsLoading,
    keywordCompareMetricsLoading,
    allKeywords,
    keywordMetricsMap,
    keywordCompareMetricsMap,
    refetchCampaignKeyword,
    disabledCriteriaIds
  } = useKeywordObjects({
    refreshCount,
    siteAlias,
    campaignIds: campaignIdsByCampaignType[ANY_AMPD_CAMPAIGN_TYPE],
    hasAmazonAccount: !!amazonAccount,
    dateRangeStartDate: dateRangeStartDateString,
    dateRangeEndDate: dateRangeEndDateString,
    compareTo
  });

  const [searchParams] = useSearchParams();

  const [selectedCampaignType, setSelectedCampaignType] = useState(
    getInitialCampaignTypeFromSearchParams(searchParams)
  );

  let tableOptionsArgs = {
    allColumns: ALL_AMAZON_CAMPAIGNS_COLUMNS,
    defaultColumns: DEFAULT_AMAZON_CAMPAIGNS_COLUMNS,
    unhideableColumns: UNHIDEABLE_CAMPAIGNS_COLUMNS,
    getStoredDataTableColumns: getStoredAmazonCampaignsTableDataColumns,
    setStoredDataTableColumns: setStoredAmazonCampaignsTableDateColumns,
    resetAllDataTableOptions: resetAmazonCampaignsTableOptions
  };
  if (selectedCampaignType !== AMAZON_AMPD_CAMPAIGN_TYPE) {
    tableOptionsArgs = {
      allColumns: ALL_WALMART_CAMPAIGNS_COLUMNS,
      defaultColumns: DEFAULT_WALMART_CAMPAIGNS_COLUMNS,
      unhideableColumns: UNHIDEABLE_CAMPAIGNS_COLUMNS,
      getStoredDataTableColumns: getStoredWalmartCampaignsTableDataColumns,
      setStoredDataTableColumns: setStoredWalmartCampaignsTableDateColumns,
      resetAllDataTableOptions: resetWalmartCampaignsTableOptions
    };
  }

  const [
    dashboardTableMetricsObjectTypes,
    setDashboardTableMetricsObjectTypes
  ] = useState([]);

  let dashboardTableMetricsRetailer = Retailer.Option.UNKNOWN;
  if (selectedCampaignType === AMAZON_AMPD_CAMPAIGN_TYPE) {
    dashboardTableMetricsRetailer = Retailer.Option.AMAZON;
  } else if (selectedCampaignType === WALMART_AMPD_CAMPAIGN_TYPE) {
    dashboardTableMetricsRetailer = Retailer.Option.WALMART;
  }

  useEffect(() => {
    setDashboardTableMetricsObjectTypes(objectTypes => {
      // When applicable load Walmart Campaigns & Keywords metrics
      if (
        campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE]?.length > 0 &&
        !objectTypes.includes(DashboardTableObjectType.CAMPAIGN)
      ) {
        return [
          ...objectTypes,
          DashboardTableObjectType.CAMPAIGN,
          DashboardTableObjectType.AD_GROUP_CRITERIA
        ];
      }
      return objectTypes;
    });
  }, [campaignIdsByCampaignType]);

  const {
    data: dashboardTableMetricsByCampaignIdByObjectType,
    isLoading: dashboardTableMetricsLoading
  } = useDashboardTableMetrics({
    objectTypes: dashboardTableMetricsObjectTypes,
    siteAlias,
    retailer: dashboardTableMetricsRetailer,
    campaignIds: campaignIdsByCampaignType[ANY_AMPD_CAMPAIGN_TYPE],
    dateRangeStartDate: dateRangeStartDateString,
    dateRangeEndDate: dateRangeEndDateString
  });

  // Only load the metrics for impacted products when they are revealed for
  // the first time (when loadImpactedProducts is called). Includes impacted
  // product metrics with the original array of ObjectTypes.
  const loadImpactedProducts = useCallback(() => {
    setDashboardTableMetricsObjectTypes(objectTypes => {
      if (objectTypes.includes(DashboardTableObjectType.IMPACTED_PRODUCT)) {
        return objectTypes;
      }
      return [...objectTypes, DashboardTableObjectType.IMPACTED_PRODUCT];
    });
  }, []);

  // TODO: These will eventually be ALL Campaign metrics, once the API returns
  // all necessary metrics data for Amazon also. For now, it is focused on
  // Walmart data & filtered to only the Walmart campaigns.
  const walmartCampaignMetricsByCampaign = useMemo(() => {
    const campaignMetricsByCampaignId =
      dashboardTableMetricsByCampaignIdByObjectType?.[
        DashboardTableObjectType.CAMPAIGN
      ] || {};

    // Populate all calculated/derived metrics on each Walmart campaign
    Object.entries(campaignMetricsByCampaignId).forEach(([campaignId]) => {
      if (!walmartCampaignIdsSet.has(campaignId)) {
        // Remove it from the object of Walmart campaigns, so it can't take
        // precedence over the graphql-sourced Amazon campaign data.
        delete campaignMetricsByCampaignId[campaignId];
      }
    });

    return campaignMetricsByCampaignId;
  }, [dashboardTableMetricsByCampaignIdByObjectType, walmartCampaignIdsSet]);

  // TODO: These will eventually be ALL Keywords metrics, once the API returns
  // all necessary metrics data for Amazon also. For now, it is focused on
  // Walmart data & filtered to only the Walmart campaigns.
  const walmartKeywordMetricsByCampaign = useMemo(() => {
    const keywordMetricsByCampaignId =
      dashboardTableMetricsByCampaignIdByObjectType?.[
        DashboardTableObjectType.AD_GROUP_CRITERIA
      ] || {};

    // Populate all calculated/derived metrics on each keyword
    Object.entries(keywordMetricsByCampaignId).forEach(([campaignId]) => {
      if (!walmartCampaignIdsSet.has(campaignId)) {
        // Remove it from the object of Walmart keywords, so it can't take
        // precedence over the graphql-sourced Amazon keywords data.
        delete keywordMetricsByCampaignId[campaignId];
      }
    });

    return keywordMetricsByCampaignId;
  }, [dashboardTableMetricsByCampaignIdByObjectType, walmartCampaignIdsSet]);

  const impactedProducts =
    dashboardTableMetricsByCampaignIdByObjectType?.[
      DashboardTableObjectType.IMPACTED_PRODUCT
    ];

  // Fields of each CampaignData object:
  //     itemizedCampaignConfiguration - attributes of the campaign.
  //     metrics - metrics of the campaign.
  //     compareMetrics - different set of campaign metrics for comparison.
  //     compareMetricsLoading - true when still loading compareMetrics.
  //     keywords:
  //         keywordsLoading - true when keyword data is still loading.
  //         refetchCampaignKeyword - function to call when keyword data was changed in UI.
  //         disabledCriteriaIds - list of keywords to be displayed as disabled (temporarily).
  //         data:
  //             ...metrics - metrics of the keyword.
  //             compareMetrics - different set of keyword metrics for comparison.
  //             compareMetricsLoading - true when still loading compareMetrics.
  //             ...keywords - attributes of the keyword
  //
  const campaignDataByCampaignId = useMemo(() => {
    const campaignData = {};
    if (
      campaignConfigurationsByCampaignId &&
      (campaignMetricsMap || !_.isEmpty(walmartCampaignMetricsByCampaign))
    ) {
      for (const [
        campaignId,
        ampdCampaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        const itemizedCampaignConfiguration = getItemizedCampaignConfiguration(
          ampdCampaignConfiguration,
          advertisingProfiles
        );

        const walmartKeywordByKey = walmartKeywordMetricsByCampaign?.[
          campaignId
        ]?.reduce((agg, curr) => {
          agg[getKeywordMetricsMapKey(curr)] = curr;
          return agg;
        }, {});

        const keywordData = {};
        for (const keyword of allKeywords || []) {
          if (String(keyword.campaignId) !== String(campaignId)) {
            continue;
          }
          const keywordKey = getKeywordMetricsMapKey(keyword);
          let keywordMetrics =
            (keywordMetricsMap && keywordMetricsMap.get(keywordKey)) || {};

          const walmartKeywordMetrics = walmartKeywordByKey?.[keywordKey];
          if (walmartKeywordMetrics) {
            keywordMetrics = {
              ...keywordMetrics,
              retailer: Retailer.Option.WALMART,
              conversions: walmartKeywordMetrics.conversions,
              unitsSold: walmartKeywordMetrics.unitsSold,
              revenue: walmartKeywordMetrics.revenue
            };
            calculateDerivedMetrics(keywordMetrics, METRIC_COLUMNS);
          }

          // TODO: Support Walmart compare metrics (current quota is too limited)
          const compareMetrics = keywordCompareMetricsMap
            ? keywordCompareMetricsMap.get(keywordKey) || {}
            : undefined;

          keywordData[keywordKey] = {
            ...keywordMetrics,
            compareMetricsLoading: keywordCompareMetricsLoading,
            compareMetrics,

            // Unpack object after metrics.
            ...keyword
          };
        }

        let campaignMetrics = campaignMetricsMap?.get(campaignId) || {};

        const walmartCampaignMetrics =
          walmartCampaignMetricsByCampaign?.[campaignId]?.[0];
        if (walmartCampaignMetrics) {
          campaignMetrics = {
            ...campaignMetrics,
            retailer: Retailer.Option.WALMART,
            conversions: walmartCampaignMetrics.conversions,
            unitsSold: walmartCampaignMetrics.unitsSold,
            revenue: walmartCampaignMetrics.revenue
          };
          calculateDerivedMetrics(campaignMetrics, METRIC_COLUMNS);
        }

        // TODO(Clint): Hook up the campaign compare metrics?
        const campaignCompareMetrics = campaignCompareMetricsMap
          ? campaignCompareMetricsMap.get(campaignId) || {}
          : undefined;
        campaignData[campaignId] = {
          itemizedCampaignConfiguration,
          metrics: campaignMetrics,
          compareMetricsLoading: campaignCompareMetricsLoading,
          compareMetrics: campaignCompareMetrics,
          keywords: {
            keywordsLoading,
            refetchCampaignKeyword,
            disabledCriteriaIds,
            data: {
              ...keywordData
            }
          }
        };
      }
    }

    return campaignData;
  }, [
    campaignConfigurationsByCampaignId,
    advertisingProfiles,
    campaignMetricsMap,
    campaignCompareMetricsLoading,
    campaignCompareMetricsMap,
    disabledCriteriaIds,
    keywordsLoading,
    keywordCompareMetricsLoading,
    allKeywords,
    keywordMetricsMap,
    keywordCompareMetricsMap,
    refetchCampaignKeyword,
    walmartCampaignMetricsByCampaign,
    walmartKeywordMetricsByCampaign
  ]);

  if (campaignConfigurationsError) {
    return <Message error>Unable to load campaign information.</Message>;
  }

  let isTableLoading = campaignConfigurationsLoading || !campaignMetricsMap;
  if (walmartCampaignIdsSet.size > 0) {
    // Consider the table still loading when we are waiting for the initial Walmart
    // campaign and keyword metrics (so they don't appear as zero while we are still
    // waiting for them).  However, don't consider the whole table still loading
    // when we are lazy loading the metrics for Impacted Products.
    if (
      dashboardTableMetricsLoading &&
      !dashboardTableMetricsObjectTypes.includes(
        DashboardTableObjectType.IMPACTED_PRODUCT
      )
    ) {
      isTableLoading = true;
    }
  }

  return (
    <CampaignsPageRenderer
      tableOptionsArgs={tableOptionsArgs}
      selectedCampaignType={selectedCampaignType}
      setSelectedCampaignType={setSelectedCampaignType}
      campaignIdsByCampaignType={campaignIdsByCampaignType}
      campaignDataByCampaignId={campaignDataByCampaignId}
      campaignConfigurationsByCampaignId={campaignConfigurationsByCampaignId}
      campaignConfigurationsLoading={isTableLoading}
      refetchCampaignConfiguration={refetchCampaignConfiguration}
      currencyCode={currencyCode}
      disabledCampaignIds={disabledCampaignIds}
      disabledCriteriaIds={disabledCriteriaIds}
      googleAdsAccount={googleAdsAccount}
      refetchCampaignKeyword={refetchCampaignKeyword}
      setShowKeywordsForCampaignId={setShowKeywordsForCampaignId}
      showKeywordsForCampaignId={showKeywordsForCampaignId}
      siteAlias={siteAlias}
      campaignCompareMetricsMap={campaignCompareMetricsMap}
      campaignCompareMetricsLoading={campaignCompareMetricsLoading}
      setQueryRemovedCampaigns={setQueryRemovedCampaigns}
      loadImpactedProducts={loadImpactedProducts}
      impactedProductsLoading={
        dashboardTableMetricsLoading &&
        dashboardTableMetricsObjectTypes.includes(
          DashboardTableObjectType.IMPACTED_PRODUCT
        )
      }
      impactedProducts={impactedProducts}
    />
  );
};

function getInitialCampaignTypeFromSearchParams(searchParams) {
  const marketplaceInfo = getMarketplaceInfoForDomain(
    searchParams.get(MARKETPLACE_QUERY_PARAM)
  );

  if (!marketplaceInfo) {
    return ANY_AMPD_CAMPAIGN_TYPE;
  }

  if (isWalmartMarketplaceInfo(marketplaceInfo)) {
    return WALMART_AMPD_CAMPAIGN_TYPE;
  }

  return AMAZON_AMPD_CAMPAIGN_TYPE;
}

export default CampaignsPage;
