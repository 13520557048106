import _ from "lodash";
import * as loggingPb from "../proto/common/logging_pb";
import * as errorPb from "../proto/errorPb/error_pb";
import { Status } from "../proto/grpcPb/status_pb";

export const defaultGraphQLServerErrorMessage = "GraphQL Server Error";

const optionToNameMap = [];
for (const name in errorPb.Kind.Option) {
  if (Object.prototype.hasOwnProperty.call(errorPb.Kind.Option, name)) {
    optionToNameMap.push(name);
  }
}

/** Native representation of a Kind.Option proto value. */
class Kind {
  /**
   * Create a Kind.
   * @param name - The name of the Option.
   * @param number - The ENUM number of the Option.
   * @param description - The plain text description of the Option.
   */
  constructor(name, number, description) {
    this.name = name;
    this.number = number;

    // TODO(will): Figure out how to find this.
    this.description = description;
  }

  /**
   * Creates a Kind from a proto Kind.Option.
   * @param option - A Kind.Option.
   * @returns {Kind}
   */
  static fromOption(option) {
    if (
      typeof option !== "number" ||
      !(option >= 0 && option <= optionToNameMap.length)
    ) {
      option = errorPb.Kind.Option.OTHER;
    }
    const name = optionToNameMap[option];
    return new Kind(name, option);
  }
}

const otherKind = Kind.fromOption(errorPb.Kind.Option.UNKNOWN);

/**
 * Returns the Kind of the error if available, otherwise Other.
 */
export function kindOf(e) {
  if (!(e instanceof Error)) {
    return otherKind;
  }
  if (e.kind.number !== errorPb.Kind.Option.OTHER) {
    return e.kind;
  }
  return kindOf(e.err);
}

/**
 * Returns the public text of the error if available.
 */
function publicTextOf(e) {
  if (!(e instanceof Error)) {
    return "";
  }
  if (e.publicText) {
    return e.publicText;
  }
  return publicTextOf(e.err);
}

function requestIDOf(e) {
  if (!(e instanceof Error)) {
    return "";
  }
  if (e.requestID) {
    return e.requestID;
  }
  return requestIDOf(e.err);
}

/**
 * Returns the top specified LogLevel in this Error and it sub-errors, if any.
 */
function logLevelOf(e) {
  if (!(e instanceof Error)) {
    return loggingPb.LogLevel.Option.UNSPECIFIED;
  }
  if (e.logLevel && e.logLevel !== loggingPb.LogLevel.Option.UNSPECIFIED) {
    return e.logLevel;
  }
  return logLevelOf(e.err);
}

/** Native representation of an Error proto value. */
class Error {
  /**
   * Creates an Error.
   *
   * @param details - An object with any of the following fields:
   *  * requestID - The requestID in which the error occurred.
   *  * publicText - The public text to display to the end user.
   *  * op - The operation that caused the error.
   *  * kindOption - The Kind.Option representing the kind of the error.
   *  * err - The underlying cause of the error, if applicable.
   *  * logLevel - The log level for the error, if specified.
   *
   *  It can also be a string, which will just fill the err field.
   */
  constructor(details) {
    let { requestID, publicText, op, kindOption, err, logLevel } = details;

    if (_.isString(details)) {
      err = details;
    }

    this.requestID = requestID;
    this.publicText = publicText;
    this.op = op;
    this.kind = Kind.fromOption(kindOption);
    this.err = err;
    this.logLevel = logLevel || loggingPb.LogLevel.Option.UNSPECIFIED;
  }

  /**
   * Returns the first non OTHER Kind in this error and its sub errors.
   */
  resolveKind() {
    return kindOf(this);
  }

  /**
   * Returns the first non empty publicText in this Error and its sub errors.
   */
  resolvePublicText() {
    return publicTextOf(this);
  }

  /**
   * Returns the first non empty requestID in this Error and its sub errors.
   */
  resolveRequestID() {
    return requestIDOf(this);
  }

  /**
   * Returns the top specified LogLevel in this Error and it sub-errors, if any.
   */
  resolveLogLevel() {
    return logLevelOf(this);
  }

  /**
   * Returns the fully rendered error, including any internal error details.
   *
   * Based on error.go::Error.internalText().
   */
  internalText() {
    const publicText = this.resolvePublicText();
    const requestID = this.resolveRequestID();
    const requestIDText = requestID ? `Request ID: ${requestID}` : "";

    const privateLines = [];

    const kindName = err => {
      const { name } = err.resolveKind();
      return name && name !== "OTHER" ? name : "";
    };

    let currentDesc = [this.op, kindName(this)]
      .filter(Boolean /* skip empty */)
      .join(": ");

    let currentErr = this;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const nextErr = currentErr.err;

      if (nextErr instanceof Error) {
        privateLines.push(currentDesc);
        currentErr = nextErr;
        currentDesc = nextErr.op;
        continue;
      }

      // There are no more Error objects. Time to break.
      const nextErrStr = nextErr ? nextErr.toString() : kindName(currentErr);
      currentDesc = currentDesc ? currentDesc + ": " + nextErrStr : nextErrStr;

      privateLines.push(currentDesc);
      break;
    }

    // Indent later lines.
    for (let i = 1; i < privateLines.length; i++) {
      privateLines[i] = "    " + privateLines[i];
    }

    const privateText = privateLines
      .filter(Boolean /* skip empty */)
      .join(":\n");

    return [publicText, privateText, requestIDText]
      .filter(Boolean /* skip empty */)
      .join("\n\n");
  }

  toString() {
    return publicTextOf(this);
  }

  /**
   * Constructs an Error from a deserialized Error proto.
   * @param input - The deserialized Error proto.
   * @returns {Error}
   */
  static fromProtoBinary(input) {
    const errorProto = errorPb.Error.deserializeBinary(input);
    return Error.fromProto(errorProto);
  }

  /**
   * Constructs an Error from an Error proto.
   * @param protoError - The Error proto.
   * @returns {Error}
   */
  static fromProto(protoError) {
    return new Error({
      requestID: protoError.getRequestId() || undefined,
      publicText: protoError.getPublicText() || undefined,
      op: protoError.getOp() || undefined,
      kindOption: protoError.getKind(),
      err: Error.fromErrorDetails(protoError.getErr()) || undefined,
      logLevel:
        protoError.getLogLevel() || loggingPb.LogLevel.Option.UNSPECIFIED
    });
  }

  /**
   * Constructs an Error from an exception returned by graphql.
   */
  static fromGraphQLErrors(e) {
    if (e.graphQLErrors.length !== 1) {
      return e;
    }
    return Error.fromGraphQLError(e.graphQLErrors[0]);
  }

  /**
   * Constructs an Error from a graphQLError.
   */
  static fromGraphQLError(e) {
    try {
      const errPb = errorPb.Error.deserializeBinary(e.message);
      return Error.fromProto(errPb);
    } catch {
      // Wrap the graphql error, but don't override the public text, let that be
      // extracted from the proto message.
      return new Error({
        requestID: "",
        publicText: defaultGraphQLServerErrorMessage,
        op: "GraphQL Server Error",
        kindOption: Kind.fromOption(0) /* OTHER */,
        err: e.message
      });
    }
  }

  /**
   * Resolves the proto ErrorDetails, either constructing an Error or returning
   * the raw string as appropriate.
   */
  static fromErrorDetails(details) {
    const type = details?.getTypeCase();
    if (!type) {
      return null;
    }

    if (type === errorPb.ErrorDetails.TypeCase.ERROR) {
      return Error.fromProto(details.getError());
    }
    if (type === errorPb.ErrorDetails.TypeCase.RAW) {
      return details.getRaw();
    }
  }

  static formatErrors(errs) {
    return errs.map(err => err.resolvePublicText()).join(", ");
  }

  static formatRequestIDs(errs) {
    return errs.map(err => err.resolveRequestID()).join(", ");
  }
}

// Attempts to extract Error from the status in the error response. Returns the
// original error if all else fails.
function extractErrorFromStatus(e) {
  try {
    const statusBase64 = e.metadata["grpc-status-details-bin"];
    if (!statusBase64) {
      return e;
    }

    const statusProto = Status.deserializeBinary(statusBase64);

    for (const details of statusProto.getDetailsList()) {
      const errorProto = details.unpack(
        errorPb.Error.deserializeBinary,
        "errorPb.Error"
      );

      // unpack() returns null if this doesn't match.
      if (errorProto === null) {
        continue;
      }

      return Error.fromProto(errorProto);
    }
    return e;
  } catch (_) {
    return e;
  }
}

/**
 * Returns a string representation of the error message.
 *
 * - If the err is undefined or null, this returns an empty string.
 * - If this is an Error, this returns its public text (if any).
 * - If this is an object with a "graphQLErrors" array, it returns a
 *   newline-concatenated string of extractErrorMessage() applied to each
 *   value.
 * - Otherwise, this returns the value of err.toString().
 *
 * Possible options:
 * - internal: Use internalText() rather than resolvePublicText().
 * - defaultGraphQLMessage: message to replace the generic "GraphQL Server Error"
 *   message.
 * @returns {String} A string representation of the error object.
 */
function extractErrorMessage(err, options = {}) {
  const { internal, defaultGraphQLMessage } = options;

  if (!err) {
    return "";
  }

  // Converts the grpc status error to an Error, if possible. Otherwise, err is
  // left as-is.
  err = extractErrorFromStatus(err);

  if (err instanceof Error) {
    let text = internal ? err.internalText() : err.resolvePublicText();

    // If we don't have any public text for the error, let's generate something
    // with the requestID, so we can find the error in the logs.
    if (!text) {
      const requestId = err.resolveRequestID();
      if (requestId) {
        text = `Server error, Request ID: ${requestId}`;
      } else {
        text = "Server error";
      }
    }
    return text;
  }

  if (_.isArray(err.graphQLErrors)) {
    let messages = "";
    err.graphQLErrors.forEach(e => {
      let message = extractErrorMessage(e, options);
      if (
        message === defaultGraphQLServerErrorMessage &&
        defaultGraphQLMessage
      ) {
        message = defaultGraphQLMessage;
      }

      if (message) {
        if (messages) {
          messages += "\n";
        }
        messages += message;
      }
    });

    return messages;
  }

  // e.g., grpc errors.
  if (err.message) {
    return err.message;
  }

  return JSON.stringify(err);
}

/**
 * Returns a request id for the error or an empty string.
 *
 * - If this is an Error, this returns the request ID (if any).
 * - If this is an object with a "graphQLErrors" array, it returns any
 *   (the first) non empty request ID.
 * - Otherwise, this returns an empty string.
 */
function extractErrorRequestID(err) {
  if (!err) {
    return "";
  }

  // Converts the grpc status error to an Error, if possible. Otherwise, err is
  // left as-is.
  err = extractErrorFromStatus(err);

  if (err instanceof Error) {
    return err.resolveRequestID();
  }

  if (_.isArray(err.graphQLErrors)) {
    const requestId = _.reduce(
      err.graphQLErrors,
      (requestId, e) => requestId || extractErrorRequestID(e),
      ""
    );

    return requestId;
  }

  return "";
}

export {
  Kind,
  Error,
  extractErrorMessage,
  extractErrorRequestID,
  extractErrorFromStatus
};
