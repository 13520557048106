// Utilities for linking to HubSpot for Metricstory users.

// Returns a URL that deep links into HubSpot for the specified company by id or name.
export function getURLForHubSpotCompany(hubSpotCompanyId, siteName) {
  if (!hubSpotCompanyId) {
    // Goes to the full list of companies with the specified search string
    return `${
      process.env.REACT_APP_HUB_SPOT_ACCOUNT_URL
    }/objects/0-2/views/all/list?query=${encodeURIComponent(siteName)}`;
  }

  // Goes to the specific company
  return `${process.env.REACT_APP_HUB_SPOT_ACCOUNT_URL}/company/${hubSpotCompanyId}`;
}

// Returns a URL that deep links into HubSpot for the specified contact by id or email.
export function getURLForHubSpotContact(hubSpotContactId, emailOrName) {
  if (!hubSpotContactId) {
    // Goes to the full list of contacts with the specified search string
    return `${
      process.env.REACT_APP_HUB_SPOT_ACCOUNT_URL
    }/objects/0-1/views/all/list?query=${encodeURIComponent(emailOrName)}`;
  }

  // Goes to the specific contact
  return `${process.env.REACT_APP_HUB_SPOT_ACCOUNT_URL}/contact/${hubSpotContactId}`;
}
