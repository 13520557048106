// source: proto/common/amazon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_common_crypto_pb = require('../../proto/common/crypto_pb.js');
goog.object.extend(proto, proto_common_crypto_pb);
goog.exportSymbol('proto.common.Amazon', null, global);
goog.exportSymbol('proto.common.Amazon.AccountProfileId', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingAccount', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingAccount.Error', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingAccount.Error.Type', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingAdvertiser', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingAuthCode', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingProfile', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingProfile.AccountType', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingProfile.AccountType.Option', null, global);
goog.exportSymbol('proto.common.Amazon.AdvertisingProfileMigration', null, global);
goog.exportSymbol('proto.common.Amazon.ExternalAdPublisher', null, global);
goog.exportSymbol('proto.common.Amazon.ExternalAdPublisher.Option', null, global);
goog.exportSymbol('proto.common.Amazon.Marketplace', null, global);
goog.exportSymbol('proto.common.Amazon.Marketplace.Config', null, global);
goog.exportSymbol('proto.common.Amazon.Marketplace.Option', null, global);
goog.exportSymbol('proto.common.Amazon.Product', null, global);
goog.exportSymbol('proto.common.Amazon.Product.AmazonsChoice', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Attribute', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Availability', null, global);
goog.exportSymbol('proto.common.Amazon.Product.BestsellersRank', null, global);
goog.exportSymbol('proto.common.Amazon.Product.BuyboxWinner', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Category', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Fulfillment', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Image', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Price', null, global);
goog.exportSymbol('proto.common.Amazon.Product.ReviewsThatMention', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Shipping', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Specification', null, global);
goog.exportSymbol('proto.common.Amazon.Product.SummarizationAttribute', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Variant', null, global);
goog.exportSymbol('proto.common.Amazon.Product.Variant.Dimension', null, global);
goog.exportSymbol('proto.common.Amazon.Region', null, global);
goog.exportSymbol('proto.common.Amazon.Region.Config', null, global);
goog.exportSymbol('proto.common.Amazon.Region.Option', null, global);
goog.exportSymbol('proto.common.Amazon.SearchResult', null, global);
goog.exportSymbol('proto.common.Amazon.SearchTermHistoryDetails', null, global);
goog.exportSymbol('proto.common.Amazon.SellerAccount', null, global);
goog.exportSymbol('proto.common.Amazon.SellerAccount.Error', null, global);
goog.exportSymbol('proto.common.Amazon.SellerAccount.Error.Type', null, global);
goog.exportSymbol('proto.common.Amazon.SellerApi', null, global);
goog.exportSymbol('proto.common.Amazon.SellerApi.Granularity', null, global);
goog.exportSymbol('proto.common.Amazon.SellerApi.Money', null, global);
goog.exportSymbol('proto.common.Amazon.SellerAuthCode', null, global);
goog.exportSymbol('proto.common.Amazon.Storefront', null, global);
goog.exportSymbol('proto.common.Amazon.StorefrontCategory', null, global);
goog.exportSymbol('proto.common.Amazon.StorefrontProduct', null, global);
goog.exportSymbol('proto.common.Amazon.UserProfile', null, global);
goog.exportSymbol('proto.common.amazonMarketplaceConfig', null, global);
goog.exportSymbol('proto.common.amazonRegionConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.displayName = 'proto.common.Amazon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingAuthCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.AdvertisingAuthCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingAuthCode.displayName = 'proto.common.Amazon.AdvertisingAuthCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SellerAuthCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.SellerAuthCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SellerAuthCode.displayName = 'proto.common.Amazon.SellerAuthCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.UserProfile.displayName = 'proto.common.Amazon.UserProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Amazon.AdvertisingAccount.repeatedFields_, null);
};
goog.inherits(proto.common.Amazon.AdvertisingAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingAccount.displayName = 'proto.common.Amazon.AdvertisingAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingAccount.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Amazon.AdvertisingAccount.Error.repeatedFields_, null);
};
goog.inherits(proto.common.Amazon.AdvertisingAccount.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingAccount.Error.displayName = 'proto.common.Amazon.AdvertisingAccount.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Amazon.AdvertisingProfile.repeatedFields_, null);
};
goog.inherits(proto.common.Amazon.AdvertisingProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingProfile.displayName = 'proto.common.Amazon.AdvertisingProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingProfile.AccountType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.AdvertisingProfile.AccountType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingProfile.AccountType.displayName = 'proto.common.Amazon.AdvertisingProfile.AccountType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AccountProfileId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.AccountProfileId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AccountProfileId.displayName = 'proto.common.Amazon.AccountProfileId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingAdvertiser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.AdvertisingAdvertiser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingAdvertiser.displayName = 'proto.common.Amazon.AdvertisingAdvertiser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.AdvertisingProfileMigration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.AdvertisingProfileMigration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.AdvertisingProfileMigration.displayName = 'proto.common.Amazon.AdvertisingProfileMigration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SellerAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.SellerAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SellerAccount.displayName = 'proto.common.Amazon.SellerAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SellerAccount.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.SellerAccount.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SellerAccount.Error.displayName = 'proto.common.Amazon.SellerAccount.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SellerApi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.SellerApi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SellerApi.displayName = 'proto.common.Amazon.SellerApi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SellerApi.Money = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.SellerApi.Money, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SellerApi.Money.displayName = 'proto.common.Amazon.SellerApi.Money';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Region = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Region, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Region.displayName = 'proto.common.Amazon.Region';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Region.Config = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Region.Config, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Region.Config.displayName = 'proto.common.Amazon.Region.Config';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.ExternalAdPublisher = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.ExternalAdPublisher, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.ExternalAdPublisher.displayName = 'proto.common.Amazon.ExternalAdPublisher';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Marketplace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Marketplace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Marketplace.displayName = 'proto.common.Amazon.Marketplace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Marketplace.Config = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Marketplace.Config, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Marketplace.Config.displayName = 'proto.common.Amazon.Marketplace.Config';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Amazon.Product.repeatedFields_, null);
};
goog.inherits(proto.common.Amazon.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.displayName = 'proto.common.Amazon.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Image.displayName = 'proto.common.Amazon.Product.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.SummarizationAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.SummarizationAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.SummarizationAttribute.displayName = 'proto.common.Amazon.Product.SummarizationAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.ReviewsThatMention = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.ReviewsThatMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.ReviewsThatMention.displayName = 'proto.common.Amazon.Product.ReviewsThatMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.BestsellersRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.BestsellersRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.BestsellersRank.displayName = 'proto.common.Amazon.Product.BestsellersRank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Attribute.displayName = 'proto.common.Amazon.Product.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.BuyboxWinner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.BuyboxWinner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.BuyboxWinner.displayName = 'proto.common.Amazon.Product.BuyboxWinner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Availability = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Availability, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Availability.displayName = 'proto.common.Amazon.Product.Availability';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Fulfillment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Fulfillment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Fulfillment.displayName = 'proto.common.Amazon.Product.Fulfillment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Price = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Price, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Price.displayName = 'proto.common.Amazon.Product.Price';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Shipping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Shipping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Shipping.displayName = 'proto.common.Amazon.Product.Shipping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Specification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Specification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Specification.displayName = 'proto.common.Amazon.Product.Specification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.AmazonsChoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.AmazonsChoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.AmazonsChoice.displayName = 'proto.common.Amazon.Product.AmazonsChoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Category.displayName = 'proto.common.Amazon.Product.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Variant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Amazon.Product.Variant.repeatedFields_, null);
};
goog.inherits(proto.common.Amazon.Product.Variant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Variant.displayName = 'proto.common.Amazon.Product.Variant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Product.Variant.Dimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Product.Variant.Dimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Product.Variant.Dimension.displayName = 'proto.common.Amazon.Product.Variant.Dimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.Storefront = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.Storefront, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.Storefront.displayName = 'proto.common.Amazon.Storefront';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.StorefrontProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.StorefrontProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.StorefrontProduct.displayName = 'proto.common.Amazon.StorefrontProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.StorefrontCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.StorefrontCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.StorefrontCategory.displayName = 'proto.common.Amazon.StorefrontCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SearchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Amazon.SearchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SearchResult.displayName = 'proto.common.Amazon.SearchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Amazon.SearchTermHistoryDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Amazon.SearchTermHistoryDetails.repeatedFields_, null);
};
goog.inherits(proto.common.Amazon.SearchTermHistoryDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Amazon.SearchTermHistoryDetails.displayName = 'proto.common.Amazon.SearchTermHistoryDetails';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon}
 */
proto.common.Amazon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon;
  return proto.common.Amazon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon}
 */
proto.common.Amazon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingAuthCode.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingAuthCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingAuthCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAuthCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingAuthCode}
 */
proto.common.Amazon.AdvertisingAuthCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingAuthCode;
  return proto.common.Amazon.AdvertisingAuthCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingAuthCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingAuthCode}
 */
proto.common.Amazon.AdvertisingAuthCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingAuthCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingAuthCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingAuthCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAuthCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Region.Option region = 1;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.common.Amazon.AdvertisingAuthCode.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.common.Amazon.AdvertisingAuthCode} returns this
 */
proto.common.Amazon.AdvertisingAuthCode.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.common.Amazon.AdvertisingAuthCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingAuthCode} returns this
 */
proto.common.Amazon.AdvertisingAuthCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SellerAuthCode.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SellerAuthCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SellerAuthCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerAuthCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    sellingPartnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileIdStr: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SellerAuthCode}
 */
proto.common.Amazon.SellerAuthCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SellerAuthCode;
  return proto.common.Amazon.SellerAuthCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SellerAuthCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SellerAuthCode}
 */
proto.common.Amazon.SellerAuthCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellingPartnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SellerAuthCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SellerAuthCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SellerAuthCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerAuthCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSellingPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string selling_partner_id = 1;
 * @return {string}
 */
proto.common.Amazon.SellerAuthCode.prototype.getSellingPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAuthCode} returns this
 */
proto.common.Amazon.SellerAuthCode.prototype.setSellingPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Region.Option region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.common.Amazon.SellerAuthCode.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.common.Amazon.SellerAuthCode} returns this
 */
proto.common.Amazon.SellerAuthCode.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.common.Amazon.SellerAuthCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAuthCode} returns this
 */
proto.common.Amazon.SellerAuthCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_id_str = 4;
 * @return {string}
 */
proto.common.Amazon.SellerAuthCode.prototype.getProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAuthCode} returns this
 */
proto.common.Amazon.SellerAuthCode.prototype.setProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.UserProfile}
 */
proto.common.Amazon.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.UserProfile;
  return proto.common.Amazon.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.UserProfile}
 */
proto.common.Amazon.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.common.Amazon.UserProfile.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.UserProfile} returns this
 */
proto.common.Amazon.UserProfile.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.common.Amazon.UserProfile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.UserProfile} returns this
 */
proto.common.Amazon.UserProfile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Amazon.AdvertisingAccount.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.common.Amazon.UserProfile.toObject(includeInstance, f),
    region: jspb.Message.getFieldWithDefault(msg, 2, 0),
    refreshToken: (f = msg.getRefreshToken()) && proto_common_crypto_pb.EncryptedData.toObject(includeInstance, f),
    advertisingProfilesList: jspb.Message.toObjectList(msg.getAdvertisingProfilesList(),
    proto.common.Amazon.AdvertisingProfile.toObject, includeInstance),
    error: (f = msg.getError()) && proto.common.Amazon.AdvertisingAccount.Error.toObject(includeInstance, f),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedTime: (f = msg.getUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingAccount}
 */
proto.common.Amazon.AdvertisingAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingAccount;
  return proto.common.Amazon.AdvertisingAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingAccount}
 */
proto.common.Amazon.AdvertisingAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Amazon.UserProfile;
      reader.readMessage(value,proto.common.Amazon.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 3:
      var value = new proto_common_crypto_pb.EncryptedData;
      reader.readMessage(value,proto_common_crypto_pb.EncryptedData.deserializeBinaryFromReader);
      msg.setRefreshToken(value);
      break;
    case 6:
      var value = new proto.common.Amazon.AdvertisingProfile;
      reader.readMessage(value,proto.common.Amazon.AdvertisingProfile.deserializeBinaryFromReader);
      msg.addAdvertisingProfiles(value);
      break;
    case 7:
      var value = new proto.common.Amazon.AdvertisingAccount.Error;
      reader.readMessage(value,proto.common.Amazon.AdvertisingAccount.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Amazon.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRefreshToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_crypto_pb.EncryptedData.serializeBinaryToWriter
    );
  }
  f = message.getAdvertisingProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.common.Amazon.AdvertisingProfile.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.Amazon.AdvertisingAccount.Error.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Amazon.AdvertisingAccount.Error.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingAccount.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingAccount.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAccount.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    previousAdvertisingProfilesList: jspb.Message.toObjectList(msg.getPreviousAdvertisingProfilesList(),
    proto.common.Amazon.AdvertisingProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingAccount.Error}
 */
proto.common.Amazon.AdvertisingAccount.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingAccount.Error;
  return proto.common.Amazon.AdvertisingAccount.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingAccount.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingAccount.Error}
 */
proto.common.Amazon.AdvertisingAccount.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.AdvertisingAccount.Error.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.common.Amazon.AdvertisingProfile;
      reader.readMessage(value,proto.common.Amazon.AdvertisingProfile.deserializeBinaryFromReader);
      msg.addPreviousAdvertisingProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingAccount.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingAccount.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAccount.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPreviousAdvertisingProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.Amazon.AdvertisingProfile.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Amazon.AdvertisingAccount.Error.Type = {
  UNKNOWN: 0,
  UNAUTHORIZED: 1
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingAccount.Error} returns this
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.common.Amazon.AdvertisingAccount.Error.Type}
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.getType = function() {
  return /** @type {!proto.common.Amazon.AdvertisingAccount.Error.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.AdvertisingAccount.Error.Type} value
 * @return {!proto.common.Amazon.AdvertisingAccount.Error} returns this
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated AdvertisingProfile previous_advertising_profiles = 3;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfile>}
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.getPreviousAdvertisingProfilesList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.AdvertisingProfile, 3));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfile>} value
 * @return {!proto.common.Amazon.AdvertisingAccount.Error} returns this
*/
proto.common.Amazon.AdvertisingAccount.Error.prototype.setPreviousAdvertisingProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.addPreviousAdvertisingProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Amazon.AdvertisingProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.AdvertisingAccount.Error} returns this
 */
proto.common.Amazon.AdvertisingAccount.Error.prototype.clearPreviousAdvertisingProfilesList = function() {
  return this.setPreviousAdvertisingProfilesList([]);
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.common.Amazon.UserProfile}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getProfile = function() {
  return /** @type{?proto.common.Amazon.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.UserProfile, 1));
};


/**
 * @param {?proto.common.Amazon.UserProfile|undefined} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
*/
proto.common.Amazon.AdvertisingAccount.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.AdvertisingAccount.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Region.Option region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional EncryptedData refresh_token = 3;
 * @return {?proto.common.EncryptedData}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getRefreshToken = function() {
  return /** @type{?proto.common.EncryptedData} */ (
    jspb.Message.getWrapperField(this, proto_common_crypto_pb.EncryptedData, 3));
};


/**
 * @param {?proto.common.EncryptedData|undefined} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
*/
proto.common.Amazon.AdvertisingAccount.prototype.setRefreshToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.clearRefreshToken = function() {
  return this.setRefreshToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.AdvertisingAccount.prototype.hasRefreshToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated AdvertisingProfile advertising_profiles = 6;
 * @return {!Array<!proto.common.Amazon.AdvertisingProfile>}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getAdvertisingProfilesList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.AdvertisingProfile, 6));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingProfile>} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
*/
proto.common.Amazon.AdvertisingAccount.prototype.setAdvertisingProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.common.Amazon.AdvertisingAccount.prototype.addAdvertisingProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.Amazon.AdvertisingProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.clearAdvertisingProfilesList = function() {
  return this.setAdvertisingProfilesList([]);
};


/**
 * optional Error error = 7;
 * @return {?proto.common.Amazon.AdvertisingAccount.Error}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getError = function() {
  return /** @type{?proto.common.Amazon.AdvertisingAccount.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.AdvertisingAccount.Error, 7));
};


/**
 * @param {?proto.common.Amazon.AdvertisingAccount.Error|undefined} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
*/
proto.common.Amazon.AdvertisingAccount.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.AdvertisingAccount.prototype.hasError = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp created_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
*/
proto.common.Amazon.AdvertisingAccount.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.AdvertisingAccount.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updated_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Amazon.AdvertisingAccount.prototype.getUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
*/
proto.common.Amazon.AdvertisingAccount.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.AdvertisingAccount} returns this
 */
proto.common.Amazon.AdvertisingAccount.prototype.clearUpdatedTime = function() {
  return this.setUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.AdvertisingAccount.prototype.hasUpdatedTime = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Amazon.AdvertisingProfile.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileIdStr: jspb.Message.getFieldWithDefault(msg, 7, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    advertisersList: jspb.Message.toObjectList(msg.getAdvertisersList(),
    proto.common.Amazon.AdvertisingAdvertiser.toObject, includeInstance),
    errorwhileretrievingadvertisers: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    accountType: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.common.Amazon.AdvertisingProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingProfile;
  return proto.common.Amazon.AdvertisingProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingProfile}
 */
proto.common.Amazon.AdvertisingProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfileId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIdStr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtype(value);
      break;
    case 6:
      var value = new proto.common.Amazon.AdvertisingAdvertiser;
      reader.readMessage(value,proto.common.Amazon.AdvertisingAdvertiser.deserializeBinaryFromReader);
      msg.addAdvertisers(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setErrorwhileretrievingadvertisers(value);
      break;
    case 9:
      var value = /** @type {!proto.common.Amazon.AdvertisingProfile.AccountType.Option} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSubtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdvertisersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.common.Amazon.AdvertisingAdvertiser.serializeBinaryToWriter
    );
  }
  f = message.getErrorwhileretrievingadvertisers();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingProfile.AccountType.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingProfile.AccountType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingProfile.AccountType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingProfile.AccountType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingProfile.AccountType}
 */
proto.common.Amazon.AdvertisingProfile.AccountType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingProfile.AccountType;
  return proto.common.Amazon.AdvertisingProfile.AccountType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingProfile.AccountType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingProfile.AccountType}
 */
proto.common.Amazon.AdvertisingProfile.AccountType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingProfile.AccountType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingProfile.AccountType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingProfile.AccountType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingProfile.AccountType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Amazon.AdvertisingProfile.AccountType.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  VENDOR: 2,
  SELLER: 3,
  AGENCY: 4
};

/**
 * optional int64 profile_id = 1;
 * @return {number}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string profile_id_str = 7;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Marketplace.Option marketplace = 4;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string subtype = 5;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getSubtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setSubtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated AdvertisingAdvertiser advertisers = 6;
 * @return {!Array<!proto.common.Amazon.AdvertisingAdvertiser>}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getAdvertisersList = function() {
  return /** @type{!Array<!proto.common.Amazon.AdvertisingAdvertiser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.AdvertisingAdvertiser, 6));
};


/**
 * @param {!Array<!proto.common.Amazon.AdvertisingAdvertiser>} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
*/
proto.common.Amazon.AdvertisingProfile.prototype.setAdvertisersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.Amazon.AdvertisingAdvertiser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.AdvertisingAdvertiser}
 */
proto.common.Amazon.AdvertisingProfile.prototype.addAdvertisers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.Amazon.AdvertisingAdvertiser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.clearAdvertisersList = function() {
  return this.setAdvertisersList([]);
};


/**
 * optional bool errorWhileRetrievingAdvertisers = 8;
 * @return {boolean}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getErrorwhileretrievingadvertisers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setErrorwhileretrievingadvertisers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional AccountType.Option account_type = 9;
 * @return {!proto.common.Amazon.AdvertisingProfile.AccountType.Option}
 */
proto.common.Amazon.AdvertisingProfile.prototype.getAccountType = function() {
  return /** @type {!proto.common.Amazon.AdvertisingProfile.AccountType.Option} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile.AccountType.Option} value
 * @return {!proto.common.Amazon.AdvertisingProfile} returns this
 */
proto.common.Amazon.AdvertisingProfile.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AccountProfileId.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AccountProfileId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AccountProfileId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AccountProfileId.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AccountProfileId}
 */
proto.common.Amazon.AccountProfileId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AccountProfileId;
  return proto.common.Amazon.AccountProfileId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AccountProfileId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AccountProfileId}
 */
proto.common.Amazon.AccountProfileId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AccountProfileId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AccountProfileId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AccountProfileId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AccountProfileId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.common.Amazon.AccountProfileId.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AccountProfileId} returns this
 */
proto.common.Amazon.AccountProfileId.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 profile_id = 2;
 * @return {number}
 */
proto.common.Amazon.AccountProfileId.prototype.getProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.AccountProfileId} returns this
 */
proto.common.Amazon.AccountProfileId.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingAdvertiser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingAdvertiser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAdvertiser.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertiserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    advertiserIdStr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingAdvertiser}
 */
proto.common.Amazon.AdvertisingAdvertiser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingAdvertiser;
  return proto.common.Amazon.AdvertisingAdvertiser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingAdvertiser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingAdvertiser}
 */
proto.common.Amazon.AdvertisingAdvertiser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdvertiserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertiserIdStr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingAdvertiser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingAdvertiser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingAdvertiser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertiserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 advertiser_id = 1;
 * @return {number}
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.getAdvertiserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.AdvertisingAdvertiser} returns this
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.setAdvertiserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string advertiser_id_str = 3;
 * @return {string}
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.getAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingAdvertiser} returns this
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.setAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingAdvertiser} returns this
 */
proto.common.Amazon.AdvertisingAdvertiser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.AdvertisingProfileMigration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.AdvertisingProfileMigration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingProfileMigration.toObject = function(includeInstance, msg) {
  var f, obj = {
    deprecatedProfileIdStr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deprecatedAdvertiserIdStr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preferredProfileIdStr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preferredAdvertiserIdStr: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.AdvertisingProfileMigration}
 */
proto.common.Amazon.AdvertisingProfileMigration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.AdvertisingProfileMigration;
  return proto.common.Amazon.AdvertisingProfileMigration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.AdvertisingProfileMigration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.AdvertisingProfileMigration}
 */
proto.common.Amazon.AdvertisingProfileMigration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedProfileIdStr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedAdvertiserIdStr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredProfileIdStr(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredAdvertiserIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.AdvertisingProfileMigration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.AdvertisingProfileMigration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.AdvertisingProfileMigration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeprecatedProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeprecatedAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreferredProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreferredAdvertiserIdStr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deprecated_profile_id_str = 1;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.getDeprecatedProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfileMigration} returns this
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.setDeprecatedProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deprecated_advertiser_id_str = 2;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.getDeprecatedAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfileMigration} returns this
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.setDeprecatedAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string preferred_profile_id_str = 3;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.getPreferredProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfileMigration} returns this
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.setPreferredProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string preferred_advertiser_id_str = 4;
 * @return {string}
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.getPreferredAdvertiserIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.AdvertisingProfileMigration} returns this
 */
proto.common.Amazon.AdvertisingProfileMigration.prototype.setPreferredAdvertiserIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SellerAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SellerAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SellerAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    sellingPartnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, 0),
    refreshToken: (f = msg.getRefreshToken()) && proto_common_crypto_pb.EncryptedData.toObject(includeInstance, f),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedTime: (f = msg.getUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.common.Amazon.SellerAccount.Error.toObject(includeInstance, f),
    accountType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    profileIdStr: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vendorGroupId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SellerAccount}
 */
proto.common.Amazon.SellerAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SellerAccount;
  return proto.common.Amazon.SellerAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SellerAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SellerAccount}
 */
proto.common.Amazon.SellerAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellingPartnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 3:
      var value = new proto_common_crypto_pb.EncryptedData;
      reader.readMessage(value,proto_common_crypto_pb.EncryptedData.deserializeBinaryFromReader);
      msg.setRefreshToken(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedTime(value);
      break;
    case 6:
      var value = new proto.common.Amazon.SellerAccount.Error;
      reader.readMessage(value,proto.common.Amazon.SellerAccount.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 7:
      var value = /** @type {!proto.common.Amazon.AdvertisingProfile.AccountType.Option} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIdStr(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SellerAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SellerAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SellerAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSellingPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRefreshToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_crypto_pb.EncryptedData.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.Amazon.SellerAccount.Error.serializeBinaryToWriter
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVendorGroupId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SellerAccount.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SellerAccount.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SellerAccount.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerAccount.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SellerAccount.Error}
 */
proto.common.Amazon.SellerAccount.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SellerAccount.Error;
  return proto.common.Amazon.SellerAccount.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SellerAccount.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SellerAccount.Error}
 */
proto.common.Amazon.SellerAccount.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.SellerAccount.Error.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SellerAccount.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SellerAccount.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SellerAccount.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerAccount.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Amazon.SellerAccount.Error.Type = {
  UNKNOWN: 0,
  UNAUTHORIZED: 1
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.common.Amazon.SellerAccount.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAccount.Error} returns this
 */
proto.common.Amazon.SellerAccount.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.common.Amazon.SellerAccount.Error.Type}
 */
proto.common.Amazon.SellerAccount.Error.prototype.getType = function() {
  return /** @type {!proto.common.Amazon.SellerAccount.Error.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.SellerAccount.Error.Type} value
 * @return {!proto.common.Amazon.SellerAccount.Error} returns this
 */
proto.common.Amazon.SellerAccount.Error.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string selling_partner_id = 1;
 * @return {string}
 */
proto.common.Amazon.SellerAccount.prototype.getSellingPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.setSellingPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Region.Option region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.common.Amazon.SellerAccount.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional EncryptedData refresh_token = 3;
 * @return {?proto.common.EncryptedData}
 */
proto.common.Amazon.SellerAccount.prototype.getRefreshToken = function() {
  return /** @type{?proto.common.EncryptedData} */ (
    jspb.Message.getWrapperField(this, proto_common_crypto_pb.EncryptedData, 3));
};


/**
 * @param {?proto.common.EncryptedData|undefined} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
*/
proto.common.Amazon.SellerAccount.prototype.setRefreshToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.clearRefreshToken = function() {
  return this.setRefreshToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.SellerAccount.prototype.hasRefreshToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp created_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Amazon.SellerAccount.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
*/
proto.common.Amazon.SellerAccount.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.SellerAccount.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updated_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Amazon.SellerAccount.prototype.getUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
*/
proto.common.Amazon.SellerAccount.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.clearUpdatedTime = function() {
  return this.setUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.SellerAccount.prototype.hasUpdatedTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Error error = 6;
 * @return {?proto.common.Amazon.SellerAccount.Error}
 */
proto.common.Amazon.SellerAccount.prototype.getError = function() {
  return /** @type{?proto.common.Amazon.SellerAccount.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.SellerAccount.Error, 6));
};


/**
 * @param {?proto.common.Amazon.SellerAccount.Error|undefined} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
*/
proto.common.Amazon.SellerAccount.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.SellerAccount.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AdvertisingProfile.AccountType.Option account_type = 7;
 * @return {!proto.common.Amazon.AdvertisingProfile.AccountType.Option}
 */
proto.common.Amazon.SellerAccount.prototype.getAccountType = function() {
  return /** @type {!proto.common.Amazon.AdvertisingProfile.AccountType.Option} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.Amazon.AdvertisingProfile.AccountType.Option} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string profile_id_str = 8;
 * @return {string}
 */
proto.common.Amazon.SellerAccount.prototype.getProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.setProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vendor_group_id = 9;
 * @return {string}
 */
proto.common.Amazon.SellerAccount.prototype.getVendorGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerAccount} returns this
 */
proto.common.Amazon.SellerAccount.prototype.setVendorGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SellerApi.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SellerApi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SellerApi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerApi.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SellerApi}
 */
proto.common.Amazon.SellerApi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SellerApi;
  return proto.common.Amazon.SellerApi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SellerApi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SellerApi}
 */
proto.common.Amazon.SellerApi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SellerApi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SellerApi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SellerApi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerApi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Amazon.SellerApi.Granularity = {
  UNSPECIFIED: 0,
  DAY: 1,
  HOUR: 2,
  MONTH: 3,
  TOTAL: 4,
  WEEK: 5,
  YEAR: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SellerApi.Money.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SellerApi.Money.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SellerApi.Money} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerApi.Money.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SellerApi.Money}
 */
proto.common.Amazon.SellerApi.Money.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SellerApi.Money;
  return proto.common.Amazon.SellerApi.Money.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SellerApi.Money} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SellerApi.Money}
 */
proto.common.Amazon.SellerApi.Money.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SellerApi.Money.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SellerApi.Money.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SellerApi.Money} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SellerApi.Money.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.common.Amazon.SellerApi.Money.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SellerApi.Money} returns this
 */
proto.common.Amazon.SellerApi.Money.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.common.Amazon.SellerApi.Money.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.SellerApi.Money} returns this
 */
proto.common.Amazon.SellerApi.Money.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Region.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Region.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Region} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Region.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Region}
 */
proto.common.Amazon.Region.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Region;
  return proto.common.Amazon.Region.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Region} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Region}
 */
proto.common.Amazon.Region.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Region.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Region.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Region} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Region.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Amazon.Region.Option = {
  UNKNOWN: 0,
  NORTH_AMERICA: 1,
  EUROPE: 2,
  FAR_EAST: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Region.Config.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Region.Config.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Region.Config} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Region.Config.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertisingApiDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sellingPartnerApiDomain: jspb.Message.getFieldWithDefault(msg, 2, ""),
    awsRegion: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Region.Config}
 */
proto.common.Amazon.Region.Config.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Region.Config;
  return proto.common.Amazon.Region.Config.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Region.Config} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Region.Config}
 */
proto.common.Amazon.Region.Config.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisingApiDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellingPartnerApiDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwsRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Region.Config.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Region.Config.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Region.Config} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Region.Config.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertisingApiDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSellingPartnerApiDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAwsRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string advertising_api_domain = 1;
 * @return {string}
 */
proto.common.Amazon.Region.Config.prototype.getAdvertisingApiDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Region.Config} returns this
 */
proto.common.Amazon.Region.Config.prototype.setAdvertisingApiDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string selling_partner_api_domain = 2;
 * @return {string}
 */
proto.common.Amazon.Region.Config.prototype.getSellingPartnerApiDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Region.Config} returns this
 */
proto.common.Amazon.Region.Config.prototype.setSellingPartnerApiDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string aws_region = 3;
 * @return {string}
 */
proto.common.Amazon.Region.Config.prototype.getAwsRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Region.Config} returns this
 */
proto.common.Amazon.Region.Config.prototype.setAwsRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.ExternalAdPublisher.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.ExternalAdPublisher.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.ExternalAdPublisher} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.ExternalAdPublisher.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.ExternalAdPublisher}
 */
proto.common.Amazon.ExternalAdPublisher.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.ExternalAdPublisher;
  return proto.common.Amazon.ExternalAdPublisher.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.ExternalAdPublisher} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.ExternalAdPublisher}
 */
proto.common.Amazon.ExternalAdPublisher.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.ExternalAdPublisher.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.ExternalAdPublisher.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.ExternalAdPublisher} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.ExternalAdPublisher.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Amazon.ExternalAdPublisher.Option = {
  UNSPECIFIED: 0,
  GOOGLE_ADS: 1,
  FACEBOOK_ADS: 2,
  MICROSOFT_ADS: 3,
  PINTEREST_ADS: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Marketplace.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Marketplace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Marketplace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Marketplace.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Marketplace}
 */
proto.common.Amazon.Marketplace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Marketplace;
  return proto.common.Amazon.Marketplace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Marketplace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Marketplace}
 */
proto.common.Amazon.Marketplace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Marketplace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Marketplace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Marketplace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Marketplace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Amazon.Marketplace.Option = {
  UNKNOWN: 0,
  CANADA: 1,
  UNITED_STATES: 2,
  MEXICO: 3,
  BRAZIL: 4,
  UNITED_ARAB_EMIRATES: 5,
  GERMANY: 6,
  SPAIN: 7,
  FRANCE: 8,
  UNITED_KINGDOM: 9,
  INDIA: 10,
  ITALY: 11,
  NETHERLANDS: 12,
  POLAND: 13,
  SAUDI_ARABIA: 14,
  SWEDEN: 15,
  TURKEY: 16,
  SINGAPORE: 17,
  AUSTRALIA: 18,
  JAPAN: 19,
  EGYPT: 20,
  BELGIUM: 21
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Marketplace.Config.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Marketplace.Config.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Marketplace.Config} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Marketplace.Config.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: jspb.Message.getFieldWithDefault(msg, 1, 0),
    marketplaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 4, ""),
    queryTimezone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Marketplace.Config}
 */
proto.common.Amazon.Marketplace.Config.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Marketplace.Config;
  return proto.common.Amazon.Marketplace.Config.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Marketplace.Config} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Marketplace.Config}
 */
proto.common.Amazon.Marketplace.Config.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketplaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryTimezone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Marketplace.Config.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Marketplace.Config.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Marketplace.Config} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Marketplace.Config.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMarketplaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQueryTimezone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional Region.Option region = 1;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.common.Amazon.Marketplace.Config.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.common.Amazon.Marketplace.Config} returns this
 */
proto.common.Amazon.Marketplace.Config.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string marketplace_id = 2;
 * @return {string}
 */
proto.common.Amazon.Marketplace.Config.prototype.getMarketplaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Marketplace.Config} returns this
 */
proto.common.Amazon.Marketplace.Config.prototype.setMarketplaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.common.Amazon.Marketplace.Config.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Marketplace.Config} returns this
 */
proto.common.Amazon.Marketplace.Config.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string domain = 4;
 * @return {string}
 */
proto.common.Amazon.Marketplace.Config.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Marketplace.Config} returns this
 */
proto.common.Amazon.Marketplace.Config.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query_timezone = 5;
 * @return {string}
 */
proto.common.Amazon.Marketplace.Config.prototype.getQueryTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Marketplace.Config} returns this
 */
proto.common.Amazon.Marketplace.Config.prototype.setQueryTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.common.Amazon.Marketplace.Config.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Marketplace.Config} returns this
 */
proto.common.Amazon.Marketplace.Config.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Amazon.Product.repeatedFields_ = [6,8,9,10,11,12,13,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    asin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentAsin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    link: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    keywordsListList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    mainImage: (f = msg.getMainImage()) && proto.common.Amazon.Product.Image.toObject(includeInstance, f),
    summarizationAttributesList: jspb.Message.toObjectList(msg.getSummarizationAttributesList(),
    proto.common.Amazon.Product.SummarizationAttribute.toObject, includeInstance),
    reviewsThatMentionList: jspb.Message.toObjectList(msg.getReviewsThatMentionList(),
    proto.common.Amazon.Product.ReviewsThatMention.toObject, includeInstance),
    bestsellersRankList: jspb.Message.toObjectList(msg.getBestsellersRankList(),
    proto.common.Amazon.Product.BestsellersRank.toObject, includeInstance),
    featureBulletsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.common.Amazon.Product.Attribute.toObject, includeInstance),
    buyboxWinner: (f = msg.getBuyboxWinner()) && proto.common.Amazon.Product.BuyboxWinner.toObject(includeInstance, f),
    specificationsList: jspb.Message.toObjectList(msg.getSpecificationsList(),
    proto.common.Amazon.Product.Specification.toObject, includeInstance),
    hasCoupon: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    amazonsChoice: (f = msg.getAmazonsChoice()) && proto.common.Amazon.Product.AmazonsChoice.toObject(includeInstance, f),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.common.Amazon.Product.Category.toObject, includeInstance),
    variantsList: jspb.Message.toObjectList(msg.getVariantsList(),
    proto.common.Amazon.Product.Variant.toObject, includeInstance),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    ratingsTotal: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product}
 */
proto.common.Amazon.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product;
  return proto.common.Amazon.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product}
 */
proto.common.Amazon.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentAsin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywordsList(value);
      break;
    case 7:
      var value = new proto.common.Amazon.Product.Image;
      reader.readMessage(value,proto.common.Amazon.Product.Image.deserializeBinaryFromReader);
      msg.setMainImage(value);
      break;
    case 8:
      var value = new proto.common.Amazon.Product.SummarizationAttribute;
      reader.readMessage(value,proto.common.Amazon.Product.SummarizationAttribute.deserializeBinaryFromReader);
      msg.addSummarizationAttributes(value);
      break;
    case 9:
      var value = new proto.common.Amazon.Product.ReviewsThatMention;
      reader.readMessage(value,proto.common.Amazon.Product.ReviewsThatMention.deserializeBinaryFromReader);
      msg.addReviewsThatMention(value);
      break;
    case 10:
      var value = new proto.common.Amazon.Product.BestsellersRank;
      reader.readMessage(value,proto.common.Amazon.Product.BestsellersRank.deserializeBinaryFromReader);
      msg.addBestsellersRank(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureBullets(value);
      break;
    case 12:
      var value = new proto.common.Amazon.Product.Attribute;
      reader.readMessage(value,proto.common.Amazon.Product.Attribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 20:
      var value = new proto.common.Amazon.Product.BuyboxWinner;
      reader.readMessage(value,proto.common.Amazon.Product.BuyboxWinner.deserializeBinaryFromReader);
      msg.setBuyboxWinner(value);
      break;
    case 13:
      var value = new proto.common.Amazon.Product.Specification;
      reader.readMessage(value,proto.common.Amazon.Product.Specification.deserializeBinaryFromReader);
      msg.addSpecifications(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCoupon(value);
      break;
    case 15:
      var value = new proto.common.Amazon.Product.AmazonsChoice;
      reader.readMessage(value,proto.common.Amazon.Product.AmazonsChoice.deserializeBinaryFromReader);
      msg.setAmazonsChoice(value);
      break;
    case 16:
      var value = new proto.common.Amazon.Product.Category;
      reader.readMessage(value,proto.common.Amazon.Product.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 17:
      var value = new proto.common.Amazon.Product.Variant;
      reader.readMessage(value,proto.common.Amazon.Product.Variant.deserializeBinaryFromReader);
      msg.addVariants(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingsTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentAsin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKeywordsListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getMainImage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.Amazon.Product.Image.serializeBinaryToWriter
    );
  }
  f = message.getSummarizationAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.common.Amazon.Product.SummarizationAttribute.serializeBinaryToWriter
    );
  }
  f = message.getReviewsThatMentionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.common.Amazon.Product.ReviewsThatMention.serializeBinaryToWriter
    );
  }
  f = message.getBestsellersRankList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.common.Amazon.Product.BestsellersRank.serializeBinaryToWriter
    );
  }
  f = message.getFeatureBulletsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.common.Amazon.Product.Attribute.serializeBinaryToWriter
    );
  }
  f = message.getBuyboxWinner();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.common.Amazon.Product.BuyboxWinner.serializeBinaryToWriter
    );
  }
  f = message.getSpecificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.common.Amazon.Product.Specification.serializeBinaryToWriter
    );
  }
  f = message.getHasCoupon();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAmazonsChoice();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.common.Amazon.Product.AmazonsChoice.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.common.Amazon.Product.Category.serializeBinaryToWriter
    );
  }
  f = message.getVariantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.common.Amazon.Product.Variant.serializeBinaryToWriter
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getRatingsTotal();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Image}
 */
proto.common.Amazon.Product.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Image;
  return proto.common.Amazon.Product.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Image}
 */
proto.common.Amazon.Product.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Image.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Image} returns this
 */
proto.common.Amazon.Product.Image.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.SummarizationAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.SummarizationAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.SummarizationAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.SummarizationAttribute}
 */
proto.common.Amazon.Product.SummarizationAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.SummarizationAttribute;
  return proto.common.Amazon.Product.SummarizationAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.SummarizationAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.SummarizationAttribute}
 */
proto.common.Amazon.Product.SummarizationAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.SummarizationAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.SummarizationAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.SummarizationAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.SummarizationAttribute} returns this
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.Product.SummarizationAttribute} returns this
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.SummarizationAttribute} returns this
 */
proto.common.Amazon.Product.SummarizationAttribute.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.ReviewsThatMention.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.ReviewsThatMention.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.ReviewsThatMention} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.ReviewsThatMention.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.ReviewsThatMention}
 */
proto.common.Amazon.Product.ReviewsThatMention.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.ReviewsThatMention;
  return proto.common.Amazon.Product.ReviewsThatMention.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.ReviewsThatMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.ReviewsThatMention}
 */
proto.common.Amazon.Product.ReviewsThatMention.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.ReviewsThatMention.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.ReviewsThatMention.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.ReviewsThatMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.ReviewsThatMention.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Product.ReviewsThatMention.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.ReviewsThatMention} returns this
 */
proto.common.Amazon.Product.ReviewsThatMention.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.BestsellersRank.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.BestsellersRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.BestsellersRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.BestsellersRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.BestsellersRank}
 */
proto.common.Amazon.Product.BestsellersRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.BestsellersRank;
  return proto.common.Amazon.Product.BestsellersRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.BestsellersRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.BestsellersRank}
 */
proto.common.Amazon.Product.BestsellersRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.BestsellersRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.BestsellersRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.BestsellersRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.BestsellersRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 rank = 1;
 * @return {number}
 */
proto.common.Amazon.Product.BestsellersRank.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.Product.BestsellersRank} returns this
 */
proto.common.Amazon.Product.BestsellersRank.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.common.Amazon.Product.BestsellersRank.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.BestsellersRank} returns this
 */
proto.common.Amazon.Product.BestsellersRank.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.common.Amazon.Product.BestsellersRank.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.BestsellersRank} returns this
 */
proto.common.Amazon.Product.BestsellersRank.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Attribute}
 */
proto.common.Amazon.Product.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Attribute;
  return proto.common.Amazon.Product.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Attribute}
 */
proto.common.Amazon.Product.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Attribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Attribute} returns this
 */
proto.common.Amazon.Product.Attribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.Amazon.Product.Attribute.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Attribute} returns this
 */
proto.common.Amazon.Product.Attribute.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.BuyboxWinner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.BuyboxWinner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.BuyboxWinner.toObject = function(includeInstance, msg) {
  var f, obj = {
    isPrime: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    availability: (f = msg.getAvailability()) && proto.common.Amazon.Product.Availability.toObject(includeInstance, f),
    fulfillment: (f = msg.getFulfillment()) && proto.common.Amazon.Product.Fulfillment.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto.common.Amazon.Product.Price.toObject(includeInstance, f),
    shipping: (f = msg.getShipping()) && proto.common.Amazon.Product.Price.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.BuyboxWinner}
 */
proto.common.Amazon.Product.BuyboxWinner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.BuyboxWinner;
  return proto.common.Amazon.Product.BuyboxWinner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.BuyboxWinner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.BuyboxWinner}
 */
proto.common.Amazon.Product.BuyboxWinner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrime(value);
      break;
    case 2:
      var value = new proto.common.Amazon.Product.Availability;
      reader.readMessage(value,proto.common.Amazon.Product.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    case 3:
      var value = new proto.common.Amazon.Product.Fulfillment;
      reader.readMessage(value,proto.common.Amazon.Product.Fulfillment.deserializeBinaryFromReader);
      msg.setFulfillment(value);
      break;
    case 4:
      var value = new proto.common.Amazon.Product.Price;
      reader.readMessage(value,proto.common.Amazon.Product.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = new proto.common.Amazon.Product.Price;
      reader.readMessage(value,proto.common.Amazon.Product.Price.deserializeBinaryFromReader);
      msg.setShipping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.BuyboxWinner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.BuyboxWinner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.BuyboxWinner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsPrime();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Amazon.Product.Availability.serializeBinaryToWriter
    );
  }
  f = message.getFulfillment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Amazon.Product.Fulfillment.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Amazon.Product.Price.serializeBinaryToWriter
    );
  }
  f = message.getShipping();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Amazon.Product.Price.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_prime = 1;
 * @return {boolean}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.getIsPrime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.setIsPrime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Availability availability = 2;
 * @return {?proto.common.Amazon.Product.Availability}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.getAvailability = function() {
  return /** @type{?proto.common.Amazon.Product.Availability} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Availability, 2));
};


/**
 * @param {?proto.common.Amazon.Product.Availability|undefined} value
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
*/
proto.common.Amazon.Product.BuyboxWinner.prototype.setAvailability = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.clearAvailability = function() {
  return this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Fulfillment fulfillment = 3;
 * @return {?proto.common.Amazon.Product.Fulfillment}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.getFulfillment = function() {
  return /** @type{?proto.common.Amazon.Product.Fulfillment} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Fulfillment, 3));
};


/**
 * @param {?proto.common.Amazon.Product.Fulfillment|undefined} value
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
*/
proto.common.Amazon.Product.BuyboxWinner.prototype.setFulfillment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.clearFulfillment = function() {
  return this.setFulfillment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.hasFulfillment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Price price = 4;
 * @return {?proto.common.Amazon.Product.Price}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.getPrice = function() {
  return /** @type{?proto.common.Amazon.Product.Price} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Price, 4));
};


/**
 * @param {?proto.common.Amazon.Product.Price|undefined} value
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
*/
proto.common.Amazon.Product.BuyboxWinner.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price shipping = 5;
 * @return {?proto.common.Amazon.Product.Price}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.getShipping = function() {
  return /** @type{?proto.common.Amazon.Product.Price} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Price, 5));
};


/**
 * @param {?proto.common.Amazon.Product.Price|undefined} value
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
*/
proto.common.Amazon.Product.BuyboxWinner.prototype.setShipping = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product.BuyboxWinner} returns this
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.clearShipping = function() {
  return this.setShipping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.BuyboxWinner.prototype.hasShipping = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Availability.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Availability.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Availability} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Availability.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    raw: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dispatchDays: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Availability}
 */
proto.common.Amazon.Product.Availability.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Availability;
  return proto.common.Amazon.Product.Availability.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Availability} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Availability}
 */
proto.common.Amazon.Product.Availability.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDispatchDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Availability.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Availability.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Availability} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Availability.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDispatchDays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Availability.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Availability} returns this
 */
proto.common.Amazon.Product.Availability.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string raw = 2;
 * @return {string}
 */
proto.common.Amazon.Product.Availability.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Availability} returns this
 */
proto.common.Amazon.Product.Availability.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 dispatch_days = 3;
 * @return {number}
 */
proto.common.Amazon.Product.Availability.prototype.getDispatchDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.Product.Availability} returns this
 */
proto.common.Amazon.Product.Availability.prototype.setDispatchDays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Fulfillment.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Fulfillment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Fulfillment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Fulfillment.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isSoldByAmazon: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isFulfilledByAmazon: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isFulfilledByThirdParty: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isSoldByThirdParty: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Fulfillment}
 */
proto.common.Amazon.Product.Fulfillment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Fulfillment;
  return proto.common.Amazon.Product.Fulfillment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Fulfillment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Fulfillment}
 */
proto.common.Amazon.Product.Fulfillment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSoldByAmazon(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFulfilledByAmazon(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFulfilledByThirdParty(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSoldByThirdParty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Fulfillment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Fulfillment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Fulfillment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Fulfillment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsSoldByAmazon();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsFulfilledByAmazon();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsFulfilledByThirdParty();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsSoldByThirdParty();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Fulfillment.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Fulfillment} returns this
 */
proto.common.Amazon.Product.Fulfillment.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_sold_by_amazon = 2;
 * @return {boolean}
 */
proto.common.Amazon.Product.Fulfillment.prototype.getIsSoldByAmazon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product.Fulfillment} returns this
 */
proto.common.Amazon.Product.Fulfillment.prototype.setIsSoldByAmazon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_fulfilled_by_amazon = 3;
 * @return {boolean}
 */
proto.common.Amazon.Product.Fulfillment.prototype.getIsFulfilledByAmazon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product.Fulfillment} returns this
 */
proto.common.Amazon.Product.Fulfillment.prototype.setIsFulfilledByAmazon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_fulfilled_by_third_party = 4;
 * @return {boolean}
 */
proto.common.Amazon.Product.Fulfillment.prototype.getIsFulfilledByThirdParty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product.Fulfillment} returns this
 */
proto.common.Amazon.Product.Fulfillment.prototype.setIsFulfilledByThirdParty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_sold_by_third_party = 5;
 * @return {boolean}
 */
proto.common.Amazon.Product.Fulfillment.prototype.getIsSoldByThirdParty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product.Fulfillment} returns this
 */
proto.common.Amazon.Product.Fulfillment.prototype.setIsSoldByThirdParty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Price.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Price.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Price} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Price.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    raw: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Price}
 */
proto.common.Amazon.Product.Price.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Price;
  return proto.common.Amazon.Product.Price.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Price} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Price}
 */
proto.common.Amazon.Product.Price.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Price.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Price.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Price} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Price.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Price.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Price} returns this
 */
proto.common.Amazon.Product.Price.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.common.Amazon.Product.Price.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.Product.Price} returns this
 */
proto.common.Amazon.Product.Price.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.common.Amazon.Product.Price.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Price} returns this
 */
proto.common.Amazon.Product.Price.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string raw = 4;
 * @return {string}
 */
proto.common.Amazon.Product.Price.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Price} returns this
 */
proto.common.Amazon.Product.Price.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Shipping.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Shipping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Shipping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Shipping.toObject = function(includeInstance, msg) {
  var f, obj = {
    raw: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Shipping}
 */
proto.common.Amazon.Product.Shipping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Shipping;
  return proto.common.Amazon.Product.Shipping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Shipping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Shipping}
 */
proto.common.Amazon.Product.Shipping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Shipping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Shipping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Shipping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Shipping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string raw = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Shipping.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Shipping} returns this
 */
proto.common.Amazon.Product.Shipping.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Specification.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Specification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Specification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Specification.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Specification}
 */
proto.common.Amazon.Product.Specification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Specification;
  return proto.common.Amazon.Product.Specification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Specification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Specification}
 */
proto.common.Amazon.Product.Specification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Specification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Specification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Specification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Specification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Specification.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Specification} returns this
 */
proto.common.Amazon.Product.Specification.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.Amazon.Product.Specification.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Specification} returns this
 */
proto.common.Amazon.Product.Specification.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.AmazonsChoice.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.AmazonsChoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.AmazonsChoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.AmazonsChoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    keywords: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.AmazonsChoice}
 */
proto.common.Amazon.Product.AmazonsChoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.AmazonsChoice;
  return proto.common.Amazon.Product.AmazonsChoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.AmazonsChoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.AmazonsChoice}
 */
proto.common.Amazon.Product.AmazonsChoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.AmazonsChoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.AmazonsChoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.AmazonsChoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.AmazonsChoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeywords();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keywords = 1;
 * @return {string}
 */
proto.common.Amazon.Product.AmazonsChoice.prototype.getKeywords = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.AmazonsChoice} returns this
 */
proto.common.Amazon.Product.AmazonsChoice.prototype.setKeywords = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Category}
 */
proto.common.Amazon.Product.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Category;
  return proto.common.Amazon.Product.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Category}
 */
proto.common.Amazon.Product.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Category.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Category} returns this
 */
proto.common.Amazon.Product.Category.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.common.Amazon.Product.Category.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Category} returns this
 */
proto.common.Amazon.Product.Category.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Amazon.Product.Variant.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Variant.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Variant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Variant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Variant.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    asin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isCurrentProduct: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    link: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dimensionsList: jspb.Message.toObjectList(msg.getDimensionsList(),
    proto.common.Amazon.Product.Variant.Dimension.toObject, includeInstance),
    mainImage: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Variant}
 */
proto.common.Amazon.Product.Variant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Variant;
  return proto.common.Amazon.Product.Variant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Variant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Variant}
 */
proto.common.Amazon.Product.Variant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrentProduct(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 5:
      var value = new proto.common.Amazon.Product.Variant.Dimension;
      reader.readMessage(value,proto.common.Amazon.Product.Variant.Dimension.deserializeBinaryFromReader);
      msg.addDimensions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Variant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Variant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Variant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Variant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsCurrentProduct();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDimensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.common.Amazon.Product.Variant.Dimension.serializeBinaryToWriter
    );
  }
  f = message.getMainImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Product.Variant.Dimension.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Product.Variant.Dimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Product.Variant.Dimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Variant.Dimension.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Product.Variant.Dimension}
 */
proto.common.Amazon.Product.Variant.Dimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Product.Variant.Dimension;
  return proto.common.Amazon.Product.Variant.Dimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Product.Variant.Dimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Product.Variant.Dimension}
 */
proto.common.Amazon.Product.Variant.Dimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Product.Variant.Dimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Product.Variant.Dimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Product.Variant.Dimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Product.Variant.Dimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Variant.Dimension.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Variant.Dimension} returns this
 */
proto.common.Amazon.Product.Variant.Dimension.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.Amazon.Product.Variant.Dimension.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Variant.Dimension} returns this
 */
proto.common.Amazon.Product.Variant.Dimension.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.common.Amazon.Product.Variant.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Variant} returns this
 */
proto.common.Amazon.Product.Variant.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asin = 2;
 * @return {string}
 */
proto.common.Amazon.Product.Variant.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Variant} returns this
 */
proto.common.Amazon.Product.Variant.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_current_product = 3;
 * @return {boolean}
 */
proto.common.Amazon.Product.Variant.prototype.getIsCurrentProduct = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product.Variant} returns this
 */
proto.common.Amazon.Product.Variant.prototype.setIsCurrentProduct = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string link = 4;
 * @return {string}
 */
proto.common.Amazon.Product.Variant.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Variant} returns this
 */
proto.common.Amazon.Product.Variant.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Dimension dimensions = 5;
 * @return {!Array<!proto.common.Amazon.Product.Variant.Dimension>}
 */
proto.common.Amazon.Product.Variant.prototype.getDimensionsList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.Variant.Dimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.Variant.Dimension, 5));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.Variant.Dimension>} value
 * @return {!proto.common.Amazon.Product.Variant} returns this
*/
proto.common.Amazon.Product.Variant.prototype.setDimensionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.Amazon.Product.Variant.Dimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.Variant.Dimension}
 */
proto.common.Amazon.Product.Variant.prototype.addDimensions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.Amazon.Product.Variant.Dimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product.Variant} returns this
 */
proto.common.Amazon.Product.Variant.prototype.clearDimensionsList = function() {
  return this.setDimensionsList([]);
};


/**
 * optional string main_image = 6;
 * @return {string}
 */
proto.common.Amazon.Product.Variant.prototype.getMainImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product.Variant} returns this
 */
proto.common.Amazon.Product.Variant.prototype.setMainImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.common.Amazon.Product.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asin = 2;
 * @return {string}
 */
proto.common.Amazon.Product.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string parent_asin = 3;
 * @return {string}
 */
proto.common.Amazon.Product.prototype.getParentAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setParentAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string link = 4;
 * @return {string}
 */
proto.common.Amazon.Product.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.common.Amazon.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string keywords_list = 6;
 * @return {!Array<string>}
 */
proto.common.Amazon.Product.prototype.getKeywordsListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setKeywordsListList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.addKeywordsList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearKeywordsListList = function() {
  return this.setKeywordsListList([]);
};


/**
 * optional Image main_image = 7;
 * @return {?proto.common.Amazon.Product.Image}
 */
proto.common.Amazon.Product.prototype.getMainImage = function() {
  return /** @type{?proto.common.Amazon.Product.Image} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Image, 7));
};


/**
 * @param {?proto.common.Amazon.Product.Image|undefined} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setMainImage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearMainImage = function() {
  return this.setMainImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.prototype.hasMainImage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated SummarizationAttribute summarization_attributes = 8;
 * @return {!Array<!proto.common.Amazon.Product.SummarizationAttribute>}
 */
proto.common.Amazon.Product.prototype.getSummarizationAttributesList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.SummarizationAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.SummarizationAttribute, 8));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.SummarizationAttribute>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setSummarizationAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.Amazon.Product.SummarizationAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.SummarizationAttribute}
 */
proto.common.Amazon.Product.prototype.addSummarizationAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.Amazon.Product.SummarizationAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearSummarizationAttributesList = function() {
  return this.setSummarizationAttributesList([]);
};


/**
 * repeated ReviewsThatMention reviews_that_mention = 9;
 * @return {!Array<!proto.common.Amazon.Product.ReviewsThatMention>}
 */
proto.common.Amazon.Product.prototype.getReviewsThatMentionList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.ReviewsThatMention>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.ReviewsThatMention, 9));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.ReviewsThatMention>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setReviewsThatMentionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.Amazon.Product.ReviewsThatMention=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.ReviewsThatMention}
 */
proto.common.Amazon.Product.prototype.addReviewsThatMention = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.Amazon.Product.ReviewsThatMention, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearReviewsThatMentionList = function() {
  return this.setReviewsThatMentionList([]);
};


/**
 * repeated BestsellersRank bestsellers_rank = 10;
 * @return {!Array<!proto.common.Amazon.Product.BestsellersRank>}
 */
proto.common.Amazon.Product.prototype.getBestsellersRankList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.BestsellersRank>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.BestsellersRank, 10));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.BestsellersRank>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setBestsellersRankList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.Amazon.Product.BestsellersRank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.BestsellersRank}
 */
proto.common.Amazon.Product.prototype.addBestsellersRank = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.Amazon.Product.BestsellersRank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearBestsellersRankList = function() {
  return this.setBestsellersRankList([]);
};


/**
 * repeated string feature_bullets = 11;
 * @return {!Array<string>}
 */
proto.common.Amazon.Product.prototype.getFeatureBulletsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setFeatureBulletsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.addFeatureBullets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearFeatureBulletsList = function() {
  return this.setFeatureBulletsList([]);
};


/**
 * repeated Attribute attributes = 12;
 * @return {!Array<!proto.common.Amazon.Product.Attribute>}
 */
proto.common.Amazon.Product.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.Attribute, 12));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.Attribute>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.common.Amazon.Product.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.Attribute}
 */
proto.common.Amazon.Product.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.common.Amazon.Product.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional BuyboxWinner buybox_winner = 20;
 * @return {?proto.common.Amazon.Product.BuyboxWinner}
 */
proto.common.Amazon.Product.prototype.getBuyboxWinner = function() {
  return /** @type{?proto.common.Amazon.Product.BuyboxWinner} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.BuyboxWinner, 20));
};


/**
 * @param {?proto.common.Amazon.Product.BuyboxWinner|undefined} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setBuyboxWinner = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearBuyboxWinner = function() {
  return this.setBuyboxWinner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.prototype.hasBuyboxWinner = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * repeated Specification specifications = 13;
 * @return {!Array<!proto.common.Amazon.Product.Specification>}
 */
proto.common.Amazon.Product.prototype.getSpecificationsList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.Specification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.Specification, 13));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.Specification>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setSpecificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.common.Amazon.Product.Specification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.Specification}
 */
proto.common.Amazon.Product.prototype.addSpecifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.common.Amazon.Product.Specification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearSpecificationsList = function() {
  return this.setSpecificationsList([]);
};


/**
 * optional bool has_coupon = 14;
 * @return {boolean}
 */
proto.common.Amazon.Product.prototype.getHasCoupon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setHasCoupon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional AmazonsChoice amazons_choice = 15;
 * @return {?proto.common.Amazon.Product.AmazonsChoice}
 */
proto.common.Amazon.Product.prototype.getAmazonsChoice = function() {
  return /** @type{?proto.common.Amazon.Product.AmazonsChoice} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.AmazonsChoice, 15));
};


/**
 * @param {?proto.common.Amazon.Product.AmazonsChoice|undefined} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setAmazonsChoice = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearAmazonsChoice = function() {
  return this.setAmazonsChoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Product.prototype.hasAmazonsChoice = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated Category categories = 16;
 * @return {!Array<!proto.common.Amazon.Product.Category>}
 */
proto.common.Amazon.Product.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.Category, 16));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.Category>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.common.Amazon.Product.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.Category}
 */
proto.common.Amazon.Product.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.common.Amazon.Product.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated Variant variants = 17;
 * @return {!Array<!proto.common.Amazon.Product.Variant>}
 */
proto.common.Amazon.Product.prototype.getVariantsList = function() {
  return /** @type{!Array<!proto.common.Amazon.Product.Variant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.Product.Variant, 17));
};


/**
 * @param {!Array<!proto.common.Amazon.Product.Variant>} value
 * @return {!proto.common.Amazon.Product} returns this
*/
proto.common.Amazon.Product.prototype.setVariantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.common.Amazon.Product.Variant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.Product.Variant}
 */
proto.common.Amazon.Product.prototype.addVariants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.common.Amazon.Product.Variant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.clearVariantsList = function() {
  return this.setVariantsList([]);
};


/**
 * optional float rating = 18;
 * @return {number}
 */
proto.common.Amazon.Product.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional int32 ratings_total = 19;
 * @return {number}
 */
proto.common.Amazon.Product.prototype.getRatingsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.Product} returns this
 */
proto.common.Amazon.Product.prototype.setRatingsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.Storefront.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.Storefront.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.Storefront} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Storefront.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: (f = msg.getLogo()) && proto.common.Amazon.Product.Image.toObject(includeInstance, f),
    heroImage: (f = msg.getHeroImage()) && proto.common.Amazon.Product.Image.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.Storefront}
 */
proto.common.Amazon.Storefront.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.Storefront;
  return proto.common.Amazon.Storefront.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.Storefront} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.Storefront}
 */
proto.common.Amazon.Storefront.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.common.Amazon.Product.Image;
      reader.readMessage(value,proto.common.Amazon.Product.Image.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    case 4:
      var value = new proto.common.Amazon.Product.Image;
      reader.readMessage(value,proto.common.Amazon.Product.Image.deserializeBinaryFromReader);
      msg.setHeroImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.Storefront.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.Storefront.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.Storefront} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.Storefront.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Amazon.Product.Image.serializeBinaryToWriter
    );
  }
  f = message.getHeroImage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Amazon.Product.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.Storefront.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Storefront} returns this
 */
proto.common.Amazon.Storefront.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.common.Amazon.Storefront.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.Storefront} returns this
 */
proto.common.Amazon.Storefront.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Product.Image logo = 3;
 * @return {?proto.common.Amazon.Product.Image}
 */
proto.common.Amazon.Storefront.prototype.getLogo = function() {
  return /** @type{?proto.common.Amazon.Product.Image} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Image, 3));
};


/**
 * @param {?proto.common.Amazon.Product.Image|undefined} value
 * @return {!proto.common.Amazon.Storefront} returns this
*/
proto.common.Amazon.Storefront.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Storefront} returns this
 */
proto.common.Amazon.Storefront.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Storefront.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Product.Image hero_image = 4;
 * @return {?proto.common.Amazon.Product.Image}
 */
proto.common.Amazon.Storefront.prototype.getHeroImage = function() {
  return /** @type{?proto.common.Amazon.Product.Image} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Image, 4));
};


/**
 * @param {?proto.common.Amazon.Product.Image|undefined} value
 * @return {!proto.common.Amazon.Storefront} returns this
*/
proto.common.Amazon.Storefront.prototype.setHeroImage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.Storefront} returns this
 */
proto.common.Amazon.Storefront.prototype.clearHeroImage = function() {
  return this.setHeroImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.Storefront.prototype.hasHeroImage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.StorefrontProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.StorefrontProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.StorefrontProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.StorefrontProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, ""),
    asin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    image: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    ratingsTotal: jspb.Message.getFieldWithDefault(msg, 7, 0),
    price: (f = msg.getPrice()) && proto.common.Amazon.Product.Price.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    editorialImage: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.StorefrontProduct}
 */
proto.common.Amazon.StorefrontProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.StorefrontProduct;
  return proto.common.Amazon.StorefrontProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.StorefrontProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.StorefrontProduct}
 */
proto.common.Amazon.StorefrontProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingsTotal(value);
      break;
    case 8:
      var value = new proto.common.Amazon.Product.Price;
      reader.readMessage(value,proto.common.Amazon.Product.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditorialImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.StorefrontProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.StorefrontProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.StorefrontProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.StorefrontProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getRatingsTotal();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.common.Amazon.Product.Price.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEditorialImage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.common.Amazon.StorefrontProduct.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.common.Amazon.StorefrontProduct.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.common.Amazon.StorefrontProduct.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string asin = 4;
 * @return {string}
 */
proto.common.Amazon.StorefrontProduct.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.common.Amazon.StorefrontProduct.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float rating = 6;
 * @return {number}
 */
proto.common.Amazon.StorefrontProduct.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int32 ratings_total = 7;
 * @return {number}
 */
proto.common.Amazon.StorefrontProduct.prototype.getRatingsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setRatingsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Product.Price price = 8;
 * @return {?proto.common.Amazon.Product.Price}
 */
proto.common.Amazon.StorefrontProduct.prototype.getPrice = function() {
  return /** @type{?proto.common.Amazon.Product.Price} */ (
    jspb.Message.getWrapperField(this, proto.common.Amazon.Product.Price, 8));
};


/**
 * @param {?proto.common.Amazon.Product.Price|undefined} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
*/
proto.common.Amazon.StorefrontProduct.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Amazon.StorefrontProduct.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.common.Amazon.StorefrontProduct.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string editorial_image = 10;
 * @return {string}
 */
proto.common.Amazon.StorefrontProduct.prototype.getEditorialImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontProduct} returns this
 */
proto.common.Amazon.StorefrontProduct.prototype.setEditorialImage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.StorefrontCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.StorefrontCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.StorefrontCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.StorefrontCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    storeId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.StorefrontCategory}
 */
proto.common.Amazon.StorefrontCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.StorefrontCategory;
  return proto.common.Amazon.StorefrontCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.StorefrontCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.StorefrontCategory}
 */
proto.common.Amazon.StorefrontCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.StorefrontCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.StorefrontCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.StorefrontCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.StorefrontCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsCurrent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStoreId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Amazon.StorefrontCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontCategory} returns this
 */
proto.common.Amazon.StorefrontCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.common.Amazon.StorefrontCategory.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontCategory} returns this
 */
proto.common.Amazon.StorefrontCategory.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_current = 3;
 * @return {boolean}
 */
proto.common.Amazon.StorefrontCategory.prototype.getIsCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.StorefrontCategory} returns this
 */
proto.common.Amazon.StorefrontCategory.prototype.setIsCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string store_id = 4;
 * @return {string}
 */
proto.common.Amazon.StorefrontCategory.prototype.getStoreId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.StorefrontCategory} returns this
 */
proto.common.Amazon.StorefrontCategory.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SearchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SearchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SearchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SearchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionInPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    asin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sponsored: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SearchResult}
 */
proto.common.Amazon.SearchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SearchResult;
  return proto.common.Amazon.SearchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SearchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SearchResult}
 */
proto.common.Amazon.SearchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionInPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsin(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSponsored(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SearchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SearchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SearchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SearchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPositionInPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAsin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSponsored();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.common.Amazon.SearchResult.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.SearchResult} returns this
 */
proto.common.Amazon.SearchResult.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.common.Amazon.SearchResult.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.SearchResult} returns this
 */
proto.common.Amazon.SearchResult.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 position_in_page = 3;
 * @return {number}
 */
proto.common.Amazon.SearchResult.prototype.getPositionInPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Amazon.SearchResult} returns this
 */
proto.common.Amazon.SearchResult.prototype.setPositionInPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string asin = 4;
 * @return {string}
 */
proto.common.Amazon.SearchResult.prototype.getAsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Amazon.SearchResult} returns this
 */
proto.common.Amazon.SearchResult.prototype.setAsin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sponsored = 5;
 * @return {boolean}
 */
proto.common.Amazon.SearchResult.prototype.getSponsored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Amazon.SearchResult} returns this
 */
proto.common.Amazon.SearchResult.prototype.setSponsored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Amazon.SearchTermHistoryDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Amazon.SearchTermHistoryDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Amazon.SearchTermHistoryDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Amazon.SearchTermHistoryDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SearchTermHistoryDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchResultsList: jspb.Message.toObjectList(msg.getSearchResultsList(),
    proto.common.Amazon.SearchResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Amazon.SearchTermHistoryDetails}
 */
proto.common.Amazon.SearchTermHistoryDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Amazon.SearchTermHistoryDetails;
  return proto.common.Amazon.SearchTermHistoryDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Amazon.SearchTermHistoryDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Amazon.SearchTermHistoryDetails}
 */
proto.common.Amazon.SearchTermHistoryDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Amazon.SearchResult;
      reader.readMessage(value,proto.common.Amazon.SearchResult.deserializeBinaryFromReader);
      msg.addSearchResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Amazon.SearchTermHistoryDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Amazon.SearchTermHistoryDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Amazon.SearchTermHistoryDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Amazon.SearchTermHistoryDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Amazon.SearchResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SearchResult search_results = 1;
 * @return {!Array<!proto.common.Amazon.SearchResult>}
 */
proto.common.Amazon.SearchTermHistoryDetails.prototype.getSearchResultsList = function() {
  return /** @type{!Array<!proto.common.Amazon.SearchResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Amazon.SearchResult, 1));
};


/**
 * @param {!Array<!proto.common.Amazon.SearchResult>} value
 * @return {!proto.common.Amazon.SearchTermHistoryDetails} returns this
*/
proto.common.Amazon.SearchTermHistoryDetails.prototype.setSearchResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Amazon.SearchResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Amazon.SearchResult}
 */
proto.common.Amazon.SearchTermHistoryDetails.prototype.addSearchResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Amazon.SearchResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Amazon.SearchTermHistoryDetails} returns this
 */
proto.common.Amazon.SearchTermHistoryDetails.prototype.clearSearchResultsList = function() {
  return this.setSearchResultsList([]);
};



/**
 * A tuple of {field number, class constructor} for the extension
 * field named `amazonMarketplaceConfig`.
 * @type {!jspb.ExtensionFieldInfo<!proto.common.Amazon.Marketplace.Config>}
 */
proto.common.amazonMarketplaceConfig = new jspb.ExtensionFieldInfo(
    50002,
    {amazonMarketplaceConfig: 0},
    proto.common.Amazon.Marketplace.Config,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.common.Amazon.Marketplace.Config.toObject),
    0);

google_protobuf_descriptor_pb.EnumValueOptions.extensionsBinary[50002] = new jspb.ExtensionFieldBinaryInfo(
    proto.common.amazonMarketplaceConfig,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.common.Amazon.Marketplace.Config.serializeBinaryToWriter,
    proto.common.Amazon.Marketplace.Config.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumValueOptions.extensions[50002] = proto.common.amazonMarketplaceConfig;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `amazonRegionConfig`.
 * @type {!jspb.ExtensionFieldInfo<!proto.common.Amazon.Region.Config>}
 */
proto.common.amazonRegionConfig = new jspb.ExtensionFieldInfo(
    50003,
    {amazonRegionConfig: 0},
    proto.common.Amazon.Region.Config,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.common.Amazon.Region.Config.toObject),
    0);

google_protobuf_descriptor_pb.EnumValueOptions.extensionsBinary[50003] = new jspb.ExtensionFieldBinaryInfo(
    proto.common.amazonRegionConfig,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.common.Amazon.Region.Config.serializeBinaryToWriter,
    proto.common.Amazon.Region.Config.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumValueOptions.extensions[50003] = proto.common.amazonRegionConfig;

goog.object.extend(exports, proto.common);
