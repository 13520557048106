import { InternalSiteDetails } from "Common/proto/entity/site_pb";
import HubspotMeetings from "Common/components/HubspotMeetings";
import { Site } from "ExtensionV2/queries/useSession";
import React, { useState } from "react";
import { Accordion, Icon, Modal, Segment } from "semantic-ui-react";
import { hasActiveStripePlan } from "Common/utils/ampdPayment";
import { EnterprisePlan } from "./AmpdPlan";
import { NextInvoice } from "./NextInvoice";
import { PlansContainer } from "./PaymentPage";

const AMAZON_FBA_CALENDAR_LINK =
  process.env.REACT_APP_AMAZON_FBA_CALENDAR_URL || "";

const AGENCY_CALENDAR_LINK = process.env.REACT_APP_AGENCY_CALENDAR_URL || "";

export const EnterprisePricing = ({
  currentSite
}: {
  currentSite: Site;
}): JSX.Element => {
  const [nextInvoicePreviewOpen, setNextInvoicePreviewOpen] = useState(false);
  const [demoScreenOpen, setDemoScreenOpen] = useState(false);

  const hasActiveSubscription = hasActiveStripePlan(currentSite);

  const targetCalendarLink =
    currentSite.organizationType ===
    InternalSiteDetails.OrganizationType.Option.AMAZON_FBA
      ? AMAZON_FBA_CALENDAR_LINK
      : AGENCY_CALENDAR_LINK;

  return demoScreenOpen ? (
    <Modal
      closeIcon={<Icon name="close" />}
      style={{ padding: "4em 2em 0 2em" }}
      open={demoScreenOpen}
      onClose={() => setDemoScreenOpen(false)}
    >
      {!!targetCalendarLink && (
        <HubspotMeetings hubspotDataSrc={targetCalendarLink} />
      )}
    </Modal>
  ) : (
    <Segment style={{ height: "100%" }}>
      <PlansContainer>
        <EnterprisePlan onClick={() => setDemoScreenOpen(true)} />
      </PlansContainer>
      <div>
        {hasActiveSubscription && (
          <Accordion
            fluid
            styled
            style={{ width: "50%", margin: "0 auto", minWidth: "30em" }}
          >
            <Accordion.Title
              active={true}
              index={0}
              onClick={() => setNextInvoicePreviewOpen(isOpen => !isOpen)}
            >
              <Icon name="dropdown" />
              View upcoming invoice
            </Accordion.Title>
            <Accordion.Content active={nextInvoicePreviewOpen}>
              <NextInvoice />
            </Accordion.Content>
          </Accordion>
        )}
      </div>
    </Segment>
  );
};
