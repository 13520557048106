import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { notify } from "react-notify-toast";
import { sendGAEvent } from "./GA";
import {
  GOOGLE_ADS_DAYS_PER_MONTH,
  computeBudgetRecommendation,
  getCurrencySymbol
} from "Common/utils/googleAds";
import { formatMetric } from "Common/utils/metrics";

const initialDailyBudgetValues = [10, 15, 20];
const defaultRecommendedDailyBudgetValue = initialDailyBudgetValues[1];

// Displays a dropdown for people to choose a budget for a campaign.
export default function BudgetDropdown(props) {
  const {
    gaCategory,
    siteAlias,
    currency,

    budget,
    recommendedBudget,
    setBudget,
    isWeekly,

    title,
    disabled,
    required
  } = props;

  // Called when a new budget is added to the dropdown. The actual budget value
  // is spliced in below from the budget state.
  const addBudgetValue = (e, { value }) => {
    const newValue = Number(value);
    if (isNaN(newValue) || newValue <= 0) {
      notify.show("Value must be a positive number.", "warning", 2000);
    }
  };

  const handleBudgetChange = (e, { value }) => {
    if (value == null) {
      setBudget(null);
      return;
    }

    const newValue = parseFloat(value);

    if (value >= 0) {
      _.defer(() => {
        sendGAEvent(gaCategory, "Update Budget", siteAlias, newValue);
        setBudget(newValue);
      });
    }
  };

  // Converts a USD budget value into the specified currency.
  const convertBudget = useCallback(
    usdDailyValue => {
      const dailyValueInCurrency =
        computeBudgetRecommendation({ usdValue: usdDailyValue, currency }) ||
        usdDailyValue;

      if (isWeekly) {
        return dailyValueInCurrency * 7;
      }

      return dailyValueInCurrency;
    },
    [currency, isWeekly]
  );

  // Set the default value for the budget to the recommended value, if not
  // otherwise specified.
  const recommendedBudgetValue =
    recommendedBudget || convertBudget(defaultRecommendedDailyBudgetValue);

  useEffect(() => {
    // A value of null means show the placeholder text so the user enters a value,
    // so don't replace it.
    // A zero or negative value is invalid and should be replaced with the
    // recommended value.
    if (_.isNumber(budget) && budget <= 0) {
      setBudget(recommendedBudgetValue);
    }
  }, [budget, recommendedBudgetValue, setBudget]);

  // Compute the budget options from the initial values.
  const budgetOptions = useMemo(() => {
    const budgetOptions = [];

    const optionBudgetValues = initialDailyBudgetValues.map(convertBudget);

    // Add the current budget value to the options list if it's not already there.
    if (budget > 0 && !_.includes(optionBudgetValues, budget)) {
      optionBudgetValues.splice(
        _.sortedIndexBy(optionBudgetValues, budget),
        0,
        budget
      );
    }

    // Add the recommended budget value to the options list if it's not already there.
    if (
      recommendedBudget > 0 &&
      recommendedBudget !== budget &&
      !_.includes(optionBudgetValues, recommendedBudget)
    ) {
      optionBudgetValues.splice(
        _.sortedIndexBy(optionBudgetValues, recommendedBudget),
        0,
        recommendedBudget
      );
    }

    // Create the options for the budget list.
    optionBudgetValues.forEach(budget => {
      const recommended = budget === recommendedBudgetValue;

      budgetOptions.push({
        key: budget,
        value: budget,
        text: budgetValueDescription({
          budget,
          isWeekly,
          currency,
          recommended
        })
      });
    });

    budgetOptions.push(<Dropdown.Divider key="divider-1" />, {
      key: "new-amount-placeholder",
      text: "Enter a different amount...",
      value: null
    });

    return budgetOptions;
  }, [
    budget,
    isWeekly,
    recommendedBudget,
    convertBudget,
    currency,
    recommendedBudgetValue
  ]);

  return (
    <Form>
      <Form.Field required={required}>
        <label style={{ paddingBottom: 10 }}>{title}:</label>
        <Dropdown
          fluid={true}
          placeholder={`Enter a ${isWeekly ? "weekly" : "daily"} budget`}
          value={budget}
          search
          selection
          options={budgetOptions}
          onChange={handleBudgetChange}
          allowAdditions
          additionLabel={`${
            isWeekly ? "Weekly" : "Daily"
          } budget: ${getCurrencySymbol(currency)} `}
          onAddItem={addBudgetValue}
          disabled={disabled}
        />
      </Form.Field>
    </Form>
  );
}

// Creates a description line for a particular budget.
function budgetValueDescription({ budget, isWeekly, currency, recommended }) {
  const currencyMetricDef = {
    isCurrency: true,
    currencyCode: _.toUpper(currency) || "USD",
    formatting: {
      postfix: "",
      sign: false,
      isPercent: false,
      currencyUseMinorUnits: true
    }
  };

  const dailyBudget = isWeekly ? budget / 7 : budget;

  return (
    <>
      {formatMetric(currencyMetricDef, budget)} {isWeekly ? "weekly" : "daily"}{" "}
      average (
      {formatMetric(currencyMetricDef, dailyBudget * GOOGLE_ADS_DAYS_PER_MONTH)}{" "}
      monthly max)
      {recommended && <strong> ← Recommended</strong>}
    </>
  );
}
