import { useCallback, useEffect, useMemo, useState } from "react";

import { SORT_ASCENDING, SORT_DESCENDING, SORT_NONE } from "../MetricColumns";

// Hook that maintains two pieces of state (sortColumn and sortIsAscending) and
// a callback (handleSortColumn).  The handleSortColumn takes a column name and
// makes it the current sort column with its default isAscending value, or if the
// column was already the current sort column, then it toggles the current
// isAscending value.  The returned object is memoized, so it can be passed as
// a unit of state to React components without causing unnecessary re-renders.
export function useColumnSort(
  defaultSortColumn: string,
  defaultSortDirectionByColumn: {
    [col: string]:
      | typeof SORT_ASCENDING
      | typeof SORT_DESCENDING
      | typeof SORT_NONE;
  }
): {
  sortColumn: string;
  sortIsAscending: boolean;
  handleSortColumn: (col: string) => boolean;
} {
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortIsAscending, setSortIsAscending] = useState(false);

  useEffect(() => {
    setSortIsAscending(
      defaultSortDirectionByColumn[defaultSortColumn] === SORT_ASCENDING
    );
  }, [defaultSortColumn, defaultSortDirectionByColumn]);

  const handleSortColumn = useCallback(
    (clickedColumn: string): boolean => {
      // only switch sort direction after the first click
      let newIsAscending = !sortIsAscending;
      if (clickedColumn !== sortColumn) {
        setSortColumn(clickedColumn);
        newIsAscending =
          defaultSortDirectionByColumn[clickedColumn] === SORT_ASCENDING;
      }
      setSortIsAscending(newIsAscending);
      return newIsAscending;
    },
    [sortColumn, sortIsAscending, defaultSortDirectionByColumn]
  );

  return useMemo(
    () => ({
      sortColumn,
      sortIsAscending,
      handleSortColumn
    }),
    [sortColumn, sortIsAscending, handleSortColumn]
  );
}
