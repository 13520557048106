import React, { useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";

import { Site, useSession } from "ExtensionV2/queries/useSession";

const ManageSiteContactEmailForm = ({
  currentSite
}: {
  currentSite: Site;
}): JSX.Element => {
  const { updateSiteContactEmailMutation } = useSession();
  const { siteContactEmail } = currentSite;

  const [email, setEmail] = useState(siteContactEmail);

  const {
    mutate: updateEmail,
    isLoading: updateEmailIsLoading,
    isSuccess: updateEmailIsSuccess,
    error: updateEmailError
  } = updateSiteContactEmailMutation;

  const handleSubmitUpdateEmail = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (email) {
      updateEmail({ email });
    }
  };

  return (
    <Form
      success={updateEmailIsSuccess}
      error={!!updateEmailError}
      onSubmit={handleSubmitUpdateEmail}
    >
      <Form.Field>
        <p>
          Occasionally Ampd may need to send you important information related
          to your account. There can only be one contact email address listed
          per Ampd account.
        </p>
        <input
          placeholder="email"
          value={email}
          onChange={ev => setEmail(ev.target.value)}
        />
        <Message
          success
          header="Update Successful"
          content={`Contact email has been set to ${siteContactEmail}`}
        />
        <Message
          error
          header="Problem updating email"
          content={<p>{updateEmailError?.message}</p>}
        />
      </Form.Field>
      <Button primary loading={updateEmailIsLoading} type="submit">
        Save Email
      </Button>
    </Form>
  );
};

export default ManageSiteContactEmailForm;
