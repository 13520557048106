import React, { useState } from "react";
import { Button, Checkbox, List, Message, Modal } from "semantic-ui-react";

import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { pluralize } from "Common/utils/strings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  LinkBillingAccountsReply,
  LinkBillingAccountsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  linkBillingExplanationMessage,
  maxClientBillingAccountMessage
} from "./CreateNewAccountButton";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";

const useLinkBillingAccountsMutation = (
  clientSiteAliases: Array<string>,
  targetSiteAlias: string,
  onSuccess: (resp: LinkBillingAccountsReply) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: (resp: LinkBillingAccountsReply) => {
      queryClient.invalidateQueries({
        queryKey: ["session", targetSiteAlias]
      });
      onSuccess(resp);
    },
    mutationFn: async (): Promise<LinkBillingAccountsReply> => {
      const req = new LinkBillingAccountsRequest();
      req.setClientSiteAliasesList(clientSiteAliases);
      req.setTargetSiteAlias(targetSiteAlias);
      const reply = await GRPCWebClient.linkBillingAccounts(req, {});
      return reply;
    }
  });
};

export const LinkBillingAccountConfirmationModalLauncher = ({
  linkableClientSites
}: {
  linkableClientSites: Array<SiteManagerLinkClient.AsObject>;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button primary onClick={() => setIsOpen(true)}>
        Link Billing
      </Button>
      {isOpen && (
        <LinkBillingAccountConfirmationModal
          linkableClientSites={linkableClientSites}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

const LinkBillingAccountConfirmationModal = ({
  linkableClientSites,
  onClose
}: {
  linkableClientSites: Array<SiteManagerLinkClient.AsObject>;
  onClose: () => void;
}) => {
  const {
    siteAlias: managerSiteAlias,
    clientBillingAccountMax,
    clientBillingAccountCount
  } = useSessionSite();

  const [additionalErrors, setAdditionalErrors] = useState<string[]>([]);

  const initiallySelectedAliases = linkableClientSites.reduce<
    Record<string, boolean>
  >((obj, clientSite) => {
    obj[clientSite.clientSiteAlias] = true;
    return obj;
  }, {});

  const [siteAliasSelections, setSiteAliasSelections] = useState<{
    [k: string]: boolean;
  }>(initiallySelectedAliases);

  const selectedAliases = Object.keys(siteAliasSelections).filter(
    clientSiteAlias => siteAliasSelections[clientSiteAlias]
  );
  const {
    mutate: linkBillingAccounts,
    isLoading: isLinkingBillingAccounts,
    error: linkingBillingAccountsError,
    reset: resetLinkBillingAccounts
  } = useLinkBillingAccountsMutation(
    selectedAliases,
    managerSiteAlias,
    reply => {
      // If one of the links fails the GRPC call will succeed but the reply will
      // contain a list of errors.
      const errors = [];
      for (const linkingError of reply.getLinkingErrorsList()) {
        const message = linkingError.getErrorMessage();
        const clientSiteAlias = linkingError.getClientSiteAlias();
        const clientSiteName = linkableClientSites.find(clientSite => {
          return clientSite.clientSiteAlias === clientSiteAlias;
        })?.clientSiteName;

        errors.push(`${clientSiteName || clientSiteAlias}: ${message}`);
      }
      setAdditionalErrors(errors);

      if (errors.length === 0) {
        onClose();
      }
    }
  );

  const handleLinkBillingAccounts = () => {
    if (linkableClientSites.length === 0) {
      return;
    }

    linkBillingAccounts();
  };

  const handleClose = () => {
    onClose();
    resetLinkBillingAccounts();
  };

  let tooManySelected = false;
  const countSelected = selectedAliases.length;
  const remainingSlots = clientBillingAccountMax - clientBillingAccountCount;
  if (countSelected > remainingSlots) {
    tooManySelected = true;
  }

  return (
    <Modal open={true} onClose={handleClose}>
      <Modal.Header>Link Payment Plans</Modal.Header>
      {/* 
         Maximum linked accounts reached error.
      */}
      {clientBillingAccountCount >= clientBillingAccountMax ? (
        <>
          <Modal.Content>{maxClientBillingAccountMessage}</Modal.Content>
        </>
      ) : (
        <>
          <Modal.Content>
            <List>
              <p>
                {linkBillingExplanationMessage(
                  clientBillingAccountCount,
                  clientBillingAccountMax
                )}
              </p>
              <h4>These clients will be linked to your payment plan:</h4>
              {linkableClientSites.map(clientSite => {
                return (
                  <List.Item key={clientSite.clientSiteAlias}>
                    <Checkbox
                      onChange={() => {
                        setSiteAliasSelections({
                          ...siteAliasSelections,
                          [clientSite.clientSiteAlias]: !siteAliasSelections[
                            clientSite.clientSiteAlias
                          ]
                        });
                      }}
                      checked={siteAliasSelections[clientSite.clientSiteAlias]}
                      label={<label>{clientSite.clientSiteName}</label>}
                    />
                  </List.Item>
                );
              })}
            </List>
            <Message hidden={!tooManySelected} error>
              You have selected too many sites. Please select fewer sites or
              reach out about increasing your limit.
            </Message>
            <Message hidden={!linkingBillingAccountsError} error>
              {String(linkingBillingAccountsError)}
            </Message>
            <Message hidden={additionalErrors.length === 0} error>
              {additionalErrors.map(error => {
                return <p key={error}>{error}</p>;
              })}
            </Message>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={handleClose} onClose={handleClose}>
              Close
            </Button>
            <Button
              loading={isLinkingBillingAccounts}
              disabled={isLinkingBillingAccounts || tooManySelected}
              positive
              onClick={handleLinkBillingAccounts}
            >
              Link {pluralize(linkableClientSites.length, "Site", "Sites")}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};
