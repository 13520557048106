// source: google/ads/googleads/v16/resources/feed_item.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_common_custom_parameter_pb = require('../../../../../google/ads/googleads/v16/common/custom_parameter_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_custom_parameter_pb);
var google_ads_googleads_v16_common_feed_common_pb = require('../../../../../google/ads/googleads/v16/common/feed_common_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_feed_common_pb);
var google_ads_googleads_v16_common_policy_pb = require('../../../../../google/ads/googleads/v16/common/policy_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_common_policy_pb);
var google_ads_googleads_v16_enums_feed_item_quality_approval_status_pb = require('../../../../../google/ads/googleads/v16/enums/feed_item_quality_approval_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_feed_item_quality_approval_status_pb);
var google_ads_googleads_v16_enums_feed_item_quality_disapproval_reason_pb = require('../../../../../google/ads/googleads/v16/enums/feed_item_quality_disapproval_reason_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_feed_item_quality_disapproval_reason_pb);
var google_ads_googleads_v16_enums_feed_item_status_pb = require('../../../../../google/ads/googleads/v16/enums/feed_item_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_feed_item_status_pb);
var google_ads_googleads_v16_enums_feed_item_validation_status_pb = require('../../../../../google/ads/googleads/v16/enums/feed_item_validation_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_feed_item_validation_status_pb);
var google_ads_googleads_v16_enums_geo_targeting_restriction_pb = require('../../../../../google/ads/googleads/v16/enums/geo_targeting_restriction_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_geo_targeting_restriction_pb);
var google_ads_googleads_v16_enums_placeholder_type_pb = require('../../../../../google/ads/googleads/v16/enums/placeholder_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_placeholder_type_pb);
var google_ads_googleads_v16_enums_policy_approval_status_pb = require('../../../../../google/ads/googleads/v16/enums/policy_approval_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_policy_approval_status_pb);
var google_ads_googleads_v16_enums_policy_review_status_pb = require('../../../../../google/ads/googleads/v16/enums/policy_review_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_policy_review_status_pb);
var google_ads_googleads_v16_errors_feed_item_validation_error_pb = require('../../../../../google/ads/googleads/v16/errors/feed_item_validation_error_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_errors_feed_item_validation_error_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.FeedItem', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.FeedItemAttributeValue', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.FeedItemValidationError', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.FeedItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.FeedItem.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.FeedItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.FeedItem.displayName = 'proto.google.ads.googleads.v16.resources.FeedItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.FeedItemAttributeValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.displayName = 'proto.google.ads.googleads.v16.resources.FeedItemAttributeValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.displayName = 'proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.ads.googleads.v16.resources.FeedItemValidationError.repeatedFields_, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.FeedItemValidationError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.FeedItemValidationError.displayName = 'proto.google.ads.googleads.v16.resources.FeedItemValidationError';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.FeedItem.repeatedFields_ = [6,8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.FeedItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.FeedItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feed: jspb.Message.getFieldWithDefault(msg, 11, ""),
    id: jspb.Message.getFieldWithDefault(msg, 12, 0),
    startDateTime: jspb.Message.getFieldWithDefault(msg, 13, ""),
    endDateTime: jspb.Message.getFieldWithDefault(msg, 14, ""),
    attributeValuesList: jspb.Message.toObjectList(msg.getAttributeValuesList(),
    proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.toObject, includeInstance),
    geoTargetingRestriction: jspb.Message.getFieldWithDefault(msg, 7, 0),
    urlCustomParametersList: jspb.Message.toObjectList(msg.getUrlCustomParametersList(),
    google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    policyInfosList: jspb.Message.toObjectList(msg.getPolicyInfosList(),
    proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem}
 */
proto.google.ads.googleads.v16.resources.FeedItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.FeedItem;
  return proto.google.ads.googleads.v16.resources.FeedItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem}
 */
proto.google.ads.googleads.v16.resources.FeedItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeed(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDateTime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDateTime(value);
      break;
    case 6:
      var value = new proto.google.ads.googleads.v16.resources.FeedItemAttributeValue;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.deserializeBinaryFromReader);
      msg.addAttributeValues(value);
      break;
    case 7:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.GeoTargetingRestrictionEnum.GeoTargetingRestriction} */ (reader.readEnum());
      msg.setGeoTargetingRestriction(value);
      break;
    case 8:
      var value = new google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter;
      reader.readMessage(value,google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter.deserializeBinaryFromReader);
      msg.addUrlCustomParameters(value);
      break;
    case 9:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.FeedItemStatusEnum.FeedItemStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = new proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.deserializeBinaryFromReader);
      msg.addPolicyInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.FeedItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAttributeValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.serializeBinaryToWriter
    );
  }
  f = message.getGeoTargetingRestriction();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getUrlCustomParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPolicyInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string feed = 11;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getFeed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setFeed = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearFeed = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.hasFeed = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 id = 12;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.hasId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string start_date_time = 13;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getStartDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setStartDateTime = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearStartDateTime = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.hasStartDateTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string end_date_time = 14;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getEndDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setEndDateTime = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearEndDateTime = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.hasEndDateTime = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated FeedItemAttributeValue attribute_values = 6;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue>}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getAttributeValuesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.resources.FeedItemAttributeValue, 6));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
*/
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setAttributeValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.addAttributeValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.ads.googleads.v16.resources.FeedItemAttributeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearAttributeValuesList = function() {
  return this.setAttributeValuesList([]);
};


/**
 * optional google.ads.googleads.v16.enums.GeoTargetingRestrictionEnum.GeoTargetingRestriction geo_targeting_restriction = 7;
 * @return {!proto.google.ads.googleads.v16.enums.GeoTargetingRestrictionEnum.GeoTargetingRestriction}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getGeoTargetingRestriction = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.GeoTargetingRestrictionEnum.GeoTargetingRestriction} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.GeoTargetingRestrictionEnum.GeoTargetingRestriction} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setGeoTargetingRestriction = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated google.ads.googleads.v16.common.CustomParameter url_custom_parameters = 8;
 * @return {!Array<!proto.google.ads.googleads.v16.common.CustomParameter>}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getUrlCustomParametersList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.CustomParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_common_custom_parameter_pb.CustomParameter, 8));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.CustomParameter>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
*/
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setUrlCustomParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.CustomParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.CustomParameter}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.addUrlCustomParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.ads.googleads.v16.common.CustomParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearUrlCustomParametersList = function() {
  return this.setUrlCustomParametersList([]);
};


/**
 * optional google.ads.googleads.v16.enums.FeedItemStatusEnum.FeedItemStatus status = 9;
 * @return {!proto.google.ads.googleads.v16.enums.FeedItemStatusEnum.FeedItemStatus}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.FeedItemStatusEnum.FeedItemStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FeedItemStatusEnum.FeedItemStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated FeedItemPlaceholderPolicyInfo policy_infos = 10;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo>}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.getPolicyInfosList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo, 10));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
*/
proto.google.ads.googleads.v16.resources.FeedItem.prototype.setPolicyInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo}
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.addPolicyInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItem} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItem.prototype.clearPolicyInfosList = function() {
  return this.setPolicyInfosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.repeatedFields_ = [16,17,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedAttributeId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    integerValue: jspb.Message.getFieldWithDefault(msg, 12, 0),
    booleanValue: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    stringValue: jspb.Message.getFieldWithDefault(msg, 14, ""),
    doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    priceValue: (f = msg.getPriceValue()) && google_ads_googleads_v16_common_feed_common_pb.Money.toObject(includeInstance, f),
    integerValuesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    booleanValuesList: (f = jspb.Message.getRepeatedBooleanField(msg, 17)) == null ? undefined : f,
    stringValuesList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    doubleValuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 19)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.FeedItemAttributeValue;
  return proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeedAttributeId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntegerValue(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBooleanValue(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleValue(value);
      break;
    case 6:
      var value = new google_ads_googleads_v16_common_feed_common_pb.Money;
      reader.readMessage(value,google_ads_googleads_v16_common_feed_common_pb.Money.deserializeBinaryFromReader);
      msg.setPriceValue(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIntegerValues(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBooleanValues(values[i]);
      }
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addStringValues(value);
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDoubleValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getPriceValue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_ads_googleads_v16_common_feed_common_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getIntegerValuesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      16,
      f
    );
  }
  f = message.getBooleanValuesList();
  if (f.length > 0) {
    writer.writePackedBool(
      17,
      f
    );
  }
  f = message.getStringValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getDoubleValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      19,
      f
    );
  }
};


/**
 * optional int64 feed_attribute_id = 11;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getFeedAttributeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setFeedAttributeId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearFeedAttributeId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.hasFeedAttributeId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 integer_value = 12;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getIntegerValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setIntegerValue = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearIntegerValue = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.hasIntegerValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool boolean_value = 13;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getBooleanValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setBooleanValue = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearBooleanValue = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.hasBooleanValue = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string string_value = 14;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setStringValue = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearStringValue = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional double double_value = 15;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getDoubleValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setDoubleValue = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearDoubleValue = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.ads.googleads.v16.common.Money price_value = 6;
 * @return {?proto.google.ads.googleads.v16.common.Money}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getPriceValue = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.Money} */ (
    jspb.Message.getWrapperField(this, google_ads_googleads_v16_common_feed_common_pb.Money, 6));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.Money|undefined} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
*/
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setPriceValue = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearPriceValue = function() {
  return this.setPriceValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.hasPriceValue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated int64 integer_values = 16;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getIntegerValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setIntegerValuesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.addIntegerValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearIntegerValuesList = function() {
  return this.setIntegerValuesList([]);
};


/**
 * repeated bool boolean_values = 17;
 * @return {!Array<boolean>}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getBooleanValuesList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 17));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setBooleanValuesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.addBooleanValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearBooleanValuesList = function() {
  return this.setBooleanValuesList([]);
};


/**
 * repeated string string_values = 18;
 * @return {!Array<string>}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getStringValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setStringValuesList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.addStringValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearStringValuesList = function() {
  return this.setStringValuesList([]);
};


/**
 * repeated double double_values = 19;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.getDoubleValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.setDoubleValuesList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.addDoubleValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemAttributeValue} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemAttributeValue.prototype.clearDoubleValuesList = function() {
  return this.setDoubleValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.repeatedFields_ = [5,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeholderTypeEnum: jspb.Message.getFieldWithDefault(msg, 10, 0),
    feedMappingResourceName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    reviewStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    approvalStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    policyTopicEntriesList: jspb.Message.toObjectList(msg.getPolicyTopicEntriesList(),
    google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry.toObject, includeInstance),
    validationStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    validationErrorsList: jspb.Message.toObjectList(msg.getValidationErrorsList(),
    proto.google.ads.googleads.v16.resources.FeedItemValidationError.toObject, includeInstance),
    qualityApprovalStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    qualityDisapprovalReasonsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo;
  return proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PlaceholderTypeEnum.PlaceholderType} */ (reader.readEnum());
      msg.setPlaceholderTypeEnum(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedMappingResourceName(value);
      break;
    case 3:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (reader.readEnum());
      msg.setReviewStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (reader.readEnum());
      msg.setApprovalStatus(value);
      break;
    case 5:
      var value = new google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry;
      reader.readMessage(value,google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry.deserializeBinaryFromReader);
      msg.addPolicyTopicEntries(value);
      break;
    case 6:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.FeedItemValidationStatusEnum.FeedItemValidationStatus} */ (reader.readEnum());
      msg.setValidationStatus(value);
      break;
    case 7:
      var value = new proto.google.ads.googleads.v16.resources.FeedItemValidationError;
      reader.readMessage(value,proto.google.ads.googleads.v16.resources.FeedItemValidationError.deserializeBinaryFromReader);
      msg.addValidationErrors(value);
      break;
    case 8:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.FeedItemQualityApprovalStatusEnum.FeedItemQualityApprovalStatus} */ (reader.readEnum());
      msg.setQualityApprovalStatus(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.google.ads.googleads.v16.enums.FeedItemQualityDisapprovalReasonEnum.FeedItemQualityDisapprovalReason>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addQualityDisapprovalReasons(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceholderTypeEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReviewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPolicyTopicEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry.serializeBinaryToWriter
    );
  }
  f = message.getValidationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getValidationErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.google.ads.googleads.v16.resources.FeedItemValidationError.serializeBinaryToWriter
    );
  }
  f = message.getQualityApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQualityDisapprovalReasonsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.PlaceholderTypeEnum.PlaceholderType placeholder_type_enum = 10;
 * @return {!proto.google.ads.googleads.v16.enums.PlaceholderTypeEnum.PlaceholderType}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getPlaceholderTypeEnum = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PlaceholderTypeEnum.PlaceholderType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PlaceholderTypeEnum.PlaceholderType} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setPlaceholderTypeEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string feed_mapping_resource_name = 11;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getFeedMappingResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setFeedMappingResourceName = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.clearFeedMappingResourceName = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.hasFeedMappingResourceName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus review_status = 3;
 * @return {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getReviewStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PolicyReviewStatusEnum.PolicyReviewStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setReviewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus approval_status = 4;
 * @return {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getApprovalStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.PolicyApprovalStatusEnum.PolicyApprovalStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated google.ads.googleads.v16.common.PolicyTopicEntry policy_topic_entries = 5;
 * @return {!Array<!proto.google.ads.googleads.v16.common.PolicyTopicEntry>}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getPolicyTopicEntriesList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.common.PolicyTopicEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_ads_googleads_v16_common_policy_pb.PolicyTopicEntry, 5));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.common.PolicyTopicEntry>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
*/
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setPolicyTopicEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.common.PolicyTopicEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.common.PolicyTopicEntry}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.addPolicyTopicEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.ads.googleads.v16.common.PolicyTopicEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.clearPolicyTopicEntriesList = function() {
  return this.setPolicyTopicEntriesList([]);
};


/**
 * optional google.ads.googleads.v16.enums.FeedItemValidationStatusEnum.FeedItemValidationStatus validation_status = 6;
 * @return {!proto.google.ads.googleads.v16.enums.FeedItemValidationStatusEnum.FeedItemValidationStatus}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getValidationStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.FeedItemValidationStatusEnum.FeedItemValidationStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FeedItemValidationStatusEnum.FeedItemValidationStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setValidationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated FeedItemValidationError validation_errors = 7;
 * @return {!Array<!proto.google.ads.googleads.v16.resources.FeedItemValidationError>}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getValidationErrorsList = function() {
  return /** @type{!Array<!proto.google.ads.googleads.v16.resources.FeedItemValidationError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.google.ads.googleads.v16.resources.FeedItemValidationError, 7));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.resources.FeedItemValidationError>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
*/
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setValidationErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemValidationError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.addValidationErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.ads.googleads.v16.resources.FeedItemValidationError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.clearValidationErrorsList = function() {
  return this.setValidationErrorsList([]);
};


/**
 * optional google.ads.googleads.v16.enums.FeedItemQualityApprovalStatusEnum.FeedItemQualityApprovalStatus quality_approval_status = 8;
 * @return {!proto.google.ads.googleads.v16.enums.FeedItemQualityApprovalStatusEnum.FeedItemQualityApprovalStatus}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getQualityApprovalStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.FeedItemQualityApprovalStatusEnum.FeedItemQualityApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FeedItemQualityApprovalStatusEnum.FeedItemQualityApprovalStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setQualityApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated google.ads.googleads.v16.enums.FeedItemQualityDisapprovalReasonEnum.FeedItemQualityDisapprovalReason quality_disapproval_reasons = 9;
 * @return {!Array<!proto.google.ads.googleads.v16.enums.FeedItemQualityDisapprovalReasonEnum.FeedItemQualityDisapprovalReason>}
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.getQualityDisapprovalReasonsList = function() {
  return /** @type {!Array<!proto.google.ads.googleads.v16.enums.FeedItemQualityDisapprovalReasonEnum.FeedItemQualityDisapprovalReason>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.google.ads.googleads.v16.enums.FeedItemQualityDisapprovalReasonEnum.FeedItemQualityDisapprovalReason>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.setQualityDisapprovalReasonsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.FeedItemQualityDisapprovalReasonEnum.FeedItemQualityDisapprovalReason} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.addQualityDisapprovalReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemPlaceholderPolicyInfo.prototype.clearQualityDisapprovalReasonsList = function() {
  return this.setQualityDisapprovalReasonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.FeedItemValidationError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.toObject = function(includeInstance, msg) {
  var f, obj = {
    validationError: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    feedAttributeIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    extraInfo: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.FeedItemValidationError;
  return proto.google.ads.googleads.v16.resources.FeedItemValidationError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.errors.FeedItemValidationErrorEnum.FeedItemValidationError} */ (reader.readEnum());
      msg.setValidationError(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFeedAttributeIds(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.FeedItemValidationError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidationError();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFeedAttributeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.errors.FeedItemValidationErrorEnum.FeedItemValidationError validation_error = 1;
 * @return {!proto.google.ads.googleads.v16.errors.FeedItemValidationErrorEnum.FeedItemValidationError}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.getValidationError = function() {
  return /** @type {!proto.google.ads.googleads.v16.errors.FeedItemValidationErrorEnum.FeedItemValidationError} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.errors.FeedItemValidationErrorEnum.FeedItemValidationError} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.setValidationError = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated int64 feed_attribute_ids = 7;
 * @return {!Array<number>}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.getFeedAttributeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.setFeedAttributeIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.addFeedAttributeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.clearFeedAttributeIdsList = function() {
  return this.setFeedAttributeIdsList([]);
};


/**
 * optional string extra_info = 8;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.getExtraInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.setExtraInfo = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.FeedItemValidationError} returns this
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.clearExtraInfo = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.FeedItemValidationError.prototype.hasExtraInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v16.resources);
