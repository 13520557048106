import moment from "moment";
import React from "react";
import { Message } from "semantic-ui-react";

import { StripeInvoiceView } from "./StripeInvoiceView";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { useNextInvoice } from "ExtensionV2/queries/useNextInvoice";

export const NextInvoice = ({
  nextPriceId,
  prorationDate
}: {
  nextPriceId?: string;
  prorationDate?: number;
}): JSX.Element => {
  const {
    data: nextInvoice,
    isLoading: nextInvoiceLoading,
    error: nextInvoiceError
  } = useNextInvoice({ priceId: nextPriceId, prorationDate });

  if (nextInvoiceLoading) {
    return <LoadingSpinner />;
  }

  if (nextInvoiceError) {
    console.error(nextInvoiceError);
    return (
      <Message error>There was a problem fetching your next invoice.</Message>
    );
  }

  if (!nextInvoice?.invoice) {
    return <></>;
  }

  const nextInvoiceSeconds =
    nextInvoice.invoice.nextPaymentAttempt?.seconds || 0;
  const nextBillingDate = new Date(nextInvoiceSeconds * 1000);

  return (
    <>
      {nextInvoiceSeconds > 0 ? (
        <p>
          Your next billing date is{" "}
          {moment(nextBillingDate).format("dddd, MMMM Do YYYY")}
        </p>
      ) : (
        <p style={{ fontWeight: "bold" }}>Due now</p>
      )}

      <StripeInvoiceView invoice={nextInvoice.invoice} />

      {nextInvoice.invoice.amountDue === 0 && (
        <i>
          Any account balance will be credited towards this and future invoices.
        </i>
      )}
    </>
  );
};
