// source: google/ads/googleads/v16/common/bidding.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_enums_target_frequency_time_unit_pb = require('../../../../../google/ads/googleads/v16/enums/target_frequency_time_unit_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_target_frequency_time_unit_pb);
var google_ads_googleads_v16_enums_target_impression_share_location_pb = require('../../../../../google/ads/googleads/v16/enums/target_impression_share_location_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_target_impression_share_location_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.common.Commission', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.EnhancedCpc', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ManualCpa', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ManualCpc', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ManualCpm', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.ManualCpv', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.MaximizeConversionValue', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.MaximizeConversions', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.PercentCpc', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetCpa', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetCpm', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetCpm.GoalCase', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetImpressionShare', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetRoas', null, global);
goog.exportSymbol('proto.google.ads.googleads.v16.common.TargetSpend', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.Commission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.Commission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.Commission.displayName = 'proto.google.ads.googleads.v16.common.Commission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.EnhancedCpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.EnhancedCpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.EnhancedCpc.displayName = 'proto.google.ads.googleads.v16.common.EnhancedCpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ManualCpa = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ManualCpa, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ManualCpa.displayName = 'proto.google.ads.googleads.v16.common.ManualCpa';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ManualCpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ManualCpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ManualCpc.displayName = 'proto.google.ads.googleads.v16.common.ManualCpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ManualCpm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ManualCpm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ManualCpm.displayName = 'proto.google.ads.googleads.v16.common.ManualCpm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.ManualCpv = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.ManualCpv, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.ManualCpv.displayName = 'proto.google.ads.googleads.v16.common.ManualCpv';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.MaximizeConversions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.MaximizeConversions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.MaximizeConversions.displayName = 'proto.google.ads.googleads.v16.common.MaximizeConversions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.MaximizeConversionValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.MaximizeConversionValue.displayName = 'proto.google.ads.googleads.v16.common.MaximizeConversionValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.TargetCpa = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.TargetCpa, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.TargetCpa.displayName = 'proto.google.ads.googleads.v16.common.TargetCpa';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.TargetCpm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.google.ads.googleads.v16.common.TargetCpm.oneofGroups_);
};
goog.inherits(proto.google.ads.googleads.v16.common.TargetCpm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.TargetCpm.displayName = 'proto.google.ads.googleads.v16.common.TargetCpm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.displayName = 'proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.TargetImpressionShare, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.TargetImpressionShare.displayName = 'proto.google.ads.googleads.v16.common.TargetImpressionShare';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.TargetRoas = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.TargetRoas, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.TargetRoas.displayName = 'proto.google.ads.googleads.v16.common.TargetRoas';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.TargetSpend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.TargetSpend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.TargetSpend.displayName = 'proto.google.ads.googleads.v16.common.TargetSpend';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.common.PercentCpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.common.PercentCpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.common.PercentCpc.displayName = 'proto.google.ads.googleads.v16.common.PercentCpc';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.Commission.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.Commission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.Commission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.Commission.toObject = function(includeInstance, msg) {
  var f, obj = {
    commissionRateMicros: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.Commission}
 */
proto.google.ads.googleads.v16.common.Commission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.Commission;
  return proto.google.ads.googleads.v16.common.Commission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.Commission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.Commission}
 */
proto.google.ads.googleads.v16.common.Commission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCommissionRateMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.Commission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.Commission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.Commission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.Commission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 commission_rate_micros = 2;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.Commission.prototype.getCommissionRateMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.Commission} returns this
 */
proto.google.ads.googleads.v16.common.Commission.prototype.setCommissionRateMicros = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.Commission} returns this
 */
proto.google.ads.googleads.v16.common.Commission.prototype.clearCommissionRateMicros = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.Commission.prototype.hasCommissionRateMicros = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.EnhancedCpc.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.EnhancedCpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.EnhancedCpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.EnhancedCpc.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.EnhancedCpc}
 */
proto.google.ads.googleads.v16.common.EnhancedCpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.EnhancedCpc;
  return proto.google.ads.googleads.v16.common.EnhancedCpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.EnhancedCpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.EnhancedCpc}
 */
proto.google.ads.googleads.v16.common.EnhancedCpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.EnhancedCpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.EnhancedCpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.EnhancedCpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.EnhancedCpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ManualCpa.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ManualCpa.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ManualCpa} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpa.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpa}
 */
proto.google.ads.googleads.v16.common.ManualCpa.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ManualCpa;
  return proto.google.ads.googleads.v16.common.ManualCpa.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpa} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpa}
 */
proto.google.ads.googleads.v16.common.ManualCpa.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ManualCpa.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ManualCpa.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpa} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpa.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ManualCpc.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ManualCpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ManualCpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    enhancedCpcEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpc}
 */
proto.google.ads.googleads.v16.common.ManualCpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ManualCpc;
  return proto.google.ads.googleads.v16.common.ManualCpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpc}
 */
proto.google.ads.googleads.v16.common.ManualCpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnhancedCpcEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ManualCpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ManualCpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool enhanced_cpc_enabled = 2;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.ManualCpc.prototype.getEnhancedCpcEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.common.ManualCpc} returns this
 */
proto.google.ads.googleads.v16.common.ManualCpc.prototype.setEnhancedCpcEnabled = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpc} returns this
 */
proto.google.ads.googleads.v16.common.ManualCpc.prototype.clearEnhancedCpcEnabled = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.ManualCpc.prototype.hasEnhancedCpcEnabled = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ManualCpm.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ManualCpm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ManualCpm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpm.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpm}
 */
proto.google.ads.googleads.v16.common.ManualCpm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ManualCpm;
  return proto.google.ads.googleads.v16.common.ManualCpm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpm}
 */
proto.google.ads.googleads.v16.common.ManualCpm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ManualCpm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ManualCpm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.ManualCpv.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.ManualCpv.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.ManualCpv} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpv.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpv}
 */
proto.google.ads.googleads.v16.common.ManualCpv.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.ManualCpv;
  return proto.google.ads.googleads.v16.common.ManualCpv.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpv} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.ManualCpv}
 */
proto.google.ads.googleads.v16.common.ManualCpv.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.ManualCpv.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.ManualCpv.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.ManualCpv} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.ManualCpv.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.MaximizeConversions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.MaximizeConversions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cpcBidFloorMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetCpaMicros: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversions}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.MaximizeConversions;
  return proto.google.ads.googleads.v16.common.MaximizeConversions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.MaximizeConversions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversions}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidFloorMicros(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetCpaMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.MaximizeConversions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.MaximizeConversions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpcBidCeilingMicros();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCpcBidFloorMicros();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTargetCpaMicros();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 cpc_bid_ceiling_micros = 2;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversions} returns this
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cpc_bid_floor_micros = 3;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.getCpcBidFloorMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversions} returns this
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.setCpcBidFloorMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 target_cpa_micros = 4;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.getTargetCpaMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversions} returns this
 */
proto.google.ads.googleads.v16.common.MaximizeConversions.prototype.setTargetCpaMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.MaximizeConversionValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.MaximizeConversionValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetRoas: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cpcBidFloorMicros: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversionValue}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.MaximizeConversionValue;
  return proto.google.ads.googleads.v16.common.MaximizeConversionValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.MaximizeConversionValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversionValue}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetRoas(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidFloorMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.MaximizeConversionValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.MaximizeConversionValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetRoas();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCpcBidCeilingMicros();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCpcBidFloorMicros();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional double target_roas = 2;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.getTargetRoas = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversionValue} returns this
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.setTargetRoas = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 cpc_bid_ceiling_micros = 3;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversionValue} returns this
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cpc_bid_floor_micros = 4;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.getCpcBidFloorMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.MaximizeConversionValue} returns this
 */
proto.google.ads.googleads.v16.common.MaximizeConversionValue.prototype.setCpcBidFloorMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.TargetCpa.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.TargetCpa} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetCpa.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetCpaMicros: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cpcBidFloorMicros: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa}
 */
proto.google.ads.googleads.v16.common.TargetCpa.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.TargetCpa;
  return proto.google.ads.googleads.v16.common.TargetCpa.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.TargetCpa} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa}
 */
proto.google.ads.googleads.v16.common.TargetCpa.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetCpaMicros(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidFloorMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.TargetCpa.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.TargetCpa} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetCpa.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 target_cpa_micros = 4;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.getTargetCpaMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.setTargetCpaMicros = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.clearTargetCpaMicros = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.hasTargetCpaMicros = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 cpc_bid_ceiling_micros = 5;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.clearCpcBidCeilingMicros = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.hasCpcBidCeilingMicros = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 cpc_bid_floor_micros = 6;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.getCpcBidFloorMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.setCpcBidFloorMicros = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpa} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.clearCpcBidFloorMicros = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetCpa.prototype.hasCpcBidFloorMicros = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.google.ads.googleads.v16.common.TargetCpm.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.google.ads.googleads.v16.common.TargetCpm.GoalCase = {
  GOAL_NOT_SET: 0,
  TARGET_FREQUENCY_GOAL: 1
};

/**
 * @return {proto.google.ads.googleads.v16.common.TargetCpm.GoalCase}
 */
proto.google.ads.googleads.v16.common.TargetCpm.prototype.getGoalCase = function() {
  return /** @type {proto.google.ads.googleads.v16.common.TargetCpm.GoalCase} */(jspb.Message.computeOneofCase(this, proto.google.ads.googleads.v16.common.TargetCpm.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.TargetCpm.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.TargetCpm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.TargetCpm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetCpm.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetFrequencyGoal: (f = msg.getTargetFrequencyGoal()) && proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpm}
 */
proto.google.ads.googleads.v16.common.TargetCpm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.TargetCpm;
  return proto.google.ads.googleads.v16.common.TargetCpm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.TargetCpm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpm}
 */
proto.google.ads.googleads.v16.common.TargetCpm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal;
      reader.readMessage(value,proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.deserializeBinaryFromReader);
      msg.setTargetFrequencyGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.TargetCpm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.TargetCpm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.TargetCpm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetCpm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetFrequencyGoal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.serializeBinaryToWriter
    );
  }
};


/**
 * optional TargetCpmTargetFrequencyGoal target_frequency_goal = 1;
 * @return {?proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal}
 */
proto.google.ads.googleads.v16.common.TargetCpm.prototype.getTargetFrequencyGoal = function() {
  return /** @type{?proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal} */ (
    jspb.Message.getWrapperField(this, proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal, 1));
};


/**
 * @param {?proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal|undefined} value
 * @return {!proto.google.ads.googleads.v16.common.TargetCpm} returns this
*/
proto.google.ads.googleads.v16.common.TargetCpm.prototype.setTargetFrequencyGoal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.google.ads.googleads.v16.common.TargetCpm.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpm} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpm.prototype.clearTargetFrequencyGoal = function() {
  return this.setTargetFrequencyGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetCpm.prototype.hasTargetFrequencyGoal = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeUnit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal}
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal;
  return proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal}
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetCount(value);
      break;
    case 2:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.TargetFrequencyTimeUnitEnum.TargetFrequencyTimeUnit} */ (reader.readEnum());
      msg.setTimeUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimeUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 target_count = 1;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.prototype.getTargetCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.prototype.setTargetCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.ads.googleads.v16.enums.TargetFrequencyTimeUnitEnum.TargetFrequencyTimeUnit time_unit = 2;
 * @return {!proto.google.ads.googleads.v16.enums.TargetFrequencyTimeUnitEnum.TargetFrequencyTimeUnit}
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.prototype.getTimeUnit = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.TargetFrequencyTimeUnitEnum.TargetFrequencyTimeUnit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.TargetFrequencyTimeUnitEnum.TargetFrequencyTimeUnit} value
 * @return {!proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal} returns this
 */
proto.google.ads.googleads.v16.common.TargetCpmTargetFrequencyGoal.prototype.setTimeUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.TargetImpressionShare.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.TargetImpressionShare} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locationFractionMicros: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.TargetImpressionShare;
  return proto.google.ads.googleads.v16.common.TargetImpressionShare.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.TargetImpressionShare} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.TargetImpressionShareLocationEnum.TargetImpressionShareLocation} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLocationFractionMicros(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.TargetImpressionShare.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.TargetImpressionShare} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional google.ads.googleads.v16.enums.TargetImpressionShareLocationEnum.TargetImpressionShareLocation location = 1;
 * @return {!proto.google.ads.googleads.v16.enums.TargetImpressionShareLocationEnum.TargetImpressionShareLocation}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.getLocation = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.TargetImpressionShareLocationEnum.TargetImpressionShareLocation} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.TargetImpressionShareLocationEnum.TargetImpressionShareLocation} value
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare} returns this
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 location_fraction_micros = 4;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.getLocationFractionMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare} returns this
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.setLocationFractionMicros = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare} returns this
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.clearLocationFractionMicros = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.hasLocationFractionMicros = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 cpc_bid_ceiling_micros = 5;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare} returns this
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetImpressionShare} returns this
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.clearCpcBidCeilingMicros = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetImpressionShare.prototype.hasCpcBidCeilingMicros = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.TargetRoas.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.TargetRoas} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetRoas.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetRoas: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cpcBidFloorMicros: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas}
 */
proto.google.ads.googleads.v16.common.TargetRoas.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.TargetRoas;
  return proto.google.ads.googleads.v16.common.TargetRoas.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.TargetRoas} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas}
 */
proto.google.ads.googleads.v16.common.TargetRoas.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetRoas(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidFloorMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.TargetRoas.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.TargetRoas} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetRoas.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional double target_roas = 4;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.getTargetRoas = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas} returns this
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.setTargetRoas = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas} returns this
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.clearTargetRoas = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.hasTargetRoas = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 cpc_bid_ceiling_micros = 5;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas} returns this
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas} returns this
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.clearCpcBidCeilingMicros = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.hasCpcBidCeilingMicros = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 cpc_bid_floor_micros = 6;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.getCpcBidFloorMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas} returns this
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.setCpcBidFloorMicros = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetRoas} returns this
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.clearCpcBidFloorMicros = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetRoas.prototype.hasCpcBidFloorMicros = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.TargetSpend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.TargetSpend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetSpend.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetSpendMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.TargetSpend}
 */
proto.google.ads.googleads.v16.common.TargetSpend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.TargetSpend;
  return proto.google.ads.googleads.v16.common.TargetSpend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.TargetSpend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.TargetSpend}
 */
proto.google.ads.googleads.v16.common.TargetSpend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetSpendMicros(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.TargetSpend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.TargetSpend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.TargetSpend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 target_spend_micros = 3;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.getTargetSpendMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetSpend} returns this
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.setTargetSpendMicros = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetSpend} returns this
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.clearTargetSpendMicros = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.hasTargetSpendMicros = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 cpc_bid_ceiling_micros = 4;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.TargetSpend} returns this
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.TargetSpend} returns this
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.clearCpcBidCeilingMicros = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.TargetSpend.prototype.hasCpcBidCeilingMicros = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.common.PercentCpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.common.PercentCpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.PercentCpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpcBidCeilingMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    enhancedCpcEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.common.PercentCpc}
 */
proto.google.ads.googleads.v16.common.PercentCpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.common.PercentCpc;
  return proto.google.ads.googleads.v16.common.PercentCpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.common.PercentCpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.common.PercentCpc}
 */
proto.google.ads.googleads.v16.common.PercentCpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCpcBidCeilingMicros(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnhancedCpcEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.common.PercentCpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.common.PercentCpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.common.PercentCpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 cpc_bid_ceiling_micros = 3;
 * @return {number}
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.getCpcBidCeilingMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.common.PercentCpc} returns this
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.setCpcBidCeilingMicros = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.PercentCpc} returns this
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.clearCpcBidCeilingMicros = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.hasCpcBidCeilingMicros = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enhanced_cpc_enabled = 4;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.getEnhancedCpcEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.common.PercentCpc} returns this
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.setEnhancedCpcEnabled = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.common.PercentCpc} returns this
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.clearEnhancedCpcEnabled = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.common.PercentCpc.prototype.hasEnhancedCpcEnabled = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.google.ads.googleads.v16.common);
