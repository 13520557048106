import React, { useState } from "react";
import { Form, Icon, List, Message, Radio } from "semantic-ui-react";
import styled from "styled-components/macro";
import { Segment } from "semantic-ui-react";

import PaymentForm, { stripePromise } from "./PaymentForm";
import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import { pluralize } from "Common/utils/strings";
import { Elements } from "@stripe/react-stripe-js";
import { StripeInvoiceView } from "./StripeInvoiceView";
import StripeCoupon from "ExtensionV2/components/stripe/StripeCoupon";
import { useNextInvoice } from "ExtensionV2/queries/useNextInvoice";
import { StrikePrice } from "./AmpdPlan";

import {
  Stripe,
  AmpdSubscription
} from "Common/proto/entity/billingAccount_pb";
import { FEATURES_BY_GROUP, genPriceHeadline } from "Common/utils/ampdPayment";

const StripeCouponContainer = styled.div`
  order: 1;
  margin-top: 1em;
`;

const ProductCheckoutStyledSegment = styled(Segment)`
  overflow-x: auto;
  max-width: 85em;
  margin: auto !important;
  display: grid;
  gap: 2em 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 2em 0.5fr 2em;
  grid-template-areas:
    "header        header       header"
    "features-list price-config credit-card-form"
    "footer        footer       footer";

  > div {
    min-width: 24em;
  }

  @media only screen and (max-width: 999px) {
    flex-direction: column;
    display: flex;

    > div {
      border: none;
    }
  }

  width: 100%;
  text-align: center;
  height: 100%;
`;

const HeaderGridArea = styled.div`
  grid-area: header;
  text-align: center;
`;

const ProductDetailsGridArea = styled.div`
  grid-area: features-list;
  padding: 0 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PriceConfigGridArea = styled.div`
  grid-area: price-config;
  padding: 0 1em;
  border-left: 1px solid #777;
  text-align: center;
`;

const CreditCardFormGridArea = styled.div`
  grid-area: credit-card-form;
  padding: 0 1em;
  border-left: 1px solid #777;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FooterGridArea = styled.div`
  grid-area: footer;
  text-align: center;
`;

type ProductCheckoutScreenProps = {
  siteAlias: string;
  // The subscription that is preselected when the page loads.
  initialSubscription: AmpdSubscription.AsObject;
  // If no onClose function is provided, the back button will not be rendered.
  onClose?: () => void;
  // Needed for the Clickwrap
  userEmail: string;
  // Needed for the Clickwrap
  userName: string;
  // If the selected subscription ID matches this ID, the onboarding fee will not be added
  // to the invoice.
  skipOnboardingFeeForSubscriptionId: AmpdSubscription.SubscriptionID | null;
  // The list of available subscriptions. On this page you probably only need to fetch the
  // subscriptions that are currently for sale.
  ampdSubscriptions: Array<AmpdSubscription.AsObject>;
};

export const ProductCheckoutScreen = ({
  initialSubscription,
  siteAlias,
  userEmail,
  userName,
  onClose,
  skipOnboardingFeeForSubscriptionId,
  ampdSubscriptions
}: ProductCheckoutScreenProps): JSX.Element => {
  const [selectedVariant, setSelectedVariant] = useState<
    AmpdSubscription.AsObject
  >(initialSubscription);

  const [coupon, setCoupon] = useState<Stripe.Coupon.AsObject | null>(null);

  const setupFeeId =
    skipOnboardingFeeForSubscriptionId === selectedVariant.subscriptionId
      ? ""
      : selectedVariant?.onboardingFee?.externalId?.id;

  // Generate a preview invoice for the selected subscription + any coupons. The invoice is passed
  // directly to the PaymentForm to minimize any math done on the client.
  const {
    data: nextInvoice,
    isLoading: nextInvoiceLoading,
    error: nextInvoiceError
  } = useNextInvoice({
    priceId: selectedVariant?.externalId?.id || "",
    couponId: coupon?.id,
    setupFeeId
  });

  const handleSelectVariant = (variant: AmpdSubscription.AsObject) => {
    if (selectedVariant !== variant) {
      setSelectedVariant(variant);
      setCoupon(null);
    }
  };

  const subscriptionGroup = ampdSubscriptions
    .filter(s => s.groupName === selectedVariant.groupName)
    .sort(
      (a, b) =>
        (b.onboardingFee?.feeCents ?? 0) - (a.onboardingFee?.feeCents ?? 0)
    );

  let onboardingFees;
  if (subscriptionGroup.length) {
    onboardingFees = subscriptionGroup.map(variantSubscription => {
      const optionLabel = pluralize(
        variantSubscription.minimumBillingIntervals,
        "month",
        "months"
      );

      const formattedFee = formatMetric(
        getCurrencyMetricDef("USD", false),
        (variantSubscription.onboardingFee?.feeCents || 0) / 100
      );

      const isSelected = selectedVariant === variantSubscription;
      return (
        <Form.Field key={variantSubscription.subscriptionId}>
          <Segment
            compact
            color={isSelected ? "blue" : undefined}
            onClick={() => handleSelectVariant(variantSubscription)}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Radio
              label={optionLabel}
              name="onboardingFees"
              value={variantSubscription.onboardingFee?.feeCents}
              checked={isSelected}
            />
            <p>
              <span style={{ fontSize: "12px" }}>Onboarding Fee: </span>
              {variantSubscription.subscriptionId ===
              skipOnboardingFeeForSubscriptionId ? (
                <>
                  <StrikePrice style={{ fontSize: "16px" }}>
                    {formattedFee}
                  </StrikePrice>{" "}
                  $0
                </>
              ) : (
                formattedFee
              )}
            </p>
          </Segment>
        </Form.Field>
      );
    });
  }

  const STRIPE_INVOICE_ERROR_PUBLIC_MESSAGE =
    "There was a problem loading this payment option.";

  let invoicePreviewContent;
  if (nextInvoiceError) {
    console.error(nextInvoiceError);
    invoicePreviewContent = (
      <Message error>{STRIPE_INVOICE_ERROR_PUBLIC_MESSAGE}</Message>
    );
  } else if (nextInvoice?.invoice) {
    invoicePreviewContent = (
      <StripeInvoiceView
        invoice={nextInvoice.invoice}
        totalLabel="Total due today"
      />
    );
  }

  const adSpendFeeMessages = selectedVariant.adSpendFeesList.map(adSpendFee => {
    return `${adSpendFee.feePercent}% fee on ad spend over ${formatMetric(
      getCurrencyMetricDef("USD", false),
      adSpendFee.minSpendCents / 100
    )} per month.`;
  });

  const finalPriceInCents = nextInvoice?.invoice?.amountDue || 0;

  const priceHeadline = genPriceHeadline(selectedVariant);

  return (
    <ProductCheckoutStyledSegment>
      <HeaderGridArea>
        <>
          {onClose && (
            <div
              style={{
                padding: "10px",
                fontWeight: 600,
                cursor: "pointer",
                position: "absolute"
              }}
              onClick={onClose}
            >
              <Icon name="arrow left" />
              <span>Back</span>
            </div>
          )}
        </>
      </HeaderGridArea>

      <ProductDetailsGridArea>
        <div
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
            letterSpacing: "1px"
          }}
        >
          <p>{`${selectedVariant.name} package`}</p>
          <p style={{ marginTop: "0" }}>{priceHeadline}</p>
        </div>

        <List style={{ marginTop: "2em", textAlign: "start" }}>
          {FEATURES_BY_GROUP[selectedVariant.groupName]?.map(f => (
            <List.Item
              icon={<Icon name="check circle" color="green" />}
              key={`feature-${f.text}`}
              content={f.text}
              style={{
                margin: "1em 0",
                fontSize: "1.3em",
                fontWeight: f.bold ? "bold" : ""
              }}
            />
          ))}
          {selectedVariant.adSpendFeesList && (
            <List.Item
              icon={<Icon name="check circle" color="green" />}
              key={`feature-ad-spend-limit`}
              content={adSpendFeeMessages.join(". ")}
              style={{ margin: "1em 0", fontSize: "1.3em" }}
            />
          )}
        </List>
      </ProductDetailsGridArea>

      <PriceConfigGridArea style={{ textAlign: "left" }}>
        {/* Term length picker */}
        <h2 style={{ textAlign: "center" }}>1. Configure your plan</h2>
        {!!onboardingFees && (
          <Segment>
            <Form>
              <h5>Select A Term Length</h5>
              {onboardingFees}
            </Form>
          </Segment>
        )}

        {/* Coupon Form */}
        <Segment>
          <h5>Coupon Code</h5>
          <StripeCouponContainer>
            <StripeCoupon
              key={selectedVariant.subscriptionId}
              priceIds={[
                selectedVariant?.externalId?.id || "",
                selectedVariant?.onboardingFee?.externalId?.id || ""
              ]}
              onValidateCoupon={setCoupon}
            />
          </StripeCouponContainer>
        </Segment>

        {/* Invoice Preview */}
        <Segment style={{ minHeight: "5em" }} loading={nextInvoiceLoading}>
          {invoicePreviewContent}
        </Segment>
      </PriceConfigGridArea>

      <CreditCardFormGridArea>
        <h2>2. Checkout</h2>
        {nextInvoiceError ? (
          <Message error>{STRIPE_INVOICE_ERROR_PUBLIC_MESSAGE}</Message>
        ) : (
          <>
            <Elements
              stripe={stripePromise}
              options={{
                mode: "subscription",
                currency: "usd",
                amount: finalPriceInCents
              }}
            >
              <PaymentForm
                ampdSubscription={selectedVariant}
                coupon={coupon}
                setupFeeId={setupFeeId || ""}
                siteAlias={siteAlias}
                userEmail={userEmail}
                userName={userName}
                invoice={nextInvoice?.invoice}
                invoiceLoading={nextInvoiceLoading}
                invoiceError={!!nextInvoiceError}
              />
            </Elements>
          </>
        )}
      </CreditCardFormGridArea>
      <FooterGridArea>
        <a
          href="https://www.ampd.io/terms?hsLang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>Terms</i>
        </a>
        <i>&nbsp; - &nbsp;</i>
        <a
          href="https://www.ampd.io/privacy?hsLang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>Privacy</i>
        </a>
      </FooterGridArea>
    </ProductCheckoutStyledSegment>
  );
};
