import React, { useState, useMemo } from "react";
import { Message, Icon, Form } from "semantic-ui-react";

import { getCurrencyMetricDef } from "Common/utils/money";
import { formatMetric } from "Common/utils/metrics";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { Stripe } from "Common/proto/entity/billingAccount_pb";
import {
  VerifyStripeCouponReply,
  VerifyStripeCouponRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Stripe_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { extractErrorMessage } from "Common/errors/error";
import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { pluralize } from "Common/utils/strings";

const ONE_TIME_COUPON = "once";
const SET_MONTH_REPEATING = "repeating";

const useVerifyCoupon = (
  couponId: string,
  priceIds: Array<string>,
  onSuccess: (resp: VerifyStripeCouponReply.AsObject) => void,
  onError: () => void
): UseMutationResult<VerifyStripeCouponReply.AsObject, unknown> => {
  const siteAlias = getSiteAliasFromURL("/t");
  return useMutation({
    onSuccess,
    onError,
    mutationFn: async () => {
      const req = new VerifyStripeCouponRequest();
      req.setCouponId(couponId);
      req.setPriceIdsList(priceIds.filter(Boolean));
      req.setSiteAlias(siteAlias);
      const reply = await GRPCWebClient.verifyStripeCoupon(req, {});

      if (!reply.getApplicable()) {
        throw new Error("Invalid coupon");
      }

      return reply.toObject();
    }
  });
};

const StripeCoupon = ({
  priceIds,
  onValidateCoupon
}: {
  priceIds: Array<string>;
  onValidateCoupon: (coupon: Stripe.Coupon.AsObject | null) => void;
}): JSX.Element => {
  const [couponId, setCouponId] = useState("");

  const {
    mutate: verifyCoupon,
    data: couponDetails,
    isLoading: isCouponDetailsLoading,
    error: couponDetailsError
  } = useVerifyCoupon(
    couponId,
    priceIds,
    //onSuccess
    reply => {
      if (reply.coupon) {
        onValidateCoupon(reply.coupon);
      }
    },
    //onError
    () => {
      onValidateCoupon(null);
      setCouponId("");
    }
  );

  const coupon = couponDetails?.coupon;
  const discountAmountMessage = useMemo(() => {
    if (!coupon) {
      return "";
    }

    if (coupon.percentOff > 0) {
      return `Coupon Applied: ${coupon.percentOff}% off`;
    }

    if (coupon.amountOff > 0) {
      const currencyDef = getCurrencyMetricDef("USD");
      const displayAmount = formatMetric(currencyDef, coupon.amountOff / 100);
      return `Coupon Applied: ${displayAmount} off`;
    }

    return "Coupon Applied";
  }, [coupon]);

  const discountLengthMessage = useMemo(() => {
    if (!coupon) {
      return "";
    }

    if (coupon.duration === ONE_TIME_COUPON) {
      return "for 1 billing period";
    }

    if (
      coupon.duration === SET_MONTH_REPEATING &&
      coupon.durationInMonths &&
      coupon.durationInMonths > 0
    ) {
      return `for ${pluralize(coupon.durationInMonths, "month", "months")}`;
    }

    return "";
  }, [coupon]);

  return (
    <Form
      onSubmit={() => {
        if (!couponId) {
          return;
        }
        verifyCoupon(couponId);
      }}
      success={!!coupon}
      error={!!couponDetailsError}
    >
      <Form.Group>
        <Form.Input
          style={{ marginBottom: "0.5rem" }}
          placeholder="Coupon Code"
          name="couponId"
          width={14}
          onChange={ev => setCouponId(ev.target.value)}
          value={couponId}
        />
        <Form.Button
          width={3}
          fluid
          icon
          type="submit"
          loading={isCouponDetailsLoading}
          positive={coupon != null}
        >
          <Icon name={coupon != null ? "checkmark" : "arrow right"} />
        </Form.Button>
      </Form.Group>
      <Message
        success
        content={`${discountAmountMessage} ${discountLengthMessage}`.trim()}
      />
      <Message error content={extractErrorMessage(couponDetailsError)} />
    </Form>
  );
};

export default StripeCoupon;
