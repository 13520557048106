import React from "react";
import { Flex } from "@rebass/grid";

import ReactSlider from "react-slider";
import styled from "styled-components/macro";
import { backgroundDark, backgroundPrimary } from "../../styles/colors";

class NumberSlider extends ReactSlider<number> {}

const StyledSlider = styled(NumberSlider)`
  width: 100%;
  height: 25px;
`;

const StyledTrack = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  top: 10px;
  bottom: 10px;
  background: #ddd;
`;

const Track: React.FC = props => <StyledTrack {...props} />;

export const VOLTAGE_MIN = 1;
export const VOLTAGE_MAX = 10;
export const VOLTAGE_STEP = 0.1;
export const VOLTAGE_MARKS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const DEFAULT_VOLTAGE = 5.6;

const StyledMark = styled.div`
  margin-left: 7px;
  margin-top: 7px;
  height: 11px;
  line-height: 5px;
  width: 11px;
  background-color: #fff;
  border-color: #ddd;
  border-style: solid;
  border-radius: 50%;
`;

const Mark: React.FC = props => <StyledMark {...props} />;

const StyledThumb = styled.div<{ disabled?: boolean }>`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  font-size: small;
  font-weight: bold;
  background-color: ${props =>
    props.disabled ? backgroundDark : backgroundPrimary};
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const Thumb: React.FC = (props, state: { valueNow: number }) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const DisabledThumb: React.FC = (props, state: { valueNow: number }) => (
  <StyledThumb {...props} disabled={true}>
    {state.valueNow}
  </StyledThumb>
);

export type RecentMetricsDisplay = {
  startDateString: string;
  endDateString: string;
  costString: string;
  clicksString: string;
  revenueString: string;
  aacosString: string;
};

const TargetVoltageSlider: React.FC<{
  disabled: boolean;
  targetVoltage: number;
  setDragTargetVoltage: (voltage: number) => void;
  setTargetVoltage: (voltage: number) => void;
}> = ({ disabled, targetVoltage, setDragTargetVoltage, setTargetVoltage }) => {
  return (
    <>
      <div>
        <StyledSlider
          min={VOLTAGE_MIN}
          max={VOLTAGE_MAX}
          step={VOLTAGE_STEP}
          marks={VOLTAGE_MARKS}
          disabled={disabled}
          value={targetVoltage || DEFAULT_VOLTAGE}
          renderTrack={Track}
          renderThumb={disabled ? DisabledThumb : Thumb}
          renderMark={Mark}
          onChange={setDragTargetVoltage}
          onAfterChange={voltage => {
            setDragTargetVoltage(0);
            setTargetVoltage(voltage);
          }}
        />
        <Flex flexDirection="row" justifyContent="space-between">
          <small>Prioritize ACOS</small>
          <small>Prioritize Revenue</small>
        </Flex>
      </div>
    </>
  );
};

export default TargetVoltageSlider;
