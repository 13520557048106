import React from "react";

import { Card, Icon } from "semantic-ui-react";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import styled from "styled-components";
import { backgroundDark } from "ExtensionV2/styles/colors";

import { formatMetric } from "Common//utils/metrics";
import { supportedChartMetricTypes } from "./performanceByChannelUtil";
import { titleCase } from "change-case";
import { NARROW_VIEW } from "../layout/AmpdGridItems";

const LIGHT_GRAY = "#d3d3d3";
const DARK_TEXT = "#202020";

// eslint-disable-next-line no-unused-vars
const TileCard = styled(({ isSeriesHidden, topColor, tileCount, ...rest }) => (
  <Card {...rest} />
))`
  &div.ui.cards a.card:hover,
  .ui.link.card:hover,
  .ui.link.cards .card:hover,
  a.ui.card:hover,
  :hover {
    border: 1px solid
      ${props => (props.isSeriesHidden ? LIGHT_GRAY : backgroundDark)} !important;
    border-top: 5px solid
      ${props => (props.isSeriesHidden ? LIGHT_GRAY : props.topColor)} !important;
    transform: none !important;
    text-decoration: none !important;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  }

  &&& {
    max-height: 100%;
    border: 1px solid
      ${props => (props.isSeriesHidden ? LIGHT_GRAY : backgroundDark)};
    border-top: 5px solid
      ${props => (props.isSeriesHidden ? LIGHT_GRAY : props.topColor)};
    width: calc((100% / ${props => props.tileCount}) - 16px);
    max-width: ${props => (props.tileCount === 4 ? "" : "500px")};
  }

  &&& > div.header,
  &.ui.card > .content > .header:not(.ui) {
    font-size: 0.9em;
    color: ${props => (props.isSeriesHidden ? LIGHT_GRAY : DARK_TEXT)};
  }

  &&& > div.description,
  &.ui.card > .content > .description {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.2em;
    padding-left: 0.5em;
    color: ${props => (props.isSeriesHidden ? LIGHT_GRAY : DARK_TEXT)};
  }

  @media (min-width: ${NARROW_VIEW}px) {
    &&& > div.description,
    &.ui.card > .content > .description {
      font-size: 2em;
      font-weight: bold;
      margin: 0.2em;
      padding-left: 0.5em;
      color: ${props => (props.isSeriesHidden ? LIGHT_GRAY : DARK_TEXT)};
    }
  }
  &&& > div.content > div.header > i.icon.info.circle {
    cursor: pointer;
    height: 1em;
    padding-bottom: 3px;
    padding-left: 1em;
    width: 1em;
  }
`;

// Builds the Channel tiles for the chart that display channel metric info
// like: channel name, metric name, timeseries aggregate value, etc.
export const SeriesTile = ({
  channelType,
  aggMetricValue,
  selectedMetric,
  metricDef,
  tileCount,
  isSeriesHidden,
  handleTileClick
}) => {
  const aggValue = aggMetricValue > 0 ? aggMetricValue : 0;
  const aggDisplayValue = formatMetric(metricDef, aggValue);

  const chartMetric = supportedChartMetricTypes?.[selectedMetric];
  const chartChannels = chartMetric?.channels?.find(
    channel => channel.channelType === channelType
  );
  const channelMetricTooltip = chartChannels?.description;
  const title = buildTitle(chartChannels.metricField, channelType.name);

  return (
    <TileCard
      topColor={channelType.color}
      tileCount={tileCount}
      isSeriesHidden={isSeriesHidden}
      onClick={() => handleTileClick(channelType)}
    >
      <Card.Content style={{ padding: "0.5em" }}>
        <Card.Header>
          {title}
          {!!channelMetricTooltip && (
            <SimpleTooltip tooltip={channelMetricTooltip}>
              <Icon name="info circle" color="grey" size="small" />
            </SimpleTooltip>
          )}
        </Card.Header>
        <Card.Description> {aggDisplayValue} </Card.Description>
      </Card.Content>
    </TileCard>
  );
};

export const ChannelChartTiles = ({
  selectedMetric,
  handleTileClick,
  isChannelSeriesHiddenMap,
  channels = [],
  skipChannels = [] // TODO(clint): Rollout feature; remove after Seller warehousing.
}) => {
  const filteredChannels = channels?.filter(
    c => !skipChannels.includes(c.channelType.key)
  );

  return (
    <Card.Group
      columns={filteredChannels.size}
      style={{
        justifyContent: `${
          filteredChannels.size === 4 ? "space-between" : "flex-start"
        }`
      }}
    >
      {filteredChannels?.map(channel => (
        <SeriesTile
          channelType={channel.channelType}
          key={channel.channelType.key}
          aggMetricValue={channel.aggMetricValue}
          selectedMetric={selectedMetric}
          metricDef={channel.metricDef}
          tileCount={filteredChannels.size}
          isSeriesHidden={isChannelSeriesHiddenMap.get(channel.channelType.key)}
          handleTileClick={handleTileClick}
        />
      ))}
    </Card.Group>
  );
};

const buildTitle = (metricField, channelName) => {
  const channelDataName = titleCase(metricField)
    // Remove data prefixes
    .replace(/amazon/gi, "")
    .replace(/google/gi, "")
    .replace(/List/gi, "")
    // Custom text styling, when found.
    .replace(/acos/gi, "ACoS")
    .replace(/tacos/gi, "ACoS (TACoS)");

  return channelDataName ? `${channelName} ${channelDataName}` : channelName;
};
