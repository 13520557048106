import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetAmazonProductRequest,
  GetAmazonProductReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Amazon_pb";
import { Amazon } from "Common/proto/common/amazon_pb";

export const fetchAmazonProduct = async (
  siteAlias: string,
  asin: string,
  marketplace: number
): Promise<GetAmazonProductReply.AsObject> => {
  const req = new GetAmazonProductRequest();
  req.setSiteAlias(siteAlias);
  req.setAsin(asin);
  req.setMarketplace(marketplace);

  const reply = await GRPCWebClient.getAmazonProduct(req, {});
  return reply.toObject();
};

export const fetchAmazonProductQueryKey = (
  siteAlias: string,
  asin: string,
  marketplace: number
): Array<string | number> => {
  return ["amazonProduct", siteAlias, asin, marketplace];
};

// Executes a realtime Rainforest scrape for any Amazon product.
export const useAmazonProduct = (
  siteAlias: string,
  asin: string,
  marketplace: Amazon.Marketplace.Option
): UseQueryResult<GetAmazonProductReply.AsObject, unknown> => {
  const oneDay = 1000 * 60 * 60 * 24; //one day
  return useQuery({
    queryKey: fetchAmazonProductQueryKey(siteAlias, asin, marketplace),
    staleTime: oneDay,
    cacheTime: oneDay,
    enabled:
      !!siteAlias && !!asin && marketplace != Amazon.Marketplace.Option.UNKNOWN,
    queryFn: async () => fetchAmazonProduct(siteAlias, asin, marketplace)
  });
};
