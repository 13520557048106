import _ from "lodash";

import React from "react";
import { Dimmer, Dropdown, Loader, Message } from "semantic-ui-react";
import { useQuery } from "react-apollo";

import { extractErrorMessage } from "Common/errors/error";
import { formatCustomerId } from "Common/utils/googleAds";
import { ListGoogleAdsCustomers } from "ExtensionV2/graphql";

// Query for the Google Ads accounts associated with an access token.
export function useAccountList({
  siteAlias,
  accessToken,
  setAccounts,
  setErrors
}) {
  return useQuery(ListGoogleAdsCustomers, {
    variables: {
      site: { siteAlias },
      accessToken
    },
    notifyOnNetworkStatusChange: true,
    skip: !accessToken,
    onCompleted: data => {
      if (data && data.googleAdsListCustomers) {
        const uniqueCustomers = _.uniqBy(
          data.googleAdsListCustomers,
          "customerId"
        );

        let accounts = uniqueCustomers.map(account => {
          let text = formatCustomerId(account.customerId);
          if (account.name) {
            text += ` - ${account.name}`;
          }

          text += !account.currencyCode
            ? " (no currency)"
            : ` (currency: ${account.currencyCode})`;

          return {
            account,
            key: account.customerId,
            text: text,
            value: account.customerId
          };
        });

        accounts = _.sortBy(accounts, o => o.account.customerId);
        setAccounts(accounts);
      }
    },
    onError: e => {
      const errMsg = extractErrorMessage(e);
      setErrors([
        <>
          <p>
            There was an error loading your Google Ads accounts. Please contact
            us for assistance.
          </p>
          {errMsg && <p>Error: {errMsg}</p>}
        </>
      ]);
    }
  });
}

// Displays a selector for the Google Ads account.
export default function GoogleAdsAccountSelector(props) {
  const options = _.clone(props.accounts);

  if (props.includeNoAccount) {
    options.unshift({
      account: null,
      key: "NO_ACCOUNT",
      text: "(No account)",
      value: ""
    });
  }

  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer inverted active={props.loading}>
          <Loader />
        </Dimmer>

        {((props.loading || props.accounts.length) && (
          <Dropdown
            options={options.map(opt => ({
              key: opt.key,
              value: opt.value,
              text: opt.text
            }))}
            onChange={props.onAccountChange}
            value={_.get(props.selectedAccount, "customerId") || ""}
            placeholder="Select an account"
            search
            fluid
            selection
          />
        )) || (
          <Message info>
            <p>
              Your account, <b>{props.email}</b>, doesn't have access to any
              Google Ads accounts.
            </p>
            <p>
              Create a new Google Ads account below or sign in with a different
              Google account above.
            </p>
          </Message>
        )}
      </Dimmer.Dimmable>
    </>
  );
}
