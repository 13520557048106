import { UseMutationResult, useMutation } from "@tanstack/react-query";

import {
  UpdateCampaignsRequest,
  UpdateCampaignsReply,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import {
  UpdateCampaignBiddingStrategyAction,
  BiddingStrategy
} from "Common/proto/googleAdsPb/campaign_pb";
import { GRPCWebClient } from "Common/utils/grpc";

import { sendGAEvent } from "../components/GA";
import {
  getCurrencyMinimumUnit,
  roundMicrosToCurrencyMinimumUnit
} from "Common/utils/googleAds";

export const BIDDING_STRATEGY_MANUAL_CPC_STR = "MANUAL_CPC";
export const BIDDING_STRATEGY_TARGET_SPEND_STR = "TARGET_SPEND";

interface UpdateCampaignBiddingStrategyArgs {
  siteAlias: string;
  gaCategory: string;
  googleAdsAccountId: string;
  currencyCode: string;
  campaignId: string;
  adGroupId: string;
  newBiddingStrategy: string;
  newCpcBid: number;
}

export const useUpdateCampaignBiddingStrategy = (): UseMutationResult<
  UpdateCampaignsReply.AsObject,
  unknown,
  UpdateCampaignBiddingStrategyArgs,
  unknown
> => {
  return useMutation(
    async (
      updateCampaignBiddingStrategyArgs: UpdateCampaignBiddingStrategyArgs
    ): Promise<UpdateCampaignsReply.AsObject> => {
      for (const [key, value] of Object.entries(
        updateCampaignBiddingStrategyArgs
      )) {
        if (key === "gaCategory") {
          continue;
        }
        if (value == null || value?.length === 0) {
          throw new Error(`Missing required argument: ${key}`);
        }
      }

      const {
        siteAlias,
        gaCategory,
        googleAdsAccountId,
        currencyCode,
        campaignId,
        adGroupId,
        newBiddingStrategy,
        newCpcBid
      } = updateCampaignBiddingStrategyArgs;

      if (gaCategory) {
        sendGAEvent(
          gaCategory,
          "Update Campaign Bidding Strategy",
          siteAlias,
          newBiddingStrategy
        );
      }

      const currencyMinimumMicros = getCurrencyMinimumUnit(currencyCode);
      const newCpcBidMicros = roundMicrosToCurrencyMinimumUnit(
        newCpcBid * 1e6,
        currencyCode,
        newBiddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR
      );

      const updateBiddingStrategy = new UpdateCampaignBiddingStrategyAction();
      updateBiddingStrategy.setCampaignId(campaignId);
      updateBiddingStrategy.setNewBiddingStrategy(
        newBiddingStrategy === BIDDING_STRATEGY_MANUAL_CPC_STR
          ? BiddingStrategy.Option.MANUAL_CPC
          : BiddingStrategy.Option.TARGET_SPEND
      );
      updateBiddingStrategy.setCampaignMaxCpcMicros(
        newBiddingStrategy === BIDDING_STRATEGY_MANUAL_CPC_STR
          ? 0
          : newCpcBidMicros
      );
      updateBiddingStrategy.setAdGroupId(adGroupId);
      updateBiddingStrategy.setAdGroupCpcBidMicros(
        newBiddingStrategy === BIDDING_STRATEGY_MANUAL_CPC_STR
          ? newCpcBidMicros
          : currencyMinimumMicros
      );

      const updateAction = new UpdateCampaignAction();
      updateAction.setUpdateBiddingStrategy(updateBiddingStrategy);

      const updateReq = new UpdateCampaignsRequest();
      updateReq.setSiteAlias(siteAlias);
      updateReq.setCustomerId(googleAdsAccountId);
      updateReq.setActionsList([updateAction]);

      const reply = await GRPCWebClient.updateCampaigns(updateReq, {});
      return reply.toObject();
    }
  );
};
