// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { UpdateSiteManagerLinkClientLabelsRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";

// Sends an update request for the manager-client link between the specified
// sites with new labels to add and/or with labels to remove.
export async function updateSiteManagerLinkClientLabels(
  managerSiteAlias,
  clientSiteAlias,
  addLabels,
  removeLabels
) {
  if (!managerSiteAlias || !clientSiteAlias || (!addLabels && !removeLabels)) {
    return;
  }

  const updateRequest = proto.set(
    new UpdateSiteManagerLinkClientLabelsRequest(),
    {
      managerSiteAlias,
      clientSiteAlias,
      addLabels,
      removeLabels
    }
  );

  const response = await GRPCWebClient.updateSiteManagerLinkClientLabels(
    updateRequest
  );
  return response?.toObject();
}
