import { useSession, User } from "./useSession";

// Returns the current user of the current session, or a blank stub object if
// there isn't one.
export const useSessionUser = (): User => {
  const { user } = useSession();
  if (user) {
    return user;
  }

  return {
    authorizedSitesList: [],
    familyName: "",
    givenName: "",
    userEmail: "",
    userId: "",
    isCurrentSiteAdmin: false,
    isAmpdOperator: false,
    uiSettings: {
      dashboardIntroVideoDismissed: 0,
      ampdDashboardV2OptIn: false
    },
    userName: ""
  };
};
