import {
  AttributionAdvertiser,
  getMarketplaceTypeByEnum
} from "Common/utils/amazon";
import { titleCase } from "change-case";
import React, { useState } from "react";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";
import { Message } from "semantic-ui-react";
import { AmazonAttributionAdvertiserSelector } from "ExtensionV2/components/AmazonAttributionAdvertiserSelector";
import { None } from "Common/utils/tsUtils";

const validateAttribution = (
  _attributionAdvertiser: AttributionAdvertiser | None
): Set<string> => {
  const errors = new Set<string>();
  // TODO: add more validation as necessary. Attribution field for a campaign can be empty because we
  // will fall back to the default attribution profile if none is provided.
  return errors;
};

export const attributionField = (
  initialValue: AttributionAdvertiser | None
): FormField<AttributionAdvertiser | None> => ({
  _value: initialValue,
  errors: validateAttribution(initialValue),
  validator: validateAttribution
});

export const AttributionReviewSection = ({
  state,
  dispatch,
  onEdit
}: {
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const [
    campaign,
    { selectAttributionAdvertiser }
  ] = getCurrentCampaignAndSelectors(state);
  const {
    attributionAdvertiserField: {
      validator: attributionAdvertiserValidator,
      errors: attributionAdvertiserErrors
    }
  } = campaign;

  const attributionAdvertiser = selectAttributionAdvertiser(campaign, state);

  const [tempAttributionProfile, setTempAttributionProfile] = useState<
    AttributionAdvertiser | None
  >(attributionAdvertiser);

  const [localErrors, setLocalErrors] = useState<Set<string>>(
    attributionAdvertiserErrors
  );

  let attributionTitle = attributionAdvertiser?.profileName;
  if (attributionAdvertiser?.profileIdStr) {
    attributionTitle += ` (${attributionAdvertiser?.profileIdStr})`;
  } else if (!attributionAdvertiser?.advertiserIdStr) {
    attributionTitle = "No attribution profile selected";
  }

  const dataComponent = (
    <div>
      {!!attributionTitle && <p>{attributionTitle}</p>}
      <p>
        {titleCase(
          getMarketplaceTypeByEnum(attributionAdvertiser?.marketplace)
        )}
      </p>
    </div>
  );

  const editComponent = (
    <>
      <AmazonAttributionAdvertiserSelector
        initialSelectedAttributionAdvertiser={tempAttributionProfile}
        onChange={attributionAdvertiser => {
          setTempAttributionProfile(attributionAdvertiser);
          setLocalErrors(attributionAdvertiserValidator(attributionAdvertiser));
        }}
        onBlur={() => {
          setLocalErrors(
            attributionAdvertiserValidator(tempAttributionProfile)
          );
        }}
      />
      {localErrors.size > 0 && (
        <Message negative>
          {Array.from(localErrors).map(error => (
            <p key={error}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  const handleSave = () => {
    dispatch({
      name: "UpdateAmazonAttributionProfile",
      data: {
        index: state.currentReviewCampaignIndex,
        attributionAdvertiser: tempAttributionProfile
      }
    });
  };

  const handleCancel = () => {
    setTempAttributionProfile(attributionAdvertiser);
  };

  return (
    <CampaignReviewSection
      dataName="Attribution"
      dataComponent={dataComponent}
      disableSave={localErrors.size > 0}
      editComponent={editComponent}
      errors={attributionAdvertiserErrors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
