import _ from "lodash";
import React from "react";
import { List, Message } from "semantic-ui-react";

import GoogleAdsAccountSelector from "../components/GoogleAdsAccountSelector";
import GoogleAdsButton from "./GoogleAdsLoginButton";

import { sendGAEvent } from "./GA";

// GraphQL
import { Error } from "Common/errors/error";
import { AUDIT_PAGE_GA_CATEGORY } from "ExtensionV2/ExtensionV2";

// Handles the logic for linking accounts. Throws an error on failure.
export async function linkAccountOrError({
  linkGoogleAdsAccounts,
  accessToken,
  siteAlias,
  selectedAccount,
  attribution
}) {
  const { customerId, managerId } = selectedAccount;

  if (!accessToken) {
    throw new Error({ publicText: "Missing access token." });
  }

  if (!customerId) {
    throw new Error({ publicText: "Missing customer id." });
  }

  if (!managerId) {
    throw new Error({ publicText: "Missing manager id." });
  }

  await linkGoogleAdsAccounts({
    variables: {
      site: {
        siteAlias
      },
      accessToken,
      customerIds: [customerId],
      managerIds: [managerId],
      skipBilling: true,
      attribution
    }
  });
}

export function SignInUser(props) {
  const { setErrors, siteAlias, email, setGoogleLogin, setAccounts } = props;

  // Handles a successful login to Google.
  const onGoogleLogin = response => {
    sendGAEvent(AUDIT_PAGE_GA_CATEGORY, "Sign Into Google Success", siteAlias);
    setGoogleLogin(response);
    setAccounts([]);
    setErrors(null);
  };

  return (
    <GoogleAdsButton
      siteAlias={siteAlias}
      gaCategory={AUDIT_PAGE_GA_CATEGORY}
      email={email}
      onGoogleLogin={onGoogleLogin}
    />
  );
}

function ConnectAccountContent(props) {
  const {
    linkingAccount,
    accessToken,
    errors,
    setErrors,
    accountsLoading,
    selectedAccount,
    setSelectedAccount,
    siteAlias,
    email,
    accounts
  } = props;

  // Handles the selection of a new account from the dropdown.
  const onAccountChange = async (e, data) => {
    e.stopPropagation();

    if (!data.value) {
      setSelectedAccount(null);
      setErrors(null);
      return;
    }

    sendGAEvent(AUDIT_PAGE_GA_CATEGORY, "Select Account", siteAlias);

    const customerId = data.value;
    const selectedObject = _.find(accounts, { value: customerId });
    const account = _.get(selectedObject, "account");

    setSelectedAccount(account);
    setErrors(null);
  };

  return (
    <div style={{ marginBottom: 16 }}>
      {accessToken && (
        <GoogleAdsAccountSelector
          loading={accountsLoading}
          email={email}
          accounts={accounts}
          includeNoAccount={true}
          disabled={linkingAccount}
          selectedAccount={selectedAccount}
          onAccountChange={onAccountChange}
        />
      )}
      {_.size(errors) > 0 && (
        <Message negative>
          <Message.Header>Errors</Message.Header>
          <List bulleted>
            {errors.map((error, i) => (
              <List.Item key={i}>{error}</List.Item>
            ))}
          </List>
        </Message>
      )}
    </div>
  );
}

export default ConnectAccountContent;
