import React from "react";

function LinkStyleButton(props) {
  return (
    <button {...props} className="button-link">
      {props.children}
    </button>
  );
}

export default LinkStyleButton;
