import { STATUS_ENABLED_STR } from "ExtensionV2/components/MetricColumns";

// Try to pick some seed keywords for the keyword picker so there are some suggestions for users to
// look through when the modal first launches. Pick the top converting keywords or ones with the
// highest impressions. If there aren't any keywords with impressions or conversions just pick
// one so the user isn't presented with a blank keyword suggestion table.
export const getTopPerformingKeywordSeeds = allKeywords => {
  if (!allKeywords || allKeywords.length === 0) {
    return [];
  }

  const desiredTopKeywordCount = 3;

  const activeKeywords = allKeywords.filter(
    kw => kw.status === STATUS_ENABLED_STR
  );

  const keywordsByConversions = activeKeywords
    .filter(kw => kw.conversions > 0)
    .sort((a, b) => b.conversions - a.conversions);
  let topKeywords = keywordsByConversions.slice(0, desiredTopKeywordCount);

  if (topKeywords.length >= desiredTopKeywordCount) {
    return topKeywords;
  }

  const keywordsByImpressions = activeKeywords
    .filter(kw => kw.impressions > 0)
    .sort((a, b) => b.impressions - a.impressions);
  for (const kw of keywordsByImpressions) {
    if (topKeywords.length >= desiredTopKeywordCount) {
      break;
    }
    if (!topKeywords.includes(kw)) {
      topKeywords.push(kw);
    }
  }

  if (topKeywords.length > 0) {
    return topKeywords;
  }

  // if there aren't any keywords with impressions or conversions just pick one
  const firstKeyword = activeKeywords[0] || allKeywords[0];
  return [firstKeyword];
};
