import React, { useState } from "react";
import { Input, Loader, Message } from "semantic-ui-react";

import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";

import { isValidUrl } from "Common/utils/isValidUrl";
import { Site } from "ExtensionV2/queries/useSession";

const validateTargetUrl = (targetUrl: string): Set<string> => {
  const errors = new Set<string>();

  // targetUrl field can be empty because we fallback to the canonical URL from rainforest when
  // submitting if the user has not provided one
  if (!targetUrl) {
    return errors;
  }

  if (!isValidUrl(targetUrl)) {
    errors.add("Target URL is invalid");
  }

  return errors;
};

export const targetUrlField = (initialValue: string): FormField<string> => ({
  _value: initialValue,
  errors: validateTargetUrl(initialValue),
  validator: validateTargetUrl
});

const splitUrl = (
  urlString: string
): { origin: string; rest: string; parseError: boolean } => {
  if (!urlString) {
    return {
      origin: "",
      rest: "",
      parseError: false
    };
  }

  try {
    const url = new URL(urlString);
    const origin = url.origin;
    const rest = url.pathname + url.search + url.hash;

    return {
      origin,
      rest,
      parseError: false
    };
  } catch (e) {
    console.error(e);

    return {
      origin: "",
      rest: "",
      parseError: true
    };
  }
};

export const TargetUrlReviewSection = ({
  site,
  state,
  dispatch,
  onEdit
}: {
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;
  const { siteAlias } = site;
  const [
    campaign,
    { selectTargetUrl, useSelectTargetInfo }
  ] = getCurrentCampaignAndSelectors(state);

  const targetUrl = selectTargetUrl(campaign);

  const {
    isLoading,
    finalUrl: initialTargetUrl,
    defaultFinalUrl: defaultTargetUrl
  } = useSelectTargetInfo(siteAlias, campaign, state);

  const [localErrors, setLocalErrors] = useState<Set<string>>(
    campaign.targetUrlField.errors
  );

  const [urlOrigin, setUrlOrigin] = useState(splitUrl(initialTargetUrl).origin);

  const [restOfUrl, setRestOfUrl] = useState(splitUrl(initialTargetUrl).rest);

  const [lastTargetUrl, setLastTargetUrl] = useState(initialTargetUrl);
  if (initialTargetUrl !== lastTargetUrl) {
    setLastTargetUrl(initialTargetUrl);

    const splitUrlResult = splitUrl(initialTargetUrl);
    setUrlOrigin(splitUrlResult.origin);
    setRestOfUrl(splitUrlResult.rest);
    if (splitUrlResult.parseError) {
      setLocalErrors(new Set(["Target URL is invalid"]));
    }
  }

  const dataComponent = isLoading ? (
    <div>
      <Loader active inline size="mini" />
      <p
        style={{
          display: "inline-block",
          marginLeft: "1em"
        }}
      >
        <i>Getting your Target Url</i>
      </p>
    </div>
  ) : (
    <p>{initialTargetUrl}</p>
  );

  const tempUrl = `${urlOrigin}${restOfUrl}`;
  let editComponent;
  if (!isLoading) {
    editComponent = (
      <>
        <p>
          This is where your ad will take users when they click on it. Changing
          this can break your attribution. You should not change this unless you
          know what you are doing.
        </p>
        <Input
          label={urlOrigin}
          style={{ width: "100%" }}
          value={restOfUrl}
          placeholder="Enter the target URL for your ad"
          onChange={(_ev, data) => {
            setRestOfUrl(data.value);
          }}
          onBlur={() => {
            const errors = validateTargetUrl(tempUrl);
            setLocalErrors(errors);
          }}
        />
        {localErrors.size > 0 && (
          <Message negative>
            {Array.from(localErrors).map((error, i) => (
              <p key={`h-${i}`}>{error}</p>
            ))}
          </Message>
        )}
        {localErrors.size === 0 && (
          <>
            <p
              style={{
                margin: "1em 0 0 0"
              }}
            >
              You can test your targeting URL here:
            </p>
            <a href={tempUrl} target="_blank" rel="noopener noreferrer">
              {tempUrl}
            </a>
          </>
        )}

        <div style={{ marginTop: "1em" }}>
          <a
            onClick={() => {
              if (!defaultTargetUrl) {
                return;
              }
              const splitUrlResult = splitUrl(defaultTargetUrl);
              setUrlOrigin(splitUrlResult.origin);
              setRestOfUrl(splitUrlResult.rest || "/");
              setLocalErrors(new Set());
            }}
          >
            <i>Reset URL</i>
          </a>
        </div>
      </>
    );
  }

  const handleSave = () => {
    // parsing here just to handle any necessary url encodings,
    try {
      const parsedUrl = new URL(tempUrl);
      dispatch({
        name: "UpdateTargetUrl",
        data: {
          index: currentReviewCampaignIndex,
          targetUrl: parsedUrl.href
        }
      });
    } catch (e) {
      console.error(e);
      setLocalErrors(new Set(["Target URL is invalid"]));
    }
  };

  const handleCancel = () => {
    setLastTargetUrl(targetUrl);
  };

  return (
    <CampaignReviewSection
      dataName="Target URL"
      dataComponent={dataComponent}
      disableSave={localErrors.size > 0}
      editComponent={editComponent}
      errors={campaign.targetUrlField.errors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
