import { UseMutationResult, useMutation } from "@tanstack/react-query";

import {
  UpdateCampaignsRequest,
  UpdateCampaignsReply,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { GRPCWebClient } from "Common/utils/grpc";

import { sendGAEvent } from "../components/GA";
import { roundMicrosToCurrencyMinimumUnit } from "Common/utils/googleAds";
import { UpdateAdGroupCriteriaCPCBidAction } from "Common/proto/googleAdsPb/adGroupCriteria_pb";

interface UpdateKeywordCPCBidArgs {
  siteAlias: string;
  gaCategory: string;
  googleAdsAccountId: string;
  currencyCode: string;
  campaignId: string;
  adGroupId: string;
  criteriaId: string;
  newCpcBid: number;
}

export const useUpdateKeywordCPCBid = (): UseMutationResult<
  UpdateCampaignsReply.AsObject,
  unknown,
  UpdateKeywordCPCBidArgs,
  unknown
> => {
  return useMutation(
    async (
      updateKeywordCPCBidArgs: UpdateKeywordCPCBidArgs
    ): Promise<UpdateCampaignsReply.AsObject> => {
      for (const [key, value] of Object.entries(updateKeywordCPCBidArgs)) {
        if (value == null || value?.length === 0) {
          throw new Error(`Missing required argument: ${key}`);
        }
      }

      const {
        siteAlias,
        gaCategory,
        googleAdsAccountId,
        currencyCode,
        adGroupId,
        criteriaId,
        newCpcBid
      } = updateKeywordCPCBidArgs;

      sendGAEvent(gaCategory, "Update Keyword CPC Bid", siteAlias);

      const newCpcBidMicros = newCpcBid
        ? roundMicrosToCurrencyMinimumUnit(newCpcBid * 1e6, currencyCode, false)
        : 0;

      const updateCpcBid = new UpdateAdGroupCriteriaCPCBidAction();
      updateCpcBid.setAdGroupId(adGroupId);
      updateCpcBid.setCriteriaId(criteriaId);
      updateCpcBid.setNewCpcBidMicros(newCpcBidMicros);

      const updateAction = new UpdateCampaignAction();
      updateAction.setUpdateAdGroupCriteriaCpcBid(updateCpcBid);

      const updateReq = new UpdateCampaignsRequest();
      updateReq.setSiteAlias(siteAlias);
      updateReq.setCustomerId(googleAdsAccountId);
      updateReq.setActionsList([updateAction]);

      const reply = await GRPCWebClient.updateCampaigns(updateReq, {});
      return reply.toObject();
    }
  );
};
