// Table for user to check or uncheck individual metric for the report.
import React from "react";
import { Checkbox, Form } from "semantic-ui-react";
import styled from "styled-components/macro";

import {
  AACOS_COL,
  ACOS_COL,
  ADD_TO_CART_CLICKS_COL,
  ADD_TO_CART_RATE_COL,
  AVERAGE_CPC_COL,
  BRAND_REFERRAL_BONUS_COL,
  CLICK_THROUGH_RATE_COL,
  CLICKS_COL,
  CONVERSION_RATE_COL,
  CONVERSIONS_COL,
  COST_COL,
  IMPRESSIONS_COL,
  NEW_TO_BRAND_CONVERSIONS_COL,
  NEW_TO_BRAND_REVENUE_COL,
  NEW_TO_BRAND_UNITS_SOLD_COL,
  NEW_TO_BRAND_ROAS_COL,
  REVENUE_COL,
  ROAS_COL,
  UNITS_SOLD_COL,
  COLUMN_TITLES,
  ANNUALIZED_REVENUE_COL,
  PURCHASE_FREQUENCY_COL
} from "../MetricColumns";

export const SUMMARY_REPORT_COLUMNS = [
  IMPRESSIONS_COL,
  CLICKS_COL,
  COST_COL,
  CONVERSIONS_COL,
  REVENUE_COL,
  BRAND_REFERRAL_BONUS_COL,
  AACOS_COL,
  ROAS_COL,
  AVERAGE_CPC_COL
];

export const ALL_REPORT_CAMPAIGN_COLUMNS = [
  IMPRESSIONS_COL,
  CLICKS_COL,
  CLICK_THROUGH_RATE_COL,
  COST_COL,
  AVERAGE_CPC_COL,
  ADD_TO_CART_CLICKS_COL,
  ADD_TO_CART_RATE_COL,
  CONVERSIONS_COL,
  CONVERSION_RATE_COL,
  UNITS_SOLD_COL,
  REVENUE_COL,
  BRAND_REFERRAL_BONUS_COL,
  AACOS_COL,
  ACOS_COL,
  ROAS_COL,
  NEW_TO_BRAND_CONVERSIONS_COL,
  NEW_TO_BRAND_REVENUE_COL,
  NEW_TO_BRAND_UNITS_SOLD_COL,
  NEW_TO_BRAND_ROAS_COL,
  PURCHASE_FREQUENCY_COL,
  ANNUALIZED_REVENUE_COL
];

export const ALL_REPORT_KEYWORD_COLUMNS = [
  IMPRESSIONS_COL,
  CLICKS_COL,
  CLICK_THROUGH_RATE_COL,
  COST_COL,
  AVERAGE_CPC_COL,
  ADD_TO_CART_CLICKS_COL,
  ADD_TO_CART_RATE_COL,
  CONVERSIONS_COL,
  CONVERSION_RATE_COL,
  UNITS_SOLD_COL,
  REVENUE_COL,
  BRAND_REFERRAL_BONUS_COL,
  AACOS_COL,
  ACOS_COL,
  ROAS_COL
];

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5em;
`;

export function MetricSelectorTable(props) {
  const { reportColumns, onToggleColumn } = props;

  return (
    <Form>
      <Form.Field>
        <label>Metrics for Summaries:</label>
        <CheckboxGrid>
          {ALL_REPORT_CAMPAIGN_COLUMNS.map(col => (
            <Checkbox
              key={col}
              label={COLUMN_TITLES[col]}
              checked={reportColumns.has(col)}
              onChange={() => onToggleColumn(col)}
            />
          ))}
        </CheckboxGrid>
      </Form.Field>
    </Form>
  );
}
