import { useEffect, useState } from "react";

const useDocuSignClickWrapAgreement = ({
  onAgreed,
  onDeclined,
  email,
  userName,
  siteAlias,
  showClickWrap,
  clickwrapId
}) => {
  const SOURCE_URL =
    "https://na4.docusign.net/clickapi/sdk/latest/docusign-click.js";

  const [docuSignLoaded, setDocuSignLoaded] = useState(false);

  useEffect(() => {
    const docuSignDiv = document.createElement("div");
    docuSignDiv.id = "ds-clickwrap";
    document.body.appendChild(docuSignDiv);

    const docuSignScript = document.createElement("script");
    docuSignScript.src = SOURCE_URL;
    docuSignScript.onload = () => setDocuSignLoaded(true);
    document.body.appendChild(docuSignScript);

    return () => {
      document.body.removeChild(docuSignScript);
      document.body.removeChild(docuSignDiv);
    };
  }, []);

  useEffect(() => {
    if (!docuSignLoaded || !showClickWrap) {
      return;
    }

    if (!clickwrapId) {
      console.error("missing clickwrap id");
      if (onDeclined) {
        onDeclined();
      }
    }
    window.docuSignClick.Clickwrap.render(
      {
        environment: process.env.REACT_APP_DOCUSIGN_ENV,
        accountId: process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID,
        clickwrapId,
        clientUserId: `${email}-${siteAlias}`,
        documentData: {
          email,
          fullName: userName
        },
        onAgreed: () => {
          if (onAgreed) {
            onAgreed();
          }
        },
        onDeclined: () => {
          if (onDeclined) {
            onDeclined();
          }
        }
      },
      "#ds-clickwrap"
    );
  }, [
    clickwrapId,
    docuSignLoaded,
    email,
    onAgreed,
    onDeclined,
    showClickWrap,
    siteAlias,
    userName
  ]);
};

export default useDocuSignClickWrapAgreement;
