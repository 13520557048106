import React, { useEffect } from "react";
import { useSession } from "ExtensionV2/queries/useSession";
import ErrorBoundary from "Common/components/ErrorBoundary";

const genAmpdDocumentTitle = (siteName, pageName) => {
  const siteNameSection = siteName ? `${siteName} - ` : "";
  const pageNameSection = pageName ? `${pageName} - ` : "";

  return `${siteNameSection}${pageNameSection}Ampd`;
};

// A wrapper for any page related actions that need to happen that aren't related
// to the function of the page. This is a good spot to call page-level trackers for
// GA or HubSpot, set the page title, etc.
const AmpdPage = ({ pageName, children }) => {
  const { currentSite } = useSession();
  const { siteName } = currentSite;

  useEffect(() => {
    window.document.title = genAmpdDocumentTitle(siteName, pageName);
  }, [siteName, pageName]);

  return <ErrorBoundary key={pageName}>{children}</ErrorBoundary>;
};

export default AmpdPage;
