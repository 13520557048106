import moment from "moment";

import { SemanticCOLORS } from "semantic-ui-react";

import { formatMetric } from "Common/utils/metrics";
import {
  GetCampaignBidAutomationResultsReply,
  GetCampaignConfigurationsReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";

import { getCurrencyMetricDef } from "Common/utils/money";
import {
  oneDecimalNumberMetricDef,
  roundedPercentageMetricDef
} from "../MetricColumns";
import {
  AutomationStatus,
  BidAutomationTrainingStatusCode
} from "../../queries/useItemizedCampaignConfiguration";

const TRAINING_BUDGET_TOKEN = "<TRAINING_BUDGET>";
const COMPLETION_DAYS_TOKEN = "<COMPLETION_DAYS>";

export type BidAutomationResults = GetCampaignBidAutomationResultsReply.AsObject;

export type BidAutomationStatusInfo = {
  rawValue: number;
  statusCode: BidAutomationTrainingStatusCode;
  statusColor: SemanticCOLORS;
  statusText: string;
  statusMessage: string;
  trainingProgress: number;
  targetedVoltage: number;
};

export function getBidAutomationStatusInfo(
  ampdCampaignConfiguration: GetCampaignConfigurationsReply.CampaignConfiguration.AsObject | null
): BidAutomationStatusInfo {
  const statusInfo: BidAutomationStatusInfo = {
    rawValue: -1, // This value will be used to sort the table based on status.
    statusCode: BidAutomationTrainingStatusCode.UNSPECIFIED,
    statusColor: "black",
    statusText: "Not turned on",
    statusMessage: "",
    trainingProgress: 0,
    targetedVoltage: 0
  };

  const trainingStatus = ampdCampaignConfiguration?.bidAutomationTrainingStatus;

  const bidAutomationSettings =
    ampdCampaignConfiguration?.settings?.googleAds?.bidAutomationSettings;
  if (bidAutomationSettings?.status !== AutomationStatus.ENABLED) {
    // If the campaign is still being evaluated with dry runs, then we can check
    // if the campaign would be qualified.  If it's not, then let's tell the user.
    // If it is, let's tell the user that, too.
    if (
      bidAutomationSettings?.status === AutomationStatus.DRY_RUN &&
      trainingStatus
    ) {
      if (
        trainingStatus.statusCode ===
        BidAutomationTrainingStatusCode.NOT_QUALIFIED
      ) {
        // Keep the message black.
        statusInfo.statusColor = "black";
        statusInfo.statusText = "Not turned on (unqualified)";
        statusInfo.rawValue = 1;
        statusInfo.statusMessage = trainingStatus.statusMessage;
        return statusInfo;
      } else {
        // Keep the message black.
        statusInfo.statusColor = "black";
        statusInfo.statusText = "Not turned on (but qualified)";
        statusInfo.rawValue = 2;
        return statusInfo;
      }
    }

    return statusInfo;
  }

  let text = "Not started yet";
  let color: SemanticCOLORS = "grey";
  let sortValue = 0;

  // If there is no training status from a previous run, then we haven't started
  // automating this campaign yet.
  if (!trainingStatus) {
    statusInfo.statusColor = color;
    statusInfo.statusText = text;
    statusInfo.rawValue = sortValue;
    return statusInfo;
  }

  const targetVoltage = bidAutomationSettings?.targetVoltage;

  let statusMessage = trainingStatus.statusMessage;

  if (trainingStatus.recommendedMinimumBudget) {
    const currencyCode =
      ampdCampaignConfiguration?.ampdResourceConfiguration?.googleAds
        ?.currencyCode || "USD";
    const costCurrencyMetricDef = getCurrencyMetricDef(currencyCode, true);

    statusMessage = statusMessage.replace(
      TRAINING_BUDGET_TOKEN,
      String(
        formatMetric(
          costCurrencyMetricDef,
          trainingStatus.recommendedMinimumBudget
        )
      )
    );
  }

  if (trainingStatus.estimatedCompletionDate) {
    const diffDays =
      moment(trainingStatus.estimatedCompletionDate).diff(moment(), "days") + 1;

    statusMessage = statusMessage.replace(
      COMPLETION_DAYS_TOKEN,
      String(diffDays)
    );
  }

  switch (trainingStatus.statusCode) {
    // @ts-expect-error - Allow fall-through behavior.
    case BidAutomationTrainingStatusCode.AWAITING_CONFIG:
      // Even if there was no voltage setting during the last run, there may be
      // one now.  If so, show it with the NORMAL message by falling through.
      if (targetVoltage) {
        /* fall-through to next case. */
      } else {
        text = "Fully trained, default priority";
        color = "teal";
        sortValue = 90;
        break;
      }

    // eslint-disable-next-line no-fallthrough
    case BidAutomationTrainingStatusCode.NORMAL: {
      text = "Fully trained";

      // Show the current voltage setting, if set.  Otherwise, show the value from
      // the last run.
      const messageVoltage = targetVoltage || trainingStatus.targetedVoltage;
      if (messageVoltage) {
        let annotation = `setting: ${formatMetric(
          oneDecimalNumberMetricDef,
          messageVoltage
        )}`;
        if (
          trainingStatus.targetedVoltage &&
          trainingStatus.targetedVoltage !== targetVoltage
        ) {
          if (
            trainingStatus.statusCode ===
            BidAutomationTrainingStatusCode.AWAITING_CONFIG
          ) {
            annotation = `${annotation}, was default`;
          } else {
            annotation = `${annotation}, was ${formatMetric(
              oneDecimalNumberMetricDef,
              trainingStatus.targetedVoltage
            )}`;
          }
        }

        text = `${text} (${annotation})`;
      }

      color = "green";
      sortValue = 100 + messageVoltage;
      break;
    }

    case BidAutomationTrainingStatusCode.TRAINING:
      text = `Training (${formatMetric(
        roundedPercentageMetricDef,
        trainingStatus.trainingProgress
      )} complete)`;
      color = "blue";
      sortValue = 50 + trainingStatus.trainingProgress;
      break;

    case BidAutomationTrainingStatusCode.TRAINING_BUDGET_LIMITED:
      text = "Training, but budget limited";
      color = "purple";
      sortValue = 45;
      break;

    case BidAutomationTrainingStatusCode.BUDGET_LIMITED:
      text = "Budget limited";
      color = "yellow";
      sortValue = 40;
      break;

    case BidAutomationTrainingStatusCode.COST_INCREASE:
      text = "Cost has increased";
      color = "yellow";
      sortValue = 30;
      break;

    case BidAutomationTrainingStatusCode.TRAFFIC_DROP:
      text = "Traffic has dropped";
      color = "orange";
      sortValue = 20;
      break;

    case BidAutomationTrainingStatusCode.NOT_QUALIFIED:
      text = "Not qualified for automation";
      color = "red";
      sortValue = 10;
      break;

    default:
    case BidAutomationTrainingStatusCode.UNSPECIFIED:
      break;
  }

  statusInfo.rawValue = sortValue;
  statusInfo.statusCode = trainingStatus.statusCode;
  statusInfo.statusColor = color;
  statusInfo.statusText = text;
  statusInfo.statusMessage = statusMessage;
  statusInfo.trainingProgress = trainingStatus.trainingProgress;
  statusInfo.targetedVoltage = trainingStatus.targetedVoltage;

  return statusInfo;
}
