import React from "react";
import { Message } from "semantic-ui-react";
import { Box, Flex } from "@rebass/grid";

import { EmailSupportButton } from "Common/components/ErrorModal";
import { makeSiteProblemEmailInfo } from "Common/utils/site";
import { useSession } from "ExtensionV2/queries/useSession";

// Renders an error message with help buttons if the site in the props has a
// problem indicating that we can't connect to the user's Google Ads account.
function GoogleAdsConnectionErrorMessage() {
  const { currentSite } = useSession();
  const { siteAlias /* siteErrors */ } = currentSite;

  // TODO: see if we're still using siteErrors (a field in site details.)
  // If we are, update GetDashboardSession to return siteErrors and then use this component
  // to display the siteErrors message.
  // If not, delete this component.
  const googleAdsConnectionError = null;
  if (!googleAdsConnectionError) {
    return null;
  }
  const emailInfo = makeSiteProblemEmailInfo(
    siteAlias,
    googleAdsConnectionError
  );

  return (
    <Message error>
      <Flex flexDirection="column" alignItems="center">
        <p style={{ margin: "1rem" }}>
          We're having trouble connecting to your Google Ads account. Please
          contact us for assistance.
        </p>
        <Flex flexDirection="row">
          <Box m={"1rem"}>
            <EmailSupportButton
              primary
              style={{ whiteSpace: "nowrap" }}
              emailInfo={emailInfo}
            />
          </Box>
        </Flex>
      </Flex>
    </Message>
  );
}

export default GoogleAdsConnectionErrorMessage;
