import { useState } from "react";

import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import {
  UpdateGoogleRsaAction,
  UpdateGoogleRsaRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { UpdateRsaCopyAction } from "Common/proto/googleAdsPb/ad_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAmazonProduct } from "./useAmazonProduct";
import {
  getDescriptionProtos,
  getHeadlineProtos
} from "./useCreateGoogleAdsCampaign";
import { ItemizedCampaignConfiguration } from "./useItemizedCampaignConfiguration";
import { useSessionSite } from "./useSessionSite";

import {
  EditAdCopy,
  getDefaultDescriptions,
  getDefaultHeadlines,
  MAX_DESCRIPTION_COUNT,
  MAX_HEADLINE_COUNT
} from "ExtensionV2/pages/CampaignSetupPage/AdCopyField";
import {
  AdDescription,
  AdHeadline
} from "ExtensionV2/pages/CampaignSetupPage/CampaignSetupPageState";

export const useEditAdCopy = (
  campaignConfiguration: ItemizedCampaignConfiguration
): {
  component: typeof EditAdCopy;
  componentProps: {
    wantsDefaultAdCopy: boolean;
    customHeadlines: Array<AdHeadline>;
    customDescriptions: Array<AdDescription>;
    defaultHeadlines: Array<AdHeadline>;
    defaultDescriptions: Array<AdDescription>;
    onUpdateCustomHeadlines: (headlines: Array<AdHeadline>) => void;
    onUpdateCustomDescriptions: (descriptions: Array<AdDescription>) => void;
    onUpdateWantsDefaultAdCopy: (val: boolean) => void;
  };
  mutation: () => void;
  productDetailsLoading: boolean;
  productDetailsError: unknown;
  componentError: boolean;
  mutationLoading: boolean;
  mutationError: unknown;
  mutationSuccess: boolean;
  mutationReset: () => void;
} => {
  const { siteAlias } = useSessionSite();
  const {
    customerId,
    adId,
    headlines,
    descriptions,
    keywords,
    asin,
    marketplaceInfo
  } = campaignConfiguration;

  const [customHeadlines, setCustomHeadlines] = useState<Array<AdHeadline>>(
    headlines
      .map(h => ({
        _id: window.crypto.randomUUID(),
        text: h.text,
        position: h.pinPosition,
        errors: new Set<string>()
      }))
      // Supply the maximum number of headline input boxes
      .concat(
        new Array(MAX_HEADLINE_COUNT - headlines.length)
          .fill(undefined)
          .map(_h => ({
            _id: window.crypto.randomUUID(),
            text: "",
            position: 0,
            errors: new Set<string>()
          }))
      )
  );
  const [customDescriptions, setCustomDescriptions] = useState<
    Array<AdDescription>
  >(
    descriptions
      .map(d => ({
        _id: window.crypto.randomUUID(),
        text: d.text,
        position: d.pinPosition,
        errors: new Set<string>()
      }))
      // Supply the maximum number of description input boxes
      .concat(
        new Array(MAX_DESCRIPTION_COUNT - descriptions.length)
          .fill(undefined)
          .map(_h => ({
            _id: window.crypto.randomUUID(),
            text: "",
            position: 0,
            errors: new Set<string>()
          }))
      )
  );

  const [wantsDefaultAdCopy, setWantsDefaultAdCopy] = useState(false);

  const {
    mutate: updateAdCopyMutation,
    isLoading: updateAdCopyIsLoading,
    isSuccess: updateAdCopyIsSuccess,
    error: updateAdCopyError,
    reset: resetUpdateAdCopy
  } = useUpdateAdCopyMutation(siteAlias);

  const {
    data: amazonProductData,
    isFetching: amazonProductDataIsLoading,
    error: amazonProductDataError
  } = useAmazonProduct(
    wantsDefaultAdCopy ? siteAlias : "", //disable the query if we're using custom ad copy
    asin,
    marketplaceInfo.marketplace
  );

  const defaultHeadlines = getDefaultHeadlines(
    marketplaceInfo,
    keywords
      .filter(k => k.status === GoogleAdsResourceStatus.Option.ENABLED)
      .map(k => k.text),
    amazonProductData?.product?.rating ?? 0,
    amazonProductData?.product?.ratingsTotal ?? 0
  );

  const defaultDescriptions = getDefaultDescriptions(marketplaceInfo);

  const headlinesError =
    !wantsDefaultAdCopy && customHeadlines.some(h => h.errors.size > 0);

  const descriptionsError =
    !wantsDefaultAdCopy && customDescriptions.some(d => d.errors.size > 0);

  return {
    component: EditAdCopy,
    componentProps: {
      wantsDefaultAdCopy,
      customHeadlines,
      customDescriptions,
      defaultHeadlines,
      defaultDescriptions,
      onUpdateCustomDescriptions: setCustomDescriptions,
      onUpdateCustomHeadlines: setCustomHeadlines,
      onUpdateWantsDefaultAdCopy: setWantsDefaultAdCopy
    },
    mutation: () =>
      updateAdCopyMutation({
        googleAdsCustomerId: String(customerId),
        siteAlias: siteAlias,
        adId: String(adId),
        headlines: wantsDefaultAdCopy ? defaultHeadlines : customHeadlines,
        descriptions: wantsDefaultAdCopy
          ? defaultDescriptions
          : customDescriptions
      }),
    productDetailsLoading: amazonProductDataIsLoading,
    productDetailsError: amazonProductDataError,
    componentError: headlinesError || descriptionsError,
    mutationLoading: updateAdCopyIsLoading,
    mutationError: updateAdCopyError,
    mutationSuccess: updateAdCopyIsSuccess,
    mutationReset: resetUpdateAdCopy
  };
};

const useUpdateAdCopyMutation = (siteAlias: string) => {
  const queryClient = useQueryClient();

  const mutationFn = async ({
    googleAdsCustomerId,
    siteAlias,
    adId,
    headlines,
    descriptions
  }: {
    googleAdsCustomerId: string;
    siteAlias: string;
    adId: string;
    headlines: Array<AdHeadline>;
    descriptions: Array<AdDescription>;
  }) => {
    if (
      !googleAdsCustomerId ||
      !siteAlias ||
      !adId ||
      headlines.length === 0 ||
      descriptions.length === 0
    ) {
      const missingArgs = [
        !googleAdsCustomerId ? "googleAdsCustomerId" : "",
        !siteAlias ? "siteAlias" : "",
        !adId ? "adId" : "",
        headlines.length === 0 ? "headlines" : "",
        descriptions.length === 0 ? "descriptions" : ""
      ]
        .filter(Boolean)
        .join(", ");

      throw Error(`Missing or empty required parameter: ${missingArgs}`);
    }

    const nonEmptyHeadlines = headlines.filter(h => h.text.trim() !== "");
    const nonEmptyDescriptions = descriptions.filter(d => d.text.trim() !== "");
    const headlineProtos = getHeadlineProtos(nonEmptyHeadlines);
    const descriptionProtos = getDescriptionProtos(nonEmptyDescriptions);

    const updateAdCopyAction = new UpdateRsaCopyAction();
    updateAdCopyAction.setHeadlinesList(headlineProtos);
    updateAdCopyAction.setDescriptionsList(descriptionProtos);
    updateAdCopyAction.setAdId(adId);

    const updateRSAAction = new UpdateGoogleRsaAction();
    updateRSAAction.setUpdateCopy(updateAdCopyAction);

    const request = new UpdateGoogleRsaRequest();
    request.setSiteAlias(siteAlias);
    request.setCustomerId(googleAdsCustomerId);
    request.setAdId(adId);
    request.setActionsList([updateRSAAction]);

    const reply = await GRPCWebClient.updateGoogleRsa(request, {});

    return reply.toObject();
  };

  const onSuccess = () => {
    return queryClient.invalidateQueries({
      queryKey: ["campaignConfigurations", siteAlias]
    });
  };

  const onError = (error: Error) => {
    console.error(error);
  };

  return useMutation({
    mutationFn,
    onSuccess,
    onError
  });
};
