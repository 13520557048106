import _ from "lodash";
import { formatMetric } from "Common/utils/metrics";

// Stripe passes currencies in cents, needing division by 100, EXCEPT for
// currencies that they call "Zero-decimal" currencies that are used raw
// https://stripe.com/docs/currencies#zero-decimal
const STRIPE_ZERO_DECIMAL_CURRENCIES = new Set([
  "BIF",
  "CLP",
  "DJF",
  "GNF",
  "JPY",
  "KMF",
  "KRW",
  "MGA",
  "PYG",
  "RWF",
  "UGX",
  "VND",
  "VUV",
  "XAF",
  "XOF",
  "XPF"
]);
const UNKNOWN_AMOUNT = "Unknown";

// Custom formatting definition for use with Stripe currencies
const makeStripeCurrencyMetricsDef = currency => {
  return {
    name: "stripe",
    commonName: "stripe",
    // If no currency, use standard number & avoid possibly-wrong USD fallback
    isCurrency: !!currency,
    currencyCode: currency?.toUpperCase(),
    formatting: {
      precision: 2,
      currencyUseMinorUnits: true
    },
    nanString: UNKNOWN_AMOUNT
  };
};

// https://stripe.com/docs/currencies
export const formatStripeCurrency = (amountInput, currency) => {
  if (amountInput === null || amountInput === undefined) return UNKNOWN_AMOUNT;

  // Verify amount is a valid number
  const amount = Number(amountInput);
  if (_.isNaN(amount)) return UNKNOWN_AMOUNT;

  // Adjust to correct decimal offset.
  // Example: 1200
  //  - Stripe "zero-decimal" cases: 1200
  //  - All others: 12.00
  const value = STRIPE_ZERO_DECIMAL_CURRENCIES.has(currency?.toUpperCase())
    ? amount
    : (amount / 100).toFixed(2);

  const formattedValue = formatMetric(
    makeStripeCurrencyMetricsDef(currency),
    value
  );
  return String(formattedValue);
};
