import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-apollo";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { navigateToPage } from "../../util";
import { needsAmpdPayment } from "../../../Common/utils/ampdPayment";
import { paymentPath } from "../../ExtensionV2";
import { sendGAEvent, sendGTMEvent } from "../GA";
import { SET_FACEBOOK_CONVERSION_MUTATION } from "Subscription/graphql";
import FadeoutTopBanner from "../FadeoutTopBanner";
import refreshBillingAccount from "../../grpc/refreshBillingAccount";
import { useSession } from "ExtensionV2/queries/useSession";
import {
  OFFER_PARAM,
  SUBSCRIPTION_ID_PARAM
} from "ExtensionV2/pages/PaymentPage/PaymentPage";

export const STRIPE_REDIRECT_STATUS_KEY = "redirect_status";
export const STRIPE_REDIRECT_SUCCESS_VALUE = "succeeded";
const STRIPE_CLIENT_SECRET_KEY = "payment_intent_client_secret";

// StripeRedirectWrapper Checks for Stripe redirect params in the url. If does not find the
// "redirect_status" param with value of "succeeded", it simply renders its children and does
// nothing else.

// If it finds redirect_status=succeeded, it attempts to finalize the payment intent with stripe.
// If the payment is not finalized it redirects to the payment page, otherwise it renders its
// children. It both cases it displays a banner informing the user of the payment status.
const StripeRedirectWrapper = ({ children }) => {
  const { forceActivateBilling, currentSite, user } = useSession();
  const { siteAlias, billingAccountStatus, subscription } = currentSite || {};
  const { userEmail } = user || {};

  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const [setFacebookConversionMutation] = useMutation(
    SET_FACEBOOK_CONVERSION_MUTATION
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stripeRedirectStatus = searchParams.get(STRIPE_REDIRECT_STATUS_KEY);
    const successfulRedirect =
      stripeRedirectStatus === STRIPE_REDIRECT_SUCCESS_VALUE;

    let paymentError;
    if (successfulRedirect) {
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
      let clientSecret = searchParams.get(STRIPE_CLIENT_SECRET_KEY);

      if (clientSecret == null) {
        paymentError =
          "Error with Stripe redirect. Please contact us for assistance. Error code missing_secret";

        console.error("Stripe Redirect: Missing Client Secret");

        navigateToPage({
          history,
          location,
          siteAlias,
          toPage: paymentPath,
          params: {
            errorMessage: paymentError
          }
        });
      } else {
        stripePromise
          .then(stripe => stripe.retrievePaymentIntent(clientSecret))
          .then(({ paymentIntent }) => {
            // Some payment methods will immediately succeed or fail upon
            // confirmation, while others will first enter a `processing` state.
            // https://stripe.com/docs/payments/payment-methods#payment-notification

            // You can find more info on testing the various intent statuses here:
            // https://stripe.com/docs/billing/subscriptions/build-subscriptions?ui=elements#test
            switch (paymentIntent.status) {
              case "succeeded":
                setDisplaySuccessMessage(true);

                // Send to the affiliate tracking software
                // window.fpr is set up in Google Tag Manager as an async script
                if (
                  userEmail &&
                  window.fpr &&
                  typeof window.fpr === "function"
                ) {
                  window.fpr("referral", { email: userEmail });
                }

                // Send to Google Analytics
                // Category is Ampd: Payment Page because we used to track this event on
                // the payment page and we want to maintain consistency.
                sendGAEvent(
                  "Ampd: Payment Page",
                  "Paid: " + subscription?.id,
                  siteAlias
                );

                // example: planValue of monthly plan id: mo_plan_497 is 497.
                sendGTMEvent({
                  event: "sign-up",
                  plan: subscription?.id,
                  planValue: subscription?.centsPerBillingInterval,
                  page: "/payment/success",
                  alias: siteAlias
                });

                setFacebookConversionMutation({
                  variables: {
                    email: userEmail ?? "",
                    value: subscription?.centsPerBillingInterval ?? 0
                  }
                });

                break;
              case "processing":
                console.error(`Stripe Redirect: processing`);
                paymentError =
                  "Your payment is still processing. Please wait a few moments then try visiting https://app.ampd.io/ again.";
                break;
              case "requires_payment_method":
                console.error(`Stripe Redirect: requires_payment_method`);
                paymentError =
                  "Your payment failed. Please try another payment method or contact us if you continue to see this error.";
                break;
              default:
                console.error(
                  `Stripe Redirect: unknown status ${paymentIntent.status}`
                );
                paymentError =
                  "Error with Stripe redirect. Please contact us for assistance. Error code unknown_status";
                break;
            }

            if (paymentError != null) {
              navigateToPage({
                history,
                location,
                siteAlias,
                toPage: paymentPath,
                params: {
                  errorMessage: paymentError
                }
              });
            }
          })
          .then(() => {
            // update the local state's billing details because we have confirmation from Stripe
            // the payment was successful.
            forceActivateBilling();

            // remove any pricing options that may have been set from a custom signup link
            localStorage.removeItem(SUBSCRIPTION_ID_PARAM);
            localStorage.removeItem(OFFER_PARAM);

            // Force a server-side refresh with Stripe, this is just a defensive measure in case
            // something is wrong with the webhook handling.
            return refreshBillingAccount(siteAlias);
          })
          .then(() => {
            history.replace({
              search: ""
            });
          });
      }
    } else if (
      stripeRedirectStatus != null &&
      needsAmpdPayment(billingAccountStatus)
    ) {
      console.error(
        `Stripe Redirect: Unexpected redirect status: ${stripeRedirectStatus}`
      );
      navigateToPage({
        history,
        location,
        siteAlias,
        toPage: paymentPath
      });
    }
  }, [
    userEmail,
    forceActivateBilling,
    history,
    location,
    setFacebookConversionMutation,
    siteAlias,
    billingAccountStatus,
    subscription?.id,
    subscription?.centsPerBillingInterval
  ]);

  return (
    <>
      {displaySuccessMessage && (
        <FadeoutTopBanner message="Your payment has successfully been processed" />
      )}
      {children}
    </>
  );
};

export default StripeRedirectWrapper;
