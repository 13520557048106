import _ from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownProps, DropdownItemProps } from "semantic-ui-react";

import { getCurrencySymbol } from "Common/utils/googleAds";
import { getHumanReadableAmount } from "Common/utils/money";

// Displays a dropdown for people to choose a cost value.
const ValueWithSuggestionsDropdown: React.FC<React.PropsWithChildren<{
  title: string;
  disabled?: boolean;
  currency?: string;
  isPercentage?: boolean;
  upward?: boolean;

  value: number | null;
  setValue: (val: number | null) => void;
  initialValueList: Array<number>;
  recommendedValue: number;
  allowZero?: boolean;
}>> = ({
  title,
  disabled,
  currency,
  isPercentage,
  upward,

  value,
  setValue,
  initialValueList,
  recommendedValue,
  allowZero,

  children
}) => {
  /*
    provide some default options to make setting this value simpler for users
  */
  const [editValueList, setEditValueList] = useState<Array<number>>([]);

  useEffect(() => {
    setEditValueList(initialValueList);
  }, [initialValueList]);

  useEffect(() => {
    // A value of null means show the placeholder text so the user enters a value,
    // so don't replace it.
    // A negative value is invalid and should be replaced with the
    // recommended value.
    if (_.isNumber(value) && (value < 0 || (value === 0 && !allowZero))) {
      setValue(recommendedValue);
    }
  }, [value, recommendedValue, setValue, allowZero]);

  /*
    build the dropdown options
  */
  const dropdownOptions: Array<DropdownItemProps> = useMemo(() => {
    const dropdownOptions: Array<DropdownItemProps> = [];

    editValueList.forEach(value => {
      if (value < 0) {
        return;
      }

      let displayValue = `${value}`;
      if (currency) {
        displayValue = String(getHumanReadableAmount(value * 1e6, currency));
      } else if (isPercentage) {
        displayValue = `${value} %`;
      }

      dropdownOptions.push({
        key: value,
        text: displayValue,
        description: value === recommendedValue ? "Recommended" : "",
        value: value
      });
    });

    dropdownOptions.push(<Dropdown.Divider key="divider-1" />, {
      key: "new-amount-placeholder",
      text: "Enter a different amount...",
      value: undefined
    });

    return dropdownOptions;
  }, [editValueList, currency, isPercentage, recommendedValue]);

  if (value != null && !editValueList.includes(value)) {
    const newValueList = [...editValueList, value];
    newValueList.sort((a, b) => a - b);
    setEditValueList(newValueList);
  }

  const _handleSelection = (optionsProps: DropdownProps) => {
    const { value } = optionsProps;

    if (value == null) {
      setValue(null);
      return;
    }

    setValue(Number(value));
  };

  const handleSelection = (
    _ev: React.SyntheticEvent<HTMLElement>,
    optionsProps: DropdownProps
  ) => {
    _handleSelection(optionsProps);
  };

  const handleAddItem = (
    _ev: React.SyntheticEvent<HTMLElement>,
    optionsProps: DropdownProps
  ) => {
    _handleSelection(optionsProps);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: 10,
          alignItems: "center"
        }}
      >
        <strong>{title}:</strong>
        <Dropdown
          style={{ minWidth: 300 }}
          disabled={disabled}
          allowAdditions={true}
          additionLabel={`${getCurrencySymbol(currency)} `}
          options={[...dropdownOptions]}
          value={value ?? undefined}
          placeholder={"Enter a value"}
          selection
          search={true}
          upward={upward}
          onAddItem={handleAddItem}
          onChange={handleSelection}
        />
        {children}
      </div>
    </>
  );
};

export default ValueWithSuggestionsDropdown;
