import React from "react";
import { Image } from "semantic-ui-react";
import useLoginWithAmazon, {
  getAmazonRegionCode
} from "../hooks/useLoginWithAmazon";

// Button for triggering the LWA login flow.
//
// Props:
// - scope: Required. The scope for the token to request. Multiple scopes may be
//   space separated.
//
// - state: Required. Client state that is mirrored back in the response. This
//   is also used for anti-CSRF forgery.
//
// - onAuthorized: Required. func(response) invoked after the user has been
//   authorized.
//
// - region: Optional. One of the following
//   - amazon.Login.Region.NorthAmerica (default)
//   - amazon.Login.Region.Europe
//   - amazon.Login.Region.AsiaPacific
//
// - src: Optional. Source for the image button.
//   See https://developer.amazon.com/docs/login-with-amazon/button.html#website-images.
export default function LoginWithAmazonButton(props) {
  const { scope, state, onAuthorized, region, src } = props;
  const amazonReady = useLoginWithAmazon();

  return (
    <Image
      style={{ cursor: "pointer" }}
      disabled={!amazonReady}
      src={
        src ||
        "https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_195x46.png"
      }
      onClick={e => {
        e.stopPropagation();

        if (!scope) {
          throw new Error("missing scope");
        }

        if (!state) {
          throw new Error("missing state");
        }

        const options = {
          interactive: "always",
          scope,
          response_type: "code",
          state
        };

        // eslint-disable-next-line no-undef
        amazon.Login.setClientId(process.env.REACT_APP_AMAZON_LWA_CLIENT_ID);

        // eslint-disable-next-line no-undef
        amazon.Login.setRegion(
          region || getAmazonRegionCode(window.amazon.Login.Region.NorthAmerica)
        );

        // eslint-disable-next-line no-undef
        amazon.Login.authorize(options, onAuthorized);

        return false;
      }}
    />
  );
}
