// source: proto/entity/site.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var proto_common_amazon_pb = require('../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
var proto_common_facebook_pb = require('../../proto/common/facebook_pb.js');
goog.object.extend(proto, proto_common_facebook_pb);
var proto_common_crudFields_pb = require('../../proto/common/crudFields_pb.js');
goog.object.extend(proto, proto_common_crudFields_pb);
var proto_common_dataSourceInfo_pb = require('../../proto/common/dataSourceInfo_pb.js');
goog.object.extend(proto, proto_common_dataSourceInfo_pb);
var proto_common_objectID_pb = require('../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
var proto_common_utmParameters_pb = require('../../proto/common/utmParameters_pb.js');
goog.object.extend(proto, proto_common_utmParameters_pb);
var proto_common_walmart_pb = require('../../proto/common/walmart_pb.js');
goog.object.extend(proto, proto_common_walmart_pb);
var proto_entity_billingAccount_pb = require('../../proto/entity/billingAccount_pb.js');
goog.object.extend(proto, proto_entity_billingAccount_pb);
goog.exportSymbol('proto.entity.DataSourceLinks', null, global);
goog.exportSymbol('proto.entity.DomainError', null, global);
goog.exportSymbol('proto.entity.DomainError.Type', null, global);
goog.exportSymbol('proto.entity.DomainError.Type.Option', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.OrganizationType', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.OrganizationType.Option', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.BigCommerce', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.Direct', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.Direct.Origin', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.Direct.Origin.Option', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.JumpFly', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.LogicalPosition', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.Shopify', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.TypeCase', null, global);
goog.exportSymbol('proto.entity.InternalSiteDetails.Source.WalmartJBP', null, global);
goog.exportSymbol('proto.entity.NewSite', null, global);
goog.exportSymbol('proto.entity.QualificationInfo', null, global);
goog.exportSymbol('proto.entity.QualificationInfo.BigCommerce', null, global);
goog.exportSymbol('proto.entity.QualificationInfo.GoogleAds', null, global);
goog.exportSymbol('proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo', null, global);
goog.exportSymbol('proto.entity.QualificationInfo.Shopify', null, global);
goog.exportSymbol('proto.entity.ReportPublication', null, global);
goog.exportSymbol('proto.entity.ReportPublication.AmazonS3Bucket', null, global);
goog.exportSymbol('proto.entity.ReportPublication.AzureBlobStorageContainer', null, global);
goog.exportSymbol('proto.entity.ReportPublication.Destination', null, global);
goog.exportSymbol('proto.entity.ReportPublication.Destination.TypeCase', null, global);
goog.exportSymbol('proto.entity.ReportPublication.ReportType', null, global);
goog.exportSymbol('proto.entity.ReportPublication.ReportType.Option', null, global);
goog.exportSymbol('proto.entity.Site', null, global);
goog.exportSymbol('proto.entity.Site.OrganizationDetails', null, global);
goog.exportSymbol('proto.entity.Site.References', null, global);
goog.exportSymbol('proto.entity.SiteDetails', null, global);
goog.exportSymbol('proto.entity.SiteDetails.AmpdAdsSubscription', null, global);
goog.exportSymbol('proto.entity.SiteDetails.AmpdAdsSubscription.Stripe', null, global);
goog.exportSymbol('proto.entity.SiteDetails.BrandInfo', null, global);
goog.exportSymbol('proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings', null, global);
goog.exportSymbol('proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings', null, global);
goog.exportSymbol('proto.entity.SiteDetails.CreatedGoogleAdsAccount', null, global);
goog.exportSymbol('proto.entity.SiteDetails.GoogleAdsAudits', null, global);
goog.exportSymbol('proto.entity.SiteDetails.SalesPlatforms', null, global);
goog.exportSymbol('proto.entity.SiteDetails.UIBehavior', null, global);
goog.exportSymbol('proto.entity.SiteError', null, global);
goog.exportSymbol('proto.entity.SiteError.ErrorCase', null, global);
goog.exportSymbol('proto.entity.SiteProblem', null, global);
goog.exportSymbol('proto.entity.SiteSelector', null, global);
goog.exportSymbol('proto.entity.SiteSelector.SelectorCase', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.ActionCase', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddAdWordsAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddApplicationAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.AddSiteProblemAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetAttributionAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetBillingAccountIDAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetDeletedAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetFacebookAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetNameAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetSiteDetailsAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetSiteDomainAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetTargetROASAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.SetUserFeedbackAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateBrandInfoAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateFeatureMaskAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateReportPublicationsAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateShopifyInfoAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateSiteProblemsAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateUIBehaviorAction', null, global);
goog.exportSymbol('proto.entity.UpdateSiteAction.UpdateWalmartProfileAction', null, global);
goog.exportSymbol('proto.entity.UserFeedback', null, global);
goog.exportSymbol('proto.entity.UserFeedback.Status', null, global);
goog.exportSymbol('proto.entity.UserFeedback.Status.Option', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteSelector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.SiteSelector.oneofGroups_);
};
goog.inherits(proto.entity.SiteSelector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteSelector.displayName = 'proto.entity.SiteSelector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.NewSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.NewSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.NewSite.displayName = 'proto.entity.NewSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Site.displayName = 'proto.entity.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Site.References = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Site.References, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Site.References.displayName = 'proto.entity.Site.References';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Site.OrganizationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Site.OrganizationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Site.OrganizationDetails.displayName = 'proto.entity.Site.OrganizationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UserFeedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UserFeedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UserFeedback.displayName = 'proto.entity.UserFeedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UserFeedback.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UserFeedback.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UserFeedback.Status.displayName = 'proto.entity.UserFeedback.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.SiteDetails.repeatedFields_, null);
};
goog.inherits(proto.entity.SiteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.displayName = 'proto.entity.SiteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.GoogleAdsAudits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.GoogleAdsAudits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.GoogleAdsAudits.displayName = 'proto.entity.SiteDetails.GoogleAdsAudits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.CreatedGoogleAdsAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.CreatedGoogleAdsAccount.displayName = 'proto.entity.SiteDetails.CreatedGoogleAdsAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.AmpdAdsSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.AmpdAdsSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.AmpdAdsSubscription.displayName = 'proto.entity.SiteDetails.AmpdAdsSubscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.AmpdAdsSubscription.Stripe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.displayName = 'proto.entity.SiteDetails.AmpdAdsSubscription.Stripe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.SalesPlatforms = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.SalesPlatforms, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.SalesPlatforms.displayName = 'proto.entity.SiteDetails.SalesPlatforms';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.BrandInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.BrandInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.BrandInfo.displayName = 'proto.entity.SiteDetails.BrandInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.displayName = 'proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.displayName = 'proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteDetails.UIBehavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteDetails.UIBehavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteDetails.UIBehavior.displayName = 'proto.entity.SiteDetails.UIBehavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.QualificationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.QualificationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.QualificationInfo.displayName = 'proto.entity.QualificationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.QualificationInfo.BigCommerce = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.QualificationInfo.BigCommerce, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.QualificationInfo.BigCommerce.displayName = 'proto.entity.QualificationInfo.BigCommerce';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.QualificationInfo.Shopify = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.QualificationInfo.Shopify, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.QualificationInfo.Shopify.displayName = 'proto.entity.QualificationInfo.Shopify';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.QualificationInfo.GoogleAds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.QualificationInfo.GoogleAds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.QualificationInfo.GoogleAds.displayName = 'proto.entity.QualificationInfo.GoogleAds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.displayName = 'proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.InternalSiteDetails.repeatedFields_, null);
};
goog.inherits(proto.entity.InternalSiteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.displayName = 'proto.entity.InternalSiteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.OrganizationType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.OrganizationType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.OrganizationType.displayName = 'proto.entity.InternalSiteDetails.OrganizationType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.InternalSiteDetails.Source.oneofGroups_);
};
goog.inherits(proto.entity.InternalSiteDetails.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.displayName = 'proto.entity.InternalSiteDetails.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.Shopify = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.Shopify, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.Shopify.displayName = 'proto.entity.InternalSiteDetails.Source.Shopify';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.BigCommerce = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.BigCommerce, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.BigCommerce.displayName = 'proto.entity.InternalSiteDetails.Source.BigCommerce';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.LogicalPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.LogicalPosition.displayName = 'proto.entity.InternalSiteDetails.Source.LogicalPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.JumpFly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.JumpFly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.JumpFly.displayName = 'proto.entity.InternalSiteDetails.Source.JumpFly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.WalmartJBP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.WalmartJBP.displayName = 'proto.entity.InternalSiteDetails.Source.WalmartJBP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.Direct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.Direct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.Direct.displayName = 'proto.entity.InternalSiteDetails.Source.Direct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalSiteDetails.Source.Direct.Origin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalSiteDetails.Source.Direct.Origin.displayName = 'proto.entity.InternalSiteDetails.Source.Direct.Origin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.DataSourceLinks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.DataSourceLinks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.DataSourceLinks.displayName = 'proto.entity.DataSourceLinks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ReportPublication = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.ReportPublication.repeatedFields_, null);
};
goog.inherits(proto.entity.ReportPublication, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ReportPublication.displayName = 'proto.entity.ReportPublication';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ReportPublication.ReportType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ReportPublication.ReportType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ReportPublication.ReportType.displayName = 'proto.entity.ReportPublication.ReportType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ReportPublication.Destination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.ReportPublication.Destination.oneofGroups_);
};
goog.inherits(proto.entity.ReportPublication.Destination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ReportPublication.Destination.displayName = 'proto.entity.ReportPublication.Destination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ReportPublication.AmazonS3Bucket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ReportPublication.AmazonS3Bucket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ReportPublication.AmazonS3Bucket.displayName = 'proto.entity.ReportPublication.AmazonS3Bucket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ReportPublication.AzureBlobStorageContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ReportPublication.AzureBlobStorageContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ReportPublication.AzureBlobStorageContainer.displayName = 'proto.entity.ReportPublication.AzureBlobStorageContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UpdateSiteAction.oneofGroups_);
};
goog.inherits(proto.entity.UpdateSiteAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.displayName = 'proto.entity.UpdateSiteAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetNameAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetNameAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetNameAction.displayName = 'proto.entity.UpdateSiteAction.SetNameAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetSiteDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetSiteDetailsAction.displayName = 'proto.entity.UpdateSiteAction.SetSiteDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.displayName = 'proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.repeatedFields_, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateFeatureMaskAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.displayName = 'proto.entity.UpdateSiteAction.UpdateFeatureMaskAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetDeletedAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetDeletedAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetDeletedAction.displayName = 'proto.entity.UpdateSiteAction.SetDeletedAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddAdWordsAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddAdWordsAccountAction.displayName = 'proto.entity.UpdateSiteAction.AddAdWordsAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.displayName = 'proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetTargetROASAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetTargetROASAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetTargetROASAction.displayName = 'proto.entity.UpdateSiteAction.SetTargetROASAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.displayName = 'proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetBillingAccountIDAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetBillingAccountIDAction.displayName = 'proto.entity.UpdateSiteAction.SetBillingAccountIDAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateShopifyInfoAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.displayName = 'proto.entity.UpdateSiteAction.UpdateShopifyInfoAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.displayName = 'proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddSiteProblemAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddSiteProblemAction.displayName = 'proto.entity.UpdateSiteAction.AddSiteProblemAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetUserFeedbackAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetUserFeedbackAction.displayName = 'proto.entity.UpdateSiteAction.SetUserFeedbackAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.repeatedFields_, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateSiteProblemsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.displayName = 'proto.entity.UpdateSiteAction.UpdateSiteProblemsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetSiteDomainAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetSiteDomainAction.displayName = 'proto.entity.UpdateSiteAction.SetSiteDomainAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddApplicationAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddApplicationAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddApplicationAction.displayName = 'proto.entity.UpdateSiteAction.AddApplicationAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetAttributionAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetAttributionAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetAttributionAction.displayName = 'proto.entity.UpdateSiteAction.SetAttributionAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.displayName = 'proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.displayName = 'proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.displayName = 'proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.displayName = 'proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.displayName = 'proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.SetFacebookAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.SetFacebookAccountAction.displayName = 'proto.entity.UpdateSiteAction.SetFacebookAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.displayName = 'proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.repeatedFields_, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateWalmartProfileAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.displayName = 'proto.entity.UpdateSiteAction.UpdateWalmartProfileAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateBrandInfoAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateBrandInfoAction.displayName = 'proto.entity.UpdateSiteAction.UpdateBrandInfoAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.repeatedFields_, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateReportPublicationsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.displayName = 'proto.entity.UpdateSiteAction.UpdateReportPublicationsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.displayName = 'proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.displayName = 'proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.displayName = 'proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.displayName = 'proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.displayName = 'proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.displayName = 'proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateSiteAction.UpdateUIBehaviorAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.displayName = 'proto.entity.UpdateSiteAction.UpdateUIBehaviorAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteProblem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteProblem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteProblem.displayName = 'proto.entity.SiteProblem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.SiteError.oneofGroups_);
};
goog.inherits(proto.entity.SiteError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteError.displayName = 'proto.entity.SiteError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.DomainError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.DomainError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.DomainError.displayName = 'proto.entity.DomainError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.DomainError.Type = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.DomainError.Type, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.DomainError.Type.displayName = 'proto.entity.DomainError.Type';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.SiteSelector.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.entity.SiteSelector.SelectorCase = {
  SELECTOR_NOT_SET: 0,
  SITE_ID: 1,
  SITE_ALIAS: 2
};

/**
 * @return {proto.entity.SiteSelector.SelectorCase}
 */
proto.entity.SiteSelector.prototype.getSelectorCase = function() {
  return /** @type {proto.entity.SiteSelector.SelectorCase} */(jspb.Message.computeOneofCase(this, proto.entity.SiteSelector.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteSelector.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteSelector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteSelector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteSelector.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: (f = msg.getSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    siteAlias: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteSelector}
 */
proto.entity.SiteSelector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteSelector;
  return proto.entity.SiteSelector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteSelector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteSelector}
 */
proto.entity.SiteSelector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteSelector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteSelector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteSelector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteSelector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional common.ObjectID site_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteSelector.prototype.getSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteSelector} returns this
*/
proto.entity.SiteSelector.prototype.setSiteId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.SiteSelector.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteSelector} returns this
 */
proto.entity.SiteSelector.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteSelector.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string site_alias = 2;
 * @return {string}
 */
proto.entity.SiteSelector.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteSelector} returns this
 */
proto.entity.SiteSelector.prototype.setSiteAlias = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.entity.SiteSelector.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.entity.SiteSelector} returns this
 */
proto.entity.SiteSelector.prototype.clearSiteAlias = function() {
  return jspb.Message.setOneofField(this, 2, proto.entity.SiteSelector.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteSelector.prototype.hasSiteAlias = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.NewSite.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.NewSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.NewSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteDetails: (f = msg.getSiteDetails()) && proto.entity.SiteDetails.toObject(includeInstance, f),
    internalDetails: (f = msg.getInternalDetails()) && proto.entity.InternalSiteDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.NewSite}
 */
proto.entity.NewSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.NewSite;
  return proto.entity.NewSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.NewSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.NewSite}
 */
proto.entity.NewSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 3:
      var value = new proto.entity.SiteDetails;
      reader.readMessage(value,proto.entity.SiteDetails.deserializeBinaryFromReader);
      msg.setSiteDetails(value);
      break;
    case 4:
      var value = new proto.entity.InternalSiteDetails;
      reader.readMessage(value,proto.entity.InternalSiteDetails.deserializeBinaryFromReader);
      msg.setInternalDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.NewSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.NewSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.NewSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.SiteDetails.serializeBinaryToWriter
    );
  }
  f = message.getInternalDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.InternalSiteDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.NewSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.NewSite} returns this
 */
proto.entity.NewSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_alias = 2;
 * @return {string}
 */
proto.entity.NewSite.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.NewSite} returns this
 */
proto.entity.NewSite.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SiteDetails site_details = 3;
 * @return {?proto.entity.SiteDetails}
 */
proto.entity.NewSite.prototype.getSiteDetails = function() {
  return /** @type{?proto.entity.SiteDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails, 3));
};


/**
 * @param {?proto.entity.SiteDetails|undefined} value
 * @return {!proto.entity.NewSite} returns this
*/
proto.entity.NewSite.prototype.setSiteDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewSite} returns this
 */
proto.entity.NewSite.prototype.clearSiteDetails = function() {
  return this.setSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewSite.prototype.hasSiteDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional InternalSiteDetails internal_details = 4;
 * @return {?proto.entity.InternalSiteDetails}
 */
proto.entity.NewSite.prototype.getInternalDetails = function() {
  return /** @type{?proto.entity.InternalSiteDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails, 4));
};


/**
 * @param {?proto.entity.InternalSiteDetails|undefined} value
 * @return {!proto.entity.NewSite} returns this
*/
proto.entity.NewSite.prototype.setInternalDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewSite} returns this
 */
proto.entity.NewSite.prototype.clearInternalDetails = function() {
  return this.setInternalDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewSite.prototype.hasInternalDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: (f = msg.getSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteAlias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteDetails: (f = msg.getSiteDetails()) && proto.entity.SiteDetails.toObject(includeInstance, f),
    internalSiteDetails: (f = msg.getInternalSiteDetails()) && proto.entity.InternalSiteDetails.toObject(includeInstance, f),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f),
    references: (f = msg.getReferences()) && proto.entity.Site.References.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Site}
 */
proto.entity.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Site;
  return proto.entity.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Site}
 */
proto.entity.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 4:
      var value = new proto.entity.SiteDetails;
      reader.readMessage(value,proto.entity.SiteDetails.deserializeBinaryFromReader);
      msg.setSiteDetails(value);
      break;
    case 6:
      var value = new proto.entity.InternalSiteDetails;
      reader.readMessage(value,proto.entity.InternalSiteDetails.deserializeBinaryFromReader);
      msg.setInternalSiteDetails(value);
      break;
    case 5:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    case 7:
      var value = new proto.entity.Site.References;
      reader.readMessage(value,proto.entity.Site.References.deserializeBinaryFromReader);
      msg.setReferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.SiteDetails.serializeBinaryToWriter
    );
  }
  f = message.getInternalSiteDetails();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.InternalSiteDetails.serializeBinaryToWriter
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
  f = message.getReferences();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.Site.References.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Site.References.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Site.References.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Site.References} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Site.References.toObject = function(includeInstance, msg) {
  var f, obj = {
    billingAccount: (f = msg.getBillingAccount()) && proto_entity_billingAccount_pb.BillingAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Site.References}
 */
proto.entity.Site.References.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Site.References;
  return proto.entity.Site.References.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Site.References} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Site.References}
 */
proto.entity.Site.References.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_billingAccount_pb.BillingAccount;
      reader.readMessage(value,proto_entity_billingAccount_pb.BillingAccount.deserializeBinaryFromReader);
      msg.setBillingAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Site.References.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Site.References.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Site.References} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Site.References.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillingAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_billingAccount_pb.BillingAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingAccount billing_account = 1;
 * @return {?proto.entity.BillingAccount}
 */
proto.entity.Site.References.prototype.getBillingAccount = function() {
  return /** @type{?proto.entity.BillingAccount} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.BillingAccount, 1));
};


/**
 * @param {?proto.entity.BillingAccount|undefined} value
 * @return {!proto.entity.Site.References} returns this
*/
proto.entity.Site.References.prototype.setBillingAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site.References} returns this
 */
proto.entity.Site.References.prototype.clearBillingAccount = function() {
  return this.setBillingAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.References.prototype.hasBillingAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Site.OrganizationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Site.OrganizationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Site.OrganizationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Site.OrganizationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: (f = msg.getOrganizationId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Site.OrganizationDetails}
 */
proto.entity.Site.OrganizationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Site.OrganizationDetails;
  return proto.entity.Site.OrganizationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Site.OrganizationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Site.OrganizationDetails}
 */
proto.entity.Site.OrganizationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Site.OrganizationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Site.OrganizationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Site.OrganizationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Site.OrganizationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID organization_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Site.OrganizationDetails.prototype.getOrganizationId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Site.OrganizationDetails} returns this
*/
proto.entity.Site.OrganizationDetails.prototype.setOrganizationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site.OrganizationDetails} returns this
 */
proto.entity.Site.OrganizationDetails.prototype.clearOrganizationId = function() {
  return this.setOrganizationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.OrganizationDetails.prototype.hasOrganizationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ObjectID site_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.Site.prototype.getSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.Site} returns this
*/
proto.entity.Site.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.Site.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_alias = 3;
 * @return {string}
 */
proto.entity.Site.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SiteDetails site_details = 4;
 * @return {?proto.entity.SiteDetails}
 */
proto.entity.Site.prototype.getSiteDetails = function() {
  return /** @type{?proto.entity.SiteDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails, 4));
};


/**
 * @param {?proto.entity.SiteDetails|undefined} value
 * @return {!proto.entity.Site} returns this
*/
proto.entity.Site.prototype.setSiteDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.clearSiteDetails = function() {
  return this.setSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.prototype.hasSiteDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional InternalSiteDetails internal_site_details = 6;
 * @return {?proto.entity.InternalSiteDetails}
 */
proto.entity.Site.prototype.getInternalSiteDetails = function() {
  return /** @type{?proto.entity.InternalSiteDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails, 6));
};


/**
 * @param {?proto.entity.InternalSiteDetails|undefined} value
 * @return {!proto.entity.Site} returns this
*/
proto.entity.Site.prototype.setInternalSiteDetails = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.clearInternalSiteDetails = function() {
  return this.setInternalSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.prototype.hasInternalSiteDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.CRUDFields crud_fields = 5;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.Site.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 5));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.Site} returns this
*/
proto.entity.Site.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional References references = 7;
 * @return {?proto.entity.Site.References}
 */
proto.entity.Site.prototype.getReferences = function() {
  return /** @type{?proto.entity.Site.References} */ (
    jspb.Message.getWrapperField(this, proto.entity.Site.References, 7));
};


/**
 * @param {?proto.entity.Site.References|undefined} value
 * @return {!proto.entity.Site} returns this
*/
proto.entity.Site.prototype.setReferences = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Site} returns this
 */
proto.entity.Site.prototype.clearReferences = function() {
  return this.setReferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Site.prototype.hasReferences = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UserFeedback.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UserFeedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UserFeedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserFeedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    updatedTime: (f = msg.getUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UserFeedback}
 */
proto.entity.UserFeedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UserFeedback;
  return proto.entity.UserFeedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UserFeedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UserFeedback}
 */
proto.entity.UserFeedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedTime(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.UserFeedback.Status.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UserFeedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UserFeedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UserFeedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserFeedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UserFeedback.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UserFeedback.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UserFeedback.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserFeedback.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UserFeedback.Status}
 */
proto.entity.UserFeedback.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UserFeedback.Status;
  return proto.entity.UserFeedback.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UserFeedback.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UserFeedback.Status}
 */
proto.entity.UserFeedback.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UserFeedback.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UserFeedback.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UserFeedback.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UserFeedback.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.UserFeedback.Status.Option = {
  UNDEFINED: 0,
  SATISFIED: 1,
  UNSATISFIED: 2,
  DISMISSED: 3
};

/**
 * optional common.ObjectID user_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.UserFeedback.prototype.getUserId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.UserFeedback} returns this
*/
proto.entity.UserFeedback.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserFeedback} returns this
 */
proto.entity.UserFeedback.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserFeedback.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.UserFeedback.prototype.getUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.UserFeedback} returns this
*/
proto.entity.UserFeedback.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UserFeedback} returns this
 */
proto.entity.UserFeedback.prototype.clearUpdatedTime = function() {
  return this.setUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UserFeedback.prototype.hasUpdatedTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status.Option status = 3;
 * @return {!proto.entity.UserFeedback.Status.Option}
 */
proto.entity.UserFeedback.prototype.getStatus = function() {
  return /** @type {!proto.entity.UserFeedback.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.UserFeedback.Status.Option} value
 * @return {!proto.entity.UserFeedback} returns this
 */
proto.entity.UserFeedback.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.entity.UserFeedback.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UserFeedback} returns this
 */
proto.entity.UserFeedback.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.SiteDetails.repeatedFields_ = [5,10,14,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationDetails: (f = msg.getOrganizationDetails()) && proto.entity.Site.OrganizationDetails.toObject(includeInstance, f),
    dataSourceLinks: (f = msg.getDataSourceLinks()) && proto.entity.DataSourceLinks.toObject(includeInstance, f),
    domainName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetRoas: (f = msg.getTargetRoas()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
    billingAccountId: (f = msg.getBillingAccountId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    siteProblemsList: jspb.Message.toObjectList(msg.getSiteProblemsList(),
    proto.entity.SiteProblem.toObject, includeInstance),
    userFeedback: (f = msg.getUserFeedback()) && proto.entity.UserFeedback.toObject(includeInstance, f),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    applicationsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    attribution: jspb.Message.getFieldWithDefault(msg, 11, ""),
    utmParameters: (f = msg.getUtmParameters()) && proto_common_utmParameters_pb.UTMParameters.toObject(includeInstance, f),
    googleAdsAudits: (f = msg.getGoogleAdsAudits()) && proto.entity.SiteDetails.GoogleAdsAudits.toObject(includeInstance, f),
    createdGoogleAdsAccountsList: jspb.Message.toObjectList(msg.getCreatedGoogleAdsAccountsList(),
    proto.entity.SiteDetails.CreatedGoogleAdsAccount.toObject, includeInstance),
    ampdAdsSubscription: (f = msg.getAmpdAdsSubscription()) && proto.entity.SiteDetails.AmpdAdsSubscription.toObject(includeInstance, f),
    salesPlatforms: (f = msg.getSalesPlatforms()) && proto.entity.SiteDetails.SalesPlatforms.toObject(includeInstance, f),
    coupon: jspb.Message.getFieldWithDefault(msg, 17, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 18, ""),
    brandInfo: (f = msg.getBrandInfo()) && proto.entity.SiteDetails.BrandInfo.toObject(includeInstance, f),
    reportPublicationsList: jspb.Message.toObjectList(msg.getReportPublicationsList(),
    proto.entity.ReportPublication.toObject, includeInstance),
    uiBehavior: (f = msg.getUiBehavior()) && proto.entity.SiteDetails.UIBehavior.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails}
 */
proto.entity.SiteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails;
  return proto.entity.SiteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails}
 */
proto.entity.SiteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = new proto.entity.Site.OrganizationDetails;
      reader.readMessage(value,proto.entity.Site.OrganizationDetails.deserializeBinaryFromReader);
      msg.setOrganizationDetails(value);
      break;
    case 1:
      var value = new proto.entity.DataSourceLinks;
      reader.readMessage(value,proto.entity.DataSourceLinks.deserializeBinaryFromReader);
      msg.setDataSourceLinks(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setTargetRoas(value);
      break;
    case 4:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setBillingAccountId(value);
      break;
    case 5:
      var value = new proto.entity.SiteProblem;
      reader.readMessage(value,proto.entity.SiteProblem.deserializeBinaryFromReader);
      msg.addSiteProblems(value);
      break;
    case 6:
      var value = new proto.entity.UserFeedback;
      reader.readMessage(value,proto.entity.UserFeedback.deserializeBinaryFromReader);
      msg.setUserFeedback(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addApplications(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribution(value);
      break;
    case 12:
      var value = new proto_common_utmParameters_pb.UTMParameters;
      reader.readMessage(value,proto_common_utmParameters_pb.UTMParameters.deserializeBinaryFromReader);
      msg.setUtmParameters(value);
      break;
    case 13:
      var value = new proto.entity.SiteDetails.GoogleAdsAudits;
      reader.readMessage(value,proto.entity.SiteDetails.GoogleAdsAudits.deserializeBinaryFromReader);
      msg.setGoogleAdsAudits(value);
      break;
    case 14:
      var value = new proto.entity.SiteDetails.CreatedGoogleAdsAccount;
      reader.readMessage(value,proto.entity.SiteDetails.CreatedGoogleAdsAccount.deserializeBinaryFromReader);
      msg.addCreatedGoogleAdsAccounts(value);
      break;
    case 15:
      var value = new proto.entity.SiteDetails.AmpdAdsSubscription;
      reader.readMessage(value,proto.entity.SiteDetails.AmpdAdsSubscription.deserializeBinaryFromReader);
      msg.setAmpdAdsSubscription(value);
      break;
    case 16:
      var value = new proto.entity.SiteDetails.SalesPlatforms;
      reader.readMessage(value,proto.entity.SiteDetails.SalesPlatforms.deserializeBinaryFromReader);
      msg.setSalesPlatforms(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoupon(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 19:
      var value = new proto.entity.SiteDetails.BrandInfo;
      reader.readMessage(value,proto.entity.SiteDetails.BrandInfo.deserializeBinaryFromReader);
      msg.setBrandInfo(value);
      break;
    case 20:
      var value = new proto.entity.ReportPublication;
      reader.readMessage(value,proto.entity.ReportPublication.deserializeBinaryFromReader);
      msg.addReportPublications(value);
      break;
    case 21:
      var value = new proto.entity.SiteDetails.UIBehavior;
      reader.readMessage(value,proto.entity.SiteDetails.UIBehavior.deserializeBinaryFromReader);
      msg.setUiBehavior(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationDetails();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.entity.Site.OrganizationDetails.serializeBinaryToWriter
    );
  }
  f = message.getDataSourceLinks();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.DataSourceLinks.serializeBinaryToWriter
    );
  }
  f = message.getDomainName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetRoas();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getBillingAccountId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getSiteProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.entity.SiteProblem.serializeBinaryToWriter
    );
  }
  f = message.getUserFeedback();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.UserFeedback.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getApplicationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getAttribution();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUtmParameters();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_common_utmParameters_pb.UTMParameters.serializeBinaryToWriter
    );
  }
  f = message.getGoogleAdsAudits();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.entity.SiteDetails.GoogleAdsAudits.serializeBinaryToWriter
    );
  }
  f = message.getCreatedGoogleAdsAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.entity.SiteDetails.CreatedGoogleAdsAccount.serializeBinaryToWriter
    );
  }
  f = message.getAmpdAdsSubscription();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.entity.SiteDetails.AmpdAdsSubscription.serializeBinaryToWriter
    );
  }
  f = message.getSalesPlatforms();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.entity.SiteDetails.SalesPlatforms.serializeBinaryToWriter
    );
  }
  f = message.getCoupon();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBrandInfo();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.entity.SiteDetails.BrandInfo.serializeBinaryToWriter
    );
  }
  f = message.getReportPublicationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.entity.ReportPublication.serializeBinaryToWriter
    );
  }
  f = message.getUiBehavior();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.entity.SiteDetails.UIBehavior.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.GoogleAdsAudits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.GoogleAdsAudits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.GoogleAdsAudits.toObject = function(includeInstance, msg) {
  var f, obj = {
    initial: (f = msg.getInitial()) && proto_common_dataSourceInfo_pb.GoogleAdsAudit.toObject(includeInstance, f),
    current: (f = msg.getCurrent()) && proto_common_dataSourceInfo_pb.GoogleAdsAudit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.GoogleAdsAudits}
 */
proto.entity.SiteDetails.GoogleAdsAudits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.GoogleAdsAudits;
  return proto.entity.SiteDetails.GoogleAdsAudits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.GoogleAdsAudits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.GoogleAdsAudits}
 */
proto.entity.SiteDetails.GoogleAdsAudits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.GoogleAdsAudit;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GoogleAdsAudit.deserializeBinaryFromReader);
      msg.setInitial(value);
      break;
    case 2:
      var value = new proto_common_dataSourceInfo_pb.GoogleAdsAudit;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GoogleAdsAudit.deserializeBinaryFromReader);
      msg.setCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.GoogleAdsAudits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.GoogleAdsAudits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.GoogleAdsAudits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitial();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.GoogleAdsAudit.serializeBinaryToWriter
    );
  }
  f = message.getCurrent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_dataSourceInfo_pb.GoogleAdsAudit.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.GoogleAdsAudit initial = 1;
 * @return {?proto.common.GoogleAdsAudit}
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.getInitial = function() {
  return /** @type{?proto.common.GoogleAdsAudit} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GoogleAdsAudit, 1));
};


/**
 * @param {?proto.common.GoogleAdsAudit|undefined} value
 * @return {!proto.entity.SiteDetails.GoogleAdsAudits} returns this
*/
proto.entity.SiteDetails.GoogleAdsAudits.prototype.setInitial = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.GoogleAdsAudits} returns this
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.clearInitial = function() {
  return this.setInitial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.hasInitial = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.GoogleAdsAudit current = 2;
 * @return {?proto.common.GoogleAdsAudit}
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.getCurrent = function() {
  return /** @type{?proto.common.GoogleAdsAudit} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GoogleAdsAudit, 2));
};


/**
 * @param {?proto.common.GoogleAdsAudit|undefined} value
 * @return {!proto.entity.SiteDetails.GoogleAdsAudits} returns this
*/
proto.entity.SiteDetails.GoogleAdsAudits.prototype.setCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.GoogleAdsAudits} returns this
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.clearCurrent = function() {
  return this.setCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.GoogleAdsAudits.prototype.hasCurrent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.CreatedGoogleAdsAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    managerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.CreatedGoogleAdsAccount;
  return proto.entity.SiteDetails.CreatedGoogleAdsAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.CreatedGoogleAdsAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} returns this
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string manager_id = 2;
 * @return {string}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} returns this
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency_code = 3;
 * @return {string}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} returns this
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} returns this
*/
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount} returns this
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.CreatedGoogleAdsAccount.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.AmpdAdsSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.AmpdAdsSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.AmpdAdsSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    stripe: (f = msg.getStripe()) && proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.AmpdAdsSubscription;
  return proto.entity.SiteDetails.AmpdAdsSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.AmpdAdsSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.AmpdAdsSubscription.Stripe;
      reader.readMessage(value,proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.deserializeBinaryFromReader);
      msg.setStripe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.AmpdAdsSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.AmpdAdsSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.AmpdAdsSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStripe();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkoutSessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscription: (f = msg.getSubscription()) && proto_entity_billingAccount_pb.StripeAccount.Subscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.AmpdAdsSubscription.Stripe;
  return proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckoutSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new proto_entity_billingAccount_pb.StripeAccount.Subscription;
      reader.readMessage(value,proto_entity_billingAccount_pb.StripeAccount.Subscription.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckoutSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entity_billingAccount_pb.StripeAccount.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional string checkout_session_id = 1;
 * @return {string}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.getCheckoutSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} returns this
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.setCheckoutSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} returns this
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StripeAccount.Subscription subscription = 3;
 * @return {?proto.entity.StripeAccount.Subscription}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.getSubscription = function() {
  return /** @type{?proto.entity.StripeAccount.Subscription} */ (
    jspb.Message.getWrapperField(this, proto_entity_billingAccount_pb.StripeAccount.Subscription, 3));
};


/**
 * @param {?proto.entity.StripeAccount.Subscription|undefined} value
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} returns this
*/
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} returns this
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.Stripe.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Stripe stripe = 1;
 * @return {?proto.entity.SiteDetails.AmpdAdsSubscription.Stripe}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.prototype.getStripe = function() {
  return /** @type{?proto.entity.SiteDetails.AmpdAdsSubscription.Stripe} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.AmpdAdsSubscription.Stripe, 1));
};


/**
 * @param {?proto.entity.SiteDetails.AmpdAdsSubscription.Stripe|undefined} value
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription} returns this
*/
proto.entity.SiteDetails.AmpdAdsSubscription.prototype.setStripe = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.AmpdAdsSubscription} returns this
 */
proto.entity.SiteDetails.AmpdAdsSubscription.prototype.clearStripe = function() {
  return this.setStripe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.AmpdAdsSubscription.prototype.hasStripe = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.SalesPlatforms.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.SalesPlatforms} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.SalesPlatforms.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopify: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    bigCommerce: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    amazon: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.SalesPlatforms}
 */
proto.entity.SiteDetails.SalesPlatforms.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.SalesPlatforms;
  return proto.entity.SiteDetails.SalesPlatforms.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.SalesPlatforms} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.SalesPlatforms}
 */
proto.entity.SiteDetails.SalesPlatforms.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShopify(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBigCommerce(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmazon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.SalesPlatforms.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.SalesPlatforms} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.SalesPlatforms.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopify();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBigCommerce();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAmazon();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool shopify = 1;
 * @return {boolean}
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.getShopify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.SalesPlatforms} returns this
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.setShopify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool big_commerce = 2;
 * @return {boolean}
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.getBigCommerce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.SalesPlatforms} returns this
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.setBigCommerce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool amazon = 3;
 * @return {boolean}
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.getAmazon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.SalesPlatforms} returns this
 */
proto.entity.SiteDetails.SalesPlatforms.prototype.setAmazon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.BrandInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.BrandInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.BrandInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.BrandInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerBehaviorSettings: (f = msg.getCustomerBehaviorSettings()) && proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.BrandInfo}
 */
proto.entity.SiteDetails.BrandInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.BrandInfo;
  return proto.entity.SiteDetails.BrandInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.BrandInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.BrandInfo}
 */
proto.entity.SiteDetails.BrandInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings;
      reader.readMessage(value,proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.deserializeBinaryFromReader);
      msg.setCustomerBehaviorSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.BrandInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.BrandInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.BrandInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.BrandInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerBehaviorSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    cartAbandonment90dayPromos: (f = msg.getCartAbandonment90dayPromos()) && proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.toObject(includeInstance, f),
    repeatCustomerPromos: (f = msg.getRepeatCustomerPromos()) && proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.toObject(includeInstance, f),
    potentialNewCustomerPromos: (f = msg.getPotentialNewCustomerPromos()) && proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.toObject(includeInstance, f),
    avgAnnualPurchasesPerCustomer: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    retargetingConversionPercentagePoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings;
  return proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings;
      reader.readMessage(value,proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.deserializeBinaryFromReader);
      msg.setCartAbandonment90dayPromos(value);
      break;
    case 2:
      var value = new proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings;
      reader.readMessage(value,proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.deserializeBinaryFromReader);
      msg.setRepeatCustomerPromos(value);
      break;
    case 3:
      var value = new proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings;
      reader.readMessage(value,proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.deserializeBinaryFromReader);
      msg.setPotentialNewCustomerPromos(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgAnnualPurchasesPerCustomer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRetargetingConversionPercentagePoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCartAbandonment90dayPromos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.serializeBinaryToWriter
    );
  }
  f = message.getRepeatCustomerPromos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.serializeBinaryToWriter
    );
  }
  f = message.getPotentialNewCustomerPromos();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.serializeBinaryToWriter
    );
  }
  f = message.getAvgAnnualPurchasesPerCustomer();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRetargetingConversionPercentagePoints();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional BrandPromotionSettings cart_abandonment_90day_promos = 1;
 * @return {?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.getCartAbandonment90dayPromos = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings, 1));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings|undefined} value
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
*/
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.setCartAbandonment90dayPromos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.clearCartAbandonment90dayPromos = function() {
  return this.setCartAbandonment90dayPromos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.hasCartAbandonment90dayPromos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BrandPromotionSettings repeat_customer_promos = 2;
 * @return {?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.getRepeatCustomerPromos = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings, 2));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings|undefined} value
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
*/
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.setRepeatCustomerPromos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.clearRepeatCustomerPromos = function() {
  return this.setRepeatCustomerPromos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.hasRepeatCustomerPromos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BrandPromotionSettings potential_new_customer_promos = 3;
 * @return {?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.getPotentialNewCustomerPromos = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings, 3));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings|undefined} value
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
*/
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.setPotentialNewCustomerPromos = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.clearPotentialNewCustomerPromos = function() {
  return this.setPotentialNewCustomerPromos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.hasPotentialNewCustomerPromos = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double avg_annual_purchases_per_customer = 4;
 * @return {number}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.getAvgAnnualPurchasesPerCustomer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.setAvgAnnualPurchasesPerCustomer = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double retargeting_conversion_percentage_points = 5;
 * @return {number}
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.getRetargetingConversionPercentagePoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings.prototype.setRetargetingConversionPercentagePoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    redemptionPercentagePoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    discountPercentagePoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings}
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings;
  return proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings}
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRedemptionPercentagePoints(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountPercentagePoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedemptionPercentagePoints();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDiscountPercentagePoints();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double redemption_percentage_points = 1;
 * @return {number}
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.prototype.getRedemptionPercentagePoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.prototype.setRedemptionPercentagePoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double discount_percentage_points = 2;
 * @return {number}
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.prototype.getDiscountPercentagePoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings} returns this
 */
proto.entity.SiteDetails.BrandInfo.BrandPromotionSettings.prototype.setDiscountPercentagePoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional CustomerBehaviorSettings customer_behavior_settings = 1;
 * @return {?proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings}
 */
proto.entity.SiteDetails.BrandInfo.prototype.getCustomerBehaviorSettings = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings, 1));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo.CustomerBehaviorSettings|undefined} value
 * @return {!proto.entity.SiteDetails.BrandInfo} returns this
*/
proto.entity.SiteDetails.BrandInfo.prototype.setCustomerBehaviorSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails.BrandInfo} returns this
 */
proto.entity.SiteDetails.BrandInfo.prototype.clearCustomerBehaviorSettings = function() {
  return this.setCustomerBehaviorSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.BrandInfo.prototype.hasCustomerBehaviorSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteDetails.UIBehavior.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteDetails.UIBehavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteDetails.UIBehavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.UIBehavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    onlyAdminsCanEditStatuses: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    onlyAdminsCanEditBids: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    onlyAdminsCanEditBudgets: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlyAdminsCanEditDetails: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    onlyAdminsCanAddKeywords: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    onlyAdminsCanAddCampaigns: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteDetails.UIBehavior}
 */
proto.entity.SiteDetails.UIBehavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteDetails.UIBehavior;
  return proto.entity.SiteDetails.UIBehavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteDetails.UIBehavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteDetails.UIBehavior}
 */
proto.entity.SiteDetails.UIBehavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditStatuses(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditBids(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditBudgets(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditDetails(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanAddKeywords(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanAddCampaigns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteDetails.UIBehavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteDetails.UIBehavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteDetails.UIBehavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteDetails.UIBehavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnlyAdminsCanEditStatuses();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOnlyAdminsCanEditBids();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOnlyAdminsCanEditBudgets();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOnlyAdminsCanEditDetails();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOnlyAdminsCanAddKeywords();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOnlyAdminsCanAddCampaigns();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool only_admins_can_edit_statuses = 1;
 * @return {boolean}
 */
proto.entity.SiteDetails.UIBehavior.prototype.getOnlyAdminsCanEditStatuses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.UIBehavior} returns this
 */
proto.entity.SiteDetails.UIBehavior.prototype.setOnlyAdminsCanEditStatuses = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool only_admins_can_edit_bids = 2;
 * @return {boolean}
 */
proto.entity.SiteDetails.UIBehavior.prototype.getOnlyAdminsCanEditBids = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.UIBehavior} returns this
 */
proto.entity.SiteDetails.UIBehavior.prototype.setOnlyAdminsCanEditBids = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool only_admins_can_edit_budgets = 3;
 * @return {boolean}
 */
proto.entity.SiteDetails.UIBehavior.prototype.getOnlyAdminsCanEditBudgets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.UIBehavior} returns this
 */
proto.entity.SiteDetails.UIBehavior.prototype.setOnlyAdminsCanEditBudgets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool only_admins_can_edit_details = 4;
 * @return {boolean}
 */
proto.entity.SiteDetails.UIBehavior.prototype.getOnlyAdminsCanEditDetails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.UIBehavior} returns this
 */
proto.entity.SiteDetails.UIBehavior.prototype.setOnlyAdminsCanEditDetails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool only_admins_can_add_keywords = 5;
 * @return {boolean}
 */
proto.entity.SiteDetails.UIBehavior.prototype.getOnlyAdminsCanAddKeywords = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.UIBehavior} returns this
 */
proto.entity.SiteDetails.UIBehavior.prototype.setOnlyAdminsCanAddKeywords = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool only_admins_can_add_campaigns = 6;
 * @return {boolean}
 */
proto.entity.SiteDetails.UIBehavior.prototype.getOnlyAdminsCanAddCampaigns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteDetails.UIBehavior} returns this
 */
proto.entity.SiteDetails.UIBehavior.prototype.setOnlyAdminsCanAddCampaigns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional Site.OrganizationDetails organization_details = 9;
 * @return {?proto.entity.Site.OrganizationDetails}
 */
proto.entity.SiteDetails.prototype.getOrganizationDetails = function() {
  return /** @type{?proto.entity.Site.OrganizationDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.Site.OrganizationDetails, 9));
};


/**
 * @param {?proto.entity.Site.OrganizationDetails|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setOrganizationDetails = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearOrganizationDetails = function() {
  return this.setOrganizationDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasOrganizationDetails = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DataSourceLinks data_source_links = 1;
 * @return {?proto.entity.DataSourceLinks}
 */
proto.entity.SiteDetails.prototype.getDataSourceLinks = function() {
  return /** @type{?proto.entity.DataSourceLinks} */ (
    jspb.Message.getWrapperField(this, proto.entity.DataSourceLinks, 1));
};


/**
 * @param {?proto.entity.DataSourceLinks|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setDataSourceLinks = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearDataSourceLinks = function() {
  return this.setDataSourceLinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasDataSourceLinks = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string domain_name = 2;
 * @return {string}
 */
proto.entity.SiteDetails.prototype.getDomainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.setDomainName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.FloatValue target_roas = 3;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.entity.SiteDetails.prototype.getTargetRoas = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 3));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setTargetRoas = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearTargetRoas = function() {
  return this.setTargetRoas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasTargetRoas = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ObjectID billing_account_id = 4;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteDetails.prototype.getBillingAccountId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 4));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setBillingAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearBillingAccountId = function() {
  return this.setBillingAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasBillingAccountId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated SiteProblem site_problems = 5;
 * @return {!Array<!proto.entity.SiteProblem>}
 */
proto.entity.SiteDetails.prototype.getSiteProblemsList = function() {
  return /** @type{!Array<!proto.entity.SiteProblem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.SiteProblem, 5));
};


/**
 * @param {!Array<!proto.entity.SiteProblem>} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setSiteProblemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.entity.SiteProblem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteProblem}
 */
proto.entity.SiteDetails.prototype.addSiteProblems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.entity.SiteProblem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearSiteProblemsList = function() {
  return this.setSiteProblemsList([]);
};


/**
 * optional UserFeedback user_feedback = 6;
 * @return {?proto.entity.UserFeedback}
 */
proto.entity.SiteDetails.prototype.getUserFeedback = function() {
  return /** @type{?proto.entity.UserFeedback} */ (
    jspb.Message.getWrapperField(this, proto.entity.UserFeedback, 6));
};


/**
 * @param {?proto.entity.UserFeedback|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setUserFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearUserFeedback = function() {
  return this.setUserFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasUserFeedback = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string currency_code = 7;
 * @return {string}
 */
proto.entity.SiteDetails.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string applications = 10;
 * @return {!Array<string>}
 */
proto.entity.SiteDetails.prototype.getApplicationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.setApplicationsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.addApplications = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearApplicationsList = function() {
  return this.setApplicationsList([]);
};


/**
 * optional string attribution = 11;
 * @return {string}
 */
proto.entity.SiteDetails.prototype.getAttribution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.setAttribution = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional common.UTMParameters utm_parameters = 12;
 * @return {?proto.common.UTMParameters}
 */
proto.entity.SiteDetails.prototype.getUtmParameters = function() {
  return /** @type{?proto.common.UTMParameters} */ (
    jspb.Message.getWrapperField(this, proto_common_utmParameters_pb.UTMParameters, 12));
};


/**
 * @param {?proto.common.UTMParameters|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setUtmParameters = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearUtmParameters = function() {
  return this.setUtmParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasUtmParameters = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional GoogleAdsAudits google_ads_audits = 13;
 * @return {?proto.entity.SiteDetails.GoogleAdsAudits}
 */
proto.entity.SiteDetails.prototype.getGoogleAdsAudits = function() {
  return /** @type{?proto.entity.SiteDetails.GoogleAdsAudits} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.GoogleAdsAudits, 13));
};


/**
 * @param {?proto.entity.SiteDetails.GoogleAdsAudits|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setGoogleAdsAudits = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearGoogleAdsAudits = function() {
  return this.setGoogleAdsAudits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasGoogleAdsAudits = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated CreatedGoogleAdsAccount created_google_ads_accounts = 14;
 * @return {!Array<!proto.entity.SiteDetails.CreatedGoogleAdsAccount>}
 */
proto.entity.SiteDetails.prototype.getCreatedGoogleAdsAccountsList = function() {
  return /** @type{!Array<!proto.entity.SiteDetails.CreatedGoogleAdsAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.SiteDetails.CreatedGoogleAdsAccount, 14));
};


/**
 * @param {!Array<!proto.entity.SiteDetails.CreatedGoogleAdsAccount>} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setCreatedGoogleAdsAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.entity.SiteDetails.CreatedGoogleAdsAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteDetails.CreatedGoogleAdsAccount}
 */
proto.entity.SiteDetails.prototype.addCreatedGoogleAdsAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.entity.SiteDetails.CreatedGoogleAdsAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearCreatedGoogleAdsAccountsList = function() {
  return this.setCreatedGoogleAdsAccountsList([]);
};


/**
 * optional AmpdAdsSubscription ampd_ads_subscription = 15;
 * @return {?proto.entity.SiteDetails.AmpdAdsSubscription}
 */
proto.entity.SiteDetails.prototype.getAmpdAdsSubscription = function() {
  return /** @type{?proto.entity.SiteDetails.AmpdAdsSubscription} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.AmpdAdsSubscription, 15));
};


/**
 * @param {?proto.entity.SiteDetails.AmpdAdsSubscription|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setAmpdAdsSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearAmpdAdsSubscription = function() {
  return this.setAmpdAdsSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasAmpdAdsSubscription = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional SalesPlatforms sales_platforms = 16;
 * @return {?proto.entity.SiteDetails.SalesPlatforms}
 */
proto.entity.SiteDetails.prototype.getSalesPlatforms = function() {
  return /** @type{?proto.entity.SiteDetails.SalesPlatforms} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.SalesPlatforms, 16));
};


/**
 * @param {?proto.entity.SiteDetails.SalesPlatforms|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setSalesPlatforms = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearSalesPlatforms = function() {
  return this.setSalesPlatforms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasSalesPlatforms = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string coupon = 17;
 * @return {string}
 */
proto.entity.SiteDetails.prototype.getCoupon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.setCoupon = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string contact_email = 18;
 * @return {string}
 */
proto.entity.SiteDetails.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional BrandInfo brand_info = 19;
 * @return {?proto.entity.SiteDetails.BrandInfo}
 */
proto.entity.SiteDetails.prototype.getBrandInfo = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.BrandInfo, 19));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setBrandInfo = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearBrandInfo = function() {
  return this.setBrandInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasBrandInfo = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated ReportPublication report_publications = 20;
 * @return {!Array<!proto.entity.ReportPublication>}
 */
proto.entity.SiteDetails.prototype.getReportPublicationsList = function() {
  return /** @type{!Array<!proto.entity.ReportPublication>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ReportPublication, 20));
};


/**
 * @param {!Array<!proto.entity.ReportPublication>} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setReportPublicationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.entity.ReportPublication=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ReportPublication}
 */
proto.entity.SiteDetails.prototype.addReportPublications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.entity.ReportPublication, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearReportPublicationsList = function() {
  return this.setReportPublicationsList([]);
};


/**
 * optional UIBehavior ui_behavior = 21;
 * @return {?proto.entity.SiteDetails.UIBehavior}
 */
proto.entity.SiteDetails.prototype.getUiBehavior = function() {
  return /** @type{?proto.entity.SiteDetails.UIBehavior} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.UIBehavior, 21));
};


/**
 * @param {?proto.entity.SiteDetails.UIBehavior|undefined} value
 * @return {!proto.entity.SiteDetails} returns this
*/
proto.entity.SiteDetails.prototype.setUiBehavior = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteDetails} returns this
 */
proto.entity.SiteDetails.prototype.clearUiBehavior = function() {
  return this.setUiBehavior(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteDetails.prototype.hasUiBehavior = function() {
  return jspb.Message.getField(this, 21) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.QualificationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.QualificationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.QualificationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    googleAds: (f = msg.getGoogleAds()) && proto.entity.QualificationInfo.GoogleAds.toObject(includeInstance, f),
    shopify: (f = msg.getShopify()) && proto.entity.QualificationInfo.Shopify.toObject(includeInstance, f),
    bigCommerce: (f = msg.getBigCommerce()) && proto.entity.QualificationInfo.BigCommerce.toObject(includeInstance, f),
    gmvGreaterThan: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.QualificationInfo}
 */
proto.entity.QualificationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.QualificationInfo;
  return proto.entity.QualificationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.QualificationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.QualificationInfo}
 */
proto.entity.QualificationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.QualificationInfo.GoogleAds;
      reader.readMessage(value,proto.entity.QualificationInfo.GoogleAds.deserializeBinaryFromReader);
      msg.setGoogleAds(value);
      break;
    case 2:
      var value = new proto.entity.QualificationInfo.Shopify;
      reader.readMessage(value,proto.entity.QualificationInfo.Shopify.deserializeBinaryFromReader);
      msg.setShopify(value);
      break;
    case 3:
      var value = new proto.entity.QualificationInfo.BigCommerce;
      reader.readMessage(value,proto.entity.QualificationInfo.BigCommerce.deserializeBinaryFromReader);
      msg.setBigCommerce(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGmvGreaterThan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.QualificationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.QualificationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.QualificationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoogleAds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.QualificationInfo.GoogleAds.serializeBinaryToWriter
    );
  }
  f = message.getShopify();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.QualificationInfo.Shopify.serializeBinaryToWriter
    );
  }
  f = message.getBigCommerce();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.QualificationInfo.BigCommerce.serializeBinaryToWriter
    );
  }
  f = message.getGmvGreaterThan();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.QualificationInfo.BigCommerce.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.QualificationInfo.BigCommerce} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.BigCommerce.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minDateCreated: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxDateCreated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.QualificationInfo.BigCommerce}
 */
proto.entity.QualificationInfo.BigCommerce.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.QualificationInfo.BigCommerce;
  return proto.entity.QualificationInfo.BigCommerce.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.QualificationInfo.BigCommerce} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.QualificationInfo.BigCommerce}
 */
proto.entity.QualificationInfo.BigCommerce.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinDateCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxDateCreated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.QualificationInfo.BigCommerce.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.QualificationInfo.BigCommerce} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.BigCommerce.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinDateCreated();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxDateCreated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrderValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string store_hash = 1;
 * @return {string}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.getStoreHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.BigCommerce} returns this
 */
proto.entity.QualificationInfo.BigCommerce.prototype.setStoreHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string min_date_created = 2;
 * @return {string}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.getMinDateCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.BigCommerce} returns this
 */
proto.entity.QualificationInfo.BigCommerce.prototype.setMinDateCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string max_date_created = 3;
 * @return {string}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.getMaxDateCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.BigCommerce} returns this
 */
proto.entity.QualificationInfo.BigCommerce.prototype.setMaxDateCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 order_count = 4;
 * @return {number}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.getOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.BigCommerce} returns this
 */
proto.entity.QualificationInfo.BigCommerce.prototype.setOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double order_value = 5;
 * @return {number}
 */
proto.entity.QualificationInfo.BigCommerce.prototype.getOrderValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.BigCommerce} returns this
 */
proto.entity.QualificationInfo.BigCommerce.prototype.setOrderValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.QualificationInfo.Shopify.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.QualificationInfo.Shopify.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.QualificationInfo.Shopify} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.Shopify.toObject = function(includeInstance, msg) {
  var f, obj = {
    shop: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAtMin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAtMax: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.QualificationInfo.Shopify}
 */
proto.entity.QualificationInfo.Shopify.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.QualificationInfo.Shopify;
  return proto.entity.QualificationInfo.Shopify.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.QualificationInfo.Shopify} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.QualificationInfo.Shopify}
 */
proto.entity.QualificationInfo.Shopify.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAtMin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAtMax(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.QualificationInfo.Shopify.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.QualificationInfo.Shopify.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.QualificationInfo.Shopify} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.Shopify.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAtMin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAtMax();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrderValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string shop = 1;
 * @return {string}
 */
proto.entity.QualificationInfo.Shopify.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.Shopify} returns this
 */
proto.entity.QualificationInfo.Shopify.prototype.setShop = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at_min = 2;
 * @return {string}
 */
proto.entity.QualificationInfo.Shopify.prototype.getCreatedAtMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.Shopify} returns this
 */
proto.entity.QualificationInfo.Shopify.prototype.setCreatedAtMin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_at_max = 3;
 * @return {string}
 */
proto.entity.QualificationInfo.Shopify.prototype.getCreatedAtMax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.Shopify} returns this
 */
proto.entity.QualificationInfo.Shopify.prototype.setCreatedAtMax = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 order_count = 4;
 * @return {number}
 */
proto.entity.QualificationInfo.Shopify.prototype.getOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.Shopify} returns this
 */
proto.entity.QualificationInfo.Shopify.prototype.setOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double order_value = 5;
 * @return {number}
 */
proto.entity.QualificationInfo.Shopify.prototype.getOrderValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.Shopify} returns this
 */
proto.entity.QualificationInfo.Shopify.prototype.setOrderValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.QualificationInfo.GoogleAds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.QualificationInfo.GoogleAds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.GoogleAds.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hasBilling: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasConversionTracking: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    campaignTypeInfoMap: (f = msg.getCampaignTypeInfoMap()) ? f.toObject(includeInstance, proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.QualificationInfo.GoogleAds}
 */
proto.entity.QualificationInfo.GoogleAds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.QualificationInfo.GoogleAds;
  return proto.entity.QualificationInfo.GoogleAds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.QualificationInfo.GoogleAds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.QualificationInfo.GoogleAds}
 */
proto.entity.QualificationInfo.GoogleAds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBilling(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasConversionTracking(value);
      break;
    case 7:
      var value = msg.getCampaignTypeInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.deserializeBinaryFromReader, "", new proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.QualificationInfo.GoogleAds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.QualificationInfo.GoogleAds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.GoogleAds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHasBilling();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasConversionTracking();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCampaignTypeInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.serializeBinaryToWriter);
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusCountsMap: (f = msg.getStatusCountsMap()) ? f.toObject(includeInstance, undefined) : [],
    impressions: jspb.Message.getFieldWithDefault(msg, 2, 0),
    clicks: jspb.Message.getFieldWithDefault(msg, 3, 0),
    conversions: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cost: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo;
  return proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getStatusCountsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "", 0);
         });
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImpressions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClicks(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConversions(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCost(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusCountsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getImpressions();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClicks();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getConversions();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCost();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * map<string, int32> status_counts = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.getStatusCountsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} returns this
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.clearStatusCountsMap = function() {
  this.getStatusCountsMap().clear();
  return this;};


/**
 * optional int64 impressions = 2;
 * @return {number}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.getImpressions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} returns this
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.setImpressions = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 clicks = 3;
 * @return {number}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.getClicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} returns this
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.setClicks = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double conversions = 4;
 * @return {number}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.getConversions = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} returns this
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.setConversions = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double cost = 5;
 * @return {number}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} returns this
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.setCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double revenue = 6;
 * @return {number}
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo} returns this
 */
proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string start_date = 2;
 * @return {string}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_date = 3;
 * @return {string}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency_code = 4;
 * @return {string}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool has_billing = 5;
 * @return {boolean}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getHasBilling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.setHasBilling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_conversion_tracking = 6;
 * @return {boolean}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getHasConversionTracking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.setHasConversionTracking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * map<string, CampaignTypeInfo> campaign_type_info = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo>}
 */
proto.entity.QualificationInfo.GoogleAds.prototype.getCampaignTypeInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.entity.QualificationInfo.GoogleAds.CampaignTypeInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.entity.QualificationInfo.GoogleAds} returns this
 */
proto.entity.QualificationInfo.GoogleAds.prototype.clearCampaignTypeInfoMap = function() {
  this.getCampaignTypeInfoMap().clear();
  return this;};


/**
 * optional GoogleAds google_ads = 1;
 * @return {?proto.entity.QualificationInfo.GoogleAds}
 */
proto.entity.QualificationInfo.prototype.getGoogleAds = function() {
  return /** @type{?proto.entity.QualificationInfo.GoogleAds} */ (
    jspb.Message.getWrapperField(this, proto.entity.QualificationInfo.GoogleAds, 1));
};


/**
 * @param {?proto.entity.QualificationInfo.GoogleAds|undefined} value
 * @return {!proto.entity.QualificationInfo} returns this
*/
proto.entity.QualificationInfo.prototype.setGoogleAds = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.QualificationInfo} returns this
 */
proto.entity.QualificationInfo.prototype.clearGoogleAds = function() {
  return this.setGoogleAds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.QualificationInfo.prototype.hasGoogleAds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Shopify shopify = 2;
 * @return {?proto.entity.QualificationInfo.Shopify}
 */
proto.entity.QualificationInfo.prototype.getShopify = function() {
  return /** @type{?proto.entity.QualificationInfo.Shopify} */ (
    jspb.Message.getWrapperField(this, proto.entity.QualificationInfo.Shopify, 2));
};


/**
 * @param {?proto.entity.QualificationInfo.Shopify|undefined} value
 * @return {!proto.entity.QualificationInfo} returns this
*/
proto.entity.QualificationInfo.prototype.setShopify = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.QualificationInfo} returns this
 */
proto.entity.QualificationInfo.prototype.clearShopify = function() {
  return this.setShopify(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.QualificationInfo.prototype.hasShopify = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BigCommerce big_commerce = 3;
 * @return {?proto.entity.QualificationInfo.BigCommerce}
 */
proto.entity.QualificationInfo.prototype.getBigCommerce = function() {
  return /** @type{?proto.entity.QualificationInfo.BigCommerce} */ (
    jspb.Message.getWrapperField(this, proto.entity.QualificationInfo.BigCommerce, 3));
};


/**
 * @param {?proto.entity.QualificationInfo.BigCommerce|undefined} value
 * @return {!proto.entity.QualificationInfo} returns this
*/
proto.entity.QualificationInfo.prototype.setBigCommerce = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.QualificationInfo} returns this
 */
proto.entity.QualificationInfo.prototype.clearBigCommerce = function() {
  return this.setBigCommerce(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.QualificationInfo.prototype.hasBigCommerce = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 gmv_greater_than = 4;
 * @return {number}
 */
proto.entity.QualificationInfo.prototype.getGmvGreaterThan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.QualificationInfo} returns this
 */
proto.entity.QualificationInfo.prototype.setGmvGreaterThan = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.InternalSiteDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureMaskList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    source: (f = msg.getSource()) && proto.entity.InternalSiteDetails.Source.toObject(includeInstance, f),
    qualificationInfo: (f = msg.getQualificationInfo()) && proto.entity.QualificationInfo.toObject(includeInstance, f),
    organizationType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hubSpotCompanyId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hubSpotContactId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails}
 */
proto.entity.InternalSiteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails;
  return proto.entity.InternalSiteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails}
 */
proto.entity.InternalSiteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureMask(value);
      break;
    case 3:
      var value = new proto.entity.InternalSiteDetails.Source;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 4:
      var value = new proto.entity.QualificationInfo;
      reader.readMessage(value,proto.entity.QualificationInfo.deserializeBinaryFromReader);
      msg.setQualificationInfo(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.InternalSiteDetails.OrganizationType.Option} */ (reader.readEnum());
      msg.setOrganizationType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotCompanyId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureMaskList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.InternalSiteDetails.Source.serializeBinaryToWriter
    );
  }
  f = message.getQualificationInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.QualificationInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getHubSpotCompanyId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHubSpotContactId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.OrganizationType.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.OrganizationType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.OrganizationType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.OrganizationType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.OrganizationType}
 */
proto.entity.InternalSiteDetails.OrganizationType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.OrganizationType;
  return proto.entity.InternalSiteDetails.OrganizationType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.OrganizationType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.OrganizationType}
 */
proto.entity.InternalSiteDetails.OrganizationType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.OrganizationType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.OrganizationType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.OrganizationType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.OrganizationType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.InternalSiteDetails.OrganizationType.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  AMAZON_FBA: 2,
  AGENCY: 3,
  AGGREGATOR: 4,
  INTERNAL: 5
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.InternalSiteDetails.Source.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.entity.InternalSiteDetails.Source.TypeCase = {
  TYPE_NOT_SET: 0,
  SHOPIFY: 1,
  BIG_COMMERCE: 2,
  LOGICAL_POSITION: 3,
  JUMP_FLY: 4,
  DIRECT: 5,
  WALMART_JBP: 6
};

/**
 * @return {proto.entity.InternalSiteDetails.Source.TypeCase}
 */
proto.entity.InternalSiteDetails.Source.prototype.getTypeCase = function() {
  return /** @type {proto.entity.InternalSiteDetails.Source.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.InternalSiteDetails.Source.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopify: (f = msg.getShopify()) && proto.entity.InternalSiteDetails.Source.Shopify.toObject(includeInstance, f),
    bigCommerce: (f = msg.getBigCommerce()) && proto.entity.InternalSiteDetails.Source.BigCommerce.toObject(includeInstance, f),
    logicalPosition: (f = msg.getLogicalPosition()) && proto.entity.InternalSiteDetails.Source.LogicalPosition.toObject(includeInstance, f),
    jumpFly: (f = msg.getJumpFly()) && proto.entity.InternalSiteDetails.Source.JumpFly.toObject(includeInstance, f),
    direct: (f = msg.getDirect()) && proto.entity.InternalSiteDetails.Source.Direct.toObject(includeInstance, f),
    walmartJbp: (f = msg.getWalmartJbp()) && proto.entity.InternalSiteDetails.Source.WalmartJBP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source}
 */
proto.entity.InternalSiteDetails.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source;
  return proto.entity.InternalSiteDetails.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source}
 */
proto.entity.InternalSiteDetails.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.InternalSiteDetails.Source.Shopify;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.Shopify.deserializeBinaryFromReader);
      msg.setShopify(value);
      break;
    case 2:
      var value = new proto.entity.InternalSiteDetails.Source.BigCommerce;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.BigCommerce.deserializeBinaryFromReader);
      msg.setBigCommerce(value);
      break;
    case 3:
      var value = new proto.entity.InternalSiteDetails.Source.LogicalPosition;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.LogicalPosition.deserializeBinaryFromReader);
      msg.setLogicalPosition(value);
      break;
    case 4:
      var value = new proto.entity.InternalSiteDetails.Source.JumpFly;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.JumpFly.deserializeBinaryFromReader);
      msg.setJumpFly(value);
      break;
    case 5:
      var value = new proto.entity.InternalSiteDetails.Source.Direct;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.Direct.deserializeBinaryFromReader);
      msg.setDirect(value);
      break;
    case 6:
      var value = new proto.entity.InternalSiteDetails.Source.WalmartJBP;
      reader.readMessage(value,proto.entity.InternalSiteDetails.Source.WalmartJBP.deserializeBinaryFromReader);
      msg.setWalmartJbp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopify();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.InternalSiteDetails.Source.Shopify.serializeBinaryToWriter
    );
  }
  f = message.getBigCommerce();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.InternalSiteDetails.Source.BigCommerce.serializeBinaryToWriter
    );
  }
  f = message.getLogicalPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.InternalSiteDetails.Source.LogicalPosition.serializeBinaryToWriter
    );
  }
  f = message.getJumpFly();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.InternalSiteDetails.Source.JumpFly.serializeBinaryToWriter
    );
  }
  f = message.getDirect();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.InternalSiteDetails.Source.Direct.serializeBinaryToWriter
    );
  }
  f = message.getWalmartJbp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.InternalSiteDetails.Source.WalmartJBP.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.Shopify.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.Shopify} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.Shopify.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shop: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.Shopify}
 */
proto.entity.InternalSiteDetails.Source.Shopify.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.Shopify;
  return proto.entity.InternalSiteDetails.Source.Shopify.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.Shopify} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.Shopify}
 */
proto.entity.InternalSiteDetails.Source.Shopify.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setShopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.Shopify.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.Shopify} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.Shopify.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint64 shop_id = 1;
 * @return {number}
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.getShopId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalSiteDetails.Source.Shopify} returns this
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.setShopId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shop = 2;
 * @return {string}
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalSiteDetails.Source.Shopify} returns this
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.setShop = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 user_id = 3;
 * @return {number}
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalSiteDetails.Source.Shopify} returns this
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalSiteDetails.Source.Shopify} returns this
 */
proto.entity.InternalSiteDetails.Source.Shopify.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.BigCommerce.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.BigCommerce} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.BigCommerce}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.BigCommerce;
  return proto.entity.InternalSiteDetails.Source.BigCommerce.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.BigCommerce} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.BigCommerce}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreHash(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.BigCommerce.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.BigCommerce} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string store_hash = 1;
 * @return {string}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.getStoreHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalSiteDetails.Source.BigCommerce} returns this
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.setStoreHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.InternalSiteDetails.Source.BigCommerce} returns this
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalSiteDetails.Source.BigCommerce} returns this
 */
proto.entity.InternalSiteDetails.Source.BigCommerce.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.LogicalPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.LogicalPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.LogicalPosition}
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.LogicalPosition;
  return proto.entity.InternalSiteDetails.Source.LogicalPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.LogicalPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.LogicalPosition}
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.LogicalPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.LogicalPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.LogicalPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.JumpFly.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.JumpFly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.JumpFly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.JumpFly.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.JumpFly}
 */
proto.entity.InternalSiteDetails.Source.JumpFly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.JumpFly;
  return proto.entity.InternalSiteDetails.Source.JumpFly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.JumpFly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.JumpFly}
 */
proto.entity.InternalSiteDetails.Source.JumpFly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.JumpFly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.JumpFly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.JumpFly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.JumpFly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.WalmartJBP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.WalmartJBP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.WalmartJBP}
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.WalmartJBP;
  return proto.entity.InternalSiteDetails.Source.WalmartJBP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.WalmartJBP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.WalmartJBP}
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.WalmartJBP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.WalmartJBP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.WalmartJBP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.Direct.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.Direct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.Direct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.Direct.toObject = function(includeInstance, msg) {
  var f, obj = {
    origin: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.Direct}
 */
proto.entity.InternalSiteDetails.Source.Direct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.Direct;
  return proto.entity.InternalSiteDetails.Source.Direct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.Direct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.Direct}
 */
proto.entity.InternalSiteDetails.Source.Direct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.InternalSiteDetails.Source.Direct.Origin.Option} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.Direct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.Direct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.Direct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.Direct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalSiteDetails.Source.Direct.Origin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalSiteDetails.Source.Direct.Origin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalSiteDetails.Source.Direct.Origin}
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalSiteDetails.Source.Direct.Origin;
  return proto.entity.InternalSiteDetails.Source.Direct.Origin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalSiteDetails.Source.Direct.Origin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalSiteDetails.Source.Direct.Origin}
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalSiteDetails.Source.Direct.Origin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalSiteDetails.Source.Direct.Origin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.InternalSiteDetails.Source.Direct.Origin.Option = {
  UNKNOWN: 0,
  STANDALONE: 1
};

/**
 * optional Origin.Option origin = 1;
 * @return {!proto.entity.InternalSiteDetails.Source.Direct.Origin.Option}
 */
proto.entity.InternalSiteDetails.Source.Direct.prototype.getOrigin = function() {
  return /** @type {!proto.entity.InternalSiteDetails.Source.Direct.Origin.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.InternalSiteDetails.Source.Direct.Origin.Option} value
 * @return {!proto.entity.InternalSiteDetails.Source.Direct} returns this
 */
proto.entity.InternalSiteDetails.Source.Direct.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Shopify shopify = 1;
 * @return {?proto.entity.InternalSiteDetails.Source.Shopify}
 */
proto.entity.InternalSiteDetails.Source.prototype.getShopify = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source.Shopify} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source.Shopify, 1));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source.Shopify|undefined} value
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
*/
proto.entity.InternalSiteDetails.Source.prototype.setShopify = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.InternalSiteDetails.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
 */
proto.entity.InternalSiteDetails.Source.prototype.clearShopify = function() {
  return this.setShopify(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.Source.prototype.hasShopify = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BigCommerce big_commerce = 2;
 * @return {?proto.entity.InternalSiteDetails.Source.BigCommerce}
 */
proto.entity.InternalSiteDetails.Source.prototype.getBigCommerce = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source.BigCommerce} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source.BigCommerce, 2));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source.BigCommerce|undefined} value
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
*/
proto.entity.InternalSiteDetails.Source.prototype.setBigCommerce = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.InternalSiteDetails.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
 */
proto.entity.InternalSiteDetails.Source.prototype.clearBigCommerce = function() {
  return this.setBigCommerce(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.Source.prototype.hasBigCommerce = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogicalPosition logical_position = 3;
 * @return {?proto.entity.InternalSiteDetails.Source.LogicalPosition}
 */
proto.entity.InternalSiteDetails.Source.prototype.getLogicalPosition = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source.LogicalPosition} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source.LogicalPosition, 3));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source.LogicalPosition|undefined} value
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
*/
proto.entity.InternalSiteDetails.Source.prototype.setLogicalPosition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.InternalSiteDetails.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
 */
proto.entity.InternalSiteDetails.Source.prototype.clearLogicalPosition = function() {
  return this.setLogicalPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.Source.prototype.hasLogicalPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional JumpFly jump_fly = 4;
 * @return {?proto.entity.InternalSiteDetails.Source.JumpFly}
 */
proto.entity.InternalSiteDetails.Source.prototype.getJumpFly = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source.JumpFly} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source.JumpFly, 4));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source.JumpFly|undefined} value
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
*/
proto.entity.InternalSiteDetails.Source.prototype.setJumpFly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.InternalSiteDetails.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
 */
proto.entity.InternalSiteDetails.Source.prototype.clearJumpFly = function() {
  return this.setJumpFly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.Source.prototype.hasJumpFly = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Direct direct = 5;
 * @return {?proto.entity.InternalSiteDetails.Source.Direct}
 */
proto.entity.InternalSiteDetails.Source.prototype.getDirect = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source.Direct} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source.Direct, 5));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source.Direct|undefined} value
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
*/
proto.entity.InternalSiteDetails.Source.prototype.setDirect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.entity.InternalSiteDetails.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
 */
proto.entity.InternalSiteDetails.Source.prototype.clearDirect = function() {
  return this.setDirect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.Source.prototype.hasDirect = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WalmartJBP walmart_jbp = 6;
 * @return {?proto.entity.InternalSiteDetails.Source.WalmartJBP}
 */
proto.entity.InternalSiteDetails.Source.prototype.getWalmartJbp = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source.WalmartJBP} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source.WalmartJBP, 6));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source.WalmartJBP|undefined} value
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
*/
proto.entity.InternalSiteDetails.Source.prototype.setWalmartJbp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.entity.InternalSiteDetails.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails.Source} returns this
 */
proto.entity.InternalSiteDetails.Source.prototype.clearWalmartJbp = function() {
  return this.setWalmartJbp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.Source.prototype.hasWalmartJbp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string feature_mask = 1;
 * @return {!Array<string>}
 */
proto.entity.InternalSiteDetails.prototype.getFeatureMaskList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.setFeatureMaskList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.addFeatureMask = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.clearFeatureMaskList = function() {
  return this.setFeatureMaskList([]);
};


/**
 * optional Source source = 3;
 * @return {?proto.entity.InternalSiteDetails.Source}
 */
proto.entity.InternalSiteDetails.prototype.getSource = function() {
  return /** @type{?proto.entity.InternalSiteDetails.Source} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails.Source, 3));
};


/**
 * @param {?proto.entity.InternalSiteDetails.Source|undefined} value
 * @return {!proto.entity.InternalSiteDetails} returns this
*/
proto.entity.InternalSiteDetails.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.prototype.hasSource = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional QualificationInfo qualification_info = 4;
 * @return {?proto.entity.QualificationInfo}
 */
proto.entity.InternalSiteDetails.prototype.getQualificationInfo = function() {
  return /** @type{?proto.entity.QualificationInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.QualificationInfo, 4));
};


/**
 * @param {?proto.entity.QualificationInfo|undefined} value
 * @return {!proto.entity.InternalSiteDetails} returns this
*/
proto.entity.InternalSiteDetails.prototype.setQualificationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.clearQualificationInfo = function() {
  return this.setQualificationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InternalSiteDetails.prototype.hasQualificationInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OrganizationType.Option organization_type = 5;
 * @return {!proto.entity.InternalSiteDetails.OrganizationType.Option}
 */
proto.entity.InternalSiteDetails.prototype.getOrganizationType = function() {
  return /** @type {!proto.entity.InternalSiteDetails.OrganizationType.Option} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.InternalSiteDetails.OrganizationType.Option} value
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.setOrganizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string hub_spot_company_id = 6;
 * @return {string}
 */
proto.entity.InternalSiteDetails.prototype.getHubSpotCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.setHubSpotCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string hub_spot_contact_id = 7;
 * @return {string}
 */
proto.entity.InternalSiteDetails.prototype.getHubSpotContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.InternalSiteDetails} returns this
 */
proto.entity.InternalSiteDetails.prototype.setHubSpotContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.DataSourceLinks.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.DataSourceLinks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.DataSourceLinks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.DataSourceLinks.toObject = function(includeInstance, msg) {
  var f, obj = {
    gaInfo: (f = msg.getGaInfo()) && proto_common_dataSourceInfo_pb.GAInfo.toObject(includeInstance, f),
    adwordsInfo: (f = msg.getAdwordsInfo()) && proto_common_dataSourceInfo_pb.AdWordsInfo.toObject(includeInstance, f),
    shopifyInfo: (f = msg.getShopifyInfo()) && proto_common_dataSourceInfo_pb.ShopifyInfo.toObject(includeInstance, f),
    bigCommerceInfo: (f = msg.getBigCommerceInfo()) && proto_common_dataSourceInfo_pb.BigCommerceInfo.toObject(includeInstance, f),
    amazonInfo: (f = msg.getAmazonInfo()) && proto_common_dataSourceInfo_pb.AmazonInfo.toObject(includeInstance, f),
    facebookInfo: (f = msg.getFacebookInfo()) && proto_common_dataSourceInfo_pb.FacebookInfo.toObject(includeInstance, f),
    walmartInfo: (f = msg.getWalmartInfo()) && proto_common_dataSourceInfo_pb.WalmartInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.DataSourceLinks}
 */
proto.entity.DataSourceLinks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.DataSourceLinks;
  return proto.entity.DataSourceLinks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.DataSourceLinks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.DataSourceLinks}
 */
proto.entity.DataSourceLinks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.GAInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GAInfo.deserializeBinaryFromReader);
      msg.setGaInfo(value);
      break;
    case 2:
      var value = new proto_common_dataSourceInfo_pb.AdWordsInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.AdWordsInfo.deserializeBinaryFromReader);
      msg.setAdwordsInfo(value);
      break;
    case 3:
      var value = new proto_common_dataSourceInfo_pb.ShopifyInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.ShopifyInfo.deserializeBinaryFromReader);
      msg.setShopifyInfo(value);
      break;
    case 4:
      var value = new proto_common_dataSourceInfo_pb.BigCommerceInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.BigCommerceInfo.deserializeBinaryFromReader);
      msg.setBigCommerceInfo(value);
      break;
    case 5:
      var value = new proto_common_dataSourceInfo_pb.AmazonInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.AmazonInfo.deserializeBinaryFromReader);
      msg.setAmazonInfo(value);
      break;
    case 6:
      var value = new proto_common_dataSourceInfo_pb.FacebookInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.FacebookInfo.deserializeBinaryFromReader);
      msg.setFacebookInfo(value);
      break;
    case 7:
      var value = new proto_common_dataSourceInfo_pb.WalmartInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.WalmartInfo.deserializeBinaryFromReader);
      msg.setWalmartInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.DataSourceLinks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.DataSourceLinks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.DataSourceLinks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.DataSourceLinks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGaInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.GAInfo.serializeBinaryToWriter
    );
  }
  f = message.getAdwordsInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_dataSourceInfo_pb.AdWordsInfo.serializeBinaryToWriter
    );
  }
  f = message.getShopifyInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_dataSourceInfo_pb.ShopifyInfo.serializeBinaryToWriter
    );
  }
  f = message.getBigCommerceInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_dataSourceInfo_pb.BigCommerceInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmazonInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_dataSourceInfo_pb.AmazonInfo.serializeBinaryToWriter
    );
  }
  f = message.getFacebookInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_common_dataSourceInfo_pb.FacebookInfo.serializeBinaryToWriter
    );
  }
  f = message.getWalmartInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_common_dataSourceInfo_pb.WalmartInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.GAInfo ga_info = 1;
 * @return {?proto.common.GAInfo}
 */
proto.entity.DataSourceLinks.prototype.getGaInfo = function() {
  return /** @type{?proto.common.GAInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GAInfo, 1));
};


/**
 * @param {?proto.common.GAInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setGaInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearGaInfo = function() {
  return this.setGaInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasGaInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.AdWordsInfo adwords_info = 2;
 * @return {?proto.common.AdWordsInfo}
 */
proto.entity.DataSourceLinks.prototype.getAdwordsInfo = function() {
  return /** @type{?proto.common.AdWordsInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.AdWordsInfo, 2));
};


/**
 * @param {?proto.common.AdWordsInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setAdwordsInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearAdwordsInfo = function() {
  return this.setAdwordsInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasAdwordsInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ShopifyInfo shopify_info = 3;
 * @return {?proto.common.ShopifyInfo}
 */
proto.entity.DataSourceLinks.prototype.getShopifyInfo = function() {
  return /** @type{?proto.common.ShopifyInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.ShopifyInfo, 3));
};


/**
 * @param {?proto.common.ShopifyInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setShopifyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearShopifyInfo = function() {
  return this.setShopifyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasShopifyInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.BigCommerceInfo big_commerce_info = 4;
 * @return {?proto.common.BigCommerceInfo}
 */
proto.entity.DataSourceLinks.prototype.getBigCommerceInfo = function() {
  return /** @type{?proto.common.BigCommerceInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.BigCommerceInfo, 4));
};


/**
 * @param {?proto.common.BigCommerceInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setBigCommerceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearBigCommerceInfo = function() {
  return this.setBigCommerceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasBigCommerceInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.AmazonInfo amazon_info = 5;
 * @return {?proto.common.AmazonInfo}
 */
proto.entity.DataSourceLinks.prototype.getAmazonInfo = function() {
  return /** @type{?proto.common.AmazonInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.AmazonInfo, 5));
};


/**
 * @param {?proto.common.AmazonInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setAmazonInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearAmazonInfo = function() {
  return this.setAmazonInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasAmazonInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.FacebookInfo facebook_info = 6;
 * @return {?proto.common.FacebookInfo}
 */
proto.entity.DataSourceLinks.prototype.getFacebookInfo = function() {
  return /** @type{?proto.common.FacebookInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.FacebookInfo, 6));
};


/**
 * @param {?proto.common.FacebookInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setFacebookInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearFacebookInfo = function() {
  return this.setFacebookInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasFacebookInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.WalmartInfo walmart_info = 7;
 * @return {?proto.common.WalmartInfo}
 */
proto.entity.DataSourceLinks.prototype.getWalmartInfo = function() {
  return /** @type{?proto.common.WalmartInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.WalmartInfo, 7));
};


/**
 * @param {?proto.common.WalmartInfo|undefined} value
 * @return {!proto.entity.DataSourceLinks} returns this
*/
proto.entity.DataSourceLinks.prototype.setWalmartInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.DataSourceLinks} returns this
 */
proto.entity.DataSourceLinks.prototype.clearWalmartInfo = function() {
  return this.setWalmartInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.DataSourceLinks.prototype.hasWalmartInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.ReportPublication.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ReportPublication.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ReportPublication.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ReportPublication} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    destinationsList: jspb.Message.toObjectList(msg.getDestinationsList(),
    proto.entity.ReportPublication.Destination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ReportPublication}
 */
proto.entity.ReportPublication.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ReportPublication;
  return proto.entity.ReportPublication.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ReportPublication} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ReportPublication}
 */
proto.entity.ReportPublication.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.ReportPublication.ReportType.Option} */ (reader.readEnum());
      msg.setReportType(value);
      break;
    case 2:
      var value = new proto.entity.ReportPublication.Destination;
      reader.readMessage(value,proto.entity.ReportPublication.Destination.deserializeBinaryFromReader);
      msg.addDestinations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ReportPublication.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ReportPublication.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ReportPublication} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.entity.ReportPublication.Destination.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ReportPublication.ReportType.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ReportPublication.ReportType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ReportPublication.ReportType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.ReportType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ReportPublication.ReportType}
 */
proto.entity.ReportPublication.ReportType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ReportPublication.ReportType;
  return proto.entity.ReportPublication.ReportType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ReportPublication.ReportType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ReportPublication.ReportType}
 */
proto.entity.ReportPublication.ReportType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ReportPublication.ReportType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ReportPublication.ReportType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ReportPublication.ReportType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.ReportType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.ReportPublication.ReportType.Option = {
  UNSPECIFIED: 0,
  DAILY_GOOGLE_ADS_TO_AMAZON_METRICS_TO_DATE_CSV: 1,
  DAILY_GOOGLE_ADS_TO_AMAZON_METRICS_30_DAY_CSVS: 2,
  DAILY_FACEBOOK_TO_AMAZON_METRICS_30_DAY_CSV: 3,
  DAILY_GOOGLE_ADS_TO_WALMART_METRICS_30_DAY_CSVS: 4
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.ReportPublication.Destination.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.entity.ReportPublication.Destination.TypeCase = {
  TYPE_NOT_SET: 0,
  AMAZON_S3_BUCKET: 1,
  AZURE_BLOB_STORAGE_CONTAINER: 2
};

/**
 * @return {proto.entity.ReportPublication.Destination.TypeCase}
 */
proto.entity.ReportPublication.Destination.prototype.getTypeCase = function() {
  return /** @type {proto.entity.ReportPublication.Destination.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.ReportPublication.Destination.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ReportPublication.Destination.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ReportPublication.Destination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ReportPublication.Destination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.Destination.toObject = function(includeInstance, msg) {
  var f, obj = {
    amazonS3Bucket: (f = msg.getAmazonS3Bucket()) && proto.entity.ReportPublication.AmazonS3Bucket.toObject(includeInstance, f),
    azureBlobStorageContainer: (f = msg.getAzureBlobStorageContainer()) && proto.entity.ReportPublication.AzureBlobStorageContainer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ReportPublication.Destination}
 */
proto.entity.ReportPublication.Destination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ReportPublication.Destination;
  return proto.entity.ReportPublication.Destination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ReportPublication.Destination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ReportPublication.Destination}
 */
proto.entity.ReportPublication.Destination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.ReportPublication.AmazonS3Bucket;
      reader.readMessage(value,proto.entity.ReportPublication.AmazonS3Bucket.deserializeBinaryFromReader);
      msg.setAmazonS3Bucket(value);
      break;
    case 2:
      var value = new proto.entity.ReportPublication.AzureBlobStorageContainer;
      reader.readMessage(value,proto.entity.ReportPublication.AzureBlobStorageContainer.deserializeBinaryFromReader);
      msg.setAzureBlobStorageContainer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ReportPublication.Destination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ReportPublication.Destination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ReportPublication.Destination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.Destination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmazonS3Bucket();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.ReportPublication.AmazonS3Bucket.serializeBinaryToWriter
    );
  }
  f = message.getAzureBlobStorageContainer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.ReportPublication.AzureBlobStorageContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional AmazonS3Bucket amazon_s3_bucket = 1;
 * @return {?proto.entity.ReportPublication.AmazonS3Bucket}
 */
proto.entity.ReportPublication.Destination.prototype.getAmazonS3Bucket = function() {
  return /** @type{?proto.entity.ReportPublication.AmazonS3Bucket} */ (
    jspb.Message.getWrapperField(this, proto.entity.ReportPublication.AmazonS3Bucket, 1));
};


/**
 * @param {?proto.entity.ReportPublication.AmazonS3Bucket|undefined} value
 * @return {!proto.entity.ReportPublication.Destination} returns this
*/
proto.entity.ReportPublication.Destination.prototype.setAmazonS3Bucket = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.ReportPublication.Destination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ReportPublication.Destination} returns this
 */
proto.entity.ReportPublication.Destination.prototype.clearAmazonS3Bucket = function() {
  return this.setAmazonS3Bucket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ReportPublication.Destination.prototype.hasAmazonS3Bucket = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AzureBlobStorageContainer azure_blob_storage_container = 2;
 * @return {?proto.entity.ReportPublication.AzureBlobStorageContainer}
 */
proto.entity.ReportPublication.Destination.prototype.getAzureBlobStorageContainer = function() {
  return /** @type{?proto.entity.ReportPublication.AzureBlobStorageContainer} */ (
    jspb.Message.getWrapperField(this, proto.entity.ReportPublication.AzureBlobStorageContainer, 2));
};


/**
 * @param {?proto.entity.ReportPublication.AzureBlobStorageContainer|undefined} value
 * @return {!proto.entity.ReportPublication.Destination} returns this
*/
proto.entity.ReportPublication.Destination.prototype.setAzureBlobStorageContainer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.ReportPublication.Destination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.ReportPublication.Destination} returns this
 */
proto.entity.ReportPublication.Destination.prototype.clearAzureBlobStorageContainer = function() {
  return this.setAzureBlobStorageContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.ReportPublication.Destination.prototype.hasAzureBlobStorageContainer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ReportPublication.AmazonS3Bucket.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ReportPublication.AmazonS3Bucket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ReportPublication.AmazonS3Bucket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.AmazonS3Bucket.toObject = function(includeInstance, msg) {
  var f, obj = {
    partition: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bucketName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ReportPublication.AmazonS3Bucket}
 */
proto.entity.ReportPublication.AmazonS3Bucket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ReportPublication.AmazonS3Bucket;
  return proto.entity.ReportPublication.AmazonS3Bucket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ReportPublication.AmazonS3Bucket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ReportPublication.AmazonS3Bucket}
 */
proto.entity.ReportPublication.AmazonS3Bucket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucketName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ReportPublication.AmazonS3Bucket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ReportPublication.AmazonS3Bucket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ReportPublication.AmazonS3Bucket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.AmazonS3Bucket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartition();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBucketName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string partition = 1;
 * @return {string}
 */
proto.entity.ReportPublication.AmazonS3Bucket.prototype.getPartition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ReportPublication.AmazonS3Bucket} returns this
 */
proto.entity.ReportPublication.AmazonS3Bucket.prototype.setPartition = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bucket_name = 2;
 * @return {string}
 */
proto.entity.ReportPublication.AmazonS3Bucket.prototype.getBucketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ReportPublication.AmazonS3Bucket} returns this
 */
proto.entity.ReportPublication.AmazonS3Bucket.prototype.setBucketName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ReportPublication.AzureBlobStorageContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ReportPublication.AzureBlobStorageContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageAccountName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    containerName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ReportPublication.AzureBlobStorageContainer}
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ReportPublication.AzureBlobStorageContainer;
  return proto.entity.ReportPublication.AzureBlobStorageContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ReportPublication.AzureBlobStorageContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ReportPublication.AzureBlobStorageContainer}
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStorageAccountName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContainerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ReportPublication.AzureBlobStorageContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ReportPublication.AzureBlobStorageContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageAccountName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContainerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string storage_account_name = 1;
 * @return {string}
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.prototype.getStorageAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ReportPublication.AzureBlobStorageContainer} returns this
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.prototype.setStorageAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string container_name = 2;
 * @return {string}
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.prototype.getContainerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ReportPublication.AzureBlobStorageContainer} returns this
 */
proto.entity.ReportPublication.AzureBlobStorageContainer.prototype.setContainerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ReportType.Option report_type = 1;
 * @return {!proto.entity.ReportPublication.ReportType.Option}
 */
proto.entity.ReportPublication.prototype.getReportType = function() {
  return /** @type {!proto.entity.ReportPublication.ReportType.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.ReportPublication.ReportType.Option} value
 * @return {!proto.entity.ReportPublication} returns this
 */
proto.entity.ReportPublication.prototype.setReportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Destination destinations = 2;
 * @return {!Array<!proto.entity.ReportPublication.Destination>}
 */
proto.entity.ReportPublication.prototype.getDestinationsList = function() {
  return /** @type{!Array<!proto.entity.ReportPublication.Destination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ReportPublication.Destination, 2));
};


/**
 * @param {!Array<!proto.entity.ReportPublication.Destination>} value
 * @return {!proto.entity.ReportPublication} returns this
*/
proto.entity.ReportPublication.prototype.setDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.ReportPublication.Destination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ReportPublication.Destination}
 */
proto.entity.ReportPublication.prototype.addDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.ReportPublication.Destination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.ReportPublication} returns this
 */
proto.entity.ReportPublication.prototype.clearDestinationsList = function() {
  return this.setDestinationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UpdateSiteAction.oneofGroups_ = [[1,2,3,4,5,6,8,7,9,10,13,11,12,14,15,17,18,19,20,21,22,23,24,25,26,35,27,28,29,30,31,32,33,34,36]];

/**
 * @enum {number}
 */
proto.entity.UpdateSiteAction.ActionCase = {
  ACTION_NOT_SET: 0,
  SET_NAME: 1,
  SET_SITE_DETAILS: 2,
  SET_INTERNAL_SITE_DETAILS: 3,
  SET_DELETED: 4,
  UPDATE_FEATURE_MASK: 5,
  ADD_ADWORDS_ACCOUNT: 6,
  SET_GOOGLE_ADS_AD_SPEND: 8,
  SET_TARGET_ROAS: 7,
  SET_BILLING_ACCOUNT_ID: 9,
  UPDATE_SHOPIFY_INFO: 10,
  UPDATE_BIG_COMMERCE_INFO: 13,
  ADD_SITE_PROBLEM: 11,
  UPDATE_SITE_PROBLEMS: 12,
  SET_SITE_DOMAIN: 14,
  SET_USER_FEEDBACK: 15,
  ADD_APPLICATION: 17,
  SET_ATTRIBUTION: 18,
  SET_GOOGLE_ADS_AUDIT: 19,
  ADD_CREATED_GOOGLE_ADS_ACCOUNT: 20,
  SET_AMPD_ADS_SUBSCRIPTION: 21,
  ADD_AMAZON_ADVERTISING_ACCOUNT: 22,
  ADD_AMAZON_SELLER_ACCOUNT: 23,
  REMOVE_AMAZON_ADVERTISING_ACCOUNT: 24,
  ADD_AMAZON_PROFILE_MIGRATION: 25,
  REMOVE_AMAZON_SELLER_ACCOUNT: 26,
  UPDATE_WALMART_PROFILE: 35,
  UPDATE_SITE_CONTACT_EMAIL: 27,
  UPDATE_HUB_SPOT_COMPANY_ID: 28,
  UPDATE_HUB_SPOT_CONTACT_ID: 29,
  UPDATE_AMAZON_SELLER_ACCOUNT: 30,
  UPDATE_BRAND_INFO: 31,
  REMOVE_ADWORDS_ACCOUNT: 32,
  UPDATE_REPORT_PUBLICATIONS: 33,
  SET_FACEBOOK_ACCOUNT: 34,
  UPDATE_UI_BEHAVIOR: 36
};

/**
 * @return {proto.entity.UpdateSiteAction.ActionCase}
 */
proto.entity.UpdateSiteAction.prototype.getActionCase = function() {
  return /** @type {proto.entity.UpdateSiteAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.entity.UpdateSiteAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    setName: (f = msg.getSetName()) && proto.entity.UpdateSiteAction.SetNameAction.toObject(includeInstance, f),
    setSiteDetails: (f = msg.getSetSiteDetails()) && proto.entity.UpdateSiteAction.SetSiteDetailsAction.toObject(includeInstance, f),
    setInternalSiteDetails: (f = msg.getSetInternalSiteDetails()) && proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.toObject(includeInstance, f),
    setDeleted: (f = msg.getSetDeleted()) && proto.entity.UpdateSiteAction.SetDeletedAction.toObject(includeInstance, f),
    updateFeatureMask: (f = msg.getUpdateFeatureMask()) && proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.toObject(includeInstance, f),
    addAdwordsAccount: (f = msg.getAddAdwordsAccount()) && proto.entity.UpdateSiteAction.AddAdWordsAccountAction.toObject(includeInstance, f),
    setGoogleAdsAdSpend: (f = msg.getSetGoogleAdsAdSpend()) && proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.toObject(includeInstance, f),
    setTargetRoas: (f = msg.getSetTargetRoas()) && proto.entity.UpdateSiteAction.SetTargetROASAction.toObject(includeInstance, f),
    setBillingAccountId: (f = msg.getSetBillingAccountId()) && proto.entity.UpdateSiteAction.SetBillingAccountIDAction.toObject(includeInstance, f),
    updateShopifyInfo: (f = msg.getUpdateShopifyInfo()) && proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.toObject(includeInstance, f),
    updateBigCommerceInfo: (f = msg.getUpdateBigCommerceInfo()) && proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.toObject(includeInstance, f),
    addSiteProblem: (f = msg.getAddSiteProblem()) && proto.entity.UpdateSiteAction.AddSiteProblemAction.toObject(includeInstance, f),
    updateSiteProblems: (f = msg.getUpdateSiteProblems()) && proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.toObject(includeInstance, f),
    setSiteDomain: (f = msg.getSetSiteDomain()) && proto.entity.UpdateSiteAction.SetSiteDomainAction.toObject(includeInstance, f),
    setUserFeedback: (f = msg.getSetUserFeedback()) && proto.entity.UpdateSiteAction.SetUserFeedbackAction.toObject(includeInstance, f),
    addApplication: (f = msg.getAddApplication()) && proto.entity.UpdateSiteAction.AddApplicationAction.toObject(includeInstance, f),
    setAttribution: (f = msg.getSetAttribution()) && proto.entity.UpdateSiteAction.SetAttributionAction.toObject(includeInstance, f),
    setGoogleAdsAudit: (f = msg.getSetGoogleAdsAudit()) && proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.toObject(includeInstance, f),
    addCreatedGoogleAdsAccount: (f = msg.getAddCreatedGoogleAdsAccount()) && proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.toObject(includeInstance, f),
    setAmpdAdsSubscription: (f = msg.getSetAmpdAdsSubscription()) && proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.toObject(includeInstance, f),
    addAmazonAdvertisingAccount: (f = msg.getAddAmazonAdvertisingAccount()) && proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.toObject(includeInstance, f),
    addAmazonSellerAccount: (f = msg.getAddAmazonSellerAccount()) && proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.toObject(includeInstance, f),
    removeAmazonAdvertisingAccount: (f = msg.getRemoveAmazonAdvertisingAccount()) && proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.toObject(includeInstance, f),
    addAmazonProfileMigration: (f = msg.getAddAmazonProfileMigration()) && proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.toObject(includeInstance, f),
    removeAmazonSellerAccount: (f = msg.getRemoveAmazonSellerAccount()) && proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.toObject(includeInstance, f),
    updateWalmartProfile: (f = msg.getUpdateWalmartProfile()) && proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.toObject(includeInstance, f),
    updateSiteContactEmail: (f = msg.getUpdateSiteContactEmail()) && proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.toObject(includeInstance, f),
    updateHubSpotCompanyId: (f = msg.getUpdateHubSpotCompanyId()) && proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.toObject(includeInstance, f),
    updateHubSpotContactId: (f = msg.getUpdateHubSpotContactId()) && proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.toObject(includeInstance, f),
    updateAmazonSellerAccount: (f = msg.getUpdateAmazonSellerAccount()) && proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.toObject(includeInstance, f),
    updateBrandInfo: (f = msg.getUpdateBrandInfo()) && proto.entity.UpdateSiteAction.UpdateBrandInfoAction.toObject(includeInstance, f),
    removeAdwordsAccount: (f = msg.getRemoveAdwordsAccount()) && proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.toObject(includeInstance, f),
    updateReportPublications: (f = msg.getUpdateReportPublications()) && proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.toObject(includeInstance, f),
    setFacebookAccount: (f = msg.getSetFacebookAccount()) && proto.entity.UpdateSiteAction.SetFacebookAccountAction.toObject(includeInstance, f),
    updateUiBehavior: (f = msg.getUpdateUiBehavior()) && proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction}
 */
proto.entity.UpdateSiteAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction;
  return proto.entity.UpdateSiteAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction}
 */
proto.entity.UpdateSiteAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.UpdateSiteAction.SetNameAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetNameAction.deserializeBinaryFromReader);
      msg.setSetName(value);
      break;
    case 2:
      var value = new proto.entity.UpdateSiteAction.SetSiteDetailsAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetSiteDetailsAction.deserializeBinaryFromReader);
      msg.setSetSiteDetails(value);
      break;
    case 3:
      var value = new proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.deserializeBinaryFromReader);
      msg.setSetInternalSiteDetails(value);
      break;
    case 4:
      var value = new proto.entity.UpdateSiteAction.SetDeletedAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetDeletedAction.deserializeBinaryFromReader);
      msg.setSetDeleted(value);
      break;
    case 5:
      var value = new proto.entity.UpdateSiteAction.UpdateFeatureMaskAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.deserializeBinaryFromReader);
      msg.setUpdateFeatureMask(value);
      break;
    case 6:
      var value = new proto.entity.UpdateSiteAction.AddAdWordsAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddAdWordsAccountAction.deserializeBinaryFromReader);
      msg.setAddAdwordsAccount(value);
      break;
    case 8:
      var value = new proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.deserializeBinaryFromReader);
      msg.setSetGoogleAdsAdSpend(value);
      break;
    case 7:
      var value = new proto.entity.UpdateSiteAction.SetTargetROASAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetTargetROASAction.deserializeBinaryFromReader);
      msg.setSetTargetRoas(value);
      break;
    case 9:
      var value = new proto.entity.UpdateSiteAction.SetBillingAccountIDAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetBillingAccountIDAction.deserializeBinaryFromReader);
      msg.setSetBillingAccountId(value);
      break;
    case 10:
      var value = new proto.entity.UpdateSiteAction.UpdateShopifyInfoAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.deserializeBinaryFromReader);
      msg.setUpdateShopifyInfo(value);
      break;
    case 13:
      var value = new proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.deserializeBinaryFromReader);
      msg.setUpdateBigCommerceInfo(value);
      break;
    case 11:
      var value = new proto.entity.UpdateSiteAction.AddSiteProblemAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddSiteProblemAction.deserializeBinaryFromReader);
      msg.setAddSiteProblem(value);
      break;
    case 12:
      var value = new proto.entity.UpdateSiteAction.UpdateSiteProblemsAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.deserializeBinaryFromReader);
      msg.setUpdateSiteProblems(value);
      break;
    case 14:
      var value = new proto.entity.UpdateSiteAction.SetSiteDomainAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetSiteDomainAction.deserializeBinaryFromReader);
      msg.setSetSiteDomain(value);
      break;
    case 15:
      var value = new proto.entity.UpdateSiteAction.SetUserFeedbackAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetUserFeedbackAction.deserializeBinaryFromReader);
      msg.setSetUserFeedback(value);
      break;
    case 17:
      var value = new proto.entity.UpdateSiteAction.AddApplicationAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddApplicationAction.deserializeBinaryFromReader);
      msg.setAddApplication(value);
      break;
    case 18:
      var value = new proto.entity.UpdateSiteAction.SetAttributionAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetAttributionAction.deserializeBinaryFromReader);
      msg.setSetAttribution(value);
      break;
    case 19:
      var value = new proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.deserializeBinaryFromReader);
      msg.setSetGoogleAdsAudit(value);
      break;
    case 20:
      var value = new proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.deserializeBinaryFromReader);
      msg.setAddCreatedGoogleAdsAccount(value);
      break;
    case 21:
      var value = new proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.deserializeBinaryFromReader);
      msg.setSetAmpdAdsSubscription(value);
      break;
    case 22:
      var value = new proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.deserializeBinaryFromReader);
      msg.setAddAmazonAdvertisingAccount(value);
      break;
    case 23:
      var value = new proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.deserializeBinaryFromReader);
      msg.setAddAmazonSellerAccount(value);
      break;
    case 24:
      var value = new proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.deserializeBinaryFromReader);
      msg.setRemoveAmazonAdvertisingAccount(value);
      break;
    case 25:
      var value = new proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.deserializeBinaryFromReader);
      msg.setAddAmazonProfileMigration(value);
      break;
    case 26:
      var value = new proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.deserializeBinaryFromReader);
      msg.setRemoveAmazonSellerAccount(value);
      break;
    case 35:
      var value = new proto.entity.UpdateSiteAction.UpdateWalmartProfileAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.deserializeBinaryFromReader);
      msg.setUpdateWalmartProfile(value);
      break;
    case 27:
      var value = new proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.deserializeBinaryFromReader);
      msg.setUpdateSiteContactEmail(value);
      break;
    case 28:
      var value = new proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.deserializeBinaryFromReader);
      msg.setUpdateHubSpotCompanyId(value);
      break;
    case 29:
      var value = new proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.deserializeBinaryFromReader);
      msg.setUpdateHubSpotContactId(value);
      break;
    case 30:
      var value = new proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.deserializeBinaryFromReader);
      msg.setUpdateAmazonSellerAccount(value);
      break;
    case 31:
      var value = new proto.entity.UpdateSiteAction.UpdateBrandInfoAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateBrandInfoAction.deserializeBinaryFromReader);
      msg.setUpdateBrandInfo(value);
      break;
    case 32:
      var value = new proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.deserializeBinaryFromReader);
      msg.setRemoveAdwordsAccount(value);
      break;
    case 33:
      var value = new proto.entity.UpdateSiteAction.UpdateReportPublicationsAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.deserializeBinaryFromReader);
      msg.setUpdateReportPublications(value);
      break;
    case 34:
      var value = new proto.entity.UpdateSiteAction.SetFacebookAccountAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.SetFacebookAccountAction.deserializeBinaryFromReader);
      msg.setSetFacebookAccount(value);
      break;
    case 36:
      var value = new proto.entity.UpdateSiteAction.UpdateUIBehaviorAction;
      reader.readMessage(value,proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.deserializeBinaryFromReader);
      msg.setUpdateUiBehavior(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.UpdateSiteAction.SetNameAction.serializeBinaryToWriter
    );
  }
  f = message.getSetSiteDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UpdateSiteAction.SetSiteDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetInternalSiteDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetDeleted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.UpdateSiteAction.SetDeletedAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateFeatureMask();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.serializeBinaryToWriter
    );
  }
  f = message.getAddAdwordsAccount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.UpdateSiteAction.AddAdWordsAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getSetGoogleAdsAdSpend();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.serializeBinaryToWriter
    );
  }
  f = message.getSetTargetRoas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.UpdateSiteAction.SetTargetROASAction.serializeBinaryToWriter
    );
  }
  f = message.getSetBillingAccountId();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.entity.UpdateSiteAction.SetBillingAccountIDAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateShopifyInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBigCommerceInfo();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.serializeBinaryToWriter
    );
  }
  f = message.getAddSiteProblem();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.entity.UpdateSiteAction.AddSiteProblemAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateSiteProblems();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetSiteDomain();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.entity.UpdateSiteAction.SetSiteDomainAction.serializeBinaryToWriter
    );
  }
  f = message.getSetUserFeedback();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.entity.UpdateSiteAction.SetUserFeedbackAction.serializeBinaryToWriter
    );
  }
  f = message.getAddApplication();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.entity.UpdateSiteAction.AddApplicationAction.serializeBinaryToWriter
    );
  }
  f = message.getSetAttribution();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.entity.UpdateSiteAction.SetAttributionAction.serializeBinaryToWriter
    );
  }
  f = message.getSetGoogleAdsAudit();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.serializeBinaryToWriter
    );
  }
  f = message.getAddCreatedGoogleAdsAccount();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getSetAmpdAdsSubscription();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.serializeBinaryToWriter
    );
  }
  f = message.getAddAmazonAdvertisingAccount();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getAddAmazonSellerAccount();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getRemoveAmazonAdvertisingAccount();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getAddAmazonProfileMigration();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.serializeBinaryToWriter
    );
  }
  f = message.getRemoveAmazonSellerAccount();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateWalmartProfile();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateSiteContactEmail();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateHubSpotCompanyId();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateHubSpotContactId();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAmazonSellerAccount();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBrandInfo();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.entity.UpdateSiteAction.UpdateBrandInfoAction.serializeBinaryToWriter
    );
  }
  f = message.getRemoveAdwordsAccount();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReportPublications();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.serializeBinaryToWriter
    );
  }
  f = message.getSetFacebookAccount();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.entity.UpdateSiteAction.SetFacebookAccountAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUiBehavior();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetNameAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetNameAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetNameAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetNameAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetNameAction}
 */
proto.entity.UpdateSiteAction.SetNameAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetNameAction;
  return proto.entity.UpdateSiteAction.SetNameAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetNameAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetNameAction}
 */
proto.entity.UpdateSiteAction.SetNameAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetNameAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetNameAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetNameAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetNameAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_name = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.SetNameAction.prototype.getNewName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.SetNameAction} returns this
 */
proto.entity.UpdateSiteAction.SetNameAction.prototype.setNewName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetSiteDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetSiteDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newSiteDetails: (f = msg.getNewSiteDetails()) && proto.entity.SiteDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetSiteDetailsAction}
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetSiteDetailsAction;
  return proto.entity.UpdateSiteAction.SetSiteDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetSiteDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetSiteDetailsAction}
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.entity.SiteDetails;
      reader.readMessage(value,proto.entity.SiteDetails.deserializeBinaryFromReader);
      msg.setNewSiteDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetSiteDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetSiteDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewSiteDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.SiteDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteDetails new_site_details = 2;
 * @return {?proto.entity.SiteDetails}
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.prototype.getNewSiteDetails = function() {
  return /** @type{?proto.entity.SiteDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails, 2));
};


/**
 * @param {?proto.entity.SiteDetails|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetSiteDetailsAction} returns this
*/
proto.entity.UpdateSiteAction.SetSiteDetailsAction.prototype.setNewSiteDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetSiteDetailsAction} returns this
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.prototype.clearNewSiteDetails = function() {
  return this.setNewSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetSiteDetailsAction.prototype.hasNewSiteDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newInternalSiteDetails: (f = msg.getNewInternalSiteDetails()) && proto.entity.InternalSiteDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction}
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction;
  return proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction}
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.entity.InternalSiteDetails;
      reader.readMessage(value,proto.entity.InternalSiteDetails.deserializeBinaryFromReader);
      msg.setNewInternalSiteDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewInternalSiteDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.InternalSiteDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional InternalSiteDetails new_internal_site_details = 2;
 * @return {?proto.entity.InternalSiteDetails}
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.prototype.getNewInternalSiteDetails = function() {
  return /** @type{?proto.entity.InternalSiteDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalSiteDetails, 2));
};


/**
 * @param {?proto.entity.InternalSiteDetails|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction} returns this
*/
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.prototype.setNewInternalSiteDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction} returns this
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.prototype.clearNewInternalSiteDetails = function() {
  return this.setNewInternalSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction.prototype.hasNewInternalSiteDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    addFeaturesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    removeFeaturesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction}
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateFeatureMaskAction;
  return proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction}
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddFeatures(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemoveFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRemoveFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string add_features = 1;
 * @return {!Array<string>}
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.getAddFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.setAddFeaturesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.addAddFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.clearAddFeaturesList = function() {
  return this.setAddFeaturesList([]);
};


/**
 * repeated string remove_features = 2;
 * @return {!Array<string>}
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.getRemoveFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.setRemoveFeaturesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.addRemoveFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateFeatureMaskAction.prototype.clearRemoveFeaturesList = function() {
  return this.setRemoveFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetDeletedAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetDeletedAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetDeletedAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetDeletedAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    setDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetDeletedAction}
 */
proto.entity.UpdateSiteAction.SetDeletedAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetDeletedAction;
  return proto.entity.UpdateSiteAction.SetDeletedAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetDeletedAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetDeletedAction}
 */
proto.entity.UpdateSiteAction.SetDeletedAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSetDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetDeletedAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetDeletedAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetDeletedAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetDeletedAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetDeleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool set_deleted = 3;
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetDeletedAction.prototype.getSetDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.UpdateSiteAction.SetDeletedAction} returns this
 */
proto.entity.UpdateSiteAction.SetDeletedAction.prototype.setSetDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddAdWordsAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAdwordsAccount: (f = msg.getNewAdwordsAccount()) && proto_common_dataSourceInfo_pb.AdWordsAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction}
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddAdWordsAccountAction;
  return proto.entity.UpdateSiteAction.AddAdWordsAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction}
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.AdWordsAccount;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.AdWordsAccount.deserializeBinaryFromReader);
      msg.setNewAdwordsAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddAdWordsAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAdwordsAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.AdWordsAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.AdWordsAccount new_adwords_account = 1;
 * @return {?proto.common.AdWordsAccount}
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.prototype.getNewAdwordsAccount = function() {
  return /** @type{?proto.common.AdWordsAccount} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.AdWordsAccount, 1));
};


/**
 * @param {?proto.common.AdWordsAccount|undefined} value
 * @return {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction} returns this
*/
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.prototype.setNewAdwordsAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.AddAdWordsAccountAction} returns this
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.prototype.clearNewAdwordsAccount = function() {
  return this.setNewAdwordsAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddAdWordsAccountAction.prototype.hasNewAdwordsAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction}
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction;
  return proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction}
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction} returns this
 */
proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetTargetROASAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetTargetROASAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newTargetRoas: (f = msg.getNewTargetRoas()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetTargetROASAction}
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetTargetROASAction;
  return proto.entity.UpdateSiteAction.SetTargetROASAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetTargetROASAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetTargetROASAction}
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setNewTargetRoas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetTargetROASAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetTargetROASAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewTargetRoas();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.FloatValue new_target_roas = 1;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.prototype.getNewTargetRoas = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 1));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetTargetROASAction} returns this
*/
proto.entity.UpdateSiteAction.SetTargetROASAction.prototype.setNewTargetRoas = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetTargetROASAction} returns this
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.prototype.clearNewTargetRoas = function() {
  return this.setNewTargetRoas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetTargetROASAction.prototype.hasNewTargetRoas = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAdSpend: (f = msg.getNewAdSpend()) && proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction;
  return proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric.deserializeBinaryFromReader);
      msg.setNewAdSpend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAdSpend();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.GoogleAdsAccountMetric new_ad_spend = 1;
 * @return {?proto.common.GoogleAdsAccountMetric}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.prototype.getNewAdSpend = function() {
  return /** @type{?proto.common.GoogleAdsAccountMetric} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric, 1));
};


/**
 * @param {?proto.common.GoogleAdsAccountMetric|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction} returns this
*/
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.prototype.setNewAdSpend = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction} returns this
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.prototype.clearNewAdSpend = function() {
  return this.setNewAdSpend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction.prototype.hasNewAdSpend = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetBillingAccountIDAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newBillingAccountId: (f = msg.getNewBillingAccountId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction}
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetBillingAccountIDAction;
  return proto.entity.UpdateSiteAction.SetBillingAccountIDAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction}
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setNewBillingAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetBillingAccountIDAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewBillingAccountId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID new_billing_account_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.prototype.getNewBillingAccountId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction} returns this
*/
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.prototype.setNewBillingAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetBillingAccountIDAction} returns this
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.prototype.clearNewBillingAccountId = function() {
  return this.setNewBillingAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetBillingAccountIDAction.prototype.hasNewBillingAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedShopifyInfo: (f = msg.getUpdatedShopifyInfo()) && proto_common_dataSourceInfo_pb.ShopifyInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction}
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateShopifyInfoAction;
  return proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction}
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.ShopifyInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.ShopifyInfo.deserializeBinaryFromReader);
      msg.setUpdatedShopifyInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedShopifyInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.ShopifyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ShopifyInfo updated_shopify_info = 1;
 * @return {?proto.common.ShopifyInfo}
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.prototype.getUpdatedShopifyInfo = function() {
  return /** @type{?proto.common.ShopifyInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.ShopifyInfo, 1));
};


/**
 * @param {?proto.common.ShopifyInfo|undefined} value
 * @return {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.prototype.setUpdatedShopifyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.UpdateShopifyInfoAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.prototype.clearUpdatedShopifyInfo = function() {
  return this.setUpdatedShopifyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.UpdateShopifyInfoAction.prototype.hasUpdatedShopifyInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedBigCommerceInfo: (f = msg.getUpdatedBigCommerceInfo()) && proto_common_dataSourceInfo_pb.BigCommerceInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction}
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction;
  return proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction}
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.BigCommerceInfo;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.BigCommerceInfo.deserializeBinaryFromReader);
      msg.setUpdatedBigCommerceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedBigCommerceInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.BigCommerceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.BigCommerceInfo updated_big_commerce_info = 1;
 * @return {?proto.common.BigCommerceInfo}
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.prototype.getUpdatedBigCommerceInfo = function() {
  return /** @type{?proto.common.BigCommerceInfo} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.BigCommerceInfo, 1));
};


/**
 * @param {?proto.common.BigCommerceInfo|undefined} value
 * @return {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.prototype.setUpdatedBigCommerceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.prototype.clearUpdatedBigCommerceInfo = function() {
  return this.setUpdatedBigCommerceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction.prototype.hasUpdatedBigCommerceInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddSiteProblemAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddSiteProblemAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteProblem: (f = msg.getSiteProblem()) && proto.entity.SiteProblem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddSiteProblemAction}
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddSiteProblemAction;
  return proto.entity.UpdateSiteAction.AddSiteProblemAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddSiteProblemAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddSiteProblemAction}
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteProblem;
      reader.readMessage(value,proto.entity.SiteProblem.deserializeBinaryFromReader);
      msg.setSiteProblem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddSiteProblemAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddSiteProblemAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteProblem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteProblem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteProblem site_problem = 1;
 * @return {?proto.entity.SiteProblem}
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.prototype.getSiteProblem = function() {
  return /** @type{?proto.entity.SiteProblem} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteProblem, 1));
};


/**
 * @param {?proto.entity.SiteProblem|undefined} value
 * @return {!proto.entity.UpdateSiteAction.AddSiteProblemAction} returns this
*/
proto.entity.UpdateSiteAction.AddSiteProblemAction.prototype.setSiteProblem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.AddSiteProblemAction} returns this
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.prototype.clearSiteProblem = function() {
  return this.setSiteProblem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddSiteProblemAction.prototype.hasSiteProblem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetUserFeedbackAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetUserFeedbackAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newUserFeedback: (f = msg.getNewUserFeedback()) && proto.entity.UserFeedback.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetUserFeedbackAction}
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetUserFeedbackAction;
  return proto.entity.UpdateSiteAction.SetUserFeedbackAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetUserFeedbackAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetUserFeedbackAction}
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.UserFeedback;
      reader.readMessage(value,proto.entity.UserFeedback.deserializeBinaryFromReader);
      msg.setNewUserFeedback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetUserFeedbackAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetUserFeedbackAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewUserFeedback();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.UserFeedback.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserFeedback new_user_feedback = 1;
 * @return {?proto.entity.UserFeedback}
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.prototype.getNewUserFeedback = function() {
  return /** @type{?proto.entity.UserFeedback} */ (
    jspb.Message.getWrapperField(this, proto.entity.UserFeedback, 1));
};


/**
 * @param {?proto.entity.UserFeedback|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetUserFeedbackAction} returns this
*/
proto.entity.UpdateSiteAction.SetUserFeedbackAction.prototype.setNewUserFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetUserFeedbackAction} returns this
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.prototype.clearNewUserFeedback = function() {
  return this.setNewUserFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetUserFeedbackAction.prototype.hasNewUserFeedback = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteProblemsList: jspb.Message.toObjectList(msg.getSiteProblemsList(),
    proto.entity.SiteProblem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction}
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateSiteProblemsAction;
  return proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction}
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteProblem;
      reader.readMessage(value,proto.entity.SiteProblem.deserializeBinaryFromReader);
      msg.addSiteProblems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.SiteProblem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteProblem site_problems = 1;
 * @return {!Array<!proto.entity.SiteProblem>}
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.prototype.getSiteProblemsList = function() {
  return /** @type{!Array<!proto.entity.SiteProblem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.SiteProblem, 1));
};


/**
 * @param {!Array<!proto.entity.SiteProblem>} value
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.prototype.setSiteProblemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.SiteProblem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteProblem}
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.prototype.addSiteProblems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.SiteProblem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteProblemsAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateSiteProblemsAction.prototype.clearSiteProblemsList = function() {
  return this.setSiteProblemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetSiteDomainAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetSiteDomainAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetSiteDomainAction}
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetSiteDomainAction;
  return proto.entity.UpdateSiteAction.SetSiteDomainAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetSiteDomainAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetSiteDomainAction}
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetSiteDomainAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetSiteDomainAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.SetSiteDomainAction} returns this
 */
proto.entity.UpdateSiteAction.SetSiteDomainAction.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddApplicationAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddApplicationAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddApplicationAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddApplicationAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    application: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddApplicationAction}
 */
proto.entity.UpdateSiteAction.AddApplicationAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddApplicationAction;
  return proto.entity.UpdateSiteAction.AddApplicationAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddApplicationAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddApplicationAction}
 */
proto.entity.UpdateSiteAction.AddApplicationAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplication(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddApplicationAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddApplicationAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddApplicationAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddApplicationAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplication();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string application = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.AddApplicationAction.prototype.getApplication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.AddApplicationAction} returns this
 */
proto.entity.UpdateSiteAction.AddApplicationAction.prototype.setApplication = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetAttributionAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetAttributionAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetAttributionAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetAttributionAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAttribution: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetAttributionAction}
 */
proto.entity.UpdateSiteAction.SetAttributionAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetAttributionAction;
  return proto.entity.UpdateSiteAction.SetAttributionAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetAttributionAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetAttributionAction}
 */
proto.entity.UpdateSiteAction.SetAttributionAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewAttribution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetAttributionAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetAttributionAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetAttributionAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetAttributionAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAttribution();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_attribution = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.SetAttributionAction.prototype.getNewAttribution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.SetAttributionAction} returns this
 */
proto.entity.UpdateSiteAction.SetAttributionAction.prototype.setNewAttribution = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newGoogleAdsAudit: (f = msg.getNewGoogleAdsAudit()) && proto_common_dataSourceInfo_pb.GoogleAdsAudit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction;
  return proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.GoogleAdsAudit;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GoogleAdsAudit.deserializeBinaryFromReader);
      msg.setNewGoogleAdsAudit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewGoogleAdsAudit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.GoogleAdsAudit.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.GoogleAdsAudit new_google_ads_audit = 1;
 * @return {?proto.common.GoogleAdsAudit}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.prototype.getNewGoogleAdsAudit = function() {
  return /** @type{?proto.common.GoogleAdsAudit} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GoogleAdsAudit, 1));
};


/**
 * @param {?proto.common.GoogleAdsAudit|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction} returns this
*/
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.prototype.setNewGoogleAdsAudit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction} returns this
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.prototype.clearNewGoogleAdsAudit = function() {
  return this.setNewGoogleAdsAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction.prototype.hasNewGoogleAdsAudit = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdGoogleAdsAccount: (f = msg.getCreatedGoogleAdsAccount()) && proto.entity.SiteDetails.CreatedGoogleAdsAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction}
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction;
  return proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction}
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.CreatedGoogleAdsAccount;
      reader.readMessage(value,proto.entity.SiteDetails.CreatedGoogleAdsAccount.deserializeBinaryFromReader);
      msg.setCreatedGoogleAdsAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedGoogleAdsAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.CreatedGoogleAdsAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteDetails.CreatedGoogleAdsAccount created_google_ads_account = 1;
 * @return {?proto.entity.SiteDetails.CreatedGoogleAdsAccount}
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.prototype.getCreatedGoogleAdsAccount = function() {
  return /** @type{?proto.entity.SiteDetails.CreatedGoogleAdsAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.CreatedGoogleAdsAccount, 1));
};


/**
 * @param {?proto.entity.SiteDetails.CreatedGoogleAdsAccount|undefined} value
 * @return {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction} returns this
*/
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.prototype.setCreatedGoogleAdsAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction} returns this
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.prototype.clearCreatedGoogleAdsAccount = function() {
  return this.setCreatedGoogleAdsAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction.prototype.hasCreatedGoogleAdsAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAmpdAdsSubscription: (f = msg.getNewAmpdAdsSubscription()) && proto.entity.SiteDetails.AmpdAdsSubscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction}
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction;
  return proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction}
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.AmpdAdsSubscription;
      reader.readMessage(value,proto.entity.SiteDetails.AmpdAdsSubscription.deserializeBinaryFromReader);
      msg.setNewAmpdAdsSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAmpdAdsSubscription();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.AmpdAdsSubscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteDetails.AmpdAdsSubscription new_ampd_ads_subscription = 1;
 * @return {?proto.entity.SiteDetails.AmpdAdsSubscription}
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.prototype.getNewAmpdAdsSubscription = function() {
  return /** @type{?proto.entity.SiteDetails.AmpdAdsSubscription} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.AmpdAdsSubscription, 1));
};


/**
 * @param {?proto.entity.SiteDetails.AmpdAdsSubscription|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction} returns this
*/
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.prototype.setNewAmpdAdsSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction} returns this
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.prototype.clearNewAmpdAdsSubscription = function() {
  return this.setNewAmpdAdsSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction.prototype.hasNewAmpdAdsSubscription = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAmazonAdvertisingAccount: (f = msg.getNewAmazonAdvertisingAccount()) && proto_common_amazon_pb.Amazon.AdvertisingAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction}
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction;
  return proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction}
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingAccount;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingAccount.deserializeBinaryFromReader);
      msg.setNewAmazonAdvertisingAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAmazonAdvertisingAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Amazon.AdvertisingAccount new_amazon_advertising_account = 1;
 * @return {?proto.common.Amazon.AdvertisingAccount}
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.prototype.getNewAmazonAdvertisingAccount = function() {
  return /** @type{?proto.common.Amazon.AdvertisingAccount} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingAccount, 1));
};


/**
 * @param {?proto.common.Amazon.AdvertisingAccount|undefined} value
 * @return {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction} returns this
*/
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.prototype.setNewAmazonAdvertisingAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction} returns this
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.prototype.clearNewAmazonAdvertisingAccount = function() {
  return this.setNewAmazonAdvertisingAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction.prototype.hasNewAmazonAdvertisingAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAmazonSellerAccount: (f = msg.getNewAmazonSellerAccount()) && proto_common_amazon_pb.Amazon.SellerAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction;
  return proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.SellerAccount;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.SellerAccount.deserializeBinaryFromReader);
      msg.setNewAmazonSellerAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAmazonSellerAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.SellerAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Amazon.SellerAccount new_amazon_seller_account = 1;
 * @return {?proto.common.Amazon.SellerAccount}
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.prototype.getNewAmazonSellerAccount = function() {
  return /** @type{?proto.common.Amazon.SellerAccount} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.SellerAccount, 1));
};


/**
 * @param {?proto.common.Amazon.SellerAccount|undefined} value
 * @return {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction} returns this
*/
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.prototype.setNewAmazonSellerAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction} returns this
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.prototype.clearNewAmazonSellerAccount = function() {
  return this.setNewAmazonSellerAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction.prototype.hasNewAmazonSellerAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.SetFacebookAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.SetFacebookAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newFacebookAccount: (f = msg.getNewFacebookAccount()) && proto_common_facebook_pb.Facebook.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.SetFacebookAccountAction}
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.SetFacebookAccountAction;
  return proto.entity.UpdateSiteAction.SetFacebookAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.SetFacebookAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.SetFacebookAccountAction}
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_facebook_pb.Facebook.Account;
      reader.readMessage(value,proto_common_facebook_pb.Facebook.Account.deserializeBinaryFromReader);
      msg.setNewFacebookAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.SetFacebookAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.SetFacebookAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewFacebookAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_facebook_pb.Facebook.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Facebook.Account new_facebook_account = 1;
 * @return {?proto.common.Facebook.Account}
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.prototype.getNewFacebookAccount = function() {
  return /** @type{?proto.common.Facebook.Account} */ (
    jspb.Message.getWrapperField(this, proto_common_facebook_pb.Facebook.Account, 1));
};


/**
 * @param {?proto.common.Facebook.Account|undefined} value
 * @return {!proto.entity.UpdateSiteAction.SetFacebookAccountAction} returns this
*/
proto.entity.UpdateSiteAction.SetFacebookAccountAction.prototype.setNewFacebookAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.SetFacebookAccountAction} returns this
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.prototype.clearNewFacebookAccount = function() {
  return this.setNewFacebookAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.SetFacebookAccountAction.prototype.hasNewFacebookAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAmazonSellerAccount: (f = msg.getNewAmazonSellerAccount()) && proto_common_amazon_pb.Amazon.SellerAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction;
  return proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.SellerAccount;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.SellerAccount.deserializeBinaryFromReader);
      msg.setNewAmazonSellerAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAmazonSellerAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.SellerAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Amazon.SellerAccount new_amazon_seller_account = 1;
 * @return {?proto.common.Amazon.SellerAccount}
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.prototype.getNewAmazonSellerAccount = function() {
  return /** @type{?proto.common.Amazon.SellerAccount} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.SellerAccount, 1));
};


/**
 * @param {?proto.common.Amazon.SellerAccount|undefined} value
 * @return {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.prototype.setNewAmazonSellerAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.prototype.clearNewAmazonSellerAccount = function() {
  return this.setNewAmazonSellerAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction.prototype.hasNewAmazonSellerAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    brandNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateWalmartProfileAction;
  return proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBrandNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProfileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBrandNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional common.Walmart.Marketplace.Option marketplace = 1;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string profile_name = 2;
 * @return {string}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.getProfileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.setProfileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string brand_names = 3;
 * @return {!Array<string>}
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.getBrandNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.setBrandNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.addBrandNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateWalmartProfileAction.prototype.clearBrandNamesList = function() {
  return this.setBrandNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateBrandInfoAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newBrandInfo: (f = msg.getNewBrandInfo()) && proto.entity.SiteDetails.BrandInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction}
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateBrandInfoAction;
  return proto.entity.UpdateSiteAction.UpdateBrandInfoAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction}
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.BrandInfo;
      reader.readMessage(value,proto.entity.SiteDetails.BrandInfo.deserializeBinaryFromReader);
      msg.setNewBrandInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateBrandInfoAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewBrandInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.BrandInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteDetails.BrandInfo new_brand_info = 1;
 * @return {?proto.entity.SiteDetails.BrandInfo}
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.prototype.getNewBrandInfo = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.BrandInfo, 1));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo|undefined} value
 * @return {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.prototype.setNewBrandInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.UpdateBrandInfoAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.prototype.clearNewBrandInfo = function() {
  return this.setNewBrandInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.UpdateBrandInfoAction.prototype.hasNewBrandInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportPublicationsList: jspb.Message.toObjectList(msg.getReportPublicationsList(),
    proto.entity.ReportPublication.toObject, includeInstance),
    onlyAdd: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateReportPublicationsAction;
  return proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.ReportPublication;
      reader.readMessage(value,proto.entity.ReportPublication.deserializeBinaryFromReader);
      msg.addReportPublications(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportPublicationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.ReportPublication.serializeBinaryToWriter
    );
  }
  f = message.getOnlyAdd();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated ReportPublication report_publications = 1;
 * @return {!Array<!proto.entity.ReportPublication>}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.getReportPublicationsList = function() {
  return /** @type{!Array<!proto.entity.ReportPublication>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.ReportPublication, 1));
};


/**
 * @param {!Array<!proto.entity.ReportPublication>} value
 * @return {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.setReportPublicationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.ReportPublication=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.ReportPublication}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.addReportPublications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.ReportPublication, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.clearReportPublicationsList = function() {
  return this.setReportPublicationsList([]);
};


/**
 * optional bool only_add = 2;
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.getOnlyAdd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateReportPublicationsAction.prototype.setOnlyAdd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newAmazonProfileMigration: (f = msg.getNewAmazonProfileMigration()) && proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.toObject(includeInstance, f),
    update: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction;
  return proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_amazon_pb.Amazon.AdvertisingProfileMigration;
      reader.readMessage(value,proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.deserializeBinaryFromReader);
      msg.setNewAmazonProfileMigration(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewAmazonProfileMigration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_amazon_pb.Amazon.AdvertisingProfileMigration.serializeBinaryToWriter
    );
  }
  f = message.getUpdate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.Amazon.AdvertisingProfileMigration new_amazon_profile_migration = 1;
 * @return {?proto.common.Amazon.AdvertisingProfileMigration}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.getNewAmazonProfileMigration = function() {
  return /** @type{?proto.common.Amazon.AdvertisingProfileMigration} */ (
    jspb.Message.getWrapperField(this, proto_common_amazon_pb.Amazon.AdvertisingProfileMigration, 1));
};


/**
 * @param {?proto.common.Amazon.AdvertisingProfileMigration|undefined} value
 * @return {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} returns this
*/
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.setNewAmazonProfileMigration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} returns this
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.clearNewAmazonProfileMigration = function() {
  return this.setNewAmazonProfileMigration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.hasNewAmazonProfileMigration = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool update = 2;
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.getUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} returns this
 */
proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction.prototype.setUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newSiteContactEmail: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction}
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction;
  return proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction}
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewSiteContactEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewSiteContactEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_site_contact_email = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.prototype.getNewSiteContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction.prototype.setNewSiteContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newHubSpotCompanyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction;
  return proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewHubSpotCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewHubSpotCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_hub_spot_company_id = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.prototype.getNewHubSpotCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction.prototype.setNewHubSpotCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newHubSpotContactId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction;
  return proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewHubSpotContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewHubSpotContactId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_hub_spot_contact_id = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.prototype.getNewHubSpotContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction.prototype.setNewHubSpotContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountRegion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction}
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction;
  return proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction}
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setAccountRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string account_user_id = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.prototype.getAccountUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction} returns this
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.prototype.setAccountUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Region.Option account_region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.prototype.getAccountRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction} returns this
 */
proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction.prototype.setAccountRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    sellingPartnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction;
  return proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellingPartnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Amazon.Region.Option} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSellingPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string selling_partner_id = 1;
 * @return {string}
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.prototype.getSellingPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction} returns this
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.prototype.setSellingPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Amazon.Region.Option region = 2;
 * @return {!proto.common.Amazon.Region.Option}
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.prototype.getRegion = function() {
  return /** @type {!proto.common.Amazon.Region.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Amazon.Region.Option} value
 * @return {!proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction} returns this
 */
proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newUiBehavior: (f = msg.getNewUiBehavior()) && proto.entity.SiteDetails.UIBehavior.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction}
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateSiteAction.UpdateUIBehaviorAction;
  return proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction}
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteDetails.UIBehavior;
      reader.readMessage(value,proto.entity.SiteDetails.UIBehavior.deserializeBinaryFromReader);
      msg.setNewUiBehavior(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewUiBehavior();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteDetails.UIBehavior.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteDetails.UIBehavior new_ui_behavior = 1;
 * @return {?proto.entity.SiteDetails.UIBehavior}
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.prototype.getNewUiBehavior = function() {
  return /** @type{?proto.entity.SiteDetails.UIBehavior} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteDetails.UIBehavior, 1));
};


/**
 * @param {?proto.entity.SiteDetails.UIBehavior|undefined} value
 * @return {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction} returns this
*/
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.prototype.setNewUiBehavior = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction.UpdateUIBehaviorAction} returns this
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.prototype.clearNewUiBehavior = function() {
  return this.setNewUiBehavior(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.UpdateUIBehaviorAction.prototype.hasNewUiBehavior = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetNameAction set_name = 1;
 * @return {?proto.entity.UpdateSiteAction.SetNameAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetName = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetNameAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetNameAction, 1));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetNameAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetName = function() {
  return this.setSetName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetSiteDetailsAction set_site_details = 2;
 * @return {?proto.entity.UpdateSiteAction.SetSiteDetailsAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetSiteDetails = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetSiteDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetSiteDetailsAction, 2));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetSiteDetailsAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetSiteDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetSiteDetails = function() {
  return this.setSetSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetSiteDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SetInternalSiteDetailsAction set_internal_site_details = 3;
 * @return {?proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetInternalSiteDetails = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction, 3));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetInternalSiteDetailsAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetInternalSiteDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetInternalSiteDetails = function() {
  return this.setSetInternalSiteDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetInternalSiteDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SetDeletedAction set_deleted = 4;
 * @return {?proto.entity.UpdateSiteAction.SetDeletedAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetDeleted = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetDeletedAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetDeletedAction, 4));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetDeletedAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetDeleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetDeleted = function() {
  return this.setSetDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdateFeatureMaskAction update_feature_mask = 5;
 * @return {?proto.entity.UpdateSiteAction.UpdateFeatureMaskAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateFeatureMask = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateFeatureMaskAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateFeatureMaskAction, 5));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateFeatureMaskAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateFeatureMask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateFeatureMask = function() {
  return this.setUpdateFeatureMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateFeatureMask = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AddAdWordsAccountAction add_adwords_account = 6;
 * @return {?proto.entity.UpdateSiteAction.AddAdWordsAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddAdwordsAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddAdWordsAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddAdWordsAccountAction, 6));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddAdWordsAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddAdwordsAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddAdwordsAccount = function() {
  return this.setAddAdwordsAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddAdwordsAccount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SetGoogleAdsAdSpendAction set_google_ads_ad_spend = 8;
 * @return {?proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetGoogleAdsAdSpend = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction, 8));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetGoogleAdsAdSpendAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetGoogleAdsAdSpend = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetGoogleAdsAdSpend = function() {
  return this.setSetGoogleAdsAdSpend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetGoogleAdsAdSpend = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SetTargetROASAction set_target_roas = 7;
 * @return {?proto.entity.UpdateSiteAction.SetTargetROASAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetTargetRoas = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetTargetROASAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetTargetROASAction, 7));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetTargetROASAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetTargetRoas = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetTargetRoas = function() {
  return this.setSetTargetRoas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetTargetRoas = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SetBillingAccountIDAction set_billing_account_id = 9;
 * @return {?proto.entity.UpdateSiteAction.SetBillingAccountIDAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetBillingAccountId = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetBillingAccountIDAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetBillingAccountIDAction, 9));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetBillingAccountIDAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetBillingAccountId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetBillingAccountId = function() {
  return this.setSetBillingAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetBillingAccountId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UpdateShopifyInfoAction update_shopify_info = 10;
 * @return {?proto.entity.UpdateSiteAction.UpdateShopifyInfoAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateShopifyInfo = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateShopifyInfoAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateShopifyInfoAction, 10));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateShopifyInfoAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateShopifyInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateShopifyInfo = function() {
  return this.setUpdateShopifyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateShopifyInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional UpdateBigCommerceInfoAction update_big_commerce_info = 13;
 * @return {?proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateBigCommerceInfo = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction, 13));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateBigCommerceInfoAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateBigCommerceInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateBigCommerceInfo = function() {
  return this.setUpdateBigCommerceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateBigCommerceInfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional AddSiteProblemAction add_site_problem = 11;
 * @return {?proto.entity.UpdateSiteAction.AddSiteProblemAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddSiteProblem = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddSiteProblemAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddSiteProblemAction, 11));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddSiteProblemAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddSiteProblem = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddSiteProblem = function() {
  return this.setAddSiteProblem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddSiteProblem = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UpdateSiteProblemsAction update_site_problems = 12;
 * @return {?proto.entity.UpdateSiteAction.UpdateSiteProblemsAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateSiteProblems = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateSiteProblemsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateSiteProblemsAction, 12));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateSiteProblemsAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateSiteProblems = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateSiteProblems = function() {
  return this.setUpdateSiteProblems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateSiteProblems = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SetSiteDomainAction set_site_domain = 14;
 * @return {?proto.entity.UpdateSiteAction.SetSiteDomainAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetSiteDomain = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetSiteDomainAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetSiteDomainAction, 14));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetSiteDomainAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetSiteDomain = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetSiteDomain = function() {
  return this.setSetSiteDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetSiteDomain = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional SetUserFeedbackAction set_user_feedback = 15;
 * @return {?proto.entity.UpdateSiteAction.SetUserFeedbackAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetUserFeedback = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetUserFeedbackAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetUserFeedbackAction, 15));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetUserFeedbackAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetUserFeedback = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetUserFeedback = function() {
  return this.setSetUserFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetUserFeedback = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional AddApplicationAction add_application = 17;
 * @return {?proto.entity.UpdateSiteAction.AddApplicationAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddApplication = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddApplicationAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddApplicationAction, 17));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddApplicationAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddApplication = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddApplication = function() {
  return this.setAddApplication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddApplication = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional SetAttributionAction set_attribution = 18;
 * @return {?proto.entity.UpdateSiteAction.SetAttributionAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetAttribution = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetAttributionAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetAttributionAction, 18));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetAttributionAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetAttribution = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetAttribution = function() {
  return this.setSetAttribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetAttribution = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional SetGoogleAdsAuditAction set_google_ads_audit = 19;
 * @return {?proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetGoogleAdsAudit = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction, 19));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetGoogleAdsAuditAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetGoogleAdsAudit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetGoogleAdsAudit = function() {
  return this.setSetGoogleAdsAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetGoogleAdsAudit = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional AddCreatedGoogleAdsAccountAction add_created_google_ads_account = 20;
 * @return {?proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddCreatedGoogleAdsAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction, 20));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddCreatedGoogleAdsAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddCreatedGoogleAdsAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddCreatedGoogleAdsAccount = function() {
  return this.setAddCreatedGoogleAdsAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddCreatedGoogleAdsAccount = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional SetAmpdAdsSubscriptionAction set_ampd_ads_subscription = 21;
 * @return {?proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetAmpdAdsSubscription = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction, 21));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetAmpdAdsSubscriptionAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetAmpdAdsSubscription = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetAmpdAdsSubscription = function() {
  return this.setSetAmpdAdsSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetAmpdAdsSubscription = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional AddAmazonAdvertisingAccountAction add_amazon_advertising_account = 22;
 * @return {?proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddAmazonAdvertisingAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction, 22));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddAmazonAdvertisingAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddAmazonAdvertisingAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddAmazonAdvertisingAccount = function() {
  return this.setAddAmazonAdvertisingAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddAmazonAdvertisingAccount = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional AddAmazonSellerAccountAction add_amazon_seller_account = 23;
 * @return {?proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddAmazonSellerAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction, 23));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddAmazonSellerAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddAmazonSellerAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddAmazonSellerAccount = function() {
  return this.setAddAmazonSellerAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddAmazonSellerAccount = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional RemoveAmazonAdvertisingAccountAction remove_amazon_advertising_account = 24;
 * @return {?proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getRemoveAmazonAdvertisingAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction, 24));
};


/**
 * @param {?proto.entity.UpdateSiteAction.RemoveAmazonAdvertisingAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setRemoveAmazonAdvertisingAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearRemoveAmazonAdvertisingAccount = function() {
  return this.setRemoveAmazonAdvertisingAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasRemoveAmazonAdvertisingAccount = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional AddAmazonProfileMigrationAction add_amazon_profile_migration = 25;
 * @return {?proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction}
 */
proto.entity.UpdateSiteAction.prototype.getAddAmazonProfileMigration = function() {
  return /** @type{?proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction, 25));
};


/**
 * @param {?proto.entity.UpdateSiteAction.AddAmazonProfileMigrationAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setAddAmazonProfileMigration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearAddAmazonProfileMigration = function() {
  return this.setAddAmazonProfileMigration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasAddAmazonProfileMigration = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional RemoveAmazonSellerAccountAction remove_amazon_seller_account = 26;
 * @return {?proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getRemoveAmazonSellerAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction, 26));
};


/**
 * @param {?proto.entity.UpdateSiteAction.RemoveAmazonSellerAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setRemoveAmazonSellerAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearRemoveAmazonSellerAccount = function() {
  return this.setRemoveAmazonSellerAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasRemoveAmazonSellerAccount = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional UpdateWalmartProfileAction update_walmart_profile = 35;
 * @return {?proto.entity.UpdateSiteAction.UpdateWalmartProfileAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateWalmartProfile = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateWalmartProfileAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateWalmartProfileAction, 35));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateWalmartProfileAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateWalmartProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateWalmartProfile = function() {
  return this.setUpdateWalmartProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateWalmartProfile = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional UpdateSiteContactEmailAction update_site_contact_email = 27;
 * @return {?proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateSiteContactEmail = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction, 27));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateSiteContactEmailAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateSiteContactEmail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateSiteContactEmail = function() {
  return this.setUpdateSiteContactEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateSiteContactEmail = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional UpdateHubSpotCompanyIDAction update_hub_spot_company_id = 28;
 * @return {?proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateHubSpotCompanyId = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction, 28));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateHubSpotCompanyIDAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateHubSpotCompanyId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateHubSpotCompanyId = function() {
  return this.setUpdateHubSpotCompanyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateHubSpotCompanyId = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional UpdateHubSpotContactIDAction update_hub_spot_contact_id = 29;
 * @return {?proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateHubSpotContactId = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction, 29));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateHubSpotContactIDAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateHubSpotContactId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateHubSpotContactId = function() {
  return this.setUpdateHubSpotContactId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateHubSpotContactId = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional UpdateAmazonSellerAccountAction update_amazon_seller_account = 30;
 * @return {?proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateAmazonSellerAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction, 30));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateAmazonSellerAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateAmazonSellerAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateAmazonSellerAccount = function() {
  return this.setUpdateAmazonSellerAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateAmazonSellerAccount = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional UpdateBrandInfoAction update_brand_info = 31;
 * @return {?proto.entity.UpdateSiteAction.UpdateBrandInfoAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateBrandInfo = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateBrandInfoAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateBrandInfoAction, 31));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateBrandInfoAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateBrandInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateBrandInfo = function() {
  return this.setUpdateBrandInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateBrandInfo = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional RemoveAdwordsAccountAction remove_adwords_account = 32;
 * @return {?proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getRemoveAdwordsAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction, 32));
};


/**
 * @param {?proto.entity.UpdateSiteAction.RemoveAdwordsAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setRemoveAdwordsAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearRemoveAdwordsAccount = function() {
  return this.setRemoveAdwordsAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasRemoveAdwordsAccount = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional UpdateReportPublicationsAction update_report_publications = 33;
 * @return {?proto.entity.UpdateSiteAction.UpdateReportPublicationsAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateReportPublications = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateReportPublicationsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateReportPublicationsAction, 33));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateReportPublicationsAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateReportPublications = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateReportPublications = function() {
  return this.setUpdateReportPublications(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateReportPublications = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional SetFacebookAccountAction set_facebook_account = 34;
 * @return {?proto.entity.UpdateSiteAction.SetFacebookAccountAction}
 */
proto.entity.UpdateSiteAction.prototype.getSetFacebookAccount = function() {
  return /** @type{?proto.entity.UpdateSiteAction.SetFacebookAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.SetFacebookAccountAction, 34));
};


/**
 * @param {?proto.entity.UpdateSiteAction.SetFacebookAccountAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setSetFacebookAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearSetFacebookAccount = function() {
  return this.setSetFacebookAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasSetFacebookAccount = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional UpdateUIBehaviorAction update_ui_behavior = 36;
 * @return {?proto.entity.UpdateSiteAction.UpdateUIBehaviorAction}
 */
proto.entity.UpdateSiteAction.prototype.getUpdateUiBehavior = function() {
  return /** @type{?proto.entity.UpdateSiteAction.UpdateUIBehaviorAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateSiteAction.UpdateUIBehaviorAction, 36));
};


/**
 * @param {?proto.entity.UpdateSiteAction.UpdateUIBehaviorAction|undefined} value
 * @return {!proto.entity.UpdateSiteAction} returns this
*/
proto.entity.UpdateSiteAction.prototype.setUpdateUiBehavior = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.entity.UpdateSiteAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateSiteAction} returns this
 */
proto.entity.UpdateSiteAction.prototype.clearUpdateUiBehavior = function() {
  return this.setUpdateUiBehavior(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateSiteAction.prototype.hasUpdateUiBehavior = function() {
  return jspb.Message.getField(this, 36) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteProblem.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteProblem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteProblem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteProblem.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.entity.SiteError.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteProblem}
 */
proto.entity.SiteProblem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteProblem;
  return proto.entity.SiteProblem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteProblem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteProblem}
 */
proto.entity.SiteProblem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.SiteError;
      reader.readMessage(value,proto.entity.SiteError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteProblem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteProblem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteProblem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteProblem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.SiteError.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional SiteError error = 1;
 * @return {?proto.entity.SiteError}
 */
proto.entity.SiteProblem.prototype.getError = function() {
  return /** @type{?proto.entity.SiteError} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteError, 1));
};


/**
 * @param {?proto.entity.SiteError|undefined} value
 * @return {!proto.entity.SiteProblem} returns this
*/
proto.entity.SiteProblem.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteProblem} returns this
 */
proto.entity.SiteProblem.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteProblem.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.entity.SiteProblem.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteProblem} returns this
 */
proto.entity.SiteProblem.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.SiteError.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.entity.SiteError.ErrorCase = {
  ERROR_NOT_SET: 0,
  DOMAIN_ERROR: 1
};

/**
 * @return {proto.entity.SiteError.ErrorCase}
 */
proto.entity.SiteError.prototype.getErrorCase = function() {
  return /** @type {proto.entity.SiteError.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.entity.SiteError.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteError.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteError.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainError: (f = msg.getDomainError()) && proto.entity.DomainError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteError}
 */
proto.entity.SiteError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteError;
  return proto.entity.SiteError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteError}
 */
proto.entity.SiteError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.DomainError;
      reader.readMessage(value,proto.entity.DomainError.deserializeBinaryFromReader);
      msg.setDomainError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.DomainError.serializeBinaryToWriter
    );
  }
};


/**
 * optional DomainError domain_error = 1;
 * @return {?proto.entity.DomainError}
 */
proto.entity.SiteError.prototype.getDomainError = function() {
  return /** @type{?proto.entity.DomainError} */ (
    jspb.Message.getWrapperField(this, proto.entity.DomainError, 1));
};


/**
 * @param {?proto.entity.DomainError|undefined} value
 * @return {!proto.entity.SiteError} returns this
*/
proto.entity.SiteError.prototype.setDomainError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.SiteError.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteError} returns this
 */
proto.entity.SiteError.prototype.clearDomainError = function() {
  return this.setDomainError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteError.prototype.hasDomainError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.DomainError.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.DomainError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.DomainError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.DomainError.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    domain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.DomainError}
 */
proto.entity.DomainError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.DomainError;
  return proto.entity.DomainError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.DomainError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.DomainError}
 */
proto.entity.DomainError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.DomainError.Type.Option} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.DomainError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.DomainError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.DomainError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.DomainError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.DomainError.Type.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.DomainError.Type.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.DomainError.Type} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.DomainError.Type.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.DomainError.Type}
 */
proto.entity.DomainError.Type.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.DomainError.Type;
  return proto.entity.DomainError.Type.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.DomainError.Type} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.DomainError.Type}
 */
proto.entity.DomainError.Type.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.DomainError.Type.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.DomainError.Type.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.DomainError.Type} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.DomainError.Type.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.DomainError.Type.Option = {
  UNDEFINED: 0,
  MISSING_DOMAIN: 1,
  DOMAIN_UNREACHABLE: 2,
  NON_ROOT_CROSS_DOMAIN_REDIRECT: 3
};

/**
 * optional Type.Option type = 1;
 * @return {!proto.entity.DomainError.Type.Option}
 */
proto.entity.DomainError.prototype.getType = function() {
  return /** @type {!proto.entity.DomainError.Type.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.DomainError.Type.Option} value
 * @return {!proto.entity.DomainError} returns this
 */
proto.entity.DomainError.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string domain = 2;
 * @return {string}
 */
proto.entity.DomainError.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.DomainError} returns this
 */
proto.entity.DomainError.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.entity);
