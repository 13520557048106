import gql from "graphql-tag";

export const PRODUCT_COLLECTIONS_QUERY = gql`
  query Products($site: SiteSelector!) {
    products(site: $site) {
      collections {
        collectionId
        title
        pageUrl
        descriptionHtml
        isPublished
      }
    }
  }
`;

export const PRODUCT_PRODUCTS_QUERY = gql`
  query Products($site: SiteSelector!) {
    products(site: $site) {
      products {
        productId
        title
        pageUrl
        imageSrc
        descriptionHtml
        isPublished
      }
    }
  }
`;

export const PRODUCT_COLLECTION_PRODUCTS_QUERY = gql`
  query Products($site: SiteSelector!, $collectionId: String) {
    products(site: $site) {
      products(collectionId: $collectionId) {
        productId
      }
    }
  }
`;

export const PRODUCT_ORDER_COUNTS_QUERY = gql`
  query Products($site: SiteSelector!) {
    products(site: $site) {
      orderCounts {
        productId
        orderCount
      }
    }
  }
`;

export const CAMPAIGNS_AND_BUDGETS_QUERY = gql`
  query AdwordsObjects(
    $site: SiteSelector!
    $endDate: String!
    $numWeeks: Int
    $queryId: String
    $campaignIds: [String]
    $campaignStatuses: [Status]
  ) {
    adwordsObjects(
      site: $site
      endDate: $endDate
      numWeeks: $numWeeks
      queryId: $queryId
      campaignIds: $campaignIds
      campaignStatuses: $campaignStatuses
    ) {
      queryId
      campaigns {
        customerId
        campaignId
        name
        status
        labelIds
        advertisingChannelType
        advertisingChannelSubType
        biddingStrategy
        maxCpcBid
        campaignStartDate
        budgetId
        adGroupCount
        adGroupTypes
        adGroups {
          adGroupId
          defaultCpcBid
          defaultCpcBidIsAuto
          effectiveDefaultCpcBid
        }
      }
      budgets {
        customerId
        budgetId
        name
        status
        campaignCount
        budgetAmount
        recommendedBudgetAmount
      }
    }
  }
`;

export const CAMPAIGN_METRICS_QUERY = gql`
  query AdwordsMetrics(
    $site: SiteSelector!
    $queryId: String
    $endDate: String!
    $startDate: String
    $numWeeks: Int
    $objectType: AdwordsObjectType
    $campaignIds: [String]
    $adGroupIds: [String]
    $criteriaIds: [String]
    $queryTexts: [String]
    $includeAmazonAttributionData: Boolean
    $queryByDay: Boolean
  ) {
    adwordsMetrics(
      site: $site
      queryId: $queryId
      endDate: $endDate
      startDate: $startDate
      numWeeks: $numWeeks
      objectType: $objectType
      campaignIds: $campaignIds
      adGroupIds: $adGroupIds
      criteriaIds: $criteriaIds
      queryTexts: $queryTexts
      includeAmazonAttributionData: $includeAmazonAttributionData
      queryByDay: $queryByDay
    ) {
      queryId
      basicMetrics {
        date
        campaignId
        adGroupId
        criteriaId
        queryText
        costCurrencyCode
        revenueCurrencyCode
        cost
        impressions
        clicks
        conversions
        revenue
        averageSessionDuration
        impressionShare
        lostImpressionShareLowRank
        lostImpressionShareLowBudget
        clickShare
        attributedClicks
        detailPageViewClicks
        addToCartClicks
        unitsSold
        newToBrandConversions
        newToBrandRevenue
        newToBrandUnitsSold
        maxDataWithheldPercent
        brandReferralBonus
        adjustedCost
        parentAsinRevenue
        parentAsinAdjustedAdsCost
        purchaseFrequency
        annualizedRevenue
        unconvertedRevenueCurrencyCode
        unconvertedRevenue
        unconvertedBrandReferralBonus
        includesAmazonAttributionData
      }
    }
  }
`;

export const VALIDATE_AD_DESCRIPTION_MUTATION = gql`
  mutation ValidateAdDescription(
    $siteAlias: String!
    $customerId: String
    $descriptionLine1: String!
    $descriptionLine2: String!
  ) {
    validateAdDescription(
      site: { siteAlias: $siteAlias }
      customerId: $customerId
      descriptionLine1: $descriptionLine1
      descriptionLine2: $descriptionLine2
    ) {
      isValid
    }
  }
`;

export const CAMPAIGN_KEYWORDS_QUERY = gql`
  query AdwordsObjects(
    $site: SiteSelector!
    $endDate: String!
    $numWeeks: Int
    $adGroupIds: [String]
    $campaignIds: [String]
    $criteriaIds: [String]
    $campaignStatuses: [Status]
    $adGroupStatuses: [Status]
    $criteriaStatuses: [Status]
    $queryId: String
  ) {
    adwordsObjects(
      site: $site
      endDate: $endDate
      numWeeks: $numWeeks
      adGroupIds: $adGroupIds
      campaignIds: $campaignIds
      criteriaIds: $criteriaIds
      campaignStatuses: $campaignStatuses
      adGroupStatuses: $adGroupStatuses
      criteriaStatuses: $criteriaStatuses
      queryId: $queryId
    ) {
      queryId
      campaigns {
        customerId
        campaignId
        adGroupCount
        adGroups {
          adGroupId
          defaultCpcBid
          defaultCpcBidIsAuto
          effectiveDefaultCpcBid
          positiveKeywords {
            adGroupId
            criteriaId
            matchType
            labelIds
            text
            criteriaText
            cpcBid
            cpcBidIsAuto
            effectiveCpcBid
            status
          }
          negativeKeywords {
            adGroupId
            criteriaId
            labelIds
            text
            criteriaText
            status
          }
        }
      }
    }
  }
`;

export const CAMPAIGN_KEYWORD_METRICS_QUERY = gql`
  query AdwordsMetrics(
    $site: SiteSelector!
    $queryId: String
    $endDate: String!
    $startDate: String
    $numWeeks: Int
    $objectType: AdwordsObjectType
    $campaignIds: [String]
    $adGroupIds: [String]
    $criteriaIds: [String]
    $queryTexts: [String]
    $includeAmazonAttributionData: Boolean
    $queryByDay: Boolean
  ) {
    adwordsMetrics(
      site: $site
      queryId: $queryId
      endDate: $endDate
      startDate: $startDate
      numWeeks: $numWeeks
      objectType: $objectType
      campaignIds: $campaignIds
      adGroupIds: $adGroupIds
      criteriaIds: $criteriaIds
      queryTexts: $queryTexts
      includeAmazonAttributionData: $includeAmazonAttributionData
      queryByDay: $queryByDay
    ) {
      queryId
      basicMetrics {
        date
        campaignId
        adGroupId
        criteriaId
        queryText
        costCurrencyCode
        revenueCurrencyCode
        cost
        impressions
        clicks
        conversions
        revenue
        averageSessionDuration
        impressionShare
        lostImpressionShareLowRank
        clickShare
        firstPageCpc
        firstPositionCpc
        topOfPageCpc
        attributedClicks
        detailPageViewClicks
        addToCartClicks
        unitsSold
        maxDataWithheldPercent
        brandReferralBonus
        adjustedCost
        unconvertedRevenueCurrencyCode
        unconvertedRevenue
        unconvertedBrandReferralBonus
        includesAmazonAttributionData
      }
    }
  }
`;

export const AMPD_REPORT_QUERY = gql`
  query AmpdReport(
    $reportType: ReportType!
    $reportData: ReportData!
    $includePausedKeywords: Boolean
  ) {
    ampdReport(
      reportType: $reportType
      reportData: $reportData
      includePausedKeywords: $includePausedKeywords
    ) {
      exports {
        filename
        mimeType
        data
      }
    }
  }
`;

export const DEFAULT_USER_ROLE = "USER";
export const ADMIN_USER_ROLE = "ADMIN";

export const ADD_SITE_USER = gql`
  mutation(
    $siteAlias: String!
    $givenName: String!
    $familyName: String!
    $email: String!
    $siteRole: SiteRole!
  ) {
    inviteUserToSite(
      site: { siteAlias: $siteAlias }
      newUserProfile: { givenName: $givenName, familyName: $familyName }
      email: $email
      siteRole: $siteRole
    ) {
      ok
    }
  }
`;

export const SITE_USERS_QUERY = gql`
  query($siteAlias: String!) {
    site(site: { siteAlias: $siteAlias }) {
      siteUsers {
        user {
          id
          email
          profile {
            givenName
            familyName
          }
        }
        siteRole
      }
    }
  }
`;

export const LinkGoogleAdsMutation = gql`
  mutation(
    $site: SiteSelector!
    $customerIds: [String]!
    $managerIds: [String]!
    $accessToken: String!
    $skipBilling: Boolean
    $attribution: String
  ) {
    linkGoogleAdsAccounts(
      site: $site
      customerIds: $customerIds
      managerIds: $managerIds
      accessToken: $accessToken
      skipBilling: $skipBilling
      attribution: $attribution
    ) {
      site {
        hasAdwordsAccount
      }
    }
  }
`;

export const ListGoogleAdsCustomers = gql`
  query($site: SiteSelector!, $accessToken: String!) {
    googleAdsListCustomers(site: $site, accessToken: $accessToken) {
      name
      customerId
      currencyCode
      isLinkedToSite
      managerId
      managerName
    }
  }
`;
