import { CreateStripeSubscriptionRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Stripe_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { siteOnboardingPagePath } from "ExtensionV2/ExtensionV2";
import { generatePath } from "react-router-dom";

export const useCreateAndFinalizeSubscriptionMutation = (): UseMutationResult<
  void,
  unknown,
  {
    siteAlias: string;
    priceId: string;
    couponId?: string;
    setupFeeId?: string;
  }
> => {
  const stripe = useStripe();
  const elements = useElements();

  const mutationFn = async (args: {
    siteAlias: string;
    priceId: string;
    couponId?: string;
    setupFeeId?: string;
  }) => {
    if (!stripe || !elements) {
      console.error(
        "useCreateAndFinalizeSubscriptionMutation: Stripe not initialized"
      );
      throw new Error(
        "There was an internal error when trying to contact Stripe."
      );
    }

    // This runs local validations on the form itself.
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error(submitError);
      throw submitError.message;
    }

    // Create the subscription with the verified coupon.
    const req = new CreateStripeSubscriptionRequest();
    req.setSiteAlias(args.siteAlias);
    req.setPlanId(args.priceId);
    req.setCouponId(args.couponId || "");
    req.setSetupFeeId(args.setupFeeId || "");

    const createSubscriptionReply = await GRPCWebClient.createStripeSubscription(
      req,
      {}
    );

    // Redirect to the site onboarding page using the fully-qualified URL
    const redirectUrl = new URL(
      generatePath(siteOnboardingPagePath, {
        siteAlias: args.siteAlias
      }),
      window.location.origin
    );

    // Confirm the payment intent from the new subscription and redirect the user.
    const { error: confirmPaymentError } = await stripe.confirmPayment({
      elements,
      clientSecret: createSubscriptionReply.getClientSecret(),
      confirmParams: {
        return_url: redirectUrl.toString()
      }
    });

    if (confirmPaymentError) {
      console.error(confirmPaymentError);
      throw confirmPaymentError.message;
    }

    // At this point the customer is being redirected to the return_url. Anything that needs
    // to happen after the payment is complete should be handled there.
  };

  return useMutation(mutationFn);
};
