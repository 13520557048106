import React, { useState } from "react";
import { Message } from "semantic-ui-react";
import { AmazonAsinSelectorDropdown } from "./SelectAmazonProductSection";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";
import { Site } from "ExtensionV2/queries/useSession";

const asinValidator = (asin: string): Set<string> => {
  const errors = new Set<string>();
  if (!asin.trim().length) {
    errors.add("Please enter an ASIN");
    return errors;
  }

  const validAsin = new RegExp("^[A-Z0-9]{10}$");
  if (!validAsin.test(asin)) {
    errors.add("Please enter a valid ASIN");
  }

  return errors;
};

export const asinField = (initialValue: string): FormField<string> => ({
  _value: initialValue,
  errors: asinValidator(initialValue),
  validator: asinValidator
});

export const AsinReviewSection = ({
  site,
  state,
  dispatch,
  onEdit
}: {
  site: Site;
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { siteAlias } = site;
  const { currentReviewCampaignIndex } = state;
  const [
    campaign,
    { selectAttributionAdvertiser, selectAsin }
  ] = getCurrentCampaignAndSelectors(state);

  const asin = selectAsin(campaign);
  const attributionAdvertiser = selectAttributionAdvertiser(campaign, state);

  const [tempASIN, setTempASIN] = useState(asin);
  const [localErrors, setLocalErrors] = useState<Set<string>>(
    campaign.asinField.errors
  );

  const dataComponent = <p>{asin}</p>;

  const editComponent = (
    <>
      <p>
        <i>Select an ASIN</i>
      </p>
      <AmazonAsinSelectorDropdown
        amazonAttributionAdvertiser={attributionAdvertiser}
        asin={tempASIN}
        onSelectAsin={asin => {
          setTempASIN(asin);
        }}
        onBlur={() => {
          setLocalErrors(campaign.asinField.validator(tempASIN));
        }}
        siteAlias={siteAlias}
      />
      {localErrors.size > 0 && (
        <Message negative>
          {Array.from(localErrors).map(error => (
            <p key={error}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  const handleSave = () => {
    dispatch({
      name: "UpdateAsin",
      data: {
        index: currentReviewCampaignIndex,
        asin: tempASIN
      }
    });
  };

  const handleCancel = () => {
    setTempASIN(asin);
  };

  return (
    <CampaignReviewSection
      dataName="ASIN"
      dataComponent={dataComponent}
      editComponent={editComponent}
      disableSave={localErrors.size > 0}
      errors={campaign.asinField.errors}
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
