import _ from "lodash";
import { useEffect } from "react";
import {
  amazonUSMarketplaceInfo,
  AttributionAdvertiser,
  findAttributionAdvertiser,
  getAmazonMarketplace,
  getAmazonMarketplaceInfo,
  getDetailPageURLForASIN,
  getRelevantAttributionAdvertisers
} from "Common/utils/amazon";
import {
  descriptionsField,
  getDefaultDescriptions,
  getDefaultHeadlines,
  headlinesField
} from "./AdCopyField";
import { attributionField } from "./AttributionField";
import { budgetMicrosField, defaultBudgetUsdMicros } from "./BudgetField";
import { campaignNameField, getDefaultCampaignName } from "./CampaignNameField";
import { geotargetsField } from "./GeotargetsField";
import { googleKeywordsField } from "./GoogleKeywordsField";
import { marketplaceInfoField } from "./MarketplaceInfoField";
import { asinField } from "ExtensionV2/pages/CampaignSetupPage/AsinField";
import { walmartItemIdField } from "ExtensionV2/pages/CampaignSetupPage/WalmartItemIdField";
import { walmartProfileNameField } from "ExtensionV2/pages/CampaignSetupPage/WalmartProfileNameField";
import { walmartBrandsField } from "ExtensionV2/pages/CampaignSetupPage/WalmartBrandsField";
import { walmartSearchPageField } from "ExtensionV2/pages/CampaignSetupPage/WalmartSearchPageField";
import { targetUrlField } from "./TargetUrlField";
import { maxCPCField } from "./MaxCPCField";
import { RECOMMENDED_MAX_CPC_USD_MICROS } from "ExtensionV2/components/MaxCPCDropdown";
import { useCampaignConfigurationsByCampaignId } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import { Site } from "ExtensionV2/queries/useSession";
import { useItemizedCampaignConfiguration } from "ExtensionV2/queries/useItemizedCampaignConfiguration";
import { MICROS_TO_CURRENCY_UNIT_FACTOR } from "Common/utils/money";
import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import { CreateGoogleAdsCampaignArgs } from "ExtensionV2/queries/useCreateGoogleAdsCampaign";
import { useAmazonProduct } from "ExtensionV2/queries/useAmazonProduct";
import { googleAdsCurrencies, USA_GEOTARGET_ID } from "Common/utils/googleAds";
import { extractErrorMessage } from "Common/errors/error";
import {
  RECOMMENDED_AACOS_THRESHOLD,
  RECOMMENDED_COST_THRESHOLD_MULTIPLE,
  RECOMMENDED_MIN_ACTIVE
} from "ExtensionV2/components/campaignEditor/PausingAutomationEditButton";
import { None } from "Common/utils/tsUtils";
import {
  getMarketplaceInfoForDomain,
  isAmazonMarketplaceInfo,
  isWalmartMarketplaceInfo,
  MarketplaceInfo
} from "Common/utils/marketplace";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { GetAmazonProductReply } from "Common/proto/edge/grpcwebPb/grpcweb_Amazon_pb";
import {
  GetWalmartProductReply,
  GetWalmartSearchPageReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { useWalmartProduct } from "ExtensionV2/queries/useWalmartProduct";
import {
  extractWalmartURLInfoFromString,
  getWalmartDetailPageURLForItemId,
  getWalmartMarketplace,
  getWalmartSearchPageURL
} from "Common/utils/walmart";
import { useWalmartSearchPage } from "ExtensionV2/queries/useWalmartSearchPage";
import { Amazon } from "Common/proto/common/amazon_pb";
import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";

export const MARKETPLACE_DOMAIN_URL_PARAM = "marketplace";
export const PROFILE_URL_PARAM = "profile";
export const ADVERTISER_URL_PARAM = "advertiser";

export enum CampaignSetupStage {
  Attribution,
  Product,
  Review
}

export interface AdHeadline {
  _id: string; // Used to identify the headline in the UI, not stored and offers no guarantees.
  text: string;
  position: number;
  errors: Set<string>;
}

export interface AdDescription {
  _id: string; // Used to identify the description in the UI, not stored and offers no guarantees.
  text: string;
  position: number;
  errors: Set<string>;
}

export interface GoogleKeyword {
  text: string;
  avgMonthlySearches?: number;
  competitionIndex?: number;
  lowTopOfPageBid?: number;
  highTopOfPageBid?: number;
}

export interface PausingAutomationConfig {
  enabled: boolean;
  itemPrice: number;
  costThresholdMicros: number;
  AACOSThresholdPoints: number;
  reevaluatePausedKeywords: boolean;
  minNumActiveKeywords: number;
}

export interface FormField<T> {
  // Don't read directly from _value, use a selector to ensure the correct value is calculated.
  _value: T;
  errors: Set<string>;
  validator: (val: T) => Set<string>;
}

export interface GoogleAdCampaign {
  // If null, the marketplaceInfo in CampaignSetupState will be used.
  marketplaceInfoField: FormField<MarketplaceInfo | None>;
  // Amazon ASIN
  asinField: FormField<string>;
  // Walmart fields
  walmartProfileNameField: FormField<string>;
  walmartBrandsField: FormField<Array<string>>;
  walmartItemIdField: FormField<string>;
  walmartSearchPageField: FormField<string>;
  // daily max, in micros
  budgetMicrosField: FormField<number>;
  // max cost per click, in micros
  maxCPCMicrosField: FormField<number>;
  // used to generate googleKeywords
  seedKeywordsField: FormField<Array<string>>;
  // the search keywords used to display the ad in Google
  googleKeywordsField: FormField<Array<GoogleKeyword> | null>;
  // If null, the attributionAdvertiser in CampaignSetupState will be used.
  attributionAdvertiserField: FormField<AttributionAdvertiser | None>;
  campaignNameField: FormField<string>;
  // The URL that will be be used when the user clicks on the ad.
  targetUrlField: FormField<string>;
  // If empty, the geotargets in CampaignSetupState will be used.
  geotargetsField: FormField<Array<number>>;
  customHeadlinesField: FormField<Array<AdHeadline>>;
  customDescriptionsField: FormField<Array<AdDescription>>;
  // Provided from the server after the campaign is created.
  campaignId: number;
  // If true use the default ad copy. Must be set to false if the user wants to provide their own ad copy.
  wantsDefaultAdCopy: boolean;
  // How to configure auto pause for this campaign.
  pausingAutomation: PausingAutomationConfig | null;
}

export interface CampaignSetupState {
  stage: CampaignSetupStage;
  currentReviewCampaignIndex: number;
  marketplaceInfo: MarketplaceInfo | null;
  attributionAdvertiser: AttributionAdvertiser | null;
  walmartProfileName: string | null;
  geotargets: Array<number>; // a geotarget country is represented by a numeric code
  campaigns: Array<GoogleAdCampaign>;
}

export const seedKeywordsField = (
  initialValue: Array<string>
): FormField<Array<string>> => ({
  _value: initialValue,
  errors: new Set<string>(),
  validator: () => new Set<string>()
});

export const createEmptyCampaign = (): GoogleAdCampaign => {
  return {
    marketplaceInfoField: marketplaceInfoField(null),
    attributionAdvertiserField: attributionField(null),
    asinField: asinField(""),
    walmartProfileNameField: walmartProfileNameField(""),
    walmartBrandsField: walmartBrandsField([]),
    walmartItemIdField: walmartItemIdField(""),
    walmartSearchPageField: walmartSearchPageField(""),
    budgetMicrosField: budgetMicrosField(defaultBudgetUsdMicros),
    maxCPCMicrosField: maxCPCField(RECOMMENDED_MAX_CPC_USD_MICROS),
    seedKeywordsField: seedKeywordsField([]),
    campaignNameField: campaignNameField(""),
    googleKeywordsField: googleKeywordsField(null),
    targetUrlField: targetUrlField(""),
    geotargetsField: geotargetsField([]),
    customHeadlinesField: headlinesField([]),
    customDescriptionsField: descriptionsField([]),
    campaignId: 0,
    wantsDefaultAdCopy: true,
    pausingAutomation: null
  };
};

export const defaultCampaignSetupState = (): CampaignSetupState => ({
  stage: CampaignSetupStage.Attribution,
  currentReviewCampaignIndex: 0,
  marketplaceInfo: null,
  attributionAdvertiser: null,
  walmartProfileName: "",
  geotargets: [USA_GEOTARGET_ID],
  campaigns: [createEmptyCampaign()]
});

// Returns the initial setup state given an optional default state and any URL search params.
// If includeInitialCampaignStates is false, the returned state will have no campaign states
// and be in the Review state.  This empty state can be built up later for copying an existing
// campaign, when the copyCampaignData returned by useCopyCampaignDefaultState is ready.
export const getInitialCampaignPageSetupState = (
  defaultCampaignSetupPageState: CampaignSetupState | None,
  initializeCampaignStates: boolean,
  searchParams: URLSearchParams,
  advertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>,
  walmartProfiles: Array<DashboardSite.DashboardWalmartProfileInfo.AsObject>
): CampaignSetupState => {
  let pageState = defaultCampaignSetupPageState;
  if (!pageState) {
    pageState = defaultCampaignSetupState();
  }

  if (!initializeCampaignStates) {
    pageState.stage = CampaignSetupStage.Review;
    pageState.campaigns = [];
  } else {
    const domain = searchParams.get(MARKETPLACE_DOMAIN_URL_PARAM);
    const profileIdStr = searchParams.get(PROFILE_URL_PARAM);
    const advertiserIdStr = searchParams.get(ADVERTISER_URL_PARAM);

    const marketplaceInfo = getMarketplaceInfoForDomain(domain);
    if (marketplaceInfo) {
      pageState.marketplaceInfo = marketplaceInfo;
      for (const campaign of pageState.campaigns) {
        campaign.marketplaceInfoField._value = marketplaceInfo;
      }
      pageState.marketplaceInfo = marketplaceInfo;
    }

    if (
      isAmazonMarketplaceInfo(marketplaceInfo) &&
      advertisingProfiles &&
      (profileIdStr || advertiserIdStr)
    ) {
      const relevantAttributionAdvertisers = getRelevantAttributionAdvertisers(
        advertisingProfiles,
        marketplaceInfo?.marketplace,
        true
      );

      for (const attributionAdvertiser of relevantAttributionAdvertisers) {
        if (attributionAdvertiser.marketplace !== marketplaceInfo.marketplace) {
          continue;
        }
        if (
          profileIdStr &&
          String(attributionAdvertiser.profileIdStr) !== profileIdStr
        ) {
          continue;
        }
        if (
          advertiserIdStr &&
          String(attributionAdvertiser.advertiserIdStr) !== advertiserIdStr
        ) {
          continue;
        }

        pageState.attributionAdvertiser = attributionAdvertiser;
      }
    }

    if (isWalmartMarketplaceInfo(marketplaceInfo)) {
      for (const walmartProfile of walmartProfiles) {
        if (
          walmartProfile.marketplace !== getWalmartMarketplace(marketplaceInfo)
        ) {
          continue;
        }

        if (profileIdStr && walmartProfile.profileName !== profileIdStr) {
          continue;
        }

        pageState.walmartProfileName = walmartProfile.profileName;
      }
    }
  }

  return pageState;
};

// If we are using the form to copy a campaign, prefill the form with the campaign values and start
// the form on the review stage.
export const useCopyCampaignDefaultState = (
  campaignId: string,
  currentSite: Site,
  doCopy: boolean
): {
  copyCampaignData: GoogleAdCampaign | null;
  copyCampaignDataIsLoading: boolean;
  copyCampaignError: boolean;
} => {
  const siteAlias = currentSite.siteAlias;

  const {
    data: campaignConfigurations,
    isLoading: campaignConfigurationsAreLoading,
    error: campaignConfigurationsError
  } = useCampaignConfigurationsByCampaignId(doCopy ? siteAlias : "");

  const campaignConfiguration = useItemizedCampaignConfiguration(
    campaignConfigurations?.get(campaignId)
  );

  if (campaignConfigurationsAreLoading) {
    return {
      copyCampaignData: null,
      copyCampaignDataIsLoading: true,
      copyCampaignError: false
    };
  }

  if (campaignConfigurationsError) {
    console.error("Error copying campaign.", {
      campaignConfigurationsError,
      siteAlias,
      campaignId
    });

    return {
      copyCampaignData: null,
      copyCampaignDataIsLoading: false,
      copyCampaignError: true
    };
  }

  if (!campaignConfiguration.campaignId) {
    return {
      copyCampaignData: null,
      copyCampaignDataIsLoading: false,
      copyCampaignError: true
    };
  }

  const allCampaignNames = Array.from(campaignConfigurations || []).map(
    ([_, c]) =>
      c.ampdResourceConfiguration?.googleAds?.campaignConfiguration
        ?.campaignName ?? ""
  );

  const newCampaignName = pickUniqueCampaignName(
    campaignConfiguration.campaignName,
    allCampaignNames
  );

  const advertisers = getRelevantAttributionAdvertisers(
    currentSite.amazonInfo.advertisingProfiles,
    null,
    false
  );

  const marketplaceInfo = campaignConfiguration.marketplaceInfo;

  const attributionAdvertiser = findAttributionAdvertiser(
    advertisers,
    campaignConfiguration.attributionAdvertiser?.advertiserIdStr ?? ""
  );

  const googleKeywords = campaignConfiguration.keywords
    .filter(k => k.status === GoogleAdsResourceStatus.Option.ENABLED)
    .map(k => ({
      text: k.text
    }));

  let walmartItemId = "";
  let walmartSearchPage = "";
  let targetUrl = campaignConfiguration.finalURL;
  const walmartSearchPhrase = campaignConfiguration.walmartSearchPhrase;
  if (walmartSearchPhrase) {
    walmartSearchPage =
      getWalmartSearchPageURL(
        marketplaceInfo,
        campaignConfiguration.walmartSearchPhrase,
        campaignConfiguration.walmartSearchBrands,
        campaignConfiguration.walmartSearchOtherFacets,
        campaignConfiguration.walmartSearchOtherParameters,
        true
      ) || "";
    if (walmartSearchPage) {
      // Let the target URL be generated from the Walmart fields.
      targetUrl = "";
    }
  } else if (campaignConfiguration.walmartItemIds.length) {
    walmartItemId = campaignConfiguration.walmartItemIds[0];
    // Let the target URL be generated from the Walmart fields.
    targetUrl = "";
  }

  const campaignToCopy: GoogleAdCampaign = {
    campaignId: 0,
    wantsDefaultAdCopy: false,
    marketplaceInfoField: marketplaceInfoField(marketplaceInfo),
    attributionAdvertiserField: attributionField(attributionAdvertiser),
    campaignNameField: campaignNameField(newCampaignName),
    asinField: asinField(campaignConfiguration.asin),
    walmartProfileNameField: walmartProfileNameField(
      campaignConfiguration.walmartProfileName
    ),
    walmartBrandsField: walmartBrandsField(
      campaignConfiguration.walmartSearchBrands
    ),
    walmartItemIdField: walmartItemIdField(walmartItemId),
    walmartSearchPageField: walmartSearchPageField(walmartSearchPage),
    targetUrlField: targetUrlField(targetUrl),
    budgetMicrosField: budgetMicrosField(
      campaignConfiguration.campaignBudget * MICROS_TO_CURRENCY_UNIT_FACTOR
    ),
    maxCPCMicrosField: maxCPCField(campaignConfiguration.campaignMaxCPCMicros),
    seedKeywordsField: seedKeywordsField(campaignConfiguration.searchTerms),
    googleKeywordsField: googleKeywordsField(googleKeywords),
    geotargetsField: geotargetsField(campaignConfiguration.geotargets),
    customHeadlinesField: headlinesField(
      campaignConfiguration.headlines.map(h => {
        return {
          _id: h.text,
          text: h.text,
          position: h.pinPosition,
          errors: new Set<string>()
        };
      }) ?? []
    ),
    customDescriptionsField: descriptionsField(
      campaignConfiguration.descriptions.map(h => {
        return {
          _id: h.text,
          text: h.text,
          position: h.pinPosition,
          errors: new Set<string>()
        };
      }) ?? []
    ),
    pausingAutomation: campaignConfiguration.pausingAutomationEnabled
      ? {
          enabled: campaignConfiguration.pausingAutomationEnabled,
          costThresholdMicros:
            campaignConfiguration.pausingAutomationCostThresholdMicros,
          AACOSThresholdPoints:
            campaignConfiguration.pausingAutomationAACOSThresholdPoints,
          reevaluatePausedKeywords:
            campaignConfiguration.pausingAutomationReevaluatePausedKeywords,
          minNumActiveKeywords:
            campaignConfiguration.pausingAutomationMinNumActiveKeywords,
          itemPrice: 0
        }
      : null
  };

  if (!attributionAdvertiser) {
    campaignToCopy.attributionAdvertiserField.errors.add(
      "We were not able to find the attribution account originally used to create this campaign. \
        Please select a new attribution account."
    );
  }

  return {
    copyCampaignData: campaignToCopy,
    copyCampaignDataIsLoading: false,
    copyCampaignError: false
  };
};

export const isValidCampaign = (campaign: GoogleAdCampaign): boolean => {
  const asin = selectAsin(campaign);
  const walmartItemId = selectWalmartItemId(campaign);
  const walmartSearchPage = selectWalmartSearchPage(campaign);

  // Of the different types of targets, validate the one that is set.
  // Ultimately, as long as we have a targetURL (whether explicit or generated)
  // we can create a campaign.
  let targetField = campaign.targetUrlField;
  let attributionField;
  if (asin) {
    targetField = campaign.asinField;
    attributionField = campaign.attributionAdvertiserField;
  } else if (walmartItemId) {
    targetField = campaign.walmartItemIdField;
  } else if (walmartSearchPage) {
    targetField = campaign.walmartSearchPageField;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldsToValidate: Array<FormField<any>> = [
    targetField,
    campaign.budgetMicrosField,
    campaign.seedKeywordsField,
    campaign.googleKeywordsField,
    campaign.campaignNameField,
    campaign.targetUrlField,
    campaign.geotargetsField
  ];

  if (attributionField) {
    fieldsToValidate.push(attributionField);
  }

  if (!campaign.wantsDefaultAdCopy) {
    fieldsToValidate.push(campaign.customHeadlinesField);
    fieldsToValidate.push(campaign.customDescriptionsField);
  }

  return fieldsToValidate.every(field => field.errors.size === 0);
};

/* 
  Actions 
*/
interface UpdateStageAction {
  name: "UpdateStage";
  data: CampaignSetupStage;
}

interface UpdateCurrentReviewCampaignIndexAction {
  name: "UpdateCurrentReviewCampaignIndex";
  data: number;
}

// Set 1 marketplace info for all campaigns. This is the expected use case in for the majority
// of customers.
interface UpdateAllMarketplaceInfoAction {
  name: "UpdateAllMarketplaceInfo";
  data: { marketplaceInfo: MarketplaceInfo | None; errors?: Set<string> };
}

// Set 1 attribution profile for all campaigns. This is the expected use case in for the majority
// of customers.
interface UpdateAllCampaignsAmazonAttributionProfileAction {
  name: "UpdateAllCampaignsAmazonAttributionProfile";
  data: {
    attributionAdvertiser: AttributionAdvertiser | None;
    errors?: Set<string>;
  };
}

// Update a single campaign's attribution profile if it should be different from the profile
// selected in the Select Your Marketplace step.
interface UpdateAmazonAttributionProfileAction {
  name: "UpdateAmazonAttributionProfile";
  data: {
    index: number;
    attributionAdvertiser: AttributionAdvertiser | None;
    errors?: Set<string>;
  };
}

// Set 1 walmart attribution profile for all campaigns. This is the expected use case in for the majority
// of customers.
interface UpdateAllCampaignsWalmartAttributionProfileAction {
  name: "UpdateAllCampaignsWalmartAttributionProfile";
  data: {
    profileName: string;
    profileBrandNames: Array<string>;
    errors?: Set<string>;
  };
}

// Update a single campaign's Walmart attribution profile if it should be different from the profile
// selected in the Select Your Marketplace step.
interface UpdateWalmartAttributionProfileAction {
  name: "UpdateWalmartAttributionProfile";
  data: {
    index: number;
    profileName: string;
    errors?: Set<string>;
  };
}

// Set 1 list of geotargets for all campaigns. This is the expected use case in for the majority
// of customers.
interface UpdateAllCampaignsGeotargetsAction {
  name: "UpdateAllCampaignsGeotargets";
  data: { geotargets: Array<number>; errors?: Set<string> };
}

// Update a single campaign's geotargets if they should be different from those selected in the
// Attribution and Country step.
interface UpdateGeotargetsAction {
  name: "UpdateGeotargets";
  data: { index: number; geotargets: Array<number>; errors?: Set<string> };
}

interface AttachCampaignAction {
  name: "AttachCampaign";
  data: GoogleAdCampaign;
}

interface RemoveCampaignAction {
  name: "RemoveCampaign";
  data: number;
}

interface UpdateAsinAction {
  name: "UpdateAsin";
  data: { index: number; asin: string; errors?: Set<string> };
}

interface UpdateWalmartProfileNameAction {
  name: "UpdateWalmartProfileName";
  data: { index: number; profileName: string; errors?: Set<string> };
}

interface UpdateWalmartBrandsAction {
  name: "UpdateWalmartBrands";
  data: { index: number; brands: Array<string>; errors?: Set<string> };
}

interface UpdateWalmartItemIdAction {
  name: "UpdateWalmartItemId";
  data: { index: number; itemId: string; errors?: Set<string> };
}

interface UpdateWalmartSearchPageAction {
  name: "UpdateWalmartSearchPage";
  data: { index: number; searchPage: string; errors?: Set<string> };
}

interface UpdateCampaignBudgetAction {
  name: "UpdateBudget";
  data: { index: number; budgetMicros: number; errors?: Set<string> };
}

interface UpdateSeedKeywordsAction {
  name: "UpdateSeedKeywords";
  data: { index: number; keywords: Array<string>; errors?: Set<string> };
}

interface UpdateGoogleKeywordsAction {
  name: "UpdateGoogleKeywords";
  data: { index: number; keywords: Array<GoogleKeyword>; errors?: Set<string> };
}

interface UpdateCampaignNameAction {
  name: "UpdateCampaignName";
  data: { index: number; campaignName: string; errors?: Set<string> };
}

interface UpdateTargetUrlAction {
  name: "UpdateTargetUrl";
  data: { index: number; targetUrl: string; errors?: Set<string> };
}

interface UpdateCustomHeadlinesAction {
  name: "UpdateCustomHeadlines";
  data: {
    index: number;
    customHeadlines: Array<AdHeadline>;
    errors?: Set<string>;
  };
}

interface UpdateCustomDescriptionsAction {
  name: "UpdateCustomDescriptions";
  data: {
    index: number;
    customDescriptions: Array<AdDescription>;
    errors?: Set<string>;
  };
}

interface UpdateCampaignIdAction {
  name: "UpdateCampaignId";
  data: { index: number; campaignId: number };
}

interface UpdateWantsDefaultAdCopyAction {
  name: "UpdateWantsDefaultAdCopy";
  data: { index: number; wantsDefaultCopy: boolean };
}

interface UpdateMaxCPCAction {
  name: "UpdateMaxCPC";
  data: { index: number; maxCPCMicros: number; errors?: Set<string> };
}

interface UpdateCampaignAction {
  name: "UpdateCampaign";
  data: { index: number; campaign: GoogleAdCampaign };
}

interface UpdatePausingAutomationConfigAction {
  name: "UpdatePausingAutomationConfig";
  data: { index: number; pausingAutomation: PausingAutomationConfig };
}

export type CampaignSetupAction =
  | UpdateCurrentReviewCampaignIndexAction
  | UpdateStageAction
  | UpdateAllMarketplaceInfoAction
  | UpdateAllCampaignsAmazonAttributionProfileAction
  | UpdateAmazonAttributionProfileAction
  | UpdateAllCampaignsWalmartAttributionProfileAction
  | UpdateWalmartAttributionProfileAction
  | UpdateAllCampaignsGeotargetsAction
  | UpdateGeotargetsAction
  | AttachCampaignAction
  | RemoveCampaignAction
  | UpdateAsinAction
  | UpdateWalmartProfileNameAction
  | UpdateWalmartBrandsAction
  | UpdateWalmartItemIdAction
  | UpdateWalmartSearchPageAction
  | UpdateCampaignBudgetAction
  | UpdateSeedKeywordsAction
  | UpdateGoogleKeywordsAction
  | UpdateCampaignNameAction
  | UpdateTargetUrlAction
  | UpdateCustomHeadlinesAction
  | UpdateCustomDescriptionsAction
  | UpdateCampaignIdAction
  | UpdateWantsDefaultAdCopyAction
  | UpdateMaxCPCAction
  | UpdateCampaignAction
  | UpdatePausingAutomationConfigAction;

const updateFormField = <T,>(
  formField: FormField<T>,
  value: T,
  actionErrors?: Set<string>
) => {
  const validationErrors = formField.validator(value);
  const errors = new Set<string>([
    ...(actionErrors || []),
    ...(validationErrors || [])
  ]);

  return { ...formField, _value: value, errors };
};

/* 
  Reducer
*/
export function campaignSetupPageReducer(
  state: CampaignSetupState,
  action: CampaignSetupAction
): CampaignSetupState {
  switch (action.name) {
    // Form level actions
    case "UpdateCurrentReviewCampaignIndex":
      return {
        ...state,
        currentReviewCampaignIndex: action.data
      };
    case "UpdateStage":
      return {
        ...state,
        stage: action.data
      };

    case "UpdateAllMarketplaceInfo": {
      const newMarketplaceInfo = action.data.marketplaceInfo || null;

      if (
        window.location.href &&
        window.history &&
        window.history.replaceState
      ) {
        const url = new URL(window.location.href);
        if (newMarketplaceInfo) {
          url.searchParams.set(
            MARKETPLACE_DOMAIN_URL_PARAM,
            newMarketplaceInfo.domain
          );
        } else {
          url.searchParams.delete(MARKETPLACE_DOMAIN_URL_PARAM);
        }
        window.history.replaceState(null, "", url.toString());
      }

      return {
        ...state,
        marketplaceInfo: newMarketplaceInfo,
        campaigns: state.campaigns.map(campaign => ({
          ...campaign,
          marketplaceInfoField: updateFormField(
            campaign.marketplaceInfoField,
            action.data.marketplaceInfo,
            action.data.errors
          )
        }))
      };
    }

    case "UpdateAllCampaignsAmazonAttributionProfile": {
      const newAttributionAdvertiser =
        action.data.attributionAdvertiser || null;

      if (
        window.location.href &&
        window.history &&
        window.history.replaceState
      ) {
        const url = new URL(window.location.href);
        if (newAttributionAdvertiser) {
          url.searchParams.set(
            PROFILE_URL_PARAM,
            newAttributionAdvertiser.profileIdStr
          );
          url.searchParams.set(
            ADVERTISER_URL_PARAM,
            newAttributionAdvertiser.advertiserIdStr
          );
        } else {
          url.searchParams.delete(PROFILE_URL_PARAM);
          url.searchParams.delete(ADVERTISER_URL_PARAM);
        }
        window.history.replaceState(null, "", url.toString());
      }

      return {
        ...state,
        attributionAdvertiser: newAttributionAdvertiser,
        campaigns: state.campaigns.map(campaign => ({
          ...campaign,
          attributionAdvertiserField: updateFormField(
            campaign.attributionAdvertiserField,
            action.data.attributionAdvertiser,
            action.data.errors
          )
        }))
      };
    }

    case "UpdateAllCampaignsWalmartAttributionProfile": {
      const newProfileName = action.data.profileName;
      const newProfileBrandNames = action.data.profileBrandNames;

      if (
        window.location.href &&
        window.history &&
        window.history.replaceState
      ) {
        const url = new URL(window.location.href);
        if (newProfileName) {
          url.searchParams.set(PROFILE_URL_PARAM, newProfileName);
          url.searchParams.delete(ADVERTISER_URL_PARAM);
        } else {
          url.searchParams.delete(PROFILE_URL_PARAM);
          url.searchParams.delete(ADVERTISER_URL_PARAM);
        }
        window.history.replaceState(null, "", url.toString());
      }

      return {
        ...state,
        walmartProfileName: newProfileName,
        campaigns: state.campaigns.map(campaign => {
          let brandsField = campaign.walmartBrandsField;
          if (!_.isEmpty(newProfileBrandNames)) {
            brandsField = updateFormField(
              brandsField,
              newProfileBrandNames,
              action.data.errors
            );
          }

          return {
            ...campaign,
            walmartProfileNameField: updateFormField(
              campaign.walmartProfileNameField,
              action.data.profileName,
              action.data.errors
            ),
            walmartBrandsField: brandsField
          };
        })
      };
    }

    case "UpdateAllCampaignsGeotargets":
      return {
        ...state,
        geotargets: action.data.geotargets,
        campaigns: state.campaigns.map(campaign => ({
          ...campaign,
          geotargetsField: updateFormField(
            campaign.geotargetsField,
            action.data.geotargets,
            action.data.errors
          )
        }))
      };
    case "AttachCampaign":
      return {
        ...state,
        campaigns: [...state.campaigns, action.data]
      };
    case "RemoveCampaign":
      return {
        ...state,
        campaigns: [
          ...state.campaigns.slice(0, action.data),
          ...state.campaigns.slice(action.data + 1)
        ]
      };
    // Campaign level actions
    case "UpdateAmazonAttributionProfile":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            attributionAdvertiserField: updateFormField(
              campaign.attributionAdvertiserField,
              action.data.attributionAdvertiser,
              action.data.errors
            ),
            targetUrlField: updateFormField(campaign.targetUrlField, "")
          };
        })
      };

    case "UpdateWalmartAttributionProfile":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            walmartProfileNameField: updateFormField(
              campaign.walmartProfileNameField,
              action.data.profileName,
              action.data.errors
            )
          };
        })
      };

    case "UpdateGeotargets":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            geotargetsField: updateFormField(
              campaign.geotargetsField,
              action.data.geotargets,
              action.data.errors
            )
          };
        })
      };

    case "UpdateAsin":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          const newCampaign = {
            ...campaign,
            asinField: updateFormField(
              campaign.asinField,
              action.data.asin,
              action.data.errors
            )
          };

          if (action.data.asin) {
            newCampaign.walmartItemIdField = updateFormField(
              campaign.walmartItemIdField,
              ""
            );
            newCampaign.walmartSearchPageField = updateFormField(
              campaign.walmartSearchPageField,
              ""
            );
            // TODO: Consider clearing out keywords/copy as well since they no longer apply to the
            // newly selected target.
            newCampaign.targetUrlField = updateFormField(
              campaign.targetUrlField,
              ""
            );
          }

          return newCampaign;
        })
      };

    case "UpdateWalmartProfileName":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            walmartProfileName: updateFormField(
              campaign.walmartProfileNameField,
              action.data.profileName,
              action.data.errors
            )
          };
        })
      };
    case "UpdateWalmartBrands":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            walmartBrandsField: updateFormField(
              campaign.walmartBrandsField,
              action.data.brands,
              action.data.errors
            ),
            // TODO: Consider clearing out keywords/copy as well since they no longer apply to the
            // newly selected target.
            targetUrlField: updateFormField(campaign.targetUrlField, "")
          };
        })
      };
    case "UpdateWalmartItemId":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          const newCampaign = {
            ...campaign,
            walmartItemIdField: updateFormField(
              campaign.walmartItemIdField,
              action.data.itemId,
              action.data.errors
            )
          };

          if (action.data.itemId) {
            newCampaign.asinField = updateFormField(campaign.asinField, "");
            newCampaign.walmartSearchPageField = updateFormField(
              campaign.walmartSearchPageField,
              ""
            );
            // TODO: Consider clearing out keywords/copy as well since they no longer apply to the
            // newly selected target.
            newCampaign.targetUrlField = updateFormField(
              campaign.targetUrlField,
              ""
            );
          }

          return newCampaign;
        })
      };
    case "UpdateWalmartSearchPage":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          const newCampaign = {
            ...campaign,
            walmartSearchPageField: updateFormField(
              campaign.walmartSearchPageField,
              action.data.searchPage,
              action.data.errors
            ),
            // TODO: Consider clearing out keywords/copy as well since they no longer apply to the
            // newly selected target.
            targetUrlField: updateFormField(campaign.targetUrlField, "")
          };

          if (action.data.searchPage) {
            newCampaign.asinField = updateFormField(campaign.asinField, "");
            newCampaign.walmartItemIdField = updateFormField(
              campaign.walmartItemIdField,
              ""
            );
            // TODO: Consider clearing out keywords/copy as well since they no longer apply to the
            // newly selected target.
            newCampaign.targetUrlField = updateFormField(
              campaign.targetUrlField,
              ""
            );
          }

          return newCampaign;
        })
      };

    case "UpdateBudget":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            budgetMicrosField: updateFormField(
              campaign.budgetMicrosField,
              action.data.budgetMicros,
              action.data.errors
            )
          };
        })
      };
    case "UpdateSeedKeywords":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            seedKeywordsField: updateFormField(
              campaign.seedKeywordsField,
              action.data.keywords,
              action.data.errors
            ),

            // reset google keywords when the seeds change
            googleKeywordsField: updateFormField(
              campaign.googleKeywordsField,
              null
            )
          };
        })
      };
    case "UpdateGoogleKeywords":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            googleKeywordsField: updateFormField(
              campaign.googleKeywordsField,
              action.data.keywords,
              action.data.errors
            )
          };
        })
      };
    case "UpdateCampaignName":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            campaignNameField: updateFormField(
              campaign.campaignNameField,
              action.data.campaignName,
              action.data.errors
            )
          };
        })
      };
    case "UpdateTargetUrl":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            targetUrlField: updateFormField(
              campaign.targetUrlField,
              action.data.targetUrl,
              action.data.errors
            )
          };
        })
      };
    case "UpdateCustomHeadlines":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            customHeadlinesField: updateFormField(
              campaign.customHeadlinesField,
              action.data.customHeadlines,
              action.data.errors
            )
          };
        })
      };
    case "UpdateCustomDescriptions":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            customDescriptionsField: updateFormField(
              campaign.customDescriptionsField,
              action.data.customDescriptions,
              action.data.errors
            )
          };
        })
      };
    case "UpdateCampaignId":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            campaignId: action.data.campaignId
          };
        })
      };
    case "UpdateWantsDefaultAdCopy":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            wantsDefaultAdCopy: action.data.wantsDefaultCopy
          };
        })
      };

    case "UpdateMaxCPC":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }

          return {
            ...campaign,
            maxCPCMicrosField: updateFormField(
              campaign.maxCPCMicrosField,
              action.data.maxCPCMicros,
              action.data.errors
            )
          };
        })
      };
    case "UpdateCampaign":
      return {
        ...state,
        campaigns: state.campaigns.length
          ? state.campaigns.map((campaign, i) => {
              if (i !== action.data.index) {
                return campaign;
              }

              return {
                ...action.data.campaign
              };
            })
          : [{ ...action.data.campaign }]
      };
    case "UpdatePausingAutomationConfig":
      return {
        ...state,
        campaigns: state.campaigns.map((campaign, i) => {
          if (i !== action.data.index) {
            return campaign;
          }
          return {
            ...campaign,
            pausingAutomation: {
              ...action.data.pausingAutomation
            }
          };
        })
      };
  }
}

/*
  Selectors
*/
const selectHeadlines = (
  campaign: GoogleAdCampaign,
  rating?: number,
  ratingsTotal?: number
): Array<AdHeadline> => {
  if (!campaign || !campaign.googleKeywordsField._value) {
    return [];
  }

  const headlines = campaign.wantsDefaultAdCopy
    ? getDefaultHeadlines(
        campaign.marketplaceInfoField._value,
        campaign.googleKeywordsField._value.map(keyword => keyword.text),
        rating ?? 0,
        ratingsTotal ?? 0
      )
    : campaign.customHeadlinesField._value;

  return headlines;
};

const selectDescriptions = (
  campaign: GoogleAdCampaign
): Array<AdDescription> => {
  if (!campaign) {
    return [];
  }

  const descriptions = campaign.wantsDefaultAdCopy
    ? getDefaultDescriptions(campaign.marketplaceInfoField._value)
    : campaign.customDescriptionsField._value;

  return descriptions;
};

const selectMarketplaceInfo = (
  campaign: GoogleAdCampaign,
  formState: CampaignSetupState
): MarketplaceInfo | null => {
  if (!campaign) {
    return null;
  }

  if (campaign.marketplaceInfoField._value) {
    return campaign.marketplaceInfoField._value;
  }

  return formState.marketplaceInfo;
};

const selectAttributionAdvertiser = (
  campaign: GoogleAdCampaign,
  formState: CampaignSetupState
): AttributionAdvertiser | null => {
  if (!campaign) {
    return null;
  }

  if (campaign.attributionAdvertiserField._value) {
    return campaign.attributionAdvertiserField._value;
  }

  return formState.attributionAdvertiser;
};

const selectBudgetMicros = (campaign: GoogleAdCampaign): number => {
  if (!campaign) {
    return -1;
  }

  return campaign.budgetMicrosField._value;
};

const selectAsin = (campaign: GoogleAdCampaign): string => {
  if (!campaign) {
    return "";
  }

  return campaign.asinField._value;
};

const selectWalmartProfileName = (campaign: GoogleAdCampaign): string => {
  if (!campaign) {
    return "";
  }

  return campaign.walmartProfileNameField._value;
};

const selectWalmartBrands = (campaign: GoogleAdCampaign): Array<string> => {
  if (!campaign) {
    return [];
  }

  return campaign.walmartBrandsField._value;
};

const selectWalmartItemId = (campaign: GoogleAdCampaign): string => {
  if (!campaign) {
    return "";
  }

  return campaign.walmartItemIdField._value;
};

const selectWalmartSearchPage = (campaign: GoogleAdCampaign): string => {
  if (!campaign) {
    return "";
  }

  return campaign.walmartSearchPageField._value;
};

const selectWalmartSearchPhrase = (campaign: GoogleAdCampaign): string => {
  if (!campaign) {
    return "";
  }

  const searchPage = campaign.walmartSearchPageField._value;
  if (!searchPage) {
    return "";
  }

  const urlInfo = extractWalmartURLInfoFromString(searchPage);
  return urlInfo.searchPhrase || "";
};

const selectCampaignName = (
  campaign: GoogleAdCampaign,
  formState: CampaignSetupState
): string => {
  if (!campaign) {
    return "";
  }

  const campaignName = campaign?.campaignNameField?._value;

  if (campaignName !== "") {
    return campaignName;
  }

  const marketplaceInfo = selectMarketplaceInfo(campaign, formState);

  const asin = selectAsin(campaign);
  const walmartItemId = selectWalmartItemId(campaign);
  const walmartSearchPhrase = selectWalmartSearchPhrase(campaign);
  const headlines = selectHeadlines(campaign);

  if (asin) {
    return getDefaultCampaignName(
      marketplaceInfo,
      asin,
      headlines[0]?.text || ""
    );
  } else if (walmartItemId) {
    return getDefaultCampaignName(
      marketplaceInfo,
      walmartItemId,
      headlines[0]?.text || ""
    );
  } else if (walmartSearchPhrase) {
    return getDefaultCampaignName(
      marketplaceInfo,
      "SERP",
      walmartSearchPhrase || headlines[0]?.text || ""
    );
  }

  return getDefaultCampaignName(marketplaceInfo, "", headlines[0]?.text || "");
};

const selectGeotargets = (
  campaign: GoogleAdCampaign,
  formState: CampaignSetupState
): Array<number> => {
  if (!campaign) {
    return [];
  }

  if (campaign.geotargetsField._value.length > 0) {
    return campaign.geotargetsField._value;
  }

  return formState.geotargets;
};

const selectGoogleKeywords = (
  campaign: GoogleAdCampaign
): Array<GoogleKeyword> => {
  if (!campaign) {
    return [];
  }

  return campaign.googleKeywordsField._value || [];
};

const selectMaxCPCMicros = (campaign: GoogleAdCampaign): number => {
  if (!campaign) {
    return -1;
  }

  return campaign.maxCPCMicrosField._value;
};

const selectTargetUrl = (campaign: GoogleAdCampaign): string => {
  if (!campaign) {
    return "";
  }

  return campaign.targetUrlField._value;
};

// Return is a campaign has enough information to generate a final URL.  This function
// doesn't actually return the final URL here because we may want to consider
// Rainforest/Bluecart product data and this is just a lightweight check.
// If we can't load the product data, we can always generate simple detail page URL
// given any ASIN or Walmart Item ID.
export const canConstructFinalUrl = (campaign: GoogleAdCampaign): boolean => {
  if (!campaign) {
    return false;
  }

  return (
    !!selectTargetUrl(campaign) ||
    !!selectAsin(campaign) ||
    !!selectWalmartItemId(campaign) ||
    !!selectWalmartSearchPage(campaign)
  );
};

const selectSeedKeywords = (campaign: GoogleAdCampaign): Array<string> => {
  if (!campaign) {
    return [];
  }

  return campaign.seedKeywordsField._value;
};

export const defaultPausingAutomationConfig = (
  itemPrice: number
): PausingAutomationConfig => {
  return {
    enabled: true,
    costThresholdMicros:
      itemPrice *
      MICROS_TO_CURRENCY_UNIT_FACTOR *
      RECOMMENDED_COST_THRESHOLD_MULTIPLE,
    AACOSThresholdPoints: RECOMMENDED_AACOS_THRESHOLD,
    reevaluatePausedKeywords: true,
    minNumActiveKeywords: RECOMMENDED_MIN_ACTIVE,
    itemPrice
  };
};

// Queries any of the third-party data that can be useful when creating
// a new campaign.  The hook also returns the final URL that should be used for
// creating the ad.  The finalUrl returned is either the TargetURL field value (if explicitly
// set during the Review stage), the canonical URL found in the Amazon/Rainforest
// or Walmart/Bluecart product data (if loaded), the constructed detail page
// URL based on the ASIN or Walmart Item ID (if product data was not loaded),
// or the constructed Walmart Search Page URL.  The defaultFinalUrl returned
// ignores any explicitly set TargetURL.
const useSelectTargetInfo = (
  siteAlias: string,
  campaign: GoogleAdCampaign,
  state: CampaignSetupState
): {
  isLoading: boolean;
  error: string | null;
  amazonProductData?: GetAmazonProductReply.AsObject;
  walmartProductData?: GetWalmartProductReply.AsObject;
  walmartSearchData?: GetWalmartSearchPageReply.AsObject;
  finalUrl: string;
  defaultFinalUrl: string;
} => {
  const marketplaceInfo = selectMarketplaceInfo(campaign, state);
  const asin = selectAsin(campaign);
  const walmartItemId = selectWalmartItemId(campaign);
  const walmartSearchPage = selectWalmartSearchPage(campaign);
  const targetUrl = selectTargetUrl(campaign);

  const {
    data: amazonProductData,
    isFetching: amazonProductIsFetching,
    error: amazonProductError
  } = useAmazonProduct(siteAlias, asin, getAmazonMarketplace(marketplaceInfo));

  const {
    data: walmartProductData,
    isFetching: walmartProductIsFetching,
    error: walmartProductError
  } = useWalmartProduct(
    siteAlias,
    walmartItemId,
    getWalmartMarketplace(marketplaceInfo)
  );

  // Look for brands by searching for the initialBrand or any string typed in by the user.
  const {
    data: walmartSearchData,
    isFetching: walmartSearchIsFetching,
    error: walmartSearchError
  } = useWalmartSearchPage(
    siteAlias,
    walmartSearchPage,
    null,
    getWalmartMarketplace(marketplaceInfo)
  );

  // Prefer the explicit target URL, then prefer the product's canonical URL from
  // rainforest/bluecart,
  let defaultFinalUrl =
    amazonProductData?.product?.link ||
    walmartProductData?.product?.link ||
    walmartSearchPage ||
    "";

  // If we cannot load canonical URLs, try to generate a detail page URL.
  if (!defaultFinalUrl) {
    if (asin && isAmazonMarketplaceInfo(marketplaceInfo)) {
      defaultFinalUrl = getDetailPageURLForASIN(marketplaceInfo, asin) || "";
    } else if (walmartItemId && isWalmartMarketplaceInfo(marketplaceInfo)) {
      defaultFinalUrl =
        getWalmartDetailPageURLForItemId(marketplaceInfo, walmartItemId) || "";
    }
  }

  return {
    // Return isLoading true if no queries are active or if an active query is fetching.
    isLoading:
      (!asin && !walmartItemId && !walmartSearchPage) ||
      (!!asin && amazonProductIsFetching) ||
      (!!walmartItemId && walmartProductIsFetching) ||
      (!!walmartSearchPage && walmartSearchIsFetching),
    error:
      extractErrorMessage(amazonProductError) ||
      extractErrorMessage(walmartProductError) ||
      extractErrorMessage(walmartSearchError) ||
      null,
    amazonProductData,
    walmartProductData,
    walmartSearchData,
    finalUrl: targetUrl || defaultFinalUrl,
    defaultFinalUrl
  };
};

const useSelectPausingAutomationConfig = (
  siteAlias: string,
  campaign: GoogleAdCampaign,
  formState: CampaignSetupState
): {
  isLoading: boolean;
  hasError: boolean;
  config: PausingAutomationConfig;
} => {
  const {
    isLoading,
    error,
    amazonProductData,
    walmartProductData,
    walmartSearchData
  } = useSelectTargetInfo(siteAlias, campaign, formState);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  if (campaign.pausingAutomation) {
    return {
      isLoading: false,
      hasError: false,
      config: campaign.pausingAutomation
    };
  }

  if (isLoading) {
    return {
      isLoading: true,
      hasError: false,
      config: defaultPausingAutomationConfig(0)
    };
  }

  let itemPrice = 0;
  if (amazonProductData) {
    itemPrice = amazonProductData?.product?.buyboxWinner?.price?.value || 0;
  }
  if (walmartProductData) {
    itemPrice = walmartProductData?.product?.buyboxWinner?.price || 0;
  }
  if (walmartSearchData) {
    // Take the average of up to the first four items (with prices).
    let itemCount = 0;
    let itemSum = 0;
    for (const product of walmartSearchData?.productsList) {
      if (product.primaryPrice) {
        itemSum += product.primaryPrice;
        itemCount++;
      }
      if (itemCount >= 4) {
        break;
      }
    }
    if (itemCount) {
      itemPrice = itemSum / itemCount;
    }
  }

  if (error || !itemPrice) {
    return {
      isLoading: false,
      hasError: true,
      config: defaultPausingAutomationConfig(0)
    };
  }

  return {
    isLoading: false,
    hasError: false,
    config: defaultPausingAutomationConfig(itemPrice)
  };
};

const useSelectPriceRange = (
  siteAlias: string,
  campaign: GoogleAdCampaign,
  formState: CampaignSetupState
): {
  isLoading: boolean;
  hasError: boolean;
  minPrice: number;
  maxPrice: number;
} => {
  const {
    isLoading,
    error,
    amazonProductData,
    walmartProductData,
    walmartSearchData
  } = useSelectTargetInfo(siteAlias, campaign, formState);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  if (isLoading || error) {
    return {
      isLoading: isLoading,
      hasError: !!error,
      minPrice: 0,
      maxPrice: 0
    };
  }

  let minPrice = 0;
  let maxPrice = 0;
  if (amazonProductData) {
    minPrice = maxPrice =
      amazonProductData?.product?.buyboxWinner?.price?.value || 0;
  }
  if (walmartProductData) {
    minPrice = maxPrice = walmartProductData?.product?.buyboxWinner?.price || 0;
  }
  if (walmartSearchData) {
    for (const product of walmartSearchData?.productsList) {
      if (product.primaryPrice) {
        if (!minPrice || minPrice > product.primaryPrice) {
          minPrice = product.primaryPrice;
        }
        if (!maxPrice || maxPrice < product.primaryPrice) {
          maxPrice = product.primaryPrice;
        }
      }
    }
  }

  return {
    isLoading: false,
    hasError: false,
    minPrice,
    maxPrice
  };
};

interface CampaignSetupSelectors {
  selectHeadlines: (
    campaign: GoogleAdCampaign,
    rating?: number,
    ratingsTotal?: number
  ) => Array<AdHeadline>;
  selectDescriptions: (campaign: GoogleAdCampaign) => Array<AdDescription>;
  selectMarketplaceInfo: (
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => MarketplaceInfo | null;
  selectAttributionAdvertiser: (
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => AttributionAdvertiser | null;
  selectBudgetMicros: (campaign: GoogleAdCampaign) => number;
  selectCampaignName: (
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => string;
  selectGeotargets: (
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => Array<number>;
  selectGoogleKeywords: (campaign: GoogleAdCampaign) => Array<GoogleKeyword>;
  selectMaxCPCMicros: (campaign: GoogleAdCampaign) => number;
  selectAsin: (campaign: GoogleAdCampaign) => string;
  selectWalmartProfileName: (campaign: GoogleAdCampaign) => string;
  selectWalmartBrands: (campaign: GoogleAdCampaign) => Array<string>;
  selectWalmartItemId: (campaign: GoogleAdCampaign) => string;
  selectWalmartSearchPage: (campaign: GoogleAdCampaign) => string;
  selectWalmartSearchPhrase: (campaign: GoogleAdCampaign) => string;
  selectTargetUrl: (campaign: GoogleAdCampaign) => string;
  selectSeedKeywords: (campaign: GoogleAdCampaign) => Array<string>;
  useSelectPausingAutomationConfig: (
    siteAlias: string,
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => {
    isLoading: boolean;
    hasError: boolean;
    config: PausingAutomationConfig;
  };
  useSelectTargetInfo: (
    siteAlias: string,
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => {
    isLoading: boolean;
    error: string | null;
    amazonProductData?: GetAmazonProductReply.AsObject;
    walmartProductData?: GetWalmartProductReply.AsObject;
    walmartSearchData?: GetWalmartSearchPageReply.AsObject;
    finalUrl: string;
    defaultFinalUrl: string;
  };
  useSelectPriceRange: (
    siteAlias: string,
    campaign: GoogleAdCampaign,
    formState: CampaignSetupState
  ) => {
    isLoading: boolean;
    hasError: boolean;
    minPrice: number;
    maxPrice: number;
  };
}
export const campaignSetupSelectors: CampaignSetupSelectors = {
  selectHeadlines,
  selectDescriptions,
  selectMarketplaceInfo,
  selectAttributionAdvertiser,
  selectBudgetMicros,
  selectCampaignName,
  selectGeotargets,
  selectGoogleKeywords,
  selectMaxCPCMicros,
  selectAsin,
  selectWalmartProfileName,
  selectWalmartBrands,
  selectWalmartItemId,
  selectWalmartSearchPage,
  selectWalmartSearchPhrase,
  selectTargetUrl,
  selectSeedKeywords,
  useSelectPausingAutomationConfig,
  useSelectTargetInfo,
  useSelectPriceRange
};

export const getCurrentCampaignAndSelectors = (
  state: CampaignSetupState
): [GoogleAdCampaign, CampaignSetupSelectors] => {
  return [
    state.campaigns[state.currentReviewCampaignIndex] || createEmptyCampaign(),
    campaignSetupSelectors
  ];
};

export const useCreateCampaignArgs = (
  currentSite: Site,
  state: CampaignSetupState,
  allowPartialFailures = false
): {
  args: CreateGoogleAdsCampaignArgs | null;
  isLoading: boolean;
  error: string;
} => {
  const { siteFeatures } = useSessionSite();
  const scrubCampaignNamesAndAddId = siteFeatures.scrubCampaignNamesWithId;

  const adwordsAccount = currentSite.adwordsAccounts[0];

  const [
    campaign,
    {
      selectMarketplaceInfo,
      selectAttributionAdvertiser,
      selectCampaignName,
      selectGeotargets,
      selectDescriptions,
      selectHeadlines,
      selectMaxCPCMicros,
      selectAsin,
      selectWalmartProfileName,
      selectWalmartItemId,
      selectWalmartSearchPage,
      selectBudgetMicros,
      selectGoogleKeywords,
      selectSeedKeywords,
      useSelectPausingAutomationConfig,
      useSelectTargetInfo,
      useSelectPriceRange
    }
  ] = getCurrentCampaignAndSelectors(state);

  const campaignName = selectCampaignName(campaign, state);
  const geotargets = selectGeotargets(campaign, state);
  const descriptions = selectDescriptions(campaign);
  const maxCPCMicros = selectMaxCPCMicros(campaign);
  const asin = selectAsin(campaign);
  const walmartProfileName = selectWalmartProfileName(campaign);
  const walmartItemId = selectWalmartItemId(campaign);
  const walmartSearchPage = selectWalmartSearchPage(campaign);
  const budget = selectBudgetMicros(campaign);
  const googleKeywords = selectGoogleKeywords(campaign);
  const seedKeywords = selectSeedKeywords(campaign);
  const attributionAdvertiser = selectAttributionAdvertiser(campaign, state);
  const marketplaceInfo =
    selectMarketplaceInfo(campaign, state) ||
    (attributionAdvertiser
      ? getAmazonMarketplaceInfo(attributionAdvertiser.marketplace)
      : amazonUSMarketplaceInfo);

  const { config: pausingAutomation } = useSelectPausingAutomationConfig(
    currentSite.siteAlias,
    campaign,
    state
  );

  const { minPrice, maxPrice } = useSelectPriceRange(
    currentSite.siteAlias,
    campaign,
    state
  );

  const {
    isLoading,
    error,
    amazonProductData,
    walmartProductData,
    walmartSearchData,
    finalUrl
  } = useSelectTargetInfo(currentSite.siteAlias, campaign, state);

  if (isLoading) {
    return {
      args: null,
      isLoading: true,
      error: ""
    };
  }

  if (error) {
    return {
      args: null,
      isLoading: false,
      error: extractErrorMessage(error)
    };
  }

  let rating = 0;
  let ratingsTotal = 0;
  if (amazonProductData) {
    rating = amazonProductData.product?.rating ?? 0;
    ratingsTotal = amazonProductData.product?.ratingsTotal ?? 0;
  } else if (walmartProductData) {
    rating = walmartProductData.product?.rating ?? 0;
    ratingsTotal = walmartProductData.product?.ratingsTotal ?? 0;
  }

  const headlines = selectHeadlines(campaign, rating, ratingsTotal);

  if (!finalUrl) {
    return {
      args: null,
      isLoading: false,
      error: `Missing required fields: 
      ${
        isAmazonMarketplaceInfo(marketplaceInfo)
          ? "ASIN"
          : "Item Id or Search Page"
      }`
    };
  }

  const googleAdsConversionFactor =
    googleAdsCurrencies.get(adwordsAccount.currencyCode)?.conversion || 1;

  const currentCampaignMaxCPCMicros = maxCPCMicros * googleAdsConversionFactor;

  // Get some extra information for walmart campaigns.
  const walmartItemBrand = walmartProductData?.product?.brand || "";
  const walmartSearchItemIds = [];
  if (walmartSearchData) {
    for (const product of walmartSearchData.productsList) {
      if (product.itemId) {
        walmartSearchItemIds.push(product.itemId);
      }
    }
    // Don't sort, leave most relevant items first.
  }

  return {
    args: {
      marketplaceInfo: marketplaceInfo || amazonUSMarketplaceInfo,
      asin: asin,
      walmartProfileName: walmartProfileName,
      walmartItemId: walmartItemId,
      walmartItemBrand: walmartItemBrand,
      walmartSearchPage: walmartSearchPage,
      walmartSearchItemIds: walmartSearchItemIds,
      attributionAdvertiserIdStr: attributionAdvertiser?.advertiserIdStr || "",
      attributionProfileIdStr: attributionAdvertiser?.profileIdStr || "",
      budgetMicros: budget,
      campaignName: campaignName,
      currencyCode: adwordsAccount.currencyCode,
      descriptions: descriptions.filter(d => d.text.trim().length > 0),
      finalURL: finalUrl,
      geotargetsList: geotargets,
      googleAdsCustomerId: adwordsAccount.customerId,
      headlines: headlines.filter(h => h.text.trim().length > 0),
      keywords: googleKeywords.map(kw => kw.text),
      maxCpcMicros: currentCampaignMaxCPCMicros,
      productTitle: descriptions[0]?.text,
      searchTermsToTrack: seedKeywords,
      siteAlias: currentSite.siteAlias,
      targetURL: finalUrl,
      validateOnly: false,
      allowPartialFailures,
      scrubCampaignNamesAndAddId,
      pausingAutomation: pausingAutomation,
      minPrice: minPrice,
      maxPrice: maxPrice
    },
    isLoading: false,
    error: ""
  };
};

function pickUniqueCampaignName(
  campaignName: string,
  allCampaignNames: Array<string>
): string {
  let newCampaignName = (campaignName || "").replace("[Ampd] ", "");

  while (newCampaignName) {
    const nextCampaignName = newCampaignName.replace(
      /\[(\d+)\]/,
      (m, p1) => `[${parseInt(p1, 10) + 1}]`
    );
    if (nextCampaignName !== newCampaignName) {
      newCampaignName = nextCampaignName;
    } else {
      newCampaignName = `${newCampaignName} [2]`;
    }

    const existingCampaign = _.find(
      allCampaignNames,
      `[Ampd] ${newCampaignName}`
    );
    if (!existingCampaign) {
      break;
    }
  }

  return newCampaignName;
}
