// Table for user to check or uncheck individual campaigns for the report.
import React from "react";

import { Icon, Loader, Table } from "semantic-ui-react";
import { StickyTableHeader } from "../MetricColumns";
import styled from "styled-components/macro";
import { generatePath } from "react-router-dom";
import { AMPD_ROOT } from "../../ExtensionV2";

const Container = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow-y: auto;
  max-height: 350px;
  flex-grow: 1;
`;

export function CampaignSelectorTable(props) {
  const {
    loading,
    siteAlias,
    siteLabel = "Brand: ",
    sortedCampaignInfos,
    reportCampaignIds,
    checkStateBySite,
    onToggleSite,
    onToggleCampaign
  } = props;

  return (
    <Container>
      {loading ? (
        <Loader active>Loading campaigns</Loader>
      ) : (
        <Table
          style={{ border: 0, fontSize: "small" }}
          compact="very"
          size="small"
        >
          <StickyTableHeader>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell collapsing />
              <Table.HeaderCell collapsing>Status</Table.HeaderCell>
              <Table.HeaderCell>Campaign</Table.HeaderCell>
            </Table.Row>
          </StickyTableHeader>
          <Table.Body>
            {sortedCampaignInfos.map((campaignInfo, index) =>
              campaignInfo.campaignId ? (
                <Table.Row
                  style={{ fontSize: "smaller" }}
                  key={index}
                  onClick={() => onToggleCampaign(campaignInfo.campaignId)}
                >
                  <Table.Cell />
                  <Table.Cell>
                    {!!campaignInfo.campaignId && (
                      <Icon
                        size="large"
                        name={
                          reportCampaignIds.has(campaignInfo.campaignId)
                            ? "check square outline"
                            : "square outline"
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>{campaignInfo.campaign?.status}</Table.Cell>
                  <Table.Cell>{campaignInfo.campaign?.name}</Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row
                  key={index}
                  onClick={() => onToggleSite(campaignInfo.siteAlias)}
                >
                  <Table.Cell colSpan={4}>
                    <Icon
                      size="large"
                      name={
                        checkStateBySite.get(campaignInfo.siteAlias) ===
                        CHECK_STATE_PARTIAL
                          ? "minus square outline"
                          : checkStateBySite.get(campaignInfo.siteAlias) ===
                            CHECK_STATE_ON
                          ? "check square outline"
                          : "square outline"
                      }
                    />
                    {siteLabel}
                    <strong>{campaignInfo.siteName}</strong> (
                    {campaignInfo.siteAlias === siteAlias ? (
                      campaignInfo.siteAlias
                    ) : (
                      <a
                        href={generateSiteHref(campaignInfo.siteAlias)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {campaignInfo.siteAlias}
                      </a>
                    )}
                    )
                  </Table.Cell>
                </Table.Row>
              )
            )}
            <Table.Row>
              <Table.Cell colSpan={4} />
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </Container>
  );
}

export const CHECK_STATE_ON = 1;
export const CHECK_STATE_OFF = 0;
export const CHECK_STATE_PARTIAL = -1;

// Determine the new check state based on the current check state and the
// target check state.
export function determineCheckState(currentCheckState, targetCheckState) {
  if (currentCheckState == null) {
    return targetCheckState;
  }

  if (currentCheckState !== targetCheckState) {
    return CHECK_STATE_PARTIAL;
  }

  return currentCheckState;
}

// Returns a full URL for the specified site alias's Google Ads Performance page.
function generateSiteHref(siteAlias) {
  return new URL(
    generatePath(AMPD_ROOT, { siteAlias }),
    window.location.origin
  );
}
