import { useCallback } from "react";
import { useQueryClient, QueryKey } from "@tanstack/react-query";

// Returns a function that can be used to invalidate one or more React queries
// based on a full or partial queryKey.  If the argument to the returned function
// is an array, then it is used as the (full or partial) queryKey passed to
// invalidateQueries.  However, if the argument is not already an array, then
// it is put in a singleton array before being passed to invalidateQueries.
export function useInvalidateQueries(): (
  fullOrPartialQueryKey: QueryKey | unknown
) => Promise<void> {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    async (fullOrPartialQueryKey: QueryKey | unknown): Promise<void> => {
      const queryKey: QueryKey = Array.isArray(fullOrPartialQueryKey)
        ? fullOrPartialQueryKey
        : [fullOrPartialQueryKey];

      queryClient.invalidateQueries(queryKey);
    },
    [queryClient]
  );

  return invalidate;
}
