import React, { useMemo } from "react";

import { Icon, Message } from "semantic-ui-react";
import { Flex } from "@rebass/grid";

import EditLabelsButton from "./EditLabelsButton";
import GenerateReportButton from "./GenerateReportButton";
import { pluralize } from "Common/utils/strings";
import { LinkBillingAccountConfirmationModalLauncher } from "./LinkBillingAccountConfirmationModal";
import { ClientSite } from "ExtensionV2/grpc/getClientSitesForManagerSite";
import { None } from "Common/utils/tsUtils";
import { ClientLabels } from "ExtensionV2/pages/AccountsPage/AccountsTable";
import Immutable from "immutable";
import { ClientSiteRowData } from "ExtensionV2/pages/AccountsPage/getClientSiteRowData";

// ref: core/entity/adapter/pgSiteManagerLink/pgSiteManagerLink.go:178
// TODO: update SiteManagerLinkClient to use an enum.
const NONE_BILLING_ACCOUNT_STATUS_STRING = "none";

const AccountsTableCheckedOperations: React.FC<{
  managerSiteAlias: string;
  clientSites: Array<ClientSite>;
  checkedSiteAliases: Array<string>;
  siteRowDataBySiteMap: Immutable.Map<string, ClientSiteRowData>;
  dateRangeStartDate: string | None;
  dateRangeEndDate: string | None;
  siteLabelsByAlias: Record<string, string[]>;
  userLabels: Array<string>;
  nonUserLabels: Array<string>;
  updateClientLabels: (args: ClientLabels) => void;
  onClose: () => void;
}> = ({
  managerSiteAlias,
  clientSites,
  checkedSiteAliases,
  siteRowDataBySiteMap,
  dateRangeStartDate,
  dateRangeEndDate,
  siteLabelsByAlias,
  userLabels,
  nonUserLabels,
  updateClientLabels,
  onClose
}) => {
  const campaignIdsToReport = useMemo(() => {
    const campaignIds: Array<string> = [];
    for (const siteAlias of checkedSiteAliases) {
      const siteRowData = siteRowDataBySiteMap.get(siteAlias);
      if (!siteRowData) {
        continue;
      }

      for (const campaignRowData of siteRowData.campaignRowDataList) {
        if (!campaignRowData.campaignId) {
          continue;
        }
        campaignIds.push(campaignRowData.campaignId);
      }
    }

    return campaignIds;
  }, [checkedSiteAliases, siteRowDataBySiteMap]);

  const handleCloseClicked = () => {
    if (onClose) {
      onClose();
    }
  };

  const selectedClientSites = clientSites.filter(clientSite =>
    checkedSiteAliases.includes(clientSite.clientSiteAlias)
  );

  const linkableClientSites = selectedClientSites.filter(clientSite => {
    return (
      clientSite.billingStatusDescription === NONE_BILLING_ACCOUNT_STATUS_STRING
    );
  });

  return (
    <>
      <Message
        style={{
          marginTop: 0,
          marginBottom: 2,
          paddingTop: "0.5em",
          paddingBottom: "0.5em"
        }}
        color="blue"
      >
        <Flex
          style={{ gap: "1em" }}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Icon name="close" onClick={handleCloseClicked} />

          <EditLabelsButton
            buttonColor="blue"
            buttonLabel="Assign Accounts"
            title={`Assign Users to ${pluralize(
              checkedSiteAliases.length,
              "Account"
            )}`}
            managerSiteAlias={managerSiteAlias}
            clientSiteAliases={checkedSiteAliases}
            siteLabelsByAlias={siteLabelsByAlias}
            knownLabels={userLabels}
            updateClientLabels={updateClientLabels}
          />
          <EditLabelsButton
            buttonColor="blue"
            buttonLabel="Edit Labels"
            title={`Edit Labels on ${pluralize(
              checkedSiteAliases.length,
              "Account"
            )}`}
            managerSiteAlias={managerSiteAlias}
            clientSiteAliases={checkedSiteAliases}
            siteLabelsByAlias={siteLabelsByAlias}
            knownLabels={nonUserLabels}
            updateClientLabels={updateClientLabels}
          />
          <GenerateReportButton
            buttonColor="blue"
            managerSiteAlias={managerSiteAlias}
            clientSites={clientSites}
            siteAliasesToReport={checkedSiteAliases}
            campaignIdsToReport={campaignIdsToReport}
            dateRangeStartDate={dateRangeStartDate}
            dateRangeEndDate={dateRangeEndDate}
          />
          {linkableClientSites.length > 0 && (
            <LinkBillingAccountConfirmationModalLauncher
              linkableClientSites={linkableClientSites}
            />
          )}
        </Flex>
      </Message>
    </>
  );
};

export default AccountsTableCheckedOperations;
