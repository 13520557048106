import React from "react";
import AuditSegment from "./AuditSegment";
import AmazonSellerConnection from "./AmazonSellerConnection";
import ManageAmazonAdvertising from "./ManageAmazonAdvertising";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";

const ManageAmazonAccountsPanel = ({ forceHideDisconnectLink = false }) => {
  const { siteAlias, amazonInfo } = useSessionSite();
  const { sellerAccounts = [], advertisingAccounts = [] } = amazonInfo;

  return (
    <>
      <AuditSegment
        siteAlias={siteAlias}
        icon={advertisingAccounts.length ? "check circle" : "warning circle"}
        color={advertisingAccounts.length ? "green" : "red"}
        title="Sign into your Amazon Advertising account"
      >
        <ManageAmazonAdvertising
          gaCategory={"Ampd: Connect Amazon Advertising Account"}
          // The disconnect flow opens a new modal which does not play nice with the overlay. The user
          // can always disconnect on the Settings page.
          forceHideDisconnectLink={forceHideDisconnectLink}
        />
      </AuditSegment>
      {!!advertisingAccounts.length && (
        <AuditSegment
          siteAlias={siteAlias}
          icon={sellerAccounts.length ? "check circle" : "warning circle"}
          color={sellerAccounts.length ? "green" : "red"}
          title="Connect Amazon Seller or Vendor account (Recommended)"
        >
          <AmazonSellerConnection
            gaCategory={"Ampd: Connect Amazon Seller or Vendor Account"}
            forceHideDisconnectLink={forceHideDisconnectLink}
          />
        </AuditSegment>
      )}
    </>
  );
};

export default ManageAmazonAccountsPanel;
