import React, { useCallback, useState } from "react";
import { Button, Icon, Message, Modal, Segment } from "semantic-ui-react";
import MaxCPCDropdown from "../MaxCPCDropdown";

import { extractErrorMessage } from "Common/errors/error";

import { getHumanReadableAmount } from "Common/utils/money";
import { updateCampaignMaxCPC } from "../../grpc/updateCampaignMaxCPC";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import SimpleTooltip from "../SimpleTooltip";
import { sendGAEvent } from "../GA";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";

const CampaignMaxCPCEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  disabled?: boolean;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  disabled
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <SimpleTooltip
        tooltip={
          disabled ? "Managed by Ampd Bid Automation" : "Edit Campaign Max CPC"
        }
      >
        <Icon
          disabled={disabled}
          name={"pencil"}
          style={{ cursor: "pointer", marginTop: -5 }}
          onClick={
            disabled
              ? undefined
              : () => {
                  sendGAEvent(
                    gaCategory,
                    `Click Edit Campaign Max CPC`,
                    siteAlias
                  );
                  setModalOpen(true);
                }
          }
        />
      </SimpleTooltip>
      {modalOpen && (
        <CampaignMaxCPCEditor
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

const CampaignMaxCPCEditor: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const {
    customerId,
    campaignId,
    currencyCode,
    campaignMaxCPCMicros
  } = itemizedCampaignConfiguration;

  const [modalOpen, setModalOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  /*
    To unset the max cpc set its value to 0. If there is no max cpc set for a campaign,
    google will return null in the proto. Google should never return a value of 0 for max cpc.
   */
  const [selectedMaxCPCMicros, setSelectedMaxCPCMicros] = useState(
    campaignMaxCPCMicros
  );

  const disableSubmit = () => {
    if (!!errorMessage || selectedMaxCPCMicros == null) {
      return true;
    }
    return campaignMaxCPCMicros == null
      ? selectedMaxCPCMicros === 0
      : selectedMaxCPCMicros === campaignMaxCPCMicros;
  };

  const getSubmitButtonText = () => {
    if (!!errorMessage || selectedMaxCPCMicros == null) {
      return "Provide a Max CPC";
    }
    return selectedMaxCPCMicros === 0
      ? "Disable Max CPC"
      : `Set Max CPC to ${getHumanReadableAmount(selectedMaxCPCMicros)}`;
  };

  const handleSubmit = async () => {
    if (!customerId || !campaignId) {
      return;
    }

    setIsSubmitting(true);
    try {
      await updateCampaignMaxCPC(
        siteAlias,
        gaCategory,
        String(customerId),
        String(campaignId),
        selectedMaxCPCMicros,
        currencyCode
      );
      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);
      console.error(e);
      setErrorMessage(message);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
        onClose();
      }}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
    >
      <Modal.Header>Change Maximum Cost Per Click Bid</Modal.Header>
      <Modal.Content scrolling>
        <Segment>
          <div style={{ minHeight: 350 }}>
            <MaxCPCDropdown
              gaCategory={gaCategory}
              siteAlias={siteAlias}
              title={"Maximum bid per click for this Google Ad campaign"}
              currency={currencyCode}
              maxCPCMicros={selectedMaxCPCMicros}
              setMaxCPCMicros={setSelectedMaxCPCMicros}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </Segment>
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setModalOpen(false);
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          primary={true}
          loading={isSubmitting}
          disabled={disableSubmit()}
          onClick={handleSubmit}
        >
          {getSubmitButtonText()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CampaignMaxCPCEditButton;
