import _ from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Form, Message } from "semantic-ui-react";

import { googleAdsCurrencies, getCurrencySymbol } from "Common/utils/googleAds";
import { getHumanReadableAmount } from "Common/utils/money";

const micros = 1e6;
const INITIAL_MAX_CPC_USD_MICROS = [
  0,
  1_000_000,
  1_500_000,
  2_000_000,
  3_000_000
];
export const RECOMMENDED_MAX_CPC_USD_MICROS = 1_500_000;

// Displays a dropdown for people to choose a maximum CPC bid.
export default function MaxCPCDropdown(props) {
  const {
    title,
    currency,
    disabled,

    maxCPCMicros,
    setMaxCPCMicros,
    dontRecommend,

    setErrorMessage
  } = props;

  const conversionAmount = googleAdsCurrencies.get(currency)?.conversion
    ? parseFloat(googleAdsCurrencies.get(currency)?.conversion).toPrecision(2)
    : 1;

  const recommendedAmount = RECOMMENDED_MAX_CPC_USD_MICROS * conversionAmount;

  /*
    provide some default options to make setting this value simpler for users
  */
  let initialCPCOptions = INITIAL_MAX_CPC_USD_MICROS.map(
    usdMicros => usdMicros * conversionAmount
  );

  if (maxCPCMicros != null && !initialCPCOptions.includes(maxCPCMicros)) {
    initialCPCOptions.push(maxCPCMicros);
    initialCPCOptions.sort((a, b) => a - b);
  }

  const [maxCPCMicrosValues, setMaxCPCMicrosValues] = useState(
    initialCPCOptions
  );

  useEffect(() => {
    // A value of zero means no-max, so it is valid.
    // A value of null means show the placeholder text so the user enters a value,
    // so don't replace it.
    // However, a negative value is invalid and should be replaced with the
    // recommended value.
    if (_.isNumber(maxCPCMicros) && maxCPCMicros < 0 && !dontRecommend) {
      setMaxCPCMicros(recommendedAmount);
    }
  }, [maxCPCMicros, dontRecommend, recommendedAmount, setMaxCPCMicros]);

  /*
    build the dropdown options
  */
  const dropdownOptions = useMemo(() => {
    const dropdownOptions = [];

    maxCPCMicrosValues.forEach(amountMicros => {
      if (amountMicros < 0) {
        return;
      }

      const displayAmount = getHumanReadableAmount(amountMicros, currency);
      dropdownOptions.push({
        key: amountMicros,
        text: (
          <>
            {amountMicros === 0
              ? "No maximum cost-per-click bid"
              : `${displayAmount} max per click`}
            {amountMicros === recommendedAmount && !dontRecommend && (
              <strong> ← Recommended</strong>
            )}
          </>
        ),
        value: amountMicros
      });
    });

    dropdownOptions.push(<Dropdown.Divider key="divider-1" />, {
      key: "new-amount-placeholder",
      text: "Enter a different amount...",
      value: null
    });

    return dropdownOptions;
  }, [maxCPCMicrosValues, dontRecommend, currency, recommendedAmount]);

  /*
    validate user provided CPCs and update the dropdown options list
  */
  useEffect(() => {
    const validateSelection = amount => {
      if (isNaN(amount) || amount < 0) {
        if (setErrorMessage) {
          setErrorMessage("Please provide a valid, positive number");
        }
        return false;
      } else {
        if (setErrorMessage) {
          setErrorMessage(null);
        }
        return true;
      }
    };

    if (validateSelection(maxCPCMicros)) {
      if (!maxCPCMicrosValues.includes(maxCPCMicros) && maxCPCMicros) {
        maxCPCMicrosValues.push(maxCPCMicros);
        maxCPCMicrosValues.sort((a, b) => a - b);
        setMaxCPCMicrosValues([...maxCPCMicrosValues]);
      }
    }
  }, [maxCPCMicros, maxCPCMicrosValues, setErrorMessage]);

  const _handleSelection = (_ev, optionsProps) => {
    let { value } = optionsProps;

    if (value == null) {
      setMaxCPCMicros(null);
      return;
    }

    // user provided values come in as currency amount strings and need to be converted to micros
    if (typeof value === "string") {
      value = Number(value);
      value *= micros;
    }

    setMaxCPCMicros(value);
  };

  const handleSelection = (ev, optionsProps) => {
    _handleSelection(ev, optionsProps);
  };

  const handleAddItem = (ev, optionsProps) => {
    _handleSelection(ev, optionsProps);
  };

  return (
    <>
      <Form>
        <Form.Field>
          {!!title && <label style={{ paddingBottom: 10 }}>{title}:</label>}
          <Dropdown
            disabled={disabled}
            allowAdditions={true}
            additionLabel={`Max per click: ${getCurrencySymbol(currency)} `}
            options={[...dropdownOptions]}
            value={maxCPCMicros}
            placeholder={"Select or enter a maximum cost-per-click bid"}
            fluid
            selection
            search={true}
            onAddItem={handleAddItem}
            onChange={handleSelection}
          />
        </Form.Field>
      </Form>
      <div style={{ paddingTop: 20 }}>
        <Message>
          <p>
            This determines the highest amount that you're willing to pay for a
            click on your ad.
          </p>
        </Message>
      </div>
    </>
  );
}
