import _ from "lodash";
import Immutable from "immutable";
import GOOGLE_ADS_CURRENCIES from "../assets/currencies/google_ads.json";
import { pluralize } from "./strings";

export const GOOGLE_ADS_DAYS_PER_MONTH = 30.4;

export const USA_GEOTARGET_ID = 2840;

export const MAX_KEYWORD_LENGTH = 80;

export const MAX_HEADLINE_LENGTH = 30;

export const MAX_DESCRIPTION_LENGTH = 90;

export const COUNT_REQUIRED_HEADLINES = 3;

export const COUNT_REQUIRED_DESCRIPTIONS = 2;

export const COUNT_MAX_HEADLINES = 15;

export const COUNT_MAX_DESCRIPTIONS = 4;

// Returns the site problem marking a Google Ads connection error, if any.
export const getGoogleAdsConnectionError = site => {
  const siteProblems = _.get(site, "siteProblems", []);

  return _.find(siteProblems, problem => {
    return _.get(problem, "error.errorType") === "data_source_link";
  });
};

export const formatCustomerId = (id, sep = "-") => {
  if (!id) {
    return "";
  }

  return id.length !== 10
    ? id
    : `${id.substring(0, 3)}${sep}${id.substring(3, 6)}${sep}${id.substring(
        6
      )}`;
};

/**
  Formats an adwords account for display in the Settings UI.
 * @param {DashboardSite.DashboardAdwordsAccountInfo.AsObject}  account
 * @param {boolean}  [includeCurrency=false]
 * @returns {string}
 */
export const formatAccount = (account, includeCurrency = false) => {
  const customerId = formatCustomerId(account.customerId);
  const name = account.name ? ` (${account.name})` : "";

  let formatted = customerId + name;
  if (includeCurrency) {
    formatted += !account.currencyCode
      ? " (no currency)"
      : ` (currency: ${account.currencyCode})`;
  }
  return formatted;
};

const goodCharRegexp = /\p{ASCII}|\p{L}|\p{N}/gu;
const possiblyExoticCharRegexp = /\p{Emoji}|\p{Math}|[•\xb7✓]/gu;
const verticalBarRegexp = /\|+/gu;
const repeatedPunctuationRegexp = /(\p{P})\1+/gu;
const repeatedSpacesRegexp = /  +/gu;
const badWhitespaceRegexp = /[\n\r\v\f\t]+/gu;
const initialPunctuationRegexp = /^\p{P}+/gu;
const doubleUpperLetterRegexp = /^\p{Lu}[^\p{Ll}\p{Lu}]*\p{Lu}/u;
const threeUpperCaseLettersInARowRegexp = /\b[A-Z]{3,}\b/g;

/**
  Returns an array of errors found in the text. These are things we know will likely get rejected by
  Google Ads if used as a description or headline.
 * @param {string}  text
 * @returns {Array.<string>}
 */
export function collectGoogleAdsTextErrors(text) {
  const errors = [];

  // Look for any Emoji.  Also look for special math characters, like arrows, and
  // known unwanted characters like middle dots and checks, but leave ascii chars,
  // letters (such as Greek letters), numbers, and punctuation.
  const possbilyExoticCharacter = text.match(possiblyExoticCharRegexp);
  if (possbilyExoticCharacter) {
    const exoticCharacters = possbilyExoticCharacter.filter(
      char => !char.match(goodCharRegexp)
    );
    if (exoticCharacters.length > 0) {
      errors.push(
        `${pluralize(
          exoticCharacters.length,
          "invalid character"
        )}: ${possbilyExoticCharacter.join(" ")}`
      );
    }
  }

  const verticalBar = text.match(verticalBarRegexp);
  if (verticalBar) {
    errors.push(`Invalid character: ${verticalBar[0]}`);
  }

  const repeatedPunctuation = text.match(repeatedPunctuationRegexp);
  if (repeatedPunctuation) {
    errors.push(`Repeated punctuation: ${repeatedPunctuation[0]}`);
  }

  const repeatedSpaces = text.match(repeatedSpacesRegexp);
  if (repeatedSpaces) {
    errors.push(`Repeated spaces`);
  }

  const badWhitespace = text.match(badWhitespaceRegexp);
  if (badWhitespace) {
    errors.push(`Invalid white space character.`);
  }

  const initialExlamation = text.indexOf("!");
  if (initialExlamation > -1) {
    let suffix = text.slice(initialExlamation + 1);
    if (suffix.indexOf("!") > -1) {
      errors.push(`Excessive exclamation marks`);
    }
  }

  const initialPunctuation = text.match(initialPunctuationRegexp);
  if (initialPunctuation) {
    errors.push(`Text cannot start with punctuation: ${initialPunctuation[0]}`);
  }

  const tripleUpperLetter = text.match(threeUpperCaseLettersInARowRegexp);
  if (tripleUpperLetter && tripleUpperLetter.length > 1) {
    errors.push(`Too many upper case letters in a row`);
  }

  return errors;
}

// Looks in a description string for any known invalid text characters.
export function findInvalidGoogleAdsDescriptionText(description) {
  if (!description) {
    return null;
  }

  // Look for any Emoji.  Also look for special math characters, like arrows, and
  // known unwanted characters like middle dots and checks, but leave ascii chars,
  // letters (such as Greek letters), numbers, and punctuation.
  let questionable = description.match(possiblyExoticCharRegexp);
  if (questionable) {
    questionable = questionable.filter(char => !char.match(goodCharRegexp));
    if (questionable.length > 0) {
      return [
        questionable[0],
        "Invalid character",
        "click to clean",
        s =>
          s
            .split("")
            .filter(char => char.match(goodCharRegexp))
            .join("")
            .replace(repeatedSpacesRegexp, " ")
            .trim()
      ];
    }
  }

  // Look for any vertical bars.
  questionable = description.match(verticalBarRegexp);
  if (questionable) {
    return [
      questionable[0],
      "Invalid character",
      "click to clean",
      s =>
        s
          .replace(verticalBarRegexp, " ")
          .replace(repeatedSpacesRegexp, " ")
          .trim()
    ];
  }

  // Look for any repeated punctuation like "...", "??", "!!".
  questionable = description.match(repeatedPunctuationRegexp);
  if (questionable) {
    return [questionable[0], "Repeated punctuation"];
  }

  // Look for any repeated spaces
  questionable = description.match(repeatedSpacesRegexp);
  if (questionable) {
    return [
      questionable[0],
      "Repeated spaces",
      "click to clean",
      s => s.replace(repeatedSpacesRegexp, " ").trim()
    ];
  }

  // Look for any newlines or other non-space white space.
  questionable = description.match(badWhitespaceRegexp);
  if (questionable) {
    return [
      questionable[0],
      "Invalid white space",
      "click to clean",
      s =>
        s
          .replace(badWhitespaceRegexp, " ")
          .replace(repeatedSpacesRegexp, " ")
          .trim()
    ];
  }

  // Look for any words with exclamation points, after any allowed first exclamation point.
  const markIndex = description.indexOf("!");
  if (markIndex >= 0) {
    const suffix = description.slice(markIndex + 1);

    questionable = suffix.match(/\S*!\S*/gu);
    if (questionable) {
      return [questionable[0], "Excessive exclamation marks"];
    }
  }

  // Look for any lines that start with punctuation characters.
  questionable = description.match(initialPunctuationRegexp);
  if (questionable) {
    return [
      questionable[0],
      "Initial punctuation",
      "click to clean",
      s =>
        s
          .replace(initialPunctuationRegexp, " ")
          .replace(repeatedSpacesRegexp, " ")
          .trim()
    ];
  }

  // Look for words that are all upper-case.
  const words = description.split(" ");
  let uppercaseWordCount = 0;
  let firstUppercaseWord = null;
  for (const word of words) {
    // Look for words that have all upper-case letters, but only consider "words"
    // that have at least two letters together.
    if (
      word.match(doubleUpperLetterRegexp) &&
      word === word.toLocaleUpperCase()
    ) {
      uppercaseWordCount++;
      if (!firstUppercaseWord) {
        firstUppercaseWord = word;
      }
      if (uppercaseWordCount >= 2) {
        return [
          firstUppercaseWord,
          "Too many upper-case words",
          "click to clean",
          _s => {
            const words = description.split(" ");
            for (let index = 0; index < words.length; index++) {
              const word = words[index];
              if (
                word.match(doubleUpperLetterRegexp) &&
                word === word.toLocaleUpperCase()
              ) {
                words[index] = _.capitalize(word);
              }
            }

            return words.join(" ");
          }
        ];
      }
    }
  }

  return null;
}

// Returns the symbol for the given currency, or the empty string if unknown.
export const getCurrencySymbol = currency => {
  const currencyDetails = googleAdsCurrencies.get(currency);
  if (!currencyDetails) {
    return "";
  }

  return currencyDetails.symbol || currencyDetails.code || "";
};

// Returns the minimum unit for a CPC bid for the given currency, falling back
// to 1e6 if unknown.
export const getCurrencyMinimumUnit = currency => {
  // It seems like all minimum units are 1e4 or 1e6.
  const defaultMinimumUnit = 1e6;

  const currencyDetails = googleAdsCurrencies.get(currency);
  if (!currencyDetails) {
    return defaultMinimumUnit;
  }

  return currencyDetails.minimumUnit || defaultMinimumUnit;
};

// Returns the specified amount (in micros) rounded to the specified currency's
// minimum unit.  If allowZero is false, then no value less than one minimum
// unit will be returned.
export const roundMicrosToCurrencyMinimumUnit = (
  amountMicros,
  currencyCode,
  allowZero = true
) => {
  // Google Ads doesn't like values that are less the currency's minimum unit,
  // so we need to round the micro values to it.
  const currencyMinimumMicros = getCurrencyMinimumUnit(currencyCode);

  const rounded =
    Math.round(amountMicros / currencyMinimumMicros) * currencyMinimumMicros;

  if (rounded <= 0) {
    return allowZero ? 0 : currencyMinimumMicros;
  }

  return rounded;
};

export const convertValueToUSD = ({ value, currency }) => {
  if (currency === "USD") {
    return value;
  }

  if (!currency) {
    console.error("convertValueToUSD(): no currency was provided");
    return NaN;
  }

  const currencyDetails = googleAdsCurrencies.get(currency);
  if (!currencyDetails) {
    console.error(`convertValueToUSD(): could not find currency "${currency}"`);
    return NaN;
  }

  const conversion = _.get(currencyDetails, "conversion");
  if (!conversion) {
    console.error(
      `convertValueToUSD(): currency "${currency}" has no conversion rate`
    );
    return NaN;
  }

  let usdValue = value / conversion;

  return Math.round((usdValue + Number.EPSILON) * 100) / 100;
};

// Given a budget value in USD and a currency, returns a relative budget value
// for that currency.
//
// The returned value will be scaled to the new currency and rounded to the
// nearest integer value with at most three significant digits.
//
// If the currency doesn't exist in the googleAdsCurrencies, NaN is returned.
export const computeBudgetRecommendation = ({ usdValue, currency }) => {
  if (!currency) {
    console.error("computeBudgetRecommendation(): no currency was provided");
    return NaN;
  }

  const currencyDetails = googleAdsCurrencies.get(currency);
  if (!currencyDetails) {
    console.error(
      `computeBudgetRecommendation(): could not find currency "${currency}"`
    );
    return NaN;
  }

  const conversion = _.get(currencyDetails, "conversion");
  if (!conversion) {
    console.error(
      `computeBudgetRecommendation(): currency "${currency}" has no conversion rate`
    );
    return NaN;
  }

  let budgetValue = usdValue * conversion;

  // Reduce to three significant digits, then round to the nearest integer.
  budgetValue = Number.parseFloat(budgetValue).toPrecision(3);
  budgetValue = Math.round(budgetValue);

  // ...but ensure we never return anything less than one.
  if (budgetValue < 1) {
    budgetValue = 1;
  }

  return budgetValue;
};

// Table of currencies that we support when creating an account. This is a
// subset of the currencies defined by Google Ads at
// https://developers.google.com/adwords/api/docs/appendix/codes-formats.
//
// Some currencies have been excluded because they are obsolete, and others
// because they were not in the Google Ads UI and may be restricted.
// The conversions are automated into the Base spreadsheet:
// Base:     https://docs.google.com/spreadsheets/d/1Fc7W2bfOyBW6ejlqvgQ-nupyfWfPn1JxrhpMUD7pHRE/edit
//
// 02/28/24: https://docs.google.com/spreadsheets/d/1_gkb8hSxmkLJ2fcgkamawSGrsNOL3ErschP65rXrP3c/edit#gid=507266881
// 04/04/23: https://docs.google.com/spreadsheets/d/1_gkb8hSxmkLJ2fcgkamawSGrsNOL3ErschP65rXrP3c/edit#gid=0
// 10/24/22: https://docs.google.com/spreadsheets/d/1ge6POrs8Ejr5WvkTTjCp1ARssgCypu_yC2XGl7sn1Bc/edit#gid=0
// Older:    https://docs.google.com/spreadsheets/d/12tEtkDRy0ClorkXgZthNT522D8XM0Hsy6_H0ljR36Bk/edit#gid=0
//
// The GoogleAds currencies definition file can be found at:
//   data/currency/google_ads.json
// and has been symlinked into JS for use at:
//   js/react/src/Common/assets/currencies/google_ads.json
//
// Example currency:
//   {
//     "USD": {
//       "code": "USD",
//       "name": "US Dollars",
//       "symbol": "$",
//       "conversion": 1,
//       "minimumUnit": 10000
//     },
//     ...
export const googleAdsCurrencies = Immutable.OrderedMap(GOOGLE_ADS_CURRENCIES);

// List of timezones recognized by Google Ads.
//
// From https://developers.google.com/adwords/api/docs/appendix/codes-formats.
export const googleAdsTimezones = Immutable.List.of(
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/San_Juan",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Bahia",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Costa_Rica",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Vevay",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Martinique",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Center",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Porto_Velho",
  "America/Port_of_Spain",
  "America/Puerto_Rico",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Rio_Branco",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Katmandu",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/Stanley",
  "Atlantic/St_Helena",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Etc/GMT",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap"
);

// List of Geotargets from Google Ads.
//
// From https://developers.google.com/adwords/api/docs/appendix/geotargeting.
export const googleAdsGeotargets = Immutable.List.of(
  {
    criteriaId: 2004,
    name: "Afghanistan"
  },
  {
    criteriaId: 2008,
    name: "Albania"
  },
  {
    criteriaId: 2012,
    name: "Algeria"
  },
  {
    criteriaId: 2016,
    name: "American Samoa"
  },
  {
    criteriaId: 2020,
    name: "Andorra"
  },
  {
    criteriaId: 2024,
    name: "Angola"
  },
  {
    criteriaId: 2660,
    name: "Anguilla"
  },
  {
    criteriaId: 2010,
    name: "Antarctica"
  },
  {
    criteriaId: 2028,
    name: "Antigua and Barbuda"
  },
  {
    criteriaId: 2032,
    name: "Argentina"
  },
  {
    criteriaId: 2051,
    name: "Armenia"
  },
  {
    criteriaId: 2533,
    name: "Aruba"
  },
  {
    criteriaId: 2036,
    name: "Australia"
  },
  {
    criteriaId: 2040,
    name: "Austria"
  },
  {
    criteriaId: 2031,
    name: "Azerbaijan"
  },
  {
    criteriaId: 2048,
    name: "Bahrain"
  },
  {
    criteriaId: 2050,
    name: "Bangladesh"
  },
  {
    criteriaId: 2052,
    name: "Barbados"
  },
  {
    criteriaId: 2112,
    name: "Belarus"
  },
  {
    criteriaId: 2056,
    name: "Belgium"
  },
  {
    criteriaId: 2084,
    name: "Belize"
  },
  {
    criteriaId: 2204,
    name: "Benin"
  },
  {
    criteriaId: 2060,
    name: "Bermuda"
  },
  {
    criteriaId: 2064,
    name: "Bhutan"
  },
  {
    criteriaId: 2068,
    name: "Bolivia"
  },
  {
    criteriaId: 2070,
    name: "Bosnia and Herzegovina"
  },
  {
    criteriaId: 2072,
    name: "Botswana"
  },
  {
    criteriaId: 2074,
    name: "Bouvet Island"
  },
  {
    criteriaId: 2076,
    name: "Brazil"
  },
  {
    criteriaId: 2086,
    name: "British Indian Ocean Territory"
  },
  {
    criteriaId: 2092,
    name: "British Virgin Islands"
  },
  {
    criteriaId: 2096,
    name: "Brunei"
  },
  {
    criteriaId: 2100,
    name: "Bulgaria"
  },
  {
    criteriaId: 2854,
    name: "Burkina Faso"
  },
  {
    criteriaId: 2108,
    name: "Burundi"
  },
  {
    criteriaId: 2116,
    name: "Cambodia"
  },
  {
    criteriaId: 2120,
    name: "Cameroon"
  },
  {
    criteriaId: 2124,
    name: "Canada"
  },
  {
    criteriaId: 2132,
    name: "Cape Verde"
  },
  {
    criteriaId: 2535,
    name: "Caribbean Netherlands"
  },
  {
    criteriaId: 2136,
    name: "Cayman Islands"
  },
  {
    criteriaId: 2140,
    name: "Central African Republic"
  },
  {
    criteriaId: 2148,
    name: "Chad"
  },
  {
    criteriaId: 2152,
    name: "Chile"
  },
  {
    criteriaId: 2156,
    name: "China"
  },
  {
    criteriaId: 2162,
    name: "Christmas Island"
  },
  {
    criteriaId: 2166,
    name: "Cocos (Keeling) Islands"
  },
  {
    criteriaId: 2170,
    name: "Colombia"
  },
  {
    criteriaId: 2174,
    name: "Comoros"
  },
  {
    criteriaId: 2184,
    name: "Cook Islands"
  },
  {
    criteriaId: 2188,
    name: "Costa Rica"
  },
  {
    criteriaId: 2384,
    name: "Cote d'Ivoire"
  },
  {
    criteriaId: 2191,
    name: "Croatia"
  },
  {
    criteriaId: 2531,
    name: "Curacao"
  },
  {
    criteriaId: 2196,
    name: "Cyprus"
  },
  {
    criteriaId: 2203,
    name: "Czechia"
  },
  {
    criteriaId: 2180,
    name: "Democratic Republic of the Congo"
  },
  {
    criteriaId: 2208,
    name: "Denmark"
  },
  {
    criteriaId: 2262,
    name: "Djibouti"
  },
  {
    criteriaId: 2212,
    name: "Dominica"
  },
  {
    criteriaId: 2214,
    name: "Dominican Republic"
  },
  {
    criteriaId: 2218,
    name: "Ecuador"
  },
  {
    criteriaId: 2818,
    name: "Egypt"
  },
  {
    criteriaId: 2222,
    name: "El Salvador"
  },
  {
    criteriaId: 2226,
    name: "Equatorial Guinea"
  },
  {
    criteriaId: 2232,
    name: "Eritrea"
  },
  {
    criteriaId: 2233,
    name: "Estonia"
  },
  {
    criteriaId: 2748,
    name: "Eswatini"
  },
  {
    criteriaId: 2231,
    name: "Ethiopia"
  },
  {
    criteriaId: 2238,
    name: "Falkland Islands (Islas Malvinas)"
  },
  {
    criteriaId: 2234,
    name: "Faroe Islands"
  },
  {
    criteriaId: 2583,
    name: "Federated States of Micronesia"
  },
  {
    criteriaId: 2242,
    name: "Fiji"
  },
  {
    criteriaId: 2246,
    name: "Finland"
  },
  {
    criteriaId: 2250,
    name: "France"
  },
  {
    criteriaId: 2254,
    name: "French Guiana"
  },
  {
    criteriaId: 2258,
    name: "French Polynesia"
  },
  {
    criteriaId: 2260,
    name: "French Southern and Antarctic Lands"
  },
  {
    criteriaId: 2266,
    name: "Gabon"
  },
  {
    criteriaId: 2268,
    name: "Georgia"
  },
  {
    criteriaId: 2276,
    name: "Germany"
  },
  {
    criteriaId: 2288,
    name: "Ghana"
  },
  {
    criteriaId: 2292,
    name: "Gibraltar"
  },
  {
    criteriaId: 2300,
    name: "Greece"
  },
  {
    criteriaId: 2304,
    name: "Greenland"
  },
  {
    criteriaId: 2308,
    name: "Grenada"
  },
  {
    criteriaId: 2312,
    name: "Guadeloupe"
  },
  {
    criteriaId: 2316,
    name: "Guam"
  },
  {
    criteriaId: 2320,
    name: "Guatemala"
  },
  {
    criteriaId: 2831,
    name: "Guernsey"
  },
  {
    criteriaId: 2324,
    name: "Guinea"
  },
  {
    criteriaId: 2624,
    name: "Guinea-Bissau"
  },
  {
    criteriaId: 2328,
    name: "Guyana"
  },
  {
    criteriaId: 2332,
    name: "Haiti"
  },
  {
    criteriaId: 2334,
    name: "Heard Island and McDonald Islands"
  },
  {
    criteriaId: 2340,
    name: "Honduras"
  },
  {
    criteriaId: 2344,
    name: "Hong Kong"
  },
  {
    criteriaId: 2348,
    name: "Hungary"
  },
  {
    criteriaId: 2352,
    name: "Iceland"
  },
  {
    criteriaId: 2356,
    name: "India"
  },
  {
    criteriaId: 2360,
    name: "Indonesia"
  },
  {
    criteriaId: 2368,
    name: "Iraq"
  },
  {
    criteriaId: 2372,
    name: "Ireland"
  },
  {
    criteriaId: 2376,
    name: "Israel"
  },
  {
    criteriaId: 2380,
    name: "Italy"
  },
  {
    criteriaId: 2388,
    name: "Jamaica"
  },
  {
    criteriaId: 2392,
    name: "Japan"
  },
  {
    criteriaId: 2832,
    name: "Jersey"
  },
  {
    criteriaId: 2400,
    name: "Jordan"
  },
  {
    criteriaId: 2398,
    name: "Kazakhstan"
  },
  {
    criteriaId: 2404,
    name: "Kenya"
  },
  {
    criteriaId: 2296,
    name: "Kiribati"
  },
  {
    criteriaId: 2900,
    name: "Kosovo"
  },
  {
    criteriaId: 2414,
    name: "Kuwait"
  },
  {
    criteriaId: 2417,
    name: "Kyrgyzstan"
  },
  {
    criteriaId: 2418,
    name: "Laos"
  },
  {
    criteriaId: 2428,
    name: "Latvia"
  },
  {
    criteriaId: 2422,
    name: "Lebanon"
  },
  {
    criteriaId: 2426,
    name: "Lesotho"
  },
  {
    criteriaId: 2430,
    name: "Liberia"
  },
  {
    criteriaId: 2434,
    name: "Libya"
  },
  {
    criteriaId: 2438,
    name: "Liechtenstein"
  },
  {
    criteriaId: 2440,
    name: "Lithuania"
  },
  {
    criteriaId: 2442,
    name: "Luxembourg"
  },
  {
    criteriaId: 2446,
    name: "Macao"
  },
  {
    criteriaId: 2450,
    name: "Madagascar"
  },
  {
    criteriaId: 2454,
    name: "Malawi"
  },
  {
    criteriaId: 2458,
    name: "Malaysia"
  },
  {
    criteriaId: 2462,
    name: "Maldives"
  },
  {
    criteriaId: 2466,
    name: "Mali"
  },
  {
    criteriaId: 2470,
    name: "Malta"
  },
  {
    criteriaId: 2584,
    name: "Marshall Islands"
  },
  {
    criteriaId: 2474,
    name: "Martinique"
  },
  {
    criteriaId: 2478,
    name: "Mauritania"
  },
  {
    criteriaId: 2480,
    name: "Mauritius"
  },
  {
    criteriaId: 2175,
    name: "Mayotte"
  },
  {
    criteriaId: 2484,
    name: "Mexico"
  },
  {
    criteriaId: 2498,
    name: "Moldova"
  },
  {
    criteriaId: 2492,
    name: "Monaco"
  },
  {
    criteriaId: 2496,
    name: "Mongolia"
  },
  {
    criteriaId: 2499,
    name: "Montenegro"
  },
  {
    criteriaId: 2500,
    name: "Montserrat"
  },
  {
    criteriaId: 2504,
    name: "Morocco"
  },
  {
    criteriaId: 2508,
    name: "Mozambique"
  },
  {
    criteriaId: 2104,
    name: "Myanmar (Burma)"
  },
  {
    criteriaId: 2516,
    name: "Namibia"
  },
  {
    criteriaId: 2520,
    name: "Nauru"
  },
  {
    criteriaId: 2524,
    name: "Nepal"
  },
  {
    criteriaId: 2528,
    name: "Netherlands"
  },
  {
    criteriaId: 2540,
    name: "New Caledonia"
  },
  {
    criteriaId: 2554,
    name: "New Zealand"
  },
  {
    criteriaId: 2558,
    name: "Nicaragua"
  },
  {
    criteriaId: 2562,
    name: "Niger"
  },
  {
    criteriaId: 2566,
    name: "Nigeria"
  },
  {
    criteriaId: 2570,
    name: "Niue"
  },
  {
    criteriaId: 2574,
    name: "Norfolk Island"
  },
  {
    criteriaId: 2807,
    name: "North Macedonia"
  },
  {
    criteriaId: 2580,
    name: "Northern Mariana Islands"
  },
  {
    criteriaId: 2578,
    name: "Norway"
  },
  {
    criteriaId: 2512,
    name: "Oman"
  },
  {
    criteriaId: 2586,
    name: "Pakistan"
  },
  {
    criteriaId: 2585,
    name: "Palau"
  },
  {
    criteriaId: 2275,
    name: "Palestine"
  },
  {
    criteriaId: 2591,
    name: "Panama"
  },
  {
    criteriaId: 2598,
    name: "Papua New Guinea"
  },
  {
    criteriaId: 2600,
    name: "Paraguay"
  },
  {
    criteriaId: 2604,
    name: "Peru"
  },
  {
    criteriaId: 2608,
    name: "Philippines"
  },
  {
    criteriaId: 2612,
    name: "Pitcairn Islands"
  },
  {
    criteriaId: 2616,
    name: "Poland"
  },
  {
    criteriaId: 2620,
    name: "Portugal"
  },
  {
    criteriaId: 2630,
    name: "Puerto Rico"
  },
  {
    criteriaId: 2634,
    name: "Qatar"
  },
  {
    criteriaId: 2178,
    name: "Republic of the Congo"
  },
  {
    criteriaId: 2638,
    name: "Reunion"
  },
  {
    criteriaId: 2642,
    name: "Romania"
  },
  {
    criteriaId: 2643,
    name: "Russia"
  },
  {
    criteriaId: 2646,
    name: "Rwanda"
  },
  {
    criteriaId: 2654,
    name: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    criteriaId: 2659,
    name: "Saint Kitts and Nevis"
  },
  {
    criteriaId: 2662,
    name: "Saint Lucia"
  },
  {
    criteriaId: 2666,
    name: "Saint Pierre and Miquelon"
  },
  {
    criteriaId: 2670,
    name: "Saint Vincent and the Grenadines"
  },
  {
    criteriaId: 2882,
    name: "Samoa"
  },
  {
    criteriaId: 2674,
    name: "San Marino"
  },
  {
    criteriaId: 2678,
    name: "Sao Tome and Principe"
  },
  {
    criteriaId: 2682,
    name: "Saudi Arabia"
  },
  {
    criteriaId: 2686,
    name: "Senegal"
  },
  {
    criteriaId: 2688,
    name: "Serbia"
  },
  {
    criteriaId: 2690,
    name: "Seychelles"
  },
  {
    criteriaId: 2694,
    name: "Sierra Leone"
  },
  {
    criteriaId: 2702,
    name: "Singapore"
  },
  {
    criteriaId: 2534,
    name: "Sint Maarten"
  },
  {
    criteriaId: 2703,
    name: "Slovakia"
  },
  {
    criteriaId: 2705,
    name: "Slovenia"
  },
  {
    criteriaId: 2090,
    name: "Solomon Islands"
  },
  {
    criteriaId: 2706,
    name: "Somalia"
  },
  {
    criteriaId: 2710,
    name: "South Africa"
  },
  {
    criteriaId: 2239,
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    criteriaId: 2410,
    name: "South Korea"
  },
  {
    criteriaId: 2724,
    name: "Spain"
  },
  {
    criteriaId: 2144,
    name: "Sri Lanka"
  },
  {
    criteriaId: 2740,
    name: "Suriname"
  },
  {
    criteriaId: 2744,
    name: "Svalbard and Jan Mayen"
  },
  {
    criteriaId: 2752,
    name: "Sweden"
  },
  {
    criteriaId: 2756,
    name: "Switzerland"
  },
  {
    criteriaId: 2158,
    name: "Taiwan"
  },
  {
    criteriaId: 2762,
    name: "Tajikistan"
  },
  {
    criteriaId: 2834,
    name: "Tanzania"
  },
  {
    criteriaId: 2764,
    name: "Thailand"
  },
  {
    criteriaId: 2044,
    name: "The Bahamas"
  },
  {
    criteriaId: 2270,
    name: "The Gambia"
  },
  {
    criteriaId: 2626,
    name: "Timor-Leste"
  },
  {
    criteriaId: 2768,
    name: "Togo"
  },
  {
    criteriaId: 2772,
    name: "Tokelau"
  },
  {
    criteriaId: 2776,
    name: "Tonga"
  },
  {
    criteriaId: 2780,
    name: "Trinidad and Tobago"
  },
  {
    criteriaId: 2788,
    name: "Tunisia"
  },
  {
    criteriaId: 2792,
    name: "Turkey"
  },
  {
    criteriaId: 2795,
    name: "Turkmenistan"
  },
  {
    criteriaId: 2796,
    name: "Turks and Caicos Islands"
  },
  {
    criteriaId: 2798,
    name: "Tuvalu"
  },
  {
    criteriaId: 2850,
    name: "U.S. Virgin Islands"
  },
  {
    criteriaId: 2800,
    name: "Uganda"
  },
  {
    criteriaId: 2804,
    name: "Ukraine"
  },
  {
    criteriaId: 2784,
    name: "United Arab Emirates"
  },
  {
    criteriaId: 2826,
    name: "United Kingdom"
  },
  {
    criteriaId: 2840,
    name: "United States"
  },
  {
    criteriaId: 2581,
    name: "United States Minor Outlying Islands"
  },
  {
    criteriaId: 2858,
    name: "Uruguay"
  },
  {
    criteriaId: 2860,
    name: "Uzbekistan"
  },
  {
    criteriaId: 2548,
    name: "Vanuatu"
  },
  {
    criteriaId: 2336,
    name: "Vatican City"
  },
  {
    criteriaId: 2862,
    name: "Venezuela"
  },
  {
    criteriaId: 2704,
    name: "Vietnam"
  },
  {
    criteriaId: 2876,
    name: "Wallis and Futuna"
  },
  {
    criteriaId: 2732,
    name: "Western Sahara"
  },
  {
    criteriaId: 2887,
    name: "Yemen"
  },
  {
    criteriaId: 2894,
    name: "Zambia"
  },
  {
    criteriaId: 2716,
    name: "Zimbabwe"
  }
);

// Map from Google Ads Geotarget ID to name.
export const googleAdsGeotargetNameMap = Immutable.Map(
  googleAdsGeotargets.map(x => [x.criteriaId, x.name])
);

// Converts the specified string into valid text for a Google Ads keyword.
export function scrubKeyword(string, maxLength) {
  if (!string) {
    return "";
  }

  string = string.trim().toLowerCase();

  // If the whole string is quoted, strip off the quotes.
  if (string[0] === '"' && string[string.length - 1] === '"') {
    string = string.slice(1, string.length - 1);
  }
  if (string[0] === "'" && string[string.length - 1] === "'") {
    string = string.slice(1, string.length - 1);
  }

  // Based on
  // https://support.google.com/google-ads/answer/2453981?sjid=5585565833272397022-NA
  // but added some non-space whitespace.
  const badKeywordRegexp = new RegExp(/[\n\r\t@\\^,=!`<>[\]()%|?;~{}*.]+/g);

  if (string.match(badKeywordRegexp)) {
    string = string.replace(badKeywordRegexp, " ").trim();
  }

  const words = string
    .trim()
    .split(" ")
    .filter(Boolean);
  string = words.slice(0, 10).join(" ");

  if (maxLength) {
    string = string.slice(0, maxLength);
  }

  return string;
}
