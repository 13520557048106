import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetCampaignBidAutomationResultsReply,
  GetCampaignBidAutomationResultsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";

export const fetchCampaignBidAutomationResults = async (
  siteAlias: string,
  campaignId: string
): Promise<GetCampaignBidAutomationResultsReply.AsObject> => {
  const req = new GetCampaignBidAutomationResultsRequest();
  req.setSiteAlias(siteAlias);
  req.setCampaignId(campaignId);

  const reply = await GRPCWebClient.getCampaignBidAutomationResults(req, {});
  return reply.toObject();
};

export const fetchCampaignBidAutomationResultsQueryKey = (
  siteAlias: string,
  campaignId: string
): Array<string> => {
  return ["campaignBidAutomationResults", siteAlias, campaignId];
};

export const useCampaignBidAutomationResults = (
  siteAlias: string,
  campaignId: string
): UseQueryResult<GetCampaignBidAutomationResultsReply.AsObject, unknown> => {
  const oneDay = 1000 * 60 * 60 * 24; //one day
  return useQuery({
    queryKey: fetchCampaignBidAutomationResultsQueryKey(siteAlias, campaignId),
    staleTime: oneDay,
    cacheTime: oneDay,
    enabled: !!siteAlias && !!campaignId,
    queryFn: async () =>
      fetchCampaignBidAutomationResults(siteAlias, campaignId)
  });
};
