import React, { useMemo, useState } from "react";
import { Button, Modal } from "semantic-ui-react";

import { campaignDropdown } from "../../styles/zIndexes";
import Reporting from "../../components/reporting/Reporting";
import Immutable from "immutable";

function GenerateReportButton({
  buttonColor,
  managerSiteAlias,
  clientSites,
  siteAliasesToReport,
  campaignIdsToReport,
  dateRangeStartDate,
  dateRangeEndDate
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [canGenerate, setCanGenerate] = useState(false);
  const [generateIndex, setGenerateIndex] = useState(0);

  const siteNameBySiteMap = useMemo(
    () =>
      Immutable.Map(
        clientSites.map(clientSite => [
          clientSite.clientSiteAlias,
          clientSite.clientSiteName
        ])
      ),
    [clientSites]
  );
  const currencyCodeBySiteMap = useMemo(
    () =>
      Immutable.Map(
        clientSites.map(clientSite => [
          clientSite.clientSiteAlias,
          clientSite.currencyCode
        ])
      ),
    [clientSites]
  );

  const handleModalOpen = () => {
    setModalOpen(true);
    setCanGenerate(false);
    setGenerateIndex(0);
  };
  const handleGenerateReport = () => {
    if (canGenerate) {
      setGenerateIndex(generateIndex => generateIndex + 1);
    }
  };

  return (
    <>
      <Modal
        style={{ zIndex: campaignDropdown }}
        onOpen={handleModalOpen}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        position="bottom left"
        size="large"
        closeOnDimmerClick={false}
        trigger={<Button color={buttonColor}>{"Generate Report"}</Button>}
      >
        <Modal.Header>{"Generate Report"}</Modal.Header>
        <Modal.Content
          style={{ maxHeight: "calc(100vh - 20em)", overflowY: "auto" }}
        >
          <Reporting
            siteAlias={managerSiteAlias}
            siteNameBySiteMap={siteNameBySiteMap}
            currencyCodeBySiteMap={currencyCodeBySiteMap}
            siteLabel={"Client Account: "}
            siteAliasesToReport={siteAliasesToReport}
            campaignIdsToReport={campaignIdsToReport}
            dateRangeStartDate={dateRangeStartDate}
            dateRangeEndDate={dateRangeEndDate}
            setCanGenerate={setCanGenerate}
            generateIndex={generateIndex}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)}>Close</Button>
          <Button
            disabled={!canGenerate}
            positive
            onClick={handleGenerateReport}
          >
            Generate and Download
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default GenerateReportButton;
