import _ from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { Header as SemanticHeader, Table } from "semantic-ui-react";

import { Theme } from "app.css";

// Table with smaller text size.
const SmallTextTable = styled(Table)`
  &.ui.table {
    font-size: 0.9rem;
  }
`;

const CurrentKey = styled.span`
  background-color: ${Theme.current};
`;

const PreviousKey = styled.span`
  background-color: ${Theme.previous};
`;

const DateWrapper = styled.div`
  margin-right: 2.5rem;
  .ui.compact.table td {
    padding: 0 0.7em;
    &.key-cell {
      padding: 0;
    }
  }
  ${CurrentKey}, ${PreviousKey} {
    display: block;
    height: 14px;
    width: 14px;
  }
`;

export class PageInfo {
  constructor(data) {
    this.data = data || {};
  }

  static make(pageInfo) {
    return pageInfo instanceof PageInfo ? pageInfo : new PageInfo(pageInfo);
  }

  // Returns whether the data of this PageInfo is equal to the specified data
  // or the data of the specified PageInfo instance.
  isDataEqual(pageInfo) {
    return _.isEqual(
      this.data,
      pageInfo instanceof PageInfo ? pageInfo.data : pageInfo
    );
  }

  renderLeftComponents() {
    const { title, subtitle } = this.data;

    return (
      <React.Fragment>
        {title && <SemanticHeader as="h3">{title}</SemanticHeader>}
        {subtitle && <span>{subtitle}</span>}
      </React.Fragment>
    );
  }

  renderCenterComponents() {
    return null;
  }

  renderRightComponents() {
    const { currentDates, previousDates } = this.data;

    return (
      <React.Fragment>
        {currentDates && previousDates && (
          <DateWrapper>
            <SmallTextTable basic="very" compact collapsing>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="key-cell">
                    <CurrentKey />
                  </Table.Cell>
                  <Table.Cell>Current</Table.Cell>
                  <Table.Cell>{currentDates}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="key-cell">
                    <PreviousKey />
                  </Table.Cell>
                  <Table.Cell>Previous</Table.Cell>
                  <Table.Cell>{previousDates}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </SmallTextTable>
          </DateWrapper>
        )}
        {currentDates && !previousDates && (
          <DateWrapper>
            <SmallTextTable basic="very" compact collapsing>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{currentDates}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </SmallTextTable>
          </DateWrapper>
        )}
      </React.Fragment>
    );
  }
}
