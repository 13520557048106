// Table for user to specify date ranges to include in the report.
import _ from "lodash";
import moment from "moment";

import React, { useState } from "react";
import { Button, Icon, Input, Menu, Popup, Table } from "semantic-ui-react";
import styled from "styled-components/macro";
import { Box, Flex } from "@rebass/grid";
import { DateInput } from "semantic-ui-calendar-react";

import { StickyTableHeader } from "../MetricColumns";

const DATE_FORMAT = "YYYY-MM-DD";

const Container = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow-y: auto;
  max-height: 400px;
`;

export function DateRangeSelectorTable(props) {
  const {
    reportStartDates,
    setReportStartDates,
    reportEndDates,
    setReportEndDates,
    reportDateRangeNames,
    setReportDateRangeNames,
    initialEndDate
  } = props;

  const [editDateIndex, setEditDateIndex] = useState(-1);
  const [lockDateChange, setLockDateChange] = useState(false);

  // NOTE: The DateInput component returns the initial date if the user opens the
  // popup, edits the text, and hits enter.  To prevent that and keep the value
  // that the user typed in, let's lock the date change during an ENTER keypress.
  // However, let's not respect the lock if the typed in value (current value)
  // is badly formatted, because the DateInput will at least give us a valid
  // date string.
  const handleDateKeyDown = e => {
    if (e.keyCode === 13 || e.which === 13) {
      setLockDateChange(true);
    } else {
      setLockDateChange(false);
    }
  };

  const handleDateKeyUp = () => {
    setLockDateChange(false);
  };

  const handleStartDateChange = (dateIndex, value) => {
    const currentValue = reportStartDates.get(dateIndex);
    if (!lockDateChange || !moment(currentValue, "YYYY-M-D", true).isValid()) {
      setReportStartDates(reportStartDates.set(dateIndex, value));
    }
    setLockDateChange(false);
  };

  const handleEndDateChange = (dateIndex, value) => {
    const currentValue = reportEndDates.get(dateIndex);
    if (!lockDateChange || !moment(currentValue, "YYYY-M-D", true).isValid()) {
      setReportEndDates(reportEndDates.set(dateIndex, value));
    }
    setLockDateChange(false);
  };

  const handleDateRangeNameChange = (dateIndex, value) => {
    setReportDateRangeNames(reportDateRangeNames.set(dateIndex, value));
  };

  const handleNumberOfDaysChange = (dateIndex, value) => {
    let days = Number(value);
    if (!_.isNaN(days)) {
      if (days < 1) {
        days = 1;
      }
      const endDate = reportEndDates.get(dateIndex);
      const newStartDate = moment(endDate)
        .subtract(days - 1, "days")
        .format(DATE_FORMAT);
      setReportStartDates(reportStartDates.set(dateIndex, newStartDate));
    }
  };

  const handleDateRowOpen = dateIndex => {
    setEditDateIndex(dateIndex);
  };

  const handleDateRowClose = dateIndex => {
    if (editDateIndex === dateIndex) {
      setEditDateIndex(-1);
    }
  };

  const handleAddDateRangeBetween = (dateIndex, addStartDate, days) => () => {
    const [newStartDates, newEndDates, newDateRangeNames] = addDateRangeBetween(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      dateIndex,
      addStartDate,
      days
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleAddDateRangeBefore = () => {
    const [newStartDates, newEndDates, newDateRangeNames] = addDateRangeBefore(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleAddDateRangeAfter = () => {
    if (editDateIndex >= 0 && editDateIndex < reportStartDates.size) {
      const [newStartDates, newEndDates, newDateRangeNames] = addDateRangeAfter(
        reportStartDates,
        reportEndDates,
        reportDateRangeNames,
        editDateIndex
      );

      if (newStartDates) {
        setReportStartDates(newStartDates);
        setReportEndDates(newEndDates);
        setReportDateRangeNames(newDateRangeNames);
      }
    }

    setEditDateIndex(-1);
  };

  const handleSplitDateRangeIntoDays = () => {
    const [
      newStartDates,
      newEndDates,
      newDateRangeNames
    ] = splitDateRangeIntoDays(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleSplitDateRangeIntoWeeks = firstIsoWeekday => () => {
    const [
      newStartDates,
      newEndDates,
      newDateRangeNames
    ] = splitDateRangeIntoWeeks(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex,
      firstIsoWeekday
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleSplitDateRangeIntoMonths = () => {
    const [
      newStartDates,
      newEndDates,
      newDateRangeNames
    ] = splitDateRangeIntoMonths(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleJoinWithDateRangesBefore = count => () => {
    const [
      newStartDates,
      newEndDates,
      newDateRangeNames
    ] = joinWithDateRangesBefore(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex,
      count
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleJoinWithDateRangesAfter = count => () => {
    const [
      newStartDates,
      newEndDates,
      newDateRangeNames
    ] = joinWithDateRangesAfter(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex,
      count
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  const handleRemoveDateRange = () => {
    const [newStartDates, newEndDates, newDateRangeNames] = removeDateRange(
      reportStartDates,
      reportEndDates,
      reportDateRangeNames,
      editDateIndex
    );

    if (newStartDates) {
      setReportStartDates(newStartDates);
      setReportEndDates(newEndDates);
      setReportDateRangeNames(newDateRangeNames);
    }

    setEditDateIndex(-1);
  };

  return (
    <Container>
      <Table style={{ border: 0 }} compact="very" size="small">
        <StickyTableHeader>
          <Table.Row>
            <Table.HeaderCell style={{ width: "20em" }}>
              Date Range Title
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "20em" }}>
              Start Date
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "20em" }}>
              End Date
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "11em" }}>
              Number of Days
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </StickyTableHeader>
        <Table.Body style={{ fontSize: "small" }}>
          {reportStartDates.map((_unused, index) => {
            // Show dates in reverse order.
            const dateIndex = reportStartDates.size - 1 - index;
            const startDate = reportStartDates.get(dateIndex);
            const endDate = reportEndDates.get(dateIndex);
            const dateRangeName = reportDateRangeNames.get(dateIndex);
            const defaultDateRangeName =
              startDate === endDate ? startDate : `${startDate} - ${endDate}`;
            const dayCount =
              moment(endDate).diff(moment(startDate), "days") + 1;

            let gapStartDate = null;
            let gapDayCount = 0;
            if (dateIndex === 0) {
              gapDayCount = moment(initialEndDate).diff(
                moment(endDate),
                "days"
              );
              if (gapDayCount < 0) {
                gapDayCount = 0;
              } else {
                gapStartDate = moment(endDate)
                  .add(1, "days")
                  .format(DATE_FORMAT);
              }
            } else {
              const otherStartDate = reportStartDates.get(dateIndex - 1);
              gapDayCount =
                moment(otherStartDate).diff(moment(endDate), "days") - 1;
              if (gapDayCount > 0) {
                gapStartDate = moment(endDate)
                  .add(1, "days")
                  .format(DATE_FORMAT);
              }
            }

            const markDays = [];
            const loopMoment = moment(startDate);
            const stopMoment = moment(endDate).add(1, "days");
            for (; loopMoment.isBefore(stopMoment); loopMoment.add(1, "days")) {
              markDays.push(moment(loopMoment));
            }

            return (
              <React.Fragment key={dateIndex}>
                <Table.Row>
                  <Table.Cell>
                    <Input
                      style={{ width: "100%" }}
                      type="text"
                      value={dateRangeName}
                      placeholder={defaultDateRangeName}
                      onChange={(e, { value }) =>
                        handleDateRangeNameChange(dateIndex, value)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <DateInput
                        style={{ width: "10em" }}
                        name="startDate"
                        placeholder="Start Date"
                        dateFormat={DATE_FORMAT}
                        value={startDate}
                        marked={markDays}
                        markColor="orange"
                        iconPosition="left"
                        closable={true}
                        onChange={(e, { value }) =>
                          handleStartDateChange(dateIndex, value)
                        }
                        onKeyDown={handleDateKeyDown}
                        onKeyUp={handleDateKeyUp}
                      />
                      <Box ml="1em" width="7em">
                        {moment(startDate).format("dddd")}
                      </Box>
                      <Box ml="auto">-</Box>
                    </Flex>
                  </Table.Cell>
                  <Table.Cell>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <DateInput
                        style={{ width: "10em" }}
                        name="endDate"
                        placeholder="End Date"
                        dateFormat={DATE_FORMAT}
                        value={endDate}
                        marked={markDays}
                        markColor="orange"
                        iconPosition="left"
                        closable={true}
                        onChange={(e, { value }) =>
                          handleEndDateChange(dateIndex, value)
                        }
                        onKeyDown={handleDateKeyDown}
                        onKeyUp={handleDateKeyUp}
                      />
                      <Box ml="1em" w="5em">
                        {moment(endDate).format("dddd")}
                      </Box>
                    </Flex>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      style={{ width: 100 }}
                      type="number"
                      value={dayCount}
                      onChange={(e, { value }) =>
                        handleNumberOfDaysChange(dateIndex, value)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <>
                      <Popup
                        open={dateIndex === editDateIndex}
                        trigger={
                          <Button
                            style={{ whiteSpace: "nowrap" }}
                            basic
                            compact
                            size="small"
                          >
                            Advanced Actions
                            <Icon name="dropdown" />
                          </Button>
                        }
                        onOpen={() => handleDateRowOpen(dateIndex)}
                        onClose={() => handleDateRowClose(dateIndex)}
                        on="click"
                      >
                        <Menu style={{ width: "18em" }} vertical size="small">
                          <Menu.Item
                            content="Add date range before"
                            onClick={handleAddDateRangeBefore}
                          />
                          <Menu.Item
                            content="Add date range after"
                            onClick={handleAddDateRangeAfter}
                          />
                          {dayCount > 1 && (
                            <Menu.Item
                              content="Split into days"
                              onClick={handleSplitDateRangeIntoDays}
                            />
                          )}
                          {dayCount > 7 && (
                            <>
                              <Menu.Item
                                content="Split into 7 day ranges"
                                onClick={handleSplitDateRangeIntoWeeks(0)}
                              />
                              <Menu.Item
                                content="Split into Monday-Sunday weeks"
                                onClick={handleSplitDateRangeIntoWeeks(1)}
                              />
                              <Menu.Item
                                content="Split into Sunday-Saturday weeks"
                                onClick={handleSplitDateRangeIntoWeeks(7)}
                              />
                            </>
                          )}
                          {dayCount > 1 && (
                            <>
                              <Menu.Item
                                content="Split into months"
                                onClick={handleSplitDateRangeIntoMonths}
                              />
                            </>
                          )}
                          {dateIndex < reportStartDates.size - 1 && (
                            <Menu.Item
                              content="Join with date range before"
                              onClick={handleJoinWithDateRangesBefore(1)}
                            />
                          )}
                          {dateIndex < reportStartDates.size - 2 && (
                            <Menu.Item
                              content="Join with all date ranges before"
                              onClick={handleJoinWithDateRangesBefore(
                                reportStartDates.size - dateIndex
                              )}
                            />
                          )}
                          {dateIndex > 0 && (
                            <Menu.Item
                              content="Join with date range after"
                              onClick={handleJoinWithDateRangesAfter(1)}
                            />
                          )}
                          {dateIndex > 1 && (
                            <Menu.Item
                              content="Join with all date ranges after"
                              onClick={handleJoinWithDateRangesAfter(dateIndex)}
                            />
                          )}
                          {reportStartDates.size > 1 && (
                            <Menu.Item
                              content="Remove date range"
                              onClick={handleRemoveDateRange}
                            />
                          )}
                        </Menu>
                      </Popup>
                    </>
                  </Table.Cell>
                </Table.Row>
                {gapDayCount > 0 && (
                  <Table.Row>
                    <Table.Cell colSpan={3} />
                    <Table.Cell disabled={true}>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box ml="1em">{gapDayCount}</Box>
                      </Flex>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        compact
                        size="small"
                        onClick={handleAddDateRangeBetween(
                          dateIndex,
                          gapStartDate,
                          gapDayCount
                        )}
                      >
                        Add
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )}
                {gapDayCount < 0 && (
                  <Table.Row>
                    <Table.Cell colSpan={3} />
                    <Table.Cell disabled={true}>Overlapping</Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                )}
              </React.Fragment>
            );
          })}
        </Table.Body>
      </Table>
    </Container>
  );
}

export function addDateRangeBefore(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex
) {
  if (dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const startDate = startDates.get(dateIndex);
  const endDate = endDates.get(dateIndex);
  const diff = moment(endDate).diff(moment(startDate), "days");
  const addEndDate = moment(startDate)
    .subtract(1, "days")
    .format(DATE_FORMAT);
  const addStartDate = moment(addEndDate)
    .subtract(diff, "days")
    .format(DATE_FORMAT);

  const newStartDates = startDates.insert(dateIndex + 1, addStartDate);
  const newEndDates = endDates.insert(dateIndex + 1, addEndDate);
  const newDateRangeNames = dateRangeNames.insert(dateIndex + 1, "");

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function addDateRangeAfter(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex
) {
  if (dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const startDate = startDates.get(dateIndex);
  const endDate = endDates.get(dateIndex);
  const diff = moment(endDate).diff(moment(startDate), "days");
  const addStartDate = moment(endDate)
    .add(1, "days")
    .format(DATE_FORMAT);
  const addEndDate = moment(addStartDate)
    .add(diff, "days")
    .format(DATE_FORMAT);

  const newStartDates = startDates.insert(dateIndex, addStartDate);
  const newEndDates = endDates.insert(dateIndex, addEndDate);
  const newDateRangeNames = dateRangeNames.insert(dateIndex, "");

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function addDateRangeBetween(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex,
  addStartDate,
  days
) {
  if (days <= 0 || dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const addEndDate = moment(addStartDate)
    .add(days - 1, "days")
    .format(DATE_FORMAT);

  const newStartDates = startDates.insert(dateIndex, addStartDate);
  const newEndDates = endDates.insert(dateIndex, addEndDate);
  const newDateRangeNames = dateRangeNames.insert(dateIndex, "");

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function splitDateRangeIntoDays(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex
) {
  if (dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const startDate = startDates.get(dateIndex);
  const endDate = endDates.get(dateIndex);

  let newStartDates = startDates.remove(dateIndex);
  let newEndDates = endDates.remove(dateIndex);
  let newDateRangeNames = dateRangeNames.remove(dateIndex);

  const diff = moment(endDate).diff(moment(startDate), "days");
  for (let offset = 0; offset < diff + 1; offset++) {
    const addStartDate = moment(startDate)
      .add(offset, "days")
      .format(DATE_FORMAT);
    const addEndDate = moment(startDate)
      .add(offset, "days")
      .format(DATE_FORMAT);

    newStartDates = newStartDates.insert(dateIndex, addStartDate);
    newEndDates = newEndDates.insert(dateIndex, addEndDate);
    newDateRangeNames = newDateRangeNames.insert(dateIndex, "");
  }

  return [newStartDates, newEndDates, newDateRangeNames];
}

// firstIsoWeekday -
//    1 (= ISO weekday for Monday) - splits range into full Monday - Sunday weeks, plus fragment
//    7 (= ISO weekday for Sunday) - splits range into full Sunday - Saturday weeks, plus fragment
//    0 - splits range into full weeks that end with the same day of the week as
//        the range's current end date.
export function splitDateRangeIntoWeeks(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex,
  firstIsoWeekday
) {
  if (dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const startDate = startDates.get(dateIndex);
  const endDate = endDates.get(dateIndex);

  let newStartDates = startDates.remove(dateIndex);
  let newEndDates = endDates.remove(dateIndex);
  let newDateRangeNames = dateRangeNames.remove(dateIndex);

  let startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // firstIsoWeekday must be one of the possible values so the subsequent while loop
  // terminates.
  if (![1, 2, 3, 4, 5, 6, 7].includes(firstIsoWeekday)) {
    firstIsoWeekday = moment(endMoment)
      .add(1, "days")
      .isoWeekday();
  }
  while (startMoment.isoWeekday() !== firstIsoWeekday) {
    startMoment.subtract(1, "days");
  }

  for (; startMoment.isBefore(endMoment); startMoment.add(7, "days")) {
    const addStartDate = moment(startMoment).format(DATE_FORMAT);
    const addEndDate = moment
      .min(endMoment, moment(startMoment).add(6, "days"))
      .format(DATE_FORMAT);

    newStartDates = newStartDates.insert(dateIndex, addStartDate);
    newEndDates = newEndDates.insert(dateIndex, addEndDate);
    newDateRangeNames = newDateRangeNames.insert(dateIndex, "");
  }

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function splitDateRangeIntoMonths(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex
) {
  if (dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const startDate = startDates.get(dateIndex);
  const endDate = endDates.get(dateIndex);

  let newStartDates = startDates.remove(dateIndex);
  let newEndDates = endDates.remove(dateIndex);
  let newDateRangeNames = dateRangeNames.remove(dateIndex);

  let startMoment = moment(startDate).startOf("month");
  const endMoment = moment(endDate);

  for (; startMoment.isBefore(endMoment); startMoment.add(1, "month")) {
    const addStartDate = moment(startMoment).format(DATE_FORMAT);
    const addEndDate = moment
      .min(endMoment, moment(startMoment).endOf("month"))
      .format(DATE_FORMAT);

    newStartDates = newStartDates.insert(dateIndex, addStartDate);
    newEndDates = newEndDates.insert(dateIndex, addEndDate);
    newDateRangeNames = newDateRangeNames.insert(dateIndex, "");
  }

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function joinWithDateRangesBefore(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex,
  count
) {
  if (dateIndex < 0 || dateIndex >= startDates.size - 1) {
    return [null, null, null];
  }

  if (count > startDates.size - 1 - dateIndex) {
    count = startDates.size - 1 - dateIndex;
  }

  const startDate = startDates.get(dateIndex + count);
  const endDate = endDates.get(dateIndex);

  const newStartDates = startDates.splice(dateIndex, count + 1, startDate);
  const newEndDates = endDates.splice(dateIndex, count + 1, endDate);
  const newDateRangeNames = dateRangeNames.splice(dateIndex, count + 1, "");

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function joinWithDateRangesAfter(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex,
  count
) {
  if (dateIndex <= 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  if (count > dateIndex) {
    count = dateIndex;
  }

  const startDate = startDates.get(dateIndex);
  const endDate = endDates.get(dateIndex - count);

  const newStartDates = startDates.splice(
    dateIndex - count,
    count + 1,
    startDate
  );
  const newEndDates = endDates.splice(dateIndex - count, count + 1, endDate);
  const newDateRangeNames = dateRangeNames.splice(
    dateIndex - count,
    count + 1,
    ""
  );

  return [newStartDates, newEndDates, newDateRangeNames];
}

export function removeDateRange(
  startDates,
  endDates,
  dateRangeNames,
  dateIndex
) {
  if (dateIndex < 0 || dateIndex >= startDates.size) {
    return [null, null, null];
  }

  const newStartDates = startDates.remove(dateIndex);
  const newEndDates = endDates.remove(dateIndex);
  const newDateRangeNames = dateRangeNames.remove(dateIndex);

  return [newStartDates, newEndDates, newDateRangeNames];
}
