import _ from "lodash";
import moment from "moment";

import * as Highcharts from "highcharts";
import HighchartsNoDataToDisplay from "highcharts/modules/no-data-to-display";

import { generatePath } from "react-router-dom-v5-compat";
import { formatMetric } from "Common/utils/metrics";
import { googleAdsCurrencies } from "Common/utils/googleAds";
import { supportedChartMetricTypes } from "./performanceByChannelUtil";
import { supportedBrandOverviewMetricTypes } from "../../pages/BrandOverviewPage/brandOverviewUtil";
import { AMPD_ROOT, CAMPAIGNS_PATH } from "../../ExtensionV2";

if (Highcharts) {
  HighchartsNoDataToDisplay(Highcharts);
}

const consistentFont = {
  style: {
    fontSize: "1.0em",
    color: "#646464"
  }
};

// When all y-axis data is zero, this keeps the x-axis zero-line from jumping
// up to the middle of the highstock chart. If on highcharts, can get the same
// effect with instead just using the following two y-axis configs:
//  min: 0, // Prevents things from going negative
//  minRange: 1, // Help prevent jumping chart when all zeros
// These are left in the config for highstock, but currently do not result in
// the same behavior when used in the highstock/stockcharts library.
function keepXAxisAtBottomWhenAllZeros(_event) {
  let extremes = this.yAxis[0].getExtremes();
  if (extremes.dataMax === 0) {
    extremes.max = 1;
    this.yAxis[0].setExtremes(0, 1);
  }
}

export const overviewChartConfig = (
  channelTimeseries,
  currentMetric,
  currencyCode
) => ({
  chart: {
    animation: false, // for redraws; initial animation on series
    type: "spline",
    height: 180,
    margin: 30, // tighten chart, but too small cuts off x-axis labels
    events: {
      load: keepXAxisAtBottomWhenAllZeros
    },
    zooming: { mouseWheel: { enabled: false } }
  },
  credits: { enabled: false },
  legend: false,
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  scrollbar: { enabled: false },
  title: null,
  xAxis: {
    labels: { enabled: true },
    type: "datetime",
    dateTimeLabelFormats: { month: "%b '%y" },
    lineColor: "transparent",
    crosshair: false
  },
  yAxis: {
    min: 0, // Prevents things from going negative
    minRange: 0.1, // Help prevent jumping chart when all zeros
    zoomEnabled: false, // prevent zoom
    labels: { enabled: false }, // no labels
    gridLineColor: "transparent" // no gridlines
  },
  plotOptions: {
    line: {
      connectNulls: true
    },
    series: {
      lineWidth: 4,
      pointIntervalUnit: "month"
    }
  },
  lang: {
    noData: "No data available"
  },
  series: [channelTimeseries],
  tooltip: {
    ...buildTooltipFormat(
      supportedBrandOverviewMetricTypes,
      currentMetric,
      currencyCode
    ),
    split: false // group tooltips into one (point & date)
  }
});

const basicHighstockChartConfig = {
  animation: false,
  chart: {
    events: {
      load: keepXAxisAtBottomWhenAllZeros
    },
    zooming: { mouseWheel: { enabled: false } }
  },
  credits: { enabled: false },
  legend: false,
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%b %e",
      week: "%b %e",
      hour: "",
      minute: "",
      second: ""
    },
    tickLength: 0,
    labels: consistentFont,
    crosshair: true // The gray vertical line when hover
  },
  tooltip: { useHTML: true }, // prevent blurry tooltips
  yAxis: {
    type: "linear",
    title: {
      ...consistentFont
    },
    labels: {
      ...consistentFont
    },
    opposite: false // left labels; highstock defaults to right labels
  }
};

export const buildPerformanceByChannelChartConfig = (
  selectedMetric,
  channelTimeseries,
  currencyCode,
  campaignVerticals
) =>
  // Merge the custom configs on top of the basic config
  _.merge({}, basicHighstockChartConfig, {
    chart: {
      type: "spline",
      height: 700
    },
    title: {
      text: null
    },
    xAxis: {
      plotLines: campaignVerticals
    },
    yAxis: {
      title: {
        text: supportedChartMetricTypes[selectedMetric]?.units
      },
      min: 0, // Prevents things from going negative
      minRange: 0.1, // Help prevent jumping chart when all zeros
      labels: {
        formatter: buildLabelFormatterFunction(selectedMetric, currencyCode)
      },
      opposite: false // left labels; highstock defaults to right labels
    },
    plotOptions: {
      series: {
        dataGrouping: buildGroupingConfig(selectedMetric)
      },
      line: {
        connectNulls: true
      }
    },
    tooltip: {
      ...buildTooltipFormat(
        supportedChartMetricTypes,
        selectedMetric,
        currencyCode
      ),
      split: true // Show all tooltips separately
    },
    lang: {
      noData: "No data available"
    },
    series: channelTimeseries?.toArray() // The series lines!
  });

export const makeSeries = (
  seriesName,
  xSeries,
  ySeries,
  seriesColor,
  showMarkers,
  hidden
) => {
  const series = ySeries?.map((_point, i) => [xSeries[i], ySeries[i]]) || [];
  return {
    name: seriesName,
    color: seriesColor,
    marker: {
      enabled: showMarkers,
      symbol: "circle"
    },
    dashStyle: "line",
    connectNulls: true, // Helps nulls act like zeros for better visuals
    data: series,
    animation: false, // prevent initial animation
    visible: !hidden
  };
};

// Builds the vertical campaign start plot lines to show above their x-axis event times.
// Includes a link to their associated campaign's details.
export const buildCampaignStartVerticals = (
  campaigns,
  siteAlias,
  searchParams,
  labelOffsetY
) =>
  campaigns?.map(campaign => {
    const startDay = moment(campaign.startDate, "YYYY-MM-DD");
    const path = toCampaignDetailsPath(
      siteAlias,
      campaign.campaignId,
      searchParams
    );

    return {
      color: "gray",
      width: 2,
      value: startDay.valueOf(), // Must align with x-axis values
      dashStyle: "dash",
      label: {
        style: { color: "gray" },
        text: `
          Campaign Started: 
          <a href="${window.location.origin}${path}">
            ${campaign.campaignId}
          </a>
        `,
        verticalAlign: "bottom",
        textAlign: "right",
        rotation: 270,
        y: labelOffsetY,
        x: -5
      }
    };
  });

// Returns the campaign details path from data pieces
const toCampaignDetailsPath = (siteAlias, campaignId, searchParams) => {
  const path = generatePath(
    `${AMPD_ROOT}/${CAMPAIGNS_PATH}?campaign=${campaignId}`,
    { siteAlias }
  );

  return `${path}&${searchParams?.toString()}`;
};

export const buildTooltipFormat = (
  supportedMetrics,
  currentMetric,
  currencyCode
) => ({
  pointFormatter: function() {
    const metricDef = supportedMetrics[currentMetric]?.metricDef;

    // Format tooltip value by applicable metricDef (currency when applies)
    if (metricDef) {
      return formatMetric(metricDef(currencyCode), this.y);
    }

    return this.y;
  },
  shared: false,
  useHTML: true // prevents blurry tooltips
});

const buildGroupingConfig = selectedMetric => ({
  approximation: supportedChartMetricTypes[selectedMetric]?.summable
    ? "sum" // summable things can be grouped by sum
    : "average", // ratios should be grouped by average
  enabled: true,
  forced: true,
  units: [
    ["day", [1]],
    ["week", [1]],
    ["month", [1]],
    ["year", [1]]
  ]
});

const buildLabelFormatterFunction = (selectedMetric, currencyCode) => {
  return function() {
    const label = this.axis.defaultLabelFormatter.call(this);
    const metricDef = supportedChartMetricTypes[selectedMetric]?.metricDef;

    // Format currency labels
    if (metricDef && metricDef(currencyCode)?.isCurrency) {
      const currency = googleAdsCurrencies.get(currencyCode);
      const symbol = currency?.symbol || "";

      // Return the label with prepended currency symbol
      return symbol + label;
    }

    // Format other labels
    if (metricDef && typeof metricDef === "function") {
      return formatMetric(metricDef(), label);
    }

    return label;
  };
};
