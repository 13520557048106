import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import queryString from "query-string";
import { sendGAEvent } from "../../ExtensionV2/components/GA";

const LWA_BUTTON_IMG_URL =
  "https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_195x46.png";

const popupTarget = "ampd-connect-to-amazon-spapi";

const defaultPopupWidth = 800;
const defaultPopupHeight = 900;

// Selects the position and size so that the position is in the middle of the
// totalSize and the size does not exceed the totalSize.
const selectPositionAndSize = (defaultSize, totalSize) => {
  const size = Math.min(defaultSize, totalSize);
  const position = (totalSize - defaultSize) / 2;
  return [position, size];
};

// Button for triggering the flow to connect to an Amazon Seller account.
// Unfortunately, since this uses a different endpoint, it is not compatible
// with the LWA login flow.
//
// Props:
// - state: Required. Client state that is mirrored back in the response. This
//   is also used for anti-CSRF forgery.
//
// - onAuthorized: Required. func({state, sellingPartnerId, code}) invoked after
//   the user has been authorized.
//
// - marketplace: Required. The Amazon marketplace to authorize connection.
//
// - src: Optional. Source for the image button.
//   See https://developer.amazon.com/docs/login-with-amazon/button.html#website-images.
export default function LinkToAmazonSellerButton(props) {
  const { gaCategory, hostname, onAuthorized, siteAlias, src, state } = props;
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    const handleEvent = event => {
      // IMPORTANT! We *must* check the origin and source of the event to avoid
      // accepting events from untrusted sources.
      if (
        event.origin !== window.location.origin ||
        event.source !== popup ||
        !event.data
      ) {
        return;
      }

      const {
        state,
        selling_partner_id: sellingPartnerId,
        spapi_oauth_code: code
      } = event.data || {};

      onAuthorized({
        state,
        sellingPartnerId,
        code,
        popup
      });
    };

    window.addEventListener("message", handleEvent);
    return () => window.removeEventListener("message", handleEvent);
  }, [onAuthorized, popup]);

  const openPopup = e => {
    e.stopPropagation();

    // Place the popup in the center of the screen.
    const [left, width] = selectPositionAndSize(
      defaultPopupWidth,
      window.screen.width
    );

    const [top, height] = selectPositionAndSize(
      defaultPopupHeight,
      window.screen.height
    );

    const popupOptions =
      `resizable,scrollbars,status,` +
      `width=${width},height=${height},left=${left},top=${top}`;

    if (!state) {
      throw new Error("missing state");
    }

    // eslint-disable-next-line no-undef
    if (!hostname) {
      throw new Error(`missing hostname for Seller/Vendor Connection`);
    }

    // NOTE: There is no scope for linking to the SPAPI.
    const queryParams = {
      application_id: process.env.REACT_APP_AMAZON_SELLER_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_AMAZON_SELLER_REDIRECT_URI,
      state
    };

    const query = queryString.stringify(queryParams);

    setPopup(
      window.open(
        `https://${hostname}/apps/authorize/consent?${query}`,
        popupTarget,
        popupOptions
      )
    );

    sendGAEvent(gaCategory, "Clicked to connect Amazon Seller", siteAlias);

    return false;
  };

  return (
    <Image
      style={{ cursor: "pointer", padding: "0.5em 0.5em" }}
      src={src || LWA_BUTTON_IMG_URL}
      onClick={openPopup}
    />
  );
}
