// source: proto/edge/grpcwebPb/grpcweb_Products.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_amazon_pb = require('../../../proto/common/amazon_pb.js');
goog.object.extend(proto, proto_common_amazon_pb);
goog.exportSymbol('proto.grpcwebPb.GetProductsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetProductsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.Product', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.RelationshipLevel', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.RelationshipLevel.Option', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.Seller', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.Seller.Amazon', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.Seller.TypeCase', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.Source', null, global);
goog.exportSymbol('proto.grpcwebPb.Product.Source.Option', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetProductsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetProductsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetProductsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetProductsRequest.displayName = 'proto.grpcwebPb.GetProductsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetProductsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.GetProductsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.GetProductsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetProductsReply.displayName = 'proto.grpcwebPb.GetProductsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.Product.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.Product.displayName = 'proto.grpcwebPb.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.Product.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.Product.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.Product.Source.displayName = 'proto.grpcwebPb.Product.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.Product.Seller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpcwebPb.Product.Seller.oneofGroups_);
};
goog.inherits(proto.grpcwebPb.Product.Seller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.Product.Seller.displayName = 'proto.grpcwebPb.Product.Seller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.Product.Seller.Amazon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.Product.Seller.Amazon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.Product.Seller.Amazon.displayName = 'proto.grpcwebPb.Product.Seller.Amazon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.Product.RelationshipLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.Product.RelationshipLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.Product.RelationshipLevel.displayName = 'proto.grpcwebPb.Product.RelationshipLevel';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetProductsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetProductsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetProductsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetProductsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetProductsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeRelated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    relationshipFiltersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetProductsRequest}
 */
proto.grpcwebPb.GetProductsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetProductsRequest;
  return proto.grpcwebPb.GetProductsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetProductsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetProductsRequest}
 */
proto.grpcwebPb.GetProductsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeRelated(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.grpcwebPb.Product.RelationshipLevel.Option>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRelationshipFilters(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetProductsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetProductsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetProductsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetProductsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeRelated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRelationshipFiltersList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetProductsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetProductsRequest} returns this
 */
proto.grpcwebPb.GetProductsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_related = 2;
 * @return {boolean}
 */
proto.grpcwebPb.GetProductsRequest.prototype.getIncludeRelated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.GetProductsRequest} returns this
 */
proto.grpcwebPb.GetProductsRequest.prototype.setIncludeRelated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Product.RelationshipLevel.Option relationship_filters = 3;
 * @return {!Array<!proto.grpcwebPb.Product.RelationshipLevel.Option>}
 */
proto.grpcwebPb.GetProductsRequest.prototype.getRelationshipFiltersList = function() {
  return /** @type {!Array<!proto.grpcwebPb.Product.RelationshipLevel.Option>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.grpcwebPb.Product.RelationshipLevel.Option>} value
 * @return {!proto.grpcwebPb.GetProductsRequest} returns this
 */
proto.grpcwebPb.GetProductsRequest.prototype.setRelationshipFiltersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.grpcwebPb.Product.RelationshipLevel.Option} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.GetProductsRequest} returns this
 */
proto.grpcwebPb.GetProductsRequest.prototype.addRelationshipFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetProductsRequest} returns this
 */
proto.grpcwebPb.GetProductsRequest.prototype.clearRelationshipFiltersList = function() {
  return this.setRelationshipFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.GetProductsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetProductsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetProductsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetProductsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetProductsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.grpcwebPb.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetProductsReply}
 */
proto.grpcwebPb.GetProductsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetProductsReply;
  return proto.grpcwebPb.GetProductsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetProductsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetProductsReply}
 */
proto.grpcwebPb.GetProductsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.Product;
      reader.readMessage(value,proto.grpcwebPb.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetProductsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetProductsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetProductsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetProductsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpcwebPb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Product products = 1;
 * @return {!Array<!proto.grpcwebPb.Product>}
 */
proto.grpcwebPb.GetProductsReply.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.grpcwebPb.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpcwebPb.Product, 1));
};


/**
 * @param {!Array<!proto.grpcwebPb.Product>} value
 * @return {!proto.grpcwebPb.GetProductsReply} returns this
*/
proto.grpcwebPb.GetProductsReply.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpcwebPb.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.Product}
 */
proto.grpcwebPb.GetProductsReply.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpcwebPb.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.GetProductsReply} returns this
 */
proto.grpcwebPb.GetProductsReply.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.Product.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parentProductId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    variantProductIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    productPageUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productHeroImageUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    productRelationshipLevel: jspb.Message.getFieldWithDefault(msg, 10, 0),
    seller: (f = msg.getSeller()) && proto.grpcwebPb.Product.Seller.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.Product}
 */
proto.grpcwebPb.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.Product;
  return proto.grpcwebPb.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.Product}
 */
proto.grpcwebPb.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {!proto.grpcwebPb.Product.Source.Option} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentProductId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addVariantProductIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductPageUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductHeroImageUrl(value);
      break;
    case 10:
      var value = /** @type {!proto.grpcwebPb.Product.RelationshipLevel.Option} */ (reader.readEnum());
      msg.setProductRelationshipLevel(value);
      break;
    case 11:
      var value = new proto.grpcwebPb.Product.Seller;
      reader.readMessage(value,proto.grpcwebPb.Product.Seller.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParentProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVariantProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getProductPageUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductHeroImageUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProductRelationshipLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.grpcwebPb.Product.Seller.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.Product.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.Product.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.Product.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.Source.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.Product.Source}
 */
proto.grpcwebPb.Product.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.Product.Source;
  return proto.grpcwebPb.Product.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.Product.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.Product.Source}
 */
proto.grpcwebPb.Product.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.Product.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.Product.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.Product.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.Product.Source.Option = {
  UNKNOWN: 0,
  AMAZON: 1,
  WALMART: 2
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpcwebPb.Product.Seller.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.grpcwebPb.Product.Seller.TypeCase = {
  TYPE_NOT_SET: 0,
  AMAZON: 1
};

/**
 * @return {proto.grpcwebPb.Product.Seller.TypeCase}
 */
proto.grpcwebPb.Product.Seller.prototype.getTypeCase = function() {
  return /** @type {proto.grpcwebPb.Product.Seller.TypeCase} */(jspb.Message.computeOneofCase(this, proto.grpcwebPb.Product.Seller.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.Product.Seller.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.Product.Seller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.Product.Seller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.Seller.toObject = function(includeInstance, msg) {
  var f, obj = {
    amazon: (f = msg.getAmazon()) && proto.grpcwebPb.Product.Seller.Amazon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.Product.Seller}
 */
proto.grpcwebPb.Product.Seller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.Product.Seller;
  return proto.grpcwebPb.Product.Seller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.Product.Seller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.Product.Seller}
 */
proto.grpcwebPb.Product.Seller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpcwebPb.Product.Seller.Amazon;
      reader.readMessage(value,proto.grpcwebPb.Product.Seller.Amazon.deserializeBinaryFromReader);
      msg.setAmazon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.Product.Seller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.Product.Seller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.Product.Seller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.Seller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmazon();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpcwebPb.Product.Seller.Amazon.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.Product.Seller.Amazon.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.Product.Seller.Amazon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.Product.Seller.Amazon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.Seller.Amazon.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributionProfileIdStr: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.Product.Seller.Amazon}
 */
proto.grpcwebPb.Product.Seller.Amazon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.Product.Seller.Amazon;
  return proto.grpcwebPb.Product.Seller.Amazon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.Product.Seller.Amazon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.Product.Seller.Amazon}
 */
proto.grpcwebPb.Product.Seller.Amazon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Amazon.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributionProfileIdStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.Product.Seller.Amazon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.Product.Seller.Amazon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.Product.Seller.Amazon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.Seller.Amazon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAttributionProfileIdStr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional common.Amazon.Marketplace.Option marketplace = 1;
 * @return {!proto.common.Amazon.Marketplace.Option}
 */
proto.grpcwebPb.Product.Seller.Amazon.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Amazon.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Amazon.Marketplace.Option} value
 * @return {!proto.grpcwebPb.Product.Seller.Amazon} returns this
 */
proto.grpcwebPb.Product.Seller.Amazon.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string attribution_profile_id_str = 2;
 * @return {string}
 */
proto.grpcwebPb.Product.Seller.Amazon.prototype.getAttributionProfileIdStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product.Seller.Amazon} returns this
 */
proto.grpcwebPb.Product.Seller.Amazon.prototype.setAttributionProfileIdStr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Amazon amazon = 1;
 * @return {?proto.grpcwebPb.Product.Seller.Amazon}
 */
proto.grpcwebPb.Product.Seller.prototype.getAmazon = function() {
  return /** @type{?proto.grpcwebPb.Product.Seller.Amazon} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.Product.Seller.Amazon, 1));
};


/**
 * @param {?proto.grpcwebPb.Product.Seller.Amazon|undefined} value
 * @return {!proto.grpcwebPb.Product.Seller} returns this
*/
proto.grpcwebPb.Product.Seller.prototype.setAmazon = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.grpcwebPb.Product.Seller.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.Product.Seller} returns this
 */
proto.grpcwebPb.Product.Seller.prototype.clearAmazon = function() {
  return this.setAmazon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.Product.Seller.prototype.hasAmazon = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.Product.RelationshipLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.Product.RelationshipLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.Product.RelationshipLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.RelationshipLevel.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.Product.RelationshipLevel}
 */
proto.grpcwebPb.Product.RelationshipLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.Product.RelationshipLevel;
  return proto.grpcwebPb.Product.RelationshipLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.Product.RelationshipLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.Product.RelationshipLevel}
 */
proto.grpcwebPb.Product.RelationshipLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.Product.RelationshipLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.Product.RelationshipLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.Product.RelationshipLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.Product.RelationshipLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.grpcwebPb.Product.RelationshipLevel.Option = {
  UNSPECIFIED: 0,
  PARENT: 1,
  VARIANT: 2,
  SINGLE: 3
};

/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.grpcwebPb.Product.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Source.Option source = 2;
 * @return {!proto.grpcwebPb.Product.Source.Option}
 */
proto.grpcwebPb.Product.prototype.getSource = function() {
  return /** @type {!proto.grpcwebPb.Product.Source.Option} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grpcwebPb.Product.Source.Option} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.grpcwebPb.Product.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.grpcwebPb.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string parent_product_id = 5;
 * @return {string}
 */
proto.grpcwebPb.Product.prototype.getParentProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setParentProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string variant_product_ids = 6;
 * @return {!Array<string>}
 */
proto.grpcwebPb.Product.prototype.getVariantProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setVariantProductIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.addVariantProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.clearVariantProductIdsList = function() {
  return this.setVariantProductIdsList([]);
};


/**
 * repeated string categories = 7;
 * @return {!Array<string>}
 */
proto.grpcwebPb.Product.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional string product_page_url = 8;
 * @return {string}
 */
proto.grpcwebPb.Product.prototype.getProductPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setProductPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_hero_image_url = 9;
 * @return {string}
 */
proto.grpcwebPb.Product.prototype.getProductHeroImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setProductHeroImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional RelationshipLevel.Option product_relationship_level = 10;
 * @return {!proto.grpcwebPb.Product.RelationshipLevel.Option}
 */
proto.grpcwebPb.Product.prototype.getProductRelationshipLevel = function() {
  return /** @type {!proto.grpcwebPb.Product.RelationshipLevel.Option} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.grpcwebPb.Product.RelationshipLevel.Option} value
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.setProductRelationshipLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Seller seller = 11;
 * @return {?proto.grpcwebPb.Product.Seller}
 */
proto.grpcwebPb.Product.prototype.getSeller = function() {
  return /** @type{?proto.grpcwebPb.Product.Seller} */ (
    jspb.Message.getWrapperField(this, proto.grpcwebPb.Product.Seller, 11));
};


/**
 * @param {?proto.grpcwebPb.Product.Seller|undefined} value
 * @return {!proto.grpcwebPb.Product} returns this
*/
proto.grpcwebPb.Product.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.Product} returns this
 */
proto.grpcwebPb.Product.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.Product.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.grpcwebPb);
