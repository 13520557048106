// Fetch the daily performance data, roll it up by the selected level, and

import { extractErrorMessage } from "Common/errors/error";
import { FacebookConfiguration } from "Common/proto/ampdPb/facebookConfiguration_pb";
import { prettyStringForEnum } from "Common/utils/proto";
import { removeNullAndUndefined } from "Common/utils/tsUtils";
import { useFacebookCampaignConfigurations } from "ExtensionV2/queries/useFacebookCampaignConfigurations";
import {
  FB_RESOURCE_TYPE,
  useFacebookPerformanceData,
  usePerformanceDataRollupByLevel
} from "ExtensionV2/queries/useFacebookPerformanceData";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useMemo } from "react";
import { FacebookPerformanceTableData } from "./FacebookPerformanceTable";

// Fetch the daily performance data, roll it up by the selected level, and
// combine it with the resources meta data to create the table rows.
export function useFacebookPerformanceRowData(
  startDate: string,
  endDate: string,
  rollupLevel: FB_RESOURCE_TYPE
): {
  rowData: Array<FacebookPerformanceTableData>;
  isLoading: boolean;
  error: string | undefined;
  awaitingUpdatedConfigs: boolean;
} {
  const { siteAlias } = useSessionSite();

  // fetch ad meta data (name, status, etc)
  const {
    data: campaignConfigurations,
    isLoading: campaignConfigurationsLoading,
    error: campaignConfigurationsError,
    isFetchingUpToDateConfigs
  } = useFacebookCampaignConfigurations({ siteAlias });

  // We need a list of ad IDs to query performance metrics from Facebook on a
  // per ad basis, and the other resources broken down by the selected rollup
  // level.
  const configurations = useMemo((): {
    adIds: Array<string>;
    selectedRollupIds: Array<string>;
    ads: Array<
      FacebookConfiguration.AdDetails.AsObject & {
        campaignName: string;
        adSetName: string;
      }
    >;
    adSets: Array<
      FacebookConfiguration.AdSetDetails.AsObject & {
        campaignName: string;
      }
    >;
    campaigns: Array<FacebookConfiguration.CampaignDetails.AsObject>;
  } => {
    const campaigns =
      campaignConfigurations
        ?.flatMap(c => c.ampdResourceConfiguration?.facebook?.campaignDetails)
        .filter(removeNullAndUndefined) || [];

    const adSets = campaigns
      .flatMap(campaign =>
        campaign?.adSetDetailsList.map(adSet => ({
          ...adSet,
          campaignName: campaign.name
        }))
      )
      .filter(removeNullAndUndefined);

    const ads = adSets
      .flatMap(adSet =>
        adSet?.adDetailsList.map(ad => ({
          ...ad,
          adSetName: adSet.name,
          campaignName: adSet.campaignName
        }))
      )
      .filter(removeNullAndUndefined);

    const adIds =
      ads?.map(ad => ad?.adId).filter((adId): adId is string => !!adId) || [];

    let selectedRollupIds: Array<string> = adIds;
    if (rollupLevel === FB_RESOURCE_TYPE.AD_SET) {
      selectedRollupIds = adSets.map(adSet => adSet.adSetId);
    } else if (rollupLevel === FB_RESOURCE_TYPE.CAMPAIGN) {
      selectedRollupIds = campaigns.map(campaign => campaign.campaignId);
    }

    return {
      adIds,
      selectedRollupIds,
      ads,
      adSets,
      campaigns
    };
  }, [campaignConfigurations, rollupLevel]);

  // request daily performance data for the Ampd ads
  const {
    data: performanceData,
    isLoading: performanceDataLoading,
    error: performanceDataError
  } = useFacebookPerformanceData(configurations.adIds, startDate, endDate);

  // If we have performance data and the first configs response, we have enough
  // data to render the table. However, this data may be stale, so we we pass
  // this flag up so the caller can decide to tell the user that we are still
  // waiting for the updated configs response.
  const awaitingUpdatedConfigs =
    !!performanceData && !!campaignConfigurations && isFetchingUpToDateConfigs;

  // rollup the daily stats by resource level (ad/adSet/campaign)
  const rollupPerformanceData = usePerformanceDataRollupByLevel(
    performanceData,
    rollupLevel,
    configurations.selectedRollupIds
  );

  // combine the resources meta data with the performance data
  const adRows: Array<FacebookPerformanceTableData> = useMemo(() => {
    if (!performanceData || rollupLevel !== FB_RESOURCE_TYPE.AD) {
      return [];
    }

    const rows: Array<FacebookPerformanceTableData> = [];
    for (const [performanceAdId, performance] of Object.entries(
      rollupPerformanceData
    )) {
      const ad = configurations.ads.find(ad => ad.adId === performanceAdId);
      if (!ad) {
        continue;
      }

      rows.push({
        ...performance,
        id: performanceAdId,
        name: ad.name,
        status: prettyStringForEnum(
          FacebookConfiguration.AdDetails.Status.Option,
          ad.status
        ),
        rollupLevel,
        bidAmountCents: ad.bidAmount,
        adSetName: ad.adSetName,
        campaignName: ad.campaignName
      });
    }

    return rows;
  }, [configurations.ads, performanceData, rollupLevel, rollupPerformanceData]);

  const adSetRows: Array<FacebookPerformanceTableData> = useMemo(() => {
    if (!performanceData || rollupLevel !== FB_RESOURCE_TYPE.AD_SET) {
      return [];
    }

    const rows: Array<FacebookPerformanceTableData> = [];
    for (const [performanceAdSetId, performance] of Object.entries(
      rollupPerformanceData
    )) {
      const adSet = configurations.adSets.find(
        adSet => adSet.adSetId === performanceAdSetId
      );

      if (!adSet) {
        continue;
      }

      rows.push({
        ...performance,
        id: performanceAdSetId,
        name: adSet.name,
        status: prettyStringForEnum(
          FacebookConfiguration.AdSetDetails.Status.Option,
          adSet.status
        ),
        rollupLevel,
        dailyBudgetCents: adSet.dailyBudget,
        lifetimeBudgetCents: adSet.lifetimeBudget,
        bidAmountCents: adSet.bidAmount,
        campaignName: adSet.campaignName
      });
    }

    return rows;
  }, [
    configurations.adSets,
    performanceData,
    rollupLevel,
    rollupPerformanceData
  ]);

  const campaignRows: Array<FacebookPerformanceTableData> = useMemo(() => {
    if (
      !campaignConfigurations ||
      !performanceData ||
      rollupLevel !== FB_RESOURCE_TYPE.CAMPAIGN
    ) {
      return [];
    }

    const rows: Array<FacebookPerformanceTableData> = [];
    for (const [campaignId, performance] of Object.entries(
      rollupPerformanceData
    )) {
      const campaign = configurations.campaigns.find(
        campaign => campaign.campaignId === campaignId
      );

      if (!campaign) {
        continue;
      }

      rows.push({
        ...performance,
        id: campaignId,
        name: campaign.name,
        status: prettyStringForEnum(
          FacebookConfiguration.CampaignDetails.Status.Option,
          campaign.status
        ),
        rollupLevel,
        dailyBudgetCents: campaign.dailyBudget,
        lifetimeBudgetCents: campaign.lifetimeBudget
      });
    }

    return rows;
  }, [
    campaignConfigurations,
    configurations.campaigns,
    performanceData,
    rollupLevel,
    rollupPerformanceData
  ]);

  let rowData: Array<FacebookPerformanceTableData> = [];
  if (rollupLevel === FB_RESOURCE_TYPE.CAMPAIGN) {
    rowData = campaignRows;
  } else if (rollupLevel === FB_RESOURCE_TYPE.AD_SET) {
    rowData = adSetRows;
  } else {
    rowData = adRows;
  }

  return {
    rowData,
    isLoading: performanceDataLoading || campaignConfigurationsLoading,
    awaitingUpdatedConfigs,
    error:
      extractErrorMessage(campaignConfigurationsError) ||
      extractErrorMessage(performanceDataError)
  };
}
