import React from "react";
import { Button, Icon, Label, Loader, Segment } from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import styled from "styled-components/macro";

import { sendGAEvent } from "./GA";
import { AUDIT_PAGE_GA_CATEGORY } from "ExtensionV2/ExtensionV2";

const SelectableSpan = styled.span`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;

const SelectableSection = styled.section`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;

function AuditSegment(props) {
  const {
    siteAlias,
    show,
    disabled,
    loading,
    icon,
    color,
    title,
    ribbon, // {text}
    details,
    children,
    button,
    buttonLabel,
    buttonLoading,
    buttonDisabled,
    buttonBasic,
    onClick,
    setModalInfo,
    modalInfo,
    setConfirmInfo,
    confirmInfo,
    review
  } = props;

  if (show === false) {
    return null;
  }

  const handleClick = setModalInfo
    ? () => {
        sendGAEvent(
          AUDIT_PAGE_GA_CATEGORY,
          "Modal Open",
          siteAlias,
          modalInfo.header
        );
        setModalInfo(modalInfo);
      }
    : setConfirmInfo
    ? () => {
        sendGAEvent(
          AUDIT_PAGE_GA_CATEGORY,
          "Confirm Open",
          siteAlias,
          confirmInfo.header
        );
        setConfirmInfo(confirmInfo);
      }
    : onClick;

  // Reduce the top margin if we are showing the ribbon to improve the vertical
  // spacing a bit.
  //
  // Ideally we could just show the ribbon to the right, but there's a bug:
  // https://github.com/Semantic-Org/Semantic-UI/issues/4536
  const titleStyle = ribbon ? { margin: "0 0 16px 0" } : null;

  return (
    <Segment disabled={disabled}>
      {ribbon && (
        <Label
          ribbon={"right"}
          style={{ backgroundColor: "#F93822", color: "#FFFFFF" }}
        >
          {ribbon.text}
        </Label>
      )}
      <h4 style={titleStyle}>
        {icon &&
          (loading ? (
            <Loader
              style={{ marginRight: 5 }}
              active={loading}
              inline
              size="tiny"
            />
          ) : (
            <Icon name={icon} color={color} />
          ))}
        <SelectableSpan>{title}</SelectableSpan>
      </h4>
      {details && (
        <p>
          <SelectableSpan>{details}</SelectableSpan>
        </p>
      )}
      <SelectableSection>{children}</SelectableSection>
      {(buttonLabel || !!button) && (
        <Flex
          style={{ marginTop: "1em" }}
          flexDirection="row"
          justifyContent="right"
        >
          {button ? (
            button
          ) : (
            <Button
              style={{ marginLeft: "auto" }}
              size="mini"
              disabled={buttonDisabled || disabled}
              loading={buttonLoading}
              primary={!buttonBasic}
              basic={buttonBasic}
              onClick={handleClick}
            >
              {buttonLabel}
            </Button>
          )}
        </Flex>
      )}
      {review === 2 && modalInfo && (
        <ul>
          <li>
            <strong>Modal Header:</strong> {modalInfo.header}
          </li>
          <li>
            {modalInfo.content.includes &&
              modalInfo.content.includes("Educational stuff") && (
                <em>TODO:</em>
              )}{" "}
            <strong>Modal Content:</strong> {modalInfo.content}
          </li>
          {modalInfo.video && (
            <li>
              {modalInfo.video.includes &&
                modalInfo.video.includes("/499288942?") && <em>TODO:</em>}{" "}
              <strong>Modal Video:</strong> {modalInfo.video}
            </li>
          )}
          {modalInfo.image && (
            <li>
              {modalInfo.image.includes &&
                modalInfo.image.includes("/launch-an-ad.") && (
                  <em>TODO:</em>
                )}{" "}
              <strong>Modal Image:</strong> {modalInfo.image}
            </li>
          )}
        </ul>
      )}
      {review === 2 && confirmInfo && (
        <ul>
          <li>
            <strong>Confirm Header:</strong> {confirmInfo.header}
          </li>
          <li>
            <strong>Confirm Content:</strong> {confirmInfo.content}
          </li>
        </ul>
      )}
    </Segment>
  );
}

export default AuditSegment;
