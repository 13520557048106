import { useQuery, useQueries, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetWalmartSearchPageRequest,
  GetWalmartSearchPageReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Walmart_pb";
import { Walmart } from "Common/proto/common/walmart_pb";
import { None } from "Common/utils/tsUtils";
import {
  getWalmartMarketplaceInfo,
  getWalmartSearchPageURL
} from "Common/utils/walmart";

const fetchWalmartSearchPage = async (
  siteAlias: string,
  searchURL: string | None,
  searchTerm: string | None,
  marketplace: Walmart.Marketplace.Option
): Promise<GetWalmartSearchPageReply.AsObject> => {
  const req = new GetWalmartSearchPageRequest();
  req.setSiteAlias(siteAlias);
  req.setSearchUrl(searchURL || "");
  req.setSearchTerm(searchTerm || "");
  req.setMarketplace(marketplace);

  const reply = await GRPCWebClient.getWalmartSearchPage(req, {});
  return reply.toObject();
};

const fetchWalmartSearchPageQueryKey = (
  siteAlias: string,
  searchURL: string | None,
  searchTerm: string | None,
  marketplace: Walmart.Marketplace.Option
): Array<string | number> => {
  return [
    "walmartSearchPage",
    siteAlias,
    searchURL || "",
    searchTerm || "",
    marketplace
  ];
};

// Executes a realtime Bluecart scrape for any Walmart search page.
export const useWalmartSearchPage = (
  siteAlias: string,
  searchURL: string | None,
  searchTerm: string | None,
  marketplace: Walmart.Marketplace.Option
): UseQueryResult<GetWalmartSearchPageReply.AsObject, unknown> => {
  const oneHour = 1000 * 60 * 60; //one hour
  return useQuery({
    queryKey: fetchWalmartSearchPageQueryKey(
      siteAlias,
      searchURL,
      searchTerm,
      marketplace
    ),
    staleTime: oneHour,
    cacheTime: oneHour,
    enabled:
      !!siteAlias &&
      (!!searchTerm || !!searchURL) &&
      marketplace != Walmart.Marketplace.Option.UNKNOWN,
    queryFn: async () =>
      fetchWalmartSearchPage(siteAlias, searchURL, searchTerm, marketplace)
  });
};

// Queries Bluecart for all items that match all of the specified brand names
export const useFindWalmartItemsForBrands = (
  siteAlias: string,
  brandNames: Array<string>,
  marketplace: Walmart.Marketplace.Option
): Array<UseQueryResult<GetWalmartSearchPageReply.AsObject, unknown>> => {
  const oneHour = 1000 * 60 * 60; //one hour

  return useQueries({
    queries: brandNames.map(brandName => ({
      queryKey: [
        "walmartFullBrandItemSearch",
        siteAlias,
        brandName,
        marketplace
      ],
      staleTime: oneHour,
      cacheTime: oneHour,
      enabled:
        !!siteAlias &&
        !!brandName &&
        marketplace != Walmart.Marketplace.Option.UNKNOWN,
      queryFn: async () => {
        const itemSearchURL =
          getWalmartSearchPageURL(
            getWalmartMarketplaceInfo(marketplace),
            " ",
            [brandName],
            null,
            null,
            true
          ) || "";

        const brandResult = await fetchWalmartSearchPage(
          siteAlias,
          itemSearchURL,
          null,
          marketplace
        );

        return brandResult;
      }
    }))
  });
};
