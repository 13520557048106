// Initialize the datadog logger early so we can log any errors that may happen
// while the app is being set up.
import "Common/utils/datadogLoggers";

import React, { Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import ReactGA from "react-ga";
import "semantic-ui-css/semantic.min.css";
import "fontsource-roboto";

import { PageInfo } from "Common/components/PageHeader";
import {
  Error,
  extractErrorMessage,
  extractErrorRequestID
} from "Common/errors/error";
import { fetchWithRefresh } from "Common/utils/auth";
import introspectionResult from "./introspection-result";
import * as serviceWorker from "./serviceWorker";
import { PageInfoProvider, PageInfoReducer } from "Common/utils/pageInfo";
import _ from "lodash";
import { extractUTMParams, saveUTMParams } from "Common/utils/utmParams";
import queryString from "query-string";

/*

  WARNING:

  Nothing should be exported from this file. If something exported from this file gets imported by 
  another file, the application will be mounted. If this happens during a unit test, the test will 
  likely break.

*/

// Set up GA tracking.
//
// TODO(jonathan): Can we get rid of some of these trackers?
import {
  GA_AMPD_TRACKER,
  GA_EXTENSION_TRACKER,
  GA_ENTERPRISE_TRACKER
} from "Common/Common";

ReactGA.initialize(
  [
    {
      trackingId: process.env.REACT_APP_AMPD_GA_TRACKING,
      titleCase: false,
      gaOptions: {
        name: GA_AMPD_TRACKER,
        // NOTE: We must use "SameSite=None; Secure" for GA tracking to work in
        // a third-party context (e.g., embedded in an iframe on Shopify).
        cookieFlags: "SameSite=None; Secure"
      }
    },
    {
      // Register tracker for GA events.
      // Customer dimensions:
      //    dimension1 - site alias, if one
      //    dimension2 - user id
      //    dimension3 - opportunity type
      //    dimension4 - opportunity id
      // NOTE: Pass {debug: true} for the second argument to print GA
      // events to the console.
      trackingId: "UA-56845332-5",
      gaOptions: {
        name: GA_EXTENSION_TRACKER
        // It's possible that we need cookieFlags here for the Google Ads to
        // work in the Chrome Extension, but we haven't tested that.
      }
    }
  ],
  { testMode: process.env.NODE_ENV === "test" }
);

// This tracker is only defined in .env.prod right now.
if (process.env.REACT_APP_GA_TRACKING) {
  ReactGA.addTrackers([
    {
      trackingId: process.env.REACT_APP_GA_TRACKING,
      gaOptions: {
        name: GA_ENTERPRISE_TRACKER
      }
    }
  ]);
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  fetch: fetchWithRefresh
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
  if (graphQLErrors) {
    if (!response) {
      response = {};
    }
    response.errors = graphQLErrors.map(e => {
      const err = Error.fromGraphQLError(e);
      console.error(extractErrorMessage(err), `${extractErrorRequestID(err)}`);
      return err;
    });
  }
});

const link = ApolloLink.from([errorLink, httpLink]);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult
});

const dataIdFromObject = object => {
  // If the object has a query ID, use that as the object's id.  Apollo uses
  // 'id' by default, but that's kind of generic (and causes unwanted caching
  // behavior sometimes which is why we generally use fields like 'campaignId').
  // Having a specific query id is especially useful for large queries so the
  // cache doesn't try to do a deep diff on seemingly similar queries
  // (which can crash the app).
  if (object.queryId) {
    return object.queryId;
  }
  return defaultDataIdFromObject(object);
};

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({ fragmentMatcher, dataIdFromObject }).restore(
    window.__APOLLO_STATE__
  )
});

const mountingRoot =
  process.env.NODE_ENV === "test"
    ? document.createElement("div")
    : document.getElementById("root");

const mount = Component => {
  render(
    <ApolloProvider client={client}>
      <Router>
        <CompatRouter>
          <Suspense fallback={null}>
            <>
              <PageInfoProvider
                initialState={{ pageInfo: new PageInfo() }}
                reducer={PageInfoReducer}
              >
                <Component />
              </PageInfoProvider>
            </>
          </Suspense>
        </CompatRouter>
      </Router>
    </ApolloProvider>,
    mountingRoot
  );
};

// Store UTM parameters, if set.
// Save the UTM parameters, if there are any.
const utmParams = extractUTMParams(queryString.parse(window.location.search));

if (!_.isEmpty(utmParams)) {
  saveUTMParams(utmParams);
}

// Uncomment to enable prototype/frame
if (/^\/t\//g.test(window.location.pathname)) {
  import("./ExtensionV2").then(({ Index }) => mount(Index));
} else {
  import("./Common").then(({ Index }) => mount(Index));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
