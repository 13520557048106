export default {
  __schema: {
    types: [
      {
        kind: "UNION",
        name: "SiteError",
        possibleTypes: [
          {
            name: "DomainError"
          },
          {
            name: "DataSourceLinkError"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BaseSiteError",
        possibleTypes: [
          {
            name: "DomainError"
          },
          {
            name: "DataSourceLinkError"
          }
        ]
      },
      {
        kind: "UNION",
        name: "Opportunity",
        possibleTypes: [
          {
            name: "IncreaseKeywordCpcBidOpportunity"
          },
          {
            name: "IncreaseProductGroupCpcBidOpportunity"
          },
          {
            name: "AddKeywordFromShoppingSearchTermOpportunity"
          },
          {
            name: "AddKeywordFromOrganicSearchTermOpportunity"
          },
          {
            name: "AddDynamicSearchForSitemapFolderOpportunity"
          },
          {
            name: "NegateInefficientSearchTermOpportunity"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BaseOpportunity",
        possibleTypes: [
          {
            name: "IncreaseKeywordCpcBidOpportunity"
          },
          {
            name: "IncreaseProductGroupCpcBidOpportunity"
          },
          {
            name: "AddKeywordFromShoppingSearchTermOpportunity"
          },
          {
            name: "AddKeywordFromOrganicSearchTermOpportunity"
          },
          {
            name: "AddDynamicSearchForSitemapFolderOpportunity"
          },
          {
            name: "NegateInefficientSearchTermOpportunity"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "AdwordsOpportunity",
        possibleTypes: [
          {
            name: "IncreaseKeywordCpcBidOpportunity"
          },
          {
            name: "IncreaseProductGroupCpcBidOpportunity"
          },
          {
            name: "AddKeywordFromShoppingSearchTermOpportunity"
          },
          {
            name: "AddKeywordFromOrganicSearchTermOpportunity"
          },
          {
            name: "AddDynamicSearchForSitemapFolderOpportunity"
          },
          {
            name: "NegateInefficientSearchTermOpportunity"
          }
        ]
      }
    ]
  }
};
