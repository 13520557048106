// source: proto/entity/billingAccount.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_common_crudFields_pb = require('../../proto/common/crudFields_pb.js');
goog.object.extend(proto, proto_common_crudFields_pb);
var proto_common_dataSourceInfo_pb = require('../../proto/common/dataSourceInfo_pb.js');
goog.object.extend(proto, proto_common_dataSourceInfo_pb);
var proto_common_objectID_pb = require('../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
goog.exportSymbol('proto.entity.AmpdSubscription', null, global);
goog.exportSymbol('proto.entity.AmpdSubscription.AdSpendFee', null, global);
goog.exportSymbol('proto.entity.AmpdSubscription.Contract', null, global);
goog.exportSymbol('proto.entity.AmpdSubscription.ExternalPaymentSystemID', null, global);
goog.exportSymbol('proto.entity.AmpdSubscription.Features', null, global);
goog.exportSymbol('proto.entity.AmpdSubscription.OnboardingFee', null, global);
goog.exportSymbol('proto.entity.AmpdSubscription.SubscriptionID', null, global);
goog.exportSymbol('proto.entity.BillingAccount', null, global);
goog.exportSymbol('proto.entity.BillingAccount.Status', null, global);
goog.exportSymbol('proto.entity.BillingAccount.Status.Option', null, global);
goog.exportSymbol('proto.entity.BillingAccount.StatusConfig', null, global);
goog.exportSymbol('proto.entity.BillingAccountClass', null, global);
goog.exportSymbol('proto.entity.BillingAccountClassDetails', null, global);
goog.exportSymbol('proto.entity.BillingAccountClassDetails.TypeCase', null, global);
goog.exportSymbol('proto.entity.BillingAccountDetails', null, global);
goog.exportSymbol('proto.entity.EnterpriseAccount', null, global);
goog.exportSymbol('proto.entity.FreeAccount', null, global);
goog.exportSymbol('proto.entity.InternalAccount', null, global);
goog.exportSymbol('proto.entity.InvoiceAccount', null, global);
goog.exportSymbol('proto.entity.InvoiceAccount.TypeCase', null, global);
goog.exportSymbol('proto.entity.LinkedAccount', null, global);
goog.exportSymbol('proto.entity.NewBillingAccount', null, global);
goog.exportSymbol('proto.entity.ShopifyAccount', null, global);
goog.exportSymbol('proto.entity.Stripe', null, global);
goog.exportSymbol('proto.entity.Stripe.Coupon', null, global);
goog.exportSymbol('proto.entity.Stripe.Customer', null, global);
goog.exportSymbol('proto.entity.Stripe.CustomerInvoiceSettings', null, global);
goog.exportSymbol('proto.entity.Stripe.Invoice', null, global);
goog.exportSymbol('proto.entity.Stripe.Invoice.Discount', null, global);
goog.exportSymbol('proto.entity.Stripe.Invoice.LineItem', null, global);
goog.exportSymbol('proto.entity.Stripe.PaymentMethod', null, global);
goog.exportSymbol('proto.entity.Stripe.SetupIntent', null, global);
goog.exportSymbol('proto.entity.StripeAccount', null, global);
goog.exportSymbol('proto.entity.StripeAccount.PaymentIntent', null, global);
goog.exportSymbol('proto.entity.StripeAccount.Plan', null, global);
goog.exportSymbol('proto.entity.StripeAccount.Subscription', null, global);
goog.exportSymbol('proto.entity.StripeInvoiceAccount', null, global);
goog.exportSymbol('proto.entity.TrialAccount', null, global);
goog.exportSymbol('proto.entity.UpdateBillingAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateBillingAccountAction.ActionCase', null, global);
goog.exportSymbol('proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction', null, global);
goog.exportSymbol('proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction', null, global);
goog.exportSymbol('proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction', null, global);
goog.exportSymbol('proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction', null, global);
goog.exportSymbol('proto.entity.billingAccountStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.NewBillingAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.NewBillingAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.NewBillingAccount.displayName = 'proto.entity.NewBillingAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BillingAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BillingAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BillingAccount.displayName = 'proto.entity.BillingAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BillingAccount.StatusConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BillingAccount.StatusConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BillingAccount.StatusConfig.displayName = 'proto.entity.BillingAccount.StatusConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BillingAccount.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BillingAccount.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BillingAccount.Status.displayName = 'proto.entity.BillingAccount.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BillingAccountDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.BillingAccountDetails.repeatedFields_, null);
};
goog.inherits(proto.entity.BillingAccountDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BillingAccountDetails.displayName = 'proto.entity.BillingAccountDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BillingAccountClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.BillingAccountClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BillingAccountClass.displayName = 'proto.entity.BillingAccountClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.BillingAccountClassDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.BillingAccountClassDetails.oneofGroups_);
};
goog.inherits(proto.entity.BillingAccountClassDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.BillingAccountClassDetails.displayName = 'proto.entity.BillingAccountClassDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.TrialAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.TrialAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.TrialAccount.displayName = 'proto.entity.TrialAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.FreeAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.FreeAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.FreeAccount.displayName = 'proto.entity.FreeAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InvoiceAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.InvoiceAccount.oneofGroups_);
};
goog.inherits(proto.entity.InvoiceAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InvoiceAccount.displayName = 'proto.entity.InvoiceAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.StripeInvoiceAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.StripeInvoiceAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.StripeInvoiceAccount.displayName = 'proto.entity.StripeInvoiceAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.displayName = 'proto.entity.Stripe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.Customer.displayName = 'proto.entity.Stripe.Customer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.CustomerInvoiceSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.CustomerInvoiceSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.CustomerInvoiceSettings.displayName = 'proto.entity.Stripe.CustomerInvoiceSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.PaymentMethod.displayName = 'proto.entity.Stripe.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.SetupIntent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.SetupIntent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.SetupIntent.displayName = 'proto.entity.Stripe.SetupIntent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.Coupon.displayName = 'proto.entity.Stripe.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.Stripe.Invoice.repeatedFields_, null);
};
goog.inherits(proto.entity.Stripe.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.Invoice.displayName = 'proto.entity.Stripe.Invoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.Invoice.LineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.Invoice.LineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.Invoice.LineItem.displayName = 'proto.entity.Stripe.Invoice.LineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.Stripe.Invoice.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.Stripe.Invoice.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.Stripe.Invoice.Discount.displayName = 'proto.entity.Stripe.Invoice.Discount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.StripeAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.StripeAccount.repeatedFields_, null);
};
goog.inherits(proto.entity.StripeAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.StripeAccount.displayName = 'proto.entity.StripeAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.StripeAccount.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.StripeAccount.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.StripeAccount.Subscription.displayName = 'proto.entity.StripeAccount.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.StripeAccount.Plan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.StripeAccount.Plan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.StripeAccount.Plan.displayName = 'proto.entity.StripeAccount.Plan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.StripeAccount.PaymentIntent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.StripeAccount.PaymentIntent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.StripeAccount.PaymentIntent.displayName = 'proto.entity.StripeAccount.PaymentIntent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.ShopifyAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.ShopifyAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.ShopifyAccount.displayName = 'proto.entity.ShopifyAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.EnterpriseAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.EnterpriseAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.EnterpriseAccount.displayName = 'proto.entity.EnterpriseAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.LinkedAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.LinkedAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.LinkedAccount.displayName = 'proto.entity.LinkedAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.InternalAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.InternalAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.InternalAccount.displayName = 'proto.entity.InternalAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateBillingAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.UpdateBillingAccountAction.oneofGroups_);
};
goog.inherits(proto.entity.UpdateBillingAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateBillingAccountAction.displayName = 'proto.entity.UpdateBillingAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.displayName = 'proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.displayName = 'proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.displayName = 'proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.displayName = 'proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.AmpdSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.AmpdSubscription.repeatedFields_, null);
};
goog.inherits(proto.entity.AmpdSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.AmpdSubscription.displayName = 'proto.entity.AmpdSubscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.AmpdSubscription.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.AmpdSubscription.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.AmpdSubscription.Contract.displayName = 'proto.entity.AmpdSubscription.Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.AmpdSubscription.ExternalPaymentSystemID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.AmpdSubscription.ExternalPaymentSystemID.displayName = 'proto.entity.AmpdSubscription.ExternalPaymentSystemID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.AmpdSubscription.OnboardingFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.AmpdSubscription.OnboardingFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.AmpdSubscription.OnboardingFee.displayName = 'proto.entity.AmpdSubscription.OnboardingFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.AmpdSubscription.AdSpendFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.AmpdSubscription.AdSpendFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.AmpdSubscription.AdSpendFee.displayName = 'proto.entity.AmpdSubscription.AdSpendFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.AmpdSubscription.Features = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.AmpdSubscription.Features, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.AmpdSubscription.Features.displayName = 'proto.entity.AmpdSubscription.Features';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.NewBillingAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.NewBillingAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.NewBillingAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewBillingAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    details: (f = msg.getDetails()) && proto.entity.BillingAccountDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.NewBillingAccount}
 */
proto.entity.NewBillingAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.NewBillingAccount;
  return proto.entity.NewBillingAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.NewBillingAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.NewBillingAccount}
 */
proto.entity.NewBillingAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.BillingAccountDetails;
      reader.readMessage(value,proto.entity.BillingAccountDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.NewBillingAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.NewBillingAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.NewBillingAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.NewBillingAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.BillingAccountDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingAccountDetails details = 1;
 * @return {?proto.entity.BillingAccountDetails}
 */
proto.entity.NewBillingAccount.prototype.getDetails = function() {
  return /** @type{?proto.entity.BillingAccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountDetails, 1));
};


/**
 * @param {?proto.entity.BillingAccountDetails|undefined} value
 * @return {!proto.entity.NewBillingAccount} returns this
*/
proto.entity.NewBillingAccount.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.NewBillingAccount} returns this
 */
proto.entity.NewBillingAccount.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.NewBillingAccount.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BillingAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BillingAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BillingAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    billingAccountId: (f = msg.getBillingAccountId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    details: (f = msg.getDetails()) && proto.entity.BillingAccountDetails.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BillingAccount}
 */
proto.entity.BillingAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BillingAccount;
  return proto.entity.BillingAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BillingAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BillingAccount}
 */
proto.entity.BillingAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setBillingAccountId(value);
      break;
    case 2:
      var value = new proto.entity.BillingAccountDetails;
      reader.readMessage(value,proto.entity.BillingAccountDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.BillingAccount.Status.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BillingAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BillingAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BillingAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillingAccountId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.BillingAccountDetails.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BillingAccount.StatusConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BillingAccount.StatusConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BillingAccount.StatusConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccount.StatusConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    precedence: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BillingAccount.StatusConfig}
 */
proto.entity.BillingAccount.StatusConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BillingAccount.StatusConfig;
  return proto.entity.BillingAccount.StatusConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BillingAccount.StatusConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BillingAccount.StatusConfig}
 */
proto.entity.BillingAccount.StatusConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecedence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BillingAccount.StatusConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BillingAccount.StatusConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BillingAccount.StatusConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccount.StatusConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrecedence();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 precedence = 1;
 * @return {number}
 */
proto.entity.BillingAccount.StatusConfig.prototype.getPrecedence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.BillingAccount.StatusConfig} returns this
 */
proto.entity.BillingAccount.StatusConfig.prototype.setPrecedence = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BillingAccount.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BillingAccount.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BillingAccount.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccount.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BillingAccount.Status}
 */
proto.entity.BillingAccount.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BillingAccount.Status;
  return proto.entity.BillingAccount.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BillingAccount.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BillingAccount.Status}
 */
proto.entity.BillingAccount.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BillingAccount.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BillingAccount.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BillingAccount.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccount.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.entity.BillingAccount.Status.Option = {
  UNKNOWN: 0,
  ACTIVE: 1,
  LINKED: 7,
  DELINQUENT: 6,
  TRIAL_ACTIVE: 2,
  PENDING: 4,
  TRIAL_EXPIRED: 3,
  CANCELLED: 5
};

/**
 * optional common.ObjectID billing_account_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.BillingAccount.prototype.getBillingAccountId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.BillingAccount} returns this
*/
proto.entity.BillingAccount.prototype.setBillingAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccount} returns this
 */
proto.entity.BillingAccount.prototype.clearBillingAccountId = function() {
  return this.setBillingAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccount.prototype.hasBillingAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BillingAccountDetails details = 2;
 * @return {?proto.entity.BillingAccountDetails}
 */
proto.entity.BillingAccount.prototype.getDetails = function() {
  return /** @type{?proto.entity.BillingAccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountDetails, 2));
};


/**
 * @param {?proto.entity.BillingAccountDetails|undefined} value
 * @return {!proto.entity.BillingAccount} returns this
*/
proto.entity.BillingAccount.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccount} returns this
 */
proto.entity.BillingAccount.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccount.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status.Option status = 4;
 * @return {!proto.entity.BillingAccount.Status.Option}
 */
proto.entity.BillingAccount.prototype.getStatus = function() {
  return /** @type {!proto.entity.BillingAccount.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.BillingAccount.Status.Option} value
 * @return {!proto.entity.BillingAccount} returns this
 */
proto.entity.BillingAccount.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.CRUDFields crud_fields = 3;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.BillingAccount.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 3));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.BillingAccount} returns this
*/
proto.entity.BillingAccount.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccount} returns this
 */
proto.entity.BillingAccount.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccount.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.BillingAccountDetails.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BillingAccountDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BillingAccountDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BillingAccountDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccountDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountClassesList: jspb.Message.toObjectList(msg.getAccountClassesList(),
    proto.entity.BillingAccountClass.toObject, includeInstance),
    ampdSubscriptionId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BillingAccountDetails}
 */
proto.entity.BillingAccountDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BillingAccountDetails;
  return proto.entity.BillingAccountDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BillingAccountDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BillingAccountDetails}
 */
proto.entity.BillingAccountDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.entity.BillingAccountClass;
      reader.readMessage(value,proto.entity.BillingAccountClass.deserializeBinaryFromReader);
      msg.addAccountClasses(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.AmpdSubscription.SubscriptionID} */ (reader.readEnum());
      msg.setAmpdSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BillingAccountDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BillingAccountDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BillingAccountDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccountDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountClassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.entity.BillingAccountClass.serializeBinaryToWriter
    );
  }
  f = message.getAmpdSubscriptionId();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated BillingAccountClass account_classes = 2;
 * @return {!Array<!proto.entity.BillingAccountClass>}
 */
proto.entity.BillingAccountDetails.prototype.getAccountClassesList = function() {
  return /** @type{!Array<!proto.entity.BillingAccountClass>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.BillingAccountClass, 2));
};


/**
 * @param {!Array<!proto.entity.BillingAccountClass>} value
 * @return {!proto.entity.BillingAccountDetails} returns this
*/
proto.entity.BillingAccountDetails.prototype.setAccountClassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.BillingAccountClass=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.BillingAccountClass}
 */
proto.entity.BillingAccountDetails.prototype.addAccountClasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.BillingAccountClass, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.BillingAccountDetails} returns this
 */
proto.entity.BillingAccountDetails.prototype.clearAccountClassesList = function() {
  return this.setAccountClassesList([]);
};


/**
 * optional AmpdSubscription.SubscriptionID ampd_subscription_id = 3;
 * @return {!proto.entity.AmpdSubscription.SubscriptionID}
 */
proto.entity.BillingAccountDetails.prototype.getAmpdSubscriptionId = function() {
  return /** @type {!proto.entity.AmpdSubscription.SubscriptionID} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.AmpdSubscription.SubscriptionID} value
 * @return {!proto.entity.BillingAccountDetails} returns this
 */
proto.entity.BillingAccountDetails.prototype.setAmpdSubscriptionId = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BillingAccountClass.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BillingAccountClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BillingAccountClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccountClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    details: (f = msg.getDetails()) && proto.entity.BillingAccountClassDetails.toObject(includeInstance, f),
    createdTime: (f = msg.getCreatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedTime: (f = msg.getUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BillingAccountClass}
 */
proto.entity.BillingAccountClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BillingAccountClass;
  return proto.entity.BillingAccountClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BillingAccountClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BillingAccountClass}
 */
proto.entity.BillingAccountClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.BillingAccountClassDetails;
      reader.readMessage(value,proto.entity.BillingAccountClassDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BillingAccountClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BillingAccountClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BillingAccountClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccountClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.BillingAccountClassDetails.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingAccountClassDetails details = 1;
 * @return {?proto.entity.BillingAccountClassDetails}
 */
proto.entity.BillingAccountClass.prototype.getDetails = function() {
  return /** @type{?proto.entity.BillingAccountClassDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountClassDetails, 1));
};


/**
 * @param {?proto.entity.BillingAccountClassDetails|undefined} value
 * @return {!proto.entity.BillingAccountClass} returns this
*/
proto.entity.BillingAccountClass.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClass} returns this
 */
proto.entity.BillingAccountClass.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClass.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp created_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.BillingAccountClass.prototype.getCreatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.BillingAccountClass} returns this
*/
proto.entity.BillingAccountClass.prototype.setCreatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClass} returns this
 */
proto.entity.BillingAccountClass.prototype.clearCreatedTime = function() {
  return this.setCreatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClass.prototype.hasCreatedTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.BillingAccountClass.prototype.getUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.BillingAccountClass} returns this
*/
proto.entity.BillingAccountClass.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClass} returns this
 */
proto.entity.BillingAccountClass.prototype.clearUpdatedTime = function() {
  return this.setUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClass.prototype.hasUpdatedTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.BillingAccountClassDetails.oneofGroups_ = [[5,6,1,4,7,2,3,8]];

/**
 * @enum {number}
 */
proto.entity.BillingAccountClassDetails.TypeCase = {
  TYPE_NOT_SET: 0,
  TRIAL: 5,
  FREE: 6,
  STRIPE: 1,
  SHOPIFY: 4,
  INVOICE: 7,
  ENTERPRISE: 2,
  INTERNAL: 3,
  LINKED: 8
};

/**
 * @return {proto.entity.BillingAccountClassDetails.TypeCase}
 */
proto.entity.BillingAccountClassDetails.prototype.getTypeCase = function() {
  return /** @type {proto.entity.BillingAccountClassDetails.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.BillingAccountClassDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.BillingAccountClassDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.BillingAccountClassDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.BillingAccountClassDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccountClassDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    trial: (f = msg.getTrial()) && proto.entity.TrialAccount.toObject(includeInstance, f),
    free: (f = msg.getFree()) && proto.entity.FreeAccount.toObject(includeInstance, f),
    stripe: (f = msg.getStripe()) && proto.entity.StripeAccount.toObject(includeInstance, f),
    shopify: (f = msg.getShopify()) && proto.entity.ShopifyAccount.toObject(includeInstance, f),
    invoice: (f = msg.getInvoice()) && proto.entity.InvoiceAccount.toObject(includeInstance, f),
    enterprise: (f = msg.getEnterprise()) && proto.entity.EnterpriseAccount.toObject(includeInstance, f),
    internal: (f = msg.getInternal()) && proto.entity.InternalAccount.toObject(includeInstance, f),
    linked: (f = msg.getLinked()) && proto.entity.LinkedAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.BillingAccountClassDetails}
 */
proto.entity.BillingAccountClassDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.BillingAccountClassDetails;
  return proto.entity.BillingAccountClassDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.BillingAccountClassDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.BillingAccountClassDetails}
 */
proto.entity.BillingAccountClassDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.entity.TrialAccount;
      reader.readMessage(value,proto.entity.TrialAccount.deserializeBinaryFromReader);
      msg.setTrial(value);
      break;
    case 6:
      var value = new proto.entity.FreeAccount;
      reader.readMessage(value,proto.entity.FreeAccount.deserializeBinaryFromReader);
      msg.setFree(value);
      break;
    case 1:
      var value = new proto.entity.StripeAccount;
      reader.readMessage(value,proto.entity.StripeAccount.deserializeBinaryFromReader);
      msg.setStripe(value);
      break;
    case 4:
      var value = new proto.entity.ShopifyAccount;
      reader.readMessage(value,proto.entity.ShopifyAccount.deserializeBinaryFromReader);
      msg.setShopify(value);
      break;
    case 7:
      var value = new proto.entity.InvoiceAccount;
      reader.readMessage(value,proto.entity.InvoiceAccount.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    case 2:
      var value = new proto.entity.EnterpriseAccount;
      reader.readMessage(value,proto.entity.EnterpriseAccount.deserializeBinaryFromReader);
      msg.setEnterprise(value);
      break;
    case 3:
      var value = new proto.entity.InternalAccount;
      reader.readMessage(value,proto.entity.InternalAccount.deserializeBinaryFromReader);
      msg.setInternal(value);
      break;
    case 8:
      var value = new proto.entity.LinkedAccount;
      reader.readMessage(value,proto.entity.LinkedAccount.deserializeBinaryFromReader);
      msg.setLinked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.BillingAccountClassDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.BillingAccountClassDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.BillingAccountClassDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.BillingAccountClassDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrial();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.TrialAccount.serializeBinaryToWriter
    );
  }
  f = message.getFree();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.FreeAccount.serializeBinaryToWriter
    );
  }
  f = message.getStripe();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.StripeAccount.serializeBinaryToWriter
    );
  }
  f = message.getShopify();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.ShopifyAccount.serializeBinaryToWriter
    );
  }
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.InvoiceAccount.serializeBinaryToWriter
    );
  }
  f = message.getEnterprise();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.EnterpriseAccount.serializeBinaryToWriter
    );
  }
  f = message.getInternal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.InternalAccount.serializeBinaryToWriter
    );
  }
  f = message.getLinked();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.entity.LinkedAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrialAccount trial = 5;
 * @return {?proto.entity.TrialAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getTrial = function() {
  return /** @type{?proto.entity.TrialAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.TrialAccount, 5));
};


/**
 * @param {?proto.entity.TrialAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setTrial = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearTrial = function() {
  return this.setTrial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasTrial = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FreeAccount free = 6;
 * @return {?proto.entity.FreeAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getFree = function() {
  return /** @type{?proto.entity.FreeAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.FreeAccount, 6));
};


/**
 * @param {?proto.entity.FreeAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setFree = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearFree = function() {
  return this.setFree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasFree = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StripeAccount stripe = 1;
 * @return {?proto.entity.StripeAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getStripe = function() {
  return /** @type{?proto.entity.StripeAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.StripeAccount, 1));
};


/**
 * @param {?proto.entity.StripeAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setStripe = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearStripe = function() {
  return this.setStripe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasStripe = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ShopifyAccount shopify = 4;
 * @return {?proto.entity.ShopifyAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getShopify = function() {
  return /** @type{?proto.entity.ShopifyAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.ShopifyAccount, 4));
};


/**
 * @param {?proto.entity.ShopifyAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setShopify = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearShopify = function() {
  return this.setShopify(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasShopify = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional InvoiceAccount invoice = 7;
 * @return {?proto.entity.InvoiceAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getInvoice = function() {
  return /** @type{?proto.entity.InvoiceAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.InvoiceAccount, 7));
};


/**
 * @param {?proto.entity.InvoiceAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setInvoice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EnterpriseAccount enterprise = 2;
 * @return {?proto.entity.EnterpriseAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getEnterprise = function() {
  return /** @type{?proto.entity.EnterpriseAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.EnterpriseAccount, 2));
};


/**
 * @param {?proto.entity.EnterpriseAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setEnterprise = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearEnterprise = function() {
  return this.setEnterprise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasEnterprise = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InternalAccount internal = 3;
 * @return {?proto.entity.InternalAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getInternal = function() {
  return /** @type{?proto.entity.InternalAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.InternalAccount, 3));
};


/**
 * @param {?proto.entity.InternalAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setInternal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearInternal = function() {
  return this.setInternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasInternal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LinkedAccount linked = 8;
 * @return {?proto.entity.LinkedAccount}
 */
proto.entity.BillingAccountClassDetails.prototype.getLinked = function() {
  return /** @type{?proto.entity.LinkedAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.LinkedAccount, 8));
};


/**
 * @param {?proto.entity.LinkedAccount|undefined} value
 * @return {!proto.entity.BillingAccountClassDetails} returns this
*/
proto.entity.BillingAccountClassDetails.prototype.setLinked = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.entity.BillingAccountClassDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.BillingAccountClassDetails} returns this
 */
proto.entity.BillingAccountClassDetails.prototype.clearLinked = function() {
  return this.setLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.BillingAccountClassDetails.prototype.hasLinked = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.TrialAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.TrialAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.TrialAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.TrialAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    expirationTime: (f = msg.getExpirationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.TrialAccount}
 */
proto.entity.TrialAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.TrialAccount;
  return proto.entity.TrialAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.TrialAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.TrialAccount}
 */
proto.entity.TrialAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.TrialAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.TrialAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.TrialAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.TrialAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp expiration_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.TrialAccount.prototype.getExpirationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.TrialAccount} returns this
*/
proto.entity.TrialAccount.prototype.setExpirationTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.TrialAccount} returns this
 */
proto.entity.TrialAccount.prototype.clearExpirationTime = function() {
  return this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.TrialAccount.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.FreeAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.FreeAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.FreeAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.FreeAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalAdSpend: (f = msg.getOriginalAdSpend()) && proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.FreeAccount}
 */
proto.entity.FreeAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.FreeAccount;
  return proto.entity.FreeAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.FreeAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.FreeAccount}
 */
proto.entity.FreeAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric;
      reader.readMessage(value,proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric.deserializeBinaryFromReader);
      msg.setOriginalAdSpend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.FreeAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.FreeAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.FreeAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.FreeAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalAdSpend();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.GoogleAdsAccountMetric original_ad_spend = 1;
 * @return {?proto.common.GoogleAdsAccountMetric}
 */
proto.entity.FreeAccount.prototype.getOriginalAdSpend = function() {
  return /** @type{?proto.common.GoogleAdsAccountMetric} */ (
    jspb.Message.getWrapperField(this, proto_common_dataSourceInfo_pb.GoogleAdsAccountMetric, 1));
};


/**
 * @param {?proto.common.GoogleAdsAccountMetric|undefined} value
 * @return {!proto.entity.FreeAccount} returns this
*/
proto.entity.FreeAccount.prototype.setOriginalAdSpend = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.FreeAccount} returns this
 */
proto.entity.FreeAccount.prototype.clearOriginalAdSpend = function() {
  return this.setOriginalAdSpend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.FreeAccount.prototype.hasOriginalAdSpend = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.InvoiceAccount.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.entity.InvoiceAccount.TypeCase = {
  TYPE_NOT_SET: 0,
  STRIPE: 1
};

/**
 * @return {proto.entity.InvoiceAccount.TypeCase}
 */
proto.entity.InvoiceAccount.prototype.getTypeCase = function() {
  return /** @type {proto.entity.InvoiceAccount.TypeCase} */(jspb.Message.computeOneofCase(this, proto.entity.InvoiceAccount.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InvoiceAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InvoiceAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InvoiceAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InvoiceAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    stripe: (f = msg.getStripe()) && proto.entity.StripeInvoiceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InvoiceAccount}
 */
proto.entity.InvoiceAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InvoiceAccount;
  return proto.entity.InvoiceAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InvoiceAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InvoiceAccount}
 */
proto.entity.InvoiceAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.StripeInvoiceAccount;
      reader.readMessage(value,proto.entity.StripeInvoiceAccount.deserializeBinaryFromReader);
      msg.setStripe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InvoiceAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InvoiceAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InvoiceAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InvoiceAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStripe();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.StripeInvoiceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional StripeInvoiceAccount stripe = 1;
 * @return {?proto.entity.StripeInvoiceAccount}
 */
proto.entity.InvoiceAccount.prototype.getStripe = function() {
  return /** @type{?proto.entity.StripeInvoiceAccount} */ (
    jspb.Message.getWrapperField(this, proto.entity.StripeInvoiceAccount, 1));
};


/**
 * @param {?proto.entity.StripeInvoiceAccount|undefined} value
 * @return {!proto.entity.InvoiceAccount} returns this
*/
proto.entity.InvoiceAccount.prototype.setStripe = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.InvoiceAccount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.InvoiceAccount} returns this
 */
proto.entity.InvoiceAccount.prototype.clearStripe = function() {
  return this.setStripe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.InvoiceAccount.prototype.hasStripe = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.StripeInvoiceAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.StripeInvoiceAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.StripeInvoiceAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeInvoiceAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: (f = msg.getCustomer()) && proto.entity.Stripe.Customer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.StripeInvoiceAccount}
 */
proto.entity.StripeInvoiceAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.StripeInvoiceAccount;
  return proto.entity.StripeInvoiceAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.StripeInvoiceAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.StripeInvoiceAccount}
 */
proto.entity.StripeInvoiceAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.Stripe.Customer;
      reader.readMessage(value,proto.entity.Stripe.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.StripeInvoiceAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.StripeInvoiceAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.StripeInvoiceAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeInvoiceAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.Stripe.Customer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Stripe.Customer customer = 1;
 * @return {?proto.entity.Stripe.Customer}
 */
proto.entity.StripeInvoiceAccount.prototype.getCustomer = function() {
  return /** @type{?proto.entity.Stripe.Customer} */ (
    jspb.Message.getWrapperField(this, proto.entity.Stripe.Customer, 1));
};


/**
 * @param {?proto.entity.Stripe.Customer|undefined} value
 * @return {!proto.entity.StripeInvoiceAccount} returns this
*/
proto.entity.StripeInvoiceAccount.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.StripeInvoiceAccount} returns this
 */
proto.entity.StripeInvoiceAccount.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.StripeInvoiceAccount.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe}
 */
proto.entity.Stripe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe;
  return proto.entity.Stripe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe}
 */
proto.entity.Stripe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    invoiceSettings: (f = msg.getInvoiceSettings()) && proto.entity.Stripe.CustomerInvoiceSettings.toObject(includeInstance, f),
    livemode: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    delinquent: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.Customer}
 */
proto.entity.Stripe.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.Customer;
  return proto.entity.Stripe.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.Customer}
 */
proto.entity.Stripe.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = new proto.entity.Stripe.CustomerInvoiceSettings;
      reader.readMessage(value,proto.entity.Stripe.CustomerInvoiceSettings.deserializeBinaryFromReader);
      msg.setInvoiceSettings(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLivemode(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelinquent(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInvoiceSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.Stripe.CustomerInvoiceSettings.serializeBinaryToWriter
    );
  }
  f = message.getLivemode();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDelinquent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.Stripe.Customer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.entity.Stripe.Customer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.entity.Stripe.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.entity.Stripe.Customer.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.entity.Stripe.Customer.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional CustomerInvoiceSettings invoice_settings = 6;
 * @return {?proto.entity.Stripe.CustomerInvoiceSettings}
 */
proto.entity.Stripe.Customer.prototype.getInvoiceSettings = function() {
  return /** @type{?proto.entity.Stripe.CustomerInvoiceSettings} */ (
    jspb.Message.getWrapperField(this, proto.entity.Stripe.CustomerInvoiceSettings, 6));
};


/**
 * @param {?proto.entity.Stripe.CustomerInvoiceSettings|undefined} value
 * @return {!proto.entity.Stripe.Customer} returns this
*/
proto.entity.Stripe.Customer.prototype.setInvoiceSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.clearInvoiceSettings = function() {
  return this.setInvoiceSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Customer.prototype.hasInvoiceSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool livemode = 7;
 * @return {boolean}
 */
proto.entity.Stripe.Customer.prototype.getLivemode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setLivemode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool delinquent = 8;
 * @return {boolean}
 */
proto.entity.Stripe.Customer.prototype.getDelinquent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setDelinquent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool deleted = 9;
 * @return {boolean}
 */
proto.entity.Stripe.Customer.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.Stripe.Customer} returns this
 */
proto.entity.Stripe.Customer.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.CustomerInvoiceSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.CustomerInvoiceSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.CustomerInvoiceSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.CustomerInvoiceSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultPaymentMethod: (f = msg.getDefaultPaymentMethod()) && proto.entity.Stripe.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.CustomerInvoiceSettings}
 */
proto.entity.Stripe.CustomerInvoiceSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.CustomerInvoiceSettings;
  return proto.entity.Stripe.CustomerInvoiceSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.CustomerInvoiceSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.CustomerInvoiceSettings}
 */
proto.entity.Stripe.CustomerInvoiceSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.Stripe.PaymentMethod;
      reader.readMessage(value,proto.entity.Stripe.PaymentMethod.deserializeBinaryFromReader);
      msg.setDefaultPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.CustomerInvoiceSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.CustomerInvoiceSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.CustomerInvoiceSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.CustomerInvoiceSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.Stripe.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaymentMethod default_payment_method = 1;
 * @return {?proto.entity.Stripe.PaymentMethod}
 */
proto.entity.Stripe.CustomerInvoiceSettings.prototype.getDefaultPaymentMethod = function() {
  return /** @type{?proto.entity.Stripe.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.entity.Stripe.PaymentMethod, 1));
};


/**
 * @param {?proto.entity.Stripe.PaymentMethod|undefined} value
 * @return {!proto.entity.Stripe.CustomerInvoiceSettings} returns this
*/
proto.entity.Stripe.CustomerInvoiceSettings.prototype.setDefaultPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.CustomerInvoiceSettings} returns this
 */
proto.entity.Stripe.CustomerInvoiceSettings.prototype.clearDefaultPaymentMethod = function() {
  return this.setDefaultPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.CustomerInvoiceSettings.prototype.hasDefaultPaymentMethod = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.PaymentMethod}
 */
proto.entity.Stripe.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.PaymentMethod;
  return proto.entity.Stripe.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.PaymentMethod}
 */
proto.entity.Stripe.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.Stripe.PaymentMethod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.PaymentMethod} returns this
 */
proto.entity.Stripe.PaymentMethod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.SetupIntent.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.SetupIntent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.SetupIntent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.SetupIntent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customer: (f = msg.getCustomer()) && proto.entity.Stripe.Customer.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.entity.Stripe.PaymentMethod.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.SetupIntent}
 */
proto.entity.Stripe.SetupIntent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.SetupIntent;
  return proto.entity.Stripe.SetupIntent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.SetupIntent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.SetupIntent}
 */
proto.entity.Stripe.SetupIntent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 3:
      var value = new proto.entity.Stripe.Customer;
      reader.readMessage(value,proto.entity.Stripe.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 4:
      var value = new proto.entity.Stripe.PaymentMethod;
      reader.readMessage(value,proto.entity.Stripe.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.SetupIntent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.SetupIntent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.SetupIntent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.SetupIntent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.Stripe.Customer.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.Stripe.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.Stripe.SetupIntent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.SetupIntent} returns this
 */
proto.entity.Stripe.SetupIntent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_secret = 2;
 * @return {string}
 */
proto.entity.Stripe.SetupIntent.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.SetupIntent} returns this
 */
proto.entity.Stripe.SetupIntent.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Customer customer = 3;
 * @return {?proto.entity.Stripe.Customer}
 */
proto.entity.Stripe.SetupIntent.prototype.getCustomer = function() {
  return /** @type{?proto.entity.Stripe.Customer} */ (
    jspb.Message.getWrapperField(this, proto.entity.Stripe.Customer, 3));
};


/**
 * @param {?proto.entity.Stripe.Customer|undefined} value
 * @return {!proto.entity.Stripe.SetupIntent} returns this
*/
proto.entity.Stripe.SetupIntent.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.SetupIntent} returns this
 */
proto.entity.Stripe.SetupIntent.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.SetupIntent.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PaymentMethod payment_method = 4;
 * @return {?proto.entity.Stripe.PaymentMethod}
 */
proto.entity.Stripe.SetupIntent.prototype.getPaymentMethod = function() {
  return /** @type{?proto.entity.Stripe.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.entity.Stripe.PaymentMethod, 4));
};


/**
 * @param {?proto.entity.Stripe.PaymentMethod|undefined} value
 * @return {!proto.entity.Stripe.SetupIntent} returns this
*/
proto.entity.Stripe.SetupIntent.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.SetupIntent} returns this
 */
proto.entity.Stripe.SetupIntent.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.SetupIntent.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.entity.Stripe.SetupIntent.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.SetupIntent} returns this
 */
proto.entity.Stripe.SetupIntent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountOff: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 3, ""),
    durationInMonths: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    percentOff: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.Coupon}
 */
proto.entity.Stripe.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.Coupon;
  return proto.entity.Stripe.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.Coupon}
 */
proto.entity.Stripe.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountOff(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationInMonths(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentOff(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountOff();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurationInMonths();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPercentOff();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 amount_off = 1;
 * @return {number}
 */
proto.entity.Stripe.Coupon.prototype.getAmountOff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setAmountOff = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.entity.Stripe.Coupon.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string duration = 3;
 * @return {string}
 */
proto.entity.Stripe.Coupon.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 duration_in_months = 4;
 * @return {number}
 */
proto.entity.Stripe.Coupon.prototype.getDurationInMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setDurationInMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.entity.Stripe.Coupon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float percent_off = 6;
 * @return {number}
 */
proto.entity.Stripe.Coupon.prototype.getPercentOff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setPercentOff = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string id = 7;
 * @return {string}
 */
proto.entity.Stripe.Coupon.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Coupon} returns this
 */
proto.entity.Stripe.Coupon.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.Stripe.Invoice.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.entity.Stripe.Invoice.LineItem.toObject, includeInstance),
    discountsList: jspb.Message.toObjectList(msg.getDiscountsList(),
    proto.entity.Stripe.Invoice.Discount.toObject, includeInstance),
    amountDue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    nextPaymentAttempt: (f = msg.getNextPaymentAttempt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.Invoice}
 */
proto.entity.Stripe.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.Invoice;
  return proto.entity.Stripe.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.Invoice}
 */
proto.entity.Stripe.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.Stripe.Invoice.LineItem;
      reader.readMessage(value,proto.entity.Stripe.Invoice.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 2:
      var value = new proto.entity.Stripe.Invoice.Discount;
      reader.readMessage(value,proto.entity.Stripe.Invoice.Discount.deserializeBinaryFromReader);
      msg.addDiscounts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountDue(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextPaymentAttempt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.Stripe.Invoice.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.entity.Stripe.Invoice.Discount.serializeBinaryToWriter
    );
  }
  f = message.getAmountDue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNextPaymentAttempt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.Invoice.LineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.Invoice.LineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Invoice.LineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.Invoice.LineItem}
 */
proto.entity.Stripe.Invoice.LineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.Invoice.LineItem;
  return proto.entity.Stripe.Invoice.LineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.Invoice.LineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.Invoice.LineItem}
 */
proto.entity.Stripe.Invoice.LineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.Invoice.LineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.Invoice.LineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Invoice.LineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Invoice.LineItem} returns this
 */
proto.entity.Stripe.Invoice.LineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.Stripe.Invoice.LineItem} returns this
*/
proto.entity.Stripe.Invoice.LineItem.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Invoice.LineItem} returns this
 */
proto.entity.Stripe.Invoice.LineItem.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.Stripe.Invoice.LineItem} returns this
*/
proto.entity.Stripe.Invoice.LineItem.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Invoice.LineItem} returns this
 */
proto.entity.Stripe.Invoice.LineItem.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.entity.Stripe.Invoice.LineItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.Stripe.Invoice.LineItem} returns this
 */
proto.entity.Stripe.Invoice.LineItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.Stripe.Invoice.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.Stripe.Invoice.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.Stripe.Invoice.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Invoice.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    coupon: (f = msg.getCoupon()) && proto.entity.Stripe.Coupon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.Stripe.Invoice.Discount}
 */
proto.entity.Stripe.Invoice.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.Stripe.Invoice.Discount;
  return proto.entity.Stripe.Invoice.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.Stripe.Invoice.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.Stripe.Invoice.Discount}
 */
proto.entity.Stripe.Invoice.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = new proto.entity.Stripe.Coupon;
      reader.readMessage(value,proto.entity.Stripe.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.Stripe.Invoice.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.Stripe.Invoice.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.Stripe.Invoice.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.Stripe.Invoice.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.Stripe.Coupon.serializeBinaryToWriter
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.entity.Stripe.Invoice.Discount.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
 */
proto.entity.Stripe.Invoice.Discount.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.Stripe.Invoice.Discount.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
*/
proto.entity.Stripe.Invoice.Discount.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
 */
proto.entity.Stripe.Invoice.Discount.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Invoice.Discount.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.Stripe.Invoice.Discount.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
*/
proto.entity.Stripe.Invoice.Discount.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
 */
proto.entity.Stripe.Invoice.Discount.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Invoice.Discount.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Coupon coupon = 4;
 * @return {?proto.entity.Stripe.Coupon}
 */
proto.entity.Stripe.Invoice.Discount.prototype.getCoupon = function() {
  return /** @type{?proto.entity.Stripe.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.entity.Stripe.Coupon, 4));
};


/**
 * @param {?proto.entity.Stripe.Coupon|undefined} value
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
*/
proto.entity.Stripe.Invoice.Discount.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Invoice.Discount} returns this
 */
proto.entity.Stripe.Invoice.Discount.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Invoice.Discount.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated LineItem line_items = 1;
 * @return {!Array<!proto.entity.Stripe.Invoice.LineItem>}
 */
proto.entity.Stripe.Invoice.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.entity.Stripe.Invoice.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.Stripe.Invoice.LineItem, 1));
};


/**
 * @param {!Array<!proto.entity.Stripe.Invoice.LineItem>} value
 * @return {!proto.entity.Stripe.Invoice} returns this
*/
proto.entity.Stripe.Invoice.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.Stripe.Invoice.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.Stripe.Invoice.LineItem}
 */
proto.entity.Stripe.Invoice.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.Stripe.Invoice.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.Stripe.Invoice} returns this
 */
proto.entity.Stripe.Invoice.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * repeated Discount discounts = 2;
 * @return {!Array<!proto.entity.Stripe.Invoice.Discount>}
 */
proto.entity.Stripe.Invoice.prototype.getDiscountsList = function() {
  return /** @type{!Array<!proto.entity.Stripe.Invoice.Discount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.Stripe.Invoice.Discount, 2));
};


/**
 * @param {!Array<!proto.entity.Stripe.Invoice.Discount>} value
 * @return {!proto.entity.Stripe.Invoice} returns this
*/
proto.entity.Stripe.Invoice.prototype.setDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.Stripe.Invoice.Discount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.Stripe.Invoice.Discount}
 */
proto.entity.Stripe.Invoice.prototype.addDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.Stripe.Invoice.Discount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.Stripe.Invoice} returns this
 */
proto.entity.Stripe.Invoice.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};


/**
 * optional int64 amount_due = 3;
 * @return {number}
 */
proto.entity.Stripe.Invoice.prototype.getAmountDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.Stripe.Invoice} returns this
 */
proto.entity.Stripe.Invoice.prototype.setAmountDue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp next_payment_attempt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.Stripe.Invoice.prototype.getNextPaymentAttempt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.Stripe.Invoice} returns this
*/
proto.entity.Stripe.Invoice.prototype.setNextPaymentAttempt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.Stripe.Invoice} returns this
 */
proto.entity.Stripe.Invoice.prototype.clearNextPaymentAttempt = function() {
  return this.setNextPaymentAttempt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.Stripe.Invoice.prototype.hasNextPaymentAttempt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.StripeAccount.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.StripeAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.StripeAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.StripeAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    proto.entity.StripeAccount.Subscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.StripeAccount}
 */
proto.entity.StripeAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.StripeAccount;
  return proto.entity.StripeAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.StripeAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.StripeAccount}
 */
proto.entity.StripeAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new proto.entity.StripeAccount.Subscription;
      reader.readMessage(value,proto.entity.StripeAccount.Subscription.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.StripeAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.StripeAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.StripeAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.entity.StripeAccount.Subscription.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.StripeAccount.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.StripeAccount.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.StripeAccount.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    canceledAt: (f = msg.getCanceledAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentPeriodEnd: (f = msg.getCurrentPeriodEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customer: jspb.Message.getFieldWithDefault(msg, 5, ""),
    livemode: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    plan: (f = msg.getPlan()) && proto.entity.StripeAccount.Plan.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.StripeAccount.Subscription}
 */
proto.entity.StripeAccount.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.StripeAccount.Subscription;
  return proto.entity.StripeAccount.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.StripeAccount.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.StripeAccount.Subscription}
 */
proto.entity.StripeAccount.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCanceledAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentPeriodEnd(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLivemode(value);
      break;
    case 7:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 8:
      var value = new proto.entity.StripeAccount.Plan;
      reader.readMessage(value,proto.entity.StripeAccount.Plan.deserializeBinaryFromReader);
      msg.setPlan(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.StripeAccount.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.StripeAccount.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.StripeAccount.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCanceledAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPeriodEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLivemode();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getPlan();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.entity.StripeAccount.Plan.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.StripeAccount.Subscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp canceled_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.StripeAccount.Subscription.prototype.getCanceledAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
*/
proto.entity.StripeAccount.Subscription.prototype.setCanceledAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.clearCanceledAt = function() {
  return this.setCanceledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.StripeAccount.Subscription.prototype.hasCanceledAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp created = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.StripeAccount.Subscription.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
*/
proto.entity.StripeAccount.Subscription.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.StripeAccount.Subscription.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp current_period_end = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.StripeAccount.Subscription.prototype.getCurrentPeriodEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
*/
proto.entity.StripeAccount.Subscription.prototype.setCurrentPeriodEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.clearCurrentPeriodEnd = function() {
  return this.setCurrentPeriodEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.StripeAccount.Subscription.prototype.hasCurrentPeriodEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string customer = 5;
 * @return {string}
 */
proto.entity.StripeAccount.Subscription.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool livemode = 6;
 * @return {boolean}
 */
proto.entity.StripeAccount.Subscription.prototype.getLivemode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.setLivemode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * map<string, string> metadata = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.entity.StripeAccount.Subscription.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional Plan plan = 8;
 * @return {?proto.entity.StripeAccount.Plan}
 */
proto.entity.StripeAccount.Subscription.prototype.getPlan = function() {
  return /** @type{?proto.entity.StripeAccount.Plan} */ (
    jspb.Message.getWrapperField(this, proto.entity.StripeAccount.Plan, 8));
};


/**
 * @param {?proto.entity.StripeAccount.Plan|undefined} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
*/
proto.entity.StripeAccount.Subscription.prototype.setPlan = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.clearPlan = function() {
  return this.setPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.StripeAccount.Subscription.prototype.hasPlan = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.entity.StripeAccount.Subscription.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Subscription} returns this
 */
proto.entity.StripeAccount.Subscription.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.StripeAccount.Plan.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.StripeAccount.Plan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.StripeAccount.Plan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.Plan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amountCents: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 4, ""),
    intervalCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    livemode: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    product: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.StripeAccount.Plan}
 */
proto.entity.StripeAccount.Plan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.StripeAccount.Plan;
  return proto.entity.StripeAccount.Plan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.StripeAccount.Plan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.StripeAccount.Plan}
 */
proto.entity.StripeAccount.Plan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountCents(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntervalCount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLivemode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.StripeAccount.Plan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.StripeAccount.Plan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.StripeAccount.Plan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.Plan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmountCents();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInterval();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntervalCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLivemode();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getProduct();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.StripeAccount.Plan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount_cents = 2;
 * @return {number}
 */
proto.entity.StripeAccount.Plan.prototype.getAmountCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setAmountCents = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.entity.StripeAccount.Plan.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string interval = 4;
 * @return {string}
 */
proto.entity.StripeAccount.Plan.prototype.getInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 interval_count = 5;
 * @return {number}
 */
proto.entity.StripeAccount.Plan.prototype.getIntervalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setIntervalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool livemode = 6;
 * @return {boolean}
 */
proto.entity.StripeAccount.Plan.prototype.getLivemode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setLivemode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string product = 7;
 * @return {string}
 */
proto.entity.StripeAccount.Plan.prototype.getProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.Plan} returns this
 */
proto.entity.StripeAccount.Plan.prototype.setProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.StripeAccount.PaymentIntent.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.StripeAccount.PaymentIntent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.StripeAccount.PaymentIntent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.PaymentIntent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.StripeAccount.PaymentIntent}
 */
proto.entity.StripeAccount.PaymentIntent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.StripeAccount.PaymentIntent;
  return proto.entity.StripeAccount.PaymentIntent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.StripeAccount.PaymentIntent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.StripeAccount.PaymentIntent}
 */
proto.entity.StripeAccount.PaymentIntent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.StripeAccount.PaymentIntent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.StripeAccount.PaymentIntent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.StripeAccount.PaymentIntent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.StripeAccount.PaymentIntent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.StripeAccount.PaymentIntent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.PaymentIntent} returns this
 */
proto.entity.StripeAccount.PaymentIntent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_secret = 2;
 * @return {string}
 */
proto.entity.StripeAccount.PaymentIntent.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.PaymentIntent} returns this
 */
proto.entity.StripeAccount.PaymentIntent.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.entity.StripeAccount.PaymentIntent.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount.PaymentIntent} returns this
 */
proto.entity.StripeAccount.PaymentIntent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.entity.StripeAccount.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.StripeAccount} returns this
 */
proto.entity.StripeAccount.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Subscription subscriptions = 2;
 * @return {!Array<!proto.entity.StripeAccount.Subscription>}
 */
proto.entity.StripeAccount.prototype.getSubscriptionsList = function() {
  return /** @type{!Array<!proto.entity.StripeAccount.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.StripeAccount.Subscription, 2));
};


/**
 * @param {!Array<!proto.entity.StripeAccount.Subscription>} value
 * @return {!proto.entity.StripeAccount} returns this
*/
proto.entity.StripeAccount.prototype.setSubscriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.StripeAccount.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.StripeAccount.Subscription}
 */
proto.entity.StripeAccount.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.StripeAccount.Subscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.StripeAccount} returns this
 */
proto.entity.StripeAccount.prototype.clearSubscriptionsList = function() {
  return this.setSubscriptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.ShopifyAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.ShopifyAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.ShopifyAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ShopifyAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    shop: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recurringChargeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    billingOn: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.ShopifyAccount}
 */
proto.entity.ShopifyAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.ShopifyAccount;
  return proto.entity.ShopifyAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.ShopifyAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.ShopifyAccount}
 */
proto.entity.ShopifyAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecurringChargeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.ShopifyAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.ShopifyAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.ShopifyAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.ShopifyAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecurringChargeId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBillingOn();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string shop = 1;
 * @return {string}
 */
proto.entity.ShopifyAccount.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ShopifyAccount} returns this
 */
proto.entity.ShopifyAccount.prototype.setShop = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 recurring_charge_id = 2;
 * @return {number}
 */
proto.entity.ShopifyAccount.prototype.getRecurringChargeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.ShopifyAccount} returns this
 */
proto.entity.ShopifyAccount.prototype.setRecurringChargeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.entity.ShopifyAccount.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ShopifyAccount} returns this
 */
proto.entity.ShopifyAccount.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string billing_on = 4;
 * @return {string}
 */
proto.entity.ShopifyAccount.prototype.getBillingOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.ShopifyAccount} returns this
 */
proto.entity.ShopifyAccount.prototype.setBillingOn = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.EnterpriseAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.EnterpriseAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.EnterpriseAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.EnterpriseAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxClientBillingAccounts: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.EnterpriseAccount}
 */
proto.entity.EnterpriseAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.EnterpriseAccount;
  return proto.entity.EnterpriseAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.EnterpriseAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.EnterpriseAccount}
 */
proto.entity.EnterpriseAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxClientBillingAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.EnterpriseAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.EnterpriseAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.EnterpriseAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.EnterpriseAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxClientBillingAccounts();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 max_client_billing_accounts = 1;
 * @return {number}
 */
proto.entity.EnterpriseAccount.prototype.getMaxClientBillingAccounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.EnterpriseAccount} returns this
 */
proto.entity.EnterpriseAccount.prototype.setMaxClientBillingAccounts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.LinkedAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.LinkedAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.LinkedAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.LinkedAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetBillingAccountId: (f = msg.getTargetBillingAccountId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.LinkedAccount}
 */
proto.entity.LinkedAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.LinkedAccount;
  return proto.entity.LinkedAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.LinkedAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.LinkedAccount}
 */
proto.entity.LinkedAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setTargetBillingAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.LinkedAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.LinkedAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.LinkedAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.LinkedAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetBillingAccountId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID target_billing_account_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.LinkedAccount.prototype.getTargetBillingAccountId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.LinkedAccount} returns this
*/
proto.entity.LinkedAccount.prototype.setTargetBillingAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.LinkedAccount} returns this
 */
proto.entity.LinkedAccount.prototype.clearTargetBillingAccountId = function() {
  return this.setTargetBillingAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.LinkedAccount.prototype.hasTargetBillingAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.InternalAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.InternalAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.InternalAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalAccount.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.InternalAccount}
 */
proto.entity.InternalAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.InternalAccount;
  return proto.entity.InternalAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.InternalAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.InternalAccount}
 */
proto.entity.InternalAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.InternalAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.InternalAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.InternalAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.InternalAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.UpdateBillingAccountAction.oneofGroups_ = [[1,3,4,2]];

/**
 * @enum {number}
 */
proto.entity.UpdateBillingAccountAction.ActionCase = {
  ACTION_NOT_SET: 0,
  SET_BILLING_ACCOUNT_DETAILS: 1,
  ADD_BILLING_ACCOUNT_CLASS_DETAILS: 3,
  UPDATE_BILLING_ACCOUNT_CLASS_DETAILS: 4,
  REFRESH_BILLING_ACCOUNT: 2
};

/**
 * @return {proto.entity.UpdateBillingAccountAction.ActionCase}
 */
proto.entity.UpdateBillingAccountAction.prototype.getActionCase = function() {
  return /** @type {proto.entity.UpdateBillingAccountAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.entity.UpdateBillingAccountAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateBillingAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateBillingAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateBillingAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    setBillingAccountDetails: (f = msg.getSetBillingAccountDetails()) && proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.toObject(includeInstance, f),
    addBillingAccountClassDetails: (f = msg.getAddBillingAccountClassDetails()) && proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.toObject(includeInstance, f),
    updateBillingAccountClassDetails: (f = msg.getUpdateBillingAccountClassDetails()) && proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.toObject(includeInstance, f),
    refreshBillingAccount: (f = msg.getRefreshBillingAccount()) && proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateBillingAccountAction}
 */
proto.entity.UpdateBillingAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateBillingAccountAction;
  return proto.entity.UpdateBillingAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateBillingAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateBillingAccountAction}
 */
proto.entity.UpdateBillingAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction;
      reader.readMessage(value,proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.deserializeBinaryFromReader);
      msg.setSetBillingAccountDetails(value);
      break;
    case 3:
      var value = new proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction;
      reader.readMessage(value,proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.deserializeBinaryFromReader);
      msg.setAddBillingAccountClassDetails(value);
      break;
    case 4:
      var value = new proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction;
      reader.readMessage(value,proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.deserializeBinaryFromReader);
      msg.setUpdateBillingAccountClassDetails(value);
      break;
    case 2:
      var value = new proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction;
      reader.readMessage(value,proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.deserializeBinaryFromReader);
      msg.setRefreshBillingAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateBillingAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateBillingAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateBillingAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetBillingAccountDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getAddBillingAccountClassDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBillingAccountClassDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.serializeBinaryToWriter
    );
  }
  f = message.getRefreshBillingAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newBillingAccountDetails: (f = msg.getNewBillingAccountDetails()) && proto.entity.BillingAccountDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction;
  return proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.BillingAccountDetails;
      reader.readMessage(value,proto.entity.BillingAccountDetails.deserializeBinaryFromReader);
      msg.setNewBillingAccountDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewBillingAccountDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.BillingAccountDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingAccountDetails new_billing_account_details = 1;
 * @return {?proto.entity.BillingAccountDetails}
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.prototype.getNewBillingAccountDetails = function() {
  return /** @type{?proto.entity.BillingAccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountDetails, 1));
};


/**
 * @param {?proto.entity.BillingAccountDetails|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction} returns this
*/
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.prototype.setNewBillingAccountDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction} returns this
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.prototype.clearNewBillingAccountDetails = function() {
  return this.setNewBillingAccountDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction.prototype.hasNewBillingAccountDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    newBillingAccountClassDetails: (f = msg.getNewBillingAccountClassDetails()) && proto.entity.BillingAccountClassDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction;
  return proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.BillingAccountClassDetails;
      reader.readMessage(value,proto.entity.BillingAccountClassDetails.deserializeBinaryFromReader);
      msg.setNewBillingAccountClassDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewBillingAccountClassDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.BillingAccountClassDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingAccountClassDetails new_billing_account_class_details = 1;
 * @return {?proto.entity.BillingAccountClassDetails}
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.prototype.getNewBillingAccountClassDetails = function() {
  return /** @type{?proto.entity.BillingAccountClassDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountClassDetails, 1));
};


/**
 * @param {?proto.entity.BillingAccountClassDetails|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction} returns this
*/
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.prototype.setNewBillingAccountClassDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction} returns this
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.prototype.clearNewBillingAccountClassDetails = function() {
  return this.setNewBillingAccountClassDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction.prototype.hasNewBillingAccountClassDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalBillingAccountClassDetails: (f = msg.getOriginalBillingAccountClassDetails()) && proto.entity.BillingAccountClassDetails.toObject(includeInstance, f),
    updatedBillingAccountClassDetails: (f = msg.getUpdatedBillingAccountClassDetails()) && proto.entity.BillingAccountClassDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction;
  return proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.BillingAccountClassDetails;
      reader.readMessage(value,proto.entity.BillingAccountClassDetails.deserializeBinaryFromReader);
      msg.setOriginalBillingAccountClassDetails(value);
      break;
    case 2:
      var value = new proto.entity.BillingAccountClassDetails;
      reader.readMessage(value,proto.entity.BillingAccountClassDetails.deserializeBinaryFromReader);
      msg.setUpdatedBillingAccountClassDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalBillingAccountClassDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.entity.BillingAccountClassDetails.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBillingAccountClassDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.BillingAccountClassDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingAccountClassDetails original_billing_account_class_details = 1;
 * @return {?proto.entity.BillingAccountClassDetails}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.getOriginalBillingAccountClassDetails = function() {
  return /** @type{?proto.entity.BillingAccountClassDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountClassDetails, 1));
};


/**
 * @param {?proto.entity.BillingAccountClassDetails|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} returns this
*/
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.setOriginalBillingAccountClassDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} returns this
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.clearOriginalBillingAccountClassDetails = function() {
  return this.setOriginalBillingAccountClassDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.hasOriginalBillingAccountClassDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BillingAccountClassDetails updated_billing_account_class_details = 2;
 * @return {?proto.entity.BillingAccountClassDetails}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.getUpdatedBillingAccountClassDetails = function() {
  return /** @type{?proto.entity.BillingAccountClassDetails} */ (
    jspb.Message.getWrapperField(this, proto.entity.BillingAccountClassDetails, 2));
};


/**
 * @param {?proto.entity.BillingAccountClassDetails|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} returns this
*/
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.setUpdatedBillingAccountClassDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} returns this
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.clearUpdatedBillingAccountClassDetails = function() {
  return this.setUpdatedBillingAccountClassDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction.prototype.hasUpdatedBillingAccountClassDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction}
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction;
  return proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction}
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional SetBillingAccountDetailsAction set_billing_account_details = 1;
 * @return {?proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.prototype.getSetBillingAccountDetails = function() {
  return /** @type{?proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction, 1));
};


/**
 * @param {?proto.entity.UpdateBillingAccountAction.SetBillingAccountDetailsAction|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
*/
proto.entity.UpdateBillingAccountAction.prototype.setSetBillingAccountDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.entity.UpdateBillingAccountAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
 */
proto.entity.UpdateBillingAccountAction.prototype.clearSetBillingAccountDetails = function() {
  return this.setSetBillingAccountDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.prototype.hasSetBillingAccountDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddBillingAccountClassDetailsAction add_billing_account_class_details = 3;
 * @return {?proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.prototype.getAddBillingAccountClassDetails = function() {
  return /** @type{?proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction, 3));
};


/**
 * @param {?proto.entity.UpdateBillingAccountAction.AddBillingAccountClassDetailsAction|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
*/
proto.entity.UpdateBillingAccountAction.prototype.setAddBillingAccountClassDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.entity.UpdateBillingAccountAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
 */
proto.entity.UpdateBillingAccountAction.prototype.clearAddBillingAccountClassDetails = function() {
  return this.setAddBillingAccountClassDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.prototype.hasAddBillingAccountClassDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UpdateBillingAccountClassDetailsAction update_billing_account_class_details = 4;
 * @return {?proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction}
 */
proto.entity.UpdateBillingAccountAction.prototype.getUpdateBillingAccountClassDetails = function() {
  return /** @type{?proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction, 4));
};


/**
 * @param {?proto.entity.UpdateBillingAccountAction.UpdateBillingAccountClassDetailsAction|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
*/
proto.entity.UpdateBillingAccountAction.prototype.setUpdateBillingAccountClassDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.entity.UpdateBillingAccountAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
 */
proto.entity.UpdateBillingAccountAction.prototype.clearUpdateBillingAccountClassDetails = function() {
  return this.setUpdateBillingAccountClassDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.prototype.hasUpdateBillingAccountClassDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RefreshBillingAccountAction refresh_billing_account = 2;
 * @return {?proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction}
 */
proto.entity.UpdateBillingAccountAction.prototype.getRefreshBillingAccount = function() {
  return /** @type{?proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction} */ (
    jspb.Message.getWrapperField(this, proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction, 2));
};


/**
 * @param {?proto.entity.UpdateBillingAccountAction.RefreshBillingAccountAction|undefined} value
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
*/
proto.entity.UpdateBillingAccountAction.prototype.setRefreshBillingAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.entity.UpdateBillingAccountAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.UpdateBillingAccountAction} returns this
 */
proto.entity.UpdateBillingAccountAction.prototype.clearRefreshBillingAccount = function() {
  return this.setRefreshBillingAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.UpdateBillingAccountAction.prototype.hasRefreshBillingAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.AmpdSubscription.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.AmpdSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.AmpdSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.AmpdSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contract: (f = msg.getContract()) && proto.entity.AmpdSubscription.Contract.toObject(includeInstance, f),
    externalId: (f = msg.getExternalId()) && proto.entity.AmpdSubscription.ExternalPaymentSystemID.toObject(includeInstance, f),
    centsPerBillingInterval: jspb.Message.getFieldWithDefault(msg, 4, 0),
    billingIntervalMonths: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minimumBillingIntervals: jspb.Message.getFieldWithDefault(msg, 6, 0),
    onboardingFee: (f = msg.getOnboardingFee()) && proto.entity.AmpdSubscription.OnboardingFee.toObject(includeInstance, f),
    adSpendFeesList: jspb.Message.toObjectList(msg.getAdSpendFeesList(),
    proto.entity.AmpdSubscription.AdSpendFee.toObject, includeInstance),
    features: (f = msg.getFeatures()) && proto.entity.AmpdSubscription.Features.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.AmpdSubscription}
 */
proto.entity.AmpdSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.AmpdSubscription;
  return proto.entity.AmpdSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.AmpdSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.AmpdSubscription}
 */
proto.entity.AmpdSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.AmpdSubscription.SubscriptionID} */ (reader.readEnum());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 10:
      var value = new proto.entity.AmpdSubscription.Contract;
      reader.readMessage(value,proto.entity.AmpdSubscription.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    case 2:
      var value = new proto.entity.AmpdSubscription.ExternalPaymentSystemID;
      reader.readMessage(value,proto.entity.AmpdSubscription.ExternalPaymentSystemID.deserializeBinaryFromReader);
      msg.setExternalId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsPerBillingInterval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillingIntervalMonths(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumBillingIntervals(value);
      break;
    case 7:
      var value = new proto.entity.AmpdSubscription.OnboardingFee;
      reader.readMessage(value,proto.entity.AmpdSubscription.OnboardingFee.deserializeBinaryFromReader);
      msg.setOnboardingFee(value);
      break;
    case 8:
      var value = new proto.entity.AmpdSubscription.AdSpendFee;
      reader.readMessage(value,proto.entity.AmpdSubscription.AdSpendFee.deserializeBinaryFromReader);
      msg.addAdSpendFees(value);
      break;
    case 9:
      var value = new proto.entity.AmpdSubscription.Features;
      reader.readMessage(value,proto.entity.AmpdSubscription.Features.deserializeBinaryFromReader);
      msg.setFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.AmpdSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.AmpdSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.AmpdSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.entity.AmpdSubscription.Contract.serializeBinaryToWriter
    );
  }
  f = message.getExternalId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.AmpdSubscription.ExternalPaymentSystemID.serializeBinaryToWriter
    );
  }
  f = message.getCentsPerBillingInterval();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBillingIntervalMonths();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMinimumBillingIntervals();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getOnboardingFee();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.AmpdSubscription.OnboardingFee.serializeBinaryToWriter
    );
  }
  f = message.getAdSpendFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.entity.AmpdSubscription.AdSpendFee.serializeBinaryToWriter
    );
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.entity.AmpdSubscription.Features.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.entity.AmpdSubscription.SubscriptionID = {
  UNKNOWN: 0,
  AMPD_STARTER_MONTHLY: 28,
  AMPD_STARTER_MONTHLY_V2: 1,
  AMPD_STARTER_QUARTERLY: 29,
  AMPD_STARTER_QUARTERLY_V2: 2,
  AMPD_STARTER_ANNUALLY: 30,
  AMPD_STARTER_ANNUALLY_V2: 3,
  AMPD_SCALE_MONTHLY: 31,
  AMPD_SCALE_MONTHLY_V2: 4,
  AMPD_SCALE_QUARTERLY: 32,
  AMPD_SCALE_QUARTERLY_V2: 5,
  AMPD_SCALE_ANNUALLY_V2: 6,
  AMPD_PRO_MONTHLY: 7,
  AMPD_PRO_3_MONTH: 8,
  AMPD_PRO_12_MONTH: 9,
  AMPD_CONNECT_1_MONTH: 10,
  AMPD_CONNECT_3_MONTH: 11,
  AMPD_CONNECT_6_MONTH: 12,
  AMPD_CONNECT_12_MONTH: 13,
  AMPD_AUTOMATION_3_MONTH: 14,
  AMPD_AUTOMATION_6_MONTH: 15,
  AMPD_AUTOMATION_12_MONTH: 16,
  AMPD_MANAGEMENT_3_MONTH: 17,
  AMPD_MANAGEMENT_6_MONTH: 18,
  AMPD_MANAGEMENT_12_MONTH: 19,
  MO_PLAN_97: 20,
  MO_PLAN_147: 21,
  MO_PLAN_247: 22,
  MO_PLAN_297: 23,
  MO_PLAN_347: 24,
  MO_PLAN_497: 25,
  ENTERPRISE: 26,
  INTERNAL: 27
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.AmpdSubscription.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.AmpdSubscription.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.AmpdSubscription.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.AmpdSubscription.Contract}
 */
proto.entity.AmpdSubscription.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.AmpdSubscription.Contract;
  return proto.entity.AmpdSubscription.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.AmpdSubscription.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.AmpdSubscription.Contract}
 */
proto.entity.AmpdSubscription.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.AmpdSubscription.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.AmpdSubscription.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.AmpdSubscription.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.AmpdSubscription.Contract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.AmpdSubscription.Contract} returns this
 */
proto.entity.AmpdSubscription.Contract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider = 2;
 * @return {string}
 */
proto.entity.AmpdSubscription.Contract.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.AmpdSubscription.Contract} returns this
 */
proto.entity.AmpdSubscription.Contract.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.AmpdSubscription.ExternalPaymentSystemID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.AmpdSubscription.ExternalPaymentSystemID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.AmpdSubscription.ExternalPaymentSystemID}
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.AmpdSubscription.ExternalPaymentSystemID;
  return proto.entity.AmpdSubscription.ExternalPaymentSystemID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.AmpdSubscription.ExternalPaymentSystemID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.AmpdSubscription.ExternalPaymentSystemID}
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.AmpdSubscription.ExternalPaymentSystemID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.AmpdSubscription.ExternalPaymentSystemID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.AmpdSubscription.ExternalPaymentSystemID} returns this
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider = 2;
 * @return {string}
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.AmpdSubscription.ExternalPaymentSystemID} returns this
 */
proto.entity.AmpdSubscription.ExternalPaymentSystemID.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.AmpdSubscription.OnboardingFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.AmpdSubscription.OnboardingFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.OnboardingFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeCents: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalId: (f = msg.getExternalId()) && proto.entity.AmpdSubscription.ExternalPaymentSystemID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.AmpdSubscription.OnboardingFee}
 */
proto.entity.AmpdSubscription.OnboardingFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.AmpdSubscription.OnboardingFee;
  return proto.entity.AmpdSubscription.OnboardingFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.AmpdSubscription.OnboardingFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.AmpdSubscription.OnboardingFee}
 */
proto.entity.AmpdSubscription.OnboardingFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeeCents(value);
      break;
    case 2:
      var value = new proto.entity.AmpdSubscription.ExternalPaymentSystemID;
      reader.readMessage(value,proto.entity.AmpdSubscription.ExternalPaymentSystemID.deserializeBinaryFromReader);
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.AmpdSubscription.OnboardingFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.AmpdSubscription.OnboardingFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.OnboardingFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeCents();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getExternalId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.AmpdSubscription.ExternalPaymentSystemID.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 fee_cents = 1;
 * @return {number}
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.getFeeCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription.OnboardingFee} returns this
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.setFeeCents = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ExternalPaymentSystemID external_id = 2;
 * @return {?proto.entity.AmpdSubscription.ExternalPaymentSystemID}
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.getExternalId = function() {
  return /** @type{?proto.entity.AmpdSubscription.ExternalPaymentSystemID} */ (
    jspb.Message.getWrapperField(this, proto.entity.AmpdSubscription.ExternalPaymentSystemID, 2));
};


/**
 * @param {?proto.entity.AmpdSubscription.ExternalPaymentSystemID|undefined} value
 * @return {!proto.entity.AmpdSubscription.OnboardingFee} returns this
*/
proto.entity.AmpdSubscription.OnboardingFee.prototype.setExternalId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.AmpdSubscription.OnboardingFee} returns this
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.clearExternalId = function() {
  return this.setExternalId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.AmpdSubscription.OnboardingFee.prototype.hasExternalId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.AmpdSubscription.AdSpendFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.AmpdSubscription.AdSpendFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.AdSpendFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    minSpendCents: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxSpendCents: jspb.Message.getFieldWithDefault(msg, 2, 0),
    feePercent: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.AmpdSubscription.AdSpendFee}
 */
proto.entity.AmpdSubscription.AdSpendFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.AmpdSubscription.AdSpendFee;
  return proto.entity.AmpdSubscription.AdSpendFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.AmpdSubscription.AdSpendFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.AmpdSubscription.AdSpendFee}
 */
proto.entity.AmpdSubscription.AdSpendFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinSpendCents(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxSpendCents(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeePercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.AmpdSubscription.AdSpendFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.AmpdSubscription.AdSpendFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.AdSpendFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinSpendCents();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMaxSpendCents();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFeePercent();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 min_spend_cents = 1;
 * @return {number}
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.getMinSpendCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription.AdSpendFee} returns this
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.setMinSpendCents = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 max_spend_cents = 2;
 * @return {number}
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.getMaxSpendCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription.AdSpendFee} returns this
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.setMaxSpendCents = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 fee_percent = 3;
 * @return {number}
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.getFeePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription.AdSpendFee} returns this
 */
proto.entity.AmpdSubscription.AdSpendFee.prototype.setFeePercent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.AmpdSubscription.Features.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.AmpdSubscription.Features.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.AmpdSubscription.Features} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.Features.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignsLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privateOnboarding: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ampdProtection: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ampdAutoBidding: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isManaged: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.AmpdSubscription.Features}
 */
proto.entity.AmpdSubscription.Features.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.AmpdSubscription.Features;
  return proto.entity.AmpdSubscription.Features.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.AmpdSubscription.Features} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.AmpdSubscription.Features}
 */
proto.entity.AmpdSubscription.Features.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignsLimit(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateOnboarding(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmpdProtection(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmpdAutoBidding(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManaged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.AmpdSubscription.Features.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.AmpdSubscription.Features.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.AmpdSubscription.Features} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.AmpdSubscription.Features.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignsLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPrivateOnboarding();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAmpdProtection();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAmpdAutoBidding();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsManaged();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 campaigns_limit = 1;
 * @return {number}
 */
proto.entity.AmpdSubscription.Features.prototype.getCampaignsLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription.Features} returns this
 */
proto.entity.AmpdSubscription.Features.prototype.setCampaignsLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool private_onboarding = 2;
 * @return {boolean}
 */
proto.entity.AmpdSubscription.Features.prototype.getPrivateOnboarding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.AmpdSubscription.Features} returns this
 */
proto.entity.AmpdSubscription.Features.prototype.setPrivateOnboarding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool ampd_protection = 3;
 * @return {boolean}
 */
proto.entity.AmpdSubscription.Features.prototype.getAmpdProtection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.AmpdSubscription.Features} returns this
 */
proto.entity.AmpdSubscription.Features.prototype.setAmpdProtection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool ampd_auto_bidding = 4;
 * @return {boolean}
 */
proto.entity.AmpdSubscription.Features.prototype.getAmpdAutoBidding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.AmpdSubscription.Features} returns this
 */
proto.entity.AmpdSubscription.Features.prototype.setAmpdAutoBidding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_managed = 5;
 * @return {boolean}
 */
proto.entity.AmpdSubscription.Features.prototype.getIsManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.AmpdSubscription.Features} returns this
 */
proto.entity.AmpdSubscription.Features.prototype.setIsManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional SubscriptionID subscription_id = 1;
 * @return {!proto.entity.AmpdSubscription.SubscriptionID}
 */
proto.entity.AmpdSubscription.prototype.getSubscriptionId = function() {
  return /** @type {!proto.entity.AmpdSubscription.SubscriptionID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.AmpdSubscription.SubscriptionID} value
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.entity.AmpdSubscription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_name = 11;
 * @return {string}
 */
proto.entity.AmpdSubscription.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Contract contract = 10;
 * @return {?proto.entity.AmpdSubscription.Contract}
 */
proto.entity.AmpdSubscription.prototype.getContract = function() {
  return /** @type{?proto.entity.AmpdSubscription.Contract} */ (
    jspb.Message.getWrapperField(this, proto.entity.AmpdSubscription.Contract, 10));
};


/**
 * @param {?proto.entity.AmpdSubscription.Contract|undefined} value
 * @return {!proto.entity.AmpdSubscription} returns this
*/
proto.entity.AmpdSubscription.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.AmpdSubscription.prototype.hasContract = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ExternalPaymentSystemID external_id = 2;
 * @return {?proto.entity.AmpdSubscription.ExternalPaymentSystemID}
 */
proto.entity.AmpdSubscription.prototype.getExternalId = function() {
  return /** @type{?proto.entity.AmpdSubscription.ExternalPaymentSystemID} */ (
    jspb.Message.getWrapperField(this, proto.entity.AmpdSubscription.ExternalPaymentSystemID, 2));
};


/**
 * @param {?proto.entity.AmpdSubscription.ExternalPaymentSystemID|undefined} value
 * @return {!proto.entity.AmpdSubscription} returns this
*/
proto.entity.AmpdSubscription.prototype.setExternalId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.clearExternalId = function() {
  return this.setExternalId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.AmpdSubscription.prototype.hasExternalId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 cents_per_billing_interval = 4;
 * @return {number}
 */
proto.entity.AmpdSubscription.prototype.getCentsPerBillingInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.setCentsPerBillingInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 billing_interval_months = 5;
 * @return {number}
 */
proto.entity.AmpdSubscription.prototype.getBillingIntervalMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.setBillingIntervalMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 minimum_billing_intervals = 6;
 * @return {number}
 */
proto.entity.AmpdSubscription.prototype.getMinimumBillingIntervals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.setMinimumBillingIntervals = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional OnboardingFee onboarding_fee = 7;
 * @return {?proto.entity.AmpdSubscription.OnboardingFee}
 */
proto.entity.AmpdSubscription.prototype.getOnboardingFee = function() {
  return /** @type{?proto.entity.AmpdSubscription.OnboardingFee} */ (
    jspb.Message.getWrapperField(this, proto.entity.AmpdSubscription.OnboardingFee, 7));
};


/**
 * @param {?proto.entity.AmpdSubscription.OnboardingFee|undefined} value
 * @return {!proto.entity.AmpdSubscription} returns this
*/
proto.entity.AmpdSubscription.prototype.setOnboardingFee = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.clearOnboardingFee = function() {
  return this.setOnboardingFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.AmpdSubscription.prototype.hasOnboardingFee = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated AdSpendFee ad_spend_fees = 8;
 * @return {!Array<!proto.entity.AmpdSubscription.AdSpendFee>}
 */
proto.entity.AmpdSubscription.prototype.getAdSpendFeesList = function() {
  return /** @type{!Array<!proto.entity.AmpdSubscription.AdSpendFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.AmpdSubscription.AdSpendFee, 8));
};


/**
 * @param {!Array<!proto.entity.AmpdSubscription.AdSpendFee>} value
 * @return {!proto.entity.AmpdSubscription} returns this
*/
proto.entity.AmpdSubscription.prototype.setAdSpendFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.entity.AmpdSubscription.AdSpendFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.AmpdSubscription.AdSpendFee}
 */
proto.entity.AmpdSubscription.prototype.addAdSpendFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.entity.AmpdSubscription.AdSpendFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.clearAdSpendFeesList = function() {
  return this.setAdSpendFeesList([]);
};


/**
 * optional Features features = 9;
 * @return {?proto.entity.AmpdSubscription.Features}
 */
proto.entity.AmpdSubscription.prototype.getFeatures = function() {
  return /** @type{?proto.entity.AmpdSubscription.Features} */ (
    jspb.Message.getWrapperField(this, proto.entity.AmpdSubscription.Features, 9));
};


/**
 * @param {?proto.entity.AmpdSubscription.Features|undefined} value
 * @return {!proto.entity.AmpdSubscription} returns this
*/
proto.entity.AmpdSubscription.prototype.setFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.AmpdSubscription} returns this
 */
proto.entity.AmpdSubscription.prototype.clearFeatures = function() {
  return this.setFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.AmpdSubscription.prototype.hasFeatures = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * A tuple of {field number, class constructor} for the extension
 * field named `billingAccountStatus`.
 * @type {!jspb.ExtensionFieldInfo<!proto.entity.BillingAccount.StatusConfig>}
 */
proto.entity.billingAccountStatus = new jspb.ExtensionFieldInfo(
    50001,
    {billingAccountStatus: 0},
    proto.entity.BillingAccount.StatusConfig,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.entity.BillingAccount.StatusConfig.toObject),
    0);

google_protobuf_descriptor_pb.EnumValueOptions.extensionsBinary[50001] = new jspb.ExtensionFieldBinaryInfo(
    proto.entity.billingAccountStatus,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.entity.BillingAccount.StatusConfig.serializeBinaryToWriter,
    proto.entity.BillingAccount.StatusConfig.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumValueOptions.extensions[50001] = proto.entity.billingAccountStatus;

goog.object.extend(exports, proto.entity);
