import { DropdownProps } from "semantic-ui-react";
import React, { useRef, useState } from "react";

import {
  getCurrencyMinimumUnit,
  getCurrencySymbol
} from "Common/utils/googleAds";
import { EditDropdown } from "./CampaignsTableRow";

import { CAMPAIGN_DASHBOARD_GA_CATEGORY } from "ExtensionV2/ExtensionV2";
import { useUpdateKeywordCPCBid } from "../queries/useUpdateKeywordCPCBid";
import { useKeywordCPCBidHistory } from "../queries/useChangeHistory";
import { EditIconWithHistory } from "./EditIconWithHistory";
import { useCanUserEditBids } from "Common/utils/siteUIBehavior";

const USE_DEFAULT_STR = "USE_DEFAULT";

const KeywordCPCBidCellContent: React.FC<{
  disabled: boolean;
  siteAlias: string;
  campaignId: string;
  adGroupId: string;
  criteriaId: string;
  cpcBid: number;
  formattedCpcBid: string | JSX.Element;
  currencyCode: string;
  googleAdsAccountId: string;
  setWillChange: (willChange: boolean) => void;
  refetchCampaignKeyword: (
    campaignId: string,
    adGroupId?: string,
    criteriaId?: string
  ) => void;
}> = ({
  disabled,
  siteAlias,
  campaignId,
  adGroupId,
  criteriaId,
  cpcBid,
  formattedCpcBid,
  currencyCode,
  googleAdsAccountId,
  setWillChange,
  refetchCampaignKeyword
}) => {
  const canEditBids = useCanUserEditBids();

  // Normally the dropdown will close after the user enters a value.  However,
  // if there is a validation error, let's keep the dropdown open and show any
  // error message there.  This ref is used to override the close event when
  // there is an error.
  const keepOpenRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [updateCpcBid, setUpdateCpcBid] = useState<number | null>(null);

  // Don't load history items unless the history popup is open.
  const historyItems = useKeywordCPCBidHistory(
    siteAlias,
    currencyCode,
    historyOpen ? campaignId : "",
    adGroupId,
    criteriaId
  );

  const {
    mutateAsync: updateKeywordCPCBid,
    isLoading
  } = useUpdateKeywordCPCBid();

  const handleOpen = () => {
    if (canEditBids) {
      setDropdownOpen(true);
    }
    setErrorMessage("");
  };

  const handleClose = () => {
    if (!keepOpenRef.current) {
      setDropdownOpen(false);
    }
    keepOpenRef.current = false;
  };

  const handleUpdateKeywordCPCBid = async (newCpcBid: number) => {
    if (
      !siteAlias ||
      !campaignId ||
      !googleAdsAccountId ||
      newCpcBid === cpcBid
    ) {
      return;
    }

    setDropdownOpen(false);
    setUpdateCpcBid(newCpcBid);

    if (setWillChange) {
      setWillChange(true);
    }

    try {
      await updateKeywordCPCBid({
        siteAlias,
        gaCategory: CAMPAIGN_DASHBOARD_GA_CATEGORY,
        googleAdsAccountId,
        currencyCode,
        campaignId,
        adGroupId,
        criteriaId,
        newCpcBid
      });

      if (refetchCampaignKeyword) {
        refetchCampaignKeyword(campaignId, adGroupId, criteriaId);
      }
    } catch (e) {
      setErrorMessage("Error updating value. Try again.");
      setUpdateCpcBid(null);
      setDropdownOpen(true);
      console.error(e);
    } finally {
      if (setWillChange) {
        setWillChange(false);
      }
    }
  };

  const handleSelect = async (
    _ev: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (disabled) {
      return;
    }

    let newCpcBid = cpcBid;
    if (value === USE_DEFAULT_STR) {
      newCpcBid = 0;
    } else {
      const minCpcBid = getCurrencyMinimumUnit(currencyCode) / 1e6;
      newCpcBid = Number(value);
      if (isNaN(newCpcBid)) {
        setErrorMessage(`Invalid amount: ${value}`);
        keepOpenRef.current = true;
        setDropdownOpen(true);
        return;
      } else if (newCpcBid < minCpcBid) {
        setErrorMessage(`Must be at least (${minCpcBid}): ${value}`);
        keepOpenRef.current = true;
        setDropdownOpen(true);
        return;
      } else {
        setErrorMessage("");
      }
    }

    await handleUpdateKeywordCPCBid(newCpcBid);
    return;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center"
      }}
    >
      <EditDropdown
        compact
        icon={
          <EditIconWithHistory
            disabled={dropdownOpen}
            readonly={!canEditBids}
            historyOpen={historyOpen}
            setHistoryOpen={setHistoryOpen}
            historyItems={historyItems}
          />
        }
        open={dropdownOpen}
        disabled={isLoading || disabled}
        selection
        search
        allowAdditions={true}
        additionLabel={`CPC Bid: ${getCurrencySymbol(currencyCode)}`}
        options={[
          errorMessage
            ? {
                key: "ERROR_MESSAGE",
                text: <span style={{ color: "red" }}>{errorMessage}</span>,
                disabled: true
              }
            : null,
          {
            key: "CPC_BID",
            text: formattedCpcBid,
            value: cpcBid
          },
          // Temporary option to show value being updated to until the UI state refreshes.
          updateCpcBid != null
            ? {
                key: "UPDATED_CPC_BID",
                text: `${updateCpcBid}`,
                value: updateCpcBid
              }
            : null,
          {
            key: USE_DEFAULT_STR,
            text: "Use Campaign Default CPC Bid",
            disabled: cpcBid === 0,
            value: USE_DEFAULT_STR
          }
        ].filter(Boolean)}
        value={updateCpcBid ?? cpcBid}
        onChange={handleSelect}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </div>
  );
};

export default KeywordCPCBidCellContent;
