import React from "react";
import { Helmet } from "react-helmet";
import { Button, Divider, Grid, Header } from "semantic-ui-react";
import { Box, Flex } from "@rebass/grid";
import { Beforeunload } from "react-beforeunload";
import { Prompt, useHistory, useLocation } from "react-router-dom";

import amplogo from "assets/ampd-red-black.svg";

import { navigateToPage } from "../util";
import { sendGAEvent } from "./GA";
import { useSession } from "ExtensionV2/queries/useSession";

function LeftDescriptionPageLayout(props) {
  const {
    historyTitle,
    heading,
    title,
    description,
    videoTitle,
    video,
    bottomDescription,
    component,
    gaCategory,
    cancelOnClick: cancelOnClickOriginal,
    cancelTo,
    cancelDisabled,
    cancelLabel,
    previousOnClick: previousOnClickOriginal,
    previousTo,
    previousLoading,
    previousDisabled,
    previousLabel,
    nextOnClick: nextOnClickOriginal,
    nextOnMouseDown,
    nextTo,
    nextLoading,
    nextDisabled,
    nextLabel
  } = props;

  const { currentSite } = useSession();
  const { siteAlias } = currentSite;

  const history = useHistory();
  const location = useLocation();

  // Creates an onClick handler, first using the original onClick if specified,
  // second using the toPage if specfied, or otherwise returning null.
  const makeOnClick = (onClickOriginal, toPage) => {
    if (onClickOriginal) {
      return onClickOriginal;
    }

    if (toPage) {
      return e => {
        e.stopPropagation();
        navigateToPage({ history, location, siteAlias, toPage });
      };
    }

    return null;
  };

  const handleAmpdLogoLink = () => {
    sendGAEvent(gaCategory || title, "Clicked Ampd Logo", siteAlias);
  };

  const previousOnClick = makeOnClick(previousOnClickOriginal, previousTo);
  const nextOnClick = makeOnClick(nextOnClickOriginal, nextTo);
  const cancelOnClick = makeOnClick(cancelOnClickOriginal, cancelTo);

  const hasLeftDescription = title || description || video;

  const handleBlurBeforeLeaving = () => {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    return true;
  };

  // TODO: The embedded video needs to be passed in with props.
  // TODO: Each slide will have it's own instructional video to help the user.

  return (
    <div
      style={{
        overflow: "auto",
        background: "white",
        width: "100%",
        height: "100%"
      }}
    >
      <Prompt message={handleBlurBeforeLeaving} />
      <Beforeunload onBeforeunload={handleBlurBeforeLeaving} />
      <Flex flexDirection="row" m={0} p={0} justifyContent="space-around">
        <Flex
          flexDirection="column"
          m={0}
          p={0}
          style={{
            maxWidth: 1000,
            maxHeight: "100vh"
          }}
        >
          <Helmet title={historyTitle} />
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box as="header" style={{ padding: 0, margin: "1rem 0 0 1rem" }}>
              <Header size="large">{heading}</Header>
            </Box>
            <Box style={{ padding: 0, margin: "1rem 1rem -6px 1rem" }}>
              <a
                href="https://ampd.io"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleAmpdLogoLink}
              >
                <img src={amplogo} width={"140px"} alt="Ampd logo" />
              </a>
            </Box>
          </Flex>
          <Divider style={{ margin: "1rem" }} />
          <Box flex="1 1" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid stackable style={{ width: "100%", margin: 0 }}>
              <Grid.Row style={{ margin: 0, padding: 0 }}>
                {hasLeftDescription && (
                  <Grid.Column width={5}>
                    {!!title && (
                      <h3 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {title}
                      </h3>
                    )}
                    <span style={{ color: "#6d7b8a", fontSize: "14px" }}>
                      {description}
                    </span>
                    {!!video && (
                      <>
                        <p />
                        {!!videoTitle && (
                          <h3 style={{ fontSize: "16px", fontWeight: "600" }}>
                            {videoTitle}
                          </h3>
                        )}
                        {video}
                        <p />
                      </>
                    )}
                    {!!bottomDescription && (
                      <span style={{ color: "#6d7b8a", fontSize: "14px" }}>
                        {bottomDescription}
                      </span>
                    )}
                  </Grid.Column>
                )}
                <Grid.Column width={hasLeftDescription ? 11 : 16}>
                  {component}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Box>
          <Box flex="1 1" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={hasLeftDescription ? 5 : 1}>
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    height="100%"
                  ></Flex>
                </Grid.Column>
                <Grid.Column width={hasLeftDescription ? 11 : 15}>
                  <Flex
                    flexDirection="row"
                    flex="0 0"
                    alignItems="center"
                    justifyContent="flex-end"
                    pl="5px"
                    pr="1rem"
                    py="1rem"
                  >
                    {!!cancelOnClick && (
                      <Button
                        style={{
                          visibility: cancelOnClick ? "visible" : "hidden",
                          marginRight: "auto"
                        }}
                        disabled={cancelDisabled}
                        basic
                        onClick={cancelOnClick}
                      >
                        {cancelLabel || "Cancel"}
                      </Button>
                    )}
                    <Button
                      style={{
                        visibility: previousOnClick ? "visible" : "hidden"
                      }}
                      disabled={previousDisabled || previousLoading}
                      loading={previousLoading}
                      basic
                      onClick={previousOnClick}
                    >
                      {previousLabel || "Previous"}
                    </Button>
                    <Button
                      style={{
                        visibility: nextOnClick ? "visible" : "hidden",
                        fontWeight: "500",
                        marginRight: 0
                      }}
                      disabled={nextDisabled || nextLoading}
                      loading={nextLoading}
                      primary
                      onClick={nextOnClick}
                      onMouseDown={nextOnMouseDown}
                    >
                      {nextLabel || "Next"}
                    </Button>
                  </Flex>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
}

export default LeftDescriptionPageLayout;
