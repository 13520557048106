import React from "react";
import styled from "styled-components/macro";
import { Button, List, Segment } from "semantic-ui-react";

import ampdLogo from "assets/ampd-icon.svg";
import { getCurrencyMetricDef } from "Common/utils/money";
import { formatMetric } from "Common/utils/metrics";
import { ampdRed } from "ExtensionV2/styles/colors";

import { AmpdSubscription } from "Common/proto/entity/billingAccount_pb";
import { isUpgrade } from "Common/utils/ampdPayment";

const PricingPlan = styled(Segment)`
  box-shadow: 9px 10px 40px -6px rgba(99, 99, 99, 1) !important;
  display: flex;
  flex-direction: column;
  @media (min-width: 1100px) {
    width: 25em;
    margin: 0 !important;
  }
`;

const PricingHeader = styled.p`
  color: #333;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0.25em;
  text-align: center;
`;

const PricingSubtitle = styled.div`
  @media (min-width: 1100px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 4.3em;
  }

  text-align: center;
`;

const PricingPrice = styled.div`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5em;

  span:last-child {
    border: 2px solid ${ampdRed};
    display: inline-block;
    border-radius: 2em;
    padding: 0.5em 0.75em;
  }
`;

const PriceExplanation = styled.div`
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: center;
`;

export const StrikePrice = styled.s`
  text-decoration: none;
  position: relative;
  font-size: 24px;

  &::before {
    top: 50%;
    background: red;
    opacity: 1;
    content: "";
    width: 110%;
    position: absolute;
    height: 0.1em;
    border-radius: 0.1em;
    left: -5%;
    white-space: nowrap;
    display: block;
    transform: rotate(-15deg);
  }
`;

const PricingFeatures = styled(List)`
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 15px;
`;

export const AmpdSubscriptionDisplay = ({
  onSelectSubscription,
  subtitle,
  disabled = false,
  targetSubscription,
  sitesCurrentSubscription,
  features
}: {
  targetSubscription: AmpdSubscription.AsObject;
  sitesCurrentSubscription: AmpdSubscription.AsObject | null | undefined;
  onSelectSubscription: (sub: AmpdSubscription.AsObject) => void;
  subtitle: string;
  disabled?: boolean;
  features: Array<{ text: string; bold: boolean }>;
}): JSX.Element => {
  const subscriptionsAreEqual =
    targetSubscription.subscriptionId ===
      sitesCurrentSubscription?.subscriptionId ||
    targetSubscription.groupName === sitesCurrentSubscription?.groupName;

  const targetPlanIsUpgrade = sitesCurrentSubscription
    ? isUpgrade(sitesCurrentSubscription, targetSubscription)
    : true;

  let buttonText: string;
  let buttonDisabled = false;
  if (!sitesCurrentSubscription) {
    buttonText = "Get Started";
  } else if (subscriptionsAreEqual) {
    buttonDisabled = true;
    buttonText = "Current Plan";
  } else if (targetPlanIsUpgrade) {
    buttonText = "Upgrade";
  } else if (disabled || !targetPlanIsUpgrade) {
    // Downgrading in app not currently supported solely for business reasons.
    buttonDisabled = true;
    buttonText = "Contact Us";
  } else {
    buttonText = "Select";
  }

  // Not all plans bill monthly, so this calculation is necessary.
  const monthlyAmountCents =
    targetSubscription.centsPerBillingInterval /
    targetSubscription.billingIntervalMonths /
    100;
  const formattedMonthlyAmount = formatMetric(
    getCurrencyMetricDef("USD", false),
    monthlyAmountCents
  );

  const adSpendInfo = targetSubscription.adSpendFeesList.map(adSpendFee => {
    return `${adSpendFee.feePercent}% fee on ad spend over ${formatMetric(
      getCurrencyMetricDef("USD", false),
      adSpendFee.minSpendCents / 100
    )} per month.`;
  });

  return (
    <PricingPlan
      style={{
        border: targetSubscription.features?.isManaged ? "2px solid red" : null
      }}
    >
      <PricingHeader>{targetSubscription.name}</PricingHeader>
      <PricingSubtitle>
        <p>{subtitle}</p>
      </PricingSubtitle>
      <PricingPrice>
        <span>{formattedMonthlyAmount}/mo</span>
      </PricingPrice>
      <PriceExplanation>
        <p>
          <i>{adSpendInfo.join(" ")}</i>
        </p>
      </PriceExplanation>

      <Button
        size="big"
        style={{
          width: "100%",
          marginTop: "0.5em",
          color: "white",
          backgroundColor: ampdRed
        }}
        disabled={buttonDisabled}
        onClick={() => onSelectSubscription(targetSubscription)}
      >
        {buttonText}
      </Button>

      <PricingFeatures style={{ padding: "10px" }} divided relaxed>
        {features?.map(f => (
          <List.Item
            key={f.text}
            style={{ paddingTop: "1.25em", paddingBottom: "1.25em" }}
          >
            <List.Icon name="check square outline" color="red" />
            {f.bold ? (
              <List.Content>
                <b>{f.text}</b>
              </List.Content>
            ) : (
              <List.Content>{f.text}</List.Content>
            )}
          </List.Item>
        ))}
      </PricingFeatures>
    </PricingPlan>
  );
};

export const EnterprisePlan = ({
  onClick
}: {
  onClick: () => void;
}): JSX.Element => {
  return (
    <PricingPlan style={{ border: "2px solid red" }}>
      <img
        src={ampdLogo}
        width={"150px"}
        alt="Ampd logo"
        style={{ margin: "2em auto" }}
      />

      <PricingSubtitle>
        <p>
          Please reach out to our sales team to build a plan that fits the needs
          of your business.
        </p>
      </PricingSubtitle>

      <Button
        size="big"
        style={{
          width: "100%",
          marginTop: "0.5em",
          color: "white",
          backgroundColor: ampdRed
        }}
        onClick={() => {
          onClick();
        }}
      >
        Book a Demo
      </Button>

      <PricingFeatures style={{ padding: "10px" }} divided relaxed>
        {[
          { text: "Available Done-For-You Campaign Creation", bold: false },
          { text: "Available Ongoing Management", bold: false },
          { text: "Available Strategy Review Calls", bold: false },
          {
            text: "Available Self-Service Options",
            bold: false
          },
          {
            text: "Data Exports",
            bold: false
          },
          { text: "Dashboard Login", bold: false }
        ].map(f => (
          <List.Item
            key={f.text}
            style={{ paddingTop: "1.25em", paddingBottom: "1.25em" }}
          >
            <List.Icon name="check square outline" color="red" />
            {f.bold ? (
              <List.Content>
                <b>{f.text}</b>
              </List.Content>
            ) : (
              <List.Content>{f.text}</List.Content>
            )}
          </List.Item>
        ))}
      </PricingFeatures>
    </PricingPlan>
  );
};
