import { Set as ImmutableSet } from "immutable";
import React, { useMemo, useState } from "react";
import {
  Message,
  Segment,
  Icon,
  Checkbox,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Table,
  Label
} from "semantic-ui-react";
import styled from "styled-components";

import { extractErrorMessage } from "Common/errors/error";
import {
  FacebookAd,
  FacebookAdSet,
  FacebookCampaign,
  FacebookMarketingResources,
  useNestedFacebookMarketingResources
} from "ExtensionV2/queries/useFacebookMarketingResources";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { EnableAttributionConfirmationModalLauncher } from "./EnableAttributionModal";
import { Retailer } from "Common/proto/common/retailer_pb";
import { campaignTrackingStatus } from "Common/utils/facebook";
import { LoadingSpinner } from "Common/components/LoadingSpinner";

const StyledCampaignInfoSegment = styled(Segment)`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  min-width: 60em;

  > div:first-child {
    display: grid;
    grid-template-columns: 2.5fr 0.5fr 1fr 1fr;
    gap: 1em;
    align-items: center;

    > h3 {
      margin-bottom: 0.5em;
    }

    div > h5 {
      margin-bottom: 0;
    }

    div > p {
      margin-bottom: 0;
    }
  }
`;

const StyledCampaignAttributionStatusMessage = styled(Message)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: fit-content;
  max-width: 40em;
`;

const StyledAdAttributionStatusTable = styled(Table)`
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;

  td,
  th {
    white-space: nowrap;
  }

  .checkbox {
    width: 7em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ad-name {
    max-width: 20em;
    min-width: 10em;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ad-status {
    max-width: 0;
    min-width: 10em;
    width: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .destination-url {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function ConfigureCampaignsTabPane(): JSX.Element {
  const {
    data: fbResources,
    isLoading: facebookAdResourcesLoading,
    isFetching: facebookAdResourcesFetching,
    error: facebookAdResourcesError
  } = useNestedFacebookMarketingResources();

  let tabContent = <></>;
  if (facebookAdResourcesLoading) {
    tabContent = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <LoadingSpinner>Loading your Meta Ads data</LoadingSpinner>
      </div>
    );
  } else if (facebookAdResourcesError) {
    const errorMessage = extractErrorMessage(facebookAdResourcesError);
    tabContent = <Message error>{errorMessage}</Message>;
  } else if (fbResources) {
    tabContent = (
      <ManageCampaigns
        fbResources={fbResources}
        resourcesFetching={facebookAdResourcesFetching}
      />
    );
  }

  return tabContent;
}
const toggleValueInSet = (
  set: ImmutableSet<string>,
  value: string
): ImmutableSet<string> => {
  if (set.has(value)) {
    return set.delete(value);
  } else {
    return set.add(value);
  }
};

function ManageCampaigns({
  fbResources,
  resourcesFetching
}: {
  fbResources: FacebookMarketingResources;
  resourcesFetching: boolean;
}) {
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<
    ImmutableSet<string>
  >(ImmutableSet());

  const [selectedAdIds, setSelectedAdIds] = useState<ImmutableSet<string>>(
    ImmutableSet()
  );

  const [expandedCampaignIds, setExpandedCampaignIds] = useState<
    ImmutableSet<string>
  >(ImmutableSet());

  const allAds = useMemo(
    () => Object.values(fbResources).flatMap(campaign => campaign.ads),
    [fbResources]
  );

  const toggleCampaign = (campaign: FacebookCampaign) => {
    if (selectedCampaignIds.has(campaign.id)) {
      // Toggle the campaign and all ads off.
      setSelectedCampaignIds(selectedCampaignIds.delete(campaign.id));

      let adIds = selectedAdIds;
      for (const ad of campaign.ads) {
        adIds = adIds.delete(ad.id);
      }

      setSelectedAdIds(adIds);
    } else {
      // Toggle the campaign and all ads on.
      setSelectedCampaignIds(selectedCampaignIds.add(campaign.id));

      let adIds = selectedAdIds;
      for (const ad of campaign.ads) {
        if (
          ad.retailer === Retailer.Option.AMAZON ||
          ad.retailer === Retailer.Option.WALMART
        ) {
          adIds = adIds.add(ad.id);
        }
      }

      setSelectedAdIds(adIds);
    }
  };

  const toggleAd = (ad: FacebookAd) => {
    if (selectedAdIds.has(ad.id)) {
      // Toggle the ad off, and if all ads in the campaign are off, toggle the campaign off too.
      const adIds = selectedAdIds.delete(ad.id);

      const siblingAds = fbResources[ad.campaignId].ads.filter(
        ad =>
          ad.retailer === Retailer.Option.AMAZON ||
          ad.retailer === Retailer.Option.WALMART
      );

      if (siblingAds.every(a => !adIds.has(a.id))) {
        setSelectedCampaignIds(selectedCampaignIds.delete(ad.campaignId));
      }

      setSelectedAdIds(adIds);
    } else {
      // Toggle the ad on, and if at least one ad in the campaign is on, toggle the campaign on too.
      setSelectedAdIds(selectedAdIds.add(ad.id));
      setSelectedCampaignIds(selectedCampaignIds.add(ad.campaignId));
    }
  };

  const selectedAds = allAds
    .filter(ad => selectedAdIds.has(ad.id))
    .filter(
      ad =>
        ad.retailer == Retailer.Option.AMAZON ||
        ad.retailer == Retailer.Option.WALMART
    )
    .filter(ad => !ad.hasTracking);

  return (
    <>
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center"
        }}
      >
        <EnableAttributionConfirmationModalLauncher
          selectedAds={selectedAds}
          disabled={resourcesFetching}
        />
        {resourcesFetching && (
          <Label>
            <Icon loading name="spinner" size="large" />
            Refreshing
          </Label>
        )}
      </div>
      <div
        style={{
          overflow: "auto",
          boxShadow: "0px 0px 1px 0px gray",
          borderRadius: "5px"
        }}
      >
        {Object.entries(fbResources).map(([campaignId, campaign]) => {
          return (
            <StyledCampaignInfoSegment key={campaignId}>
              <CampaignInfo
                key={campaignId}
                isSelected={selectedCampaignIds.has(campaignId)}
                campaign={campaign}
                onToggleSelectCampaign={toggleCampaign}
              />

              {/* More Info Expanding Section */}
              <Accordion>
                <AccordionTitle
                  active={expandedCampaignIds.has(campaignId)}
                  index={campaignId}
                  onClick={() => {
                    setExpandedCampaignIds(
                      toggleValueInSet(expandedCampaignIds, campaignId)
                    );
                  }}
                >
                  <Icon name="dropdown" />
                  More Info
                </AccordionTitle>
                <AccordionContent
                  active={expandedCampaignIds.has(campaignId)}
                  style={{ overflow: "auto" }}
                >
                  <AdAttributionStatusTable
                    adSets={campaign.adSets}
                    selectedAdIds={selectedAdIds}
                    onToggleSelectAd={toggleAd}
                    resourcesFetching={resourcesFetching}
                  />
                </AccordionContent>
              </Accordion>
            </StyledCampaignInfoSegment>
          );
        })}
      </div>
    </>
  );
}

function CampaignInfo({
  campaign,
  isSelected,
  onToggleSelectCampaign
}: {
  campaign: FacebookCampaign;
  isSelected: boolean;
  onToggleSelectCampaign: (campaign: FacebookCampaign) => void;
}) {
  return (
    <div>
      <div>
        <h3>{campaign.name}</h3>
        <CampaignAttributionStatusMessage
          campaign={campaign}
          isSelected={isSelected}
          onToggleSelectCampaign={onToggleSelectCampaign}
        />
      </div>

      <div>
        <h5>Status</h5>
        <p>{campaign.effectiveStatus}</p>
      </div>

      <div>
        <h5>Objective</h5>
        <p>{campaign.objective}</p>
      </div>

      <div>
        <h5>ID</h5>
        <p>{campaign.id}</p>
      </div>
    </div>
  );
}

function CampaignAttributionStatusMessage({
  campaign,
  isSelected,
  onToggleSelectCampaign
}: {
  campaign: FacebookCampaign;
  isSelected: boolean;
  onToggleSelectCampaign: (campaignId: FacebookCampaign) => void;
}) {
  const {
    hasAtLeastOneAmazonAd,
    hasAtLeastOneTrackedAmazonAd,
    hasAtLeastOneWalmartAd,
    hasAtLeastOneTrackedWalmartAd
  } = campaignTrackingStatus(campaign);

  let attributionContent = <></>;
  let messageType: "info" | "positive" | "warning" = "info";
  if (hasAtLeastOneTrackedAmazonAd || hasAtLeastOneTrackedWalmartAd) {
    messageType = "positive";
    attributionContent = (
      <>
        <Icon name="check circle" color="green" />
        <div style={{ width: "fit-content" }}>
          <p>This campaign is collecting Ampd Attribution</p>
        </div>
      </>
    );
  } else if (hasAtLeastOneAmazonAd || hasAtLeastOneWalmartAd) {
    messageType = "info";
    attributionContent = (
      <Checkbox
        checked={isSelected}
        onChange={() => {
          onToggleSelectCampaign(campaign);
        }}
        label="Turn on Ampd Attribution for this campaign"
      />
    );
  } else {
    messageType = "warning";
    attributionContent = (
      <>
        <Icon name="exclamation triangle" color="yellow" />
        <div style={{ width: "fit-content", maxWidth: "30em" }}>
          <p>This campaign is not eligible for Ampd Attribution.</p>
          <p>
            At least one Ad Creative must have a destination URL targeting an
            Ampd supported marketplace.
          </p>
        </div>
      </>
    );
  }

  return (
    <StyledCampaignAttributionStatusMessage
      warning={messageType === "warning"}
      positive={messageType === "positive"}
      info={messageType === "info"}
    >
      {attributionContent}
    </StyledCampaignAttributionStatusMessage>
  );
}

function AdAttributionStatusTable({
  adSets,
  selectedAdIds,
  onToggleSelectAd,
  resourcesFetching
}: {
  adSets: Array<FacebookAdSet>;
  selectedAdIds: ImmutableSet<string>;
  onToggleSelectAd: (ad: FacebookAd) => void;
  resourcesFetching: boolean;
}) {
  return (
    <StyledAdAttributionStatusTable size="small" compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="checkbox">
            <p>
              Attribution
              <br />
              Enabled
            </p>
          </Table.HeaderCell>
          <Table.HeaderCell className="ad-name">Ad Name</Table.HeaderCell>
          <Table.HeaderCell className="ad-status">Ad Status</Table.HeaderCell>
          <Table.HeaderCell className="destination-url">
            Destination URL
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {adSets.map(adSet => {
          return (
            <React.Fragment key={adSet.id}>
              {adSet.ads.map(ad => {
                return (
                  <AdAttributionStatusRow
                    key={ad.id}
                    ad={ad}
                    selected={selectedAdIds.has(ad.id)}
                    onToggleSelectAd={onToggleSelectAd}
                    resourcesFetching={resourcesFetching}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </Table.Body>
    </StyledAdAttributionStatusTable>
  );
}

function AdAttributionStatusRow({
  ad,
  selected,
  onToggleSelectAd,
  resourcesFetching
}: {
  ad: FacebookAd;
  selected: boolean;
  onToggleSelectAd: (ad: FacebookAd) => void;
  resourcesFetching: boolean;
}): JSX.Element {
  let attributionCellContent = <></>;
  if (ad.hasTracking) {
    // The ad has tracking enabled, no actions for the user to take.
    attributionCellContent = <Icon name="check circle" color="green" />;
  } else if (
    ad.retailer === Retailer.Option.AMAZON ||
    ad.retailer === Retailer.Option.WALMART
  ) {
    // The ad is eligible for tracking, either make it selectable with a checkbox or
    // it was just recently mutated and we are fetching the updated data.
    attributionCellContent =
      selected && resourcesFetching ? (
        <Icon loading name="spinner" />
      ) : (
        <Checkbox checked={selected} onChange={() => onToggleSelectAd(ad)} />
      );
  } else {
    // The ad is not eligible for tracking.
    attributionCellContent = <>N/A</>;
  }

  const destinationUrl =
    ad.adCreative?.linkUrl ||
    ad.adCreative?.assetFeedSpec?.linkUrlsList[0]?.websiteUrl ||
    ad.adCreative?.objectStorySpec?.linkData?.link;

  return (
    <Table.Row key={ad.id}>
      <Table.Cell className="checkbox">{attributionCellContent}</Table.Cell>
      <SimpleTooltip tooltip={ad.name}>
        <Table.Cell className="ad-name">{ad.name}</Table.Cell>
      </SimpleTooltip>
      <Table.Cell className="ad-status">{ad.effectiveStatus}</Table.Cell>
      <SimpleTooltip tooltip={destinationUrl}>
        <Table.Cell className="destination-url">{destinationUrl}</Table.Cell>
      </SimpleTooltip>
    </Table.Row>
  );
}
