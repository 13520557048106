// source: proto/entity/siteManagerLink.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_common_crudFields_pb = require('../../proto/common/crudFields_pb.js');
goog.object.extend(proto, proto_common_crudFields_pb);
var proto_common_objectID_pb = require('../../proto/common/objectID_pb.js');
goog.object.extend(proto, proto_common_objectID_pb);
goog.exportSymbol('proto.entity.SiteManagerLink', null, global);
goog.exportSymbol('proto.entity.SiteManagerLinkClient', null, global);
goog.exportSymbol('proto.entity.SiteManagerLinkClient.Details', null, global);
goog.exportSymbol('proto.entity.SiteManagerLinkManager', null, global);
goog.exportSymbol('proto.entity.SiteManagerLinkManager.Details', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteManagerLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteManagerLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteManagerLink.displayName = 'proto.entity.SiteManagerLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteManagerLinkClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteManagerLinkClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteManagerLinkClient.displayName = 'proto.entity.SiteManagerLinkClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteManagerLinkClient.Details = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.SiteManagerLinkClient.Details.repeatedFields_, null);
};
goog.inherits(proto.entity.SiteManagerLinkClient.Details, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteManagerLinkClient.Details.displayName = 'proto.entity.SiteManagerLinkClient.Details';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteManagerLinkManager = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteManagerLinkManager, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteManagerLinkManager.displayName = 'proto.entity.SiteManagerLinkManager';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.SiteManagerLinkManager.Details = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.SiteManagerLinkManager.Details, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.SiteManagerLinkManager.Details.displayName = 'proto.entity.SiteManagerLinkManager.Details';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteManagerLink.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteManagerLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteManagerLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteId: (f = msg.getManagerSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    clientSiteId: (f = msg.getClientSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    detailsAboutManager: (f = msg.getDetailsAboutManager()) && proto.entity.SiteManagerLinkManager.Details.toObject(includeInstance, f),
    detailsAboutClient: (f = msg.getDetailsAboutClient()) && proto.entity.SiteManagerLinkClient.Details.toObject(includeInstance, f),
    crudFields: (f = msg.getCrudFields()) && proto_common_crudFields_pb.CRUDFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteManagerLink}
 */
proto.entity.SiteManagerLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteManagerLink;
  return proto.entity.SiteManagerLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteManagerLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteManagerLink}
 */
proto.entity.SiteManagerLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setManagerSiteId(value);
      break;
    case 2:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setClientSiteId(value);
      break;
    case 3:
      var value = new proto.entity.SiteManagerLinkManager.Details;
      reader.readMessage(value,proto.entity.SiteManagerLinkManager.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutManager(value);
      break;
    case 4:
      var value = new proto.entity.SiteManagerLinkClient.Details;
      reader.readMessage(value,proto.entity.SiteManagerLinkClient.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutClient(value);
      break;
    case 5:
      var value = new proto_common_crudFields_pb.CRUDFields;
      reader.readMessage(value,proto_common_crudFields_pb.CRUDFields.deserializeBinaryFromReader);
      msg.setCrudFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteManagerLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteManagerLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteManagerLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getClientSiteId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getDetailsAboutManager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.SiteManagerLinkManager.Details.serializeBinaryToWriter
    );
  }
  f = message.getDetailsAboutClient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.SiteManagerLinkClient.Details.serializeBinaryToWriter
    );
  }
  f = message.getCrudFields();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_crudFields_pb.CRUDFields.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.ObjectID manager_site_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteManagerLink.prototype.getManagerSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteManagerLink} returns this
*/
proto.entity.SiteManagerLink.prototype.setManagerSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLink} returns this
 */
proto.entity.SiteManagerLink.prototype.clearManagerSiteId = function() {
  return this.setManagerSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLink.prototype.hasManagerSiteId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.ObjectID client_site_id = 2;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteManagerLink.prototype.getClientSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 2));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteManagerLink} returns this
*/
proto.entity.SiteManagerLink.prototype.setClientSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLink} returns this
 */
proto.entity.SiteManagerLink.prototype.clearClientSiteId = function() {
  return this.setClientSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLink.prototype.hasClientSiteId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SiteManagerLinkManager.Details details_about_manager = 3;
 * @return {?proto.entity.SiteManagerLinkManager.Details}
 */
proto.entity.SiteManagerLink.prototype.getDetailsAboutManager = function() {
  return /** @type{?proto.entity.SiteManagerLinkManager.Details} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteManagerLinkManager.Details, 3));
};


/**
 * @param {?proto.entity.SiteManagerLinkManager.Details|undefined} value
 * @return {!proto.entity.SiteManagerLink} returns this
*/
proto.entity.SiteManagerLink.prototype.setDetailsAboutManager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLink} returns this
 */
proto.entity.SiteManagerLink.prototype.clearDetailsAboutManager = function() {
  return this.setDetailsAboutManager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLink.prototype.hasDetailsAboutManager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SiteManagerLinkClient.Details details_about_client = 4;
 * @return {?proto.entity.SiteManagerLinkClient.Details}
 */
proto.entity.SiteManagerLink.prototype.getDetailsAboutClient = function() {
  return /** @type{?proto.entity.SiteManagerLinkClient.Details} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteManagerLinkClient.Details, 4));
};


/**
 * @param {?proto.entity.SiteManagerLinkClient.Details|undefined} value
 * @return {!proto.entity.SiteManagerLink} returns this
*/
proto.entity.SiteManagerLink.prototype.setDetailsAboutClient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLink} returns this
 */
proto.entity.SiteManagerLink.prototype.clearDetailsAboutClient = function() {
  return this.setDetailsAboutClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLink.prototype.hasDetailsAboutClient = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.CRUDFields crud_fields = 5;
 * @return {?proto.common.CRUDFields}
 */
proto.entity.SiteManagerLink.prototype.getCrudFields = function() {
  return /** @type{?proto.common.CRUDFields} */ (
    jspb.Message.getWrapperField(this, proto_common_crudFields_pb.CRUDFields, 5));
};


/**
 * @param {?proto.common.CRUDFields|undefined} value
 * @return {!proto.entity.SiteManagerLink} returns this
*/
proto.entity.SiteManagerLink.prototype.setCrudFields = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLink} returns this
 */
proto.entity.SiteManagerLink.prototype.clearCrudFields = function() {
  return this.setCrudFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLink.prototype.hasCrudFields = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteManagerLinkClient.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteManagerLinkClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteManagerLinkClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSiteId: (f = msg.getClientSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    clientSiteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientSiteAlias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hubSpotCompanyId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    googleAdsCustomerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    googleAdsErrorDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hasAmazonAdvertisingAccount: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    amazonAdvertisingErrorDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    hasAmazonSellerAccount: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    amazonSellerErrorDescription: jspb.Message.getFieldWithDefault(msg, 13, ""),
    billingStatusDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    billingCanceledTime: (f = msg.getBillingCanceledTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    billingActiveUntilTime: (f = msg.getBillingActiveUntilTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    detailsAboutClient: (f = msg.getDetailsAboutClient()) && proto.entity.SiteManagerLinkClient.Details.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteManagerLinkClient}
 */
proto.entity.SiteManagerLinkClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteManagerLinkClient;
  return proto.entity.SiteManagerLinkClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteManagerLinkClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteManagerLinkClient}
 */
proto.entity.SiteManagerLinkClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setClientSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSiteAlias(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotCompanyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoogleAdsCustomerId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoogleAdsErrorDescription(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAmazonAdvertisingAccount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonAdvertisingErrorDescription(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAmazonSellerAccount(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonSellerErrorDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingStatusDescription(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBillingCanceledTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBillingActiveUntilTime(value);
      break;
    case 8:
      var value = new proto.entity.SiteManagerLinkClient.Details;
      reader.readMessage(value,proto.entity.SiteManagerLinkClient.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteManagerLinkClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteManagerLinkClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteManagerLinkClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSiteId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getClientSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHubSpotCompanyId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGoogleAdsCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGoogleAdsErrorDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHasAmazonAdvertisingAccount();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAmazonAdvertisingErrorDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHasAmazonSellerAccount();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAmazonSellerErrorDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBillingStatusDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBillingCanceledTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBillingActiveUntilTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDetailsAboutClient();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.entity.SiteManagerLinkClient.Details.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.SiteManagerLinkClient.Details.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteManagerLinkClient.Details.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteManagerLinkClient.Details.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteManagerLinkClient.Details} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkClient.Details.toObject = function(includeInstance, msg) {
  var f, obj = {
    alternativeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteManagerLinkClient.Details}
 */
proto.entity.SiteManagerLinkClient.Details.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteManagerLinkClient.Details;
  return proto.entity.SiteManagerLinkClient.Details.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteManagerLinkClient.Details} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteManagerLinkClient.Details}
 */
proto.entity.SiteManagerLinkClient.Details.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternativeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteManagerLinkClient.Details.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteManagerLinkClient.Details.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteManagerLinkClient.Details} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkClient.Details.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlternativeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string alternative_name = 1;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.Details.prototype.getAlternativeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient.Details} returns this
 */
proto.entity.SiteManagerLinkClient.Details.prototype.setAlternativeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.entity.SiteManagerLinkClient.Details.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.SiteManagerLinkClient.Details} returns this
 */
proto.entity.SiteManagerLinkClient.Details.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.SiteManagerLinkClient.Details} returns this
 */
proto.entity.SiteManagerLinkClient.Details.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.SiteManagerLinkClient.Details} returns this
 */
proto.entity.SiteManagerLinkClient.Details.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional common.ObjectID client_site_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteManagerLinkClient.prototype.getClientSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
*/
proto.entity.SiteManagerLinkClient.prototype.setClientSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.clearClientSiteId = function() {
  return this.setClientSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLinkClient.prototype.hasClientSiteId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string client_site_name = 2;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getClientSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setClientSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_site_alias = 3;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getClientSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setClientSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency_code = 14;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string hub_spot_company_id = 15;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getHubSpotCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setHubSpotCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string google_ads_customer_id = 4;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getGoogleAdsCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setGoogleAdsCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string google_ads_error_description = 9;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getGoogleAdsErrorDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setGoogleAdsErrorDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool has_amazon_advertising_account = 10;
 * @return {boolean}
 */
proto.entity.SiteManagerLinkClient.prototype.getHasAmazonAdvertisingAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setHasAmazonAdvertisingAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string amazon_advertising_error_description = 11;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getAmazonAdvertisingErrorDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setAmazonAdvertisingErrorDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool has_amazon_seller_account = 12;
 * @return {boolean}
 */
proto.entity.SiteManagerLinkClient.prototype.getHasAmazonSellerAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setHasAmazonSellerAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string amazon_seller_error_description = 13;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getAmazonSellerErrorDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setAmazonSellerErrorDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string billing_status_description = 5;
 * @return {string}
 */
proto.entity.SiteManagerLinkClient.prototype.getBillingStatusDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.setBillingStatusDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp billing_canceled_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.SiteManagerLinkClient.prototype.getBillingCanceledTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
*/
proto.entity.SiteManagerLinkClient.prototype.setBillingCanceledTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.clearBillingCanceledTime = function() {
  return this.setBillingCanceledTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLinkClient.prototype.hasBillingCanceledTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp billing_active_until_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.entity.SiteManagerLinkClient.prototype.getBillingActiveUntilTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
*/
proto.entity.SiteManagerLinkClient.prototype.setBillingActiveUntilTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.clearBillingActiveUntilTime = function() {
  return this.setBillingActiveUntilTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLinkClient.prototype.hasBillingActiveUntilTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Details details_about_client = 8;
 * @return {?proto.entity.SiteManagerLinkClient.Details}
 */
proto.entity.SiteManagerLinkClient.prototype.getDetailsAboutClient = function() {
  return /** @type{?proto.entity.SiteManagerLinkClient.Details} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteManagerLinkClient.Details, 8));
};


/**
 * @param {?proto.entity.SiteManagerLinkClient.Details|undefined} value
 * @return {!proto.entity.SiteManagerLinkClient} returns this
*/
proto.entity.SiteManagerLinkClient.prototype.setDetailsAboutClient = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLinkClient} returns this
 */
proto.entity.SiteManagerLinkClient.prototype.clearDetailsAboutClient = function() {
  return this.setDetailsAboutClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLinkClient.prototype.hasDetailsAboutClient = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteManagerLinkManager.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteManagerLinkManager.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteManagerLinkManager} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkManager.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerSiteId: (f = msg.getManagerSiteId()) && proto_common_objectID_pb.ObjectID.toObject(includeInstance, f),
    managerSiteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    managerSiteAlias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hubSpotCompanyId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    detailsAboutManager: (f = msg.getDetailsAboutManager()) && proto.entity.SiteManagerLinkManager.Details.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteManagerLinkManager}
 */
proto.entity.SiteManagerLinkManager.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteManagerLinkManager;
  return proto.entity.SiteManagerLinkManager.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteManagerLinkManager} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteManagerLinkManager}
 */
proto.entity.SiteManagerLinkManager.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_objectID_pb.ObjectID;
      reader.readMessage(value,proto_common_objectID_pb.ObjectID.deserializeBinaryFromReader);
      msg.setManagerSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerSiteAlias(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubSpotCompanyId(value);
      break;
    case 4:
      var value = new proto.entity.SiteManagerLinkManager.Details;
      reader.readMessage(value,proto.entity.SiteManagerLinkManager.Details.deserializeBinaryFromReader);
      msg.setDetailsAboutManager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteManagerLinkManager.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteManagerLinkManager.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteManagerLinkManager} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkManager.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerSiteId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_objectID_pb.ObjectID.serializeBinaryToWriter
    );
  }
  f = message.getManagerSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManagerSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHubSpotCompanyId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDetailsAboutManager();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.SiteManagerLinkManager.Details.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.SiteManagerLinkManager.Details.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.SiteManagerLinkManager.Details.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.SiteManagerLinkManager.Details} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkManager.Details.toObject = function(includeInstance, msg) {
  var f, obj = {
    alternativeName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.SiteManagerLinkManager.Details}
 */
proto.entity.SiteManagerLinkManager.Details.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.SiteManagerLinkManager.Details;
  return proto.entity.SiteManagerLinkManager.Details.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.SiteManagerLinkManager.Details} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.SiteManagerLinkManager.Details}
 */
proto.entity.SiteManagerLinkManager.Details.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternativeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.SiteManagerLinkManager.Details.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.SiteManagerLinkManager.Details.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.SiteManagerLinkManager.Details} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.SiteManagerLinkManager.Details.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlternativeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alternative_name = 1;
 * @return {string}
 */
proto.entity.SiteManagerLinkManager.Details.prototype.getAlternativeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkManager.Details} returns this
 */
proto.entity.SiteManagerLinkManager.Details.prototype.setAlternativeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ObjectID manager_site_id = 1;
 * @return {?proto.common.ObjectID}
 */
proto.entity.SiteManagerLinkManager.prototype.getManagerSiteId = function() {
  return /** @type{?proto.common.ObjectID} */ (
    jspb.Message.getWrapperField(this, proto_common_objectID_pb.ObjectID, 1));
};


/**
 * @param {?proto.common.ObjectID|undefined} value
 * @return {!proto.entity.SiteManagerLinkManager} returns this
*/
proto.entity.SiteManagerLinkManager.prototype.setManagerSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLinkManager} returns this
 */
proto.entity.SiteManagerLinkManager.prototype.clearManagerSiteId = function() {
  return this.setManagerSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLinkManager.prototype.hasManagerSiteId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string manager_site_name = 2;
 * @return {string}
 */
proto.entity.SiteManagerLinkManager.prototype.getManagerSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkManager} returns this
 */
proto.entity.SiteManagerLinkManager.prototype.setManagerSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string manager_site_alias = 3;
 * @return {string}
 */
proto.entity.SiteManagerLinkManager.prototype.getManagerSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkManager} returns this
 */
proto.entity.SiteManagerLinkManager.prototype.setManagerSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string hub_spot_company_id = 5;
 * @return {string}
 */
proto.entity.SiteManagerLinkManager.prototype.getHubSpotCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.SiteManagerLinkManager} returns this
 */
proto.entity.SiteManagerLinkManager.prototype.setHubSpotCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Details details_about_manager = 4;
 * @return {?proto.entity.SiteManagerLinkManager.Details}
 */
proto.entity.SiteManagerLinkManager.prototype.getDetailsAboutManager = function() {
  return /** @type{?proto.entity.SiteManagerLinkManager.Details} */ (
    jspb.Message.getWrapperField(this, proto.entity.SiteManagerLinkManager.Details, 4));
};


/**
 * @param {?proto.entity.SiteManagerLinkManager.Details|undefined} value
 * @return {!proto.entity.SiteManagerLinkManager} returns this
*/
proto.entity.SiteManagerLinkManager.prototype.setDetailsAboutManager = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.SiteManagerLinkManager} returns this
 */
proto.entity.SiteManagerLinkManager.prototype.clearDetailsAboutManager = function() {
  return this.setDetailsAboutManager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.SiteManagerLinkManager.prototype.hasDetailsAboutManager = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.entity);
