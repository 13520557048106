import { DateInput } from "semantic-ui-calendar-react";
import { Checkbox, Dropdown, DropdownProps } from "semantic-ui-react";
import { useLocation, Link, useSearchParams } from "react-router-dom-v5-compat";
import moment, { Moment } from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import {
  METRIC_DATE_RANGE_OPTIONS,
  parseDateRangeParam
} from "Common/components/dateRangeUtils";
import styled from "styled-components";
import getCompareToDates, {
  COMPARE_TO_PREVIOUS
} from "ExtensionV2/state/getCompareToDates";
import SimpleTooltip from "./SimpleTooltip";
import { pluralize } from "Common/utils/strings";

import { AMAZON_ATTRIBUTION_DELAY_DAYS } from "Common/utils/amazon";
import { GlobalDateContext } from "ExtensionV2";

export const DatePickerLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

export const DatePickerCheckbox = styled(Checkbox)`
  &&& {
    display: block;
    margin: 0 0 0.3em 0.5em;
    font-weight: bold;
    font-size: small;
  }
`;

const OLDEST_DATA_DATE = "2010-01-01";

export const QUERY_STRING_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_RANGE_CUSTOM_START_URL_PARAM = "queryStart";
export const DATE_RANGE_CUSTOM_END_URL_PARAM = "queryEnd";
export const DATE_RANGE_NAME_URL_PARAM = "queryRange";
export const DATE_RANGE_COMPARE_TO_URL_PARAM = "compare";
export const DEFAULT_NAMED_DATE_RANGE = "last-30-days";

const COMPARE_STARTING_AT = "startingAt";

const DISPLAY_FORMAT = "ddd, MMM Do YYYY"; // ex: Sat, Jul 2nd 2022

export type GlobalDates = {
  startDate: string;
  endDate: string;
  namedRange: string;
  compareTo: string;
};

export type GlobalDateSetters = {
  setGlobalStartDate: (newStartDate: string) => void;
  setGlobalEndDate: (newEndDate: string) => void;
  setGlobalNamedRange: (newNamedRange: string) => void;
  setGlobalCompareTo: (newCompareTo: string) => void;
};

// A hook that holds the global date range values in state. Only call this hook
// once at the root of the application. After that, use the GlobalDateContext to
// access the global dates.
export function useGlobalDates(
  excludeAmazonLagPeriod: boolean
): GlobalDates & GlobalDateSetters {
  const [searchParams] = useSearchParams();

  const urlStart = searchParams.get(DATE_RANGE_CUSTOM_START_URL_PARAM) || "";
  const urlEnd = searchParams.get(DATE_RANGE_CUSTOM_END_URL_PARAM) || "";
  const urlNamedRange = searchParams.get(DATE_RANGE_NAME_URL_PARAM) || "";
  const urlCompare = searchParams.get(DATE_RANGE_COMPARE_TO_URL_PARAM) || "";
  // When the app first loads, the URL params determine the value of the initial
  // state. After that, the URL params are controlled by the application state.
  const [globalDates, setGlobalDates] = useState<GlobalDates>(() =>
    calculateDefaultGlobalDates(
      urlStart,
      urlEnd,
      urlNamedRange,
      urlCompare,
      excludeAmazonLagPeriod
    )
  );

  // When the excludeAmazonLagPeriod setting is changed, update the global date
  // if a named range is selected.
  const [prevExcludeAmazonLagPeriod, setPrevExcludeAmazonLagPeriod] = useState(
    excludeAmazonLagPeriod
  );
  if (prevExcludeAmazonLagPeriod !== excludeAmazonLagPeriod) {
    setPrevExcludeAmazonLagPeriod(excludeAmazonLagPeriod);

    if (globalDates.namedRange) {
      const [
        effectiveStartDate,
        effectiveEndDate
      ] = calculateDatesFromNamedRange(
        globalDates.namedRange,
        excludeAmazonLagPeriod
      );

      setGlobalDates(dates => ({
        ...dates,
        startDate: effectiveStartDate,
        endDate: effectiveEndDate
      }));
    }
  }

  const setGlobalStartDate = useCallback(
    (newStartDate: string) => {
      const [effectiveStartDate, effectiveEndDate] = calculateEffectiveDates(
        newStartDate,
        globalDates.endDate
      );

      setGlobalDates(dates => ({
        ...dates,
        startDate: effectiveStartDate,
        endDate: effectiveEndDate,
        namedRange: ""
      }));
    },
    [globalDates.endDate]
  );

  const setGlobalEndDate = useCallback(
    (newEndDate: string) => {
      const [effectiveStartDate, effectiveEndDate] = calculateEffectiveDates(
        globalDates.startDate,
        newEndDate
      );

      setGlobalDates(dates => ({
        ...dates,
        startDate: effectiveStartDate,
        endDate: effectiveEndDate,
        namedRange: ""
      }));
    },
    [globalDates.startDate]
  );

  const setGlobalNamedRange = useCallback(
    (newNamedRange: string) => {
      const [
        effectiveStartDate,
        effectiveEndDate
      ] = calculateDatesFromNamedRange(newNamedRange, excludeAmazonLagPeriod);

      setGlobalDates(dates => ({
        ...dates,
        startDate: effectiveStartDate,
        endDate: effectiveEndDate,
        namedRange: newNamedRange
      }));
    },
    [excludeAmazonLagPeriod]
  );

  const setGlobalCompareTo = useCallback((newCompareTo: string) => {
    const effectiveCompareTo = getEffectiveCompareTo(newCompareTo);
    setGlobalDates(dates => ({
      ...dates,
      compareTo: effectiveCompareTo
    }));
  }, []);

  return {
    ...globalDates,
    setGlobalStartDate,
    setGlobalEndDate,
    setGlobalNamedRange,
    setGlobalCompareTo
  };
}

// Manages the dashboard-wide date range.
export const GlobalDatePicker = ({
  offerCompare = false
}: {
  offerCompare?: boolean;
}): JSX.Element => {
  const location = useLocation();

  const globalDates = useContext(GlobalDateContext);

  // As a side effect of the GlobalDatePicker mounting, the stateful date values
  // should be written to the URL. This causes the URL params to be written back
  // to the URL after navigating from a page that doesn't have the
  // GlobalDatePicker.
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (globalDates.compareTo) {
      searchParams.set(DATE_RANGE_COMPARE_TO_URL_PARAM, globalDates.compareTo);
    } else {
      searchParams.delete(DATE_RANGE_COMPARE_TO_URL_PARAM);
    }

    if (globalDates.namedRange) {
      searchParams.set(DATE_RANGE_NAME_URL_PARAM, globalDates.namedRange);
      searchParams.delete(DATE_RANGE_CUSTOM_START_URL_PARAM);
      searchParams.delete(DATE_RANGE_CUSTOM_END_URL_PARAM);
    } else {
      searchParams.delete(DATE_RANGE_NAME_URL_PARAM);
      searchParams.set(
        DATE_RANGE_CUSTOM_START_URL_PARAM,
        globalDates.startDate
      );
      searchParams.set(DATE_RANGE_CUSTOM_END_URL_PARAM, globalDates.endDate);
    }

    // Don't use react-router to update the URL here. Calling react-router's
    // setSearchParams method will trigger a re-render in any component that
    // uses it's useSearchParams hook, which in this specific case is not
    // appropriate. If something needs to be subscribed to date changes, it
    // should be using the GlobalDateContext.
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }, [
    globalDates.compareTo,
    globalDates.endDate,
    globalDates.namedRange,
    globalDates.startDate
  ]);

  /*
    Convert our internal date format to a customer-friendly format
  */
  const displayStartDate = internalFormatToCustomerFormat(
    globalDates.startDate
  );
  const displayEndDate = internalFormatToCustomerFormat(globalDates.endDate);
  const displayTodayDate = internalFormatToCustomerFormat(
    moment().format(QUERY_STRING_DATE_FORMAT)
  );

  const diffDays = moment(displayEndDate, DISPLAY_FORMAT).diff(
    moment(displayStartDate, DISPLAY_FORMAT),
    "days"
  );

  const [compareRangeStartDate, compareRangeEndDate] = useMemo(
    () =>
      getCompareToDates(
        globalDates.startDate,
        globalDates.endDate,
        globalDates.compareTo || COMPARE_TO_PREVIOUS
      ),
    [globalDates.startDate, globalDates.endDate, globalDates.compareTo]
  );

  const compareToOptions = useMemo(
    () => [
      {
        text: "--",
        value: "none"
      },
      {
        text: `Previous ${pluralize(diffDays + 1, "day")}`,
        value: COMPARE_TO_PREVIOUS
      },
      {
        text: `${pluralize(diffDays + 1, "day")} starting at`,
        value: COMPARE_STARTING_AT
      }
    ],
    [diffDays]
  );

  const compareToValue = useMemo(() => {
    if (!globalDates.compareTo) {
      return "none";
    }
    if (globalDates.compareTo === COMPARE_TO_PREVIOUS) {
      return COMPARE_TO_PREVIOUS;
    }
    return COMPARE_STARTING_AT;
  }, [globalDates.compareTo]);

  const compareToTooltip = useMemo(() => {
    if (!offerCompare) {
      return null;
    }

    const label = `Compare to ${pluralize(diffDays + 1, "day")}`;
    const start = internalFormatToCustomerFormat(compareRangeStartDate);
    const end = internalFormatToCustomerFormat(compareRangeEndDate);

    return (
      <div>
        {label}:
        <br />
        <Link
          to={{
            pathname: location.pathname,
            search: new URLSearchParams({
              [DATE_RANGE_CUSTOM_START_URL_PARAM]: compareRangeStartDate,
              [DATE_RANGE_CUSTOM_END_URL_PARAM]: compareRangeEndDate
            }).toString()
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {start} - {end}
        </Link>
      </div>
    );
  }, [
    offerCompare,
    diffDays,
    compareRangeStartDate,
    compareRangeEndDate,
    location.pathname
  ]);

  const customerFormatToInternalFormat = (dateString: string) => {
    return moment(dateString, DISPLAY_FORMAT).format(QUERY_STRING_DATE_FORMAT);
  };

  const handleSelectStartDate = (
    _ev: React.SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string }
  ) => {
    globalDates.setGlobalStartDate(customerFormatToInternalFormat(value));
  };

  const handleSelectEndDate = (
    _ev: React.SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string }
  ) => {
    globalDates.setGlobalEndDate(customerFormatToInternalFormat(value));
  };

  const handleSelectNamedRange = (
    _ev: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    globalDates.setGlobalNamedRange(value as string);
  };

  const handleSelectCompareDate = (
    _ev: React.SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string }
  ) => {
    globalDates.setGlobalCompareTo(customerFormatToInternalFormat(value));
  };

  const handleCompareTo = (
    _ev: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    let newCompareTo = "";
    if (value === COMPARE_TO_PREVIOUS) {
      newCompareTo = COMPARE_TO_PREVIOUS;
    } else if (value === COMPARE_STARTING_AT) {
      newCompareTo = compareRangeStartDate;
    }
    globalDates.setGlobalCompareTo(newCompareTo);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "1em"
      }}
    >
      <div>
        <DatePickerLabel>Start Date</DatePickerLabel>
        <DateInput
          name="startDate"
          placeholder="start date"
          dateFormat={DISPLAY_FORMAT}
          value={displayStartDate}
          minDate={OLDEST_DATA_DATE}
          maxDate={displayEndDate}
          iconPosition="left"
          closable={true}
          onChange={handleSelectStartDate}
        />
      </div>

      <SimpleTooltip
        position="top center"
        tooltip={getIncompleteDataMessage()}
        wide={true}
      >
        <div>
          <DatePickerLabel>End Date</DatePickerLabel>
          <DateInput
            name="endDate"
            placeholder="end date"
            dateFormat={DISPLAY_FORMAT}
            value={displayEndDate}
            minDate={displayStartDate}
            maxDate={displayTodayDate}
            iconPosition="left"
            closable={true}
            onChange={handleSelectEndDate}
          />
        </div>
      </SimpleTooltip>

      <div>
        <DatePickerLabel>Range</DatePickerLabel>
        <SimpleTooltip
          position="top center"
          tooltip={"Select a predefined date range"}
        >
          <Dropdown
            style={{ minWidth: "12em" }}
            value={globalDates.namedRange || pluralize(diffDays + 1, "day")}
            selection
            options={
              !globalDates.namedRange
                ? [
                    {
                      text: pluralize(diffDays + 1, "day"),
                      value: pluralize(diffDays + 1, "day")
                    },
                    ...METRIC_DATE_RANGE_OPTIONS
                  ]
                : METRIC_DATE_RANGE_OPTIONS
            }
            onChange={handleSelectNamedRange}
          />
        </SimpleTooltip>
      </div>

      {offerCompare && (
        <>
          <div>
            <DatePickerLabel>Compare To</DatePickerLabel>
            <div>
              <SimpleTooltip
                position="top center"
                tooltip={compareToTooltip}
                hoverable={true}
                wide={true}
              >
                <Dropdown
                  style={{ minWidth: "11em", display: "inline-block" }}
                  value={compareToValue}
                  selection
                  options={compareToOptions}
                  onChange={handleCompareTo}
                />
              </SimpleTooltip>
              {compareToValue === COMPARE_STARTING_AT && (
                <div style={{ display: "inline-block", marginLeft: 2 }}>
                  <DateInput
                    name="compareDate"
                    dateFormat={DISPLAY_FORMAT}
                    value={
                      compareToValue === COMPARE_STARTING_AT
                        ? internalFormatToCustomerFormat(compareRangeStartDate)
                        : ""
                    }
                    iconPosition="left"
                    closable={true}
                    onChange={handleSelectCompareDate}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function getIncompleteDataMessage() {
  const amazonIncompleteDataDate = moment()
    .startOf("day")
    .subtract(AMAZON_ATTRIBUTION_DELAY_DAYS + 1, "days");

  return `Data after ${amazonIncompleteDataDate.format(
    "MMM Do YYYY"
  )} is incomplete`;
}

// Parse the start/end date and fallback to defaults if invalid. If a named
// range and a start/end date is present, ensure the start/end date match the
// time period defined by the named range (named range takes precedence.)
function calculateDefaultGlobalDates(
  urlStart: string,
  urlEnd: string,
  urlNamedRange: string,
  urlCompareTo: string,
  excludeAmazonLagPeriod: boolean
): GlobalDates {
  if (urlNamedRange) {
    const [start, end] = calculateDatesFromNamedRange(
      urlNamedRange,
      excludeAmazonLagPeriod
    );
    return {
      startDate: start,
      endDate: end,
      namedRange: urlNamedRange,
      compareTo: getEffectiveCompareTo(urlCompareTo)
    };
  }

  if (!urlStart && !urlEnd) {
    const [start, end] = calculateDatesFromNamedRange(
      DEFAULT_NAMED_DATE_RANGE,
      excludeAmazonLagPeriod
    );
    return {
      startDate: start,
      endDate: end,
      namedRange: DEFAULT_NAMED_DATE_RANGE,
      compareTo: getEffectiveCompareTo(urlCompareTo)
    };
  }

  const [start, end] = calculateEffectiveDates(urlStart, urlEnd);
  return {
    startDate: start,
    endDate: end,
    namedRange: "",
    compareTo: getEffectiveCompareTo(urlCompareTo)
  };
}

function calculateEffectiveDates(
  startDate: string,
  endDate: string
): [string, string] {
  let start = startDate;
  let end = endDate;

  if (start && !moment(start, QUERY_STRING_DATE_FORMAT).isValid()) {
    start = "";
  }

  if (end && !moment(end, QUERY_STRING_DATE_FORMAT).isValid()) {
    end = "";
  }

  if (!start && !end) {
    [start, end] = getDefaultRange();
  } else if (start && !end) {
    // the url has a start date + no end date, set end date to today
    end = moment().format(QUERY_STRING_DATE_FORMAT);
  } else if (end && !start) {
    // the url has an end date + no start date, use default range
    [start, end] = getDefaultRange();
  } else {
    // ensure the start date is before the end date and the end date is before today,
    // otherwise use the default range
    const startMoment = moment(start, QUERY_STRING_DATE_FORMAT);
    const endMoment = moment(end, QUERY_STRING_DATE_FORMAT);
    const today = moment().startOf("day");

    if (
      startMoment.isAfter(endMoment) ||
      endMoment.isAfter(today) ||
      startMoment.isBefore(OLDEST_DATA_DATE)
    ) {
      [start, end] = getDefaultRange();
    }
  }

  return [start, end];
}

function calculateDatesFromNamedRange(
  namedRange: string,
  excludeAmazonLagPeriod: boolean
): [string, string] {
  const refDate = moment()
    .subtract(
      excludeAmazonLagPeriod ? Math.max(AMAZON_ATTRIBUTION_DELAY_DAYS, 0) : 0,
      "days"
    )
    .endOf("day");

  let startMoment: Moment, endMoment: Moment;
  try {
    [startMoment, endMoment] = parseDateRangeParam(namedRange, refDate);
  } catch (err) {
    console.error(err);
    [startMoment, endMoment] = parseDateRangeParam(
      DEFAULT_NAMED_DATE_RANGE,
      refDate
    );
  }

  return [
    startMoment.format(QUERY_STRING_DATE_FORMAT),
    endMoment.format(QUERY_STRING_DATE_FORMAT)
  ];
}

const getEffectiveCompareTo = (compareTo: string): string => {
  if (compareTo === COMPARE_TO_PREVIOUS) {
    return compareTo;
  }

  if (moment(compareTo, QUERY_STRING_DATE_FORMAT).isValid()) {
    return compareTo;
  }

  return "";
};

function internalFormatToCustomerFormat(dateString: string) {
  return moment(dateString, QUERY_STRING_DATE_FORMAT).format(DISPLAY_FORMAT);
}

const getDefaultRange = (): Array<string> => {
  const defaultEndDate = moment()
    .startOf("day")
    .subtract(1, "day");
  const defaultEndDateString = defaultEndDate.format(QUERY_STRING_DATE_FORMAT);

  const defaultStartDateString = moment(defaultEndDate)
    .startOf("day")
    .subtract(30 - 1, "days")
    .format(QUERY_STRING_DATE_FORMAT);

  return [
    defaultStartDateString,
    defaultEndDateString,
    DEFAULT_NAMED_DATE_RANGE
  ];
};
