const aliasPathRegex = /\/s\/([a-z0-9][a-z0-9-]{1,61}[a-z0-9]).*/;

const getSiteAliasFromURL = topLevelPath =>
  extractAliasFromPath(
    window.location.pathname,
    window.location.search,
    topLevelPath
  );

const extractAliasFromPath = (path, query, topLevelPath) => {
  let regex = aliasPathRegex;
  if (topLevelPath) {
    regex = new RegExp(topLevelPath + "/([a-z0-9][a-z0-9-]{1,61}[a-z0-9]).*");
  }

  const pathRes = regex.exec(path);
  let res = "";
  if (pathRes && pathRes.length > 1) {
    res = pathRes[pathRes.length - 1];
  } else {
    const params = new URLSearchParams(query);
    res = params.get("site");
  }

  if (res) {
    localStorage.setItem("site_alias", res || "");
    return res;
  }

  return localStorage.getItem("site_alias") || "";
};

export { extractAliasFromPath };
export default getSiteAliasFromURL;
