// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import { UnlinkClientSiteFromManagerSiteRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";

// Removes a  manager-client link between the specified sites.
export async function unlinkClientSiteFromManagerSite(
  clientSiteAlias,
  managerSiteAlias
) {
  if (!managerSiteAlias || !clientSiteAlias) {
    return undefined;
  }

  const unlinkRequest = proto.set(
    new UnlinkClientSiteFromManagerSiteRequest(),
    {
      managerSiteAlias,
      clientSiteAlias
    }
  );

  const response = await GRPCWebClient.unlinkClientSiteFromManagerSite(
    unlinkRequest
  );
  return response?.toObject();
}
