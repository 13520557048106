import { buildDateRangeV2Proto } from "Common/utils/DateUtilities";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";
import {
  GetAsinPerformanceMetricsRequest,
  GetAsinPerformanceMetricsReply,
  RequestedPerformanceMetrics
} from "Common/proto/edge/grpcwebPb/grpcweb_PerformanceMetrics_pb";

type AsinMetrics = GetAsinPerformanceMetricsReply.AsinMetrics.AsObject;

export async function getAsinPerformanceMetrics(
  siteAlias: string,
  startDate: string,
  endDate: string,
  requestedMetrics?: Array<RequestedPerformanceMetrics.Options>
): Promise<Array<AsinMetrics>> {
  const { dateRangeProto } = buildDateRangeV2Proto(startDate, endDate);

  const req = new GetAsinPerformanceMetricsRequest();
  req.setSiteAlias(siteAlias);
  req.setDateRange(dateRangeProto);

  // Not specifying metrics defaults to including ALL
  if (requestedMetrics?.length) {
    req.setMetricsInclusionsList([...requestedMetrics]);
  }

  // Accumulate streamed metrics for access by ASIN
  const asinPerformanceMetrics: Array<AsinMetrics> = [];
  try {
    await streamProcessor(
      GRPCWebCallbackClient.getAsinPerformanceMetrics(req),
      (reply: GetAsinPerformanceMetricsReply) => {
        const metrics = reply?.toObject()?.asinMetricsList || [];

        asinPerformanceMetrics.push(...metrics);
      }
    );
  } catch (err) {
    console.error(err);
  }

  return asinPerformanceMetrics;
}
