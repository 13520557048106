import React, { useState } from "react";
import { Input, Label, Message } from "semantic-ui-react";
import { CampaignReviewSection } from "./ReviewCampaignsStage";

import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";
import { MarketplaceInfo } from "Common/utils/marketplace";
import { None } from "Common/utils/tsUtils";

export const campaignNameValidator = (name: string): Set<string> => {
  // campaignNameField can be empty because we will fallback to the default campaign name if none is
  // provided.
  if (name === "") {
    return new Set<string>();
  }
  const errors = new Set<string>();

  if (name.length > 244) {
    errors.add("Campaign name must be less than 255 characters");
  }

  if (name.includes(";")) {
    errors.add("Campaign name cannot contain a semi-colon");
  }

  return errors;
};

export const campaignNameField = (initialValue: string): FormField<string> => ({
  _value: initialValue,
  errors: campaignNameValidator(initialValue),
  validator: campaignNameValidator
});

export const getDefaultCampaignName = (
  marketplaceInfo: MarketplaceInfo | None,
  target: string,
  headline: string
): string => {
  if (!headline) {
    return `${marketplaceInfo?.domain} ${target}`;
  }

  return `${marketplaceInfo?.domain} ${target} ${headline}`;
};

export const CampaignNameReviewSection = ({
  state,
  dispatch,
  onEdit
}: {
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;

  const [campaign, { selectCampaignName }] = getCurrentCampaignAndSelectors(
    state
  );
  const {
    campaignNameField: { validator: validateName, errors: nameErrors }
  } = campaign;
  const campaignName = selectCampaignName(campaign, state);

  const [tempCampaignName, setTempCampaignName] = useState<string>(
    campaignName
  );

  const [localErrors, setLocalErrors] = useState<Set<string>>(nameErrors);

  const handleSave = () => {
    dispatch({
      name: "UpdateCampaignName",
      data: {
        index: currentReviewCampaignIndex,
        campaignName: tempCampaignName
      }
    });
  };

  const handleCancel = () => {
    setTempCampaignName(campaignName);
  };

  const dataComponent = <p>{campaignName}</p>;

  const editComponent = (
    <>
      <p>
        The campaign name is to help you keep track of your campaigns. It does
        not effect how your ad appears to users.
      </p>
      <Input
        labelPosition="left"
        style={{ width: "100%" }}
        value={tempCampaignName}
        placeholder="Enter a name for your campaign"
        onChange={(_ev, data) => {
          setTempCampaignName(data.value);
        }}
        onBlur={() => {
          const errors = validateName(tempCampaignName);
          setLocalErrors(errors);
        }}
      >
        <Label basic>[Ampd]</Label>
        <input />
      </Input>
      {localErrors.size > 0 && (
        <Message negative>
          {Array.from(localErrors).map(error => (
            <p key={error}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  return (
    <div>
      <CampaignReviewSection
        dataName="Campaign Name"
        dataComponent={dataComponent}
        disableSave={!!localErrors.size || !tempCampaignName.length}
        editComponent={editComponent}
        errors={nameErrors}
        onEdit={onEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </div>
  );
};
