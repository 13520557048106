import _ from "lodash";

import React, { useState } from "react";
import { Confirm, List } from "semantic-ui-react";
import styled from "styled-components/macro";

import SimpleTooltip from "../../components/SimpleTooltip";
import OneClickButton from "../../../Common/components/OneClickButton";
import { unlinkClientSiteFromManagerSite } from "../../grpc/unlinkClientSiteFromManagerSite";
import { extractErrorMessage } from "Common/errors/error";

const LinkButton = styled.a`
  font-style: normal;
  font-weight: bold;
`;

function UnlinkClientAccountButton({
  managerSiteAlias,
  clientSiteAlias,
  clientSites,
  onDone
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const clientSite = _.find(
    clientSites,
    clientSite => clientSite.clientSiteAlias === clientSiteAlias
  );

  const handleUnlinkButtonClicked = () => {
    setConfirmOpen(true);
  };

  const handleUnlinkClientAccounts = async () => {
    await unlinkClientSiteFromManagerSite(
      clientSiteAlias,
      managerSiteAlias
    ).catch(e => {
      const message = extractErrorMessage(e);
      console.error(message);
    });

    setConfirmOpen(false);

    if (onDone) {
      onDone(clientSiteAlias);
    }
  };

  return (
    <>
      <SimpleTooltip tooltip={"Disconnect this client account"}>
        <LinkButton onClick={handleUnlinkButtonClicked}>Disconnect</LinkButton>
      </SimpleTooltip>
      {confirmOpen && (
        <Confirm
          open={confirmOpen}
          onCancel={e => {
            e.stopPropagation();
            setConfirmOpen(false);
          }}
          confirmButton={{ as: OneClickButton, content: "OK" }}
          onConfirm={handleUnlinkClientAccounts}
          content={
            <div style={{ margin: "1.5rem" }}>
              <h3>
                Are you sure you want to disconnect from this client account?
              </h3>
              <List bulleted>
                <List.Item>{clientSite?.clientSiteName}</List.Item>
              </List>
            </div>
          }
        />
      )}
    </>
  );
}

export default UnlinkClientAccountButton;
