import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getAsinPerformanceMetrics } from "ExtensionV2/grpc/getAsinPerformanceMetrics";
import {
  GetAsinPerformanceMetricsReply,
  RequestedPerformanceMetrics
} from "Common/proto/edge/grpcwebPb/grpcweb_PerformanceMetrics_pb";
export type AsinMetrics = GetAsinPerformanceMetricsReply.AsinMetrics.AsObject;

export const GET_ASIN_PERFORMANCE_METRICS_QUERY_KEY_NAME =
  "getAsinPerformanceMetrics";

// Since productIds (like ASIN) are only guaranteed unique per marketplace, we
// must ensure that the productId data we are using is marketplace-specific.
// To keep the access flat (easier for dropdown values & such), each unique
// marketplace-productId combination is stored by a custom key made up of the
// combination.
// Example Key: "2_B111111111"  (ie: <marketplace_enum_number>_<productId>)
export const getProductIdKey = (
  marketplace: number | null,
  productId: string
): string | null =>
  marketplace && productId ? `${marketplace}_${productId}` : null;

// Returns the ProductIdKey marketplace prefix.
export const getProductIdKeyPrefix = (marketplace: number): string | null =>
  marketplace ? `${marketplace}_` : null;

// Returns the single aggregated value form an aggregates array. Aggregates
// share an array-type with Timeseries, but the aggregates array only contains a
// single appropriately-aggregated value at position zero. This helper retrieves
// that value.
export const getAggregate = (
  aggregateList: Array<number> | undefined
): number | null => aggregateList?.[0] || null;

// Retrieves Ads Performance metrics by ASIN for the requested site & date range.
// Includes "dateRangeAggregated" summary metrics as well as daily "timeseries"
// metrics for ASIN product performance including: GoogleAds, AmazonAds,
// Organics, ASIN related ranks, etc.
export const useAsinPerformanceMetricsByKey = ({
  siteAlias,
  startDate,
  endDate,
  requestedMetrics
}: {
  siteAlias: string;
  startDate: string;
  endDate: string;
  requestedMetrics: Array<RequestedPerformanceMetrics.Options>;
}): UseQueryResult<{ [productIdKey: string]: AsinMetrics }> => {
  return useQuery({
    queryKey: [
      GET_ASIN_PERFORMANCE_METRICS_QUERY_KEY_NAME,
      siteAlias,
      startDate,
      endDate,
      requestedMetrics.join("-")
    ],
    staleTime: 15 * 60 * 1_000, // 15 minutes
    enabled: !!siteAlias,
    queryFn: async () => {
      const asinPerformanceMetrics = await getAsinPerformanceMetrics(
        siteAlias,
        startDate,
        endDate,
        requestedMetrics
      );

      // Map by ASIN key (marketplace+asin)
      return (asinPerformanceMetrics || []).reduce(
        (byKey: { [asinKey: string]: AsinMetrics }, current: AsinMetrics) => {
          const asinKey = getProductIdKey(current.marketplace, current.asin);

          if (!asinKey) {
            return byKey;
          }

          byKey[asinKey] = current;

          return byKey;
        },
        {}
      );
    }
  });
};
