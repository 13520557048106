import _ from "lodash";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import { useInterval } from "Common/hooks/useInterval";

import AdMockUp from "../AdMockUp";

// Extracts the domain from the URL, or returns an empty string if that fails.
function extractDomainFromURL(url) {
  try {
    return new URL(url).host;
  } catch (_) {
    return "";
  }
}

/* 
  Specialization of AdMockUp built for the product RSA flow. RSAs (responsive search ads)
  are Google Ads that dynamically mix and match user provided titles and descriptions to try to
  find the most effective combo with using ML. Here we randomly rotate through titles/descriptions to
  try to give our customers a sense of how the ad might actually appear on Google.
*/
export default function RSAAdMockUp(props) {
  const { productURL, headlines, descriptions, style, initiallyPaused } = props;

  const domain = useMemo(() => extractDomainFromURL(productURL), [productURL]);
  const [sampleHeadlines, setSampleHeadlines] = useState([]);
  const [sampleDescriptions, setSampleDescriptions] = useState([]);
  const [paused, setPaused] = useState(initiallyPaused);

  const chooseHeadlines = useCallback(() => {
    let sample = headlines.slice(1);
    if (sample.length > 2) {
      sample = _.sampleSize(sample, 2);
    }
    if (headlines.length > 0 && headlines[0]) {
      sample.unshift(headlines[0].slice(0, 30));
    }

    if (!sample.some(Boolean)) {
      sample = [
        "<Product Name>",
        "<Feature or Benefit>",
        "<More Features or Ratings, Prime, etc.>"
      ];
    }

    setSampleHeadlines(sample);

    sample = descriptions.slice(1);
    if (sample.length > 1) {
      sample = _.sampleSize(sample, 1);
    }
    if (descriptions[0]) {
      sample.unshift((descriptions[0] || "").slice(0, 90));
    }

    if (!sample.some(Boolean)) {
      sample = [
        "<First description will go here and should have product details and benefits>",
        "<Additional description will go here and may not always show>"
      ];
    }

    setSampleDescriptions(sample);
  }, [headlines, descriptions]);

  // Choose sample headlines if we have none and there are headlines.
  if (sampleHeadlines.length === 0 && headlines?.length > 0) {
    chooseHeadlines();
  }

  useEffect(() => {
    // If the headlines or descriptions change, clear the sample headlines so
    // new ones are chosen on the next render.
    setSampleHeadlines([]);
    setSampleDescriptions([]);
  }, [headlines, descriptions]);

  const mayAnimate = headlines.length > 3 || descriptions.length > 2;

  useInterval(() => {
    if (!paused && mayAnimate) {
      chooseHeadlines();
    }
  }, 4000);

  const handlePlayPausedClicked = e => {
    e.stopPropagation();
    setPaused(!paused);
  };

  return (
    <div style={{ position: "relative" }}>
      <AdMockUp
        style={style}
        domain={domain}
        headline={sampleHeadlines.filter(Boolean).join(" | ")}
        description1={sampleDescriptions[0]}
        description2={sampleDescriptions[1]}
      >
        {mayAnimate && (
          <Button
            style={{
              position: "absolute",
              bottom: 5,
              right: -2
            }}
            size="mini"
            basic
            icon={paused ? "play" : "pause"}
            onClick={handlePlayPausedClicked}
          />
        )}
      </AdMockUp>
    </div>
  );
}
