import Immutable from "immutable";
import pLimit from "p-limit";

import { useEffect, useRef, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { randomString } from "Common/utils/strings";
import { queryGoogleAdsCampaignMetrics } from "../grpc/queryGoogleAds";

// Shared limiter used for report queries so there are not too many queries from
// one user in flight at the same time.
const queryPromiseLimiterMap = {};

function useMultiSiteAmazonAmpdCampaignMetrics({
  campaignIdsBySiteMap,
  onStart = undefined,
  onLoad = undefined,
  onFinish = undefined,
  dateRangeStartDate,
  dateRangeEndDate,
  queryGroup = "campaignMetrics"
}) {
  let queryPromiseLimiter = queryPromiseLimiterMap[queryGroup];
  if (!queryPromiseLimiter) {
    queryPromiseLimiter = pLimit(10);
    queryPromiseLimiterMap[queryGroup] = queryPromiseLimiter;
  }

  // The full state of the hook will be updated asynchronously as different
  // sites return from their query.  It is important that all inner loop
  // calls to 'setHookState' pass a callback instead of a value based on this
  // 'hookState' variable, because otherwise some results will be lost.
  // The 'hookState' variable will be returned on each render but any number of
  // updates could happen between renders.
  const [hookState, setHookState] = useState({
    sitesLoadingSet: Immutable.Set(),
    sitesErrorMap: Immutable.Map(),
    campaignMetricsMap: Immutable.Map()
  });
  const queryIdRef = useRef(null);

  const apolloClient = useApolloClient();

  useEffect(() => {
    let mounted = true;

    // Don't continue with previous queries that haven't been started yet.
    queryPromiseLimiter.clearQueue();

    // Let's create a random id to identify this set of queries.
    const queryId = randomString(16);
    queryIdRef.current = queryId;

    // Update the hook state directly at the start.
    setHookState({
      sitesLoadingSet: !dateRangeStartDate
        ? Immutable.Set()
        : Immutable.Set(campaignIdsBySiteMap.keys()),
      sitesErrorMap: Immutable.Map(),
      campaignMetricsMap: Immutable.Map()
    });
    if (campaignIdsBySiteMap.size === 0 || !dateRangeStartDate) {
      return () => (mounted = false);
    }

    if (onStart) {
      onStart(campaignIdsBySiteMap.size);
    }

    const collectCampaignMetrics = () =>
      campaignIdsBySiteMap
        .map((campaignIds, siteAlias) =>
          queryPromiseLimiter(async () => {
            if (!mounted || queryIdRef.current !== queryId) {
              return;
            }

            const { siteMetricsMap, err } =
              !campaignIds || campaignIds.length === 0
                ? { siteMetricsMap: Immutable.Map() }
                : await queryGoogleAdsCampaignMetrics(
                    apolloClient,
                    siteAlias,
                    dateRangeStartDate,
                    dateRangeEndDate,
                    false,
                    campaignIds
                  );

            // Update the hook state asynchronously through a callback.
            setHookState(state => {
              let {
                sitesLoadingSet,
                sitesErrorMap,
                campaignMetricsMap
              } = state;

              sitesLoadingSet = sitesLoadingSet.remove(siteAlias);
              if (err) {
                sitesErrorMap = sitesErrorMap.set(siteAlias, err);
              }

              siteMetricsMap.forEach((metricsList, campaignId) => {
                campaignMetricsMap = campaignMetricsMap.set(
                  campaignId,
                  metricsList[0]
                );
              });

              return {
                sitesLoadingSet,
                sitesErrorMap,
                campaignMetricsMap
              };
            });

            if (mounted && onLoad) {
              onLoad(siteAlias, siteMetricsMap);
            }
          })
        )
        .valueSeq()
        .toArray();

    Promise.all(collectCampaignMetrics())
      .then(() => {
        if (mounted && onFinish) {
          onFinish();
        }
      })
      .catch(err => {
        console.error(err);
        if (mounted && onFinish) {
          onFinish(err);
        }
      });

    return () => (mounted = false);
  }, [
    campaignIdsBySiteMap,
    onStart,
    onLoad,
    onFinish,
    dateRangeStartDate,
    dateRangeEndDate,
    apolloClient,
    queryPromiseLimiter
  ]);

  return hookState;
}

export default useMultiSiteAmazonAmpdCampaignMetrics;
