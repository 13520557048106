// source: google/ads/googleads/v16/resources/campaign_budget.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_ads_googleads_v16_enums_budget_delivery_method_pb = require('../../../../../google/ads/googleads/v16/enums/budget_delivery_method_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_budget_delivery_method_pb);
var google_ads_googleads_v16_enums_budget_period_pb = require('../../../../../google/ads/googleads/v16/enums/budget_period_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_budget_period_pb);
var google_ads_googleads_v16_enums_budget_status_pb = require('../../../../../google/ads/googleads/v16/enums/budget_status_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_budget_status_pb);
var google_ads_googleads_v16_enums_budget_type_pb = require('../../../../../google/ads/googleads/v16/enums/budget_type_pb.js');
goog.object.extend(proto, google_ads_googleads_v16_enums_budget_type_pb);
var google_api_field_behavior_pb = require('../../../../../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../../../../../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.google.ads.googleads.v16.resources.CampaignBudget', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.ads.googleads.v16.resources.CampaignBudget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.ads.googleads.v16.resources.CampaignBudget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.ads.googleads.v16.resources.CampaignBudget.displayName = 'proto.google.ads.googleads.v16.resources.CampaignBudget';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.toObject = function(opt_includeInstance) {
  return proto.google.ads.googleads.v16.resources.CampaignBudget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.google.ads.googleads.v16.resources.CampaignBudget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 19, 0),
    name: jspb.Message.getFieldWithDefault(msg, 20, ""),
    amountMicros: jspb.Message.getFieldWithDefault(msg, 21, 0),
    totalAmountMicros: jspb.Message.getFieldWithDefault(msg, 22, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    deliveryMethod: jspb.Message.getFieldWithDefault(msg, 7, 0),
    explicitlyShared: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    referenceCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    hasRecommendedBudget: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    recommendedBudgetAmountMicros: jspb.Message.getFieldWithDefault(msg, 26, 0),
    period: jspb.Message.getFieldWithDefault(msg, 13, 0),
    recommendedBudgetEstimatedChangeWeeklyClicks: jspb.Message.getFieldWithDefault(msg, 27, 0),
    recommendedBudgetEstimatedChangeWeeklyCostMicros: jspb.Message.getFieldWithDefault(msg, 28, 0),
    recommendedBudgetEstimatedChangeWeeklyInteractions: jspb.Message.getFieldWithDefault(msg, 29, 0),
    recommendedBudgetEstimatedChangeWeeklyViews: jspb.Message.getFieldWithDefault(msg, 30, 0),
    type: jspb.Message.getFieldWithDefault(msg, 18, 0),
    alignedBiddingStrategyId: jspb.Message.getFieldWithDefault(msg, 31, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.ads.googleads.v16.resources.CampaignBudget;
  return proto.google.ads.googleads.v16.resources.CampaignBudget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.ads.googleads.v16.resources.CampaignBudget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountMicros(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalAmountMicros(value);
      break;
    case 6:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BudgetStatusEnum.BudgetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BudgetDeliveryMethodEnum.BudgetDeliveryMethod} */ (reader.readEnum());
      msg.setDeliveryMethod(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExplicitlyShared(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReferenceCount(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRecommendedBudget(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecommendedBudgetAmountMicros(value);
      break;
    case 13:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BudgetPeriodEnum.BudgetPeriod} */ (reader.readEnum());
      msg.setPeriod(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecommendedBudgetEstimatedChangeWeeklyClicks(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecommendedBudgetEstimatedChangeWeeklyCostMicros(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecommendedBudgetEstimatedChangeWeeklyInteractions(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecommendedBudgetEstimatedChangeWeeklyViews(value);
      break;
    case 18:
      var value = /** @type {!proto.google.ads.googleads.v16.enums.BudgetTypeEnum.BudgetType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAlignedBiddingStrategyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.google.ads.googleads.v16.resources.CampaignBudget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.ads.googleads.v16.resources.CampaignBudget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDeliveryMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeBool(
      23,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeBool(
      25,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getPeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeInt64(
      29,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getAlignedBiddingStrategyId();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 19;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setId = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearId = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasId = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string name = 20;
 * @return {string}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setName = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearName = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasName = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 amount_micros = 21;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getAmountMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setAmountMicros = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearAmountMicros = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasAmountMicros = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional int64 total_amount_micros = 22;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getTotalAmountMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setTotalAmountMicros = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearTotalAmountMicros = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasTotalAmountMicros = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.ads.googleads.v16.enums.BudgetStatusEnum.BudgetStatus status = 6;
 * @return {!proto.google.ads.googleads.v16.enums.BudgetStatusEnum.BudgetStatus}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getStatus = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BudgetStatusEnum.BudgetStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BudgetStatusEnum.BudgetStatus} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.ads.googleads.v16.enums.BudgetDeliveryMethodEnum.BudgetDeliveryMethod delivery_method = 7;
 * @return {!proto.google.ads.googleads.v16.enums.BudgetDeliveryMethodEnum.BudgetDeliveryMethod}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getDeliveryMethod = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BudgetDeliveryMethodEnum.BudgetDeliveryMethod} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BudgetDeliveryMethodEnum.BudgetDeliveryMethod} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setDeliveryMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool explicitly_shared = 23;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getExplicitlyShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setExplicitlyShared = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearExplicitlyShared = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasExplicitlyShared = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional int64 reference_count = 24;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getReferenceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setReferenceCount = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearReferenceCount = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasReferenceCount = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool has_recommended_budget = 25;
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getHasRecommendedBudget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setHasRecommendedBudget = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearHasRecommendedBudget = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasHasRecommendedBudget = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional int64 recommended_budget_amount_micros = 26;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getRecommendedBudgetAmountMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setRecommendedBudgetAmountMicros = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearRecommendedBudgetAmountMicros = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasRecommendedBudgetAmountMicros = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.ads.googleads.v16.enums.BudgetPeriodEnum.BudgetPeriod period = 13;
 * @return {!proto.google.ads.googleads.v16.enums.BudgetPeriodEnum.BudgetPeriod}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getPeriod = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BudgetPeriodEnum.BudgetPeriod} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BudgetPeriodEnum.BudgetPeriod} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional int64 recommended_budget_estimated_change_weekly_clicks = 27;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getRecommendedBudgetEstimatedChangeWeeklyClicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setRecommendedBudgetEstimatedChangeWeeklyClicks = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearRecommendedBudgetEstimatedChangeWeeklyClicks = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasRecommendedBudgetEstimatedChangeWeeklyClicks = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional int64 recommended_budget_estimated_change_weekly_cost_micros = 28;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getRecommendedBudgetEstimatedChangeWeeklyCostMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setRecommendedBudgetEstimatedChangeWeeklyCostMicros = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearRecommendedBudgetEstimatedChangeWeeklyCostMicros = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasRecommendedBudgetEstimatedChangeWeeklyCostMicros = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional int64 recommended_budget_estimated_change_weekly_interactions = 29;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getRecommendedBudgetEstimatedChangeWeeklyInteractions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setRecommendedBudgetEstimatedChangeWeeklyInteractions = function(value) {
  return jspb.Message.setField(this, 29, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearRecommendedBudgetEstimatedChangeWeeklyInteractions = function() {
  return jspb.Message.setField(this, 29, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasRecommendedBudgetEstimatedChangeWeeklyInteractions = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional int64 recommended_budget_estimated_change_weekly_views = 30;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getRecommendedBudgetEstimatedChangeWeeklyViews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setRecommendedBudgetEstimatedChangeWeeklyViews = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.clearRecommendedBudgetEstimatedChangeWeeklyViews = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.hasRecommendedBudgetEstimatedChangeWeeklyViews = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.ads.googleads.v16.enums.BudgetTypeEnum.BudgetType type = 18;
 * @return {!proto.google.ads.googleads.v16.enums.BudgetTypeEnum.BudgetType}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getType = function() {
  return /** @type {!proto.google.ads.googleads.v16.enums.BudgetTypeEnum.BudgetType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.google.ads.googleads.v16.enums.BudgetTypeEnum.BudgetType} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional int64 aligned_bidding_strategy_id = 31;
 * @return {number}
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.getAlignedBiddingStrategyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.google.ads.googleads.v16.resources.CampaignBudget} returns this
 */
proto.google.ads.googleads.v16.resources.CampaignBudget.prototype.setAlignedBiddingStrategyId = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


goog.object.extend(exports, proto.google.ads.googleads.v16.resources);
