import styled from "styled-components/macro";
import { NavLink } from "react-router-dom-v5-compat";

import {
  backgroundDark,
  backgroundLight,
  darkAccent
} from "ExtensionV2/styles/colors";
import SimpleTooltip from "../SimpleTooltip";

export const NARROW_VIEW = 1350;

export const SidebarNavItemTooltip = styled(SimpleTooltip)`
  display: none;
  @media (min-width: ${NARROW_VIEW}px) {
    display: none !important;
  }
`;

export const SidebarNavItem = styled(NavLink)`
  text-align: center;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${backgroundLight};
  /* Add 20% padding to go under the scrollbar gutter when it is not visible. */
  width: 120%;
  padding-left: 0.5em;
  @media (max-width: ${NARROW_VIEW}px) {
    padding-left: 1em;
  }
  padding-right: 1em;
  aspect-ratio: 120 / 80;
  cursor: pointer;
  border: none;
  &.active {
    background-color: ${backgroundDark};
  }
  &:hover {
    background-color: ${backgroundDark};
    text-decoration: none;
    ${SidebarNavItemTooltip} {
      display: block;
    }
  }
`;

export const AmpdGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(4em, min-content) 1fr;
  grid-template-rows: minmax(3em, 5vw) 1fr 6em;
  grid-template-areas:
    "header header"
    "sidebar content"
    "chat content";
`;

export const AmpdHeader = styled.div`
  justify-content: space-between;
  display: flex;
  background-color: ${darkAccent};
  padding: 0 2em;
  grid-area: header;
`;

export const AmpdContentArea = styled.main`
  grid-area: content;
  background-color: ${backgroundDark};
  height: 95vh;
  width: 100%;
  overflow-x: auto;
  padding: 0.5em;
`;

export const AmpdNavBar = styled.nav`
  grid-area: sidebar;
  background-color: ${backgroundLight};
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
`;

export const AmpdChatReserve = styled.div`
  grid-area: chat;
  background-color: ${backgroundLight};
  overflow: hidden;
`;

export const NavItemLabel = styled.p<{ disabled?: boolean }>`
  align-self: center !important;
  color: ${props => (props.disabled ? "gray" : "initial")};
  font-size: small;
  @media (max-width: ${NARROW_VIEW}px) {
    display: none;
  }
`;

export const NavItemIcon = styled.div`
  margin: 0 auto 0.5em auto !important;
  display: block;
`;
